import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { resetPassword } from "../../../../redux/actions/userActions";
import { NotificationManager } from "react-notifications";
import Header from "../../../common/header";
import { Base64 } from "js-base64";

/* eslint-disable no-useless-escape */

//Password should be at least 8 characters with mix of letters,numbers and numeric characters.
const passwordRegex = RegExp(
  /(?=[A-Za-z0-9!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~])(?=.{8,}).*$/
  ///^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~])[a-zA-Z0-9!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~]{8}$/
);

const formValid = (formErrors) => {
  let valid = true;
  Object.values(formErrors).forEach(function (val) {
    val.length > 0 && (valid = false);
  });

  return valid;
};

class resetpassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reset: false,
      password: null,
      password_confirm: null,
      isLoading: false,
      countTimer: 1,
      formErrors: {
        password: "",
        password_confirm: "",
        notMatch: "",
        otherMessage: "",
        timerError: "",
      },
    };
  }
  clearInputs = () => {
    this.setState({
      password: "",
      password_confirm: "",
    });
  };

  onHandleSubmit = (e) => {
    let fErrors = this.state.formErrors;
    fErrors.password = "";
    fErrors.password_confirm = "";
    fErrors.notMatch = "";
    fErrors.otherMessage = "";

    e.preventDefault();
    fErrors.password =
      passwordRegex.test(this.state.password) && this.state.password.length > 0
        ? ""
        : "Invalid password (Password should be at least 8 characters with mix of letters,numbers & numeric characters.)";
    fErrors.password_confirm =
      passwordRegex.test(this.state.password_confirm) &&
      this.state.password_confirm.length > 0
        ? ""
        : "Please enter confirm password";

    fErrors.notMatch =
      this.state.password !== this.state.password_confirm &&
      this.state.password !== null &&
      this.state.password_confirm !== null
        ? "New password and confirm password do not match, please try again."
        : "";

    this.setState({
      formErrors: fErrors,
    });

    if (formValid(this.state.formErrors)) {
      this.setState({ isLoading: true });
      const currentURL = window.location.href;
      let firstSplit = currentURL.split("resetpassword/")[1];
      let token = firstSplit.split("/")[0];
      this.props
        .resetPassword(Base64.encode(this.state.password), token)
        .then((res) => {
          this.setState({ isLoading: false });
          if (res.data.portalErrorCode === 200) {
            this.clearInputs();
            NotificationManager.success("Password changed successfully");
            this.setState({
              reset: true,
            });
          } else if (!res.data.isError) {
            if (res.data.portalErrorCode === 401) {
              fErrors.otherMessage = "Your password reset link is already used";
            } else if (!res.data.result.isValid) {
              fErrors.otherMessage = "Time is up";
            } else {
              fErrors.otherMessage = "Error occured";
            }
          } else {
            fErrors.otherMessage = "Error while authentication";
          }
          this.setState({ formErrors: fErrors });
        });
    } else {
      console.log(`Form Invalid - Display Error Message`);
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case "password":
        formErrors.password = value.length > 0 ? "" : "Enter password";
        break;
      case "password_confirm":
        formErrors.password_confirm =
          value.length > 0 ? "" : "Enter confirm password";
        break;
      default:
        break;
    }
    this.setState({ formErrors, [name]: value });
  };

  render() {
    if (this.state.reset) {
      return <Redirect to={"/login"} />;
    }
    const { formErrors } = this.state;
    return (
      <>
        <Header />
        <div className="loginBg d-flex justify-content-center align-items-center flex-wrap">
          <div className="LoginWindow rounded pb-4">
            <h1 className="primary-heading mb-2">New password</h1>
            <p className="mb-3">Please enter your new pssword</p>
            {formErrors.notMatch.length > 0 && (
              <div class="alert">{formErrors.notMatch}</div>
            )}
            {formErrors.otherMessage.length > 0 && (
              <div class="alert">{formErrors.otherMessage}</div>
            )}
            {formErrors.timerError.length > 0 && (
              <div class="alert">{formErrors.timerError}</div>
            )}
            {this.state.isLoading && <div className="loader"></div>}
            <div className="formGroup">
              <label>New Password</label>

              <input
                type="password"
                name="password"
                placeholder="********"
                className="inputstyle"
                onChange={this.handleChange}
              />
              <div className="helptext">
                Password should be at least 8 characters with mix of letters,
                numbers &amp; numeric characters.
              </div>
              {formErrors.password.length > 0 && (
                <span className="errorMessage">{formErrors.password}</span>
              )}
            </div>
            <div className="formGroup">
              <label>Confirm Password</label>
              <input
                type="password"
                name="password_confirm"
                placeholder="********"
                className="inputstyle"
                onChange={this.handleChange}
              />
              {formErrors.password_confirm.length > 0 && (
                <span className="errorMessage">
                  {formErrors.password_confirm}
                </span>
              )}
            </div>
            <Button
              className="generalBtn mt-3 mb-2"
              type="submit"
              onClick={this.onHandleSubmit}
            >
              Submit
            </Button>
            <p className="copyright">Copyright @ 2021</p>
          </div>
        </div>
      </>
    );
  }
  componentDidMount() {
    const currentURL = window.location.href;
    const month = currentURL.split("resetpassword/")[1].split("/")[1];
    const str = currentURL.split("resetpassword/")[1].split("/")[3];
    const year = str.substr(0, 4);
    const day = currentURL.split("resetpassword/")[1].split("/")[2];
    const time = str
      .substr(4, str.length - 1)
      .replace("%20", " ")
      .replace("%20", " ");
    const strDate = year + "/" + month + "/" + day + time;
    const dateNow = new Date()
      .toISOString()
      .replace(/T/, " ")
      .replace(/\..+/, "");
    let diffMs = new Date(strDate) - new Date(dateNow);
    let diffDays = Math.floor(diffMs / 86400000); // days
    let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    let valMins = Math.abs(diffMins);
    let valDays = Math.abs(diffDays);
    let valHrs = Math.abs(diffHrs);
    if (valDays > 1 || valHrs > 1 || valMins > 30) {
      let fErrors = this.state.formErrors;
      fErrors.timerError = "Time is up!";
      this.setState({
        formErrors: fErrors,
      });
    } else {
      let remainMin = 30 - valMins;
      let second = remainMin * 60;
      let valSec = Math.abs(second);
      const startCount = valSec;
      this.setState({
        countTimer: startCount,
      });
      this.doIntervalChange();
    }
  }
  doIntervalChange = () => {
    this.myInterval = setInterval(() => {
      this.setState((prevState) => ({
        countTimer: prevState.countTimer - 1,
      }));

      if (this.state.countTimer <= 0) {
        let fErrors = this.state.formErrors;
        fErrors.timerError = "Time is up!";
        this.setState({
          formErrors: fErrors,
        });
      }
    }, 1000);
  };
  componentWillUnmount() {
    clearInterval(this.myInterval);
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (data, token) => dispatch(resetPassword(data, token)),
  };
};
export default connect(null, mapDispatchToProps)(resetpassword);
