export const GET_CLIENT_LIST_REQUEST = "GET_CLIENT_LIST_REQUEST";
export const GET_CLIENT_LIST_SUCCESS = "GET_CLIENT_LIST_SUCCESS";
export const GET_CLIENT_LIST_FAIL = "GET_CLIENT_LIST_FAIL";

export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAIL = "DELETE_CLIENT_FAIL";

export const ADD_CLIENT_REQUEST = "ADD_CLIENT_REQUEST";
export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_FAIL = "ADD_CLIENT_FAIL";

export const GET_CLIENT_BY_ID_REQUEST = "GET_CLIENT_BY_ID_REQUEST";
export const GET_CLIENT_BY_ID_SUCCESS = "GET_CLIENT_BY_ID_SUCCESS";
export const GET_CLIENT_BY_ID_FAIL = "GET_CLIENT_BY_ID_FAIL";

export const UPDATE_CLIENT_REQUEST = "UPDATE_CLIENT_REQUEST";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAIL = "UPDATE_CLIENT_FAIL";

export const BULK_DELETE_REQUEST = "BULK_DELETE_REQUEST";
export const BULK_DELETE_SUCCESS = "BULK_DELETE_SUCCESS";
export const BULK_DELETE_FAIL = "BULK_DELETE_FAIL";

export const APPROVE_REJECT_FROM_MAIL_REQUEST =
  "APPROVE_REJECT_FROM_MAIL_REQUEST";
export const APPROVE_REJECT_FROM_MAIL_SUCCESS =
  "APPROVE_REJECT_FROM_MAIL_SUCCESS";
export const APPROVE_REJECT_FROM_MAIL_FAIL = "APPROVE_REJECT_FROM_MAIL_FAIL";
export const GET_All_OFFICES_REQUEST = "GET_All_OFFICES_REQUEST";
export const GET_All_OFFICES_SUCCESS = "GET_All_OFFICES_SUCCESS";
export const GET_All_OFFICES_FAIL = "GET_All_OFFICES_FAIL";

export const CLIENT_APPROVAL_LIST_REQUEST = "CLIENT_APPROVAL_LIST_REQUEST";
export const CLIENT_APPROVAL_LIST_SUCCESS = "CLIENT_APPROVAL_LIST_SUCCESS";
export const CLIENT_APPROVAL_LIST_FAIL = "CLIENT_APPROVAL_LIST_FAIL";

export const APPROVE_REJECT_CLIENT_REQUEST = "APPROVE_REJECT_CLIENT_REQUEST";
export const APPROVE_REJECT_CLIENT_SUCCESS = "APPROVE_REJECT_CLIENT_SUCCESS";
export const APPROVE_REJECT_CLIENT_FAIL = "APPROVE_REJECT_CLIENT_FAIL";

export const ASSIGN_USER_LIST_REQUEST = "ASSIGN_USER_LIST_REQUEST";
export const ASSIGN_USER_LIST_SUCCESS = "ASSIGN_USER_LIST_SUCCESS";
export const ASSIGN_USER_LIST_FAIL = "ASSIGN_USER_LIST_FAIL";
