import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { connect } from "react-redux";
import { setAllErrorsToNull } from "../../../../redux/actions/InternalChapterErrorActions";
let formData;


let validForms = {};
let isCAFValid;
class NLInternalSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: null,
    };
  }
  render() {
    const { location } = this.props.history;
    const { internalErrors } = this.props;
    Object.entries(internalErrors).forEach((errors) => {
      const [key, value] = errors;
      const error = value?.errors;
      if (error) {
        validForms[key] = error;
      }
    });
    setInterval(() => {
      if (!formData) {
        formData = localStorage.getItem("formData");
        if (formData) {
          this.setState({ formData: formData });
        }
        isCAFValid = localStorage.getItem("isCAFValid");
      }
    }, 100);
    return (
      <div className="sidebar">
        <ul>
          
          <li>
            <label>Client Maintenance</label>

            <ul className="sidebar-innerlist">
              <li>
                <Link
                  to="/clientlist"
                  onClick={() => {
                    formData = null;
                    isCAFValid = null;
                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={
                    location.pathname === "/clientlist" ? "active" : ""
                  }
                >
                  <i className="fa fa-user fa-fw"></i> Client list
                </Link>
                <Link
                  to="/clientapprovals"
                  onClick={() => {
                    formData = null;
                    isCAFValid = null;
                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={
                    location.pathname === "/clientapprovals" ? "active" : ""
                  }
                >
                  <i className="fa fa-check-circle fa-fw"></i> Client User Approvals
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <label>User Maintenance</label>
            {/* <Link to="/userlist"  className={activeIndex === "userlist" ? "active" : ""}>User Maintainance</Link> */}
            <ul className="sidebar-innerlist">
              <li>
                <Link
                  to="/userlist"
                  onClick={() => {
                    formData = null;
                    isCAFValid = null;
                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={location.pathname === "/userlist" ? "active" : ""}
                >
                  <i className="fa fa-user fa-fw"></i> User list
                </Link>
                <Link
                  to="/userapprovals"
                  onClick={() => {
                    formData = null;
                    isCAFValid = null;
                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={
                    location.pathname === "/userapprovals" ? "active" : ""
                  }
                >
                  <i className="fa fa-check-circle fa-fw"></i> User Approvals
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <label>Client Acceptance Form</label>
            <ul className="sidebar-innerlist">
              <li>
                <Link
                  onClick={() => {
                    formData = null;
                    isCAFValid = null;
                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  to="/client-form-list"
                  className={
                    location.pathname === "/client-form-list" ? "active" : ""
                  }
                >
                  <i className="fa fa-user fa-fw"></i> Client Form List
                </Link>
              </li>
            </ul>
            <ul className="sidebar-innerlist subPoint">
              <li>
                <Link
                  to="/netherlands/client-activity"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter1?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/netherlands/client-activity"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-check fa-fw"></i> 1-Client Activity{" "}
                </Link>
              </li>
              <li>
                <Link
                  to="/netherlands/client-investigation"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter2?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/netherlands/client-investigation"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-search fa-fw"></i> 2-Intermediary/ Feeder{" "}
                </Link>
              </li>
              <li>
                <Link
                  to="/netherlands/ubo-upc"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter3?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/netherlands/ubo-upc" ? "active" : ""
                  }
                >
                  <i className="fa fa-user-circle fa-fw"></i> 3-UBO/UPC
                </Link>
              </li>
              <li>
                <Link
                  to="/netherlands/object-company"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter4?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/netherlands/object-company"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-building-o fa-fw"></i> 4-Object Company
                </Link>
              </li>
              <li>
                <Link
                  to="/netherlands/country-sanction-risks"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter5?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/netherlands/country-sanction-risks"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-globe fa-fw"></i> 5-Country/Sanction Risks
                </Link>
              </li>
              <li>
                <Link
                  to="/netherlands/agreement"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter6?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/netherlands/agreement"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-thumbs-up fa-fw"></i> 6-Agreements- Purpose of Trust services
                </Link>
              </li>
              <li>
                <Link
                  to="/netherlands/consolidated-risk-assessment"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter8?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname ===
                    "/netherlands/consolidated-risk-assessment"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-check-square-o fa-fw"></i> 7-Consolidated
                  Risk Assessment
                </Link>
              </li>
              <li>
                <Link
                  to="/netherlands/correspondence"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter9?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/netherlands/correspondence"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-file-text-o fa-fw"></i> 8-Correspondence
                </Link>
              </li>
              <li>
                <Link
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter10?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  to="/netherlands/miscellaneous"
                  className={
                    location.pathname === "/netherlands/miscellaneous"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-cog fa-fw"></i> 9-Miscellaneous
                </Link>
              </li>
              <li>
                <Link
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter11?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  to="/netherlands/summary-sheet-caf"
                  className={
                    location.pathname === "/netherlands/summary-sheet-caf"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-list-alt fa-fw"></i> 10-Summery sheet CAF
                </Link>
              </li>
              <li>
                <Link
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter12?.isValid === true
                        ? "bolder"
                        : null,
                  }}
                  to="/netherlands/formal-sign-off"
                  className={
                    location.pathname === "/netherlands/formal-sign-off"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-pencil-square-o fa-fw"></i> 11-Formal sign
                  off
                </Link>
              </li>
              <li>
                <Link
                  style={{
                    pointerEvents: isCAFValid ? "" : "none",
                    opacity: isCAFValid ? "" : "0.5",
                  }}
                  to="/netherlands/signature"
                  className={
                    (location.pathname === "/netherlands/signature") === true
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-font fa-fw"></i> Signature
                </Link>
              </li>
            </ul>
          </li>

          {/* <li>
            <label>CAF Form</label>

            <ul className="sidebar-innerlist">
              <li>
                <Link
                  to="/clientactivity"
                  className={activeIndex === "clientactivity" ? "active" : ""}
                >
                  <i className="fa fa-cog fa-fw"></i> Client Acceptance Form
                </Link>
              </li>
            </ul>
          </li> */}
          {/* 
          <li>
            <Link to="/changepassword"  className={activeIndex === "changepassword" ? "active" : ""}>Change Password</Link>
          </li> */}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    internalErrors: state.InternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAllErrorToNull: () => dispatch(setAllErrorsToNull()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NLInternalSidebar)
);
