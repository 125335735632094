import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import DropDown from "../../../../elements/DropDown";
import TextBox from "../../../../elements/TextBox";
import cc from "currency-codes";

let currencies = [];
cc.codes().map((currency) => currencies.push({ label: currency }));

const objBaseName = "shareCapComp";
class ShareCapital extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isShrCapComp, chapter } = this.props;

    return (
      <Row className="pt-3 pb-2">
        <div className="mb-3 col-lg-4">
          <CheckBox
            label="Is this a share capital of the target company?"
            info="Minimum authorized share capital is EUR 1,164,69, 20% paid up"
            options={["Yes", "No"]}
            name="isShrCapComp"
            id="isShrCapComp"
            objBaseName={objBaseName}
            chapter={chapter}
            disabled={true}
            reducer={this.props.externalReducer}
          />
        </div>
        {isShrCapComp === "Yes" && (
          <>
            <div className="mb-3 col-lg-4">
              <DropDown
                label="Please specify currency"
                name="shrCapCurrency"
                id="shrCapCurrency"
                options={currencies}
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                disabled={true}
                objBaseName={objBaseName}
              />
            </div>
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Authorized share capital"
                name="authrzdShrCap"
                id={`authrzdShrCap`}
                placeholder="enter your text"
                objBaseName={objBaseName}
                chapter={chapter}
                reducer={this.props.externalReducer}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Issued share capital"
                name="issuedShrCap"
                id={`issuedShrCap`}
                placeholder="enter your text"
                objBaseName={objBaseName}
                chapter={chapter}
                reducer={this.props.externalReducer}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Denomination"
                name="shrCapDenm"
                id={`shrCapDenm`}
                placeholder="enter your text"
                objBaseName={objBaseName}
                chapter={chapter}
                reducer={this.props.externalReducer}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          </>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { externalReducer } = ownProps;
  return {
    isShrCapComp:
      state[externalReducer]["chapter4"]["shareCapComp"]["isShrCapComp"],
  };
};

export default connect(mapStateToProps, null)(ShareCapital);
