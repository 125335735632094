import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import NumberInput from "../../../../elements/NumberInput";
import TextBox from "../../../../elements/TextBox";
import { AdditionDocs } from "../../../../JSONs/InternalUser/Chapter1";
import AdditionalDocs from "./AdditionalDocs";
import InternalFileUpload from "../../../../elements/InternalFileUpload";
import { fileAcceptType } from "../../Onboarding/Constants";

class MediaCoverage extends React.Component {
  render() {
    const {
      chapter,
      reducer,
      isMediaCoverageOnStruct,
      isAddDocs,
      numAddDocs,
      errors,
      isRequired,
    } = this.props;
    let errorsMsg = JSON.parse(errors);
    let docs = [];
    for (let i = 0; i < numAddDocs; i++) {
      docs.push(
        <AdditionalDocs
          index={i}
          errorsMsg={errors}
          baseName="addDocs"
          reducer={reducer}
          chapter={chapter}
          PdfGeneration={this.props.PdfGeneration}
          form={this.props.form}
          isRequired={isRequired}
        />
      );
    }

    return (
      <Row className="mb-3">
         {isMediaCoverageOnStruct === "Yes" && (<div className="html2pdf__page-break"></div>)}
        <div className="mb-2 col-lg-4">
          <CheckBox
            label={
              this.props.form === "Malta" && this.props.chapter === "chapter2"
                ? "Negative Media Coverage on the Intermediary / Agent?"
                : this.props.form === "Malta" &&
                  this.props.chapter === "chapter3"
                ? "Adverse media on the UBO?"
                : this.props.form === "Malta" &&
                  this.props.chapter === "chapter4"
                ? "Media Coverage on the Target Company"
                : this.props.form === "Malta" &&
                  this.props.chapter === "chapter5"
                ? "Negative Media on the countries and the relevant parties involved in the structure"
                : this.props.form === "Malta"
                ? "Is there any negative media coverage in the structure?"
                :  this.props.form === "UK" && this.props.chapter === "chapter2"
                ? "Are there further investigation required following the Pascal report?" 
                :  this.props.form === "UK" && this.props.chapter === "chapter3"
                ? "Are there further investigation required following the Pascal report?" 
                : "Are Pascal checks performed on the structure?"
            }
            name="isMediaCoverageOnStruct"
            id="isMediaCoverageOnStruct"
            options={["Yes", "No"]}
            chapter={chapter}
            isRequired={isRequired}
            reducer={reducer}
            isMultiple={true}
            isRequired={isRequired}
          />
          {errorsMsg &&
            errorsMsg.isSubmitted === true &&
            errorsMsg?.isMediaCoverageOnStruct?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errorsMsg.isMediaCoverageOnStruct}
              </span>
            )}
        </div>
        {isMediaCoverageOnStruct === "Yes" && this.props.form !== "UK" && (
          <div className="col-lg-8">
            <Row>
              {this.props.form === "Malta" && (
                <div className="col-lg-12 mb-3">
                  <TextBox
                    label="Please add details of the negative media coverage"
                    name="mediaCmnt"
                    id="mediaCmnt"
                    placeholder="Enter your text"
                    reducer={reducer}
                    chapter={chapter}
                    isRequired={isRequired}
                  />{" "}
                  {errorsMsg &&
                    errorsMsg.isSubmitted === true &&
                    errorsMsg?.mediaCmnt &&
                    errorsMsg?.mediaCmnt?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.mediaCmnt}
                      </span>
                    )}
                </div>
              )}
            </Row>          
          
            <Row>
              <div className="col-lg-6">
                <CheckBox
                  label="Do you have any additional documents?"
                  name="isAddDocs"
                  id="isAddDocs"
                  options={["Yes", "No"]}
                  chapter={chapter}
                  isRequired={isRequired}
                  reducer={reducer}
                  isMultiple={true}                  
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.isAddDocs?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isAddDocs}
                    </span>
                  )}
              </div>
              {isAddDocs === "Yes" && (
                <>
                  <div className="col-lg-6">
                    <NumberInput
                      label="How many additional documents do you have?"
                      name="numAddDocs"
                      chapter={chapter}
                      reducer={reducer}
                      referral={["addDocs"]}
                      pushedObject={[AdditionDocs]}
                      maxLimit={100}
                      isRequired={isRequired}
                    />
                    <div className="errorMessage"></div>
                  </div>
                  {docs}
                </>
              )}
            </Row>
           
          </div>
        )}
         {this.props.form === "UK" && (
            <>
              {isMediaCoverageOnStruct === "Yes" && (
             <div className="mb-2 col-lg-3">            
               <InternalFileUpload
                    label="Upload other report"
                    name="pascalOtherReportUpload"
                    id="pascalOtherReportUpload"
                    //isRequired={true}
                    reducer={reducer}
                    acceptType={fileAcceptType}
                    chapter={chapter}
                  />                 
              </div>
               )} 
              {isMediaCoverageOnStruct !== null && (         
             <div className="mb-2 col-lg-5">
                  <TextBox
                    label="Explain"
                    name="pascalExplain"
                    id="pascalExplain"
                    placeholder="Enter your text"
                    reducer={reducer}
                    chapter={chapter}
                    isMultipleLines={true}
                    //isRequired={isRequired}
                  />
              </div>
              )}
               
         </>
            )}   
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { chapter, reducer } = ownProps;
  return {
    numAddDocs: state[reducer][chapter]["numAddDocs"],
    isMediaCoverageOnStruct: state[reducer][chapter]["isMediaCoverageOnStruct"],
    isAddDocs: state[reducer][chapter]["isAddDocs"],
  };
};

export default connect(mapStateToProps, null)(MediaCoverage);
