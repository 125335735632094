import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import TextBox from "../../../../elements/TextBox";

const objBaseName = "serviceProviders";
class ServiceProviderMalta extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { propAuditorExsts, propAccntExsts, errors, isRequired } = this.props;
    const errorMsg = JSON.parse(errors);
    return (
      <>
        <h4 className="sub-chapter-title mb-4">Service Providers</h4>
        <h5>Proposed Auditor</h5>
        <Row>
          <div className="mb-3 col-lg-4 inline-checkbox">
            <CheckBox
              label="Is proposed auditor exists?"
              name="propAuditorExsts"
              id="propAuditorExsts"
              options={["Yes", "No"]}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={objBaseName}
              isRequired={isRequired}
            />
            {errorMsg &&
              errorMsg?.propAuditorExsts &&
              errorMsg?.propAuditorExsts?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.propAuditorExsts}
                </span>
              )}
          </div>
          {propAuditorExsts === "Yes" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Name of the proposed auditor"
                name="propAuditorName"
                id="propAuditorName"
                placeholder="Enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                isRequired={isRequired}
                objBaseName={objBaseName}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
              {errorMsg &&
                errorMsg?.propAuditorName &&
                errorMsg?.propAuditorName?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorMsg?.propAuditorName}
                  </span>
                )}
            </div>
          )}
        </Row>
        <h5>Proposed Accountant</h5>
        <Row>
          <div className="mb-3 col-lg-8 inline-checkbox">
            <CheckBox
              name="propAccntExsts"
              id="propAccntExsts"
              options={
                this.props.form === "Malta"
                  ? ["UIM Malta Limited", "Others", "N/A"]
                  : ["UIM S.A.", "Others", "N/A"]
              }
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={isRequired}
              objBaseName={objBaseName}
            />
            {errorMsg &&
              errorMsg?.propAccntExsts &&
              errorMsg?.propAccntExsts?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.propAccntExsts}
                </span>
              )}
          </div>
          {propAccntExsts === "Others" && (
            <div className="mb-3 col-lg-8">
              <TextBox
                label="Name of the proposed accountant"
                name="propAccntName"
                id="propAccntName"
                placeholder="Enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                isRequired={isRequired}
                objBaseName={objBaseName}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
              {errorMsg &&
                errorMsg?.propAccntName &&
                errorMsg?.propAccntName?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorMsg?.propAccntName}
                  </span>
                )}
            </div>
          )}
        </Row>
        <b>Proposed Tax Advisors (Only for Tax Declarations)</b>
        <Row>
          <div className="mb-3 col-lg-8 inline-checkbox">
            <CheckBox
              name="propTaxAdvisor"
              id="propTaxAdvisor"
              options={
                this.props.form === "Malta"
                  ? ["UIM Malta Limited", "Others", "N/A"]
                  : ["UIM S.A.", "Others", "N/A"]
              }
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={isRequired}
              objBaseName={objBaseName}
            />
            {errorMsg &&
              errorMsg?.propTaxAdvisor &&
              errorMsg?.propTaxAdvisor?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.propTaxAdvisor}
                </span>
              )}
          </div>
          {propAccntExsts === "Others" && (
            <div className="mb-3 col-lg-8">
              <TextBox
                label="Name of tax advisor"
                name="propTaxAdvisorName"
                id="propTaxAdvisorName"
                placeholder="Enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                isRequired={isRequired}
                objBaseName={objBaseName}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />{" "}
              {errorMsg &&
                errorMsg?.propTaxAdvisorName &&
                errorMsg?.propTaxAdvisorName?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorMsg?.propTaxAdvisorName}
                  </span>
                )}
            </div>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer, chapter } = ownProps;

  return {
    propAuditorExsts:
      state[reducer][chapter]["serviceProviders"]["propAuditorExsts"],
    propAccntExsts:
      state[reducer][chapter]["serviceProviders"]["propAccntExsts"],
  };
};

export default connect(mapStateToProps, null)(ServiceProviderMalta);
