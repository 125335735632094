import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import {
  authenticationUser,
  authenticationUBO,
} from "../../../../redux/actions/userActions";
import { checkFormStatus } from "../../../../redux/actions/UBOPEPActions";
import Header from "../../../common/header";

const checkValidity = (radioLength, radios) => {
  for (let i = 0; i < radioLength; i++) {
    if (radios[i].checked) return true;
  }
  return false;
};
const formValid = (formErrors) => {
  let valid = true;
  Object.values(formErrors).forEach(function (val) {
    val.length > 0 && (valid = false);
  });

  return valid;
};

class authentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: this.props.match.params.token,
      otp: "",
      isLoading: false,
      selectedOption: "Mobile SMS",
      isFormShow: true,
      usedLink: false,
      displayMsg: null,
      formErrors: {
        selectedRadio: "",
        otherMessage: "",
      },
    };
    this.onRadioChange = this.onRadioChange.bind(this);
  }

  onRadioChange = (e) => {
    this.setState({
      selectedOption: e.target.value,
    });
  };
  onValueChange = (e) => {
    e.preventDefault();
    const selectedValue = e.target.value;

    this.setState({
      selectedOption: selectedValue,
    });
  };
  clearInputs = () => {
    this.setState({
      selectedOption: "",
      otherMessage: "",
    });
  };

  componentDidMount = () => {
    this.setState({ isLoading: true });
    if (this.state.token) {
      this.props.checkFormStatus({ token: this.state.token }).then((res) => {
        this.setState({ isLoading: false });
        if (res.data?.portalErrorCode === 200) {
          this.setState({ usedLink: false });
        } else {
          this.setState({
            usedLink: true,
            displayMsg: res.data?.portalMessage,
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  catchError = (error) => {
    let fErrors = this.state.formErrors;
    if (error != null) {
      fErrors.otherMessage = "Invalid Authentication";
    }
    this.setState({ formErrors: fErrors });
  };

  redirect = (e) => {
    let fErrors = this.state.formErrors;
    fErrors.otherMessage = "";
    fErrors.selectedRadio = "";
    this.setState({ formErrors: fErrors });

    const form = document.querySelector("#sectionForm");
    const radios = form.querySelectorAll("input[type=radio]");
    const radioLength = radios.length;
    let isValid = checkValidity(radioLength, radios);

    e.preventDefault();

    fErrors.selectedRadio = !isValid
      ? "At least one option must be selected."
      : "";
    this.setState({
      formErrors: fErrors,
    });
    if (formValid(this.state.formErrors)) {
      this.setState({ isLoading: true });
      let opt = this.state.selectedOption === "Mobile SMS" ? "SMS" : "Email";
      let data = {
        otpMode: opt,
      };
      if (this.state.token) {
        data["token"] = this.state.token;
        this.props
          .authenticationUBO(data)
          .then((res) => {
            this.setState({ isLoading: false });
            if (res.data.portalErrorCode === 200) {
              this.clearInputs();
              opt === "SMS"
                ? NotificationManager.success(
                    "Check SMS for an OTP on registered mobile number"
                  )
                : NotificationManager.success(
                    "Check registered Email for an OTP"
                  );
              localStorage.setItem(
                "ubo-token",
                JSON.stringify(this.state.token)
              );
              return this.props.history.push({
                pathname: "/ubo-verify",
                otpMode: opt,
                from: "UBO",
                token: this.state.token,
              });
            } else if (!res.data.isError) {
              if (res.data.portalMessage === undefined) {
                fErrors.otherMessage = "Error occurred!";
              } else {
                fErrors.otherMessage = res.data.portalMessage;
              }
            } else {
              fErrors.otherMessage = "Error while authentication";
            }
            this.setState({ formErrors: fErrors });
          })
          .catch(this.catchError);
      } else {
        this.props
          .authenticationUser(data)
          .then((res) => {
            this.setState({ isLoading: false });
            if (res.data.portalErrorCode === 200) {
              this.clearInputs();
              opt === "SMS"
                ? NotificationManager.success(
                    "Check SMS for an OTP on registered mobile number"
                  )
                : NotificationManager.success(
                    "Check registered Email for an OTP"
                  );

              return this.props.history.push({
                pathname: "/verify",
                otpMode: opt,
              });
            } else if (!res.data.isError) {
              if (res.data.portalMessage === undefined) {
                fErrors.otherMessage = "Error occurred!";
              } else {
                fErrors.otherMessage = res.data.portalMessage;
              }
            } else {
              fErrors.otherMessage = "Error while authentication";
            }
            this.setState({ formErrors: fErrors });
          })
          .catch(this.catchError);
      }
    } else {
      //console.log(`Form Invalid - Display Error Message`);
    }
  };

  render() {
    const { formErrors } = this.state;
    return (
      <>
        <Header />
        <div className="loginBg pt-5" id="sectionForm">
          <div className="LoginWindow rounded pb-4 mt-5">
            {!this.state.usedLink ? (
              <>
                <h1 className="primary-heading mb-2">Authentication</h1>
                <p className="mb-3">
                  Please select the option to complete the authentication
                  process, You will get an OTP to verify your self.{" "}
                </p>
                {formErrors.selectedRadio.length > 0 && (
                  <div class="alert">
                    <strong>Error! </strong>
                    {formErrors.selectedRadio}
                  </div>
                )}
                {formErrors.otherMessage.length > 0 && (
                  <div class="alert">
                    <strong>Error! </strong>
                    {formErrors.otherMessage}
                  </div>
                )}
                {this.state.isLoading && <div className="loader"></div>}
                <div className="formGroup">
                  <Row>
                    <Col lg={12}>
                      <label className="d-flex align-items-center mb-4">
                        <input
                          type="radio"
                          className="radio mr-2"
                          value="Mobile SMS"
                          checked={this.state.selectedOption === "Mobile SMS"}
                          onChange={this.onRadioChange}
                        />
                        Mobile SMS
                      </label>
                    </Col>
                    <Col lg={12}>
                      <label className="d-flex align-items-center">
                        <input
                          type="radio"
                          className="radio mr-2"
                          value="Registered Email ID"
                          checked={
                            this.state.selectedOption === "Registered Email ID"
                          }
                          onChange={this.onRadioChange}
                        />
                        Registered Email ID
                      </label>
                    </Col>
                  </Row>
                </div>
                <Button
                  className="generalBtn mt-3 mb-2"
                  type="submit"
                  onClick={this.redirect}
                  disabled={
                    !this.state.token &&
                    window.location.pathname === "/ubo-authentication"
                  }
                >
                  Proceed
                </Button>
                <p className="copyright">Copyright @ 2021</p>
              </>
            ) : (
              <div className="warningMessage text-center">
                {this.state.displayMsg}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    authenticationUser: (data) => dispatch(authenticationUser(data)),
    authenticationUBO: (data) => dispatch(authenticationUBO(data)),
    checkFormStatus: (data) => dispatch(checkFormStatus(data)),
  };
};
export default connect(null, mapDispatchToProps)(authentication);
