import React, { Component } from "react";
import { Row } from "react-bootstrap";
import countryList from "react-select-country-list";
import DropDown from "../../../../../../elements/DropDown";
import TextBox from "../../../../../../elements/TextBox";
import CheckBox from "../../../../../../elements/CheckBox";
import FileUpload from "../../../../../../elements/FileUpload";
import { fileAcceptType, NatureOfObjectComArray } from "../constants";
import { connect } from "react-redux";
class Protectors extends Component {
    constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, errorMsg,isprotecPassport,isprotecProofAdd,isprotecRefLett} = this.props;
    const eMsg = errorMsg;
    return (
      <div className="mb-4 col-lg-12">
        <fieldset>
          <legend>Protector {this.props.index + 1}</legend>
          <Row>
          <div className="mb-2 col-lg-12 col-xl-12">
            <TextBox
              label="protector details"
              name="protectorDetails"
              id={`protectorDetails[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              validationType="Text"
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objProtectors &&
                    errorMsg.objProtectors[index] &&
                    errorMsg.objProtectors[index]
                      ?.protectorDetails?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objProtectors[index]
                            ?.protectorDetails
                        }
                      </span>
                    )}
          </div>
          
          <div className="mb-3 col-lg-6">
            <CheckBox
             label="Protector's passport or national identity card available?"
              name="isprotecPassport"
              options={["Yes", "No"]}
              id={`isprotecPassport[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objProtectors &&
                    errorMsg.objProtectors[index] &&
                    errorMsg.objProtectors[index]
                      ?.isprotecPassport?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objProtectors[index]
                            ?.isprotecPassport
                        }
                      </span>
                    )}
          </div>
          {isprotecPassport === "Yes" && (
            <div className="mb-3 col-lg-4">
            
              <FileUpload
                label="Upload Protector's Passport or national identity"
                acceptType={fileAcceptType}
                name="protecPassport"
                id={`protecPassport[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              //isRequired={this.props.form === "UK" ? true : false} 
              />
             {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objProtectors &&
                    errorMsg.objProtectors[index] &&
                    errorMsg.objProtectors[index]
                      ?.protecPassport?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objProtectors[index]
                            ?.protecPassport
                        }
                      </span>
                    )}
            </div>
          )} 
           {isprotecPassport === "No" && (
              <div className="mb-3 col-lg-4">
                  <TextBox
                label="Explanation"
                name="protecPassportReason"
                id={`protecPassportReason[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              validationType="Text"
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
              />
               {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objProtectors &&
                    errorMsg.objProtectors[index] &&
                    errorMsg.objProtectors[index]
                      ?.protecPassportReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objProtectors[index]
                            ?.protecPassportReason
                        }
                      </span>
                    )}

              </div>              
              )} 
        </Row> 
        <Row>
          <div className="mb-3 col-lg-6">
            <CheckBox
               label="Protector's proof of address available?"
               name="isprotecProofAdd"
              options={["Yes", "No"]}
              id={`isprotecProofAdd[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
            />
             {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objProtectors &&
                    errorMsg.objProtectors[index] &&
                    errorMsg.objProtectors[index]
                      ?.isprotecProofAdd?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objProtectors[index]
                            ?.isprotecProofAdd
                        }
                      </span>
                    )}
          </div>
          {isprotecProofAdd === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
               label="Upload Protector's proof of addres"
                info="Not older than 3 months"
                acceptType={fileAcceptType}
                name="protecProofAdd"
                id={`protecProofAdd[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              //isRequired={this.props.form === "UK" ? true : false} 
              />
             {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objProtectors &&
                    errorMsg.objProtectors[index] &&
                    errorMsg.objProtectors[index]
                      ?.protecProofAdd?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objProtectors[index]
                            ?.protecProofAdd
                        }
                      </span>
                    )}
            </div>
          )}
          {isprotecProofAdd === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="protecProofAddReason"
                id={`protecProofAddReason[${index}]`}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objProtectors &&
                    errorMsg.objProtectors[index] &&
                    errorMsg.objProtectors[index]
                      ?.protecProofAddReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objProtectors[index]
                            ?.protecProofAddReason
                        }
                      </span>
                    )}
            </div>
          )}
        </Row>   
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-6">
            <CheckBox
               label="Protector's reference letter available?"
              name="isprotecRefLett"
              options={["Yes", "No"]}
              id={`isprotecRefLett[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objProtectors &&
                    errorMsg.objProtectors[index] &&
                    errorMsg.objProtectors[index]
                      ?.isprotecRefLett?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objProtectors[index]
                            ?.isprotecRefLett
                        }
                      </span>
                    )}
          </div>
          {isprotecRefLett === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Upload Protector's reference letter"
                info="by Accountant, Banker or Lawyer"
                acceptType={fileAcceptType}
                name="protecRefLett"
                id={`protecRefLett[${index}]`}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objProtectors &&
                    errorMsg.objProtectors[index] &&
                    errorMsg.objProtectors[index]
                      ?.protecRefLett?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objProtectors[index]
                            ?.protecRefLett
                        }
                      </span>
                    )}
            </div>
          )}
          {isprotecRefLett === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="protecRefLettReason"
                id={`protecRefLettReason[${index}]`}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objProtectors &&
                    errorMsg.objProtectors[index] &&
                    errorMsg.objProtectors[index]
                      ?.protecRefLett?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objProtectors[index]
                            ?.protecRefLett
                        }
                      </span>
                    )}
            </div>
          )}
        </Row>     
        </fieldset>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
    const { baseName, index, reducer,objBaseName,indexObjBaseName } = ownProps;
    return {
        isprotecPassport: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["isprotecPassport"],
        isprotecProofAdd: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["isprotecProofAdd"],
        isprotecRefLett:state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["isprotecRefLett"],
    };
  };
  
  export default connect(mapStateToProps, null)(Protectors);
