import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { forgotPassword } from "../../../../redux/actions/userActions";
import Header from "../../../common/header";

/* eslint-disable no-useless-escape */
const emailRegex = RegExp(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const formValid = (formErrors) => {
  let valid = true;

  Object.values(formErrors).forEach(function (val) {
    val.length > 0 && (valid = false);
  });

  return valid;
};
class forgotpassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmailSent: false,
      isFormShow: false,
      email: null,
      isLoading: false,
      formErrors: {
        email: "",
        otherMessage: "",
      },
    };
  }
  clearInputs = () => {
    this.setState({
      email: "",
    });
  };

  redirect = () => {
    const timer = setTimeout(() => {
      return this.props.history.push({
        pathname: "/login",
      });
    }, 5000);
  };

  onHandleSubmit = (e) => {
    let fErrors = this.state.formErrors;
    fErrors.otherMessage = "";
    fErrors.selectedRadio = "";
    this.setState({ formErrors: fErrors });

    e.preventDefault();
    fErrors.email =
      emailRegex.test(this.state.email) && this.state.email.length > 0
        ? ""
        : "Add valid Email ID";
    this.setState({
      formErrors: fErrors,
    });
    if (formValid(this.state.formErrors)) {
      this.setState({ isLoading: true });
      let data = {
        email: this.state.email,
      };
      this.props.forgotPassword(data).then((res) => {
        this.setState({ isLoading: false });

        if (res.data.portalErrorCode === 200) {
          this.clearInputs();
          this.setState({
            isEmailSent: true,
            isFormShow: true,
          });
          this.redirect();
        } else if (!res.data.isError) {
          if (res.data.portalMessage === undefined) {
            fErrors.otherMessage = "Error occured!";
          } else {
            fErrors.otherMessage = "Enter email ID is not registered with app";
          }
        } else {
          fErrors.otherMessage = "Error while authentication";
        }
        this.setState({ formErrors: fErrors });
      });
    } else {
      console.log(`Form Invalid - Display Error Message`);
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;

    switch (name) {
      case "email":
        formErrors.email =
          emailRegex.test(value) && value.length > 0
            ? ""
            : "Add valid Email ID";

        break;
      default:
        break;
    }
    this.setState({ formErrors, [name]: value });
  };

  render() {
    const { isEmailSent, isFormShow, formErrors } = this.state;
    return (
      <>
        <Header />
        <div className="loginBg d-flex justify-content-center align-items-center flex-wrap">
          <div className="LoginWindow rounded">
            <div
              className={
                isFormShow ? "forgotpasswordForm d-none" : "forgotpasswordForm"
              }
            >
              <h1 className="primary-heading mb-2">Forgot password?</h1>
              <p className="mb-3">
                Please enter the e-mail address, We'll send you instruction to
                reset your password.
              </p>
              {formErrors.otherMessage.length > 0 && (
                <div class="alert">
                  <strong>Error! </strong>
                  {formErrors.otherMessage}
                </div>
              )}
              {this.state.isLoading && <div className="loader"></div>}
              <div className="formGroup">
                <label>Email ID</label>
                <input
                  type="email"
                  name="email"
                  noValidate
                  placeholder="Enter email ID"
                  className="inputstyle"
                  onChange={this.handleChange}
                />
                {formErrors.email.length > 0 && (
                  <span className="errorMessage">{formErrors.email}</span>
                )}
              </div>
              <Button
                className="generalBtn mt-3"
                type="submit"
                onClick={this.onHandleSubmit}
              >
                Submit
              </Button>
              <p className="text-center pt-4">
                <Link to="/login" className="link">
                  Back to Login
                </Link>
              </p>
              <p className="copyright">Copyright @ 2021</p>
            </div>

            <div
              className={
                isEmailSent
                  ? "notificationMessage text-center mb-4"
                  : "notificationMessage d-none"
              }
            >
              An email has been sent to your registered email ID. Please visit
              your registered email to get new password.
              <p className="text-center pt-4">
                <Link to="/login" className="link">
                  Back to Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (data) => dispatch(forgotPassword(data)),
  };
};
export default connect(null, mapDispatchToProps)(forgotpassword);
