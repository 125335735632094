import React, { Component } from "react";
import { Accordion, Card, Row } from "react-bootstrap";

class PartE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeId: "",
    };
  }

  toggleActive = (id) => {
    if (this.state.activeId === id) {
      this.setState({
        activeId: null,
      });
    } else {
      this.setState({
        activeId: id,
      });
    }
  };

  render() {
    const { activeId } = this.state;
    return (<>
      <h2 className="chapter-title">DECLARATION</h2>
      <Row className="pt-3 pb-2">
        <div className="col-md-12">
          <Accordion defaultActiveKey={activeId}>
            <Card className="rounded-lg mb-3 border-0">
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                onClick={() => this.toggleActive("0")}
                className={activeId === "0" ? "active" : ""}
              >
                I declare that I am aware of the fact that, with respect to the
                Object Company, I am bound to all Dutch tax and legal
                obligations and requirements.
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    I declare that all tangible and intangible assets and all
                    other assets belonging to the Object Company (including cash
                    and securities), and the income or proceeds thereof, as well
                    as my interest in the Object Company, will be fully
                    disclosed to the relevant tax- and, if required, other
                    relevant authorities in my country of residence and in any
                    other jurisdiction.
                  </p>
                  <p>
                    I will immediately notify United of any changes which might
                    occur in the future concerning the above information and
                    concerning the identity of the main shareholder(s) or the
                    ultimate beneficiary/beneficiaries of the Object Company.
                  </p>
                  <p>
                    I will immediately notify United of any changes which might
                    occur in the future concerning the above information and
                    concerning the identity of the main shareholder(s) or the
                    ultimate beneficiary/beneficiaries of the Object Company.
                  </p>

                  <p>
                    United and/or the directors made available by United, are
                    authorized to share this information with:
                  </p>
                  <ul>
                    <li>
                      the bank where the Object Company will open an account (if
                      required/applicable); and/or
                    </li>
                    <li>
                      Regulators/ competent courts or other competent
                      authorities in case United is requested to disclose the
                      information based on requirements under Dutch law.
                    </li>
                  </ul>

                  <p>
                    I declare that I and/-or the entities within the group
                    structure are not involved in importing or exporting goods
                    from or to the following countries: Iran, Cuba, North Korea,
                    Sudan or Syria.
                  </p>
                  <p>
                    I declare that I and/-or the entities within the group
                    structure do not cooperate in transactions related to
                    certain countries, parties or goods that are in violation
                    with the EU and/or US sanction regulations.{" "}
                  </p>
                  <p>
                    I declare that the establishment of the Object Company is
                    not intended to facilitate unlawful behavior.
                  </p>
                  <p>
                    I declare that I and/- or the entities within the group
                    structure are not involved in activities (production,
                    investment, trade or other activities) related to the
                    nuclear weapons, cluster weapons, anti-personnel land mines,
                    biological and chemical weapons.
                  </p>
                  <p>
                    I declare that I and/ -or the entities within the group
                    structure are not involved in human rights violations.
                  </p>
                  <p>
                    I acknowledge and confirm that the information provided
                    above is true, correct and complete to the best of my
                    knowledge and belief.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </Row>
      </>);
  }
}

export default PartE;
