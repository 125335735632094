import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setDataToReducer } from "../../../../../../redux/actions/ClientInformationAction";
import countryList from "react-select-country-list";
import Select from "react-select";
import Modal from "../../../../../components/shared/Modal";
import { getReducer } from "../../../../../elements/getReducer";
import cc from "currency-codes";
import PhoneInput from "react-phone-input-2";

let currencies = [];
const getOffice = (office) => {
  switch (office) {
    case "Netherlands":
      return "nl";
    case "NL":
      return "nl";
    case "Curacao":
      return "cw";
    case "Malta":
      return "mt";
    case "United Kingdom":
      return "gb";
    case "UK":
      return "gb";
    case "Luxembourg":
      return "lu";
    default:
      return "nl";
  }
};
cc.codes().map((currency) => currencies.push({ label: currency }));
class BankInformationMalta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      id: null,
      bnkName: "",
      registrationCountry: "",
      accNum: "",
      currency: "",
      authorizdPrsn: "",
      contactNo: "",
      dialCode: "",
      address: "",
      passportNum: "",
      errors: {},
      toggleCleared: false,
      selectedRows: [],
      addForm: false,
      columns: [
        {
          id: 1,
          name: "Name of the bank",
          selector: (row) => row.bnkName,
        },
        {
          id: 2,
          name: "Country of Registration",
          selector: (row) => row.registrationCountry,
        },
        {
          id: 3,
          name: "Currency",
          selector: (row) => row.currency,
        },
        {
          id: 4,
          name: "Account Number",
          selector: (row) => row.accNum,
        },
        {
          id: 5,
          name: "Authorized Person(s)",
          selector: (row) => row.authorizdPrsn,
        },
        {
          id: 6,
          name: "Contact Number",
          selector: (row) => row.contactNo,
        },
        {
          id: 7,
          name: "Address",
          selector: (row) => row.address,
        },
        {
          id: 8,
          name: "Passport",
          selector: (row) => row.passportNum,
        },
        {
          id: 9,
          title: "",
          cell: (row) => (
            <div className="Edit-Btn">
              <button
                type="button"
                className="action-btn edit"
                onClick={() => this.handleEdit(row)}
                style={{
                  pointerEvents:
                    this.props.from !== "clientInformation" ? "none" : "",
                }}
              >
                Edit
              </button>
            </div>
          ),
        },
      ],
    };
  }

  modalOpen = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  modalClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  cancelForm = () => {
    this.setState({
      isEdit: false,
      addForm: false,
    });
  };

  handleAddForm = () => {
    this.setState({
      id: null,
      bnkName: "",
      registrationCountry: "",
      accNum: "",
      currency: "",
      authorizdPrsn: "",
      contactNo: "",
      address: "",
      passportNum: "",
      isEdit: false,
      addForm: true,
    });
  };

  clearInputs = () => {
    this.setState({
      id: null,
      bnkName: "",
      registrationCountry: "",
      accNum: "",
      currency: "",
      authorizdPrsn: "",
      contactNo: "+31",
      address: "",
      passportNum: "",
    });
  };

  handleEdit = (row) => {
    this.setState({
      isEdit: true,
      addForm: true,
      id: row.id,
      bnkName: row.bnkName,
      registrationCountry: row.registrationCountry,
      accNum: row.accNum,
      currency: row.currency,
      authorizdPrsn: row.authorizdPrsn,
      contactNo: row.contactNo,
      address: row.address,
      passportNum: row.passportNum,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });

    this.setState({
      errors: {
        ...this.state.errors,
        [name]: "",
      },
    });
  };

  handleCountryChange = (e) => {
    this.setState({ registrationCountry: e.label });
    this.setState({
      errors: {
        ...this.state.errors,
        registrationCountry: "",
      },
    });
  };

  handleCurrencyChange = (e) => {
    this.setState({ currency: e.label });
    this.setState({
      errors: {
        ...this.state.errors,
        currency: "",
      },
    });
  };

  handleRowSelection = (selectedRows) => {
    this.setState({ selectedRows: selectedRows });
  };

  validateForm = () => {
    let errors = {};
    let formIsValid = true;
    const {
      bnkName,
      registrationCountry,
      accNum,
      passportNum,
      currency,
      contactNo,
      address,
      authorizdPrsn,
    } = this.state;
    if (!bnkName) {
      formIsValid = false;
      errors["bnkName"] = "Please enter bank name";
    }
    if (!registrationCountry) {
      formIsValid = false;
      errors["registrationCountry"] = "Please select country";
    }
    if (!accNum) {
      formIsValid = false;
      errors["accNum"] = "Please enter account number";
    }
    if (!currency) {
      formIsValid = false;
      errors["currency"] = "Please select currency";
    }
    if (!authorizdPrsn) {
      formIsValid = false;
      errors["authorizdPrsn"] = "Please enter authorized person";
    }
    if (!passportNum) {
      formIsValid = false;
      errors["passportNum"] = "Please enter passport number";
    }
    if (!address) {
      formIsValid = false;
      errors["address"] = "Please enter address";
    }
    if (contactNo === "") {
      formIsValid = false;
      errors["contactNo"] = "Please enter contact number";
    }

    this.setState({
      errors,
    });
    return formIsValid;
  };

  handleOnChange = (value, data, event, formattedValue) => {
    this.setState({ contactNo: value, dialCode: data.dialCode });
  };

  handleSubmit = async () => {
    const {
      id,
      bnkName,
      registrationCountry,
      accNum,
      currency,
      authorizdPrsn,
      address,
      contactNo,
      passportNum,
    } = this.state;

    let array = this.props.bnkInfo;
    if (this.validateForm()) {
      if (this.state.isEdit) {
        const index = array.findIndex((f) => f.id === id);
        let obj = array[index];
        obj = {
          ...obj,
          bnkName: bnkName,
          registrationCountry: registrationCountry,
          accNum: accNum,
          currency: currency,
          authorizdPrsn: authorizdPrsn,
          address: address,
          contactNo: contactNo,
          passportNum: passportNum,
        };
        array[index] = obj;
      } else {
        let Id =
          array.length === 0
            ? 1
            : Math.max(
                ...array.map(function (i) {
                  return i.id;
                })
              ) + 1;
        let obj = {
          id: String(Id),
          bnkName: bnkName,
          registrationCountry: registrationCountry,
          accNum: accNum,
          currency: currency,
          authorizdPrsn: authorizdPrsn,
          address: address,
          contactNo: contactNo,
          passportNum: passportNum,
        };
        array.push(obj);
      }
      this.props.setDataToReducer({
        type: getReducer(this.props.reducer),
        chapter: this.props.chapter,
        name: "bnkInfo",
        value: array,
      });
      this.clearInputs();
      this.cancelForm();
    }
  };

  handleDelete = () => {
    this.modalClose();
    let array = this.props.bnkInfo;
    let selectedRows = this.state.selectedRows.selectedRows;
    let IdArray = [];
    selectedRows.forEach((obj) => {
      IdArray.push(obj.id);
    });
    let newArray = array.filter((obj) => !IdArray.includes(obj.id));

    this.props.setDataToReducer({
      type: getReducer(this.props.reducer),
      chapter: this.props.chapter,
      name: "bnkInfo",
      value: newArray,
    });
    this.setState({
      selectedRows: [],
      toggleCleared: !this.state.toggleCleared,
    });
  };

  render() {
    const {
      selectedRows,
      addForm,
      errors,
      currency,
      accNum,
      registrationCountry,
      authorizdPrsn,
      bnkName,
      toggleCleared,
    } = this.state;
    let countryDial = getOffice(localStorage.getItem("Office"));
    return (
      <>
        <Container fluid>
          <Row>
            <Col sm={6} className="text-left d-flex">
              {selectedRows?.selectedRows?.length > 0 && (
                <button
                  className="action-btn delete"
                  onClick={this.modalOpen}
                  style={{
                    pointerEvents:
                      this.props.from !== "clientInformation" ? "none" : "",
                  }}
                >
                  Delete
                </button>
              )}
            </Col>
            <Col sm={6} className="text-right add-button">
              <Link
                to="#"
                className="action-btn big addRightBtn"
                onClick={this.handleAddForm}
                style={{
                  pointerEvents:
                    this.props.from === "clientInformation" ? "" : "none",
                }}
              >
                Add Bank Information
              </Link>
            </Col>
          </Row>
        </Container>
        <DataTable
          title="Bank Information"
          columns={this.state.columns}
          data={[...this.props.bnkInfo]}
          selectableRows
          pagination
          onSelectedRowsChange={this.handleRowSelection}
          clearSelectedRows={toggleCleared}
        />
        {addForm && (
          <div className="addFormForCAF mt-3">
            <div className="col-md-8 m-auto pt-3">
              <Row>
                <div className="mb-2 col-lg-4">
                  <label>Name of the bank</label>
                  <input
                    type="text"
                    name="bnkName"
                    className="form-control"
                    placeholder="enter bankname"
                    onChange={this.handleChange}
                    value={bnkName}
                  ></input>
                  <div className="errorMsg">{errors.bnkName}</div>
                </div>
                <div className="mb-2 col-lg-4">
                  <label>Country of registration</label>
                  <Select
                    options={countryList().getData()}
                    value={registrationCountry ? registrationCountry : ""}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.label}
                    onChange={this.handleCountryChange}
                    placeholder={
                      registrationCountry ? (
                        registrationCountry
                      ) : (
                        <div>select country</div>
                      )
                    }
                  />
                  <div className="errorMsg">{errors.registrationCountry}</div>
                </div>
                <div className="mb-2 col-lg-4">
                  <label>Currency</label>

                  <Select
                    options={currencies}
                    value={currency ? currency : ""}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.label}
                    onChange={this.handleCurrencyChange}
                    placeholder={
                      currency ? currency : <div>select currency</div>
                    }
                  />
                  <div className="errorMsg">{errors.currency}</div>
                </div>
                <div className="mb-2 col-lg-6">
                  <label>Account Number</label>
                  <input
                    type="text"
                    name="accNum"
                    className="form-control"
                    placeholder="enter account number"
                    onChange={this.handleChange}
                    value={accNum}
                  ></input>
                  <div className="errorMsg">{errors.accNum}</div>
                </div>
                <div className="mb-2 col-lg-6">
                  <label>Authorized Person(s)</label>
                  <input
                    type="text"
                    name="authorizdPrsn"
                    className="form-control"
                    placeholder="enter authorized person"
                    onChange={this.handleChange}
                    value={authorizdPrsn}
                  ></input>
                  <div className="errorMsg">{errors.authorizdPrsn}</div>
                </div>
                <div className="mb-2 col-lg-4">
                  <label>Contact Number</label>
                  <PhoneInput
                    country={countryDial}
                    className="input_field"
                    name="contactNo"
                    value={this.state.contactNo}
                    onChange={this.handleOnChange}
                  />
                  <div className="errorMsg">{errors.contactNo}</div>
                </div>
                <div className="mb-2 col-lg-4">
                  <label>Address</label>
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    placeholder="enter address"
                    onChange={this.handleChange}
                    value={this.state.address}
                  ></input>
                  <div className="errorMsg">{errors.address}</div>
                </div>
                <div className="mb-2 col-lg-4">
                  <label>Passport Number</label>
                  <input
                    type="text"
                    name="passportNum"
                    className="form-control"
                    placeholder="enter passport number"
                    onChange={this.handleChange}
                    value={this.state.passportNum}
                  ></input>
                  <div className="errorMsg">{errors.passportNum}</div>
                </div>
              </Row>
            </div>
            <Row className="pt-4 pb-2 text-center">
              <div className="mb-2 col-lg-12 ">
                <button
                  type="button"
                  className="action-btn noicon pl-3 pr-3"
                  onClick={this.handleSubmit}
                >
                  {this.state.isEdit ? "Edit Row" : "Add Row"}
                </button>
                <button
                  type="button"
                  className="action-btn link noicon pl-4 pr-4"
                  onClick={this.cancelForm}
                >
                  Cancel
                </button>
              </div>
            </Row>
          </div>
        )}
        <Modal
          show={this.state.isModalOpen}
          handleClose={(e) => this.modalClose(e)}
        >
          <h4 className="mb-4">Are you sure you want to delete?</h4>

          <div className="form-group mb-0">
            <button
              onClick={(e) => this.handleDelete(e)}
              type="button"
              className="action-btn noicon mr-2"
            >
              Yes
            </button>
            <button
              onClick={this.modalClose}
              type="button"
              className="action-btn greybtn noicon"
            >
              No
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    bnkInfo: state.commonFields["bnkInfo"],
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankInformationMalta);
