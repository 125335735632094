/* eslint-disable no-useless-escape */
export function isFieldEmpty(data) {
  if (data === "" || data === undefined || data === null) {
    return "Please fill this detail";
  } else if (Array.isArray(data)) {
    if (data.length == 0) {
      return "Please fill this detail";
    }
  } else {
    return "";
  }
}

export function isFileEmpty(data) {
  if (data === undefined || data === null || data.length === 0) {
    return "Please upload the file";
  } else {
    return "";
  }
}
const emailRegex = RegExp(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
export function isEmailValid(data) {
  if (emailRegex.test(data)) {
    return "";
  } else {
    return "Please enter valid email";
  }
}
