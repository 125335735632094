import { AdditionDocs } from "./Chapter1";
export const NewClient={
  clientName:null,
  country:null,
  explainDetails:null,
}
// let arrayClient=[];
// for (let i = 0; i < 10; i++) {
//   arrayClient.push(arrayClient);
// }

export const IntermediaryObj = {
  intermediaryId: null,
  isClientNew: null,
  isSigningClientntAg: null,
  noNewClients:1,
  newClients:[NewClient],
};

let array = [];
for (let i = 0; i < 10; i++) {
  array.push(IntermediaryObj);
}

export const Chapter2 = {
  noUBOInfoByUIM: null,
  isPascalReport: null,
  noPascalReportReason: null,
  pascalReportUpload: [],
  isMediaCoverageOnStruct: null,
  pascalOtherReportUpload:[],
  pascalExplain:null,
  isAddDocs: null,
  numAddDocs: 1,
  addDocs: [AdditionDocs],
  risksCncrnngIntermediary: null,
  isSrvcPrvdrTermntdSrvcsClr: null,
  isSrvcPrvdrReputable: null,
  isReceiveNecessaryInfo: null,
  isIncreasedIntegrityRisk: null,
  increasedIntegrityRiskReason: null,
  intermediarySignNew: array,
  dirty: false,
};
