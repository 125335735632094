import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import {
  getClientList,
  deleteClient,
  bulkDeleteClient,
  getAllOffices,
} from "../../../redux/actions/clientActions";
import "./clientlist.scss";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import Modal from "../shared/Modal";
import jwt_decode from "jwt-decode";
import orderBy from "lodash/orderBy";
let selectedOffices = [];
let allOffices;
const customSort = (rows, field, direction) => {
  const handleField = (row) => {
    if (row[field]) {
      return row[field].toLowerCase();
    }
    return row[field];
  };
  return orderBy(rows, handleField, direction);
};

class clientList extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      clientDataList: [],
      selectedRows: [],
      isLoading: false,
      searchInput: "",
      filterText: "",
      selectedValue: "",
      resetPaginationToggle: false,
      columns: [],
      originalData: [],
      data: [],
      tokenData: null,    
    };
   
    this.props.getAllOffices().then((res) => {
      if (res.data.portalErrorCode === 200) {
        allOffices = res.data.result;
        const checkboxs = this.subHeaderFilterComponent();
        this.setState({
          officeList: res.data.result,
          columns: [
            {
              name: "Client Name",
              selector: "name",
              sortable: true,
            },
            {
              name: "City",
              selector: "city",
              sortable: true,
            },
            {
              name: "Post Code",
              selector: "postalCode",
              sortable: true,
            },
            {
              name: "Country",
              selector: "country",
              sortable: true,
              filterable: true,
            },
            {
              name: (
                <div class="office-dropdown-hover">
                  <div class="office-button">United Office Location</div>
                  <div class="office-dropdown-content office-bar-block office-card-4">
                    {checkboxs}
                  </div>
                </div>
              ),

              cell: (row) => (
                <div className="officeLocation">
                  {row.office &&
                    row.office.length > 0 &&
                    row.office.map((office, key) => {
                      return (
                        <div key={key}>
                          {office?.name && (
                            <span className={office?.name}>{office?.name}</span>
                          )}
                        </div>
                      );
                    })}
                </div>
              ),
              width: "25%",
            },
            {
              name: "",
              cell: (row) => (
                <>
                  {row.status === "PendingApproval" ? (
                    <div className="Approval-pending">Approval Pending</div>
                  ) : (
                    <>
                      <div className="Edit-Btn">
                        <Link
                          className="action-btn edit"
                          to={{
                            pathname: `/editClient/${row.id}`,
                          }}
                        >
                          Edit
                        </Link>
                      </div>
                    </>
                  )}
                </>
              ),
              sortable: false,
              width: "200px",
            },
          ],
        });
      }
    });
  }

  getClientListfromAPI = () => {
    let token = JSON.parse(localStorage.getItem("authToken"));
    if (token) {
      var decoded = jwt_decode(token);
      var data = {
        name: decoded.name,
      };
      this.setState({ isLoading: true, tokenData: data });
      this.props.getClientList(data).then((res) => {
        this.setState({ isLoading: false });
        if (res.data.portalErrorCode === 200) {
         
          this.setState({
            originalData: res.data.result,
            clientDataList: res.data.result,
            data: res.data.result,
          });
      
        // else{
        //   let filteredlst = [];
        //   res.data.result.filter((client) => {client.office.filter(value => {         
        //      if(value.name.includes("Curacao")){
        //        filteredlst.push(client);
        //      }})});
        //      this.setState({
        //       originalData:filteredlst,
        //       clientDataList: filteredlst,
        //       data: filteredlst,
        //     });
        // }
        }
      });
    }
  };

  componentDidMount() {
    this.getClientListfromAPI();
  }

  handleRowSelected = (selectedRows) => {
    this.setState({ selectedRows: selectedRows });
  };

  deleteRecords = (rows) => {
    let array = [];
    rows.selectedRows.length > 0 &&
      rows.selectedRows.forEach((row) => {
        let obj = {};
        obj["id"] = row.id;
        array.push(obj);
      });
    this.setState({ isLoading: true });
    this.props
      .bulkDeleteClient(array)
      .then((res) => {
        this.setState({ isLoading: false });
        let successfulCount = 0,
          failedCount = 0;
        if (res.data?.length > 0) {
          res.data.forEach((record) => {
            if (record.portalErrorCode === 200) {
              successfulCount++;
            } else {
              failedCount++;
            }
          });
          let successMsg = successfulCount === 1 ? `record` : `records`;
          successfulCount > 0 &&
            NotificationManager.success(
              `${successfulCount} ${successMsg} ${`deleted successfully`}`
            );

          let failMsg = failedCount === 1 ? `record.` : `records.`;
          failedCount > 0 &&
            NotificationManager.error(
              `Error while deleting ${failedCount} ${failMsg}`
            );

          this.setState({
            selectedRows: [],
            toggleCleared: !this.state.toggleCleared,
          });
          this.getClientListfromAPI();
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData = this.state.originalData.filter((value) => {
      return (
        value.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.city?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.country?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.postalCode
          ?.toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      );
    });
    this.setState({ clientDataList: filteredData });
  };
  OfficeFilter = () => {
    if (selectedOffices.length !== 0) {
      let filteredData = [];
      this.state.originalData.filter((client) => {
        return client.office.filter((value) => {
          return selectedOffices.forEach((element) => {
             if (value.name.includes(element.name)) {
              return filteredData.push(client);
            }
          });
        });
      });
      //let ids = filteredData.forEach((o) => o.id);
      let ids = filteredData.map(o => (o.id ));
      const filtered = filteredData.filter(({ id }, index) => {
        return !ids.includes(id, index + 1);
      });
      if (filtered.length !== 0) {
        this.setState({ clientDataList: filtered });
      }
    } else {
      this.setState({ clientDataList: this.state.originalData });
    }
  };
  handleClick(e) {
    let clickedValue = e.target.value;
    let clickedChecked = e.target.checked;
    const form = document.querySelector("#checkboxes");
    const checkboxs = form.querySelectorAll("input[type=checkbox]");

    if (clickedValue === "Clear All" && clickedChecked) {
      selectedOffices = [];
      checkboxs.forEach((element) => {
        if (element.checked === true && element.name !== "Clear All") {
          element.checked = false;
        } else {
          element.checked = true;
        }
      });
    } else if (clickedValue === "Clear All" && !clickedChecked) {
      checkboxs.forEach((element) => {
        if (element.checked === true && element.name === "Clear All") {
          element.checked = false;
        }
      });
    } else if (clickedValue !== "Clear All" && clickedChecked) {
      selectedOffices.push({ name: clickedValue });
      checkboxs.forEach((element) => {
        if (element.name === "Clear All") {
          element.checked = false;
        }
      });
    } else if (clickedValue !== "Clear All" && !clickedChecked) {
      selectedOffices.splice(
        selectedOffices.findIndex((v) => v.name === clickedValue),
        1
      );
    }
    this.OfficeFilter();
  }

  handleSearch = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  subHeaderFilterComponent = () => {
    const options = [];

    if (allOffices !== undefined) {
      allOffices.forEach((element) => {
        options.push(element);
      });
    }
    options.push({ name: "Clear All" });

    return (
      <div id="checkboxes">
        {options.map((option) => (
          <label>
            <input
              onClick={this.handleClick}
              type="checkbox"
              name={option.name}
              key={option.name}
              value={option.name}
            />{" "}
            <div id="filterName">{option.name}</div>
          </label>
        ))}
      </div>
    );
  };

  modalOpen = () => {
    this.setState({ modal: true });
  };

  modalClose = () => {
    this.setState({
      modal: false,
    });
  };

  handleYes = () => {
    this.deleteRecords(this.state.selectedRows);

    this.modalClose();
  };
  handleNo = () => {
    this.modalClose();
  };

  handleClear = () => {
    const { resetPaginationToggle } = this.state;

    if (this.state.filterText) {
      this.setState({
        resetPaginationToggle: !resetPaginationToggle,
        filterText: "",
      });
    }
  };

  render() {
    const {
      columns,
      selectedRows,
      searchInput,
      clientDataList,
      toggleCleared,
    } = this.state;
    const conditionalRowStyles = [
      {
        when: (row) => row.status === "PendingApproval",
        classNames: ["disabled"],
        style: {
          backgroundColor: "#f5f6f7 !important",
          cursor: "not-allowed",
          pointerEvents: "none",
        },
      },
    ];
    const rowDisabledCriteria = (row) => row.status === "PendingApproval";

    return (
      <div className="dashboard">
        <div className="userlist">
          <div className="eventHeader pt-3 pb-3">
            <Container fluid>
              <Row>
                <Col sm={6} className="text-left d-flex">
                  <h2 className="mb-0  mt-2 pagetitle">Client Maintenance</h2>
                </Col>
                <Col sm={6} className="text-right add-button">
                  <Link to="/addClient" className="action-btn big">
                    Add Client
                  </Link>
                </Col>
              </Row>
            </Container>
          </div>

          <Container fluid>
            <Row className="pt-3 pb-2">
              <Col lg={6} className="pt-2 pb-2">
                {selectedRows?.selectedRows?.length > 0 && (
                  <>
                    <button
                      className="action-btn delete"
                      onClick={() => {
                        this.modalOpen();
                      }}
                    >
                      Delete
                    </button>
                  </>
                )}
              </Col>
              <Col lg={6} className="position-relative">
                <div className="datatableSearch">
                  <input
                    type="text"
                    name="searchInput"
                    value={searchInput || ""}
                    onChange={this.handleSearch}
                    placeholder="Search"
                  />
                  <i className="fa fa-search search-bar-icon"></i>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <DataTable
                  className="datatable"
                  columns={columns}
                  data={clientDataList}
                  onSelectedRowsChange={this.handleRowSelected}
                  clearSelectedRows={toggleCleared}
                  selectableRows
                  conditionalRowStyles={conditionalRowStyles}
                  selectableRowDisabled={rowDisabledCriteria}
                  selectableRows
                  selectableRowsComponentProps={{ inkDisabled: true }}
                  sortFunction={customSort}
                  pagination
                  persistTableHead
                />
              </Col>
            </Row>
          </Container>
          <Modal
            show={this.state.modal}
            handleClose={(e) => this.modalClose(e)}
          >
            <h4 className="mb-4">Are you sure you want to delete?</h4>

            <div className="form-group mb-0">
              <button
                onClick={(e) => this.handleYes(e)}
                type="button"
                className="action-btn approve noicon mr-2"
              >
                Yes
              </button>
              <button
                onClick={(e) => this.handleNo(e)}
                type="button"
                className="action-btn reject noicon"
              >
                No
              </button>
            </div>
          </Modal>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const forEachStateToProps = (state) => {
  return {
    clients: state.user.data,
  };
};

const forEachDispatchToProps = (dispatch) => {
  return {
    getClientList: async (data) => dispatch(await getClientList(data)),
    bulkDeleteClient: async (data) => dispatch(await bulkDeleteClient(data)),
    deleteClient: async (data) => dispatch(await deleteClient(data)),
    getAllOffices: async () => dispatch(await getAllOffices()),
  };
};
export default connect(forEachStateToProps, forEachDispatchToProps)(clientList);
