import axios from "axios";
import {
  GET_CLIENT_LIST_REQUEST,
  GET_CLIENT_LIST_SUCCESS,
  GET_CLIENT_LIST_FAIL,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
  ADD_CLIENT_REQUEST,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAIL,
  GET_CLIENT_BY_ID_REQUEST,
  GET_CLIENT_BY_ID_SUCCESS,
  GET_CLIENT_BY_ID_FAIL,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  BULK_DELETE_REQUEST,
  BULK_DELETE_SUCCESS,
  BULK_DELETE_FAIL,
  APPROVE_REJECT_FROM_MAIL_REQUEST,
  APPROVE_REJECT_FROM_MAIL_SUCCESS,
  APPROVE_REJECT_FROM_MAIL_FAIL,
  GET_All_OFFICES_REQUEST,
  GET_All_OFFICES_SUCCESS,
  GET_All_OFFICES_FAIL,
  CLIENT_APPROVAL_LIST_REQUEST,
  CLIENT_APPROVAL_LIST_SUCCESS,
  CLIENT_APPROVAL_LIST_FAIL,
  APPROVE_REJECT_CLIENT_REQUEST,
  APPROVE_REJECT_CLIENT_SUCCESS,
  APPROVE_REJECT_CLIENT_FAIL,
  ASSIGN_USER_LIST_REQUEST,
  ASSIGN_USER_LIST_SUCCESS,
  ASSIGN_USER_LIST_FAIL,
} from "../constants/clientConstants";
import {getToken} from "./getToken"
const MSLOGIN_CLIENTID = process.env.REACT_APP_MSLOGIN_CLIENTID;
const MSLOGIN_REDIRECTURL = process.env.REACT_APP_MSLOGIN_REDIRECTURL;

// const getToken = async () => {
//   const cfg = {
//     auth: {
//       clientId: MSLOGIN_CLIENTID,
//       authority:
//         "https://login.microsoftonline.com/37ab276a-6d7b-4b26-95c4-23f9fd7d5786", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
//       redirectUri: MSLOGIN_REDIRECTURL,
//     },
//     cache: {
//       cacheLocation: "localStorage", // This configures where your cache will be stored
//       storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
//     },
//   };
  

//   const pca = new PublicClientApplication(cfg);
//   let allAccounts = JSON.parse(localStorage.getItem("allAccounts"));
//   const account = allAccounts[0];
//   let idToken = null;
//   try {
//     const resp = await pca.acquireTokenSilent({
//       scopes: ["User.Read"],
//       account,
//     });
//     idToken = resp.idToken;
//     return idToken;
//   } catch (err) {
//     return;
//   }
// };
const BACKEND_API_URL = process.env.REACT_APP_COMPANY_BACKEND_API_URL;
const COMPANY_API_URL = process.env.REACT_APP_INTERNAL_USER_BACKEND_API_URL;

//export default getAuthToken;
// Get CLIENT LIST in add user Section
const getClientListRequest = () => {
  return {
    type: GET_CLIENT_LIST_REQUEST,
  };
};

const getClientListReceiveData = (json) => {
  return {
    type: GET_CLIENT_LIST_SUCCESS,
    data: json,
  };
};

export const getClientList = async (data) => {
  let idToken = await getToken();
  // debugger;
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(getClientListRequest());
    return axios
      .post(`${BACKEND_API_URL}/Company/ReadAllCompany`, data, { headers })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: GET_CLIENT_LIST_FAIL, data: json });
        } else {
          return dispatch(getClientListReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: GET_CLIENT_LIST_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// Get CLIENT LIST in add user Section
const deleteClientRequest = () => {
  return {
    type: DELETE_CLIENT_REQUEST,
  };
};

const deleteClientReceiveData = (json) => {
  return {
    type: DELETE_CLIENT_SUCCESS,
    data: json,
  };
};

export const deleteClient = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(deleteClientRequest());
    return axios
      .post(`${BACKEND_API_URL}​​/Company/DeleteCompany`, data, { headers })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: DELETE_CLIENT_FAIL, data: json });
        } else {
          return dispatch(deleteClientReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: DELETE_CLIENT_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// ADD CLIENT in Client Section
const addClientRequest = () => {
  return {
    type: ADD_CLIENT_REQUEST,
  };
};

const addClientReceiveData = (json) => {
  return {
    type: ADD_CLIENT_SUCCESS,
    data: json,
  };
};

export const addClient = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(addClientRequest());
    return axios
      .post(`${BACKEND_API_URL}​/Company/CreateCompany`, data, { headers })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: ADD_CLIENT_FAIL, data: json });
        } else {
          return dispatch(addClientReceiveData(json));
        }
      })
      .catch((err) => {
        return dispatch({
          type: ADD_CLIENT_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// UPDATE CLIENT IN CLIENT SECTION
const updateClientRequest = () => {
  return {
    type: UPDATE_CLIENT_REQUEST,
  };
};

const updateClientReceiveData = (json) => {
  return {
    type: UPDATE_CLIENT_SUCCESS,
    data: json,
  };
};

export const updateClient = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(updateClientRequest());
    return axios
      .post(`${BACKEND_API_URL}​/Company/UpdateCompany`, data, { headers })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: UPDATE_CLIENT_FAIL, data: json });
        } else {
          return dispatch(updateClientReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: UPDATE_CLIENT_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// Get CLIENT DETAILS BY ID FOR EDIT CLIENT
const getClientByIdRequest = () => {
  return {
    type: GET_CLIENT_BY_ID_REQUEST,
  };
};

const getClientByIdReceiveData = (json) => {
  return {
    type: GET_CLIENT_BY_ID_SUCCESS,
    data: json,
  };
};

export const getClientById = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(getClientByIdRequest());
    return axios
      .post(`${BACKEND_API_URL}​/Company/ReadCompanyById`, data, { headers })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: GET_CLIENT_BY_ID_FAIL, data: json });
        } else {
          return dispatch(getClientByIdReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: GET_CLIENT_BY_ID_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// BULKD CLIENT DELETE
const bulkDeleteClientRequest = () => {
  return {
    type: BULK_DELETE_REQUEST,
  };
};

const bulkDeleteClientReceiveData = (json) => {
  return {
    type: BULK_DELETE_SUCCESS,
    data: json,
  };
};

export const bulkDeleteClient = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(bulkDeleteClientRequest());
    return axios
      .post(`${BACKEND_API_URL}​/Company/BulkDelete`, data, { headers })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: BULK_DELETE_FAIL, data: json });
        } else {
          return dispatch(bulkDeleteClientReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: BULK_DELETE_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// Approve and Reject - handling from email
const approveRejectRequest = () => {
  return {
    type: APPROVE_REJECT_FROM_MAIL_REQUEST,
  };
};

const approveRejectReceiveData = (json) => {
  return {
    type: APPROVE_REJECT_FROM_MAIL_SUCCESS,
    data: json,
  };
};

export const approveRejectFromMail = (data) => {
  return (dispatch) => {
    dispatch(approveRejectRequest());

    return axios
      .post(`${BACKEND_API_URL}​/Company/SetClientStatus`, data)
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: APPROVE_REJECT_FROM_MAIL_FAIL, data: json });
        } else {
          return dispatch(approveRejectReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);

        return dispatch({
          type: APPROVE_REJECT_FROM_MAIL_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// Get all offices
const getAllOfficesRequest = () => {
  return {
    type: GET_All_OFFICES_REQUEST,
  };
};
const getAllOfficesReceiveData = (json) => {
  return {
    type: GET_All_OFFICES_SUCCESS,
    data: json,
  };
};
export const getAllOffices = async () => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(getAllOfficesRequest());
    return axios
      .post(`${BACKEND_API_URL}/Company/ReadAllOffice`, null, { headers })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: GET_All_OFFICES_FAIL, data: json });
        } else {
          return dispatch(getAllOfficesReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: GET_All_OFFICES_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// Get client approval request list
const clientApprovalListRequest = () => {
  return {
    type: CLIENT_APPROVAL_LIST_REQUEST,
  };
};
const clientApprovalListReceiveData = (json) => {
  return {
    type: CLIENT_APPROVAL_LIST_SUCCESS,
    data: json,
  };
};
export const clientApprovalList = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(clientApprovalListRequest());
    return axios
      .post(`${BACKEND_API_URL}/Company/FetchApproval`, data, { headers })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: CLIENT_APPROVAL_LIST_FAIL, data: json });
        } else {
          return dispatch(clientApprovalListReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: CLIENT_APPROVAL_LIST_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// approve reject Client
const approveRejectClientRequest = () => {
  return {
    type: APPROVE_REJECT_CLIENT_REQUEST,
  };
};

const approveRejectClientReceiveData = (json) => {
  return {
    type: APPROVE_REJECT_CLIENT_SUCCESS,
    data: json,
  };
};

export const approveRejectClient = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(approveRejectClientRequest());
    return axios
      .post(`${BACKEND_API_URL}/Company/SetHeadApproval`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: APPROVE_REJECT_CLIENT_FAIL, data: json });
        } else {
          return dispatch(approveRejectClientReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: APPROVE_REJECT_CLIENT_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// assign user list
const assignUserListRequest = () => {
  return {
    type: ASSIGN_USER_LIST_REQUEST,
  };
};

const assignUserListReceiveData = (json) => {
  return {
    type: ASSIGN_USER_LIST_SUCCESS,
    data: json,
  };
};

export const assignUserList = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(assignUserListRequest());
    return axios
      .post(`${COMPANY_API_URL}/InternalUser/ReadAllAssingUsers`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: ASSIGN_USER_LIST_FAIL, data: json });
        } else {
          return dispatch(assignUserListReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: ASSIGN_USER_LIST_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};
