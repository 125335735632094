import React, { Component } from 'react'

class Tooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltipShow: false
        };
    }

    showTooltip = () => {
        this.setState({
          tooltipShow: !this.state.tooltipShow
        })
    }

    render() {
        const {tooltipShow} = this.state;
        const {text} = this.props;
        return (
            <div className="tooltip-container">
                <div className={tooltipShow ? 'tooltip-box visible' : 'tooltip-box'}>
                    {text}
                    <span className="tooltip-arrow" />
                </div>
                <div
                    onMouseEnter={() => this.showTooltip()}
                    onMouseLeave={() => this.showTooltip()}
                >
                    <i className="fa fa-info-circle ml-2"></i>
                </div>
            </div>
        )
    }
}

export default Tooltip;