import React, { Component } from "react";
import Header from "../../../common/header";
export default class Home extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="homeBg d-flex align-items-center justify-content-center">
          <div className="punchline text-center">
            <h1>
              Welcome to <span>customer</span> portal
            </h1>
            <p>Seamless service in finance and trust</p>
          </div>
        </div>
      </>
    );
  }
}
