import IdleTimer  from "react-idle-timer";
import React, { Component }  from "react";

class IdleTimerContainer extends Component {
    constructor(props){
        super(props)

        this.state = {
            timeout:1000 * 3600 * 1,
            userLoggedIn: false,
            isTimedOut: false
        }

        this.idleTimer = null
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
    }
      _onActive() {
       this.setState({isTimedOut: false})
      }
     
      _onIdle() {
        this.setState({isTimedOut: false})
        // const isTimedOut = this.state.isTimedOut
        // if (isTimedOut) {
        //   localStorage.removeItem("CompanyName");
        //   localStorage.removeItem("Office");
        //   localStorage.removeItem("formId");
        //   localStorage.removeItem("user");
        //  window.location = "/Login";
          
        // } else {       
        //   this.idleTimer.reset();
        //   this.setState({isTimedOut: true})
        // }
        
      }
    render() {
    return(
        <div>
            <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout} />
         
        </div>
    )
 }
}

export default IdleTimerContainer