export const DeclarationForms = {
  firstName: "",
  middleName: "",
  lastName: "",
  dateOfBirth: "",
  placeOfBirth: "",
  email: "",
  passportNumber: "",
  address: "",
  street: "",
  postalCode: "",
  city: "",
  country: "",
  powerOfAttorneyDated: "",
  powerOfAttorney: [],
  copyOfPassport: [],
};
export const AnnexUBO = {
  noOfRepresentative: 1,
  declarationForms: [DeclarationForms],
  date: new Date(),
  name: null,
  place: null,
  isUBO: "Yes",
  dirty: false,
};
