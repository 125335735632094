export const AdditionDocs = {
  addDocUpload: [],
  addDocExplain: null,
};

export const Chapter1 = {
  structureChartComm: null,
  isInLineUIMAccPolicy: null,
  explainIsInLineUIMAccPolicy:null,
  isMediaCoverageOnStruct: null,
  isAddDocs: null,
  numAddDocs: 1,
  addDocs: [AdditionDocs],
  isUIMnoResonDiscntnuBusRel: null,
  reportOnActivities: null,
  dirty: false,
};
