import React from "react";
export default (props) => {
  const bodyRef = React.createRef();
  const createPdf = () => {
    props.createPdf(bodyRef.current);
  };
  return (
    <div>
      <div ref={bodyRef} className={props.classDisplayFlag && "PDFexportCSS"}>
        {props.children}
      </div>
      <div className="pdfBTN text-center">
        <button
          className="action-btn noicon pt-2 pb-2 pl-4 pr-4"
          onClick={createPdf}
          disabled={props.disableButton}
        >
          I Consent
        </button>
      </div>
    </div>
  );
};
