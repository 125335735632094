import { AdditionDocs, CustActivityRisksMalta } from "./Chapter1";

export const Chapter2 = {
  noUBOInfoByUIM: null,
  isPascalReport: null,
  noPascalReportReason: null,
  pascalReportUpload: [],
  isMediaCoverageOnStruct: null,
  mediaCmnt: null,
  isAddDocs: null,
  numAddDocs: 1,
  addDocs: [AdditionDocs],
  isRisk: null,
  numCustActivityRisks: 1,
  custActivityRisks: [CustActivityRisksMalta],
  risksCncrnngIntermediary: null,
  isSrvcPrvdrTermntdSrvcsClr: null,
  isSrvcPrvdrReputable: null,
  isReceiveNecessaryInfo: null,
  isIncreasedIntegrityRisk: null,
  increasedIntegrityRiskReason: null,
  dirty: false,
};
