import { Chapter1 } from "../../../app/JSONs/UK-Form/External/Client-Information/Chapter1";
import { Chapter2 } from "../../../app/JSONs/UK-Form/External/Client-Information/Chapter2";
import { Chapter3 } from "../../../app/JSONs/UK-Form/External/Client-Information/Chapter3";
import { Chapter4 } from "../../../app/JSONs/UK-Form/External/Client-Information/Chapter4";
import { Chapter5 } from "../../../app/JSONs/UK-Form/External/Client-Information/Chapter5";
import { Chapter6 } from "../../../app/JSONs/UK-Form/External/Client-Information/Chapter6";

const initialState = {
  chapter1: Chapter1,
  chapter2: Chapter2,
  chapter3: Chapter3,
  chapter4: Chapter4,
  chapter5: Chapter5,
  chapter6: Chapter6,
};

const UKClientInformationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "UPDATE_UK_CLIENTINFO_DATA":
      const {
        baseName,
        index,
        name,
        value,
        chapter,
        objBaseName,
        nestedObjBaseName,
        indexObjBaseName
      } = action.data;
      let array, obj;
      if (!baseName && !objBaseName) {
        return {
          ...state,
          [chapter]: {
            ...state[chapter],
            [name]: value,
            dirty: true,
          },
        };
      } else if (nestedObjBaseName && objBaseName) {
       return {
          ...state,
          [chapter]: {
            ...state[chapter],
            [objBaseName]: {
              ...state[chapter][objBaseName],
              [nestedObjBaseName]: {
                ...state[chapter][objBaseName][nestedObjBaseName],
                [name]: value,
              },
            },
            dirty: true,
          },
        };
      } else if (objBaseName && !baseName) {
        return {
          ...state,
          [chapter]: {
            ...state[chapter],
            [objBaseName]: {
              ...state[chapter][objBaseName],
              [name]: value,
            },
            dirty: true,
          },
        };
      } 
      else if (objBaseName && baseName && indexObjBaseName) {
       array = state[chapter][objBaseName][indexObjBaseName][baseName];
       obj = {
          ...array[index],
          [name]: value,
        };
        array[index] = obj;
        return {
          ...state,
          [chapter]: {
            ...state[chapter],
            [objBaseName]: Object.values({
              ...state[chapter][objBaseName],
              [indexObjBaseName]:{
                  ...state[chapter][objBaseName][indexObjBaseName],
                  [baseName]: array,
                
               
              }
            }),
           dirty: true,
          },
        };
      } 
      else if (objBaseName && baseName && !indexObjBaseName) {
        array = state[chapter][objBaseName][baseName];
        obj = {
          ...array[index],
          [name]: value,
        };
        array[index] = obj;
        return {
          ...state,
          [chapter]: {
            ...state[chapter],
            [objBaseName]: {
              ...state[chapter][objBaseName],
              [baseName]: array,
            },
            dirty: true,
          },
        };
      } else {
        array = state[chapter][baseName];
        obj = {
          ...array[index],
          [name]: value,
        };
        array[index] = obj;
        return {
          ...state,
          [chapter]: {
            ...state[chapter],
            [baseName]: array,
            dirty: true,
          },
        };
      }

    case "UPDATE_NEW_UK_DATA":
      return action.data;

    case "GET_UK_CAF_DETAILS_SUCCESS":
      let response = action.data.result;
      let resChapter1,
        resChapter2,
        resChapter3,
        resChapter4,
        resChapter5,
        resChapter6;
      resChapter1 =
        response.chapter1 !== undefined ? response.chapter1 : Chapter1;
      resChapter2 =
        response.chapter2 !== undefined ? response.chapter2 : Chapter2;
      resChapter3 =
        response.chapter3 !== undefined ? response.chapter3 : Chapter3;
      resChapter4 =
        response.chapter4 !== undefined ? response.chapter4 : Chapter4;

      resChapter5 =
        response.chapter5 !== undefined ? response.chapter5 : Chapter5;
      resChapter6 =
        response.chapter6 !== undefined ? response.chapter6 : Chapter6;
      resChapter1.dirty = false;
      resChapter2.dirty = false;
      resChapter3.dirty = false;
      resChapter5.dirty = false;
      resChapter6.dirty = false;

      return {
        ...state,
        chapter1: resChapter1,
        chapter2: resChapter2,
        chapter3: resChapter3,
        chapter4: resChapter4,
        chapter5: resChapter5,
        chapter6: resChapter6,
      };

    case "CREATE_UK_CLIENTINFO_OBJECT":
      const { chap, maxIndex, referral, pushedObject, nestedObjName,masterObj, masterIndex } =
        action.data;
      let arrayData;
      if (nestedObjName) {
        arrayData = state[chap][nestedObjName][referral];
      } 
      else if(masterObj){
        arrayData =state[chap][masterObj][masterIndex][referral]
      }
      else {
         arrayData = state[chap][referral];        
      }
      if (arrayData !== null && arrayData.length < maxIndex) {
        let diff = maxIndex - arrayData.length;

        for (let i = 0; i < diff; i++) {
          arrayData.push(pushedObject);
        }
      }

      return {
        ...state,
        [chap]: {
          ...state[chap],
          [referral]: arrayData,
        },
      };

    case "GET_UK_INTERNAL_CAF_DETAILS_SUCCESS":
      let uk_res = action.data.result;
      if (uk_res) {
        let chapters = uk_res.cafExternal;
        return {
          ...state,
          ...chapters,
        };
      } else {
        return state;
      }

    case "REMOVE_UK_CLIENTINFO_OBJECT":
      const { remChap, remMaxIndex, remReferral, remNestedObjName,remmasterObj,remmasterIndex } =
        action.data;

      let remArray;
      if (remNestedObjName) {
        remArray = state[remChap][remNestedObjName][remReferral];
      } 
      else if(remmasterObj){
        remArray=state[remChap][remmasterObj][remmasterIndex][remReferral];
      }
      else {
        remArray = state[remChap][remReferral];
      }

      if (remArray !== null && remArray.length < remMaxIndex) {
        let diff = remMaxIndex - remArray.length;
        for (let i = 0; i < diff; i++) {
          remArray.splice(-1);
        }
      }

      return {
        ...state,
        [remChap]: {
          ...state[remChap],
          [remReferral]: remArray,
        },
      };

    case "NEW_UK_CLIENT_INFO_STATE":
      if (action.data.state) return action.data.state;
      else return state;

    default:
      return state;
  }
};

export default UKClientInformationReducer;
