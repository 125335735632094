const ovrvwBnkAC = {
  isFatcaSCF: "",
  fatcaSelfCertifctnFrm: [],
  noFATCAformReason: null,
  bnkStmnts: [],
  agremntsGrUpEntities: [],
  agremntsOthrParties: [],
  financialStmntsObjComp: [],
  finanicalStmntsAllPrticipatns: [],
  financialStmntsParntComp: [],
  declartnsFinanicalInstitutns: [],
};
export const DirectorNamesArray = {
  dirName: null,
  dirNationality: null,
  dirCertifiedPass:null,
  dirProofAddress:null
};
const DocumentsObjectCompany = {
  copyPassportManagement: [],
  extractChamberOfCommerce: [],
  deedOfIncorporation: [],
  shareholdersRegister: [],
  shareholdersCertificates: [],
  financialStatmntsThreeYears: [],
  isAPA: "",
  objCompTaxResult: "",
  explanation: null,
  otherDocs: [],
};

export const Chapter4 = {
  arethereExistingUPCDir:"",
  isObjCompExists: "",
  commercialRegister: null,
  noOfDirectors: 1,
  objCompDirectorNames: [DirectorNamesArray],
  objCompActiveCountry: [],
  isObjCompBranches: "",
  objCompBranch: "",
  documentsObjComp: DocumentsObjectCompany,
  taxAdvisorFinds: [],
  ovrvwBnkAC: ovrvwBnkAC,
};
