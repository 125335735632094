import React, { Component } from "react";
import FileUpload from "../../../../../../elements/FileUpload";
import PhoneInputField from "../../../../../../elements/PhoneInputField";
import DateTimePicker from "../../../../../../elements/DateTimePicker";
import TextBox from "../../../../../../elements/TextBox";
import countryList from "react-select-country-list";
import { fileAcceptType } from "../constants";
import DropDown from "../../../../../../elements/DropDown";
class UboBasicInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, baseName, errorMsg } = this.props;
    const eMsg = JSON.parse(errorMsg);
    return (
      <div className="row mb-0">
        <div className="mb-3 col-lg-4">
          <TextBox
            label="Name"
            placeholder="Enter name"
            name="name"
            id={`name[${index}]`}
            chapter={this.props.chapter}
            index={index}
            baseName={baseName}
            reducer={this.props.reducer}
            objBaseName={this.props.objBaseName}
            validationType="OnlyLetter"
            isRequired={this.props.form === "UK" ? true : false}  
          />
          {eMsg !== null &&
            Object.entries(eMsg).length !== 0 &&
            eMsg.ubox &&
            eMsg.ubox[index] &&
            eMsg.ubox[index]?.name?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.ubox[index]?.name}
              </span>
            )}
        </div>
        <div className="mb-3 col-lg-4">
          <TextBox
            label="Email"
            placeholder="Enter email"
            name="email"
            id={`email[${index}]`}
            index={index}
            chapter={this.props.chapter}
            baseName={baseName}
            reducer={this.props.reducer}
            objBaseName={this.props.objBaseName}
            validationType="Email"
            isRequired={this.props.form === "UK" ? true : false}  
            ShowValidation={
              eMsg !== null &&
              Object.entries(eMsg).length !== 0 &&
              eMsg.ubox &&
              eMsg.ubox[index] &&
              eMsg.ubox[index]?.email?.length > 0
                ? false
                : true
            }
          />
          {eMsg !== null &&
            Object.entries(eMsg).length !== 0 &&
            eMsg.ubox &&
            eMsg.ubox[index] &&
            eMsg.ubox[index]?.email?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.ubox[index]?.email}
              </span>
            )}
        </div>
        <div className="mb-3 col-lg-4" id={`phone[${index}]`}>
          <PhoneInputField
            label="Phone Number"
            name="phone"
            index={index}
            baseName={baseName}
            chapter={this.props.chapter}
            reducer={this.props.reducer}
            objBaseName={this.props.objBaseName}
            country={this.props.form}
            isRequired={this.props.form === "UK" ? true : false}  
          />
          {eMsg !== null &&
            Object.entries(eMsg).length !== 0 &&
            eMsg.ubox &&
            eMsg.ubox[index] &&
            eMsg.ubox[index]?.phone?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.ubox[index]?.phone}
              </span>
            )}
        </div>
        <div className="mb-3 col-lg-4">
          <TextBox
            label="Residental Address"
            placeholder="Enter residental address"
            name="residentialAddress"
            id={`residentialAddress[${index}]`}
            index={index}
            chapter={this.props.chapter}
            baseName={baseName}
            reducer={this.props.reducer}
            objBaseName={this.props.objBaseName}
            validationType="Text"
            isRequired={this.props.form === "UK" ? true : false}  
          />
          {eMsg !== null &&
            Object.entries(eMsg).length !== 0 &&
            eMsg.ubox &&
            eMsg.ubox[index] &&
            eMsg.ubox[index]?.residentialAddress?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.ubox[index]?.residentialAddress}
              </span>
            )}
        </div>
        <div className="mb-3 col-lg-4">
          <TextBox
            label="Registered Office"
            placeholder="Enter registered office"
            name="registeredOffice"
            id={`registeredOffice[${index}]`}
            index={index}
            chapter={this.props.chapter}
            baseName={baseName}
            reducer={this.props.reducer}
            objBaseName={this.props.objBaseName}
            validationType="Text"
            //isRequired={this.props.form === "UK" ? true : false}  
          />
          {/* {eMsg !== null &&
            Object.entries(eMsg).length !== 0 &&
            eMsg.ubox &&
            eMsg.ubox[index] &&
            eMsg.ubox[index]?.registeredOffice?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.ubox[index]?.registeredOffice}
              </span>
            )} */}
        </div>

        <div className="mb-3 col-lg-4">
          <TextBox
            label="Alternate Correspondence Address"
            placeholder="Enter alternative address"
            name="altCrspdnAddress"
            id={`altCrspdnAddress[${index}]`}
            index={index}
            chapter={this.props.chapter}
            baseName={baseName}
            reducer={this.props.reducer}
            objBaseName={this.props.objBaseName}
            validationType="Text"
            //isRequired={this.props.form === "UK" ? true : false}  
          />{" "}
          {/* {eMsg !== null &&
            Object.entries(eMsg).length !== 0 &&
            eMsg.ubox &&
            eMsg.ubox[index] &&
            eMsg.ubox[index]?.altCrspdnAddress?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.ubox[index]?.altCrspdnAddress}
              </span>
            )} */}
        </div>

        <div className="mb-3 col-lg-4" id={`dob[${index}]`}>
          <DateTimePicker
            label="Date of Birth"
            name="dob"
            index={index}
            chapter={this.props.chapter}
            baseName={baseName}
            reducer={this.props.reducer}
            objBaseName={this.props.objBaseName}
            validationType="Text"
            isRequired={this.props.form === "UK" ? true : false}  
          />
          {eMsg !== null &&
            Object.entries(eMsg).length !== 0 &&
            eMsg.ubox &&
            eMsg.ubox[index] &&
            eMsg.ubox[index]?.dob?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.ubox[index]?.dob}
              </span>
            )}
        </div>

        <div className="mb-3 col-lg-4">
          <DropDown
            label="Nationality"
            name="uboNationality"
            id={`uboNationality[${index}]`}
            options={countryList().getData()}
            index={index}
            chapter={this.props.chapter}
            baseName={baseName}
            reducer={this.props.reducer}
            objBaseName={this.props.objBaseName}
            validationType="Text"
            isRequired={this.props.form === "UK" ? true : false}  
          />
          {eMsg !== null &&
            Object.entries(eMsg).length !== 0 &&
            eMsg.ubox &&
            eMsg.ubox[index] &&
            eMsg.ubox[index]?.uboNationality?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.ubox[index]?.uboNationality}
              </span>
            )}
        </div>
        <div className="mb-3 col-lg-4">
          <DropDown
            label="Country of birth"
            name="uboCountryOfBirth"
            id={`uboCountryOfBirth[${index}]`}
            options={countryList().getData()}
            index={index}
            chapter={this.props.chapter}
            baseName={baseName}
            reducer={this.props.reducer}
            objBaseName={this.props.objBaseName}
            validationType="Text"
            isRequired={this.props.form === "UK" ? true : false}  
          />
          {eMsg !== null &&
            Object.entries(eMsg).length !== 0 &&
            eMsg.ubox &&
            eMsg.ubox[index] &&
            eMsg.ubox[index]?.uboCountryOfBirth?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.ubox[index]?.uboCountryOfBirth}
              </span>
            )}
        </div>
        <div className="mb-3 col-lg-4">
          <DropDown
            label="Country of Residence"
            name="uboCountryOfResidence"
            id={`uboCountryOfResidence[${index}]`}
            options={countryList().getData()}
            index={index}
            chapter={this.props.chapter}
            baseName={baseName}
            reducer={this.props.reducer}
            objBaseName={this.props.objBaseName}
            validationType="Text"
            isRequired={this.props.form === "UK" ? true : false}  
          />
          {eMsg !== null &&
            Object.entries(eMsg).length !== 0 &&
            eMsg.ubox &&
            eMsg.ubox[index] &&
            eMsg.ubox[index]?.uboCountryOfResidence?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.ubox[index]?.uboCountryOfResidence}
              </span>
            )}
        </div>
        <div className="mb-3 col-lg-4">
          <DropDown
            label="Country of Tax Residence"
            name="countryOfTaxResidence"
            id={`countryOfTaxResidence[${index}]`}
            options={countryList().getData()}
            index={index}
            chapter={this.props.chapter}
            baseName={baseName}
            reducer={this.props.reducer}
            objBaseName={this.props.objBaseName}
            validationType="Text"
            isRequired={this.props.form === "UK" ? true : false}  
          />
          {eMsg !== null &&
            Object.entries(eMsg).length !== 0 &&
            eMsg.ubox &&
            eMsg.ubox[index] &&
            eMsg.ubox[index]?.countryOfTaxResidence?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.ubox[index]?.countryOfTaxResidence}
              </span>
            )}
        </div>

        <div className="mb-2 col-lg-4">
          <FileUpload
            label={"Certified True Copy Passport"}
            acceptType={fileAcceptType}
            name="certifiedTrueCopyPass"
            id={`certifiedTrueCopyPass[${index}]`}
            index={index}
            chapter={this.props.chapter}
            baseName={baseName}
            reducer={this.props.reducer}
            objBaseName={this.props.objBaseName}
            isRequired={this.props.form === "UK" ? true : false}  
          />
          {eMsg !== null &&
            Object.entries(eMsg).length !== 0 &&
            eMsg.ubox &&
            eMsg.ubox[index] &&
            eMsg.ubox[index]?.certifiedTrueCopyPass?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.ubox[index]?.certifiedTrueCopyPass}
              </span>
            )}
        </div>
        <div className="mb-2 col-lg-4">
          <FileUpload
            label={"Proof of Address"}
            acceptType={fileAcceptType}
            name="proofAddress"
            id={`proofAddress[${index}]`}
            index={index}
            chapter={this.props.chapter}
            baseName={baseName}
            reducer={this.props.reducer}
            objBaseName={this.props.objBaseName}
            isRequired={this.props.form === "UK" ? true : false}  
          />
          {eMsg !== null &&
            Object.entries(eMsg).length !== 0 &&
            eMsg.ubox &&
            eMsg.ubox[index] &&
            eMsg.ubox[index]?.proofAddress?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.ubox[index]?.proofAddress}
              </span>
            )}
        </div>
        <div className="mb-2 col-lg-4">
          <FileUpload
            label={"Reference Letter"}
            acceptType={fileAcceptType}
            name="refLetter"
            id={`refLetter[${index}]`}
            index={index}
            chapter={this.props.chapter}
            baseName={baseName}
            reducer={this.props.reducer}
            objBaseName={this.props.objBaseName}
            //isRequired={this.props.form === "UK" ? true : false}
          />
          {/* {eMsg !== null &&
            Object.entries(eMsg).length !== 0 &&
            eMsg.ubox &&
            eMsg.ubox[index] &&
            eMsg.ubox[index]?.refLetter?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.ubox[index]?.refLetter}
              </span>
            )} */}
        </div>
      </div>
    );
  }
}

export default UboBasicInfo;
