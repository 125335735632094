export const Entities = {
  chamberExtract: null,
  article: null,
  shareholderRegister: null,
  finStat: null,
};

export const Chapter1 = {
  structureChart: [],
  isCompIncorporated: "",
  noOfEntities: 1,
  entities: [Entities],
  isClientNew: null,
  clientUIMMalta: null,
  introducerName: null,
  companyNameMal: null,
  doesClientIntrdNewPer: null,
  descNewPer: null,
  clientPersName: null,
  clientPersComp: null,
  clientPersNationality: null,
  introducerNationality: null,
  dirty: false,
};
