import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import countryList from "react-select-country-list";
import CheckBox from "../../../../../elements/CheckBox";
import DropDown from "../../../../../elements/DropDown";
import TextBox from "../../../../../elements/TextBox";

const chapter = "chapter6";

class Chapter6 extends Component {
  render() {
    const { errorMsg } = this.props;
    const eMsg = JSON.parse(errorMsg);
    // const eMsg = {};
    return (
      <div className="generic-form client-investigation mt-4">
        <h5 className="mb-3 mt-2">          
          {this.props.form === "NL"
              ? "6- Agreements - Purpose of the (trust) services"
              :"Agreements - Purpose of the (trust) services"}
        </h5>
        <h6 className="sub-chapter-title mt-4 mb-0">External Directors</h6>
        <Row className="pt-3 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Are there external directors?"
              options={["Yes", "No"]}
              name="isExternalDirectors"
              id="isExternalDirectors"
              chapter={chapter}
              reducer={this.props.reducer}
            />
            {eMsg &&
              eMsg?.isExternalDirectors &&
              eMsg.isExternalDirectors?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {eMsg.isExternalDirectors}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-8">
            {this.props.isExternalDirectors === "Yes" && (
              <Row>
                <div className="mb-3 col-lg-6">
                  <CheckBox
                    label="Are the external directors a natural person?"
                    options={["Yes", "No"]}
                    name="isExternalDirectorsNaturalPerson"
                    id="isExternalDirectorsNaturalPerson"
                    chapter={chapter}
                    reducer={this.props.reducer}
                    isRequired={this.props.form === "UK" ? true : false} 
                  />
                  {eMsg &&
                    eMsg?.isExternalDirectorsNaturalPerson &&
                    eMsg.isExternalDirectorsNaturalPerson?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {eMsg.isExternalDirectorsNaturalPerson}
                      </span>
                    )}
                </div>
                {this.props.isExternalDirectorsNaturalPerson === "Yes" && (
                  <>
                    <div className="mb-3 col-lg-6">
                      <TextBox
                        label="Enter name of natural person"
                        placeholder="enter name"
                        name="nameOfNaturalPerson"
                        id="nameOfNaturalPerson"
                        chapter={chapter}
                        reducer={this.props.reducer}
                      />
                      {eMsg &&
                        eMsg?.nameOfNaturalPerson &&
                        eMsg.nameOfNaturalPerson?.length > 0 && (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {eMsg.nameOfNaturalPerson}
                          </span>
                        )}
                    </div>
                    <div className="mb-3 col-lg-6">
                      <DropDown
                        label="Nationality"
                        name="nationality"
                        id="nationality"
                        options={countryList().getData()}
                        chapter={chapter}
                        reducer={this.props.reducer}
                      />
                      {eMsg &&
                        eMsg?.nationality &&
                        eMsg.nationality?.length > 0 && (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {eMsg.nationality}
                          </span>
                        )}
                    </div>
                  </>
                )}
              </Row>
            )}
          </div>
        </Row>
        {this.props.form !== "UK" && (
          <Row>
            <div className="mb-3 col-lg-4">
              <CheckBox
                label="Are the directors jointly authorized?"
                //label="Are one or more external directors independently authorized?"
                options={["Yes", "No"]}
                name="isOneOrMoreExtDirectorsAuthorized"
                id="isOneOrMoreExtDirectorsAuthorized"
                chapter={chapter}
                reducer={this.props.reducer}
              />
              {eMsg &&
                eMsg?.isOneOrMoreExtDirectorsAuthorized &&
                eMsg.isOneOrMoreExtDirectorsAuthorized?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {eMsg.isOneOrMoreExtDirectorsAuthorized}
                  </span>
                )}
            </div>
            {this.props.isOneOrMoreExtDirectorsAuthorized==="No" &&(
            <div className="mb-3 col-lg-4">
              <CheckBox
                label="Is external director of object company solely authorised?"
                //label="Are one or more external directors independently authorized?"
                options={["Yes", "No"]}
                name="isExtDirectorsOfobjectcompanyAuthorized"
                id="isExtDirectorsOfobjectcompanyAuthorized"
                chapter={chapter}
                reducer={this.props.reducer}
              />
             
            </div>
            )}
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer } = ownProps;
  return {
    isExternalDirectors: state[reducer]["chapter6"]["isExternalDirectors"],
    isExternalDirectorsNaturalPerson:
      state[reducer]["chapter6"]["isExternalDirectorsNaturalPerson"],
      isOneOrMoreExtDirectorsAuthorized:state[reducer]["chapter6"]["isOneOrMoreExtDirectorsAuthorized"],
  };
};

export default connect(mapStateToProps, null)(Chapter6);
