import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  Authentication_USER_REQUEST,
  Authentication_USER_SUCCESS,
  Authentication_USER_FAIL,
  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  GET_USER_BY_USERID_REQUEST,
  GET_USER_BY_USERID_SUCCESS,
  GET_USER_BY_USERID_FAIL,
  BULK_DELETE_REQUEST,
  BULK_DELETE_SUCCESS,
  BULK_DELETE_FAIL,
  APPROVE_REJECT_FROM_MAIL_REQUEST,
  APPROVE_REJECT_FROM_MAIL_SUCCESS,
  APPROVE_REJECT_FROM_MAIL_FAIL,
  USER_APPROVAL_LIST_REQUEST,
  USER_APPROVAL_LIST_SUCCESS,
  USER_APPROVAL_LIST_FAIL,
  APPROVE_REJECT_USER_REQUEST,
  APPROVE_REJECT_USER_SUCCESS,
  APPROVE_REJECT_USER_FAIL,
  ASSIGN_CLIENT_LIST_REQUEST,
  ASSIGN_CLIENT_LIST_SUCCESS,
  ASSIGN_CLIENT_LIST_FAIL,
  Authentication_UBO_REQUEST,
  Authentication_UBO_SUCCESS,
  Authentication_UBO_FAIL,
  VERIFY_UBO_REQUEST,
  VERIFY_UBO_SUCCESS,
  VERIFY_UBO_FAIL,
  DELETE_UBO_REQUEST,
  DELETE_UBO_SUCCESS,
  DELETE_UBO_FAIL,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  SET_PROFILE_REQUEST,
  SET_PROFILE_SUCCESS,
  SET_PROFILE_FAIL,
  DOWNLOAD_FATCA_REQUEST,
  DOWNLOAD_FATCA_SUCCESS,
  DOWNLOAD_FATCA_FAIL,
} from "../constants/userConstants";

const initialState = {
  isLoading: false,
  user: [],
  error: "",
};

const userReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
    case ADD_USER_REQUEST:
    case CHANGE_PASSWORD_REQUEST:
    case Authentication_USER_REQUEST:
    case Authentication_UBO_REQUEST:
    case VERIFY_USER_REQUEST:
    case VERIFY_UBO_REQUEST:
    case DELETE_UBO_REQUEST:
    case FORGOT_PASSWORD_REQUEST:
    case RESET_PASSWORD_REQUEST:
    case UPDATE_USER_REQUEST:
    case DELETE_USER_REQUEST:
    case GET_USER_LIST_REQUEST:
    case GET_USER_BY_USERID_REQUEST:
    case BULK_DELETE_REQUEST:
    case APPROVE_REJECT_FROM_MAIL_REQUEST:
    case USER_APPROVAL_LIST_REQUEST:
    case APPROVE_REJECT_USER_REQUEST:
    case ASSIGN_CLIENT_LIST_REQUEST:
    case GET_PROFILE_REQUEST:
    case SET_PROFILE_REQUEST:
    case DOWNLOAD_FATCA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case LOGIN_USER_SUCCESS:
    case ADD_USER_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
    case Authentication_USER_SUCCESS:
    case Authentication_UBO_SUCCESS:
    case VERIFY_USER_SUCCESS:
    case VERIFY_UBO_SUCCESS:
    case DELETE_UBO_SUCCESS:
    case FORGOT_PASSWORD_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
    case UPDATE_USER_SUCCESS:
    case DELETE_USER_SUCCESS:
    case GET_USER_LIST_SUCCESS:
    case GET_USER_BY_USERID_SUCCESS:
    case BULK_DELETE_SUCCESS:
    case APPROVE_REJECT_FROM_MAIL_SUCCESS:
    case USER_APPROVAL_LIST_SUCCESS:
    case APPROVE_REJECT_USER_SUCCESS:
    case ASSIGN_CLIENT_LIST_SUCCESS:
    case GET_PROFILE_SUCCESS:
    case SET_PROFILE_SUCCESS:
    case DOWNLOAD_FATCA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case LOGIN_USER_FAIL:
    case ADD_USER_FAIL:
    case CHANGE_PASSWORD_FAIL:
    case Authentication_USER_FAIL:
    case Authentication_UBO_FAIL:
    case VERIFY_USER_FAIL:
    case VERIFY_UBO_FAIL:
    case DELETE_UBO_FAIL:
    case FORGOT_PASSWORD_FAIL:
    case RESET_PASSWORD_FAIL:
    case UPDATE_USER_FAIL:
    case DELETE_USER_FAIL:
    case GET_USER_LIST_FAIL:
    case GET_USER_BY_USERID_FAIL:
    case BULK_DELETE_FAIL:
    case APPROVE_REJECT_FROM_MAIL_FAIL:
    case USER_APPROVAL_LIST_FAIL:
    case APPROVE_REJECT_USER_FAIL:
    case ASSIGN_CLIENT_LIST_FAIL:
    case GET_PROFILE_FAIL:
    case SET_PROFILE_FAIL:
    case DOWNLOAD_FATCA_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default userReducer;
