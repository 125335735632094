import React, { Component } from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";

class UBODetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, errorMsg } = this.props;
    const eMsg = errorMsg;
    return (
     
        <fieldset>
          <legend>UBO {this.props.index + 1}</legend>
          <Row>
            <div className="mb-2 col-lg-6 col-xl-6">
              <TextBox
                label="Name"
                name="uboName"
                id={`uboName[${index}]`}
                placeholder="enter your text"               
                index={this.props.index}
                baseName="ubos"
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}              
              />             
            </div>           
          </Row>
          <Row>
            <div className="mb-2 col-lg-6 col-xl-6">
              <TextBox
                label="Address"
                name="uboAddress"
                id={`uboAddress[${index}]`}
                placeholder="enter your text"               
                index={this.props.index}
                baseName="ubos"
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}              
              />             
            </div>           
          </Row>
          <Row>
            <div className="mb-2 col-lg-6 col-xl-6">
              <TextBox
                label="Residence"
                name="uboResidence"
                id={`uboResidence[${index}]`}
                placeholder="enter your text"               
                index={this.props.index}
                baseName="ubos"
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}              
              />             
            </div>           
          </Row>
          <Row>
            <div className="mb-2 col-lg-6 col-xl-6">
              <TextBox
                label="Telephone/Fax"
                name="uboTelephone"
                id={`uboTelephone[${index}]`}
                placeholder="enter your text"               
                index={this.props.index}
                baseName="ubos"
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}              
              />             
            </div>           
          </Row>
          <Row>
            <div className="mb-2 col-lg-6 col-xl-6">
              <TextBox
                label="Email"
                name="uboEmail"
                id={`uboEmail[${index}]`}
                placeholder="enter your text"               
                index={this.props.index}
                baseName="ubos"
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}              
              />             
            </div>           
          </Row>
        </fieldset>
     
    );
  }
}

export default UBODetails;
