import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";

import {
  getGenericDetails,
  globalStateNotChanged,
} from "../../../../redux/actions/CAFGenericActions";

class NLExternalSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recordId: "",
      selectedOptionUBO: "",
      selectedOptionPEP: "",
    };
  }

  componentDidMount = () => {
    let rId = localStorage.getItem("formId");
    if (rId !== "null") {
      this.setState({ isLoading: true });
      this.props
        .getGenericDetails({
          id: rId,
        })
        .then((res) => {
          if (res.data.portalErrorCode === 200) {
            this.setState({
              isLoading: false,
              selectedOptionUBO: res.data.result.genericDetails.isUBO,
              selectedOptionPEP: res.data.result.genericDetails.isPEP,
            });
            this.props.globalStateNotChanged();
          }
        })
        .catch(this.catchError);
      this.setState({ isLoading: false });
    }
  };

  handleChange(e) {
    this.props.handleOfficeChange(e);
  }

  render() {
    const url = window.location.pathname;
    
    return (
      <>
        <ul>
          <li>
            <Link
              to={
                url === "/dashboard/client-information-list" ||
                url === "/dashboard/add-clientinformation"
                  ? "#"
                  : "/generic-form"
              }
              className={this.props.clientInfoActiveLinkLevel >= 1 && "active"}
            >
              <i className="fa fa-user mr-3"></i> Generic Form
            </Link>
          </li>
          <li>
            <Link
              to={
                url === "/dashboard/client-information-list" ||
                url === "/dashboard/add-clientinformation"
                  ? "#"
                  : "/client-information"
              }
              style={{
                pointerEvents:
                  this.props.clientInfoActiveLinkLevel === 0 ||
                  this.props.clientInfoActiveLinkLevel === "0"
                    ? "none"
                    : "",
              }}
              className={this.props.clientInfoActiveLinkLevel >= 2 && "active"}
            >
              <i className="fa fa-id-badge mr-3"></i> Client Information
            </Link>
          </li>
          <li>
            <Link
              to={
                url === "/dashboard/client-information-list" ||
                url === "/dashboard/add-clientinformation"
                  ? "#"
                  : "/ubo-status-list"
              }
              style={{
                pointerEvents:
                  this.props.clientInfoActiveLinkLevel === 0 ||
                  this.props.clientInfoActiveLinkLevel === "0" ||
                  this.props.clientInfoActiveLinkLevel === 1 ||
                  this.props.clientInfoActiveLinkLevel === "1"
                    ? "none"
                    : "",
              }}
              className={this.props.clientInfoActiveLinkLevel >= 3 && "active"}
            >
              <i className="fa fa-check-circle mr-3"></i> UBO/PEP status
            </Link>
          </li>

          {/* {this.props.clientInfoActiveLinkLevel >= 1 && (
            <>
              {selectedOptionUBO && selectedOptionPEP ? (
                <>
                  <li>
                    <Link
                      to="/ubo-declaration-uim"
                      //style={{ pointerEvents:this.props.clientInfoActiveLinkLevel >= 3 ? "" : "none" }}
                      className={this.props.clientInfoActiveLinkLevel >= 3 && "active"}
                    >
                      <i className="fa fa-check-circle mr-3"></i> UBO declaration UIM
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/pep-declaration"
                      //style={{ pointerEvents:this.props.clientInfoActiveLinkLevel >= 4 ? "" : "none" }}
                      className={
                        this.props.clientInfoActiveLinkLevel >= 4 && "active"
                      }
                    >
                      <i className="fa fa-check-circle mr-3"></i> PEP declaration
                    </Link>
                  </li>
                </>
              ) : !selectedOptionUBO && selectedOptionPEP ? (
                <>
                  <li>
                    <Link
                      to="/pep-declaration"
                      //style={{ pointerEvents:this.props.clientInfoActiveLinkLevel >= 3 ? "" : "none" }}
                      className={
                        this.props.clientInfoActiveLinkLevel >= 3 && "active"
                      }
                    >
                      <i className="fa fa-check-circle mr-3"></i> PEP declaration
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/Annex-No-UBO"
                      className={this.props.clientInfoActiveLinkLevel >= 4 && "active"}
                    >
                      <i className="fa fa-check-circle mr-3"></i> Annex No UBO Declaration
            </Link>
                  </li>
                </>
              ) : selectedOptionUBO && !selectedOptionPEP ? (
                <>
                  <li>
                    <Link
                      to="/Annex-UBO"
                      className={this.props.clientInfoActiveLinkLevel >= 3 && "active"}
                    >
                      <i className="fa fa-check-circle mr-3"></i> Annex UBO Declaration
            </Link>
                  </li>
                </>
              ) : null}
            </>
          )} */}

          {/* <li>
            <Link
              to="/Annex-No-UBO"
              className={activeIndex === "clientinformation" ? "active" : ""}
            >
              <i className="fa fa-check-circle mr-3"></i> Annex No UBO
              Declaration
            </Link>
          </li>
          <li>
            <Link
              to="/Annex-UBO"
              className={activeIndex === "clientinformation" ? "active" : ""}
            >
              <i className="fa fa-check-circle mr-3"></i> Annex UBO Declaration
            </Link>
          </li> */}
        </ul>

        <p className="backtoformLink">
          {window.location.href.indexOf("client-information-list") > -1 ? (
            <> </>
          ) : (
            <Link
              to="/client-information-list"
              onClick={(e) => this.handleChange(e)}
              // onClick={() => localStorage.removeItem("formId")}
            >
              <i className="fa fa-angle-left mr-1"></i> Back to Client Forms
              List
            </Link>
          )}
        </p>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    genericDataRecordId: state.genericInformation.data.recordId,
    globalState: state.CAFGeneric.globalState,
    linkGeneric: state.CAFGeneric.linkGeneric,
    clientInfoActiveLinkLevel: state.CAFGeneric.clientInfoActiveLinkLevel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGenericDetails: (data) => dispatch(getGenericDetails(data)),
    globalStateNotChanged: () => dispatch(globalStateNotChanged()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NLExternalSidebar);
