import React from "react";
import { Row } from "react-bootstrap";
import {
  setCuracaoInternalCAFDetails,
  getCuracaoInternalCAFDetails,
} from "../../../../../redux/actions/Curacao/CuracaoInternalCAFActions";
import "../../Onboarding/internalCAF.scss";
import { connect } from "react-redux";
import NotificationManager from "react-notifications/lib/NotificationManager";
import AutoSaving from "../AutoSaving";
import FormalQuestions from "../../Onboarding/Chapter12/FormalQuestions";
import DateTimePicker from "../../../../elements/DateTimePicker";
import { setCuracaoInternalChapterError } from "../../../../../redux/actions/Curacao/CuracaoInternalChapterErrors";
import getSequentialFields from "../getSequentialFields";
import { chapValidationErrorCuracao } from "../Constants";
import { getFirstUnfilledField } from "../../MaltaOffice/getFirstUnfilledField";
import { HashLink } from "react-router-hash-link";

const chapter = "chapter12";
const reducer = "CuracaoInternalCAF";

let timer;

class Chapter12 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formErrorsChapter12: {},
      isCAFValid: null,
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: "all",
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.getCuracaoInternalCAFDetails(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationErrorCuracao.forEach(async (element) => {
            if (element.chapter !== "chapter12") {
              let response =
                res?.data?.result?.cafInternalValidations[element.chapter];

              const newResponse = await Promise.resolve(
                getSequentialFields(response, element.chapter, true)
              );

              this.props.setCuracaoInternalChapterError({
                form: `formErrors${element.chapter}`,
                errors: newResponse?.errorChapter,
              });
            }
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;

    this.props.setCuracaoInternalCAFDetails(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.props.ClientAcceptance.chapter12["dirty"] = false;
        let response = res.data.result;

        const newResponse = await Promise.resolve(
          getSequentialFields(response, chapter, true)
        );
        this.props.setCuracaoInternalChapterError({
          errors: newResponse?.errorChapter,
          form: "formErrorschapter12",
        });
        chapValidationErrorCuracao.forEach((element) => {
          element.isvalid =
            this.props.chapterErrors[
              `formErrors${element.chapter}`
            ]?.errors?.isValid;
        });
        if (!res.data.result.isCAFValid) {
          this.setState({ isCAFValid: false });
          NotificationManager.error("Please fill all the details");
        } else {
          this.setState({ isCAFValid: true });

          NotificationManager.success("Form is submitted successfully.");

          return this.props.history.push({
            pathname: "/curacao/signature",
          });
        }
      }
    });
  };

  render() {
    const { internalErrors } = this.props;
    const formErrorsChapter12 = JSON.stringify(internalErrors.errors);
    const errorsMsg = JSON.parse(formErrorsChapter12);

    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="html2pdf__page-break"></div>
          <div className="cafHeader">
            <h2 className="m-0">Client Acceptance Form</h2>
          </div>
          <div className="internal_caf generic-form">
            <h3 className="chapterttl">Formal sign off</h3>
            <FormalQuestions
              reducer={reducer}
              chapter={chapter}
              errors={formErrorsChapter12}
              isRequired={true}
              PdfGeneration={this.props.PdfGeneration}
            />
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-4" id="dateStrtOfSrvcs">
                <DateTimePicker
                  info="Note that the services may only start at the registration date in the CoC and AFTER BAC approval"
                  label="Date of start of services"
                  chapter={chapter}
                  name="dateStrtOfSrvcs"
                  id="dateStrtOfSrvcs"
                  reducer={reducer}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.dateStrtOfSrvcs?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.dateStrtOfSrvcs}
                    </span>
                  )}
              </div>
            </Row>
            {/* <RisksSummary
              reducer={reducer}
              chapter={chapter}
              errors={formErrorsChapter12}
            /> */}
            {this.state.isCAFValid === false ? (
              <div className="internalErrorSection">
                <h3>
                  All the fields from the chapters are mandatory, please fill
                  the unfilled fields.
                </h3>
                {chapValidationErrorCuracao
                  .filter((element) => element.isvalid === false)
                  .map((element) => {
                    const res = getFirstUnfilledField(
                      this.props.chapterErrors[`formErrors${element.chapter}`]
                        .errors
                    );
                    return (
                      <HashLink to={`/curacao/${element.path}#${res}`}>
                        <i className="fa fa-arrow-circle-o-right fa-fw"></i>
                        {element.label} has unfilled fields
                      </HashLink>
                    );
                  })}
              </div>
            ) : null}
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ClientAcceptance: state.CuracaoInternalCAF,
    data: state,
    internalErrors: state.CuracaoInternalChapterErrors.formErrorschapter12,
    chapterErrors: state.CuracaoInternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCuracaoInternalCAFDetails: async (data) =>
      dispatch(await getCuracaoInternalCAFDetails(data)),
    setCuracaoInternalCAFDetails: async (data) =>
      dispatch(await setCuracaoInternalCAFDetails(data)),
    setCuracaoInternalChapterError: (data) =>
      dispatch(setCuracaoInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter12);
