import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../../elements/CheckBox";
import DropDown from "../../../../../elements/DropDown";
import { complianceList } from "./Constants";

class MaltaShareholder extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isShareholderUBO, errorMsg } = this.props;
    return (
      <>
        <h4 className="sub-chapter-title mb-4">
          Shareholder of the{" "}
          {this.props.form === "Malta" ? "Target Company" : "LuxCo"}
        </h4>
        <Row>
          <div className="mb-2 col-lg-4">
            <CheckBox
              label="Is the shareholder UBO?"
              options={["Yes", "No"]}
              name="isShareholderUBO"
              id="isShareholderUBO"
              reducer="commonFields"
              isCommon={true}
            />
            {errorMsg &&
              errorMsg?.isShareholderUBO &&
              errorMsg.isShareholderUBO?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errorMsg.isShareholderUBO}
                </span>
              )}
          </div>
          {isShareholderUBO === "No" && this.props.form !== "Malta" && (
            <div className="mb-2 col-lg-4">
              <DropDown
                label="Compliance list"
                name="complianceList"
                id="complianceList"
                options={complianceList}
                reducer="commonFields"
                isCommon={true}
              />
              {errorMsg &&
                errorMsg.complianceList &&
                errorMsg.complianceList?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errorMsg.complianceList}
                  </span>
                )}
            </div>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isShareholderUBO: state.commonFields["isShareholderUBO"],
  };
};

export default connect(mapStateToProps, null)(MaltaShareholder);
