import React, { Component } from "react";
import { Button } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { iconSuccess } from "../../../../assets/images";
import { connect } from "react-redux";
import { verifyUser, verifyUBO } from "../../../../redux/actions/userActions";
import Header from "../../../common/header";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import { authenticationUser } from "../../../../redux/actions/userActions";
import jwt_decode from "jwt-decode";

const formValid = (formErrors) => {
  let valid = true;
  Object.values(formErrors).forEach(function (val) {
    val.length > 0 && (valid = false);
  });

  return valid;
};
class authentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      isLoading: false,
      isFormShow: true,
      countTimer: 130,
      formErrors: {
        verificationCode: "",
        otherMessage: "",
      },
    };
  }

  handleChange = (otp) => {
    this.setState({ otp });
  };

  clearInputs = () => {
    this.setState({
      otp: "",
    });
  };
  redirect = (userInfo) => {
    // let userInfo = JSON.parse(localStorage.getItem("autheticate-token"));
    // localStorage.removeItem("autheticate-token");
    // localStorage.setItem("user", JSON.stringify(userInfo));
    if (userInfo.isTemporaryPassword) {
      const timer = setTimeout(() => {
        return this.props.history.push({
          pathname: "/changepassword",
        });
      }, 5000);
    } else {
      const timer = setTimeout(() => {
        return this.props.history.push({
          pathname: "/dashboard",
        });
      }, 5000);
    }
  };

  resendOTPCode = (e) => {
    this.setState({ isLoading: true });
    let fErrors = this.state.formErrors;
    let data = {
      otpMode: "SMS",
    };
    this.props.authenticationUser(data).then((res) => {
      this.setState({ isLoading: false });
      if (res.data.portalErrorCode === 200) {
        this.setState({
          countTimer: 130,
        });

        this.props.history.push({
          otpMode: "SMS",
        });
      } else if (!res.data.isError) {
        if (res.data.portalMessage === undefined) {
          fErrors.otherMessage = "Error occurred!";
        } else {
          fErrors.otherMessage = res.data.portalMessage;
        }
      } else {
        fErrors.otherMessage = "Error while authentication";
      }
      this.setState({ formErrors: fErrors });
    });
  };

  handleOTPSubmit = (e) => {
    let fErrors = this.state.formErrors;
    fErrors.otherMessage = "";
    fErrors.selectedRadio = "";
    this.setState({ formErrors: fErrors });

    e.preventDefault();
    fErrors.verificationCode =
      this.state.otp.length === 0 || this.state.otp.length < 4
        ? "Add valid OTP"
        : "";
    this.setState({
      formErrors: fErrors,
    });
    if (formValid(this.state.formErrors)) {
      this.setState({ isLoading: true });
      const { otpMode } = this.props.location;
      if (window.location.pathname === "/ubo-verify") {
        let data = {
          token: JSON.parse(localStorage.getItem("ubo-token")),
          otp: Number(this.state.otp),
        };

        this.props.verifyUBO(data).then((res) => {
          this.setState({ isLoading: false });
          if (res.data.portalErrorCode === 200) {
            localStorage.removeItem("ubo-token");
            localStorage.setItem(
              "ubo-form-token",
              JSON.stringify(res?.data?.result)
            );
            let decoded_token = jwt_decode(res?.data?.result);
            localStorage.setItem("Office", decoded_token.Office);

            this.clearInputs();
            NotificationManager.success("OTP is successfully verified");
            this.setState({
              isFormShow: !this.state.isFormShow,
              isSuccessMessage: !this.state.isSuccessMessage,
            });
            return this.props.history.push({
              pathname: "/declaration-form",
            });
          } else if (!res.data.isError) {
            this.clearInputs();
            if (res.data.portalMessage === undefined) {
              fErrors.otherMessage = "Enter valid OTP";
            } else {
              fErrors.otherMessage = "Enter valid OTP";
            }
          } else {
            fErrors.otherMessage = "Error while authentication";
          }
          this.setState({ formErrors: fErrors });
        });
      } else {
        let data = {
          otpMode: otpMode,
          otpCode: this.state.otp,
        };
        this.props.verifyUser(data).then((res) => {
          this.setState({ isLoading: false });
          if (res.data.portalErrorCode === 200) {
            this.clearInputs();
            NotificationManager.success("OTP is successfully verified");
            localStorage.setItem(
              "user",  
              JSON.stringify(res.data.result)
              );
            localStorage.removeItem("autheticate-token");
            this.setState({
              isFormShow: !this.state.isFormShow,
              isSuccessMessage: !this.state.isSuccessMessage,
            });
            this.redirect(res.data.result);
            
          } else if (!res.data.isError) {
            this.clearInputs();
            if (res.data.portalMessage === undefined) {
              fErrors.otherMessage = "Enter valid OTP";
            } else {
              fErrors.otherMessage = "Enter valid OTP";
            }
          } else {
            fErrors.otherMessage = "Error while authentication";
          }
          this.setState({ formErrors: fErrors });
        });
      }
    } else {
      console.log(`Form Invalid`);
    }
  };

  render() {
    const { isFormShow, formErrors } = this.state;
    const { otpMode } = this.props.location;

    return (
      <>
        <Header />
        <div className="loginBg pt-5">
          <div className="LoginWindow rounded pb-4 mt-5">
            {isFormShow ? (
              <>
                <h1 className="primary-heading mb-2">Verify</h1>
                <p className="mb-3">
                  Please enter a verification code which has been sent to your
                  phone or email.
                </p>
                {formErrors.verificationCode.length > 0 && (
                  <div class="alert">{formErrors.verificationCode}</div>
                )}
                {formErrors.otherMessage.length > 0 && (
                  <div class="alert">{formErrors.otherMessage}</div>
                )}
                {this.state.isLoading && <div className="loader"></div>}
                <div className="formGroup d-flex justify-content-center">
                  <OtpInput
                    value={this.state.otp}
                    onChange={this.handleChange}
                    numInputs={4}
                    isInputNum={true}
                    className="otpInputStyle"
                  />
                </div>
                {this.state.countTimer <= 0 && otpMode === "SMS" && (
                  <p className="text-center pt-4">
                    <Link to="/verify" className="link">
                      <span onClick={this.resendOTPCode}>Resend OTP</span>
                    </Link>
                  </p>
                )}
                {this.state.countTimer > 0 &&
                  this.state.countTimer <= 120 &&
                  otpMode === "SMS" && (
                    <p className="text-center pt-4">{this.state.countTimer}</p>
                  )}
                <Button
                  className="generalBtn mt-5 mb-2"
                  type="submit"
                  onClick={this.handleOTPSubmit}
                >
                  Proceed
                </Button>
                <p className="copyright">Copyright @ 2021</p>
              </>
            ) : (
              <div className="successMessage text-center">
                <img src={iconSuccess} alt="" />
                <p className="mt-4 success-message">
                  2 factor authentication completed successfully. <br />
                  Please wait for a moment, we are redirecting you to customer
                  portal.
                </p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
  componentDidMount() {
    this.myInterval = setInterval(() => {
      this.setState((prevState) => ({
        countTimer: prevState.countTimer - 1,
      }));
    }, 1000);
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    verifyUser: (data) => dispatch(verifyUser(data)),
    verifyUBO: (data) => dispatch(verifyUBO(data)),
    authenticationUser: (data) => dispatch(authenticationUser(data)),
  };
};
export default connect(null, mapDispatchToProps)(authentication);
