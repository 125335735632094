import React from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";
import {
  GetClientAcceptance,
  SetClientAcceptance,
} from "../../../../../redux/actions/ClientAcceptanceFormActions";
import "./../internalCAF.scss";
import { connect } from "react-redux";
import NotificationManager from "react-notifications/lib/NotificationManager";
import AutoSaving from "../AutoSaving";
import { setInternalChapterError } from "../../../../../redux/actions/InternalChapterErrorActions";
import getSequentialFields from "../getSequentialFields";
import { chapValidationError } from "../Constants";

const chapter = "chapter8";
const reducer = "ClientAcceptance";
let timer;
class Chapter8 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formErrorsChapter8: {},
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: chapter,
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.GetClientAcceptance(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          Object.keys(this.props.errors.errors).length === 0 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationError.forEach(async (element) => {
            if (element.chapter !== "chapter12") {
              let response =
                res?.data?.result?.cafInternalValidations[element.chapter];

              const newResponse = await Promise.resolve(
                getSequentialFields(response, element.chapter, false)
              );
              this.props.setInternalChapterError({
                form: `formErrors${element.chapter}`,
                errors: newResponse?.errorChapter,
              });
            }
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    if (timer) {
      clearTimeout(timer);
    }
  };
  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;
    requestData["commonFields"] = this.props.commonFields;

    this.props.SetClientAcceptance(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.props.ClientAcceptance.chapter8["dirty"] = false;
        let response = res.data.result;

        const newResponse = await Promise.resolve(
          getSequentialFields(response, chapter, true)
        );
        this.props.setInternalChapterError({
          form: "formErrorschapter8",
          errors: newResponse?.errorChapter,
        });
        if (!res.data.result.isValid) {
          NotificationManager.info(
            "All fields are mandatory to fill in",
            null,
            4000
          );
          timer = setTimeout(() => {
            return this.props.history.push({
              pathname: "/netherlands/correspondence",
            });
          }, 4000);
        } else {
          NotificationManager.success("Form is submitted successfully.");

          return this.props.history.push({
            pathname: "/netherlands/correspondence",
          });
        }
      }
    });
  };

  render() {
    const { errors } = this.props;
    const formErrorsChapter8 = JSON.stringify(errors.errors);
    const errorsMsg = JSON.parse(formErrorsChapter8);
    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="cafHeader">
            <h2 className="m-0">Client Acceptance Form</h2>
          </div>
          <div className="internal_caf generic-form">
            <div className="html2pdf__page-break"></div>
            <h3 className="chapterttl mb-3">7-Consolidated Risk Assessment</h3>
            <Row className="pt-0 pb-2">
              <div className="col-lg-12">
                <TextBox
                  label="Based on the individual risk ratings per tab, what is your view on the overall risk rating?"
                  placeholder="Enter your text"
                  name="overallRiskRating"
                  id="overallRiskRating"
                  chapter={chapter}
                  reducer={reducer}
                  isMultipleLines={true}
                  isRequired={false}
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtArea"
                      : "form-control"
                  }
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.overallRiskRating?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.overallRiskRating}
                    </span>
                  )}
                <div className="errorMessage"></div>
              </div>
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ClientAcceptance: state.ClientAcceptance,
    data: state,
    errors: state.InternalChapterErrors.formErrorschapter8,
    commonFields: state.commonFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetClientAcceptance: async (data) =>
      dispatch(await GetClientAcceptance(data)),
    SetClientAcceptance: async (data) =>
      dispatch(await SetClientAcceptance(data)),
    setInternalChapterError: (data) => dispatch(setInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter8);
