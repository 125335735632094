import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import countryList from "react-select-country-list";
import FileUpload from "../../../../../elements/FileUpload";
import CheckBox from "../../../../../elements/CheckBox";
import DropDown from "../../../../../elements/DropDown";
import PeerEntities from "./PeerEntities";
import { fileAcceptType } from "./Constants";
import NumberInput from "../../../../../elements/NumberInput";
import { Entities } from "../../../../../JSONs/ClientInformationForm/Chapter1";
import MainActivities from "./MainActivities";
import NewClient from "./NewClient";
import { UKEntities } from "../../../../../JSONs/UK-Form/External/Client-Information/Chapter1";
import { checkIsCommon } from "../CommonFunctions";
import BusinessIntroduction from "../Chapter4/BusinessIntroduction";
const chapter = "chapter1";
class Chapter1 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { noOfEntities, isCompIncorporated, errorMsg,errorMsgCh4 } = this.props;
    let entities = [];
      let eMsg = JSON.parse(errorMsg);
     for (let i = 0; i < noOfEntities; i++) {
      entities.push(
        <PeerEntities
          index={i}
          form={this.props.form}
          baseName="entities"
          reducer={this.props.reducer}
          errorMsg={eMsg}
        />
      );
    }

    return (
      <div className="generic-form">
        <div className="eventHeader pt-0 pb-3 mb-4">
          <Row>
            <h2 className="mb-0 col-md-6 pagetitle">
              {this.props.form === "Malta"
                ? "Target Company Information"
                : "Company Information"}
            </h2>
            <div className="mb-0 col-md-6 text-right">
              <div className="infotext">
                <i className="fa fa-info-circle ml-2 animation"></i> Form will
                be Autosave
              </div>
            </div>
          </Row>
        </div>

        <h5 className="mb-0  mt-2">
          {this.props.form === "Malta"  && ("Target Company Activity")}
          {this.props.form !== "Malta"  && this.props.form !== "UK"  && ("1- Client Activity")}
         
        </h5>
        {this.props.form === "UK" && (
        <BusinessIntroduction
              reducer={this.props.reducer}
              chapter="chapter4"
              objBaseName="businessIntroduction"
              errorMsg={errorMsgCh4}
              form={this.props.form}
            />
           )}
           {this.props.form === "UK"  && (
        <h4 className="sub-chapter-title mb-4">
       Client Activity
        </h4>)}
        <Row className="pt-3 pb-0">
          <div className="mb-2 col-lg-4">
            <FileUpload
              label="Upload structure chart"
              info="Upload organization structure chart or Create from Structure Chart Configuration section"
              acceptType={fileAcceptType}
              id="structureChart"
              name="structureChart"
              chapter={chapter}
              reducer={this.props.reducer} 
              isRequired={this.props.form === "UK" ? true : false}            
            />
            {eMsg.structureChart?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.structureChart}
              </span>
            )}
          </div>
          {this.props.form === "UK" && (
            <div className="mb-2 col-lg-4">
              <DropDown
                label="Entity Jurisdiction"
                options={countryList().getData()}
                name="entityJurisdiction"
                id="entityJurisdiction"
                chapter={chapter}
                reducer={this.props.reducer}
                isRequired={this.props.form === "UK" ? true : false}
              />
              {eMsg.entityJurisdiction?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {eMsg.entityJurisdiction}
                </span>
              )}
            </div>
          )}

          {(this.props.from === "NL" ||
            this.props.form === "Curacao" ||
            this.props.form === "Malta" ||
            this.props.form === "Luxembourg") && (
            <div className="mb-2 col-lg-4">
               {isCompIncorporated === "Yes" &&(
              <NumberInput
                label="How many entities are in the uploaded chart?"
                name="noOfEntities"
                chapter={chapter}
                referral={["entities"]}
                pushedObject={[Entities]}
                maxLimit={100}
                reducer={this.props.reducer}
              />
               )}
            </div>
          )}
          {/* {this.props.from === "UK" && (
            <div className="mb-2 col-lg-4">
              <NumberInput
                label="How many entities are in the uploaded chart?"
                name="noOfEntities"
                chapter={chapter}
                referral={["entities"]}
                pushedObject={[UKEntities]}
                maxLimit={100}
                reducer={this.props.reducer}
              />
              <div className="errorMsg"></div>
            </div>
          )} */}
        </Row>
        <Row className="pt-3 pb-0">
          <div className="mb-2 col-lg-4">
            <CheckBox
              label={this.props.from === "UK" || this.props.from === "NL" ? "Is object company already incorporated?":"Is Target company already incorporated?"}
              options={["Yes", "No"]}
              name="isCompIncorporated"
              id="isCompIncorporated"
              chapter={chapter}
              reducer={this.props.reducer}
              isRequired={this.props.form === "UK" ? true : false}       
            />
            {eMsg.isCompIncorporated?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.isCompIncorporated}
              </span>
            )}
          </div>
          {this.props.form !== "UK" && (
          <div className="mb-2 col-lg-8">
            {isCompIncorporated === "Yes" && entities}
          </div>)}
        </Row>
        {(this.props.form === "Malta" || this.props.form === "Luxembourg") && (
          <NewClient
            chapter={chapter}
            reducer={this.props.reducer}
            errorMsg={eMsg}
            form={this.props.form}
          />
        )}
        <Row className="pt-3 pb-0">
          <div className="mb-2 col-lg-12">
            <MainActivities
              chapter={chapter}
              reducer={
                checkIsCommon(this.props.form)
                  ? "commonFields"
                  : this.props.reducer
              }
              form={this.props.form}
              errorMsg={eMsg}
              from="clientInformation"
            />
          </div>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer } = ownProps;
  return {
    noOfEntities: state[reducer]["chapter1"]["noOfEntities"],
    isCompIncorporated: state[reducer]["chapter1"]["isCompIncorporated"],
  };
};

// export default Chapter1;
export default connect(mapStateToProps, null)(Chapter1);
