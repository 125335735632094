export const getFirstUnfilledField = (formErrors) => {
  let name = [];
  Object.entries(formErrors).forEach(function (val) {
    const [key, value] = val;
    if (Array.isArray(value)) {
      value.forEach(function (errors, index) {
        Object.entries(errors).forEach(function (val) {
          const [valkey, valError] = val;
          if (valError.length > 0) {
            name.push(`${valkey}[${index}]`);
          }
        });
      });
    } else if (
      typeof value === "object" &&
      !Array.isArray(value) &&
      value !== null
    ) {
      Object.entries(value).forEach(function (val) {
        const valKey = val[0];
        const valError = val[1];

        if (valError.length > 0) {
          name.push(valKey);
        }
      });
    } else {
      const valKey = val[0];
      const valError = val[1];
      if (valError.length > 0) {
        name.push(valKey);
      }
    }
  });

  if (name.length > 0) {
    return name[0];
  } else {
    return null;
  }
};
