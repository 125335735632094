import { CustActivityRisks } from "../../InternalUser/Chapter1";

const NonRecFees = {
  currency: null,
  incrtnFees: null,
  comIncrtn: null,
  shelfCompany: null,
  others: null,
  feesTrfrCom: null,
};

const AnnualFees = {
  currency: null,
  firstYearOfc: null,
  rentOfcSpc: null,
  firstYearMngt: null,
  firstYearAdmnst: null,
  taxDeclaration: null,
  directorFees: null,
  isOtherFees: null,
  otherFeesCmnt: null,
};
export const Chapter6 = {
  isDomiciliationSvc: null,
  isManagementSvc: null,
  isAdministrationSvc: null,
  isUIMPrvdOthSvc: null,
  explanationOthSvc: null,
  isLeaseAgmntOfcSpace: null,
  isDomicilliationAgmnt: null,
  isAdministrationAgmnt: null,
  isPrinciplePartyAgmnt: null,
  isManagementAgmnt: null,
  isOtherAgmnt: null,
  reportWorldCheckExtDir: [],
  nonRecurringFees: NonRecFees,
  annualFees: AnnualFees,
  findingsWorldCheck: null,
  isRisk: null,
  numCustActivityRisks: 1,
  custActivityRisks: [CustActivityRisks],
  custActvtyRskRtng: null,
  descActnNeedsTkn: null,
  isUnacceptableRisksIdentified: null,
  unacceptableRisksReason: null,
  dirty: false,
};
