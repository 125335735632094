import React, { Component } from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";
import DropDown from "../../../../elements/DropDown";
import CheckBox from "../../../../elements/CheckBox";
import { riskLevel, riskType } from "../Constants";

class ActivityRisks extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, chapter, reducer, errorsMsg, isRequired } = this.props;

    let errors = JSON.parse(errorsMsg);

    return (
      <>
        <div className="mb-4 col-lg-12">
          <fieldset>
            <legend>
              {this.props.boxLabel} {index + 1}
            </legend>
            <Row>
              <div className="col-lg-12 mb-3">
                <TextBox
                  label={
                    this.props.form === "Malta" && chapter === "chapter2"
                      ? "Intermediary / Agent activity (Risk)"
                      : "Customer activity (Risk)"
                  }
                  chapter={chapter}
                  index={index}
                  placeholder="Enter risk name"
                  name="risk"
                  id={`risk[${index}]`}
                  reducer={reducer}
                  isRequired={isRequired}
                  baseName={this.props.baseName}
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                  isMultipleLines={true}
                />

                {errors &&
                  errors.isSubmitted === true &&
                  errors.custActivityRisks &&
                  errors.custActivityRisks[index]?.risk?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors.custActivityRisks[index].risk}
                    </span>
                  )}
              </div>
            </Row>
            <Row>
              <div className="col-lg-6  mb-3">
                <DropDown
                  label="Risk Type"
                  chapter={chapter}
                  options={riskType}
                  index={index}
                  name="riskType"
                  id={`riskType[${index}]`}
                  reducer={reducer}
                  isRequired={isRequired}
                  baseName={this.props.baseName}
                />
                {errors &&
                  errors.isSubmitted === true &&
                  errors.custActivityRisks &&
                  errors.custActivityRisks[index]?.riskType?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors.custActivityRisks[index].riskType}
                    </span>
                  )}
              </div>
              <div className="col-lg-6 mb-3">
                <DropDown
                  label="Risk Level"
                  chapter={chapter}
                  options={riskLevel}
                  index={index}
                  name="riskLevel"
                  id={`riskLevel[${index}]`}
                  reducer={reducer}
                  isRequired={isRequired}
                  baseName={this.props.baseName}
                />
                {errors &&
                  errors.isSubmitted === true &&
                  errors.custActivityRisks &&
                  errors.custActivityRisks[index]?.riskLevel?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors.custActivityRisks[index].riskLevel}
                    </span>
                  )}
              </div>
            </Row>
            <Row>
              <div className="col-lg-12 mb-3">
                <TextBox
                  label="Preventive/ Mitigating measures"
                  chapter={chapter}
                  index={index}
                  placeholder="Enter your text"
                  name="preventiveMeasure"
                  id={`preventiveMeasure[${index}]`}
                  reducer={reducer}
                  isRequired={isRequired}
                  baseName={this.props.baseName}
                  isMultipleLines={true}
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
                {errors &&
                  errors.isSubmitted === true &&
                  errors.custActivityRisks &&
                  errors.custActivityRisks[index]?.preventiveMeasure?.length >
                    0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors.custActivityRisks[index].preventiveMeasure}
                    </span>
                  )}
              </div>
            </Row>
            {this.props.form !== "Malta" && (
              <Row>
                <div className="col-lg-12">
                  <CheckBox
                    label="Did the measures result in a lower rating of risk?"
                    chapter={chapter}
                    options={["Yes", "No"]}
                    index={index}
                    name="isMeasrResLowRtngRsk"
                    id={`isMeasrResLowRtngRsk[${index}]`}
                    reducer={reducer}
                    isRequired={isRequired}
                    baseName={this.props.baseName}
                    isMultiple={true}
                  />
                  {errors &&
                    errors.isSubmitted === true &&
                    errors.custActivityRisks &&
                    errors?.custActivityRisks[index]?.isMeasrResLowRtngRsk
                      ?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.custActivityRisks[index].isMeasrResLowRtngRsk}
                      </span>
                    )}
                </div>
              </Row>
            )}
          </fieldset>
        </div>
        {/* <div className="html2pdf__page-break"></div> // REMOVED BREAK for Malta Internal PDF Extra space */}
      </>
    );
  }
}

export default ActivityRisks;
