import React, { Component } from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";

class SourceOfFunds extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <h4 className="sub-chapter-title mb-4">Source of Funds</h4>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Bank Name"
              placeholder="Enter bank name"
              name="bankName"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={this.props.index}
              baseName={this.props.baseName}
              objBaseName={this.props.objBaseName}
              validationType="Text"
              disabled={true}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Location"
              placeholder="Enter location"
              name="location"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={this.props.index}
              baseName={this.props.baseName}
              objBaseName={this.props.objBaseName}
              validationType="Text"
              disabled={true}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Remitter Name"
              placeholder="Enter remitter name"
              name="remitterName"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={this.props.index}
              baseName={this.props.baseName}
              objBaseName={this.props.objBaseName}
              validationType="Text"
              disabled={true}
            />
          </div>
          <div className="mb-3 col-lg-12">
            <TextBox
              label="Address"
              placeholder="Enter address"
              name="address"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={this.props.index}
              disabled={true}
              baseName={this.props.baseName}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
          </div>
        </Row>
      </>
    );
  }
}

export default SourceOfFunds;
