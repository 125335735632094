import React, { Component } from "react";
import {
  setGenericDetails,
  getGenericDetails,
  updateClientInformation,
  setNewGenericDataToReducer,
} from "../../../../../redux/actions/CAFGenericActions";
import {
  setCommonFields,
  setNewCommonFieldsToReducer,
} from "../../../../../redux/actions/CommonFieldsActions";
import { connect } from "react-redux";

class AutoSaving extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  apiCall = () => {
    //api call for chapters data

    let apiCallFlag = false;
    let data = this.props.genericDetails;

    if (data["dirty"] === true) {
      apiCallFlag = true;
      delete data["dirty"];
    }
    let requestData = {
      Id: localStorage.getItem("formId"),
      CompanyId: localStorage.getItem("clientId"),
      office: localStorage.getItem("Office"),
      GenericDetails: data,
    };
    if (apiCallFlag) {
      this.props.setGenericDetails(requestData).then((res) => {
        if (res.data.portalErrorCode === 200) {
          let details = {
            Id: localStorage.getItem("formId"),
            lastActivityName: "generic-form",
            status: "In Progress",
            activeLinkLevel: 0,
          };
          this.props.updateClientInformation(details);
          data["dirty"] = false;
          this.props.setNewGenericDataToReducer(data);
        }
      });
    }

    //api call for common fields
    requestData = this.props.commonFields;
    apiCallFlag = false;

    if (requestData["dirty"] === true) {
      apiCallFlag = true;
      delete requestData["dirty"];
    }
    requestData["Id"] = localStorage.getItem("formId");
    requestData["isGeneric"] = true;
    if (apiCallFlag) {
      this.props.setCommonFields(requestData);
    }
  };

  componentDidMount = () => {
    this.apiCall();
    setInterval(this.apiCall, 5000);
  };

  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    genericDetails: state.genericInformation.generic,
    commonFields: state.commonFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGenericDetails: (data) => dispatch(setGenericDetails(data)),
    getGenericDetails: (data) => dispatch(getGenericDetails(data)),
    updateClientInformation: (data) => dispatch(updateClientInformation(data)),
    setCommonFields: (data) => dispatch(setCommonFields(data)),
    setNewCommonFieldsToReducer: (data) =>
      dispatch(setNewCommonFieldsToReducer(data)),
    setNewGenericDataToReducer: (data) =>
      dispatch(setNewGenericDataToReducer(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AutoSaving);
