import React from "react";
import { Row } from "react-bootstrap";
import CheckBox from "../../../../elements/CheckBox";
import TextBox from "../../../../elements/TextBox";

class FormalQuestions extends React.Component {
  render() {
    const { reducer, chapter, errors, isRequired } = this.props;
    const errorsMsg = JSON.parse(errors);

    let formData = JSON.parse(localStorage.getItem("formData"));
    return (
      <>
        <Row className="pt-3 pb-2">
          <div className="mb-2 col-lg-4">
            <CheckBox
              label={
                this.props.form === "Malta"
                  ? "Are there (legal) reasons for a further client investigation?"
                  : "Are there (legal) reasons for a tighter client investigation?"
              }
              options={["Yes", "No"]}
              name="isReasonTigCliInvstg"
              id="isReasonTigCliInvstg"
              reducer={reducer}
              chapter={chapter}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isReasonTigCliInvstg?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isReasonTigCliInvstg}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-8">
            <TextBox
              label="Describe the reserves of the Business Acceptance Committee"
              placeholder="Enter the text"
              name="reserveBusAccepComite"
              id="reserveBusAccepComite"
              reducer={reducer}
              isRequired={isRequired}
              chapter={chapter}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.reserveBusAccepComite?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.reserveBusAccepComite}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-3 pb-2">
          <div className="mb-2 col-lg-4">
            <CheckBox
              label={
                this.props.form === "Malta"
                  ? "Did the Business Acceptance Committee accept the client?"
                  : "Did the Business Acceptance Committee accept the client? If yes were there any reserves?"
              }
              options={["Yes", "No"]}
              name="isBusAccepAccClnt"
              id="isBusAccepAccClnt"
              reducer={reducer}
              isRequired={isRequired}
              chapter={chapter}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isBusAccepAccClnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isBusAccepAccClnt}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-8">
            <TextBox
              label="Description of the reasons for an enhanced due diligence."
              placeholder="Enter the text"
              name="descReasDueDilignce"
              id="descReasDueDilignce"
              reducer={reducer}
              isRequired={isRequired}
              chapter={chapter}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.descReasDueDilignce?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.descReasDueDilignce}
                </span>
              )}
          </div>
        </Row>
        {this.props.form === "Malta" && (
          <Row className="pt-3 pb-2">
            <div className="mb-2 col-lg-4">
              <CheckBox
                label={
                  "Is there any suspicion of ML/FT which needs to be reported to the MLRO?"
                }
                options={["Yes", "No"]}
                name="isMLRO"
                id="isMLRO"
                reducer={reducer}
                chapter={chapter}
              />
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.isMLRO?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg.isMLRO}
                  </span>
                )}
            </div>
            <div className="mb-2 col-lg-8">
              <TextBox
                label="Description"
                placeholder="Enter the text"
                name="mlroComment"
                id="mlroComment"
                reducer={reducer}
                isRequired={isRequired}
                chapter={chapter}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.descReasDueDilignce?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg.descReasDueDilignce}
                  </span>
                )}
            </div>
          </Row>
        )}
        <Row className="pt-3 pb-2">
          <div className="mb-2 col-lg-4">
            <CheckBox
              label="Structural overview is clear and substantiated?"
              options={["Yes", "No"]}
              name="isstructOvrvwClr"
              id="isstructOvrvwClr"
              reducer={reducer}
              isRequired={isRequired}
              chapter={chapter}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isstructOvrvwClr?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isstructOvrvwClr}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4">
            <CheckBox
              label={
                this.props.form === "Malta"
                  ? "Related parties are identified and verified"
                  : "Clients are identified and verified?"
              }
              options={["Yes", "No"]}
              name="isclientsIdntVrfd"
              id="isclientsIdntVrfd"
              reducer={reducer}
              isRequired={isRequired}
              chapter={chapter}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isclientsIdntVrfd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isclientsIdntVrfd}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4">
            <CheckBox
              label="UBO classification, identification and verification completed?"
              options={["Yes", "No"]}
              name="isuboClssfctnIdntVrfctn"
              id="isuboClssfctnIdntVrfctn"
              reducer={reducer}
              isRequired={isRequired}
              chapter={chapter}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isuboClssfctnIdntVrfctn?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isuboClssfctnIdntVrfctn}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4">
            <CheckBox
              label="Source of wealth is clear?"
              options={["Yes", "No"]}
              name="issrcWlthClr"
              id="issrcWlthClr"
              reducer={reducer}
              isRequired={isRequired}
              chapter={chapter}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.issrcWlthClr?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.issrcWlthClr}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4">
            <CheckBox
              label="Check PEP?"
              options={["Yes", "No"]}
              name="ischckPEP"
              id="ischckPEP"
              reducer={reducer}
              isRequired={isRequired}
              chapter={chapter}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.ischckPEP?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.ischckPEP}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4">
            <CheckBox
              label={
                this.props.form === "Malta"
                  ? "Object and purpose Target Company is clear?"
                  : "Object and purpose Object Company is clear?"
              }
              options={["Yes", "No"]}
              name="isobjPurObjCompClr"
              id="isobjPurObjCompClr"
              reducer={reducer}
              isRequired={isRequired}
              chapter={chapter}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isobjPurObjCompClr?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isobjPurObjCompClr}
                </span>
              )}
          </div>
          <div className="html2pdf__page-break"></div>
          <div className="mb-2 col-lg-4">
            <CheckBox
              label="Transaction profile (source of funds) is clear?"
              options={["Yes", "No"]}
              name="isTrnsPrflClr"
              id="isTrnsPrflClr"
              reducer={reducer}
              chapter={chapter}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isTrnsPrflClr?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isTrnsPrflClr}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4">
            <CheckBox
              label={`Purpose of structuring in the ${formData.office} known and recorded?`}
              options={["Yes", "No"]}
              name="isPurStrctrngKnwRecd"
              id="isPurStrctrngKnwRecd"
              reducer={reducer}
              isRequired={isRequired}
              chapter={chapter}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isPurStrctrngKnwRecd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isPurStrctrngKnwRecd}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4">
            <CheckBox
              label="Sanction rules checked?"
              options={["Yes", "No"]}
              name="isSancRulChckd"
              id="isSancRulChckd"
              reducer={reducer}
              isRequired={isRequired}
              chapter={chapter}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isSancRulChckd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isSancRulChckd}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4">
            <CheckBox
              label="Is all-relevant correspondence added to the client file with respect to the client acceptance?"
              options={["Yes", "No"]}
              name="isRelCorrespndceAdded"
              id="isRelCorrespndceAdded"
              isRequired={isRequired}
              reducer={reducer}
              chapter={chapter}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isRelCorrespndceAdded?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isRelCorrespndceAdded}
                </span>
              )}
          </div>
        </Row>
      </>
    );
  }
}

export default FormalQuestions;
