import React from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";
import {
  setMaltaInternalCAFDetails,
  getMaltaInternalCAFDetails,
} from "../../../../../redux/actions/Malta/MaltaInternalCAFActions";
import "../../Onboarding/internalCAF.scss";
import AutoSaving from "../AutoSaving";
import { connect } from "react-redux";
import NotificationManager from "react-notifications/lib/NotificationManager";
import DownloadFile from "../../../../elements/DownloadFile";
import CheckBox from "../../../../elements/CheckBox";
import DateTimePicker from "../../../../elements/DateTimePicker";
import MediaCoverage from "../../Onboarding/Chapter1/MediaCoverage";
import Risks from "../../Onboarding/Chapter1/Risks";
import OCExists from "../../Onboarding/Chapter4/OCExists";
import Directors from "../../Onboarding/Chapter4/Directors";
import OCSecretary from "./OCSecretary";
import ContactPerson from "./ContactPerson";
import ObjectCompany from "../../Onboarding/Chapter4/ObjectCompany";
import Rationale from "../../../external/OnBoarding/client-information/Chapter4/Rationale";
import DOC from "../../Onboarding/Chapter4/DOC";
import ShareCapital from "./ShareCapital";
import SourceOfFundsOC from "../../Onboarding/Chapter4/SourceOfFundsOC";
import ServicesProvidedMalta from "./ServicesProvidedMalta";
import Situation from "./Situation";
import Documentation from "./Documentation";
import BankAccntDetails from "../../Onboarding/Chapter4/BankAccntDetails";
import { setMaltaInternalChapterError } from "../../../../../redux/actions/Malta/MaltaInternalChapterErrorActions";
import ServiceProviderMalta from "./ServiceProviderMalta";
import getSequentialFields from "../getSequentialFields";
import { chapValidationErrorMalta } from "../Constants";

const chapter = "chapter4";
const reducer = "MaltaInternalCAF";
const externalReducer = "MaltaClientInformation";

let timer;

class Chapter4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formErrorsChapter4: {},
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: "all",
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.getMaltaInternalCAFDetails(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          Object.keys(this.props.internalErrors.errors).length === 0 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationErrorMalta.forEach(async (element) => {
            let response =
              res?.data?.result?.cafInternalValidations[element.chapter];

            const newResponse = await Promise.resolve(
              getSequentialFields(response, element.chapter, false)
            );
            this.props.setMaltaInternalChapterError({
              form: `formErrors${element.chapter}`,
              errors: newResponse?.errorChapter,
            });
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;
    requestData["commonFields"] = this.props.commonFields;
    requestData["cafExternalChap4"] = this.props.chapter4;

    this.props.setMaltaInternalCAFDetails(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.props.ClientAcceptance.chapter4["dirty"] = false;
        let response = res.data.result;

        const newResponse = await Promise.resolve(
          getSequentialFields(response, chapter, true)
        );
        this.props.setMaltaInternalChapterError({
          errors: newResponse?.errorChapter,
          form: "formErrorschapter4",
        });
        if (!res.data.result.isValid) {
          NotificationManager.info(
            "All fields are mandatory to fill in",
            null,
            4000
          );
          timer = setTimeout(() => {
            return this.props.history.push({
              pathname: "/malta/country-sanction-risks",
            });
          }, 4000);
        } else {
          NotificationManager.success("Form is submitted successfully.");

          return this.props.history.push({
            pathname: "/malta/country-sanction-risks",
          });
        }
      }
    });
  };

  render() {
    const { chapter4, objCompIncrdIntgtyRsk, internalErrors } = this.props;
    const formErrorsChapter4 = JSON.stringify(internalErrors.errors);
    const errorsMsg = JSON.parse(formErrorsChapter4);

    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="cafHeader">
            <h2 className="m-0">Business Acceptance Form </h2>
          </div>
          <div className="internal_caf generic-form">
            <div className="html2pdf__page-break"></div>
            <h3 className="chapterttl">
              Target Company Information - Object of the Target Company
            </h3>
            <OCExists
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              form="Malta"
              isRequired={true}
              PdfGeneration={this.props.PdfGeneration}
            />
            <Directors
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              form="Malta"
              isRequired={true}
              PdfGeneration={this.props.PdfGeneration}
            />
            {/* <div className="html2pdf__page-break"></div> */}
            <OCSecretary
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              form="Malta"
              isRequired={true}
              PdfGeneration={this.props.PdfGeneration}
            />
            <Row className="pt-3 pb-2">
              <div className="mb-3 col-lg-4">
                <CheckBox
                  label="Accounting year ends at 31st December. Do you want to change?"
                  options={["Yes", "No"]}
                  name="isAcctngYrChng"
                  id="isAcctngYrChng"
                  chapter={chapter}
                  reducer={externalReducer}
                  disabled={true}
                />
              </div>
              <div className="mb-3 col-lg-8">
                {chapter4.isAcctngYrChng === "Yes" && (
                  <>
                    <Row>
                      <div className="mb-3 col-lg-6" id={`acctngDtChng`}>
                        <DateTimePicker
                          label="Select different date"
                          name="acctngDtChng"
                          chapter={chapter}
                          reducer={externalReducer}
                          disabled={true}
                        />
                      </div>
                      <div className="mb-3 col-lg-6">
                        <DownloadFile
                          label="Upload anticipated annual turnover, Financial statement of past 3 year"
                          value={chapter4.antcptAnnTurnFinStmts}
                        />
                      </div>
                    </Row>
                  </>
                )}
              </div>
              <div className="mb-3 col-lg-4">&nbsp;</div>
              <div className="mb-3 col-lg-4">
                <TextBox
                  label="Please enter comment"
                  placeholder="Enter your text"
                  name="accntngYrCmmnt"
                  id="accntngYrCmmnt"
                  reducer={reducer}
                  chapter="chapter4"
                  validationType="Text"
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
                {errorsMsg &&
                  errorsMsg?.accntngYrCmmnt &&
                  errorsMsg?.accntngYrCmmnt?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg?.accntngYrCmmnt}
                    </span>
                  )}
              </div>
            </Row>
            <ContactPerson
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              form="Malta"
              errors={formErrorsChapter4}
              PdfGeneration={this.props.PdfGeneration}
            />
            <ObjectCompany
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              form="Malta"
              isRequired={true}
              PdfGeneration={this.props.PdfGeneration}
            />
            <Rationale
              reducer="commonFields"
              errorMsg={formErrorsChapter4}
              from="internal"
              form="Malta"
              PdfGeneration={this.props.PdfGeneration}
            />
            <div className="mb-2 col-lg-12 pl-0">
              <TextBox
                label="Please add comment"
                placeholder="Enter your text"
                name="rationaleStrctrngForNLCmmnt"
                id="rationaleStrctrngForNLCmmnt"
                reducer={reducer}
                chapter="chapter4"
                isRequired={true}
                validationType="Text"
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.rationaleStrctrngForNLCmmnt?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg.rationaleStrctrngForNLCmmnt}
                  </span>
                )}
            </div>
            {/* <div className="html2pdf__page-break"></div> */}
            <Row className="pt-3 pb-2">
              <div className="mb-3 col-lg-4">
                <DownloadFile
                  label="Include the findings of the independent tax advisor or legal advisor."
                  value={chapter4.taxAdvisorFinds}
                />
              </div>
              <div className="mb-3 col-lg-4">
                <CheckBox
                  label="Is approved?"
                  options={["Yes", "No"]}
                  name="findingsIndpTaxAdvisorApprvd"
                  id="findingsIndpTaxAdvisorApprvd"
                  chapter="chapter4"
                  reducer={reducer}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.findingsIndpTaxAdvisorApprvd?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.findingsIndpTaxAdvisorApprvd}
                    </span>
                  )}
              </div>
            </Row>
            <ShareCapital
              chapter="chapter4"
              reducer={reducer}
              externalReducer={externalReducer}
              errors={formErrorsChapter4}
              isRequired={false}
              PdfGeneration={this.props.PdfGeneration}
            />
            {/* <div className="html2pdf__page-break"></div> */}
            <ServicesProvidedMalta
              from="Malta"
              form="Malta"
              chapter="chapter4"
              reducer={reducer}
              externalReducer={externalReducer}
              errors={formErrorsChapter4}
              isRequired={false}
              PdfGeneration={this.props.PdfGeneration}
            />
            <div className="html2pdf__page-break"></div>
            <ServiceProviderMalta
              chapter="chapter4"
              reducer={reducer}
              externalReducer={externalReducer}
              errors={formErrorsChapter4}
              form="Malta"
              isRequired={false}
              PdfGeneration={this.props.PdfGeneration}
            />
            <Situation
              chapter="chapter4"
              reducer={reducer}
              externalReducer={externalReducer}
              errors={formErrorsChapter4}
              isRequired={false}
              PdfGeneration={this.props.PdfGeneration}
              form="Malta"
            />
            <Documentation
              chapter="chapter4"
              reducer={reducer}
              externalReducer={externalReducer}
              errors={formErrorsChapter4}
              isRequired={false}
            />
            <div className="html2pdf__page-break"></div>
            <DOC
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              form="Malta"
              isRequired={true}
              PdfGeneration={this.props.PdfGeneration}
            />
            <SourceOfFundsOC
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              isRequired={true}
              form="Malta"
              PdfGeneration={this.props.PdfGeneration}
            />
            {/* <div className="html2pdf__page-break"></div> */}
            <BankAccntDetails
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              form="Malta"
              isRequired={true}
              PdfGeneration={this.props.PdfGeneration}
            />
            <div className="html2pdf__page-break"></div>
            <MediaCoverage
              chapter={chapter}
              reducer={reducer}
              errors={formErrorsChapter4}
              form="Malta"
              PdfGeneration={this.props.PdfGeneration}
            />
            {/* <div className="html2pdf__page-break"></div> */}
            <Risks
              chapter={chapter}
              reducer={reducer}
              errors={formErrorsChapter4}
              form="Malta"
              riskLabel="Object Company and Transaction Profile risk rating"
              questionLabel="How many OC/TP related risks can be identified?"
              boxLabel="Risk"
              isRequired={true}
              PdfGeneration={this.props.PdfGeneration}
            />
            {/* <div className="html2pdf__page-break"></div> */}
            <Row className="mb-3">
              <div className="col-lg-12">
                If there are integrity risks about the purpose, nature and
                reason for structuring via{" "}
                {JSON.parse(localStorage.getItem("formData")).office} of the
                target company (e.g. Aggressive tax planning, BEPS) and explain
                if there are integrity risks with respect to the transaction
                profile and the rights and obligations of the object company.
              </div>
            </Row>
            <Row className="mb-3">
              <div className="col-lg-12">
                <CheckBox
                  label="In addition, report whether the OC/Transaction Profile criteria result in a potentially increased integrity risk."
                  options={["Yes", "No"]}
                  name="objCompIncrdIntgtyRsk"
                  id="objCompIncrdIntgtyRsk"
                  chapter={chapter}
                  reducer={reducer}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.objCompIncrdIntgtyRsk?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.objCompIncrdIntgtyRsk}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="mb-3">
              {objCompIncrdIntgtyRsk === "Yes" && (
                <div className="col-lg-12">
                  <TextBox
                    label="Please describe"
                    placeholder="Enter your text"
                    name="objCompIncrdIntgtyRskText"
                    id="objCompIncrdIntgtyRskText"
                    chapter={chapter}
                    reducer={reducer}
                    isMultipleLines={true}
                    isRequired={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtArea"
                        : "form-control"
                    }
                  />
                  {errorsMsg &&
                    errorsMsg?.isSubmitted === true &&
                    errorsMsg?.objCompIncrdIntgtyRskText?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.objCompIncrdIntgtyRskText}
                      </span>
                    )}
                </div>
              )}
              {objCompIncrdIntgtyRsk === "No" && (
                <div className="col-md-12">
                  <b>
                    With respect to the OC/Transaction profile information, UIM
                    sees no reason to discontinue the set-up of a business
                    relationship.
                  </b>
                </div>
              )}
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    chapter4: state.MaltaClientInformation.chapter4,
    objCompIncrdIntgtyRsk:
      state.MaltaInternalCAF.chapter4["objCompIncrdIntgtyRsk"],

    ClientAcceptance: state.MaltaInternalCAF,
    commonFields: state.commonFields,
    data: state,
    internalErrors: state.MaltaInternalChapterErrors.formErrorschapter4,
    errors: state.MaltaInternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMaltaInternalCAFDetails: async (data) =>
      dispatch(await getMaltaInternalCAFDetails(data)),
    setMaltaInternalCAFDetails: async (data) =>
      dispatch(await setMaltaInternalCAFDetails(data)),
    setMaltaInternalChapterError: (data) =>
      dispatch(setMaltaInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter4);
