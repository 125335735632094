import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../../elements/CheckBox";
import TextBox from "../../../../../elements/TextBox";

class GrossAnnualIncome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { grossAnnualIncome } = this.props;
    return (
      <>
        <Row className="mt-3 mb-0">
          {/* <h4 className="sub-chapter-title ">
            What is your gross annual Income
          </h4> */}
          <div className="mb-2 col-lg-12 rangeCheckbox">
            <CheckBox
              label="What is your gross annual Income (please tick)"
              options={["< EUR 500.000", "EUR 500.000 – 1 Mln", "> EUR 1 Mln"]}
              id="grossAnnualIncome"
              name="grossAnnualIncome"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partB?.grossAnnualIncome?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partB?.grossAnnualIncome}
                </span>
              )}
          </div>
          {grossAnnualIncome === "> EUR 1 Mln" && (
            <div className="mb-3 col-lg-12">
              <TextBox
                label="please state here your annual income if it is above EUR 1 Mln"
                placeholder="enter annual income"
                reducer={this.props.reducer}
                name="anualIncmAbvEUR1Mln"
                chapter={this.props.chapter}
              />
              {this.props.errorMsg !== null &&
                Object.entries(this.props.errorMsg).length !== 0 &&
                this.props.errorMsg?.partB?.anualIncmAbvEUR1Mln?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {this.props.errorMsg?.partB?.anualIncmAbvEUR1Mln}
                  </span>
                )}
            </div>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    grossAnnualIncome: state.UBODeclarationUIM.partB["grossAnnualIncome"],
  };
};

export default connect(mapStateToProps, null)(GrossAnnualIncome);
