import React from "react";
import { UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
const MSLOGIN_REDIRECTURL=process.env.REACT_APP_MSLOGIN_REDIRECTURL;
const handleLogin = async (instance, accounts, history) => {
 try {
  let tokenResponse = await instance.handleRedirectPromise();
  let accountObj;
  if (tokenResponse) {
    accountObj = tokenResponse.account;
  } else {
    accountObj = instance.getAllAccounts()[0];
  }
  if (accountObj && tokenResponse) {
    console.log("[AuthService.init] Got valid accountObj and tokenResponse")
  } else if (accountObj) {
    try {
      tokenResponse = await instance.acquireTokenSilent({
        account: instance.getAllAccounts()[0],
        scopes: ["user.read"]
      })
    } catch(err) {
      await instance.acquireTokenRedirect({scopes: ["user.read"]});
    }
  } else {
    //  await instance.loginRedirect({scopes: ["user.read"]})
     await instance.loginPopup({
      scopes: ["User.Read"],
      redirectUri: MSLOGIN_REDIRECTURL,
      popupWindowAttributes: {
          popupSize: {
              height: 500,
              width: 500
          },
          popupPosition: {
              top: 500,
              left: 500
          }
        }
     }).then((res) => {
        if (res.accessToken.length > 10) {
          const allAccounts = instance.getAllAccounts();
          localStorage.setItem("allAccounts", JSON.stringify(allAccounts));
          localStorage.setItem("authToken", JSON.stringify(res.idToken));
          window.location = "/internal-dashboard/clientlist";
        }
  
  }).catch((e) => {
     localStorage.removeItem("authToken");
    //window.localStorage.clear();
    //handleLogin(instance, accounts, history);
   

  });

  }
} catch (error) {
  console.error("[AuthService.init] Failed to handleRedirectPromise()", error)
}
}

 



export const SignInButton = () => {
  const { instance, accounts } = useMsal();
  const history = useHistory();
  return (
    <div>
      <UnauthenticatedTemplate>
        <button variant="secondary" className="action-btn whiteBtn text-center mt-2 rounded" onClick={() => handleLogin(instance, accounts, history)}>Login</button>
      </UnauthenticatedTemplate>
    </div>
  );
}