import {
  SET_GENERIC_DETAILS_FAIL,
  SET_GENERIC_DETAILS_REQUEST,
  SET_GENERIC_DETAILS_SUCCESS,
  GET_CUSTOMER_ACTIVITY_FAIL,
  GET_CUSTOMER_ACTIVITY_REQUEST,
  GET_CUSTOMER_ACTIVITY_SUCCESS,
  SET_CUSTOMER_ACTIVITY_FAIL,
  SET_CUSTOMER_ACTIVITY_REQUEST,
  SET_CUSTOMER_ACTIVITY_SUCCESS,
  CHANGE_FILE_URL_REQUEST,
  CHANGE_FILE_URL_SUCCESS,
  CHANGE_FILE_URL_FAIL,
  DOWNLOAD_FILE_REQUEST,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_FAIL,
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAIL,
} from "../constants/CAFGenericConstants";

const initialState = {
  isLoading: false,
  user: [],
  error: "",
  globalState: false,
  clientInfoActiveLinkLevel: 0,
};

const CAFGenericReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    //generic form
    case SET_GENERIC_DETAILS_REQUEST:
    case SET_CUSTOMER_ACTIVITY_REQUEST:
    case GET_CUSTOMER_ACTIVITY_REQUEST:
    case CHANGE_FILE_URL_REQUEST:
    case DOWNLOAD_FILE_REQUEST:
    case DELETE_FILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case SET_GENERIC_DETAILS_SUCCESS:
    case SET_CUSTOMER_ACTIVITY_SUCCESS:
    case GET_CUSTOMER_ACTIVITY_SUCCESS:
    case CHANGE_FILE_URL_SUCCESS:
    case DOWNLOAD_FILE_SUCCESS:
    case DELETE_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case SET_GENERIC_DETAILS_FAIL:
    case SET_CUSTOMER_ACTIVITY_FAIL:
    case GET_CUSTOMER_ACTIVITY_FAIL:
    case CHANGE_FILE_URL_FAIL:
    case DOWNLOAD_FILE_FAIL:
    case DELETE_FILE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case "STATE_CHANGED":
      return {
        ...state,
        globalState: true,
      };
    case "STATE_NOT_CHANGED":
      return {
        ...state,
        globalState: false,
        clientInfoActiveLinkLevel: localStorage.getItem("activeLinkLevel"),
      };
    case "SET_CLIENT_INFORMATION_IN_REDUCER":
      return {
        ...state,
        isLoading: false,
        clientInfoActiveLinkLevel: action.data,
        error: "",
      };

    default:
      return state;
  }
};

export default CAFGenericReducer;
