import React, { Component } from "react";
import CheckBox from "../../../../../elements/CheckBox";
import DropDown from "../../../../../elements/DropDown";
import DateTimePicker from "../../../../../elements/DateTimePicker";
import countryList from "react-select-country-list";
import FileUpload from "../../../../../elements/FileUpload";
import { fileAcceptType } from "./Constants";
import PhoneInputField from "../../../../../elements/PhoneInputField";
import TextBox from "../../../../../elements/TextBox";

class MaltaDirectors extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { chapter, index, errors } = this.props;
    const eMsg = JSON.parse(errors);
    return (
      <div className="mb-4 col-lg-12">
        <fieldset>
          <legend>Director {index + 1}</legend>
          <div className="row">
            <div className="mb-3 col-lg-4">
              <TextBox
                label={`Name Director of Target Company`}
                name="dirName"
                id={`dirName[${index}]`}
                placeholder="enter your text"
                chapter={chapter}
                baseName="objCompDirectorNames"
                index={index}
                reducer={this.props.reducer}
                validationType="Text"
              />
              {eMsg &&
                eMsg.objCompDirectorNames &&
                eMsg.objCompDirectorNames[index] &&
                eMsg.objCompDirectorNames[index]?.dirName?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.objCompDirectorNames[index]?.dirName}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Residental Address"
                name="dirResAdd"
                id={`dirResAdd[${index}]`}
                placeholder="enter your text"
                chapter={chapter}
                baseName="objCompDirectorNames"
                index={index}
                reducer={this.props.reducer}
                validationType="Text"
              />
              {eMsg &&
                eMsg.objCompDirectorNames &&
                eMsg.objCompDirectorNames[index] &&
                eMsg.objCompDirectorNames[index]?.dirResAdd?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.objCompDirectorNames[index]?.dirResAdd}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-4">
              <DropDown
                label={`Nationality of Director`}
                name="dirNationality"
                id={`dirNationality[${index}]`}
                options={countryList().getData()}
                chapter={chapter}
                baseName="objCompDirectorNames"
                index={index}
                reducer={this.props.reducer}
              />
              {eMsg &&
                eMsg.objCompDirectorNames &&
                eMsg.objCompDirectorNames[index] &&
                eMsg.objCompDirectorNames[index]?.dirNationality?.length >
                  0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.objCompDirectorNames[index]?.dirNationality}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Passport/Identity/Registration No."
                name="dirPassIdntyNum"
                id={`dirPassIdntyNum[${index}]`}
                placeholder="enter your text"
                chapter={chapter}
                baseName="objCompDirectorNames"
                index={index}
                reducer={this.props.reducer}
                validationType="Text"
              />
              {eMsg &&
                eMsg.objCompDirectorNames &&
                eMsg.objCompDirectorNames[index] &&
                eMsg.objCompDirectorNames[index]?.dirPassIdntyNum?.length >
                  0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.objCompDirectorNames[index]?.dirPassIdntyNum}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Place of issue"
                name="dirPlaceOfIssu"
                id={`dirPlaceOfIssu[${index}]`}
                placeholder="enter your text"
                chapter={chapter}
                baseName="objCompDirectorNames"
                index={index}
                reducer={this.props.reducer}
                validationType="Text"
              />
              {eMsg &&
                eMsg.objCompDirectorNames &&
                eMsg.objCompDirectorNames[index] &&
                eMsg.objCompDirectorNames[index]?.dirPlaceOfIssu?.length >
                  0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.objCompDirectorNames[index]?.dirPlaceOfIssu}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-4" id={`dirDtIssue[${index}]`}>
              <DateTimePicker
                label="Date of issue"
                name="dirDtIssue"
                id={`dirDtIssue[${index}]`}
                chapter={chapter}
                baseName="objCompDirectorNames"
                index={index}
                reducer={this.props.reducer}
              />
              {eMsg &&
                eMsg.objCompDirectorNames &&
                eMsg.objCompDirectorNames[index] &&
                eMsg.objCompDirectorNames[index]?.dirDtIssue?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.objCompDirectorNames[index]?.dirDtIssue}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-4" id={`dirExprDt[${index}]`}>
              <DateTimePicker
                label="Expiry date"
                name="dirExprDt"
                id={`dirExprDt[${index}]`}
                chapter={chapter}
                baseName="objCompDirectorNames"
                index={index}
                maxDate={true}
                reducer={this.props.reducer}
              />
              {eMsg &&
                eMsg.objCompDirectorNames &&
                eMsg.objCompDirectorNames[index] &&
                eMsg.objCompDirectorNames[index]?.dirExprDt?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.objCompDirectorNames[index]?.dirExprDt}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-4" id={`dirTelPhnNum[${index}]`}>
              <PhoneInputField
                label="Telephone No."
                name="dirTelPhnNum"
                chapter={chapter}
                baseName="objCompDirectorNames"
                index={index}
                reducer={this.props.reducer}
                country={this.props.form}
              />
              {eMsg &&
                eMsg.objCompDirectorNames &&
                eMsg.objCompDirectorNames[index] &&
                eMsg.objCompDirectorNames[index]?.dirTelPhnNum?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.objCompDirectorNames[index]?.dirTelPhnNum}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Email address"
                name="dirEmail"
                id={`dirEmail[${index}]`}
                placeholder="enter your text"
                chapter={chapter}
                baseName="objCompDirectorNames"
                index={index}
                reducer={this.props.reducer}
                validationType="Email"
                ShowValidation={
                  eMsg &&
                  eMsg?.objCompDirectorNames &&
                  eMsg?.objCompDirectorNames[index] &&
                  eMsg?.objCompDirectorNames[index]?.dirEmail &&
                  eMsg?.objCompDirectorNames[index]?.dirEmail?.length > 0
                    ? false
                    : true
                }
              />
              {eMsg &&
                eMsg.objCompDirectorNames &&
                eMsg.objCompDirectorNames[index] &&
                eMsg.objCompDirectorNames[index]?.dirEmail?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.objCompDirectorNames[index]?.dirEmail}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-4">
              <CheckBox
                label="Is director judical & representative of the company?"
                options={["Yes", "No"]}
                name="isDirJudRepComp"
                id={`isDirJudRepComp[${index}]`}
                chapter={chapter}
                baseName="objCompDirectorNames"
                index={index}
                reducer={this.props.reducer}
              />
              {eMsg &&
                eMsg.objCompDirectorNames &&
                eMsg.objCompDirectorNames[index] &&
                eMsg.objCompDirectorNames[index]?.isDirJudRepComp?.length >
                  0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.objCompDirectorNames[index]?.isDirJudRepComp}
                  </span>
                )}
            </div>
            {this.props.form === "Malta" && (
              <>
                <div className="mb-3 col-lg-4">
                  <FileUpload
                    label="Upload passport copy"
                    acceptType={fileAcceptType}
                    name="dirPassportCopy"
                    id={`dirPassportCopy${index}`}
                    chapter={chapter}
                    baseName="objCompDirectorNames"
                    index={index}
                    reducer={this.props.reducer}
                  />
                  {eMsg &&
                    eMsg.objCompDirectorNames &&
                    eMsg.objCompDirectorNames[index] &&
                    eMsg.objCompDirectorNames[index]?.dirPassportCopy?.length >
                      0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg.objCompDirectorNames[index]?.dirPassportCopy}
                      </span>
                    )}
                </div>
                <div className="mb-3 col-lg-4">
                  <FileUpload
                    label="Upload utility bills"
                    acceptType={fileAcceptType}
                    name="dirUtilityBill"
                    id={`dirUtilityBill${index}`}
                    chapter={chapter}
                    baseName="objCompDirectorNames"
                    index={index}
                    reducer={this.props.reducer}
                  />
                  {eMsg &&
                    eMsg.objCompDirectorNames &&
                    eMsg.objCompDirectorNames[index] &&
                    eMsg.objCompDirectorNames[index]?.dirUtilityBill?.length >
                      0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg.objCompDirectorNames[index]?.dirUtilityBill}
                      </span>
                    )}
                </div>
                <div className="mb-3 col-lg-4">
                  <FileUpload
                    label="Upload reference letter"
                    acceptType={fileAcceptType}
                    name="dirReferenceLetter"
                    id={`dirReferenceLetter${index}`}
                    chapter={chapter}
                    baseName="objCompDirectorNames"
                    index={index}
                    reducer={this.props.reducer}
                  />
                  {eMsg &&
                    eMsg.objCompDirectorNames &&
                    eMsg.objCompDirectorNames[index] &&
                    eMsg.objCompDirectorNames[index]?.dirReferenceLetter
                      ?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg.objCompDirectorNames[index]?.dirReferenceLetter}
                      </span>
                    )}
                </div>
              </>
            )}
          </div>
        </fieldset>
      </div>
    );
  }
}

export default MaltaDirectors;
