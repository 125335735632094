import React from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";
import {
  setLuxembourgInternalCAFDetails,
  getLuxembourgInternalCAFDetails,
} from "../../../../../redux/actions/Luxembourg/LuxembourgInternalCAFActions";
import "../../Onboarding/internalCAF.scss";
import { connect } from "react-redux";
import NotificationManager from "react-notifications/lib/NotificationManager";
import CheckBox from "../../../../elements/CheckBox";
import AutoSaving from "../AutoSaving";
import { chapValidationErrorLuxembourg } from "../Constants";
import Risks from "../../Onboarding/Chapter1/Risks";
import ExternalDirectors from "../../Onboarding/Chapter6/ExternalDirectors";
import Agreements from "../../Onboarding/Chapter6/Agreements";
import SignedAgreements from "../../Onboarding/Chapter6/SignedAgreements";
import NonRecFees from "../../MaltaOffice/Chapter6/NonRecFees";
import AnnualFees from "../../MaltaOffice/Chapter6/AnnualFees";
import getSequentialFields from "../getSequentialFields";
import { setLuxembourgInternalChapterError } from "../../../../../redux/actions/Luxembourg/LuxembourgInternalChapterErrors";

const chapter = "chapter6";
const reducer = "LuxembourgInternalCAF";
const externalReducer = "LuxembourgClientInformation";
let timer;

class Chapter6 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formErrorsChapter6: {},
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: "all",
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.getLuxembourgInternalCAFDetails(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          Object.keys(this.props.internalErrors.errors).length === 0 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationErrorLuxembourg.forEach(async (element) => {
            let response =
              res?.data?.result?.cafInternalValidations[element.chapter];

            const newResponse = await Promise.resolve(
              getSequentialFields(response, element.chapter, false)
            );
            this.props.setLuxembourgInternalChapterError({
              form: `formErrors${element.chapter}`,
              errors: newResponse?.errorChapter,
            });
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;

    this.props
      .setLuxembourgInternalCAFDetails(requestData)
      .then(async (res) => {
        if (res.data.portalErrorCode === 200) {
          this.props.ClientAcceptance.chapter6["dirty"] = false;
          let response = res.data.result;

          const newResponse = await Promise.resolve(
            getSequentialFields(response, chapter, true)
          );
          this.props.setLuxembourgInternalChapterError({
            errors: newResponse?.errorChapter,
            form: "formErrorschapter6",
          });
          if (!res.data.result.isValid) {
            NotificationManager.info(
              "All fields are mandatory to fill in",
              null,
              4000
            );
            timer = setTimeout(() => {
              return this.props.history.push({
                pathname: "/luxembourg/consolidated-risk-assessment",
              });
            }, 4000);
          } else {
            NotificationManager.success("Form is submitted successfully.");

            return this.props.history.push({
              pathname: "/luxembourg/consolidated-risk-assessment",
            });
          }
        }
      });
  };

  render() {
    const { isUnacceptableRisksIdentified, internalErrors } = this.props;
    const formErrorsChapter6 = JSON.stringify(internalErrors.errors);
    const errorsMsg = JSON.parse(formErrorsChapter6);

    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="html2pdf__page-break"></div>
          <div className="cafHeader">
            <h2 className="m-0">Client Acceptance Form</h2>
          </div>
          <div className="internal_caf generic-form">
            <h3 className="chapterttl">
              Agreements - Purpose of the (trust) services
            </h3>
            <Agreements
              chapter={chapter}
              reducer={reducer}
              errors={formErrorsChapter6}
              isRequired={true}
              form="Luxembourg"
              PdfGeneration={this.props.PdfGeneration}
            />
            <SignedAgreements
              chapter={chapter}
              reducer={reducer}
              errors={formErrorsChapter6}
              isRequired={true}
              form="Luxembourg"
            />
            <div className="html2pdf__page-break"></div>
            <h4 className="col-md-12 sub-chapter-title pl-0 mb-2">Fees</h4>
            <NonRecFees
              errors={formErrorsChapter6}
              isRequired={true}
              chapter={chapter}
              reducer={reducer}
              PdfGeneration={this.props.PdfGeneration}
              form="Luxembourg"
            />
            <AnnualFees
              errors={formErrorsChapter6}
              isRequired={true}
              chapter={chapter}
              reducer={reducer}
              PdfGeneration={this.props.PdfGeneration}
              form="Luxembourg"
            />
            <div className="html2pdf__page-break"></div>
            <ExternalDirectors
              chapter={chapter}
              reducer={reducer}
              externalReducer={externalReducer}
              errors={formErrorsChapter6}
              form="Luxembourg"
              isRequired={true}
              PdfGeneration={this.props.PdfGeneration}
            />

            <Risks
              chapter={chapter}
              reducer={reducer}
              errors={formErrorsChapter6}
              riskLabel="Services risk rating"
              questionLabel="How many service related risks can be identified?"
              boxLabel="Risk"
              isRequired={true}
              PdfGeneration={this.props.PdfGeneration}
            />

            <Row className="mb-3">
              <div className="col-lg-12">
                Brief description of the purpose of the services to be provided
                by the trust office. As well as a description of the potential
                integrity risks. (Management services (2:9 BW, articles of
                association, external director), Domiciliation and
                administration (no management services)
              </div>
            </Row>
            <Row className="mb-3">
              <div className="col-lg-12">
                <CheckBox
                  label="Unacceptable risks can be identified?"
                  options={["Yes", "No"]}
                  name="isUnacceptableRisksIdentified"
                  id="isUnacceptableRisksIdentified"
                  chapter={chapter}
                  reducer={reducer}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.isUnacceptableRisksIdentified?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isUnacceptableRisksIdentified}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="mb-3">
              {isUnacceptableRisksIdentified === "Yes" && (
                <div className="col-lg-12">
                  <TextBox
                    label="Please describe"
                    placeholder="Enter your text"
                    name="unacceptableRisksReason"
                    id="unacceptableRisksReason"
                    isRequired={true}
                    chapter={chapter}
                    reducer={reducer}
                    isMultipleLines={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtArea"
                        : "form-control"
                    }
                  />
                  {errorsMsg &&
                    errorsMsg?.isSubmitted === true &&
                    errorsMsg?.unacceptableRisksReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.unacceptableRisksReason}
                      </span>
                    )}
                </div>
              )}
              {isUnacceptableRisksIdentified === "No" && (
                <div className="col-md-12">
                  <b>
                    With respect to the intended services, UIM sees no reason to
                    discontinue the set-up of a business relationship
                  </b>
                </div>
              )}
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    chapter6: state.LuxembourgClientInformation.chapter6,
    ClientAcceptance: state.LuxembourgInternalCAF,
    isUnacceptableRisksIdentified:
      state.LuxembourgInternalCAF.chapter6.isUnacceptableRisksIdentified,
    commonFields: state.commonFields,
    data: state,
    internalErrors: state.LuxembourgInternalChapterErrors.formErrorschapter6,
    errors: state.LuxembourgInternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLuxembourgInternalCAFDetails: async (data) =>
      dispatch(await getLuxembourgInternalCAFDetails(data)),
    setLuxembourgInternalCAFDetails: async (data) =>
      dispatch(await setLuxembourgInternalCAFDetails(data)),
    setLuxembourgInternalChapterError: (data) =>
      dispatch(setLuxembourgInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter6);
