import {
  chapter1,
  chapter2,
  chapter3,
  chapter4,
  chapter5,
  chapter6,
  chapter8,
  chapter9,
  chapter10,
  chapter11,
} from "./constants";

export default function getSequentialFields(response, chapter, isSubmitted) {
  let errorChapter;

  switch (chapter) {
    case "chapter1": {
      errorChapter = chapter1;
      break;
    }
    case "chapter2": {
      errorChapter = chapter2;
      break;
    }
    case "chapter3": {
      errorChapter = chapter3;
      break;
    }
    case "chapter4": {
      errorChapter = chapter4;
      break;
    }
    case "chapter5": {
      errorChapter = chapter5;
      break;
    }
    case "chapter6": {
      errorChapter = chapter6;
      break;
    }
    case "chapter8": {
      errorChapter = chapter8;
      break;
    }
    case "chapter9": {
      errorChapter = chapter9;
      break;
    }
    case "chapter10": {
      errorChapter = chapter10;
      break;
    }
    case "chapter11": {
      errorChapter = chapter11;
      break;
    }
    //   case "chapter12": {
    //     errorChapter = chapter12;
    //     break;
    //   }
    default:
      return;
  }
  Object.keys(errorChapter).forEach((key) => {
    Object.entries(response).forEach((val) => {
      const reskey = val[0];
      const resVal = val[1];
      if (key === reskey) {
        errorChapter[key] = resVal;
      }
    });
  });
  errorChapter["isValid"] = response?.isValid;
  if (isSubmitted) {
    errorChapter["isSubmitted"] = true;
  } else {
    errorChapter["isSubmitted"] = false;
  }
  return { errorChapter };
}
