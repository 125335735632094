import axios from "axios";

const BACKEND_API_URL = process.env.REACT_APP_USER_BACKEND_API_URL;

const setNewCuracaoDataToReducerReceiveData = (json) => {
  return {
    type: "UPDATE_NEW_CURACAO_DATA",
    data: json,
  };
};

export const setNewCuracaoDataToReducer = (data) => {
  return (dispatch) => {
    dispatch(setNewCuracaoDataToReducerReceiveData(data));
  };
};

const SetCuracaoCAFDetailsRequest = () => {
  return {
    type: "SET_CURACAO_CAF_DETAILS_REQUEST",
  };
};

const SetCuracaoCAFDetailsReceiveData = (json) => {
  return {
    type: "SET_CURACAO_CAF_DETAILS_SUCCESS",
    data: json,
  };
};

export const setCuracaoCAFDetails = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(SetCuracaoCAFDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/CuracaoCAF/SetCAFDetails`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: "SET_CURACAO_CAF_DETAILS_FAIL", data: json });
        } else {
          return dispatch(SetCuracaoCAFDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "SET_CURACAO_CAF_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};

const GetCuracaoCAFDetailsRequest = () => {
  return {
    type: "GET_CURACAO_CAF_DETAILS_REQUEST",
  };
};

const GetCuracaoCAFDetailsReceiveData = (json) => {
  return {
    type: "GET_CURACAO_CAF_DETAILS_SUCCESS",
    data: json,
  };
};

export const getCuracaoCAFDetails = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(GetCuracaoCAFDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/CuracaoCAF/GetCAFExternal`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: "GET_CURACAO_CAF_DETAILS_FAIL", data: json });
        } else {
          return dispatch(GetCuracaoCAFDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "GET_CURACAO_CAF_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};
