import React from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";
import {
  setLuxembourgInternalCAFDetails,
  getLuxembourgInternalCAFDetails,
} from "../../../../../redux/actions/Luxembourg/LuxembourgInternalCAFActions";
import "../../Onboarding/internalCAF.scss";
import { connect } from "react-redux";
import NotificationManager from "react-notifications/lib/NotificationManager";
import CheckBox from "../../../../elements/CheckBox";
import AutoSaving from "../AutoSaving";
import NumberInput from "../../../../elements/NumberInput";
import InternalFileUpload from "../../../../elements/InternalFileUpload";
import { chapValidationErrorLuxembourg, fileAcceptType } from "../Constants";
import MediaCoverage from "../../Onboarding/Chapter1/MediaCoverage";
import Risks from "../../Onboarding/Chapter1/Risks";
import UpcDetails from "../../Onboarding/Chapter3/UpcDetails";
import UboDetails from "../../Onboarding/Chapter3/UboDetails";
import UboQuestions from "../../Onboarding/Chapter3/UboQuestions";
import MaltaShareholder from "../../MaltaOffice/Chapter3/MaltaShareholder";
import { setLuxembourgInternalChapterError } from "../../../../../redux/actions/Luxembourg/LuxembourgInternalChapterErrors";
import getSequentialFields from "../getSequentialFields";

const chapter = "chapter3";
const reducer = "LuxembourgInternalCAF";
const externalReducer = "LuxembourgClientInformation";
let timer;

class Chapter3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formErrorsChapter3: {},
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: "all",
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.getLuxembourgInternalCAFDetails(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          Object.keys(this.props.internalErrors.errors).length === 0 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationErrorLuxembourg.forEach(async (element) => {
            let response =
              res?.data?.result?.cafInternalValidations[element.chapter];

            const newResponse = await Promise.resolve(
              getSequentialFields(response, element.chapter, false)
            );
            this.props.setLuxembourgInternalChapterError({
              form: `formErrors${element.chapter}`,
              errors: newResponse?.errorChapter,
            });
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;
    requestData["commonFields"] = this.props.commonFields;
    requestData["cafExternalChap3"] = this.props.chapter3;
    this.props
      .setLuxembourgInternalCAFDetails(requestData)
      .then(async (res) => {
        if (res.data.portalErrorCode === 200) {
          this.props.ClientAcceptance.chapter3["dirty"] = false;
          let response = res.data.result;

          const newResponse = await Promise.resolve(
            getSequentialFields(response, chapter, true)
          );
          this.props.setLuxembourgInternalChapterError({
            errors: newResponse?.errorChapter,
            form: "formErrorschapter3",
          });
          if (!res.data.result.isValid) {
            NotificationManager.info(
              "All fields are mandatory to fill in",
              null,
              4000
            );
            timer = setTimeout(() => {
              return this.props.history.push({
                pathname: "/luxembourg/object-company",
              });
            }, 4000);
          } else {
            NotificationManager.success("Form is submitted successfully.");

            return this.props.history.push({
              pathname: "/luxembourg/object-company",
            });
          }
        }
      });
  };

  render() {
    const {
      noOfUBOs,
      isUPCExists,
      chapter3,
      isUBOresultIncrsdIntgrtyRsk,
      internalErrors,
    } = this.props;

    const formErrorsChapter3 = JSON.stringify(internalErrors.errors);
    const errorsMsg = JSON.parse(formErrorsChapter3);

    let uboinfo = [];
    for (let i = 0; i < noOfUBOs; i++) {
      uboinfo.push(
        <UboDetails
          errors={formErrorsChapter3}
          index={i}
          baseName="ubox"
          reducer={reducer}
          objBaseName="ubo"
          form="Luxembourg"
          isRequired={true}
        />
      );
    }

    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="cafHeader">
            <h2 className="m-0">Client Acceptance Form </h2>
          </div>
          <div className="internal_caf generic-form">
            <div className="html2pdf__page-break"></div>
            <h3 className="chapterttl">
              Client Investigation (UBO)/ Ultimate Parent Company (UPC)
            </h3>
            <Row className="mb-3">
              <div className="col-md-12">
                <UboQuestions
                  chapter={chapter}
                  reducer={reducer}
                  externalReducer={externalReducer}
                  errors={formErrorsChapter3}
                  isRequired={true}
                />
              </div>
            </Row>
            {chapter3.isHoldingInterest === "Yes" &&
              chapter3.isUBOClassified === "Yes" &&
              chapter3.isAssetClient === "Yes" && (
                <>
                  <div className="html2pdf__page-break"></div>
                  <Row className="mb-3">
                    <div className="col-lg-4">
                      <NumberInput
                        label="How many UBO's are there?"
                        name="noOfUBOs"
                        reducer="commonFields"
                        isCommon={true}
                        objBaseName="ubo"
                        disabled={true}
                      />
                      <div className="errorMessage"></div>
                    </div>
                    <div className="col-lg-8">{uboinfo}</div>
                  </Row>
                  <div className="html2pdf__page-break"></div>
                </>
              )}

            <Row className="mb-3">
              <div className="col-lg-6">
                <CheckBox
                  label="Does the Ultimate Parent Company exist?"
                  options={["Yes", "No"]}
                  name="isUPCExists"
                  reducer="commonFields"
                  isCommon={true}
                  disabled={true}
                />
                <div className="errorMessage"></div>
              </div>
            </Row>
            {isUPCExists === "Yes" && (
              <UpcDetails
                errors={formErrorsChapter3}
                from="ClientAcceptance"
                externalReducer="commonFields"
                reducer={reducer}
                objBaseName="upc"
                form="Luxembourg"
                isRequired={true}
                PdfGeneration={this.props.PdfGeneration}
              />
            )}
            <Row className="mb-3">
              <div className="col-lg-4">
                <InternalFileUpload
                  label="Pascal report"
                  name="pascalReportUpload"
                  id="pascalReportUpload"
                  chapter={chapter}
                  acceptType={fileAcceptType}
                  reducer={reducer}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.pascalReportUpload?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.pascalReportUpload}
                    </span>
                  )}
              </div>
              <div className="col-lg-4">
                <TextBox
                  label="Findings"
                  placeholder="Enter your text"
                  name="isFindings"
                  id="isFindings"
                  chapter={chapter}
                  reducer={reducer}
                  validationType="Text"
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.isFindings?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isFindings}
                    </span>
                  )}
              </div>
              <div className="col-lg-4">
                <CheckBox
                  label="Verified Machine Readable(MRZ) Passport through Passport-Check Pascal"
                  options={["Yes", "No"]}
                  name="isMRZPassport"
                  id="isMRZPassport"
                  chapter={chapter}
                  reducer={reducer}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.isMRZPassport?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isMRZPassport}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="mb-3">
              <div className="col-lg-12">
                <TextBox
                  label="Please describe how the investigation into the UBO in the scope of determining whether he or she can be classified as a Politically Exposed Person (PEP) was carried out. What is his position and which extra measures should be taken?"
                  placeholder="Enter your text"
                  name="descInvstgUBOClsifiedPEP"
                  id="descInvstgUBOClsifiedPEP"
                  chapter={chapter}
                  reducer={reducer}
                  isMultipleLines={true}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.descInvstgUBOClsifiedPEP?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.descInvstgUBOClsifiedPEP}
                    </span>
                  )}
              </div>
            </Row>
            <div className="html2pdf__page-break"></div>
            <MaltaShareholder
              reducer={reducer}
              chapter={chapter}
              errorMsg={errorsMsg}
              isRequired={true}
              form="Luxembourg"
            />
            <MediaCoverage
              chapter={chapter}
              reducer={reducer}
              errors={formErrorsChapter3}
              form="Luxembourg"
              isRequired={true}
            />

            <Risks
              chapter={chapter}
              reducer={reducer}
              errors={formErrorsChapter3}
              riskLabel="Client risk rating"
              questionLabel="How many UBO/UPC related risks can be identified?"
              boxLabel="Risk"
              isRequired={true}
              PdfGeneration={this.props.PdfGeneration}
            />
            {/* <div className="html2pdf__page-break"></div> */}
            <Row className="mb-3">
              <div className="col-lg-12">
                if there are integrity risks related to the UBO, the
                qualification PEP and the acquiring of wealth over time by
                assessing the obtained information to verify the size and scope
                of the assets and how the assets were obtained. 'The story must
                be right'. Is it sufficiently substantiated that the client's
                (Object Company and UBO) entire wealth originates from
                legitimate sources. After this assessment it is possible to
                identify potential integrity risks which need to be added to the
                risk log. Take into account the press coverage of the UBO.
              </div>
            </Row>
            <Row className="mb-3">
              <div className="col-lg-12">
                <CheckBox
                  label="In addition, report whether the UBO criteria result in a potentially increased integrity risk"
                  options={["Yes", "No"]}
                  name="isUBOresultIncrsdIntgrtyRsk"
                  id="isUBOresultIncrsdIntgrtyRsk"
                  chapter={chapter}
                  isRequired={true}
                  reducer={reducer}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.isUBOresultIncrsdIntgrtyRsk?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isUBOresultIncrsdIntgrtyRsk}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="mb-3">
              {isUBOresultIncrsdIntgrtyRsk === "Yes" && (
                <div className="col-lg-12">
                  <TextBox
                    label="Please describe"
                    placeholder="Enter your text"
                    name="ubOresultIncrsdIntgrtyRskCmmnt"
                    id="ubOresultIncrsdIntgrtyRskCmmnt"
                    chapter={chapter}
                    reducer={reducer}
                    isRequired={true}
                    isMultipleLines={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtArea"
                        : "form-control"
                    }
                  />
                  {errorsMsg &&
                    errorsMsg.isSubmitted === true &&
                    errorsMsg?.ubOresultIncrsdIntgrtyRskCmmnt?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.ubOresultIncrsdIntgrtyRskCmmnt}
                      </span>
                    )}
                </div>
              )}
              {isUBOresultIncrsdIntgrtyRsk === "No" && (
                <div className="col-md-12 font-weight-bold">
                  With respect to the UBO information, UIM sees no reason to
                  discontinue the set-up of a business relationship
                </div>
              )}
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    noOfUBOs: state.commonFields.ubo["noOfUBOs"],
    isUPCExists: state.commonFields["isUPCExists"],
    chapter3: state.LuxembourgClientInformation.chapter3,
    isUBOresultIncrsdIntgrtyRsk:
      state.LuxembourgInternalCAF.chapter3["isUBOresultIncrsdIntgrtyRsk"],
    ClientAcceptance: state.LuxembourgInternalCAF,
    commonFields: state.commonFields,
    data: state,
    internalErrors: state.LuxembourgInternalChapterErrors.formErrorschapter3,
    errors: state.LuxembourgInternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLuxembourgInternalCAFDetails: async (data) =>
      dispatch(await getLuxembourgInternalCAFDetails(data)),
    setLuxembourgInternalCAFDetails: async (data) =>
      dispatch(await setLuxembourgInternalCAFDetails(data)),
    setLuxembourgInternalChapterError: (data) =>
      dispatch(setLuxembourgInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter3);
