import React, { Component } from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../../elements/TextBox";
import Rationale from "../../client-information/Chapter4/Rationale";
import MainActivities from "../../client-information/Chapter1/MainActivities";
import TaxInvestmentMemorandum from "../../client-information/Chapter4/TaxInvestmentMemorandum";

class ObjectCompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Row>
          <div className="mb-2 col-lg-6">
            <TextBox
              label={
                this.props.form === "Malta"
                  ? "Name of Target Company"
                  : "Name of Object Company"
              }
              name="objCompName"
              placeholder="enter name"
              reducer="commonFields"
              validationType="Text"
              isCommon={true}
              disabled={true}
            />
            <div className="errorMsg"></div>
          </div>
        </Row>
        <div className="html2pdf__page-break"></div>
        <Row className="pt-3 pb-2 pageBreak">
          <div className="mb-2 col-lg-12">
            <MainActivities
              reducer="commonFields"
              from="Ubo-pep"
              form={this.props.form}
            />
          </div>
        </Row>
        {this.props.form !== "Malta" && (
          <TaxInvestmentMemorandum
            chapter={this.props.chapter}
            reducer={"commonFields"}
            objBaseName={this.props.objBaseName}
            errorMsg={this.props.errorMsg}
            proofOfTaxRes={true}
            form={this.props.form}
          />
        )}

        <Rationale
          reducer="commonFields"
          form={this.props.form}
          from="declaration"
          errorMsg={this.props.errorMsg}
        />
      </>
    );
  }
}

export default ObjectCompanyDetails;
