import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import TextBox from "../../../../elements/TextBox";
import { checkIsCommon } from "../../../external/OnBoarding/client-information/CommonFunctions";
import { TypeSizeFreqTransct } from "../Constants";
import { getTypeSizeFrqArray } from "../getTypeSizeFreqArray";

class TypeSizeFreq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      isUBOInvstObjComp,
      isUPCfundObjComp,
      isTpInvstObjComp,
      errorsMsg,
      isRequired,
    } = this.props;
    let TypeSizeFrqArray = getTypeSizeFrqArray(this.props.form);

    return (
      <>
        <h4 className="col-md-12 sub-chapter-title pl-0 mb-2">
          Type, size and frequency of transactions
        </h4>
        {TypeSizeFrqArray.map((transaction, index) => {
          return (
            <>
              {transaction.dependency === "isUPCfundObjComp" &&
                isUPCfundObjComp === "Yes" && (
                  <div key={index} className="groupCompaniesBlock">
                    <h6 className="font-weight-bold">{transaction.label}</h6>
                    <Row className="mt-2">
                      {TypeSizeFreqTransct.map((item) => {
                        return (
                          <div
                            className="col-lg-4"
                            key={transaction.dependency + item.name}
                          >
                            <TextBox
                              label={item.label}
                              name={item.name}
                              id={item.name}
                              placeholder="enter your text"
                              chapter={
                                checkIsCommon(this.props.form)
                                  ? null
                                  : this.props.chapter
                              }
                              reducer={
                                checkIsCommon(this.props.form)
                                  ? "commonFields"
                                  : this.props.externalReducer
                              }
                              isCommon={checkIsCommon(this.props.form)}
                              objBaseName="srcFundsObjComp"
                              nestedObjBaseName={transaction.name}
                              validationType="Text"
                              disabled={true}
                              className={
                                this.props.PdfGeneration === "Yes"
                                  ? "txtBox"
                                  : "form-control"
                              }
                            />
                          </div>
                        );
                      })}
                      <div className="mb-2 mt-3 col-lg-12">
                        <TextBox
                          label="Please add comment"
                          placeholder="Enter your text"
                          name={transaction.textboxName}
                          id={transaction.textboxName}
                          chapter={this.props.chapter}
                          reducer={this.props.reducer}
                          isRequired={
                            this.props.form === "UK" ? false : isRequired
                          }
                          validationType="Text"
                          className={
                            this.props.PdfGeneration === "Yes"
                              ? "txtBox"
                              : "form-control"
                          }
                        />
                        {errorsMsg &&
                          errorsMsg?.isSubmitted === true &&
                          errorsMsg[transaction.textboxName] !== "" && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              {errorsMsg[transaction.textboxName]}
                            </span>
                          )}
                      </div>
                    </Row>
                  </div>
                )}
              {transaction.dependency === "isUBOInvstObjComp" &&
                isUBOInvstObjComp === "Yes" && (
                  <div
                    key={transaction.dependency}
                    className="groupCompaniesBlock"
                  >
                    <h6 className="font-weight-bold">{transaction.label}</h6>
                    <Row className="mt-2">
                      {TypeSizeFreqTransct.map((item) => {
                        return (
                          <div
                            className="col-lg-4"
                            key={transaction.dependency + item.name}
                          >
                            <TextBox
                              label={item.label}
                              name={item.name}
                              id={item.name}
                              placeholder="enter your text"
                              chapter={
                                checkIsCommon(this.props.form)
                                  ? null
                                  : this.props.chapter
                              }
                              reducer={
                                checkIsCommon(this.props.form)
                                  ? "commonFields"
                                  : this.props.externalReducer
                              }
                              isCommon={checkIsCommon(this.props.form)}
                              objBaseName="srcFundsObjComp"
                              nestedObjBaseName={transaction.name}
                              validationType="Text"
                              disabled={true}
                              className={
                                this.props.PdfGeneration === "Yes"
                                  ? "txtBox"
                                  : "form-control"
                              }
                            />
                          </div>
                        );
                      })}
                      <div className="mb-2 mt-3 col-lg-12">
                        <TextBox
                          label="Please add comment"
                          placeholder="Enter your text"
                          id={transaction.textboxName}
                          name={transaction.textboxName}
                          chapter={this.props.chapter}
                          reducer={this.props.reducer}
                          isRequired={
                            this.props.form === "UK" ? false : isRequired
                          }
                          validationType="Text"
                          className={
                            this.props.PdfGeneration === "Yes"
                              ? "txtBox"
                              : "form-control"
                          }
                        />
                        {errorsMsg &&
                          errorsMsg?.isSubmitted === true &&
                          errorsMsg[transaction.textboxName] !== "" && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              {errorsMsg[transaction.textboxName]}
                            </span>
                          )}
                      </div>
                    </Row>
                  </div>
                )}
              {transaction.dependency === "isTpInvstObjComp" &&
                isTpInvstObjComp === "Yes" && (
                  <div
                    key={transaction.dependency}
                    className="groupCompaniesBlock"
                  >
                    {transaction.label ===
                    "From Object Company to third parties" ? (
                      <div className="html2pdf__page-break"></div>
                    ) : (
                      <div></div>
                    )}

                    <h6 className="font-weight-bold">{transaction.label}</h6>
                    <Row className="mt-2">
                      {TypeSizeFreqTransct.map((item) => {
                        return (
                          <div
                            className="col-lg-4"
                            key={transaction.dependency + item.name}
                          >
                            <TextBox
                              label={item.label}
                              name={item.name}
                              id={item.name}
                              placeholder="enter your text"
                              chapter={
                                checkIsCommon(this.props.form)
                                  ? null
                                  : this.props.chapter
                              }
                              reducer={
                                checkIsCommon(this.props.form)
                                  ? "commonFields"
                                  : this.props.externalReducer
                              }
                              isCommon={checkIsCommon(this.props.form)}
                              objBaseName="srcFundsObjComp"
                              nestedObjBaseName={transaction.name}
                              validationType="Text"
                              disabled={true}
                              className={
                                this.props.PdfGeneration === "Yes"
                                  ? "txtBox"
                                  : "form-control"
                              }
                            />
                          </div>
                        );
                      })}
                      <div className="mb-2 mt-3 col-lg-12">
                        <TextBox
                          label="Please add comment"
                          placeholder="Enter your text"
                          id={transaction.textboxName}
                          name={transaction.textboxName}
                          chapter={this.props.chapter}
                          reducer={this.props.reducer}
                          isRequired={
                            this.props.form === "UK" ? false : isRequired
                          }
                          validationType="Text"
                          className={
                            this.props.PdfGeneration === "Yes"
                              ? "txtBox"
                              : "form-control"
                          }
                        />
                        {errorsMsg &&
                          errorsMsg?.isSubmitted === true &&
                          errorsMsg[transaction.textboxName] !== "" && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              {errorsMsg[transaction.textboxName]}
                            </span>
                          )}
                      </div>
                    </Row>
                  </div>
                )}
            </>
          );
        })}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { externalReducer, chapter, form } = ownProps;
  if (
    form === "NL" ||
    form === "Curacao" ||
    form === "Malta" ||
    form === "Luxembourg"
  ) {
    return {
      isUBOInvstObjComp:
        state.commonFields.srcFundsObjComp["isUBOInvstObjComp"],
      isUPCfundObjComp: state.commonFields.srcFundsObjComp["isUPCfundObjComp"],
      isTpInvstObjComp: state.commonFields.srcFundsObjComp["isTpInvstObjComp"],
    };
  } else {
    return {
      isUBOInvstObjComp:
        state[externalReducer][chapter].srcFundsObjComp["isUBOInvstObjComp"],
      isUPCfundObjComp:
        state[externalReducer][chapter].srcFundsObjComp["isUPCfundObjComp"],
      isTpInvstObjComp:
        state[externalReducer][chapter].srcFundsObjComp["isTpInvstObjComp"],
    };
  }
};

export default connect(mapStateToProps, null)(TypeSizeFreq);
