import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import TextBox from "../../../../elements/TextBox";

class BusinessIntro extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { businessIntroduction } = this.props;

    return (
      <>
        <div className="html2pdf__page-break"></div>
        <h4 className="sub-chapter-title mb-4">
          Details of Business Introduction
        </h4>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Third Party Referral"
              name="thirdPartyRefApprvl"
              id="thirdPartyRefApprvl"
              options={["Yes", "No"]}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {businessIntroduction.thirdPartyRefApprvl === "Yes" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Third Party Details"
                name="thirdPartyDetails"
                id="thirdPartyDetails"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Group Referral"
              name="grpRefApprvl"
              id="grpRefApprvl"
              options={["Yes", "No"]}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {businessIntroduction.grpRefApprvl === "Yes" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Office and Individual"
                name="officeIndividualDetails"
                id="officeIndividualDetails"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Existing Client Referral"
              name="exstngClntRefApprvl"
              id="exstngClntRefApprvl"
              options={["Yes", "No"]}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {businessIntroduction.exstngClntRefApprvl === "Yes" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Client Details"
                name="clientDetails"
                id="clientDetails"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Personal contact/ direct prospecting"
              name="prsnCntctDirePrspctngApprvl"
              id="prsnCntctDirePrspctngApprvl"
              options={["Yes", "No"]}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {businessIntroduction.prsnCntctDirePrspctngApprvl === "Yes" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="UIM UK staff Details"
                name="uimUKStaffDetails"
                id="uimUKStaffDetails"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Walk In"
              name="walkInApprvl"
              id="walkInApprvl"
              options={["Yes", "No"]}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {businessIntroduction.walkInApprvl === "Yes" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="How did they hear of UIM"
                name="hearofUIM"
                id="hearofUIM"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Internet"
              name="internetApprvl"
              id="internetApprvl"
              options={["Yes", "No"]}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { chapter, externalReducer } = ownProps;
  return {
    businessIntroduction:
      state[externalReducer][chapter]["businessIntroduction"],
  };
};

export default connect(mapStateToProps, null)(BusinessIntro);
