import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import {getToken} from "../getToken"
const MSLOGIN_CLIENTID = process.env.REACT_APP_MSLOGIN_CLIENTID;
const MSLOGIN_REDIRECTURL = process.env.REACT_APP_MSLOGIN_REDIRECTURL;
// const getToken = async () => {
//   const cfg = {
//     auth: {
//       clientId: MSLOGIN_CLIENTID,
//       authority:
//         "https://login.microsoftonline.com/37ab276a-6d7b-4b26-95c4-23f9fd7d5786", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
//       redirectUri: MSLOGIN_REDIRECTURL,
//     },
//     cache: {
//       cacheLocation: "localStorage", // This configures where your cache will be stored
//       storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
//     },
//   };

//   const pca = new PublicClientApplication(cfg);
//   let allAccounts = JSON.parse(localStorage.getItem("allAccounts"));
//   const account = allAccounts[0];
//   let idToken = null;
//   try {
//     const resp = await pca.acquireTokenSilent({
//       scopes: ["User.Read"],
//       account,
//     });
//     idToken = resp.idToken;
//     return idToken;
//   } catch (err) {
//     return;
//   }
// };
const BACKEND_API_URL = process.env.REACT_APP_INTERNAL_USER_BACKEND_API_URL;
const GetUKInternalCAFRejectCommentsRequest = () => {
  return {
    type: "GET_UK_INTERNAL_CAF_RejectComments_REQUEST",
  };
};
const GetUKInternalCAFRejectCommentsReceiveData = (json) => {
  return {
    type: "GET_UK_INTERNAL_CAF_Reject_SUCCESS",
    data: json,
  };
};
export const getUKInternalCAFRejectComments = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(GetUKInternalCAFRejectCommentsRequest());
    return axios
      .post(`${BACKEND_API_URL}/UKCAF/GetAllRejectComents`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({
            type: "GET_UK_INTERNAL_CAF_RejectComments_FAIL",
            data: json,
          });
        } else {
          return dispatch(GetUKInternalCAFRejectCommentsReceiveData(json));
        }
      })
      .catch((err) => {
       return dispatch({
          type: "GET_UK_INTERNAL_CAF_RejectComments_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};

const SetUKInternalCAFRejectRequest = () => {
  return {
    type: "SET_UK_INTERNAL_CAF_Reject_REQUEST",
  };
};
const SetUKInternalCAFRejectReceiveData = (json) => {
  return {
    type: "SET_UK_INTERNAL_CAF_Reject_SUCCESS",
    data: json,
  };
};
export const setUKInternalCAFReject = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(SetUKInternalCAFRejectRequest());
    return axios
      .post(`${BACKEND_API_URL}/UKCAF/SetCAFReject`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({
            type: "SET_UK_INTERNAL_CAF_Reject_FAIL",
            data: json,
          });
        } else {
          return dispatch(SetUKInternalCAFRejectReceiveData(json));
        }
      })
      .catch((err) => {
       return dispatch({
          type: "SET_UK_INTERNAL_CAF_Reject_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};

const SetUKInternalCAFDetailsRequest = () => {
  return {
    type: "SET_UK_INTERNAL_CAF_DETAILS_REQUEST",
  };
};

const SetUKInternalCAFDetailsReceiveData = (json) => {
  return {
    type: "SET_UK_INTERNAL_CAF_DETAILS_SUCCESS",
    data: json,
  };
};

export const setUKInternalCAFDetails = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(SetUKInternalCAFDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/UKCAF/SetCAFDetails`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({
            type: "SET_UK_INTERNAL_CAF_DETAILS_FAIL",
            data: json,
          });
        } else {
          return dispatch(SetUKInternalCAFDetailsReceiveData(json));
        }
      })
      .catch((err) => {
       return dispatch({
          type: "SET_UK_INTERNAL_CAF_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};

const GetUKInternalCAFDetailsRequest = () => {
  return {
    type: "GET_UK_INTERNAL_CAF_DETAILS_REQUEST",
  };
};

const GetUKInternalCAFDetailsReceiveData = (json) => {
  return {
    type: "GET_UK_INTERNAL_CAF_DETAILS_SUCCESS",
    data: json,
  };
};

export const getUKInternalCAFDetails = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(GetUKInternalCAFDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/UKCAF/GetCAFInternal`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({
            type: "GET_UK_INTERNAL_CAF_DETAILS_FAIL",
            data: json,
          });
        } else {
          return dispatch(GetUKInternalCAFDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        return dispatch({
          type: "GET_UK_INTERNAL_CAF_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};
