import React, { Component } from "react";
import TextBox from "../../../../elements/TextBox";

class BillingAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { chapter, reducer, objBaseName, errors } = this.props;
    const errorsMsg = JSON.parse(errors);
    return (
      <div className="mb-2 col-lg-12">
        <fieldset className="eqheight">
          <legend>Billing Address</legend>
          <div className="row mb-0">
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Name"
                placeholder="Enter name"
                name="name"
                id="name"
                reducer={reducer}
                chapter={chapter}
                objBaseName={objBaseName}
                validationType="Text"
                //isRequired={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.billingAddress &&
                errorsMsg?.billingAddress?.name?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg?.billingAddress.name}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-8">
              <TextBox
                label="Address"
                placeholder="Enter address"
                name="address"
                id="address"
                reducer={reducer}
                chapter={chapter}
                objBaseName={objBaseName}
                //isRequired={true}
                validationType="Text"
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />{" "}
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.billingAddress &&
                errorsMsg?.billingAddress?.address?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg?.billingAddress.address}
                  </span>
                )}
            </div>
          </div>
        </fieldset>
      </div>
    );
  }
}

export default BillingAddress;
