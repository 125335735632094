import React, { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./adduser.scss";
import {
  addUser,
  getUserById,
  updateUser,
  assignClientList,
} from "../../../redux/actions/userActions";
import { getClientList } from "../../../redux/actions/clientActions";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { Base64 } from "js-base64";
import Select from "react-select";
import jwt_decode from "jwt-decode";

/* eslint-disable no-useless-escape */

const userRolesArray = ["Client", "Authorized Representative"];
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
const styles = {
  Container: (base) => {
    return { ...base };
  },
  multiValue: (base, state) => {
    return state.data.isFixed
      ? { ...base, backgroundColor: "#d2d2d2", borderRadius: "25px" }
      : { ...base, backgroundColor: "white", borderRadius: "25px" };
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: "500", color: "#a5a5a5", paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed
      ? { ...base, display: "none" }
      : {
          ...base,
          borderRadius: "50%",
          background: "white",
          height: "20px",
          width: "20px",
          marginTop: "4px",
          color: "#b00335",
          border: "1px solid #b00335",
        };
  },
};

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      isLoading: false,
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      userRole: "Client",
      mobileNumber: "",
      AssignToClientList: [],
      errors: {},
      clientList: [],
      dialCode: "+31",
      tokenData: null,
    };
  }

  //handle change on all inputs
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidMount = async () => {
    let originalClientList = [];
    let token = JSON.parse(localStorage.getItem("authToken"));
    if (token) {
      var decoded = jwt_decode(token);
      var data = {
        name: decoded.name,
      };
      await this.props.getClientList(data).then((res) => {
        if (res.data.portalErrorCode === 200) {
          this.setState({ isLoading: false });
          this.setState({ clientList: res?.data?.result });
          originalClientList = res.data?.result;
        }
      });
    }
    if (this.state.id) {
      this.setState({ isLoading: true });
      let reqData = {
        id: this.state.id,
      };
      await this.props.getUserById(reqData).then((res) => {
        this.setState({ isLoading: false });
        if (res.data.portalErrorCode === 200) {
          const { result } = res.data;
          let assignToClientArray = [];
          result.assignClients?.length > 0 &&
            result.assignClients?.forEach((client) => {
              let flag = false;
              originalClientList.length > 0 &&
                originalClientList.forEach((originalClient) => {
                  if (
                    client.id === originalClient.id &&
                    originalClient.status === "PendingApproval"
                  ) {
                    flag = true;
                  }
                });
              if (flag) {
                let obj = {};
                obj["value"] = client.id;
                obj["label"] = client.name;
                obj["isFixed"] = true;
                assignToClientArray.push(obj);
              } else {
                let obj = {};
                obj["value"] = client.id;
                obj["label"] = client.name;
                obj["isFixed"] = false;
                assignToClientArray.push(obj);
              }
            });

          this.setState({
            firstname: result.firstName,
            lastname: result.lastName,
            email: result.email,
            password: Base64.decode(result.password),
            userRole: result.role,
            mobileNumber: result.phoneNo,
            AssignToClientList: assignToClientArray,
          });
        }
      });
    }
  };
  handleSelectionOfClients = (AssignToClientList, selectedItem) => {
    this.setState({ AssignToClientList });
  };

  handleDeletionOfClients = (AssignToClientList, deletedItem) => {
    this.setState({ AssignToClientList });
  };

  validateForm = () => {
    let errors = {};
    let formIsValid = true;
    const { firstname, lastname, email, password, userRole, mobileNumber } =
      this.state;

    const msg = "Please fill required detail";
    if (!firstname) {
      formIsValid = false;
      errors["firstname"] = msg;
    }
    if (!lastname) {
      formIsValid = false;
      errors["lastname"] = msg;
    }
    if (!email) {
      formIsValid = false;
      errors["email"] = msg;
    }
    if (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(email)) {
        formIsValid = false;
        errors["email"] = "Please enter valid email";
      }
    }
    if (!password) {
      formIsValid = false;
      errors["password"] = msg;
    }
    if (password) {
      if (!password.match(/^.*(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*$/)) {
        formIsValid = false;
        errors["password"] = "Please enter valid password";
      }
    }
    if (userRole === "Select Role") {
      formIsValid = false;
      errors["userRole"] = msg;
    }

    if (
      mobileNumber === "+" + this.state.dialCode ||
      mobileNumber === this.state.dialCode ||
      mobileNumber === ""
    ) {
      formIsValid = false;
      errors["mobileNumber"] = msg;
    }

    this.setState({ errors });
    return formIsValid;
  };

  clearInputs = () => {
    this.setState({
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      userRole: "Select Role",
      mobileNumber: "",
      AssignToClientList: [],
    });
  };

  manageAssignToClientList = () => {
    let clientList = [];
    this.state.AssignToClientList.length > 0 &&
      this.state.AssignToClientList.forEach((client) => {
        let obj = {};
        obj["id"] = client.value;
        obj["Name"] = client.label;
        clientList.push(obj);
      });
    return clientList;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const { firstname, lastname, email, password, userRole, mobileNumber } =
      this.state;
    this.setState({ isLoading: true });
    let errors = {};
    if (this.validateForm()) {
      let data = {
        FirstName: capitalizeFirstLetter(firstname),
        LastName: capitalizeFirstLetter(lastname),
        Email: email.toLowerCase(),
        Password: Base64.encode(password),
        Role: userRole,
        PhoneNo: mobileNumber,
        AssignClient: this.manageAssignToClientList(),
      };
      this.props
        .addUser(data)
        .then((res) => {
          this.setState({ isLoading: false });
          if (res.data.portalErrorCode === 200) {
            NotificationManager.success("User added successfully!");
            return this.props.history.push({
              pathname: "/userlist",
            });
          } else if (!res.data.isError) {
            errors["otherMessage"] = res.data.portalMessage;
          } else {
            errors["otherMessage"] = "Error while adding user";
          }
          this.setState({ errors });
        })
        .catch((err) => console.log(err));
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleUpdate = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const { id, firstname, lastname, email, password, userRole, mobileNumber } =
      this.state;
    let errors = {};
    this.setState({ isLoading: true });
    if (this.validateForm()) {
      let data = {
        id: id,
        FirstName: capitalizeFirstLetter(firstname),
        LastName: capitalizeFirstLetter(lastname),
        Email: email.toLowerCase(),
        Password: Base64.encode(password),
        Role: userRole,
        PhoneNo: mobileNumber,
        AssignClient: this.manageAssignToClientList(),
      };

      this.props
        .updateUser(data)
        .then((res) => {
          this.setState({ isLoading: false });
          if (res.data.portalErrorCode === 200) {
            NotificationManager.success("User updated successfully");
            return this.props.history.push({
              pathname: "/userlist",
            });
          } else if (!res.data.isError) {
            errors["otherMessage"] = res.data.portalMessage;
          } else {
            errors["otherMessage"] = "Error while adding user";
          }
          this.setState({ errors });
        })
        .catch((err) => console.log(err));
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleMultiSelectionChange = (value, { action, removedValue }) => {
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue.isFixed) {
          return;
        }
        break;
    }
    this.setState({ AssignToClientList: value });
  };
  handleOnChange = (value, data, event, formattedValue) => {
    this.setState({ mobileNumber: value, dialCode: data.dialCode });
  };

  render() {
    const {
      clientList,
      firstname,
      lastname,
      email,
      password,
      userRole,
      mobileNumber,
      AssignToClientList,
      errors,
    } = this.state;
    let clientArray = [];
    clientList.length > 0 &&
      clientList.forEach((client) => {
        if (client.status !== "PendingApproval") {
          let obj = {};
          obj["value"] = client.id;
          obj["label"] = client.name;
          clientArray.push(obj);
        }
      });

    return (
      <>
        <div className="dashboard">
          <div className="userlist">
            <div className="eventHeader pt-3 pb-3">
              <Container fluid>
                <Row className="align-items-center">
                  <Col sm={6} className="text-left">
                    <h2 className="mb-0 mt-2 pagetitle">User Maintenance</h2>
                  </Col>
                </Row>
              </Container>
            </div>

            <Container fluid>
              <Row className="pt-5">
                <Col lg={3}></Col>
                <Col lg={6}>
                  <h2 className="heading2">
                    {this.state.id ? "Edit User" : "Add User"}
                  </h2>
                  <div className="addUserflow">
                    <div className="errorMsg">{errors.otherMessage}</div>
                    <Row>
                      <Col lg="6" className="mb-2">
                        <label>First Name *</label>
                        <input
                          type="text"
                          value={firstname}
                          name="firstname"
                          placeholder="Enter your firstname"
                          className="input_field"
                          onChange={this.handleChange}
                        />
                        <div className="errorMsg">{errors.firstname}</div>
                      </Col>

                      <Col lg="6" className="mb-2">
                        <label>Last Name *</label>
                        <input
                          type="text"
                          name="lastname"
                          value={lastname}
                          placeholder="Enter your lastname"
                          className="input_field"
                          onChange={this.handleChange}
                        />
                        <div className="errorMsg">{errors.lastname}</div>
                      </Col>
                      <Col lg="6" className="mb-2">
                        <label>Email Address *</label>
                        <input
                          type="text"
                          name="email"
                          value={email}
                          placeholder="Enter your email address"
                          className="input_field"
                          onChange={this.handleChange}
                        />
                        <div className="errorMsg">{errors.email}</div>
                      </Col>
                      {!this.state.id && (
                        <Col lg="6" className="mb-2">
                          <label>Password *</label>
                          <input
                            type="password"
                            name="password"
                            value={password}
                            placeholder="******"
                            className="input_field"
                            onChange={this.handleChange}
                          />
                          <div className="errorMsg">{errors.password}</div>
                          <div className="helptext">
                            Password should be at least of 8 characters with
                            alpha-numerics, Caps &amp; special characters.
                          </div>
                        </Col>
                      )}

                      <Col lg="6" className="mb-2">
                        <label>User Role *</label>
                        {/* <input type="text" value="" placeholder="Enter your email address" className="input_field" /> */}
                        <select
                          className="input_field"
                          value={userRole}
                          name="userRole"
                          onChange={this.handleChange}
                        >
                          <option disabled defaultValue>
                            Select Role
                          </option>
                          {userRolesArray.map((role, index) => {
                            return (
                              <option value={role} key={index}>
                                {role}
                              </option>
                            );
                          })}
                        </select>
                        <div className="errorMsg">{errors.userRole}</div>
                      </Col>
                      <Col lg="6" className="mb-2">
                        <label>Mobile Number *</label>
                        <PhoneInput
                          country={"nl"}
                          className="input_field"
                          name="mobileNumber"
                          value={mobileNumber}
                          onChange={this.handleOnChange}
                        />
                        <div className="errorMsg">{errors.mobileNumber}</div>
                      </Col>
                      <Col lg="12" className="mb-2 autocomplete">
                        <label className="d-block">Assign to Client </label>
                        <Select
                          options={clientArray}
                          isMulti={true}
                          value={AssignToClientList}
                          onChange={this.handleMultiSelectionChange}
                          styles={styles}
                          isClearable={false}
                        />
                        <i
                          className="fa fa-search"
                          style={{
                            position: "absolute",
                            right: "25px",
                            top: "35px",
                          }}
                        ></i>

                        <div className="errorMsg">
                          {errors.AssignToClientList}
                        </div>
                      </Col>
                      <Col lg="12" className="mt-3">
                        {this.state.id ? (
                          <Button
                            className="action-btn big mr-4"
                            onClick={this.handleUpdate}
                          >
                            Update
                          </Button>
                        ) : (
                          <Button
                            className="action-btn big mr-4"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </Button>
                        )}

                        <Link
                          to="/userlist"
                          className="action-btn link font-weight-bold"
                        >
                          Cancel
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={3}></Col>
                {this.state.isLoading && <div className="loader"></div>}
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.user.data,
    user: state.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: async (data) => dispatch(await addUser(data)),
    getClientList: async (data) => dispatch(await getClientList(data)),
    getUserById: async (data) => dispatch(await getUserById(data)),
    updateUser: async (data) => dispatch(await updateUser(data)),
    assignClientList: async (data) => dispatch(await assignClientList(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
