import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getUBOUIMDetails,
  makeReducerEmpty,
} from "../../../../../redux/actions/UBOdeclarationUIMactions";
import PartA from "./PartA/PartA";
import PartB from "./PartB/PartB";
import PartC from "./PartC/PartC";
import PartE from "./PartE/PartE";
import FamilyAndAnuualIncome from "./FamilyAndAnuualIncome";
import SignatureComponent from "./SignatureComponent";
import MaltaDeclaration from "./MaltaDeclaration";

const reducer = "UBODeclarationUIM";
class UboDeclarationUIMDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  render() {
    const {
      isUBO,
      isPEP,
      isUBOPEP,
      setRefPEP,
      setRefUBO,
      setRefUBO2,
      errorMsg,
      name,
    } = this.props;
    let eMsg = JSON.parse(this.props.errorMsg);

    return (
      <>
        <div ref={setRefUBO} className="printPDFUBO">
          <PartA
            reducer={reducer}
            isUBO={isUBO}
            isPEP={isPEP}
            form={this.props.form}
            isUBOPEP={isUBOPEP}
            eMsg={errorMsg}
          />
          <PartB
            reducer={reducer}
            isUBO={isUBO}
            isPEP={isPEP}
            isUBOPEP={isUBOPEP}
            errorMsg={errorMsg}
            form={this.props.form}
          />
        </div>

        <div ref={setRefPEP} className="printPDFUBO">
          {(isPEP === "Yes" || (isUBO === "Yes" && isUBOPEP === "Yes")) && (
            <>
              <div className="html2pdf__page-break"></div>
              <div id="familyAnnualIncome"></div>
              <FamilyAndAnuualIncome
                reducer={reducer}
                errorMsg={errorMsg}
                classDisplayFlag={this.props.classDisplayFlag}
              />
            </>
          )}
        </div>
        {eMsg !== null &&
          Object.entries(eMsg).length !== 0 &&
          eMsg?.partA?.familyAnnualIncome?.length > 0 && (
            <span style={{ color: "red", fontSize: "14px" }}>
              {eMsg?.partA?.familyAnnualIncome}
            </span>
          )}
        <div ref={setRefUBO2} className="printPDFUBO">
          <PartC
            reducer={reducer}
            isUBO={isUBO}
            isPEP={isPEP}
            form={this.props.form}
            isUBOPEP={isUBOPEP}
            errorMsg={errorMsg}
          />

          <div className="html2pdf__page-break"></div>
          {this.props.form === "Malta" ? (
            <MaltaDeclaration reducer={reducer} errorMsg={errorMsg} />
          ) : (
            <PartE isUBO={isUBO} isPEP={isPEP} isUBOPEP={isUBOPEP} />
          )}
          <div className="html2pdf__page-break"></div>
          <SignatureComponent
            reducer={reducer}
            from="uboDeclarationPep"
            errorMsg={eMsg}
            disabled={true}
            name={name || localStorage.getItem("name")}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientInformation: state.clientInformation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUBOUIMDetails: (data) => dispatch(getUBOUIMDetails(data)),
    makeReducerEmpty: () => dispatch(makeReducerEmpty()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UboDeclarationUIMDashboard);
