import axios from "axios";

const BACKEND_API_URL = process.env.REACT_APP_USER_BACKEND_API_URL;

const setNewLuxembourgDataToReducerReceiveData = (json) => {
  return {
    type: "UPDATE_NEW_LUXEMBOURG_DATA",
    data: json,
  };
};

export const setNewLuxembourgDataToReducer = (data) => {
  return (dispatch) => {
    dispatch(setNewLuxembourgDataToReducerReceiveData(data));
  };
};

const SetLuxembourgCAFDetailsRequest = () => {
  return {
    type: "SET_LUXEMBOURG_CAF_DETAILS_REQUEST",
  };
};

const SetLuxembourgCAFDetailsReceiveData = (json) => {
  return {
    type: "SET_LUXEMBOURG_CAF_DETAILS_SUCCESS",
    data: json,
  };
};

export const setLuxembourgCAFDetails = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(SetLuxembourgCAFDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/LuxembourgCAF/SetCAFDetails`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({
            type: "SET_LUXEMBOURG_CAF_DETAILS_FAIL",
            data: json,
          });
        } else {
          return dispatch(SetLuxembourgCAFDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "SET_LUXEMBOURG_CAF_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};

const GetLuxembourgCAFDetailsRequest = () => {
  return {
    type: "GET_LUXEMBOURG_CAF_DETAILS_REQUEST",
  };
};

const GetLuxembourgCAFDetailsReceiveData = (json) => {
  return {
    type: "GET_LUXEMBOURG_CAF_DETAILS_SUCCESS",
    data: json,
  };
};

export const getLuxembourgCAFDetails = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(GetLuxembourgCAFDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/LuxembourgCAF/GetCAFExternal`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({
            type: "GET_LUXEMBOURG_CAF_DETAILS_FAIL",
            data: json,
          });
        } else {
          return dispatch(GetLuxembourgCAFDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "GET_LUXEMBOURG_CAF_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};
