import {
  SET_ALL_LUXEMBOURG_ERRORS_TO_NULL,
  SET_LUXEMBOURG_ERROR_FOR_CHAPTER,
} from "../../constants/Luxembourg/LuxembourgInternalChapterErrorConstants";

export const setLuxembourgInternalChapterError = (data) => {
  return (dispatch) => {
    return dispatch({ type: SET_LUXEMBOURG_ERROR_FOR_CHAPTER, data: data });
  };
};

export const setAllLuxembourgErrorsToNull = () => {
  return (dispatch) => {
    return dispatch({ type: SET_ALL_LUXEMBOURG_ERRORS_TO_NULL });
  };
};
