import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import CombineReducers from "../reducers/combineReducers";

export default function ConfigureStore(initialState) {
  const composeEnhancers =
    process.env.NODE_ENV !== "production" &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const middleware = [thunk];

  const store = createStore(
    CombineReducers,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  return store;
}
