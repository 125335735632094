import { AnnexUBO } from "../../app/JSONs/UBOJSON/AnnexUBO";
import { AnnexNoUBO } from "../../app/JSONs/UBOJSON/AnnexNoUBO";

const initialState = {
  AnnexUBO: AnnexUBO,
  AnnexNoUBO: AnnexNoUBO,
};

const AnnexUBOReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "UPDATE_ANNEX_UBO_DATA":
      const {
        baseName,
        index,
        name,
        value,
        chapter,
        objBaseName,
        nestedObjBaseName,
        noChapter,
      } = action.data;
      let array, obj;
      if (noChapter) {
        return {
          ...state,
          [name]: value,
        };
      } else if (!baseName && !objBaseName) {
        return {
          ...state,
          [chapter]: {
            ...state[chapter],
            [name]: value,
            dirty: true,
          },
        };
      } else if (nestedObjBaseName && objBaseName) {
        return {
          ...state,
          [chapter]: {
            ...state[chapter],
            [objBaseName]: {
              ...state[chapter][objBaseName],
              [nestedObjBaseName]: {
                ...state[chapter][objBaseName][nestedObjBaseName],
                [name]: value,
              },
            },
            dirty: true,
          },
        };
      } else if (objBaseName && !baseName) {
        return {
          ...state,
          [chapter]: {
            ...state[chapter],
            [objBaseName]: {
              ...state[chapter][objBaseName],
              [name]: value,
            },
            dirty: true,
          },
        };
      } else if (objBaseName && baseName) {
        array = state[chapter][objBaseName][baseName];

        obj = {
          ...array[index],
          [name]: value,
        };
        array[index] = obj;
        return {
          ...state,
          [chapter]: {
            ...state[chapter],
            [objBaseName]: {
              ...state[chapter][objBaseName],
              [baseName]: array,
            },
            dirty: true,
          },
        };
      } else {
        array = state[chapter][baseName];
        obj = {
          ...array[index],
          [name]: value,
        };
        array[index] = obj;
        return {
          ...state,
          [chapter]: {
            ...state[chapter],
            [baseName]: array,
            dirty: true,
          },
        };
      }

    case "SET_NEW_ANNEX_DATA":
      let data = action.data;
      if (data.isUBO === "Yes") {
        return {
          ...state,
          AnnexUBO: data.newData,
        };
      } else {
        return {
          ...state,
          AnnexNoUBO: data.newData,
        };
      }

    case "SET_NEW_ANNEX_FROM_API":
      let res = action.data?.data;
      if (res == null) {
        return {
          ...state,
          AnnexNoUBO: res,
        };
      }
      if (res["isUBO"] === "Yes") {
        return {
          ...state,
          AnnexUBO: res,
        };
      } else {
        return {
          ...state,
          AnnexNoUBO: res,
        };
      }

    case "GET_UBO_DECLARATIONS_SUCCESS":
      let response = action.data?.result;
      if (response.isUBO === "Yes") {
        let objRes = response;
        objRes["dirty"] = false;
        return {
          ...state,
          AnnexUBO: objRes,
        };
      } else if (response.isUBO === "No") {
        let objResNoUBO = response;
        objResNoUBO["dirty"] = false;
        return {
          ...state,
          AnnexNoUBO: objResNoUBO,
        };
      }
      break;

    case "CREATE_ANNEX_UBO_OBJECT":
      const { chap, maxIndex, referral, pushedObject, nestedObjName } =
        action.data;
      let arrayData;
      if (nestedObjName) {
        arrayData = state[chap][nestedObjName][referral];
      } else {
        arrayData = state[chap][referral];
      }
      if (arrayData !== null && arrayData.length < maxIndex) {
        let diff = maxIndex - arrayData.length;
        for (let i = 0; i < diff; i++) {
          arrayData.push(pushedObject);
        }
      }

      return {
        ...state,
        [chap]: {
          ...state[chap],
          [referral]: arrayData,
        },
      };

    case "REMOVE_ANNEX_UBO_OBJECT":
      const { remChap, remMaxIndex, remReferral, remNestedObjName } =
        action.data;

      let remArray;
      if (remNestedObjName) {
        remArray = state[remChap][remNestedObjName][remReferral];
      } else {
        remArray = state[remChap][remReferral];
      }

      if (remArray !== null && remArray.length < remMaxIndex) {
        let diff = remMaxIndex - remArray.length;
        for (let i = 0; i < diff; i++) {
          remArray.splice(-1);
        }
      }

      return {
        ...state,
        [remChap]: {
          ...state[remChap],
          [remReferral]: remArray,
        },
      };

    default:
      return state;
  }
};

export default AnnexUBOReducer;
