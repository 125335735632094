import React, { Component } from "react";
import { Row } from "react-bootstrap";
import countryList from "react-select-country-list";
import DropDown from "../../../../../../elements/DropDown";
import TextBox from "../../../../../../elements/TextBox";
import CheckBox from "../../../../../../elements/CheckBox";
import FileUpload from "../../../../../../elements/FileUpload";
import { fileAcceptType, NatureOfObjectComArray } from "../constants";
import { connect } from "react-redux";
class Settlors extends Component {
    constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, errorMsg,issettPassport,issettProofAdd,issettRefLett} = this.props;
    const eMsg = errorMsg;
    return (
      <div className="mb-4 col-lg-12">
        <fieldset>
          <legend>Settlor {this.props.index + 1}</legend>
          <Row>
          <div className="mb-2 col-lg-12 col-xl-12">
            <TextBox
              label="Settlor details"
              name="settlorsDetails"
              id={`settlorsDetails[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              validationType="Text"
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
            />
           {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objSettlors &&
                    errorMsg.objSettlors[index] &&
                    errorMsg.objSettlors[index]
                      ?.settlorsDetails?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objSettlors[index]
                            ?.settlorsDetails
                        }
                      </span>
                    )}
          </div>
          
          <div className="mb-3 col-lg-6">
            <CheckBox
            label="Settlor's passport or national identity card available?"
              name="issettPassport"
              options={["Yes", "No"]}
              id={`issettPassport[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
            />
           {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objSettlors &&
                    errorMsg.objSettlors[index] &&
                    errorMsg.objSettlors[index]
                      ?.issettPassport?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objSettlors[index]
                            ?.issettPassport
                        }
                      </span>
                    )}
          </div>
          {issettPassport === "Yes" && (
            <div className="mb-3 col-lg-4">
            
              <FileUpload
                label="Upload Settlor's Passport or national identity"
                acceptType={fileAcceptType}
                name="settPassport"
                id={`settPassport[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              //isRequired={this.props.form === "UK" ? true : false} 
              />
             {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objSettlors &&
                    errorMsg.objSettlors[index] &&
                    errorMsg.objSettlors[index]
                      ?.settPassport?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objSettlors[index]
                            ?.settPassport
                        }
                      </span>
                    )}
            </div>
          )} 
           {issettPassport === "No" && (
              <div className="mb-3 col-lg-4">
                  <TextBox
                label="Explanation"
                name="settPassportReason"
                id={`settPassportReason[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              validationType="Text"
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
              />
              {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objSettlors &&
                    errorMsg.objSettlors[index] &&
                    errorMsg.objSettlors[index]
                      ?.settPassportReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objSettlors[index]
                            ?.settPassportReason
                        }
                      </span>
                    )}

              </div>              
              )} 
        </Row> 
        <Row>
          <div className="mb-3 col-lg-6">
            <CheckBox
               label="Settlor's proof of address available?"
               name="issettProofAdd"
              options={["Yes", "No"]}
              id={`issettProofAdd[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objSettlors &&
                    errorMsg.objSettlors[index] &&
                    errorMsg.objSettlors[index]
                      ?.issettProofAdd?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objSettlors[index]
                            ?.issettProofAdd
                        }
                      </span>
                    )}
          </div>
          {issettProofAdd === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
               label="Upload Settlor's proof of addres"
                info="Not older than 3 months"
                acceptType={fileAcceptType}
                name="settProofAdd"
                id={`settProofAdd[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              //isRequired={this.props.form === "UK" ? true : false} 
              />
              {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objSettlors &&
                    errorMsg.objSettlors[index] &&
                    errorMsg.objSettlors[index]
                      ?.settProofAdd?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objSettlors[index]
                            ?.settProofAdd
                        }
                      </span>
                    )}
            </div>
          )}
          {issettProofAdd === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="settProofAddReason"
                id={`settProofAddReason[${index}]`}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}
                isRequired={this.props.form === "UK" ? true : false} 
              />
             {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objSettlors &&
                    errorMsg.objSettlors[index] &&
                    errorMsg.objSettlors[index]
                      ?.settProofAddReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objSettlors[index]
                            ?.settProofAddReason
                        }
                      </span>
                    )}
            </div>
          )}
        </Row>   
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-6">
            <CheckBox
               label="Settlor's reference letter available?"
              name="issettRefLett"
              options={["Yes", "No"]}
              id={`issettRefLett[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
            />
           {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objSettlors &&
                    errorMsg.objSettlors[index] &&
                    errorMsg.objSettlors[index]
                      ?.issettRefLett?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objSettlors[index]
                            ?.issettRefLett
                        }
                      </span>
                    )}
          </div>
          {issettRefLett === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Upload Settlor's reference letter"
                info="by Accountant, Banker or Lawyer"
                acceptType={fileAcceptType}
                name="settRefLett"
                id={`settRefLett[${index}]`}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objSettlors &&
                    errorMsg.objSettlors[index] &&
                    errorMsg.objSettlors[index]
                      ?.settRefLett?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objSettlors[index]
                            ?.settRefLett
                        }
                      </span>
                    )}
            </div>
          )}
          {issettRefLett === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="settRefLettReason"
                id={`settRefLettReason[${index}]`}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objSettlors &&
                    errorMsg.objSettlors[index] &&
                    errorMsg.objSettlors[index]
                      ?.settRefLettReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objSettlors[index]
                            ?.settRefLettReason
                        }
                      </span>
                    )}
            </div>
          )}
        </Row>     
        </fieldset>
      </div>
    );
  }
}

//export default Trustees;
const mapStateToProps = (state, ownProps) => {
    const { baseName, index, reducer,objBaseName,indexObjBaseName } = ownProps;
    return {
        issettPassport: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["issettPassport"],
        issettProofAdd: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["issettProofAdd"],
        issettRefLett:state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["issettRefLett"],
    };
  };
  
  export default connect(mapStateToProps, null)(Settlors);
