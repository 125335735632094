import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { connect } from "react-redux";
import { setAllCuracaoErrorsToNull } from "../../../../redux/actions/Curacao/CuracaoInternalChapterErrors";
let formData;

let validForms = {};

class CuracaoInternalSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: null,
    };
  }
  render() {
    const { location } = this.props.history;
    const { internalErrors } = this.props;
    Object.entries(internalErrors).forEach((errors) => {
      const [key, value] = errors;
      const error = value?.errors;
      if (error) {
        validForms[key] = error;
      }
    });
    setInterval(() => {
      if (!formData) {
        formData = localStorage.getItem("formData");
        if (formData) {
          this.setState({ formData: formData });
        }
      }
    }, 100);
    return (
      <div className="sidebar">
        <ul>
          {/* <li>
            <label>User Maintenance</label>

            <ul className="sidebar-innerlist">
              <li>
                <Link
                  to="/userlist"
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={location.pathname === "/userlist" ? "active" : ""}
                >
                  <i className="fa fa-user fa-fw"></i> User list
                </Link>
                <Link
                  to="/userapprovals"
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={
                    location.pathname === "/userapprovals" ? "active" : ""
                  }
                >
                  <i className="fa fa-check-circle fa-fw"></i> User Approvals
                </Link>
              </li>
            </ul>
          </li> */}
          <li>
            <label>Client Maintenance</label>

            <ul className="sidebar-innerlist">
              <li>
                <Link
                  to="/clientlist"
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={
                    location.pathname === "/clientlist" ? "active" : ""
                  }
                >
                  <i className="fa fa-user fa-fw"></i> Client list
                </Link>
                <Link
                  to="/clientapprovals"
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={
                    location.pathname === "/clientapprovals" ? "active" : ""
                  }
                >
                  <i className="fa fa-check-circle fa-fw"></i> Client User Approvals
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <label>Client Acceptance Form</label>
            <ul className="sidebar-innerlist">
              <li>
                <Link
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  to="/client-form-list"
                  className={
                    location.pathname === "/client-form-list" ? "active" : ""
                  }
                >
                  <i className="fa fa-user fa-fw"></i> Client Form List
                </Link>
              </li>
              <ul className="sidebar-innerlist subPoint">
            <li>
              <Link
                to="/curacao/editClientForm"
                style={{
                  pointerEvents: formData ? "" : "none",
                  opacity: formData ? "" : "0.5",
                  fontWeight:
                    validForms?.formErrorschapter1?.isValid === true
                      ? "bolder"
                      : "",
                }}
                className={
                  location.pathname === "/curacao/editClientForm"
                    ? "active"
                    : ""
                }
              >
                <i className="fa fa-check fa-fw"></i> Set-up form {" "}
              </Link>
            </li>
           
            <li>
              <Link
                style={{
                  pointerEvents: formData ? "" : "none",
                  opacity: formData ? "" : "0.5",
                  fontWeight:
                    validForms?.formErrorschapter12?.isValid === true
                      ? "bolder"
                      : null,
                }}
                to="/curacao/signature"
                className={
                  location.pathname === "/curacao/signature"
                    ? "active"
                    : ""
                }
              >
                <i className="fa fa-pencil-square-o fa-fw"></i> Formal sign off
              </Link>
            </li>
          </ul>
            </ul>
          </li>
        
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    internalErrors: state.CuracaoInternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAllErrorToNull: () => dispatch(setAllCuracaoErrorsToNull()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CuracaoInternalSidebar)
);
