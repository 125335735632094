import React, { Component } from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../../../elements/TextBox";

class SourceOfFunds extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, errorMsg } = this.props;
    const eMsg = JSON.parse(errorMsg);
    return (
      <>
        <h4 className="sub-chapter-title mb-4">Source of Funds</h4>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Bank Name"
              placeholder="Enter bank name"
              name="bankName"
              id={`bankName[${index}]`}
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={index}
              baseName={this.props.baseName}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {eMsg !== null &&
              Object.entries(eMsg).length !== 0 &&
              eMsg.ubox &&
              eMsg.ubox[index] &&
              eMsg.ubox[index]?.bankName?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {eMsg.ubox[index]?.bankName}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Location"
              placeholder="Enter location"
              name="location"
              id={`location[${index}]`}
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={index}
              baseName={this.props.baseName}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {eMsg !== null &&
              Object.entries(eMsg).length !== 0 &&
              eMsg.ubox &&
              eMsg.ubox[index] &&
              eMsg.ubox[index]?.location?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {eMsg.ubox[index]?.location}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Remitter Name"
              placeholder="Enter remitter name"
              name="remitterName"
              id={`remitterName[${index}]`}
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={index}
              baseName={this.props.baseName}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {eMsg !== null &&
              Object.entries(eMsg).length !== 0 &&
              eMsg.ubox &&
              eMsg.ubox[index] &&
              eMsg.ubox[index]?.remitterName?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {eMsg.ubox[index]?.remitterName}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-12">
            <TextBox
              label="Address"
              placeholder="Enter address"
              name="address"
              id={`address[${index}]`}
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={index}
              baseName={this.props.baseName}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {eMsg !== null &&
              Object.entries(eMsg).length !== 0 &&
              eMsg.ubox &&
              eMsg.ubox[index] &&
              eMsg.ubox[index]?.address?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {eMsg.ubox[index]?.address}
                </span>
              )}
          </div>
        </Row>
      </>
    );
  }
}

export default SourceOfFunds;
