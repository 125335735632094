export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const Authentication_USER_REQUEST = "Authentication_USER_REQUEST";
export const Authentication_USER_SUCCESS = "Authentication_USER_SUCCESS";
export const Authentication_USER_FAIL = "Authentication_USER_FAIL";

export const Authentication_UBO_REQUEST = "Authentication_UBO_REQUEST";
export const Authentication_UBO_SUCCESS = "Authentication_UBO_SUCCESS";
export const Authentication_UBO_FAIL = "Authentication_UBO_FAIL";

export const VERIFY_USER_REQUEST = "VERIFY_USER_REQUEST";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_FAIL = "VERIFY_USER_FAIL";

export const VERIFY_UBO_REQUEST = "VERIFY_UBO_REQUEST";
export const VERIFY_UBO_SUCCESS = "VERIFY_UBO_SUCCESS";
export const VERIFY_UBO_FAIL = "VERIFY_UBO_FAIL";

export const DELETE_UBO_REQUEST = "DELETE_UBO_REQUEST";
export const DELETE_UBO_SUCCESS = "DELETE_UBO_SUCCESS";
export const DELETE_UBO_FAIL = "DELETE_UBO_FAIL";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const GET_USER_LIST_REQUEST = "GET_USER_LIST_REQUEST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAIL = "GET_USER_LIST_FAIL";

export const GET_USER_BY_USERID_REQUEST = "GET_USER_BY_USERID_REQUEST";
export const GET_USER_BY_USERID_SUCCESS = "GET_USER_BY_USERID_SUCCESS";
export const GET_USER_BY_USERID_FAIL = "GET_USER_BY_USERID_FAIL";

export const BULK_DELETE_REQUEST = "BULK_DELETE_REQUEST";
export const BULK_DELETE_SUCCESS = "BULK_DELETE_SUCCESS";
export const BULK_DELETE_FAIL = "BULK_DELETE_FAIL";

export const APPROVE_REJECT_FROM_MAIL_REQUEST =
  "APPROVE_REJECT_FROM_MAIL_REQUEST";
export const APPROVE_REJECT_FROM_MAIL_SUCCESS =
  "APPROVE_REJECT_FROM_MAIL_SUCCESS";
export const APPROVE_REJECT_FROM_MAIL_FAIL = "APPROVE_REJECT_FROM_MAIL_FAIL";

export const USER_APPROVAL_LIST_REQUEST = "USER_APPROVAL_LIST_REQUEST";
export const USER_APPROVAL_LIST_SUCCESS = "USER_APPROVAL_LIST_SUCCESS";
export const USER_APPROVAL_LIST_FAIL = "USER_APPROVAL_LIST_FAIL";

export const APPROVE_REJECT_USER_REQUEST = "APPROVE_REJECT_USER_REQUEST";
export const APPROVE_REJECT_USER_SUCCESS = "APPROVE_REJECT_USER_SUCCESS";
export const APPROVE_REJECT_USER_FAIL = "APPROVE_REJECT_USER_FAIL";

export const ASSIGN_CLIENT_LIST_REQUEST = "ASSIGN_CLIENT_LIST_REQUEST";
export const ASSIGN_CLIENT_LIST_SUCCESS = "ASSIGN_CLIENT_LIST_SUCCESS";
export const ASSIGN_CLIENT_LIST_FAIL = "ASSIGN_CLIENT_LIST_FAIL";

export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const SET_PROFILE_REQUEST = "SET_PROFILE_REQUEST";
export const SET_PROFILE_SUCCESS = "SET_PROFILE_SUCCESS";
export const SET_PROFILE_FAIL = "SET_PROFILE_FAIL";

export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";

export const DOWNLOAD_FATCA_REQUEST = "DOWNLOAD_FATCA_REQUEST";
export const DOWNLOAD_FATCA_SUCCESS = "DOWNLOAD_FATCA_SUCCESS";
export const DOWNLOAD_FATCA_FAIL = "DOWNLOAD_FATCA_FAIL";

export const GET_USER_OFFICE_REQUEST = "GET_USER_OFFICE_REQUEST";
export const GET_USER_OFFICE_SUCCESS = "GET_USER_OFFICE_SUCCESS";
export const GET_USER_OFFICE_FAIL = "GET_USER_OFFICE_FAIL";
