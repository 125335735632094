export const CustActivityRisksMalta = {
  risk: null,
  riskType: null,
  riskLevel: null,
  preventiveMeasure: null,
};

export const AdditionDocs = {
  addDocUpload: [],
  addDocExplain: null,
};

export const Chapter1 = {
  structureChartComm: null,
  relvClientComment: null,
  isInLineUIMAccPolicy: null,
  isMediaCoverageOnStruct: null,
  mediaCmnt: null,
  isAddDocs: null,
  numAddDocs: 1,
  addDocs: [AdditionDocs],
  isRisk: null,
  numCustActivityRisks: 1,
  custActivityRisks: [CustActivityRisksMalta],
  isUIMnoResonDiscntnuBusRel: null,
  reportOnActivities: null,
  dirty: false,
};
