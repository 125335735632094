export const getDependentReducer = (office) => {
  switch (office) {
    case "Netherlands":
      return "clientInformation";
    case "United Kingdom":
      return "UKClientInformation";
    case "Curacao":
      return "CuracaoClientInformation";
    case "Malta":
      return "MaltaClientInformation";
    case "Luxembourg":
      return "LuxembourgClientInformation";
    default:
      return "MaltaClientInformation";
  }
};
