export const chapter1 = {
  structureChart: "",
  isCompIncorporated: "",
  entities: [],
  isClientNew: "",
  clientUIMMalta: "",
  introducerName: "",
  companyNameMal: "",
  introducerNationality: "",
  doesClientIntrdNewPer: "",
  clientPersName: "",
  clientPersNationality: "",
  clientPersComp: "",
  descNewPer: "",
  upcActivites: "",
  upcSector: "",
  shareholderActivites: "",
  shareholderSector: "",
  objCompActivities: "",
  objCompSector: "",
  participantActivites: "",
  participantSector: "",
  otherActivites: "",
  otherSector: "",
};
export const chapter2 = {
  isClientRepInvolved: "",
  intermediaryDetails: [],
  isClientTransfrd: "",
  srvcPrvdrName: "",
  reasonClientTransfrd: "",
};
export const chapter3 = {
  isHoldingInterestReason: "",
  isUBOClassifiedReason: "",
  isAssetClientReason: "",
  ubox: [],
  isUPCExists: "",
  nameUPC: "",
  addressRegistered: "",
  postalCode: "",
  city: "",
  country: "",
  websiteUPC: "",
  taxNum: "",
  tradeRegisterNum: "",
  phoneNumberUPC: "",
  countryOfTaxRsdnc: "",
  typeOfUPC: "",
  isForceCloseFinAcc: "",
  forceCloseFinAcc: "",
  upcAuthDirName: "",
  upcAuthDirEmail: "",
  upcAuthDirNationality: "",
  upcAuthDirUtilityBill: "",
  upcAuthDirPass: "",
  extractChamberOfCommerce: "",
  deedOfIncorporation: "",
  shareholdersRegister: "",
  shareholdersCertificates: "",
  nomineeAgreement: "",
  financialStatementsThreeYears: "",
  isShareholderUBO: "",
};
export const chapter4 = {
  isObjCompExists: "",
  objCompName: "",
  commercialRegister: "",
  regOffMal: "",
  typOfCompMal: "",
  typeCmpOthrSpcfy: "",
  objCompDirectorNames: [],
  isCmpSecExist: "",
  cmpSectName: "",
  cmpSecResAddress: "",
  cmpPassIdntyRegNum: "",
  cmpPlaceOfIss: "",
  cmpDtIssue: "",
  cmpExprDt: "",
  cmpTelPhnNum: "",
  cmpEmail: "",
  cmpSecNationality: "",
  isAcctngYrChng: "",
  acctngDtChng: "",
  antcptAnnTurnFinStmts: "",
  isCntcPersnExist: "",
  cntctPrsnName: "",
  cntctPrsnResAdd: "",
  cntctPrsnTelPhnNum: "",
  cntctPrsnEmail: "",
  cntctPrsnMailingAdd: "",
  cntctNationality: "",
  prtclrInstructions: "",
  objCompActiveCountry: "",
  objCompNaturePropsdObjctv: "",
  objCompOtherNaturePropsdObjctv: "",
  isObjCompBranches: "",
  objCompBranch: "",
  rationaleStructurigForNL: "",
  rationaleSelectedInfo: "",
  taxAdvisorFinds: "",
  isShrCapComp: "",
  shrCapCurrency: "",
  authrzdShrCap: "",
  issuedShrCap: "",
  shrCapDenm: "",

  extractChamberOfCommerce4: "",
  deedOfIncorporation4: "",
  shareholdersRegister4: "",
  shareholdersCertificates4: "",
  financialStatmntsThreeYears4: "",
  utilityBills: "",
  refLetter: "",

  isAPA: "",
  objCompTaxResult: "",
  explanation: "",
  otherDocs: "",
  sttngCompCntrct: "",
  serviceNames: "",
  byWhom: "",
  othrService: "",
  isOffRegstrd: "",
  serviceNames2: "",
  comAddComReg: "",
  othrService2: "",

  descClientNeeds: "",
  benefitOfClient: "",
  taxImplications: "",
  busAcptncCnflctIntrst: "",
  certifiedCopyIdentity: "",
  bankersRef: "",
  profRef: "",
  declrGoodStndng: "",
  formDocConfmIdntyShrHold: "",
  recGoodStndngCrt: "",
  cpyIncorDoc: "",
  relCompaniesHse: "",
  ltstFinStmnts: "",
  isUBOInvstObjComp: "",
  uboInvstCurrencyObjComp: "",
  uboInvstObjCompAmnt: "",
  uboInvstObjCompOrigin: "",
  isUPCfundObjComp: "",
  upcFundObjCompOrigin: "",
  annualFinStmnt: "",
  isTpInvstObjComp: "",
  tpName: "",
  tpCountry: "",
  tpAnnualFinStmnts: "",
  cntrysInvlvdTrnsctnsObjComp: "",
  estmtdAnnualTrnOvrObjComp: "",
  assetSummryObjComp: "",
  assetSummryEvidence: "",
  expctdTrnsctnsSummary: "",
  expctdTrnsctnsEvidence: "",
  objCompToUBO: "",
  uboToObjComp: "",
  objCompToGrpComp: "",
  grpCompToObjComp: "",
  objCompToTP: "",
  tpToObjComp: "",
  rightsAndObligations: "",
  bnkInfo: "",
};
export const chapter5 = { countryList: "" };
// export const chapter6 = {
//   isExternalDirectors: "",
//   isExternalDirectorsNaturalPerson: "",
//   nameOfNaturalPerson: "",
//   nationality: "",
//   isOneOrMoreExtDirectorsAuthorized: "",
// };
