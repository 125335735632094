import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import TextBox from "../../../../elements/TextBox";

class UboQuestions extends React.Component {
  render() {
    const {
      chapter,
      reducer,
      externalReducer,
      isHoldingInterest,
      isUBOClassified,
      isAssetClient,
      errors,
      isRequired,
    } = this.props;
    let errorsMsg = JSON.parse(errors);

    return (
      <>
        <Row className="mt-3 mb-2">
          <div className="mb-0 col-lg-6">
            <CheckBox
              label={
                this.props.form === "malta"
                  ? "Is there a natural person holding an interest of 10% or more in the capital interest of the target company or can the natural person exercise 10% or more of the voting rights "
                  : this.props.form === "NL"? "Is there a natural person holding an interest of 25% or more in the capital interest of the legal entity or can the natural person exercise 25% or more of the voting rights":                   
                  "Is there a natural person holding an interest of 10% or more in the capital interest of the legal entity or can the natural person exercise 10% or more of the voting rights "
              }
              options={["Yes", "No"]}
              name="isHoldingInterest"
              chapter="chapter3"
              reducer={externalReducer}
              bothDisabled={true}
            />
          </div>
          {isHoldingInterest === "Yes" && (
            <div className="mb-3 col-lg-6 fieldAlignment">
              <TextBox
                label="Describe how the UBO is directly related to the top entity"
                name="isHoldingInterestReason"
                placeholder="enter reason"
                chapter="chapter3"
                reducer={externalReducer}
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
          <div className="mb-3 mt-3 col-lg-12">
            <TextBox
              label={this.props.form=="NL"? "Description": "Enter your comment"}
              name="isHldngIntrstCmmnt"
              id="isHldngIntrstCmmnt"
              placeholder="Enter text"
              chapter={chapter}
              reducer={reducer}
              isRequired={isRequired}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorsMsg &&
              errorsMsg.isSubmitted === true &&
              errorsMsg?.isHldngIntrstCmmnt &&
              errorsMsg?.isHldngIntrstCmmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isHldngIntrstCmmnt}
                </span>
              )}
          </div>
        </Row>

        <Row className="mt-3 mb-2">
          <div className="mb-3 col-lg-6 fieldAlignment">
            <CheckBox
              label={
                this.props.form === "malta"
                  ? "Is there a natural person who legally holds,on behalf of third parties in virtue of a fiduciary agreement, 10% or more of the assets of the Target Company or has effective control over the Target Company?"
                  : "Is there a natural person with ultimate effective control who should also be classified as UBO? "
              }
              options={["Yes", "No"]}
              name="isUBOClassified"
              chapter="chapter3"
              reducer={externalReducer}
              checkBoxValue={isUBOClassified}
              bothDisabled={true}
            />
          </div>

          {isUBOClassified === "Yes" && (
            <div className="mb-3 col-lg-6 fieldAlignment">
              <TextBox
                label="Describe the reason why there are natural persons who are considered as persons who have ultimate effective control"
                placeholder="enter reason"
                name="isUBOClassifiedReason"
                id="isUBOClassifiedReason"
                chapter="chapter3"
                reducer={externalReducer}
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
          <div className="mb-3 col-lg-12">
            <TextBox
               label={this.props.form=="NL"? "Description": "Enter your comment"}
              name="isUBOClsifiedCmmnt"
              id="isUBOClsifiedCmmnt"
              placeholder="Enter text"
              chapter={chapter}
              reducer={reducer}
              isRequired={isRequired}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorsMsg &&
              errorsMsg.isSubmitted === true &&
              errorsMsg?.isUBOClsifiedCmmnt &&
              errorsMsg?.isUBOClsifiedCmmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isUBOClsifiedCmmnt}
                </span>
              )}
          </div>
        </Row>
        <Row className="mt-3 mb-2">
          <div className="mb-0 col-lg-6">
            <CheckBox
              label="Is there a natural person who holds 10 per cent or more of the assets of a client or a trust or has special control over 10 per cent or more of the assets of a client (beneficiary)."
              options={["Yes", "No"]}
              name="isAssetClient"
              chapter="chapter3"
              reducer={externalReducer}
              bothDisabled={true}
            />
          </div>
          {isAssetClient === "Yes" && (
            <div className="mb-0 col-lg-6 fieldAlignment">
              <TextBox
                label="Describe the reason why there are natural persons who are considered as beneficiaries"
                placeholder="enter reason"
                name="isAssetClientReason"
                chapter="chapter3"
                reducer={externalReducer}
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
          <div className="mb-3 mt-3 col-lg-12">
            <TextBox
              label={this.props.form=="NL"? "Description": "Enter your comment"}
              name="isAsstClientCmmnt"
              id="isAsstClientCmmnt"
              placeholder="Enter text"
              chapter={chapter}
              reducer={reducer}
              isRequired={isRequired}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorsMsg &&
              errorsMsg.isSubmitted === true &&
              errorsMsg?.isAsstClientCmmnt &&
              errorsMsg?.isAsstClientCmmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isAsstClientCmmnt}
                </span>
              )}
          </div>
          {isHoldingInterest === "No" &&
            isUBOClassified === "No" &&
            isAssetClient === "No" && (
              <div className="mb-3 col-lg-12">
                <TextBox
                  label={this.props.form=="NL"? "Description": "Enter your comment"}
                  name="noUBOClassifiedCmmnt"
                  id="noUBOClassifiedCmmnt"
                  placeholder="Enter text"
                  chapter={chapter}
                  reducer={reducer}
                  isRequired={isRequired}
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.noUBOClassifiedCmmnt?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.noUBOClassifiedCmmnt}
                    </span>
                  )}
              </div>
            )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { externalReducer } = ownProps;
  return {
    isHoldingInterest: state[externalReducer]["chapter3"]["isHoldingInterest"],
    isUBOClassified: state[externalReducer]["chapter3"]["isUBOClassified"],
    isAssetClient: state[externalReducer]["chapter3"]["isAssetClient"],
    isUPCExists: state.commonFields["isUPCExists"],
  };
};

export default connect(mapStateToProps, null)(UboQuestions);
