import React, { Component } from "react";
import "./NumberInput.css";
import { connect } from "react-redux";
import {
  setDataToReducer,
  createObject,
  removeObject,
} from "../../redux/actions/ClientInformationAction";
import { deleteUBO } from "../../redux/actions/userActions";
import { getReducer, increaseObjects, decreaseObjects } from "./getReducer";
class NumberInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  increaseValue = () => {
    if (this.props.value < this.props.maxLimit) {
      this.props.setDataToReducer({
        type: getReducer(this.props.reducer),
        chapter: this.props.chapter,
        index: this.props.index,
        name: this.props.name,
        value: this.props.value + 1,
        baseName: this.props.baseName,
        objBaseName: this.props.objBaseName,
      });
      let length = this.props.referral.length; 
      for (let i = 0; i < length; i++) {
        this.props.createObject({
          type: increaseObjects(this.props.reducer),
          chap: this.props.chapter,
          maxIndex: this.props.value + 1,
          referral: this.props.referral[i],
          pushedObject: this.props.pushedObject[i],
          nestedObjName: this.props.objBaseName,
          generateId: this.props.generateId,
          masterObj:this.props.masterObj,
          masterIndex:this.props.masterIndex,
        });
      }
    }
  };

  decreaseValue = () => {
    if (this.props.value > 1) {
      if (this.props.deleteData) {
        let array = this.props.deleteData;
        let last_element = array[array.length - 1];
        let id = last_element[this.props.generateId];
        let data = {
          id: id,
        };
        this.props.deleteUBO(data).then((res) => {
         
        });
      }
      this.props.setDataToReducer({
        type: getReducer(this.props.reducer),
        chapter: this.props.chapter,
        index: this.props.index,
        name: this.props.name,
        value: this.props.value - 1,
        baseName: this.props.baseName,
        objBaseName: this.props.objBaseName,
      });
      let length = this.props.referral.length;
      for (let i = 0; i < length; i++) {
        this.props.removeObject({
          type: decreaseObjects(this.props.reducer),
          remChap: this.props.chapter,
          remMaxIndex: this.props.value + 1,
          remReferral: this.props.referral[i],
          spliceObject: this.props.pushedObject[i],
          remNestedObjName: this.props.objBaseName,
          remmasterObj:this.props.masterObj,
          remmasterIndex:this.props.masterIndex,
        });
      }
    }
  };

  render() {
    const { label, name } = this.props;
    return (
      <div className="fieldAlignment">
        <label>{label}</label>
        <div>
          <button
            onClick={this.decreaseValue}
            className="NumberInputButton"
            disabled={this.props.disabled}
          >
            -
          </button>
          <input
            type="number"
            value={this.props.value}
            className="NumberInputField"
            name={name}
            readOnly
            disabled={this.props.disabled}
          />
          <button
            onClick={this.increaseValue}
            name={name}
            className="NumberInputButton"
            disabled={this.props.disabled}
          >
            +
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
    createObject: (data) => dispatch(createObject(data)),
    removeObject: (data) => dispatch(removeObject(data)),
    deleteUBO: (data) => dispatch(deleteUBO(data)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    name,
    chapter,
    baseName,
    index,
    objBaseName,
    reducer,
    nestedObjBaseName,
    isCommon,
  } = ownProps;

  if (isCommon) {
    if (objBaseName) {
      return {
        value: state[reducer][objBaseName][name],
      };
    } else {
      return {
        value: state[reducer][name],
      };
    }
  } else if (!baseName && !objBaseName) {
    return {
      value: state[reducer][chapter][name],
    };
  } else if (objBaseName && nestedObjBaseName) {
    return {
      value: state[reducer][chapter][objBaseName][nestedObjBaseName][name],
    };
  } else if (objBaseName && !baseName) {
    return {
      value: state[reducer][chapter][objBaseName][name],
    };
  } else if (objBaseName && baseName) {
    return {
      value: state[reducer][chapter][objBaseName][baseName][index][name],
    };
  } else {
    return {
      value: state[reducer][chapter][baseName][index][name],
    };
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NumberInput);
