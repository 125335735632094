import React, { Component } from "react";
import CuracaoSidebar from "./CuracaoSidebar";
import LuxembourgSidebar from "./LuxembourgSidebar";
import MaltaSidebar from "./MaltaSidebar";
import NLExternalSidebar from "./NLExternalSidebar";
import UKExternalSidebar from "./UKExternalSidebar";

class SidebarSelections extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleChange(e) {
    this.props.handleOfficeChange(e);
  }

  render() {
    switch (this.props.office) {
      case "Netherlands": {
        return (
          <NLExternalSidebar handleOfficeChange={(e) => this.handleChange(e)} />
        );
      }
      case "United Kingdom": {
        return (
          <UKExternalSidebar handleOfficeChange={(e) => this.handleChange(e)} />
        );
      }

      case "Malta": {
        return (
          <MaltaSidebar handleOfficeChange={(e) => this.handleChange(e)} />
        );
      }
      case "Luxembourg": {
        return (
          <LuxembourgSidebar handleOfficeChange={(e) => this.handleChange(e)} />
        );
      }

      case "Cyprus": {
        return (
          <NLExternalSidebar handleOfficeChange={(e) => this.handleChange(e)} />
        );
      }
      case "Curcao": {
        return (
          <CuracaoSidebar handleOfficeChange={(e) => this.handleChange(e)} />
        );
      }

      case "Curacao": {
        return (
          <CuracaoSidebar handleOfficeChange={(e) => this.handleChange(e)} />
        );
      }
      case "Onboarding Uk test": {
        return (
          <NLExternalSidebar handleOfficeChange={(e) => this.handleChange(e)} />
        );
      }
      default: {
        return <></>;
      }
    }
  }
}

export default SidebarSelections;
