import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import DownloadFile from "../../../../elements/DownloadFile";
import DropDown from "../../../../elements/DropDown";
import NumberInput from "../../../../elements/NumberInput";
import TextBox from "../../../../elements/TextBox";
import MaltaDirectors from "../../MaltaOffice/Chapter4/MaltaDirectors";
import countryList from "react-select-country-list";
import { DirectorObj } from "../../../../JSONs/InternalUser/Chapter4";
import CheckBox from "../../../../elements/CheckBox";
class Directors extends React.Component {
  render() {
    const { noOfDirectors, objCompDirectorNames, errors, isRequired, form, ditectorsDetail, noOfDirectorsInter, arethereExistingUPCDir, arethereExistingUPCDirInter,isObjCompExists } =
      this.props;
    const errorsMsg = JSON.parse(errors);
    let directorArray = [];
    for (let i = 0; i < noOfDirectors; i++) {
      directorArray.push(
        <>
          {this.props.form === "Malta" || this.props.form === "Luxembourg" ? (
            <MaltaDirectors
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              externalReducer={this.props.externalReducer}
              index={i}
              PdfGeneration={this.props.PdfGeneration}
              form={this.props.form}
            />
          ) : (
            <div className="mb-4 col-lg-12">
              <fieldset>
                <legend>Director {i + 1}</legend>
                <div className="row">
                {this.props.form !== "UK" && (
                   <>
                  <div className="mb-3 col-lg-4">                   
                    <TextBox
                      label={`Name Director of Object Company`}
                      name="dirName"
                      placeholder="enter your text"
                      chapter={this.props.chapter}
                      baseName="objCompDirectorNames"
                      index={i}
                      disabled={true}
                      reducer={this.props.externalReducer}
                      className={
                        this.props.PdfGeneration === "Yes"
                          ? "txtBox"
                          : "form-control"
                      }
                    />
                  </div>
                  <div className="mb-3 col-lg-4">
                    <DropDown
                      label={`Nationality of Director`}
                      name="dirNationality"
                      //options={countryList().getData()}
                      chapter={this.props.chapter}
                      baseName="objCompDirectorNames"
                      index={i}
                      disabled={true}
                      reducer={this.props.externalReducer}
                    />

                  </div>
                  </>
                )}
                  {this.props.form === "UK" && (
                    <>
                     
                    <div className="mb-2 col-lg-4 col-xl-4">
                      <DropDown
                        label="Type of Director"
                        name="typeOfDirector"
                        options={[
                          { value: 'United', label: 'United' },
                          { value: 'External', label: 'External' },
                        ]}
                        //id={`typeOfDirector[${i}]`}
                        chapter={this.props.chapter}
                        baseName="objCompDirectorNames"
                        index={i}
                        disabled={true}
                        reducer={this.props.externalReducer}
                        validationType="Text"
                       
                      />                     
                    </div>
                    <div className="mb-3 col-lg-4">                   
                    <TextBox
                      label={`Name Director of Object Company`}
                      name="dirName"
                      placeholder="enter your text"
                      chapter={this.props.chapter}
                      baseName="objCompDirectorNames"
                      index={i}
                      disabled={true}
                      reducer={this.props.externalReducer}
                      className={
                        this.props.PdfGeneration === "Yes"
                          ? "txtBox"
                          : "form-control"
                      }
                    />
                  </div>
                  <div className="mb-3 col-lg-4">
                    <DropDown
                      label={`Nationality of Director`}
                      name="dirNationality"
                      //options={countryList().getData()}
                      chapter={this.props.chapter}
                      baseName="objCompDirectorNames"
                      index={i}
                      disabled={true}
                      reducer={this.props.externalReducer}
                    />

                  </div>
               
                      <div className="mb-2 col-lg-5">
                        <DownloadFile
                          label={`Certified Passport Copy`}
                          name="dirCertifiedPass"
                          value={objCompDirectorNames[i].dirCertifiedPass}
                        />
                      </div>
                      <div className="mb-2 col-lg-5">
                        <DownloadFile
                          label={`Proof of Address`}
                          name="dirProofAddress"
                          value={objCompDirectorNames[i].dirProofAddress}
                        />
                      </div>
                 
                    </>
                  )}
                </div>
              </fieldset>
            </div>
          )}
        </>
      );
    }
    let directorArrayInter = [];
    for (let i = 0; i < noOfDirectorsInter; i++) {
      directorArrayInter.push(
        <>
          <div className="mb-4 col-lg-12">
            <fieldset>
              <legend>Director {i + 1}</legend>
              <div className="row">
                <div className="mb-3 col-lg-4">
                  <TextBox
                    label={`Name Director of Object Company`}
                    name="dirName"
                    placeholder="enter your text"
                    reducer={this.props.reducer}
                    chapter={this.props.chapter}
                    baseName="ditectorsDetail"
                    index={i}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtBox"
                        : "form-control"
                    }
                  />
                </div>
                <div className="mb-3 col-lg-4">

                  <DropDown
                    label={`Nationality of Director`}
                    name="dirNationality"
                    options={countryList().getData()}
                    reducer={this.props.reducer}
                    chapter={this.props.chapter}
                    baseName="ditectorsDetail"
                    index={i}

                  />

                </div>

              </div>
            </fieldset>
          </div>

        </>
      );
    }
    return (
      <Row className="pt-3 pb-2">
        <div className="mb-3 col-lg-4">
          {arethereExistingUPCDir && isObjCompExists==="Yes"  && (
            <CheckBox
              label="Are there existing Director(s)?"
              options={["Yes", "No"]}
              name="arethereExistingUPCDir"
              chapter={this.props.chapter}
              reducer={this.props.externalReducer}
              bothDisabled={true}
            />
          )}
          {!arethereExistingUPCDir && form === "NL" && (
            <CheckBox
              label="Are there existing Director(s)?"
              options={["Yes", "No"]}
              name="arethereExistingUPCDir"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
            />
          )}
        </div>
        <div className="mb-3 col-lg-12">
          {isObjCompExists==="Yes" && form !="NL" &&(
            <>
            <NumberInput
              label={
                form === "Malta"
                  ? "Number of directors"
                  : "How many directors would you like to add?"
              }
              name="noOfDirectors"
              chapter={this.props.chapter}
              maxLimit={5}
              disabled={true}
              reducer={this.props.externalReducer}
            />
            {directorArray}
          </>
          )}
          {arethereExistingUPCDir && form === "NL"  && (
            <>
              <NumberInput
                label={
                  form === "Malta"
                    ? "Number of directors"
                    : "How many directors would you like to add?"
                }
                name="noOfDirectors"
                chapter={this.props.chapter}
                maxLimit={5}
                disabled={true}
                reducer={this.props.externalReducer}
              />
              {directorArray}
            </>
          )}
          {!arethereExistingUPCDir && arethereExistingUPCDirInter === "Yes" && form === "NL" && (
            <>
              <NumberInput
                label="How many intermediaries/client epresentatives can be identified?"
                name="noOfDirectors"
                referral={["ditectorsDetail"]}
                pushedObject={[DirectorObj]}
                maxLimit={5}
                reducer={this.props.reducer}
                chapter={this.props.chapter}
              />
              {directorArrayInter}
            </>
          )}
        </div>

        {(this.props.form === "NL" ||
          this.props.form === "Curacao" ||
          (this.props.form === "UK" &&  isObjCompExists==="Yes" )) && (           
            <div className="mb-2 col-lg-12">
              <TextBox
                label="Please add comment"
                placeholder="Enter your text"
                name="objCompDirCmmnt"
                id="objCompDirCmmnt"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                validationType="Text"
                isRequired={isRequired}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.objCompDirCmmnt &&
                errorsMsg?.objCompDirCmmnt?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg.objCompDirCmmnt}
                  </span>
                )}
            </div>
          )}
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { externalReducer, chapter } = ownProps;
   return {
    objCompDirectorNames:state[externalReducer][chapter]["objCompDirectorNames"],
    noOfDirectors: state[externalReducer][chapter]["noOfDirectors"],
    arethereExistingUPCDir: state[externalReducer][chapter]["arethereExistingUPCDir"],
    ditectorsDetail: state["ClientAcceptance"]["chapter4"]["ditectorsDetail"],
    noOfDirectorsInter: state["ClientAcceptance"]["chapter4"]["noOfDirectors"],
    arethereExistingUPCDirInter: state["ClientAcceptance"]["chapter4"]["arethereExistingUPCDir"],
    isObjCompExists: state[externalReducer].chapter4.isObjCompExists,
  };
};

export default connect(mapStateToProps, null)(Directors);
