import { Component } from "react";
import Html2Pdf from "js-html2pdf";
import { saveAs } from "file-saver";

let options = {
  margin: [0.5, 0.3, 0.3, 0.3],
  filename: "File.pdf",
  image: { type: "jpeg", quality: 0.98 },
  html2canvas: { dpi: 300, letterRendering: true },
  jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
};

class DocService extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //Create PDF
  createPdf = (html) => {
    var exporter = new Html2Pdf(html, options);
    //Get the jsPDF object to work with it
    return exporter.getPdf(false).then((pdf) => {
      //get the raw output as binary string
      //let output=pdf.output()
      //get the PDF as blob, which we can serialize and send to  API
      let outputBlob = pdf.output("blob");
      //get the PDF as binary buffer, which we can serialize and send to  API
      // let outputarraybuffer=pdf.output('arraybuffer')
      //pdf.save(outputBlob);
      return outputBlob;
    });
  };
   //print PDF
   printPdf = (html) => {
    var exporter = new Html2Pdf(html, options);
    //Get the jsPDF object to work with it
    return exporter.getPdf(false).then((pdf) => {
      //get the raw output as binary string
      //let output=pdf.output()
      //get the PDF as blob, which we can serialize and send to  API
      let outputBlob = pdf.output("blob");
      //get the PDF as binary buffer, which we can serialize and send to  API
      // let outputarraybuffer=pdf.output('arraybuffer')
      //pdf.save(outputBlob);
      saveAs(outputBlob);
      //return outputBlob;
    });
  };
  
}
const Doc = new DocService();
export default Doc;
