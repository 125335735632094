import React, { Component } from "react";
import { setDataToReducer } from "../../redux/actions/ClientInformationAction";
import { connect } from "react-redux";
import Select from "react-select";
import { getReducer } from "./getReducer";
import Tooltip from "./Tooltip";
class MultiSelectDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleMultiSelectionChange = (value) => {
    this.props.setDataToReducer({
      type: getReducer(this.props.reducer),
      chapter: this.props.chapter,
      index: this.props.index,
      name: this.props.name,
      value: value,
      baseName: this.props.baseName,
      objBaseName: this.props.objBaseName,
    });
  };
  render() {
    const { label, id, name, options,isRequired,info } = this.props;
    const selectedvalue = [];
    if (this.props.value) {
      this.props.value.forEach((element) => {
        selectedvalue.push(element.label);
      });
    }
    return (
      <>
        <label>
          {label}
          {isRequired && <span style={{ color: "red" }}>*</span>}   
          {info && <Tooltip text={info}></Tooltip>}
        </label>
        {this.props.PdfGeneration === "Yes" ? (
          <input
            type="text"
            name={name}
            className={"txtBox"}
            value={selectedvalue || ""}
            autoComplete="off"
            id={id}
            disabled={true}
          ></input>
        ) : (
          <Select
            id={id}
            options={options}
            isMulti={true}
            value={this.props.value}
            onChange={this.handleMultiSelectionChange}
            isDisabled={this.props.disabled}
            styles={this.props.PdfGeneration === "Yes" ? { color: "red" } : ""}
          />
        )}

        {/* {!selectedvalue.label ? (
        
        ) : (
          <Select
            options={options}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.label}
            onChange={this.handleChange}
            value={selectedvalue}
          />
        )} */}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    name,
    chapter,
    baseName,
    index,
    objBaseName,
    reducer,
    nestedObjBaseName,
    isCommon,
  } = ownProps;
  if (isCommon) {
    if (!objBaseName && !baseName) {
      return {
        value: state[reducer][name],
      };
    } else if (objBaseName && !baseName) {
      return {
        value: state[reducer][objBaseName][name],
      };
    } else if (objBaseName && baseName) {
      return {
        value: state[reducer][objBaseName][baseName][index][name],
      };
    }
  } else if (!baseName && !objBaseName) {
    return {
      value: state[reducer][chapter][name],
    };
  } else if (objBaseName && nestedObjBaseName) {
    return {
      value: state[reducer][chapter][objBaseName][nestedObjBaseName][name],
    };
  } else if (objBaseName && !baseName) {
    return {
      value: state[reducer][chapter][objBaseName][name],
    };
  } else if (objBaseName && baseName) {
    return {
      value: state[reducer][chapter][objBaseName][baseName][index][name],
    };
  } else {
    return {
      value: state[reducer][chapter][baseName][index][name],
    };
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiSelectDropdown);
