import React, { Component } from "react";
import { Row } from "react-bootstrap";
import countryList from "react-select-country-list";
import TextBox from "../../../../elements/TextBox";
import CheckBox from "../../../../elements/CheckBox";
import DownloadFile from "../../../../elements/DownloadFile";
import { connect } from "react-redux";
class Settlors extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { index, errorMsg, issettPassport, issettProofAdd, issettRefLett, settData } = this.props;
        const eMsg = errorMsg;
        return (
            <div className="mb-4 col-lg-12">
                <fieldset>
                    <legend>Settlor {this.props.index + 1}</legend>
                    <Row>
                        <div className="mb-2 col-lg-12 col-xl-12">
                            <TextBox
                                label="Settlor details"
                                name="settlorsDetails"
                                id={`settlorsDetails[${index}]`}
                                placeholder="enter your text"
                                objBaseName={this.props.objBaseName}
                                indexObjBaseName={`${this.props.indexObjBaseName}`}
                                index={this.props.index}
                                baseName={this.props.baseName}
                                reducer={this.props.reducer}
                                validationType="Text"
                                chapter={this.props.chapter}
                                disabled={true}
                            />
                        </div>

                        <div className="mb-3 col-lg-6">
                            <CheckBox
                                label="Settlor's passport or national identity card available?"
                                name="issettPassport"
                                options={["Yes", "No"]}
                                id={`issettPassport[${index}]`}
                                placeholder="enter your text"
                                objBaseName={this.props.objBaseName}
                                indexObjBaseName={`${this.props.indexObjBaseName}`}
                                index={this.props.index}
                                baseName={this.props.baseName}
                                reducer={this.props.reducer}
                                chapter={this.props.chapter}
                                disabled={true}
                            />
                        </div>
                        {issettPassport === "Yes" && (
                            <div className="mb-3 col-lg-4">
                                <DownloadFile
                                    label="Upload Settlor's Passport or national identity"
                                    name="settPassport"
                                    value={settData.settPassport}
                                />
                            </div>
                        )}
                        {issettPassport === "No" && (
                            <div className="mb-3 col-lg-4">
                                <TextBox
                                    label="Explanation"
                                    name="settPassportReason"
                                    id={`settPassportReason[${index}]`}
                                    placeholder="enter your text"
                                    objBaseName={this.props.objBaseName}
                                    indexObjBaseName={`${this.props.indexObjBaseName}`}
                                    index={this.props.index}
                                    baseName={this.props.baseName}
                                    reducer={this.props.reducer}
                                    validationType="Text"
                                    chapter={this.props.chapter}
                                    disabled={true}
                                />
                            </div>
                        )}
                    </Row>
                    <Row>
                        <div className="mb-3 col-lg-6">
                            <CheckBox
                                label="Settlor's proof of address available?"
                                name="issettProofAdd"
                                options={["Yes", "No"]}
                                id={`issettProofAdd[${index}]`}
                                placeholder="enter your text"
                                objBaseName={this.props.objBaseName}
                                indexObjBaseName={`${this.props.indexObjBaseName}`}
                                index={this.props.index}
                                baseName={this.props.baseName}
                                reducer={this.props.reducer}
                                chapter={this.props.chapter}
                                disabled={true}
                            />
                        </div>
                        {issettProofAdd === "Yes" && (
                            <div className="mb-3 col-lg-4">
                                <DownloadFile
                                    label="Upload Settlor's proof of addres"
                                    name="settProofAdd"
                                    value={settData.settProofAdd}
                                />
                            </div>
                        )}
                        {issettProofAdd === "No" && (
                            <div className="mb-3 col-lg-4">
                                <TextBox
                                    label="Explanation"
                                    name="settProofAddReason"
                                    id={`settProofAddReason[${index}]`}
                                    placeholder="enter your text"
                                    objBaseName={this.props.objBaseName}
                                    indexObjBaseName={`${this.props.indexObjBaseName}`}
                                    index={this.props.index}
                                    baseName={this.props.baseName}
                                    reducer={this.props.reducer}
                                    validationType="Text"
                                    chapter={this.props.chapter}
                                    disabled={true}
                                />
                            </div>
                        )}
                    </Row>
                    <Row className="pt-1 pb-2">
                        <div className="mb-3 col-lg-6">
                            <CheckBox
                                label="Settlor's reference letter available?"
                                name="issettRefLett"
                                options={["Yes", "No"]}
                                id={`issettRefLett[${index}]`}
                                placeholder="enter your text"
                                objBaseName={this.props.objBaseName}
                                indexObjBaseName={`${this.props.indexObjBaseName}`}
                                index={this.props.index}
                                baseName={this.props.baseName}
                                reducer={this.props.reducer}
                                chapter={this.props.chapter}
                                disabled={true}
                            />
                        </div>
                        {issettRefLett === "Yes" && (
                            <div className="mb-3 col-lg-4">
                                <DownloadFile
                                    label="Upload Settlor's reference letter"
                                    name="settRefLett"
                                    value={settData.settRefLett}
                                />
                            </div>
                        )}
                        {issettRefLett === "No" && (
                            <div className="mb-3 col-lg-4">
                                <TextBox
                                    label="Explanation"
                                    name="settRefLettReason"
                                    id={`settRefLettReason[${index}]`}
                                    placeholder="enter your text"
                                    objBaseName={this.props.objBaseName}
                                    indexObjBaseName={`${this.props.indexObjBaseName}`}
                                    index={this.props.index}
                                    baseName={this.props.baseName}
                                    reducer={this.props.reducer}
                                    validationType="Text"
                                    chapter={this.props.chapter}
                                    disabled={true}
                                />
                            </div>
                        )}
                    </Row>
                </fieldset>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { baseName, index, reducer, objBaseName, indexObjBaseName } = ownProps;
    return {
        settData: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index],
        issettPassport: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["issettPassport"],
        issettProofAdd: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["issettProofAdd"],
        issettRefLett: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["issettRefLett"],
    };
};

export default connect(mapStateToProps, null)(Settlors);
