import { Route, Redirect } from "react-router-dom";

const InternalPublicRoutes = ({ auth, ...props }) => {
  return localStorage.getItem("authToken") ? (
    <Redirect to="/internal-dashboard" />
  ) : (
    <Route {...props} />
  );
};

export default InternalPublicRoutes;
