import React, { Component } from "react";
import { Row } from "react-bootstrap";
import DropDown from "../../../../elements/DropDown";
import { clientIndustryArr } from "../Constants";
class ClientIndustries extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, errorMsg } = this.props;
    const eMsg = errorMsg;
    return (
     
        <fieldset>
          <legend>Client Industry {this.props.index + 1}</legend>
          <Row>
            <div className="mb-2 col-lg-6 col-xl-6">
             
              <DropDown
                          label="Name"
                          name="clientIName"
                          id={`clientIName[${index}]`}
                          placeholder="enter your text"               
                          index={this.props.index}
                          baseName="clientIndustries"
                          reducer={this.props.reducer}                         
                          chapter={this.props.chapter} 
                          options={clientIndustryArr}                          
             />           
            </div>           
          </Row>
        </fieldset>
     
    );
  }
}

export default ClientIndustries;
