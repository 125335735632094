import React, { Component } from "react";
import { connect } from "react-redux";
import { setDataToReducer } from "../../redux/actions/ClientInformationAction";
import { getReducer } from "./getReducer";
import PhoneInput from "react-phone-input-2";
const getOffice = (office) => {
  switch (office) {
    case "Netherlands":
      return "nl";
    case "NL":
      return "nl";
    case "Curacao":
      return "cw";
    case "Malta":
      return "mt";
    case "United Kingdom":
      return "gb";
    case "UK":
      return "gb";
    case "Luxembourg":
      return "lu";
    default:
      return "nl";
  }
};
class PhoneInputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chapter: this.props.chapter,
      isInValidValue: false,
    };
  }

  handleChange = (value) => {
    this.props.setDataToReducer({
      type: getReducer(this.props.reducer),
      chapter: this.props.chapter,
      index: this.props.index,
      name: this.props.name,
      value: value,
      baseName: this.props.baseName,
      objBaseName: this.props.objBaseName,
    });
  };

  render() {
    const { label, name, value, id, isRequired, country } = this.props;
    let office  = getOffice(country); 
    return (
      <>
        <label>
          {label}
          {isRequired && <span style={{ color: "red" }}>*</span>}
        </label>
        {this.props.PdfGeneration === "Yes" ? (
          <input
            type="text"
            name={name}
            className="txtBox"
            value={"+" + value || ""}
            autoComplete="off"
            disabled={true}
          ></input>
        ) : value ? (
            <PhoneInput
            id={id}
            className="input_field"
            country= {value !== null && this.props.disabled!==true ? "" : office}
            name={name}
            value={value || ""}
            onChange={this.handleChange}
            disabled={this.props.disabled}
          />
          ) : (
          <PhoneInput
            id={id}
            className="input_field"
            country= {office}
            name={name}
            value={value || ""}
            onChange={this.handleChange}
            disabled={this.props.disabled}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    name,
    chapter,
    baseName,
    index,
    objBaseName,
    reducer,
    nestedObjBaseName,
    isCommon,
  } = ownProps;

  if (isCommon) {
    if (!objBaseName && !baseName) {
      return {
        value: state[reducer][name],
      };
    } else if (objBaseName && nestedObjBaseName) {
      return {
        value: state[reducer][objBaseName][nestedObjBaseName][name],
      };
    } else if (objBaseName && !baseName) {
      return {
        value: state[reducer][objBaseName][name],
      };
    } else if (objBaseName && baseName) {
      return {
        value: state[reducer][objBaseName][baseName][index][name],
      };
    }
  } else if (!baseName && !objBaseName) {
    return {
      value: state[reducer][chapter][name],
    };
  } else if (objBaseName && nestedObjBaseName) {
    return {
      value: state[reducer][chapter][objBaseName][nestedObjBaseName][name],
    };
  } else if (objBaseName && !baseName) {
    return {
      value: state[reducer][chapter][objBaseName][name],
    };
  } else if (objBaseName && baseName) {
    return {
      value: state[reducer][chapter][objBaseName][baseName][index][name],
    };
  } else {
    return {
      value: state[reducer][chapter][baseName][index][name],
    };
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneInputField);
