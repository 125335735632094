import React, { Component } from "react";
import cc from "currency-codes";
import TextBox from "../../../../elements/TextBox";
import DropDown from "../../../../elements/DropDown";

let currencies = [];
cc.codes().map((currency) => currencies.push({ label: currency }));
class AnnualManFee extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { chapter, reducer, objBaseName, errors } = this.props;
    const errorsMsg = JSON.parse(errors);
    return (
      <div className="mb-2 col-lg-12">
        <fieldset className="eqheight">
          <legend>Annual Management Fees</legend>
          <div className="row mb-0">
            <div className="mb-1 col-lg-4">
              <DropDown
                label="Select currency"
                options={currencies}
                name="annlMngmtCurrency"
                id="annlMngmtCurrency"
                reducer={reducer}
                chapter={chapter}
                objBaseName={objBaseName}
                validationType="Text"
                //isRequired={true}
              />{" "}
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.annualManagementFees &&
                errorsMsg?.annualManagementFees?.annlMngmtCurrency?.length >
                  0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    {errorsMsg?.annualManagementFees?.annlMngmtCurrency}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-4">
              <TextBox
                label="1st bill period to December"
                placeholder="Enter bill period"
                name="frstBillPeriodToDec"
                id="frstBillPeriodToDec"
                reducer={reducer}
                chapter={chapter}
                objBaseName={objBaseName}
                validationType="Amount"
                //isRequired={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.annualManagementFees &&
                errorsMsg?.annualManagementFees?.frstBillPeriodToDec?.length >
                  0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    {errorsMsg?.annualManagementFees?.frstBillPeriodToDec}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Annual Resp. Fee"
                placeholder="Enter annual resp. fee"
                name="annualRespFee"
                id="annualRespFee"
                reducer={reducer}
                chapter={chapter}
                objBaseName={objBaseName}
                validationType="Amount"
                //isRequired={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.annualManagementFees &&
                errorsMsg?.annualManagementFees?.annualRespFee?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    {errorsMsg?.annualManagementFees?.annualRespFee}
                  </span>
                )}
            </div>
          </div>
        </fieldset>
      </div>
    );
  }
}

export default AnnualManFee;
