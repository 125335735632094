import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import {
  clientApprovalList,
  approveRejectClient,
  getAllOffices,
} from "../../../redux/actions/clientActions";
import { NotificationManager } from "react-notifications";
import Modal from "../shared/Modal";
import jwt_decode from "jwt-decode";
import "./clientlist.scss";
import orderBy from "lodash/orderBy";
let selectedOffices = [];
let allOffices;

const customSort = (rows, field, direction) => {
  const handleField = (row) => {
    if (row[field]) {
      return row[field].toLowerCase();
    }
    return row[field];
  };
  return orderBy(rows, handleField, direction);
};

class clientApprovals extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      columns: [],
      data: [],
      originalData: [],
      selectedRows: [],
      error: "",
      isLoading: false,
      modal: false,
      reason: "",
      searchInput: "",
      toggleCleared: false,
      tokenData: null,
      errors: {},
    };

    this.props.getAllOffices().then((res) => {
      if (res.data.portalErrorCode === 200) {
        allOffices = res.data.result;
        const checkboxs = this.subHeaderFilterComponent();
        this.setState({
          officeList: res.data.result,
          columns: [
            {
              name: "Client Name",
              selector: "name",
              sortable: true,
            },
            {
              name: "City",
              selector: "city",
              sortable: true,
            },
            {
              name: "Post Code",
              selector: "postalCode",
              sortable: true,
            },
            {
              name: "Country",
              selector: "country",
              sortable: true,
              filterable: true,
            },
            {
              name: (
                <div className="office-dropdown-hover">
                  <div className="office-button">United Office Location</div>
                  <div className="office-dropdown-content office-bar-block office-card-4">
                    {checkboxs}
                  </div>
                </div>
              ),

              cell: (row) => (
                <div className="officeLocation">
                  {row.office &&
                    row.office.length > 0 &&
                    row.office.map((office) => {
                      return (
                        <>
                          {office?.name && (
                            <span className={office?.name}>{office?.name}</span>
                          )}
                        </>
                      );
                    })}
                </div>
              ),
              sortable: false,
              width: "18%",
            },
          ],
        });
      }
    });
  }

  getClientApprovalList = () => {
    let token = JSON.parse(localStorage.getItem("authToken"));
    if (token) {
      var decoded = jwt_decode(token);
      var data = {
        name: decoded.name,
      };

      this.setState({ isLoading: true, tokenData: data });
      this.props.clientApprovalList(data).then((res) => {
        this.setState({ isLoading: false });
        if (res.data.portalErrorCode === 200) {
          this.setState({
            originalData: res.data.result,
            data: res.data.result,
          });
        }
      });
    }
  };
  componentDidMount = () => {
    this.getClientApprovalList();
  };

  validateActionOnButtons = () => {
    let isValid = true;
    if (this.state.selectedRows.length <= 0) {
      isValid = false;
      this.setState({ error: "Please select client for approval/rejection" });
    }
    return isValid;
  };

  handleApprove = async () => {
    const { selectedRows } = this.state;
    this.setState({ isLoading: true });
    if (this.validateActionOnButtons()) {
      let array = [];
      selectedRows.forEach((client) => {
        let status;
        if (client.portalStatus === "DeleteCompany") {
          status = 3;
        } else if (
          client.portalStatus === "UpdateCompany" ||
          client.portalStatus === "AddCompany"
        ) {
          status = 1;
        }
        let obj = {};
        obj["Id"] = client.id;
        obj["Remarks"] = "";
        obj["Status"] = status;
        array.push(obj);
      });

      await this.props.approveRejectClient(array).then((res) => {
        this.setState({ isLoading: false });
        let successfulCount = 0,
          failedCount = 0;
        if (res.data?.length > 0) {
          res.data.forEach((record) => {
            if (record.portalErrorCode === 200) {
              successfulCount++;
            } else {
              failedCount++;
            }
          });
          let successMsg = successfulCount === 1 ? `record` : `records`;
          successfulCount > 0 &&
            NotificationManager.success(
              `${successfulCount} ${successMsg} ${`approved successfully`}`
            );

          let failMsg = failedCount === 1 ? `record.` : `records.`;
          failedCount > 0 &&
            NotificationManager.error(
              `Error while approving ${failedCount} ${failMsg}`
            );
          this.setState({
            selectedRows: [],
            toggleCleared: !this.state.toggleCleared,
          });
          this.getClientApprovalList();
        } else {
          NotificationManager.error("Error while approving records");
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleSubmit = async () => {
    let errors = {};
    if (this.state.reason) {
      this.handleCancel();
      const { selectedRows } = this.state;
      this.setState({ isLoading: true });
      if (this.validateActionOnButtons()) {
        let array = [];
        selectedRows.forEach((client) => {
          let status;
          if (client.portalStatus === "DeleteCompany") {
            status = 4;
          } else if (client.portalStatus === "UpdateCompany") {
            status = 7;
          } else if (client.portalStatus === "AddCompany") {
            status = 2;
          }
          let obj = {};
          obj["Id"] = client.id;
          obj["Remarks"] = this.state.reason;
          obj["Status"] = status;
          array.push(obj);
        });
        await this.props.approveRejectClient(array).then((res) => {
          this.setState({ isLoading: false });
          let successfulCount = 0,
            failedCount = 0;
          if (res.data?.length > 0) {
            res.data.forEach((record) => {
              if (record.portalErrorCode === 200) {
                successfulCount++;
              } else {
                failedCount++;
              }
            });
            let successMsg = successfulCount === 1 ? `record` : `records`;
            successfulCount > 0 &&
              NotificationManager.success(
                `${successfulCount} ${successMsg} ${`rejected successfully`}`
              );

            let failMsg = failedCount === 1 ? `record.` : `records.`;
            failedCount > 0 &&
              NotificationManager.error(
                `Error while rejecting ${failedCount} ${failMsg}`
              );
            this.setState({
              selectedRows: [],
              toggleCleared: !this.state.toggleCleared,
            });
            this.getClientApprovalList();
          } else {
            NotificationManager.error("Error while rejecting records");
          }
        });
      } else {
        this.setState({ isLoading: false });
      }
    } else {
      errors["othermessage"] = "Please enter a remark";
    }
    this.setState({ errors });
  };

  //handle change on all inputs
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSearch = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData = this.state.originalData.filter((value) => {
      return (
        value.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.city?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.country?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.postalCode
          ?.toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      );
    });
    this.setState({ data: filteredData });
  };
  OfficeFilter = () => {
    if (selectedOffices.length !== 0) {
      let filteredData = [];
      this.state.originalData.filter((client) => {
        return client.office.filter((value) => {
          return selectedOffices.forEach((element) => {
            if (value.name.includes(element.name)) {
              return filteredData.push(client);
            }
          });
        });
      });
      const ids = filteredData.map((o) => o.id);
      const filtered = filteredData.filter(({ id }, index) => {
        return !ids.includes(id, index + 1);
      });
      if (filtered.length !== 0) {
        this.setState({ data: filtered });
      }
    } else {
      this.setState({ data: this.state.originalData });
    }
  };
  handleClick(e) {
    let clickedValue = e.target.value;
    let clickedChecked = e.target.checked;
    const form = document.querySelector("#checkboxes");
    const checkboxs = form.querySelectorAll("input[type=checkbox]");
    if (clickedValue === "Clear All" && clickedChecked) {
      selectedOffices = [];
      checkboxs.forEach((element) => {
        if (element.checked === true && element.name !== "Clear All") {
          element.checked = false;
        } else {
          element.checked = true;
        }
      });
    } else if (clickedValue === "Clear All" && !clickedChecked) {
      checkboxs.forEach((element) => {
        if (element.checked === true && element.name === "Clear All") {
          element.checked = false;
        }
      });
    } else if (clickedValue !== "Clear All" && clickedChecked) {
      selectedOffices.push({ name: clickedValue });
      checkboxs.forEach((element) => {
        if (element.name === "Clear All") {
          element.checked = false;
        }
      });
    } else if (clickedValue !== "Clear All" && !clickedChecked) {
      selectedOffices.splice(
        selectedOffices.findIndex((v) => v.name === clickedValue),
        1
      );
    }
    this.OfficeFilter();
  }
  subHeaderFilterComponent = () => {
    const options = [];

    if (allOffices !== undefined) {
      allOffices.forEach((element) => {
        options.push(element);
      });
    }
    options.push({ name: "Clear All" });

    return (
      <div id="checkboxes">
        {options.map((option) => (
          <label>
            <input
              onClick={this.handleClick}
              type="checkbox"
              name={option.name}
              key={option.name}
              value={option.name}
            />{" "}
            <div id="filterName">{option.name}</div>
          </label>
        ))}
      </div>
    );
  };

  modalOpen = () => {
    if (this.validateActionOnButtons()) {
      this.setState({ modal: true });
    }
  };

  handleRowSelection = (selectedRows) => {
    this.setState({ selectedRows: selectedRows.selectedRows });
  };

  handleCancel = () => {
    this.setState({ modal: false });
  };
  render() {
    const { error, searchInput, toggleCleared } = this.state;
    return (
      <div className="dashboard">
        <div className="userlist">
          <div className="eventHeader pt-3 pb-3">
            <Container fluid>
              <Row>
                <Col sm={6} className="text-left d-flex">
                  <h2 className="mb-0  mt-2 pagetitle">Client User Approvals</h2>
                </Col>
              </Row>
            </Container>
          </div>

          <Container fluid>
            <Row className="pt-3 pb-2">
              <Col lg={6} className="pb-2">
                {this.state.data?.length > 0 && (
                  <>
                    <button
                      className="action-btn approve"
                      onClick={this.handleApprove}
                    >
                      Approve
                    </button>
                    <button
                      className="action-btn delete"
                      onClick={this.modalOpen}
                    >
                      Reject
                    </button>
                    {error && <div className="errorMsg">{error} </div>}
                  </>
                )}
              </Col>
              <Col lg={6} className="position-relative">
                <div className="datatableSearch">
                  <input
                    type="text"
                    name="searchInput"
                    value={searchInput || ""}
                    onChange={this.handleSearch}
                    placeholder="Search"
                  />
                  <i className="fa fa-search search-bar-icon"></i>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <DataTable
                  className="datatable"
                  columns={this.state.columns}
                  data={this.state.data}
                  onSelectedRowsChange={this.handleRowSelection}
                  sortFunction={customSort}
                  selectableRows
                  pagination
                  clearSelectedRows={toggleCleared}
                />
              </Col>
            </Row>
          </Container>
          <Modal
            show={this.state.modal}
            handleClose={(e) => this.handleCancel(e)}
          >
            <h4 className="heading-midium mb-3 text-left">Remarks</h4>
            <div className="errorMsg">{this.state.errors.othermessage}</div>
            <div className="form-group mb-2">
              <textarea
                onChange={this.handleChange}
                className="remarks-input"
                name="reason"
                value={this.state.reason}
                rows="5"
                style={{ width: "100%" }}
              ></textarea>
              <div
                className="approval-list-poppup button-group pt-2"
                style={{ float: "left" }}
              >
                <button
                  onClick={(e) => this.handleSubmit(e)}
                  type="button"
                  className="action-btn big noicon"
                >
                  Submit
                </button>
                <button
                  onClick={(e) => this.handleCancel(e)}
                  type="button"
                  className="action-btn big reject noicon ml-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>

          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.client.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clientApprovalList: async (data) =>
      dispatch(await clientApprovalList(data)),
    approveRejectClient: async (data) =>
      dispatch(await approveRejectClient(data)),
    getAllOffices: async () => dispatch(await getAllOffices()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(clientApprovals);

// export default userlist;
