import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import DropDown from "../../../../elements/DropDown";
import TextBox from "../../../../elements/TextBox";
import { checkIsCommon } from "../../../external/OnBoarding/client-information/CommonFunctions";

class OCExists extends React.Component {
  render() {
    const { isRequired, isObjCompExists, typOfCompMal, form } = this.props;
    const errorsMsg = JSON.parse(this.props.errors);

    return (
      <>
        <Row className="pt-3 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label={
                form === "Malta"
                  ? "Does the Target Company already exist?"
                  : "Does the Object Company already exist?"
              }
              options={["Yes", "No"]}
              name="isObjCompExists"
              chapter={this.props.chapter}
              reducer={this.props.externalReducer}
              bothDisabled={true}
            />
          </div>

          <div className="mb-3 col-lg-4">
            <TextBox
              label={
                form === "Malta" ? "Name Target Company" : "Name Object Company"
              }
              name="objCompName"
              placeholder="enter your text"
              reducer={
                checkIsCommon(this.props.form)
                  ? "commonFields"
                  : this.props.externalReducer
              }
              chapter={
                checkIsCommon(this.props.form) ? null : this.props.chapter
              }
              isCommon={checkIsCommon(this.props.form)}
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
          {isObjCompExists === "Yes" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Commercial Register"
                name="commercialRegister"
                placeholder="enter your text"
                chapter={this.props.chapter}
                reducer={this.props.externalReducer}
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
          {(this.props.form === "Malta" ||
            this.props.form === "Luxembourg") && (
            <>
              <div className="mb-3 col-lg-4">
                <TextBox
                  label={`Registered office in ${this.props.form}`}
                  name="regOffMal"
                  id="regOffMal"
                  placeholder="enter your text"
                  chapter={this.props.chapter}
                  reducer={this.props.externalReducer}
                  disabled={true}
                  validationType="Text"
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
              </div>
              <div className="mb-3 col-lg-4">
                <DropDown
                  label={`Type of company`}
                  name="typOfCompMal"
                  id={`typOfCompMal`}
                  //options={companyTypeMalta}
                  chapter={this.props.chapter}
                  reducer={this.props.externalReducer}
                  disabled={true}
                />
              </div>
              {typOfCompMal === "Other" && (
                <div className="mb-3 col-lg-4">
                  <TextBox
                    label="Please, describe"
                    name="typeCmpOthrSpcfy"
                    id="typeCmpOthrSpcfy"
                    placeholder="enter your text"
                    chapter={this.props.chapter}
                    reducer={this.props.externalReducer}
                    validationType="Text"
                    disabled={true}
                  />
                </div>
              )}
            </>
          )}
          <div className="mb-2 col-lg-12">
            <TextBox
              label="Please add comment"
              placeholder="Enter your text"
              name="objCompCmmnt"
              id="objCompCmmnt"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              validationType="Text"
              isRequired={isRequired}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.objCompCmmnt &&
              errorsMsg?.objCompCmmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.objCompCmmnt}
                </span>
              )}
          </div>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { externalReducer } = ownProps;
  return {
    isObjCompExists: state[externalReducer].chapter4.isObjCompExists,
    typOfCompMal: state.MaltaClientInformation.chapter4.typOfCompMal,
  };
};

export default connect(mapStateToProps, null)(OCExists);
