import React, { Component } from "react";
import { Row } from "react-bootstrap";
import countryList from "react-select-country-list";
import DropDown from "../../../../elements/DropDown";
import TextBox from "../../../../elements/TextBox";

class UPCDirectors extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, errorMsg } = this.props;
    const eMsg = errorMsg;
    return (
      <div className="mb-4 col-lg-12">
        <fieldset>
          <legend>Director {this.props.index + 1}</legend>
          <Row>

            <div className="mb-2 col-lg-6 col-xl-4">
              <TextBox
                label="Director name"
                name="upcAuthDirName"
                id={`upcAuthDirName[${index}]`}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}
                disabled={true}
              />
             
            </div>
            <div className="mb-2 col-lg-6 col-xl-4">
              <TextBox
                label="Director email"
                name="upcAuthDirEmail"
                id={`upcAuthDirEmail[${index}]`}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}
                disabled={true}              
              />
             
            </div>
            <div className="mb-2 col-lg-6 col-xl-4">
              <DropDown
                label="Director nationality"
                name="upcAuthDirNationality"
                id={`upcAuthDirNationality[${index}]`}
                options={countryList().getData()}
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                baseName={this.props.baseName}
                index={this.props.index}
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                disabled={true}
              />
             
            </div>
          </Row>
        </fieldset>
      </div>
    );
  }
}

export default UPCDirectors;
