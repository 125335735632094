export const getFirstUnfilledFieldExternal = (formErrors) => {
  // debugger;
  let name = [];
  let grpCompToObjComp = false;
  let objCompToGrpComp = false;
  let objCompToTP = false;
  let tpToObjComp = false;
  let objCompToUBO = false;
  let uboToObjComp = false;
  Object.entries(formErrors).forEach(function (val) {
    const [key, value] = val;
    if (Array.isArray(value)) {
      value.forEach(function (errors, index) {
        Object.entries(errors).forEach(function (val) {
          const [valkey, valError] = val;
          if (valError !== undefined && valError.length > 0) {
            name.push(`${valkey}[${index}]`);
          }
        });
      });
    } else if (
      typeof value === "object" &&
      !Array.isArray(value) &&
      value !== null
    ) {
      Object.entries(value).forEach(function (val) {
        const valKey = val[0];
        const valError = val[1];
        if (key == "grpCompToObjComp") {
          if (valError.length > 0 && grpCompToObjComp === false) {
            name.push(key);
            grpCompToObjComp = true;
          }
        }
        if (key == "objCompToGrpComp") {
          if (valError.length > 0 && objCompToGrpComp === false) {
            name.push(key);
            objCompToGrpComp = true;
          }
        }
        if (key == "objCompToUBO") {
          if (valError.length > 0 && objCompToUBO === false) {
            name.push(key);
            objCompToUBO = true;
          }
        }
        if (key == "uboToObjComp") {
          if (valError.length > 0 && uboToObjComp === false) {
            name.push(key);
            uboToObjComp = true;
          }
        }
        if (key == "objCompToTP") {
          if (valError.length > 0 && objCompToTP === false) {
            name.push(key);
            objCompToTP = true;
          }
        }
        if (key == "tpToObjComp") {
          if (valError.length > 0 && tpToObjComp === false) {
            name.push(key);
            tpToObjComp = true;
          }
        }
        if (valError.length > 0) {
          name.push(valKey);
        }
      });
    } else {
      const valKey = val[0];
      const valError = val[1];
      if (valError !== undefined && valError.length > 0) {
        name.push(valKey);
      }
    }
  });

  if (name.length > 0) {
    return name[0];
  } else {
    return null;
  }
};
