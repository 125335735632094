import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import FileUpload from "../../../../../elements/FileUpload";
import { downloadFATCAForm } from "../../../../../../redux/actions/userActions";
import { fileAcceptType } from "./Constants";
import { saveAs } from "file-saver";

const objBaseName = "documentation";
class Documentation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  downloadForm = (fileName) => {
    this.props.downloadFATCAForm({ fileName: fileName }).then((result) => {
      var blob = new Blob([result.data]);
      let file = fileName;
      saveAs(blob, file);
    });
  };

  render() {
    const { chapter, errorMsg } = this.props;
    const errors = JSON.parse(errorMsg);
    return (
      <>
        <h4 className="sub-chapter-title mb-4">Documentation</h4>
        <Row className="pt-0 pb-0">
          <div className="mb-3 col-lg-4">
            <FileUpload
              label="Certified copy of passport details / Identity card"
              acceptType={fileAcceptType}
              name="certifiedCopyIdentity"
              id="certifiedCopyIdentity"
              objBaseName={objBaseName}
              chapter={chapter}
              reducer={this.props.reducer}
            />
            {errors &&
              errors?.certifiedCopyIdentity &&
              errors?.certifiedCopyIdentity?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.certifiedCopyIdentity}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-0 pb-0">
          <div className="mb-3 col-lg-4">
            <label>Bankers' reference</label>
            <div className="fileDownload">
              <button
                className="action-btn big download"
                onClick={() => this.downloadForm("Malta-Banker-Refrence.pdf")}
              >
                Download Form <i className="fa fa-download ml-2"></i>
              </button>
            </div>
          </div>
          <div className="mb-3 col-lg-4">
            <FileUpload
              label="Upload bankers' reference"
              acceptType={fileAcceptType}
              name="bankersRef"
              id="bankersRef"
              objBaseName={objBaseName}
              chapter={chapter}
              reducer={this.props.reducer}
            />
            {errors && errors?.bankersRef && errors?.bankersRef?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors?.bankersRef}
              </span>
            )}
          </div>
          <div className="mb-3 col-lg-12">
            <Row>
              <div className="mb-3 col-lg-4">
                <label>Professional reference</label>
                <div className="fileDownload">
                  <button
                    className="action-btn big download"
                    onClick={() =>
                      this.downloadForm("Reference Letter - Template.docx")
                    }
                  >
                    Download Form <i className="fa fa-download ml-2"></i>
                  </button>
                </div>
              </div>
              <div className="mb-3 col-lg-4">
                <FileUpload
                  label="Upload professional reference"
                  acceptType={fileAcceptType}
                  name="profRef"
                  id="profRef"
                  objBaseName={objBaseName}
                  chapter={chapter}
                  reducer={this.props.reducer}
                />{" "}
                {errors && errors?.profRef && errors?.profRef?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.profRef}
                  </span>
                )}
              </div>
            </Row>
          </div>

          <div className="mb-3 col-lg-4">
            <label>Declaration of good standing</label>
            <div className="fileDownload">
              <button
                className="action-btn big download"
                onClick={() =>
                  this.downloadForm("Reference Letter - Template.docx")
                }
              >
                Download Form <i className="fa fa-download ml-2"></i>
              </button>
            </div>
          </div>
          <div className="mb-3 col-lg-4">
            <FileUpload
              label="Upload declaration of good standing"
              acceptType={fileAcceptType}
              name="declrGoodStndng"
              id="declrGoodStndng"
              objBaseName={objBaseName}
              chapter={chapter}
              reducer={this.props.reducer}
            />
            {errors &&
              errors?.declrGoodStndng &&
              errors?.declrGoodStndng?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.declrGoodStndng}
                </span>
              )}
          </div>
        </Row>
        <h5>In the case of corporate shareholders</h5>
        <Row className="pt-0 pb-0">
          <div className="mb-3 col-lg-4">
            <FileUpload
              label="Formal document confirming identity of shareholders and directors"
              acceptType={fileAcceptType}
              name="formDocConfmIdntyShrHold"
              id="formDocConfmIdntyShrHold"
              objBaseName={objBaseName}
              chapter={chapter}
              reducer={this.props.reducer}
            />
            {errors &&
              errors?.formDocConfmIdntyShrHold &&
              errors?.formDocConfmIdntyShrHold?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.formDocConfmIdntyShrHold}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-4">
            <FileUpload
              label="A recent good standing certificate"
              info="In favour of the corporate shareholder issued by the Registration Authorities certifying the existence of the body corporate and establishing the identity of the directors and shareholders"
              acceptType={fileAcceptType}
              name="recGoodStndngCrt"
              id="recGoodStndngCrt"
              objBaseName={objBaseName}
              chapter={chapter}
              reducer={this.props.reducer}
            />
            {errors &&
              errors?.recGoodStndngCrt &&
              errors?.recGoodStndngCrt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.recGoodStndngCrt}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-4">
            <FileUpload
              label="Copy of the incorporation document"
              info="or memorandum and articles of association of the corporate entity"
              acceptType={fileAcceptType}
              name="cpyIncorDoc"
              id="cpyIncorDoc"
              objBaseName={objBaseName}
              chapter={chapter}
              reducer={this.props.reducer}
            />
            {errors &&
              errors?.cpyIncorDoc &&
              errors?.cpyIncorDoc?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.cpyIncorDoc}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-4">
            <FileUpload
              label="Extract from the relative Companies’ House reflecting the date of registration and registered address of the entity"
              acceptType={fileAcceptType}
              name="relCompaniesHse"
              id="relCompaniesHse"
              objBaseName={objBaseName}
              chapter={chapter}
              reducer={this.props.reducer}
            />
            {errors &&
              errors?.relCompaniesHse &&
              errors?.relCompaniesHse?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.relCompaniesHse}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-4">
            <FileUpload
              label="Latest financial statements"
              acceptType={fileAcceptType}
              name="ltstFinStmnts"
              id="ltstFinStmnts"
              objBaseName={objBaseName}
              chapter={chapter}
              reducer={this.props.reducer}
            />
            {errors &&
              errors?.ltstFinStmnts &&
              errors?.ltstFinStmnts?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.ltstFinStmnts}
                </span>
              )}
          </div>
        </Row>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    downloadFATCAForm: (data) => dispatch(downloadFATCAForm(data)),
  };
};

export default connect(null, mapDispatchToProps)(Documentation);
