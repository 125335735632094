import React, { Component } from "react";
import { connect } from "react-redux";
import DropDown from "../../../../../elements/DropDown";
import TextBox from "../../../../../elements/TextBox";
import { checkIsCommon } from "../CommonFunctions";
import { NatureOfObjectComArray } from "./Constants";
class NatureOfObjectCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { objCompNaturePropsdObjctv, errorMsg } = this.props;
    const eMsg =
      errorMsg !== null || errorMsg !== undefined ? JSON.parse(errorMsg) : null;
    return (
      <>
        <div className="mb-3 col-lg-4">
          <DropDown
            label={`Nature and proposed objective of the ${
              this.props.form === "Malta" ? "Target" : "Object"
            } Company`}
            name="objCompNaturePropsdObjctv"
            id="objCompNaturePropsdObjctv"
            options={NatureOfObjectComArray}
            reducer={this.props.reducer}
            chapter={this.props.chapter}
            isCommon={checkIsCommon(this.props.form) ? true : false}
            disabled={this.props.from === "Ubo-Pep" ? true : false}
            isRequired={this.props.form === "UK" || (this.props.form === "NL" && this.props.from === "clientInformation") ? true : false} 
           />
          {eMsg &&
            eMsg?.objCompNaturePropsdObjctv &&
            eMsg.objCompNaturePropsdObjctv?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {eMsg.objCompNaturePropsdObjctv}
              </span>
            )}
        </div>
        {objCompNaturePropsdObjctv === "Other" && (
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Other"
              name="objCompOtherNaturePropsdObjctv"
              id="objCompOtherNaturePropsdObjctv"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isCommon={checkIsCommon(this.props.form) ? true : false}
              placeholder="enter your text"
              validationType="Text"
              disabled={this.props.from !== "clientInformation" ? true : false}
            />
            {eMsg &&
              eMsg?.objCompOtherNaturePropsdObjctv &&
              eMsg.objCompOtherNaturePropsdObjctv?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg.objCompOtherNaturePropsdObjctv}
                </span>
              )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer, chapter, form } = ownProps;

  if (
    form === "NL" ||
    form === "Curacao" ||
    form === "Malta" ||
    form === "Luxembourg"
  ) {
    return {
      objCompNaturePropsdObjctv:
        state.commonFields["objCompNaturePropsdObjctv"],
    };
  } else {
    return {
      objCompNaturePropsdObjctv:
        state[reducer][chapter]["objCompNaturePropsdObjctv"],
    };
  }
};

export default connect(mapStateToProps, null)(NatureOfObjectCompany);
