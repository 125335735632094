import React from "react";
import {
  GetClientAcceptance,
  SetClientAcceptance,
} from "../../../../../redux/actions/ClientAcceptanceFormActions";
import "./../internalCAF.scss";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import NotificationManager from "react-notifications/lib/NotificationManager";
import RisksSummary from "./RisksSummary";
import FormalQuestions from "./FormalQuestions";
import AutoSaving from "../AutoSaving";
import { formValid } from "../formValid";
import { chapValidationError } from "../Constants";
import { setInternalChapterError } from "../../../../../redux/actions/InternalChapterErrorActions";
import { HashLink } from "react-router-hash-link";
import getSequentialFields from "../getSequentialFields";

const chapter = "chapter12";
const reducer = "ClientAcceptance";

class Chapter12 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formErrorsChapter12: {},
      formErrors: {},
      totalErrors: [],
      errors: {},
      isCAFValid: null,
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: "all",
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.GetClientAcceptance(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          res.data.result.cafInternalValidations !== null
        ) {
          chapValidationError.forEach(async (element) => {
            if (element.chapter !== "chapter12") {
              let response =
                res?.data?.result?.cafInternalValidations[element.chapter];

              const newResponse = await Promise.resolve(
                getSequentialFields(response, element.chapter, true)
              );
              this.props.setInternalChapterError({
                form: `formErrors${element.chapter}`,
                errors: newResponse?.errorChapter,
              });
            }
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;

    this.props.SetClientAcceptance(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.props.ClientAcceptance.chapter12["dirty"] = false;

        let response = res.data.result;

        const newResponse = await Promise.resolve(
          getSequentialFields(response, chapter, true)
        );
        this.props.setInternalChapterError({
          errors: newResponse?.errorChapter,
          form: "formErrorschapter12",
        });
        chapValidationError.forEach((element) => {
          element.isvalid =
            this.props.chapterErrors[
              `formErrors${element.chapter}`
            ]?.errors?.isValid;
        });
        localStorage.setItem("isCAFValid", res.data.result.isCAFValid);
        if (res.data.result.isCAFValid) {
          this.setState({ isCAFValid: true });
          NotificationManager.success("Form submitted successfully");
          return this.props.history.push({
            pathname: "/netherlands/signature",
          });
        } else {
          this.setState({ isCAFValid: false });
          NotificationManager.error("Please fill all the details");
        }
      }
    });
  };

  render() {
    const { errors } = this.props;
    const { isCAFValid } = this.state;
    const formErrorsChapter12 = JSON.stringify(errors.errors);

    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="cafHeader">
            <h2 className="m-0">Client Acceptance Form</h2>
          </div>
          <div className="html2pdf__page-break"></div>
          <div className="internal_caf generic-form">
            <h3 className="chapterttl">11-Formal sign off</h3>
            <FormalQuestions
              reducer={reducer}
              chapter={chapter}
              errors={formErrorsChapter12}
              PdfGeneration={this.props.PdfGeneration}
              isRequired={false}
            />
            <div className="html2pdf__page-break"></div>
            <RisksSummary
              reducer={reducer}
              chapter={chapter}
              errors={formErrorsChapter12}
              form="NL"
              PdfGeneration={this.props.PdfGeneration}
              isRequired={false}
            />
            {isCAFValid === false ? (
              <div className="internalErrorSection">
                <h3>
                  All the fields from the chapters are mandatory, please fill
                  the unfilled fields.
                </h3>
                {chapValidationError
                  .filter((element) => element.isvalid === false)
                  .map((element) => {
                    const res = formValid(
                      this.props.chapterErrors[`formErrors${element.chapter}`]
                        .errors
                    );
                    return (
                      <HashLink to={`/netherlands/${element.path}#${res}`}>
                        <i className="fa fa-arrow-circle-o-right fa-fw"></i>
                        {element.label} has unfilled fields
                      </HashLink>
                    );
                  })}
              </div>
            ) : null}

            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ClientAcceptance: state.ClientAcceptance,
    data: state,
    internalChapterError: state.InternalChapterErrors.formErrorsChapter12,
    commonFields: state.commonFields,
    errors: state.InternalChapterErrors[`formErrors${chapter}`],
    chapterErrors: state.InternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetClientAcceptance: async (data) =>
      dispatch(await GetClientAcceptance(data)),
    SetClientAcceptance: async (data) =>
      dispatch(await SetClientAcceptance(data)),
    setInternalChapterError: (data) => dispatch(setInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter12);
