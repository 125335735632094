import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../../elements/CheckBox";
import FileUpload from "../../../../../elements/FileUpload";
import TextBox from "../../../../../elements/TextBox";
import { checkIsCommon } from "../CommonFunctions";
import { fileAcceptType } from "./Constants";

class TaxInvestmentMemorandum extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isTaxMemorandum, isInvstmntMemorandum, errorMsg } = this.props;

    const eMsg = JSON.parse(errorMsg);
    // const eMsg = {};
    return (
      <>
        <Row className="pt-2 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Is a tax advice, memorandum or opinion available?"
              options={["Yes", "No"]}
              name="isTaxMemorandum"
              id="isTaxMemorandum"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isCommon={checkIsCommon(this.props.form) && true}
              disabled={
                (this.props.form === "NL" ||
                  this.props.form === "Curacao" ||
                  this.props.form === "Malta" ||
                  this.props.form === "Luxembourg") &&
                this.props.from !== "clientInformation"
              }
              isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false}  
            />

            {eMsg &&
              eMsg?.isTaxMemorandum &&
              eMsg?.isTaxMemorandum?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg?.isTaxMemorandum}
                </span>
              )}
          </div>

          {isTaxMemorandum === "Yes" && (
            <>
              <div className="mb-3 col-lg-4">
                <TextBox
                  label="Add name advisor"
                  name="taxAdvisorName"
                  id="taxAdvisorName"
                  placeholder="enter your text"
                  reducer={this.props.reducer}
                  chapter={this.props.chapter}
                  isCommon={checkIsCommon(this.props.form) && true}
                  validationType="Text"
                  isRequired={this.props.form === "UK" ? true : false} 
                  disabled={
                    (this.props.form === "NL" ||
                      this.props.form === "Curacao" ||
                      this.props.form === "Malta" ||
                      this.props.form === "Luxembourg") &&
                    this.props.from !== "clientInformation"
                  }
                  isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false}  
                />
                {eMsg &&
                  eMsg?.taxAdvisorName &&
                  eMsg?.taxAdvisorName?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.taxAdvisorName}
                    </span>
                  )}
              </div>

              <div className="mb-3 col-lg-4">
                <FileUpload
                  label="Upload"
                  acceptType={fileAcceptType}
                  name="taxMemorandum"
                  id="taxMemorandum"
                  reducer={this.props.reducer}
                  chapter={this.props.chapter}
                  isCommon={checkIsCommon(this.props.form) && true}
                  isRequired={this.props.form === "UK" ? true : false} 
                  disabled={
                    (this.props.form === "NL" ||
                      this.props.form === "Curacao" ||
                      this.props.form === "Malta" ||
                      this.props.form === "Luxembourg") &&
                    this.props.from !== "clientInformation"
                  }
                  isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false}  
                />
                {eMsg &&
                  eMsg?.taxMemorandum &&
                  eMsg?.taxMemorandum?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.taxMemorandum}
                    </span>
                  )}
              </div>
            </>
          )}
        </Row>

        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Name tax advisor If no tax advice available/ required is trust office’s own assess-ment available?"
              name="taxMemorandumAdvisorName"
              id="taxMemorandumAdvisorName"
              placeholder="enter your text"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isCommon={checkIsCommon(this.props.form) && true}
              validationType="Text"
              disabled={
                (this.props.form === "NL" ||
                  this.props.form === "Curacao" ||
                  this.props.form === "Malta" ||
                  this.props.form === "Luxembourg") &&
                this.props.from !== "clientInformation"
              }
              isRequired={this.props.form === "NL" && this.props.from === "clientInformation"  ? true : false}  
            />
            {eMsg &&
              eMsg?.taxMemorandumAdvisorName &&
              eMsg?.taxMemorandumAdvisorName?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg?.taxMemorandumAdvisorName}
                </span>
              )}
          </div>
        </Row>
        <div className="html2pdf__page-break"></div>
        {this.props.proofOfTaxRes && this.props.form !== "Malta" && (
          <Row>
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Proof of Tax Residency"
                acceptType={fileAcceptType}
                name="proofOfTaxRes"
                id="proofOfTaxRes"
                reducer="UBODeclarationUIM"
                chapter="partA"
              />
              {eMsg &&
                eMsg?.partA &&
                eMsg?.partA?.proofOfTaxRes &&
                eMsg?.partA?.proofOfTaxRes?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg?.partA?.proofOfTaxRes}
                  </span>
                )}
            </div>
          </Row>
        )}

        {/* investment */}
        {(this.props.form === "NL" || this.props.form === "UK") && (
          <>
            <div className="html2pdf__page-break"></div>
            <Row className="pt-3 pb-2">
              <div className="mb-3 col-lg-4">
                <CheckBox
                  label="Is an investment memorandum available describing the purpose of the investment?"
                  options={["Yes", "No"]}
                  name="isInvstmntMemorandum"
                  id="isInvstmntMemorandum"
                  reducer={this.props.reducer}
                  chapter={this.props.chapter}
                  isCommon={checkIsCommon(this.props.form) && true}
                  disabled={
                    (this.props.form === "NL" ||
                      this.props.form === "Curacao" ||
                      this.props.form === "Luxembourg") &&
                    this.props.from !== "clientInformation"
                  }
                  isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false}  
                />
                {eMsg &&
                  eMsg?.isInvstmntMemorandum &&
                  eMsg?.isInvstmntMemorandum?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.isInvstmntMemorandum}
                    </span>
                  )}
              </div>
              {isInvstmntMemorandum === "Yes" && (
                <div className="mb-3 col-lg-4">
                  <FileUpload
                    label="please include the investment memorandum"
                    acceptType={fileAcceptType}
                    name="invstmntMemorandum"
                    id="invstmntMemorandum"
                    reducer={this.props.reducer}
                    chapter={this.props.chapter}
                    isCommon={this.props.form === "NL" && true}
                    isRequired={this.props.form === "UK" ? true : false} 
                    disabled={
                      (this.props.form === "NL" ||
                        this.props.form === "Curacao" ||
                        this.props.form === "Luxembourg") &&
                      this.props.from !== "clientInformation"
                    }
                    isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false}  
                  />
                  {eMsg &&
                    eMsg?.invstmntMemorandum &&
                    eMsg?.invstmntMemorandum?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg?.invstmntMemorandum}
                      </span>
                    )}
                </div>
              )}
            </Row>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { form, reducer, chapter } = ownProps;
  if (
    form === "NL" ||
    form === "Curacao" ||
    form === "Malta" ||
    form === "Luxembourg"
  ) {
    return {
      isTaxMemorandum: state.commonFields["isTaxMemorandum"],
      isInvstmntMemorandum: state.commonFields["isInvstmntMemorandum"],
    };
  } else {
    return {
      isTaxMemorandum: state[reducer][chapter]["isTaxMemorandum"],
      isInvstmntMemorandum: state[reducer][chapter]["isInvstmntMemorandum"],
    };
  }
};

export default connect(mapStateToProps, null)(TaxInvestmentMemorandum);
