import {
  DOCUSIGN_GENERATE_URL_FAIL,
  DOCUSIGN_GENERATE_URL_REQUEST,
  DOCUSIGN_GENERATE_URL_SUCCESS,
  DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_FAIL,
  DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_REQUEST,
  DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_SUCCESS,
  DOWNLOAD_EXTERNAL_FILE_FAIL,
  DOWNLOAD_EXTERNAL_FILE_REQUEST,
  DOWNLOAD_EXTERNAL_FILE_SUCCESS,
  DOWNLOAD_SIGNED_DOCS_FAIL,
  DOWNLOAD_SIGNED_DOCS_REQUEST,
  DOWNLOAD_SIGNED_DOCS_SUCCESS,
  INTERNAL_DELETE_FILE_FAIL,
  INTERNAL_DELETE_FILE_REQUEST,
  INTERNAL_DELETE_FILE_SUCCESS,
  INTERNAL_DOWNLOAD_FILE_FAIL,
  INTERNAL_DOWNLOAD_FILE_REQUEST,
  INTERNAL_DOWNLOAD_FILE_SUCCESS,
  INTERNAL_UPLOAD_FILE_FAIL,
  INTERNAL_UPLOAD_FILE_REQUEST,
  INTERNAL_UPLOAD_FILE_SUCCESS,
} from "../constants/InternalFileHandleConstants";

const initialState = {
  isLoading: false,
  user: [],
  error: "",
};

const InternalFileHandleReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case INTERNAL_UPLOAD_FILE_REQUEST:
    case INTERNAL_DELETE_FILE_REQUEST:
    case INTERNAL_DOWNLOAD_FILE_REQUEST:
    case DOWNLOAD_EXTERNAL_FILE_REQUEST:
    case DOWNLOAD_SIGNED_DOCS_REQUEST:
    case DOCUSIGN_GENERATE_URL_REQUEST:
    case DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case INTERNAL_UPLOAD_FILE_SUCCESS:
    case INTERNAL_DELETE_FILE_SUCCESS:
    case INTERNAL_DOWNLOAD_FILE_SUCCESS:
    case DOWNLOAD_EXTERNAL_FILE_SUCCESS:
    case DOWNLOAD_SIGNED_DOCS_SUCCESS:
    case DOCUSIGN_GENERATE_URL_SUCCESS:
    case DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case INTERNAL_UPLOAD_FILE_FAIL:
    case INTERNAL_DELETE_FILE_FAIL:
    case INTERNAL_DOWNLOAD_FILE_FAIL:
    case DOWNLOAD_EXTERNAL_FILE_FAIL:
    case DOWNLOAD_SIGNED_DOCS_FAIL:
    case DOCUSIGN_GENERATE_URL_FAIL:
    case DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default InternalFileHandleReducer;
