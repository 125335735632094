export const fileAcceptType = ".doc,.docx,.odt,.pdf,.rtf,.tex,.txt,.wpd,.ods,.xls,.xlsm,.xlsx,.key,.odp,.pps,.ppt,.pptx,.jpeg,.jpg,.png";

export const chapter1 = {
  structureChartComm: "",
  relvClientComment: "",
  isInLineUIMAccPolicy: "",
  isMediaCoverageOnStruct: "",
  mediaCmnt: "",
  isAddDocs: "",
  addDocs: "",
  isRisk: "",
  custActivityRisks: "",
  custActvtyRskRtng: "",
  descActnNeedsTkn: "",
  isUIMnoResonDiscntnuBusRel: "",
  reportOnActivities: "",
};
export const chapter2 = {
  mediaCmnt: "",
  addDocs: "",
  isRisk: "",
  custActivityRisks: "",
  custActvtyRskRtng: "",
  descActnNeedsTkn: "",
};
export const chapter3 = {
  isHldngIntrstCmmnt: "",
  isUBOClsifiedCmmnt: "",
  isAsstClientCmmnt: "",
  noUBOClassifiedCmmnt: "",
  uboApproval: [],
  upcApproval: [],
  pascalReportUpload: "",
  isFindings: "",
  isMRZPassport: "",
  descInvstgUBOClsifiedPEP: "",
  shareHolderCmnt: "",
  isMediaCoverageOnStruct: "",
  mediaCmnt: "",
  isAddDocs: "",
  addDocs: "",
  isRisk: "",
  custActivityRisks: "",
  custActvtyRskRtng: "",
  descActnNeedsTkn: "",
  isSOWVerifed: "",
  isUBOresultIncrsdIntgrtyRsk: "",
  ubOresultIncrsdIntgrtyRskCmmnt: "",
};
export const chapter4 = {
  objCompCmmnt: "",
  compSecCmmnt: "",
  accntngYrCmmnt: "",
  cntctPerCmmnt: "",
  objPascalResport: "",
  objFindings: "",
  objCompActiveConCmmnt: "",
  objCompBranchesCmmnt: "",
  rationaleStrctrngForNLCmmnt: "",
  findingsIndpTaxAdvisorApprvd: "",
  oneTimeServCmmnt: "",
  reccrntServCmmnt: "",
  propDirsManSigCmmnt: "",
  relationshipUBO: "",
  propDirsMangInfo: "",
  signatoryPowers: "",
  signPowInfo: "",
  propAuditorExsts: "",
  propAuditorName: "",
  propAccntExsts: "",
  propAccntName: "",
  propTaxAdvisor: "",
  propTaxAdvisorName: "",

  isCrtfdPassportCpy: "",
  isBankersRef: "",
  isProfRef: "",
  isGoodStanding: "",
  isIdentityShareholder: "",
  isRecGoodStanding: "",
  isCpyIncorpDoc: "",
  isFormDocConfmIdntyShrHold: "",
  isRelCompHouse: "",
  isLatestFinStmnts: "",

  extCOCApprvd: "",
  deedOfIncorpApprvd: "",
  shareholdersRegApprvd: "",
  shareholdersCrtfictsApprvd: "",
  finStmntsPstthreeYrsApprvd: "",
  utilityBillsApprvd: "",
  refLetterApprvd: "",
  apaCmmnt: "",
  otherRelDocsApprvd: "",
  otherRelDocsCmmnt: "",
  uboInvstObjCompCmmnt: "",
  annualFinStmntupcApprvd: "",
  upcFundObjCompCmmnt: "",
  anulFinStmntTpApprvd: "",
  tpInvstObjCompCmmnt: "",
  cntrysInvldObjCompCmmnt: "",
  asstSmmryEvidenceApprvd: "",
  assSmmryObjCompCommnt: "",
  expctdTrnsctnsSummary: "",
  expctdTransSummryApprvd: "",
  expctdTransSummryCmmnt: "",
  objCompUboCmmnt: "",
  uboObjCmpCmmnt: "",
  objCompGrpCompsCmmnt: "",
  grpCompsObjCompCmmnt: "",
  objCompTpCmmnt: "",
  tpObjCompCmmnt: "",
  bnkInfoRsk: "",
  bnkInfoCmmnt: "",
  aggrmntsGrpEntApprvd: "",
  aggrmntsOthrParApprvd: "",
  finStmntObjCompApprvd: "",
  finStmntAllPartciApprvd: "",
  finStmntParCompApprvd: "",
  declrFinInstApprvd: "",
  isMediaCoverageOnStruct: "",
  mediaCmnt: "",
  isAddDocs: "",
  addDocs: "",
  isRisk: "",
  custActivityRisks: "",
  custActvtyRskRtng: "",
  descActnNeedsTkn: "",
  objCompIncrdIntgtyRsk: "",
  objCompIncrdIntgtyRskText: "",
};
export const chapter5 = {
  isUIMAwareOfIncidents: "",
  uIMComment: "",
  structureComment: "",
  isRestrictiveMeasure: "",
  isStructurePlacedOnFATF: "",
  isStructureOrObjCompanyDirectly: "",
  includeInformation: "",
  isClientsInclRelevantCounterparties: "",
  isMediaCoverageOnStruct: "",
  mediaCmnt: "",
  isAddDocs: "",
  addDocs: "",
  isRisk: "",
  custActivityRisks: "",
  custActvtyRskRtng: "",
  descActnNeedsTkn: "",
  isUnacceptableRisksIdentified: "",
  unacceptableRisksReason: "",
};
export const chapter6 = {
  isDomiciliationSvc: "",
  isManagementSvc: "",
  isAdministrationSvc: "",
  isUIMPrvdOthSvc: "",
  explanationOthSvc: "",
  isLeaseAgmntOfcSpace: "",
  isDomicilliationAgmnt: "",
  isAdministrationAgmnt: "",
  isPrinciplePartyAgmnt: "",
  isManagementAgmnt: "",
  isOtherAgmnt: "",
  currency: "",
  incrtnFees: "",
  comIncrtn: "",
  others: "",
  feesTrfrCom: "",
  annualCurrency: "",
  firstYearOfc: "",
  rentOfcSpc: "",
  firstYearMngt: "",
  firstYearAdmnst: "",
  taxDeclaration: "",
  directorFees: "",
  isOtherFees: "",
  otherFeesCmnt: "",
  reportWorldCheckExtDir: "",
  findingsWorldCheck: "",
  isRisk: "",
  custActivityRisks: "",
  custActvtyRskRtng: "",
  descActnNeedsTkn: "",
  isUnacceptableRisksIdentified: "",
  unacceptableRisksReason: "",
};
export const chapter8 = { overallRiskRating: "" };
export const chapter9 = {
  overallRiskRating: "",
  relevantDocs: "",
  findingWorldCheck: "",
};
export const chapter10 = {
  isOtherMatterNotable: "",
  relevantDocs: "",
  otherMtrsImpCmnt: "",
};
export const chapter11 = {
  ovrallRsk: "",
  isDomiciliationSecServcs: "",
  isMngmntSecServcs: "",
  isAdministrtnSecServcs: "",
  othrSecSrvcs: "",
  isGoogleAlert: "",
};
export const chapter12 = {
  isReasonTigCliInvstg: "",
  reserveBusAccepComite: "",
  isBusAccepAccClnt: "",
  descReasDueDilignce: "",
  isMLRO: "",
  isstructOvrvwClr: "",
  isclientsIdntVrfd: "",
  isuboClssfctnIdntVrfctn: "",
  issrcWlthClr: "",
  ischckPEP: "",
  isobjPurObjCompClr: "",
  isTrnsPrflClr: "",
  isPurStrctrngKnwRecd: "",
  isSancRulChckd: "",
  isRelCorrespndceAdded: "",
  consolidtdRskObjComp: "",
  netRskRskCompliance: "",
  dateStrtOfSrvcs: "",
};

export const SignedByList = [
  {
    label: "Form Creator",
    value: "FormCreator",
    isFixed: false,
    name: null,
    email: null,
    dos: new Date(),
  },
  {
    label: "Sponsor",
    value: "Sponsor",
    isFixed: false,
    name: null,
    email: null,
    dos: null,
  },
  {
    label: "Director",
    value: "Director",
    isFixed: false,
    name: null,
    email: null,
    dos: null,
  },
  {
    label: "Compliance Officer",
    value: "ComplianceOfficer",
    isFixed: false,
    name: null,
    email: null,
    dos: null,
  },
];

export const chapValidationErrorMalta = [
  {
    chapter: "chapter1",
    label: "Target Company Activity",
    path: "client-activity",
    isvalid: false,
    name: "isChapter1valid",
  },
  {
    chapter: "chapter2",
    label: "Target Company - Intermediary / Agent",
    path: "client-investigation",
    name: "isChapter2valid",
    isvalid: false,
  },
  {
    chapter: "chapter3",
    label: "Target Company - UBO/UPC",
    path: "ubo-upc",
    name: "isChapter3valid",
    isvalid: false,
  },
  {
    chapter: "chapter4",
    label: "Target Company",
    name: "isChapter4valid",
    path: "object-company",
    isvalid: false,
  },
  {
    chapter: "chapter5",
    label: "Country/Sanction Risks",
    name: "isChapter5valid",
    path: "country-sanction-risks",
    isvalid: false,
  },
  {
    chapter: "chapter6",
    label: "Agreement",
    name: "isChapter6valid",
    path: "agreement",
    isvalid: false,
  },
  {
    chapter: "chapter8",
    label: "Consolidated Risk Assesment",
    name: "isChapter8valid",
    path: "consolidated-risk-assessment",

    isvalid: false,
  },
  {
    chapter: "chapter9",
    label: "Correspondence",
    path: "correspondence",
    name: "isChapter9valid",

    isvalid: false,
  },
  {
    chapter: "chapter10",
    name: "isChapter10valid",
    label: "Miscellaneous",
    path: "miscellaneous",
    isvalid: false,
  },
  {
    chapter: "chapter11",
    name: "isChapter11valid",
    label: "Summary sheet CAF",
    path: "summary-sheet-caf",
    isvalid: false,
  },
  {
    chapter: "chapter12",
    label: "Formal sign off",
    name: "isChapter12valid",
    path: "formal-sign-off",
    isvalid: false,
  },
];
