import { AnnexNoUBO, UBOPEP, AnnexUBO } from "./constants";
export default function getSequentialFields(response, chapter) {
  let errorChapter;
  switch (chapter) {
    case "AnnexNoUBO":
      errorChapter = AnnexNoUBO;
      break;
    case "UBOPEP":
      errorChapter = UBOPEP;
      break;
    case "AnnexUBO":
      errorChapter = AnnexUBO;
      break;
    default:
      return null;
  }
  Object.keys(errorChapter).forEach((key) => {
    Object.entries(response).forEach((val) => {
      const reskey = val[0];
      const resVal = val[1];
      if (key === reskey) {
        errorChapter[key] = resVal;
      }
    });
  });
  return { errorChapter };
}
