import axios from "axios";

const BACKEND_API_URL = process.env.REACT_APP_USER_BACKEND_API_URL;

const setNewUKDataToReducerReceiveData = (json) => {
  return {
    type: "UPDATE_NEW_UK_DATA",
    data: json,
  };
};

export const setNewUKDataToReducer = (data) => {
  return (dispatch) => {
    dispatch(setNewUKDataToReducerReceiveData(data));
  };
};

const SetUKCAFDetailsRequest = () => {
  return {
    type: "SET_UK_CAF_DETAILS_REQUEST",
  };
};

const SetUKCAFDetailsReceiveData = (json) => {
  return {
    type: "SET_UK_CAF_DETAILS_SUCCESS",
    data: json,
  };
};

export const setUKCAFDetails = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(SetUKCAFDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/UKCAF/SetCAFDetails`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: "SET_UK_CAF_DETAILS_FAIL", data: json });
        } else {
          return dispatch(SetUKCAFDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "SET_UK_CAF_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};

const GetUKCAFDetailsRequest = () => {
  return {
    type: "GET_UK_CAF_DETAILS_REQUEST",
  };
};

const GetUKCAFDetailsReceiveData = (json) => {
  return {
    type: "GET_UK_CAF_DETAILS_SUCCESS",
    data: json,
  };
};

export const getUKCAFDetails = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(GetUKCAFDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/UKCAF/GetCAFExternal`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: "GET_UK_CAF_DETAILS_FAIL", data: json });
        } else {         
          return dispatch(GetUKCAFDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "GET_UK_CAF_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};
