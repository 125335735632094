import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { connect } from "react-redux";
import { setAllUKErrorsToNull } from "../../../../redux/actions/UK/UKInternalChapterErrorActions";
import { setUKInternalCAFReject,getUKInternalCAFRejectComments } from "../../../../redux/actions/UK/UKInternalCAFActions";
import Modal from "../../../components/shared/Modal";
import NotificationManager from "react-notifications/lib/NotificationManager";
import moment from "moment";
import jwt_decode from "jwt-decode";
import DataTable from "react-data-table-component";
let formData;
let validForms = {};

class UKInternalSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      formData: null,
      modal:false,
      hasCmnt:true,
      rejectionCmntHistory:[],
      columnsRejectionHistory: [ 
        {
          name: "Rejected by",
          selector: "rejectedByUserName",
          sortable: true,
          },
        {
        name: "Date",
        sortable: true,
        selector: (row) => (
          <div>
            {row.lastUpdate ? (
              moment(row.lastUpdate).format("YYYY-MM-DD HH:mm")
            ) : (
              <div className="center">-</div>
            )}
          </div>
        ),
        },
        {
        name: "Comment",
        selector: "comment",
        sortable: true,
        },
     ],
     };
  }
  handleReject= () => {
     formData = localStorage.getItem("formData");
     this.modalOpen(formData); 
  };
  modalOpen = (formData) => {
    this.setState({ 
      //rejectionCmntHistory: res.data.result,
      modal: true,
      formData:formData 
     });         
    let reqData = {
      id: JSON.parse(formData).id,
      userId: JSON.parse(formData).userId,  
      };
    this.props.getUKInternalCAFRejectComments(reqData).then((res) => {
      if (res.data.portalErrorCode !== 200) {
        //NotificationManager.error("Error occured");
      }else {
        this.setState({ 
          rejectionCmntHistory: res.data.result,
          
         });          
      }
    });
  };

  modalClose = () => {
    this.setState({
      modal: false,
    });
  };

  handleChangeRejectCmnt = (event) => {
    if(event.target.value){
      this.setState({
        hasCmnt: false,
      });
    }
    else{
      this.setState({
        hasCmnt: true,
      });
    }
    this.setState({
      rejectCmnt: event.target.value,
    });
  };
  handleYes = () => {
    let userName;
    let userEmail;
    const token = JSON.parse(localStorage.getItem("authToken"));
    if (token) {
      const decodedDetails = jwt_decode(token);
      userName=decodedDetails.name
      userEmail=decodedDetails.preferred_username

    }
    let reqData = {
      id: JSON.parse(this.state.formData).id,
      userId: JSON.parse(this.state.formData).userId,  
      rejectedByUsername:userName,
      rejectedByUserEmail:userEmail,
      userName:JSON.parse(this.state.formData).userName,
      userEmail:JSON.parse(this.state.formData).userEmail,
      rejectCmnt: this.state.rejectCmnt,

    };
    this.props.setUKInternalCAFReject(reqData).then((res) => {
          if (res.data.portalErrorCode !== 200) {
            NotificationManager.error("Error occured");
          }else {
            NotificationManager.success("Form is rejected");
            this.modalClose();
            window.location = "/internal-dashboard/client-form-list" 
            }
        });
      };
 handleNo = () => {
        this.modalClose();
      };

  render() {
    const { location } = this.props.history;
    const { internalErrors } = this.props;
    Object.entries(internalErrors).forEach((errors) => {
      const [key, value] = errors;
      const error = value?.errors;
      if (error) {
        validForms[key] = error;
      }
    });
    setInterval(() => {
      if (!formData) {
        formData = localStorage.getItem("formData");
        if (formData) {
          this.setState({ formData: formData });
        }
      }
    }, 100);
   
    return (
      <div className="sidebar">
        <ul>
         
          <li>
            <label>Client Maintenance</label>
            <ul className="sidebar-innerlist">
              <li>
                <Link
                  to="/clientlist"
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    this.props.setAllUKErrorToNull();
                    localStorage.removeItem("formData");
                  }}
                  className={
                    location.pathname === "/clientlist" ? "active" : ""
                  }
                >
                  <i className="fa fa-user fa-fw"></i> Client list
                </Link>
                <Link
                  to="/clientapprovals"
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    this.props.setAllUKErrorToNull();
                    localStorage.removeItem("formData");
                  }}
                  className={
                    location.pathname === "/clientapprovals" ? "active" : ""
                  }
                >
                  <i className="fa fa-check-circle fa-fw"></i> Client User Approvals
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <label>User Maintenance</label>
            <ul className="sidebar-innerlist">
              <li>
                <Link
                  to="/userlist"
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    this.props.setAllUKErrorToNull();
                    localStorage.removeItem("formData");
                  }}
                  className={location.pathname === "/userlist" ? "active" : ""}
                >
                  <i className="fa fa-user fa-fw"></i> User list
                </Link>
                <Link
                  to="/userapprovals"
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    this.props.setAllUKErrorToNull();
                    localStorage.removeItem("formData");
                  }}
                  className={
                    location.pathname === "/userapprovals" ? "active" : ""
                  }
                >
                  <i className="fa fa-check-circle fa-fw"></i> User Approvals
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <label>Client Acceptance Form</label>
            <ul className="sidebar-innerlist">
              <li>
                <Link
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllUKErrorToNull();
                  }}
                  to="/client-form-list"
                  className={
                    location.pathname === "/client-form-list" ? "active" : ""
                  }
                >
                  <i className="fa fa-user fa-fw"></i> Client Form List
                </Link>
              </li>
            </ul>
            <ul className="sidebar-innerlist subPoint">
              <li>
                <Link
                  to="/united-kingdom/client-activity"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter1?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/united-kingdom/client-activity"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-check fa-fw"></i> Client Activity
                </Link>
              </li>
              <li>
                <Link
                  to="/united-kingdom/client-investigation"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter2?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/united-kingdom/client-investigation"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-search fa-fw"></i> Client Investigation{" "}
                </Link>
              </li>
              <li>
                <Link
                  to="/united-kingdom/ubo-upc"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter3?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/united-kingdom/ubo-upc"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-user-circle fa-fw"></i> UBO/UPC
                </Link>
              </li>
              <li>
                <Link
                  to="/united-kingdom/object-company"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter4?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/united-kingdom/object-company"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-building-o fa-fw"></i> Object Company
                </Link>
              </li>
              <li>
                <Link
                  to="/united-kingdom/country-sanction-risks"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter5?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname ===
                    "/united-kingdom/country-sanction-risks"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-globe fa-fw"></i> Country/Sanction Risks
                </Link>
              </li>
              <li>
                <Link
                  to="/united-kingdom/agreement"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter6?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/united-kingdom/agreement"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-thumbs-up fa-fw"></i> Agreement
                </Link>
              </li>
              <li>
                <Link
                  to="/united-kingdom/consolidated-risk-assessment"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter8?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname ===
                    "/united-kingdom/consolidated-risk-assessment"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-check-square-o fa-fw"></i> Consolidated
                  Risk Assessment
                </Link>
              </li>
              <li>
                <Link
                  to="/united-kingdom/correspondence"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter9?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/united-kingdom/correspondence"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-file-text-o fa-fw"></i> Correspondence
                </Link>
              </li>
              <li>
                <Link
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter10?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  to="/united-kingdom/miscellaneous"
                  className={
                    location.pathname === "/united-kingdom/miscellaneous"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-cog fa-fw"></i> Miscellaneous
                </Link>
              </li>
              <li>
                <Link
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter11?.isValid === true
                        ? "bolder"
                        : null,
                  }}
                  to="/united-kingdom/formal-sign-off"
                  className={
                    location.pathname === "/united-kingdom/formal-sign-off"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-pencil-square-o fa-fw"></i> Formal sign
                  off
                </Link>
              </li>
              <li>
              <button
                     type="button"
                     className={location.pathname.includes("/united-kingdom/") ? "reject-btn noicon":"reject-btn noicon disabled"}
                     disabled={location.pathname.includes("/united-kingdom/") ? false:true}
                     onClick={() => this.handleReject()}
                   >
                     Reject
              </button>
              </li>
            </ul>
          </li>
        </ul>
        <Modal 
              customSize="45%"
              show={this.state.modal}
              handleClose={(e) => this.modalClose(e)}
            >
             
              <DataTable
                  className="datatable"
                  columns={this.state.columnsRejectionHistory}
                  data={this.state.rejectionCmntHistory}
                  onSelectedRowsChange={this.handleRowSelection}
                  pagination
                />
            <br></br>
              <h4 className="mb-4">Are you sure you want to reject?</h4>
              <div>
                  <textarea
                   type="text"
                   name="rejectCmnt"
                   className="form-control"
                   onChange={(e) => this.handleChangeRejectCmnt(e)}
                   value={this.state.rejectCmnt}
                   autoComplete="off"
                   id="rejectCmnt"
                  />
              </div>
              <br></br>
              <div className="form-group mb-0">
                <button
                  onClick={(e) => this.handleYes(e)}
                  type="button"
                  className="btn btn-success mr-2"
                  disabled={this.state.hasCmnt}
                >
                  Yes
                </button>
                <button
                  onClick={(e) => this.handleNo(e)}
                  type="button"
                  className="btn btn-secondary mr-2 active"
                >
                  No
                </button>
              </div>
            </Modal>
      </div>
      
    );
  }
}

const mapStateToProps = (state) => {
  return {
    internalErrors: state.UKInternalChapterError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAllUKErrorToNull: () => dispatch(setAllUKErrorsToNull()),
    setUKInternalCAFReject: async (data) => dispatch(await setUKInternalCAFReject(data)),
    getUKInternalCAFRejectComments: async (data) => dispatch(await getUKInternalCAFRejectComments(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UKInternalSidebar)
);
