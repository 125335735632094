export const INTERNAL_UPLOAD_FILE_REQUEST = "INTERNAL_UPLOAD_FILE_REQUEST";
export const INTERNAL_UPLOAD_FILE_SUCCESS = "INTERNAL_UPLOAD_FILE_SUCCESS";
export const INTERNAL_UPLOAD_FILE_FAIL = "INTERNAL_UPLOAD_FILE_FAIL";

export const INTERNAL_DOWNLOAD_FILE_REQUEST = "INTERNAL_DOWNLOAD_FILE_REQUEST";
export const INTERNAL_DOWNLOAD_FILE_SUCCESS = "INTERNAL_DOWNLOAD_FILE_SUCCESS";
export const INTERNAL_DOWNLOAD_FILE_FAIL = "INTERNAL_DOWNLOAD_FILE_FAIL";

export const DOWNLOAD_EXTERNAL_FILE_REQUEST = "DOWNLOAD_EXTERNAL_FILE_REQUEST";
export const DOWNLOAD_EXTERNAL_FILE_SUCCESS = "DOWNLOAD_EXTERNAL_FILE_SUCCESS";
export const DOWNLOAD_EXTERNAL_FILE_FAIL = "DOWNLOAD_EXTERNAL_FILE_FAIL";

export const INTERNAL_DELETE_FILE_REQUEST = "INTERNAL_DELETE_FILE_REQUEST";
export const INTERNAL_DELETE_FILE_SUCCESS = "INTERNAL_DELETE_FILE_SUCCESS";
export const INTERNAL_DELETE_FILE_FAIL = "INTERNAL_DELETE_FILE_FAIL";

export const DOWNLOAD_SIGNED_DOCS_REQUEST = "DOWNLOAD_SIGNED_DOCS_REQUEST";
export const DOWNLOAD_SIGNED_DOCS_SUCCESS = "DOWNLOAD_SIGNED_DOCS_SUCCESS";
export const DOWNLOAD_SIGNED_DOCS_FAIL = "DOWNLOAD_SIGNED_DOCS_FAIL";

export const DOCUSIGN_GENERATE_URL_REQUEST = "DOCUSIGN_GENERATE_URL_REQUEST";
export const DOCUSIGN_GENERATE_URL_SUCCESS = "DOCUSIGN_GENERATE_URL_SUCCESS";
export const DOCUSIGN_GENERATE_URL_FAIL = "DOCUSIGN_GENERATE_URL_FAIL";

export const DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_REQUEST =
  "DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_REQUEST";
export const DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_SUCCESS =
  "DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_SUCCESS";
export const DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_FAIL =
  "DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_FAIL";
