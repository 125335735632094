export const fileAcceptType = ".doc,.docx,.odt,.pdf,.rtf,.tex,.txt,.wpd,.ods,.xls,.xlsm,.xlsx,.key,.odp,.pps,.ppt,.pptx,.jpeg,.jpg,.png";

export const upcTypes = [
  {
    label: "Listed Corporate",
    value: "Listed Corporate",
  },
  {
    label: "Unlisted Corporate",
    value: "Unlisted Corporate",
  },
  {
    label: "Regulated Institution",
    value: "Regulated Institution",
  },
  {
    label: "Investment Fund",
    value: "Investment Fund",
  },
  {
    label: "Trust",
    value: "Trust",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const complianceList = [
  {
    label: "Physical Person(s)",
    value: "Physical Person(s)",
  },
  {
    label: "Corporate",
    value: "Corporate",
  },
  {
    label: "Other",
    value: "Other",
  },
];
