export const fileAcceptType = ".doc,.docx,.odt,.pdf,.rtf,.tex,.txt,.wpd,.ods,.xls,.xlsm,.xlsx,.key,.odp,.pps,.ppt,.pptx,.jpeg,.jpg,.png";
export const legalArray=[
  {value:"Anglo-Saxon Trust",label:"Anglo-Saxon Trust"},
  {value:"AUNV",label:"AUNV"},
  {value:"AVV",label:"AVV"},
  {value:"Business Company",label:"Business Company"},
  {value:"Channel Islands Limited",label:"Channel Islands Limited"},
  {value:"Corporation",label:"Corporation"},
  {value:"CV",label:"CV"},
  {value:"CV_NFR",label:"CV_NFR"},
  {value:"Curacao Trust",label:"Curacao Trust"},
  {value:"FOUND_NFR",label:"FOUND_NFR"},
  {value:"FOUNDATION",label:"FOUNDATION"},
  {value:"GmbH",label:"GmbH"},
  {value:"IBC",label:"IBC"},
  {value:"IBC_NFR",label:"IBC_NFR"},
  {value:"Limited Liability Company",label:"Limited Liability Company"},
  {value:"LIMITED PARTNERSHIP",label:"LIMITED PARTNERSHIP"},
  {value:"LIMITED",label:"LIMITED"},
  {value:"LIMITED_NFR",label:"LIMITED_NFR"},
  {value:"NABV",label:"NABV"},
  {value:"NABV_NFR",label:"NABV_NFR"},
  {value:"NANV",label:"NANV"},
  {value:"NANV_NFR",label:"NANV_NFR"},
  {value:"NLBV",label:"NLBV"},
  {value:"NLBV_NFR",label:"NLBV_NFR"},
  {value:"NLCV",label:"NLCV"},
  {value:"NLNV",label:"NLNV"},
  {value:"NLNV_NFR",label:"NLNV_NFR"},
  {value:"Open Partnership",label:"Open Partnership"},
  {value:"OTHER",label:"OTHER"},
  {value:"PANAMESE PRIVATE FOUNDATION",label:"PANAMESE PRIVATE FOUNDATION"},
  {value:"PANAMESE S.A",label:"PANAMESE S.A"},
  {value:"Private Limited Company",label:"Private Limited Company"},
  {value:"Private Limited Liability Company",label:"Private Limited Liability Company"},
  {value:"Private Person",label:"Private Person"},
  {value:"SA",label:"SA"},
  {value:"SPF",label:"SPF"},
  {value:"SPF_NFR",label:"SPF_NFR"},
  {value:"Stichting Administratiekantoor",label:"Stichting Administratiekantoor"},
  {value:"STAK_NFR",label:"STAK_NFR"},
  {value:"Stichting",label:"Stichting"},
  {value:"VOF",label:"VOF"}, 
]
export const clientGroupArr=[
  {value:"Altcom Group of Companies",label:"Altcom Group of Companies"},
  {value:"Arab Investments",label:"Arab Investments"},
  {value:"Azizollahoff",label:"Azizollahoff"},
  {value:"BEREG Group",label:"BEREG Group"},
  {value:"Blanckenburg Group of Companies",label:"Blanckenburg Group of Companies"},
  {value:"Bluewater",label:"Bluewater"},
  {value:"Bongers",label:"Bongers"},
  {value:"Candal",label:"Candal"},
  {value:"Cardon Group of Companies",label:"Cardon Group of Companies"},
  {value:"Delaware",label:"Delaware"},
  {value:"El-Assir Group of Companies",label:"El-Assir Group of Companies"},
  {value:"Excellence Group of Companies",label:"Excellence Group of Companies"},
  {value:"Fortis Intertrust Management S.A. Group",label:"Fortis Intertrust Management S.A. Group"},
  {value:"Halstead",label:"Halstead"},
  {value:"Heerema",label:"Heerema"},
  {value:"Hetrix groep",label:"Hetrix groep"},
  {value:"Indofin Group of Companies",label:"Indofin Group of Companies"},
  {value:"ITPS Group of companies",label:"ITPS Group of companies"},
  {value:"Kerssens",label:"Kerssens"},
  {value:"Klene Group of Companies",label:"Klene Group of Companies"},
  {value:"Korswagen",label:"Korswagen"},
  {value:"LBH",label:"LBH"},
  {value:"Merifin Group of Companies",label:"Merifin Group of Companies"},
  {value:"Moderne Group of Companies",label:"Moderne Group of Companies"},
  {value:"NGen Group of Companies",label:"NGen Group of Companies"},
  {value:"Nieuwenhuijzen",label:"Nieuwenhuijzen"},
  {value:"Panama Companies",label:"Panama Companies"},
  {value:"Phaedrus Group of Companies",label:"Phaedrus Group of Companies"},
  {value:"Ramphastos",label:"Ramphastos"},
  {value:"Rixos Group of Companies",label:"Rixos Group of Companies"},
  {value:"Rixos/Sembol Group of Companies",label:"Rixos/Sembol Group of Companies"},
  {value:"Sedco",label:"Sedco"},
  {value:"Socar Group of Companies",label:"Socar Group of Companies"},
  {value:"Soliderco Group of Companies",label:"Soliderco Group of Companies"},
  {value:"SSX Group of Companies",label:"SSX Group of Companies"},
  {value:"Timbookto Group of Companies",label:"Timbookto Group of Companies"},
  {value:"Tucana Group of Companies",label:"Tucana Group of Companies"},
  {value:"Universal Brands N.V. Group of Companies",label:"Universal Brands N.V. Group of Companies"},
  {value:"United Group of Companies",label:"United Group of Companies"},
  {value:"Walmart",label:"Walmart"},
  {value:"ZBG",label:"ZBG"}, 
]
export const clientTypeArr=[
  {value:"Corporate",label:"Corporate"},
  {value:"Private",label:"Private"},
]
export const clientIndustryArr=[ 
  {value:"Advisory",label:"Advisory"},
  {value:"Agriculture",label:"Agriculture"},
  {value:"Automotive",label:"Automotive"},
  {value:"Aviation",label:"Aviation"},
  {value:"Banking & Financing",label:"Banking & Financing"},
  {value:"Charitable institution",label:"Charitable institution"},
  {value:"Chemicals",label:"Chemicals"},
  {value:"Construction and Industrial",label:"Construction and Industrial"},
  {value:"Consulting",label:"Consulting"},
  {value:"Education",label:"Education"},
  {value:"Energy",label:"Energy"},
  {value:"Engineering",label:"Engineering"},
  {value:"Financing",label:"Financing"},
  {value:"Foreign company",label:"Foreign company"},
  {value:"Food & Beverages",label:"Food & Beverages"},
  {value:"Fund",label:"Fund"},
  {value:"Health Care and Pharmaceuticals",label:"Health Care and Pharmaceuticals"},
  {value:"Holding company",label:"Holding company"},
  {value:"Industrial",label:"Industrial"},
  {value:"not in use",label:"not in use"},
  {value:"Investment company",label:"Investment company"},
  {value:"Internet Technology",label:"Internet Technology"},
  {value:"Leasing",label:"Leasing"},
  {value:"Logistic",label:"Logistic"},
  {value:"Marine and Aviation",label:"Marine and Aviation"},
  {value:"Mining",label:"Mining"},
  {value:"Oil & Gas company",label:"Oil & Gas company"},
  {value:"Pulp and Paper",label:"Pulp and Paper"},
  {value:"Pension Fund",label:"Pension Fund"},
  {value:"Private Equity",label:"Private Equity"},
  {value:"Production",label:"Production"},
  {value:"Publishing",label:"Publishing"},
  {value:"Real Estate",label:"Real Estate"},
  {value:"Renewable Energy",label:"Renewable Energy"},
  {value:"Retail",label:"Retail"},
  {value:"Royalty Company",label:"Royalty Company"},
  {value:"Services",label:"Services"},
  {value:"Sports and Entertainment",label:"Sports and Entertainment"},
  {value:"Technology Media & Telecom",label:"Technology Media & Telecom"},
  {value:"Tobacco Industry",label:"Tobacco Industry"},
  {value:"Trading company",label:"Trading company"},
  {value:"Transportation",label:"Transportation"},
  {value:"Waste Management",label:"Waste Management"},
 
]
export const clientActivitiesTypeArr=[
  {value:"Agency",label:"Agency"},
  {value:"Aviation",label:"Aviation"},
  {value:"Captive Insurance",label:"Captive Insurance"},
  {value:"Construction",label:"Construction"},
  {value:"Consulting",label:"Consulting"},
  {value:"E-zone",label:"E-zone"},
  {value:"Finance",label:"Finance"},
  {value:"Holding",label:"Holding"},
  {value:"Investment",label:"Investment"},
  {value:"IT",label:"IT"},
  {value:"Lening van",label:"Lening van"},
  {value:"Lening uit",label:"Lening uit"},
  {value:"Marine",label:"Marine"},
  {value:"Real Estate",label:"Real Estate"},
  {value:"Royalties",label:"Royalties"},
  {value:"Shipping",label:"Shipping"},
  {value:"Trading",label:"Trading"},
  
]

export const clientActivitiesRegionArr=[
  {value:"Africa(excl. North Africa)",label:"Africa(excl. North Africa)"},
  {value:"Caribbean",label:"Caribbean"},
  {value:"Asia",label:"Asia"},
  {value:"Europe",label:"Europe"},
  {value:"CIS (Commonwealth of Independent States: Armenia, Belarus, Kazakhstan, Kyrgyzstan, Moldova, Russia, Tajikistan, Turkmenistan, Ukraine, and Uzbekistan)",label:"CIS (Commonwealth of Independent States: Armenia, Belarus, Kazakhstan, Kyrgyzstan, Moldova, Russia, Tajikistan, Turkmenistan, Ukraine, and Uzbekistan)"},
  {value:"LATAM",label:"LATAM"},
  {value:"M.E / N.A. (Middle East/North Africa)",label:"M.E / N.A. (Middle East/North Africa)"},
  {value:"USA / Canada",label:"USA / Canada"},
]

export const chapter1 = {
  legalform:"",
  address:"",
  statutorySeat:"",
  actualBusinessSeat:"",
  incorporationDate:"",
  registrationNumberCOC:"",
  isbusinessSeatFromStatutorySeat:"",
  registrationNumberStatutorySeat:"",
  firstBookYear:"",
  dateBecameCustomer:"",
  transferredFrom:"",
  numCorporateDirectors:1,
  corporateDirectors:[],
  numPersonalDirectors:1,
  personalDirectors:[],
  management:"",
  managementFee:"",
  representation:"",
  representationFee:"",
  legalServices:"",
  legalServicesFee:"",
  accountingServices:"",
  accountingServicesFee:"",
  taxServices:"",
  taxServicesFee:"",
  hoursFeeArrang:"",
  fixedFeeArrang:"",
  nameContact:"",
  addressContact:"",
  telephonContact:"",
  emailContact:"",
  nameCopyInvoice:"",
  addressCopyInvoice:"",
  telephonCopyInvoice:"",
  emailCopyInvoice:"",
  isNewclientGroup:"",
  clientGroup:"",
  introducingFirm:"",
  introducingPerson:"",
  clientType:"",
  numClientIndustries:1,
  clientIndustry:"",
  clientActivitiesType:"",
  clientActivitiesCountry:"",
  clientActivitiesRegion:"",
  dateCompleted:"",
  by:"",
  temporaryAcceptance:"",
  clientAccepted:"",
  numUBOs:1,
  ubos:[],
  descripClientAndBackgroundInfo:"",
  descripTaxPositionAndUnderlyingRationale:"",
  descripStructureAndEntityDiagram:"",
  numEntities:1,
  Entities:[],
  // structureChartComm: "",
  // isInLineUIMAccPolicy: "",
  // isAddDocs: "",
  // addDocs: [],
  // isRisk: "",
  // custActivityRisks: [],
  // custActvtyRskRtng: "",
  // descActnNeedsTkn: "",
};
export const chapter2 = {
  isAddDocs: "",
  addDocs: [],

  isRisk: "",
  custActivityRisks: [],
  custActvtyRskRtng: "",
  descActnNeedsTkn: "",
};
export const chapter3 = {
  isHldngIntrstCmmnt: "",
  isUBOClsifiedCmmnt: "",
  isAsstClientCmmnt: "",
  noUBOClassifiedCmmnt: "",
  uboApproval: [],
  upcApproval: [],
  pascalReportUpload: "",
  isFindings: "",
  descInvstgUBOClsifiedPEP: "",
  isMediaCoverageOnStruct: "",
  isAddDocs: "",
  addDocs: [],
  isRisk: "",
  custActivityRisks: [],
  custActvtyRskRtng: "",
  descActnNeedsTkn: "",
  isUBOresultIncrsdIntgrtyRsk: "",
  ubOresultIncrsdIntgrtyRskCmmnt: "",
};
export const chapter4 = {
  objCompCmmnt: "",
  objCompDirCmmnt: "",
  objPascalResport: "",
  objFindings: "",
  objCompActiveConCmmnt: "",
  rationaleStrctrngForNLCmmnt: "",
  findingsIndpTaxAdvisorApprvd: "",
  bsEvidencesIsApprvd: "",
  bsEvidencesCmmnt: "",
  cpyPassportMangApprvd: "",
  extCOCApprvd: "",
  deedOfIncorpApprvd: "",
  shareholdersRegApprvd: "",
  shareholdersCrtfictsApprvd: "",
  finStmntsPstthreeYrsApprvd: "",
  uboInvstObjCompCmmnt: "",
  annualFinStmntupcApprvd: "",
  upcFundObjCompCmmnt: "",
  anulFinStmntTpApprvd: "",
  tpInvstObjCompCmmnt: "",
  cntrysInvldObjCompCmmnt: "",
  asstSmmryEvidenceApprvd: "",
  assSmmryObjCompCommnt: "",
  expctdTransSummryApprvd: "",
  expctdTransSummryCmmnt: "",
  uboObjCmpCmmnt: "",
  objCompUboCmmnt: "",
  objCompGrpCompsCmmnt: "",
  grpCompsObjCompCmmnt: "",
  objCompTpCmmnt: "",
  tpObjCompCmmnt: "",
  bnkInfoRsk: "",
  bnkInfoCmmnt: "",
  isAddDocs: "",
  addDocs: [],
  isRisk: "",
  apaCmmnt: "",
  custActivityRisks: [],
  custActvtyRskRtng: "",
  descActnNeedsTkn: "",
  objCompIncrdIntgtyRsk: "",
  objCompIncrdIntgtyRskText: "",
};
export const chapter5 = {
  isUIMAwareOfIncidents: "",
  uIMComment: "",
  structureComment: "",
  isRestrictiveMeasure: "",
  isStructurePlacedOnFATF: "",
  isAddDocs: "",
  addDocs: [],
  isRisk: "",
  custActivityRisks: [],
  custActvtyRskRtng: "",
  descActnNeedsTkn: "",
  isUnacceptableRisksIdentified: "",
  unacceptableRisksReason: "",
};
export const chapter6 = {
  reportWorldCheckExtDir: "",
  findingsWorldCheck: "",
  isAddDocs: "",
  addDocs: [],
  isRisk: "",
  custActivityRisks: [],
  custActvtyRskRtng: "",
  descActnNeedsTkn: "",
  isUnacceptableRisksIdentified: "",
  unacceptableRisksReason: "",
};
export const chapter8 = { overallRiskRating: "" };
export const chapter9 = { overallRiskRating: "", findingWorldCheck: "" };
export const chapter10 = { isOtherMatterNotable: "", otherMtrsImpCmnt: "" };
export const chapter11 = { ovrallRsk: "" };
export const chapter12 = {
  isReasonTigCliInvstg: "",
  reserveBusAccepComite: "",
  isBusAccepAccClnt: "",
  descReasDueDilignce: "",
  isstructOvrvwClr: "",
  isclientsIdntVrfd: "",
  isuboClssfctnIdntVrfctn: "",
  issrcWlthClr: "",
  ischckPEP: "",
  isobjPurObjCompClr: "",
  isTrnsPrflClr: "",
  isPurStrctrngKnwRecd: "",
  isSancRulChckd: "",
  isRelCorrespndceAdded: "",
  dateStrtOfSrvcs: "",
};


export const SignedByList = [
  {
    label: "Form Creator",
    value: "FormCreator",
    isFixed: false,
    name: null,
    email: null,
    dos: new Date(),
  },
  {
    label: "Legal Manager",
    value: "LegalManager",
    isFixed: false,
    name: null,
    email: null,
    dos: null,
  },
  {
    label: "Compliance Officer",
    value: "ComplianceOfficer",
    isFixed: false,
    name: null,
    email: null,
    dos: null,
  },
  {
    label: "Management",
    value: "Management",
    isFixed: false,
    name: null,
    email: null,
    dos: null,
  },
];

export const chapValidationErrorCuracao = [
  {
    chapter: "chapter1",
    label: "Client Activity",
    path: "client-activity",
    isvalid: false,
    name: "isChapter1valid",
  },
  {
    chapter: "chapter2",
    label: "Client Investigation",
    path: "client-investigation",
    name: "isChapter2valid",
    isvalid: false,
  },
  {
    chapter: "chapter3",
    label: "UBO/UPC",
    path: "ubo-upc",
    name: "isChapter3valid",
    isvalid: false,
  },
  {
    chapter: "chapter4",
    label: "Object Company",
    name: "isChapter4valid",
    path: "object-company",
    isvalid: false,
  },
  {
    chapter: "chapter5",
    label: "Country/Sanction Risks",
    name: "isChapter5valid",
    path: "country-sanction-risks",
    isvalid: false,
  },
  {
    chapter: "chapter6",
    label: "Agreement",
    name: "isChapter6valid",
    path: "agreement",
    isvalid: false,
  },
  {
    chapter: "chapter8",
    label: "Consolidated Risk Assesment",
    name: "isChapter8valid",
    path: "consolidated-risk-assessment",

    isvalid: false,
  },
  {
    chapter: "chapter9",
    label: "Correspondence",
    path: "correspondence",
    name: "isChapter9valid",

    isvalid: false,
  },
  {
    chapter: "chapter10",
    name: "isChapter10valid",
    label: "Miscellaneous",
    path: "miscellaneous",
    isvalid: false,
  },
  {
    chapter: "chapter11",
    name: "isChapter11valid",
    label: "Summary sheet CAF",
    path: "summary-sheet-caf",
    isvalid: false,
  },
  {
    chapter: "chapter12",
    label: "Formal sign off",
    name: "isChapter12valid",
    path: "formal-sign-off",
    isvalid: false,
  },
];
