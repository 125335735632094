import {
  SET_ALL_MALTA_ERRORS_TO_NULL,
  SET_MALTA_ERROR_FOR_CHAPTER,
} from "../../constants/Malta/MaltaInternalChapterErrorConstants";

export const setMaltaInternalChapterError = (data) => {
  return (dispatch) => {
    return dispatch({ type: SET_MALTA_ERROR_FOR_CHAPTER, data: data });
  };
};

export const setAllMaltaErrorsToNull = () => {
  return (dispatch) => {
    return dispatch({ type: SET_ALL_MALTA_ERRORS_TO_NULL });
  };
};
