import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import {getToken} from "../getToken"
const MSLOGIN_CLIENTID = process.env.REACT_APP_MSLOGIN_CLIENTID;
const MSLOGIN_REDIRECTURL = process.env.REACT_APP_MSLOGIN_REDIRECTURL;
// const getToken = async () => {
//   const cfg = {
//     auth: {
//       clientId: MSLOGIN_CLIENTID,
//       authority:
//         "https://login.microsoftonline.com/37ab276a-6d7b-4b26-95c4-23f9fd7d5786", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
//       redirectUri: MSLOGIN_REDIRECTURL,
//     },
//     cache: {
//       cacheLocation: "localStorage", // This configures where your cache will be stored
//       storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
//     },
//   };

//   const pca = new PublicClientApplication(cfg);
//   let allAccounts = JSON.parse(localStorage.getItem("allAccounts"));
//   const account = allAccounts[0];
//   let idToken = null;
//   try {
//     const resp = await pca.acquireTokenSilent({
//       scopes: ["User.Read"],
//       account,
//     });
//     idToken = resp.idToken;
//     return idToken;
//   } catch (err) {
//     return;
//   }
// };
const BACKEND_API_URL = process.env.REACT_APP_INTERNAL_USER_BACKEND_API_URL;

const SetMaltaInternalCAFDetailsRequest = () => {
  return {
    type: "SET_MALTA_INTERNAL_CAF_DETAILS_REQUEST",
  };
};

const SetMaltaInternalCAFDetailsReceiveData = (json) => {
  return {
    type: "SET_MALTA_INTERNAL_CAF_DETAILS_SUCCESS",
    data: json,
  };
};

export const setMaltaInternalCAFDetails = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(SetMaltaInternalCAFDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/MaltaCAF/SetCAFDetails`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({
            type: "SET_MALTA_INTERNAL_CAF_DETAILS_FAIL",
            data: json,
          });
        } else {
          return dispatch(SetMaltaInternalCAFDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: "SET_MALTA_INTERNAL_CAF_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};

const GetMaltaInternalCAFDetailsRequest = () => {
  return {
    type: "GET_MALTA_INTERNAL_CAF_DETAILS_REQUEST",
  };
};

const GetMaltaInternalCAFDetailsReceiveData = (json) => {
  return {
    type: "GET_MALTA_INTERNAL_CAF_DETAILS_SUCCESS",
    data: json,
  };
};

export const getMaltaInternalCAFDetails = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(GetMaltaInternalCAFDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/MaltaCAF/GetCAFInternal`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({
            type: "GET_MALTA_INTERNAL_CAF_DETAILS_FAIL",
            data: json,
          });
        } else {
          return dispatch(GetMaltaInternalCAFDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: "GET_MALTA_INTERNAL_CAF_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};
