export const GET_UBO_PEP_REQUEST = "GET_UBO_PEP_REQUEST";
export const GET_UBO_PEP_SUCCESS = "GET_UBO_PEP_SUCCESS";
export const GET_UBO_PEP_FAIL = "GET_UBO_PEP_FAIL";

export const SEND_BULK_EMAIL_REQUEST = "SENT_BULK_EMAIL_REQUEST";
export const SEND_BULK_EMAIL_FAIL = "SENT_BULK_EMAIL_FAIL";
export const SEND_BULK_EMAIL_SUCCESSS = "SENT_BULK_EMAIL_SUCCESS";

export const SEND_EMAIL_TO_UBO_REQUEST = "SEND_EMAIL_TO_UBO_REQUEST";
export const SEND_EMAIL_TO_UBO_FAIL = "SEND_EMAIL_TO_UBO_FAIL";
export const SEND_EMAIL_TO_UBO_SUCCESS = "SENT_EMAIL_TO_UBO_SUCCESS";

export const RESEND_EMAIL_TO_UBO_REQUEST = "RESEND_EMAIL_TO_UBO_REQUEST";
export const RESEND_EMAIL_TO_UBO_FAIL = "RESEND_EMAIL_TO_UBO_FAIL";
export const RESEND_EMAIL_TO_UBO_SUCCESS = "RESENT_EMAIL_TO_UBO_SUCCESS";

export const CHANGE_STATUS_ON_NEXT_REQUEST = "CHANGE_STATUS_ON_NEXT_REQUEST";
export const CHANGE_STATUS_ON_NEXT_SUCCESS = "CHANGE_STATUS_ON_NEXT_SUCCESS";
export const CHANGE_STATUS_ON_NEXT_FAIL = "CHANGE_STATUS_ON_NEXT_FAIL";

export const CHECK_FORM_STATUS_REQUEST = "CHECK_FORM_STATUS_REQUEST";
export const CHECK_FORM_STATUS_SUCCESS = "CHECK_FORM_STATUS_SUCCESS";
export const CHECK_FORM_STATUS_FAIL = "CHECK_FORM_STATUS_FAIL";
