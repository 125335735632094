import React, { Component } from "react";
import { Row } from "react-bootstrap";
import countryList from "react-select-country-list";
import DropDown from "../../../../../../elements/DropDown";
import TextBox from "../../../../../../elements/TextBox";
import CheckBox from "../../../../../../elements/CheckBox";
import FileUpload from "../../../../../../elements/FileUpload";
import { fileAcceptType, NatureOfObjectComArray } from "../constants";
import { connect } from "react-redux";
class Beneficiaries extends Component {
    constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, errorMsg,isbenPassport, isbenProofAdd,isbenRefLett} = this.props;
    const eMsg = errorMsg;
    return (
      <div className="mb-4 col-lg-12">
        <fieldset>
          <legend>Beneficiary {this.props.index + 1}</legend>
          <Row>
          <div className="mb-2 col-lg-12 col-xl-12">
            <TextBox
              label="Beneficiary details"
              name="beneficiarieDetails"
              id={`beneficiarieDetails[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              validationType="Text"
              chapter={this.props.chapter}
            />
           {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objBeneficiaries &&
                    errorMsg.objBeneficiaries[index] &&
                    errorMsg.objBeneficiaries[index]
                      ?.beneficiarieDetails?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objBeneficiaries[index]
                            ?.beneficiarieDetails
                        }
                      </span>
                    )}
          </div>
          
          <div className="mb-3 col-lg-6">
            <CheckBox
            label="Beneficiary's passport or national identity card available?"
              name="isbenPassport"
              options={["Yes", "No"]}
              id={`isbenPassport[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
            />
           {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objBeneficiaries &&
                    errorMsg.objBeneficiaries[index] &&
                    errorMsg.objBeneficiaries[index]
                      ?.isbenPassport?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objBeneficiaries[index]
                            ?.isbenPassport
                        }
                      </span>
                    )}
          </div>
          {isbenPassport === "Yes" && (
            <div className="mb-3 col-lg-4">
            
              <FileUpload
                label="Upload Beneficiary's Passport or national identity"
                acceptType={fileAcceptType}
                name="benPassport"
                id={`benPassport[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              //isRequired={this.props.form === "UK" ? true : false} 
              />
             {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objBeneficiaries &&
                    errorMsg.objBeneficiaries[index] &&
                    errorMsg.objBeneficiaries[index]
                      ?.benPassport?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objBeneficiaries[index]
                            ?.benPassport
                        }
                      </span>
                    )}
            </div>
          )} 
           {isbenPassport === "No" && (
              <div className="mb-3 col-lg-4">
                  <TextBox
                label="Explanation"
                name="benPassportReason"
                id={`benPassportReason[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              validationType="Text"
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
              />
             {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objBeneficiaries &&
                    errorMsg.objBeneficiaries[index] &&
                    errorMsg.objBeneficiaries[index]
                      ?.benPassportReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objBeneficiaries[index]
                            ?.benPassportReason
                        }
                      </span>
                    )}

              </div>              
              )} 
        </Row> 
        <Row>
          <div className="mb-3 col-lg-6">
            <CheckBox
               label="Beneficiary's proof of address available?"
               name="isbenProofAdd"
              options={["Yes", "No"]}
              id={`isbenProofAdd[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
            />
           {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objBeneficiaries &&
                    errorMsg.objBeneficiaries[index] &&
                    errorMsg.objBeneficiaries[index]
                      ?.isbenProofAdd?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objBeneficiaries[index]
                            ?.isbenProofAdd
                        }
                      </span>
                    )}
          </div>
          {isbenProofAdd === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
               label="Upload Beneficiary's proof of addres"
                info="Not older than 3 months"
                acceptType={fileAcceptType}
                name="benProofAdd"
                id={`benProofAdd[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              //isRequired={this.props.form === "UK" ? true : false} 
              />
             {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objBeneficiaries &&
                    errorMsg.objBeneficiaries[index] &&
                    errorMsg.objBeneficiaries[index]
                      ?.benProofAdd?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objBeneficiaries[index]
                            ?.benProofAdd
                        }
                      </span>
                    )}
            </div>
          )}
          {isbenProofAdd === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="benProofAddReason"
                id={`benProofAddReason[${index}]`}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}
                isRequired={this.props.form === "UK" ? true : false} 
              />
             {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objBeneficiaries &&
                    errorMsg.objBeneficiaries[index] &&
                    errorMsg.objBeneficiaries[index]
                      ?.benProofAddReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objBeneficiaries[index]
                            ?.benProofAddReason
                        }
                      </span>
                    )}
            </div>
          )}
        </Row>   
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-6">
            <CheckBox
               label="Beneficiary's reference letter available?"
              name="isbenRefLett"
              options={["Yes", "No"]}
              id={`isbenRefLett[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
            />
           {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objBeneficiaries &&
                    errorMsg.objBeneficiaries[index] &&
                    errorMsg.objBeneficiaries[index]
                      ?.isbenRefLett?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objBeneficiaries[index]
                            ?.isbenRefLett
                        }
                      </span>
                    )}
          </div>
          {isbenRefLett === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Upload Beneficiary's reference letter"
                info="by Accountant, Banker or Lawyer"
                acceptType={fileAcceptType}
                name="benRefLett"
                id={`benRefLett[${index}]`}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objBeneficiaries &&
                    errorMsg.objBeneficiaries[index] &&
                    errorMsg.objBeneficiaries[index]
                      ?.benRefLett?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objBeneficiaries[index]
                            ?.benRefLett
                        }
                      </span>
                    )}
            </div>
          )}
          {isbenRefLett === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="benRefLettReason"
                id={`benRefLettReason[${index}]`}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}
                isRequired={this.props.form === "UK" ? true : false} 
              />
             {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objBeneficiaries &&
                    errorMsg.objBeneficiaries[index] &&
                    errorMsg.objBeneficiaries[index]
                      ?.benRefLettReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objBeneficiaries[index]
                            ?.benRefLettReason
                        }
                      </span>
                    )}
            </div>
          )}
        </Row>     
        </fieldset>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
    const { baseName, index, reducer,objBaseName,indexObjBaseName } = ownProps;
    return {
        isbenPassport: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["isbenPassport"],
        isbenProofAdd: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["isbenProofAdd"],
        isbenRefLett:state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["isbenRefLett"],
    };
  };
  
  export default connect(mapStateToProps, null)(Beneficiaries);
