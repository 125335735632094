import { Provider } from "react-redux";
import ConfigureStore from "./redux/store/configureStore";
import Routes from "./routes/routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.scss";
import "./assets/css/common.scss";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import IdleTimerContainer from "./app/components/shared/IdleTimerContainer";
import IdleTimerContainerInternal from "./app/components/shared/IdleTimerContainerInternal";
import { useMsal, useAccount } from "@azure/msal-react";

const store = ConfigureStore();

function App() {
  // const { instance, accounts } = useMsal();
  // const account = useAccount(accounts[0] || {});
  // const MSLOGIN_CLIENTID = process.env.REACT_APP_MSLOGIN_CLIENTID;
  // let serverError = localStorage.getItem(
  //   `server-telemetry-${MSLOGIN_CLIENTID}`
  // );
  // let serverErrorCnt = 0;
  // if (serverError !== null) {
  //   serverErrorCnt = JSON.parse(serverError).errors.length;
  // }
  // if (account && serverErrorCnt === 0) {
  //   try {
  //     instance
  //       .acquireTokenSilent({
  //         scopes: ["user.read"],
  //         authority:
  //           "https://login.microsoftonline.com/37ab276a-6d7b-4b26-95c4-23f9fd7d5786",
  //         account: account,
  //       })
  //       .then((response) => {
  //         localStorage.setItem("authToken", JSON.stringify(response.idToken));
  //       })
  //       .catch((err) => {
  //         if (err.name === "InteractionRequiredAuthError") {
  //           instance
  //             .acquireTokenPopup({
  //               scopes: ["User.Read"],
  //               account: account,
  //             })
  //             .then((response) => {
  //               if (response) {
  //                 localStorage.setItem(
  //                   "authToken",
  //                   JSON.stringify(response.idToken)
  //                 );
  //               }
  //             })
  //             .catch((e) => {
  //               console.log("error:", e);
  //             });
  //         }
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  return (
    <Provider store={store}>
      <Routes />
      <NotificationContainer />
      {/* {account !== null && (

<IdleTimerContainerInternal></IdleTimerContainerInternal>

)} */}
      {/* {account !== null ? (
        <IdleTimerContainerInternal></IdleTimerContainerInternal>
      ) : 
     <></>
     
      } */}
    </Provider>
  );
}

export default App;
