export const getFormNameFromOffice = (office) => {
  switch (office) {
    case "Netherlands":
      return "NL";
    case "Curacao":
      return "Curacao";
    case "Malta":
      return "Malta";
    case "Luxembourg":
      return "Luxembourg";
    default:
      return "NL";
  }
};
