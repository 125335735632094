import React, { Component } from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";
import DropDown from "../../../../elements/DropDown";
import countryList from "react-select-country-list";
import { fileAcceptType } from "../Constants";
import InternalFileUpload from "../../../../elements/InternalFileUpload";
class Agmnt extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, errorMsg } = this.props;
    const eMsg = errorMsg;
    return (
      <div className="mb-4 col-lg-12">
        <fieldset>
          <legend>Agreement {this.props.index + 1}</legend>
          <Row>

            <div className="mb-2 col-lg-6 col-xl-6">
              <TextBox
                label="Agreement name"
                name="agreementName"
                id={`agreementName[${index}]`}
                placeholder="enter your text"               
                index={this.props.index}
                baseName="lstAgmnt"
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}              
              />
             
            </div>
            <div className="mb-2 col-lg-6 col-xl-6">
            <InternalFileUpload
              label="Agrement file"
              name="agreementFile"
              id={`agreementFile[${index}]`}  
              index={this.props.index}            
              chapter={this.props.chapter}  
              acceptType={fileAcceptType}
              baseName="lstAgmnt"
              reducer={this.props.reducer}
            />

            </div>
           
           
          </Row>
        </fieldset>
      </div>
    );
  }
}

export default Agmnt;
