import React, { Component } from "react";
import TextBox from "../../../../elements/TextBox";
import PhoneInputField from "../../../../elements/PhoneInputField";
import DateTimePicker from "../../../../elements/DateTimePicker";
import DownloadFile from "../../../../elements/DownloadFile";
import { connect } from "react-redux";
import DropDown from "../../../../elements/DropDown";
import SourceOfFunds from "./SourceOfFunds";
import DeclarationSoW from "./DeclarationSoW";
import AdditionalUbodetails from "./AdditionalUboDetails";
class UboDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { uboData, reducer, baseName, index, objBaseName, chapter, errors } =
      this.props;

    return (
      <fieldset className="eqheight">
        <legend>UBO {index + 1}</legend>
        <div className="row mb-0">
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Name"
              placeholder="Enter name"
              name="name"
              index={index}
              baseName={baseName}
              reducer={reducer}
              chapter={chapter}
              objBaseName={objBaseName}
              disabled={true}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Email"
              placeholder="Enter email"
              name="email"
              index={index}
              baseName={baseName}
              reducer={reducer}
              chapter={chapter}
              objBaseName={objBaseName}
              disabled={true}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <PhoneInputField
              label="Phone"
              name="phone"
              index={index}
              baseName={baseName}
              reducer={reducer}
              chapter={chapter}
              objBaseName={objBaseName}
              disabled={true}
              country="UK"
            />
          </div>
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Residental Address"
              placeholder="Enter residental address"
              name="residentialAddress"
              index={index}
              chapter={chapter}
              baseName={baseName}
              reducer={reducer}
              objBaseName={objBaseName}
              validationType="Text"
              disabled={true}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Registered Office"
              placeholder="Enter registered office"
              name="registeredOffice"
              index={index}
              chapter={chapter}
              baseName={baseName}
              reducer={reducer}
              objBaseName={objBaseName}
              validationType="Text"
              disabled={true}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Alternate Correspondence Address"
              placeholder="Enter alternative address"
              name="altCrspdnAddress"
              index={index}
              chapter={chapter}
              baseName={baseName}
              reducer={reducer}
              objBaseName={objBaseName}
              validationType="Text"
              disabled={true}
            />
          </div>
          <div className="mb-3 col-lg-4" id="dob">
            <DateTimePicker
              label="Date of Birth"
              name="dob"
              index={index}
              chapter={chapter}
              baseName={baseName}
              reducer={reducer}
              objBaseName={objBaseName}
              validationType="Text"
              disabled={true}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <DropDown
              label="Nationality"
              name="uboNationality"
              index={index}
              chapter={chapter}
              baseName={baseName}
              reducer={reducer}
              objBaseName={objBaseName}
              validationType="Text"
              disabled={true}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <DropDown
              label="Country of birth"
              name="uboCountryOfBirth"
              index={index}
              chapter={chapter}
              baseName={baseName}
              reducer={reducer}
              objBaseName={objBaseName}
              validationType="Text"
              disabled={true}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <DropDown
              label="Country of Residence"
              name="uboCountryOfResidence"
              index={index}
              chapter={chapter}
              baseName={baseName}
              reducer={reducer}
              objBaseName={objBaseName}
              validationType="Text"
              disabled={true}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <DropDown
              label="Country of Tax Residence"
              name="countryOfTaxResidence"
              index={index}
              chapter={chapter}
              baseName={baseName}
              reducer={reducer}
              objBaseName={objBaseName}
              validationType="Text"
              disabled={true}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <DownloadFile
              label={"Certified True Copy Passport"}
              name="certifiedTrueCopyPass"
              value={uboData.certifiedTrueCopyPass}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <DownloadFile
              label={"Proof of Address"}
              name="proofAddress"
              value={uboData.proofAddress}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <DownloadFile
              label={"Reference Letter"}
              name="refLetter"
              value={uboData.refLetter}
            />
          </div>
        </div>
        <SourceOfFunds
          index={index}
          chapter={chapter}
          baseName={baseName}
          reducer={reducer}
          objBaseName={objBaseName}
        />

        <DeclarationSoW
          index={index}
          chapter={chapter}
          baseName={baseName}
          reducer={reducer}
          errors={errors}
          objBaseName={objBaseName}
        />

        <AdditionalUbodetails
          index={index}
          chapter={chapter}
          baseName="uboApproval"
          reducer="UKInternalCAF"
          errors={errors}
          form="UK"
        />
      </fieldset>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { index, reducer } = ownProps;
  return {
    uboData: state[reducer].chapter3.ubo.ubox[index],
    uboApproval: state.ClientAcceptance.chapter3.uboApproval[index],
  };
};

export default connect(mapStateToProps, null)(UboDetails);
