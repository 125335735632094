import React, { Component } from "react";
import { Row } from "react-bootstrap";
import CheckBox from "../../../../elements/CheckBox";
import TextBox from "../../../../elements/TextBox";
class MaltaShareholder extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { errorMsg } = this.props;

    return (
      <>
        <h4 className="sub-chapter-title mb-4">
          {this.props.form === "Malta"
            ? "Shareholder of the Target Company"
            : "Shareholder of the LuxCo"}
        </h4>
        <Row>
          <div className="mb-2 col-lg-4">
            <CheckBox
              label="Is the shareholder UBO?"
              options={["Yes", "No"]}
              name="isShareholderUBO"
              id="isShareholderUBO"
              reducer="commonFields"
              isCommon={true}
              disabled={true}
            />
            {errorMsg &&
              errorMsg?.isShareholderUBO &&
              errorMsg.isShareholderUBO?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errorMsg.isShareholderUBO}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-12">
            <TextBox
              label="Enter your comment"
              placeholder="Enter your comment"
              name="shareHolderCmnt"
              id="shareHolderCmnt"
              isMultipleLines={true}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={this.props.isRequired}
            />
            {errorMsg &&
              errorMsg?.isSubmitted === true &&
              errorMsg?.shareHolderCmnt &&
              errorMsg?.shareHolderCmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.shareHolderCmnt}
                </span>
              )}
          </div>
        </Row>
      </>
    );
  }
}

export default MaltaShareholder;
