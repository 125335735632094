import React, { Component } from "react";
import CheckBox from "../../../../elements/CheckBox";
import DropDown from "../../../../elements/DropDown";
import DateTimePicker from "../../../../elements/DateTimePicker";
import countryList from "react-select-country-list";
import PhoneInputField from "../../../../elements/PhoneInputField";
import TextBox from "../../../../elements/TextBox";
import DownloadFile from "../../../../elements/DownloadFile";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";

class MaltaDirectors extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { director, index } = this.props;

    return (
      <div className="mb-4 col-lg-12">
        <fieldset>
          <legend>Director {index + 1}</legend>
          <div className="row">
            <div className="mb-3 col-lg-4">
              <TextBox
                label={`Name Director of Target Company`}
                name="dirName"
                id={`dirName[${index}]`}
                placeholder="enter your text"
                chapter={this.props.chapter}
                baseName="objCompDirectorNames"
                index={index}
                reducer={this.props.externalReducer}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Residental Address"
                name="dirResAdd"
                id={`dirResAdd[${index}]`}
                placeholder="enter your text"
                chapter={this.props.chapter}
                baseName="objCompDirectorNames"
                index={index}
                reducer={this.props.externalReducer}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
            <div className="mb-3 col-lg-4">
              <DropDown
                label={`Nationality of Director`}
                name="dirNationality"
                id={`dirNationality[${index}]`}
                options={countryList().getData()}
                chapter={this.props.chapter}
                baseName="objCompDirectorNames"
                index={index}
                reducer={this.props.externalReducer}
                disabled={true}
              />
            </div>
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Passport/Identity/Registration No."
                name="dirPassIdntyNum"
                id={`dirPassIdntyNum[${index}]`}
                placeholder="enter your text"
                chapter={this.props.chapter}
                baseName="objCompDirectorNames"
                index={index}
                reducer={this.props.externalReducer}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Place of issue"
                name="dirPlaceOfIssu"
                id={`dirPlaceOfIssu[${index}]`}
                placeholder="enter your text"
                chapter={this.props.chapter}
                baseName="objCompDirectorNames"
                index={index}
                reducer={this.props.externalReducer}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
            <div className="mb-3 col-lg-4" id={`dirDtIssue[${index}]`}>
              <DateTimePicker
                label="Date of issue"
                name="dirDtIssue"
                chapter={this.props.chapter}
                baseName="objCompDirectorNames"
                index={index}
                reducer={this.props.externalReducer}
                disabled={true}
              />
            </div>
            <div className="mb-3 col-lg-4" id={`dirExprDt[${index}]`}>
              <DateTimePicker
                label="Expiry date"
                name="dirExprDt"
                chapter={this.props.chapter}
                baseName="objCompDirectorNames"
                index={index}
                maxDate={true}
                reducer={this.props.externalReducer}
                disabled={true}
              />
            </div>
            <div className="mb-3 col-lg-4" id={`dirTelPhnNum[${index}]`}>
              <PhoneInputField
                label="Telephone No."
                name="dirTelPhnNum"
                chapter={this.props.chapter}
                baseName="objCompDirectorNames"
                index={index}
                reducer={this.props.externalReducer}
                disabled={true}
                PdfGeneration={this.props.PdfGeneration}
              />
            </div>
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Email address"
                name="dirEmail"
                id={`dirEmail[${index}]`}
                placeholder="enter your text"
                chapter={this.props.chapter}
                baseName="objCompDirectorNames"
                index={index}
                reducer={this.props.externalReducer}
                validationType="Email"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
            <div className="html2pdf__page-break"></div>
            <div className="mb-3 col-lg-4">
              <CheckBox
                label="Is director judical & representative of the company?"
                options={["Yes", "No"]}
                name="isDirJudRepComp"
                id={`isDirJudRepComp${index}`}
                chapter={this.props.chapter}
                baseName="objCompDirectorNames"
                index={index}
                reducer={this.props.externalReducer}
                disabled={true}
              />
            </div>
            {this.props.form === "Malta" && (
              <>
                {" "}
                <div className="mb-3 col-lg-4">
                  <DownloadFile
                    label="Passport copy"
                    value={director.dirPassportCopy}
                  />
                </div>
                <div className="mb-3 col-lg-4">
                  <CheckBox
                    label="Is approved?"
                    options={["Yes", "No"]}
                    name="isDirPassportApprvd"
                    id={`isDirPassportApprvd[${index}]`}
                    chapter={this.props.chapter}
                    baseName="dirCmmnt"
                    index={index}
                    reducer={this.props.reducer}
                    validationType="Text"
                  />
                </div>
                <div className="mb-3 col-lg-4">
                  <DownloadFile
                    label="Utility bills"
                    value={director.dirUtilityBill}
                  />
                </div>
                <div className="mb-3 col-lg-4">
                  <CheckBox
                    label="Is approved?"
                    options={["Yes", "No"]}
                    name="isDirUtilityBillApprvd"
                    id={`isDirUtilityBillApprvd[${index}]`}
                    chapter={this.props.chapter}
                    baseName="dirCmmnt"
                    index={index}
                    reducer={this.props.reducer}
                    validationType="Text"
                  />
                </div>
                <div className="mb-3 col-lg-4">
                  <DownloadFile
                    label="Reference letter"
                    value={director.dirReferenceLetter}
                  />
                </div>
                <div className="mb-3 col-lg-4">
                  <CheckBox
                    label="Is approved?"
                    options={["Yes", "No"]}
                    name="isDirReffLetterApprvd"
                    id={`isDirReffLetterApprvd[${index}]`}
                    chapter={this.props.chapter}
                    baseName="dirCmmnt"
                    index={index}
                    reducer={this.props.reducer}
                    validationType="Text"
                  />
                </div>
              </>
            )}
          </div>
          <Row className="mt-2 border-top pt-3">
            <div className="mb-3 col-lg-4">
              <TextBox
                label={`Please enter comment`}
                name="dirComment"
                id={`dirComment[${index}]`}
                placeholder="enter your text"
                chapter={this.props.chapter}
                baseName="dirCmmnt"
                index={index}
                reducer={this.props.reducer}
                validationType="Text"
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          </Row>
        </fieldset>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { index, externalReducer, chapter } = ownProps;

  return {
    director: state[externalReducer][chapter]["objCompDirectorNames"][index],
  };
};

export default connect(mapStateToProps, null)(MaltaDirectors);
