import { Component } from "react";
import { isEmailValid, isFieldEmpty, isFileEmpty } from "./checkFields";

class FormValidations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrorschapter1: {},
      formErrorschapter2: {},
      formErrorschapter3: {},
      formErrorschapter4: {},
      formErrorschapter5: {},
      formErrorschapter6: {},
    };
  }

  validateDetails = async (data) => {
    let formErrors = {};
    Object.entries(data).forEach(async (entry) => {
      const [key, value] = entry;
      if (key === "UKClientInformation") {
        Object.entries(value).forEach(async (chp) => {
          const [chapter, requestData] = chp;
          let result = await this.checkFormValidations(requestData, chapter);
          formErrors[chapter] = result;
        });
      }
    });
    return { formErrors };
  };

  checkFormValidations = (data, chapter) => {
    switch (chapter) {
      case "chapter1": {
        let formErrorsChapter1 = this.state.formErrorschapter1;
        formErrorsChapter1.structureChart = isFileEmpty(data.structureChart);
        formErrorsChapter1.entityJurisdiction = isFieldEmpty(
          data.entityJurisdiction
        );
        formErrorsChapter1.isCompIncorporated = isFieldEmpty(
          data.isCompIncorporated
        );
        if (data.isCompIncorporated === "Yes") {
          formErrorsChapter1.entities = [];
          data.entities.forEach((element) => {
            let errors = {};
            formErrorsChapter1.entities.push(errors);
          });
        } else {
          formErrorsChapter1.entities = [];
        }
        formErrorsChapter1.objCompActivities = isFieldEmpty(
          data.objCompActivities
        );
        formErrorsChapter1.objCompSector = isFieldEmpty(data.objCompSector);
        this.setState({ formErrorschapter1: formErrorsChapter1 });
        return { formErrorsChapter1 };
      }

      case "chapter2": {
        let formErrorsChapter2 = this.state.formErrorschapter2;
        formErrorsChapter2.isClientRepInvolved = "";
        if (data.isClientRepInvolved === "Yes") {
          formErrorsChapter2.intermediaryDetails = [];
          data.intermediaryDetails.forEach((element) => {
            let errors = {};
            errors.activeInCountry = isFieldEmpty(element.activeInCountry);
            errors.isPOA = isFieldEmpty(element.isPOA);
            if (element.isPOA === "Yes") {
              errors.poa = isFileEmpty(element.poa);
              errors.noPOAReason = "";
            } else {
              errors.poa = "";
              errors.noPOAReason = isFieldEmpty(element.noPOAReason);
            }
            errors.isIntRegUKEqRegu = isFieldEmpty(element.isIntRegUKEqRegu);
            if (element.isIntRegUKEqRegu === "Yes") {
              errors.eqRegExplaination = "";
            }
            if (element.isIntRegUKEqRegu === "No") {
              errors.eqRegExplaination = isFieldEmpty(
                element.eqRegExplaination
              );
              errors.eqRegulator = "";
            }
            errors.isUBOIdentifyVerify = isFieldEmpty(
              element.isUBOIdentifyVerify
            );
            if (element.isUBOIdentifyVerify === "Yes") {
              errors.uboIdentityPassport = isFileEmpty(
                element.uboIdentityPassport
              );
              errors.noUBOIdentifyVerifyReason = "";
            } else {
              errors.uboIdentityPassport = "";
              errors.noUBOIdentifyVerifyReason = isFieldEmpty(
                element.noUBOIdentifyVerifyReason
              );
            }
            formErrorsChapter2.intermediaryDetails.push(errors);
          });
        } else {
          formErrorsChapter2.intermediaryDetails = [];
        }
        formErrorsChapter2.isAuthrzdPrsn = isFieldEmpty(data.isAuthrzdPrsn);
        if (data.isAuthrzdPrsn === "Yes") {

          formErrorsChapter2.authorisedDetails = [];

          data.authorisedDetails.forEach((element) => {
            let errors = {};
            errors.authrzdPrsnName = isFieldEmpty(element.authrzdPrsnName);

            errors.authrzdPrsnNationality = isFieldEmpty(element.authrzdPrsnNationality);
            errors.authrzdPrsnPassport = isFileEmpty(
              element.authrzdPrsnPassport
            );
            errors.authrzdPrsnAuthLetter = isFileEmpty(
              element.authrzdPrsnAuthLetter
            );
            errors.authrzdPrsnPOA = isFileEmpty(
              element.authrzdPrsnPOA
            );

            formErrorsChapter2.authorisedDetails.push(errors);
          });
        }
        if (data.isAuthrzdPrsn === "No") {
          formErrorsChapter2.authrzdPrsnName = "";
          formErrorsChapter2.authrzdPrsnNationality = "";
          formErrorsChapter2.authrzdPrsnPassport = "";
          formErrorsChapter2.authrzdPrsnAuthLetter = "";
          formErrorsChapter2.authrzdPrsnRefLetter = "";
          formErrorsChapter2.authrzdPrsnPOA = "";
        }
        formErrorsChapter2.isClientTransfrd = isFieldEmpty(
          data.isClientTransfrd
        );
        if (data.isClientTransfrd === "Yes") {
          formErrorsChapter2.srvcPrvdrName = isFieldEmpty(data.srvcPrvdrName);
          formErrorsChapter2.reasonClientTransfrd = isFieldEmpty(
            data.reasonClientTransfrd
          );
        } else {
          formErrorsChapter2.srvcPrvdrName = "";
          formErrorsChapter2.reasonClientTransfrd = "";
        }
        this.setState({ formErrorschapter2: formErrorsChapter2 });
        return { formErrorsChapter2 };
      }

      case "chapter3": {
        let formErrorsChapter3 = this.state.formErrorschapter3;

        formErrorsChapter3.isHoldingInterest = isFieldEmpty(
          data.isHoldingInterest
        );
        if (data.isHoldingInterest === "Yes") {
          formErrorsChapter3.isHoldingInterestReason = isFieldEmpty(
            data.isHoldingInterestReason
          );
        }
        if (data.isHoldingInterestReason === "No") {
          formErrorsChapter3.isHoldingInterestReason = "";
        }

        formErrorsChapter3.isUBOClassified = isFieldEmpty(data.isUBOClassified);

        if (data.isUBOClassified === "Yes") {
          formErrorsChapter3.isUBOClassifiedReason = isFieldEmpty(
            data.isUBOClassifiedReason
          );
        }
        if (data.isUBOClassified === "No") {
          formErrorsChapter3.isUBOClassifiedReason = "";
        }

        formErrorsChapter3.isAssetClient = isFieldEmpty(data.isAssetClient);

        if (data.isAssetClient === "Yes") {
          formErrorsChapter3.isAssetClientReason = isFieldEmpty(
            data.isAssetClientReason
          );
        }

        if (data.isAssetClient === "No") {
          formErrorsChapter3.isAssetClientReason = "";
        }

        if (
          data.isHoldingInterest === "No" &&
          data.isUBOClassified === "No" &&
          data.isAssetClient === "No"
        ) {
          formErrorsChapter3.isHoldingInterestReason = isFieldEmpty(
            data.isHoldingInterestReason
          );
          formErrorsChapter3.ubox = [];
        }
        if (
          data.isHoldingInterest === "Yes" &&
          data.isUBOClassified === "Yes" &&
          data.isAssetClient === "Yes"
        ) {
          formErrorsChapter3.isUBOExists = isFieldEmpty(data.isUBOExists);
          formErrorsChapter3.ubox = [];
          if (data.isUBOExists === "Yes") {
            data.ubo.ubox.forEach((element) => {
              let errors = {};
              errors.name = isFieldEmpty(element.name);
              errors.email = isFieldEmpty(element.email);
              if (element.email) {
                errors.email = isEmailValid(element.email);
              }
              errors.phone = isFieldEmpty(element.phone);
              errors.residentialAddress = isFieldEmpty(
                element.residentialAddress
              );
              errors.dob = isFieldEmpty(element.dob);
              errors.uboNationality = isFieldEmpty(element.uboNationality);
              errors.uboCountryOfBirth = isFieldEmpty(element.uboCountryOfBirth);
              errors.uboCountryOfResidence = isFieldEmpty(
                element.uboCountryOfResidence
              );
              errors.countryOfTaxResidence = isFieldEmpty(
                element.countryOfTaxResidence
              );

              errors.certifiedTrueCopyPass = isFileEmpty(
                element.certifiedTrueCopyPass
              );
              errors.proofAddress = isFileEmpty(element.proofAddress);
              errors.grossAnnualIncome = isFieldEmpty(element.grossAnnualIncome);
              if (element.grossAnnualIncome === "> EUR 1 Mln") {
                errors.anualIncmAbvEUR1Mln = isFieldEmpty(
                  element.anualIncmAbvEUR1Mln
                );
              } else {
                errors.anualIncmAbvEUR1Mln = "";
              }
              errors.wealthRange = isFieldEmpty(element.wealthRange);
              errors.srcOfWealth = isFieldEmpty(element.srcOfWealth);
              formErrorsChapter3.ubox.push(errors);
            });
          }
        }

        formErrorsChapter3.isUPCExists = isFieldEmpty(data.isUPCExists);

        if (data.isUPCExists === "Yes") {
          formErrorsChapter3.upcExplanation = "";
          formErrorsChapter3.upcDetails = [];
          data.upcDetails.forEach((element) => {
            let errors = {};
            errors.entityType = isFileEmpty(
              element.entityType
            );
            errors.options = isFileEmpty(
              element.options
            );

            if (element.entityType !== "Trust/Foundation") {
              errors.directorsPartners = "";
              formErrorsChapter3.objTrustees = [];
              formErrorsChapter3.objProtectors = [];
              formErrorsChapter3.objBeneficiaries = [];
              formErrorsChapter3.objSettlors = [];
              errors.nameUPC = isFieldEmpty(element.nameUPC);
              errors.addressRegistered = isFieldEmpty(element.addressRegistered);
              errors.city = isFieldEmpty(element.city);
              errors.country = isFieldEmpty(element.country);
              errors.tradeRegisterNum = isFieldEmpty(
                element.tradeRegisterNum
              );
              errors.countryOfTaxRsdnc = isFieldEmpty(
                element.countryOfTaxRsdnc
              );

              errors.isForceCloseFinAcc = isFieldEmpty(
                element.isForceCloseFinAcc
              );
              errors.arethereExistingUPCDir = isFieldEmpty(
                element.arethereExistingUPCDir
              );
              if (element.isForceCloseFinAcc === "Yes") {
                errors.forceCloseFinAcc = isFieldEmpty(
                  element.forceCloseFinAcc
                );
              }
              if (element.isForceCloseFinAcc === "No") {
                errors.forceCloseFinAcc = "";
              }


              formErrorsChapter3.upcDirectors = [];
              if (element.arethereExistingUPCDir === "Yes") {
                element.upcDirectors.forEach((x) => {
                  let errorsDir = {};
                  errorsDir.upcAuthDirName = isFieldEmpty(x.upcAuthDirName);
                  errorsDir.upcAuthDirEmail = isFieldEmpty(x.upcAuthDirEmail);
                  if (element.upcAuthDirEmail) {
                    errorsDir.upcAuthDirEmail = isEmailValid(x.upcAuthDirEmail);
                  }
                  errorsDir.upcAuthDirNationality = isFieldEmpty(
                    x.upcAuthDirNationality
                  );
                  formErrorsChapter3.upcDirectors.push(errorsDir);
                });
              }

            } else {
              errors.nameUPC = "";
              errors.addressRegistered = "";
              errors.city = "";
              errors.country = "";
              errors.tradeRegisterNum = "";
              errors.countryOfTaxRsdnc = "";
              errors.isForceCloseFinAcc = "";
              errors.forceCloseFinAcc = "";
              formErrorsChapter3.upcDirectors = []
              errors.directorsPartners = isFieldEmpty(element.directorsPartners);
              formErrorsChapter3.objTrustees = [];
              element.objTrustees.forEach((x) => {
                let errorsTrustees = {};
                errorsTrustees.trusteeName = isFieldEmpty(x.trusteeName);
                errorsTrustees.istrusPassport = isFieldEmpty(x.istrusPassport);
                if (x.istrusPassport == "No") {
                  errorsTrustees.truePassportReason = isFieldEmpty(x.truePassportReason);
                  errorsTrustees.trusPassport = "";
                }
                if (x.istrusPassport == "Yes") {
                  errorsTrustees.truePassportReason = "";
                  //errorsTrustees.trusPassport = isFileEmpty(x.trusPassport);
                }
                errorsTrustees.istrusProofAdd = isFieldEmpty(x.istrusProofAdd);
                if (x.istrusProofAdd == "No") {
                  errorsTrustees.trusProofAdd = "";
                  //errorsTrustees.trusProofAddReason = isFieldEmpty(x.trusProofAddReason);
                }
                if (x.istrusProofAdd == "Yes") {
                  errorsTrustees.trusProofAddReason = "";
                  //errorsTrustees.trusProofAdd = isFileEmpty(x.trusProofAdd);
                }
                errorsTrustees.istrusRefLett = isFieldEmpty(x.istrusRefLett);
                if (x.istrusRefLett == "No") {
                  errorsTrustees.trusRefLett = "";
                  errorsTrustees.trusRefLettReason = isFieldEmpty(x.trusRefLettReason);
                }
                if (x.istrusRefLett == "Yes") {
                  errorsTrustees.trusRefLettReason = "";
                  //errorsTrustees.trusRefLett=isFileEmpty(x.trusRefLett);
                }
                formErrorsChapter3.objTrustees.push(errorsTrustees);
              });
              //-----------------------
              formErrorsChapter3.objProtectors = [];
              element.objProtectors.forEach((x) => {
                let errorsProtectors = {};
                errorsProtectors.protectorDetails = isFieldEmpty(x.protectorDetails);
                errorsProtectors.isprotecPassport = isFieldEmpty(x.isprotecPassport);
                if (x.isprotecPassport == "No") {
                  errorsProtectors.protecPassportReason = isFieldEmpty(x.protecPassportReason);
                  errorsProtectors.protecPassport = "";
                }
                if (x.isprotecPassport == "Yes") {
                  errorsProtectors.protecPassportReason = "";
                  //errorsProtectors.protecPassport = isFileEmpty(x.protecPassport);
                }
                errorsProtectors.isprotecProofAdd = isFieldEmpty(x.isprotecProofAdd);
                if (x.isprotecProofAdd == "No") {
                  errorsProtectors.protecProofAdd = "";
                  errorsProtectors.protecProofAddReason = isFieldEmpty(x.protecProofAddReason);
                }
                if (x.isprotecProofAdd == "Yes") {
                  errorsProtectors.protecProofAddReason = "";
                  //errorsProtectors.protecProofAdd = isFileEmpty(x.protecProofAdd);
                }
                errorsProtectors.isprotecRefLett = isFieldEmpty(x.isprotecRefLett);
                if (x.isprotecRefLett == "No") {
                  errorsProtectors.protecRefLett = "";
                  errorsProtectors.protecRefLettReason = isFieldEmpty(x.protecRefLettReason);
                }
                if (x.isprotecRefLett == "Yes") {
                  errorsProtectors.protecRefLettReason = "";
                  errorsProtectors.protecRefLett = isFileEmpty(x.protecRefLett);
                }
                formErrorsChapter3.objProtectors.push(errorsProtectors);
              });
              //------------
              formErrorsChapter3.objSettlors = [];
              element.objSettlors.forEach((x) => {
                let errorsSettlors = {};
                errorsSettlors.settlorsDetails = isFieldEmpty(x.settlorsDetails);
                errorsSettlors.issettPassport = isFieldEmpty(x.issettPassport);
                if (x.issettPassport == "No") {
                  errorsSettlors.settPassportReason = isFieldEmpty(x.settPassportReason);
                  errorsSettlors.settPassport = "";
                }
                if (x.issettPassport == "Yes") {
                  errorsSettlors.settPassportReason = "";
                  //errorsSettlors.settPassport = isFileEmpty(x.settPassport);
                }
                errorsSettlors.issettProofAdd = isFieldEmpty(x.issettProofAdd);
                if (x.issettProofAdd == "No") {
                  errorsSettlors.settProofAdd = "";
                  errorsSettlors.settProofAddReason = isFieldEmpty(x.settProofAddReason);
                }
                if (x.issettProofAdd == "Yes") {
                  errorsSettlors.settProofAddReason = "";
                  //errorsSettlors.settProofAdd = isFileEmpty(x.settProofAdd);
                }
                errorsSettlors.issettRefLett = isFieldEmpty(x.issettRefLett);
                if (x.issettRefLett == "No") {
                  errorsSettlors.settRefLett = "";
                  errorsSettlors.settRefLettReason = isFieldEmpty(x.settRefLettReason);
                }
                if (x.issettRefLett == "Yes") {
                  errorsSettlors.settRefLettReason = "";
                  errorsSettlors.settRefLett = isFileEmpty(x.settRefLett);
                }
                formErrorsChapter3.objSettlors.push(errorsSettlors);
              });
              //---------------------------------
              formErrorsChapter3.objBeneficiaries = [];
              element.objBeneficiaries.forEach((x) => {
                let errorsBeneficiaries = {};
                errorsBeneficiaries.beneficiarieDetails = isFieldEmpty(x.beneficiarieDetails);
                errorsBeneficiaries.isbenPassport = isFieldEmpty(x.isbenPassport);
                if (x.isbenPassport == "No") {
                  errorsBeneficiaries.benPassportReason = isFieldEmpty(x.benPassportReason);
                  errorsBeneficiaries.benPassport = "";
                }
                if (x.isbenPassport == "Yes") {
                  errorsBeneficiaries.benPassportReason = "";
                  //errorsBeneficiaries.benPassport = isFileEmpty(x.benPassport);
                }
                errorsBeneficiaries.isbenProofAdd = isFieldEmpty(x.isbenProofAdd);
                if (x.isbenProofAdd == "No") {
                  errorsBeneficiaries.benProofAdd = "";
                  errorsBeneficiaries.benProofAddReason = isFieldEmpty(x.benProofAddReason);
                }
                if (x.isbenProofAdd == "Yes") {
                  errorsBeneficiaries.benProofAddReason = "";
                  //errorsBeneficiaries.benProofAdd = isFileEmpty(x.benProofAdd);
                }
                errorsBeneficiaries.isbenRefLett = isFieldEmpty(x.isbenRefLett);
                if (x.isbenRefLett == "No") {
                  errorsBeneficiaries.benRefLett = "";
                  errorsBeneficiaries.benRefLettReason = isFieldEmpty(x.benRefLettReason);
                }
                if (x.isbenRefLett == "Yes") {
                  errorsBeneficiaries.benRefLettReason = "";
                  errorsBeneficiaries.benRefLett = isFileEmpty(x.benRefLett);
                }
                formErrorsChapter3.objBeneficiaries.push(errorsBeneficiaries);
              });


            }
            formErrorsChapter3.upcDetails.push(errors);
          });
        }
        if (data.isUPCExists === "No") {
          formErrorsChapter3.upcExplanation = isFieldEmpty(data.upcExplanation);
          formErrorsChapter3.intermediaryDetails = [];
        }
        this.setState({ formErrorschapter3: formErrorsChapter3 });
        return { formErrorsChapter3 };
      }

      case "chapter4": {
        let formErrorsChapter4 = this.state.formErrorschapter4;
        //---------------------------------------------
        formErrorsChapter4.isObjCompExists = isFieldEmpty(data.isObjCompExists);
        if (data.isObjCompExists === "Yes") {
          formErrorsChapter4.objCompName = isFieldEmpty(data.objCompName);
        }
        if (data.isObjCompExists === "No") {
          formErrorsChapter4.objCompName = "";
        }
        if (data.isObjCompExists === "Yes") {
          formErrorsChapter4.commercialRegister = isFieldEmpty(
            data.commercialRegister
          );
        }
        if (data.isObjCompExists === "No") {
          formErrorsChapter4.commercialRegister = "";
        }
        formErrorsChapter4.arethereExistingUPCDir = isFieldEmpty(
          data.arethereExistingUPCDir
        );
        if (data.arethereExistingUPCDir == "Yes") {
          formErrorsChapter4.objCompDirectorNames = [];
          data.objCompDirectorNames.forEach((element) => {
            let errors = {};
            errors.typeOfDirector = isFieldEmpty(element.typeOfDirector);
            errors.dirName = isFieldEmpty(element.dirName);
            errors.dirNationality = isFieldEmpty(element.dirNationality);
            formErrorsChapter4.objCompDirectorNames.push(errors);
          });
        }
        if (data.arethereExistingUPCDir == "No") {
          formErrorsChapter4.objCompDirectorNames = [];
        }
        formErrorsChapter4.objCompActiveCountry = isFieldEmpty(
          data.objCompActiveCountry
        );
        formErrorsChapter4.objCompNaturePropsdObjctv = isFieldEmpty(
          data.objCompNaturePropsdObjctv
        );
        if (data.objCompNaturePropsdObjctv === "Other") {
          formErrorsChapter4.objCompOtherNaturePropsdObjctv = isFieldEmpty(
            data.objCompOtherNaturePropsdObjctv
          );
        } else {
          formErrorsChapter4.objCompOtherNaturePropsdObjctv = "";
        }
        formErrorsChapter4.isObjCompBranches = isFieldEmpty(
          data.isObjCompBranches
        );
        if (data.isObjCompBranches === "Yes") {
          formErrorsChapter4.objCompBranch = isFieldEmpty(data.objCompBranch);
        } else {
          formErrorsChapter4.objCompBranch = "";
        }
        formErrorsChapter4.rationaleStructurigForNL = isFieldEmpty(
          data.rationaleStructurigForNL
        );
        formErrorsChapter4.rationaleSelectedInfo = isFieldEmpty(
          data.rationaleSelectedInfo
        );
        //formErrorsChapter4.taxAdvisorFinds = isFileEmpty(data.taxAdvisorFinds);
        formErrorsChapter4.thirdPartyRefApprvl = isFieldEmpty(
          data.businessIntroduction.thirdPartyRefApprvl
        );
        if (data.businessIntroduction.thirdPartyRefApprvl === "Yes") {
          formErrorsChapter4.thirdPartyDetails = isFieldEmpty(
            data.businessIntroduction.thirdPartyDetails
          );
        }
        if (data.businessIntroduction.thirdPartyRefApprvl === "No") {
          formErrorsChapter4.thirdPartyDetails = "";
        }

        formErrorsChapter4.grpRefApprvl = isFieldEmpty(
          data.businessIntroduction.grpRefApprvl
        );
        if (data.businessIntroduction.grpRefApprvl === "Yes") {
          formErrorsChapter4.officeIndividualDetails = isFieldEmpty(
            data.businessIntroduction.officeIndividualDetails
          );
        }
        if (data.businessIntroduction.grpRefApprvl === "No") {
          formErrorsChapter4.officeIndividualDetails = "";
        }
        formErrorsChapter4.exstngClntRefApprvl = isFieldEmpty(
          data.businessIntroduction.exstngClntRefApprvl
        );
        if (data.businessIntroduction.exstngClntRefApprvl === "Yes") {
          formErrorsChapter4.clientDetails = isFieldEmpty(
            data.businessIntroduction.clientDetails
          );
        }
        if (data.businessIntroduction.exstngClntRefApprvl === "No") {
          formErrorsChapter4.clientDetails = "";
        }
        formErrorsChapter4.prsnCntctDirePrspctngApprvl = isFieldEmpty(
          data.businessIntroduction.prsnCntctDirePrspctngApprvl
        );
        if (data.businessIntroduction.prsnCntctDirePrspctngApprvl === "Yes") {
          formErrorsChapter4.uimUKStaffDetails = isFieldEmpty(
            data.businessIntroduction.uimUKStaffDetails
          );
        }
        if (data.businessIntroduction.prsnCntctDirePrspctngApprvl === "No") {
          formErrorsChapter4.uimUKStaffDetails = "";
        }
        formErrorsChapter4.walkInApprvl = isFieldEmpty(
          data.businessIntroduction.walkInApprvl
        );
        if (data.businessIntroduction.walkInApprvl === "Yes") {
          formErrorsChapter4.hearofUIM = isFieldEmpty(
            data.businessIntroduction.hearofUIM
          );
        }
        if (data.businessIntroduction.walkInApprvl === "No") {
          formErrorsChapter4.hearofUIM = "";
        }
        formErrorsChapter4.internetApprvl = isFieldEmpty(
          data.businessIntroduction.internetApprvl
        );
        if (data.businessIntroduction.internetApprvl === "Yes") {
          formErrorsChapter4.fromWhichWebsite = isFieldEmpty(
            data.businessIntroduction.fromWhichWebsite
          );
        }
        if (data.businessIntroduction.internetApprvl === "No") {
          formErrorsChapter4.fromWhichWebsite = "";
        }
        formErrorsChapter4.domiciliationSrvsApprvl = isFieldEmpty(
          data.servicesProvided.domiciliationSrvsApprvl
        );
        if (data.servicesProvided.domiciliationSrvsApprvl === "No") {
          formErrorsChapter4.domSrvcsExplaination = isFieldEmpty(
            data.servicesProvided.domSrvcsExplaination
          );
        }
        if (data.servicesProvided.domiciliationSrvsApprvl === "Yes") {
          formErrorsChapter4.domSrvcsExplaination = "";
        }
        formErrorsChapter4.accntngSrvsApprvl = isFieldEmpty(
          data.servicesProvided.accntngSrvsApprvl
        );
        if (data.servicesProvided.accntngSrvsApprvl === "No") {
          formErrorsChapter4.thirdPartyDetailsNoUIM = isFieldEmpty(
            data.servicesProvided.thirdPartyDetailsNoUIM
          );
        }
        if (data.servicesProvided.accntngSrvsApprvl === "Yes") {
          formErrorsChapter4.thirdPartyDetailsNoUIM = "";
        }
        formErrorsChapter4.taxCmplanceSrvcsApprvl = isFieldEmpty(
          data.servicesProvided.taxCmplanceSrvcsApprvl
        );
        if (data.servicesProvided.taxCmplanceSrvcsApprvl === "No") {
          formErrorsChapter4.taxCmplanceExplanation = isFieldEmpty(
            data.servicesProvided.taxCmplanceExplanation
          );
        }
        if (data.servicesProvided.taxCmplanceSrvcsApprvl === "Yes") {
          formErrorsChapter4.taxCmplanceExplanation = "";
        }
        formErrorsChapter4.coSecServicesApprvl = isFieldEmpty(
          data.servicesProvided.coSecServicesApprvl
        );
        if (data.servicesProvided.coSecServicesApprvl === "No") {
          formErrorsChapter4.coSecServicesExplanation = isFieldEmpty(
            data.servicesProvided.coSecServicesExplanation
          );
        }
        if (data.servicesProvided.coSecServicesApprvl === "Yes") {
          formErrorsChapter4.coSecServicesExplanation = "";
        }

        formErrorsChapter4.bnkAccReqApprvl = isFieldEmpty(
          data.servicesProvided.bnkAccReqApprvl
        );
        if (data.servicesProvided.bnkAccReqApprvl === "No") {
          formErrorsChapter4.bankDetails = isFieldEmpty(
            data.servicesProvided.bankDetails
          );
        }
        if (data.servicesProvided.bnkAccReqApprvl === "Yes") {
          formErrorsChapter4.bankDetails = "";
        }
        formErrorsChapter4.othrSrvcsApprvl = isFieldEmpty(
          data.servicesProvided.othrSrvcsApprvl
        );
        if (data.servicesProvided.othrSrvcsApprvl === "Yes") {
          formErrorsChapter4.othrSrvcsExplain = isFieldEmpty(
            data.servicesProvided.othrSrvcsExplain
          );
        }
        if (data.servicesProvided.othrSrvcsApprvl === "No") {
          formErrorsChapter4.othrSrvcsExplain = "";
        }
        if (data.isTaxMemorandum === "Yes") {
          formErrorsChapter4.taxAdvisorName = isFieldEmpty(data.taxAdvisorName);
          formErrorsChapter4.taxMemorandum = isFieldEmpty(data.taxMemorandum);
        }        
        formErrorsChapter4.isFatcaSCF = isFieldEmpty(
          data?.ovrvwBnkAC?.isFatcaSCF
        );
        if (data?.ovrvwBnkAC?.isFatcaSCF === "Yes") {
          formErrorsChapter4.fatcaSelfCertifctnFrm = isFileEmpty(
            data?.ovrvwBnkAC?.fatcaSelfCertifctnFrm
          );
          formErrorsChapter4.noFATCAformReason = "";
        }
        if (data?.ovrvwBnkAC?.isFatcaSCF === "No") {
          formErrorsChapter4.noFATCAformReason = isFieldEmpty(
            data?.ovrvwBnkAC?.noFATCAformReason
          );
          formErrorsChapter4.fatcaSelfCertifctnFrm = "";
        }        
        this.setState({ formErrorschapter4: formErrorsChapter4 });
        return { formErrorsChapter4 };
      }

      case "chapter5": {
        let formErrorsChapter5 = this.state.formErrorschapter5;
        formErrorsChapter5.countryList = isFieldEmpty(data.countryList);
        this.setState({ formErrorschapter5: formErrorsChapter5 });
        return { formErrorsChapter5 };
      }
      case "chapter6": {
        let formErrorsChapter6 = this.state.formErrorschapter6;
        this.setState({ formErrorschapter6: formErrorsChapter6 });
        return { formErrorsChapter6 };
      }
      default:
        return null;
    }
  };
}
const Validations = new FormValidations();
export default Validations;
