import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import countryList from "react-select-country-list";
import CheckBox from "../../../../../elements/CheckBox";
import DropDown from "../../../../../elements/DropDown";
import FileUpload from "../../../../../elements/FileUpload";
import MultiSelectDropdown from "../../../../../elements/MultiSelectDropdown";
import TextBox from "../../../../../elements/TextBox";
import NumberInput from "../../../../../elements/NumberInput";
import { ThirdPartyInvstObjComp } from "../../../../../JSONs/UK-Form/External/Client-Information/Chapter4";
import { fileAcceptType } from "./Constants";
import RightObligationCompany from "./rightObligationCompany";
import TypeSizeFreq from "./TypeSizeFreq";
import cc from "currency-codes";
import { checkIsCommon } from "../CommonFunctions";

let currencies = [];
cc.codes().map((currency) => currencies.push({ label: currency }));

class SourceOfFunds extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isUBOInvstObjComp, isUPCfundObjComp, isTpInvstObjComp, errorMsg,isUPCExists,isHoldingInterest,isCompIncorporated } =
      this.props;

    const eMsg = JSON.parse(errorMsg);
    console.log(eMsg);
    // const eMsg = {};
    let tpOCArray = [];
    if (this.props.form === "UK") {
      for (let i = 0; i < this.props.noOfTPInvestObj; i++) {
        tpOCArray.push(
          <div className="mb-4 col-lg-12">
            <fieldset>
              <legend>Third party {i + 1}</legend>

              <Row>
                <div className="mb-3 col-lg-4">
                  <TextBox
                    label="Please provide name of company"
                    name="tpName"
                    id="tpName"
                    placeholder="enter your text"
                    isCommon={checkIsCommon(this.props.form) && true}
                    reducer={this.props.reducer}
                    chapter={this.props.chapter}
                    objBaseName={this.props.objBaseName}
                    baseName="tpInvstObjComp"
                    isRequired={this.props.form === "UK" ? true : false} 
                    index={i}
                    disabled={
                      (this.props.form === "NL" ||
                        this.props.form === "Curacao" ||
                        this.props.form === "Malta") &&
                      this.props.from !== "clientInformation"
                    }
                  />
                  {eMsg &&
                    eMsg.tpInvstObjComp &&
                    eMsg.tpInvstObjComp[i] &&
                    eMsg.tpInvstObjComp[i].tpName?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg.tpInvstObjComp[i].tpName}
                      </span>
                    )}
                </div>
                <div className="mb-3 col-lg-4">
                  <DropDown
                    label="Please provide the country of residence of third party"
                    options={countryList().getData()}
                    name="tpCountry"
                    id="tpCountry"
                    isCommon={checkIsCommon(this.props.form) && true}
                    reducer={this.props.reducer}
                    chapter={this.props.chapter}
                    objBaseName={this.props.objBaseName}
                    baseName="tpInvstObjComp"
                    isRequired={this.props.form === "UK" ? true : false} 
                    index={i}
                    disabled={
                      (this.props.form === "NL" ||
                        this.props.form === "Curacao" ||
                        this.props.form === "Malta") &&
                      this.props.from !== "clientInformation"
                    }
                  />
                  {eMsg &&
                    eMsg.tpInvstObjComp &&
                    eMsg.tpInvstObjComp[i] &&
                    eMsg.tpInvstObjComp[i].tpCountry?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg.tpInvstObjComp[i].tpCountry}
                      </span>
                    )}
                </div>
                <div className="mb-3 col-lg-4">
                  <FileUpload
                    label="Please provide Annual Financial Statements/cash flow charts"
                    name="tpAnnualFinStmnts"
                    id="tpAnnualFinStmnts"
                    acceptType={fileAcceptType}
                    isCommon={checkIsCommon(this.props.form) && true}
                    chapter={this.props.chapter}
                    reducer={this.props.reducer}
                    objBaseName={this.props.objBaseName}
                    baseName="tpInvstObjComp"
                    isRequired={this.props.form === "UK" ? true : false} 
                    index={i}
                    disabled={
                      (this.props.form === "NL" ||
                        this.props.form === "Curacao" ||
                        this.props.form === "Malta") &&
                      this.props.from !== "clientInformation"
                    }
                  />
                  {eMsg &&
                    eMsg.tpInvstObjComp &&
                    eMsg.tpInvstObjComp[i] &&
                    eMsg.tpInvstObjComp[i].tpAnnualFinStmnts?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg.tpInvstObjComp[i].tpAnnualFinStmnts}
                      </span>
                    )}
                </div>
              </Row>
            </fieldset>
          </div>
        );
      }
    }
    return (
      <>
        <h4 className="sub-chapter-title mb-2">
          {this.props.form === "Malta"
            ? "Source of Funds Target Company / Transaction profile"
            : "Source of Funds Object Company / Transaction profile"}
        </h4>
        <Row className="pt-1 pb-0">
            
          <div className="mb-3 col-lg-4">
            <CheckBox
              label={`Will the UBO invest in the ${
                this.props.form === "Malta" ? "Target" : "Object"
              } Company?`}
              name="isUBOInvstObjComp"
              id="isUBOInvstObjComp"
              options={["Yes", "No"]}
              isCommon={checkIsCommon(this.props.form) && true}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={
                (this.props.form === "NL" ||
                  this.props.form === "Curacao" ||
                  this.props.form === "Malta" ||
                  this.props.form === "Luxembourg") &&
                this.props.from !== "clientInformation"
              }
              isRequired={false}
              //isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
            />
            {eMsg &&
              eMsg.isUBOInvstObjComp &&
              eMsg.isUBOInvstObjComp?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg.isUBOInvstObjComp}
                </span>
              )}
          </div>
          
          {isUBOInvstObjComp === "Yes" && (
            <>
              <div className="mb-3 col-lg-8">
                <Row>
                  <div className="mb-3 col-lg-6">
                    <DropDown
                      label="Please specify currency"
                      name="uboInvstCurrencyObjComp"
                      id="uboInvstCurrencyObjComp"
                      options={currencies}
                      isCommon={checkIsCommon(this.props.form) && true}
                      reducer={this.props.reducer}
                      chapter={this.props.chapter}
                      objBaseName={this.props.objBaseName}
                      isRequired={this.props.form === "UK" ? true : false} 
                      disabled={
                        (this.props.form === "NL" ||
                          this.props.form === "Curacao" ||
                          this.props.form === "Malta" ||
                          this.props.form === "Luxembourg") &&
                        this.props.from !== "clientInformation"
                      }
                      isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
                    />
                    {eMsg &&
                      eMsg?.uboInvstCurrencyObjComp &&
                      eMsg.uboInvstCurrencyObjComp?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {eMsg.uboInvstCurrencyObjComp}
                        </span>
                      )}
                  </div>
                  <div className="mb-3 col-lg-6">
                    <TextBox
                      label="Please specify the amount"
                      name="uboInvstObjCompAmnt"
                      id="uboInvstObjCompAmnt"
                      placeholder="enter amount"
                      isCommon={checkIsCommon(this.props.form) && true}
                      reducer={this.props.reducer}
                      chapter={this.props.chapter}
                      objBaseName={this.props.objBaseName}
                      validationType="Amount"
                      isRequired={this.props.form === "UK" ? true : false} 
                      disabled={
                        (this.props.form === "NL" ||
                          this.props.form === "Curacao" ||
                          this.props.form === "Malta" ||
                          this.props.form === "Luxembourg") &&
                        this.props.from !== "clientInformation"
                      }
                      isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
                    />
                    {eMsg &&
                      eMsg.uboInvstObjCompAmnt &&
                      eMsg.uboInvstObjCompAmnt?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {eMsg.uboInvstObjCompAmnt}
                        </span>
                      )}
                  </div>
                  <div className="mb-3 col-lg-6">
                    <TextBox
                      label="Please specify source of funds"
                      name="uboInvstObjCompOrigin"
                      id="uboInvstObjCompOrigin"
                      placeholder="enter source"
                      isCommon={checkIsCommon(this.props.form) && true}
                      reducer={this.props.reducer}
                      chapter={this.props.chapter}
                      objBaseName={this.props.objBaseName}
                      validationType="Text"
                      isRequired={this.props.form === "UK" ? true : false} 
                      disabled={
                        (this.props.form === "NL" ||
                          this.props.form === "Curacao" ||
                          this.props.form === "Malta" ||
                          this.props.form === "Luxembourg") &&
                        this.props.from !== "clientInformation"
                      }
                     
                     isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
                    />
                    {eMsg &&
                      eMsg?.uboInvstObjCompOrigin &&
                      eMsg.uboInvstObjCompOrigin?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {eMsg.uboInvstObjCompOrigin}
                        </span>
                      )}
                  </div>
                </Row>
              </div>
            </>
          )}
        </Row>
        <Row className="pt-0 pb-1">
           <div className="mb-3 col-lg-4">
            <CheckBox
              label={`Will the (Ultimate) Parent Company fund the ${
                this.props.form === "Malta" ? "Target" : "Object"
              } Company?`}
              name="isUPCfundObjComp"
              id="isUPCfundObjComp"
              options={["Yes", "No"]}
              isCommon={checkIsCommon(this.props.form) && true}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={
                (this.props.form === "NL" ||
                  this.props.form === "Curacao" ||
                  this.props.form === "Malta" ||
                  this.props.form === "Luxembourg") &&
                this.props.from !== "clientInformation"
              }
              isRequired={false}
             // isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
            />
            {eMsg &&
              eMsg?.isUPCfundObjComp &&
              eMsg.isUPCfundObjComp?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg.isUPCfundObjComp}
                </span>
              )}
          </div>
          
          {isUPCfundObjComp === "Yes" && (
            <>
              <div className="mb-3 col-lg-4">
                <TextBox
                  label="Please specify source/origin of funds"
                  name="upcFundObjCompOrigin"
                  id="upcFundObjCompOrigin"
                  placeholder="enter source"
                  isCommon={checkIsCommon(this.props.form) && true}
                  reducer={this.props.reducer}
                  chapter={this.props.chapter}
                  objBaseName={this.props.objBaseName}
                  validationType="Text"
                  isRequired={this.props.form === "UK" ? true : false} 
                  disabled={
                    (this.props.form === "NL" ||
                      this.props.form === "Curacao" ||
                      this.props.form === "Malta" ||
                      this.props.form === "Luxembourg") &&
                    this.props.from !== "clientInformation"
                  }
                  isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
                />
                {eMsg &&
                  eMsg?.upcFundObjCompOrigin &&
                  eMsg.upcFundObjCompOrigin?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg.upcFundObjCompOrigin}
                    </span>
                  )}
              </div>
              <div className="mb-3 col-lg-4">
                <FileUpload
                  label="Please provide Annual Financial Statements"
                  name="annualFinStmnt"
                  id="annualFinStmnt"
                  acceptType={fileAcceptType}
                  isCommon={checkIsCommon(this.props.form) && true}
                  reducer={this.props.reducer}
                  chapter={this.props.chapter}
                  objBaseName={this.props.objBaseName}
                  isRequired={this.props.form === "UK" ? true : false} 
                  disabled={
                    (this.props.form === "NL" ||
                      this.props.form === "Curacao" ||
                      this.props.form === "Malta" ||
                      this.props.form === "Luxembourg") &&
                    this.props.from !== "clientInformation"
                  }
                  isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
                />
                {eMsg &&
                  eMsg?.annualFinStmnt &&
                  eMsg.annualFinStmnt?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg.annualFinStmnt}
                    </span>
                  )}
              </div>
            </>
          )}
        </Row>
        <Row className="pt-0">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label={
                this.props.form === "Malta"
                  ? "Does the financing come from third party/loan (other than bank loans)?"
                  : "Will third parties invest in the Object Company?"
              }
              name="isTpInvstObjComp"
              id="isTpInvstObjComp"
              options={["Yes", "No"]}
              isCommon={checkIsCommon(this.props.form) && true}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={
                (this.props.form === "NL" ||
                  this.props.form === "Curacao" ||
                  this.props.form === "Malta" ||
                  this.props.form === "Luxembourg") &&
                this.props.from !== "clientInformation"
              }
              isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
            />
            {eMsg &&
              eMsg?.isTpInvstObjComp &&
              eMsg.isTpInvstObjComp?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg.isTpInvstObjComp}
                </span>
              )}
          </div>
          {isTpInvstObjComp === "Yes" && (
            <>
              {this.props.form === "UK" ? (
                <>
                  <div className="mb-3 col-lg-4">
                    <NumberInput
                      label="How many third parties will invest in the object Company?"
                      name="noOfTPInvestObj"
                      reducer={this.props.reducer}
                      chapter={this.props.chapter}
                      objBaseName={this.props.objBaseName}
                      referral={["tpInvstObjComp"]}
                      pushedObject={[ThirdPartyInvstObjComp]}
                      maxLimit={100}
                    />
                  </div>
                  {tpOCArray}
                </>
              ) : (
                <div className="mb-0 col-lg-8">
                  <Row>
                    <div className="mb-3 col-lg-6">
                      <TextBox
                        label="Please provide the names of these entities"
                        name="tpName"
                        id="tpName"
                        placeholder="enter your text"
                        isCommon={checkIsCommon(this.props.form) && true}
                        reducer={this.props.reducer}
                        chapter={this.props.chapter}
                        objBaseName={this.props.objBaseName}
                        nestedObjBaseName="tpInvstObjComp"
                        disabled={
                          (this.props.form === "NL" ||
                            this.props.form === "Curacao" ||
                            this.props.form === "Malta" ||
                            this.props.form === "Luxembourg") &&
                          this.props.from !== "clientInformation"
                        }
                        isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
                      />
                      {eMsg && eMsg?.tpName && eMsg.tpName?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {eMsg.tpName}
                        </span>
                      )}
                    </div>
                    <div className="mb-3 col-lg-6">
                      <DropDown
                        label="Please provide the country of residence of these entities"
                        options={countryList().getData()}
                        name="tpCountry"
                        id="tpCountry"
                        isCommon={checkIsCommon(this.props.form) && true}
                        reducer={this.props.reducer}
                        chapter={this.props.chapter}
                        objBaseName={this.props.objBaseName}
                        nestedObjBaseName="tpInvstObjComp"
                        disabled={
                          (this.props.form === "NL" ||
                            this.props.form === "Curacao" ||
                            this.props.form === "Malta" ||
                            this.props.form === "Luxembourg") &&
                          this.props.from !== "clientInformation"
                        }
                        isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
                      />
                      {eMsg &&
                        eMsg?.tpCountry &&
                        eMsg.tpCountry?.length > 0 && (
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {eMsg.tpCountry}
                          </span>
                        )}
                    </div>
                    <div className="mb-3 col-lg-6">
                      <FileUpload
                        label="Please provide Annual Financial Statements/cash flow charts"
                        name="tpAnnualFinStmnts"
                        id="tpAnnualFinStmnts"
                        acceptType={fileAcceptType}
                        isCommon={checkIsCommon(this.props.form) && true}
                        chapter={this.props.chapter}
                        reducer={this.props.reducer}
                        objBaseName={this.props.objBaseName}
                        nestedObjBaseName="tpInvstObjComp"
                        disabled={
                          (this.props.form === "NL" ||
                            this.props.form === "Curacao" ||
                            this.props.form === "Malta" ||
                            this.props.form === "Luxembourg") &&
                          this.props.from !== "clientInformation"
                        }
                        isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
                      />
                      {eMsg &&
                        eMsg?.tpAnnualFinStmnts &&
                        eMsg.tpAnnualFinStmnts?.length > 0 && (
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {eMsg.tpAnnualFinStmnts}
                          </span>
                        )}
                    </div>
                  </Row>
                </div>
              )}
            </>
          )}
        </Row>
        <div className="html2pdf__page-break"></div>
        <Row className="pt-0 pb-2">
          <div className="mb-3 col-lg-6">
            <MultiSelectDropdown
              label={`Which countries are/will be involved in transactions with the ${
                this.props.form === "Malta" ? "Target" : "Object"
              } Company?`}
              name="cntrysInvlvdTrnsctnsObjComp"
              id="cntrysInvlvdTrnsctnsObjComp"
              options={countryList().getData()}
              isCommon={checkIsCommon(this.props.form) && true}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={
                (this.props.form === "NL" ||
                  this.props.form === "Curacao" ||
                  this.props.form === "Malta" ||
                  this.props.form === "Luxembourg") &&
                this.props.from !== "clientInformation"
              }
              isRequired={this.props.form === "NL" && this.props.from === "clientInformation" && isCompIncorporated==="Yes" ? true : false} 
            />
            {eMsg &&
              eMsg?.cntrysInvlvdTrnsctnsObjComp &&
              eMsg.cntrysInvlvdTrnsctnsObjComp?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg.cntrysInvlvdTrnsctnsObjComp}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-6 fieldAlignment">
            <TextBox
              label={
                this.props.form === "Malta"
                  ? "Estimated annual turnover Target Company"
                  : "Estimated annual turnover Object Company"
              }
              name="estmtdAnnualTrnOvrObjComp"
              id="estmtdAnnualTrnOvrObjComp"
              placeholder="enter turnover"
              isCommon={checkIsCommon(this.props.form) && true}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
              disabled={
                (this.props.form === "NL" ||
                  this.props.form === "Curacao" ||
                  this.props.form === "Malta" ||
                  this.props.form === "Luxembourg") &&
                this.props.from !== "clientInformation"
              }
              isRequired={this.props.form === "NL" && this.props.from === "clientInformation" && isCompIncorporated==="Yes" ? true : false} 
            />
            {eMsg &&
              eMsg?.estmtdAnnualTrnOvrObjComp &&
              eMsg.estmtdAnnualTrnOvrObjComp?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg.estmtdAnnualTrnOvrObjComp}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-0 pb-2">
          <div className="mb-3 col-lg-6">
            <TextBox
              label={
                this.props.form === "Malta"
                  ? "Summary of assets (excluding participations) of Target Company"
                  : "Summary of assets (excluding participations) of Object Company"
              }
              name="assetSummryObjComp"
              id="assetSummryObjComp"
              placeholder="enter your text"
              isCommon={checkIsCommon(this.props.form) && true}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              isMultipleLines={true}
              disabled={
                (this.props.form === "NL" ||
                  this.props.form === "Curacao" ||
                  this.props.form === "Malta" ||
                  this.props.form === "Luxembourg") &&
                this.props.from !== "clientInformation"
              }
              isRequired={this.props.form === "NL" && this.props.from === "clientInformation"  && isCompIncorporated==="Yes" ? true : false} 
            />
            {eMsg &&
              eMsg?.assetSummryObjComp &&
              eMsg.assetSummryObjComp?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg.assetSummryObjComp}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-6">
            <FileUpload
              label="For existing companies include supporting evidence"
              name="assetSummryEvidence"
              id="assetSummryEvidence"
              acceptType={fileAcceptType}
              isCommon={checkIsCommon(this.props.form) && true}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={
                (this.props.form === "NL" ||
                  this.props.form === "Curacao" ||
                  this.props.form === "Malta" ||
                  this.props.form === "Luxembourg") &&
                this.props.from !== "clientInformation"
              }
              isRequired={this.props.form === "NL" && this.props.from === "clientInformation" && isCompIncorporated==="Yes" ? true : false} 
            />
            {eMsg &&
              eMsg?.assetSummryEvidence &&
              eMsg.assetSummryEvidence?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg.assetSummryEvidence}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-0 pb-2">
          <div className="mb-3 col-lg-6">
            <TextBox
              label="Summary of expected transactions"
              name="expctdTrnsctnsSummary"
              id="expctdTrnsctnsSummary"
              placeholder="enter your text"
              isCommon={checkIsCommon(this.props.form) && true}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              isMultipleLines={true}
              disabled={
                (this.props.form === "NL" ||
                  this.props.form === "Curacao" ||
                  this.props.form === "Malta" ||
                  this.props.form === "Luxembourg") &&
                this.props.from !== "clientInformation"
              }
              isRequired={this.props.form === "NL" && this.props.from === "clientInformation" && isCompIncorporated==="Yes" ? true : false} 
            />
            {eMsg &&
              eMsg?.expctdTrnsctnsSummary &&
              eMsg.expctdTrnsctnsSummary?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg.expctdTrnsctnsSummary}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-6">
            <FileUpload
              label="For existing companies include supporting evidence"
              name="expctdTrnsctnsEvidence"
              id="expctdTrnsctnsEvidence"
              acceptType={fileAcceptType}
              isCommon={checkIsCommon(this.props.form) && true}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={
                (this.props.form === "NL" ||
                  this.props.form === "Curacao" ||
                  this.props.form === "Malta" ||
                  this.props.form === "Luxembourg") &&
                this.props.from !== "clientInformation"
              }
              isRequired={this.props.form === "NL" && this.props.from === "clientInformation" && isCompIncorporated==="Yes" ? true : false} 
            />
            {eMsg &&
              eMsg?.expctdTrnsctnsEvidence &&
              eMsg.expctdTrnsctnsEvidence?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg.expctdTrnsctnsEvidence}
                </span>
              )}
          </div>
        </Row>

        {(isUBOInvstObjComp === "Yes" ||
          isUPCfundObjComp === "Yes" ||
          isTpInvstObjComp === "Yes") && (
          <div className="pt-0 pb-2">
            <TypeSizeFreq
              reducer={this.props.reducer}
              objBaseName={this.props.objBaseName}
              chapter={this.props.chapter}
              form={this.props.form}
              errorMsg={errorMsg}
              from={this.props.from}
            />
          </div>
        )}
        {this.props.from === "ubo-declaration-uim" && this.props.form === "NL" && (
          <>
            <div className="html2pdf__page-break"></div>
            <Row>
              <div className="pt-0 col-lg-12">
                <TextBox
                  label="Summary rights and obligations"
                  id="summaryrightsAndObligations"
                  name="summaryrightsAndObligations"
                  placeholder="enter your text"
                  reducer="UBODeclarationUIM"
                  chapter="partC"
                  validationType="Text"
                  isMultipleLines={true}
                />
                {eMsg !== null &&
                  Object.entries(eMsg).length !== 0 &&
                  eMsg?.partC?.summaryrightsAndObligations?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.partC?.summaryrightsAndObligations}
                    </span>
                  )}
              </div>
            </Row>
          </>
        )}

        {this.props.form === "Curacao" ||
        this.props.form === "Malta" ||
        this.props.form === "Luxembourg" ? (
          <></>
        ) : (
          <>
          {isCompIncorporated ==="Yes" && this.props.form === "NL" &&(<> <h4
              className="sub-chapter-title pt-5 mb-4"
              id="rightsAndObligations"
            >
              {this.props.form === "NL" ?(<>Agreements entered into by Object company</>):( <> Rights and Obligations of the Object Company </>)}
            </h4>
            <div className="pt-2 pb-2 obligationComapnygrid mb-5">
              <RightObligationCompany
                reducer={this.props.reducer}
                form={this.props.form}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                from={this.props.from}                
              />  
              {this.props.form === "NL" && eMsg &&
                eMsg?.rightsAndObligations &&
                eMsg.rightsAndObligations?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.rightsAndObligations}
                  </span>
                )} 
                
            </div></>)}
            {this.props.form === "UK" &&(<> <h4
              className="sub-chapter-title pt-5 mb-4"
              id="rightsAndObligations"
            >
           Rights and Obligations of the Object Company
            </h4>
            <div className="pt-2 pb-2 obligationComapnygrid mb-5">
              <RightObligationCompany
                reducer={this.props.reducer}
                form={this.props.form}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                from={this.props.from}
              />                
            
            </div></>)}
           
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { form, reducer, chapter } = ownProps;
  if (
    form === "NL" ||
    form === "Curacao" ||
    form === "Malta" ||
    form === "Luxembourg"
  ) {
    return {
      isUBOInvstObjComp:
        state.commonFields.srcFundsObjComp["isUBOInvstObjComp"],
      isUPCfundObjComp: state.commonFields.srcFundsObjComp["isUPCfundObjComp"],
      isTpInvstObjComp: state.commonFields.srcFundsObjComp["isTpInvstObjComp"],
      isUPCExists: state.commonFields["isUPCExists"],
      isHoldingInterest: state["clientInformation"]["chapter3"]["isHoldingInterest"],
      isCompIncorporated: state["clientInformation"]["chapter1"]["isCompIncorporated"]
    };
  } else {
    return {
      isUBOInvstObjComp:
        state[reducer][chapter]["srcFundsObjComp"]["isUBOInvstObjComp"],
      isUPCfundObjComp:
        state[reducer][chapter]["srcFundsObjComp"]["isUPCfundObjComp"],
      isTpInvstObjComp:
        state[reducer][chapter]["srcFundsObjComp"]["isTpInvstObjComp"],
      noOfTPInvestObj:
        state[reducer][chapter]["srcFundsObjComp"]["noOfTPInvestObj"],
    };
  }
};

export default connect(mapStateToProps, null)(SourceOfFunds);
