import React from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";
import {
  setMaltaInternalCAFDetails,
  getMaltaInternalCAFDetails,
} from "../../../../../redux/actions/Malta/MaltaInternalCAFActions";
import "../../Onboarding/internalCAF.scss";
import AutoSaving from "../AutoSaving";
import { connect } from "react-redux";
import NotificationManager from "react-notifications/lib/NotificationManager";
import CheckBox from "../../../../elements/CheckBox";
import MediaCoverage from "../../Onboarding/Chapter1/MediaCoverage";
import Risks from "../../Onboarding/Chapter1/Risks";
import InternalFileUpload from "../../../../elements/InternalFileUpload";
import NumberInput from "../../../../elements/NumberInput";
import { fileAcceptType } from "../../Onboarding/Constants";
import UboQuestions from "../../Onboarding/Chapter3/UboQuestions";
import UpcDetails from "../../Onboarding/Chapter3/UpcDetails";
import UboDetails from "../../Onboarding/Chapter3/UboDetails";
import MaltaShareholder from "./MaltaShareholder";
import getSequentialFields from "../getSequentialFields";
import { setMaltaInternalChapterError } from "../../../../../redux/actions/Malta/MaltaInternalChapterErrorActions";
import { chapValidationErrorMalta } from "../Constants";

const chapter = "chapter3";
const reducer = "MaltaInternalCAF";
const externalReducer = "MaltaClientInformation";

let timer;

class Chapter3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formErrorsChapter3: {},
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: "all",
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.getMaltaInternalCAFDetails(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          Object.keys(this.props.internalErrors.errors).length === 0 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationErrorMalta.forEach(async (element) => {
            let response =
              res?.data?.result?.cafInternalValidations[element.chapter];

            const newResponse = await Promise.resolve(
              getSequentialFields(response, element.chapter, false)
            );
            this.props.setMaltaInternalChapterError({
              form: `formErrors${element.chapter}`,
              errors: newResponse?.errorChapter,
            });
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;
    requestData["commonFields"] = this.props.commonFields;
    requestData["cafExternalChap3"] = this.props.chapter3;

    this.props.setMaltaInternalCAFDetails(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.props.ClientAcceptance.chapter3["dirty"] = false;
        let response = res.data.result;

        const newResponse = await Promise.resolve(
          getSequentialFields(response, chapter, true)
        );
        this.props.setMaltaInternalChapterError({
          errors: newResponse?.errorChapter,
          form: "formErrorschapter3",
        });
        if (!res.data.result.isValid) {
          NotificationManager.info(
            "All fields are mandatory to fill in",
            null,
            4000
          );
          timer = setTimeout(() => {
            return this.props.history.push({
              pathname: "/malta/object-company",
            });
          }, 4000);
        } else {
          NotificationManager.success("Form is submitted successfully.");

          return this.props.history.push({
            pathname: "/malta/object-company",
          });
        }
      }
    });
  };

  render() {
    const {
      noOfUBOs,
      isUPCExists,
      chapter3,
      isUBOresultIncrsdIntgrtyRsk,
      internalErrors,
    } = this.props;

    const formErrorsChapter3 = JSON.stringify(internalErrors.errors);
    const errorsMsg = JSON.parse(formErrorsChapter3);

    let uboinfo = [];
    for (let i = 0; i < noOfUBOs; i++) {
      uboinfo.push(
        <UboDetails
          errors={formErrorsChapter3}
          index={i}
          baseName="ubox"
          reducer={reducer}
          objBaseName="ubo"
          form="Malta"
          isRequired={true}
        />
      );
    }

    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="cafHeader">
            <h2 className="m-0">Business Acceptance Form </h2>
          </div>
          <div className="internal_caf generic-form">
            <div className="html2pdf__page-break"></div>
            <h3 className="chapterttl">
              Target Company - UBO/s or Ultimate Parent Company
            </h3>
            <Row className="mb-3">
              <div className="col-md-12">
                <UboQuestions
                  chapter={chapter}
                  reducer={reducer}
                  externalReducer={externalReducer}
                  errors={formErrorsChapter3}
                  isRequired={true}
                  form="malta"
                />
              </div>
            </Row>
            {chapter3.isHoldingInterest === "Yes" &&
              chapter3.isUBOClassified === "Yes" &&
              chapter3.isAssetClient === "Yes" && (
                <>
                  <div className="html2pdf__page-break"></div>
                  <Row className="mb-3">
                    <div className="col-lg-4">
                      <NumberInput
                        label="How many UBO's are there?"
                        name="noOfUBOs"
                        reducer="commonFields"
                        isCommon={true}
                        objBaseName="ubo"
                        disabled={true}
                      />
                      <div className="errorMessage"></div>
                    </div>
                    <div className="col-lg-8">{uboinfo}</div>
                  </Row>
                  <div className="html2pdf__page-break"></div>
                </>
              )}
            <div className="html2pdf__page-break"></div>
            <Row className="mb-3">
              <div className="col-lg-6">
                <CheckBox
                  label="Does the Ultimate Parent Company exist?"
                  options={["Yes", "No"]}
                  name="isUPCExists"
                  reducer="commonFields"
                  isCommon={true}
                  disabled={true}
                />
                <div className="errorMessage"></div>
              </div>
            </Row>
            {isUPCExists === "Yes" && (
              <UpcDetails
                errors={formErrorsChapter3}
                from="ClientAcceptance"
                externalReducer="commonFields"
                reducer={reducer}
                objBaseName="upc"
                form="Malta"
                isRequired={true}
                PdfGeneration={this.props.PdfGeneration}
              />
            )}
            <Row className="mb-3">
              <div className="col-lg-4">
                <InternalFileUpload
                  label="Pascal report"
                  name="pascalReportUpload"
                  id="pascalReportUpload"
                  chapter={chapter}
                  acceptType={fileAcceptType}
                  reducer={reducer}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.pascalReportUpload?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.pascalReportUpload}
                    </span>
                  )}
              </div>
              <div className="col-lg-4">
                <TextBox
                  label="Findings"
                  placeholder="Enter your text"
                  name="isFindings"
                  id="isFindings"
                  chapter={chapter}
                  reducer={reducer}
                  validationType="Text"
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.isFindings?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isFindings}
                    </span>
                  )}
              </div>
              <div className="col-lg-4">
                <CheckBox
                  label="Verified Machine Readable(MRZ) Passport through Passport-Check Pascal"
                  options={["Yes", "No"]}
                  name="isMRZPassport"
                  id="isMRZPassport"
                  chapter={chapter}
                  reducer={reducer}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.isMRZPassport?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isMRZPassport}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="mb-3">
              <div className="col-lg-12">
                <TextBox
                  label="Please describe how the investigation into the UBO in the scope of determining whether he or she can be classified as a Politically Exposed Person (PEP) was carried out. What is his position and which extra measures should be taken?"
                  placeholder="Enter your text"
                  name="descInvstgUBOClsifiedPEP"
                  id="descInvstgUBOClsifiedPEP"
                  chapter={chapter}
                  reducer={reducer}
                  isMultipleLines={true}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.descInvstgUBOClsifiedPEP?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.descInvstgUBOClsifiedPEP}
                    </span>
                  )}
              </div>
            </Row>
            <div className="html2pdf__page-break"></div>
            <MaltaShareholder
              reducer={reducer}
              chapter={chapter}
              errorMsg={errorsMsg}
              form="Malta"
              isRequired={true}
            />
            <div className="html2pdf__page-break"></div>
            <MediaCoverage
              chapter={chapter}
              reducer={reducer}
              errors={formErrorsChapter3}
              form="Malta"
              isRequired={true}
            />
            {/* <div className="html2pdf__page-break"></div> */}
            <Risks
              chapter={chapter}
              reducer={reducer}
              errors={formErrorsChapter3}
              riskLabel="Client risk rating"
              questionLabel="How many UBO/UPC related risks can be identified?"
              boxLabel="Risk"
              form="Malta"
              isRequired={true}
              PdfGeneration={this.props.PdfGeneration}
            />
            {/* <div className="html2pdf__page-break"></div> */}
            <Row className="mb-3">
              <div className="col-lg-12">
                <TextBox
                  label="Has the source of wealth properly been verified? Are there any suspicions to be reported?"
                  placeholder="Enter your text"
                  name="isSOWVerifed"
                  id="isSOWVerifed"
                  chapter={chapter}
                  reducer={reducer}
                  isRequired={true}
                  isMultipleLines={true}
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtArea"
                      : "form-control"
                  }
                />{" "}
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.isSOWVerifed?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isSOWVerifed}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="mb-3">
              <div className="col-lg-12">
                <CheckBox
                  label="In addition, report whether the UBO criteria result in a potentially increased integrity risk"
                  options={["Yes", "No"]}
                  name="isUBOresultIncrsdIntgrtyRsk"
                  id="isUBOresultIncrsdIntgrtyRsk"
                  chapter={chapter}
                  isRequired={true}
                  reducer={reducer}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.isUBOresultIncrsdIntgrtyRsk?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isUBOresultIncrsdIntgrtyRsk}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="mb-3">
              {isUBOresultIncrsdIntgrtyRsk === "Yes" && (
                <div className="col-lg-12">
                  <TextBox
                    label="Please describe"
                    placeholder="Enter your text"
                    name="ubOresultIncrsdIntgrtyRskCmmnt"
                    id="ubOresultIncrsdIntgrtyRskCmmnt"
                    chapter={chapter}
                    reducer={reducer}
                    isRequired={true}
                    isMultipleLines={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtArea"
                        : "form-control"
                    }
                  />
                  {errorsMsg &&
                    errorsMsg.isSubmitted === true &&
                    errorsMsg?.ubOresultIncrsdIntgrtyRskCmmnt?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.ubOresultIncrsdIntgrtyRskCmmnt}
                      </span>
                    )}
                </div>
              )}
              {isUBOresultIncrsdIntgrtyRsk === "No" && (
                <div className="col-md-12 font-weight-bold">
                  With respect to the UBO information, UIM sees no reason to
                  discontinue the set-up of a business relationship
                </div>
              )}
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    noOfUBOs: state.commonFields.ubo["noOfUBOs"],
    commonFields: state.commonFields,
    isUPCExists: state.commonFields["isUPCExists"],
    chapter3: state.MaltaClientInformation.chapter3,
    isUBOresultIncrsdIntgrtyRsk:
      state.MaltaInternalCAF.chapter3["isUBOresultIncrsdIntgrtyRsk"],
    ClientAcceptance: state.MaltaInternalCAF,
    data: state,
    internalErrors: state.MaltaInternalChapterErrors.formErrorschapter3,
    errors: state.MaltaInternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMaltaInternalCAFDetails: async (data) =>
      dispatch(await getMaltaInternalCAFDetails(data)),
    setMaltaInternalCAFDetails: async (data) =>
      dispatch(await setMaltaInternalCAFDetails(data)),
    setMaltaInternalChapterError: (data) =>
      dispatch(setMaltaInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter3);
