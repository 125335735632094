import { AdditionDocs } from "../../InternalUser/Chapter1";
import { CustActivityRisksMalta } from "./Chapter1";

export const RightsAndOblgtnsRsk = {
  rightsId: null,
  integrityRsk: null,
};

export const BnkInfoRsk = {
  bankId: null,
  integrityRsk: null,
};

let bankarray = [];
for (let i = 0; i < 10; i++) {
  bankarray.push(BnkInfoRsk);
}

let dirObj = {
  dirId: null,
  isDirPassportApprvd: null,
  isDirUtilityBillApprvd: null,
  isDirReffLetterApprvd: null,
  dirComment: null,
};
let DirArray = [];
for (let i = 0; i < 10; i++) {
  DirArray.push(dirObj);
}

const DocumentApproval = {
  isCrtfdPassportCpy: null,
  isBankersRef: null,
  isProfRef: null,
  isGoodStanding: null,
  isIdentityShareholder: null,
  isRecGoodStanding: null,
  isCpyIncorpDoc: null,
  isRelCompHouse: null,
  isStructureChart: null,
  isLatestFinStmnts: null,
};

const ServiceProviders = {
  propAuditorExsts: null,
  propAuditorName: null,
  propAccntExsts: null,
  propAccntName: null,
  propTaxAdvisor: null,
  propTaxAdvisorName: null,
};
export const Chapter4 = {
  objCompCmmnt: null,
  compSecCmmnt: null,
  accntngYrCmmnt: null,
  cntctPerCmmnt: null,
  objCompActiveConCmmnt: null,
  objCompBranchesCmmnt: null,
  rationaleStrctrngForNLCmmnt: null,
  objPascalResport: [],
  objFindings: null,
  findingsIndpTaxAdvisorApprvd: null,
  dirCmmnt: DirArray,
  oneTimeServCmmnt: null,
  extCOCApprvd: null,
  deedOfIncorpApprvd: null,
  shareholdersRegApprvd: null,
  shareholdersCrtfictsApprvd: null,
  finStmntsPstthreeYrsApprvd: null,
  propDirsManSigCmmnt: null,
  apaCmmnt: null,
  otherRelDocsApprvd: null,
  otherRelDocsCmmnt: null,
  signatoryPowers: null,
  propDirsMangInfo: null,
  relationshipUBO: null,
  signPowInfo: null,
  documentApproval: DocumentApproval,
  serviceProviders: ServiceProviders,
  annualFinStmntupcApprvd: null,
  upcFundObjCompCmmnt: null,
  anulFinStmntTpApprvd: null,
  tpInvstObjCompCmmnt: null,
  cntrysInvldObjCompCmmnt: null,
  asstSmmryEvidenceApprvd: null,
  assSmmryObjCompCommnt: null,
  expctdTransSummryApprvd: null,
  expctdTransSummryCmmnt: null,
  objCompGrpCompsCmmnt: null,
  grpCompsObjCompCmmnt: null,
  objCompUboCmmnt: null,
  uboObjCmpCmmnt: null,
  objCompTpCmmnt: null,
  tpObjCompCmmnt: null,
  bnkInfoRsk: bankarray,
  bnkInfoCmmnt: null,
  fatcaFormApprvd: null,
  bnkStmntsApprvd: null,
  aggrmntsGrpEntApprvd: null,
  aggrmntsOthrParApprvd: null,
  finStmntObjCompApprvd: null,
  finStmntAllPartciApprvd: null,
  finStmntParCompApprvd: null,
  declrFinInstApprvd: null,
  isMediaCoverageOnStruct: null,
  mediaCmnt: null,
  isAddDocs: null,
  numAddDocs: 1,
  addDocs: [AdditionDocs],
  isRisk: null,
  numCustActivityRisks: 1,
  custActivityRisks: [CustActivityRisksMalta],
  custActvtyRskRtng: null,
  descActnNeedsTkn: null,
  objCompIncrdIntgtyRsk: null,
  objCompIncrdIntgtyRskText: null,
  dirty: false,
};
