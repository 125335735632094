import React, { Component } from "react";
import {
  setUKCAFDetails,
  setNewUKDataToReducer,
} from "../../../../../../redux/actions/UK/UKClientInformationActions";
import { connect } from "react-redux";
import { updateClientInformation } from "../../../../../../redux/actions/CAFGenericActions";
class AutoSaving extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  apiCall = () => {
    //api call for chapters data
    let requestData = {};
    let apiCallFlag = false;
    for (let [chapterNumber, data] of Object.entries(
      this.props.UKClientInformation
    )) {
      if (data["dirty"] === true) {
        apiCallFlag = true;
        delete data["dirty"];
        requestData[chapterNumber] = data;
      }
    }

    requestData["id"] = localStorage.getItem("formId");
    requestData["companyId"] = localStorage.getItem("clientId");
    requestData["office"] = localStorage.getItem("Office");
    requestData["isSubmitted"] = false;
    if (apiCallFlag) {
      this.props.setUKCAFDetails(requestData).then((res) => {
        if (res.data.portalErrorCode === 200) {
          let details = {
            Id: localStorage.getItem("formId"),
            lastActivityName: "client-information",
            status: "In Progress",
            activeLinkLevel: 0,
          };
          this.props.updateClientInformation(details);
          for (let [chapterNumber, data] of Object.entries(
            this.props.UKClientInformation
          )) {
            data["dirty"] = false;
          }
          this.props.setNewUKDataToReducer(this.props.UKClientInformation);
        }
      });
    }
  };

  componentDidMount = () => {
    this.apiCall();
    setInterval(this.apiCall, 5000);
  };

  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    UKClientInformation: state.UKClientInformation,
  //  commonFields: state.commonFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUKCAFDetails: (data) => dispatch(setUKCAFDetails(data)),
    setNewUKDataToReducer: (data) => dispatch(setNewUKDataToReducer(data)),
    updateClientInformation: (data) => dispatch(updateClientInformation(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AutoSaving);
