import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import DownloadFile from "../../../../elements/DownloadFile";
import TextBox from "../../../../elements/TextBox";
import { checkIsCommon } from "../../../external/OnBoarding/client-information/CommonFunctions";
import { fileAcceptType } from "../Constants";
import InternalFileUpload from "../../../../elements/InternalFileUpload";
class DOC extends React.Component {
  render() {
    const {
      documentsObjComp,
      isTaxMemorandum,
      isInvstmntMemorandum,
      taxMemorandum,
      invstmntMemorandum,
      errors,
      isRequired,
      isOCSFI,
      UKInternalCAF
    } = this.props;
    const errorsMsg = JSON.parse(errors);

    return (
      <>
        <h4 className="sub-chapter-title mb-2">Documents Object Company</h4>

        {this.props.form !== "Malta" && this.props.form !== "UK" && (
          <Row className="pt-0 pb-2">
            <div className="mb-2 col-lg-6 col-xl-4">
              {documentsObjComp.copyPassportManagement.length != 0 && (
                <DownloadFile
                  label="Copy Passport Management"
                  value={documentsObjComp.copyPassportManagement}
                />
              )}
              {documentsObjComp.copyPassportManagement.length == 0 && this.props.form == "NL" && (
                <InternalFileUpload
                  label="Copy Passport Management"
                  name="copyPassportManagement"
                  id="copyPassportManagement"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  acceptType={fileAcceptType}
                />
              )}
            </div>
            <div className="mb-2 col-lg-6 col-xl-4">
              <CheckBox
                label="Is Approved?"
                options={["Yes", "No"]}
                name="cpyPassportMangApprvd"
                id="cpyPassportMangApprvd"
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                isRequired={isRequired}
              />
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.cpyPassportMangApprvd &&
                errorsMsg?.cpyPassportMangApprvd?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg.cpyPassportMangApprvd}
                  </span>
                )}
            </div>
          </Row>
        )}

        <Row>
          <div className="mb-2 col-lg-6 col-xl-4">
            {this.props.form != "NL" && (
              <DownloadFile
                label="Extract Chamber of Commerce"
                value={documentsObjComp.extractChamberOfCommerce}
              />
            )}
            {documentsObjComp.extractChamberOfCommerce.length != 0 && this.props.form == "NL" && (
              <DownloadFile
                label="Extract Chamber of Commerce"
                value={documentsObjComp.extractChamberOfCommerce}
              />
            )}
            {documentsObjComp.extractChamberOfCommerce.length == 0 && this.props.form == "NL" && (
              <InternalFileUpload
                label="Extract Chamber of Commerce"
                name="extractChamberOfCommerce"
                id="extractChamberOfCommerce"
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                acceptType={fileAcceptType}
              />
            )}
          </div>

          <div className="mb-2 col-lg-3 col-xl-3">
             <CheckBox
               label="Is Approved?"
               options={["Yes", "No"]}
               name="extCOCApprvd"
               id="extCOCApprvd"
               chapter={this.props.chapter}
               reducer={this.props.reducer}
               isRequired={isRequired}
             />
             {errorsMsg &&
               errorsMsg?.isSubmitted === true &&
               errorsMsg?.extCOCApprvd &&
               errorsMsg?.extCOCApprvd?.length > 0 && (
                 <span style={{ color: "red", fontSize: "12px" }}>
                   {errorsMsg.extCOCApprvd}
                 </span>
               )}
           </div>
          {this.props.form == "UK"  && (
            <>
            {UKInternalCAF.extCOCApprvd ==="No" &&(
          <div className="mb-2 col-lg-5 col-xl-5">
          <TextBox
                label="Please explain"
                placeholder="Enter your text"
                name="extCOCApprvdExplain"
                id="extCOCApprvdExplain"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                validationType="Text"
                               className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
          </div>
          )}
          </>
          )}
        </Row>
        <Row>
          <div className="mb-2 col-lg-6 col-xl-4">
            {this.props.form != "NL" && (
              <DownloadFile
                label="Deed of incorporation/ Articles of association"
                value={documentsObjComp.deedOfIncorporation}
              />
            )}
            {documentsObjComp.deedOfIncorporation.length != 0 && this.props.form == "NL" && (
              <DownloadFile
                label="Deed of incorporation/ Articles of association"
                value={documentsObjComp.deedOfIncorporation}
              />
            )}
            {documentsObjComp.deedOfIncorporation.length == 0 && this.props.form == "NL" && (
              <InternalFileUpload
                label="Deed of incorporation/ Articles of association"
                name="deedOfIncorporation"
                id="deedOfIncorporation"
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                acceptType={fileAcceptType}
              />
            )}
          </div>
          <div className="mb-2 col-lg-3 col-xl-3">
            <CheckBox
              label="Is Approved?"
              options={["Yes", "No"]}
              name="deedOfIncorpApprvd"
              id="deedOfIncorpApprvd"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.deedOfIncorpApprvd &&
              errorsMsg?.deedOfIncorpApprvd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.deedOfIncorpApprvd}
                </span>
              )}
          </div>
          {this.props.form == "UK" && (
            <>
            {UKInternalCAF.deedOfIncorpApprvd ==="No" &&(
          <div className="mb-2 col-lg-5 col-xl-5">
          <TextBox
                label="Please explain"
                placeholder="Enter your text"
                name="deedOfIncorpApprvdExplain"
                id="deedOfIncorpApprvdExplain"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                validationType="Text"
                               className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
          </div>
            )}
            </>
          )}
        </Row>
        <Row>
          <div className="mb-2 col-lg-4 col-xl-4">
            {this.props.form != "NL" && (
              <DownloadFile
                label="Shareholders register"
                value={documentsObjComp.shareholdersRegister}
              />
            )}
            {documentsObjComp.shareholdersRegister.length != 0 && this.props.form == "NL" && (
              <DownloadFile
                label="Shareholders register"
                value={documentsObjComp.shareholdersRegister}
              />
            )}
            {documentsObjComp.shareholdersRegister.length == 0 && this.props.form == "NL" && (
              <InternalFileUpload
                label="Shareholders register"
                name="shareholdersRegister"
                id="shareholdersRegister"
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                acceptType={fileAcceptType}
              />
            )}
          </div>
          <div className="mb-2 col-lg-3 col-xl-3">
            <CheckBox
              label="Is Approved?"
              options={["Yes", "No"]}
              name="shareholdersRegApprvd"
              id="shareholdersRegApprvd"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.shareholdersRegApprvd &&
              errorsMsg?.shareholdersRegApprvd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.shareholdersRegApprvd}
                </span>
              )}
          </div>
          {this.props.form == "UK" &&(
            <>
            {UKInternalCAF.shareholdersRegApprvd ==="No" &&(
          <div className="mb-2 col-lg-5 col-xl-5">
          <TextBox
                label="Please explain"
                placeholder="Enter your text"
                name="shareholdersRegApprvdExplain"
                id="shareholdersRegApprvdExplain"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                validationType="Text"
                               className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
          </div>
          )}
          </>
          )}
        </Row>
        <Row>
          <div className="mb-2 col-lg-6 col-xl-4">
            {this.props.form != "NL" && (
              <DownloadFile
                label="Shareholders certificates"
                value={documentsObjComp.shareholdersCertificates}
              />
            )}
            {documentsObjComp.shareholdersCertificates.length != 0 && this.props.form == "NL" && (
              <DownloadFile
                label="Shareholders certificates"
                value={documentsObjComp.shareholdersCertificates}
              />
            )}
            {documentsObjComp.shareholdersCertificates.length == 0 && this.props.form == "NL" && (
              <InternalFileUpload
                label="Shareholders certificates"
                name="shareholdersCertificates"
                id="shareholdersCertificates"
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                acceptType={fileAcceptType}
              />
            )}
          </div>
          <div className="mb-2 col-lg-3 col-xl-3">
            <CheckBox
              label="Is Approved?"
              options={["Yes", "No"]}
              name="shareholdersCrtfictsApprvd"
              id="shareholdersCrtfictsApprvd"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.shareholdersCrtfictsApprvd &&
              errorsMsg?.shareholdersCrtfictsApprvd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.shareholdersCrtfictsApprvd}
                </span>
              )}
          </div>
          {this.props.form == "UK" &&(
            <>
            {UKInternalCAF.shareholdersCrtfictsApprvd ==="No" && (
          <div className="mb-2 col-lg-5 col-xl-5">
          <TextBox
                label="Please explain"
                placeholder="Enter your text"
                name="shareholdersCrtfictsApprvdExplain"
                id="shareholdersCrtfictsApprvdExplain"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                validationType="Text"
                               className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
          </div>
          )}
          </>
          )}
        </Row>
        <Row>
          <div className="mb-2 col-lg-6 col-xl-4">
            {this.props.form != "NL" && (
              <DownloadFile
                label="Financial statements of the past three years"
                value={documentsObjComp.financialStatmntsThreeYears}
              />
            )}
            {documentsObjComp.financialStatmntsThreeYears.length != 0 && this.props.form == "NL" && (
              <DownloadFile
                label="Financial statements of the past three years"
                value={documentsObjComp.financialStatmntsThreeYears}
              />
            )}
            {documentsObjComp.financialStatmntsThreeYears.length == 0 && this.props.form == "NL" && (
              <InternalFileUpload
                label="Financial statements of the past three years"
                name="financialStatmntsThreeYears"
                id="financialStatmntsThreeYears"
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                acceptType={fileAcceptType}
              />
            )}
          </div>
          <div className="mb-2 col-lg-3 col-xl-3">
            <CheckBox
              label="Is Approved?"
              options={["Yes", "No"]}
              name="finStmntsPstthreeYrsApprvd"
              id="finStmntsPstthreeYrsApprvd"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.finStmntsPstthreeYrsApprvd &&
              errorsMsg?.finStmntsPstthreeYrsApprvd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.finStmntsPstthreeYrsApprvd}
                </span>
              )}
          </div>
          {this.props.form == "UK" &&(
            <>
            {UKInternalCAF.finStmntsPstthreeYrsApprvd ==="No" &&(
          <div className="mb-2 col-lg-5 col-xl-5">
          <TextBox
                label="Please explain"
                placeholder="Enter your text"
                name="finStmntsPstthreeYrsApprvdExplain"
                id="finStmntsPstthreeYrsApprvdExplain"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                validationType="Text"
                               className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
          </div>
          )}
          </>
          )}
        </Row>
        {this.props.form === "Malta" && (
          <>
            <Row>
              <div className="mb-2 col-lg-6 col-xl-4">
                <DownloadFile
                  label="Utility bills"
                  value={documentsObjComp.utilityBills}
                />
              </div>
              <div className="mb-2 col-lg-6 col-xl-4">
                <CheckBox
                  label="Is Approved?"
                  options={["Yes", "No"]}
                  name="utilityBillsApprvd"
                  id="utilityBillsApprvd"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  isRequired={isRequired}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.utilityBillsApprvd &&
                  errorsMsg?.utilityBillsApprvd?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.utilityBillsApprvd}
                    </span>
                  )}
              </div>
            </Row>
            <Row>
              <div className="mb-2 col-lg-6 col-xl-4">
                <DownloadFile
                  label="Copy Passport Management"
                  value={documentsObjComp.refLetter}
                />
              </div>
              <div className="mb-2 col-lg-6 col-xl-4">
                <CheckBox
                  label="Is Approved?"
                  options={["Yes", "No"]}
                  name="refLetterApprvd"
                  id="refLetterApprvd"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  isRequired={isRequired}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.refLetterApprvd &&
                  errorsMsg?.refLetterApprvd?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.refLetterApprvd}
                    </span>
                  )}
              </div>
            </Row>
          </>
        )}

        {this.props.form !== "Malta" && (
          <Row className="pt-3 pb-2">
            <div className="mb-3 col-lg-4">
              <CheckBox
                label="Tax advice, memorandum or opinion available?"
                options={["Yes", "No"]}
                name="isTaxMemorandum"
                chapter={
                  checkIsCommon(this.props.form) ? null : this.props.chapter
                }
                reducer={
                  checkIsCommon(this.props.form)
                    ? "commonFields"
                    : this.props.externalReducer
                }
                isCommon={checkIsCommon(this.props.form)}
                bothDisabled={true}
              />
            </div>

            {isTaxMemorandum === "Yes" && (
              <>
                <div className="mb-3 col-lg-4">
                  <TextBox
                    label="Add name advisor"
                    name="taxAdvisorName"
                    placeholder="enter your text"
                    chapter={
                      checkIsCommon(this.props.form) ? null : this.props.chapter
                    }
                    reducer={
                      checkIsCommon(this.props.form)
                        ? "commonFields"
                        : this.props.externalReducer
                    }
                    isCommon={checkIsCommon(this.props.form)}
                    validationType="Text"
                    disabled={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtArea"
                        : "form-control"
                    }
                  />
                </div>

                <div className="mb-3 col-lg-4">
                  <DownloadFile
                    label="Please include evidence"
                    value={taxMemorandum}
                  />
                </div>
                <div className="mb-2 col-lg-6 col-xl-4">
                  <CheckBox
                    label="Is approved"
                    options={["Yes", "No"]}
                    name="taxAdviceApprvd"
                    id="taxAdviceApprvd"
                    chapter={this.props.chapter}
                    reducer={this.props.reducer}
                    isRequired={this.props.form === "Curacao" || this.props.form ==="NL" ? false : true}
                  />
                  {errorsMsg &&
                    errorsMsg?.isSubmitted === true &&
                    errorsMsg?.taxAdviceApprvd &&
                    errorsMsg?.taxAdviceApprvd?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.taxAdviceApprvd}
                      </span>
                    )}
                </div>
              </>
            )}
            <div className="mb-2 col-lg-12">
              <TextBox
                label="Please add comment"
                placeholder="Enter your text"
                name="taxAdviceCmmnt"
                id="taxAdviceCmmnt"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                validationType="Text"
                isRequired={this.props.form ==="UK" || this.props.form === "Curacao" || this.props.form ==="NL" ? false : true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.taxAdviceCmmnt &&
                errorsMsg?.taxAdviceCmmnt?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg.taxAdviceCmmnt}
                  </span>
                )}
            </div>
          </Row>
        )}

        {/* investment */}
        {(this.props.form === "NL" || this.props.form === "UK") && (
          <>
            <div className="html2pdf__page-break"></div>
            <Row className="pt-3 pb-2">
              <div className="mb-3 col-lg-4">
                <CheckBox
                  label="Is an investment memorandum available describing the purpose of the investment?"
                  options={["Yes", "No"]}
                  name="isInvstmntMemorandum"
                  chapter={
                    checkIsCommon(this.props.form) ? null : this.props.chapter
                  }
                  reducer={
                    checkIsCommon(this.props.form)
                      ? "commonFields"
                      : this.props.externalReducer
                  }
                  isCommon={checkIsCommon(this.props.form)}
                  bothDisabled={true}
                />
              </div>
              {isInvstmntMemorandum === "Yes" && (
                <>
                  <div className="mb-3 col-lg-4">
                    <DownloadFile
                      label="please include the investment memorandum"
                      value={invstmntMemorandum}
                    />
                  </div>
                  <div className="mb-2 col-lg-6 col-xl-4">
                    <CheckBox
                      label="Is approved"
                      options={["Yes", "No"]}
                      name="invstmntMemoApprvd"
                      id="invstmntMemoApprvd"
                      chapter={this.props.chapter}
                      reducer={this.props.reducer}
                      isRequired={isRequired}
                    />
                    {errorsMsg &&
                      errorsMsg?.isSubmitted === true &&
                      errorsMsg?.invstmntMemoApprvd &&
                      errorsMsg?.invstmntMemoApprvd?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errorsMsg.invstmntMemoApprvd}
                        </span>
                      )}
                  </div>
                </>
              )}
            </Row>
          </>
        )}
        <div className="html2pdf__page-break"></div>
        <Row className="pt-3 pb-2">
          <div className="mb-3 col-lg-4">
            {this.props.form !== "NL" && (
              <CheckBox
                label="Advance Pricing Agreements (APA) or Advance Tax Rulings (ATR)"
                options={["Yes", "No"]}
                name="isAPA"
                chapter={this.props.chapter}
                objBaseName="documentsObjComp"
                reducer={this.props.externalReducer}
                bothDisabled={true}
              />
            )}
            {documentsObjComp.isAPA && this.props.form === "NL" && (
              <CheckBox
                label="Advance Pricing Agreements (APA) or Advance Tax Rulings (ATR)"
                options={["Yes", "No"]}
                name="isAPA"
                chapter={this.props.chapter}
                objBaseName="documentsObjComp"
                reducer={this.props.externalReducer}
                bothDisabled={true}
              />
            )}
            {!documentsObjComp.isAPA && this.props.form === "NL" && (
              <CheckBox
                label="Advance Pricing Agreements (APA) or Advance Tax Rulings (ATR)"
                options={["Yes", "No"]}
                name="isAPA"
                chapter={this.props.chapter}
                reducer={this.props.reducer}

              />
            )}
          </div>
          <div className="mb-3 col-lg-4">
            {this.props.form !== "NL" && (
              <CheckBox
                label={
                  this.props.form === "Malta"
                    ? "Tax result for the Target Company is not contrary to the intentions of law and regulation"
                    : "Tax result for the Object Company is not contrary to the intentions of law and regulation"
                }
                options={["Yes", "No"]}
                name="objCompTaxResult"
                chapter={this.props.chapter}
                objBaseName="documentsObjComp"
                reducer={this.props.externalReducer}
                bothDisabled={true}
              />
            )}
            {this.props.form === "NL" && (
              <>
                {documentsObjComp.objCompTaxResult && (
                  <CheckBox
                    label="Tax result for the Object Company is not contrary to the intentions of law and regulation"
                    options={["Yes", "No"]}
                    name="objCompTaxResult"
                    chapter={this.props.chapter}
                    objBaseName="documentsObjComp"
                    reducer={this.props.externalReducer}
                    bothDisabled={true}
                  />
                )}
                {!documentsObjComp.objCompTaxResult && (
                  <CheckBox
                    label="Tax result for the Object Company is not contrary to the intentions of law and regulation"
                    options={["Yes", "No"]}
                    name="objCompTaxResult"
                    chapter={this.props.chapter}
                    reducer={this.props.reducer}
                  />
                )}
              </>
            )}
          </div>
          <div className="mb-3 col-lg-4 fieldAlignment">
            {documentsObjComp.explanation && (
              <TextBox
                label="Explanation"
                name="explanation"
                placeholder="enter your text"
                chapter={this.props.chapter}
                objBaseName="documentsObjComp"
                reducer={this.props.externalReducer}
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            )}
            {!documentsObjComp.explanation && this.props.form === "NL" && (
              <TextBox
                label="Explanation"
                name="explanation"
                placeholder="enter your text"
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            )}
          </div>
          <div className="mb-2 col-lg-12">
            <TextBox
              label="Please add comment"
              placeholder="Enter your text"
              name="apaCmmnt"
              id="apaCmmnt"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              validationType="Text"
              isRequired={this.props.form ==="UK" || this.props.form ==="NL" ? false : true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.apaCmmnt &&
              errorsMsg?.apaCmmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.apaCmmnt}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-3 pb-2">
          <div className="mb-3 col-lg-4">
            {this.props.form !== "NL" && (
              <DownloadFile
                label="Other relevant documents"
                value={documentsObjComp.otherDocs}
              />
            )}
            {documentsObjComp.otherDocs.length != 0 && this.props.form === "NL" && (
              <DownloadFile
                label="Other relevant documents"
                value={documentsObjComp.otherDocs}
              />
            )}
            {documentsObjComp.otherDocs.length == 0 && this.props.form === "NL" && (
              <InternalFileUpload
                label="Other relevant documents"
                name="otherDocs"
                id="otherDocs"
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                acceptType={fileAcceptType}
              />
            )}
          </div>

          <div className="mb-2 col-lg-6 col-xl-4">
            <CheckBox
              label="Is approved"
              options={["Yes", "No"]}
              name="otherRelDocsApprvd"
              id="otherRelDocsApprvd"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              isRequired={
                this.props.form === "Curacao" || this.props.form === "Malta" || this.props.form ==="NL"
                  ? false
                  : true
              }
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.otherRelDocsApprvd &&
              errorsMsg?.otherRelDocsApprvd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.otherRelDocsApprvd}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-12">
            <TextBox
              label="Please add comment"
              placeholder="Enter your text"
              name="otherRelDocsCmmnt"
              id="otherRelDocsCmmnt"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              validationType="Text"
              isRequired={
                this.props.form === "UK" || this.props.form === "Curacao" || this.props.form === "Malta" || this.props.form ==="NL"
                  ? false
                  : true
              }
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.otherRelDocsCmmnt &&
              errorsMsg?.otherRelDocsCmmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.otherRelDocsCmmnt}
                </span>
              )}
          </div>

          {this.props.form === "NL" && (
            <>
              <div className="mb-3 col-lg-4">
                {documentsObjComp.isOCSFI && (
                  <CheckBox
                    label="Is the Object Company registered as SFI?"
                    options={["Yes", "No"]}
                    name="isOCSFI"
                    chapter={this.props.chapter}
                    objBaseName="documentsObjComp"
                    reducer={this.props.externalReducer}
                    bothDisabled={true}
                  />
                )}
                {!documentsObjComp.isOCSFI && (
                  <CheckBox
                    label="Is the Object Company registered as SFI?"
                    options={["Yes", "No"]}
                    name="isOCSFI"
                    chapter={this.props.chapter}
                    reducer={this.props.reducer}
                  />
                )}
              </div>
              {documentsObjComp.isOCSFI === "Yes" && (
                <div className="mb-3 col-lg-4">
                  <TextBox
                    label="Registration number"
                    name="registrationNum"
                    chapter={this.props.chapter}
                    objBaseName="documentsObjComp"
                    reducer={this.props.externalReducer}
                    validationType="Text"
                    disabled={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtBox"
                        : "form-control"
                    }
                  />
                </div>
              )}
              {isOCSFI === "Yes" && (
                <div className="mb-3 col-lg-4">
                  <TextBox
                    label="Registration number"
                    name="registrationNum"
                    chapter={this.props.chapter}
                    reducer={this.props.reducer}
                    validationType="Text"
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtBox"
                        : "form-control"
                    }
                  />
                </div>
              )}

              <div className="mb-2 col-lg-12">
                <TextBox
                  label="Please add comment"
                  placeholder="Enter your text"
                  name="objCompSFICmmnt"
                  id="objCompSFICmmnt"
                  reducer={this.props.reducer}
                  chapter={this.props.chapter}
                  validationType="Text"
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.objCompSFICmmnt &&
                  errorsMsg?.objCompSFICmmnt?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.objCompSFICmmnt}
                    </span>
                  )}
              </div>
            </>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { externalReducer, chapter, form } = ownProps;
  if (
    form === "NL" ||
    form === "Curacao" ||
    form === "Malta" ||
    form === "Luxembourg"
  ) {
    return {
      documentsObjComp: state[externalReducer].chapter4.documentsObjComp,
      isTaxMemorandum: state.commonFields["isTaxMemorandum"],
      isInvstmntMemorandum: state.commonFields["isInvstmntMemorandum"],
      taxMemorandum: state.commonFields["taxMemorandum"],
      invstmntMemorandum: state.commonFields["invstmntMemorandum"],
      isOCSFI: state["ClientAcceptance"]["chapter4"]["isOCSFI"],
    };
  } else {
    return {
      documentsObjComp: state[externalReducer][chapter]["documentsObjComp"],
      isTaxMemorandum: state[externalReducer][chapter]["isTaxMemorandum"],
      isInvstmntMemorandum:
        state[externalReducer][chapter]["isInvstmntMemorandum"],
      taxMemorandum: state[externalReducer][chapter]["taxMemorandum"],
      invstmntMemorandum: state[externalReducer][chapter]["invstmntMemorandum"],
      UKInternalCAF:state["UKInternalCAF"][chapter],
    };
  }
};

export default connect(mapStateToProps, null)(DOC);
