import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import DateTimePicker from "../../../../elements/DateTimePicker";
import DownloadDocuSignDoc from "../../../../elements/DownloadDocuSignDoc";
import DownloadFile from "../../../../elements/DownloadFile";
import PhoneInputField from "../../../../elements/PhoneInputField";
import TextBox from "../../../../elements/TextBox";

class UboDetails extends React.Component {
  render() {
    const {
      baseName,
      objBaseName,
      index,
      uboData,
      uboApproval,
      errors,
      isRequired,
    } = this.props;
    const errorsMsg = JSON.parse(errors);

    return (
      <div className="mb-4 col-lg-12">
        <fieldset className="eqheight">
          <legend>UBO {index + 1}</legend>
          <div className="row mb-0">
            <div className="mb-3 col-lg-6">
              <TextBox
                label="Name"
                placeholder="Enter name"
                name="uboXName"
                index={index}
                baseName={baseName}
                reducer="commonFields"
                isCommon={true}
                objBaseName={objBaseName}
                validationType="OnlyLetter"
                disabled={true}
              />
            </div>
            <div className="mb-3 col-lg-6">
              <TextBox
                label="Email"
                placeholder="Enter email"
                name="uboEmail"
                index={index}
                baseName={baseName}
                reducer="commonFields"
                isCommon={true}
                objBaseName={this.props.objBaseName}
                validationType="Email"
                disabled={true}
              />
            </div>
            <div className="mb-3 col-lg-6">
              <PhoneInputField
                label="Phone number"
                name="uboPhoneNum"
                index={index}
                baseName={baseName}
                reducer="commonFields"
                isCommon={true}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                country={this.props.form}
              />
            </div>
            <div className="mb-3 col-lg-6"></div>
            <div className="mb-3 col-lg-6">
              <CheckBox
                label="Is the UBO a PEP?"
                options={["Yes", "No"]}
                name="isUBOPEP"
                index={index}
                baseName={baseName}
                reducer="commonFields"
                isCommon={true}
                objBaseName={this.props.objBaseName}
                bothDisabled={true}
              />
            </div>
            <div className="mb-2 col-lg-6">
              <DownloadFile
                label={"Certified True Copy Passport"}
                name="certifiedPassportUBO"
                value={uboData.certifiedPassportUBO}
              />
            </div>
            <div className="mb-3 col-lg-6">              
               <CheckBox
                 label="Is verified by UIM?"
                options={["Yes", "No"]}
                name="isIdntifiedVerifiedByUIM"
                id={`isIdntifiedVerifiedByUIM[${index}]`}
                chapter="chapter3"
                index={index}
                reducer={this.props.reducer}
                baseName="uboApproval"
                isRequired={isRequired}
                validationType="Text"
                />
              {errorsMsg &&
                errorsMsg.isSubmitted === true &&
                errorsMsg.uboApproval &&
                errorsMsg.uboApproval[index].isIdntifiedVerifiedByUIM?.length >
                  0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg.uboApproval[index].isIdntifiedVerifiedByUIM}
                  </span>
                )}
            </div>
            {uboApproval.isIdntifiedVerifiedByUIM === "No" && (
              <>
                <div className="mb-3 col-lg-6">
                  <div className="editableNotice" id={`dateOfNotary[${index}]`}>
                    Identity verified by{" "}
                    <TextBox
                      className="noticeName"
                      label="Verified by"
                      placeholder="Enter your name"
                      name="verifiedName"
                      id={`verifiedName[${index}]`}
                      chapter="chapter3"
                      index={index}
                      reducer={this.props.reducer}
                      baseName="uboApproval"
                      validationType="Text"
                      hideLable={true}
                    />
                    ,{" "}
                    <TextBox
                      label="Legal entity name"
                      className="noticeName"
                      placeholder="Enter legal entity"
                      name="legalEntityName"
                      id={`legalEntityName[${index}]`}
                      chapter="chapter3"
                      index={index}
                      reducer={this.props.reducer}
                      baseName="uboApproval"
                      validationType="Text"
                      hideLable={true}
                    />{" "}
                    on{" "}
                    <DateTimePicker
                      label="Notary date"
                      className="noticeName"
                      name="dateOfNotary"
                      chapter="chapter3"
                      index={index}
                      reducer={this.props.reducer}
                      baseName="uboApproval"
                      validationType="Text"
                      hideLable={true}
                    />{" "}
                    If the lawyer or notary is located outside the EU such
                    certification needs to be accompanied by an Apostille or be
                    legalized by the embassy or consulate.
                  </div>
                  {errorsMsg &&
                    errorsMsg.isSubmitted === true &&
                    errorsMsg.uboApproval &&
                    (errorsMsg.uboApproval[index].dateOfNotary?.length > 0 ||
                      errorsMsg.uboApproval[index].legalEntityName?.length >
                        0 ||
                      errorsMsg.uboApproval[index].verifiedName?.length >
                        0) && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.uboApproval[index].dateOfNotary?.length > 0
                          ? errorsMsg.uboApproval[index].dateOfNotary
                          : errorsMsg.uboApproval[index].legalEntityName
                              ?.length > 0
                          ? errorsMsg.uboApproval[index].legalEntityName
                          : errorsMsg.uboApproval[index].verifiedName?.length >
                            0
                          ? errorsMsg.uboApproval[index].verifiedName
                          : null}
                      </span>
                    )}
                </div>
              </>
            )}
            <div className="mb-3 col-lg-6">
              {uboApproval.isIdntifiedVerifiedByUIM === "Yes" && (
                <span className="noticeText">
                  {`I confirm that I have seen the original of the passport of
                  ${this.props.uboData.uboXName} on dated
                  ${moment(new Date()).format("DD-MM-YYYY")}`}
                </span>
              )}
            </div>
            <div className="mb-3 col-lg-6">
              <DownloadDocuSignDoc
                label="Download UBO Form"
                uboData={uboData}
              />
            </div>
            <div className="col-lg-12 internalUserApproval">
              <CheckBox
                label="Is approved?"
                options={["Yes", "No"]}
                name="isApproved"
                id={`isApproved[${index}]`}
                chapter="chapter3"
                index={index}
                reducer={this.props.reducer}
                baseName="uboApproval"
                isRequired={isRequired}
                validationType="Text"
              />
              {errorsMsg &&
                errorsMsg.isSubmitted === true &&
                errorsMsg.uboApproval &&
                errorsMsg.uboApproval[index].isApproved?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg.uboApproval[index].isApproved}
                  </span>
                )}
            </div>
          </div>
        </fieldset>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { index, reducer } = ownProps;
  return {
    uboData: state.commonFields.ubo.ubox[index],
    uboApproval: state[reducer].chapter3.uboApproval[index],
  };
};

export default connect(mapStateToProps, null)(UboDetails);
