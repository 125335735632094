import React, { Component } from "react";
import { connect } from "react-redux";
import { setCuracaoInternalCAFDetails } from "../../../../redux/actions/Curacao/CuracaoInternalCAFActions";
import { setCuracaoInternalChapterError } from "../../../../redux/actions/Curacao/CuracaoInternalChapterErrors";
import getSequentialFields from "./getSequentialFields";
class AutoSaving extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  apiCall = () => {
    //api call for chapters data
    let requestData = {};
    let apiCallFlag = false;
    let chapter = this.props.chapter;
    let data = this.props.ClientAcceptance[chapter];

    if (data["dirty"] === true) {
      apiCallFlag = true;
      delete data["dirty"];
    }
    if (apiCallFlag) {
      let user_data = JSON.parse(localStorage.getItem("formData"));
      requestData["id"] = user_data.id;
      requestData["userId"] = user_data.userId;
      if (chapter === "chapter3") {
        requestData[chapter] = data;
        requestData["commonFields"] = this.props.commonFields;
        requestData["cafExternalChap3"] = this.props.clientInformation[chapter];
      } else if (chapter === "chapter4") {
        requestData[chapter] = data;
        requestData["commonFields"] = this.props.commonFields;
        requestData["cafExternalChap4"] = this.props.clientInformation[chapter];
      } else {
        requestData[chapter] = data;
      }

      this.props.setCuracaoInternalCAFDetails(requestData).then(async (res) => {
        if (res.data.portalErrorCode === 200) {
          this.props.ClientAcceptance[this.props.chapter]["dirty"] = false;
          let response = res.data?.result;

          if (
            Object.keys(
              this.props.internalErrors[`formErrors${chapter}`]?.errors
            ).length > 0 &&
            this.props.internalErrors[`formErrors${chapter}`]?.errors
              ?.isSubmitted === true
          ) {
            const newRes = new Promise((resolve) =>
              resolve(getSequentialFields(response, chapter, true))
            );
            const newResponse = await newRes;
            this.props.setCuracaoInternalChapterError({
              form: `formErrors${this.props.chapter}`,
              errors: newResponse?.errorChapter,
            });
          } else {
            const newRes = new Promise((resolve) =>
              resolve(getSequentialFields(response, chapter, false))
            );
            const newResponse = await newRes;
            this.props.setCuracaoInternalChapterError({
              form: `formErrors${this.props.chapter}`,
              errors: newResponse?.errorChapter,
            });
          }
        }
      });
    }
  };

  componentDidMount = () => {
    this.apiCall();
    setInterval(this.apiCall, 5000);
  };

  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    ClientAcceptance: state.CuracaoInternalCAF,
    commonFields: state.commonFields,
    clientInformation: state.CuracaoClientInformation,
    internalErrors: state.CuracaoInternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCuracaoInternalCAFDetails: async (data) =>
      dispatch(await setCuracaoInternalCAFDetails(data)),
    setCuracaoInternalChapterError: (data) =>
      dispatch(setCuracaoInternalChapterError(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AutoSaving);
