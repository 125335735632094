import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import DownloadFile from "../../../../elements/DownloadFile";
import CheckBox from "../../../../elements/CheckBox";
import TextBox from "../../../../elements/TextBox";

class EntityTrustDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { entityTrustDetails } = this.props;

    return (
      <>
        <h4 className="sub-chapter-title mb-4">Entity/Trust Details</h4>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Directors/Partners"
              name="directorsPartners"
              id="directorsPartners"
              placeholder="enter your text"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Shareholders/Members"
              name="shareholdersMembers"
              id="shareholdersMembers"
              placeholder="enter your text"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Registered office"
              name="regOffice"
              id="regOffice"
              placeholder="enter your text"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Trustee name"
              name="trusteeName"
              id="trusteeName"
              placeholder="enter your text"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Trustee address"
              name="trusteeAddress"
              id="trusteeAddress"
              placeholder="enter your text"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
        </Row>
        <div className="html2pdf__page-break"></div>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Trustee's passport or national identity card available?"
              name="istrusPassport"
              options={["Yes", "No"]}
              id="istrusPassport"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {entityTrustDetails.istrusPassport === "Yes" && (
            <div className="mb-3 col-lg-4">
              <DownloadFile
                label="Upload Trustee's Passport or national identity"
                value={entityTrustDetails.trusPassport}
              />
            </div>
          )}
          {entityTrustDetails.istrusPassport === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="truePassportReason"
                id="truePassportReason"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Trustee's proof of address available?"
              name="istrusProofAdd"
              options={["Yes", "No"]}
              id="istrusProofAdd"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {entityTrustDetails.istrusProofAdd === "Yes" && (
            <div className="mb-3 col-lg-4">
              <DownloadFile
                label="Upload Trustee's proof of address"
                value={entityTrustDetails.trusProofAdd}
              />
            </div>
          )}
          {entityTrustDetails.istrusProofAdd === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="trusProofAddReason"
                id="trusProofAddReason"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Trustee's reference letter available?"
              name="istrusRefLett"
              options={["Yes", "No"]}
              id="istrusRefLett"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {entityTrustDetails.istrusRefLett === "Yes" && (
            <div className="mb-3 col-lg-4">
              <DownloadFile
                label="Upload Trustee's reference letter"
                value={entityTrustDetails.trusRefLett}
              />
            </div>
          )}
          {entityTrustDetails.istrusRefLett === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="trusRefLettReason"
                id="trusRefLettReason"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Protector details"
              name="protectorDetails"
              id="protectorDetails"
              placeholder="enter your text"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Protector's passport or national identity card available?"
              name="isprotecPassport"
              options={["Yes", "No"]}
              id="isprotecPassport"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {entityTrustDetails.isprotecPassport === "Yes" && (
            <div className="mb-3 col-lg-4">
              <DownloadFile
                label="Upload Protector's Passport or national identity"
                value={entityTrustDetails.protecPassport}
              />
            </div>
          )}
          {entityTrustDetails.isprotecPassport === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="protecPassportReason"
                id="protecPassportReason"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Protector's proof of address available?"
              name="isprotecProofAdd"
              options={["Yes", "No"]}
              id="isprotecProofAdd"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {entityTrustDetails.isprotecProofAdd === "Yes" && (
            <div className="mb-3 col-lg-4">
              <DownloadFile
                label="Upload Protector's proof of address"
                value={entityTrustDetails.protecProofAdd}
              />
            </div>
          )}
          {entityTrustDetails.isprotecProofAdd === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="protecProofAddReason"
                id="protecProofAddReason"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Protector's reference letter available?"
              name="isprotecRefLett"
              options={["Yes", "No"]}
              id="isprotecRefLett"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {entityTrustDetails.isprotecRefLett === "Yes" && (
            <div className="mb-3 col-lg-4">
              <DownloadFile
                label="Upload Protector's reference letter"
                value={entityTrustDetails.protecRefLett}
              />
            </div>
          )}
          {entityTrustDetails.isprotecRefLett === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="protecRefLettReason"
                id="protecRefLettReason"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Settlor details"
              name="settlorDetails"
              id="settlorDetails"
              placeholder="enter your text"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
        </Row>
        <div className="html2pdf__page-break"></div>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Settlor's passport or national identity card available?"
              name="issettPassport"
              options={["Yes", "No"]}
              id="issettPassport"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {entityTrustDetails.issettPassport === "Yes" && (
            <div className="mb-3 col-lg-4">
              <DownloadFile
                label="Upload Settlor's Passport or national identity"
                value={entityTrustDetails.settPassport}
              />
            </div>
          )}
          {entityTrustDetails.issettPassport === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="settPassportReason"
                id="settPassportReason"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Settlor's proof of address available?"
              name="issettProofAdd"
              options={["Yes", "No"]}
              id="issettProofAdd"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {entityTrustDetails.issettProofAdd === "Yes" && (
            <div className="mb-3 col-lg-4">
              <DownloadFile
                label="Upload Settlor's proof of address"
                value={entityTrustDetails.settProofAdd}
              />
            </div>
          )}
          {entityTrustDetails.issettProofAdd === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="settProofAddReason"
                id="settProofAddReason"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Settlor's reference letter available?"
              name="issettRefLett"
              options={["Yes", "No"]}
              id="issettRefLett"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {entityTrustDetails.issettRefLett === "Yes" && (
            <div className="mb-3 col-lg-4">
              <DownloadFile
                label="Upload Settlor's reference letter"
                value={entityTrustDetails.settRefLett}
              />
            </div>
          )}
          {entityTrustDetails.issettRefLett === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="settRefLettReason"
                id="settRefLettReason"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Beneficiary details"
              name="beneficiaryDetails"
              id="beneficiaryDetails"
              placeholder="enter your text"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Beneficiary's passport or national identity card available?"
              name="isbenPassport"
              options={["Yes", "No"]}
              id="isbenPassport"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {entityTrustDetails.isbenPassport === "Yes" && (
            <div className="mb-3 col-lg-4">
              <DownloadFile
                label="Upload Beneficiary's Passport or national identity"
                value={entityTrustDetails.benPassport}
              />
            </div>
          )}
          {entityTrustDetails.isbenPassport === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="benPassportReason"
                id="benPassportReason"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Beneficiary's proof of address available?"
              name="isbenProofAdd"
              options={["Yes", "No"]}
              id="isbenProofAdd"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {entityTrustDetails.isbenProofAdd === "Yes" && (
            <div className="mb-3 col-lg-4">
              <DownloadFile
                label="Upload Beneficiary's proof of address"
                value={entityTrustDetails.benProofAdd}
              />
            </div>
          )}
          {entityTrustDetails.isbenProofAdd === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="benProofAddReason"
                id="benProofAddReason"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Beneficiary's reference letter available?"
              name="isbenRefLett"
              options={["Yes", "No"]}
              id="isbenRefLett"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {entityTrustDetails.isbenRefLett === "Yes" && (
            <div className="mb-3 col-lg-4">
              <DownloadFile
                label="Upload Beneficiary's reference letter"
                value={entityTrustDetails.benRefLett}
              />
            </div>
          )}
          {entityTrustDetails.isbenRefLett === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="benRefLettReason"
                id="benRefLettReason"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { externalReducer, chapter } = ownProps;

  return {
    entityTrustDetails: state[externalReducer][chapter]["entityTrustDetails"],
  };
};

export default connect(mapStateToProps, null)(EntityTrustDetails);
