import { CustActivityRisks } from "../../InternalUser/Chapter1";
export const Chapter6 = {
  reportWorldCheckExtDir: [],
  findingsWorldCheck: null,
  isRisk: null,
  numCustActivityRisks: 1,
  custActivityRisks: [CustActivityRisks],
  custActvtyRskRtng: null,
  descActnNeedsTkn: null,
  isUnacceptableRisksIdentified: null,
  unacceptableRisksReason: null,
  dirty: false,
};
