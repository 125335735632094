import { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "../app/pages/external/login/login";
import ForgotPassword from "../app/pages/external/forgotpassword/forgotpassword";
import ResetPassword from "../app/pages/external/forgotpassword/resetpassword";
import Authentication from "../app/pages/external/twostepauthentication/authentication";
import Verify from "../app/pages/external/twostepauthentication/verify";
import Home from "../app/pages/shared/home/home";
import ChangePassword from "../app/pages/external/changepassword/changepassword";
import PrivateRoutes from "./privateRoutes";
import InternalHome from "../app/pages/internal/Home/InternalHome";
import InternalUserRoutes from "./InternalUserRoutes";
import DashboardHeader from "../app/common/dashboardHeader";
import userMailAcceptance from "../app/components/user/userMailAcceptance";
import clientMailAcceptance from "../app/components/client/clientMailAcceptance";
import ExternalDashboard from "../app/pages/external/externalDashboard/externalDashboard";
import UBOPEPFormDashboard from "../app/pages/external/OnBoarding/ubo-declaration-uim/UBOPEPFormDashboard";
import UBORoutes from "./UBORoutes";
import ExternalPublicRoutes from "./ExternalPublicRoutes";
import SuccessMsg from "../app/pages/external/OnBoarding/ubo-declaration-uim/SuccessMsg";
import CommonDashboard from "../app/pages/internal/Routing/CommonDashboard";
import InternalPublicRoutes from "./InternalPublicRoutes";
import { connect } from "react-redux";
import { getUserOffice } from "../redux/actions/userActions";
class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = { office: localStorage.getItem("Office"),   
    userType:localStorage.getItem("userType")
    };     
  }
  handleOfficeChange(office) {    
    this.props
      .getUserOffice()
      .then((res) => res.data)
      .then((data) => {
        if (data?.statusCode === 200) {
          localStorage.setItem("userType", data?.result?.name); 
          this.setState({ userType: data?.result?.name});   
        }
      });
      this.setState({ office: office});
  }
  componentDidMount() {
    this.props
      .getUserOffice()
      .then((res) => res.data)
      .then((data) => {
        if (data?.statusCode === 200) {
          localStorage.setItem("userType", data?.result?.name); 
          this.setState({ userType: data?.result?.name});   
        }
      });
  }
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <ExternalPublicRoutes exact path="/" component={Home} />
          <ExternalPublicRoutes path="/login" component={Login} />
          <ExternalPublicRoutes
            path="/authentication"
            component={Authentication}
          />
          <ExternalPublicRoutes path="/verify" component={Verify} />
          <InternalPublicRoutes
            exact
            path="/internal-home"
            component={InternalHome}
          />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/resetpassword" component={ResetPassword} />
          <PrivateRoutes path="/changepassword" component={ChangePassword} />
          <Route
            path="/user-email-acceptance/:token/:status"
            component={userMailAcceptance}
          />
          <Route
            path="/client-email-acceptance/:token/:status"
            component={clientMailAcceptance}
          />
          <InternalUserRoutes
            office={this.state.office}  
            userType={this.state.userType}          
            handleOfficeChange={(e) => this.handleOfficeChange(e)}
            path="/internal-dashboard"
            component={CommonDashboard}
          />
          <PrivateRoutes path="/dashboardHeader" component={DashboardHeader} />
          <PrivateRoutes path="/authentication" component={Authentication} />
          <Route path="/declaration-form-status" component={SuccessMsg} />
          <Route path="/ubo-authentication/:token" component={Authentication} />
          <Route path="/ubo-authentication" component={Authentication} />
          <Route path="/ubo-verify" component={Verify} />
          <PrivateRoutes path="/verify" component={Verify} />
          <PrivateRoutes
            office={this.state.office}           
            handleOfficeChange={(e) => this.handleOfficeChange(e)}
            path="/dashboard"
            component={ExternalDashboard}
          ></PrivateRoutes>
          <UBORoutes path="/declaration-form" component={UBOPEPFormDashboard} />
        </Switch>
      </BrowserRouter>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return { getUserOffice: async () => dispatch(await getUserOffice()) };
};
export default connect(null, mapDispatchToProps)(Routes);
//export default Routes;
