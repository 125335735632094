import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import TextBox from "../../../../elements/TextBox";
import { newClient } from "../constants";
import NumberInput from "../../../../elements/NumberInput";
import Client from "../../../internal/Onboarding/Chapter2/Client";
class AdditionalUbodetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { uboApproval, index, errors } = this.props;
    const errorsMsg = JSON.parse(errors);
    const exsistingClients = [];
    if (uboApproval.isClientNew=="No" && this.props.form =="UK") {     
      for (let i = 0; i < uboApproval.noNewClients; i++) {
        exsistingClients.push(
          <Client
            index={i}
            form="UK"
            baseName="newClients"
            objBaseName= "uboApproval"
            indexObjBaseName={index}
            reducer={this.props.reducer}
            chapter={this.props.chapter}            
          />
         
        );
      }
    }
    return (
      <>
        <Row className="mt-2 border-top pt-3">
          <div className="mb-2 col-lg-4">
            <CheckBox
              label="Signing client Agreement?"
              options={["Yes", "No"]}
              name="isSigningClient"
              id={`isSigningClient[${index}]`}
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={this.props.index}
              baseName={this.props.baseName}
              isRequired={true}
            />
            {errorsMsg &&
              errorsMsg.isSubmitted === true &&
              errorsMsg?.uboApproval &&
              errorsMsg?.uboApproval[index] &&
              errorsMsg?.uboApproval[index]?.isSigningClient &&
              errorsMsg?.uboApproval[index]?.isSigningClient?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg?.uboApproval[index]?.isSigningClient}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-8">
            <CheckBox
              label="Is client new?"
              options={["Yes", "No"]}
              name="isClientNew"
              id={`isClientNew[${index}]`}
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={this.props.index}
              baseName={this.props.baseName}
              isRequired={true}
            />
            {errorsMsg &&
              errorsMsg.isSubmitted === true &&
              errorsMsg?.uboApproval &&
              errorsMsg?.uboApproval[index] &&
              errorsMsg?.uboApproval[index]?.isClientNew &&
              errorsMsg?.uboApproval[index]?.isClientNew?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg?.uboApproval[index]?.isClientNew}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4">
                </div>
          {uboApproval.isClientNew === "No" && (
          <div className="mb-3 col-lg-8">
             <NumberInput
                      label="How many clients?"
                      name="noNewClients"
                      masterObj={["uboApproval"]}
                      masterIndex={index}
                      referral={["newClients"]}
                      pushedObject={[newClient]}
                      maxLimit={100}
                      id={`noNewClients[${index}]`}
                      index={index}
                      chapter={this.props.chapter}
                      baseName="uboApproval"
                      reducer={this.props.reducer}
                    />
                       {exsistingClients}

          </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Politically Exposed Person (PEP)?"
              options={["Yes", "No"]}
              name="isPep"
              id={`isPep[${index}]`}
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={this.props.index}
              baseName={this.props.baseName}
              isRequired={true}
            />
            {errorsMsg &&
              errorsMsg.isSubmitted === true &&
              errorsMsg?.uboApproval &&
              errorsMsg?.uboApproval[index] &&
              errorsMsg?.uboApproval[index]?.isPep &&
              errorsMsg?.uboApproval[index]?.isPep?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg?.uboApproval[index]?.isPep}
                </span>
              )}
          </div>
          {uboApproval.isPep === "Yes" && (
            <div className="mb-3 col-lg-8">
              <TextBox
                label="Provide additional details"
                placeholder="Provide additional details"
                name="pepDetail"
                id={`pepDetail[${index}]`}
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                index={this.props.index}
                baseName={this.props.baseName}
                validationType="Text"
                isRequired={true}
              />
              {errorsMsg &&
                errorsMsg.isSubmitted === true &&
                errorsMsg?.uboApproval &&
                errorsMsg?.uboApproval[index] &&
                errorsMsg?.uboApproval[index]?.pepDetail &&
                errorsMsg?.uboApproval[index]?.pepDetail?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg?.uboApproval[index]?.pepDetail}
                  </span>
                )}
            </div>
          )}
        </Row>
        <div className="html2pdf__page-break"></div>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Associated with such persons?"
              options={["Yes", "No"]}
              name="isAsctPerson"
              id={`isAsctPerson[${index}]`}
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={this.props.index}
              baseName={this.props.baseName}
              isRequired={true}
            />
            {errorsMsg &&
              errorsMsg.isSubmitted === true &&
              errorsMsg?.uboApproval &&
              errorsMsg?.uboApproval[index] &&
              errorsMsg?.uboApproval[index]?.isAsctPerson &&
              errorsMsg?.uboApproval[index]?.isAsctPerson?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg?.uboApproval[index]?.isAsctPerson}
                </span>
              )}
          </div>
          {uboApproval.isAsctPerson === "Yes" && (
            <div className="mb-3 col-lg-8">
              <TextBox
                label="Provide additional details"
                placeholder="Provide additional details"
                name="asctPersonDetail"
                id={`asctPersonDetail[${index}]`}
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                index={this.props.index}
                baseName={this.props.baseName}
                validationType="Text"
                isRequired={true}
              />
              {errorsMsg &&
                errorsMsg.isSubmitted === true &&
                errorsMsg?.uboApproval &&
                errorsMsg?.uboApproval[index] &&
                errorsMsg?.uboApproval[index]?.asctPersonDetail &&
                errorsMsg?.uboApproval[index]?.asctPersonDetail?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg?.uboApproval[index]?.asctPersonDetail}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="High profile person?"
              options={["Yes", "No"]}
              name="isHighProfilePerson"
              id={`isHighProfilePerson[${index}]`}
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={this.props.index}
              baseName={this.props.baseName}
              isRequired={true}
            />{" "}
            {errorsMsg &&
              errorsMsg.isSubmitted === true &&
              errorsMsg?.uboApproval &&
              errorsMsg?.uboApproval[index] &&
              errorsMsg?.uboApproval[index]?.isHighProfilePerson &&
              errorsMsg?.uboApproval[index]?.isHighProfilePerson?.length >
                0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg?.uboApproval[index]?.isHighProfilePerson}
                </span>
              )}
          </div>
          {uboApproval.isHighProfilePerson === "Yes" && (
            <div className="mb-3 col-lg-8">
              <TextBox
                label="Provide additional details"
                placeholder="Provide additional details"
                name="highProfDetail"
                id={`highProfDetail[${index}]`}
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                index={this.props.index}
                baseName={this.props.baseName}
                validationType="Text"
                isRequired={true}
              />
              {errorsMsg &&
                errorsMsg.isSubmitted === true &&
                errorsMsg?.uboApproval &&
                errorsMsg?.uboApproval[index] &&
                errorsMsg?.uboApproval[index]?.highProfDetail &&
                errorsMsg?.uboApproval[index]?.highProfDetail?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg?.uboApproval[index]?.highProfDetail}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Hign net worth?"
              options={["Yes", "No"]}
              name="isHighNetWorth"
              id={`isHighNetWorth[${index}]`}
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={this.props.index}
              baseName={this.props.baseName}
              isRequired={true}
            />
            {errorsMsg &&
              errorsMsg.isSubmitted === true &&
              errorsMsg?.uboApproval &&
              errorsMsg?.uboApproval[index] &&
              errorsMsg?.uboApproval[index]?.isHighNetWorth &&
              errorsMsg?.uboApproval[index]?.isHighNetWorth?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg?.uboApproval[index]?.isHighNetWorth}
                </span>
              )}
          </div>
          {uboApproval.isHighNetWorth === "Yes" && (
            <div className="mb-3 col-lg-8">
              <TextBox
                label="Provide additional details"
                placeholder="Provide additional details"
                name="highNetDetail"
                id={`highNetDetail[${index}]`}
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                index={this.props.index}
                baseName={this.props.baseName}
                validationType="Text"
                isRequired={true}
              />
              {errorsMsg &&
                errorsMsg.isSubmitted === true &&
                errorsMsg?.uboApproval &&
                errorsMsg?.uboApproval[index] &&
                errorsMsg?.uboApproval[index]?.highNetDetail &&
                errorsMsg?.uboApproval[index]?.highNetDetail?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg?.uboApproval[index]?.highNetDetail}
                  </span>
                )}
            </div>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { index, reducer, chapter, baseName } = ownProps;
  return {
    uboApproval: state[reducer][chapter][baseName][index],
  };
};

export default connect(mapStateToProps, null)(AdditionalUbodetails);
