import React, { Component } from "react";
import { Link } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";

class UKExternalSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleChange(e) {
    this.props.handleOfficeChange(e);
  }

  render() {
    const url = window.location.pathname;
    return (
      <>
        <ul>
          <li>
            <Link to="#">
              <i className="fa fa-id-badge mr-3"></i> Client Information
            </Link>
          </li>
        </ul>
        <p className="backtoformLink">
          {window.location.href.indexOf("client-information-list") > -1 ? (
            <> </>
          ) : (
            <Link
              to="/client-information-list"
              onClick={(e) => this.handleChange(e)}

              // onClick={() => localStorage.removeItem("formId")}
            >
              <i className="fa fa-angle-left mr-1"></i> Back to Client Forms
              List
            </Link>
          )}
        </p>
      </>
    );
  }
}

export default UKExternalSidebar;
