export const Entities = {
  chamberExtract: null,
  article: null,
  shareholderRegister: null,
  finStat: null,
};

export const Chapter1 = {
  structureChart: [],
  isCompIncorporated: "",
  noOfEntities: 1,
  entities: [Entities],
  dirty: false,
};
