import { commonFields } from "../../app/JSONs/CommonFields/commonFields";
import { uuid } from "../../app/JSONs/CommonFields/GenerateGUID";

const initialState = commonFields;
const CommonFieldsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "UPDATE_COMMON_FIELDS":
      const { baseName, index, name, value, objBaseName, nestedObjBaseName } =
        action.data;
      let array, obj;
      if (!objBaseName && !baseName) {
        return {
          ...state,
          [name]: value,
          dirty: true,
        };
      } else if (objBaseName && nestedObjBaseName) {
        return {
          ...state,
          [objBaseName]: {
            ...state[objBaseName],
            [nestedObjBaseName]: {
              ...state[objBaseName][nestedObjBaseName],
              [name]: value,
            },
          },
          dirty: true,
        };
      } else if (objBaseName && !baseName) {
        return {
          ...state,
          [objBaseName]: {
            ...state[objBaseName],
            [name]: value,
          },
          dirty: true,
        };
      } else if (objBaseName && baseName) {
        array = state[objBaseName][baseName];

        obj = {
          ...array[index],
          [name]: value,
        };
        array[index] = obj;
        return {
          ...state,
          [objBaseName]: {
            ...state[objBaseName],
            [baseName]: array,
          },
          dirty: true,
        };
      } else {
        array = state[baseName];
        obj = {
          ...array[index],
          [name]: value,
        };
        array[index] = obj;
        return {
          ...state,
          [baseName]: array,
          dirty: true,
        };
      }

    case "UPDATE_NEW_COMMON_DATA":
      return action.data;

    case "GET_COMMON_FIELDS_SUCCESS":
      let response = action.data.result;
      if (response) {
        response["dirty"] = false;
      } else {
        response = commonFields;
      }

      return response;

    case "GET_CLIENT_ACCEPTANCE_SUCCESS":
    case "GET_CURACAO_INTERNAL_CAF_DETAILS_SUCCESS":
    case "GET_MALTA_INTERNAL_CAF_DETAILS_SUCCESS":
    case "GET_LUXEMBOURG_INTERNAL_CAF_DETAILS_SUCCESS":
      let res = action.data.result;
      if (res) {
        let cFields = res.commonFields;
        return {
          ...state,
          ...cFields,
        };
      } else {
        return state;
      }

    case "CREATE_COMMON_FIELDS_OBJECT":
      const { maxIndex, referral, pushedObject, nestedObjName, generateId } =
        action.data;
      let arrayData;
      if (nestedObjName) {
        arrayData = state[nestedObjName][referral];
      } else {
        arrayData = state[referral];
      }
      if (arrayData !== null && arrayData.length < maxIndex) {
        let diff = maxIndex - arrayData.length;
        for (let i = 0; i < diff; i++) {
          if (generateId) {
            let pObj = pushedObject;
            pObj = {
              ...pObj,
              [generateId]: uuid(),
            };
            arrayData.push(pObj);
          } else {
            arrayData.push(pushedObject);
          }
        }
      }

      return {
        ...state,
        [referral]: arrayData,
      };

    case "REMOVE_COMMON_FIELDS_OBJECT":
      const { remMaxIndex, remReferral, remNestedObjName } = action.data;

      let remArray;
      if (remNestedObjName) {
        remArray = state[remNestedObjName][remReferral];
      } else {
        remArray = state[remReferral];
      }

      if (remArray !== null && remArray.length < remMaxIndex) {
        let diff = remMaxIndex - remArray.length;
        for (let i = 0; i < diff; i++) {
          remArray.splice(-1);
        }
      }

      return {
        ...state,
        [remReferral]: remArray,
      };

    case "SET_NEW_COMMON_FROM_UBO_PEP":
      return action.data.data;

    case "NEW_COMMON_FIELD_STATE":
      if (action.data.state) return action.data.state;
      else return state;

    default:
      return state;
  }
};

export default CommonFieldsReducer;
