import React, { Component } from "react";
import { Row } from "react-bootstrap";
import DateTimePicker from "../../../../elements/DateTimePicker";
import TextBox from "../../../../elements/TextBox";

class SignatureComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <h2 className="chapter-title mt-4">
          Signature of The UBO/Representative/director
        </h2>
        <Row className="pt-3 pb-2">
          <div className="mb-2 col-lg-4">
            <TextBox
              label="Full-name"
              placeholder="enter name"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              defaultValue={this.props.name}
              id="name"
              name="name"
              disabled
              noChapter={this.props.from === "uboDeclarationPep" ? true : false}
              validationType="Text"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.name?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.name}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4" id="date">
            <DateTimePicker
              label="Date"
              placeholder="select date"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              name="date"
              disabled={this.props.disabled}
              noChapter={this.props.from === "uboDeclarationPep" ? true : false}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.date?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.date}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-4">
            <TextBox
              label="Place"
              placeholder="enter place"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              noChapter={this.props.from === "uboDeclarationPep" ? true : false}
              id="place"
              name="place"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.place?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.place}
                </span>
              )}
          </div>
          <div className="mb-2 pt-3 col-lg-12">
            <div
              style={{
                height: "160px",
                backgroundColor: "#f5f5f5",
                padding: "15px",
                borderRadius: "5px",
                border: "1px solid #ddd",
              }}
            >
              <label>Add your signature here</label>
            </div>
            {/* <textarea type="text" className="form-control" autoComplete="off" /> */}
          </div>
        </Row>
      </>
    );
  }
}

export default SignatureComponent;
