export function uuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  var currentdate = new Date();

  var month = ("0" + (currentdate.getUTCMonth() + 1)).slice(-2);
  var date = ("0" + currentdate.getUTCDate()).slice(-2);
  var year = currentdate.getUTCFullYear().toString().substr(-2);
  var hour = ("0" + currentdate.getUTCHours()).slice(-2);
  var minute = ("0" + currentdate.getUTCMinutes()).slice(-2);
  var second = ("0" + currentdate.getUTCSeconds()).slice(-2);
  var datetime = date + month + year + hour + minute + second;

  return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + datetime;
}
