import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import DownloadFile from "../../../../elements/DownloadFile";

const objBaseName = "documentApproval";
class Documentation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { documentation, errors, isRequired } = this.props;
    const errorMsg = JSON.parse(errors);

    return (
      <>
        <div className="html2pdf__page-break"></div>
        <h4 className="sub-chapter-title mb-4">Documentation</h4>
        <Row className="pt-0 pb-0">
          <div className="mb-3 col-lg-4">
            <DownloadFile
              label="Certified copy of passport details / Identity card"
              value={documentation.certifiedCopyIdentity}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Is approved?"
              options={["Yes", "No"]}
              name="isCrtfdPassportCpy"
              id="isCrtfdPassportCpy"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={objBaseName}
              isRequired={isRequired}
            />
            {errorMsg &&
              errorMsg?.isCrtfdPassportCpy &&
              errorMsg?.isCrtfdPassportCpy?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.isCrtfdPassportCpy}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-0 pb-0">
          <div className="mb-3 col-lg-4">
            <DownloadFile
              label="Upload bankers' reference"
              value={documentation.bankersRef}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Is approved?"
              options={["Yes", "No"]}
              name="isBankersRef"
              id="isBankersRef"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={isRequired}
              objBaseName={objBaseName}
            />
            {errorMsg &&
              errorMsg?.isBankersRef &&
              errorMsg?.isBankersRef?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.isBankersRef}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-0 pb-0">
          <div className="mb-3 col-lg-4">
            <DownloadFile
              label="Upload professional reference"
              value={documentation.profRef}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Is approved?"
              options={["Yes", "No"]}
              name="isProfRef"
              id="isProfRef"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={isRequired}
              objBaseName={objBaseName}
            />{" "}
            {errorMsg &&
              errorMsg?.isProfRef &&
              errorMsg?.isProfRef?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.isProfRef}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-0 pb-0">
          <div className="mb-3 col-lg-4">
            <DownloadFile
              label="Upload declaration of good standing"
              value={documentation.declrGoodStndng}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Is approved?"
              options={["Yes", "No"]}
              name="isGoodStanding"
              isRequired={isRequired}
              id="isGoodStanding"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={objBaseName}
            />
            {errorMsg &&
              errorMsg?.isGoodStanding &&
              errorMsg?.isGoodStanding?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.isGoodStanding}
                </span>
              )}
          </div>
        </Row>
        <div className="html2pdf__page-break"></div>
        <h5>In the case of corporate shareholders</h5>
        <Row className="pt-3 pb-2">
          <div className="mb-3 col-lg-4">
            <DownloadFile
              label="Formal document confirming identity of shareholders and directors"
              value={documentation.formDocConfmIdntyShrHold}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Is approved?"
              options={["Yes", "No"]}
              name="isIdentityShareholder"
              id="isIdentityShareholder"
              isRequired={isRequired}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={objBaseName}
            />
            {errorMsg &&
              errorMsg?.isIdentityShareholder &&
              errorMsg?.isIdentityShareholder?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.isIdentityShareholder}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-0 pb-2">
          <div className="mb-3 col-lg-4">
            <DownloadFile
              label="A recent good standing certificate"
              value={documentation.recGoodStndngCrt}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Is approved?"
              options={["Yes", "No"]}
              name="isRecGoodStanding"
              id="isRecGoodStanding"
              isRequired={isRequired}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={objBaseName}
            />
            {errorMsg &&
              errorMsg?.isRecGoodStanding &&
              errorMsg?.isRecGoodStanding?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.isRecGoodStanding}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-0 pb-2">
          <div className="mb-3 col-lg-4">
            <DownloadFile
              label="Copy of the incorporation document"
              value={documentation.cpyIncorDoc}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Is approved?"
              options={["Yes", "No"]}
              name="isCpyIncorpDoc"
              id="isCpyIncorpDoc"
              isRequired={isRequired}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={objBaseName}
            />
            {errorMsg &&
              errorMsg?.isCpyIncorpDoc &&
              errorMsg?.isCpyIncorpDoc?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.isCpyIncorpDoc}
                </span>
              )}
          </div>
        </Row>
        <div className="html2pdf__page-break"></div>
        <Row className="pt-0 pb-2">
          <div className="mb-3 col-lg-4">
            <DownloadFile
              label="Extract from the relative Companies’ House reflecting the date of registration and registered address of the entity"
              value={documentation.relCompaniesHse}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Is approved?"
              options={["Yes", "No"]}
              name="isRelCompHouse"
              isRequired={isRequired}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={objBaseName}
            />
            {errorMsg &&
              errorMsg?.isRelCompHouse &&
              errorMsg?.isRelCompHouse?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.isRelCompHouse}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-0 pb-2">
          <div className="mb-3 col-lg-4">
            <DownloadFile
              label="Latest financial statements"
              value={documentation.ltstFinStmnts}
            />
          </div>
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Is approved?"
              options={["Yes", "No"]}
              name="isLatestFinStmnts"
              id="isLatestFinStmnts"
              isRequired={isRequired}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={objBaseName}
            />
            {errorMsg &&
              errorMsg?.isLatestFinStmnts &&
              errorMsg?.isLatestFinStmnts?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.isLatestFinStmnts}
                </span>
              )}
          </div>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { externalReducer, chapter } = ownProps;

  return {
    documentation: state[externalReducer][chapter]["documentation"],
  };
};

export default connect(mapStateToProps, null)(Documentation);
