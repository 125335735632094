import { AdditionDocs, CustActivityRisks } from "./Chapter1";
export const IntermediaryObj = {
  isClientReal: "",
  clientRepNationality: null,
  clientRepName: null,
  clientRepPassport: [],
  activeInCountry: [],
  legalEntityName: null,
  cocExtract: [],
  isPOA: "",
  poa: [],
  noPOAReason: null,
  isUBOIdentifyVerify: "",
  uboIdentityPassport: [],
  noUBOIdentifyVerifyReason: null,
  intrAmlPoliciesProcs:null
};
export const Chapter2 = {
  isClientRepInvolved:null,
  clientRepNumInter:1,
  intermediaryDetails: [IntermediaryObj],
  //--------------------------------------
  noUBOInfoByUIM: null,
  isPascalReport: null,
  noPascalReportReason: null,
  pascalReportUpload: [],
  isMediaCoverageOnStruct: null,
  isAddDocs: null,
  numAddDocs: 1,
  addDocs: [AdditionDocs],
  isRisk: null,
  numCustActivityRisks: 1,
  custActivityRisks: [CustActivityRisks],
  custActvtyRskRtng: null,
  descActnNeedsTkn: null,
  risksCncrnngIntermediary: null,
  isSrvcPrvdrTermntdSrvcsClr: null,
  isSrvcPrvdrReputable: null,
  isReceiveNecessaryInfo: null,
  isIncreasedIntegrityRisk: null,
  increasedIntegrityRiskReason: null,
  dirty: false,
};
