import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import TextBox from "../../../../../elements/TextBox";
import CheckBox from "../../../../../elements/CheckBox";

class UboUpcQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      isUBOEntrstdPEP,
      isUBOCnctedPEP,
      isUBOAssociatePEP,
      isUBOForceCloseFinAcc,
      isUPCForceCloseFinAcc,
      isUBOActingOnSelfBehalf,
      isClientTransfrd,
      isUBOInvlvdDoubtfulFinancng,
      isOthrCrmstncsNegImpct,
    } = this.props;
    return (
      <>
        <Row className="mt-3 mb-3">
          <div className="mb-3 col-lg-8">
            <CheckBox
              label={`Is the UBO${
                this.props.form === "Malta" ? "" : "/Director"
              } an individual who is or has been entrusted with a (prominent) public function (PEP)?`}
              options={["Yes", "No"]}
              id="isUBOEntrstdPEP"
              name="isUBOEntrstdPEP"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.isUBOEntrstdPEP?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.isUBOEntrstdPEP}
                </span>
              )}
          </div>

          {isUBOEntrstdPEP === "Yes" && (
            <div className="col-lg-4">
              <div className="noticeText">
                Please contact your legal manager at United to provide the
                ‘PEP-declaration'
              </div>
            </div>
          )}
        </Row>
        {/* <div className="html2pdf__page-break"></div> */}
        <Row className="mt-3 mb-3">
          <div className="mb-3 col-lg-8">
            <CheckBox
              label={`Is the UBO${
                this.props.form === "Malta" ? "" : "/Director"
              } a family member of a PEP, married to a PEP or closely connected to a PEP, either socially or professionally?`}
              options={["Yes", "No"]}
              id="isUBOCnctedPEP"
              name="isUBOCnctedPEP"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.isUBOCnctedPEP?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.isUBOCnctedPEP}
                </span>
              )}
          </div>

          {isUBOCnctedPEP === "Yes" && (
            <div className="col-lg-4">
              <div className="noticeText">
                Please contact your legal manager at United to provide the
                ‘PEP-declaration'
              </div>
            </div>
          )}
        </Row>
        <Row className="mt-3 mb-3">
          <div className="mb-3 col-lg-8">
            <CheckBox
              label={`Is the UBO${
                this.props.form === "Malta" ? "" : "/Director"
              } a close associate of PEP (Joint ultimate beneficiary owner of legal entity, Close business relationship or jointly owner of a legal structure for which a PEP is UBO?`}
              options={["Yes", "No"]}
              id="isUBOAssociatePEP"
              name="isUBOAssociatePEP"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.isUBOAssociatePEP?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.isUBOAssociatePEP}
                </span>
              )}
          </div>

          {isUBOAssociatePEP === "Yes" && (
            <div className="col-lg-4">
              <div className="noticeText">
                Please contact your legal manager at United to provide the
                ‘PEP-declaration'
              </div>
            </div>
          )}
        </Row>
        <Row className="mt-3 mb-3">
          <div className="mb-3 col-lg-8">
            <CheckBox
              label={`Has the UBO${
                this.props.form === "Malta" ? "" : "/Director"
              } ever been rejected or forced to close accounts with any bank or financial institution?`}
              options={["Yes", "No"]}
              id="isUBOForceCloseFinAcc"
              name="isUBOForceCloseFinAcc"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.isUBOForceCloseFinAcc?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.isUBOForceCloseFinAcc}
                </span>
              )}
          </div>

          {isUBOForceCloseFinAcc === "Yes" && (
            <div className="mb-2 col-lg-4">
              <TextBox
                label="Please specify a reason"
                placeholder="enter reason"
                reducer={this.props.reducer}
                id="uboForceCloseFinAcc"
                name="uboForceCloseFinAcc"
                chapter={this.props.chapter}
                validationType="Text"
              />
              {this.props.errorMsg !== null &&
                Object.entries(this.props.errorMsg).length !== 0 &&
                this.props.errorMsg?.partA?.uboForceCloseFinAcc?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {this.props.errorMsg?.partA?.uboForceCloseFinAcc}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="mt-3 mb-3">
          <div className="mb-3 col-lg-8">
            <CheckBox
              label="Has the Ultimate Parent Company ever been rejected or forced to close accounts with any bank or financial institution?"
              options={["Yes", "No"]}
              id="isUPCForceCloseFinAcc"
              name="isUPCForceCloseFinAcc"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.isUPCForceCloseFinAcc?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.isUPCForceCloseFinAcc}
                </span>
              )}
          </div>

          {isUPCForceCloseFinAcc === "Yes" && (
            <div className="mb-2 col-lg-4">
              <TextBox
                label="Please specify a reason"
                placeholder="enter reason"
                reducer={this.props.reducer}
                id="upcForceCloseFinAcc"
                name="upcForceCloseFinAcc"
                chapter={this.props.chapter}
                validationType="Text"
              />
              {this.props.errorMsg !== null &&
                Object.entries(this.props.errorMsg).length !== 0 &&
                this.props.errorMsg?.partA?.upcForceCloseFinAcc?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {this.props.errorMsg?.partA?.upcForceCloseFinAcc}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="mt-3 mb-3">
          <div className="mb-3 col-lg-8">
            <CheckBox
              label="The UBO acts on his/her own behalf and is not acting in a fiduciary capacity or on behalf of a third parties."
              options={["Yes", "No"]}
              id="isUBOActingOnSelfBehalf"
              name="isUBOActingOnSelfBehalf"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.isUBOActingOnSelfBehalf?.length >
                0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.isUBOActingOnSelfBehalf}
                </span>
              )}
          </div>

          {isUBOActingOnSelfBehalf === "Yes" && (
            <div className="mb-2 col-lg-4">
              <TextBox
                label="Please specify a reason"
                placeholder="enter reason"
                reducer={this.props.reducer}
                id="uboNotActingOnSelfBehalf"
                name="uboNotActingOnSelfBehalf"
                chapter={this.props.chapter}
                validationType="Text"
              />
              {this.props.errorMsg !== null &&
                Object.entries(this.props.errorMsg).length !== 0 &&
                this.props.errorMsg?.partA?.uboNotActingOnSelfBehalf?.length >
                  0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {this.props.errorMsg?.partA?.uboNotActingOnSelfBehalf}
                  </span>
                )}
            </div>
          )}
        </Row>
        <div className="html2pdf__page-break"></div>
        <Row className="mt-3 mb-3">
          <div className="mb-2 col-lg-4">
            <CheckBox
              label="Was the client transferred from another service provider (trust office)?"
              options={["Yes", "No"]}
              name="isClientTransfrd"
              reducer="commonFields"
              bothDisabled={true}
              isCommon={true}
            />
            {/* {this.props.errorMsg !==null && Object.entries(this.props.errorMsg).length !== 0 && this.props.errorMsg?.partA?.isClientTransfrd?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>{this.props.errorMsg?.partA?.isClientTransfrd}</span>
            )} */}
          </div>

          {isClientTransfrd === "Yes" && (
            <>
              <div className="mb-2 col-lg-4">
                <TextBox
                  label="Please specify which service provider"
                  placeholder="enter name of service provider"
                  reducer="commonFields"
                  isCommon={true}
                  name="srvcPrvdrName"
                  validationType="Text"
                  disabled={true}
                />
                {/* {this.props.errorMsg !==null && Object.entries(this.props.errorMsg).length !== 0 && this.props.errorMsg?.partA?.srvcPrvdrName?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>{this.props.errorMsg?.partA?.srvcPrvdrName}</span>
            )} */}
              </div>

              <div className="mb-2 col-lg-4">
                <TextBox
                  label="Why they wants to change the service provider?"
                  placeholder="enter reason"
                  reducer="commonFields"
                  isCommon={true}
                  name="reasonClientTransfrd"
                  validationType="Text"
                  disabled={true}
                />
                {/* {this.props.errorMsg !==null && Object.entries(this.props.errorMsg).length !== 0 && this.props.errorMsg?.partA?.reasonClientTransfrd?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>{this.props.errorMsg?.partA?.reasonClientTransfrd}</span>
            )} */}
              </div>
            </>
          )}
        </Row>
        <Row className="mt-3 mb-3">
          <div className="mb-3 col-lg-8">
            <CheckBox
              label="Has the UBO ever been involved in bankruptcy, money laundering/ terrorist financing or in similar proceedings or any other matters of a doubtful nature whatsoever?"
              options={["Yes", "No"]}
              id="isUBOInvlvdDoubtfulFinancng"
              name="isUBOInvlvdDoubtfulFinancng"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.isUBOInvlvdDoubtfulFinancng?.length >
                0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.isUBOInvlvdDoubtfulFinancng}
                </span>
              )}
          </div>

          {isUBOInvlvdDoubtfulFinancng === "Yes" && (
            <>
              <div className="mb-2 col-lg-4">
                <TextBox
                  label="Please specify a reason"
                  placeholder="enter reason"
                  reducer={this.props.reducer}
                  id="uboInvlvdDbtfulFinancng"
                  name="uboInvlvdDbtfulFinancng"
                  chapter={this.props.chapter}
                  validationType="Text"
                />
                {this.props.errorMsg !== null &&
                  Object.entries(this.props.errorMsg).length !== 0 &&
                  this.props.errorMsg?.partA?.uboInvlvdDbtfulFinancng?.length >
                    0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {this.props.errorMsg?.partA?.uboInvlvdDbtfulFinancng}
                    </span>
                  )}
              </div>
            </>
          )}
        </Row>
        <Row className="mt-3 mb-3">
          <div className="mb-3 col-lg-8">
            <CheckBox
              label="Are there other circumstances which could have a negative impact on the integrity/ reputation risk of the UBO and/or United?"
              options={["Yes", "No"]}
              id="isOthrCrmstncsNegImpct"
              name="isOthrCrmstncsNegImpct"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.isOthrCrmstncsNegImpct?.length >
                0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.isOthrCrmstncsNegImpct}
                </span>
              )}
          </div>

          {isOthrCrmstncsNegImpct === "Yes" && (
            <>
              <div className="mb-2 col-lg-4">
                <TextBox
                  label="Please specify a reason"
                  placeholder="enter reason"
                  reducer={this.props.reducer}
                  id="othrCrmstncsNegImpct"
                  name="othrCrmstncsNegImpct"
                  chapter={this.props.chapter}
                  validationType="Text"
                />
                {this.props.errorMsg !== null &&
                  Object.entries(this.props.errorMsg).length !== 0 &&
                  this.props.errorMsg?.partA?.othrCrmstncsNegImpct?.length >
                    0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {this.props.errorMsg?.partA?.othrCrmstncsNegImpct}
                    </span>
                  )}
              </div>
            </>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isUBOEntrstdPEP: state.UBODeclarationUIM.partA["isUBOEntrstdPEP"],
    isUBOCnctedPEP: state.UBODeclarationUIM.partA["isUBOCnctedPEP"],
    isUBOAssociatePEP: state.UBODeclarationUIM.partA["isUBOAssociatePEP"],
    isUPCForceCloseFinAcc:
      state.UBODeclarationUIM.partA["isUPCForceCloseFinAcc"],
    isUBOForceCloseFinAcc:
      state.UBODeclarationUIM.partA["isUBOForceCloseFinAcc"],
    isUBOActingOnSelfBehalf:
      state.UBODeclarationUIM.partA["isUBOActingOnSelfBehalf"],
    isClientTransfrd: state.commonFields["isClientTransfrd"],
    isUBOInvlvdDoubtfulFinancng:
      state.UBODeclarationUIM.partA["isUBOInvlvdDoubtfulFinancng"],
    isOthrCrmstncsNegImpct:
      state.UBODeclarationUIM.partA["isOthrCrmstncsNegImpct"],
  };
};

export default connect(mapStateToProps, null)(UboUpcQuestions);
