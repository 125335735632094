import React, { Component } from "react";
import ExternalHeader from "../header/ExternalHeader";
import ExternalSidebar from "../sidebar/externalSidebar";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import PrivateRoutes from "../../../../routes/privateRoutes";
import GenericForm from "../OnBoarding/generic-form/GenericForm";
import AnnexUBO from "../OnBoarding/UBO/AnnexUBO/AnnexUBO";
import AnnexNoUBO from "../OnBoarding/UBO/AnnexNoUBO/AnnexNoUBO";
import lazyLoadingDemo from "../OnBoarding/lazyLoadingDemo";
import ClientInformationList from "../OnBoarding/clientInformationList/ClientInformationList";
import UboDeclarationUIMDashboard from "../OnBoarding/ubo-declaration-uim/UboDeclarationUIMDashboard";
import AddEditClientInformation from "../OnBoarding/clientInformationList/AddEditClientInformation";
import UboList from "../OnBoarding/ubo-list/UboList";
import OfficeFormSelection from "../OnBoarding/OfficeFormSelection";

let office = localStorage.getItem("Office");
class ExternalDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    office = localStorage.getItem("Office");

    window.addEventListener("scroll", () => {
      let btnVisible = "btnTopVisible";
      if (window.scrollY === 0) {
        btnVisible = "btnTopHide";
      }
      this.setState({ btnVisible });
    });
  }

  handleChange(e) {
    this.props.handleOfficeChange(e);
  }

  render() {
    return (
      <>
        <ExternalHeader />
        <Router basename="/dashboard">
          <div className="d-flex">
            <ExternalSidebar
              office={this.props.office}
              handleOfficeChange={(e) => this.handleChange(e)}
            />
            <Switch>
              <Redirect exact from="/" to="/client-information-list" />
              <PrivateRoutes
                path="/client-information-list"
                component={ClientInformationList}
                office={this.props.office}
                handleOfficeChange={(e) => this.handleChange(e)}
              />

              <PrivateRoutes
                path="/add-clientinformation"
                component={AddEditClientInformation}
                office={this.props.office}
                handleOfficeChange={(e) => this.handleChange(e)}
              />
              <PrivateRoutes path="/generic-form" component={GenericForm} />
              {/* <PrivateRoutes
              path="/pep-declaration"
              component={PEPDeclarationDashboard}
            /> */}
              <PrivateRoutes path="/demo" component={lazyLoadingDemo} />
              <PrivateRoutes
                path="/client-information"
                component={OfficeFormSelection}
                office={office}
              />
              <PrivateRoutes path="/Annex-UBO" component={AnnexUBO} />
              <PrivateRoutes path="/Annex-No-UBO" component={AnnexNoUBO} />
              <PrivateRoutes
                path="/ubo-declaration-uim"
                component={UboDeclarationUIMDashboard}
              />
              <PrivateRoutes path="/ubo-status-list" component={UboList} />
            </Switch>
          </div>

          <button
            onClick={this.scrollToTop}
            className={this.state.btnVisible}
          ></button>
        </Router>
      </>
    );
  }
}

export default ExternalDashboard;
