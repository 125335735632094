import React, { Component } from "react";
import { Row } from "react-bootstrap";
import countryList from "react-select-country-list";
import DropDown from "../../../../elements/DropDown";
import TextBox from "../../../../elements/TextBox";
import CheckBox from "../../../../elements/CheckBox";
import DownloadFile from "../../../../elements/DownloadFile";
import { connect } from "react-redux";
class Beneficiaries extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { index, errorMsg, isbenPassport, isbenProofAdd, isbenRefLett,benData } = this.props;
        const eMsg = errorMsg;
        return (
            <div className="mb-4 col-lg-12">
                <fieldset>
                    <legend>Beneficiary {this.props.index + 1}</legend>
                    <Row>
                        <div className="mb-2 col-lg-12 col-xl-12">
                            <TextBox
                                label="Beneficiary details"
                                name="beneficiarieDetails"
                                id={`beneficiarieDetails[${index}]`}
                                placeholder="enter your text"
                                objBaseName={this.props.objBaseName}
                                indexObjBaseName={`${this.props.indexObjBaseName}`}
                                index={this.props.index}
                                baseName={this.props.baseName}
                                reducer={this.props.reducer}
                                validationType="Text"
                                chapter={this.props.chapter}
                                disabled={true}
                            />                          
                        </div>

                        <div className="mb-3 col-lg-6">
                            <CheckBox
                                label="Beneficiary's passport or national identity card available?"
                                name="isbenPassport"
                                options={["Yes", "No"]}
                                id={`isbenPassport[${index}]`}
                                placeholder="enter your text"
                                objBaseName={this.props.objBaseName}
                                indexObjBaseName={`${this.props.indexObjBaseName}`}
                                index={this.props.index}
                                baseName={this.props.baseName}
                                reducer={this.props.reducer}
                                chapter={this.props.chapter}
                               // isRequired={this.props.form === "UK" ? true : false}
                               disabled={true}
                            />                           
                        </div>
                        {isbenPassport === "Yes" && (
                            <div className="mb-3 col-lg-4"> 
                                 <DownloadFile
                                     label="Upload Beneficiary's Passport or national identity"
                                    name="benPassport"
                                    value={benData.benPassport}
                                />                              
                            </div>
                        )}
                        {isbenPassport === "No" && (
                            <div className="mb-3 col-lg-4">
                                <TextBox
                                    label="Explanation"
                                    name="benPassportReason"
                                    id={`benPassportReason[${index}]`}
                                    placeholder="enter your text"
                                    objBaseName={this.props.objBaseName}
                                    indexObjBaseName={`${this.props.indexObjBaseName}`}
                                    index={this.props.index}
                                    baseName={this.props.baseName}
                                    reducer={this.props.reducer}
                                    validationType="Text"
                                    chapter={this.props.chapter}
                                    disabled={true}
                                    //isRequired={this.props.form === "UK" ? true : false}
                                />
                            </div>
                        )}
                    </Row>
                    <Row>
                        <div className="mb-3 col-lg-6">
                            <CheckBox
                                label="Beneficiary's proof of address available?"
                                name="isbenProofAdd"
                                options={["Yes", "No"]}
                                id={`isbenProofAdd[${index}]`}
                                placeholder="enter your text"
                                objBaseName={this.props.objBaseName}
                                indexObjBaseName={`${this.props.indexObjBaseName}`}
                                index={this.props.index}
                                baseName={this.props.baseName}
                                reducer={this.props.reducer}
                                chapter={this.props.chapter}
                                disabled={true}
                                //isRequired={this.props.form === "UK" ? true : false}
                            />
                           
                        </div>
                        {isbenProofAdd === "Yes" && (
                            <div className="mb-3 col-lg-4">
                                <DownloadFile
                                    label="Upload Beneficiary's proof of addres"
                                    name="benProofAdd"
                                    value={benData.benProofAdd}
                                />                               
                            </div>
                        )}
                        {isbenProofAdd === "No" && (
                            <div className="mb-3 col-lg-4">
                                <TextBox
                                    label="Explanation"
                                    name="benProofAddReason"
                                    id={`benProofAddReason[${index}]`}
                                    placeholder="enter your text"
                                    objBaseName={this.props.objBaseName}
                                    indexObjBaseName={`${this.props.indexObjBaseName}`}
                                    index={this.props.index}
                                    baseName={this.props.baseName}
                                    reducer={this.props.reducer}
                                    validationType="Text"
                                    chapter={this.props.chapter}
                                    disabled={true}
                                    //isRequired={this.props.form === "UK" ? true : false}
                                />                               
                            </div>
                        )}
                    </Row>
                    <Row className="pt-1 pb-2">
                        <div className="mb-3 col-lg-6">
                            <CheckBox
                                label="Beneficiary's reference letter available?"
                                name="isbenRefLett"
                                options={["Yes", "No"]}
                                id={`isbenRefLett[${index}]`}
                                placeholder="enter your text"
                                objBaseName={this.props.objBaseName}
                                indexObjBaseName={`${this.props.indexObjBaseName}`}
                                index={this.props.index}
                                baseName={this.props.baseName}
                                reducer={this.props.reducer}
                                chapter={this.props.chapter}
                                disabled={true}
                                //isRequired={this.props.form === "UK" ? true : false}
                            />                           
                        </div>
                        {isbenRefLett === "Yes" && (
                            <div className="mb-3 col-lg-4">                                
                                <DownloadFile
                                    label="Upload Beneficiary's reference letter"
                                    name="benRefLett"
                                    value={benData.benRefLett}
                                />                              
                            </div>
                        )}
                        {isbenRefLett === "No" && (
                            <div className="mb-3 col-lg-4">
                                <TextBox
                                    label="Explanation"
                                    name="benRefLettReason"
                                    id={`benRefLettReason[${index}]`}
                                    placeholder="enter your text"
                                    objBaseName={this.props.objBaseName}
                                    indexObjBaseName={`${this.props.indexObjBaseName}`}
                                    index={this.props.index}
                                    baseName={this.props.baseName}
                                    reducer={this.props.reducer}
                                    validationType="Text"
                                    chapter={this.props.chapter}
                                    disabled={true}
                                />                                
                            </div>
                        )}
                    </Row>
                </fieldset>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    const { baseName, index, reducer, objBaseName, indexObjBaseName } = ownProps;
    return {
        benData:state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index],
        isbenPassport: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["isbenPassport"],
        isbenProofAdd: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["isbenProofAdd"],
        isbenRefLett: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["isbenRefLett"],
    };
};

export default connect(mapStateToProps, null)(Beneficiaries);
