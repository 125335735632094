import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import DownloadFile from "../../../../elements/DownloadFile";
import DropDown from "../../../../elements/DropDown";
import PhoneInputField from "../../../../elements/PhoneInputField";
import TextBox from "../../../../elements/TextBox";
import { checkIsCommon } from "../../../external/OnBoarding/client-information/CommonFunctions";
import { chapValidationError, fileAcceptType } from "../Constants";
import InternalFileUpload from "../../../../elements/InternalFileUpload";
class UpcDetails extends React.Component {
  render() {
    const { upc, errors, isRequired, upcApproval } = this.props;
    const errorsMsg = JSON.parse(errors);
    return (
      <>
        {this.props.form === "NL" ? (
          <div className="html2pdf__page-break"></div>
        ) : (
          <div></div>
        )}
        <Row className="pt-2 pb-2">
          <div className="mb-2 col-lg-6 col-xl-4">
            <TextBox
              label="Name of UPC"
              name="nameUPC"
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              isCommon={checkIsCommon(this.props.form)}
              validationType="Text"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
          <div className="mb-2 col-lg-6 col-xl-4">
            <TextBox
              label="Registered address"
              name="addressRegistered"
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              isCommon={checkIsCommon(this.props.form)}
              validationType="Text"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
          <div className="mb-2 col-lg-6 col-xl-4">
            <TextBox
              label="Website"
              name="websiteUPC"
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              isCommon={checkIsCommon(this.props.form)}
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
          <div className="mb-2 col-lg-6 col-xl-4">
            <TextBox
              label="Postal code"
              name="postalCode"
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              isCommon={checkIsCommon(this.props.form)}
              validationType="Text"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
          <div className="mb-2 col-lg-6 col-xl-4">
            <TextBox
              label="City"
              name="city"
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              isCommon={checkIsCommon(this.props.form)}
              validationType="Text"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
          <div className="mb-2 col-lg-6 col-xl-4">
            <DropDown
              label="Country"
              name="country"
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              isCommon={checkIsCommon(this.props.form)}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>

          <div className="mb-2 col-lg-6 col-xl-4">
            <TextBox
              label="Tax-number"
              name="taxNum"
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              isCommon={checkIsCommon(this.props.form)}
              validationType="Text"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
          <div className="mb-2 col-lg-6 col-xl-4">
            <TextBox
              label="Trade register number"
              name="tradeRegisterNum"
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              isCommon={checkIsCommon(this.props.form)}
              validationType="Text"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
          <div className="mb-2 col-lg-6 col-xl-4">
            <PhoneInputField
              label="Phone number"
              name="phoneNumberUPC"
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              isCommon={checkIsCommon(this.props.form)}
              disabled={true}
              country={this.props.form}
            />
          </div>
          {/* <div className="mb-2 col-lg-6 col-xl-4">
            {upc.isForceCloseFinAcc && (
              <CheckBox
                label="Has the UPC ever been rejected or forced to close accounts with any bank or financial institution?"
                options={["Yes", "No"]}
                name="isForceCloseFinAcc"
                objBaseName={this.props.objBaseName}
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                isCommon={checkIsCommon(this.props.form)}
                bothDisabled={true}
              />
            )}
            {!upc.isForceCloseFinAcc && (
              <CheckBox
                label="Has the UPC ever been rejected or forced to close accounts with any bank or financial institution?"
                options={["Yes", "No"]}
                name="isForceCloseFinAcc"
                chapter="chapter3"
                objBaseName="upcApproval"
                reducer={this.props.reducer}
              />
            )}
          </div> */}

          {upc.isForceCloseFinAcc === "Yes" && (
            <div className="mb-2 col-lg-6 col-xl-4">
              <TextBox
                label="Please specify a reason"
                placeholder="enter a text"
                name="forceCloseFinAcc"
                objBaseName={this.props.objBaseName}
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                isCommon={checkIsCommon(this.props.form)}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
          {upcApproval.isForceCloseFinAcc === "Yes" && (
            <div className="mb-2 col-lg-6 col-xl-4">
              <TextBox
                label="Please specify a reason"
                placeholder="enter a text"
                name="forceCloseFinAcc"
                chapter="chapter3"
                objBaseName="upcApproval"
                reducer={this.props.reducer}
                validationType="Text"
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}

        </Row>

        {this.props.form === "Malta" && (
          <>
            <div className="html2pdf__page-break"></div>
            <Row>
              <div className="mb-2 col-lg-8 col-xl-4">
                <DownloadFile
                  label="Passport of the UPC Director"
                  value={upc.upcAuthDirPass}
                />
              </div>
              <div className="mb-2 col-lg-4 col-xl-4">
                <CheckBox
                  label="Is Approved?"
                  options={["Yes", "No"]}
                  name="isPassportCopyApprvd"
                  id="isPassportCopyApprvd"
                  chapter="chapter3"
                  objBaseName="upcApproval"
                  reducer={this.props.reducer}
                  isRequired={isRequired}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.upcApproval?.isPassportCopyApprvd?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg?.upcApproval?.isPassportCopyApprvd}
                    </span>
                  )}
              </div>
            </Row>
            <Row>
              <div className="mb-2 col-lg-8 col-xl-4">
                <DownloadFile
                  label="Utility Bills of the UPC Director"
                  value={upc.upcAuthDirUtilityBill}
                />
              </div>
              <div className="mb-2 col-lg-4 col-xl-4">
                <CheckBox
                  label="Is Approved?"
                  options={["Yes", "No"]}
                  name="isUtilityBillsApprvd"
                  id="isUtilityBillsApprvd"
                  chapter="chapter3"
                  objBaseName="upcApproval"
                  reducer={this.props.reducer}
                  isRequired={isRequired}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.upcApproval?.isUtilityBillsApprvd?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg?.upcApproval?.isUtilityBillsApprvd}
                    </span>
                  )}
              </div>
            </Row>
          </>
        )}

        <Row className="pt-0 pb-2">
          <div className="mb-2 col-lg-8 col-xl-4">
            {upc.extractChamberOfCommerce.length != 0 && (
              <DownloadFile
                label="Extract Chamber of Commerce"
                value={upc.extractChamberOfCommerce}
              />
            )}
            {upc.extractChamberOfCommerce.length == 0 && (
              <InternalFileUpload
                label="Extract Chamber of Commerce"
                name="extractChamberOfCommerce"
                id="extractChamberOfCommerce"
                acceptType={fileAcceptType}
                chapter="chapter3"
                objBaseName="upcApproval"
                reducer={this.props.reducer}

              />
            )}
          </div>
          <div className="mb-2 col-lg-4 col-xl-4">
            <CheckBox
              label="Is Approved?"
              options={["Yes", "No"]}
              name="isExtCOCApprvd"
              id="isExtCOCApprvd"
              chapter="chapter3"
              objBaseName="upcApproval"
              reducer={this.props.reducer}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg.isSubmitted === true &&
              errorsMsg?.upcApproval?.isExtCOCApprvd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg?.upcApproval?.isExtCOCApprvd}
                </span>
              )}
          </div>
        </Row>
        <Row>
          <div className="mb-2 col-lg-8 col-xl-4">
            {upc.deedOfIncorporation.length != 0 && (
              <DownloadFile
                label="Deed of incorporation/ Articles of association"
                value={upc.deedOfIncorporation}
              />
            )}
            {upc.deedOfIncorporation.length == 0 && (
              <InternalFileUpload
                label="Deed of incorporation/ Articles of association"
                name="deedOfIncorporation"
                id="deedOfIncorporation"
                acceptType={fileAcceptType}
                chapter="chapter3"
                objBaseName="upcApproval"
                reducer={this.props.reducer}

              />
            )}
          </div>
          <div className="mb-2 col-lg-4 col-xl-4">
            <CheckBox
              label="Is Approved?"
              options={["Yes", "No"]}
              name="isDeedOfIncorpoApprvd"
              id="isDeedOfIncorpoApprvd"
              chapter="chapter3"
              objBaseName="upcApproval"
              reducer={this.props.reducer}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg.isSubmitted === true &&
              errorsMsg?.upcApproval?.isDeedOfIncorpoApprvd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg?.upcApproval?.isDeedOfIncorpoApprvd}
                </span>
              )}
          </div>
        </Row>
        <Row>
          <div className="mb-2 col-lg-8 col-xl-4">
            {upc.shareholdersRegister.length != 0 && (
              <DownloadFile
                label="Shareholders’ register"
                value={upc.shareholdersRegister}
              />
            )}
            {upc.shareholdersRegister.length == 0 && (
              <InternalFileUpload
                label="Shareholders’ register"
                name="shareholdersRegister"
                id="shareholdersRegister"
                acceptType={fileAcceptType}
                chapter="chapter3"
                objBaseName="upcApproval"
                reducer={this.props.reducer}

              />
            )}
          </div>
          <div className="mb-2 col-lg-4 col-xl-4">
            <CheckBox
              label="Is Approved?"
              options={["Yes", "No"]}
              name="isShareholdersRegisterApprvd"
              id="isShareholdersRegisterApprvd"
              chapter="chapter3"
              objBaseName="upcApproval"
              reducer={this.props.reducer}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg.isSubmitted === true &&
              errorsMsg?.upcApproval?.isShareholdersRegisterApprvd?.length >
              0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg?.upcApproval?.isShareholdersRegisterApprvd}
                </span>
              )}
          </div>
        </Row>
        <Row>
          <div className="mb-2 col-lg-8 col-xl-4">
            {upc.shareholdersCertificates.length != 0 && (
              <DownloadFile
                label="if applicable, Shareholders’ certificates"
                value={upc.shareholdersCertificates}
              />)}
            {upc.shareholdersCertificates.length == 0 && (
              <InternalFileUpload
                label="if applicable, Shareholders’ certificates"
                name="shareholdersCertificates"
                id="shareholdersCertificates"
                acceptType={fileAcceptType}
                chapter="chapter3"
                objBaseName="upcApproval"
                reducer={this.props.reducer}

              />
            )}
          </div>
          <div className="mb-2 col-lg-4 col-xl-4">
            <CheckBox
              label="Is Approved?"
              options={["Yes", "No"]}
              name="isshareholdersCertificatesApprvd"
              id="isshareholdersCertificatesApprvd"
              chapter="chapter3"
              objBaseName="upcApproval"
              reducer={this.props.reducer}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg.isSubmitted === true &&
              errorsMsg?.upcApproval?.isshareholdersCertificatesApprvd?.length >
              0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg?.upcApproval?.isshareholdersCertificatesApprvd}
                </span>
              )}
          </div>
        </Row>
        <div className="html2pdf__page-break"></div>
        <Row>
          <div className="mb-2 col-lg-8 col-xl-4">
            {upc.nomineeAgreement.length != 0 && (
              <DownloadFile
                label="Nominee agreement/ Declaration of trust"
                value={upc.nomineeAgreement}
              />
            )}
            {upc.nomineeAgreement.length == 0 && (
              <InternalFileUpload
                label="Nominee agreement/ Declaration of trust"
                name="nomineeAgreement"
                id="nomineeAgreement"
                acceptType={fileAcceptType}
                chapter="chapter3"
                objBaseName="upcApproval"
                reducer={this.props.reducer}

              />
            )}
          </div>
          <div className="mb-2 col-lg-4 col-xl-4">
            <CheckBox
              label="Is Approved?"
              options={["Yes", "No"]}
              name="isNomineeAgreementApprvd"
              id="isNomineeAgreementApprvd"
              chapter="chapter3"
              objBaseName="upcApproval"
              reducer={this.props.reducer}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg.isSubmitted === true &&
              errorsMsg?.upcApproval?.isNomineeAgreementApprvd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg?.upcApproval?.isNomineeAgreementApprvd}
                </span>
              )}
          </div>
        </Row>
        <Row>
          <div className="mb-2 col-lg-8 col-xl-4">
            {upc.financialStatementsThreeYears.length != 0 && (
              <DownloadFile
                label="Financial statements of the past three years"
                value={upc.financialStatementsThreeYears}
              />
            )}
            {upc.financialStatementsThreeYears.length == 0 && (
              <InternalFileUpload
                label="Financial statements of the past three years"
                name="financialStatementsThreeYears"
                id="financialStatementsThreeYears"
                acceptType={fileAcceptType}
                chapter="chapter3"
                objBaseName="upcApproval"
                reducer={this.props.reducer}

              />
            )}
          </div>
          <div className="mb-2 col-lg-4 col-xl-4">
            <CheckBox
              label="Is Approved?"
              options={["Yes", "No"]}
              name="isFinStatmntsThreeYrsApprvd"
              id="isFinStatmntsThreeYrsApprvd"
              chapter="chapter3"
              objBaseName="upcApproval"
              reducer={this.props.reducer}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg.isSubmitted === true &&
              errorsMsg?.upcApproval?.isFinStatmntsThreeYrsApprvd?.length >
              0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg?.upcApproval?.isFinStatmntsThreeYrsApprvd}
                </span>
              )}
          </div>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { chapter, externalReducer, form } = ownProps;
 

  if (
    form === "NL" ||
    form === "Curacao" ||
    form === "Malta" ||
    form === "Luxembourg"
  ) {
    return {
      upc: state.commonFields.upc,
      upcApproval: state["ClientAcceptance"]["chapter3"]["upcApproval"],
    };
  } else {
    return {
      upc: state[externalReducer][chapter]["upc"],


    };
  }
};

export default connect(mapStateToProps, null)(UpcDetails);
