import React, { Component } from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";

class PersonalDir extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, errorMsg } = this.props;
    const eMsg = errorMsg;
    return (
     
        <fieldset>
          <legend>Personal director {this.props.index + 1}</legend>
          <Row>
            <div className="mb-2 col-lg-6 col-xl-6">
              <TextBox
                label="Name"
                name="personalDirectorName"
                id={`personalDirectorName[${index}]`}
                placeholder="enter your text"               
                index={this.props.index}
                baseName="personalDirectors"
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}              
              />             
            </div>           
          </Row>
        </fieldset>
     
    );
  }
}

export default PersonalDir;
