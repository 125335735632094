import React from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";
import { getRiskList } from "./getRiskList";

class RiskList extends React.Component {
  render() {
    const { chapters } = this.props;
    let riskList = getRiskList(this.props.reducer);
    let isHeadingVisible = false;
    riskList.forEach((risk) => {
      if (chapters[risk.chapter][risk.name]) isHeadingVisible = true;
    });

    return (
      <>
        {isHeadingVisible && (
          <h4 className="sub-chapter-title pt-4 mb-3">
            List of outstanding actions
          </h4>
        )}
        {riskList.map((risk, index) => {
          return (
            <>
              {chapters[risk.chapter][risk.name] && (
                <div
                  className={
                    this.props.PdfGeneration !== "Yes"
                      ? "groupCompaniesBlock"
                      : ""
                  }
                  key={index}
                >
                  <h6
                    className={
                      this.props.PdfGeneration !== "Yes"
                        ? "font-weight-bold internal_chapter_sub_title"
                        : "font-weight-bold"
                    }
                  >
                    {risk.label}
                  </h6>
                  <Row>
                    <div className="col-md-12">
                      <TextBox
                        label="Description of actions which still need to be taken or fill in 'None'"
                        chapter={risk.chapter}
                        placeholder="None"
                        name={risk.name}
                        reducer={risk.reducer}
                        isMultipleLines={true}
                        disabled={true}
                        className={
                          this.props.PdfGeneration === "Yes"
                            ? "txtBox"
                            : "form-control"
                        }
                      />
                    </div>
                  </Row>
                </div>
              )}
            </>
          );
        })}
      </>
    );
  }
}

export default RiskList;
