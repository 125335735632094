import React, { Component } from "react";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import DownloadFile from "../../../../elements/DownloadFile";
import TextBox from "../../../../elements/TextBox";
import "../../Onboarding/internalCAF.scss";
import { Row } from "react-bootstrap";
import {
  setUKInternalCAFDetails,
  getUKInternalCAFDetails,
} from "../../../../../redux/actions/UK/UKInternalCAFActions";
import MediaCoverage from "../../Onboarding/Chapter1/MediaCoverage";
import { NotificationManager } from "react-notifications";
import ActivityDetails from "../../Onboarding/Chapter1/ActivityDetails";
import AutoSaving from "../AutoSaving";
import getSequentialFields from "../getSequentialFields";
import { setUKInternalChapterError } from "../../../../../redux/actions/UK/UKInternalChapterErrorActions";
import { chapValidationErrorUK } from "../constants";
const chapter = "chapter1";
const reducer = "UKInternalCAF";

let timer;

class Chapter1 extends Component {
  constructor(props) {
    super(props);
    this.setRefChapter1 = React.createRef();
    this.state = {
      isLoading: false,
      formErrorsChapter1: {},
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      formName: formData.formName,
      chapter: "all",
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.getUKInternalCAFDetails(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          Object.keys(this.props.internalErrors.errors).length === 0 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationErrorUK.forEach(async (element) => {
            const response =
              res.data.result.cafInternalValidations[element.chapter];

            const newResponse = await Promise.resolve(
              getSequentialFields(response, element.chapter, false)
            );
            this.props.setUKInternalChapterError({
              form: `formErrors${element.chapter}`,
              errors: newResponse?.errorChapter,
            });
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    if (timer) {
      clearTimeout(timer);
    }
  };
  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;

    this.props.setUKInternalCAFDetails(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.props.ClientAcceptance.chapter1["dirty"] = false;
        let response = res.data.result;

        const newResponse = await Promise.resolve(
          getSequentialFields(response, chapter, true)
        );
        this.props.setUKInternalChapterError({
          form: "formErrorschapter1",
          errors: newResponse?.errorChapter,
        });

        if (!res.data.result.isValid) {
          NotificationManager.info(
            "All fields are mandatory to fill in",
            null,
            4000
          );
          timer = setTimeout(() => {
            return this.props.history.push({
              pathname: "/united-kingdom/client-investigation",
            });
          }, 4000);
        } else {
          NotificationManager.success("Form is submitted successfully.");

          return this.props.history.push({
            pathname: "/united-kingdom/client-investigation",
          });
        }
      }
    });
  };

  render() {
    const { isUIMnoResonDiscntnuBusRel, internalErrors,isInLineUIMAccPolicy } = this.props;
    const formErrorsChapter1 = JSON.stringify(internalErrors.errors);
    const errorsMsg = JSON.parse(formErrorsChapter1);
    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist" ref={this.setRefChapter1}>
          <div className="cafHeader">
            <h2 className="m-0">Client Acceptance Form </h2>
          </div>
          <div className="internal_caf generic-form">
            <h3 className="chapterttl">Client Activity</h3>

            <Row className="mb-3 fileuploader">
              <div className="col-lg-4 ActivitydownloadedFile">
                <DownloadFile
                  label="Uploaded structure chart"
                  name="structureChart"
                  info="Download an uploaded structure chart"
                  reducer="clientInformation"
                  chapter="chapter1"
                  value={this.props.chapter1Ext.structureChart}
                />
                <div className="errorMessage"></div>
              </div>

              {/* <div className="col-lg-4 ActivitydownloadedFile d-none">
                <label className="d-block">
                  Created structure chart
                  <Tooltip text="If the structure chart not exist you can create by clicking on this button "></Tooltip>{" "}
                </label>
                <button className="action-btn noicon">
                  Create structure chart
                </button>
              </div> */}
            </Row>

            <Row className="mb-3">
              <div className="col-lg-12">
                <TextBox
                  id="structureChartComm"
                  label="Describe your comment"
                  chapter={chapter}
                  name="structureChartComm"
                  placeholder="Enter your text"
                  reducer={reducer}
                  validationType="Text"
                  isMultipleLines={true}
                  //isRequired={true}
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtArea"
                      : "form-control"
                  }
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.structureChartComm?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.structureChartComm}
                    </span>
                  )}
              </div>
            </Row>

            <Row className="mb-3">
              <div className="col-lg-4">
                <CheckBox
                  label="Are the activities filled by the client?"
                  name="activitiesFilled"
                  options={["Yes", "No"]}
                  defaultValue={"Yes"}
                  chapter={chapter}
                  reducer={reducer}
                  disabled={true}
                />
                <div className="errorMessage"></div>
              </div>
              <div className="col-lg-3">
                <CheckBox
                  label="Is it inline with the UIM acceptance policy?"
                  name="isInLineUIMAccPolicy"
                  options={["Yes", "No"]}
                  id="isInLineUIMAccPolicy"
                  chapter={chapter}
                  reducer={reducer}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.isInLineUIMAccPolicy?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isInLineUIMAccPolicy}
                    </span>
                  )}
              </div>
              <div className="col-lg-5">
              {isInLineUIMAccPolicy === "No" && (
                <div className="col-lg-12">
                  <TextBox
                    label="Please explain"
                    name="explainIsInLineUIMAccPolicy"
                    id="explainIsInLineUIMAccPolicy"
                    placeholder="Enter your text"
                    chapter={chapter}
                    reducer={reducer}
                    //isRequired={true}
                  />
                  {errorsMsg &&
                    errorsMsg?.isSubmitted === true &&
                    errorsMsg?.explainIsInLineUIMAccPolicy?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.explainIsInLineUIMAccPolicy}
                      </span>
                    )}
                </div>
                
              )}
              </div>
            </Row>
            <MediaCoverage
              reducer={reducer}
              chapter={chapter}
              isRequired={false}
              form="UK"
              errors={formErrorsChapter1}
            />

            <Row className="mb-3">
              <div className="col-lg-12">
                <ActivityDetails activites={this.props.chapter1Ext} />
              </div>
            </Row>
            <div className="html2pdf__page-break"></div>
            <Row className="mb-3">
              <div className="col-lg-12">
                <CheckBox
                  label="Whether the activities/sector result in a potentially increased integrity risk."
                  name="isUIMnoResonDiscntnuBusRel"
                  id="isUIMnoResonDiscntnuBusRel"
                  options={["Yes", "No"]}
                  chapter={chapter}
                  reducer={reducer}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.isUIMnoResonDiscntnuBusRel?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isUIMnoResonDiscntnuBusRel}
                    </span>
                  )}
              </div>
            </Row>

            <Row className="mb-3">
              {isUIMnoResonDiscntnuBusRel === "Yes" && (
                <div className="col-lg-12">
                  <TextBox
                    label="Report on activities"
                    name="reportOnActivities"
                    id="reportOnActivities"
                    placeholder="Enter your text"
                    chapter={chapter}
                    reducer={reducer}
                    //isRequired={true}
                  />
                  {errorsMsg &&
                    errorsMsg?.isSubmitted === true &&
                    errorsMsg?.reportOnActivities?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.reportOnActivities}
                      </span>
                    )}
                </div>
              )}
              {isUIMnoResonDiscntnuBusRel === "No" && (
                <div className="col-lg-12">
                  <b>
                    The activities and the sector, UIM sees no reason to
                    discountinue the set-up of a business relationship
                  </b>
                </div>
              )}
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ClientAcceptance: state.UKInternalCAF,
    chapter1Ext: state.UKClientInformation.chapter1,
    isUIMnoResonDiscntnuBusRel:
      state.UKInternalCAF.chapter1["isUIMnoResonDiscntnuBusRel"],
    isMediaCoverageOnStruct:
      state.UKInternalCAF.chapter1["isMediaCoverageOnStruct"],
    isInLineUIMAccPolicy: state.UKInternalCAF.chapter1["isInLineUIMAccPolicy"],
    // data: state,
    internalErrors: state.UKInternalChapterError.formErrorschapter1,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUKInternalCAFDetails: async (data) =>
      dispatch(await getUKInternalCAFDetails(data)),
    setUKInternalCAFDetails: async (data) =>
      dispatch(await setUKInternalCAFDetails(data)),
    setUKInternalChapterError: (data) =>
      dispatch(setUKInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter1);
