import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../../elements/CheckBox";
import TextBox from "../../../../../elements/TextBox";

class BusinessIntroduction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { businessIntroduction, errorMsg } = this.props;
    const errors = JSON.parse(errorMsg);
    return (
      <>
        <div className="html2pdf__page-break"></div>
        <h4 className="sub-chapter-title mb-4">
          Details of Business Introduction
        </h4>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Third Party Referral"
              name="thirdPartyRefApprvl"
              id="thirdPartyRefApprvl"
              options={["Yes", "No"]}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {errors &&
              errors?.thirdPartyRefApprvl &&
              errors?.thirdPartyRefApprvl?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.thirdPartyRefApprvl}
                </span>
              )}
          </div>
          {businessIntroduction.thirdPartyRefApprvl === "Yes" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Third Party Details"
                name="thirdPartyDetails"
                id="thirdPartyDetails"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />{" "}
              {errors &&
                errors?.thirdPartyDetails &&
                errors?.thirdPartyDetails?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.thirdPartyDetails}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Group Referral"
              name="grpRefApprvl"
              id="grpRefApprvl"
              options={["Yes", "No"]}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {errors &&
              errors?.grpRefApprvl &&
              errors?.grpRefApprvl?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.grpRefApprvl}
                </span>
              )}
          </div>
          {businessIntroduction.grpRefApprvl === "Yes" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Office and Individual"
                name="officeIndividualDetails"
                id="officeIndividualDetails"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.officeIndividualDetails &&
                errors?.officeIndividualDetails?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.officeIndividualDetails}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Existing Client Referral"
              name="exstngClntRefApprvl"
              id="exstngClntRefApprvl"
              options={["Yes", "No"]}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              isRequired={this.props.form === "UK" ? true : false} 
            />{" "}
            {errors &&
              errors?.exstngClntRefApprvl &&
              errors?.exstngClntRefApprvl?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.exstngClntRefApprvl}
                </span>
              )}
          </div>
          {businessIntroduction.exstngClntRefApprvl === "Yes" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Client Details"
                name="clientDetails"
                id="clientDetails"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.clientDetails &&
                errors?.clientDetails?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.clientDetails}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Personal contact/ direct prospecting"
              name="prsnCntctDirePrspctngApprvl"
              id="prsnCntctDirePrspctngApprvl"
              options={["Yes", "No"]}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {errors &&
              errors?.prsnCntctDirePrspctngApprvl &&
              errors?.prsnCntctDirePrspctngApprvl?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.prsnCntctDirePrspctngApprvl}
                </span>
              )}
          </div>
          {businessIntroduction.prsnCntctDirePrspctngApprvl === "Yes" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="UIM UK staff Details"
                name="uimUKStaffDetails"
                id="uimUKStaffDetails"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.uimUKStaffDetails &&
                errors?.uimUKStaffDetails?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.uimUKStaffDetails}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Walk In"
              name="walkInApprvl"
              id="walkInApprvl"
              options={["Yes", "No"]}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {errors &&
              errors?.walkInApprvl &&
              errors?.walkInApprvl?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.walkInApprvl}
                </span>
              )}
          </div>
          {businessIntroduction.walkInApprvl === "Yes" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="How did they hear of UIM"
                name="hearofUIM"
                id="hearofUIM"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors && errors?.hearofUIM && errors?.hearofUIM?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.hearofUIM}
                </span>
              )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Internet"
              name="internetApprvl"
              id="internetApprvl"
              options={["Yes", "No"]}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {errors &&
              errors?.internetApprvl &&
              errors?.internetApprvl?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.internetApprvl}
                </span>
              )}
          </div>
          {businessIntroduction.internetApprvl === "Yes" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="From which website"
                name="fromWhichWebsite"
                id="fromWhichWebsite"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors && errors?.fromWhichWebsite && errors?.fromWhichWebsite?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.fromWhichWebsite}
                </span>
              )}
            </div>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer, chapter } = ownProps;
  return {
    businessIntroduction: state[reducer][chapter]["businessIntroduction"],
  };
};

export default connect(mapStateToProps, null)(BusinessIntroduction);
