export const AnnexNoUBO = {
    declarationForms: [],
    name:"",
    date:"",
    place:""
  };
 export const UBOPEP = {
    partA:{},
    partB:{},
    partC:{},
    date:"",
    name:"",
    place:""
  };
export const AnnexUBO = {
    declarationForms: [],
    name:"",
    date:"",
    place:""
  };