import { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";

class DashboardHeader extends Component {
  handleLogout = () => {
    localStorage.removeItem("CompanyName");
    localStorage.removeItem("Office");
    localStorage.removeItem("formId");
    localStorage.removeItem("user");
    window.location = "/";
  };
  render() {
    return (
      <div className="header">
        <Container>
          <Row>
            <Col xs={6}>
              <Link to="" className="logo" />
            </Col>
            <Col xs={6} className="text-right">
              <Link
                className="loginbtn text-center mt-2 rounded"
                onClick={this.handleLogout}
              >
                Logout
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(DashboardHeader);
