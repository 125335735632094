import React, { Component } from "react";
import { Row } from "react-bootstrap";
import countryList from "react-select-country-list";
import MultiSelectDropdown from "../../../../../elements/MultiSelectDropdown";
import TextBox from "../../../../../elements/TextBox";
import DropDown from "../../../../../elements/DropDown";
import FileUpload from "../../../../../elements/FileUpload";
import { fileAcceptType } from "./Constants";
import { connect } from "react-redux";

class MaltaUBOPEPDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isDirIndirOwner } = this.props;
    return (
      <>
        <div className="html2pdf__page-break"></div>
        <Row className="pt-3 pb-2">
          <div className="mb-2 col-lg-4">
            <TextBox
              label="Civil status"
              placeholder="enter civil status"
              reducer={this.props.reducer}
              name="civilStatus"
              id="civilStatus"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.civilStatus?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.civilStatus}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4">
            <TextBox
              label="TIN number"
              placeholder="enter tin number"
              reducer={this.props.reducer}
              name="tinNumber"
              id="tinNumber"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.tinNumber?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.tinNumber}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-4">
            <FileUpload
              label="Proof of Tax Residency"
              acceptType={fileAcceptType}
              name="proofOfTaxRes"
              id="proofOfTaxRes"
              reducer="UBODeclarationUIM"
              chapter="partA"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.proofOfTaxRes?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.proofOfTaxRes}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-4">
            <DropDown
              label="Country of TAX residence"
              options={countryList().getData()}
              reducer={this.props.reducer}
              name="tax"
              id="tax"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.tax?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.tax}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4">
            <TextBox
              label="Any US ties, past or present"
              placeholder="enter your text"
              reducer={this.props.reducer}
              name="usTiesPast"
              id="usTiesPast"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.usTiesPast?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.usTiesPast}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4">
            <TextBox
              label="Any US ties, personal or business"
              placeholder="enter your text"
              reducer={this.props.reducer}
              name="usTiesPersonal"
              id="usTiesPersonal"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.usTiesPersonal?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.usTiesPersonal}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4">
            <TextBox
              label="Has tax advise been obtained?"
              placeholder="enter your text"
              reducer={this.props.reducer}
              name="hasTaxAdvice"
              id="hasTaxAdvice"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.hasTaxAdvice?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.hasTaxAdvice}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4">
            <TextBox
              label="Conviction of criminal offence"
              placeholder="enter your text"
              reducer={this.props.reducer}
              name="convCriminalofnce"
              id="convCriminalofnce"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.convCriminalofnce?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.convCriminalofnce}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4">
            <TextBox
              label="Avail of a citizenship by investment scheme"
              placeholder="enter your text"
              reducer={this.props.reducer}
              name="citizspInvsmtScheme"
              id="citizspInvsmtScheme"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.citizspInvsmtScheme?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.citizspInvsmtScheme}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4">
            <TextBox
              label="Dual citizenship"
              placeholder="enter your text"
              reducer={this.props.reducer}
              name="dualCitizenship"
              id="dualCitizenship"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.dualCitizenship?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.dualCitizenship}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4">
            <MultiSelectDropdown
              label="Residency in more than one jurisdiction"
              options={countryList().getData()}
              reducer={this.props.reducer}
              name="residency"
              id="residency"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.residency?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.residency}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-4">
            <TextBox
              label="Whether acting on behalf of third parties"
              placeholder="enter your text"
              reducer={this.props.reducer}
              name="actingThirdParties"
              id="actingThirdParties"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.actingThirdParties?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.actingThirdParties}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-4">
            <DropDown
              label="Select owner"
              options={[
                { label: "Direct Owner", value: "Direct Owner" },
                { label: "Indirect Owner", value: "Indirect Owner" },
              ]}
              reducer={this.props.reducer}
              name="isDirIndirOwner"
              id="isDirIndirOwner"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.isDirIndirOwner?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.isDirIndirOwner}
                </span>
              )}
          </div>

          {isDirIndirOwner === "Direct Owner" && (
            <div className="mb-2 col-lg-4">
              <TextBox
                label="Percentage of shares to be held (in %)"
                placeholder="enter your text"
                reducer={this.props.reducer}
                name="perctgShareHeld"
                id="perctgShareHeld"
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
              />
              {this.props.errorMsg !== null &&
                Object.entries(this.props.errorMsg).length !== 0 &&
                this.props.errorMsg?.partA?.perctgShareHeld?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {this.props.errorMsg?.partA?.perctgShareHeld}
                  </span>
                )}
            </div>
          )}

          {isDirIndirOwner === "Indirect Owner" && (
            <>
              <div className="mb-2 col-lg-4">
                <TextBox
                  label="Percentage of shares to be held (in %)"
                  placeholder="enter your text"
                  reducer={this.props.reducer}
                  name="perctgShareHeld"
                  id="perctgShareHeld"
                  chapter={this.props.chapter}
                  objBaseName={this.props.objBaseName}
                  validationType="Text"
                />
                {this.props.errorMsg !== null &&
                  Object.entries(this.props.errorMsg).length !== 0 &&
                  this.props.errorMsg?.partA?.perctgShareHeld?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {this.props.errorMsg?.partA?.perctgShareHeld}
                    </span>
                  )}
              </div>
              <div className="mb-2 col-lg-4">
                <TextBox
                  label="Please mention entities"
                  placeholder="enter your text"
                  reducer={this.props.reducer}
                  name="indirectEntity"
                  id="indirectEntity"
                  chapter={this.props.chapter}
                  objBaseName={this.props.objBaseName}
                  validationType="Text"
                />
                {this.props.errorMsg !== null &&
                  Object.entries(this.props.errorMsg).length !== 0 &&
                  this.props.errorMsg?.partA?.indirectEntity?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {this.props.errorMsg?.partA?.indirectEntity}
                    </span>
                  )}
              </div>
            </>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isDirIndirOwner:
      state.UBODeclarationUIM.partA.maltaUBOPEPDetails["isDirIndirOwner"],
  };
};

export default connect(mapStateToProps, null)(MaltaUBOPEPDetails);
