export const fileAcceptType = ".doc,.docx,.odt,.pdf,.rtf,.tex,.txt,.wpd,.ods,.xls,.xlsm,.xlsx,.key,.odp,.pps,.ppt,.pptx,.jpeg,.jpg,.png";

export function rationaleArray(office) {
  let rationales = [
    {
      label: "Tax planning",
      isChecked: false,
    },
    {
      label: "Asset protection",
      isChecked: false,
    },
    {
      label: "Identity protection",
      isChecked: false,
    },
    {
      label: "Investment protection",
      isChecked: false,
    },
    {
      label: "Stable legal infrastructure",
      isChecked: false,
    },
    {
      label: `Geographical location of ${office}`,
      isChecked: false,
    },
    {
      label: "Participation exemption",
      isChecked: false,
    },
    {
      label: "Other commercial reasons",
      isChecked: false,
    },
    {
      label: "Other",
      isChecked: false,
    },
  ];

  return rationales;
}

export const RightsAndObligationArray = [
  { value: "Loan Agreement", label: "Loan Agreement" },
  { value: "Receivables", label: "Receivables" },
  { value: "Other", label: "Other" },
];

export const CurrencyArray = [
  { value: "USD", label: "USD" },
  { value: "EUR", label: "EUR" },
  { value: "GBP", label: "GBP" },
];

export function getTypeSizeFrqArray(office) {
  let array = [
    {
      label: `From ${
        office === "Malta" ? "Target" : "Object"
      } Company to group companies`,
      name: "objCompToGrpComp",
      dependency: "isUPCfundObjComp",
    },
    {
      label: `From group companies to ${
        office === "Malta" ? "Target" : "Object"
      } Company`,
      name: "grpCompToObjComp",
      dependency: "isUPCfundObjComp",
    },
    {
      label: `From ${office === "Malta" ? "Target" : "Object"} Company to UBO`,
      name: "objCompToUBO",
      dependency: "isUBOInvstObjComp",
    },
    {
      label: `From UBO to ${office === "Malta" ? "Target" : "Object"} Company`,
      name: "uboToObjComp",
      dependency: "isUBOInvstObjComp",
    },
    {
      label: `From ${
        office === "Malta" ? "Target" : "Object"
      } Company to third parties`,
      name: "objCompToTP",
      dependency: "isTpInvstObjComp",
    },
    {
      label: `From third parties to ${
        office === "Malta" ? "Target" : "Object"
      } Company`,
      name: "tpToObjComp",
      dependency: "isTpInvstObjComp",
    },
  ];
  return array;
}
export const TypeSizeFreqTransct = [
  {
    label: "Type",
    name: "typeTrnsct",
  },
  {
    label: "Size",
    name: "sizeTrnsct",
  },
  {
    label: "Frequency of transactions",
    name: "freqTrnsct",
  },
];

export const NatureOfObjectComArray = [
  { value: "Holding", label: "Holding" },
  { value: "Financing", label: "Financing" },
  { value: "Royalty", label: "Royalty" },
  { value: "Operational", label: "Operational" },
  { value: "Counsultancy/Management", label: "Counsultancy/Management" },
  { value: "Other", label: "Other" },
];

export const FATCAUSList = [
  {
    label: "FFI (Foreign Financial Institution)",
    Value: "FFI (Foreign Financial Institution)",
  },
  {
    label: "Active NFFE (Non-Financial Foreign Entity)",
    Value: "Active NFFE (Non-Financial Foreign Entity)",
  },
  {
    label: "Passive NFFE (Non-Financial Foreign Entity)",
    Value: "Passive NFFE (Non-Financial Foreign Entity)",
  },
  {
    label: "Specified US Person",
    Value: "Specified US Person",
  },
];

export const companyTypeMalta = [
  { value: "Limited Holding", label: "Limited Holding" },
  { value: "Limited Trading", label: "Limited Trading" },
  { value: "Limited Finance", label: "Limited Finance" },
  { value: "Other", label: "Other" },
];

export const oneServicesMalta = [
  {
    value: "Transfer of an existing company to UIM Malta Limited",
    label: "Transfer of an existing company to UIM Malta Limited",
  },
  { value: "Others", label: "Others" },
];
export const oneServicesLuxembourg = [
  {
    value: "Transfer of an existing company to UIM Malta Limited",
    label: "Transfer of an existing company to UIM Malta Limited",
  },
  { value: "Others", label: "Others" },
];

export const recurrentServicesMalta = [
  { label: "Company secretarial", value: "Company secretarial" },
  { label: "Bookkeeping", value: "Bookkeeping" },
  { label: "Consolidation", value: "Consolidation" },
  { label: "Reporting", value: "Reporting" },
  { label: "Tax declaration", value: "Tax declaration" },
  { label: "Administration", value: "Administration" },
  { label: "Directors / Managers", value: "Directors / Managers" },
  { label: "Others", value: "Others" },
];
export const recurrentServicesLuxembourg = [
  { label: "Company secretarial", value: "Company secretarial" },
  { label: "Bookkeeping", value: "Bookkeeping" },
  { label: "Consolidation", value: "Consolidation" },
  { label: "Reporting", value: "Reporting" },
  { label: "Tax declaration", value: "Tax declaration" },
  { label: "Administration", value: "Administration" },
  { label: "Directors / Managers", value: "Directors / Managers" },
  { label: "Others", value: "Others" },
];
