export function getStructureActivities(form) {
  let array = [
    {
      label: "Ultimate Parent Company Activities",
      activityName: "upcActivites",
      sectorName: "upcSector",
    },
    {
      label: "Shareholder Activities",
      activityName: "shareholderActivites",
      sectorName: "shareholderSector",
    },
    {
      label: `${form === "Malta" ? "Target" : "Object"} Company Activities`,
      activityName: "objCompActivities",
      sectorName: "objCompSector",
    },
    {
      label: `${
        form === "Malta" ? "Subsidiaries" : "Participations"
      } Activities`,
      activityName: "participantActivites",
      sectorName: "participantSector",
    },
    {
      label: "Other Activities",
      activityName: "otherActivites",
      sectorName: "otherSector",
    },
  ];
  return array;
}

export const peerEntities = [
  { label: "Chamber of Commerce extract", name: "chamberExtract" },
  { label: "Articles of incorporation", name: "article" },
  { label: "Shareholders Register", name: "shareholderRegister" },
  { label: "Financial Statement of past three years", name: "finStat" },
];

export const fileAcceptType = ".doc,.docx,.odt,.pdf,.rtf,.tex,.txt,.wpd,.ods,.xls,.xlsm,.xlsx,.key,.odp,.pps,.ppt,.pptx,.jpeg,.jpg,.png";

export const EntityType = [
  {
    value: "Company",
    label: "Company",
  },
  {
    value: "Trust",
    label: "Trust",
  },
  {
    value: "Other",
    label: "Other",
  },
];
