import axios from "axios";
import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  Authentication_USER_REQUEST,
  Authentication_USER_SUCCESS,
  Authentication_USER_FAIL,
  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  GET_USER_BY_USERID_REQUEST,
  GET_USER_BY_USERID_SUCCESS,
  GET_USER_BY_USERID_FAIL,
  BULK_DELETE_REQUEST,
  BULK_DELETE_SUCCESS,
  BULK_DELETE_FAIL,
  APPROVE_REJECT_FROM_MAIL_REQUEST,
  APPROVE_REJECT_FROM_MAIL_SUCCESS,
  APPROVE_REJECT_FROM_MAIL_FAIL,
  USER_APPROVAL_LIST_REQUEST,
  USER_APPROVAL_LIST_SUCCESS,
  USER_APPROVAL_LIST_FAIL,
  APPROVE_REJECT_USER_REQUEST,
  APPROVE_REJECT_USER_SUCCESS,
  APPROVE_REJECT_USER_FAIL,
  ASSIGN_CLIENT_LIST_REQUEST,
  ASSIGN_CLIENT_LIST_SUCCESS,
  ASSIGN_CLIENT_LIST_FAIL,
  Authentication_UBO_REQUEST,
  Authentication_UBO_FAIL,
  VERIFY_UBO_REQUEST,
  VERIFY_UBO_FAIL,
  DELETE_UBO_REQUEST,
  DELETE_UBO_SUCCESS,
  DELETE_UBO_FAIL,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  SET_PROFILE_REQUEST,
  SET_PROFILE_SUCCESS,
  SET_PROFILE_FAIL,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_FAIL,
  DOWNLOAD_FATCA_REQUEST,
  DOWNLOAD_FATCA_SUCCESS,
  DOWNLOAD_FATCA_FAIL,
  GET_USER_OFFICE_REQUEST,
  GET_USER_OFFICE_FAIL,
  GET_USER_OFFICE_SUCCESS,
} from "../constants/userConstants";
import { PublicClientApplication } from "@azure/msal-browser";
import {getToken} from "./getToken"
const MSLOGIN_CLIENTID = process.env.REACT_APP_MSLOGIN_CLIENTID;
const MSLOGIN_REDIRECTURL = process.env.REACT_APP_MSLOGIN_REDIRECTURL;
// const getToken = async () => {
//   const cfg = {
//     auth: {
//       clientId: MSLOGIN_CLIENTID,
//       authority:
//         "https://login.microsoftonline.com/37ab276a-6d7b-4b26-95c4-23f9fd7d5786", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
//       redirectUri: MSLOGIN_REDIRECTURL,
//     },
//     cache: {
//       cacheLocation: "localStorage", // This configures where your cache will be stored
//       storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
//     },
//   };

//   const pca = new PublicClientApplication(cfg);
//   let allAccounts = JSON.parse(localStorage.getItem("allAccounts"));
//   const account = allAccounts[0];
//   let idToken = null;
//   try {
//     const resp = await pca.acquireTokenSilent({
//       scopes: ["User.Read"],
//       account,
//     });
//     idToken = resp.idToken;
//     return idToken;
//   } catch (err) {
//     return;
//   }
// };
const BACKEND_API_URL = process.env.REACT_APP_USER_BACKEND_API_URL;
const INTERNAL_USER_BACKEND_API =
  process.env.REACT_APP_INTERNAL_USER_BACKEND_API_URL;
const COMPANY_BACKEND_URL = process.env.REACT_APP_COMPANY_BACKEND_API_URL;

// Login USER
const loginUserRequest = () => {
  return {
    type: LOGIN_USER_REQUEST,
  };
};

const loginUserReceiveData = (json) => {
  return {
    type: LOGIN_USER_SUCCESS,
    data: json,
  };
};

export const loginUser = (data) => {
  return (dispatch) => {
    dispatch(loginUserRequest());
    return axios
      .post(`${BACKEND_API_URL}/User/SignIn`, data)
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: LOGIN_USER_FAIL, data: json });
        } else {
          return dispatch(loginUserReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: LOGIN_USER_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// Add USER in User Maintenance Section
const addUserRequest = () => {
  return {
    type: ADD_USER_REQUEST,
  };
};

const addUserReceiveData = (json) => {
  return {
    type: ADD_USER_SUCCESS,
    data: json,
  };
};

export const addUser = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(addUserRequest());
    return axios
      .post(`${INTERNAL_USER_BACKEND_API}/InternalUser/CreateUser`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: ADD_USER_FAIL, data: json });
        } else {
          return dispatch(addUserReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: ADD_USER_FAIL,
          error: err.response,
        });
      });
  };
};

// Authentication USER (SendOtp)
const authenticationUserRequest = () => {
  return {
    type: Authentication_USER_REQUEST,
  };
};

const authenticationUserReceiveData = (json) => {
  return {
    type: Authentication_USER_SUCCESS,
    data: json,
  };
};

export const authenticationUser = (data) => {
  const user = localStorage.getItem("autheticate-token");
  const headers = {
    Authorization: "Bearer " + user,
  };
  return (dispatch) => {
    dispatch(authenticationUserRequest());
    return axios
      .post(`${BACKEND_API_URL}/User/SendOtp`, data, { headers })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: Authentication_USER_FAIL, data: json });
        } else {
          return dispatch(authenticationUserReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: Authentication_USER_FAIL,
          error: err.response,
        });
      });
  };
};

//authenticate UBO
const authenticationUBORequest = () => {
  return {
    type: Authentication_UBO_REQUEST,
  };
};

const authenticationUBOReceiveData = (json) => {
  return {
    type: Authentication_USER_SUCCESS,
    data: json,
  };
};

export const authenticationUBO = (data) => {
  return (dispatch) => {
    dispatch(authenticationUBORequest());
    return axios
      .post(`${BACKEND_API_URL}/UBOUIM/SendOtpToUBO`, data)
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: Authentication_UBO_FAIL, data: json });
        } else {
          return dispatch(authenticationUBOReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: Authentication_UBO_FAIL,
          error: err.response,
          //data: [],
        });
      });
  };
};

// Verify USER
const verifyUserRequest = () => {
  return {
    type: VERIFY_USER_REQUEST,
  };
};

const verifyUserReceiveData = (json) => {
  return {
    type: VERIFY_USER_SUCCESS,
    data: json,
  };
};

export const verifyUser = (data) => {
  const user = localStorage.getItem("autheticate-token");
  const headers = {
    Authorization: "Bearer " + user,
  };
  return (dispatch) => {
    dispatch(verifyUserRequest());
    return axios
      .post(`${BACKEND_API_URL}/User/OtpVerification`, data, { headers })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: VERIFY_USER_FAIL, data: json });
        } else {
          return dispatch(verifyUserReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: VERIFY_USER_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// Verify UBO
const verifyUBORequest = () => {
  return {
    type: VERIFY_UBO_REQUEST,
  };
};

const verifyUBOReceiveData = (json) => {
  return {
    type: VERIFY_USER_SUCCESS,
    data: json,
  };
};

export const verifyUBO = (data) => {
  return (dispatch) => {
    dispatch(verifyUBORequest());
    return axios
      .post(`${BACKEND_API_URL}/UBOUIM/VerifyUBOUIMOtp`, data)
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: VERIFY_UBO_FAIL, data: json });
        } else {
          return dispatch(verifyUBOReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: VERIFY_UBO_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// Delete UBO UIM
const deleteUBORequest = () => {
  return {
    type: DELETE_UBO_REQUEST,
  };
};

const deleteUBOReceiveData = (json) => {
  return {
    type: DELETE_UBO_SUCCESS,
    data: json,
  };
};

export const deleteUBO = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(deleteUBORequest());
    return axios
      .post(`${BACKEND_API_URL}/UBOUIM/DeleteUBOUIM`, data, { headers })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: DELETE_UBO_FAIL, data: json });
        } else {
          return dispatch(deleteUBOReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: DELETE_UBO_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// Change password after login
const changePasswordRequest = () => {
  return {
    type: CHANGE_PASSWORD_REQUEST,
  };
};

const changePasswordReceiveData = (json) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    data: json,
  };
};

export const changePwd = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(changePasswordRequest());
    return axios
      .post(`${BACKEND_API_URL}/User/ChangePassword`, data, { headers })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: CHANGE_PASSWORD_FAIL, data: json });
        } else {
          return dispatch(changePasswordReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: CHANGE_PASSWORD_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

//Forgot Password
const forgotPasswordRequest = () => {
  return {
    type: FORGOT_PASSWORD_REQUEST,
  };
};

const forgotPasswordReceiveData = (json) => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    data: json,
  };
};

export const forgotPassword = (data) => {
  return (dispatch) => {
    dispatch(forgotPasswordRequest());
    return axios
      .post(`${BACKEND_API_URL}/User/ForgotPassword`, data)
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: FORGOT_PASSWORD_FAIL, data: json });
        } else {
          return dispatch(forgotPasswordReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: FORGOT_PASSWORD_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};
//Reset Password
const resetPasswordRequest = () => {
  return {
    type: RESET_PASSWORD_REQUEST,
  };
};

const resetPasswordReceiveData = (json) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    data: json,
  };
};

export const resetPassword = (data, token) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return (dispatch) => {
    dispatch(resetPasswordRequest());
    return axios
      .post(`${BACKEND_API_URL}/User/ResetPassword`, JSON.stringify(data), {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.isError === "false") {
          return dispatch({ type: RESET_PASSWORD_FAIL, data: json });
        } else {
          return dispatch(resetPasswordReceiveData(json));
        }
      })
      .catch((err) => {
        console.error("error:", err.response);
        return dispatch({
          type: RESET_PASSWORD_FAIL,
          error: err.response,
          // data: [],
        });
      });
  };
};

// Update USER in User Maintenance Section
const updateUserRequest = () => {
  return {
    type: UPDATE_USER_REQUEST,
  };
};

const updateUserReceiveData = (json) => {
  return {
    type: UPDATE_USER_SUCCESS,
    data: json,
  };
};

export const updateUser = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(updateUserRequest());
    return axios
      .post(`${INTERNAL_USER_BACKEND_API}/InternalUser/UpdateUser`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: UPDATE_USER_FAIL, data: json });
        } else {
          return dispatch(updateUserReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: UPDATE_USER_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// Delete USER in User Maintenance Section
const deleteUserRequest = () => {
  return {
    type: DELETE_USER_REQUEST,
  };
};

const deleteUserReceiveData = (json) => {
  return {
    type: DELETE_USER_SUCCESS,
    data: json,
  };
};

export const deleteUser = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(deleteUserRequest());
    return axios
      .post(`${INTERNAL_USER_BACKEND_API}/InternalUser/DeleteUser`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: DELETE_USER_FAIL, data: json });
        } else {
          return dispatch(deleteUserReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: DELETE_USER_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// BULK Delete USER in User Maintenance Section
const bulkDeleteUserRequest = () => {
  return {
    type: BULK_DELETE_REQUEST,
  };
};

const bulkDeleteUserReceiveData = (json) => {
  return {
    type: BULK_DELETE_SUCCESS,
    data: json,
  };
};

export const bulkDeleteUser = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(bulkDeleteUserRequest());
    return axios
      .post(`${INTERNAL_USER_BACKEND_API}/InternalUser/BulkDelete`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: BULK_DELETE_FAIL, data: json });
        } else {
          return dispatch(bulkDeleteUserReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: BULK_DELETE_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// Get USER LIST in User Maintenance Section
const getUsersRequest = () => {
  return {
    type: GET_USER_LIST_REQUEST,
  };
};

const getUsersReceiveData = (json) => {
  return {
    type: GET_USER_LIST_SUCCESS,
    data: json,
  };
};

export const getUserList = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }

  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(getUsersRequest());
    return axios
      .post(`${INTERNAL_USER_BACKEND_API}/InternalUser/ReadAllUsers`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: GET_USER_LIST_FAIL, data: json });
        } else {
          return dispatch(getUsersReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: GET_USER_LIST_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// Get USER DETAILS BY ID in User Maintenance Section
const getUsersByIdRequest = () => {
  return {
    type: GET_USER_BY_USERID_REQUEST,
  };
};

const getUserByIdReceiveData = (json) => {
  return {
    type: GET_USER_BY_USERID_SUCCESS,
    data: json,
  };
};

export const getUserById = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(getUsersByIdRequest());
    return axios
      .post(`${INTERNAL_USER_BACKEND_API}​/InternalUser/ReadUserById`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: GET_USER_BY_USERID_FAIL, data: json });
        } else {
          return dispatch(getUserByIdReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: GET_USER_BY_USERID_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// Approve and Reject - handling from email
const approveRejectRequest = () => {
  return {
    type: APPROVE_REJECT_FROM_MAIL_REQUEST,
  };
};

const approveRejectReceiveData = (json) => {
  return {
    type: APPROVE_REJECT_FROM_MAIL_SUCCESS,
    data: json,
  };
};

export const approveRejectFromMail = (data) => {
  return (dispatch) => {
    dispatch(approveRejectRequest());

    return axios
      .post(`${INTERNAL_USER_BACKEND_API}​/InternalUser/SetUserStatus`, data)
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: APPROVE_REJECT_FROM_MAIL_FAIL, data: json });
        } else {
          return dispatch(approveRejectReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);

        return dispatch({
          type: APPROVE_REJECT_FROM_MAIL_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// get user approval list
const userApprovalListRequest = () => {
  return {
    type: USER_APPROVAL_LIST_REQUEST,
  };
};

const userApprovalListReceiveData = (json) => {
  return {
    type: USER_APPROVAL_LIST_SUCCESS,
    data: json,
  };
};

export const userApprovalList = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(userApprovalListRequest());
    return axios
      .post(`${INTERNAL_USER_BACKEND_API}/InternalUser/FetchApproval`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: USER_APPROVAL_LIST_FAIL, data: json });
        } else {
          return dispatch(userApprovalListReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: USER_APPROVAL_LIST_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// assign client list
const assignClientListRequest = () => {
  return {
    type: ASSIGN_CLIENT_LIST_REQUEST,
  };
};

const assignClientListReceiveData = (json) => {
  return {
    type: ASSIGN_CLIENT_LIST_SUCCESS,
    data: json,
  };
};

export const assignClientList = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(assignClientListRequest());
    return axios
      .post(`${COMPANY_BACKEND_URL}​/Company/ReadAllAssignCompany`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: ASSIGN_CLIENT_LIST_FAIL, data: json });
        } else {
          return dispatch(assignClientListReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: ASSIGN_CLIENT_LIST_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// approve reject user
const approveRejectUserRequest = () => {
  return {
    type: APPROVE_REJECT_USER_REQUEST,
  };
};

const approveRejectUserReceiveData = (json) => {
  return {
    type: APPROVE_REJECT_USER_SUCCESS,
    data: json,
  };
};

export const approveRejectUser = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(approveRejectUserRequest());
    return axios
      .post(
        `${INTERNAL_USER_BACKEND_API}​/InternalUser/SetHeadApproval`,
        data,
        {
          headers,
        }
      )
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: APPROVE_REJECT_USER_FAIL, data: json });
        } else {
          return dispatch(approveRejectUserReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: APPROVE_REJECT_USER_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// get profile
const getProfileRequest = () => {
  return {
    type: GET_PROFILE_REQUEST,
  };
};

const getProfileResponse = (json) => {
  return {
    type: GET_PROFILE_SUCCESS,
    data: json,
  };
};

export const getProfile = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(getProfileRequest());
    return axios
      .post(`${BACKEND_API_URL}​/User/ReadUserProfile`, null, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: GET_PROFILE_FAIL, data: json });
        } else {
          return dispatch(getProfileResponse(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: GET_PROFILE_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// set profile
const setProfileRequest = () => {
  return {
    type: SET_PROFILE_REQUEST,
  };
};

const setProfileResponse = (json) => {
  return {
    type: SET_PROFILE_SUCCESS,
    data: json,
  };
};

export const setProfile = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(setProfileRequest());
    return axios
      .post(`${BACKEND_API_URL}​/User/UpdateUserProfile`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: SET_PROFILE_FAIL, data: json });
        } else {
          return dispatch(setProfileResponse(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: SET_PROFILE_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};
const getAllUsersRequest = () => {
  return {
    type: GET_ALL_USERS_REQUEST,
  };
};
const getAllUsersSuccess = (json) => {
  return {
    type: GET_ALL_USERS_SUCCESS,
    data: json,
  };
};

export const getAllUsers = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(getAllUsersRequest());
    return axios
      .post(
        `${INTERNAL_USER_BACKEND_API}/InternalUser/ReadAllAADUsers`,

        data,
        { headers }
      )
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: GET_ALL_USERS_FAIL, data: json });
        } else {
          return dispatch(getAllUsersSuccess(json));
        }
      })
      .catch((err) => {
         return dispatch({
          type: GET_ALL_USERS_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// download FATCA form
const downloadFATCARequest = () => {
  return {
    type: DOWNLOAD_FATCA_REQUEST,
  };
};

const downloadFATCAResponse = (json) => {
  return {
    type: DOWNLOAD_FATCA_SUCCESS,
    data: json,
  };
};

export const downloadFATCAForm = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(downloadFATCARequest());
    return axios
      .post(`${BACKEND_API_URL}​/CAF/DownloadFATCAFile`, data, {
        headers,
        responseType: "blob",
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: DOWNLOAD_FATCA_FAIL, data: json });
        } else {
          return dispatch(downloadFATCAResponse(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: DOWNLOAD_FATCA_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

const getUserOfficeRequest = () => {
  return {
    type: GET_USER_OFFICE_REQUEST,
  };
};
const getUserOfficeSuccess = (json) => {
  return {
    type: GET_USER_OFFICE_SUCCESS,
    data: json,
  };
};
export const getUserOffice = async () => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(getUserOfficeRequest());
    return axios
      .post(`${INTERNAL_USER_BACKEND_API}/InternalUser/fetchUserOffice`, null, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: GET_USER_OFFICE_FAIL, data: json });
        } else {
          return dispatch(getUserOfficeSuccess(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: GET_USER_OFFICE_FAIL,
          error: err.response,
        });
      });
  };
};
