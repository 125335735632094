import React from "react";
import { connect } from "react-redux";
import { iconSuccess, iconError } from "../../../../../assets/images";
import { setUBOUIMDetails } from "../../../../../redux/actions/UBOdeclarationUIMactions";
import ExternalHeader from "./../../header/ExternalHeader";

class SuccessMsg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSuccess: false,
    };
  }
  componentDidMount = () => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function (event) {
      window.history.go(1);
    };
    let url = window.location.href;
    let successURL = url.split("?")[1];
    if (successURL === "success") {
      this.setState({ isSuccess: true });
    } else {
      this.setState({ isSuccess: false });
    }
  };

  render() {
    return (
      <>
        <div className="successHeader">
          <ExternalHeader />
        </div>
        <div className="loginBg pt-5">
          <div className="LoginWindow rounded pb-4 mt-5">
            {this.state.isSuccess ? (
              <div className="successMessage text-center">
                <img src={iconSuccess} alt="" />
                <p className="mt-4 success-message">
                  Form is submitted successfully
                </p>
              </div>
            ) : (
              <div className="errorMessage text-center">
                <img src={iconError} alt="" />
                <p className="mt-4 error-message">
                  Error while submitting form
                </p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUBOUIMDetails: (data) => dispatch(setUBOUIMDetails(data)),
  };
};

export default connect(null, mapDispatchToProps)(SuccessMsg);
