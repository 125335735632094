import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getClientInformationListDetails,
  deleteClientInformation,
  globalStateChanged,
  globalStateNotChanged,
  deleteClientCAF,
  setClientInfoFormDataToReducer,
  getAllOffices,
} from "../../../../../redux/actions/CAFGenericActions";
import { NotificationManager } from "react-notifications";
import Modal from "../../../../components/shared/Modal";
import orderBy from "lodash/orderBy";
import { Button } from "react-bootstrap";
import { manageLandingPage } from "./manageLandingPage";
import moment from "moment";
let selectedOffices = [];
let allOffices;
const customSort = (rows, field, direction) => {
  const handleField = (row) => {
    if (row[field]) {
      return row[field].toLowerCase();
    }
    return row[field];
  };
  return orderBy(rows, handleField, direction);
};

class clientInformationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalReject:false,
      graphData: null,
      isLoading: false,
      selectedRows: [],
      searchInput: "",
      data: [],
      rejectionHistory:[],
      singleDelete: false,
      multipleDelete: false,
      deleteRecord: {},
      deleteRecordsArray: [],
      toggleCleared: false,
      originalData: [],
    };

    this.props.getAllOffices().then((res) => {
      if (res.data.portalErrorCode === 200) {
        allOffices = res.data.result;
        const checkboxs = this.subHeaderFilterComponent();
        this.setState({
          officeList: res.data.result,
          columnsRejectionHistory:[ 
            {
              name: "Rejected by",
              selector: "rejectedByUserName",
              sortable: true,
              },
            {
            name: "Date",
            //selector: "lastUpdate",
            sortable: true,
            selector: (row) => (
              <div>
                {row.lastUpdate ? (
                  moment(row.lastUpdate).format("YYYY-MM-DD HH:mm")
                ) : (
                  <div className="center">-</div>
                )}
              </div>
            ),
            },
            {
            name: "Comment",
            selector: "comment",
            sortable: true,
            },
         ],
          columns: [
            {
              name: "Form Name",
              selector: "name",
              sortable: true,
            },
            {
              name: "Client Name",
              selector: "clientName",
              sortable: true,
            },
            {
              name: (
                <div class="office-dropdown-hover">
                  <div class="office-button">Office</div>
                  <div class="office-dropdown-content office-bar-block office-card-4">
                    {checkboxs}
                  </div>
                </div>
              ),

              cell: (row) => (
                <div className="officeLocation">
                  <span className={row.office}>{row.office}</span>
                </div>
              ),
              width: "20%",
            },
            {
              name: "Status",
              selector: "status",
              sortable: true,
               },
            {
              name: "",
              selector: (row) => (
                <>
                  {row.status === "In Progress" ? (
                    <div className="Edit-Btn">
                      <Button
                        className="action-btn edit clientEdit"
                        onClick={() => {
                          this.editClientInformation(row);
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  ) : row.status === "Not Started" ? (
                    <div className="Edit-Btn">
                      <Button
                        className="action-btn start"
                        onClick={() => {
                          this.startClientInformation(row);
                        }}
                      >
                        Start
                      </Button>
                    </div>
                  ):row.status === "Rejected" ? (
                    <div className="officeLocation">
                     <div className="Edit-Btn">
                        <Button
                          className="action-btn edit clientEdit"
                          onClick={() => {
                            this.editClientInformation(row);
                          }}
                        >
                          Edit
                        </Button>
                        <Button                  
                     className="reject-btn"
                     onClick={() => this.rejectionHistory(row)}
                          >
                         Rejection history
                       </Button>  
                      </div>
                  </div>
                  )                
                  : (
                    <div className="Edit-Btn">
                      <span className="action-btn approve">Completed</span>
                    </div>
                  )}
                </>
              ),
              width: "200px",
            },
          ],
        });
      }
    });
  }

  rejectionHistory=(row)=>{
    this.modalRejectOpen(row);
  }
  modalRejectOpen = (row) => {
    let reqData = {
      id: row.id,
      userId: row.userId,
     }; 
     let filteredRejectHistory= this.state.originalData.filter((value) => {
      return (
        value.id ===  row.id &&
        value.userId === row.userId
       
      );
    });
    this.setState({
      rejectionHistory: filteredRejectHistory[0].rejectCmnt,
      modalReject: true,
      selectedRow:row });
  };
  modalRejectClose = () => {
    this.setState({
      modalReject: false,
    });
  };
  componentDidMount = () => {
    localStorage.removeItem("activeLinkLevel");
    localStorage.removeItem("CompanyName");
    localStorage.removeItem("Office");
    localStorage.removeItem("formId");
    this.setState({ isLoading: true });
    this.props.setClientInfoFormDataToReducer(0);
    this.props.getClientInformationListDetails().then((res) => {
      this.setState({ isLoading: false });
      if (res.data.portalErrorCode === 200) {
        this.setState({
          originalData: res.data.result,
          data: res.data.result,
        });
      }
    });
  };

  handleChange(e) {
    this.props.handleOfficeChange(e);
  }
  startClientInformation = (row) => {
    localStorage.setItem("formId", row.id);
    localStorage.setItem("clientId", row.clientId);
    localStorage.setItem("CompanyName", row.clientName);
    localStorage.setItem("Office", row.office);
    this.handleChange(row.office);
    this.props.globalStateNotChanged();
    let path = manageLandingPage(row.office, "start", "");
    this.props.history.push({
      pathname: path,
    });
  };
  editClientInformation = (row) => {
    localStorage.setItem("formId", row.id);
    localStorage.setItem("clientId", row.clientId);
    localStorage.setItem("CompanyName", row.clientName);
    localStorage.setItem("Office", row.office);
    this.handleChange(row.office);

    localStorage.setItem("activeLinkLevel", row.activeLinkLevel);
    this.props.setClientInfoFormDataToReducer(row.activeLinkLevel);
    this.props.globalStateChanged();
    let path = manageLandingPage(row.office, "edit", row.activeLinkLevel);
    this.props.history.push({
      pathname: path,
    });
  };
  handleRowSelection = (selectedRows) => {
    this.setState({ selectedRows: selectedRows });
  };
  deleteRecords = (rows) => {
    let array = [];
    rows.selectedRows.length > 0 &&
      rows.selectedRows.map((row) => {
        let obj = {};
        obj["id"] = row.id;
        array.push(obj);
      });
    this.setState({ isLoading: true });
    this.props
      .deleteClientInformation(array)
      .then((res) => {
        this.setState({ isLoading: false });
        let successfulCount = 0,
          failedCount = 0;
        if (res.data?.length > 0) {
          res.data.map((record) => {
            if (record.portalErrorCode === 200) {
              successfulCount++;
            } else {
              failedCount++;
            }
          });
          successfulCount > 0 &&
            NotificationManager.success(
              `${successfulCount} ${
                successfulCount === 1 ? `record ` : `records`
              } ${`deleted successfully`}`
            );
          failedCount > 0 &&
            NotificationManager.error(
              `Error while deleting ${failedCount} ${
                failedCount === 1 ? `record.` : `records.`
              }`
            );
          this.setState({
            selectedRows: [],
            toggleCleared: !this.state.toggleCleared,
          });
          this.props.getClientInformationListDetails().then((res) => {
            this.setState({ isLoading: false });
            if (res.data.portalErrorCode === 200) {
              this.setState({
                originalData: res.data.result,
                data: res.data.result,
              });
            } else {
              NotificationManager.error("Error while loading list");
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData = this.state.originalData.filter((value) => {
      return (
        value.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.clientName?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.office?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.status
          ?.toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      );
    });
    this.setState({ data: filteredData });
  };
  handleSearch = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  modalOpen = (row, choice) => {
    if (choice === "Single") {
      this.setState({ modal: true, singleDelete: true, deleteRecord: row });
    } else if (choice === "Multiple") {
      this.setState({
        modal: true,
        multipleDelete: true,
        deleteRecordsArray: row,
      });
    }
  };

  modalClose = () => {
    this.setState({
      modal: false,
    });
  };

  handleYes = () => {
    this.deleteRecords(this.state.selectedRows);
    this.modalClose();
  };
  handleNo = () => {
    this.modalClose();
  };

  OfficeFilter = () => {
    if (selectedOffices.length !== 0) {
      let filteredData = [];
      this.state.originalData.filter((item) => {
        selectedOffices.forEach((element) => {
          if (item.office.includes(element.name)) {
            filteredData.push(item);
          }
        });
      });
      const ids = filteredData.map((o) => o.id);
      const filtered = filteredData.filter(
        ({ id }, index) => !ids.includes(id, index + 1)
      );
      if (filtered.length !== 0) {
        this.setState({ data: filtered });
      }
    } else {
      this.setState({ data: this.state.originalData });
    }
  };

  handleClick = (e) => {
    let clickedValue = e.target.value;
    let clickedChecked = e.target.checked;
    const form = document.querySelector("#checkboxes");
    const checkboxs = form.querySelectorAll("input[type=checkbox]");

    if (clickedValue === "Clear All" && clickedChecked) {
      selectedOffices = [];
      checkboxs.forEach((element) => {
        if ((element.checked = true && element.name !== "Clear All")) {
          element.checked = false;
        } else {
          element.checked = true;
        }
      });
    } else if (clickedValue === "Clear All" && !clickedChecked) {
      checkboxs.forEach((element) => {
        if ((element.checked = true && element.name === "Clear All")) {
          element.checked = false;
        }
      });
    } else if (clickedValue !== "Clear All" && clickedChecked) {
      selectedOffices.push({ name: clickedValue });
      checkboxs.forEach((element) => {
        if (element.name === "Clear All") {
          element.checked = false;
        }
      });
    } else if (clickedValue !== "Clear All" && !clickedChecked) {
      selectedOffices.splice(
        selectedOffices.findIndex((v) => v.name === clickedValue),
        1
      );
    }
    this.OfficeFilter();
  };

  subHeaderFilterComponent = () => {
    const options = [];

    if (allOffices !== undefined) {
      allOffices.forEach((element) => {
        options.push(element);
      });
    }
    options.push({ name: "Clear All" });

    return (
      <div id="checkboxes">
        {options.map((option) => (
          <label>
            <input
              onClick={this.handleClick}
              type="checkbox"
              name={option.name}
              key={option.name}
              value={option.name}
            />{" "}
            <div id="filterName">{option.name}</div>
          </label>
        ))}
      </div>
    );
  };

  render() {
    const { columns, data, selectedRows, searchInput, toggleCleared, columnsRejectionHistory, rejectionHistory } =
      this.state;
    const conditionalRowStyles = [
      {
        when: (row) => row.status === "Completed",
        classNames: ["disabled"],
        style: {
          backgroundColor: "#f5f6f7 !important",
          cursor: "not-allowed",
          pointerEvents: "none",
        },
      },
    ];
    const rowDisabledCriteria = (row) => row.status === "Completed";
    return (
      <>
        <div className="dashboard external-dashboard">
          <div className="generic-form border-0">
            <Container fluid>
              <Row className="eventHeader">
                <Col sm={9} className="pt-0 pb-0 pl-0">
                  <h2 className="m-0 p-0 pagetitle">
                    Client Information forms
                  </h2>
                  <p className="pt-2 mb-1">
                    List of the united forms assigned to you, Your available
                    actions are shown for each form
                  </p>
                </Col>
                <Col sm={3} className="text-right add-button">
                  <Link to="/add-clientinformation" className="action-btn big">
                    Create Form
                  </Link>
                </Col>
              </Row>
            </Container>
            <Row className="pt-3 pb-2">
              <Col lg={6} className="pb-2">
                {selectedRows?.selectedRows?.length > 0 && (
                  <button
                    className="action-btn delete"
                    onClick={() => {
                      let selectedlength =
                        selectedRows?.selectedRows.length === 1
                          ? "Single"
                          : "Multiple";
                      this.modalOpen(
                        selectedRows?.selectedRows,
                        selectedlength
                      );
                    }}
                  >
                    Delete
                  </button>
                )}
              </Col>
              <Col lg={6} className="position-relative">
                <div className="datatableSearch">
                  <input
                    type="text"
                    name="searchInput"
                    value={searchInput || ""}
                    onChange={this.handleSearch}
                    placeholder="Search"
                  />
                  <i className="fa fa-search search-bar-icon"></i>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <DataTable
                  className="datatable"
                  columns={columns}
                  data={data}
                  onSelectedRowsChange={this.handleRowSelection}
                  sortFunction={customSort}
                  selectableRows
                  pagination
                  conditionalRowStyles={conditionalRowStyles}
                  clearSelectedRows={toggleCleared}
                  selectableRowDisabled={rowDisabledCriteria}
                />
              </Col>
            </Row>
          </div>
          <Modal
            show={this.state.modal}
            handleClose={(e) => this.modalClose(e)}
          >
            <h4 className="mb-4">Are you sure you want to delete?</h4>

            <div className="form-group mb-0">
              <button
                onClick={(e) => this.handleYes(e)}
                type="button"
                className="action-btn noicon mr-2"
              >
                Yes
              </button>
              <button
                onClick={(e) => this.handleNo(e)}
                type="button"
                className="action-btn greybtn noicon"
              >
                No
              </button>
            </div>
          </Modal>
          <Modal
            customSize="55%"
            show={this.state.modalReject}
            handleClose={(e) => this.modalRejectClose(e)}
          >
            <DataTable
                  className="datatable"
                  columns={columnsRejectionHistory}
                  data={rejectionHistory}
                  onSelectedRowsChange={this.handleRowSelection}
                  pagination
                />
            </Modal>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getClientInformationListDetails: () =>
      dispatch(getClientInformationListDetails()),
    deleteClientInformation: (data) => dispatch(deleteClientInformation(data)),
    globalStateChanged: () => dispatch(globalStateChanged()),
    globalStateNotChanged: () => dispatch(globalStateNotChanged()),
    deleteClientCAF: (data) => dispatch(deleteClientCAF(data)),
    setClientInfoFormDataToReducer: (data) =>
      dispatch(setClientInfoFormDataToReducer(data)),
    getAllOffices: async () => dispatch(await getAllOffices()),
  };
};
export default connect(null, mapDispatchToProps)(clientInformationList);
