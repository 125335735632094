export const srcOfWealthArray = [
  {
    label: "Income employment",
    isChecked: false,
  },
  {
    label: "Savings- fixed deposit",
    isChecked: false,
  },
  {
    label: "Sale of property",
    isChecked: false,
  },
  {
    label: "Inheritance",
    isChecked: false,
  },
  {
    label: "Company sale",
    isChecked: false,
  },
  {
    label: "Insurance policy",
    isChecked: false,
  },
  {
    label: "Company profits",
    isChecked: false,
  },
  {
    label: "Retirement Income",
    isChecked: false,
  },
  {
    label: "Dividend payments",
    isChecked: false,
  },
  {
    label: "Gift",
    isChecked: false,
  },
  {
    label: "Other",
    isChecked: false,
  },
];
export const mainSrcOfWealthArray = [
  {
    label: "Income employment",
    isChecked: false,
  },
  {
    label: "Savings- fixed deposit",
    isChecked: false,
  },
  {
    label: "Sale of property",
    isChecked: false,
  },
  {
    label: "Inheritance",
    isChecked: false,
  },
  {
    label: "Company sale",
    isChecked: false,
  },
  {
    label: "Insurance policy",
    isChecked: false,
  },
  {
    label: "Company profits",
    isChecked: false,
  },
  {
    label: "Retirement Income",
    isChecked: false,
  },
  {
    label: "Dividend payments",
    isChecked: false,
  },
  {
    label: "Gift",
    isChecked: false,
  },
  {
    label: "Other",
    isChecked: false,
  },
];

export const fileAcceptType = ".doc,.docx,.odt,.pdf,.rtf,.tex,.txt,.wpd,.ods,.xls,.xlsm,.xlsx,.key,.odp,.pps,.ppt,.pptx,.jpeg,.jpg,.png";
