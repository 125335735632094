import React, { Component } from "react";
import "../../../../assets/css/style.scss";
import { InternalHeader } from "../header/InternalHeader";

export default class InternalHome extends Component {
  render() {
    return (
      <>
        <InternalHeader />
        <div className="homeBg d-flex align-items-center justify-content-center">
          <div className="punchline text-center">
            <h1>
              Welcome to <span>client</span> portal
            </h1>
            <p>Seamless service in finance and trust</p>
          </div>
        </div>
      </>
    );
  }
}
