import React, { Component } from "react";
import CheckBox from "../../../../../elements/CheckBox";
import FileUpload from "../../../../../elements/FileUpload";
import PhoneInputField from "../../../../../elements/PhoneInputField";
import TextBox from "../../../../../elements/TextBox";
import DropDown from "../../../../../elements/DropDown";

import { fileAcceptType } from "./Constants";
class UboInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { index, baseName, errorMsg, form } = this.props;
    return (
      <div className="mb-4 col-lg-12">
        <fieldset className="eqheight">
          <legend>UBO {index + 1}</legend>
          <div className="row mb-0">
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Name"
                placeholder="Enter name"
                name="uboXName"
                id={`uboXName[${index}]`}
                index={index}
                baseName={baseName}
                reducer="commonFields"
                isCommon={true}
                objBaseName={this.props.objBaseName}
                validationType="OnlyLetter"
                disabled={this.props.from !== "generic" ? true : false}
                isRequired={true}
              />
              {errorMsg !== null &&
                Object.entries(errorMsg).length !== 0 &&
                errorMsg.ubox &&
                errorMsg.ubox[index] &&
                errorMsg.ubox[index]?.uboXName?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorMsg.ubox[index]?.uboXName}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Email"
                placeholder="Enter email"
                name="uboEmail"
                id={`uboEmail[${index}]`}
                index={index}
                baseName={baseName}
                reducer="commonFields"
                isCommon={true}
                objBaseName={this.props.objBaseName}
                validationType="Email"
                ShowValidation={
                  errorMsg !== null &&
                  Object.entries(errorMsg).length !== 0 &&
                  errorMsg.ubox &&
                  errorMsg.ubox[index] &&
                  errorMsg.ubox[index]?.uboEmail?.length > 0
                    ? false
                    : true
                }
                disabled={this.props.from !== "generic" ? true : false}
                isRequired={true}
              />
              {errorMsg !== null &&
                Object.entries(errorMsg).length !== 0 &&
                errorMsg.ubox &&
                errorMsg.ubox[index] &&
                errorMsg.ubox[index]?.uboEmail?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorMsg.ubox[index]?.uboEmail}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-4" id={`uboPhoneNum[${index}]`}>
              <PhoneInputField
                label="Phone Number"
                name="uboPhoneNum"
                index={index}
                baseName={baseName}
                reducer="commonFields"
                isCommon={true}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={this.props.from !== "generic" ? true : false}
                country={form}
                isRequired={true}
              />
              {errorMsg !== null &&
                Object.entries(errorMsg).length !== 0 &&
                errorMsg.ubox &&
                errorMsg.ubox[index] &&
                errorMsg.ubox[index]?.uboPhoneNum?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorMsg.ubox[index]?.uboPhoneNum}
                  </span>
                )}
            </div>

            <div className="mb-3 col-lg-4">
              <CheckBox
                label="Is the UBO a PEP?"
                options={["Yes", "No"]}
                name="isUBOPEP"
                id={`isUBOPEP[${index}]`}
                index={index}
                baseName={baseName}
                reducer="commonFields"
                isCommon={true}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                //disabled={this.props.from !== "generic" ? true : false}
                bothDisabled={this.props.from !== "generic" ? true : false}
              />
              {errorMsg !== null &&
                Object.entries(errorMsg).length !== 0 &&
                errorMsg.ubox &&
                errorMsg.ubox[index] &&
                errorMsg.ubox[index]?.isUBOPEP?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorMsg.ubox[index]?.isUBOPEP}
                  </span>
                )}
            </div>
            {this.props.from !== "generic" && (
              <>
              <div className="mb-2 col-lg-4">
              <DropDown
                        label="Status"
                        name="status"
                        options={[
                          { value: 'PENDING', label: 'PENDING' },
                          { value: 'TO BE DECIDED', label: 'TO BE DECIDED' },
                        ]}
                        //id={`typeOfDirector[${i}]`}
                        chapter={this.props.chapter}
                        baseName="objCompDirectorNames"
                        index={index}
                       id={`status[${index}]`}
                        baseName={baseName}
                      reducer="commonFields"
                       isCommon={true}
                      objBaseName={this.props.objBaseName}
                      //isRequired={true}
                        validationType="Text"
                       
                      />  
                </div>
              <div className="mb-2 col-lg-4">
                <FileUpload
                  label={"Certified True Copy Passport"}
                  acceptType={fileAcceptType}
                  name="certifiedPassportUBO"
                  index={index}
                  id={`certifiedPassportUBO[${index}]`}
                  baseName={baseName}
                  reducer="commonFields"
                  isCommon={true}
                  objBaseName={this.props.objBaseName}
                  //isRequired={true}
                />
                {errorMsg !== null &&
                  Object.entries(errorMsg).length !== 0 &&
                  errorMsg.ubox &&
                  errorMsg.ubox[index] &&
                  errorMsg.ubox[index]?.certifiedPassportUBO?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorMsg.ubox[index]?.certifiedPassportUBO}
                    </span>
                  )}
              </div>
              </>
            )}
          </div>
        </fieldset>
      </div>
    );
  }
}

export default UboInfo;
