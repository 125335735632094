import React, { Component } from "react";
import {
  setDataToReducer,
  manageNewState,
} from "../../redux/actions/ClientInformationAction";
import Modal from "../components/shared/Modal";
import { connect } from "react-redux";
import { manageData, getReducer } from "./getReducer";
import { ManageDataOnSelection } from "./ManageDataOnSelection";
import { deleteUBO } from "../../redux/actions/userActions";
import Tooltip from "./Tooltip";

class CheckBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      value: "",
    };
  }

  modalOpen = (e) => {
    this.setState({ isModalOpen: true, value: e.target.value });
  };

  modalClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleNo = () => {
    this.modalClose();
  };

  handleYes = (e) => {
    this.modalClose();
    let data = this.props.state[0];
    if (this.props.name === "isUBO") {
      let ubos = data.ubo.ubox;
      for (let i = 0; i < ubos.length; i++) {
        this.props.deleteUBO({ id: ubos[i].uboId });
      }
    } else if (this.props.name === "isPEP") {
      let peps = data.pep.pepx;
      for (let i = 0; i < peps.length; i++) {
        this.props.deleteUBO({ id: peps[i].pepId });
      }
    }
    this.handleChange(this.state.value);
  };

  setValue = (e) => {
    this.setState({ value: e.target.value });
    this.handleChange(e.target.value);
  };

  handleChange = (value) => {
    if (this.props.state && this.props.dependentReducer) {
      for (let i = 0; i < this.props.dependentReducer.length; i++) {
        let newState = ManageDataOnSelection(
          this.props.name,
          value,
          this.props.dependentReducer[i],
          this.props.state[i]
        );

        this.props.manageNewState({
          type: manageData(this.props.dependentReducer[i]),
          state: newState,
        });
      }
    }

    this.props.setDataToReducer({
      type: getReducer(this.props.reducer),
      chapter: this.props.chapter,
      index: this.props.index,
      name: this.props.name,
      value: value,
      baseName: this.props.baseName,
      objBaseName: this.props.objBaseName,
      indexObjBaseName:this.props.indexObjBaseName,
      nestedObjBaseName: this.props.nestedObjBaseName,
    });
  };

  render() {
    const {
      label,
      name,
      id,
      options,
      info,
      index,
      checkBoxValue,
      defaultValue,
      isRequired,
    } = this.props;
    return (
      <>
        <label>
          {label} {isRequired && <span style={{ color: "red" }}>*</span>}
        </label>

        {info && <Tooltip text={info}></Tooltip>}
        <div className="options">
          {options.map((option) => {
            return (
              <label
                key={option}
                className={
                  ((checkBoxValue !== "" &&
                    this.props.disabled &&
                    option !== checkBoxValue) ||
                    this.props.bothDisabled) &&
                  "disabledCheckbox"
                }
              >
                {index ? (
                  <input
                    id={id}
                    type="radio"
                    name={name + index}
                    value={option}
                    disabled={this.props.bothDisabled}
                    onChange={(e) => {
                      this.props.confirmation && option === "No"
                        ? this.modalOpen(e)
                        : this.setValue(e);
                    }}
                    checked={
                      defaultValue
                        ? this.props.defaultValue === option || false
                        : this.props.value === option || false
                    }
                  />
                ) : (
                  <input
                    type="radio"
                    id={id}
                    disabled={
                      (checkBoxValue !== "" &&
                        this.props.disabled &&
                        option !== checkBoxValue) ||
                      this.props.bothDisabled
                    }
                    name={
                      this.props.isMultiple ? name + this.props.chapter : name
                    }
                    value={option}
                    onChange={(e) => {
                      this.props.confirmation && option === "No"
                        ? this.modalOpen(e)
                        : this.setValue(e);
                    }}
                    checked={
                      defaultValue
                        ? this.props.defaultValue === option || false
                        : this.props.value === option || false
                    }
                  />
                )}

                <img alt="" />
                <span className="ml-2">{option}</span>
              </label>
            );
          })}
        </div>
        <Modal
          show={this.state.isModalOpen}
          handleClose={(e) => this.modalClose(e)}
        >
          <h4 className="mb-4">
            Are you sure you want to change the selection?
            <br /> If you change the selection your filled data will be lost
          </h4>

          <div className="form-group mb-0">
            <button
              onClick={(e) => this.handleYes(e)}
              type="button"
              className="action-btn noicon mr-2"
            >
              Yes
            </button>
            <button
              onClick={(e) => this.handleNo(e)}
              type="button"
              className="action-btn greybtn noicon"
            >
              No
            </button>
          </div>
        </Modal>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
    manageNewState: (data) => dispatch(manageNewState(data)),
    deleteUBO: (data) => dispatch(deleteUBO(data)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    name,
    chapter,
    baseName,
    index,
    objBaseName,
    reducer,
    nestedObjBaseName,
    isCommon,
    indexObjBaseName
  } = ownProps;

  if (isCommon) {
    if (!objBaseName && !baseName) {
      return {
        value: state[reducer][name],
      };
    } else if (objBaseName && nestedObjBaseName) {
      return {
        value: state[reducer][objBaseName][nestedObjBaseName][name],
      };
    } else if (objBaseName && !baseName) {
      return {
        value: state[reducer][objBaseName][name],
      };
    } else if (objBaseName && baseName) {
      return {
        value: state[reducer][objBaseName][baseName][index][name],
      };
    }
  } else if (!baseName && !objBaseName) {
    return {
      value: state[reducer][chapter][name],
    };
  } else if (objBaseName && nestedObjBaseName) {
    return {
      value: state[reducer][chapter][objBaseName][nestedObjBaseName][name],
    };
  } else if (objBaseName && !baseName) {
    return {
      value: state[reducer][chapter][objBaseName][name],
    };
  }
  else if(objBaseName && baseName && indexObjBaseName) {
    return {
      value: state[reducer][chapter][objBaseName][indexObjBaseName][baseName][index][name],
    };
  }
  else if (objBaseName && baseName) {
    return {
      value: state[reducer][chapter][objBaseName][baseName][index][name],
    };
  } else {
         return {
           value: state[reducer][chapter][baseName][index][name],
    };
 
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckBox);
