import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import NumberInput from "../../../../../../elements/NumberInput";
import { UBOXArray } from "../../../../../../JSONs/UK-Form/External/Client-Information/Chapter3";
import UboDetails from "./UboDetails";

class UKUBOs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { noOfUBOs, errorMsg } = this.props;
    let uboinfo = [];
    for (let i = 0; i < noOfUBOs; i++) {
      uboinfo.push(
        <UboDetails
          index={i}
          chapter="chapter3"
          baseName="ubox"
          reducer={this.props.reducer}
          objBaseName="ubo"
          errorMsg={errorMsg}
          form="UK"
        />
      );
    }
    return (
      <Row className="pt-3 pb-3">
        <div className="mb-4 col-lg-4">
          <NumberInput
            label="How many UBO's are there?"
            name="noOfUBOs"
            chapter="chapter3"
            referral={["ubox"]}
            pushedObject={[UBOXArray]}
            maxLimit={10}
            reducer={this.props.reducer}
            objBaseName="ubo"
          />
          <div className="errorMsg"></div>
        </div>
        {uboinfo}
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer } = ownProps;
  return {
    noOfUBOs: state[reducer]["chapter3"]["ubo"]["noOfUBOs"],
  };
};

export default connect(mapStateToProps, null)(UKUBOs);
