import React, { Component } from "react";
import {
  changeFileURL,
  downloadFile,
  deleteFile,
} from "../../redux/actions/CAFGenericActions";
import { setDataToReducer } from "../../redux/actions/ClientInformationAction";
import { connect } from "react-redux";
import { saveAs } from "file-saver";
import { NotificationManager } from "react-notifications";
import { getReducer } from "./getReducer";
import Tooltip from "./Tooltip";
const validExtensions = [
  "doc",
  "docx",
  "odt",
  "pdf",
  "rtf",
  "tex",
  "txt",
  "wpd",
  "ods",
  "xls",
  "xlsm",
  "xlsx",
  "key",
  "odp",
  "pps",
  "ppt",
  "pptx",
  "jpeg",
  "jpg",
  "png",
];
class FileUpload extends Component {
   constructor(props) {
    super(props);
    this.state = {
      fileArray: [],
      user: JSON.parse(localStorage.getItem("user")),
      office: localStorage.getItem("Office"),
      companyName: localStorage.getItem("CompanyName"),
      companyId: localStorage.getItem("clientId"),
    };
  }
  componentDidMount = () => {
    if (this.props.value && this.props.value.length > 0) {
      this.setState({ fileArray: this.props.value });
    } else {
      this.setState({ fileArray: [] });
    }
  };

  handleFileChange = async (event, fileArray) => {
    const { user } = this.state;
    let array = [];
    if (this.props.value !== null) {
      array = this.props.value;
    }
    const newFiles = event.target.files;
    for (let i = 0; i < newFiles.length; i++) {
      let fname = newFiles[i].name;
      let extension = fname.split(".").pop();
      if (validExtensions.includes(extension)) {
      let formData = new FormData();
      formData.append(`file`, newFiles[i]);
      formData.append("UserEmail", user.email);
      formData.append("UserName", user.firstName + " " + user.lastName);
      formData.append("Office", this.state.office);
      formData.append("CompanyName", this.state.companyName);
      formData.append("CompanyId", this.state.companyId);
      await this.props.changeFileURL(formData).then(async (res) => {
        if (res.data.portalErrorCode === 200) {
          let newObject = {};
          newObject["fileName"] = newFiles[i].name;
          array.push(newObject);
          this.setState({
            fileArray: array,
          });
          NotificationManager.success(
            `${newFiles[i].name} uploaded successfully`
          );
        } else if (res.data.portalErrorCode === 500) {
          NotificationManager.error(`${newFiles[i].name} is already exist`);
        }
        else if (res.data.portalErrorCode === 400) {

          NotificationManager.error(`This file format is not supported.`);

        }
        else {
          NotificationManager.error(
            `Error while uploading ${newFiles[i].name}`
          );
        }
      
    
      });
    }
    else{
      NotificationManager.error(`.${extension} file format is not allowed.`);
    }

    }


    await this.props.setDataToReducer({
      type: getReducer(this.props.reducer),
      chapter: this.props.chapter,
      index: this.props.index,
      name: this.props.name,
      value: array,
      baseName: this.props.baseName,
      objBaseName: this.props.objBaseName,
      nestedObjBaseName: this.props.nestedObjBaseName,
      indexObjBaseName:this.props.indexObjBaseName
    });
  };

  handleDownload = (fileName) => {
    const { user } = this.state;
    let data = {
      fileName: fileName,
      office: this.state.office,
      userName: user.firstName + " " + user.lastName,
      userEmail: user.email,
      companyName: this.state.companyName,
      companyId: this.state.companyId,
    };
    this.props.downloadFile(data).then((result) => {
      var blob = new Blob([result.data]);
      let file = fileName;
      saveAs(blob, file);
    });
  };

  handleDelete = async (file) => {
    const { user } = this.state;
    const array = this.props.value || [];

    let data = {
      fileName: file,
      office: this.state.office,
      userName: user.firstName + " " + user.lastName,
      userEmail: user.email,
      companyName: this.state.companyName,
      companyId: this.state.companyId,
    };
    await this.props.deleteFile(data).then((res) => {
      if (res.data.portalErrorCode === 200) {
        NotificationManager.success(`${file} deleted successfully`);
        const index = array.findIndex((f) => f.fileName === file);
        if (index > -1) {
          array.splice(index, 1);
         if(index==0){
          let randomString = Math.random().toString(36);
          this.setState({
            theInputKey: randomString
          });
         }
        }
        this.setState({
          fileArray: array,
        });
             
      } else {
        NotificationManager.error(`Error while deleting ${file}`);
      }
    });
    await this.props.setDataToReducer({
      type: getReducer(this.props.reducer),
      chapter: this.props.chapter,
      index: this.props.index,
      name: this.props.name,
      value: array,
      baseName: this.props.baseName,
      objBaseName: this.props.objBaseName,
      indexObjBaseName:this.props.indexObjBaseName
    });
   
  };

  render() {
    const { label, info, acceptType, id, isRequired } = this.props;
    return (
      <>
        <label>
          {label}
          {isRequired && <span style={{ color: "red" }}>*</span>}
          {info && <Tooltip text={info}></Tooltip>}
        </label>
        <br />
        <div className="form-group files mb-0">
          <div className="row d-flex align-items-center">
            {!this.props.disabled && (
              <div className="col-lg-12 col-xl-12 mb-2">
                <input
                  type="file"
                  name="file"
                  id={id}
                  key={this.state.theInputKey || '' } 
                  disabled={this.props.disabled}
                  onChange={(e) => {
                    this.handleFileChange(e, this.props.value);
                  }}
                  accept={acceptType}
                  multiple
                />
                <p className="helptext mt-2 mb-0">
                  (.pdf,.doc,.jpeg,.ppt,.docx,.png)
                </p>
              </div>
            )}

            {this.props.value &&
              this.props.value.map((file, index) => {
                return (
                  <div className="col-lg-12 mb-1" key={index}>
                    <div
                      className="d-flex align-items-center upload-file-name w-100"
                      style={
                        this.props.disabled
                          ? { backgroundColor: "#e9ecef" }
                          : {}
                      }
                    >
                      <p
                        className="FileNameDisplay"
                        style={{ margin: "0", flexGrow: "1" }}
                        title={file.fileName}
                      >
                        {file.fileName}
                      </p>

                      {!this.props.disabled && (
                        <span className="fileDelete">
                          <i
                            className="fa fa-times-circle"
                            style={{
                              pointerEvents: this.props.disabled && "none",
                            }}
                            onClick={() => this.handleDelete(file.fileName)}
                          ></i>
                        </span>
                      )}

                      <span className="fileDownload">
                        <i
                          className="fa fa-download"
                          onClick={() => this.handleDownload(file.fileName)}
                        ></i>
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    name,
    chapter,
    baseName,
    index,
    objBaseName,
    reducer,
    nestedObjBaseName,
    isCommon,
    indexObjBaseName
  } = ownProps;

  if (isCommon) {
    if (!objBaseName && !baseName) {
      return {
        value: state[reducer][name],
      };
    } else if (objBaseName && nestedObjBaseName) {
      return {
        value: state[reducer][objBaseName][nestedObjBaseName][name],
      };
    } else if (objBaseName && !baseName) {
      return {
        value: state[reducer][objBaseName][name],
      };
    } else if (objBaseName && baseName) {
      return {
        value: state[reducer][objBaseName][baseName][index][name],
      };
    }
  } else if (!baseName && !objBaseName) {
    return {
      value: state[reducer][chapter][name],
    };
  } else if (objBaseName && nestedObjBaseName) {
    return {
      value: state[reducer][chapter][objBaseName][nestedObjBaseName][name],
    };
  } else if (objBaseName && !baseName) {
    return {
      value: state[reducer][chapter][objBaseName][name],
    };
  } 
  else if(objBaseName && baseName && indexObjBaseName) {
    return {
      value: state[reducer][chapter][objBaseName][indexObjBaseName][baseName][index][name],
    };
  }
  else if (objBaseName && baseName) {
    return {
      value: state[reducer][chapter][objBaseName][baseName][index][name],
    };
  } else {
    return {
      value: state[reducer][chapter][baseName][index][name],
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeFileURL: (data) => dispatch(changeFileURL(data)),
    downloadFile: (data) => dispatch(downloadFile(data)),
    deleteFile: (data) => dispatch(deleteFile(data)),
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
