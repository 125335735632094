import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import countryList from "react-select-country-list";
import CheckBox from "../../../../elements/CheckBox";
import TextBox from "../../../../elements/TextBox";
import DownloadFile from "../../../../elements/DownloadFile";
import MultiSelectDropdown from "../../../../elements/MultiSelectDropdown";
import DropDown from "../../../../elements/DropDown";

class Authorised extends React.Component {
    render() {
        const { index, chapter, reducer, authorised, baseName, errors } =
            this.props;
        const errorsMsg = JSON.parse(errors);
        return (
            <Row>
                <div className="mb-4 col-lg-12">
                    <fieldset>
                        <legend>
                            Autorised person {index + 1}
                        </legend>
                        <Row>
                            <div className="mb-2 col-lg-8">
                                <TextBox
                                    label="Name of authorized person"
                                    placeholder="Enter authorized person Name"
                                    name="authrzdPrsnName"
                                    index={index}
                                    chapter={chapter}
                                    baseName={baseName}
                                    reducer={this.props.reducer}
                                    validationType="Text"
                                    disabled={true}
                                />
                            </div>
                            <div className="mb-2 col-lg-6">
                                <DropDown
                                    label="Nationality of authorized person"
                                    name="authrzdPrsnNationality"
                                    options={countryList().getData()}
                                    index={index}
                                    chapter={chapter}
                                    baseName={baseName}
                                    reducer={this.props.reducer}
                                    disabled={true}
                                />

                            </div>
                            <div className="mb-2 col-lg-6">
                                <DownloadFile
                                    label="Upload passport"
                                    name="authrzdPrsnPassport"
                                    value={authorised.authrzdPrsnPassport}
                                    index={index}
                                    chapter={chapter}
                                    baseName={baseName}
                                    reducer={this.props.reducer}
                                />
                            </div>
                            <div className="mb-2 col-lg-6">
                                <DownloadFile
                                    label="Upload authorization letter"
                                    name="authrzdPrsnAuthLetter"
                                    value={authorised.authrzdPrsnAuthLetter}
                                    index={index}
                                    chapter={chapter}
                                    baseName={baseName}
                                    reducer={this.props.reducer}
                                />
                            </div>
                            <div className="mb-2 col-lg-6">
                                <DownloadFile
                                    label="Upload proof of address"
                                    name="authrzdPrsnPOA"
                                    value={authorised.authrzdPrsnPOA}
                                    index={index}
                                    chapter={chapter}
                                    baseName={baseName}
                                    reducer={this.props.reducer}
                                />

                            </div>

                        </Row>

                    </fieldset>
                </div>
            </Row>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    const { baseName, index, reducer, chapter } = ownProps;
    return {
        authorised: state[reducer][chapter][baseName][index],
    };
};

export default connect(mapStateToProps, null)(Authorised);
