import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import AnnexNoUBO from "../UBO/AnnexNoUBO/AnnexNoUBO";
import AnnexUBO from "../UBO/AnnexUBO/AnnexUBO";
import UboDeclarationUIMDashboard from "./UboDeclarationUIMDashboard";
import jwt_decode from "jwt-decode";
import { setUBOUIMDetails } from "../../../../../redux/actions/UBOdeclarationUIMactions";
import { setNewAnnexData } from "../../../../../redux/actions/UBOInformationAction";
import {
  getUBOUIMDetails,
  setUBOUIMToReducer,
  setCommonFromUBOPEP,
} from "../../../../../redux/actions/UBOdeclarationUIMactions";
import {
  setAnnexToReducer,
  GenerateURL,
} from "../../../../../redux/actions/UBOInformationAction";
import AutoSaving from "./AutoSaving";
import Doc from "../../../../elements/DocService";
import PdfContainer from "../../../../elements/PdfContainer";
import NotificationManager from "react-notifications/lib/NotificationManager";
import Validation from "./FormValidation";
import "./print.scss";
import { getFormNameFromOffice } from "./getFormNameFromOffice";
import { getFirstUnfilledFieldExternal } from "../UKOffice/getFirstUnfilledField";
import getSequentialFields from "./getSequentialFields";

const formValid = (formErrors) => {
  let valid = true;
  if (formErrors) {
    if (isObject(formErrors)) {
      Object.values(formErrors).forEach(function (val) {
        if (val !== undefined) {
          if (Array.isArray(val)) {
            val.forEach(function (errors) {
              Object.values(errors).forEach(function (val) {
                val.length > 0 && (valid = false);
              });
            });
          } else if (isObject(val)) {
            Object.values(val).forEach(function (element) {
              element.length > 0 && (valid = false);
            });
          } else {
            val.length > 0 && (valid = false);
          }
        }
      });
    } else {
      formErrors.length > 0 && (valid = false);
    }
  }
  return valid;
};
function isObject(objValue) {
  return (
    objValue && typeof objValue === "object" && objValue.constructor === Object
  );
}
class UBOPEPFormDashboard extends Component {
  constructor(props) {
    super(props);
    this.UBO = React.createRef();
    this.UBO2 = React.createRef();
    this.PEP = React.createRef();
    this.AnnexUBO = React.createRef();
    this.AnnexNoUBO = React.createRef();
    this.setRefPEP = this.setRefPEP.bind(this);
    this.setRefUBO = this.setRefUBO.bind(this);
    this.setRefUBO2 = this.setRefUBO2.bind(this);

    this.state = {
      uboEmail: null,
      uboName: null,
      isLoading: false,
      isUBO: null,
      uboId: null,
      isPEP: null,
      isUBOPEP: null,
      formId: null,
      office: null,
      ErrorsAnnexNoUBO: {},
      ErrorsUBOPEP: {},
      ErrorAnnexUBO: {},
      classDisplayFlag: false,
      disableButton: false,
      isValid: false,
    };
  }

  disableBack = () => {
    window.history.forward();
  };

  componentDidMount = () => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function (event) {
      window.history.go(1);
    };
    let token = JSON.parse(localStorage.getItem("ubo-form-token"));
    let decoded;
    if (token) {
      decoded = jwt_decode(token);

      this.setState({
        isUBO: decoded.IsUBO,
        isPEP: decoded.IsPEP,
        isUBOPEP: decoded.IsUBOPEP,
        formId: decoded.FormId,
        uboId: decoded.UBOId,
        userId: decoded.UserId,
        clientId: decoded.ClientId,
        office: getFormNameFromOffice(decoded.Office),
        AuthToken: decoded.AuthToken,
      });
    }
    let user = {
      authToken: decoded.AuthToken,
      firstname: decoded.UserFirstName,
      lastname: decoded.UserLastName,
      email: decoded.UserEmail,
    };
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("CompanyName", decoded.ClientName);
    localStorage.setItem("Office", decoded.Office);
    localStorage.setItem("clientId", decoded.ClientId);

    let data = {
      id: decoded.UBOId,
      formId: decoded.FormId,
    };

    this.props.getUBOUIMDetails(data).then((res) => {
      let UBO_data = res.data?.result?.uBOUIMEmailCommonModel;
      let common_fields = res.data?.result?.commonFields;
      if (this.state.isUBO === "Yes") {
        let UBO = {};
        common_fields.ubo.ubox.map((ubo) => {
          if (ubo.uboId === decoded.UBOId) {
            UBO = ubo;
          }
        });
        let uboDetails = UBO_data.partA.uboDetails;
        uboDetails = {
          ...uboDetails,
          ...UBO,
        };

        UBO_data.partA.uboDetails = uboDetails;
        UBO_data.name = uboDetails.uboXName;

        this.props.AnnexUBOInformation.AnnexUBO.name = uboDetails.uboXName;
        this.setState({
          uboEmail: uboDetails.uboEmail,
          uboName: uboDetails.uboXName,
        });
      }
      if (this.state.isUBO === "No" && this.state.isPEP === "Yes") {
        let PEP = {};
        common_fields.pep.pepx.map((pep) => {
          if (pep.pepId === decoded.UBOId) {
            PEP = pep;
          }
        });
        let pepDetails = UBO_data.partA.pepDetails;
        pepDetails = {
          ...pepDetails,
          ...PEP,
        };
        UBO_data.partA.pepDetails = pepDetails;
        UBO_data.name = pepDetails.pepName;
        this.setState({
          uboEmail: pepDetails.pepEmail,
          uboName: pepDetails.pepName,
        });
      }

      if (this.state.isUBO === "No" && this.state.isPEP === "No") {
        this.setState({
          uboEmail: res.data?.result?.commonFields["upc"]["upcAuthDirEmail"],
          uboName: res.data?.result?.commonFields["upc"]["upcAuthDirName"],
        });
      }
      if (common_fields) {
        common_fields["dirty"] = false;
      }
      if (UBO_data) {
        UBO_data["dirty"] = false;
        UBO_data.date = new Date();
      }
      let annex_data = res.data?.result?.uBODeclarationsModel;

      if (annex_data) {
        annex_data["isUBO"] = decoded.IsUBO;
        annex_data["name"] = this.state.uboName;
        annex_data["dirty"] = false;
        annex_data.date = new Date();
      }
      this.props.setUBOUIMToReducer({
        data: UBO_data,
      });

      this.props.setAnnexToReducer({
        data: annex_data,
      });
      this.props.setCommonFromUBOPEP({
        data: common_fields,
      });
    });

    //  this.props.getCommonFields("decoded.FormId");
  };
  setRefPEP(element) {
    this.PEP = element;
  }
  setRefUBO(element) {
    this.UBO = element;
  }
  setRefUBO2(element) {
    this.UBO2 = element;
  }

  createPdf = async () => {
    //api call for final saving data
    let requestData = {};
    let apiCallFlag = false;
    let successSetDataAPI = false;
    let annex_call_flag = false;
    if (this.props.UBODeclarationUIM.dirty) {
      apiCallFlag = true;
      delete this.props.UBODeclarationUIM["dirty"];
      requestData["uBOUIMEmailCommonModel"] = this.props.UBODeclarationUIM;
    }
    let uBODeclarationsModel =
      this.props.isUBO === "Yes"
        ? this.props.AnnexUBOInformation.AnnexUBO
        : this.props.AnnexUBOInformation.AnnexNoUBO;
    if (uBODeclarationsModel.dirty) {
      apiCallFlag = true;
      annex_call_flag = true;
      delete uBODeclarationsModel["dirty"];
      requestData["uBODeclarationsModel"] = uBODeclarationsModel;
    }
    let commonFields = this.props.commonFields;
    if (commonFields.dirty) {
      apiCallFlag = true;
      delete commonFields["dirty"];
      requestData["commonFields"] = commonFields;
    }
    requestData["id"] = this.props.uboId;
    requestData["formId"] = this.props.formId;
    requestData["userId"] = this.props.userId;
    requestData["clientId"] = this.props.clientId;
    requestData["office"] = this.props.office;
    requestData["status"] = false;
    let response = await Validation.ValidateClientInformation(
      this.state.isUBO,
      this.state.isPEP,
      this.state.isUBOPEP,
      this.props.UBODeclarationUIM,
      this.props.commonFields,
      this.props.AnnexUBOInformation.AnnexUBO,
      this.props.AnnexUBOInformation.AnnexNoUBO
    );
    this.setState({
      ErrorsAnnexNoUBO: response.formErrors.formErrorsAnnexNoUBO,
      ErrorsUBOPEP: response.formErrors.ErrorsUBOPEP,
      ErrorAnnexUBO: response.formErrors.formErrorsAnnexUBO,
    });

    if (
      formValid(this.state.ErrorsAnnexNoUBO) &&
      formValid(this.state.ErrorsUBOPEP) &&
      formValid(this.state.ErrorAnnexUBO)
    ) {
      this.setState({ isValid: true });
      this.setState({ isLoading: true });
      if (apiCallFlag) {
        this.props.setUBOUIMDetails(requestData).then((res) => {
          if (res.data?.portalErrorCode === 200) {
            successSetDataAPI = true;
            this.props.UBODeclarationUIM["dirty"] = false;
            this.props.commonFields["dirty"] = false;
            uBODeclarationsModel["dirty"] = false;
            if (annex_call_flag) {
              this.props.setNewAnnexData({
                isUBO: this.props.isUBO,
                newData: uBODeclarationsModel,
              });
            }
          }
        });
      }

      //to generate pdf
      if ((successSetDataAPI && apiCallFlag) || !apiCallFlag) {
        this.setState({ classDisplayFlag: true });
        const formData = new FormData();
        formData.append("Id", this.state.uboId);
        formData.append("Name", this.state.uboName);
        formData.append("Email", this.state.uboEmail);
        let pdfAnnexUBO;
        let pdfAnnexNoUBO;

        if (this.state.isUBO === "Yes" && this.state.isPEP === "Yes") {
          let PEPhtml = document.getElementById("pdfCreation");
          PEPhtml.append(this.UBO);
          PEPhtml.append(this.PEP);
          PEPhtml.append(this.UBO2);
          let UBOhtml = document.getElementById("pdfUBOCreation");
          UBOhtml.append(this.UBO);
          UBOhtml.append(this.UBO2);
          let pdfUBO = await Doc.createPdf(UBOhtml);
          let pdfPEP = await Doc.createPdf(PEPhtml);
          formData.append(`Ubofile`, pdfUBO, "Ubofile.pdf");
          formData.append(`Pepfile`, pdfPEP, "Pepfile.pdf");
          const htmlAnnexUBO = this.AnnexUBO.current;
          pdfAnnexUBO = await Doc.createPdf(htmlAnnexUBO);
          formData.append(`Annexubofile`, pdfAnnexUBO, "Annexubofile.pdf");
        } else if (this.state.isUBO === "Yes" && this.state.isPEP === "No") {
          let UBOhtml = document.getElementById("pdfCreation");
          UBOhtml.append(this.UBO);
          if (this.state.isUBOPEP === "Yes") {
            UBOhtml.append(this.PEP);
          }
          UBOhtml.append(this.UBO2);
          let pdfUBO = await Doc.createPdf(UBOhtml);
          formData.append(`Ubofile`, pdfUBO, "Ubofile.pdf");
          const htmlAnnexUBO = this.AnnexUBO.current;
          pdfAnnexUBO = await Doc.createPdf(htmlAnnexUBO);
          formData.append(`Annexubofile`, pdfAnnexUBO, "Annexubofile.pdf");
        } else if (this.state.isUBO === "No" && this.state.isPEP === "Yes") {
          let PEPhtml = document.getElementById("pdfCreation");
          PEPhtml.append(this.UBO);
          PEPhtml.append(this.PEP);
          PEPhtml.append(this.UBO2);
          let pdfPEP = await Doc.createPdf(PEPhtml);
          formData.append(`Pepfile`, pdfPEP, "Pepfile.pdf");
        } else if (this.state.isUBO === "No" && this.state.isPEP === "No") {
          const htmlAnnexNoUBO = this.AnnexNoUBO.current;
          pdfAnnexNoUBO = await Doc.createPdf(htmlAnnexNoUBO);
          formData.append(`Noubofile`, pdfAnnexNoUBO, "Noubofile.pdf");
        }

        this.props
          .GenerateURL(formData)
          .then((res) => {
            this.setState({ isLoading: false });
            if (res.data?.portalErrorCode === 200) {
              window.location = res.data.result;
            }
          })
          .catch(this.catchError);
      }
    } else {
      this.setState({ isValid: false });
      let fields = [];
      if (this.state.ErrorsUBOPEP) {
        const newErrorsUBOPEP = await getSequentialFields(
          this.state.ErrorsUBOPEP,
          "UBOPEP"
        );
        const firstFieldsUBOPEP = await getFirstUnfilledFieldExternal(
          newErrorsUBOPEP?.errorChapter
        );

        if (firstFieldsUBOPEP) {
          fields.push(firstFieldsUBOPEP);
        }
      }

      if (this.state.ErrorsAnnexNoUBO) {
        const newErrorsAnnexNoUBO = await getSequentialFields(
          this.state.ErrorsAnnexNoUBO,
          "AnnexNoUBO"
        );
        const firstFieldsAnnexNoUBO = await getFirstUnfilledFieldExternal(
          newErrorsAnnexNoUBO?.errorChapter
        );
        if (firstFieldsAnnexNoUBO) {
          fields.push(firstFieldsAnnexNoUBO);
        }
      }

      if (this.state.ErrorAnnexUBO) {
        const newErrorsAnnexUBO = await getSequentialFields(
          this.state.ErrorAnnexUBO,
          "AnnexUBO"
        );
        const firstFieldsAnnexUBO = await getFirstUnfilledFieldExternal(
          newErrorsAnnexUBO?.errorChapter
        );
        if (firstFieldsAnnexUBO) {
          fields.push(firstFieldsAnnexUBO);
        }
      }

      this.setState({ firstFields: fields[0] ? fields[0] : null });

      // window.scrollTo(0, 0);
      console.log(fields[0]);
      if (fields[0] !== null && fields.length > 0) {
        document.getElementById(fields[0]).scrollIntoView({
          behavior: "smooth",
        });
      }
      NotificationManager.error("Please fill the mandatory fields");
    }
  };
  render() {
    const {
      isUBO,
      isPEP,
      isUBOPEP,
      formId,
      uboId,
      office,
      clientId,
      userId,
      uboName,
    } = this.state;
    const form = getFormNameFromOffice(localStorage.getItem("Office"));
    const formErrorsAnnexNoUBO = JSON.stringify(this.state.ErrorsAnnexNoUBO);
    const formErrorsUBOPEP = JSON.stringify(this.state.ErrorsUBOPEP);
    const formErrorsAnnexUBO = JSON.stringify(this.state.ErrorAnnexUBO);

    return (
      <>
        <AutoSaving
          isUBO={isUBO}
          isPEP={isPEP}
          isUBOPEP={isUBOPEP}
          formId={formId}
          uboId={uboId}
          office={office}
          clientId={clientId}
          userId={userId}
        />
        <div className="container">
          {this.state.isLoading && <div className="loader"></div>}
          <div className="dashboard w-100 external-dashboard">
            <div className="generic-form ubo-pepForm">
              <div style={{ display: "none" }} id="pdfUBOCreation"></div>
              <div className="pdfCreation" id="pdfCreation"></div>
              <PdfContainer
                createPdf={this.createPdf}
                className="PDFexportCSS"
                classDisplayFlag={this.state.classDisplayFlag}
                disableButton={this.state.disableButton}
              >
                {isUBO === "No" && isPEP === "No" ? null : (
                  <UboDeclarationUIMDashboard
                    isUBO={isUBO}
                    isPEP={isPEP}
                    isUBOPEP={isUBOPEP}
                    setRefPEP={this.setRefPEP}
                    setRefUBO={this.setRefUBO}
                    setRefUBO2={this.setRefUBO2}
                    errorMsg={formErrorsUBOPEP}
                    name={uboName}
                    form={form}
                    classDisplayFlag={this.state.classDisplayFlag}
                  />
                )}
                <div ref={this.UBO}></div>
                {this.state.isUBO === "No" && this.state.isPEP !== "Yes" && (
                  <div ref={this.AnnexNoUBO} className="printPDFUBO">
                    <AnnexNoUBO
                      errorMsg={formErrorsAnnexNoUBO}
                      name={uboName}
                      form={form}
                    />
                  </div>
                )}

                {this.state.isUBO === "Yes" && (
                  <div ref={this.AnnexUBO} className="printPDFUBO">
                    <AnnexUBO
                      errorMsg={formErrorsAnnexUBO}
                      name={uboName}
                      form={form}
                    />
                  </div>
                )}
              </PdfContainer>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UBODeclarationUIM: state.UBODeclarationUIM,
    AnnexUBOInformation: state.AnnexUBOInformation,
    commonFields: state.commonFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUBOUIMDetails: (data) => dispatch(getUBOUIMDetails(data)),
    setUBOUIMToReducer: (data) => dispatch(setUBOUIMToReducer(data)),
    setAnnexToReducer: (data) => dispatch(setAnnexToReducer(data)),
    setCommonFromUBOPEP: (data) => dispatch(setCommonFromUBOPEP(data)),
    GenerateURL: (data) => dispatch(GenerateURL(data)),
    setUBOUIMDetails: (data) => dispatch(setUBOUIMDetails(data)),
    setNewAnnexData: (data) => dispatch(setNewAnnexData(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UBOPEPFormDashboard)
);
