import { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { connect } from "react-redux";
import { setAllErrorsToNull } from "../../redux/actions/InternalChapterErrorActions";
let formData;
let validForms = {};

class InternalSidebar extends Component {
  render() {
    const { location } = this.props.history;
    const { internalErrors } = this.props;
    Object.entries(internalErrors).forEach((errors) => {
      const [key, value] = errors;
      const error = value?.errors;
      if (error) {
        validForms[key] = error;
      }
    });

    setInterval(() => {
      if (!formData) {
        formData = localStorage.getItem("formData");
      }
    }, 1000);
    return (
      <div className="sidebar">
        <ul>
          <li>
            <label>Client Maintenance</label>
            {/* <Link to="/clientlist"  className={activeIndex === "clientlist" ? "active" : ""}>Client Maintainance</Link> */}
             <ul className="sidebar-innerlist">
              <li>
                <Link
                  to="/clientlist"
                  onClick={() => {
                    formData = null;
                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={
                    location.pathname === "/clientlist" ? "active" : ""
                  }
                >
                  <i className="fa fa-user fa-fw"></i> Client list
                </Link>
                <Link
                  to="/clientapprovals"
                  onClick={() => {
                    formData = null;
                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={
                    location.pathname === "/clientapprovals" ? "active" : ""
                  }
                >
                  <i className="fa fa-check-circle fa-fw"></i> Client User Approvals
                </Link>
              </li>
            </ul> 
          </li>
          <li>
            <label>User Maintenance</label>
            {/* <Link to="/userlist"  className={activeIndex === "userlist" ? "active" : ""}>User Maintainance</Link> */}
             <ul className="sidebar-innerlist">
             <li>
                <Link
                  to="/userlist"
                  onClick={() => {
                    formData = null;
                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={location.pathname === "/userlist" ? "active" : ""}
                >
                  <i className="fa fa-user fa-fw"></i> User list
                </Link>
                <Link
                  to="/userapprovals"
                  onClick={() => {
                    formData = null;
                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={
                    location.pathname === "/userapprovals" ? "active" : ""
                  }
                >
                  <i className="fa fa-check-circle fa-fw"></i> User Approvals
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <label>Client Acceptance Form</label>
         <ul className="sidebar-innerlist">
              <li>
                <Link
                  onClick={() => {
                    formData = null;
                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  to="/client-form-list"
                  className={
                    location.pathname === "/client-form-list" ? "active" : ""
                  }
                >
                  <i className="fa fa-user fa-fw"></i> Client Form List
                </Link>
              </li>
            </ul> 
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    internalErrors: state.InternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAllErrorToNull: () => dispatch(setAllErrorsToNull()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InternalSidebar)
);
