import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { connect } from "react-redux";
import { setAllMaltaErrorsToNull } from "../../../../redux/actions/Malta/MaltaInternalChapterErrorActions";
let formData;
let validForms = {};

class MaltaSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: null,
    };
  }
  render() {
    const { location } = this.props.history;
    const { internalErrors } = this.props;
    Object.entries(internalErrors).forEach((errors) => {
      const [key, value] = errors;
      const error = value?.errors;
      if (error) {
        validForms[key] = error;
      }
    });
    setInterval(() => {
      if (!formData) {
        formData = localStorage.getItem("formData");
        if (formData) {
          this.setState({ formData: formData });
        }
      }
    }, 100);
    return (
      <div className="sidebar">
        <ul>
          
          <li>
            <label>Client Maintenance</label>
            {/* <Link to="/clientlist"  className={activeIndex === "clientlist" ? "active" : ""}>Client Maintainance</Link> */}
            <ul className="sidebar-innerlist">
              <li>
                <Link
                  to="/clientlist"
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={
                    location.pathname === "/clientlist" ? "active" : ""
                  }
                >
                  <i className="fa fa-user fa-fw"></i> Client list
                </Link>
                <Link
                  to="/clientapprovals"
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={
                    location.pathname === "/clientapprovals" ? "active" : ""
                  }
                >
                  <i className="fa fa-check-circle fa-fw"></i> Client User Approvals
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <label>User Maintenance</label>

            <ul className="sidebar-innerlist">
              <li>
                <Link
                  to="/userlist"
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={location.pathname === "/userlist" ? "active" : ""}
                >
                  <i className="fa fa-user fa-fw"></i> User list
                </Link>
                <Link
                  to="/userapprovals"
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={
                    location.pathname === "/userapprovals" ? "active" : ""
                  }
                >
                  <i className="fa fa-check-circle fa-fw"></i> User Approvals
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <label>Client Acceptance Form</label>
            <ul className="sidebar-innerlist">
              <li>
                <Link
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  to="/client-form-list"
                  className={
                    location.pathname === "/client-form-list" ? "active" : ""
                  }
                >
                  <i className="fa fa-user fa-fw"></i> Client Form List
                </Link>
              </li>
              </ul>
              </li>
              <li>
              <ul className="sidebar-innerlist subPoint">
              <li>
                <Link
                  to="/malta/client-activity"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter1?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/malta/client-activity"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-check fa-fw"></i> Target Company Activity{" "}
                </Link>
              </li>
              <li>
                <Link
                  to="/malta/client-investigation"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter2?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/malta/client-investigation"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-search fa-fw"></i> Target Company -
                  Intermediary / Agent
                </Link>
              </li>
              <li>
                <Link
                  to="/malta/ubo-upc"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter3?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/malta/ubo-upc" ? "active" : ""
                  }
                >
                  <i className="fa fa-user-circle fa-fw"></i>Target Company -
                  UBO/UPC
                </Link>
              </li>
              <li>
                <Link
                  to="/malta/object-company"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter4?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/malta/object-company"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-building-o fa-fw"></i> Target Company
                </Link>
              </li>
              <li>
                <Link
                  to="/malta/country-sanction-risks"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter5?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/malta/country-sanction-risks"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-globe fa-fw"></i> Country/Sanction Risks
                </Link>
              </li>
              <li>
                <Link
                  to="/malta/agreement"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter6?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/malta/agreement" ? "active" : ""
                  }
                >
                  <i className="fa fa-thumbs-up fa-fw"></i> Agreement
                </Link>
              </li>
              <li>
                <Link
                  to="/malta/consolidated-risk-assessment"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter8?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/malta/consolidated-risk-assessment"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-check-square-o fa-fw"></i> Consolidated
                  Risk Assessment
                </Link>
              </li>
              <li>
                <Link
                  to="/malta/correspondence"
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter9?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  className={
                    location.pathname === "/malta/correspondence"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-file-text-o fa-fw"></i> Correspondence
                </Link>
              </li>
              <li>
                <Link
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter10?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  to="/malta/miscellaneous"
                  className={
                    location.pathname === "/malta/miscellaneous" ? "active" : ""
                  }
                >
                  <i className="fa fa-cog fa-fw"></i> Miscellaneous
                </Link>
              </li>
              <li>
                <Link
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter11?.isValid === true
                        ? "bolder"
                        : "",
                  }}
                  to="/malta/summary-sheet-caf"
                  className={
                    location.pathname === "/malta/summary-sheet-caf"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-list-alt fa-fw"></i> Summery sheet CAF
                </Link>
              </li>
              <li>
                <Link
                  style={{
                    pointerEvents: formData ? "" : "none",
                    opacity: formData ? "" : "0.5",
                    fontWeight:
                      validForms?.formErrorschapter12?.isValid === true
                        ? "bolder"
                        : null,
                  }}
                  to="/malta/formal-sign-off"
                  className={
                    location.pathname === "/malta/formal-sign-off"
                      ? "active"
                      : ""
                  }
                >
                  <i className="fa fa-pencil-square-o fa-fw"></i> Formal sign
                  off
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    internalErrors: state.MaltaInternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAllErrorToNull: () => dispatch(setAllMaltaErrorsToNull()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MaltaSidebar)
);
