import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../../elements/CheckBox";
import DropDown from "../../../../../elements/DropDown";
import TextBox from "../../../../../elements/TextBox";
import cc from "currency-codes";

let currencies = [];
cc.codes().map((currency) => currencies.push({ label: currency }));

const objBaseName = "shareCapComp";
class ShareCapital extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isShrCapComp, chapter, errorMsg } = this.props;
    const errors = JSON.parse(errorMsg);
    return (
      <Row className="pt-3 pb-2">
        <div className="mb-3 col-lg-4">
          <CheckBox
            label="Is this a share capital of the target company?"
            info="Minimum authorized share capital is EUR 1,164,69, 20% paid up"
            options={["Yes", "No"]}
            name="isShrCapComp"
            id="isShrCapComp"
            objBaseName={objBaseName}
            chapter={chapter}
            reducer={this.props.reducer}
          />
          {errors &&
            errors?.isShrCapComp &&
            errors?.isShrCapComp?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors?.isShrCapComp}
              </span>
            )}
        </div>
        <div className="mb-3 col-lg-8">
          <Row>
          {isShrCapComp === "Yes" && (
            <>
              <div className="mb-3 col-lg-6">
                <DropDown
                  label="Please specify currency"
                  name="shrCapCurrency"
                  id="shrCapCurrency"
                  options={currencies}
                  reducer={this.props.reducer}
                  chapter={this.props.chapter}
                  objBaseName={objBaseName}
                />
                {errors &&
                  errors?.shrCapCurrency &&
                  errors?.shrCapCurrency?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors?.shrCapCurrency}
                    </span>
                  )}
              </div>
              <div className="mb-3 col-lg-6">
                <TextBox
                  label="Authorized share capital"
                  name="authrzdShrCap"
                  id={`authrzdShrCap`}
                  placeholder="enter your text"
                  objBaseName={objBaseName}
                  chapter={chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                />
                {errors &&
                  errors?.authrzdShrCap &&
                  errors?.authrzdShrCap?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors?.authrzdShrCap}
                    </span>
                  )}
              </div>
              <div className="mb-3 col-lg-6">
                <TextBox
                  label="Issued share capital"
                  name="issuedShrCap"
                  id={`issuedShrCap`}
                  placeholder="enter your text"
                  objBaseName={objBaseName}
                  chapter={chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                />
                {errors &&
                  errors?.issuedShrCap &&
                  errors?.issuedShrCap?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors?.issuedShrCap}
                    </span>
                  )}
              </div>
              <div className="mb-3 col-lg-6">
                <TextBox
                  label="Denomination"
                  name="shrCapDenm"
                  id={`shrCapDenm`}
                  placeholder="enter your text"
                  objBaseName={objBaseName}
                  chapter={chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                />
                {errors &&
                  errors?.shrCapDenm &&
                  errors?.shrCapDenm?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors?.shrCapDenm}
                    </span>
                  )}
              </div>
            </>
          )}
          </Row>
        </div>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer } = ownProps;
  return {
    isShrCapComp: state[reducer]["chapter4"]["shareCapComp"]["isShrCapComp"],
  };
};

export default connect(mapStateToProps, null)(ShareCapital);
