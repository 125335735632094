import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import InternalFileUpload from "../../../../elements/InternalFileUpload";
import TextBox from "../../../../elements/TextBox";
import { fileAcceptType } from "../Constants";

class AdditionalDocs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, chapter, reducer, errorsMsg, isRequired, isAddDocs } =
      this.props;
    let errors = JSON.parse(errorsMsg);

    return (
      <div className="mb-3 col-lg-12">
        <fieldset>
          <legend>Additional Documents {index + 1}</legend>

          <Row>
            <div className="col-lg-6">
              <InternalFileUpload
                label="Upload document"
                chapter={chapter}
                index={index}
                name="addDocUpload"
                id={`addDocUpload[${index}]`}
                acceptType={fileAcceptType}
                reducer={reducer}
                isRequired={
                  (this.props.form !== "NL" && this.props.form !== "UK" && isAddDocs === "Yes") ||
                  isRequired
                    ? true
                    : false
                }
                baseName={this.props.baseName}
              />
              {errors &&
                errors.isSubmitted === true &&
                errors.addDocs &&
                errors.addDocs[index]?.addDocUpload?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors.addDocs[index].addDocUpload}
                  </span>
                )}
            </div>
            <div className="col-lg-6">
              <TextBox
                label="Explain"
                chapter={chapter}
                index={index}
                name="addDocExplain"
                id={`addDocExplain[${index}]`}
                acceptType={fileAcceptType}
                reducer={reducer}
                isRequired={
                  (this.props.form !== "NL" && this.props.form !== "UK" && isAddDocs === "Yes") ||
                  isRequired
                    ? true
                    : false
                }
                baseName={this.props.baseName}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
              {errors &&
                errors.isSubmitted === true &&
                errors.addDocs &&
                errors.addDocs[index]?.addDocExplain?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors.addDocs[index]?.addDocExplain}
                  </span>
                )}
            </div>
          </Row>
        </fieldset>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const { chapter, reducer } = ownProps;
  return {
    isAddDocs: state[reducer][chapter]["isAddDocs"],
  };
};
export default connect(mapStateToProps, null)(AdditionalDocs);
