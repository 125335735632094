import React from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";
import {
  setCuracaoInternalCAFDetails,
  getCuracaoInternalCAFDetails,
} from "../../../../../redux/actions/Curacao/CuracaoInternalCAFActions";
import "../../Onboarding/internalCAF.scss";
import { connect } from "react-redux";
import NotificationManager from "react-notifications/lib/NotificationManager";
import CheckBox from "../../../../elements/CheckBox";
import Intermediary from "../../Onboarding/Chapter2/Intermediary";
import AutoSaving from "../AutoSaving";
import NumberInput from "../../../../elements/NumberInput";
import InternalFileUpload from "../../../../elements/InternalFileUpload";
import { chapValidationErrorCuracao, fileAcceptType } from "../Constants";
import MediaCoverage from "../../Onboarding/Chapter1/MediaCoverage";
import Risks from "../../Onboarding/Chapter1/Risks";
import { setCuracaoInternalChapterError } from "../../../../../redux/actions/Curacao/CuracaoInternalChapterErrors";
import getSequentialFields from "../getSequentialFields";

const chapter = "chapter2";
const reducer = "CuracaoInternalCAF";
const externalReducer = "CuracaoClientInformation";
let timer;

class Chapter2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formErrorsChapter2: {},
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: "all",
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.getCuracaoInternalCAFDetails(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          Object.keys(this.props.internalErrors.errors).length === 0 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationErrorCuracao.forEach(async (element) => {
            let response =
              res?.data?.result?.cafInternalValidations[element.chapter];

            const newResponse = await Promise.resolve(
              getSequentialFields(response, element.chapter, false)
            );
            this.props.setCuracaoInternalChapterError({
              form: `formErrors${element.chapter}`,
              errors: newResponse?.errorChapter,
            });
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;

    this.props.setCuracaoInternalCAFDetails(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.props.ClientAcceptance.chapter2["dirty"] = false;
        let response = res.data.result;

        const newResponse = await Promise.resolve(
          getSequentialFields(response, chapter, true)
        );
        this.props.setCuracaoInternalChapterError({
          errors: newResponse?.errorChapter,
          form: "formErrorschapter2",
        });
        if (!res.data.result.isValid) {
          NotificationManager.info(
            "All fields are mandatory to fill in",
            null,
            4000
          );
          timer = setTimeout(() => {
            return this.props.history.push({
              pathname: "/curacao/ubo-upc",
            });
          }, 4000);
        } else {
          NotificationManager.success("Form is submitted successfully.");

          return this.props.history.push({
            pathname: "/curacao/ubo-upc",
          });
        }
      }
    });
  };
  render() {
    const { chapter2, chapter2Int, commonFields, internalErrors } = this.props;
    const formErrorsChapter2 = JSON.stringify(internalErrors.errors);
    const errorsMsg = JSON.parse(formErrorsChapter2);

    let intermediaries = [];

    for (let i = 0; i < chapter2.clientRepNum; i++) {
      intermediaries.push(
        <Intermediary
          index={i}
          baseName="intermediaryDetails"
          reducer={externalReducer}
          chapter={chapter}
          errors={formErrorsChapter2}
          form="Curacao"
        />
      );
    }

    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="cafHeader">
            <h2 className="m-0">Client Acceptance Form </h2>
          </div>
          <div className="internal_caf generic-form">
            <div className="html2pdf__page-break"></div>
            <h3 className="chapterttl">
              Client Investigation - Client Relationship (intermediary); Feeder
            </h3>
            <Row className="mb-3">
              <div className="col-lg-4">
                <CheckBox
                  label="Are relevant intermediaries/client representatives involved in the structure?"
                  options={["Yes", "No"]}
                  defaultValue={chapter2.isClientRepInvolved}
                  name="isClientRepInvolved"
                  chapter={chapter}
                  reducer={externalReducer}
                  disabled={true}
                />
              </div>

              <div className="col-lg-6">
                <NumberInput
                  label="How many intermediaries/client epresentatives can be identified?"
                  name="clientRepNum"
                  reducer={externalReducer}
                  chapter={chapter}
                  disabled={true}
                />
                <div className="errorMessage"></div>
              </div>
            </Row>
            {chapter2.isClientRepInvolved === "Yes" && intermediaries}
            <div className="html2pdf__page-break"></div>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-4">
                <CheckBox
                  label="Was the client transferred from another service provider (trust office)?"
                  options={["Yes", "No"]}
                  name="isClientTransfrd"
                  id="isClientTransfrd"
                  reducer="commonFields"
                  isCommon={true}
                  bothDisabled={true}
                />
              </div>
              {commonFields.isClientTransfrd === "Yes" && (
                <>
                  <div className="mb-2 col-lg-4 fieldAlignment">
                    <TextBox
                      label="Please mention name of the service provider"
                      placeholder="enter service provider name"
                      name="srvcPrvdrName"
                      id="srvcPrvdrName"
                      reducer="commonFields"
                      isCommon={true}
                      validationType="Text"
                      disabled={true}
                    />
                  </div>
                  <div className="mb-2 col-lg-4 fieldAlignment">
                    <TextBox
                      label="What are the reasons for changing of service provider?"
                      placeholder="enter reason"
                      name="reasonClientTransfrd"
                      id="reasonClientTransfrd"
                      reducer="commonFields"
                      isCommon={true}
                      validationType="Text"
                      disabled={true}
                    />
                  </div>
                </>
              )}
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <TextBox
                  label="If the intermediary does not have an ultimate beneficial owner, which information does UIM have based on which this has been determined?"
                  placeholder="Enter your text"
                  name="noUBOInfoByUIM"
                  id="noUBOInfoByUIM"
                  reducer={reducer}
                  chapter={chapter}
                  validationType="Text"
                  isMultipleLines={true}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg.noUBOInfoByUIM?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.noUBOInfoByUIM}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-4">
                <CheckBox
                  label="Pascal available of legal entity, client representative and UBO of intermediary?"
                  options={["Yes", "No"]}
                  name="isPascalReport"
                  id="isPascalReport"
                  reducer={reducer}
                  chapter={chapter}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg.isPascalReport?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isPascalReport}
                    </span>
                  )}
              </div>

              {chapter2Int.isPascalReport === "Yes" && (
                <div className="mb-2 col-lg-4">
                  <InternalFileUpload
                    label="Upload report"
                    name="pascalReportUpload"
                    id="pascalReportUpload"
                    reducer={reducer}
                    acceptType={fileAcceptType}
                    chapter={chapter}
                  />
                  {errorsMsg &&
                    errorsMsg.isSubmitted === true &&
                    errorsMsg.pascalReportUpload?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.pascalReportUpload}
                      </span>
                    )}
                </div>
              )}
              {chapter2.isPascalReport === "No" && (
                <div className="mb-2 col-lg-4">
                  <TextBox
                    label="Why not?"
                    name="noPascalReportReason"
                    reducer={reducer}
                    id="noPascalReportReason"
                    placeholder="Enter reason"
                    chapter={chapter}
                  />
                  {errorsMsg &&
                    errorsMsg.isSubmitted === true &&
                    errorsMsg.noPascalReportReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.noPascalReportReason}
                      </span>
                    )}
                </div>
              )}
            </Row>
            <MediaCoverage
              chapter={chapter}
              reducer={reducer}
              form="Curacao"
              errors={formErrorsChapter2}
            />
            <div className="html2pdf__page-break"></div>
            <Risks
              chapter={chapter}
              reducer={reducer}
              errors={formErrorsChapter2}
              riskLabel="Distribution risk rating"
              questionLabel="How many distribution risks can be identified?"
              boxLabel="Risk"
              isRequired={true}
              PdfGeneration={this.props.PdfGeneration}
            />

            <Row className="pb-2">
              <div className="mb-2 col-lg-12">
                <TextBox
                  label="Explain if there are (integrity) risks concerning intermediary or former (trust) service provider."
                  placeholder="Enter your text"
                  name="risksCncrnngIntermediary"
                  id="risksCncrnngIntermediary"
                  reducer={reducer}
                  chapter={chapter}
                  validationType="Text"
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg.risksCncrnngIntermediary?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.risksCncrnngIntermediary}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="pt-1 pb-2">
              <div className="mb-2 col-lg-4">
                <CheckBox
                  label="Is it sufficiently clear why the intermediary or former (trust) service provider has terminated its services?"
                  options={["Yes", "No"]}
                  name="isSrvcPrvdrTermntdSrvcsClr"
                  id="isSrvcPrvdrTermntdSrvcsClr"
                  reducer={reducer}
                  chapter={chapter}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg.isSrvcPrvdrTermntdSrvcsClr?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isSrvcPrvdrTermntdSrvcsClr}
                    </span>
                  )}
              </div>
              <div className="mb-2 col-lg-4">
                <CheckBox
                  label="Is the intermediary or former (trust) service provider reputable?"
                  options={["Yes", "No"]}
                  name="isSrvcPrvdrReputable"
                  reducer={reducer}
                  id="isSrvcPrvdrReputable"
                  chapter={chapter}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg.isSrvcPrvdrReputable?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isSrvcPrvdrReputable}
                    </span>
                  )}
              </div>
              <div className="mb-2 col-lg-4">
                <CheckBox
                  label="Can we be certain that we will receive all necessary information?"
                  options={["Yes", "No"]}
                  name="isReceiveNecessaryInfo"
                  id="isReceiveNecessaryInfo"
                  reducer={reducer}
                  chapter={chapter}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg.isReceiveNecessaryInfo?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isReceiveNecessaryInfo}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="pt-1">
              <div className="mb-3 col-lg-12">
                <CheckBox
                  label="In addition, report whether the distribution criteria result in a potentially increased integrity risk (sector risk analysis)"
                  options={["Yes", "No"]}
                  name="isIncreasedIntegrityRisk"
                  id="isIncreasedIntegrityRisk"
                  reducer={reducer}
                  chapter={chapter}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg.isIncreasedIntegrityRisk?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isIncreasedIntegrityRisk}
                    </span>
                  )}
              </div>
              {chapter2Int.isIncreasedIntegrityRisk === "Yes" && (
                <div className="mb-2 col-lg-12">
                  <TextBox
                    label="Please describe"
                    placeholder="Enter your text"
                    name="increasedIntegrityRiskReason"
                    id="increasedIntegrityRiskReason"
                    reducer={reducer}
                    chapter={chapter}
                    validationType="Text"
                    isMultipleLines={true}
                  />
                  {errorsMsg &&
                    errorsMsg.isSubmitted === true &&
                    errorsMsg.increasedIntegrityRiskReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.increasedIntegrityRiskReason}
                      </span>
                    )}
                </div>
              )}
              {chapter2Int.isIncreasedIntegrityRisk === "No" && (
                <div className="col-lg-12">
                  <b>
                    With respect to the intermediaries and/or feeders of the
                    client, UIM sees no reason to discontinue the set-up of a
                    business relationship
                  </b>
                </div>
              )}
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    chapter2: state.CuracaoClientInformation.chapter2,
    ClientAcceptance: state.CuracaoInternalCAF,
    chapter2Int: state.CuracaoInternalCAF.chapter2,
    commonFields: state.commonFields,
    data: state,
    internalErrors: state.CuracaoInternalChapterErrors.formErrorschapter2,
    errors: state.CuracaoInternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCuracaoInternalCAFDetails: async (data) =>
      dispatch(await getCuracaoInternalCAFDetails(data)),
    setCuracaoInternalCAFDetails: async (data) =>
      dispatch(await setCuracaoInternalCAFDetails(data)),
    setCuracaoInternalChapterError: (data) =>
      dispatch(setCuracaoInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter2);
