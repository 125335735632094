import { Component } from "react";
const message = "Please fill this detail";
const messageFile = "Please upload the file";

/* eslint-disable no-useless-escape */

const emailRegex = RegExp(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
class FormValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrorsAnnexNoUBO: {},
      formErrorsPEPpartA: {},
      formErrorsPEPpartB: {},
      formErrorsPEPpartC: {},
      formErrorsAnnexUBO: {},
    };
  }
  ValidateClientInformation = async (
    isUBO,
    isPEP,
    isUBOPEP,
    data,
    commonFields,
    annexUBO,
    annexNoUBO
  ) => {
    let formErrors = {};
    formErrors["formErrorsAnnexNoUBO"] = {};
    formErrors["formErrorsAnnexUBO"] = {};
    formErrors["ErrorsUBOPEP"] = {};
    if (isUBO === "No" && isPEP === "No") {
      let result = await this.checkAnnexNoUBOValidations(annexNoUBO);
      formErrors = result;
    } else if (isUBO === "No" && isPEP === "Yes") {
      formErrors["formErrorsAnnexUBO"] = {};
      Object.entries(data).forEach(async (entry) => {
        const [key, value] = entry;
        let result = await this.checkFormValidations(
          isUBO,
          isPEP,
          isUBOPEP,
          value,
          key,
          commonFields
        );
        formErrors["ErrorsUBOPEP"][key] = result[key];
      });
    } else if (isUBO === "Yes" && isPEP === "No") {
      let annexUBOE = await this.checkAnnexUBOValidations(annexUBO);
      formErrors["formErrorsAnnexUBO"] = annexUBOE.formErrorsAnnexUBO;
      Object.entries(data).forEach(async (entry) => {
        const [key, value] = entry;
        let result = await this.checkFormValidations(
          isUBO,
          isPEP,
          isUBOPEP,
          value,
          key,
          commonFields
        );
        formErrors["ErrorsUBOPEP"][key] = result[key];
      });
    } else if (isUBO === "Yes" && isPEP === "Yes") {
      let annexUBOE = await this.checkAnnexUBOValidations(annexUBO);
      formErrors["formErrorsAnnexUBO"] = annexUBOE.formErrorsAnnexUBO;
      Object.entries(data).forEach(async (entry) => {
        const [key, value] = entry;
        let result = await this.checkFormValidations(
          isUBO,
          isPEP,
          isUBOPEP,
          value,
          key,
          commonFields
        );
        formErrors["ErrorsUBOPEP"][key] = result[key];
      });
    }
    return { formErrors };
  };
  //check Annex No UBO
  checkAnnexNoUBOValidations = (data) => {
    let formErrorsAnnexNoUBO = this.state.formErrorsAnnexNoUBO;
    formErrorsAnnexNoUBO.declarationForms = [];
    data["declarationForms"].forEach((element) => {
      let errors = {};
      errors.address =
        element["address"] === null ||
        element["address"] === "" ||
        element["address"] === undefined
          ? message
          : "";
      errors.city =
        element["city"] === "" ||
        element["city"] === null ||
        element["city"] === undefined
          ? message
          : "";
      errors.copyOfPassport =
        element["copyOfPassport"] === null ||
        element["copyOfPassport"].length === 0
          ? messageFile
          : "";
      errors.country =
        element["country"] === "" ||
        element["country"] === null ||
        element["country"] === undefined
          ? message
          : "";
      errors.nationality =
        element["nationality"] === "" ||
        element["nationality"] === null ||
        element["nationality"] === undefined
          ? message
          : "";

      errors.dateOfBirth =
        element["dateOfBirth"] === "" ||
        element["dateOfBirth"] === null ||
        element["dateOfBirth"] === undefined
          ? message
          : "";
      errors.email =
        element["email"] === "" ||
        element["email"] === null ||
        element["email"] === undefined
          ? message
          : "";
      if (element["email"] !== "") {
        errors.email = emailRegex.test(element["email"])
          ? ""
          : " Please fill data correctly";
      }
      errors.firstName =
        element["firstName"] === "" ||
        element["firstName"] === null ||
        element["firstName"] === undefined
          ? message
          : "";
      errors.lastName =
        element["lastName"] === "" ||
        element["lastName"] === null ||
        element["lastName"] === undefined
          ? message
          : "";
      errors.middleName =
        element["middleName"] === "" ||
        element["middleName"] === null ||
        element["middleName"] === undefined
          ? message
          : "";
      errors.passportNumber =
        element["passportNumber"] === "" ||
        element["passportNumber"] === null ||
        element["passportNumber"] === undefined
          ? message
          : "";
      errors.placeOfBirth =
        element["placeOfBirth"] === "" ||
        element["placeOfBirth"] === null ||
        element["placeOfBirth"] === undefined
          ? message
          : "";
      errors.postalCode =
        element["postalCode"] === "" ||
        element["postalCode"] === null ||
        element["postalCode"] === undefined
          ? message
          : "";
      errors.powerOfAttorneyDated =
        element["powerOfAttorneyDated"] === "" ||
        element["powerOfAttorneyDated"] === null ||
        element["powerOfAttorneyDated"] === undefined
          ? message
          : "";
      errors.powerOfAttorney =
        element["powerOfAttorney"] === "" ||
        element["powerOfAttorney"] === null ||
        element["powerOfAttorney"] === undefined
          ? messageFile
          : "";
      errors.street =
        element["street"] === "" ||
        element["street"] === null ||
        element["street"] === undefined
          ? message
          : "";
      formErrorsAnnexNoUBO.declarationForms.push(errors);
    });
    formErrorsAnnexNoUBO.name =
      data["name"] === "" || data["name"] === null || data["name"] === undefined
        ? message
        : "";
    formErrorsAnnexNoUBO.date =
      data["date"] === "" || data["date"] === null || data["date"] === undefined
        ? message
        : "";
    formErrorsAnnexNoUBO.place =
      data["place"] === "" ||
      data["place"] === null ||
      data["place"] === undefined
        ? message
        : "";
    this.setState({
      formErrorsAnnexNoUBO: formErrorsAnnexNoUBO,
    });
    return { formErrorsAnnexNoUBO };
  };
  //check Annex UBO
  checkAnnexUBOValidations = (data) => {
    let formErrorsAnnexUBO = this.state.formErrorsAnnexUBO;
    formErrorsAnnexUBO.declarationForms = [];
    data["declarationForms"].forEach((element) => {
      let errors = {};
      errors.address =
        element["address"] === null ||
        element["address"] === "" ||
        element["address"] === undefined
          ? message
          : "";
      errors.city =
        element["city"] === "" ||
        element["city"] === null ||
        element["city"] === undefined
          ? message
          : "";
      errors.copyOfPassport =
        element["copyOfPassport"] === null ||
        element["copyOfPassport"].length === 0
          ? messageFile
          : "";
      errors.country =
        element["country"] === "" ||
        element["country"] === null ||
        element["country"] === undefined
          ? message
          : "";
      errors.nationality =
        element["nationality"] === "" ||
        element["nationality"] === null ||
        element["nationality"] === undefined
          ? message
          : "";
      errors.dateOfBirth =
        element["dateOfBirth"] === "" ||
        element["dateOfBirth"] === null ||
        element["dateOfBirth"] === undefined
          ? message
          : "";
      errors.email =
        element["email"] === "" ||
        element["email"] === null ||
        element["email"] === undefined
          ? message
          : "";
      if (element["email"] !== "") {
        errors.email = emailRegex.test(element["email"])
          ? ""
          : " Please fill data correctly";
      }
      errors.firstName =
        element["firstName"] === "" ||
        element["firstName"] === null ||
        element["firstName"] === undefined
          ? message
          : "";
      errors.lastName =
        element["lastName"] === "" ||
        element["lastName"] === null ||
        element["lastName"] === undefined
          ? message
          : "";
      errors.middleName =
        element["middleName"] === "" ||
        element["middleName"] === null ||
        element["middleName"] === undefined
          ? message
          : "";
      errors.passportNumber =
        element["passportNumber"] === "" ||
        element["passportNumber"] === null ||
        element["passportNumber"] === undefined
          ? message
          : "";
      errors.placeOfBirth =
        element["placeOfBirth"] === "" ||
        element["placeOfBirth"] === null ||
        element["placeOfBirth"] === undefined
          ? message
          : "";
      errors.postalCode =
        element["postalCode"] === "" ||
        element["postalCode"] === null ||
        element["postalCode"] === undefined
          ? message
          : "";
      errors.powerOfAttorneyDated =
        element["powerOfAttorneyDated"] === "" ||
        element["powerOfAttorneyDated"] === null ||
        element["powerOfAttorneyDated"] === undefined
          ? message
          : "";
      errors.powerOfAttorney =
        element["powerOfAttorney"] === "" ||
        element["powerOfAttorney"] === null ||
        element["powerOfAttorney"] === undefined
          ? messageFile
          : "";
      errors.street =
        element["street"] === "" ||
        element["street"] === null ||
        element["street"] === undefined
          ? message
          : "";
      formErrorsAnnexUBO.declarationForms.push(errors);
    });
    formErrorsAnnexUBO.name =
      data["name"] === "" || data["name"] === null || data["name"] === undefined
        ? message
        : "";
    formErrorsAnnexUBO.date =
      data["date"] === "" || data["date"] === null || data["date"] === undefined
        ? message
        : "";
    formErrorsAnnexUBO.place =
      data["place"] === "" ||
      data["place"] === null ||
      data["place"] === undefined
        ? message
        : "";
    this.setState({
      formErrorsAnnexUBO: formErrorsAnnexUBO,
    });
    return { formErrorsAnnexUBO };
  };

  //Check Form validations
  checkFormValidations = (isUBO, isPEP, isUBOPEP, data, part, commonFields) => {
    switch (part) {
      case "partA":
        let partA = this.state.formErrorsPEPpartA;
        if (isPEP === "Yes") {
          partA.pepName =
            data.pepDetails["pepName"] === "" ||
            data.pepDetails["pepName"] === null ||
            data.pepDetails["pepName"] === undefined
              ? message
              : "";
          partA.dob =
            data.pepDetails["dob"] === "" ||
            data.pepDetails["dob"] === null ||
            data.pepDetails["dob"] === undefined
              ? message
              : "";
          partA.pob =
            data.pepDetails["pob"] === "" ||
            data.pepDetails["pob"] === null ||
            data.pepDetails["pob"] === undefined
              ? message
              : "";
          partA.cob =
            data.pepDetails["cob"] === "" ||
            data.pepDetails["cob"] === null ||
            data.pepDetails["cob"] === undefined
              ? message
              : "";
          if (localStorage.getItem("Office") === "Curacao") {
            partA.multiNationality =
              data.pepDetails["multiNationality"] === "" ||
              data.pepDetails["multiNationality"] === null ||
              data.pepDetails["multiNationality"] === undefined
                ? message
                : "";
          } else {
            partA.nationality =
              data.pepDetails["nationality"] === "" ||
              data.pepDetails["nationality"] === null ||
              data.pepDetails["nationality"] === undefined
                ? message
                : "";
          }

          partA.pepPassportNum =
            data.pepDetails["pepPassportNum"] === "" ||
            data.pepDetails["pepPassportNum"] === null ||
            data.pepDetails["pepPassportNum"] === undefined
              ? message
              : "";
          if (localStorage.getItem("Office") !== "Malta") {
            partA.maritalStatus =
              data.pepDetails["maritalStatus"] === "" ||
              data.pepDetails["maritalStatus"] === null ||
              data.pepDetails["maritalStatus"] === undefined
                ? message
                : "";
          }

          partA.passportUpload =
            data.pepDetails["passportUpload"] === null ||
            data.pepDetails["passportUpload"].length === 0
              ? messageFile
              : "";
          partA.pvtAddress =
            data.pepDetails["pvtAddress"] === "" ||
            data.pepDetails["pvtAddress"] === null ||
            data.pepDetails["pvtAddress"] === undefined
              ? message
              : "";
          partA.uboPostalCode =
            data.pepDetails["uboPostalCode"] === "" ||
            data.pepDetails["uboPostalCode"] === null ||
            data.pepDetails["uboPostalCode"] === undefined
              ? message
              : "";
          partA.uboCity =
            data.pepDetails["uboCity"] === "" ||
            data.pepDetails["uboCity"] === null ||
            data.pepDetails["uboCity"] === undefined
              ? message
              : "";
          partA.uboCountry =
            data.pepDetails["uboCountry"] === "" ||
            data.pepDetails["uboCountry"] === null ||
            data.pepDetails["uboCountry"] === undefined
              ? message
              : "";
          partA.pepEmail =
            data.pepDetails["pepEmail"] === "" ||
            data.pepDetails["pepEmail"] === null ||
            data.pepDetails["pepEmail"] === undefined
              ? message
              : "";
          partA.pepPhoneNum =
            data.pepDetails["pepPhoneNum"] === "" ||
            data.pepDetails["pepPhoneNum"] === null ||
            data.pepDetails["pepPhoneNum"] === undefined
              ? message
              : "";
          partA.fax =
            data.pepDetails["fax"] === "" ||
            data.pepDetails["fax"] === null ||
            data.pepDetails["fax"] === undefined
              ? message
              : "";
          partA.occupation =
            data.pepDetails["occupation"] === "" ||
            data.pepDetails["occupation"] === null ||
            data.pepDetails["occupation"] === undefined
              ? message
              : "";
          partA.curriculumVitae =
            data.pepDetails["curriculumVitae"] === null ||
            data.pepDetails["curriculumVitae"].length === 0
              ? messageFile
              : "";
          partA.proofOfAddress =
            data.pepDetails["proofOfAddress"] === null ||
            data.pepDetails["proofOfAddress"].length === 0
              ? messageFile
              : "";
        }
        if (isUBO === "Yes") {
          partA.uboXName =
            data.uboDetails["uboXName"] === "" ||
            data.uboDetails["uboXName"] === null ||
            data.uboDetails["uboXName"] === undefined
              ? message
              : "";
          partA.dob =
            data.uboDetails["dob"] === "" ||
            data.uboDetails["dob"] === null ||
            data.uboDetails["dob"] === undefined
              ? message
              : "";
          partA.pob =
            data.uboDetails["pob"] === "" ||
            data.uboDetails["pob"] === null ||
            data.uboDetails["pob"] === undefined
              ? message
              : "";
          partA.cob =
            data.uboDetails["cob"] === "" ||
            data.uboDetails["cob"] === null ||
            data.uboDetails["cob"] === undefined
              ? message
              : "";
          if (localStorage.getItem("Office") === "Curacao") {
            partA.multiNationality =
              data.uboDetails["multiNationality"] === "" ||
              data.uboDetails["multiNationality"] === null ||
              data.uboDetails["multiNationality"] === undefined
                ? message
                : "";
          } else {
            partA.nationality =
              data.uboDetails["nationality"] === "" ||
              data.uboDetails["nationality"] === null ||
              data.uboDetails["nationality"] === undefined
                ? message
                : "";
          }

          partA.passportNum =
            data.uboDetails["passportNum"] === "" ||
            data.uboDetails["passportNum"] === null ||
            data.uboDetails["passportNum"] === undefined
              ? message
              : "";
          if (localStorage.getItem("Office") !== "Malta") {
            partA.maritalStatus =
              data.uboDetails["maritalStatus"] === "" ||
              data.uboDetails["maritalStatus"] === null ||
              data.uboDetails["maritalStatus"] === undefined
                ? message
                : "";
          }

          partA.certifiedPassportUBO =
            data.uboDetails["certifiedPassportUBO"] === null ||
            data.uboDetails["certifiedPassportUBO"].length === 0
              ? messageFile
              : "";
          partA.pvtAddress =
            data.uboDetails["pvtAddress"] === "" ||
            data.uboDetails["pvtAddress"] === null ||
            data.uboDetails["pvtAddress"] === undefined
              ? message
              : "";
          partA.uboPostalCode =
            data.uboDetails["uboPostalCode"] === "" ||
            data.uboDetails["uboPostalCode"] === null ||
            data.uboDetails["uboPostalCode"] === undefined
              ? message
              : "";
          partA.uboCity =
            data.uboDetails["uboCity"] === "" ||
            data.uboDetails["uboCity"] === null ||
            data.uboDetails["uboCity"] === undefined
              ? message
              : "";
          partA.uboCountry =
            data.uboDetails["uboCountry"] === "" ||
            data.uboDetails["uboCountry"] === null ||
            data.uboDetails["uboCountry"] === undefined
              ? message
              : "";
          partA.uboEmail =
            data.uboDetails["uboEmail"] === "" ||
            data.uboDetails["uboEmail"] === null ||
            data.uboDetails["uboEmail"] === undefined
              ? message
              : "";
          partA.uboPhoneNum =
            data.uboDetails["uboPhoneNum"] === "" ||
            data.uboDetails["uboPhoneNum"] === null ||
            data.uboDetails["uboPhoneNum"] === undefined
              ? message
              : "";
          partA.fax =
            data.uboDetails["fax"] === "" ||
            data.uboDetails["fax"] === null ||
            data.uboDetails["fax"] === undefined
              ? message
              : "";
          partA.occupation =
            data.uboDetails["occupation"] === "" ||
            data.uboDetails["occupation"] === null ||
            data.uboDetails["occupation"] === undefined
              ? message
              : "";
          partA.curriculumVitae =
            data.uboDetails["curriculumVitae"] === null ||
            data.uboDetails["proofOfAddress"].length === 0
            ? messageFile
            : "";
          partA.proofOfAddress =
            data.uboDetails["proofOfAddress"] === null ||
            data.uboDetails["proofOfAddress"].length === 0
              ? messageFile
              : "";
        }
        if (
          isUBO === "Yes" ||
          isPEP === "Yes" ||
          (isUBO === "Yes" && isUBOPEP === "Yes")
        ) {
          if (localStorage.getItem("Office") === "Malta") {
            partA.actingThirdParties =
              data.maltaUBOPEPDetails["actingThirdParties"] === "" ||
              data.maltaUBOPEPDetails["actingThirdParties"] === null ||
              data.maltaUBOPEPDetails["actingThirdParties"] === undefined
                ? message
                : "";
            partA.citizspInvsmtScheme =
              data.maltaUBOPEPDetails["citizspInvsmtScheme"] === "" ||
              data.maltaUBOPEPDetails["citizspInvsmtScheme"] === null ||
              data.maltaUBOPEPDetails["citizspInvsmtScheme"] === undefined
                ? message
                : "";
            partA.civilStatus =
              data.maltaUBOPEPDetails["civilStatus"] === "" ||
              data.maltaUBOPEPDetails["civilStatus"] === null ||
              data.maltaUBOPEPDetails["civilStatus"] === undefined
                ? message
                : "";
            partA.convCriminalofnce =
              data.maltaUBOPEPDetails["convCriminalofnce"] === "" ||
              data.maltaUBOPEPDetails["convCriminalofnce"] === null ||
              data.maltaUBOPEPDetails["convCriminalofnce"] === undefined
                ? message
                : "";
            partA.dualCitizenship =
              data.maltaUBOPEPDetails["dualCitizenship"] === "" ||
              data.maltaUBOPEPDetails["dualCitizenship"] === null ||
              data.maltaUBOPEPDetails["dualCitizenship"] === undefined
                ? message
                : "";
            partA.hasTaxAdvice =
              data.maltaUBOPEPDetails["hasTaxAdvice"] === "" ||
              data.maltaUBOPEPDetails["hasTaxAdvice"] === null ||
              data.maltaUBOPEPDetails["hasTaxAdvice"] === undefined
                ? message
                : "";
            partA.perctgShareHeld =
              data.maltaUBOPEPDetails["perctgShareHeld"] === "" ||
              data.maltaUBOPEPDetails["perctgShareHeld"] === null ||
              data.maltaUBOPEPDetails["perctgShareHeld"] === undefined
                ? message
                : "";
            partA.residency =
              data.maltaUBOPEPDetails["residency"] === "" ||
              data.maltaUBOPEPDetails["residency"].length === 0
                ? message
                : "";
            partA.tax =
              data.maltaUBOPEPDetails["tax"] === "" ||
              data.maltaUBOPEPDetails["tax"] === null ||
              data.maltaUBOPEPDetails["tax"] === undefined
                ? message
                : "";
            partA.tinNumber =
              data.maltaUBOPEPDetails["tinNumber"] === "" ||
              data.maltaUBOPEPDetails["tinNumber"] === null ||
              data.maltaUBOPEPDetails["tinNumber"] === undefined
                ? message
                : "";
            partA.usTiesPast =
              data.maltaUBOPEPDetails["usTiesPast"] === "" ||
              data.maltaUBOPEPDetails["usTiesPast"] === null ||
              data.maltaUBOPEPDetails["usTiesPast"] === undefined
                ? message
                : "";
            partA.usTiesPersonal =
              data.maltaUBOPEPDetails["usTiesPersonal"] === "" ||
              data.maltaUBOPEPDetails["usTiesPersonal"] === null ||
              data.maltaUBOPEPDetails["usTiesPersonal"] === undefined
                ? message
                : "";
            partA.isDirIndirOwner =
              data.maltaUBOPEPDetails["isDirIndirOwner"] === "" ||
              data.maltaUBOPEPDetails["isDirIndirOwner"] === null ||
              data.maltaUBOPEPDetails["isDirIndirOwner"] === undefined
                ? message
                : "";
            if (
              data.maltaUBOPEPDetails["isDirIndirOwner"] === "Indirect Owner"
            ) {
              partA.indirectEntity =
                data.maltaUBOPEPDetails["indirectEntity"] === "" ||
                data.maltaUBOPEPDetails["indirectEntity"] === null ||
                data.maltaUBOPEPDetails["indirectEntity"] === undefined
                  ? message
                  : "";
            } else {
              partA.indirectEntity = "";
            }
          }
        }
        partA.proofOfTaxRes =
          data["proofOfTaxRes"] === undefined ||
          data["proofOfTaxRes"] === null ||
          data["proofOfTaxRes"].length === 0
            ? messageFile
            : "";
        partA.isNomineeAgrmntUBO =
          data["isNomineeAgrmntUBO"] === "" ||
          data["isNomineeAgrmntUBO"] === null ||
          data["isNomineeAgrmntUBO"] === undefined
            ? message
            : "";
        partA.nomineeAgrmntUBO =
          data["isNomineeAgrmntUBO"] === "Yes" &&
          (data["nomineeAgrmntUBO"] === null ||
            data["nomineeAgrmntUBO"].length === 0)
            ? messageFile
            : "";
        if (data["isNomineeAgrmntUBO"] === "No") {
          partA.nomineeAgrmntUBO = "";
        }
        partA.perIntrstUBOinUPC =
          data["perIntrstUBOinUPC"] === "" ||
          data["perIntrstUBOinUPC"] === null ||
          data["perIntrstUBOinUPC"] === undefined
            ? message
            : "";
        partA.perIntrstBeneficiary =
          data["perIntrstBeneficiary"] === "" ||
          data["perIntrstBeneficiary"] === null ||
          data["perIntrstBeneficiary"] === undefined
            ? message
            : "";
        partA.uboEfctvCtrlMngmntLglEntity =
          data["uboEfctvCtrlMngmntLglEntity"] === "" ||
          data["uboEfctvCtrlMngmntLglEntity"] === null ||
          data["uboEfctvCtrlMngmntLglEntity"] === undefined
            ? message
            : "";
        partA.reasonUboEfctvCtrlMngmntLglEntity =
          data["uboEfctvCtrlMngmntLglEntity"] === "Yes" &&
          (data["reasonUboEfctvCtrlMngmntLglEntity"] === "" ||
            data["reasonUboEfctvCtrlMngmntLglEntity"] === null ||
            data["reasonUboEfctvCtrlMngmntLglEntity"] === undefined)
            ? message
            : "";
        if (data["uboEfctvCtrlMngmntLglEntity"] === "No") {
          partA.reasonUboEfctvCtrlMngmntLglEntity = "";
        }
        partA.isUBOEntrstdPEP =
          data["isUBOEntrstdPEP"] === "" ||
          data["isUBOEntrstdPEP"] === null ||
          data["isUBOEntrstdPEP"] === undefined
            ? message
            : "";
        partA.isUBOCnctedPEP =
          data["isUBOCnctedPEP"] === "" ||
          data["isUBOCnctedPEP"] === null ||
          data["isUBOCnctedPEP"] === undefined
            ? message
            : "";
        partA.isUBOAssociatePEP =
          data["isUBOAssociatePEP"] === "" ||
          data["isUBOAssociatePEP"] === null ||
          data["isUBOAssociatePEP"] === undefined
            ? message
            : "";
        partA.isUBOForceCloseFinAcc =
          data["isUBOForceCloseFinAcc"] === "" ||
          data["isUBOForceCloseFinAcc"] === null ||
          data["isUBOForceCloseFinAcc"] === undefined
            ? message
            : "";
        partA.uboForceCloseFinAcc =
          data["isUBOForceCloseFinAcc"] === "Yes" &&
          (data["uboForceCloseFinAcc"] === "" ||
            data["uboForceCloseFinAcc"] === null ||
            data["uboForceCloseFinAcc"] === undefined)
            ? message
            : "";
        if (data["isUBOForceCloseFinAcc"] === "No") {
          partA.uboForceCloseFinAcc = "";
        }
        partA.isUPCForceCloseFinAcc =
          data["isUPCForceCloseFinAcc"] === "" ||
          data["isUPCForceCloseFinAcc"] === null ||
          data["isUPCForceCloseFinAcc"] === undefined
            ? message
            : "";
        partA.upcForceCloseFinAcc =
          data["isUPCForceCloseFinAcc"] === "Yes" &&
          (data["upcForceCloseFinAcc"] === "" ||
            data["upcForceCloseFinAcc"] === null ||
            data["upcForceCloseFinAcc"] === undefined)
            ? message
            : "";
        if (data["isUPCForceCloseFinAcc"] === "No") {
          partA.upcForceCloseFinAcc = "";
        }
        partA.isUBOActingOnSelfBehalf =
          data["isUBOActingOnSelfBehalf"] === "" ||
          data["isUBOActingOnSelfBehalf"] === null ||
          data["isUBOActingOnSelfBehalf"] === undefined
            ? message
            : "";
        partA.uboNotActingOnSelfBehalf =
          data["isUBOActingOnSelfBehalf"] === "Yes" &&
          (data["uboNotActingOnSelfBehalf"] === "" ||
            data["uboNotActingOnSelfBehalf"] === null ||
            data["uboNotActingOnSelfBehalf"] === undefined)
            ? message
            : "";
        if (data["isUBOActingOnSelfBehalf"] === "No") {
          partA.uboNotActingOnSelfBehalf = "";
        }
        partA.isUBOInvlvdDoubtfulFinancng =
          data["isUBOInvlvdDoubtfulFinancng"] === "" ||
          data["isUBOInvlvdDoubtfulFinancng"] === null ||
          data["isUBOInvlvdDoubtfulFinancng"] === undefined
            ? message
            : "";
        partA.uboInvlvdDbtfulFinancng =
          data["isUBOInvlvdDoubtfulFinancng"] === "Yes" &&
          (data["uboInvlvdDbtfulFinancng"] === "" ||
            data["uboInvlvdDbtfulFinancng"] === null ||
            data["uboInvlvdDbtfulFinancng"] === undefined)
            ? message
            : "";
        if (data["isUBOInvlvdDoubtfulFinancng"] === "No") {
          partA.uboInvlvdDbtfulFinancng = "";
        }
        partA.isOthrCrmstncsNegImpct =
          data["isOthrCrmstncsNegImpct"] === "" ||
          data["isOthrCrmstncsNegImpct"] === null ||
          data["isOthrCrmstncsNegImpct"] === undefined
            ? message
            : "";
        partA.othrCrmstncsNegImpct =
          data["isOthrCrmstncsNegImpct"] === "Yes" &&
          (data["othrCrmstncsNegImpct"] === "" ||
            data["othrCrmstncsNegImpct"] === null ||
            data["othrCrmstncsNegImpct"] === undefined)
            ? message
            : "";
        if (data["isOthrCrmstncsNegImpct"] === "No") {
          partA.othrCrmstncsNegImpct = "";
        }
        this.setState({
          partA: partA,
        });
        return { partA };

      case "partB":
        let partB = this.state.formErrorsPEPpartB;
        partB.grossAnnualIncome =
          data["grossAnnualIncome"] === "" ||
          data["grossAnnualIncome"] === null ||
          data["grossAnnualIncome"] === undefined
            ? message
            : "";
        partB.wealthRange =
          data["wealthRange"] === "" ||
          data["wealthRange"] === null ||
          data["wealthRange"] === undefined
            ? message
            : "";
        partB.srcOfWealth =
          data["srcOfWealth"] === null || data["srcOfWealth"].length === 0
            ? message
            : "";
        partB.evdncSrcWealth =
          data["evdncSrcWealth"] === null || data["evdncSrcWealth"].length === 0
            ? message
            : "";
        partB.uboAcqreWlthProfBusCar =
          data["uboAcqreWlthProfBusCar"] === "" ||
          data["uboAcqreWlthProfBusCar"] === null ||
          data["uboAcqreWlthProfBusCar"] === undefined
            ? message
            : "";

        if (isPEP === "Yes" || (isUBO === "Yes" && isUBOPEP === "Yes")) {
          partB.familyAnnualIncome =
            data["familyAnnualIncome"] === "" ||
            data["familyAnnualIncome"] === null ||
            data["familyAnnualIncome"].length === 0
              ? message
              : "";
        }
        partB.anualIncmAbvEUR1Mln =
          data["grossAnnualIncome"] === "> EUR 1 Mln" &&
          (data["anualIncmAbvEUR1Mln"] === "" ||
            data["anualIncmAbvEUR1Mln"] === null ||
            data["anualIncmAbvEUR1Mln"] === undefined)
            ? message
            : "";
        this.setState({
          partB: partB,
        });
        return { partB };

      case "partC":
        let partC = this.state.formErrorsPEPpartC;
        let office = localStorage.getItem("Office");
        if (office === "Netherlands") {
          partC.summaryrightsAndObligations =
            data["summaryrightsAndObligations"] === "" ||
            data["summaryrightsAndObligations"] === null ||
            data["summaryrightsAndObligations"] === undefined
              ? message
              : "";

          this.setState({
            partC: partC,
          });
        } else if (localStorage.getItem("Office") === "Malta") {
          partC.checkProspectUBO =
            data["checkProspectUBO"] === "" ||
            data["checkProspectUBO"] === null ||
            data["checkProspectUBO"] === undefined
              ? message
              : "";
          this.setState({
            partC: partC,
          });
        }
        return { partC };

      case "name":
        let name = "";
        name =
          data === "" || data === null || data === undefined ? message : "";
        return { name };

      case "date":
        let date = "";
        date =
          data === "" || data === null || data === undefined ? message : "";
        return { date };

      case "place":
        let place = "";
        place =
          data === "" || data === null || data === undefined ? message : "";
        return { place };

      default:
        return "";
    }
  };
}
const Validation = new FormValidation();
export default Validation;
