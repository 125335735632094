import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import { setDataToReducer } from "../../../../../redux/actions/ClientInformationAction";
import DataTable from "react-data-table-component";

import {
  getCuracaoInternalCAFDetails,
  setCuracaoInternalCAFDetails,
} from "../../../../../redux/actions/Curacao/CuracaoInternalCAFActions";
import { docuSignGenerateURL } from "../../../../../redux/actions/InternalFileHandleActions";
import { downloadInternalSignedFile } from "../../../../../redux/actions/InternalFileHandleActions";
import { saveAs } from "file-saver";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { withRouter } from "react-router";
import jwt_decode from "jwt-decode";

class SignedBy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      signedBy: null,
      errors: {},
      signedDataLength: null,
      signedByUserList: [],
      columns: [],
      isSignedByAll: false,
    };
  }

  APICall = async (array) => {
    let chapter13 = {};
    chapter13.signedBy = array;
    let requestData = {};
    let data = await this.props.ClientAcceptance["chapter13"];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData["chapter13"] = chapter13;
    await this.props.setCuracaoInternalCAFDetails(requestData).then((res) => {
      if (res.data.portalErrorCode === 200) {
        NotificationManager.success("Form details are submitted successfully.");
      }
    });
  };

  componentDidMount = () => {
    this.setState({
      columns: [
        {
          id: 1,
          name: "Name",
          selector: (row) => row.name,
        },
        {
          id: 2,
          name: "Email",
          selector: (row) => row.email,
        },
        {
          id: 3,
          name: "Date of Signature",
          selector: (row) => (
            <div>
              {row.dos ? (
                moment(row.dos).format("DD-MM-YYYY")
              ) : (
                <div className="center">-</div>
              )}
            </div>
          ),
        },
        {
          id: 4,
          name: "Status",
          selector: (row) =>
            row.status === "NotSigned" ? "Not Signed" : "Signed",
        },
        {
          id: 5,
          name: "Signed By",
          selector: (row) => row.signedBy,
        },
        {
          id: 6,
          selector: (row) => (
            <>
              {row.status === "NotSigned" && this.checkUser(row) && (
                <button
                  type="button"
                  className="action-btn edit noicon"
                  onClick={() => this.handleSign(row)}
                >
                  Click to sign
                </button>
              )}
            </>
          ),
        },
      ],
    });
  };

  checkUser = (row) => {
    let logged_in_user = JSON.parse(localStorage.getItem("authToken"));
    let decoded = jwt_decode(logged_in_user);
    return decoded.name === row.name;
  };

  validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.signedBy) {
      formIsValid = false;
      errors["signedBy"] = "Please select Signed By";
    }
    this.setState({ errors });
    return formIsValid;
  };

  clearInputs = () => {
    this.setState({ signedBy: "" });
  };

  handleSign = (row) => {
    this.setState({ isLoading: true });
    const formData = new FormData();
    let user_data = JSON.parse(localStorage.getItem("formData"));
    formData.append("id", row.id);
    formData.append("signedByEmail", row.email);
    formData.append("signedByName", row.name);
    formData.append("extUsrOffice", user_data.office);
    formData.append("extUserEmail", user_data.userEmail);
    formData.append("extUsername", user_data.userName);
    formData.append("formId", user_data.id);
    formData.append("userId", user_data.userId);
    formData.append("companyId", user_data.clientId);
    formData.append("companyName", user_data.clientName);
    formData.append("signedByRole", row.signedBy);
    this.props
      .docuSignGenerateURL(formData)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data?.portalErrorCode === 200) {
          window.location = res.data.result;
        } else {
          NotificationManager.error("Error while signing the document");
        }
      })
      .catch(this.catchError);
  };

  downLoadForm = () => {
    let formData = JSON.parse(localStorage.getItem("formData"));
    let obj = {
      fileName: "Combined.pdf",
      office: formData.office,
      userName: formData.userName,
      userEmail: formData.userEmail,
      companyName: formData.clientName,
      companyId: formData.clientId,
    };

    this.props.downloadInternalSignedFile(obj).then((result) => {
      var blob = new Blob([result.data]);
      let file = "Combined.pdf";
      saveAs(blob, file);
    });
  };

  handleClientAccepted = () => {
    let requestData = {};
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData["status"] = "VerifiedSigned";

    this.props.setCuracaoInternalCAFDetails(requestData).then((res) => {
      if (res.data.portalErrorCode === 200) {
        NotificationManager.success("Form is completed successfully.");
        localStorage.removeItem("formData");
        return this.props.history.push({
          pathname: "/client-form-list",
        });
      }
    });
  };

  render() {
    let array = [...this.props.signedBy];
    let isDownloadVisible = array.filter((data) => data.status === "Signed");

    let isSignedByAll = false;

    if (array.length >= 4) {
      array.forEach((role) => {
        if (role.status !== "Signed") {
          isSignedByAll = false;

          return;
        } else {
          isSignedByAll = true;
        }
      });
    }

    return (
      <>
        <h4 className="sub-chapter-title pt-2 mb-4">Signed By</h4>
        {this.state.isLoading && <div className="loader"></div>}
        <div className="signedDatatable">
          <DataTable
            columns={this.state.columns}
            data={[...this.props.signedBy]}
          />
        </div>
        {isDownloadVisible.length > 0 && (
          <button
            className="action-btn borderbtn noicon mt-2"
            onClick={this.downLoadForm}
          >
            Download Form
          </button>
        )}
        <Row className="pt-3 pb-2">
          <div className="mb-2 col-lg-12">
            <button
              onClick={this.handleClientAccepted}
              type="button"
              className={`action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5 ${
                !isSignedByAll ? "disabled" : ""
              }`}
            >
              Client Accepted
            </button>
          </div>
        </Row>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
    downloadInternalSignedFile: async (data) =>
      dispatch(await downloadInternalSignedFile(data)),
    getCuracaoInternalCAFDetails: async (data) =>
      dispatch(await getCuracaoInternalCAFDetails(data)),
    setCuracaoInternalCAFDetails: async (data) =>
      dispatch(await setCuracaoInternalCAFDetails(data)),
    docuSignGenerateURL: async (data) =>
      dispatch(await docuSignGenerateURL(data)),
  };
};
const mapStateToProps = (state) => {
  return {
    ClientAcceptance: state.CuracaoInternalCAF,
    signedBy: state.CuracaoInternalCAF.chapter13["signedBy"],
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignedBy)
);
