import React, { Component } from "react";
import cc from "currency-codes";
import DropDown from "../../../../elements/DropDown";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";

let currencies = [];
cc.codes().map((currency) => currencies.push({ label: currency }));

const objBaseName = "nonRecurringFees";
class NonRecFees extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { errors, isRequired } = this.props;
    const errorMsg = JSON.parse(errors);

    return (
      <div
        className={
          this.props.PdfGeneration !== "Yes" ? "groupCompaniesBlock" : ""
        }
      >
        <h6
          className={
            this.props.PdfGeneration !== "Yes"
              ? "font-weight-bold internal_chapter_sub_title"
              : "font-weight-bold"
          }
        >
          Proposed Non-recurring Fixed Fees
        </h6>
        <Row>
          <div className="mb-2 col-md-4">
            <DropDown
              label="Select currency"
              options={currencies}
              chapter={this.props.chapter}
              name="currency"
              reducer={this.props.reducer}
              objBaseName={objBaseName}
              isRequired={isRequired}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorMsg &&
              errorMsg?.isSubmitted === true &&
              errorMsg?.currency &&
              errorMsg?.currency?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.currency}
                </span>
              )}
          </div>
          <div className="mb-2 col-md-4">
            <TextBox
              label="Incorporation/set up fees"
              placeholder="Enter fees"
              isRequired={isRequired}
              chapter={this.props.chapter}
              name="incrtnFees"
              reducer={this.props.reducer}
              objBaseName={objBaseName}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
              validationType="Amount"
            />
            {errorMsg &&
              errorMsg?.isSubmitted === true &&
              errorMsg?.incrtnFees &&
              errorMsg?.incrtnFees?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.incrtnFees}
                </span>
              )}
          </div>
          <div className="mb-2 col-md-4">
            <TextBox
              label="Help to company incorporation"
              placeholder="Enter fees"
              chapter={this.props.chapter}
              name="comIncrtn"
              reducer={this.props.reducer}
              isRequired={isRequired}
              objBaseName={objBaseName}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
              validationType="Amount"
            />
            {errorMsg &&
              errorMsg?.isSubmitted === true &&
              errorMsg?.comIncrtn &&
              errorMsg?.comIncrtn?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.comIncrtn}
                </span>
              )}
          </div>
          {this.props.form === "Luxembourg" && (
            <div className="mb-2 col-md-4">
              <TextBox
                label="Shelf company (acquisition fees)"
                placeholder="Enter fees"
                chapter={this.props.chapter}
                name="shelfCompany"
                reducer={this.props.reducer}
                objBaseName={objBaseName}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
                validationType="Amount"
              />
              {errorMsg &&
                errorMsg?.isSubmitted === true &&
                errorMsg?.shelfCompany &&
                errorMsg?.shelfCompany?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorMsg?.shelfCompany}
                  </span>
                )}
            </div>
          )}
          <div className="mb-2 col-md-4">
            <TextBox
              label="Others"
              placeholder="Enter fees"
              chapter={this.props.chapter}
              name="others"
              isRequired={isRequired}
              reducer={this.props.reducer}
              objBaseName={objBaseName}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
              validationType="Amount"
            />
            {errorMsg &&
              errorMsg?.isSubmitted === true &&
              errorMsg?.others &&
              errorMsg?.others?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.others}
                </span>
              )}
          </div>
          <div className="mb-2 col-md-4">
            <TextBox
              label="Fees for the transfer of the company"
              placeholder="Enter fees"
              chapter={this.props.chapter}
              name="feesTrfrCom"
              isRequired={isRequired}
              reducer={this.props.reducer}
              objBaseName={objBaseName}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
              validationType="Amount"
            />
            {errorMsg &&
              errorMsg?.isSubmitted === true &&
              errorMsg?.feesTrfrCom &&
              errorMsg?.feesTrfrCom?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.feesTrfrCom}
                </span>
              )}
          </div>
        </Row>
      </div>
    );
  }
}

export default NonRecFees;
