import React, { Component } from "react";
import BankInformation from "./bankInformation";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../../elements/CheckBox";
import FileUpload from "../../../../../elements/FileUpload";
import TextBox from "../../../../../elements/TextBox";
import { FATCAUSList, fileAcceptType } from "./Constants";
import { downloadFATCAForm } from "../../../../../../redux/actions/userActions";
import { saveAs } from "file-saver";
import DropDown from "../../../../../elements/DropDown";
import { checkIsCommon } from "../CommonFunctions";
import BankInformationMalta from "./BankInformationMalta";
class BankAccountOverView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  downloadFatca = () => {
    let fileName = "United NL_CRS FATCA Report.pdf";
    this.props.downloadFATCAForm({ fileName: fileName }).then((result) => {
      var blob = new Blob([result.data]);
      let file = "FATCA.pdf";
      saveAs(blob, file);
    });
  };

  render() {
    const { isFatcaSCF, errorMsg } = this.props;
    const eMsg = JSON.parse(errorMsg);
    return (
      <>
        <h4 className="sub-chapter-title mb-4" id="bnkInfo">
          Overview of bank accounts
          {this.props.form === "Malta" ? " for existing Target Company" : ""}
        </h4>
        <div className="pt-0 pb-2 obligationComapnygrid">
          {this.props.form === "Malta" ? (
            <BankInformationMalta
              reducer={
                checkIsCommon(this.props.form)
                  ? "commonFields"
                  : this.props.reducer
              }
              chapter={this.props.chapter}
              form={this.props.form}
              from="clientInformation"
            />
          ) : (
            <>
              <BankInformation
                reducer={
                  checkIsCommon(this.props.form)
                    ? "commonFields"
                    : this.props.reducer
                }
                chapter={this.props.chapter}
                form={this.props.form}
                from="clientInformation"
              />
            </>
          )}
          {eMsg && eMsg.bnkInfo && eMsg.bnkInfo?.length > 0 && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {eMsg.bnkInfo}
            </span>
          )}
        </div>
        <h4 className="sub-chapter-title mb-4 mt-5">
          Explain the procedure of handling payments/processing of bank
          statements and drafting of financial statements.
        </h4>
        {(this.props.form === "NL" || this.props.form === "UK") && (
          <>
            <Row className="pb-2">
              <div className="mb-3 col-lg-4">
                <CheckBox
                  label="FATCA Self Certification Form?"
                  options={["Yes", "No"]}
                  name="isFatcaSCF"
                  id="isFatcaSCF"
                  chapter={this.props.chapter}
                  objBaseName={this.props.objBaseName}
                  reducer={this.props.reducer}
                  isRequired={this.props.form === "UK" ? true : false} 
                />
                {eMsg && eMsg?.isFatcaSCF && eMsg.isFatcaSCF?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {eMsg.isFatcaSCF}
                  </span>
                )}
              </div>

              {isFatcaSCF === "Yes" && (
                <>
                  <div className="mb-3 col-lg-4">
                    <label>Download FATCA Form</label>
                    <div className="fileDownload">
                      <button
                        className="action-btn big download"
                        onClick={this.downloadFatca}
                      >
                        Download Form <i className="fa fa-download ml-2"></i>
                      </button>
                    </div>
                  </div>
                  <div className="mb-3 col-lg-4">
                    <FileUpload
                      label="Upload FATCA Self Certification Form"
                      acceptType={fileAcceptType}
                      name="fatcaSelfCertifctnFrm"
                      id="fatcaSelfCertifctnFrm"
                      chapter={this.props.chapter}
                      objBaseName={this.props.objBaseName}
                      reducer={this.props.reducer}
                      isRequired={this.props.form === "UK" ? true : false} 
                    />
                    {eMsg &&
                      eMsg?.fatcaSelfCertifctnFrm &&
                      eMsg.fatcaSelfCertifctnFrm?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {eMsg.fatcaSelfCertifctnFrm}
                        </span>
                      )}
                  </div>
                </>
              )}
              {isFatcaSCF === "No" && (
                <div className="mb-3 col-lg-4">
                  <TextBox
                    label="Please, explain why there is no self-certification for FATCA"
                    name="noFATCAformReason"
                    id="noFATCAformReason"
                    placeholder="enter your text"
                    chapter={this.props.chapter}
                    objBaseName={this.props.objBaseName}
                    reducer={this.props.reducer}
                    validationType="Text"
                    isRequired={this.props.form === "UK" ? true : false} 
                  />
                  {eMsg &&
                    eMsg?.noFATCAformReason &&
                    eMsg.noFATCAformReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {eMsg.noFATCAformReason}
                      </span>
                    )}
                </div>
              )}
            </Row>
            <Row>
              <div className="mb-3 col-lg-4">
                <DropDown
                  label="FATCA (US Foreign Account Tax Compliance Act)"
                  name="fatcaClassification"
                  id="fatcaClassification"
                  options={FATCAUSList}
                  reducer={this.props.reducer}
                  chapter={this.props.chapter}
                  objBaseName={this.props.objBaseName}
                />
                {eMsg &&
                  eMsg?.fatcaClassification &&
                  eMsg.fatcaClassification?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {eMsg.fatcaClassification}
                    </span>
                  )}
              </div>
            </Row>
          </>
        )}

        <Row className="pt-3 pb-2">
          {(this.props.form === "NL" || this.props.form === "UK") && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Bank Statements"
                acceptType={fileAcceptType}
                name="bnkStmnts"
                id="bnkStmnts"
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                reducer={this.props.reducer}
              />
              {eMsg && eMsg?.bnkStmnts && eMsg.bnkStmnts?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {eMsg.bnkStmnts}
                </span>
              )}
            </div>
          )}

          <div className="mb-3 col-lg-4">
            <FileUpload
              label="Agreements with group entities or other parties"
              acceptType={fileAcceptType}
              name="agremntsGrUpEntities"
              id="agremntsGrUpEntities"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              reducer={this.props.reducer}
            />
            {eMsg &&
              eMsg?.agremntsGrUpEntities &&
              eMsg.agremntsGrUpEntities?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {eMsg.agremntsGrUpEntities}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-4">
            <FileUpload
              label="Agreements with other parties"
              acceptType={fileAcceptType}
              name="agremntsOthrParties"
              id="agremntsOthrParties"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              reducer={this.props.reducer}
            />
            {eMsg &&
              eMsg?.agremntsOthrParties &&
              eMsg?.agremntsOthrParties?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {eMsg.agremntsOthrParties}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-4">
            <FileUpload
              label={
                this.props.form === "Malta"
                  ? "Financial statements Target Company"
                  : "Financial statements Object Company"
              }
              info="Upload Last three years statement"
              acceptType={fileAcceptType}
              name="financialStmntsObjComp"
              id="financialStmntsObjComp"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              reducer={this.props.reducer}
            />
            {eMsg &&
              eMsg?.financialStmntsObjComp &&
              eMsg.financialStmntsObjComp?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {eMsg.financialStmntsObjComp}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-4">
            <FileUpload
              label="Financial statements for all participations"
              info="Upload Last three years statement"
              acceptType={fileAcceptType}
              name="finanicalStmntsAllPrticipatns"
              id="finanicalStmntsAllPrticipatns"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              reducer={this.props.reducer}
            />
            {eMsg &&
              eMsg?.finanicalStmntsAllPrticipatns &&
              eMsg.finanicalStmntsAllPrticipatns?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {eMsg.finanicalStmntsAllPrticipatns}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-4">
            <FileUpload
              label="Financial statements for the parent company"
              info="Upload Last three years statement"
              acceptType={fileAcceptType}
              name="financialStmntsParntComp"
              id="financialStmntsParntComp"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              reducer={this.props.reducer}
            />
            {eMsg &&
              eMsg?.financialStmntsParntComp &&
              eMsg.financialStmntsParntComp?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {eMsg.financialStmntsParntComp}
                </span>
              )}
          </div>
          {(this.props.form === "NL" || this.props.form === "UK") && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Declarations from financial institutions"
                acceptType={fileAcceptType}
                name="declartnsFinanicalInstitutns"
                id="declartnsFinanicalInstitutns"
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                reducer={this.props.reducer}
              />
              {eMsg &&
                eMsg?.declartnsFinanicalInstitutns &&
                eMsg.declartnsFinanicalInstitutns?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {eMsg.declartnsFinanicalInstitutns}
                  </span>
                )}
            </div>
          )}
        </Row>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    downloadFATCAForm: (data) => dispatch(downloadFATCAForm(data)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { reducer } = ownProps;
  return {
    isFatcaSCF: state[reducer]["chapter4"]["ovrvwBnkAC"]["isFatcaSCF"],
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankAccountOverView);
