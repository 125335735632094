import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../../elements/CheckBox";
import DropDown from "../../../../../elements/DropDown";
import FileUpload from "../../../../../elements/FileUpload";
import TextBox from "../../../../../elements/TextBox";
import { fileAcceptType } from "./Constants";
import cc from "currency-codes";
import { checkIsCommon } from "../CommonFunctions";
let currencies = [];
cc.codes().map((currency) => currencies.push({ label: currency }));
class BearerShares extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isInsuranceBearerShares, errorMsg } = this.props;
    const eMsg =
      errorMsg !== null || errorMsg !== undefined ? JSON.parse(errorMsg) : null;
    return (
      <>
        <div className="html2pdf__page-break"></div>
        <Row className="pt-3 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Is there any issuance of bearer shares in the chain of the structure?"
              options={["Yes", "No"]}
              name="isInsuranceBearerShares"
              id="isInsuranceBearerShares"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              isCommon={checkIsCommon(this.props.form)}
              disabled={
                (this.props.form === "NL" ||
                  this.props.form === "Curacao" ||
                  this.props.form === "Malta") &&
                this.props.from !== "clientInformation"
              }
              isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false}
            />
            {eMsg &&
              eMsg?.isInsuranceBearerShares &&
              eMsg?.isInsuranceBearerShares?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg?.isInsuranceBearerShares}
                </span>
              )}
          </div>
          {isInsuranceBearerShares === "Yes" && (
            <>
              <div className="mb-3 col-lg-8">
                <Row className="pt-3 pb-2">
                  <div className="mb-3 col-lg-6">
                    <DropDown
                      label="Please specify currency"
                      name="bsCurrency"
                      id="bsCurrency"
                      options={currencies}
                      reducer={this.props.reducer}
                      chapter={this.props.chapter}
                      isCommon={checkIsCommon(this.props.form)}
                      disabled={
                        (this.props.form === "NL" ||
                          this.props.form === "Curacao" ||
                          this.props.form === "Malta") &&
                        this.props.from !== "clientInformation"
                      }
                      isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false}
                    />
                    {eMsg &&
                      eMsg?.bsCurrency &&
                      eMsg?.bsCurrency?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {eMsg?.bsCurrency}
                        </span>
                      )}
                  </div>
                  <div className="mb-3 col-lg-6">
                    <TextBox
                      label="Please specify amount"
                      placeholder="enter amount"
                      name="bsAmount"
                      id="bsAmount"
                      reducer={this.props.reducer}
                      chapter={this.props.chapter}
                      isCommon={checkIsCommon(this.props.form)}
                      validationType="Amount"
                      disabled={
                        (this.props.form === "NL" ||
                          this.props.form === "Curacao" ||
                          this.props.form === "Malta") &&
                        this.props.from !== "clientInformation"
                      }
                      isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false}
                    />
                    {eMsg && eMsg?.bsAmount && eMsg?.bsAmount?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg?.bsAmount}
                      </span>
                    )}
                  </div>
                  <div className="mb-3 col-lg-6">
                    <TextBox
                      label="Please specify holder(custodian) of bearer shares"
                      placeholder="enter holder"
                      name="bsHolder"
                      id="bsHolder"
                      reducer={this.props.reducer}
                      chapter={this.props.chapter}
                      isCommon={checkIsCommon(this.props.form)}
                      validationType="Text"
                      disabled={
                        (this.props.form === "NL" ||
                          this.props.form === "Curacao" ||
                          this.props.form === "Malta") &&
                        this.props.from !== "clientInformation"
                      }
                      isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false}
                    />
                    {eMsg && eMsg?.bsHolder && eMsg?.bsHolder?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg?.bsHolder}
                      </span>
                    )}
                  </div>
                  <div className="mb-3 col-lg-6">
                    <TextBox
                      label="Please specify beneficiary of bearer shares"
                      placeholder="enter beneficiary"
                      name="bsBeneficiary"
                      id="bsBeneficiary"
                      reducer={this.props.reducer}
                      chapter={this.props.chapter}
                      isCommon={checkIsCommon(this.props.form)}
                      validationType="Text"
                      disabled={
                        (this.props.form === "NL" ||
                          this.props.form === "Curacao" ||
                          this.props.form === "Malta") &&
                        this.props.from !== "clientInformation"
                      }
                      isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false}
                    />
                    {eMsg &&
                      eMsg?.bsBeneficiary &&
                      eMsg?.bsBeneficiary?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {eMsg?.bsBeneficiary}
                        </span>
                      )}
                  </div>
                  <div className="mb-3 col-lg-6">
                    <FileUpload
                      label="Please include evidence (e.g. certificate of deposit)"
                      acceptType={fileAcceptType}
                      name="bsEvidences"
                      id="bsEvidences"
                      reducer={this.props.reducer}
                      chapter={this.props.chapter}
                      isCommon={checkIsCommon(this.props.form)}
                      disabled={
                        (this.props.form === "NL" ||
                          this.props.form === "Curacao" ||
                          this.props.form === "Malta") &&
                        this.props.from !== "clientInformation"
                      }
                      isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false}
                    />
                    {eMsg &&
                      eMsg?.bsEvidences &&
                      eMsg?.bsEvidences?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {eMsg?.bsEvidences}
                        </span>
                      )}
                  </div>
                </Row>
              </div>
            </>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { form, reducer, chapter } = ownProps;
  if (form === "NL" || form === "Curacao" || form === "Malta") {
    return {
      isInsuranceBearerShares: state.commonFields["isInsuranceBearerShares"],
    };
  } else {
    return {
      isInsuranceBearerShares:
        state[reducer][chapter]["isInsuranceBearerShares"],
    };
  }
};

export default connect(mapStateToProps, null)(BearerShares);
