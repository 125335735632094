import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import countryList from "react-select-country-list";
import CheckBox from "../../../../elements/CheckBox";
import TextBox from "../../../../elements/TextBox";
import DownloadFile from "../../../../elements/DownloadFile";
import MultiSelectDropdown from "../../../../elements/MultiSelectDropdown";
import DropDown from "../../../../elements/DropDown";
import { fileAcceptType,newClient } from "../Constants";
import InternalFileUpload from "../../../../elements/InternalFileUpload";
import NumberInput from "../../../../elements/NumberInput";
import Client from "./Client";
class Intermediary extends React.Component {
  render() {
    const { index, chapter, reducer, intermediary, baseName, errors,isClientNew,noNewClients } =
      this.props;
    const errorsMsg = JSON.parse(errors);
    const exsistingClients = [];
    if (isClientNew=="No" && this.props.form =="UK") {     
      for (let i = 0; i < noNewClients; i++) {
        exsistingClients.push(
          <Client
            index={i}
            form="UK"
            baseName="newClients"
            objBaseName= "intermediarySignNew"
            indexObjBaseName={index}
            reducer="UKInternalCAF"
            chapter={chapter}            
          />
         
        );
      }
    }

    return (
      <Row>
        <div className="mb-4 col-lg-12">
          <fieldset>
            <legend>
              {this.props.form === "Malta"
                ? "Intermediary / Agent"
                : "Intermediary"}{" "}
              {index + 1}
            </legend>
            <Row>
              <div className="mb-2 col-lg-4">
                <CheckBox
                  label="Natural Person"
                  options={["Yes", "No"]}
                  name="isClientReal"
                  index={index}
                  chapter={chapter}
                  baseName={baseName}
                  reducer={reducer}
                  bothDisabled={this.props.reducer === "ClientAcceptance" ? false : true} 
                />
              </div>
              {intermediary.isClientReal === "Yes" && (
                <>
                  <div className="mb-2 col-lg-8">
                    {this.props.form === "Malta" && (
                      <>
                        <Row>
                          <div className="mb-2 col-lg-6">
                            <CheckBox
                              label="Is the natural person intermediary?"
                              options={["Yes", "No"]}
                              name="isNaturPerInterm"
                              index={index}
                              chapter={chapter}
                              baseName={baseName}
                              reducer={reducer}
                              bothDisabled={this.props.reducer === "ClientAcceptance" ? false : true} 
                            />
                          </div>
                          {intermediary.isNaturPerInterm === "No" && (
                            <div className="mb-2 col-lg-4">
                              <CheckBox
                                label="Is the natural person agent?"
                                options={["Yes", "No"]}
                                name="isNaturPerAgent"
                                index={index}
                                chapter={chapter}
                                baseName={baseName}
                                reducer={reducer}
                                bothDisabled={this.props.reducer === "ClientAcceptance" ? false : true} 
                              />
                            </div>
                          )}
                        </Row>
                      </>
                    )}
                  </div>
                </>
              )}
              {(this.props.form !== "Malta" ||
                (this.props.form === "Malta" &&
                  intermediary.isNaturPerAgent === "Yes") ||
                (this.props.form === "Malta" &&
                  intermediary.isNaturPerInterm === "Yes")) && (
                <>
                  <div className="mb-2 col-lg-8">
                  {(this.props.form !== "UK") &&(
                    <Row>                   
                      <div className="mb-3 col-lg-6">
                        <TextBox
                          label={`Name intermediary ${
                            this.props.form === "Malta" ? "/ agent" : ""
                          }`}
                          placeholder="enter Intermediary name"
                          name="clientRepName"
                          index={index}
                          chapter={chapter}
                          baseName={baseName}
                          reducer={reducer}
                          validationType="Text"
                          disabled={this.props.reducer === "ClientAcceptance" ? false : true} 
                        />
                      </div>               
                      <div className="mb-2 col-lg-6">
                        <TextBox
                          label="Legal entity name"
                          placeholder="enter legal entity name"
                          name="legalEntityName"
                          index={index}
                          chapter={chapter}
                          baseName={baseName}
                          reducer={reducer}
                          validationType="Text"
                          disabled={this.props.reducer === "ClientAcceptance" ? false : true} 
                        />
                      </div>
                      <div className="mb-2 col-lg-6">
                        {intermediary.clientRepPassport.length !=0 && this.props.reducer !== "ClientAcceptance" &&(
                        <DownloadFile
                          label="Upload passport copy"
                          index={index}
                          name="clientRepPassport"
                          value={intermediary.clientRepPassport}
                          chapter={chapter}
                          baseName={baseName}
                          reducer={reducer}
                        />
                        )}
                        {this.props.reducer === "ClientAcceptance" && (
                       <InternalFileUpload
                         label="Upload passport copy"
                         index={index}
                         name="clientRepPassport"
                         index={index}
                         chapter={chapter}
                         baseName={baseName}
                         reducer={reducer}
                         acceptType={fileAcceptType}
                        
                      />
                        )}
                      </div>
                      <div className="mb-2 col-lg-6">
                        <DropDown
                          label="Nationality"
                          index={index}
                          name="clientRepNationality"
                          options={countryList().getData()}
                          chapter={chapter}
                          baseName={baseName}
                          reducer={reducer}
                          disabled={this.props.reducer === "ClientAcceptance" ? false : true} 
                        />
                      </div>
                      
                    </Row>
                  )}
                  {this.props.form === "UK" && (
                  <Row>                  
                        <>
                        {intermediary.isClientReal === "Yes" && (
                          <>
                          <div className="mb-3 col-lg-6">
                        <TextBox
                          label={`Name intermediary ${
                            this.props.form === "Malta" ? "/ agent" : ""
                          }`}
                          placeholder="enter Intermediary name"
                          name="clientRepName"
                          index={index}
                          chapter={chapter}
                          baseName={baseName}
                          reducer={reducer}
                          validationType="Text"
                          disabled={this.props.reducer === "ClientAcceptance" ? false : true} 
                        />
                      </div>               
                      <div className="mb-2 col-lg-6">
                        <TextBox
                          label="Legal entity name"
                          placeholder="enter legal entity name"
                          name="legalEntityName"
                          index={index}
                          chapter={chapter}
                          baseName={baseName}
                          reducer={reducer}
                          validationType="Text"
                          disabled={this.props.reducer === "ClientAcceptance" ? false : true} 
                        />
                      </div>
                      <div className="mb-2 col-lg-6">
                        {this.props.reducer !== "ClientAcceptance" &&(
                        <DownloadFile
                          label="Upload passport copy"
                          index={index}
                          name="clientRepPassport"
                          value={intermediary.clientRepPassport}
                          chapter={chapter}
                          baseName={baseName}
                          reducer={reducer}
                        />
                        )}
                        {this.props.reducer === "ClientAcceptance" && (
                       <InternalFileUpload
                         label="Upload passport copy"
                         index={index}
                         name="clientRepPassport"
                         index={index}
                         chapter={chapter}
                         baseName={baseName}
                         reducer={reducer}
                         acceptType={fileAcceptType}
                        
                      />
                        )}
                      </div>
                      <div className="mb-2 col-lg-6">
                        <DropDown
                          label="Nationality"
                          index={index}
                          name="clientRepNationality"
                          options={countryList().getData()}
                          chapter={chapter}
                          baseName={baseName}
                          reducer={reducer}
                          disabled={this.props.reducer === "ClientAcceptance" ? false : true} 
                        />
                      </div>
                           <div className="mb-2 col-lg-6">
                           <DownloadFile
                             label="Upload Proof of Address"
                             index={index}
                             name="intrProofAdd"
                             value={intermediary.intrProofAdd}
                             chapter={chapter}
                             baseName={baseName}
                             reducer={reducer}
                           />
                         </div>
                         <div className="mb-2 col-lg-6">
                           <DownloadFile
                             label="Upload reference letter"
                             index={index}
                             name="intrReferenceLett"
                             value={intermediary.intrReferenceLett}
                             chapter={chapter}
                             baseName={baseName}
                             reducer={reducer}
                           />
                         </div>
                         </>
                        )}
                        {intermediary.isClientReal === "No" && (
                           <>
                            <div className="mb-2 col-lg-4">
                    <TextBox
                      label="Legal entity name"
                      name="legalEntityName"
                      placeholder="enter legal entity name"
                      index={index}
                      chapter={chapter}
                      baseName={baseName}
                      reducer={reducer}
                      validationType="Text"
                      disabled={this.props.reducer === "ClientAcceptance" ? false : true} 
                    />
                  </div>
                  <div className="mb-2 col-lg-4">
                  {this.props.reducer !== "ClientAcceptance" && (
                    <DownloadFile
                      label="Upload CoC extract"
                      index={index}
                      name="cocExtract"
                      value={intermediary.cocExtract}
                      chapter={chapter}
                      baseName={baseName}
                      reducer={this.props.reducer}
                    />
                  )}
                   {this.props.reducer === "ClientAcceptance" && (
                       <InternalFileUpload
                         label="Upload CoC extract"
                         index={index}
                         name="cocExtract"
                         index={index}
                         chapter={chapter}
                         baseName={baseName}
                         reducer={reducer}
                         acceptType={fileAcceptType}
                        
                      />
                        )}
                  </div>
                           </>
                        )}
                        
                        </>               
                  </Row>
                    )}
                  </div>
                </>
              )}
              {this.props.form !== "UK" && intermediary.isClientReal === "No" && (
                <>
                  <div className="mb-2 col-lg-4">
                    <TextBox
                      label="Legal entity name"
                      name="legalEntityName"
                      placeholder="enter legal entity name"
                      index={index}
                      chapter={chapter}
                      baseName={baseName}
                      reducer={reducer}
                      validationType="Text"
                      disabled={this.props.reducer === "ClientAcceptance" ? false : true} 
                    />
                  </div>
                  <div className="mb-2 col-lg-4">
                  {intermediary.cocExtract.length !=0 && this.props.reducer !== "ClientAcceptance" && (
                    <DownloadFile
                      label="Upload CoC extract"
                      index={index}
                      name="cocExtract"
                      value={intermediary.cocExtract}
                      chapter={chapter}
                      baseName={baseName}
                      reducer={this.props.reducer}
                    />
                  )}
                   {this.props.reducer === "ClientAcceptance" && (
                       <InternalFileUpload
                         label="Upload CoC extract"
                         index={index}
                         name="cocExtract"
                         index={index}
                         chapter={chapter}
                         baseName={baseName}
                         reducer={reducer}
                         acceptType={fileAcceptType}
                        
                      />
                        )}
                  </div>
                </>
              )}
              {intermediary.isClientReal === "" && (
                <>
                  <div className="mb-2 col-lg-4"></div>
                  <div className="mb-2 col-lg-4"></div>
                </>
              )}
              <div className="col-lg-12">
                <div className="row">
                  <div className="form-group col-lg-4">
                    <MultiSelectDropdown
                      label="Active in countries"
                      name="activeInCountry"
                      options={countryList().getData()}
                      index={index}
                      chapter={chapter}
                      baseName={baseName}
                      reducer={this.props.reducer}
                      disabled={this.props.reducer === "ClientAcceptance" ? false : true} 
                    />
                  </div>

                  <div className="mb-2 col-lg-4">
                    <CheckBox
                      label="Power of attorney available"
                      options={["Yes", "No"]}
                      name="isPOA"
                      index={index}
                      chapter={chapter}
                      baseName={baseName}
                      reducer={this.props.reducer}
                      bothDisabled={this.props.reducer === "ClientAcceptance" ? false : true} 
                    />
                  </div>
                  {intermediary.isPOA === "Yes" && (
                    <div className="mb-2 col-lg-4">
                      {intermediary.poa.length !=0 && this.props.reducer !== "ClientAcceptance" &&(
                      <DownloadFile
                        label="Upload"
                        name="poa"
                        value={intermediary.poa}
                        index={index}
                        chapter={chapter}
                        baseName={baseName}
                        reducer={this.props.reducer}
                      />
                      )}
                       {this.props.reducer === "ClientAcceptance" && (
                       <InternalFileUpload
                         label="Upload"
                         index={index}
                         name="poa"
                         index={index}
                         chapter={chapter}
                         baseName={baseName}
                         reducer={reducer}
                         acceptType={fileAcceptType}
                        
                      />
                        )}
                    </div>
                  )}
                  {intermediary.isPOA === "No" && (
                    <div className="mb-2 col-lg-4">
                      <TextBox
                        label="Why Not?"
                        name="noPOAReason"
                        placeholder="Enter Reason"
                        index={index}
                        chapter={chapter}
                        baseName={baseName}
                        reducer={this.props.reducer}
                        validationType="Text"
                        disabled={this.props.reducer === "ClientAcceptance" ? false : true} 
                      />
                    </div>
                  )}
                  {intermediary.isPOA === "" && (
                    <div className="mb-2 col-lg-4"></div>
                  )}
                </div>
              </div>
              {this.props.form === "UK" && (
                <div className="col-lg-12">
                  <div className="row">
                    <div className="mb-2 col-lg-4">
                      <CheckBox
                        label="Is the intermediary regulated by UK equivalent regulator?"
                        options={["Yes", "No"]}
                        name="isIntRegUKEqRegu"
                        id={`isIntRegUKEqRegu[${index}]`}
                        index={index}
                        chapter={chapter}
                        baseName={baseName}
                        reducer={this.props.reducer}
                        disabled={this.props.reducer === "ClientAcceptance" ? false : true} 
                      />
                    </div>
                    {intermediary.isIntRegUKEqRegu === "Yes" && (
                      <div className="mb-2 col-lg-4">
                        <DownloadFile
                          label="Upload supporting documents"
                          name="eqRegulator"
                          value={intermediary.eqRegulator}
                          index={index}
                          chapter={chapter}
                          baseName={baseName}
                          reducer={this.props.reducer}
                        />
                      </div>
                    )}
                    {intermediary.isIntRegUKEqRegu === "No" && (
                      <div className="mb-2 col-lg-4">
                        <TextBox
                          label="Why Not?"
                          placeholder="Enter Reason"
                          name="eqRegExplaination"
                          id={`eqRegExplaination[${index}]`}
                          index={index}
                          chapter={chapter}
                          baseName={baseName}
                          reducer={this.props.reducer}
                          validationType="Text"
                          disabled={this.props.reducer === "ClientAcceptance" ? false : true} 
                        />
                      </div>
                    )}
                    {intermediary.isIntRegUKEqRegu === "" && (
                      <div className="mb-2 col-lg-4"></div>
                    )}
                  </div>
                </div>
              )}
              <div className="col-lg-12">
                <div className="row">
                  <div className="mb-2 col-lg-4">
                    <CheckBox
                      label="Is the intermediary's' ultimate beneficial owner identified and verified? "
                      options={["Yes", "No"]}
                      name="isUBOIdentifyVerify"
                      index={index}
                      chapter={chapter}
                      baseName={baseName}
                      reducer={this.props.reducer}
                      bothDisabled={this.props.reducer === "ClientAcceptance" ? false : true} 
                    />
                  </div>

                  {intermediary.isUBOIdentifyVerify === "Yes" && (
                    <div className="mb-2 col-lg-4">
                      {intermediary.uboIdentityPassport.length !=0 && this.props.reducer !== "ClientAcceptance" &&(
                      <DownloadFile
                        label="Upload Passport"
                        name="uboIdentityPassport"
                        value={intermediary.uboIdentityPassport}
                        index={index}
                        chapter={chapter}
                        baseName={baseName}
                        reducer={this.props.reducer}
                      />
                      )}
                       {this.props.reducer === "ClientAcceptance" && (
                          <InternalFileUpload
                          label="Upload Passport"
                          index={index}
                          name="uboIdentityPassport"
                          index={index}
                          chapter={chapter}
                          baseName={baseName}
                          reducer={reducer}
                          acceptType={fileAcceptType}
                         
                       />
                       )}
                    </div>
                  )}
                  {intermediary.isUBOIdentifyVerify === "No" && (
                    <div className="mb-2 col-lg-4">
                      <TextBox
                        label="Why Not?"
                        placeholder="Enter Reason"
                        name="noUBOIdentifyVerifyReason"
                        index={index}
                        chapter={chapter}
                        baseName={baseName}
                        reducer={this.props.reducer}
                        validationType="Text"
                        disabled={this.props.reducer === "ClientAcceptance" ? false : true} 
                      />
                    </div>
                  )}
                  {intermediary.isUBOIdentifyVerify === "" && (
                    <div className="mb-2 col-lg-4"></div>
                  )}
                </div>
                {this.props.form === "UK" && (
                  <Row>
                    <div className="mb-2 col-lg-4">
                      <DownloadFile
                        label="Upload AML policies and procedure"
                        name="intrAmlPoliciesProcs"
                        value={intermediary.intrAmlPoliciesProcs}
                        index={index}
                        chapter={chapter}
                        baseName={baseName}
                        reducer={this.props.reducer}
                      />
                    </div>
                  </Row>
                )}
              </div>
            </Row>
            {this.props.form === "UK" && (
              <Row className="mt-2 border-top pt-3">
                <div className="mb-2 col-lg-4">
                  <CheckBox
                    label="Signing client Agreement?"
                    options={["Yes", "No"]}
                    name="isSigningClientntAg"
                    index={index}
                    id={`isSigningClientntAg[${index}]`}
                    chapter={chapter}
                    baseName="intermediarySignNew"
                    reducer="UKInternalCAF"
                    isRequired={true}
                  />
                  {errorsMsg &&
                    errorsMsg.isSubmitted === true &&
                    errorsMsg.intermediarySignNew &&
                    errorsMsg.intermediarySignNew[index] &&
                    errorsMsg.intermediarySignNew[index]?.isSigningClientntAg &&
                    errorsMsg.intermediarySignNew[index]?.isSigningClientntAg
                      ?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {
                          errorsMsg.intermediarySignNew[index]
                            ?.isSigningClientntAg
                        }
                      </span>
                    )}
                </div>
                <div className="mb-2 col-lg-8">
                  <CheckBox
                    label="Is Client New?"
                    options={["Yes", "No"]}
                    name="isClientNew"
                    index={index}
                    chapter={chapter}
                    id={`isClientNew[${index}]`}
                    baseName="intermediarySignNew"
                    reducer="UKInternalCAF"
                    isRequired={true}
                  />
                  {errorsMsg &&
                    errorsMsg.isSubmitted === true &&
                    errorsMsg.intermediarySignNew &&
                    errorsMsg.intermediarySignNew[index] &&
                    errorsMsg.intermediarySignNew[index]?.isClientNew &&
                    errorsMsg.intermediarySignNew[index]?.isClientNew?.length >
                      0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.intermediarySignNew[index]?.isClientNew}
                      </span>
                    )}
                </div>
                <div className="mb-2 col-lg-4">
                </div>
                {isClientNew === "No" && (
                  <>
                  <div className="mb-2 col-lg-4 col-xl-8">
                  <NumberInput
                      label="How many clients?"
                      name="noNewClients"
                      masterObj={["intermediarySignNew"]}
                      masterIndex={index}
                      referral={["newClients"]}
                      pushedObject={[newClient]}
                      maxLimit={100}
                      id={`noNewClients[${index}]`}
                      index={index}
                      chapter={chapter}
                      baseName="intermediarySignNew"
                      reducer="UKInternalCAF"
                    />
                       {exsistingClients}
                  </div>
               </>               
            )}
           
              </Row>
               
            )}
            {this.props.form === "Malta" &&
              intermediary.isUBOIdentifyVerify === "Yes" && (
                <Row>
                  <div className="mb-3 col-lg-4">
                    <TextBox
                      label="Address"
                      placeholder="enter address"
                      name="address"
                      index={index}
                      chapter={chapter}
                      baseName={baseName}
                      reducer={reducer}
                      validationType="Text"
                      disabled={true}
                    />
                  </div>
                  <div className="mb-2 col-lg-4">
                    <DownloadFile
                      label="Utility bill"
                      name="utilityBill"
                      value={intermediary.utilityBill}
                      index={index}
                      chapter={chapter}
                      baseName={baseName}
                      reducer={this.props.reducer}
                    />
                  </div>
                </Row>
              )}
          </fieldset>
        </div>
      </Row>
    );    
  }
}

const mapStateToProps = (state, ownProps) => {
  const { baseName, index, reducer, chapter } = ownProps;
 
  return {
    intermediary: state[reducer][chapter][baseName][index],
    isClientNew: state["UKInternalCAF"][chapter]["intermediarySignNew"][index].isClientNew,
    noNewClients:state["UKInternalCAF"][chapter]["intermediarySignNew"][index].noNewClients
  };
};

export default connect(mapStateToProps, null)(Intermediary);
