import {
  GET_CLIENT_LIST_REQUEST,
  GET_CLIENT_LIST_SUCCESS,
  GET_CLIENT_LIST_FAIL,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
  ADD_CLIENT_REQUEST,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAIL,
  GET_CLIENT_BY_ID_REQUEST,
  GET_CLIENT_BY_ID_SUCCESS,
  GET_CLIENT_BY_ID_FAIL,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  BULK_DELETE_REQUEST,
  BULK_DELETE_SUCCESS,
  BULK_DELETE_FAIL,
  APPROVE_REJECT_FROM_MAIL_REQUEST,
  APPROVE_REJECT_FROM_MAIL_SUCCESS,
  APPROVE_REJECT_FROM_MAIL_FAIL,
  CLIENT_APPROVAL_LIST_REQUEST,
  CLIENT_APPROVAL_LIST_SUCCESS,
  CLIENT_APPROVAL_LIST_FAIL,
  APPROVE_REJECT_CLIENT_FAIL,
  APPROVE_REJECT_CLIENT_REQUEST,
  APPROVE_REJECT_CLIENT_SUCCESS,
  ASSIGN_USER_LIST_REQUEST,
  ASSIGN_USER_LIST_SUCCESS,
  ASSIGN_USER_LIST_FAIL,
} from "../constants/clientConstants";

const initialState = {
  isLoading: false,
  user: [],
  error: "",
};

const clientReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CLIENT_LIST_REQUEST:
    case DELETE_CLIENT_REQUEST:
    case GET_CLIENT_BY_ID_REQUEST:
    case ADD_CLIENT_REQUEST:
    case UPDATE_CLIENT_REQUEST:
    case BULK_DELETE_REQUEST:
    case APPROVE_REJECT_FROM_MAIL_REQUEST:
    case CLIENT_APPROVAL_LIST_REQUEST:
    case APPROVE_REJECT_CLIENT_REQUEST:
    case ASSIGN_USER_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    case GET_CLIENT_LIST_SUCCESS:
    case DELETE_CLIENT_SUCCESS:
    case GET_CLIENT_BY_ID_SUCCESS:
    case ADD_CLIENT_SUCCESS:
    case UPDATE_CLIENT_SUCCESS:
    case BULK_DELETE_SUCCESS:
    case APPROVE_REJECT_FROM_MAIL_SUCCESS:
    case CLIENT_APPROVAL_LIST_SUCCESS:
    case APPROVE_REJECT_CLIENT_SUCCESS:
    case ASSIGN_USER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case GET_CLIENT_LIST_FAIL:
    case DELETE_CLIENT_FAIL:
    case GET_CLIENT_BY_ID_FAIL:
    case ADD_CLIENT_FAIL:
    case UPDATE_CLIENT_FAIL:
    case BULK_DELETE_FAIL:
    case APPROVE_REJECT_FROM_MAIL_FAIL:
    case CLIENT_APPROVAL_LIST_FAIL:
    case APPROVE_REJECT_CLIENT_FAIL:
    case ASSIGN_USER_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default clientReducer;
