/* eslint-disable no-useless-escape */
export const validateGenericForm = (state) => {
  let errors = {};
  let isValid = true;
  const message = "Please fill this detail";
  const { isUBO, isPEP } = state.genericInformation.generic;
  const emailRegex = RegExp(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const { commonFields } = state;
  let UBOErrors = {
    ubox: [],
  };
  let PEPErrors = {
    PEP: [],
  };
  if (isUBO === "" || isUBO === null) {
    isValid = false;
    errors["isUBO"] = message;
  }
  if (isPEP === "" || isPEP === null) {
    isValid = false;
    errors["isPEP"] = message;
  }

  //needs to add mandatory fields validations here
  if (isUBO === "Yes" || isPEP === "Yes") {
    let PEPArray = commonFields.pep.pepx;
    let UBOArray = commonFields.ubo.ubox;
    let foundInUBO = false;
    let foundInPEP = false;

    for (let i = 0; i < UBOArray.length; i++) {
      let obj = UBOArray[i];
      if (obj.uboEmail !== null && obj.uboPhoneNum !== null) {
        const filteredArray = UBOArray.filter((item) => item !== obj);
        foundInUBO = filteredArray.some(
          (ele) =>
            ele.uboEmail === obj.uboEmail || ele.uboPhoneNum === obj.uboPhoneNum
        );
        foundInPEP = PEPArray.some(
          (ele) =>
            ele.pepEmail === obj.uboEmail || ele.pepPhoneNum === obj.uboPhoneNum
        );
        if (foundInUBO || foundInPEP) {
          isValid = false;
          errors["sameData"] =
            "PEP or UBO details should not be same as other PEP or UBO details";
        }
      }
      if (isUBO === "Yes") {
        let errorsUBO = {};
        errorsUBO.uboXName =
          obj["uboXName"] === "" ||
          obj["uboXName"] === null ||
          obj["uboXName"] === undefined
            ? message
            : "";
        errorsUBO.uboEmail =
          obj["uboEmail"] === "" ||
          obj["uboEmail"] === null ||
          obj["uboEmail"] === undefined
            ? message
            : "";
        if (obj["uboEmail"]) {
          emailRegex.test(obj["uboEmail"])
            ? (errorsUBO.uboEmail = "")
            : (errorsUBO.uboEmail = "Add valid Email ID");
        }
        errorsUBO.uboPhoneNum =
          obj["uboPhoneNum"] === "" ||
          obj["uboPhoneNum"] === null ||
          obj["uboPhoneNum"] === undefined
            ? message
            : "";

        errorsUBO.isUBOPEP =
          obj["isUBOPEP"] === "" ||
          obj["isUBOPEP"] === null ||
          obj["isUBOPEP"] === undefined
            ? message
            : "";

        UBOErrors.ubox.push(errorsUBO);
      }
    }

    for (let i = 0; i < PEPArray.length; i++) {
      let obj = PEPArray[i];
      if (obj.pepEmail !== null && obj.pepPhoneNum !== null) {
        const filteredArray = PEPArray.filter((item) => item !== obj);
        foundInPEP = filteredArray.some(
          (ele) =>
            obj.pepEmail === ele.pepEmail || obj.pepPhoneNum === ele.pepPhoneNum
        );
        foundInUBO = UBOArray.some(
          (ele) =>
            obj.pepEmail === ele.uboEmail || obj.pepPhoneNum === ele.uboPhoneNum
        );
        if (foundInUBO || foundInPEP) {
          isValid = false;
          errors["sameData"] =
            "PEP or UBO should not be same as other PEP or UBO";
        }
      }
      if (isPEP === "Yes") {
        let errorsPEP = {};
        errorsPEP.pepName =
          obj["pepName"] === "" ||
          obj["pepName"] === null ||
          obj["pepName"] === undefined
            ? message
            : "";
        errorsPEP.pepEmail =
          obj["pepEmail"] === "" ||
          obj["pepEmail"] === null ||
          obj["pepEmail"] === undefined
            ? message
            : "";
        if (obj["pepEmail"]) {
          emailRegex.test(obj["pepEmail"])
            ? (errorsPEP.pepEmail = "")
            : (errorsPEP.pepEmail = "Add  valid Email ID");
        }
        errorsPEP.pepPhoneNum =
          obj["pepPhoneNum"] === "" ||
          obj["pepPhoneNum"] === null ||
          obj["pepPhoneNum"] === undefined
            ? message
            : "";

        PEPErrors.PEP.push(errorsPEP);
      }
    }
  }

  return { isValid, errors, UBOErrors, PEPErrors };
};
