import React, { Component } from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";

class EntityDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, errorMsg } = this.props;
    const eMsg = errorMsg;
    return (
      <fieldset>
          <legend>Entity {this.props.index + 1}</legend>
          <Row>
            <div className="mb-2 col-lg-6 col-xl-6">
              <TextBox
                label="Name"
                name="entityName"
                id={`entityName[${index}]`}
                placeholder="enter your text"               
                index={this.props.index}
                baseName="entities"
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}              
              />             
            </div>  
            <div class="mb-2 col-lg-4 col-xl-4">
    
              <TextBox
                label="Percent %"
                name="entityPercent"
                id={`entityPercent[${index}]`}
                //placeholder="enter your text"               
                index={this.props.index}
                baseName="entities"
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}              
              />                                
            </div>
             
          </Row>
        </fieldset>
     
    );
  }
}

export default EntityDetails;
