import { uuid } from "./GenerateGUID";

const upc = {
  nameUPC: null,
  addressRegistered: null,
  postalCode: null,
  city: null,
  country: null,
  websiteUPC: null,
  taxNum: null,
  tradeRegisterNum: null,
  phoneNumberUPC: null,
  countryOfTaxRsdnc: null,
  typeOfUPC: null,
  isForceCloseFinAcc: "",
  ForceCloseFinAcc: null,
  extractChamberOfCommerce: [],
  deedOfIncorporation: [],
  shareholdersRegister: [],
  shareholdersCertificates: [],
  nomineeAgreement: [],
  financialStatementsThreeYears: [],
  upcAuthDirName: null,
  upcAuthDirEmail: null,
  upcAuthDirNationality: null,
  upcAuthDirUtilityBill: [],
  upcAuthDirPass: [],
};

export const UBOXArray = {
  uboId: uuid(),
  uboXName: null,
  uboEmail: null,
  uboPhoneNum: null,
  passportNum: null,
  isUBOPEP: "",
  certifiedPassportUBO: [],
};

const ubo = {
  noOfUBOs: 1,
  ubox: [UBOXArray],
};

export const PEPXArray = {
  pepId: uuid(),
  pepName: null,
  pepEmail: null,
  pepPassportNum: null,
  pepPhoneNum: null,
};
const pep = {
  noNonUboPep: 1,
  pepx: [PEPXArray],
};

const ThirdPartyInvstObjComp = {
  tpName: null,
  tpAnnualFinStmnts: [],
  tpCountry: null,
};
const TypeSizeFreqTransct = {
  typeTrnsct: "",
  sizeTrnsct: "",
  freqTrnsct: "",
};

const srcFundsObjComp = {
  isUBOInvstObjComp: "",
  uboInvstCurrencyObjComp: null,
  uboInvstObjCompAmnt: null,
  uboInvstObjCompOrigin: null,
  isUPCfundObjComp: "",
  upcFundObjCompOrigin: null,
  annualFinStmnt: [],
  isTpInvstObjComp: "",
  tpInvstObjComp: ThirdPartyInvstObjComp,
  cntrysInvlvdTrnsctnsObjComp: [],
  estmtdAnnualTrnOvrObjComp: "",
  assetSummryObjComp: "",
  assetSummryEvidence: [],
  expctdTrnsctnsSummary: "",
  expctdTrnsctnsEvidence: [],
  objCompToGrpComp: TypeSizeFreqTransct,
  grpCompToObjComp: TypeSizeFreqTransct,
  objCompToUBO: TypeSizeFreqTransct,
  uboToObjComp: TypeSizeFreqTransct,
  objCompToTP: TypeSizeFreqTransct,
  tpToObjComp: TypeSizeFreqTransct,
  rightsAndObligations: [],
};

export const commonFields = {
  //chapter 1
  upcActivites: null,
  upcSector: null,
  shareholderActivites: null,
  shareholderSector: null,
  objCompActivities: null,
  objCompSector: null,
  participantActivites: null,
  participantSector: null,
  otherActivites: null,
  otherSector: null,

  //chapter 2
  isClientTransfrd: "",
  srvcPrvdrName: null,
  reasonClientTransfrd: null,

  //chapter3
  isUBOExists:"",
  isUPCExists: "",
  upcExplanation:"",
  isShareholderUBO: null,
  complianceList: null,
  upc: upc,
  ubo: ubo,

  //chapter4
  objCompName: null,
  objCompNaturePropsdObjctv: null,
  objCompOtherNaturePropsdObjctv: null,
  rationaleStructurigForNL: [],
  rationaleSelectedInfo: null,
  isTaxMemorandum: null,
  taxAdvisorName: null,
  taxMemorandumAdvisorName: null,
  taxMemorandum: [],
  isInvstmntMemorandum: null,
  invstmntMemorandum: [],
  isInsuranceBearerShares: "",
  bsCurrency: null,
  bsAmount: null,
  bsHolder: null,
  bsBeneficiary: null,
  bsEvidences: [],
  srcFundsObjComp: srcFundsObjComp,
  bnkInfo: [],

  //not included in CAF
  pep: pep,
  dirty: false,
};
