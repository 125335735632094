import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { Component } from "react";
import { connect } from "react-redux";
import countryList from "react-select-country-list";
import DropDown from "../../../../elements/DropDown";
import TextBox from "../../../../elements/TextBox";
import {
  setCuracaoInternalCAFDetails,
  getCuracaoInternalCAFDetails,
  createCuracaoInternalCAFDetails,
} from "../../../../../redux/actions/Curacao/CuracaoInternalCAFActions";
import {
  setNewDataToReducer,
} from "../../../../../redux/actions/ClientInformationAction";
import "../../Onboarding/internalCAF.scss";
import ActivityRisks from "../../Onboarding/Chapter1/ActivityRisks";
import NotificationManager from "react-notifications/lib/NotificationManager";
import DownloadFile from "../../../../elements/DownloadFile";
import CheckBox from "../../../../elements/CheckBox";
import MediaCoverage from "../../Onboarding/Chapter1/MediaCoverage";
import Risks from "../../Onboarding/Chapter1/Risks";
import ActivityDetails from "../../Onboarding/Chapter1/ActivityDetails";
import AutoSaving from "../AutoSaving";
import getSequentialFields from "../getSequentialFields";
import { setCuracaoInternalChapterError } from "../../../../../redux/actions/Curacao/CuracaoInternalChapterErrors";
import { chapValidationErrorCuracao } from "../Constants";
import Select from "react-select";
import jwt_decode from "jwt-decode";
import DateTimePicker from "../../../../elements/DateTimePicker";
import NumberInput from "../../../../elements/NumberInput";
import { getClientList } from "../../../../../redux/actions/clientActions";
import Tooltip from "../../../../elements/Tooltip";
import Modal from "../../../../components/shared/Modal";

const chapter = "chapter1";
const reducer = "CuracaoInternalCAF";
let timer;
class AddClientForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      isLoading: false,
      errors: {},
      clientInfoFormName:"",
      countries: [],
      offices: [],
      clientList: [],
      tokenData: null,
      clientName: "",
      clientId: "",
      selectedClient: null
    };

  }
  componentDidMount = async () => {
     let originalClientList = [];
    let token = JSON.parse(localStorage.getItem("authToken"));
    if (token) {
      var decoded = jwt_decode(token);
      var data = {
        name: decoded.name,
      };
      this.setState({ isLoading: true });
      //----------------get all CU clients-----------
      await this.props.getClientList(data).then((res) => {
        if (res.data.portalErrorCode === 200) {
          console.log("res?.data?.result", res?.data?.result);
          let filteredlst = [];
          res?.data?.result.filter((client) => {
            client.office.filter(value => {
              if (value.name.includes("Curacao")) {
                filteredlst.push(client);
              }
            })
          });
          this.setState({
            isLoading: false,
            clientList: filteredlst,
            
          });

          originalClientList = filteredlst;
        }
      });
    }

  };
 
  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = "853e42b3-1402-4de4-b666-6eb9d7b89db4";
    requestData[chapter] = data;

    
  };
  onchangeCountry = (selectedoption) => {
    let errors = {};
    errors["country"] = "";
    this.setState({ errors });
    this.setState({
      offices: [],
      selectedOffice: "",
    });
    this.setState({
      selectedCountry: selectedoption,
      offices: selectedoption.office,
    });
  };
  onchangeOffice = (selectedoffice) => {
    let errors = {};
    errors["office"] = "";
    this.setState({ errors });
    this.setState({
      selectedOffice: selectedoffice,
    });
  };
  backToList = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };
  hideModal = () => {
    this.setState({
      modalOpen: false,
    });
  };
  confirmClick = () => {
    this.setState({
      modalOpen: false,
    });
    this.props.history.push({
      pathname: "/client-form-list",
    });
  };
  handleChange = (event) => {
    let fErrors = this.state.errors;
    const re = /[^a-zA-Z0-9\s_]/;
    //const re = /^[a-zA-Z0-9_]*$/;
    if (re.test(event.target.value)) {
      fErrors.clientInfoFormName = "Special characters not allowed.";
      this.setState({ errors: fErrors });
    } else {
      fErrors.clientInfoFormName = "";
      this.setState({
        clientInfoFormName: event.target.value,
        errors: fErrors,
      });
    }
  };
  
  handleFillNow = async () => {
    const requestData = {
      formName: this.state.clientInfoFormName,
      userId:"853e42b3-1402-4de4-b666-6eb9d7b89db4",
      userName:"Cu User LN Cu",
      userEmail:"tst.cu@yopmail.com",
      clientId:this.state.selectedCountry.id,
      clientName:this.state.selectedCountry.name,      
      office: this.state.selectedOffice.name,
      };
    this.setState({ isLoading: true });
    this.props.createCuracaoInternalCAFDetails(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        
        this.setState({ isLoading: false });
        this.props.ClientAcceptance.chapter1["dirty"] = false;
        let response = res.data.result;    
        console.log(response);   
          NotificationManager.success("Form is submitted successfully.");
          localStorage.setItem("formData", `{"id":"`+response.id+`","formName":"`+response.formName+`","userId":"`+response.userId+`","userName":"`+response.userName+`","userEmail":"`+response.userEmail+`","clientId":"`+response.clientId+`","clientName":"`+response.clientName+`","office":"Curacao","pascalCSVFileName":null,"isCAFOpen":true,"createdOn":"`+response.createdOn+`","lastModifiedOn":"`+response.lastModifiedOn+`","status":"InProgress"}`);
          window.setTimeout(() => {
             this.props.history.push({
              pathname: "/curacao/editClientForm",
              });
            }, 1000);
      }
    });
   
    // localStorage.setItem("CompanyName", this.state.selectedCountry.name);
  //  localStorage.setItem("Office", this.state.selectedOffice.name);
    // this.handleNewOfficeChange(this.state.selectedOffice.name);
    // const data = {
    //   id: this.state.id,
    //   name: this.state.clientInfoFormName,
    //   clientId: this.state.selectedCountry.id,
    //   clientName: this.state.selectedCountry.name,
    //   office: this.state.selectedOffice.name,
    //   status: "In Progress",
    //   lastActivityName: "generic-form",
    //   activeLinkLevel: 0,
    // };
    // let res = false;
    // res = await this.AddClientInformation(data);
    // if (res) {
    //   this.setState({ response: false });
    //   let path = manageLandingPage(this.state.selectedOffice.name);
    //   this.props.history.push({
    //     pathname: path,
    //   });
    // }
  };

  handleFillLater = () => {
    const requestData = {
      formName: this.state.clientInfoFormName,
      userId:"853e42b3-1402-4de4-b666-6eb9d7b89db4",
      userName:"Cu User LN Cu",
      userEmail:"tst.cu@yopmail.com",
      clientId:this.state.selectedCountry.id,
      clientName:this.state.selectedCountry.name,      
      office: this.state.selectedOffice.name,
      };
    this.setState({ isLoading: true });
    this.props.createCuracaoInternalCAFDetails(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.setState({ isLoading: false });
        this.props.ClientAcceptance.chapter1["dirty"] = false;
        let response = res.data.result;       
          NotificationManager.success("Form is submitted successfully.");
          window.setTimeout(() => {
             this.props.history.push({
                pathname: "/client-form-list",
              });
            }, 1000);
      }
    });
   
  };
  render() {
    const {
      clientList,
      clientId, clientName,
      errors,
      clientInfoFormName
    } = this.state;
    let clientArray = [];
    clientList.length > 0 &&
      clientList.forEach((client) => {
        if (client.status !== "PendingApproval") {
          let obj = {};
          obj["value"] = client.id;
          obj["label"] = client.name;
          clientArray.push(obj);

        }
      });
    let objClient = {};
    objClient["value"] = clientId;
    objClient["label"] = clientName;
    let selectedClient = objClient

    return (
      <div className="dashboard external-dashboard">
        <div className="generic-form border-bottom-0">
          <div className="eventHeader pb-3">
            <h2 className="m-0 p-0 pagetitle">Create Form</h2>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
          <Row className="pt-5 pb-4">
            <div className="create-form p-4">
              <Row>
                <Col lg="12" className="mb-2">
                  <label className="tooltipFullWidth">
                    Form Name *{" "}
                    <Tooltip text="Ideal way of writing form name ”Clientname_officename_formname"></Tooltip>
                  </label>

                  <input
                    type="text"
                    value={clientInfoFormName}
                    name="clientInfoFormName"
                    placeholder="Enter Form Name"
                    className="form-control"
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{errors.clientInfoFormName}</div>
                </Col>

                <Col lg="12" className="mb-2">
                  <label>Select Client *</label>
                  <Select
                    options={this.state.clientList}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={this.onchangeCountry}
                    value={this.state.selectedCountry}
                  />
                  <div className="errorMsg">{errors.country}</div>
                </Col>

                <Col lg="12" className="mb-2">
                  <label>Select Office *</label>
                  <Select
                    options={this.state.offices}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    onChange={this.onchangeOffice}
                    value={this.state.selectedOffice}
                  />
                  <div className="errorMsg">{errors.office}</div>
                </Col>

                <Col lg="12" className="mt-3">
                  <Button
                    className="action-btn big mr-4"
                    onClick={this.handleFillNow}
                  >
                    Start Filling Now
                  </Button>
                  <Button
                    className="action-btn greybtn big mr-4"
                    onClick={this.handleFillLater}
                  >
                    Fill Form Later
                  </Button>
                </Col>
              </Row>
            </div>
            {this.state.isLoading && <div className="loader"></div>}
          </Row>
          <Row className="justify-content-center pt-3">
            <Button className="action-btn link" onClick={this.backToList}>
              Back to List
            </Button>
          </Row>
          {this.state.modalOpen && (
            <Modal
              show={this.state.modalOpen}
              handleClose={(e) => this.hideModal(e)}
            >
              <h4 className="mb-4">
                Are you sure you wants to go back? <br /> Your data will not be
                saved
              </h4>
              <div className="form-group mb-0">
                <button
                  onClick={(e) => this.confirmClick(e)}
                  type="button"
                  className="action-btn noicon mr-2"
                >
                  Yes
                </button>
                <button
                  onClick={(e) => this.hideModal(e)}
                  type="button"
                  className="action-btn greybtn noicon"
                >
                  No
                </button>
              </div>
            </Modal>
          )}
        </div>
      </div>
        
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.user.data,
    chapter1: state.CuracaoInternalCAF.chapter1,
    chapter1Ext: state.CuracaoClientInformation.chapter1,
    ClientAcceptance: state.CuracaoInternalCAF,
    data: state,
    commonFields: state.commonFields,
    internalErrors: state.CuracaoInternalChapterErrors.formErrorschapter1,
    errors: state.CuracaoInternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientList: async (data) => dispatch(await getClientList(data)),
    getCuracaoInternalCAFDetails: async (data) =>
      dispatch(await getCuracaoInternalCAFDetails(data)),
    setCuracaoInternalCAFDetails: async (data) =>
      dispatch(await setCuracaoInternalCAFDetails(data)),
    createCuracaoInternalCAFDetails: async (data) =>
      dispatch(await createCuracaoInternalCAFDetails(data)),
    setCuracaoInternalChapterError: (data) =>
      dispatch(setCuracaoInternalChapterError(data)),
    setNewDataToReducer: (data) => dispatch(setNewDataToReducer(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddClientForm);
