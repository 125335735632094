import React from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";
import { riskList } from "../Constants";

class RiskList extends React.Component {
  render() {
    return (
      <>
        <h4 className="sub-chapter-title pt-5 mb-4">
          List of outstanding actions
        </h4>
        {riskList.map((risk, index) => {
          return (
            <>
              <div className="groupCompaniesBlock" key={index}>
                <h6 className="font-weight-bold internal_chapter_sub_title">
                  {risk.label}
                </h6>
                <Row className="mt-2">
                  <div className="col-md-12">
                    <TextBox
                      label="Description of actions which still need to be taken or fill in 'None'"
                      chapter={risk.chapter}
                      placeholder="None"
                      name={risk.name}
                      reducer={risk.reducer}
                      isMultipleLines={true}
                      disabled={true}
                      className={
                        this.props.PdfGeneration === "Yes"
                          ? "txtBox"
                          : "form-control"
                      }
                    />
                  </div>
                </Row>
              </div>
            </>
          );
        })}
      </>
    );
  }
}

export default RiskList;
