import {
  SET_ALL_CURACAO_ERRORS_TO_NULL,
  SET_CURACAO_ERROR_FOR_CHAPTER,
} from "../../constants/Curacao/CuracaoInternalChapterErrorConstants";

const initialState = {
  formErrorschapter1: { errors: {} },
  formErrorschapter2: { errors: {} },
  formErrorschapter3: { errors: {} },
  formErrorschapter4: { errors: {} },
  formErrorschapter5: { errors: {} },
  formErrorschapter6: { errors: {} },
  formErrorschapter8: { errors: {} },
  formErrorschapter9: { errors: {} },
  formErrorschapter10: { errors: {} },
  formErrorschapter11: { errors: {} },
  formErrorschapter12: { errors: {} },
};

const CuracaoInternalChapterErrorReducer = (
  state = initialState,
  action = {}
) => {
  switch (action.type) {
    case SET_CURACAO_ERROR_FOR_CHAPTER:
      const { errors, form } = action.data;
      if (
        state[form] &&
        state[form]["errors"] &&
        state[form]["errors"]["isSubmitted"]
      ) {
        errors.isSubmitted = true;
      }
      return {
        ...state,
        [form]: {
          ...state[form],
          errors: errors,
        },
      };

    case SET_ALL_CURACAO_ERRORS_TO_NULL:
      return {
        ...state,
        formErrorschapter1: initialState.formErrorschapter1,
        formErrorschapter2: initialState.formErrorschapter2,
        formErrorschapter3: initialState.formErrorschapter3,
        formErrorschapter4: initialState.formErrorschapter4,
        formErrorschapter5: initialState.formErrorschapter5,
        formErrorschapter6: initialState.formErrorschapter6,
        formErrorschapter8: initialState.formErrorschapter8,
        formErrorschapter9: initialState.formErrorschapter9,
        formErrorschapter10: initialState.formErrorschapter10,
        formErrorschapter11: initialState.formErrorschapter11,
        formErrorschapter12: initialState.formErrorschapter12,
      };
    default:
      return state;
  }
};

export default CuracaoInternalChapterErrorReducer;
