import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import TextBox from "../../../../elements/TextBox";
import {
  oneServicesLuxembourg,
  oneServicesMalta,
  recurrentServicesLuxembourg,
  recurrentServicesMalta,
} from "../../../external/OnBoarding/client-information/Chapter4/Constants";

const objBaseName = "servicesProvidedMal";
class ServicesProvidedMalta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oneServices:
        props.form === "Malta" ? oneServicesMalta : oneServicesLuxembourg,
      recurrentServices:
        props.form === "Malta"
          ? recurrentServicesMalta
          : recurrentServicesLuxembourg,
    };
  }

  render() {
    let oneServicesArray = this.props.oneServices;
    let recurrentServicesArray = this.props.recurrentServices;

    let existingOneServices = this.state.oneServices;
    let existingRecurrentServices = this.state.recurrentServices;

    if (oneServicesArray?.length > 0) {
      existingOneServices.forEach((item) => {
        oneServicesArray.forEach((service) => {
          if (item.label === service.service) {
            item.isChecked = true;
          }
        });
      });
    }
    if (recurrentServicesArray?.length > 0) {
      existingRecurrentServices.forEach((item) => {
        recurrentServicesArray.forEach((service) => {
          if (item.label === service.service) {
            item.isChecked = true;
          }
        });
      });
    }

    const {
      sttngCompCntrct,
      isOffRegstrd,
      propDirsManSigCmmnt,
      signatoryPowers,
      errors,
      isRequired,
    } = this.props;
    const errorMsg = JSON.parse(errors);

    return (
      <>
        <div className="html2pdf__page-break"></div>
        <h4 className="sub-chapter-title mb-4">Services to be Provided</h4>
        <Row>
          <div className="mb-3 col-lg-4">
            <p>Onetime Services</p>
          </div>
          <div className="mb-3 col-lg-8">
            <div className="multiSelection">
              <CheckBox
                label={`Setting up of company${
                  this.props.form === "Luxembourg" ? "/contract by UIM SA" : ""
                }`}
                name="sttngCompCntrct"
                id="sttngCompCntrct"
                options={["Yes", "No"]}
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={objBaseName}
                nestedObjBaseName="oneTimeServ"
                disabled={true}
              />
            </div>{" "}
            {this.props.form === "Malta" && sttngCompCntrct === "Yes" && (
              <div id="serviceNames">
                {existingOneServices.map((option, index) => {
                  return (
                    <div key={index}>
                      <div className="options">
                        <label
                          value={option.label}
                          className={
                            this.props.from !== "External" && "disabledCheckbox"
                          }
                        >
                          <input
                            type="checkbox"
                            name={option.label}
                            onChange={(e) =>
                              this.handleChange(option, "oneTimeServ")
                            }
                            checked={option.isChecked}
                            value={option.label}
                            disabled={this.props.from !== "External"}
                          />
                          <img alt="" />
                          <span>{option.label}</span>
                        </label>
                      </div>
                    </div>
                  );
                })}
                {errors &&
                  errors?.serviceNames &&
                  errors?.serviceNames?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors?.serviceNames}
                    </span>
                  )}
                {oneServicesArray?.filter((item) => item.service === "Others")
                  .length > 0 && (
                  <div className="mb-3 col-lg-12">
                    <TextBox
                      label="Provide name"
                      name="othrService"
                      id="othrService"
                      placeholder="Enter your text"
                      reducer={this.props.externalReducer}
                      chapter={this.props.chapter}
                      objBaseName={objBaseName}
                      nestedObjBaseName="oneTimeServ"
                      disabled={true}
                      className={
                        this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                      }

                    />
                    {errors &&
                      errors?.othrService &&
                      errors?.othrService?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors?.othrService}
                        </span>
                      )}
                  </div>
                )}
              </div>
            )}
            {this.props.form === "Luxembourg" && sttngCompCntrct === "Yes" && (
              <div id="serviceNames">
                {existingOneServices.map((option, index) => {
                  return (
                    <div key={index}>
                      <div className="options">
                        <label
                          value={option.label}
                          className={
                            this.props.from !== "External" && "disabledCheckbox"
                          }
                        >
                          <input
                            type="checkbox"
                            name={option.label}
                            onChange={(e) =>
                              this.handleChange(option, "oneTimeServ")
                            }
                            checked={option.isChecked}
                            value={option.label}
                            disabled={this.props.from !== "External"}
                          />
                          <img alt="" />
                          <span>{option.label}</span>
                        </label>
                      </div>
                    </div>
                  );
                })}
                {errors &&
                  errors?.serviceNames &&
                  errors?.serviceNames?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors?.serviceNames}
                    </span>
                  )}
                {oneServicesArray?.filter((item) => item.service === "Others")
                  .length > 0 && (
                  <div className="mb-3 col-lg-12">
                    <TextBox
                      label="Provide name"
                      name="othrService"
                      id="othrService"
                      placeholder="Enter your text"
                      reducer={this.props.externalReducer}
                      chapter={this.props.chapter}
                      objBaseName={objBaseName}
                      nestedObjBaseName="oneTimeServ"
                      disabled={true}
                      className={
                        this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                      }
                    />
                    {errors &&
                      errors?.othrService &&
                      errors?.othrService?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors?.othrService}
                        </span>
                      )}
                  </div>
                )}
              </div>
            )}
          </div>
          {sttngCompCntrct === "No" && (
            <div className="mb-3 col-lg-12">
              <TextBox
                label="By whom?"
                name="byWhom"
                id="byWhom"
                placeholder="Enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={objBaseName}
                nestedObjBaseName="oneTimeServ"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}

          <div className="mb-3 col-lg-12">
            <TextBox
              label="Please enter comment"
              name="oneTimeServCmmnt"
              id="oneTimeServCmmnt"
              placeholder="Enter your text"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={isRequired}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorMsg &&
              errorMsg?.oneTimeServCmmnt &&
              errorMsg?.oneTimeServCmmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.oneTimeServCmmnt}
                </span>
              )}
          </div>
        </Row>
        <Row>
          <div className="mb-3 col-lg-4">
            <p>Recurrent Services</p>
          </div>
          <div className="mb-3 col-lg-8">
            <div className="multiSelection">
              <CheckBox
                label={`${
                  this.props.form === "Malta"
                    ? "Is the office registered?"
                    : "Domiciliation"
                }`}
                name="isOffRegstrd"
                id="isOffRegstrd"
                options={["Yes", "No"]}
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={objBaseName}
                nestedObjBaseName="recurrntSrvcs"
                disabled={true}
              />
            </div>
            {this.props.form === "Malta" && isOffRegstrd === "Yes" && (
              <>
                {existingRecurrentServices.map((option, index) => {
                  return (
                    <div key={index}>
                      <div className="options">
                        <label
                          value={option.label}
                          className={
                            this.props.from !== "External" && "disabledCheckbox"
                          }
                        >
                          <input
                            type="checkbox"
                            name={option.label}
                            onChange={(e) =>
                              this.handleChange(option, "recurrntSrvcs")
                            }
                            checked={option.isChecked}
                            value={option.label}
                            disabled={this.props.from !== "External"}
                          />
                          <img alt="" />
                          <span>{option.label}</span>
                        </label>
                      </div>
                    </div>
                  );
                })}
                {errors &&
                  errors?.serviceNames2 &&
                  errors?.serviceNames2?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors?.serviceNames2}
                    </span>
                  )}
                {recurrentServicesArray?.filter((item) => {
                    return item.service === "Others";
                }).length > 0 && (
                  <div className="mb-3 col-lg-12">
                    <TextBox
                      label="Provide name"
                      name="othrService"
                      id="othrService"
                      placeholder="Enter your text"
                      reducer={this.props.externalReducer}
                      chapter={this.props.chapter}
                      objBaseName={objBaseName}
                      disabled={true}
                      nestedObjBaseName="recurrntSrvcs"
                      className={
                        this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                      }
                    />
                    {errors &&
                      errors?.othrService2 &&
                      errors?.othrService2?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors?.othrService2}
                        </span>
                      )}
                  </div>
                )}
              </>
            )}
            {this.props.form === "Luxembourg" && isOffRegstrd === "Yes" && (
              <div id="serviceNames2">
                {existingRecurrentServices.map((option, index) => {
                  return (
                    <div key={index}>
                      <div className="options">
                        <label
                          value={option.label}
                          className={
                            this.props.from !== "External" && "disabledCheckbox"
                          }
                        >
                          <input
                            type="checkbox"
                            name={option.label}
                            onChange={(e) =>
                              this.handleChange(option, "recurrntSrvcs")
                            }
                            checked={option.isChecked}
                            value={option.label}
                            disabled={this.props.from !== "External"}
                          />
                          <img alt="" />
                          <span>{option.label}</span>
                        </label>
                      </div>
                    </div>
                  );
                })}
                {errors &&
                  errors?.serviceNames2 &&
                  errors?.serviceNames2?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors?.serviceNames2}
                    </span>
                  )}
                {recurrentServicesArray?.filter(
                  (item) => item.service === "Others"
                ).length > 0 && (
                  <div className="mb-3 col-lg-12">
                    <TextBox
                      label="Provide name"
                      name="othrService"
                      id="othrService2"
                      placeholder="Enter your text"
                      reducer={this.props.externalReducer}
                      chapter={this.props.chapter}
                      objBaseName={objBaseName}
                      nestedObjBaseName="recurrntSrvcs"
                      disabled={true}
                      className={
                        this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                      }
                    />
                    {errors &&
                      errors?.othrService2 &&
                      errors?.othrService2?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors?.othrService2}
                        </span>
                      )}
                  </div>
                )}
              </div>
            )}
          </div>
          {isOffRegstrd === "No" && (
            <div className="mb-3 col-lg-12">
              <TextBox
                label="Provide complete address where the company is registered?"
                name="comAddComReg"
                id="comAddComReg"
                placeholder="Enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={objBaseName}
                nestedObjBaseName="recurrntSrvcs"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}

          <div className="mb-3 col-lg-12">
            <TextBox
              label="Please enter comment"
              name="reccrntServCmmnt"
              id="reccrntServCmmnt"
              placeholder="Enter your text"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={isRequired}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorMsg &&
              errorMsg?.reccrntServCmmnt &&
              errorMsg?.reccrntServCmmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.reccrntServCmmnt}
                </span>
              )}
          </div>
        </Row>
        <div className="html2pdf__page-break"></div>
        <b>Proposed Directors / Managers and legal & judicial representation</b>
        <Row>
          <div className="mb-0 col-lg-8">
            <div className="multiSelection">
              <CheckBox
                name="propDirsManSigCmmnt"
                id="propDirsManSigCmmnt"
                options={["Internal Being", "External Being"]}
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                isRequired={isRequired}
              />
              {errorMsg &&
                errorMsg?.propDirsManSigCmmnt &&
                errorMsg?.propDirsManSigCmmnt?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorMsg?.propDirsManSigCmmnt}
                  </span>
                )}
            </div>
          </div>
          {propDirsManSigCmmnt === "External Being" && (
            <>
              <div className="mb-3 col-lg-8" style={{marginTop: '-15px'}}>
                <CheckBox
                  name="relationshipUBO"
                  id="relationshipUBO"
                  options={["Relationship with UBO"]}
                  reducer={this.props.reducer}
                  chapter={this.props.chapter}
                  isRequired={isRequired}
                />
                {errorMsg &&
                  errorMsg?.relationshipUBO &&
                  errorMsg?.relationshipUBO?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorMsg?.relationshipUBO}
                    </span>
                  )}
              </div>

              <div className="mb-3 col-lg-8">
                <TextBox
                  label="Add information"
                  placeholder="Enter your text"
                  name="propDirsMangInfo"
                  id="propDirsMangInfo"
                  reducer={this.props.reducer}
                  isRequired={isRequired}
                  chapter={this.props.chapter}
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
                {errorMsg &&
                  errorMsg?.propDirsMangInfo &&
                  errorMsg?.propDirsMangInfo?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorMsg?.propDirsMangInfo}
                    </span>
                  )}
              </div>
            </>
          )}
        </Row>
        <b>Signatory Powers</b>
        <Row>
          <div className="mb-3 col-lg-8">
            <div className="multiSelection">
              <CheckBox
                name="signatoryPowers"
                id="signatoryPowers"
                options={
                  this.props.form === "Malta"
                    ? [
                        "Only UIM Malta directors / managers",
                        "Joint Signatures",
                        "Independent external director's",
                      ]
                    : [
                        "Only UIM S.A. directors / managers",
                        "A+B",
                        "Independent external director's",
                      ]
                }
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                isRequired={isRequired}
              />
              {errorMsg &&
                errorMsg?.signatoryPowers &&
                errorMsg?.signatoryPowers?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorMsg?.signatoryPowers}
                  </span>
                )}
            </div>
          </div>
          {signatoryPowers === "A+B" && (
            <>
              <div className="mb-3 col-lg-8">
                <TextBox
                  label="Add information"
                  placeholder="Enter your text"
                  name="signPowInfo"
                  id="signPowInfo"
                  reducer={this.props.reducer}
                  chapter={this.props.chapter}
                  isRequired={isRequired}
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
                {errorMsg &&
                  errorMsg?.signPowInfo &&
                  errorMsg?.signPowInfo?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorMsg?.signPowInfo}
                    </span>
                  )}
              </div>
            </>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { externalReducer, reducer, chapter } = ownProps;

  return {
    sttngCompCntrct:
      state[externalReducer][chapter]["servicesProvidedMal"]["oneTimeServ"][
        "sttngCompCntrct"
      ],
    isOffRegstrd:
      state[externalReducer][chapter]["servicesProvidedMal"]["recurrntSrvcs"][
        "isOffRegstrd"
      ],
    propDirsManSigCmmnt: state[reducer][chapter]["propDirsManSigCmmnt"],
    signatoryPowers: state[reducer][chapter]["signatoryPowers"],
    oneServices:
      state[externalReducer][chapter]["servicesProvidedMal"]["oneTimeServ"][
        "serviceNames"
      ],
    recurrentServices:
      state[externalReducer][chapter]["servicesProvidedMal"]["recurrntSrvcs"][
        "serviceNames"
      ],
  };
};
export default connect(mapStateToProps, null)(ServicesProvidedMalta);
