import React from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";
import CheckBox from "../../../../elements/CheckBox";
import {
  setLuxembourgInternalCAFDetails,
  getLuxembourgInternalCAFDetails,
} from "../../../../../redux/actions/Luxembourg/LuxembourgInternalCAFActions";
import "../../Onboarding/internalCAF.scss";
import { connect } from "react-redux";
import NotificationManager from "react-notifications/lib/NotificationManager";
import AutoSaving from "../AutoSaving";
import getSequentialFields from "../getSequentialFields";
import { setLuxembourgInternalChapterError } from "../../../../../redux/actions/Luxembourg/LuxembourgInternalChapterErrors";
import { chapValidationErrorLuxembourg } from "../Constants";

const chapter = "chapter9";
const reducer = "LuxembourgInternalCAF";

let timer;

class Chapter9 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formErrorsChapter9: {},
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: "all",
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.getLuxembourgInternalCAFDetails(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          Object.keys(this.props.internalErrors.errors).length === 0 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationErrorLuxembourg.forEach(async (element) => {
            let response =
              res?.data?.result?.cafInternalValidations[element.chapter];

            const newResponse = await Promise.resolve(
              getSequentialFields(response, element.chapter, false)
            );
            this.props.setLuxembourgInternalChapterError({
              form: `formErrors${element.chapter}`,
              errors: newResponse?.errorChapter,
            });
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;

    this.props
      .setLuxembourgInternalCAFDetails(requestData)
      .then(async (res) => {
        if (res.data.portalErrorCode === 200) {
          this.props.ClientAcceptance.chapter9["dirty"] = false;
          let response = res.data.result;

          const newResponse = await Promise.resolve(
            getSequentialFields(response, chapter, true)
          );
          this.props.setLuxembourgInternalChapterError({
            errors: newResponse?.errorChapter,
            form: "formErrorschapter9",
          });
          if (!res.data.result.isValid) {
            NotificationManager.info(
              "All fields are mandatory to fill in",
              null,
              4000
            );
            timer = setTimeout(() => {
              return this.props.history.push({
                pathname: "/luxembourg/miscellaneous",
              });
            }, 4000);
          } else {
            NotificationManager.success("Form is submitted successfully.");

            return this.props.history.push({
              pathname: "/luxembourg/miscellaneous",
            });
          }
        }
      });
  };

  render() {
    const { ClientAcceptance, internalErrors } = this.props;
    const formErrorsChapter9 = JSON.stringify(internalErrors.errors);
    const errorsMsg = JSON.parse(formErrorsChapter9);

    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="cafHeader">
            <h2 className="m-0">Client Acceptance Form</h2>
          </div>
          <div className="internal_caf generic-form">
            <div className="html2pdf__page-break"></div>
            <h3 className="chapterttl">
              Correspondence – Correspondence with regard to compliance
            </h3>
            <Row>
              <div className="col-lg-5">
                <CheckBox
                  label="Is all relevant correspondence added to the client file with respect to the client acceptance?"
                  options={["Yes", "No"]}
                  name="isRelevantCorrespondence"
                  id="overallRiskRating"
                  chapter={chapter}
                  reducer={reducer}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.overallRiskRating?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.overallRiskRating}
                    </span>
                  )}
              </div>
              {ClientAcceptance.chapter9.isRelevantCorrespondence === "Yes" && (
                <div className="col-lg-7">
                  <TextBox
                    //label="Findings Pascal"
                    label="Please provide a description of actions, substantiated with documents, which were taken with regard to complementing the investigation in the context of the client acceptance."
                    placeholder="Enter your text"
                    name="findingWorldCheck"
                    id="findingWorldCheck"
                    chapter={chapter}
                    reducer={reducer}
                    isRequired={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtBox"
                        : "form-control"
                    }
                  />
                  {errorsMsg &&
                    errorsMsg?.isSubmitted === true &&
                    errorsMsg?.findingWorldCheck?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.findingWorldCheck}
                      </span>
                    )}
                </div>
              )}
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ClientAcceptance: state.LuxembourgInternalCAF,
    data: state,
    internalErrors: state.LuxembourgInternalChapterErrors.formErrorschapter9,
    errors: state.LuxembourgInternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLuxembourgInternalCAFDetails: async (data) =>
      dispatch(await getLuxembourgInternalCAFDetails(data)),
    setLuxembourgInternalCAFDetails: async (data) =>
      dispatch(await setLuxembourgInternalCAFDetails(data)),
    setLuxembourgInternalChapterError: (data) =>
      dispatch(setLuxembourgInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter9);
