import { CustActivityRisks } from "./Chapter1";
export const Chapter6 = {
  isDomiciliationSvc: null,
  isManagementSvc: null,
  isAdministrationSvc: null,
  isUIMPrvdOthSvc: null,
  explanationOthSvc: null,
  isLeaseAgmntOfcSpace: null,
  isDomicilliationAgmnt: null,
  isDeapForm: null,
  isAdministrationAgmnt: null,
  isPrinciplePartyAgmnt: null,
  isManagementAgmnt: null,
  isOtherAgmnt: null,
  reportWorldCheckExtDir: [],
  findingsWorldCheck: null,
  isRisk: null,
  numCustActivityRisks: 1,
  custActivityRisks: [CustActivityRisks],
  custActvtyRskRtng: null,
  descActnNeedsTkn: null,
  isUnacceptableRisksIdentified: null,
  unacceptableRisksReason: null,
  dirty: false,
};

