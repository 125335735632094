import React from "react";
import { Row } from "react-bootstrap";
import CheckBox from "../../../../elements/CheckBox";
import TextBox from "../../../../elements/TextBox";
import {
  setUKInternalCAFDetails,
  getUKInternalCAFDetails,
} from "../../../../../redux/actions/UK/UKInternalCAFActions";
import MediaCoverage from "../../Onboarding/Chapter1/MediaCoverage";
import "../../Onboarding/internalCAF.scss";
import AutoSaving from "../AutoSaving";
import { connect } from "react-redux";
import OCExists from "../../Onboarding/Chapter4/OCExists";
import Directors from "../../Onboarding/Chapter4/Directors";
import ObjectCompany from "../../Onboarding/Chapter4/ObjectCompany";
import Rationale from "../../../external/OnBoarding/client-information/Chapter4/Rationale";
import DownloadFile from "../../../../elements/DownloadFile";
import DOC from "../../Onboarding/Chapter4/DOC";
import SourceOfFundsOC from "../../Onboarding/Chapter4/SourceOfFundsOC";
import BankAccntDetails from "../../Onboarding/Chapter4/BankAccntDetails";
import RightsNdObligations from "../../Onboarding/Chapter4/RightsNdObligations";
import NotificationManager from "react-notifications/lib/NotificationManager";
import BusinessIntro from "./BusinessIntro";
import EntityTrustDetails from "./EntityTrustDetails";
import ServiceProvided from "./ServiceProvided";
import { setUKInternalChapterError } from "../../../../../redux/actions/UK/UKInternalChapterErrorActions";
import getSequentialFields from "../getSequentialFields";
import { chapValidationErrorUK } from "../constants";

const chapter = "chapter4";
const reducer = "UKInternalCAF";
const externalReducer = "UKClientInformation";
let timer;

class Chapter4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idLoading: false,
      formErrorsChapter4: {},
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: "all",
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.getUKInternalCAFDetails(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          Object.keys(this.props.internalErrors.errors).length === 0 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationErrorUK.forEach(async (element) => {
            const response =
              res.data.result.cafInternalValidations[element.chapter];

            const newResponse = await Promise.resolve(
              getSequentialFields(response, element.chapter, false)
            );
            this.props.setUKInternalChapterError({
              form: `formErrors${element.chapter}`,
              errors: newResponse?.errorChapter,
            });
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;

    this.props.setUKInternalCAFDetails(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.props.ClientAcceptance.chapter4["dirty"] = false;
        let response = res.data.result;

        const newResponse = await Promise.resolve(
          getSequentialFields(response, chapter, true)
        );
        this.props.setUKInternalChapterError({
          errors: newResponse?.errorChapter,
          form: "formErrorschapter4",
        });
        if (!res.data.result.isValid) {
          NotificationManager.info(
            "All fields are mandatory to fill in",
            null,
            4000
          );
          timer = setTimeout(() => {
            return this.props.history.push({
              pathname: "/united-kingdom/country-sanction-risks",
            });
          }, 4000);
        } else {
          NotificationManager.success("Form is submitted successfully.");

          return this.props.history.push({
            pathname: "/united-kingdom/country-sanction-risks",
          });
        }
      }
    });
  };

  render() {
    const { taxAdvisorFinds, objCompIncrdIntgtyRsk, internalErrors } =
      this.props;
    const formErrorsChapter4 = JSON.stringify(internalErrors.errors);
    const errorsMsg = JSON.parse(formErrorsChapter4);
    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="cafHeader">
            <h2 className="m-0">Client Acceptance Form </h2>
          </div>
          <div className="internal_caf generic-form">
            <div className="html2pdf__page-break"></div>
            <h3 className="chapterttl">
              Client Investigation - Object Company
            </h3>
            <OCExists
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              isRequired={false}
            />
            <Directors
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              isRequired={false}
              chapter={chapter}
              form="UK"
            />
            <ObjectCompany
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              isRequired={false}
              chapter={chapter}
              form="UK"
              PdfGeneration={this.props.PdfGeneration}
            />
            <Rationale
              reducer={externalReducer}
              chapter={chapter}
              from="internal"
              errorMsg={formErrorsChapter4}
            />
            <div className="mb-2 col-lg-12 pl-0">
              <TextBox
                label="Please add comment"
                placeholder="Enter your text"
                name="rationaleStrctrngForNLCmmnt"
                id="rationaleStrctrngForNLCmmnt"
                reducer={reducer}
                chapter={chapter}
                validationType="Text"
                isRequired={false}
              />
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.rationaleStrctrngForNLCmmnt &&
                errorsMsg?.rationaleStrctrngForNLCmmnt?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg.rationaleStrctrngForNLCmmnt}
                  </span>
                )}
            </div>
            <div className="html2pdf__page-break"></div>
            <Row className="pt-3 pb-2">
              <div className="mb-3 col-lg-4">
                <DownloadFile
                  label="Include the findings of the independent tax advisor."
                  value={taxAdvisorFinds}
                />
              </div>
              <div className="mb-3 col-lg-4">
                <CheckBox
                  label="Is approved?"
                  options={["Yes", "No"]}
                  name="findingsIndpTaxAdvisorApprvd"
                  id="findingsIndpTaxAdvisorApprvd"
                  chapter={chapter}
                  reducer={reducer}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.findingsIndpTaxAdvisorApprvd &&
                  errorsMsg?.findingsIndpTaxAdvisorApprvd?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.findingsIndpTaxAdvisorApprvd}
                    </span>
                  )}
              </div>
            </Row>
            <div className="html2pdf__page-break"></div>
            <DOC
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              isRequired={true}
              chapter={chapter}
              form="UK"
              PdfGeneration={this.props.PdfGeneration}
            />
            <BusinessIntro
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              objBaseName="businessIntroduction"
              PdfGeneration={this.props.PdfGeneration}
            />
            {/* <EntityTrustDetails
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              errors={formErrorsChapter4}
              objBaseName="entityTrustDetails"
              PdfGeneration={this.props.PdfGeneration}
            /> */}
            <ServiceProvided
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              objBaseName="servicesProvided"
              PdfGeneration={this.props.PdfGeneration}
            />
            <div className="html2pdf__page-break"></div>
              <SourceOfFundsOC
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              isRequired={true}
              chapter={chapter}
              form="UK"
              PdfGeneration={this.props.PdfGeneration}
            />
            <div className="html2pdf__page-break"></div>
            <RightsNdObligations
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              isRequired={true}
              chapter={chapter}
              PdfGeneration={this.props.PdfGeneration}
            />
            <BankAccntDetails
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              isRequired={true}
              chapter={chapter}
              form="UK"
              PdfGeneration={this.props.PdfGeneration}
            />
            <div className="html2pdf__page-break"></div>
            <MediaCoverage
              chapter={chapter}
              reducer={reducer}
              errors={formErrorsChapter4}
              isRequired={true}
              form="UK"
              PdfGeneration={this.props.PdfGeneration}
            />
            <div className="html2pdf__page-break"></div>
            <Row className="mb-3">
              <div className="col-lg-12">
                If there are integrity risks about the purpose, nature and
                reason for structuring via{" "}
                {JSON.parse(localStorage.getItem("formData")).office} of the
                object company (e.g. Aggressive tax planning, BEPS) and explain
                if there are integrity risks with respect to the transaction
                profile and the rights and obligations of the object company.
              </div>
            </Row>
            <Row className="mb-3">
              <div className="col-lg-12">
                <CheckBox
                  label="In addition, report whether the OC/Transaction Profile criteria result in a potentially increased integrity risk."
                  options={["Yes", "No"]}
                  name="objCompIncrdIntgtyRsk"
                  id="objCompIncrdIntgtyRsk"
                  chapter={chapter}
                  isRequired={true}
                  reducer={reducer}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.objCompIncrdIntgtyRsk?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.objCompIncrdIntgtyRsk}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="mb-3">
              {objCompIncrdIntgtyRsk === "Yes" && (
                <div className="col-lg-12">
                  <TextBox
                    label="Please describe"
                    placeholder="Enter your text"
                    name="objCompIncrdIntgtyRskText"
                    id="objCompIncrdIntgtyRskText"
                    chapter={chapter}
                    reducer={reducer}
                    isMultipleLines={true}
                    isRequired={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtArea"
                        : "form-control"
                    }
                  />
                  {errorsMsg &&
                    errorsMsg?.isSubmitted === true &&
                    errorsMsg?.objCompIncrdIntgtyRskText?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.objCompIncrdIntgtyRskText}
                      </span>
                    )}
                </div>
              )}
              {objCompIncrdIntgtyRsk === "No" && (
                <div className="col-md-12">
                  <b>
                    With respect to the OC/Transaction profile information, UIM
                    sees no reason to discontinue the set-up of a business
                    relationship.
                  </b>
                </div>
              )}
            </Row>{" "}
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ClientAcceptance: state.UKInternalCAF,
    taxAdvisorFinds: state.UKClientInformation.chapter4["taxAdvisorFinds"],
    objCompIncrdIntgtyRsk:
      state.UKInternalCAF.chapter4["objCompIncrdIntgtyRsk"],

    data: state,
    internalErrors: state.UKInternalChapterError.formErrorschapter4,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUKInternalCAFDetails: async (data) =>
      dispatch(await getUKInternalCAFDetails(data)),
    setUKInternalCAFDetails: async (data) =>
      dispatch(await setUKInternalCAFDetails(data)),
    setUKInternalChapterError: (data) =>
      dispatch(setUKInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter4);
