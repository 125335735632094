import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getUserList,
  bulkDeleteUser,
  deleteUser,
  getUserOffice,
} from "../../../redux/actions/userActions";
import { NotificationManager } from "react-notifications";
import Modal from "../shared/Modal";
import jwt_decode from "jwt-decode";
import orderBy from "lodash/orderBy";
const customSort = (rows, field, direction) => {
  const handleField = (row) => {
    if (row[field]) {
      return row[field].toLowerCase();
    }
    return row[field];
  };
  return orderBy(rows, handleField, direction);
};
class userlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      graphData: null,
      isLoading: false,
      selectedRows: [],
      searchInput: "",
      data: [],
      singleDelete: false,
      multipleDelete: false,
      deleteRecord: {},
      deleteRecordsArray: [],
      toggleCleared: false,
      columns: [
        {
          name: "First Name",
          selector: "firstName",
          sortable: true,
        },
        {
          name: "Last Name",
          selector: "lastName",
          sortable: true,
        },
        {
          name: "User Role",
          selector: "role",
          sortable: true,
        },
        {
          name: "Email ID",
          selector: "email",
          sortable: true,
        },
        {
          name: "Mobile Number",
          selector: "phoneNo",
          sortable: true,
        },
        {
          name: "",
          selector: (row) => (
            <>
              {row.status === "PendingApproval" ? (
                <div className="Approval-pending">Approval Pending</div>
              ) : (
                <>
                  <div className="Edit-Btn">
                    <Link
                      className="action-btn edit"
                      to={{
                        pathname: `/editUser/${row.id}`,
                      }}
                    >
                      Edit
                    </Link>
                  </div>
                </>
              )}
            </>
          ),
          width: "200px",
        },
      ],
      originalData: [],
      tokenData: null,
    };
  }

  componentDidMount = () => {
    let token = JSON.parse(localStorage.getItem("authToken"));
    if (token) {
      var decoded = jwt_decode(token);
      var data = {
        name: decoded.name,
      };
      this.setState({ isLoading: true, tokenData: data });

      this.props.getUserList(data).then((res) => {
        this.setState({ isLoading: false });
        if (res.data.portalErrorCode === 200) {
          this.setState({
            originalData: res.data.result,
            data: res.data.result,
          });
        }
      });
    }
  };

  handleRowSelection = (selectedRows) => {
    this.setState({ selectedRows: selectedRows });
  };

  deleteRecords = (rows) => {
    let array = [];
    rows.selectedRows.length > 0 &&
      rows.selectedRows.map((row) => {
        let obj = {};
        obj["id"] = row.id;
        array.push(obj);
      });
    this.setState({ isLoading: true });
    this.props
      .bulkDeleteUser(array)
      .then((res) => {
        this.setState({ isLoading: false });
        let successfulCount = 0,
          failedCount = 0;
        if (res.data?.length > 0) {
          res.data.map((record) => {
            if (record.portalErrorCode === 200) {
              successfulCount++;
            } else {
              failedCount++;
            }
          });
          successfulCount > 0 &&
            NotificationManager.success(
              `${successfulCount} ${
                successfulCount === 1 ? `record ` : `records`
              } ${`deleted successfully`}`
            );
          failedCount > 0 &&
            NotificationManager.error(
              `Error while deleting ${failedCount} ${
                failedCount === 1 ? `record.` : `records.`
              }`
            );
          this.setState({
            selectedRows: [],
            toggleCleared: !this.state.toggleCleared,
          });
          this.props.getUserList(this.state.tokenData).then((res) => {
            this.setState({ isLoading: false });
            if (res.data.portalErrorCode === 200) {
              this.setState({
                originalData: res.data.result,
                data: res.data.result,
              });
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleDelete = (row) => {
    let data = {
      id: row.id,
    };
    this.setState({ isLoading: true });
    this.props
      .deleteUser(data)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res?.data?.portalErrorCode === 200) {
          NotificationManager.success("User deleted successfully");
          this.props.getUserList(this.state.tokenData).then((res) => {
            this.setState({ isLoading: false });
            if (res?.data?.portalErrorCode === 200) {
              this.setState({
                originalData: res.data?.result,
                data: res.data?.result,
                selectedRows: [],
                toggleCleared: !this.state.toggleCleared,
              });
            }
          });
        } else {
          NotificationManager.error("Error while deleting record.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData = this.state.originalData.filter((value) => {
      return (
        value.firstName?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.lastName?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.email?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.phoneNo
          ?.toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      );
    });
    this.setState({ data: filteredData });
  };

  handleSearch = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  modalOpen = (row, choice) => {
    if (choice === "Single") {
      this.setState({ modal: true, singleDelete: true, deleteRecord: row });
    } else if (choice === "Multiple") {
      this.setState({
        modal: true,
        multipleDelete: true,
        deleteRecordsArray: row,
      });
    }
  };

  modalClose = () => {
    this.setState({
      modal: false,
    });
  };

  handleYes = () => {
    this.deleteRecords(this.state.selectedRows);
    this.modalClose();
  };
  handleNo = () => {
    this.modalClose();
  };

  render() {
    const { columns, data, selectedRows, searchInput, toggleCleared } =
      this.state;
    const conditionalRowStyles = [
      {
        when: (row) => row.status === "PendingApproval",
        classNames: ["disabled"],
        style: {
          backgroundColor: "#f5f6f7 !important",
          cursor: "not-allowed",
          pointerEvents: "none",
        },
      },
    ];
    const rowDisabledCriteria = (row) => row.status === "PendingApproval";
    return (
      <div className="dashboard">
        <div className="userlist">
          <div className="eventHeader pt-3 pb-3">
            <Container fluid>
              <Row>
                <Col sm={6} className="text-left d-flex">
                  <h2 className="mb-0  mt-2 pagetitle">User Maintenance</h2>
                </Col>
                <Col sm={6} className="text-right add-button">
                  <Link to="/adduser" className="action-btn big">
                    Add User
                  </Link>
                </Col>
              </Row>
            </Container>
          </div>

          <Container fluid>
            <Row className="pt-3 pb-2">
              <Col lg={6} className="pb-2">
                {selectedRows?.selectedRows?.length > 0 && (
                  <button
                    className="action-btn delete"
                    onClick={() => {
                      let selectedlength =
                        selectedRows?.selectedRows.length === 1
                          ? "Single"
                          : "Multiple";
                      this.modalOpen(
                        selectedRows?.selectedRows,
                        selectedlength
                      );
                    }}
                  >
                    Delete
                  </button>
                )}
              </Col>
              <Col lg={6} className="position-relative">
                <div className="datatableSearch">
                  <input
                    type="text"
                    name="searchInput"
                    value={searchInput || ""}
                    onChange={this.handleSearch}
                    placeholder="Search"
                  />
                  <i className="fa fa-search search-bar-icon"></i>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <DataTable
                  className="datatable"
                  columns={columns}
                  data={data}
                  onSelectedRowsChange={this.handleRowSelection}
                  sortFunction={customSort}
                  selectableRows
                  pagination
                  conditionalRowStyles={conditionalRowStyles}
                  clearSelectedRows={toggleCleared}
                  selectableRowDisabled={rowDisabledCriteria}
                />
              </Col>
            </Row>
          </Container>
          <Modal
            show={this.state.modal}
            handleClose={(e) => this.modalClose(e)}
          >
            <h4 className="mb-4">Are you sure you want to delete?</h4>

            <div className="form-group mb-0">
              <button
                onClick={(e) => this.handleYes(e)}
                type="button"
                className="action-btn approve noicon mr-2"
              >
                Yes
              </button>
              <button
                onClick={(e) => this.handleNo(e)}
                type="button"
                className="action-btn reject noicon"
              >
                No
              </button>
            </div>
          </Modal>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserList: async (data) => dispatch(await getUserList(data)),
    bulkDeleteUser: async (data) => dispatch(await bulkDeleteUser(data)),
    deleteUser: async (data) => dispatch(await deleteUser(data)),
    getUserOffice: async () => dispatch(await getUserOffice()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(userlist);
