import React, { Component } from "react";
import { setDataToReducer } from "../../redux/actions/ClientInformationAction";
import { connect } from "react-redux";
import Select from "react-select";
import { getReducer } from "./getReducer";
import Tooltip from "./Tooltip";
class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e) => {
    this.props.setDataToReducer({
      type: getReducer(this.props.reducer),
      chapter: this.props.chapter,
      index: this.props.index,
      name: this.props.name,
      value: e.label,
      baseName: this.props.baseName,
      objBaseName: this.props.objBaseName,
      indexObjBaseName:this.props.indexObjBaseName,
      nestedObjBaseName: this.props.nestedObjBaseName,
    });
  };
  render() {
    const { label, name, options, id, isRequired,info } = this.props;
    const selectedvalue = {
      label: this.props.value,
    };
    return (
      <>
        <label>
          {label}
          {isRequired && <span style={{ color: "red" }}>*</span>}
          {info && <Tooltip text={info}></Tooltip>}
        </label>
        {!selectedvalue.label ? (
          <>
            {this.props.PdfGeneration === "Yes" ? (
              <input
                type="text"
                name={name}
                className="form-control"
                value={selectedvalue.label}
                autoComplete="off"
                id={id}
                disabled={true}
              ></input>
            ) : (
              <Select
                options={options}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.label}
                onChange={this.handleChange}
                id={id}
                value={selectedvalue.label}
                isDisabled={this.props.disabled}
                placeholder={!selectedvalue.label && <div>select...</div>}
              />
            )}
          </>
        ) : (
          <>
            {this.props.PdfGeneration === "Yes" ? (
              <input
                type="text"
                name={name}
                className="form-control"
                value={selectedvalue.label}
                autoComplete="off"
                id={id}
                disabled={true}
              ></input>
            ) : (
              <Select
                options={options}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.label}
                onChange={this.handleChange}
                isDisabled={this.props.disabled}
                value={selectedvalue}
                id={id}
              />
            )}
          </>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    name,
    chapter,
    baseName,
    index,
    objBaseName,
    reducer,
    nestedObjBaseName,
    isCommon,
    noChapter,
    indexObjBaseName
  } = ownProps;

  if (isCommon) {
    if (!objBaseName && !baseName) {
      return {
        value: state[reducer][name],
      };
    } else if (objBaseName && nestedObjBaseName) {
      return {
        value: state[reducer][objBaseName][nestedObjBaseName][name],
      };
    } else if (objBaseName && !baseName) {
      return {
        value: state[reducer][objBaseName][name],
      };
    } else if (objBaseName && baseName) {
      return {
        value: state[reducer][objBaseName][baseName][index][name],
      };
    }
  } else if (noChapter) {
    return {
      value: state[reducer][name],
    };
  } else if (!baseName && !objBaseName) {
    return {
      value: state[reducer][chapter][name],
    };
  } else if (objBaseName && nestedObjBaseName) {
    return {
      value: state[reducer][chapter][objBaseName][nestedObjBaseName][name],
    };
  } else if (objBaseName && !baseName) {
    return {
      value: state[reducer][chapter][objBaseName][name],
    };
  } 
  else if(objBaseName && baseName && indexObjBaseName) {
    try {

      return {
        value: state[reducer][chapter][objBaseName][indexObjBaseName][baseName][index][name],
      };
    
    } catch (err) {
    
      // error handling
    
    }
   
  }
  else if (objBaseName && baseName) {
    return {
      value: state[reducer][chapter][objBaseName][baseName][index][name],
    };
  } else {
    return {
      value: state[reducer][chapter][baseName][index][name],
    };
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(DropDown);
