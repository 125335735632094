export const getReducer = (reducer) => {
  switch (reducer) {
    case "genericInformation":
      return "UPDATE_GENERIC_DETAILS";
    case "clientInformation":
      return "UPDATE_CLIENTINFO_DATA";
    case "UBODeclarationUIM":
      return "UPDATE_UIM_UBO_DATA";
    case "AnnexUBOInformation":
      return "UPDATE_ANNEX_UBO_DATA";
    case "commonFields":
      return "UPDATE_COMMON_FIELDS";
    case "ClientAcceptance":
      return "UPDATE_CLIENT_ACCEPTANCE_DETAILS";
    case "UKClientInformation":
      return "UPDATE_UK_CLIENTINFO_DATA";
    case "UKInternalCAF":
      return "UPDATE_UK_INTERNAL_CAF_DATA";
    case "CuracaoClientInformation":
      return "UPDATE_CURACAO_CLIENTINFO_DATA";
    case "CuracaoInternalCAF":
      return "UPDATE_CURACAO_INTERNAL_CAF_DATA";
    case "MaltaClientInformation":
      return "UPDATE_MALTA_CLIENTINFO_DATA";
    case "MaltaInternalCAF":
      return "UPDATE_MALTA_INTERNAL_CAF_DATA";
    case "LuxembourgClientInformation":
      return "UPDATE_LUXEMBOURG_CLIENTINFO_DATA";
    case "LuxembourgInternalCAF":
      return "UPDATE_LUXEMBOURG_INTERNAL_CAF_DATA";

    default:
      return null;
  }
};

export const manageData = (reducer) => {
  switch (reducer) {
    case "clientInformation":
      return "NEW_CLIENT_INFO_STATE";
    case "UKClientInformation":
      return "NEW_UK_CLIENT_INFO_STATE";
    case "CuracaoClientInformation":
      return "NEW_CURACAO_CLIENT_INFO_STATE";
    case "MaltaClientInformation":
      return "NEW_MALTA_CLIENT_INFO_STATE";
    case "LuxembourgClientInformation":
      return "NEW_LUXEMBOURG_CLIENT_INFO_STATE";
    case "commonFields":
      return "NEW_COMMON_FIELD_STATE";

    default:
      return null;
  }
};

export const increaseObjects = (reducer) => {
  switch (reducer) {
    case "clientInformation":
      return "CREATE_CLIENTINFO_OBJECT";
    case "UBODeclarationUIM":
      return "CREATE_UBO_UIM_OBJECT";
    case "AnnexUBOInformation":
      return "CREATE_ANNEX_UBO_OBJECT";
    case "commonFields":
      return "CREATE_COMMON_FIELDS_OBJECT";
    case "ClientAcceptance":
      return "CREATE_CLIENT_ACCEPT_OBJECT";
    case "UKClientInformation":
      return "CREATE_UK_CLIENTINFO_OBJECT";
    case "UKInternalCAF":
      return "CREATE_INTERNAL_UK_CAF_OBJECT";
    case "CuracaoClientInformation":
      return "CREATE_CURACAO_CLIENTINFO_OBJECT";
    case "CuracaoInternalCAF":
      return "CREATE_INTERNAL_CURACAO_CAF_OBJECT";
    case "MaltaClientInformation":
      return "CREATE_MALTA_CLIENTINFO_OBJECT";
    case "MaltaInternalCAF":
      return "CREATE_INTERNAL_MALTA_CAF_OBJECT";
    case "LuxembourgClientInformation":
      return "CREATE_LUXEMBOURG_CLIENTINFO_OBJECT";
    case "LuxembourgInternalCAF":
      return "CREATE_INTERNAL_LUXEMBOURG_CAF_OBJECT";
    default:
      return null;
  }
};

export const decreaseObjects = (reducer) => {
  switch (reducer) {
    case "clientInformation":
      return "REMOVE_CLIENTINFO_OBJECT";
    case "UBODeclarationUIM":
      return "REMOVE_UBO_UIM_OBJECT";
    case "AnnexUBOInformation":
      return "REMOVE_ANNEX_UBO_OBJECT";
    case "commonFields":
      return "REMOVE_COMMON_FIELDS_OBJECT";
    case "ClientAcceptance":
      return "DECREASE_COMMON_FIELDS_OBJECT";
    case "UKClientInformation":
      return "REMOVE_UK_CLIENTINFO_OBJECT";
    case "UKInternalCAF":
      return "REMOVE_INTERNAL_UK_CAF_OBJECT";
    case "CuracaoClientInformation":
      return "REMOVE_CURACAO_CLIENTINFO_OBJECT";
    case "CuracaoInternalCAF":
      return "REMOVE_INTERNAL_CURACAO_CAF_OBJECT";
    case "MaltaClientInformation":
      return "REMOVE_MALTA_CLIENTINFO_OBJECT";
    case "MaltaInternalCAF":
      return "REMOVE_INTERNAL_MALTA_CAF_OBJECT";
    case "LuxembourgClientInformation":
      return "REMOVE_LUXEMBOURG_CLIENTINFO_OBJECT";
    case "LuxembourgInternalCAF":
      return "REMOVE_INTERNAL_LUXEMBOURG_CAF_OBJECT";
    default:
      return null;
  }
};
