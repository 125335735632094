import React from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";
import {
  setMaltaInternalCAFDetails,
  getMaltaInternalCAFDetails,
} from "../../../../../redux/actions/Malta/MaltaInternalCAFActions";
import "../../Onboarding/internalCAF.scss";
import { connect } from "react-redux";
import NotificationManager from "react-notifications/lib/NotificationManager";
import CheckBox from "../../../../elements/CheckBox";
import AutoSaving from "../AutoSaving";
import { chapValidationErrorMalta } from "../Constants";
import MediaCoverage from "../../Onboarding/Chapter1/MediaCoverage";
import Risks from "../../Onboarding/Chapter1/Risks";
import MultiSelectDropdown from "../../../../elements/MultiSelectDropdown";
import getSequentialFields from "../getSequentialFields";
import { setMaltaInternalChapterError } from "../../../../../redux/actions/Malta/MaltaInternalChapterErrorActions";

const chapter = "chapter5";
const reducer = "MaltaInternalCAF";
const externalReducer = "MaltaClientInformation";
let timer;

class Chapter5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formErrorsChapter5: {},
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: "all",
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.getMaltaInternalCAFDetails(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          Object.keys(this.props.internalErrors.errors).length === 0 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationErrorMalta.forEach(async (element) => {
            let response =
              res?.data?.result?.cafInternalValidations[element.chapter];

            const newResponse = await Promise.resolve(
              getSequentialFields(response, element.chapter, false)
            );

            this.props.setMaltaInternalChapterError({
              form: `formErrors${element.chapter}`,
              errors: newResponse?.errorChapter,
            });
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;

    this.props.setMaltaInternalCAFDetails(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.props.ClientAcceptance.chapter5["dirty"] = false;
        let response = res.data.result;

        const newResponse = await Promise.resolve(
          getSequentialFields(response, chapter, true)
        );
        this.props.setMaltaInternalChapterError({
          errors: newResponse?.errorChapter,
          form: "formErrorschapter5",
        });
        if (!res.data.result.isValid) {
          NotificationManager.info(
            "All fields are mandatory to fill in",
            null,
            4000
          );
          timer = setTimeout(() => {
            return this.props.history.push({
              pathname: "/malta/agreement",
            });
          }, 4000);
        } else {
          NotificationManager.success("Form is submitted successfully.");

          return this.props.history.push({
            pathname: "/malta/agreement",
          });
        }
      }
    });
  };

  render() {
    const { isUnacceptableRisksIdentified, internalErrors } = this.props;
    const formErrorChapter5 = JSON.stringify(internalErrors.errors);
    const errorsMsg = JSON.parse(formErrorChapter5);
    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="html2pdf__page-break"></div>
          <div className="cafHeader">
            <h2 className="m-0">Business Acceptance Form </h2>
          </div>
          <div className="internal_caf generic-form">
            <h3 className="chapterttl">
              Client Acceptance - Country / Sanctions Risks
            </h3>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-6">
                <TextBox
                  label="Is UIM aware of any incidents or FIU report(s) that are relevant?"
                  placeholder="Enter your text"
                  name="isUIMAwareOfIncidents"
                  id="isUIMAwareOfIncidents"
                  reducer={reducer}
                  chapter={chapter}
                  validationType="Text"
                  isRequired={true}
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.isUIMAwareOfIncidents?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isUIMAwareOfIncidents}
                    </span>
                  )}
              </div>
              <div className="mb-2 col-lg-6">
                <TextBox
                  label="Please enter comment"
                  placeholder="Enter your text"
                  name="uIMComment"
                  id="uIMComment"
                  reducer={reducer}
                  chapter={chapter}
                  validationType="Text"
                  isRequired={true}
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.uIMComment?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.uIMComment}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-6">
                <MultiSelectDropdown
                  label="Which countries are involved in the client structure?"
                  name="countryList"
                  isMulti={true}
                  reducer={externalReducer}
                  chapter="chapter5"
                  disabled={true}
                  PdfGeneration={this.props.PdfGeneration}
                />
              </div>
              <div className="mb-2 col-lg-6">
                <TextBox
                  label="Please enter comment"
                  placeholder="Enter your text"
                  name="structureComment"
                  id="structureComment"
                  reducer={reducer}
                  chapter={chapter}
                  validationType="Text"
                  isRequired={true}
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.structureComment?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.structureComment}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-6">
                <CheckBox
                  label="Are countries involved where Restrictive measures are in force?"
                  options={["Yes", "No"]}
                  name="isRestrictiveMeasure"
                  id="isRestrictiveMeasure"
                  reducer={reducer}
                  chapter={chapter}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.isRestrictiveMeasure?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isRestrictiveMeasure}
                    </span>
                  )}
              </div>
              <div className="mb-3 col-lg-6">
                <CheckBox
                  label="Are there countries involved in the structure placed on the FATF list"
                  options={["Yes", "No"]}
                  name="isStructurePlacedOnFATF"
                  id="isStructurePlacedOnFATF"
                  reducer={reducer}
                  chapter={chapter}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.isStructurePlacedOnFATF?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isStructurePlacedOnFATF}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-6">
                <CheckBox
                  label="Are there countries in the structure and/or is the target company directly?"
                  options={["Yes", "No"]}
                  name="isStructureOrObjCompanyDirectly"
                  id="isStructureOrObjCompanyDirectly"
                  reducer={reducer}
                  chapter={chapter}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.isStructureOrObjCompanyDirectly?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isStructureOrObjCompanyDirectly}
                    </span>
                  )}
              </div>
              <div className="mb-2 col-lg-6">
                <TextBox
                  label="Include information"
                  placeholder="Enter your text"
                  name="includeInformation"
                  id="includeInformation"
                  reducer={reducer}
                  chapter={chapter}
                  validationType="Text"
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.includeInformation?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.includeInformation}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="pt-3 mb-3">
              <div className="mb-2 col-lg-6">
                <CheckBox
                  label="Have all parties involved in the structure(legal entities and natural persons) been checked in Pascal?"
                  options={["Yes", "No"]}
                  name="isClientsInclRelevantCounterparties"
                  id="isClientsInclRelevantCounterparties"
                  reducer={reducer}
                  chapter={chapter}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.isClientsInclRelevantCounterparties?.length >
                    0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isClientsInclRelevantCounterparties}
                    </span>
                  )}
              </div>
            </Row>
            <div className="html2pdf__page-break"></div>
            <MediaCoverage
              chapter={chapter}
              reducer={reducer}
              errors={formErrorChapter5}
              form="Malta"
              PdfGeneration={this.props.PdfGeneration}
              isRequired={true}
            />
            <Risks
              chapter={chapter}
              reducer={reducer}
              errors={formErrorChapter5}
              riskLabel="Country risk rating"
              questionLabel="How many country/sanction risks can be identified?"
              form="Malta"
              boxLabel="Risk"
              isRequired={true}
              PdfGeneration={this.props.PdfGeneration}
            />
            <div className="html2pdf__page-break"></div>
            <Row className="mb-3">
              <div className="col-lg-12">
                Explain if a trade embargo or sanction measure applies, a
                country is involved in the structure which is on the FATF list
                or certain countries have a low CPI score or there is a hit on
                Pascal.
              </div>
            </Row>
            <Row className="mb-3">
              <div className="col-lg-12">
                <CheckBox
                  label="Unacceptable risks can be identified?"
                  options={["Yes", "No"]}
                  name="isUnacceptableRisksIdentified"
                  id="isUnacceptableRisksIdentified"
                  chapter={chapter}
                  reducer={reducer}
                  isMultiple={true}
                  isRequired={true}
                />

                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.isUnacceptableRisksIdentified?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isUnacceptableRisksIdentified}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="mb-3">
              {isUnacceptableRisksIdentified === "Yes" && (
                <div className="col-lg-12">
                  <TextBox
                    label="Please describe"
                    placeholder="Enter your text"
                    name="unacceptableRisksReason"
                    id="unacceptableRisksReason"
                    chapter={chapter}
                    reducer={reducer}
                    isMultipleLines={true}
                    isRequired={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtArea"
                        : "form-control"
                    }
                  />
                  {errorsMsg &&
                    errorsMsg?.isSubmitted === true &&
                    errorsMsg?.unacceptableRisksReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.unacceptableRisksReason}
                      </span>
                    )}
                </div>
              )}
              {isUnacceptableRisksIdentified === "No" && (
                <div className="col-md-12">
                  <b>
                    With respect to the country and sanctions information, UIM
                    sees no reason to discontinue the set-up of a business
                    relationship.
                  </b>
                </div>
              )}
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    chapter5: state.MaltaClientInformation.chapter5,
    isUnacceptableRisksIdentified:
      state.MaltaInternalCAF.chapter5["isUnacceptableRisksIdentified"],
    ClientAcceptance: state.MaltaInternalCAF,
    commonFields: state.commonFields,
    data: state,
    internalErrors: state.MaltaInternalChapterErrors.formErrorschapter5,
    errors: state.MaltaInternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMaltaInternalCAFDetails: async (data) =>
      dispatch(await getMaltaInternalCAFDetails(data)),
    setMaltaInternalCAFDetails: async (data) =>
      dispatch(await setMaltaInternalCAFDetails(data)),
    setMaltaInternalChapterError: (data) =>
      dispatch(setMaltaInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter5);
