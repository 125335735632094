import React, { Component } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";

class ExternalHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleLogout = () => {
    localStorage.removeItem("CompanyName");
    localStorage.removeItem("Office");
    localStorage.removeItem("formId");
    localStorage.removeItem("user");
    localStorage.removeItem("activeLinkLevel");
    window.location = "/login";
  };

  componentDidMount = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    this.setState({ user });
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  handleRoute = () => {
    return this.props.history.push({
      pathname: "/changepassword",
    });
  };

  render() {
    let user = JSON.parse(localStorage.getItem("user"));
    let initials;
    if (user) {
      initials = user.firstName + " " + user.lastName;
    }
    return (
      <div className="header headerInternal">
        <Container fluid>
          <Row>
            <Col xs={6}>
              <Link to="" className="logo" />
            </Col>

            <Col
              xs={6}
              className="d-flex align-items-center justify-content-end"
            >
              <Dropdown>
                <Dropdown.Toggle
                  className="dropdownNameLink"
                  id="dropdown-basic"
                >
                  {initials}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.handleRoute}>
                    My Profile
                  </Dropdown.Item>
                  <Dropdown.Item href="#" onClick={this.handleLogout}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(ExternalHeader);
