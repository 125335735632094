import { Chapter1 } from "../../../app/JSONs/UK-Form/Internal/Chapter1";
import { Chapter2 } from "../../../app/JSONs/UK-Form/Internal/Chapter2";
import { Chapter3 } from "../../../app/JSONs/UK-Form/Internal/Chapter3";
import { Chapter4 } from "../../../app/JSONs/UK-Form/Internal/Chapter4";
import { Chapter5 } from "../../../app/JSONs/UK-Form/Internal/Chapter5";
import { Chapter6 } from "../../../app/JSONs/UK-Form/Internal/Chapter6";
import { Chapter8 } from "../../../app/JSONs/UK-Form/Internal/Chapter8";
import { Chapter9 } from "../../../app/JSONs/InternalUser/Chapter9";
import { Chapter10 } from "../../../app/JSONs/InternalUser/Chapter10";
import { Chapter11 } from "../../../app/JSONs/UK-Form/Internal/Chapter11";
import { Chapter12 } from "../../../app/JSONs/UK-Form/Internal/Chapter12";

const initialState = {
  chapter1: Chapter1,
  chapter2: Chapter2,
  chapter3: Chapter3,
  chapter4: Chapter4,
  chapter5: Chapter5,
  chapter6: Chapter6,
  chapter8: Chapter8,
  chapter9: Chapter9,
  chapter10: Chapter10,
  chapter11: Chapter11,
  chapter12: Chapter12,
};

const UKInternalCAFReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "UPDATE_UK_INTERNAL_CAF_DATA":
      const {
        baseName,
        index,
        name,
        value,
        chapter,
        objBaseName,
        nestedObjBaseName,
        indexObjBaseName
      } = action.data;
      let array, obj;
      if (!baseName && !objBaseName) {
        return {
          ...state,
          [chapter]: {
            ...state[chapter],
            [name]: value,
            dirty: true,
          },
        };
      } else if (nestedObjBaseName && objBaseName) {
        return {
          ...state,
          [chapter]: {
            ...state[chapter],
            [objBaseName]: {
              ...state[chapter][objBaseName],
              [nestedObjBaseName]: {
                ...state[chapter][objBaseName][nestedObjBaseName],
                [name]: value,
              },
            },
            dirty: true,
          },
        };
      } else if (objBaseName && !baseName) {
        return {
          ...state,
          [chapter]: {
            ...state[chapter],
            [objBaseName]: {
              ...state[chapter][objBaseName],
              [name]: value,
            },
            dirty: true,
          },
        };
      }  else if (objBaseName && baseName && indexObjBaseName) {
        array = state[chapter][objBaseName][indexObjBaseName][baseName];
        obj = {
           ...array[index],
           [name]: value,
         };
         array[index] = obj;
         return {
           ...state,
           [chapter]: {
             ...state[chapter],
             [objBaseName]: Object.values({
               ...state[chapter][objBaseName],
               [indexObjBaseName]:{
                   ...state[chapter][objBaseName][indexObjBaseName],
                   [baseName]: array,
                 
                
               }
             }),
            dirty: true,
           },
         };
       } 
      else if (objBaseName && baseName  && !indexObjBaseName) {
        array = state[chapter][objBaseName][baseName];
        obj = {
          ...array[index],
          [name]: value,
        };
        array[index] = obj;
        return {
          ...state,
          [chapter]: {
            ...state[chapter],
            [objBaseName]: {
              ...state[chapter][objBaseName],
              [baseName]: array,
            },
            dirty: true,
          },
        };
      } else {
        array = state[chapter][baseName];
        obj = {
          ...array[index],
          [name]: value,
        };
        array[index] = obj;
        return {
          ...state,
          [chapter]: {
            ...state[chapter],
            [baseName]: array,
            dirty: true,
          },
        };
      }

    case "GET_UK_INTERNAL_CAF_DETAILS_SUCCESS":
      let res = action.data.result;
      if (res) {
        let chapter1 = res.cafInternal.chapter1
          ? res.cafInternal.chapter1
          : Chapter1;
        let chapter2 = res.cafInternal.chapter2
          ? res.cafInternal.chapter2
          : Chapter2;
        let chapter3 = res.cafInternal.chapter3
          ? res.cafInternal.chapter3
          : Chapter3;
        let chapter4 = res.cafInternal.chapter4
          ? res.cafInternal.chapter4
          : Chapter4;
        let chapter5 = res.cafInternal.chapter5
          ? res.cafInternal.chapter5
          : Chapter5;
        let chapter6 = res.cafInternal.chapter6
          ? res.cafInternal.chapter6
          : Chapter6;
        let chapter8 = res.cafInternal.chapter8
          ? res.cafInternal.chapter8
          : Chapter8;
        let chapter9 = res.cafInternal.chapter9
          ? res.cafInternal.chapter9
          : Chapter9;
        let chapter10 = res.cafInternal.chapter10
          ? res.cafInternal.chapter10
          : Chapter10;
        let chapter11 = res.cafInternal.chapter11
          ? res.cafInternal.chapter11
          : Chapter11;
        let chapter12 = res.cafInternal.chapter12
          ? res.cafInternal.chapter12
          : Chapter12;

        chapter1.dirty = false;
        chapter2.dirty = false;
        chapter3.dirty = false;
        chapter4.dirty = false;
        chapter5.dirty = false;
        chapter6.dirty = false;
        chapter8.dirty = false;
        chapter9.dirty = false;
        chapter10.dirty = false;
        chapter11.dirty = false;
        return {
          ...state,
          chapter1: chapter1,
          chapter2: chapter2,
          chapter3: chapter3,
          chapter4: chapter4,
          chapter5: chapter5,
          chapter6: chapter6,
          chapter8: chapter8,
          chapter9: chapter9,
          chapter10: chapter10,
          chapter11: chapter11,
          chapter12: chapter12,
        };
      } else {
        return state;
      }

    case "CREATE_INTERNAL_UK_CAF_OBJECT":
      const { chap, maxIndex, referral, pushedObject, nestedObjName,masterObj, masterIndex } =
        action.data;
        let arrData;
      if (nestedObjName) {
        arrData = state[chap][nestedObjName][referral];
      } else if(masterObj){       
        arrData =state[chap][masterObj][masterIndex][referral];
      } 
      else {
        arrData = state[chap][referral];
      }
      if (arrData !== null && arrData.length < maxIndex) {
        let diff = maxIndex - arrData.length;

        for (let i = 0; i < diff; i++) {
          arrData.push(pushedObject);
        }
      }

      return {
        ...state,
        [chap]: {
          ...state[chap],
          [referral]: arrData,
        },
      };

    case "REMOVE_INTERNAL_UK_CAF_OBJECT":
      const { remChap, remMaxIndex, remReferral, remNestedObjName } =
        action.data;

      let remArray;
      if (remNestedObjName) {
        remArray = state[remChap][remNestedObjName][remReferral];
      } else {
        remArray = state[remChap][remReferral];
      }

      if (remArray !== null && remArray.length < remMaxIndex) {
        let diff = remMaxIndex - remArray.length;
        for (let i = 0; i < diff; i++) {
          remArray.splice(-1);
        }
      }

      return {
        ...state,
        [remChap]: {
          ...state[remChap],
          [remReferral]: remArray,
        },
      };

    case "NEW_CLIENT_INFO_STATE":
      if (action.data.state) return action.data.state;
      else return state;

    default:
      return state;
  }
};

export default UKInternalCAFReducer;
