import React, { Component } from "react";
import { Row } from "react-bootstrap";
import countryList from "react-select-country-list";
import DropDown from "../../../../elements/DropDown";
import TextBox from "../../../../elements/TextBox";
import CheckBox from "../../../../elements/CheckBox";
import DownloadFile from "../../../../elements/DownloadFile";
import { connect } from "react-redux";
class Trustees extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { index, errorMsg, istrusPassport, istrusProofAdd, istrusRefLett, trusData } = this.props;
        const eMsg = errorMsg;
        return (
            <div className="mb-4 col-lg-12">
                <fieldset>
                    <legend>Trustee {this.props.index + 1}</legend>
                    <Row>
                        <div className="mb-2 col-lg-6 col-xl-6">
                            <TextBox
                                label="Trustee name"
                                name="trusteeName"
                                id={`trusteeName[${index}]`}
                                placeholder="enter your text"
                                objBaseName={this.props.objBaseName}
                                indexObjBaseName={`${this.props.indexObjBaseName}`}
                                index={this.props.index}
                                baseName={this.props.baseName}
                                reducer={this.props.reducer}
                                validationType="Text"
                                chapter={this.props.chapter}
                                disabled={true}
                            />
                        </div>
                        <div className="mb-2 col-lg-6 col-xl-6">
                            <TextBox
                                label="Trustee address"
                                name="trusteeAddress"
                                id={`trusteeAddress[${index}]`}
                                placeholder="enter your text"
                                objBaseName={this.props.objBaseName}
                                indexObjBaseName={`${this.props.indexObjBaseName}`}
                                index={this.props.index}
                                baseName={this.props.baseName}
                                reducer={this.props.reducer}
                                validationType="Text"
                                chapter={this.props.chapter}
                                disabled={true}
                            />
                        </div>
                        <div className="mb-3 col-lg-6">
                            <CheckBox
                                label="Trustee's passport or national identity card available?"
                                name="istrusPassport"
                                options={["Yes", "No"]}
                                id={`istrusPassport[${index}]`}
                                placeholder="enter your text"
                                objBaseName={this.props.objBaseName}
                                indexObjBaseName={`${this.props.indexObjBaseName}`}
                                index={this.props.index}
                                baseName={this.props.baseName}
                                reducer={this.props.reducer}
                                chapter={this.props.chapter}
                                disabled={true}
                            />
                        </div>
                        {istrusPassport === "Yes" && (
                            <div className="mb-3 col-lg-4">
                                <DownloadFile
                                    label="Upload Trustee's Passport or national identity"
                                    name="trusPassport"
                                    value={trusData.trusPassport}
                                />

                            </div>
                        )}
                        {istrusPassport === "No" && (
                            <div className="mb-3 col-lg-4">
                                <TextBox
                                    label="Explanation"
                                    name="truePassportReason"
                                    id={`truePassportReason[${index}]`}
                                    placeholder="enter your text"
                                    objBaseName={this.props.objBaseName}
                                    indexObjBaseName={`${this.props.indexObjBaseName}`}
                                    index={this.props.index}
                                    baseName={this.props.baseName}
                                    reducer={this.props.reducer}
                                    validationType="Text"
                                    chapter={this.props.chapter}
                                    disabled={true}
                                />

                            </div>
                        )}
                    </Row>
                    <Row>
                        <div className="mb-3 col-lg-6">
                            <CheckBox
                                label="Trustee's proof of address available?"
                                name="istrusProofAdd"
                                options={["Yes", "No"]}
                                id={`istrusProofAdd[${index}]`}
                                placeholder="enter your text"
                                objBaseName={this.props.objBaseName}
                                indexObjBaseName={`${this.props.indexObjBaseName}`}
                                index={this.props.index}
                                baseName={this.props.baseName}
                                reducer={this.props.reducer}
                                chapter={this.props.chapter}
                                disabled={true}
                            />

                        </div>
                        {istrusProofAdd === "Yes" && (
                            <div className="mb-3 col-lg-4">
                                <DownloadFile
                                    label="Upload Trustee's proof of address"
                                    name="trusProofAdd"
                                    value={trusData.trusProofAdd}
                                />
                            </div>
                        )}
                        {istrusProofAdd === "No" && (
                            <div className="mb-3 col-lg-4">
                                <TextBox
                                    label="Explanation"
                                    name="trusProofAddReason"
                                    id={`trusProofAddReason[${index}]`}
                                    placeholder="enter your text"
                                    objBaseName={this.props.objBaseName}
                                    indexObjBaseName={`${this.props.indexObjBaseName}`}
                                    index={this.props.index}
                                    baseName={this.props.baseName}
                                    reducer={this.props.reducer}
                                    validationType="Text"
                                    chapter={this.props.chapter}
                                    disabled={true}
                                />

                            </div>
                        )}
                    </Row>
                    <Row className="pt-1 pb-2">
                        <div className="mb-3 col-lg-6">
                            <CheckBox
                                label="Trustee's reference letter available?"
                                name="istrusRefLett"
                                options={["Yes", "No"]}
                                id={`istrusRefLett[${index}]`}
                                placeholder="enter your text"
                                objBaseName={this.props.objBaseName}
                                indexObjBaseName={`${this.props.indexObjBaseName}`}
                                index={this.props.index}
                                baseName={this.props.baseName}
                                reducer={this.props.reducer}
                                chapter={this.props.chapter}
                                disabled={true}
                            />

                        </div>
                        {istrusRefLett === "Yes" && (
                            <div className="mb-3 col-lg-4">
                                <DownloadFile
                                    label="Upload Trustee's reference letter"
                                    name="trusRefLett"
                                    value={trusData.trusRefLett}
                                />
                            </div>
                        )}
                        {istrusRefLett === "No" && (
                            <div className="mb-3 col-lg-4">
                                <TextBox
                                    label="Explanation"
                                    name="trusRefLettReason"
                                    id={`trusRefLettReason[${index}]`}
                                    placeholder="enter your text"
                                    objBaseName={this.props.objBaseName}
                                    indexObjBaseName={`${this.props.indexObjBaseName}`}
                                    index={this.props.index}
                                    baseName={this.props.baseName}
                                    reducer={this.props.reducer}
                                    validationType="Text"
                                    chapter={this.props.chapter}
                                    disabled={true}
                                />

                            </div>
                        )}
                    </Row>
                </fieldset>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { baseName, index, reducer, objBaseName, indexObjBaseName } = ownProps;
    return {
        trusData: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index],
        istrusPassport: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["istrusPassport"],
        istrusProofAdd: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["istrusProofAdd"],
        istrusRefLett: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["istrusRefLett"],
    };
};

export default connect(mapStateToProps, null)(Trustees);
