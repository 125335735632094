import React, { Component } from "react";
import TextBox from "../../../../../elements/TextBox";
import { Row } from "react-bootstrap";
import { authorizedRepresentative } from "./Constants";
import DateTimePicker from "../../../../../elements/DateTimePicker";
import FileUpload from "../../../../../elements/FileUpload";
import { fileAcceptType, reducer } from "./Constants";
import countryList from "react-select-country-list";
import DropDown from "../../../../../elements/DropDown";

const UBOForm = "AnnexUBO";

class AuthorizedRepresentative extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, baseName, errorMsg } = this.props;
    return (
      <div className="mb-4 col-lg-12">
        <h5 className="mb-2">Authorized representative {index + 1}</h5>
        {authorizedRepresentative.map((i) => {
          return (
            <>
              <div className="form-group mb-1" key={i}>
                <div className="row">
                  <div className="mb-2 col-lg-4">
                    <TextBox
                      label="First name"
                      placeholder="Enter First name"
                      id={`firstName[${index}]`}
                      name="firstName"
                      index={index}
                      chapter={UBOForm}
                      baseName={baseName}
                      reducer={reducer}
                      validationType="OnlyLetter"
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.declarationForms[index]?.firstName?.length >
                        0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errorMsg.declarationForms[index]?.firstName}
                        </span>
                      )}
                  </div>
                  <div className="mb-2 col-lg-4">
                    <TextBox
                      label="Middle name(s)"
                      placeholder="Enter middle name"
                      id={`middleName[${index}]`}
                      name="middleName"
                      index={index}
                      chapter={UBOForm}
                      baseName={baseName}
                      reducer={reducer}
                      validationType="OnlyLetter"
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.declarationForms[index]?.middleName?.length >
                        0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errorMsg.declarationForms[index]?.middleName}
                        </span>
                      )}
                  </div>
                  <div className="mb-2 col-lg-4">
                    <TextBox
                      label="Last name"
                      placeholder="Enter last name"
                      id={`lastName[${index}]`}
                      name="lastName"
                      index={index}
                      chapter={UBOForm}
                      baseName={baseName}
                      reducer={reducer}
                      validationType="OnlyLetter"
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.declarationForms[index]?.lastName?.length >
                        0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errorMsg.declarationForms[index]?.lastName}
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <div className="form-group mb-1">
                <div className="row">
                  <div className="mb-2 col-lg-4"  id={`dateOfBirth[${index}]`}>
                    <DateTimePicker
                      label="Date of birth"
                      name="dateOfBirth"
                      index={index}
                      chapter={UBOForm}
                      baseName={baseName}
                      reducer={reducer}
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.declarationForms[index]?.dateOfBirth?.length >
                        0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errorMsg.declarationForms[index]?.dateOfBirth}
                        </span>
                      )}
                  </div>
                  <div className="mb-2 col-lg-4">
                    <TextBox
                      label="Place of birth"
                      placeholder="Enter Place of birth"
                      id={`placeOfBirth[${index}]`}
                      name="placeOfBirth"
                      index={index}
                      chapter={UBOForm}
                      baseName={baseName}
                      reducer={reducer}
                      validationType="OnlyLetter"
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.declarationForms[index]?.placeOfBirth?.length >
                        0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errorMsg.declarationForms[index]?.placeOfBirth}
                        </span>
                      )}
                  </div>
                  <div className="mb-2 col-lg-4">
                    <TextBox
                      label="Email Address"
                      placeholder="Enter Email Address"
                      id={`email[${index}]`}
                      name="email"
                      index={index}
                      chapter={UBOForm}
                      baseName={baseName}
                      reducer={reducer}
                      validationType="Email"
                      ShowValidation= {Object.entries(errorMsg).length !== 0 &&
                        errorMsg.declarationForms[index]?.email?.length > 0 ? false:true}
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.declarationForms[index]?.email?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errorMsg.declarationForms[index]?.email}
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <div className="form-group mb-1">
                <div className="row">
                  <div className="mb-2 col-lg-4">
                    <TextBox
                      label="Passport number"
                      placeholder="Enter Passport number"
                      id={`passportNumber[${index}]`}
                      name="passportNumber"
                      index={index}
                      chapter={UBOForm}
                      baseName={baseName}
                      reducer={reducer}
                      validationType="Text"
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.declarationForms[index]?.passportNumber?.length >
                        0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errorMsg.declarationForms[index]?.passportNumber}
                        </span>
                      )}
                  </div>
                  <div className="mb-2 col-lg-4">
                    <TextBox
                      label="Address"
                      placeholder="Enter Address"
                      id={`address[${index}]`}
                      name="address"
                      index={index}
                      chapter={UBOForm}
                      baseName={baseName}
                      reducer={reducer}
                      validationType="Text"
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.declarationForms[index]?.address?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errorMsg.declarationForms[index]?.address}
                        </span>
                      )}
                  </div>
                  <div className="mb-2 col-lg-4">
                    <TextBox
                      label="Street"
                      placeholder="Enter Street"
                      id={`street[${index}]`}
                      name="street"
                      index={index}
                      chapter={UBOForm}
                      baseName={baseName}
                      reducer={reducer}
                      validationType="Text"
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.declarationForms[index]?.street?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errorMsg.declarationForms[index]?.street}
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <div className="form-group mb-1">
                <div className="row">
                  <div className="mb-2 col-lg-4">
                    <TextBox
                      label="Postal Code"
                      placeholder="Enter Postal Code"
                      id={`postalCode[${index}]`}
                      name="postalCode"
                      index={index}
                      chapter={UBOForm}
                      baseName={baseName}
                      reducer={reducer}
                      validationType="Text"
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.declarationForms[index]?.postalCode?.length >
                        0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errorMsg.declarationForms[index]?.postalCode}
                        </span>
                      )}
                  </div>
                  <div className="mb-2 col-lg-4">
                    <TextBox
                      label="City"
                      placeholder="Enter City"
                      id={`city[${index}]`}
                      name="city"
                      index={index}
                      chapter={UBOForm}
                      baseName={baseName}
                      reducer={reducer}
                      validationType="Text"
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.declarationForms[index]?.city?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errorMsg.declarationForms[index]?.city}
                        </span>
                      )}
                  </div>
                  <div className="mb-2 col-lg-4 countrySelection">
                    <DropDown
                      label="Country"
                      id={`country[${index}]`}
                      name="country"
                      options={countryList().getData()}
                      index={index}
                      chapter={UBOForm}
                      baseName={baseName}
                      reducer={reducer}
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.declarationForms[index]?.country?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errorMsg.declarationForms[index]?.country}
                        </span>
                      )}
                  </div>

                  <div className="mb-2 col-lg-4 countrySelection">
                    <DropDown
                      label="Nationality"
                      id={`nationality[${index}]`}
                      name="nationality"
                      options={countryList().getData()}
                      index={index}
                      chapter={UBOForm}
                      baseName={baseName}
                      reducer={reducer}
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.declarationForms[index]?.nationality?.length >
                        0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errorMsg.declarationForms[index]?.nationality}
                        </span>
                      )}
                  </div>

                  <div className="mb-2 col-lg-4" id={`powerOfAttorneyDated[${index}]`}>
                    <DateTimePicker
                      label="Power of attorney dated"
                      name="powerOfAttorneyDated"
                      index={index}
                      chapter={UBOForm}
                      baseName={baseName}
                      reducer={reducer}
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.declarationForms[index]?.powerOfAttorneyDated
                        ?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {
                            errorMsg.declarationForms[index]
                              ?.powerOfAttorneyDated
                          }
                        </span>
                      )}
                  </div>
                  <div className="col-md-12">
                  <Row className="pt-2">
                  <div className="mb-2 col-lg-4">
                    <FileUpload
                      label="Upload Power of Attorney"
                      acceptType={fileAcceptType}
                      id={`powerOfAttorney[${index}]`}
                      name="powerOfAttorney"
                      index={index}
                      chapter={UBOForm}
                      baseName={baseName}
                      reducer={reducer}
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.declarationForms[index]?.powerOfAttorney
                        ?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errorMsg.declarationForms[index]?.powerOfAttorney}
                        </span>
                      )}
                  </div>
                  <div className="mb-2 col-lg-4">
                    <FileUpload
                      label="Upload passport"
                      acceptType={fileAcceptType}
                      id={`copyOfPassport[${index}]`}
                      name="copyOfPassport"
                      index={index}
                      chapter={UBOForm}
                      baseName={baseName}
                      reducer={reducer}
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.declarationForms[index]?.copyOfPassport?.length >
                        0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errorMsg.declarationForms[index]?.copyOfPassport}
                        </span>
                      )}
                  </div>
                  </Row>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    );
  }
}

export default AuthorizedRepresentative;
