import {
  SET_ERROR_FOR_CHAPTER,
  SET_ALL_ERRORS_TO_NULL,
} from "../constants/InternalChapterErrorConstants";

export const setInternalChapterError = (data) => {
 
  return (dispatch) => {
    return dispatch({ type: SET_ERROR_FOR_CHAPTER, data: data });
  };
};

export const setAllErrorsToNull = () => {
  return (dispatch) => {
    return dispatch({ type: SET_ALL_ERRORS_TO_NULL });
  };
};
