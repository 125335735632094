import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { getClientFormList } from "../../../../../redux/actions/ClientFormListActions";
import { getUKInternalCAFDetails } from "../../../../../redux/actions/UK/UKInternalCAFActions";
import { setUKInternalCAFReject } from "../../../../../redux/actions/UK/UKInternalCAFActions";
import { getUKInternalCAFRejectComments } from "../../../../../redux/actions/UK/UKInternalCAFActions";
import { getCuracaoInternalCAFDetails } from "../../../../../redux/actions/Curacao/CuracaoInternalCAFActions";
import { getMaltaInternalCAFDetails } from "../../../../../redux/actions/Malta/MaltaInternalCAFActions";
import { getLuxembourgInternalCAFDetails } from "../../../../../redux/actions/Luxembourg/LuxembourgInternalCAFActions";
import { downloadInternalSignedFile } from "../../../../../redux/actions/InternalFileHandleActions";
import { setInternalChapterError } from "../../../../../redux/actions/InternalChapterErrorActions";
import Modal from "../../../../components/shared/Modal";
import TextBox from "../../../../elements/TextBox";
import { fileDownload } from "../../../../../redux/actions/InternalFileHandleActions";
import { saveAs } from "file-saver";
import { GetClientAcceptance } from "../../../../../redux/actions/ClientAcceptanceFormActions";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { setAllErrorsToNull } from "../../../../../redux/actions/InternalChapterErrorActions";
import orderBy from "lodash/orderBy";
import { chapValidationError } from "../Constants";
import { chapValidationErrorUK } from "../../UKOffice/constants";
import { chapValidationErrorCuracao } from "../../CuracaoOffice/Constants";
import { setUKInternalChapterError } from "../../../../../redux/actions/UK/UKInternalChapterErrorActions";
import { generateURL } from "../../Routing/generateUrl";
import { setCuracaoInternalChapterError } from "../../../../../redux/actions/Curacao/CuracaoInternalChapterErrors";
import { setMaltaInternalChapterError } from "../../../../../redux/actions/Malta/MaltaInternalChapterErrorActions";
import { chapValidationErrorMalta } from "../../MaltaOffice/Constants";
import { chapValidationErrorLuxembourg } from "../../LuxembourgOffice/Constants";
import { setLuxembourgInternalChapterError } from "../../../../../redux/actions/Luxembourg/LuxembourgInternalChapterErrors";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import moment from "moment";

let selectedOffices = [];
let selectedStatus = [];
let allOffices = [];


const customSort = (rows, field, direction) => {
  const handleField = (row) => {
    if (field?.toString().includes("formName")) {
      return row.formName.toLowerCase();
    } else if (field?.toString().includes("clientName")) {
      return row.clientName.toLowerCase();
    } else if (field?.toString().includes("Verified & Signed")) {
      return row.status.toLowerCase();
    } else if (field?.toString().includes("officeLocation")) {
      return row.office.toLowerCase();
    } else if (field?.toString().includes("action-btn edit noicon")) {
      return row.status.toUpperCase();
    }
    return row[field];
  };
  return orderBy(rows, handleField, direction);
};
class ClientFormList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasCmnt: true,
      clientFormList: [],
      selectedRows: [],
      isLoading: false,
      filterType: "office",
      originalData: [],
      msg: null,
      data: [],
      rejectionCmntHistory: [],
      userType: localStorage.getItem("userType"),
      columns: [],
      columnsRejectionHistory: [
        {
          name: "Rejected by",
          selector: "rejectedByUserName",
          sortable: true,
        },
        {
          name: "Date",
          //selector: "lastUpdate",
          sortable: true,
          selector: (row) => (
            <div>
              {row.lastUpdate ? (
                moment(row.lastUpdate).format("YYYY-MM-DD HH:mm")
              ) : (
                <div className="center">-</div>
              )}
            </div>
          ),
        },
        {
          name: "Comment",
          selector: "comment",
          sortable: true,
        },
      ],
      selectedRow: ""
    };
  }

  getStatus = (status) => {
    if (status === "NotStarted") {
      return <div>Not Started</div>;
    } else if (status === "InProgress") {
      return <div style={{ color: "#172983" }}>In Progress</div>;
    } else if (status === "Verified")
      return <div style={{ color: "#B00335" }}>Verified</div>;
    else if (status === "VerifiedSigned")
      return <div style={{ color: "#B00335" }}>Verified & Signed</div>;
    else if (status === "Rejected")
      return <div style={{ color: "#B00335" }}>Rejected</div>;
  };
  getAllClientFormList = () => {
    this.props.getClientFormList().then((res) => {
      this.setState({ isLoading: false });

      if (res.data?.portalErrorCode === 200) {
        allOffices = res.data.result;

        const statusCheckboxes = this.subHeaderFilterComponent("status");

        this.setState({
          columns: [
            {
              name: "Form Name",
              selector: (row) => row.formName,
              sortable: true,
              maxWidth: "25%",
            },
            {
              name: "Client Name",
              selector: (row) => row.clientName,
              sortable: true,
              width: "15%",
            },
            {
              name: "Office",
              width: "10%",
              sortable: true,
              selector: (row) => (
                <div className="officeLocation">
                  <div className={`${row.office}`}>{row.office} </div>
                </div>
              ),
            },
            {
              name: (
                <div className="office-dropdown-hover">
                  <div className="office-button">Status</div>

                  <div className="office-dropdown-content office-bar-block office-card-4">
                    {statusCheckboxes}
                  </div>
                </div>
              ),

              width: "15%",
              sortable: true,
              selector: (row) => this.getStatus(row.status),
            },
            {
              name: "Pascal CSV",
              width: "10%",
              selector: (row) => (
                <>
                  {row.pascalCSVFileName !== null ? (
                    <button
                      type="button"
                      className="action-btn borderbtn noicon"
                      onClick={() => this.handleDownload(row)}
                    >
                      CSV
                    </button>
                  ) : (
                    <></>
                  )}
                </>
              ),
            },
            {
              name: "Action",
              sortable: true,
              selector: (row) => (
                <>
                  {row.status === "NotStarted" ? (
                    <button
                      type="button"
                      className="action-btn edit noicon"
                      onClick={() => this.handleVerify(row, "/client-activity")}
                    >
                      Verify
                    </button>
                  ) : row.status === "InProgress" && row.office === "United Kingdom" ? (
                    <div>
                      <button
                        type="button"
                        className="action-btn edit noicon"
                        onClick={() => this.handleVerify(row, "/client-activity")}
                      >
                        Verify
                      </button>
                      <button
                        type="button"
                        className="reject-btn noicon"
                        onClick={() => this.handleReject(row)}
                      >
                        Reject
                      </button>
                    </div>

                  ) : row.status === "InProgress" && row.office !== "United Kingdom" ? (
                    <button
                      type="button"
                      className="action-btn edit noicon"
                      onClick={() => this.handleVerify(row, "/client-activity")}
                    >
                      Verify
                    </button>

                  ) : row.status === "Rejected" && row.office === "United Kingdom" ? (
                    <button
                      type="button"
                      className="reject-btn noicon"
                      onClick={() => this.rejectionHistory(row)}
                    >
                      Rejection history
                    </button>) : row.status === "Verified" && row.office !== "Curacao"? (
                      <button
                        type="button"
                        className="action-btn edit noicon"
                        onClick={() => this.handleVerify(row, "/signature")}
                      >
                        Go to Signature
                      </button>
                    ) : row.status === "VerifiedSigned" ? (
                      <button
                        type="button"
                        className="action-btn approve"
                        onClick={() => this.handleClientAcceptanceButton(row)}
                      >
                        Client Accepted
                      </button>
                    ) : row.status === "InProgress" && row.office === "Curacao" ? (
                      <>
                        <button
                          type="button"
                          className="action-btn edit noicon"
                          onClick={() => this.handleVerify(row, "/curacao/editClientForm")}
                        >
                          Edit
                        </button>

                      </>
                    ): row.status === "Verified" && row.office === "Curacao" ? (
                      <button
                        type="button"
                        className="action-btn edit noicon"
                        onClick={() => this.handleVerify(row, "/curacao/signature")}
                      >
                        Go to Signature
                      </button>
                    ) : (
                    <></>
                  )}
                </>
              ),
            },
          ],

          originalData: res.data.result,
          data: res.data.result,
        });
      } else if (res.data?.portalErrorCode === 400) {
        this.setState({ msg: "There are no any records found" });
      } else if (res.data?.portalErrorCode === 404) {
        this.setState({
          msg: "You are not part of any office group",
        });
      }
    });
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props.getClientFormList().then((res) => {
      this.setState({ isLoading: false });

      if (res.data?.portalErrorCode === 200) {
        allOffices = res.data.result;

        const statusCheckboxes = this.subHeaderFilterComponent("status");

        this.setState({
          columns: [
            {
              name: "Form Name",
              selector: (row) => row.formName,
              sortable: true,
              maxWidth: "25%",
            },
            {
              name: "Client Name",
              selector: (row) => row.clientName,
              sortable: true,
              width: "15%",
            },
            {
              name: "Office",
              width: "10%",
              sortable: true,
              selector: (row) => (
                <div className="officeLocation">
                  <div className={`${row.office}`}>{row.office} </div>
                </div>
              ),
            },
            {
              name: (
                <div className="office-dropdown-hover">
                  <div className="office-button">Status</div>

                  <div className="office-dropdown-content office-bar-block office-card-4">
                    {statusCheckboxes}
                  </div>
                </div>
              ),

              width: "15%",
              sortable: true,
              selector: (row) => this.getStatus(row.status),
            },
            {
              name: "Pascal CSV",
              width: "10%",
              selector: (row) => (
                <>
                  {row.pascalCSVFileName !== null ? (
                    <button
                      type="button"
                      className="action-btn borderbtn noicon"
                      onClick={() => this.handleDownload(row)}
                    >
                      CSV
                    </button>
                  ) : (
                    <></>
                  )}
                </>
              ),
            },
            {
              name: "Action",
              sortable: true,
              selector: (row) => (
                <>
                  {row.status === "NotStarted" ? (
                    <button
                      type="button"
                      className="action-btn edit noicon"
                      onClick={() => this.handleVerify(row, "/client-activity")}
                    >
                      Verify
                    </button>
                  ) : row.status === "InProgress" && row.office === "United Kingdom" ? (
                    <div>
                      <button
                        type="button"
                        className="action-btn edit noicon"
                        onClick={() => this.handleVerify(row, "/client-activity")}
                      >
                        Verify
                      </button>
                      <button
                        type="button"
                        className="reject-btn noicon"
                        onClick={() => this.handleReject(row)}
                      >
                        Reject
                      </button>
                    </div>

                  ) : row.status === "Rejected" && row.office === "United Kingdom" ? (
                    <button
                      type="button"
                      className="reject-btn noicon"
                      onClick={() => this.rejectionHistory(row)}
                    >
                      Rejection history
                    </button>

                  ) : row.status === "InProgress" && row.office !== "United Kingdom" && row.office !== "Curacao" ? (
                    <button
                      type="button"
                      className="action-btn edit noicon"
                      onClick={() => this.handleVerify(row, "/client-activity")}
                    >
                      Verify
                    </button>

                  ) : row.status === "Verified" && row.office !== "Curacao" ? (
                    <button
                      type="button"
                      className="action-btn edit noicon"
                      onClick={() => this.handleVerify(row, "/signature")}
                    >
                      Go to Signature
                    </button>
                  ) : row.status === "VerifiedSigned" ? (
                    <button
                      type="button"
                      className="action-btn approve"
                      onClick={() => this.handleClientAcceptanceButton(row)}
                    >
                      Client Accepted
                    </button>
                  ) : row.status === "InProgress" && row.office === "Curacao" ? (
                    <>
                      <button
                        type="button"
                        className="action-btn edit noicon"
                        onClick={() => this.handleVerify(row, "/curacao/editClientForm")}
                      >
                        Edit
                      </button>

                    </>
                  ): row.status === "Verified" && row.office === "Curacao" ? (
                    <button
                      type="button"
                      className="action-btn edit noicon"
                      onClick={() => this.handleVerify(row, "/curacao/signature")}
                    >
                      Go to Signature
                    </button>
                  ) : (
                    <></>
                  )}
                </>
              ),
            },
          ],

          originalData: res.data.result,
          data: res.data.result,
        });
      } else if (res.data?.portalErrorCode === 400) {
        this.setState({ msg: "There are no any records found" });
      } else if (res.data?.portalErrorCode === 404) {
        this.setState({
          msg: "You are not part of any office group",
        });
      }
    });
  }

  handleClientAcceptanceButton = (row) => {
    let obj = {
      fileName: "Combined.pdf",
      office: row.office,
      userName: row.userName,
      userEmail: row.userEmail,
      companyName: row.clientName,
      companyId: row.clientId,
    };

    this.props.downloadInternalSignedFile(obj).then((result) => {
      var blob = new Blob([result.data]);
      let file = "Combined.pdf";
      saveAs(blob, file);
    });
  };

  removeDuplicates = (arr) => {
    return [...new Set(arr)];
  };

  manageDataForDiffOffice = (office, reqData, path) => {
    if (office === "Netherlands") {
      this.props.GetClientAcceptance(reqData).then((res) => {
        if (res.data.portalErrorCode !== 200) {
          NotificationManager.error("Form has been deleted");
        } else if (res?.data?.result?.cafInternalValidations !== null) {
          localStorage.setItem("isCAFValid", res.data?.result?.isCAFValid);
          const response = res.data?.result;
          chapValidationError.forEach((element) => {
            this.props.setInternalChapterErrors({
              form: `formErrors${element.chapter}`,
              errors: response?.cafInternalValidations
                ? response?.cafInternalValidations[element.chapter]
                : "",
            });
          });
          return this.props.history.push({
            pathname: path,
          });
        } else {
          localStorage.setItem("isCAFValid", res.data?.result?.isCAFValid);
          return this.props.history.push({
            pathname: path,
          });
        }
      });
    } else if (office === "United Kingdom") {
      this.props.getUKInternalCAFDetails(reqData).then((res) => {
        if (res.data.portalErrorCode !== 200) {
          NotificationManager.error("Form has been deleted");
        } else if (res?.data?.result?.cafInternalValidations !== null) {
          localStorage.setItem("isCAFValid", res.data?.result?.isCAFValid);
          const response = res.data?.result;
          chapValidationErrorUK.forEach((element) => {
            this.props.setUKInternalChapterErrors({
              form: `formErrors${element.chapter}`,
              errors: response?.cafInternalValidations
                ? response?.cafInternalValidations[element.chapter]
                : "",
            });
          });
          return this.props.history.push({
            pathname: path,
          });
        } else {
          localStorage.setItem("isCAFValid", res.data?.result?.isCAFValid);
          return this.props.history.push({
            pathname: path,
          });
        }
      });
    } else if (office === "Curacao") {
      this.props.getCuracaoInternalCAFDetails(reqData).then((res) => {
        if (res.data?.portalErrorCode === 200) {
          return this.props.history.push({
            pathname: path,
          });
        } else if (
          res?.data?.result &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          localStorage.setItem("isCAFValid", res.data?.result?.isCAFValid);
          const response = res.data?.result;
          chapValidationErrorCuracao.forEach((element) => {
            this.props.setCuracaoInternalChapterErrors({
              form: `formErrors${element.chapter}`,
              errors: response?.cafInternalValidations
                ? response?.cafInternalValidations[element.chapter]
                : "",
            });
          });
          return this.props.history.push({
            pathname: path,
          });
        } else {
          NotificationManager.error("Form has been deleted");
        }
      });
    } else if (office === "Malta") {
      this.props.getMaltaInternalCAFDetails(reqData).then((res) => {
        if (res.data?.portalErrorCode === 200) {
          return this.props.history.push({
            pathname: path,
          });
        } else if (
          res?.data?.result &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          localStorage.setItem("isCAFValid", res.data?.result?.isCAFValid);
          const response = res.data?.result;
          chapValidationErrorMalta.forEach((element) => {
            this.props.setMaltaInternalChapterError({
              form: `formErrors${element.chapter}`,
              errors: response?.cafInternalValidations
                ? response?.cafInternalValidations[element.chapter]
                : "",
            });
          });
          return this.props.history.push({
            pathname: path,
          });
        } else {
          NotificationManager.error("Form has been deleted");
        }
      });
    } else if (office === "Luxembourg") {
      this.props.getLuxembourgInternalCAFDetails(reqData).then((res) => {
        if (res.data?.portalErrorCode === 200) {
          return this.props.history.push({
            pathname: path,
          });
        } else if (
          res?.data?.result &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          localStorage.setItem("isCAFValid", res.data?.result?.isCAFValid);
          const response = res.data?.result;
          chapValidationErrorLuxembourg.forEach((element) => {
            this.props.setLuxembourgInternalChapterError({
              form: `formErrors${element.chapter}`,
              errors: response?.cafInternalValidations
                ? response?.cafInternalValidations[element.chapter]
                : "",
            });
          });
          return this.props.history.push({
            pathname: path,
          });
        } else {
          NotificationManager.error("Form has been deleted");
        }
      });
    }
  };

  handleChange(e) {
    this.props.handleOfficeChange(e);
  }

  //this will work for verify as well as Go To Signature button
  handleVerify = (row, path) => {
    this.handleChange(row.office);
    localStorage.setItem("Office", row.office);
    localStorage.setItem("formData", JSON.stringify(row));
    let reqData = {
      id: row.id,
      userId: row.userId,
      chapter: "chapter1",
    };
    const office = generateURL(row.office);
    const newPath = office + path;
    if(row.office !=="Curacao"){
    this.manageDataForDiffOffice(row.office, reqData, newPath);
    }
    else{
      return this.props.history.push({
        pathname: path,
      });
    }
  };
  //this will work for reject the item and change the status
  handleReject = (row) => {
    this.modalOpen(row);

  };
  modalOpen = (row) => {
    this.setState({
      //rejectionCmntHistory: res.data.result,
      modal: true,
      selectedRow: row
    });
    let reqData = {
      id: row.id,
      userId: row.userId,
    };
    this.props.getUKInternalCAFRejectComments(reqData).then((res) => {
      if (res.data.portalErrorCode !== 200) {
        //NotificationManager.error("Error occured");
      } else {
        this.setState({
          rejectionCmntHistory: res.data.result,
        });
      }
    });
  };

  modalClose = () => {
    this.setState({
      modal: false,
    });
  };

  handleYes = () => {
    let userName;
    let userEmail;
    const token = JSON.parse(localStorage.getItem("authToken"));
    if (token) {
      const decodedDetails = jwt_decode(token);
      userName = decodedDetails.name
      userEmail = decodedDetails.preferred_username

    }
    let reqData = {
      id: this.state.selectedRow.id,
      userId: this.state.selectedRow.userId,
      rejectedByUsername: userName,
      rejectedByUserEmail: userEmail,
      userName: this.state.selectedRow.userName,
      userEmail: this.state.selectedRow.userEmail,
      rejectCmnt: this.state.rejectCmnt,

    };
    this.props.setUKInternalCAFReject(reqData).then((res) => {
      if (res.data.portalErrorCode !== 200) {
        NotificationManager.error("Error occured");
      } else {
        NotificationManager.success("Form is rejected");
        this.getAllClientFormList();
      }
    });

    this.modalClose();
  };
  handleNo = () => {
    this.modalClose();
  };
  rejectionHistory = (row) => {
    this.modalOpen(row);
  }

  handleDownload = (row) => {
    this.props
      .fileDownload({
        fileName: row.pascalCSVFileName,
        office: row.office,
        userName: row.userName,
        userEmail: row.userEmail,
        companyName: row.clientName,
        companyId: row.clientId,
      })
      .then((res) => {
        var blob = new Blob([res.data]);
        let file = `${row.pascalCSVFileName}`;
        saveAs(blob, file);
      });
  };

  statusFilter = () => {
    if (selectedStatus.length !== 0) {
      let filteredData = [];
      this.state.originalData.filter((clientForm) => {
        return selectedStatus.forEach((sta) => {
          if (selectedOffices.length > 0) {
            selectedOffices.forEach((off) => {
              if (
                clientForm.office === off.name &&
                clientForm.status === sta.name
              ) {
                return filteredData.push(clientForm);
              }
            });
          } else {
            if (clientForm.status === sta.name) {
              return filteredData.push(clientForm);
            }
          }
        });
      });
      const ids = filteredData.map((o) => o.id);
      const filtered = filteredData.filter(
        ({ id }, index) => !ids.includes(id, index + 1)
      );
      if (filtered.length !== 0) {
        this.setState({ data: filtered });
      }

    } else if (selectedOffices.length > 0 && selectedStatus.length === 0) {
      let filteredData = [];
      this.state.originalData.filter((clientForm) => {
        return selectedOffices.forEach((off) => {
          if (clientForm.office === off.name) {
            return filteredData.push(clientForm);
          }
          const ids = filteredData.map((o) => o.id);
          const filtered = filteredData.filter(
            ({ id }, index) => !ids.includes(id, index + 1)
          );
          if (filtered.length !== 0) {
            this.setState({ data: filtered });
          }
        });
      });
    } else {
      this.setState({ data: this.state.originalData });
    }
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData = this.state.originalData.filter((value) => {
      return (
        value.formName?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.clientName?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.office?.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ data: filteredData });
  };

  OfficeFilter = () => {
    if (selectedOffices.length !== 0) {
      let filteredData = [];
      this.state.originalData.filter((clientForm) => {
        return selectedOffices.forEach((off) => {
          if (selectedStatus.length > 0) {
            selectedStatus.forEach((sta) => {
              if (
                clientForm.office === off.name &&
                clientForm.status === sta.name
              ) {
                return filteredData.push(clientForm);
              }
            });
          } else {
            if (clientForm.office === off.name) {
              return filteredData.push(clientForm);
            }
          }
        });
      });
      const ids = filteredData.map((o) => o.id);
      const filtered = filteredData.filter(
        ({ id }, index) => !ids.includes(id, index + 1)
      );
      if (filtered.length !== 0) {
        this.setState({ data: filtered });
      }
    } else if (selectedStatus.length > 0 && selectedOffices.length === 0) {
      let filteredData = [];
      this.state.originalData.filter((clientForm) => {
        return selectedStatus.forEach((sta) => {
          if (clientForm.status === sta.name) {
            return filteredData.push(clientForm);
          }
          const ids = filteredData.map((o) => o.id);
          const filtered = filteredData.filter(
            ({ id }, index) => !ids.includes(id, index + 1)
          );
          if (filtered.length !== 0) {
            this.setState({ data: filtered });
          }
        });
      });
    } else {
      this.setState({ data: this.state.originalData });
    }
  };

  handleSearch = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };
  handleClick(e, type) {
    if (type === "office") {
      let clickedValue = e.target.value;
      let clickedChecked = e.target.checked;
      const form = document.querySelector("#checkboxes");
      const checkboxs = form.querySelectorAll("input[type=checkbox]");
      if (clickedValue === "Clear All" && clickedChecked) {
        selectedOffices = [];
        checkboxs.forEach((element) => {
          if (element.checked === true && element.name !== "Clear All") {
            element.checked = false;
          } else if (
            element.checked === false &&
            element.name !== "Clear All"
          ) {
            element.checked = false;
          } else {
            element.checked = true;
          }
        });
      } else if (clickedValue === "Clear All" && !clickedChecked) {
        checkboxs.forEach((element) => {
          if (element.checked === true && element.name === "Clear All") {
            element.checked = false;
          }
        });
      } else if (clickedValue !== "Clear All" && clickedChecked) {
        selectedOffices.push({ name: clickedValue });
        checkboxs.forEach((element) => {
          if (element.name === "Clear All") {
            element.checked = false;
          }
        });
      } else if (clickedValue !== "Clear All" && !clickedChecked) {
        selectedOffices.splice(
          selectedOffices.findIndex((v) => v.name === clickedValue),
          1
        );
      }
      this.OfficeFilter();
    }
    if (type === "status") {
      let clickedValue = e.target.value;
      let clickedChecked = e.target.checked;
      const form = document.querySelector(`#checkboxesstatus`);

      const checkboxes = form.querySelectorAll("input[type=checkbox]");
      if (clickedValue === "Clear All" && clickedChecked) {
        selectedStatus = [];
        checkboxes.forEach((element) => {
          if (element.checked === true && element.name !== "Clear All") {
            element.checked = false;
          } else if (
            element.checked === false &&
            element.name !== "Clear All"
          ) {
            element.checked = false;
          } else {
            element.checked = true;
          }
        });
      } else if (clickedValue === "Clear All" && !clickedChecked) {
        checkboxes.forEach((element) => {
          if (element.checked === true && element.name === "Clear All") {
            element.checked = false;
          }
        });
      } else if (clickedValue !== "Clear All" && clickedChecked) {
        selectedStatus.push({ name: clickedValue });
        checkboxes.forEach((element) => {
          if (element.name === "Clear All") {
            element.checked = false;
          }
        });
      } else if (clickedValue !== "Clear All" && !clickedChecked) {
        selectedStatus.splice(
          selectedStatus.findIndex((v) => v.name === clickedValue),
          1
        );
      }
      this.statusFilter();
    }
  }
  handleChangeRejectCmnt = (event) => {
    if (event.target.value) {
      this.setState({
        hasCmnt: false,
      });
    }
    else {
      this.setState({
        hasCmnt: true,
      });
    }
    this.setState({
      rejectCmnt: event.target.value,
    });
  };
  subHeaderFilterComponent = (type) => {
    const options = [];
    if (type === "office") {
      if (allOffices !== undefined) {
        allOffices.forEach((element) => {
          options.push(element.office);
        });
      }
      options.push("Clear All");
      const newOptions = this.removeDuplicates(options);
      return (
        <div id="checkboxes">
          {newOptions.map((option) => {
            return (
              <label>
                <input
                  onClick={(e) => this.handleClick(e, type)}
                  type="checkbox"
                  name={option}
                  key={option}
                  value={option}
                />
                <div id="filterName">{option}</div>
              </label>
            );
          })}
        </div>
      );
    } else if (type === "status") {
      options.push("NotStarted");
      options.push("InProgress");
      options.push("Verified");
      options.push("VerifiedSigned");
      options.push("Rejected");

      options.push("Clear All");
      return (
        <div id="checkboxesstatus">
          {options.map((option) => {
            return (
              <label>
                <input
                  onClick={(e) => this.handleClick(e, type)}
                  type="checkbox"
                  name={option}
                  key={option}
                  value={option}
                />
                <div id="filterName">
                  {option === "InProgress"
                    ? "In Progress"
                    : option === "NotStarted"
                      ? "Not Started"
                      : option === "Verified"
                        ? "Verified"
                        : option === "VerifiedSigned"
                          ? "Verified & Signed"
                          : option === "Rejected"
                            ? "Rejected"
                            : option}
                </div>
              </label>
            );
          })}
        </div>
      );
    }
  };


  render() {
    const { columns, data, searchInput, columnsRejectionHistory, rejectionCmntHistory } = this.state;
    return (
      <>
        <div className="dashboard">
          <div className="userlist">
            <div className="eventHeader pt-3 pb-3">
              <Container fluid>
                <Row>
                  <Col sm={6} className="text-left d-flex">
                    <h2 className="mb-0  mt-2 pagetitle">Client Form List</h2>
                  </Col>
                  {this.state.userType === "Curacao" && (
                    <Col sm={6} className="text-right add-button">
                      <Link to="/curacao/addClientForm" className="action-btn big">
                        Add Client Form
                      </Link>
                    </Col>
                  )}
                </Row>
              </Container>
            </div>
            {this.state.msg ? (
              <Row className="pt-12">
                <Col lg={12}>{this.state.msg}</Col>
              </Row>
            ) : (
              <Container fluid>
                <Row className="pt-4">
                  <Col lg={12} className="position-relative">
                    <div className="datatableSearch">
                      <input
                        type="text"
                        name="searchInput"
                        value={searchInput || ""}
                        onChange={this.handleSearch}
                        placeholder="Search"
                      />
                      <i className="fa fa-search search-bar-icon"></i>
                    </div>
                  </Col>
                </Row>
                {/* className="noDataTableSelection" */}

                <Row>
                  <Col lg={12} className="pt-4">
                    <DataTable
                      className="datatable"
                      columns={columns}
                      sortFunction={customSort}
                      data={data}
                      pagination
                    />
                  </Col>
                </Row>
              </Container>
            )}

            <Modal
              customSize="55%"
              show={this.state.modal}
              handleClose={(e) => this.modalClose(e)}
            >

              <DataTable
                className="datatable"
                columns={columnsRejectionHistory}
                data={rejectionCmntHistory}
                onSelectedRowsChange={this.handleRowSelection}
                pagination
              />
              <br></br>
              {this.state.selectedRow.status !== "Rejected" && (
                <div>
                  <div>
                    <h4 className="mb-4">Are you sure you want to reject?</h4>
                    <textarea
                      type="text"
                      name="rejectCmnt"
                      className="form-control"
                      onChange={(e) => this.handleChangeRejectCmnt(e)}
                      value={this.state.rejectCmnt}
                      autoComplete="off"
                      id="rejectCmnt"
                    />
                  </div>
                  <br></br>
                  <div className="form-group mb-0">
                    <button
                      onClick={(e) => this.handleYes(e)}
                      type="button"
                      className="btn btn-success mr-2"
                      disabled={this.state.hasCmnt}
                    >
                      Yes
                    </button>
                    <button
                      onClick={(e) => this.handleNo(e)}
                      type="button"
                      className="btn btn-secondary mr-2 active"
                    >
                      No
                    </button>
                  </div>
                </div>
              )}

            </Modal>
            {this.state.isLoading && <div className="loader"></div>}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getClientFormList: async () => dispatch(await getClientFormList()),
  fileDownload: async (data) => dispatch(await fileDownload(data)),
  GetClientAcceptance: async (data) =>
    dispatch(await GetClientAcceptance(data)),
  getUKInternalCAFDetails: async (data) =>
    dispatch(await getUKInternalCAFDetails(data)),
  getUKInternalCAFRejectComments: async (data) =>
    dispatch(await getUKInternalCAFRejectComments(data)),
  setUKInternalCAFReject: async (data) =>
    dispatch(await setUKInternalCAFReject(data)),
  getCuracaoInternalCAFDetails: async (data) =>
    dispatch(await getCuracaoInternalCAFDetails(data)),
  getMaltaInternalCAFDetails: async (data) =>
    dispatch(await getMaltaInternalCAFDetails(data)),
  getLuxembourgInternalCAFDetails: async (data) =>
    dispatch(await getLuxembourgInternalCAFDetails(data)),
  downloadInternalSignedFile: async (data) =>
    dispatch(await downloadInternalSignedFile(data)),
  setInternalChapterErrors: (data) => dispatch(setInternalChapterError(data)),
  setUKInternalChapterErrors: (data) =>
    dispatch(setUKInternalChapterError(data)),
  setAllErrorsToNull: () => dispatch(setAllErrorsToNull()),
  setCuracaoInternalChapterErrors: () =>
    dispatch(setCuracaoInternalChapterError()),
  setMaltaInternalChapterError: () => dispatch(setMaltaInternalChapterError()),
  setLuxembourgInternalChapterError: () =>
    dispatch(setLuxembourgInternalChapterError()),
});

export default connect(null, mapDispatchToProps)(ClientFormList);
