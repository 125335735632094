export const manageLandingPage = (office, from, activeLevel) => {
  switch (office) {
    case "United Kingdom":
      return "/client-information";
    case "Netherlands":
      if (from === "edit" && activeLevel === 0) {
        return "/generic-form";
      }
      if (from === "edit" && activeLevel === 1) {
        return "/client-information";
      }
      if (from === "edit" && activeLevel === 2) {
        return "/ubo-status-list";
      } else {
        return "/generic-form";
      }
    case "Curcao":
      if (from === "edit" && activeLevel === 0) {
        return "/generic-form";
      }
      if (from === "edit" && activeLevel === 1) {
        return "/client-information";
      }
      if (from === "edit" && activeLevel === 2) {
        return "/ubo-status-list";
      } else {
        return "/generic-form";
      }
    case "Curacao":
      if (from === "edit" && activeLevel === 0) {
        return "/generic-form";
      }
      if (from === "edit" && activeLevel === 1) {
        return "/client-information";
      }
      if (from === "edit" && activeLevel === 2) {
        return "/ubo-status-list";
      } else {
        return "/generic-form";
      }
    case "Malta":
      if (from === "edit" && activeLevel === 0) {
        return "/generic-form";
      }
      if (from === "edit" && activeLevel === 1) {
        return "/client-information";
      }
      if (from === "edit" && activeLevel === 2) {
        return "/ubo-status-list";
      } else {
        return "/generic-form";
      }
    case "Luxembourg":
      if (from === "edit" && activeLevel === 0) {
        return "/generic-form";
      }
      if (from === "edit" && activeLevel === 1) {
        return "/client-information";
      }
      if (from === "edit" && activeLevel === 2) {
        return "/ubo-status-list";
      } else {
        return "/generic-form";
      }
    default:
      return "/generic-form";
  }
};
