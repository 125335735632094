import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import {
  userApprovalList,
  approveRejectUser,
} from "../../../redux/actions/userActions";
import { NotificationManager } from "react-notifications";
import Modal from "../shared/Modal";
import jwt_decode from "jwt-decode";
import orderBy from "lodash/orderBy";
const customSort = (rows, field, direction) => {
  const handleField = (row) => {
    if (row[field]) {
      return row[field].toLowerCase();
    }
    return row[field];
  };
  return orderBy(rows, handleField, direction);
};
class userApprovals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: "First Name",
          selector: "firstName",
          sortable: true,
        },
        {
          name: "Last Name",
          selector: "lastName",
          sortable: true,
        },
        {
          name: "User Role",
          selector: "role",
          sortable: true,
        },
        {
          name: "Email ID",
          selector: "email",
          sortable: true,
        },
        {
          name: "Mobile Number",
          selector: "phoneNo",
          sortable: true,
        },
      ],
      data: [],
      originalData: [],
      selectedRows: [],
      error: "",
      isLoading: false,
      modal: false,
      reason: "",
      searchInput: "",
      toggleCleared: false,
      tokenData: null,
      errors: {},
    };
  }

  getUserApprovalList = () => {
    let token = JSON.parse(localStorage.getItem("authToken"));
    if (token) {
      var decoded = jwt_decode(token);
      var data = {
        name: decoded.name,
      };
      this.setState({ isLoading: true, tokenData: data });
      this.props.userApprovalList(data).then((res) => {
        this.setState({ isLoading: false });
        if (res.data.portalErrorCode === 200) {
          this.setState({
            originalData: res.data.result,
            data: res.data.result,
          });
        }
      });
    }
  };

  componentDidMount = () => {
    this.getUserApprovalList();
  };

  validateActionOnButtons = () => {
    let isValid = true;
    if (this.state.selectedRows.length <= 0) {
      isValid = false;
      this.setState({ error: "Please select user for approval/rejection" });
    }
    return isValid;
  };

  handleApprove = async () => {
    const { selectedRows } = this.state;
    this.setState({ isLoading: true });
    if (this.validateActionOnButtons()) {
      let array = [];
      selectedRows.forEach((user) => {
        let status;
        if (user.portalStatus === "DeleteUser") {
          status = 3;
        } else if (
          user.portalStatus === "UpdateUser" ||
          user.portalStatus === "AddUser"
        ) {
          status = 1;
        }
        let obj = {};
        obj["Id"] = user.id;
        obj["Remarks"] = "";
        obj["Status"] = status;
        array.push(obj);
      });

      await this.props.approveRejectUser(array).then((res) => {
        this.setState({ isLoading: false });
        let successfulCount = 0,
          failedCount = 0;
        if (res.data?.length > 0) {
          res.data.map((record) => {
            if (record.portalErrorCode === 200) {
              successfulCount++;
            } else {
              failedCount++;
            }
          });

          let successMsg = successfulCount === 1 ? `record` : `records`;
          successfulCount > 0 &&
            NotificationManager.success(
              `${successfulCount} ${successMsg} ${`approved successfully`}`
            );

          let failMsg = failedCount === 1 ? `record.` : `records.`;
          failedCount > 0 &&
            NotificationManager.error(
              `Error while approving ${failedCount} ${failMsg}`
            );
          this.setState({
            selectedRows: [],
            toggleCleared: !this.state.toggleCleared,
          });
          let token = JSON.parse(localStorage.getItem("authToken"));

          var decoded = jwt_decode(token);
          var data = {
            name: decoded.name,
          };
          this.props.userApprovalList(data).then((res) => {
            this.setState({ isLoading: false });
            if (res.data.portalErrorCode === 200) {
              this.setState({
                originalData: res.data.result,
                data: res.data.result,
              });
            }
          });
        } else {
          NotificationManager.error("Error while approving records");
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleSubmit = async () => {
    let errors = {};
    if (this.state.reason) {
      this.handleCancel();
      const { selectedRows } = this.state;
      this.setState({ isLoading: true });
      if (this.validateActionOnButtons()) {
        let array = [];

        selectedRows.forEach((user) => {
          let status;
          if (user.portalStatus === "DeleteUser") {
            status = 4;
          } else if (user.portalStatus === "UpdateUser") {
            status = 7;
          } else if (user.portalStatus === "AddUser") {
            status = 2;
          }

          let obj = {};
          obj["Id"] = user.id;
          obj["Remarks"] = this.state.reason;
          obj["Status"] = status;
          array.push(obj);
        });

        await this.props.approveRejectUser(array).then((res) => {
          this.setState({ isLoading: false });
          let successfulCount = 0,
            failedCount = 0;
          if (res.data?.length > 0) {
            res.data.map((record) => {
              if (record.portalErrorCode === 200) {
                successfulCount++;
              } else {
                failedCount++;
              }
            });
            let successMsg = successfulCount === 1 ? `record` : `records`;
            successfulCount > 0 &&
              NotificationManager.success(
                `${successfulCount} ${successMsg} ${`rejected successfully`}`
              );

            let failMsg = failedCount === 1 ? `record.` : `records.`;
            failedCount > 0 &&
              NotificationManager.error(
                `Error while rejecting ${failedCount} ${failMsg}`
              );
            this.setState({
              selectedRows: [],
              toggleCleared: !this.state.toggleCleared,
            });
            this.getUserApprovalList();
          } else {
            NotificationManager.error("Error while rejecting records");
          }
        });
      } else {
        this.setState({ isLoading: false });
      }
    } else {
      errors["othermessage"] = "Please enter a remark";
    }
    this.setState({ errors });
  };

  //handle change on all inputs
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSearch = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData = this.state.originalData.filter((value) => {
      return (
        value.firstName?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.lastName?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.email?.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.phoneNo
          ?.toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      );
    });
    this.setState({ data: filteredData });
  };

  modalOpen = () => {
    if (this.validateActionOnButtons()) {
      this.setState({ modal: true });
    }
  };

  handleRowSelection = (selectedRows) => {
    this.setState({ selectedRows: selectedRows.selectedRows });
  };

  handleCancel = () => {
    this.setState({ modal: false });
  };
  render() {
    const { error, searchInput, toggleCleared } = this.state;
    return (
      <div className="dashboard">
        <div className="userlist">
          <div className="eventHeader pt-3 pb-3">
            <Container fluid>
              <Row>
                <Col sm={6} className="text-left d-flex">
                  <h2 className="mb-0  mt-2 pagetitle">User Approvals</h2>
                </Col>
              </Row>
            </Container>
          </div>

          <Container fluid>
            <Row className="pt-3 pb-2">
              <Col lg={6} className="pb-2">
                {this.state.data?.length > 0 && (
                  <>
                    <button
                      className="action-btn approve"
                      onClick={this.handleApprove}
                    >
                      Approve
                    </button>{" "}
                    <button
                      className="action-btn delete"
                      onClick={this.modalOpen}
                    >
                      Reject
                    </button>
                    {error && <div className="errorMsg">{error} </div>}
                  </>
                )}
              </Col>
              <Col lg={6} className="position-relative">
                <div className="datatableSearch">
                  <input
                    type="text"
                    name="searchInput"
                    value={searchInput || ""}
                    onChange={this.handleSearch}
                    placeholder="Search"
                  />
                  <i className="fa fa-search search-bar-icon"></i>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <DataTable
                  className="datatable"
                  columns={this.state.columns}
                  data={this.state.data}
                  onSelectedRowsChange={this.handleRowSelection}
                  sortFunction={customSort}
                  selectableRows
                  pagination
                  clearSelectedRows={toggleCleared}
                />
              </Col>
            </Row>
          </Container>
          <Modal
            show={this.state.modal}
            handleClose={(e) => this.handleCancel(e)}
          >
            <h4 className="heading-midium mb-3 text-left">Remarks</h4>
            <div className="errorMsg">{this.state.errors.othermessage}</div>
            <div className="form-group mb-0">
              <textarea
                onChange={this.handleChange}
                className="remarks-input"
                name="reason"
                value={this.state.reason}
                rows="5"
                style={{ width: "100%" }}
              ></textarea>
              <div
                class="approval-list-poppup button-group pt-2"
                style={{ float: "left" }}
              >
                <button
                  onClick={(e) => this.handleSubmit(e)}
                  type="button"
                  className="action-btn big noicon"
                >
                  Submit
                </button>
                <button
                  onClick={(e) => this.handleCancel(e)}
                  type="button"
                  className="action-btn big reject noicon ml-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userApprovalList: async (data) => dispatch(await userApprovalList(data)),
    approveRejectUser: async (data) => dispatch(await approveRejectUser(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(userApprovals);
