import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../../elements/CheckBox";
import DropDown from "../../../../../elements/DropDown";
import countryList from "react-select-country-list";
import FileUpload from "../../../../../elements/FileUpload";
import NumberInput from "../../../../../elements/NumberInput";
import TextBox from "../../../../../elements/TextBox";
import { companyTypeMalta, fileAcceptType } from "./Constants";
import Rationale from "./Rationale";
import DocumentsObjectCompany from "./DocumentsObjectCompany";
import BankAccountOverview from "./BankAccountOverview";
import { DirectorNamesArray } from "../../../../../JSONs/ClientInformationForm/Chapter4";
import SourceOfFunds from "./SourceOfFunds";
import BearerShares from "./BearerShares";
import NatureOfObjectCompany from "./NatureOfObjectCompany";
import MultiSelectDropdown from "../../../../../elements/MultiSelectDropdown";
import BusinessIntroduction from "./BusinessIntroduction";
import ServiceProvided from "./ServiceProvided";
import EntityTrustDetails from "./EntityTrustDetails";
import MaltaDirectors from "./MaltaDirectors";
import { checkIsCommon } from "../CommonFunctions";
import OCSecretary from "./OCSecretary";
import DateTimePicker from "../../../../../elements/DateTimePicker";
import ContactPerson from "./ContactPerson";
import ShareCapital from "./ShareCapital";
import Situation from "./Situation";
import Documentation from "./Documentation";
import ServicesProvidedMalta from "./ServicesProvidedMalta";

const chapter = "chapter4";

class Chapter4 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      noOfDirectors,
      isAcctngYrChng,
      typOfCompMal,
      isObjCompBranches,
      arethereExistingUPCDir,
      errorMsg,
      isObjCompExists,
      isCompIncorporated
    } = this.props;

    const eMsg = JSON.parse(errorMsg);
    let directorArray = [];
    for (let i = 0; i < noOfDirectors; i++) {
      directorArray.push(
        <>
          {/* this will work for Malta as well as Luxembourg */}
          {this.props.form === "Malta" || this.props.form === "Luxembourg" ? (
            <MaltaDirectors
              chapter={chapter}
              reducer={this.props.reducer}
              index={i}
              errors={errorMsg}
              form={this.props.form}
            />
          ) : (
            <div className="mb-4 col-lg-12">
              <fieldset>
                <legend>Director {i + 1}</legend>
                {this.props.form === "UK" && (
                  <div className="row">
                    <div className="mb-2 col-lg-6 col-xl-6">

                      <DropDown
                        label="Type of Director"
                        name="typeOfDirector"
                        options={[
                          { value: 'United', label: 'United' },
                          { value: 'External', label: 'External' },
                        ]}
                        id={`typeOfDirector[${i}]`}
                        chapter={chapter}
                        baseName="objCompDirectorNames"
                        index={i}
                        reducer={this.props.reducer}
                        validationType="Text"
                        isRequired={this.props.form === "UK" ? true : false}
                      />
                      {eMsg &&
                        eMsg.objCompDirectorNames &&
                        eMsg.objCompDirectorNames[i] &&
                        eMsg.objCompDirectorNames[i]?.typeOfDirector?.length > 0 && (
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {eMsg.objCompDirectorNames[i]?.typeOfDirector}
                          </span>
                        )}

                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="mb-3 col-lg-4">
                    <TextBox
                      label={`Name Director of Object Company`}
                      name="dirName"
                      id={`dirName[${i}]`}
                      placeholder="enter your text"
                      chapter={chapter}
                      baseName="objCompDirectorNames"
                      index={i}
                      reducer={this.props.reducer}
                      validationType="Text"
                      isRequired={this.props.form === "UK" ? true : false}
                    />
                    {eMsg &&
                      eMsg.objCompDirectorNames &&
                      eMsg.objCompDirectorNames[i] &&
                      eMsg.objCompDirectorNames[i]?.dirName?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {eMsg.objCompDirectorNames[i]?.dirName}
                        </span>
                      )}
                  </div>

                  <div className="mb-3 col-lg-4">
                    <DropDown
                      label={`Nationality of Director`}
                      name="dirNationality"
                      id={`dirNationality[${i}]`}
                      options={countryList().getData()}
                      chapter={chapter}
                      baseName="objCompDirectorNames"
                      index={i}
                      reducer={this.props.reducer}
                      isRequired={this.props.form === "UK" ? true : false}
                    />
                    {eMsg &&
                      eMsg?.objCompDirectorNames &&
                      eMsg.objCompDirectorNames[i] &&
                      eMsg.objCompDirectorNames[i]?.dirNationality?.length >
                      0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {eMsg.objCompDirectorNames[i]?.dirNationality}
                        </span>
                      )}
                  </div>
                </div>
                <div className="row">
                  {this.props.form === "UK" && (
                    <>
                      <div className="mb-3 col-lg-4">
                        <FileUpload
                          label={`Certified Passport Copy`}
                          name="dirCertifiedPass"
                          id={`dirCertifiedPass[${i}]`}
                          acceptType={fileAcceptType}
                          chapter={chapter}
                          baseName="objCompDirectorNames"
                          index={i}
                          reducer={this.props.reducer}
                         // isRequired={this.props.form === "UK" ? true : false}
                        />
                        {eMsg &&
                          eMsg?.objCompDirectorNames &&
                          eMsg.objCompDirectorNames[i] &&
                          eMsg.objCompDirectorNames[i]?.dirCertifiedPass
                            ?.length > 0 && (
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {eMsg.objCompDirectorNames[i]?.dirCertifiedPass}
                            </span>
                          )}
                      </div>
                      <div className="mb-3 col-lg-4">
                        <FileUpload
                          label={`Proof of Address`}
                          name="dirProofAddress"
                          id={`dirProofAddress[${i}]`}
                          acceptType={fileAcceptType}
                          chapter={chapter}
                          baseName="objCompDirectorNames"
                          index={i}
                          reducer={this.props.reducer}
                          //isRequired={this.props.form === "UK" ? true : false}
                        />
                        {eMsg &&
                          eMsg?.objCompDirectorNames &&
                          eMsg.objCompDirectorNames[i] &&
                          eMsg.objCompDirectorNames[i]?.dirProofAddress
                            ?.length > 0 && (
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {eMsg.objCompDirectorNames[i]?.dirProofAddress}
                            </span>
                          )}
                      </div>
                    </>
                  )}
                </div>
              </fieldset>
            </div>
          )}
        </>
      );
    }
    return (

      <div className="generic-form client-investigation mt-4">

        <div>
          <h2 className="chapter-title mb-3 mt-2">
            {this.props.form === "Malta"
              ? "Target Company Information - Object of the Target Company"
              : this.props.form === "NL" ?"4- Client Investigation - Object Company":"Client Investigation - Object Company"}
          </h2>

          <Row className="pt-3 pb-2">
            <div className="mb-3 col-lg-4">
              <CheckBox
                label={
                  this.props.form === "Malta"
                    ? "Does the Target Company already exist?"
                    : "Does the Object Company already exist"
                }
                options={["Yes", "No"]}
                name="isObjCompExists"
                id="isObjCompExists"
                chapter={chapter}
                reducer={this.props.reducer}
                isRequired={this.props.form === "UK" || this.props.form === "NL" ? true : false}
              />
              {eMsg &&
                eMsg?.isObjCompExists &&
                eMsg.isObjCompExists?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.isObjCompExists}
                  </span>
                )}
            </div>

            <div className="mb-3 col-lg-8">
              <Row>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label={
                      this.props.form === "Malta"
                        ? "Name of Target Company"
                        : "Name of Object Company"
                    }
                    name="objCompName"
                    id="objCompName"
                    placeholder="enter your text"
                    chapter={this.props.form !== "NL" && "chapter4"}
                    reducer={
                      checkIsCommon(this.props.form)
                        ? "commonFields"
                        : this.props.reducer
                    }
                    isCommon={checkIsCommon(this.props.form)}
                    validationType="Text"
                    isRequired={(this.props.form === "UK" && isObjCompExists == "Yes") || (this.props.form == "NL") ? true : false}
                  />
                  {eMsg && eMsg?.objCompName && eMsg.objCompName?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg.objCompName}
                    </span>
                  )}
                </div>

                {isObjCompExists === "Yes" && (
                  <div className="mb-3 col-lg-6">
                    <TextBox
                      label={this.props.form === "UK" ? "Jurisdiction" : "Commercial Register"}
                      name="commercialRegister"
                      id="commercialRegister"
                      placeholder="enter your text"
                      chapter={chapter}
                      reducer={this.props.reducer}
                      validationType="Text"
                      isRequired={this.props.form === "UK" || this.props.form === "NL" ? true : false}
                    />
                    {eMsg &&
                      eMsg?.commercialRegister &&
                      eMsg.commercialRegister?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {eMsg.commercialRegister}
                        </span>
                      )}
                  </div>
                )}

                {(this.props.form === "Malta" ||
                  this.props.form === "Luxembourg") && (
                    <>
                      <div className="mb-3 col-lg-6">
                        <TextBox
                          label={`Registered office in ${this.props.form}`}
                          name="regOffMal"
                          id="regOffMal"
                          placeholder="enter your text"
                          chapter={chapter}
                          reducer={this.props.reducer}
                          validationType="Text"
                        />
                        {eMsg && eMsg?.regOffMal && eMsg.regOffMal?.length > 0 && (
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {eMsg.regOffMal}
                          </span>
                        )}
                      </div>
                      <div className="mb-3 col-lg-6">
                        <DropDown
                          label={`Type of company`}
                          name="typOfCompMal"
                          id={`typOfCompMal`}
                          options={companyTypeMalta}
                          chapter={chapter}
                          reducer={this.props.reducer}
                        />
                        {eMsg &&
                          eMsg?.typOfCompMal &&
                          eMsg.typOfCompMal?.length > 0 && (
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {eMsg.typOfCompMal}
                            </span>
                          )}
                      </div>
                      {typOfCompMal === "Other" && (
                        <div className="mb-3 col-lg-6">
                          <TextBox
                            label="Please, describe"
                            name="typeCmpOthrSpcfy"
                            id="typeCmpOthrSpcfy"
                            placeholder="enter your text"
                            chapter={chapter}
                            reducer={this.props.reducer}
                            validationType="Text"
                          />
                          {eMsg &&
                            eMsg?.typeCmpOthrSpcfy &&
                            eMsg.typeCmpOthrSpcfy?.length > 0 && (
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {eMsg.typeCmpOthrSpcfy}
                              </span>
                            )}
                        </div>
                      )}
                    </>
                  )}
              </Row>
            </div>
          </Row>
          {this.props.form === "NL" && isCompIncorporated === "Yes" &&(
            <>
          <Row >
            <div className="mb-3 col-lg-6 col-xl-4">
              <CheckBox
                label="Are there existing Director(s)?"
                options={["Yes", "No"]}
                name="arethereExistingUPCDir"
                id="arethereExistingUPCDir"
                chapter={chapter}
                reducer={this.props.reducer}
                isRequired={this.props.form === "UK" ? true : false}

              />

            </div>
          </Row>
          {arethereExistingUPCDir==="Yes" && (
            <Row className="pt-3 pb-2">
              <div className="mb-3 col-lg-4">
                <NumberInput
                  label={
                    this.props.form === "Malta"
                      ? "Director/s information of Target Company"
                      : "How many directors would you like to add?"
                  }
                  name="noOfDirectors"
                  chapter={chapter}
                  referral={["objCompDirectorNames"]}
                  pushedObject={[DirectorNamesArray]}
                  maxLimit={5}
                  reducer={this.props.reducer}
                />
              </div>
              {directorArray}
            </Row>
          )}
          </>
          )}
           {this.props.form !== "NL" &&(
             <>
          <Row >
            <div className="mb-3 col-lg-6 col-xl-4">
              <CheckBox
                label="Are there existing Director(s)?"
                options={["Yes", "No"]}
                name="arethereExistingUPCDir"
                id="arethereExistingUPCDir"
                chapter={chapter}
                reducer={this.props.reducer}
                isRequired={this.props.form === "UK" ? true : false}

              />

            </div>
          </Row>
          {arethereExistingUPCDir==="Yes" && (
            <Row className="pt-3 pb-2">
              <div className="mb-3 col-lg-4">
                <NumberInput
                  label={
                    this.props.form === "Malta"
                      ? "Director/s information of Target Company"
                      : "How many directors would you like to add?"
                  }
                  name="noOfDirectors"
                  chapter={chapter}
                  referral={["objCompDirectorNames"]}
                  pushedObject={[DirectorNamesArray]}
                  maxLimit={5}
                  reducer={this.props.reducer}
                />
              </div>
              {directorArray}
            </Row>
          )}
          </>
          )}
          
          {this.props.form === "Malta" && (
            <>
              <div className="html2pdf__page-break"></div>
              <OCSecretary
                reducer={this.props.reducer}
                chapter={chapter}
                errorMsg={errorMsg}
                form={this.props.form}
              />
              <Row className="pt-0 pb-2">
                <div className="mb-3 col-lg-4">
                  <CheckBox
                    label="Accounting year ends at 31st December. Do you want to change?"
                    options={["Yes", "No"]}
                    name="isAcctngYrChng"
                    id="isAcctngYrChng"
                    chapter={chapter}
                    reducer={this.props.reducer}
                  />
                  {eMsg &&
                    eMsg?.isAcctngYrChng &&
                    eMsg.isAcctngYrChng?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg.isAcctngYrChng}
                      </span>
                    )}
                </div>
                {isAcctngYrChng === "Yes" && (
                  <>
                    <div className="mb-3 col-lg-8">
                      <Row>
                        <div className="mb-3 col-lg-6" id={`acctngDtChng`}>
                          <DateTimePicker
                            label="Select different date"
                            name="acctngDtChng"
                            chapter={chapter}
                            reducer={this.props.reducer}
                          />
                          {eMsg &&
                            eMsg?.acctngDtChng &&
                            eMsg.acctngDtChng?.length > 0 && (
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {eMsg.acctngDtChng}
                              </span>
                            )}
                        </div>
                        <div className="mb-3 col-lg-6">
                          <FileUpload
                            label="Upload anticipated annual turnover, Financial statement of past 3 year"
                            acceptType={fileAcceptType}
                            name="antcptAnnTurnFinStmts"
                            id="antcptAnnTurnFinStmts"
                            chapter={chapter}
                            reducer={this.props.reducer}
                          />
                          {eMsg &&
                            eMsg?.antcptAnnTurnFinStmts &&
                            eMsg.antcptAnnTurnFinStmts?.length > 0 && (
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {eMsg.antcptAnnTurnFinStmts}
                              </span>
                            )}
                        </div>
                      </Row>
                    </div>
                  </>
                )}
              </Row>
              <ContactPerson
                reducer={this.props.reducer}
                chapter={chapter}
                errorMsg={errorMsg}
                form={this.props.form}
              />
            </>
          )}
          <Row className="pt-0 pb-2">
            <div className="mb-3 col-lg-4 fieldAlignment">
              <MultiSelectDropdown
                label={`${this.props.form === "Malta" ? "Target" : "Object"
                  } Company Active in country?`}
                name="objCompActiveCountry"
                id="objCompActiveCountry"
                options={countryList().getData()}
                reducer={this.props.reducer}
                chapter={chapter}
                isRequired={this.props.form === "UK" ? true : false}
              />
              {eMsg &&
                eMsg?.objCompActiveCountry &&
                eMsg.objCompActiveCountry?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.objCompActiveCountry}
                  </span>
                )}
            </div>

            <NatureOfObjectCompany
              reducer={
                checkIsCommon(this.props.form)
                  ? "commonFields"
                  : this.props.reducer
              }
              chapter={chapter}
              form={this.props.form}
              errorMsg={errorMsg}
              from="clientInformation"
            />
          </Row>
          <Row className="pt-3 pb-2">
            <div className="mb-3 col-lg-4">
              <CheckBox
                label={`Does the ${this.props.form === "Malta" ? "Target" : "Object"
                  } Company have branches?`}
                options={["Yes", "No"]}
                name="isObjCompBranches"
                id="isObjCompBranches"
                chapter={chapter}
                reducer={this.props.reducer}
                isRequired={this.props.form === "UK" ? true : false}
              />
              {eMsg &&
                eMsg?.isObjCompBranches &&
                eMsg.isObjCompBranches?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.isObjCompBranches}
                  </span>
                )}
            </div>

            {isObjCompBranches === "Yes" && (
              <div className="col-lg-4">
                <MultiSelectDropdown
                  label="In which country/countries?"
                  name="objCompBranch"
                  id="objCompBranch"
                  options={countryList().getData()}
                  reducer={this.props.reducer}
                  chapter={chapter}
                  isRequired={this.props.form === "UK" ? true : false}
                />
                {eMsg &&
                  eMsg?.objCompBranch &&
                  eMsg.objCompBranch?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg.objCompBranch}
                    </span>
                  )}
              </div>
            )}
          </Row>
          <Rationale
            reducer={
              checkIsCommon(this.props.form) ? "commonFields" : this.props.reducer
            }
            chapter={this.props.form !== "NL" && "chapter4"}
            form={this.props.form}
            errorMsg={errorMsg}
            from="external"
          />
          
          <Row className="pt-0 pb-0">
            <div className="mb-3 col-lg-6">
              <FileUpload
                label={`Please include the findings of the independent tax advisor ${this.props.form === "Malta" ? "or legal advisor." : ""
                  }`}
                acceptType={fileAcceptType}
                name="taxAdvisorFinds"
                id="taxAdvisorFinds"
                chapter={chapter}
                reducer={this.props.reducer}
              />
              {eMsg &&
                eMsg?.taxAdvisorFinds &&
                eMsg.taxAdvisorFinds?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.taxAdvisorFinds}
                  </span>
                )}
            </div>
          </Row>
         
        </div>


        {(this.props.form === "NL" || this.props.form === "Curacao") && (
          <BearerShares
            from="clientInformation"
            reducer={
              checkIsCommon(this.props.form)
                ? "commonFields"
                : this.props.reducer
            }
            chapter={chapter}
            form={this.props.form}
            errorMsg={errorMsg}
          />
        )}
        {this.props.form === "Malta" && (
          <ShareCapital
            reducer={this.props.reducer}
            chapter={chapter}
            errorMsg={errorMsg}
          />
        )}
        {this.props.form === "UK" && (
          <>
            {/* <BusinessIntroduction
              reducer={this.props.reducer}
              chapter={chapter}
              objBaseName="businessIntroduction"
              errorMsg={errorMsg}
              form={this.props.form}
            /> */}
            <ServiceProvided
              reducer={this.props.reducer}
              chapter={chapter}
              objBaseName="servicesProvided"
              errorMsg={errorMsg}
              form={this.props.form}
            />
            {this.props.form !== "UK" && (
              <EntityTrustDetails
                reducer={this.props.reducer}
                chapter={chapter}
                objBaseName="entityTrustDetails"
                errorMsg={errorMsg}
                form={this.props.form}
              />
            )}
          </>
        )}
        <DocumentsObjectCompany
          reducer={this.props.reducer}
          chapter={chapter}
          objBaseName="documentsObjComp"
          errorMsg={errorMsg}
          form={this.props.form}
        />
        {this.props.form === "Malta" && (
          <>
            <ServicesProvidedMalta
              reducer={this.props.reducer}
              chapter={chapter}
              errorMsg={errorMsg}
              form={this.props.form}
              from="External"
            />
            <Situation
              reducer={this.props.reducer}
              chapter={chapter}
              errorMsg={errorMsg}
              form={this.props.form}
            />
            <Documentation
              reducer={this.props.reducer}
              chapter={chapter}
              errorMsg={errorMsg}
              form={this.props.form}
            />
          </>
        )}
        {this.props.form === "Luxembourg" && (
          <>
            <ServicesProvidedMalta
              reducer={this.props.reducer}
              chapter={chapter}
              errorMsg={errorMsg}
              form={this.props.form}
              from="External"
            />
            <Situation
              reducer={this.props.reducer}
              chapter={chapter}
              errorMsg={errorMsg}
              form={this.props.form}
            />
          </>
        )}        
        <SourceOfFunds
          reducer={
            checkIsCommon(this.props.form) ? "commonFields" : this.props.reducer
          }
          chapter={checkIsCommon(this.props.form) ? null : "chapter4"}
          objBaseName="srcFundsObjComp"
          form={this.props.form}
          errorMsg={errorMsg}
          from="clientInformation"
        />
       {this.props.form === "NL" && isCompIncorporated === "Yes" &&(
         <BankAccountOverview
          reducer={this.props.reducer}
          objBaseName="ovrvwBnkAC"
          chapter={chapter}
          form={this.props.form}
          errorMsg={errorMsg}
        />
        )}
        {this.props.form !== "NL" &&(
          <BankAccountOverview
          reducer={this.props.reducer}
          objBaseName="ovrvwBnkAC"
          chapter={chapter}
          form={this.props.form}
          errorMsg={errorMsg}
        />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer } = ownProps;
  return {
    isObjCompExists: state[reducer]["chapter4"]["isObjCompExists"],
    arethereExistingUPCDir: state[reducer]["chapter4"]["arethereExistingUPCDir"],
    noOfDirectors: state[reducer]["chapter4"]["noOfDirectors"],
    typOfCompMal: state[reducer]["chapter4"]["typOfCompMal"],
    isAcctngYrChng: state[reducer]["chapter4"]["isAcctngYrChng"],
    isObjCompBranches: state[reducer]["chapter4"]["isObjCompBranches"],
    isInsuranceBearerShares: state.commonFields["isInsuranceBearerShares"],
    isCompIncorporated: state[reducer]["chapter1"]["isCompIncorporated"]
  };
};

export default connect(mapStateToProps, null)(Chapter4);
