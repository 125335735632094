import React, { Component } from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../../elements/TextBox";
import cc from "currency-codes";

let currencies = [];
cc.codes().map((currency) => currencies.push({ label: currency }));

const objBaseName = "situation";
class Situation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { chapter, errorMsg } = this.props;
    const errors = JSON.parse(errorMsg);
    return (
      <>
        <h4 className="sub-chapter-title mb-4">
          {this.props.form === "Malta"
            ? "Detail of Proposed Business"
            : "Situation"}
        </h4>
        <Row className="pt-3 pb-2">
          <div className="mb-3 col-lg-6">
            <TextBox
              label={
                this.props.form === "Malta"
                  ? "Brief description of the proposed business plan"
                  : "Description of client's need, objective or intensions"
              }
              name="descClientNeeds"
              id={`descClientNeeds`}
              placeholder="Enter Description"
              objBaseName={objBaseName}
              chapter={chapter}
              reducer={this.props.reducer}
              validationType="Text"
            />
            {errors &&
              errors?.descClientNeeds &&
              errors?.descClientNeeds?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.descClientNeeds}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-6">
            <TextBox
              label={`What is the benefit of the client in the proposed structure and the reason for choosing ${this.props.form}?`}
              name="benefitOfClient"
              id={`benefitOfClient`}
              placeholder="Enter Description"
              objBaseName={objBaseName}
              chapter={chapter}
              reducer={this.props.reducer}
              validationType="Text"
            />
            {errors &&
              errors?.benefitOfClient &&
              errors?.benefitOfClient?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.benefitOfClient}
                </span>
              )}
          </div>

          <div className="mb-3 col-lg-6">
            <TextBox
              label="Tax Implications"
              name="taxImplications"
              id={`taxImplications`}
              placeholder="Enter Description"
              objBaseName={objBaseName}
              chapter={chapter}
              reducer={this.props.reducer}
              validationType="Text"
            />
            {errors &&
              errors?.taxImplications &&
              errors?.taxImplications?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.taxImplications}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-6">
            <TextBox
              label="Could the business acceptance entail a conflict of interest?"
              name="busAcptncCnflctIntrst"
              id={`busAcptncCnflctIntrsts`}
              placeholder="Enter Description"
              objBaseName={objBaseName}
              chapter={chapter}
              reducer={this.props.reducer}
              validationType="Text"
            />
            {errors &&
              errors?.busAcptncCnflctIntrst &&
              errors?.busAcptncCnflctIntrst?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.busAcptncCnflctIntrst}
                </span>
              )}
          </div>
        </Row>
      </>
    );
  }
}

export default Situation;
