import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../../elements/CheckBox";
import FileUpload from "../../../../../elements/FileUpload";
import TextBox from "../../../../../elements/TextBox";
import { fileAcceptType } from "./Constants";

class EntityTrustDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { entityTrustDetails, errorMsg,form } = this.props;
    const errors = JSON.parse(errorMsg);
    return (
      <>
        <h4 className="sub-chapter-title mb-4">Entity/Trust Details</h4>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Directors/Partners"
              name="directorsPartners"
              id="directorsPartners"
              placeholder="enter your text"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {errors &&
              errors?.directorsPartners &&
              errors?.directorsPartners?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.directorsPartners}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Shareholders/Members"
              name="shareholdersMembers"
              id="shareholdersMembers"
              placeholder="enter your text"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {errors &&
              errors?.shareholdersMembers &&
              errors?.shareholdersMembers?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.shareholdersMembers}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Registered office"
              name="regOffice"
              id="regOffice"
              placeholder="enter your text"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {errors && errors?.regOffice && errors?.regOffice?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors?.regOffice}
              </span>
            )}
          </div>
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Trustee name"
              name="trusteeName"
              id="trusteeName"
              placeholder="enter your text"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {errors &&
              errors?.trusteeName &&
              errors?.trusteeName?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.trusteeName}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Trustee address"
              name="trusteeAddress"
              id="trusteeAddress"
              placeholder="enter your text"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {errors &&
              errors?.trusteeAddress &&
              errors?.trusteeAddress?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.trusteeAddress}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-6">
            <CheckBox
              label="Trustee's passport or national identity card available?"
              name="istrusPassport"
              options={["Yes", "No"]}
              id="istrusPassport"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
            />
            {errors &&
              errors?.istrusPassport &&
              errors?.istrusPassport?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.istrusPassport}
                </span>
              )}
          </div>
          {entityTrustDetails.istrusPassport === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Upload Trustee's Passport or national identity"
                acceptType={fileAcceptType}
                name="trusPassport"
                id="trusPassport"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.trusPassport &&
                errors?.trusPassport?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.trusPassport}
                  </span>
                )}
            </div>
          )}
          {entityTrustDetails.istrusPassport === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="truePassportReason"
                id="truePassportReason"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
              />
              {errors &&
                errors?.truePassportReason &&
                errors?.truePassportReason?.length > 0 && form !=="UK" &&(
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.truePassportReason}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-6">
            <CheckBox
              label="Trustee's proof of address available?"
              name="istrusProofAdd"
              options={["Yes", "No"]}
              id="istrusProofAdd"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
            />
            {errors &&
              errors?.istrusProofAdd &&
              errors?.istrusProofAdd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.istrusProofAdd}
                </span>
              )}
          </div>
          {entityTrustDetails.istrusProofAdd === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Upload Trustee's proof of address"
                info="Not older than 3 months"
                acceptType={fileAcceptType}
                name="trusProofAdd"
                id="trusProofAdd"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.trusProofAdd &&
                errors?.trusProofAdd?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.trusProofAdd}
                  </span>
                )}
            </div>
          )}
          {entityTrustDetails.istrusProofAdd === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="trusProofAddReason"
                id="trusProofAddReason"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
              />
              {errors &&
                errors?.trusProofAddReason &&
                errors?.trusProofAddReason?.length > 0 && form !=="UK" &&(
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.trusProofAddReason}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-6">
            <CheckBox
              label="Trustee's reference letter available?"
              name="istrusRefLett"
              options={["Yes", "No"]}
              id="istrusRefLett"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
            />
            {errors &&
              errors?.istrusRefLett &&
              errors?.istrusRefLett?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.istrusRefLett}
                </span>
              )}
          </div>
          {entityTrustDetails.istrusRefLett === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Upload Trustee's reference letter"
                info="by Accountant, Banker or Lawyer"
                acceptType={fileAcceptType}
                name="trusRefLett"
                id="trusRefLett"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.trusRefLett &&
                errors?.trusRefLett?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.trusRefLett}
                  </span>
                )}
            </div>
          )}
          {entityTrustDetails.istrusRefLett === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="trusRefLettReason"
                id="trusRefLettReason"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
              />
              {errors &&
                errors?.trusRefLettReason &&
                errors?.trusRefLettReason?.length > 0 && form !=="UK" &&(
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.trusRefLettReason}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Protector details"
              name="protectorDetails"
              id="protectorDetails"
              placeholder="enter your text"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {errors &&
              errors?.protectorDetails &&
              errors?.protectorDetails?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.protectorDetails}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-6">
            <CheckBox
              label="Protector's passport or national identity card available?"
              name="isprotecPassport"
              options={["Yes", "No"]}
              id="isprotecPassport"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
            />
            {errors &&
              errors?.isprotecPassport &&
              errors?.isprotecPassport?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.isprotecPassport}
                </span>
              )}
          </div>
          {entityTrustDetails.isprotecPassport === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Upload Protector's Passport or national identity"
                acceptType={fileAcceptType}
                name="protecPassport"
                id="protecPassport"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.protecPassport &&
                errors?.protecPassport?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.protecPassport}
                  </span>
                )}
            </div>
          )}
          {entityTrustDetails.isprotecPassport === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="protecPassportReason"
                id="protecPassportReason"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.protecPassportReason &&
                errors?.protecPassportReason?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.protecPassportReason}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-6">
            <CheckBox
              label="Protector's proof of address available?"
              name="isprotecProofAdd"
              options={["Yes", "No"]}
              id="isprotecProofAdd"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
            />
            {errors &&
              errors?.isprotecProofAdd &&
              errors?.isprotecProofAdd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.isprotecProofAdd}
                </span>
              )}
          </div>
          {entityTrustDetails.isprotecProofAdd === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Upload Protector's proof of addres"
                info="Not older than 3 months"
                acceptType={fileAcceptType}
                name="protecProofAdd"
                id="protecProofAdd"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.protecProofAdd &&
                errors?.protecProofAdd?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.protecProofAdd}
                  </span>
                )}
            </div>
          )}
          {entityTrustDetails.isprotecProofAdd === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="protecProofAddReason"
                id="protecProofAddReason"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.protecProofAddReason &&
                errors?.protecProofAddReason?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.protecProofAddReason}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-6">
            <CheckBox
              label="Protector's reference letter available?"
              name="isprotecRefLett"
              options={["Yes", "No"]}
              id="isprotecRefLett"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
            />
            {errors &&
              errors?.isprotecRefLett &&
              errors?.isprotecRefLett?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.isprotecRefLett}
                </span>
              )}
          </div>
          {entityTrustDetails.isprotecRefLett === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Upload Protector's reference letter"
                info="by Accountant, Banker or Lawyer"
                acceptType={fileAcceptType}
                name="protecRefLett"
                id="protecRefLett"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.protecRefLett &&
                errors?.protecRefLett?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.protecRefLett}
                  </span>
                )}
            </div>
          )}
          {entityTrustDetails.isprotecRefLett === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="protecRefLettReason"
                id="protecRefLettReason"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.protecRefLettReason &&
                errors?.protecRefLettReason?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.protecRefLettReason}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Settlor details"
              name="settlorDetails"
              id="settlorDetails"
              placeholder="enter your text"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {errors &&
              errors?.settlorDetails &&
              errors?.settlorDetails?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.settlorDetails}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-6">
            <CheckBox
              label="Settlor's passport or national identity card available?"
              name="issettPassport"
              options={["Yes", "No"]}
              id="issettPassport"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}              
            />
            {errors &&
              errors?.issettPassport &&
              errors?.issettPassport?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.issettPassport}
                </span>
              )}
          </div>
          {entityTrustDetails.issettPassport === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Upload Settlor's Passport or national identity"
                acceptType={fileAcceptType}
                name="settPassport"
                id="settPassport"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.settPassport &&
                errors?.settPassport?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.settPassport}
                  </span>
                )}
            </div>
          )}
          {entityTrustDetails.issettPassport === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="settPassportReason"
                id="settPassportReason"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.settPassportReason &&
                errors?.settPassportReason?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.settPassportReason}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-6">
            <CheckBox
              label="Settlor's proof of address available?"
              name="issettProofAdd"
              options={["Yes", "No"]}
              id="issettProofAdd"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
            />
            {errors &&
              errors?.issettProofAdd &&
              errors?.issettProofAdd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.issettProofAdd}
                </span>
              )}
          </div>
          {entityTrustDetails.issettProofAdd === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Upload Settlor's proof of address"
                info="Not older than 3 months"
                acceptType={fileAcceptType}
                name="settProofAdd"
                id="settProofAdd"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.settProofAdd &&
                errors?.settProofAdd?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.settProofAdd}
                  </span>
                )}
            </div>
          )}
          {entityTrustDetails.issettProofAdd === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="settProofAddReason"
                id="settProofAddReason"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.settProofAdd &&
                errors?.settProofAdd?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.settProofAdd}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-6">
            <CheckBox
              label="Settlor's reference letter available?"
              name="issettRefLett"
              options={["Yes", "No"]}
              id="issettRefLett"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
            />
            {errors &&
              errors?.issettRefLett &&
              errors?.issettRefLett?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.issettRefLett}
                </span>
              )}
          </div>
          {entityTrustDetails.issettRefLett === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Upload Settlor's reference letter"
                info="by Accountant, Banker or Lawyer"
                acceptType={fileAcceptType}
                name="settRefLett"
                id="settRefLett"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.settRefLett &&
                errors?.settRefLett?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.settRefLett}
                  </span>
                )}
            </div>
          )}
          {entityTrustDetails.issettRefLett === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="settRefLettReason"
                id="settRefLettReason"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.settRefLettReason &&
                errors?.settRefLettReason?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.settRefLettReason}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Beneficiary details"
              name="beneficiaryDetails"
              id="beneficiaryDetails"
              placeholder="enter your text"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {errors &&
              errors?.beneficiaryDetails &&
              errors?.beneficiaryDetails?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.beneficiaryDetails}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-6">
            <CheckBox
              label="Beneficiary's passport or national identity card available?"
              name="isbenPassport"
              options={["Yes", "No"]}
              id="isbenPassport"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
            />
            {errors &&
              errors?.isbenPassport &&
              errors?.isbenPassport?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.isbenPassport}
                </span>
              )}
          </div>
          {entityTrustDetails.isbenPassport === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Upload Beneficiary's Passport or national identity"
                acceptType={fileAcceptType}
                name="benPassport"
                id="benPassport"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.benPassport &&
                errors?.benPassport?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.benPassport}
                  </span>
                )}
            </div>
          )}
          {entityTrustDetails.isbenPassport === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="benPassportReason"
                id="benPassportReason"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.benPassportReason &&
                errors?.benPassportReason?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.benPassportReason}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-6">
            <CheckBox
              label="Beneficiary's proof of address available?"
              name="isbenProofAdd"
              options={["Yes", "No"]}
              id="isbenProofAdd"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
            />
            {errors &&
              errors?.isbenProofAdd &&
              errors?.isbenProofAdd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.isbenProofAdd}
                </span>
              )}
          </div>
          {entityTrustDetails.isbenProofAdd === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Upload Beneficiary's proof of address"
                info="Not older than 3 months"
                acceptType={fileAcceptType}
                name="benProofAdd"
                id="benProofAdd"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.benProofAdd &&
                errors?.benProofAdd?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.benProofAdd}
                  </span>
                )}
            </div>
          )}
          {entityTrustDetails.isbenProofAdd === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="benProofAddReason"
                id="benProofAddReason"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.benProofAddReason &&
                errors?.benProofAddReason?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.benProofAddReason}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-6">
            <CheckBox
              label="Beneficiary's reference letter available?"
              name="isbenRefLett"
              options={["Yes", "No"]}
              id="isbenRefLett"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
            />
            {errors &&
              errors?.isbenRefLett &&
              errors?.isbenRefLett?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.isbenRefLett}
                </span>
              )}
          </div>
          {entityTrustDetails.isbenRefLett === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Upload Beneficiary's reference letter"
                info="by Accountant, Banker or Lawyer"
                acceptType={fileAcceptType}
                name="benRefLett"
                id="benRefLett"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.benRefLett &&
                errors?.benRefLett?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.benRefLett}
                  </span>
                )}
            </div>
          )}
          {entityTrustDetails.isbenRefLett === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="benRefLettReason"
                id="benRefLettReason"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.benRefLettReason &&
                errors?.benRefLettReason?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.benRefLettReason}
                  </span>
                )}
            </div>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer, chapter } = ownProps;

  return {
    entityTrustDetails: state[reducer][chapter]["entityTrustDetails"],
  };
};

export default connect(mapStateToProps, null)(EntityTrustDetails);
