import React, { Component } from "react";
import SourceOfFunds from "./SourceOfFunds";
import UboBasicInfo from "./UBOBasicInfo";
import DeclarationSoW from "./DeclarationSoW";

class UboDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, baseName } = this.props;
    return (
      <div className="mb-4 col-lg-12">
        <fieldset className="eqheight">
          <legend>UBO {index + 1}</legend>
          <UboBasicInfo
            reducer={this.props.reducer}
            chapter={this.props.chapter}
            index={index}
            baseName={baseName}
            errorMsg={this.props.errorMsg}
            objBaseName={this.props.objBaseName}
            form={this.props.form}
          />
          <SourceOfFunds
            reducer={this.props.reducer}
            chapter={this.props.chapter}
            index={index}
            baseName={baseName}
            errorMsg={this.props.errorMsg}
            objBaseName={this.props.objBaseName}
            form={this.props.form}
          />
          <DeclarationSoW
            reducer={this.props.reducer}
            chapter={this.props.chapter}
            index={index}
            errorMsg={this.props.errorMsg}
            baseName={baseName}
            objBaseName={this.props.objBaseName}
            form={this.props.form}
          />
        </fieldset>
      </div>
    );
  }
}

export default UboDetails;
