import React from "react";
import { connect } from "react-redux";
import TextBox from "../../../../../elements/TextBox";
import { Row } from "react-bootstrap";
import { rationaleArray } from "./Constants";
import { setDataToReducer } from "../../../../../../redux/actions/ClientInformationAction";
import { getReducer } from "../../../../../elements/getReducer";
import { checkIsCommon } from "../CommonFunctions";

class Rationale extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rationales: rationaleArray(
        this.props.from === "Internal"
          ? JSON.parse(localStorage.getItem("formData")).office
          : localStorage.getItem("Office")
      ),
      selectedRationale: [],
    };
      
  }

  handleChange = (rationale) => {
    let array = this.state.rationales;
    let newIndex = array.indexOf(rationale);
    rationale.isChecked = !rationale.isChecked;
    array[newIndex] = rationale;
  
    this.setState({
      rationales: array,
    });
    let selectedItemsArray = [];
    array.forEach((item) => {
      if (item.isChecked) {
        let obj = {};
        obj["rationalStructure"] = item.label;
        selectedItemsArray.push(obj);
      }
    });
    this.setState({
      selectedRationale: selectedItemsArray,
    });
    this.props.setDataToReducer({
      type: getReducer(this.props.reducer),
      name: "rationaleStructurigForNL",
      chapter: this.props.chapter,
      value: selectedItemsArray,
      objBaseName: this.props.objBaseName,
    });
  };

  render() {
    let array = this.props.rationaleStructurigForNL;
    const eMsg = JSON.parse(this.props.errorMsg);
    let existingArray = this.state.rationales;
    if (array?.length > 0) {
      existingArray.forEach((item) => {
        array.forEach((rationale) => {
          if (item.label === rationale.rationalStructure) {
            item.isChecked = true;
          }
         
        });
      });
    }
    else{
      existingArray.forEach((item) => {
        item.isChecked = false;
      }); 
    }
    const Office = localStorage.getItem("Office");
    const formData = JSON.parse(localStorage.getItem("formData"));
    return (
      <Row className="pt-3 pb-4">
        <p className="col-md-12 mb-3" id="rationaleStructurigForNL">
            What is/are the rationale for structuring {" "}
          {this.props.from === "internal" ? "via " + formData.office :
          this.props.form === "UK" ? "in the selected jurisdiction" :"via " + Office}?
          {this.props.form === "UK" && <span style={{ color: "red" }}>*</span>}
          {this.props.form === "NL" && this.props.from==="external" && <span style={{ color: "red" }}>*</span>}
        </p>
        {existingArray.map((option, index) => {
          return (
            <div className="mb-2 col-lg-4" key={index}>
              <div className="options">
                 <label
                  value={option.label}
                  className={
                    this.props.from !== "external" && "disabledCheckbox"
                  }
                >
                   
                  <input
                    type="checkbox"
                    name={option.label}
                    onChange={(e) => this.handleChange(option)}
                    checked={option.isChecked}
                    value={option.label}
                    disabled={this.props.from !== "external"}
                  />
                  <img alt="" />
                  <span>{option.label}</span>
                </label>
              </div>
            </div>
          );
        })}
        <div className="col-md-12 pt-3">
          {eMsg &&
            eMsg?.rationaleStructurigForNL &&
            eMsg.rationaleStructurigForNL?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {eMsg.rationaleStructurigForNL}
              </span>
            )}
        </div>
        <div className="html2pdf__page-break"></div>
        <div className="col-md-12 pt-2">
          <TextBox
            label="Please provide further information based on the rationale selected"
            name="rationaleSelectedInfo"
            id="rationaleSelectedInfo"
            placeholder="enter more information"
            reducer={this.props.reducer}
            isCommon={checkIsCommon(this.props.form)}
            chapter={this.props.chapter}
            validationType="Text"
            objBaseName={this.props.objBaseName}
            disabled={this.props.from !== "external"}
            className={
              this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
            }
            isRequired={this.props.form === "UK" || (this.props.form === "NL" && this.props.from==="external")  ? true : false}    
          />
          {eMsg &&
            eMsg?.rationaleSelectedInfo &&
            eMsg.rationaleSelectedInfo?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {eMsg?.rationaleSelectedInfo}
              </span>
            )}
        </div>
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { reducer, form } = ownProps;
  if (
    form === "NL" ||
    form === "Curacao" ||
    form === "Malta" ||
    form === "Luxembourg"
  ) {
    return {
      rationaleStructurigForNL: state.commonFields["rationaleStructurigForNL"],
    };
  } else {
    return {
      rationaleStructurigForNL:
        state[reducer]["chapter4"]["rationaleStructurigForNL"],
    };
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Rationale);
