import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import {getToken} from "../getToken"
const MSLOGIN_CLIENTID = process.env.REACT_APP_MSLOGIN_CLIENTID;
const MSLOGIN_REDIRECTURL = process.env.REACT_APP_MSLOGIN_REDIRECTURL;
const BACKEND_API_URL = process.env.REACT_APP_INTERNAL_USER_BACKEND_API_URL;
const CreateCuracaoInternalCAFDetailsRequest = () => {
  return {
    type: "CREATE_CURACAO_INTERNAL_CAF_DETAILS_REQUEST",
  };
};

const CreateCuracaoInternalCAFDetailsReceiveData = (json) => {
  return {
    type: "CREATE_CURACAO_INTERNAL_CAF_DETAILS_SUCCESS",
    data: json,
  };
};

export const createCuracaoInternalCAFDetails = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(CreateCuracaoInternalCAFDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/CuracaoCAF/CreateCAFDetails`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({
            type: "CREATE_CURACAO_INTERNAL_CAF_DETAILS_FAIL",
            data: json,
          });
        } else {
          return dispatch(CreateCuracaoInternalCAFDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "CREATE_CURACAO_INTERNAL_CAF_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};
//--------------------------------------------
const SetCuracaoInternalCAFDetailsRequest = () => {
  return {
    type: "SET_CURACAO_INTERNAL_CAF_DETAILS_REQUEST",
  };
};

const SetCuracaoInternalCAFDetailsReceiveData = (json) => {
  return {
    type: "SET_CURACAO_INTERNAL_CAF_DETAILS_SUCCESS",
    data: json,
  };
};

export const setCuracaoInternalCAFDetails = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(SetCuracaoInternalCAFDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/CuracaoCAF/SetCAFDetails`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({
            type: "SET_CURACAO_INTERNAL_CAF_DETAILS_FAIL",
            data: json,
          });
        } else {
          return dispatch(SetCuracaoInternalCAFDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "SET_CURACAO_INTERNAL_CAF_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};

const GetCuracaoInternalCAFDetailsRequest = () => {
  return {
    type: "GET_CURACAO_INTERNAL_CAF_DETAILS_REQUEST",
  };
};

const GetCuracaoInternalCAFDetailsReceiveData = (json) => {
  return {
    type: "GET_CURACAO_INTERNAL_CAF_DETAILS_SUCCESS",
    data: json,
  };
};

export const getCuracaoInternalCAFDetails = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(GetCuracaoInternalCAFDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/CuracaoCAF/GetCAFInternal`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({
            type: "GET_CURACAO_INTERNAL_CAF_DETAILS_FAIL",
            data: json,
          });
        } else {
          return dispatch(GetCuracaoInternalCAFDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "GET_CURACAO_INTERNAL_CAF_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};
