import React from "react";
import { connect } from "react-redux";
import { saveAs } from "file-saver";
import { downloadSignedDocs } from "../../redux/actions/InternalFileHandleActions";

class DownloadDocuSignDoc extends React.Component {
  handleDownload = () => {
    let formData = JSON.parse(localStorage.getItem("formData"));
    const { uboData } = this.props;
    let data = {
      formId: formData.id,
      uboId: uboData.uboId,
      uboEmail: uboData.uboEmail,
      downloadFileName: "Combined.pdf",
    };
    this.props.downloadSignedDocs(data).then((res) => {
      var blob = new Blob([res.data]);
      let file = uboData.uboXName + ".pdf";
      saveAs(blob, file);
    });
  };
  render() {
    const { label } = this.props;
    return (
      <div className="mb-3 col-lg-12">
        <label className="row">Download signed UBO/PEP form</label>
        <div className="d-flex align-items-center upload-file-name w-100">
            <p
              className="FileNameDisplay"
              style={{ margin: "0", flexGrow: "1" }}
            >
              Download signed form
            </p>
            <span className="fileDownload">
              <i
                className="fa fa-download"
                onClick={this.handleDownload}
              ></i>
            </span>
        </div>

      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    downloadSignedDocs: async(data) => dispatch(await downloadSignedDocs(data)),
  };
};
export default connect(null, mapDispatchToProps)(DownloadDocuSignDoc);
