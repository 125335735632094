import { Route, Redirect } from "react-router-dom";

const InternalUserRoutes = ({
  component: Component,
  office,
  handleOfficeChange,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("authToken") ? (
          <Component
            {...props}
            office={office}
            handleOfficeChange={handleOfficeChange}
          />
        ) : (
          <Redirect to="/internal-home" />
        )
      }
    ></Route>
  );
};
export default InternalUserRoutes;
