import React, { Component } from "react";
import { connect } from "react-redux";
import { setDataToReducer } from "../../redux/actions/ClientInformationAction";
import "react-calendar/dist/Calendar.css";
import "./DateTimePicker.css";
import Tooltip from "./Tooltip";
import DatePicker from "react-date-picker";
import { getReducer } from "./getReducer";

class DateTimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chapter: this.props.chapter,
      errorMsg: "",
    };
  }

  handleChange = (value) => {
    let errorMessage = "";
    if (value > new Date() && !this.props.maxDate) {
      errorMessage = "Date should be less than current date";
    }
    this.setState({
      errorMsg: errorMessage,
    });
    this.props.setDataToReducer({
      type: getReducer(this.props.reducer),
      chapter: this.props.chapter,
      index: this.props.index,
      name: this.props.name,
      value: value,
      baseName: this.props.baseName,
      objBaseName: this.props.objBaseName,
      nestedObjBaseName: this.props.nestedObjBaseName,
      noChapter: this.props.noChapter,
    });
  };

  render() {
    const { label, name, value, info, hideLable, className, id, isRequired } =
      this.props;
    let selectedVal;
    if (value) {
      selectedVal = new Date(value);
    } else {
      selectedVal = null;
    }

    return (
      <>
        <div>
          {!hideLable && (
            <label>
              {label} {info && <Tooltip text={info}></Tooltip>}
            </label>
          )}
          {isRequired && <span style={{ color: "red" }}>*</span>}

          <DatePicker
            name={name}
            id={id}
            onChange={(v) => this.handleChange(v)}
            value={selectedVal}
            disabled={this.props.disabled}
            className={className ? className : null}
            maxDate={this.props.maxDate ? null : new Date()}
          />
          {this.state.errorMsg.length > 0 && (
            <span style={{ color: "red", fontSize: "14px" }}>
              {this.state.errorMsg}
            </span>
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    name,
    chapter,
    baseName,
    index,
    objBaseName,
    reducer,
    nestedObjBaseName,
    isCommon,
    noChapter,
  } = ownProps;

  if (isCommon) {
    if (!objBaseName && !baseName) {
      return {
        value: state[reducer][name],
      };
    } else if (objBaseName && nestedObjBaseName) {
      return {
        value: state[reducer][objBaseName][nestedObjBaseName][name],
      };
    } else if (objBaseName && !baseName) {
      return {
        value: state[reducer][objBaseName][name],
      };
    } else if (objBaseName && baseName) {
      return {
        value: state[reducer][objBaseName][baseName][index][name],
      };
    }
  } else if (noChapter) {
    return {
      value: state[reducer][name],
    };
  } else if (!baseName && !objBaseName) {
    return {
      value: state[reducer][chapter][name],
    };
  } else if (objBaseName && nestedObjBaseName) {
    return {
      value: state[reducer][chapter][objBaseName][nestedObjBaseName][name],
    };
  } else if (objBaseName && !baseName) {
    return {
      value: state[reducer][chapter][objBaseName][name],
    };
  } else if (objBaseName && baseName) {
    return {
      value: state[reducer][chapter][objBaseName][baseName][index][name],
    };
  } else {
    return {
      value: state[reducer][chapter][baseName][index][name],
    };
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(DateTimePicker);
