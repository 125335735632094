import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import NumberInput from "../../../../../elements/NumberInput";
import { UBOXArray } from "../../../../../JSONs/CommonFields/commonFields";
import UboInfo from "./uboinfo";

const objBaseName = "ubo";
class UBO extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { noOfUBOs,errorMsg,form } = this.props;
    let uboinfo = [];
    for (let i = 0; i < noOfUBOs; i++) {
      uboinfo.push(
        <UboInfo
          index={i}
          baseName="ubox"
          reducer="commonFields"
          objBaseName={objBaseName}
          errorMsg={errorMsg}
          form={form}
        />
      );
    }
    return (
      <Row className="pt-3 pb-3">
        <div className="mb-4 col-lg-4">
          <NumberInput
            label="How many UBO's are there?"
            name="noOfUBOs"
            referral={["ubox"]}
            pushedObject={[UBOXArray]}
            maxLimit={10}
            reducer="commonFields"
            isCommon={true}
            objBaseName="ubo"
            disabled={true}
          />
          <div className="errorMsg"></div>
        </div>
        {uboinfo}
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    noOfUBOs: state.commonFields.ubo["noOfUBOs"],
  };
};

export default connect(mapStateToProps, null)(UBO);
