export const checkIsCommon = (form) => {
  if (
    form === "NL" ||
    form === "Curacao" ||
    form === "Malta" ||
    form === "Luxembourg"
  )
    return true;
  else return false;
};
