export const fileAcceptType = ".doc,.docx,.odt,.pdf,.rtf,.tex,.txt,.wpd,.ods,.xls,.xlsm,.xlsx,.key,.odp,.pps,.ppt,.pptx,.jpeg,.jpg,.png";

export const riskType = [
  {
    value: "The risk of reputational damage",
    label: "The risk of reputational damage",
  },
  {
    value: "Financial losses",
    label: "Financial losses",
  },
  {
    value: "Non-compliance with law",
    label: "Non-compliance with law",
  },
  {
    value: "Regulation for UIM",
    label: "Regulation for UIM",
  },
];

export const riskLevel = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];

export const structureActivities = [
  {
    label: "Ultimate parent company",
    activityName: "upcActivites",
    sectorName: "upcSector",
  },
  {
    label: "Shareholder",
    activityName: "shareholderActivites",
    sectorName: "shareholderSector",
  },
  {
    label: "Object company",
    activityName: "objCompActivities",
    sectorName: "objCompSector",
  },
  {
    label: "Participations",
    activityName: "participantActivites",
    sectorName: "participantSector",
  },
  {
    label: "Other",
    activityName: "otherActivites",
    sectorName: "otherSector",
  },
];

export const RightsAndObligationArray = [
  { value: "Loan Agreement", label: "Loan Agreement" },
  { value: "Receivables", label: "Receivables" },
  { value: "Other", label: "Other" },
];

export const CurrencyArray = [
  { value: "USD", label: "USD" },
  { value: "EUR", label: "EUR" },
  { value: "GBP", label: "GBP" },
];

export const TypeSizeFrqArray = [
  {
    label: "From Object Company to group companies",
    name: "objCompToGrpComp",
    dependency: "isUPCfundObjComp",
    textboxName: "objCompGrpCompsCmmnt",
  },
  {
    label: "From group companies to Object Company",
    name: "grpCompToObjComp",
    dependency: "isUPCfundObjComp",
    textboxName: "grpCompsObjCompCmmnt",
  },
  {
    label: "From Object Company to UBO",
    name: "objCompToUBO",
    dependency: "isUBOInvstObjComp",
    textboxName: "objCompUboCmmnt",
  },
  {
    label: "From UBO to Object Company",
    name: "uboToObjComp",
    dependency: "isUBOInvstObjComp",
    textboxName: "uboObjCmpCmmnt",
  },
  {
    label: "From Object Company to third parties",
    name: "objCompToTP",
    dependency: "isTpInvstObjComp",
    textboxName: "objCompTpCmmnt",
  },
  {
    label: "From third parties to Object Company",
    name: "tpToObjComp",
    dependency: "isTpInvstObjComp",
    textboxName: "tpObjCompCmmnt",
  },
];

export const TypeSizeFreqTransct = [
  {
    label: "Type",
    name: "typeTrnsct",
  },
  {
    label: "Size",
    name: "sizeTrnsct",
  },
  {
    label: "Frequency of transactions",
    name: "freqTrnsct",
  },
];

export const riskList = [
  {
    label:
      "General - Structural overview of the relevant parts of the client structure",
    reducer: "ClientAcceptance",
    chapter: "chapter1",
    name: "descActnNeedsTkn",
  },
  {
    label: "Client Investigation - Client Relationship",
    reducer: "ClientAcceptance",
    chapter: "chapter2",
    name: "descActnNeedsTkn",
  },
  {
    label: "Client Investigation - UBO/ Ultimate Parent Company (UPC)",
    reducer: "ClientAcceptance",
    chapter: "chapter3",
    name: "descActnNeedsTkn",
  },
  {
    label: "Client Investigation - Object Company",
    reducer: "ClientAcceptance",
    chapter: "chapter4",
    name: "descActnNeedsTkn",
  },
  {
    label: "Client Acceptance - Sanctions external",
    reducer: "ClientAcceptance",
    chapter: "chapter5",
    name: "descActnNeedsTkn",
  },
  {
    label: "Agreements - Purpose of the (trust)services",
    reducer: "ClientAcceptance",
    chapter: "chapter6",
    name: "descActnNeedsTkn",
  },
];

export function getRiskSummary(reducer) {
  let array = [
    {
      label: "Customer activity risk rating",
      reducer: reducer,
      chapter: "chapter1",
      name: "custActvtyRskRtng",
    },
    {
      label: "Distribution risk rating",
      reducer: reducer,
      chapter: "chapter2",
      name: "custActvtyRskRtng",
    },
    {
      label: "Client risk rating",
      reducer: reducer,
      chapter: "chapter3",
      name: "custActvtyRskRtng",
    },
    {
      label: "Object Company and Transaction Profile risk rating",
      reducer: reducer,
      chapter: "chapter4",
      name: "custActvtyRskRtng",
    },
    {
      label: "Country risk rating",
      reducer: reducer,
      chapter: "chapter5",
      name: "custActvtyRskRtng",
    },
    {
      label: "Services risk rating",
      reducer: reducer,
      chapter: "chapter6",
      name: "custActvtyRskRtng",
    },
  ];
  return array;
}

export const SignedByList = [
  {
    label: "Form Creator",
    value: "FormCreator",
    isFixed: false,
    name: null,
    email: null,
    dos: new Date(),
  },
  {
    label: "Legal Manager",
    value: "LegalManager",
    isFixed: false,
    name: null,
    email: null,
    dos: null,
  },
  {
    label: "Compliance Officer",
    value: "ComplianceOfficer",
    isFixed: false,
    name: null,
    email: null,
    dos: null,
  },
  {
    label: "Management",
    value: "Management",
    isFixed: false,
    name: null,
    email: null,
    dos: null,
  },
];

export const chapValidationError = [
  {
    chapter: "chapter1",
    label: "Client Activity",
    path: "client-activity",
    isvalid: false,
    name: "isChapter1valid",
  },
  {
    chapter: "chapter2",
    label: "Client Investigation",
    path: "client-investigation",
    name: "isChapter2valid",
    isvalid: false,
  },
  {
    chapter: "chapter3",
    label: "UBO/UPC",
    path: "ubo-upc",
    name: "isChapter3valid",
    isvalid: false,
  },
  {
    chapter: "chapter4",
    label: "Object Company",
    name: "isChapter4valid",
    path: "object-company",
    isvalid: false,
  },
  {
    chapter: "chapter5",
    label: "Country/Sanction Risks",
    name: "isChapter5valid",
    path: "country-sanction-risks",
    isvalid: false,
  },
  {
    chapter: "chapter6",
    label: "Agreement",
    name: "isChapter6valid",
    path: "agreement",
    isvalid: false,
  },
  {
    chapter: "chapter8",
    label: "Consolidated Risk Assesment",
    name: "isChapter8valid",
    path: "consolidated-risk-assessment",

    isvalid: false,
  },
  {
    chapter: "chapter9",
    label: "Correspondence",
    path: "correspondence",
    name: "isChapter9valid",

    isvalid: false,
  },
  {
    chapter: "chapter10",
    name: "isChapter10valid",
    label: "Miscellaneous",
    path: "miscellaneous",
    isvalid: false,
  },
  {
    chapter: "chapter11",
    name: "isChapter11valid",
    label: "Summary sheet CAF",
    path: "summary-sheet-caf",
    isvalid: false,
  },
  {
    chapter: "chapter12",
    label: "Formal sign off",
    name: "isChapter12valid",
    path: "formal-sign-off",
    isvalid: false,
  },
];

export const chapter1 = {
  structureChartComm: "",
  isInLineUIMAccPolicy: "",
  isMediaCoverageOnStruct: "",
  isAddDocs: "",
  addDocs: [],
  isRisk: "",
  custActivityRisks: "",
  custActvtyRskRtng: "",
  descActnNeedsTkn: "",
  isUIMnoResonDiscntnuBusRel: "",
  reportOnActivities: "",
};

export const chapter2 = {
  noUBOInfoByUIM: "",
  isPascalReport: "",
  noPascalReportReason: "",
  pascalReportUpload: [],
  isMediaCoverageOnStruct: "",
  isAddDocs: "",
  addDocs: [],
  isRisk: "",
  custActivityRisks: [],
  custActvtyRskRtng: "",
  descActnNeedsTkn: "",
  risksCncrnngIntermediary: "",
  isSrvcPrvdrTermntdSrvcsClr: "",
  isSrvcPrvdrReputable: "",
  isReceiveNecessaryInfo: "",
  isIncreasedIntegrityRisk: "",
  increasedIntegrityRiskReason: "",
};
export const newClient = {
  clientName: null,
  country: null,
  explainDetails: null,
};

export const chapter3 = {
  isHldngIntrstCmmnt: "",
  isUBOClsifiedCmmnt: "",
  isAsstClientCmmnt: "",
  noUBOClassifiedCmmnt: "",
  uboApproval: [],
  upcApproval: [],
  pascalReportUpload: [],
  isFindings: "",
  isMRZPassport: "",
  descInvstgUBOClsifiedPEP: "",
  isMediaCoverageOnStruct: "",
  isAddDocs: "",
  addDocs: [],
  isRisk: "",
  custActivityRisks: [],
  custActvtyRskRtng: "",
  descActnNeedsTkn: "",
  isUBOresultIncrsdIntgrtyRsk: "",
  ubOresultIncrsdIntgrtyRskCmmnt: "",
};

export const chapter4 = {
  objCompCmmnt: "",
  objCompDirCmmnt: "",
  objPascalResport: [],
  objFindings: "",
  objCompActiveConCmmnt: "",
  objCompBranchesCmmnt: "",
  rationaleStrctrngForNLCmmnt: "",
  findingsIndpTaxAdvisorApprvd: "",
  bsEvidencesIsApprvd: "",
  bsEvidencesCmmnt: "",
  cpyPassportMangApprvd: "",
  extCOCApprvd: "",
  deedOfIncorpApprvd: "",
  shareholdersRegApprvd: "",
  shareholdersCrtfictsApprvd: "",
  finStmntsPstthreeYrsApprvd: "",
  taxAdviceApprvd: "",
  taxAdviceCmmnt: "",
  invstmntMemoApprvd: "",
  apaCmmnt: "",
  otherRelDocsApprvd: "",
  otherRelDocsCmmnt: "",
  objCompSFICmmnt: "",
  uboInvstObjCompCmmnt: "",
  annualFinStmntupcApprvd: "",
  upcFundObjCompCmmnt: "",
  anulFinStmntTpApprvd: "",
  tpInvstObjCompCmmnt: "",
  cntrysInvldObjCompCmmnt: "",
  asstSmmryEvidenceApprvd: "",
  assSmmryObjCompCommnt: "",
  expctdTransSummryApprvd: "",
  expctdTransSummryCmmnt: "",
  objCompGrpCompsCmmnt: "",
  grpCompsObjCompCmmnt: "",
  objCompUboCmmnt: "",
  uboObjCmpCmmnt: "",
  objCompTpCmmnt: "",
  tpObjCompCmmnt: "",
  rightsAndOblgtnsRsk: "",
  rightsAndOblgtnsCmmnt: "",
  bnkInfoRsk: "",
  bnkInfoCmmnt: "",
  fatcaFormApprvd: "",
  bnkStmntsApprvd: "",
  aggrmntsGrpEntApprvd: "",
  aggrmntsOthrParApprvd: "",
  finStmntObjCompApprvd: "",
  finStmntAllPartciApprvd: "",
  finStmntParCompApprvd: "",
  declrFinInstApprvd: "",
  isMediaCoverageOnStruct: "",
  isAddDocs: "",
  addDocs: [],
  isRisk: "",
  custActivityRisks: [],
  custActvtyRskRtng: "",
  descActnNeedsTkn: "",
  objCompIncrdIntgtyRsk: "",
  objCompIncrdIntgtyRskText: "",
  ditectorsDetail:[],
};

export const chapter5 = {
  isUIMAwareOfIncidents: "",
  uIMComment: "",
  structureComment: "",
  isRestrictiveMeasure: "",
  isStructurePlacedOnFATF: "",
  isStructureOrObjCompanyDirectly: "",
  includeInformation: "",
  isClientsInclRelevantCounterparties: "",
  overviewOfNaturalPerson: "",
  isMediaCoverageOnStruct: "",
  isAddDocs: "",
  addDocs: [],
  isRisk: "",
  custActivityRisks: [],
  custActvtyRskRtng: "",
  descActnNeedsTkn: "",
  isUnacceptableRisksIdentified: "",
  unacceptableRisksReason: "",
};

export const chapter6 = {
  isDomiciliationSvc: "",
  isManagementSvc: "",
  isAdministrationSvc: "",
  isUIMPrvdOthSvc: "",
  explanationOthSvc: "",
  isLeaseAgmntOfcSpace: "",
  isDomicilliationAgmnt: "",
  isDeapForm: "",
  isAdministrationAgmnt: "",
  isPrinciplePartyAgmnt: "",
  isManagementAgmnt: "",
  isOtherAgmnt: "",
  reportWorldCheckExtDir: [],
  findingsWorldCheck: "",
  isRisk: "",
  custActivityRisks: [],
  custActvtyRskRtng: "",
  descActnNeedsTkn: "",
  isUnacceptableRisksIdentified: "",
  unacceptableRisksReason: "",
};

export const chapter8 = {
  overallRiskRating: "",
};

export const chapter9 = {
  overallRiskRating: "",
  findingWorldCheck: "",
};

export const chapter10 = {
  isOtherMatterNotable: "",
  otherMtrsImpCmnt: "",
};

export const chapter11 = {
  ovrallRsk: "",
  isDomiciliationSecServcs: "",
  isMngmntSecServcs: "",
  isAdministrtnSecServcs: "",
  othrSecSrvcs: "",
  isGoogleAlert: "",
};

export const chapter12 = {
  isReasonTigCliInvstg: "",
  isBusAccepAccClnt: "",
  reserveBusAccepComite: "",
  descReasDueDilignce: "",
  isstructOvrvwClr: "",
  isclientsIdntVrfd: "",
  isuboClssfctnIdntVrfctn: "",
  issrcWlthClr: "",
  ischckPEP: "",
  isobjPurObjCompClr: "",
  isTrnsPrflClr: "",
  isPurStrctrngKnwRecd: "",
  isSancRulChckd: "",
  isRelCorrespndceAdded: "",
  consolidtdRskObjComp: "",
  netRskRskCompliance: "",
  dateStrtOfSrvcs: "",
};
