import React, { Component } from "react";
import cc from "currency-codes";
import DropDown from "../../../../elements/DropDown";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";
import CheckBox from "../../../../elements/CheckBox";
import { connect } from "react-redux";

let currencies = [];
cc.codes().map((currency) => currencies.push({ label: currency }));

const objBaseName = "annualFees";
class AnnualFees extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isOtherFees } = this.props;
    const { errors, isRequired } = this.props;
    const errorMsg = JSON.parse(errors);

    return (
      <div
        className={
          this.props.PdfGeneration !== "Yes" ? "groupCompaniesBlock" : ""
        }
      >
        <h6
          className={
            this.props.PdfGeneration !== "Yes"
              ? "font-weight-bold internal_chapter_sub_title"
              : "font-weight-bold"
          }
        >
          Proposed Annual Fees
        </h6>
        <Row>
          <div className="mb-2 col-md-4">
            <DropDown
              label="Select currency"
              options={currencies}
              chapter={this.props.chapter}
              name="currency"
              id="annualCurrency"
              reducer={this.props.reducer}
              isRequired={isRequired}
              objBaseName={objBaseName}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorMsg &&
              errorMsg?.isSubmitted === true &&
              errorMsg?.annualCurrency &&
              errorMsg?.annualCurrency?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.annualCurrency}
                </span>
              )}
          </div>
          <div className="mb-2 col-md-4">
            <TextBox
              label="First yer registered office fees"
              placeholder="Enter fees"
              chapter={this.props.chapter}
              name="firstYearOfc"
              reducer={this.props.reducer}
              isRequired={isRequired}
              objBaseName={objBaseName}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
              validationType="Amount"
            />
            {errorMsg &&
              errorMsg?.isSubmitted === true &&
              errorMsg?.firstYearOfc &&
              errorMsg?.firstYearOfc?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.firstYearOfc}
                </span>
              )}
          </div>
          <div className="mb-2 col-md-4">
            <TextBox
              label="Rent of office space"
              placeholder="Enter fees"
              chapter={this.props.chapter}
              name="rentOfcSpc"
              isRequired={isRequired}
              reducer={this.props.reducer}
              objBaseName={objBaseName}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
              validationType="Amount"
            />
            {errorMsg &&
              errorMsg?.isSubmitted === true &&
              errorMsg?.rentOfcSpc &&
              errorMsg?.rentOfcSpc?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.rentOfcSpc}
                </span>
              )}
          </div>
          <div className="mb-2 col-md-4">
            <TextBox
              label="First year fixed management fees"
              placeholder="Enter fees"
              chapter={this.props.chapter}
              name="firstYearMngt"
              isRequired={isRequired}
              reducer={this.props.reducer}
              objBaseName={objBaseName}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
              validationType="Amount"
            />
            {errorMsg &&
              errorMsg?.isSubmitted === true &&
              errorMsg?.firstYearMngt &&
              errorMsg?.firstYearMngt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.firstYearMngt}
                </span>
              )}
          </div>
          <div className="mb-2 col-md-4">
            <TextBox
              label="First year time-spent basis administration fees"
              placeholder="Enter fees"
              chapter={this.props.chapter}
              name="firstYearAdmnst"
              isRequired={isRequired}
              reducer={this.props.reducer}
              objBaseName={objBaseName}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
              validationType="Amount"
            />
            {errorMsg &&
              errorMsg?.isSubmitted === true &&
              errorMsg?.firstYearAdmnst &&
              errorMsg?.firstYearAdmnst?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.firstYearAdmnst}
                </span>
              )}
          </div>
          <div className="mb-2 col-md-4">
            <TextBox
              label="Tax declaration"
              placeholder="Enter fees"
              isRequired={isRequired}
              chapter={this.props.chapter}
              name="taxDeclaration"
              reducer={this.props.reducer}
              objBaseName={objBaseName}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
              validationType="Amount"
            />
            {errorMsg &&
              errorMsg?.isSubmitted === true &&
              errorMsg?.taxDeclaration &&
              errorMsg?.taxDeclaration?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.taxDeclaration}
                </span>
              )}
          </div>
          <div className="mb-2 col-md-4">
            <TextBox
              label="Director's fees"
              placeholder="Enter fees"
              isRequired={isRequired}
              chapter={this.props.chapter}
              name="directorFees"
              reducer={this.props.reducer}
              objBaseName={objBaseName}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
              validationType="Amount"
            />
            {errorMsg &&
              errorMsg?.isSubmitted === true &&
              errorMsg?.directorFees &&
              errorMsg?.directorFees?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.directorFees}
                </span>
              )}
          </div>
          <div className="mb-2 col-md-4">
            <CheckBox
              label="Is there any other fees"
              options={["Yes", "No"]}
              chapter={this.props.chapter}
              name="isOtherFees"
              isRequired={isRequired}
              reducer={this.props.reducer}
              objBaseName={objBaseName}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorMsg &&
              errorMsg?.isSubmitted === true &&
              errorMsg?.isOtherFees &&
              errorMsg?.isOtherFees?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.isOtherFees}
                </span>
              )}
          </div>
          {isOtherFees === "Yes" && (
            <div className="mb-2 col-md-4">
              <TextBox
                label="Please describe"
                placeholder="Enter description"
                chapter={this.props.chapter}
                isRequired={isRequired}
                name="otherFeesCmnt"
                id="otherFeesCmnt"
                reducer={this.props.reducer}
                objBaseName={objBaseName}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
              {errorMsg &&
                errorMsg?.isSubmitted === true &&
                errorMsg?.otherFeesCmnt &&
                errorMsg?.otherFeesCmnt?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorMsg?.otherFeesCmnt}
                  </span>
                )}
            </div>
          )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer } = ownProps;
  return {
    isOtherFees: state[reducer].chapter6.annualFees.isOtherFees,
  };
};

export default connect(mapStateToProps, null)(AnnualFees);
