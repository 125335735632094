import React, { Component } from "react";
import { Switch } from "react-router-dom";
import InternalUserRoutes from "../../../../routes/InternalUserRoutes";

import Chapter1 from "../MaltaOffice/Chapter1/Chapter1";
import Chapter2 from "../MaltaOffice/Chapter2/Chapter2";
import Chapter3 from "../MaltaOffice/Chapter3/Chapter3";
import Chapter4 from "../MaltaOffice/Chapter4/Chapter4";
import Chapter5 from "../MaltaOffice/Chapter5/Chapter5";
import Chapter6 from "../MaltaOffice/Chapter6/Chapter6";
import Chapter8 from "../MaltaOffice/Chapter8/Chapter8";
import Chapter9 from "../MaltaOffice/Chapter9/Chapter9";
import Chapter10 from "../MaltaOffice/Chapter10/Chapter10";
import Chapter11 from "../MaltaOffice/Chapter11/Chapter11";
import Chapter12 from "../MaltaOffice/Chapter12/Chapter12";
import Chapter13 from "../MaltaOffice/Chapter13/Chapter13";

class MaltaInternalDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  handleChange(e) {
    this.props.handleOfficeChange(e);
  }
  render() {
    return (
      <Switch>
        <InternalUserRoutes
          path="/malta/client-activity"
          component={Chapter1}
        />

        <InternalUserRoutes
          path="/malta/client-investigation"
          component={Chapter2}
        />
        <InternalUserRoutes path="/malta/ubo-upc" component={Chapter3} />
        <InternalUserRoutes path="/malta/object-company" component={Chapter4} />
        <InternalUserRoutes
          path="/malta/country-sanction-risks"
          component={Chapter5}
        />
        <InternalUserRoutes path="/malta/agreement" component={Chapter6} />
        <InternalUserRoutes
          path="/malta/consolidated-risk-assessment"
          component={Chapter8}
        />
        <InternalUserRoutes path="/malta/correspondence" component={Chapter9} />
        <InternalUserRoutes path="/malta/miscellaneous" component={Chapter10} />
        <InternalUserRoutes
          path="/malta/summary-sheet-caf"
          component={Chapter11}
        />

        <InternalUserRoutes
          path="/malta/formal-sign-off"
          component={Chapter12}
        />
        <InternalUserRoutes path="/malta/signature" component={Chapter13} />
      </Switch>
    );
  }
}

export default MaltaInternalDashboard;
