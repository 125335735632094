import axios from "axios";
const BACKEND_API_URL = process.env.REACT_APP_USER_BACKEND_API_URL;
//Generate URL DocuSign
const GenerateURLRequest=()=>{
  return {
    type: "GENERATE_URL_REQUEST",
  };
}
const GenerateURLReceiveData = (json) => {
  return {
    type: "GENERATE_URL_SUCCESS",
    data: json,
  };
};

export const GenerateURL = (data) => {
   return (dispatch) => {
    dispatch(GenerateURLRequest());
    return axios
      .post(`${BACKEND_API_URL}/Docusign/GenerateURL`,data, null)
      .then((response) =>response.data)
      .then((json) => {
       
        if (json.Success === "false") {
          return dispatch({ type: "GENERATE_URL_FAIL", data: json });
        } else {
          return dispatch(GenerateURLReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "GENERATE_URL_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};
//Set UBO Declararions
const setUBODeclarationsRequest = () => {
  return {
    type: "SET_UBO_DECLARATIONS_REQUEST",
  };
};

const setUBODeclarationsReceiveData = (json) => {
  return {
    type: "SET_UBO_DECLARATIONS_SUCCESS",
    data: json,
  };
};

export const setUBODeclarations = (data) => {
  let userInfo = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + userInfo.authToken,
  };
  return (dispatch) => {
    dispatch(setUBODeclarationsRequest());
    return axios
      .post(`${BACKEND_API_URL}/api/UBODeclarations/SetUBODeclaration`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: "SET_UBO_DECLARATIONS_FAIL", data: json });
        } else {
          return dispatch(setUBODeclarationsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "SET_UBO_DECLARATIONS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};

//Get UBO Declararions
const getUBODeclarationsRequest = () => {
  return {
    type: "GET_UBO_DECLARATIONS_REQUEST",
  };
};

const getUBODeclarationsReceiveData = (json) => {
  return {
    type: "GET_UBO_DECLARATIONS_SUCCESS",
    data: json,
  };
};

export const getUBODeclarations = (data) => {
  let userInfo = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + userInfo.authToken,
  };
  return (dispatch) => {
    dispatch(getUBODeclarationsRequest());
    return axios
      .post(`${BACKEND_API_URL}/api/UBODeclarations/GetUBODeclaration`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: "GET_UBO_DECLARATIONS_FAIL", data: json });
        } else {
          return dispatch(getUBODeclarationsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error("Error", err);
        return dispatch({
          type: "GET_UBO_DECLARATIONS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};
//set New UBO Data To Reducer
const setNewUBODataToReducerReceiveData = (json) => {
  return {
    type: "UPDATE_NEW_UBO_DATA",
    data: json,
  };
};

export const setNewUBODataToReducer = (data) => {
  return (dispatch) => {
    dispatch(setNewUBODataToReducerReceiveData(data));
  };
};
//set New No UBO Data To Reducer
const setNewNoUBODataToReducerReceiveData = (json) => {
  return {
    type: "UPDATE_NEW_NO_UBO_DATA",
    data: json,
  };
};

export const setNewNoUBODataToReducer = (data) => {
  return (dispatch) => {
    dispatch(setNewNoUBODataToReducerReceiveData(data));
  };
};

const setNewAnnexFinalData = (json) => {
  return {
    type: "SET_NEW_ANNEX_DATA",
    data: json,
  };
};

export const setNewAnnexData = (data) => {
  return (dispatch) => {
    dispatch(setNewAnnexFinalData(data));
  };
};

const setAnnexToReducerRes = (json) => {
  return {
    type: "SET_NEW_ANNEX_FROM_API",
    data: json,
  };
};

export const setAnnexToReducer = (data) => {
  return (dispatch) => {
    dispatch(setAnnexToReducerRes(data));
  };
};
