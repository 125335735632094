import React from "react";
import EditClientForm from "./Chapter1/editClientForm"
import Chapter1 from "./Chapter1/Chapter1";
import Chapter2 from "./Chapter2/Chapter2";
import Chapter3 from "./Chapter3/Chapter3";
import Chapter4 from "./Chapter4/Chapter4";
import Chapter5 from "./Chapter5/Chapter5";
import Chapter6 from "./Chapter6/Chapter6";
import Chapter8 from "./Chapter8/Chapter8";
import Chapter9 from "./Chapter9/Chapter9";
import Chapter10 from "./Chapter10/Chapter10";
import Chapter11 from "./Chapter11/Chapter11";
import Chapter12 from "./Chapter12/Chapter12";

function PDFContainerAllChapters(props) {
  const bodyRef = React.createRef();

  const createPdf = async () => {
    const bodyhtml = bodyRef.current;

    props.createPdf(bodyhtml);
  };
  const printPdf = async () => {
    const bodyhtml = bodyRef.current;

    props.printPdf(bodyhtml);
  };

  return (
    <div>
      <div style={{ display: "none" }} className="InternalPDFexportCSS">
        {/* style={{ display: "none" }} */}
        <div
          ref={bodyRef}
          //className={props.classDisplayFlag && "InternalPDFexportCSS"}
          className="InternalPDFexportCSS"
        >
         <EditClientForm ></EditClientForm>
          {/* <Chapter1 PdfGeneration="Yes"></Chapter1>
          <Chapter2 PdfGeneration="Yes"></Chapter2>
          <Chapter3 PdfGeneration="Yes"></Chapter3>
          <Chapter4 PdfGeneration="Yes"></Chapter4>
          <Chapter5 PdfGeneration="Yes"></Chapter5>
          <Chapter6 PdfGeneration="Yes"></Chapter6>
          <Chapter8 PdfGeneration="Yes"></Chapter8>
          <Chapter9 PdfGeneration="Yes"></Chapter9>
          <Chapter10 PdfGeneration="Yes"></Chapter10>
          <Chapter11 PdfGeneration="Yes"></Chapter11>
          <Chapter12 PdfGeneration="Yes"></Chapter12> */}
          
          {props.children}
        </div>
      </div>

      <div className="pdfBTN text-center">
       
        <button
          className="action-btn noicon pt-2 pb-2 pl-4 pr-4"
          onClick={createPdf}
        >
          I Consent
        </button>
        <span> </span>
        <button
          className="action-btn greybtn pt-2 pb-2 pl-4 pr-4"
          onClick={printPdf}
        >
          Print
        </button>
        
       
      </div>
    </div>
  );
}

export default PDFContainerAllChapters;
