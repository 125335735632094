import React from "react";
import { Row } from "react-bootstrap";
import DropDown from "../../../../elements/DropDown";
import TextBox from "../../../../elements/TextBox";
import DateTimePicker from "../../../../elements/DateTimePicker";
import { riskLevel, getRiskSummary } from "../Constants";

class RisksSummary extends React.Component {
  render() {
    const { reducer, chapter, errors, isRequired } = this.props;
    const errorsMsg = JSON.parse(errors);
    let riskSummary = getRiskSummary(reducer);
    return (
      <>
        <div className="html2pdf__page-break"></div>
        <h4 className="sub-chapter-title pt-5 mb-4">
          Net risk analysis summary
        </h4>
        <Row className="pt-3 pb-2">
          {riskSummary.map((risk, index) => {
            return (
              <div className="mb-2 col-lg-4" key={index}>
                <DropDown
                  label={risk.label}
                  chapter={risk.chapter}
                  name={risk.name}
                  reducer={risk.reducer}
                  disabled={true}
                />
              </div>
            );
          })}
        </Row>
        <Row className="pt-3 pb-2">
          <div className="mb-2 col-lg-4">
            <DropDown
              label="Taking into account the above and the mitigating measures described, the consolidated risk for the Object Company is:"
              chapter={chapter}
              name="consolidtdRskObjComp"
              id="consolidtdRskObjComp"
              reducer={reducer}
              options={riskLevel}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.consolidtdRskObjComp?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.consolidtdRskObjComp}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-8">
            <TextBox
              label="Please describe why you think this is the net risk or discuss with Risk and Compliance"
              chapter={chapter}
              placeholder="Describe the information"
              name="netRskRskCompliance"
              id="netRskRskCompliance"
              isMultipleLines={true}
              isRequired={isRequired}
              reducer={reducer}
              className={
                this.props.PdfGeneration === "Yes" ? "txtArea" : "form-control"
              }
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.netRskRskCompliance?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.netRskRskCompliance}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-3 pb-2">
          <div className="mb-2 col-lg-4" id="dateStrtOfSrvcs">
            <DateTimePicker
              info="Note that the services may only start at the registration date in the CoC and AFTER BAC approval"
              label="Date of start of services"
              chapter={chapter}
              name="dateStrtOfSrvcs"
              isRequired={isRequired}
              reducer={reducer}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.dateStrtOfSrvcs?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.dateStrtOfSrvcs}
                </span>
              )}
          </div>
        </Row>
      </>
    );
  }
}

export default RisksSummary;
