import React from "react";

const Modal = ({ handleClose, show, children,customSize }) => {
  const showHideClassName = show ? "modal d-block" : "modal d-none";
  const widthSize = customSize ?  customSize:"auto" ;
  const customizheight=customSize ?  "80%":"auto" ;
  const customizoverflowX=customSize ?  "hidden":"" ;
  const customizoverflowY=customSize ?  "scroll":"" ;
  const customizRight=customSize ?  "5px":"-10px" ;
  const customizTop=customSize ?  "5px":"-10px" ;
  const MODAL_STYLES = {
     backgroundColor: "#FFF",
     position: "absolute",
     top: "15%",
     height:customizheight,
     width: widthSize,
     zIndex: "1000",
     padding: "15px",
   
     overflowX:customizoverflowX,
     overflowY: customizoverflowY,
  };
  const MODAL_CLOSE={
    right:customizRight,
    top:customizTop
  }
  return (
    <div className={showHideClassName}   >
      <div className="modal-container" style={MODAL_STYLES} >
        {children}
        <a className="modal-close" style={MODAL_CLOSE} onClick={handleClose}>
          X
        </a>
      </div>
    </div>
  );
};

export default Modal;
