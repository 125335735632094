import React, { Component } from "react";
import { Row } from "react-bootstrap";
import CheckBox from "../../../../../elements/CheckBox";
import FileUpload from "../../../../../elements/FileUpload";
import TextBox from "../../../../../elements/TextBox";
import countryList from "react-select-country-list";
import DropDown from "../../../../../elements/DropDown";
import { fileAcceptType } from "./Constants";
import { connect } from "react-redux";
import MultiSelectDropdown from "../../../../../elements/MultiSelectDropdown";

const chapter = "chapter2";
const baseName = "authorisedDetails";
class Authorised extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, authorised, eMsg } = this.props;

    const errorMsg = JSON.parse(eMsg);
    return (
      <div className="mb-4 col-lg-12">
        <fieldset>
          <legend>
            Autorised person
            {index + 1}
          </legend>
          <Row>
            <div className="mb-2 col-lg-8">

              <TextBox
                label="Name of authorized person"
                placeholder="Enter authorized person Name"
                name="authrzdPrsnName"
                id={`authrzdPrsnName[${index}]`}
                index={index}
                chapter={chapter}
                baseName={baseName}
                reducer={this.props.reducer}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false}
              />
              {Object.entries(errorMsg).length !== 0 &&
                errorMsg.authorisedDetails &&
                errorMsg.authorisedDetails[index] &&
                errorMsg.authorisedDetails[index]
                  ?.authrzdPrsnName?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {
                      errorMsg.authorisedDetails[index]
                        ?.authrzdPrsnName
                    }
                  </span>
                )}
            </div>
            <div className="mb-2 col-lg-6">
              <DropDown
                label="Nationality of authorized person"
                name="authrzdPrsnNationality"
                id={`authrzdPrsnNationality[${index}]`}
                options={countryList().getData()}
                index={index}
                chapter={chapter}
                baseName={baseName}
                reducer={this.props.reducer}
                isRequired={this.props.form === "UK" ? true : false}
              />
              {Object.entries(errorMsg).length !== 0 &&
                errorMsg.authorisedDetails &&
                errorMsg.authorisedDetails[index] &&
                errorMsg.authorisedDetails[index]
                  ?.authrzdPrsnNationality?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {
                      errorMsg.authorisedDetails[index]
                        ?.authrzdPrsnNationality
                    }
                  </span>
                )}
            </div>
            <div className="mb-2 col-lg-6">
              <FileUpload
                label="Upload passport"
                acceptType={fileAcceptType}
                name="authrzdPrsnPassport"
                id={`authrzdPrsnPassport[${index}]`}
                index={index}
                chapter={chapter}
                baseName={baseName}
                reducer={this.props.reducer}
                isRequired={this.props.form === "UK" ? true : false}
              />
              {Object.entries(errorMsg).length !== 0 &&
                errorMsg.authorisedDetails &&
                errorMsg.authorisedDetails[index] &&
                errorMsg.authorisedDetails[index]
                  ?.authrzdPrsnPassport?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {
                      errorMsg.authorisedDetails[index]
                        ?.authrzdPrsnPassport
                    }
                  </span>
                )}
            </div>
            <div className="mb-2 col-lg-6">
              <FileUpload
                label="Upload authorization letter"
                acceptType={fileAcceptType}
                name="authrzdPrsnAuthLetter"
                id={`authrzdPrsnAuthLetter[${index}]`}
                index={index}
                chapter={chapter}
                baseName={baseName}
                reducer={this.props.reducer}
                isRequired={this.props.form === "UK" ? true : false}
              />
              {Object.entries(errorMsg).length !== 0 &&
                errorMsg.authorisedDetails &&
                errorMsg.authorisedDetails[index] &&
                errorMsg.authorisedDetails[index]
                  ?.authrzdPrsnAuthLetter?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {
                      errorMsg.authorisedDetails[index]
                        ?.authrzdPrsnAuthLetter
                    }
                  </span>
                )}
            </div>


            {/* <div className="mb-2 col-lg-6">
                      <FileUpload
                        label="Upload reference letter"
                        acceptType={fileAcceptType}
                        name="authrzdPrsnRefLetter"
                        id={`authrzdPrsnRefLetter[${index}]`}
                        chapter={chapter}
                        reducer={this.props.reducer}
                        isRequired={this.props.form === "UK" ? true : false} 
                      />
                      {eMsg.authrzdPrsnRefLetter?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {eMsg.authrzdPrsnRefLetter}
                        </span>
                      )}
                    </div> */}
            <div className="mb-2 col-lg-6">
              <FileUpload
                label="Upload proof of address"
                acceptType={fileAcceptType}
                name="authrzdPrsnPOA"
                id={`authrzdPrsnPOA[${index}]`}
                index={index}
                chapter={chapter}
                baseName={baseName}
                reducer={this.props.reducer}
                isRequired={this.props.form === "UK" ? true : false}
              />
              {Object.entries(errorMsg).length !== 0 &&
                errorMsg.authorisedDetails &&
                errorMsg.authorisedDetails[index] &&
                errorMsg.authorisedDetails[index]
                  ?.authrzdPrsnPOA?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {
                      errorMsg.authorisedDetails[index]
                        ?.authrzdPrsnPOA
                    }
                  </span>
                )}
            </div>
          </Row>
        </fieldset>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { baseName, index, reducer } = ownProps;
  return {
    authorised: state[reducer]["chapter2"][baseName][index],

  };
};

export default connect(mapStateToProps, null)(Authorised);
