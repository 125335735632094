import React, { Component } from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../../elements/TextBox";
import CheckBox from "../../../../../elements/CheckBox";
import { connect } from "react-redux";

class EffectiveControlOfUBO extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { uboEfctvCtrlMngmntLglEntity } = this.props;
    return (
      <>
        <div className="html2pdf__page-break"></div>
        <h4 className="sub-chapter-title col-md-12 mt-4 mb-3 pl-0 pr-0">
          Effective control of UBO
        </h4>
        <Row className="mt-3 mb-3">
          <div className="mb-3 col-lg-4 fieldAlignment">
            <TextBox
              label="Interest of UBO in upc in %"
              placeholder="enter interest of ubo ultimate parent company"
              reducer={this.props.reducer}
              id="perIntrstUBOinUPC"
              name="perIntrstUBOinUPC"
              chapter={this.props.chapter}
              validationType="Amount"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.perIntrstUBOinUPC?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.perIntrstUBOinUPC}
                </span>
              )}
          </div>

          <div className="mb-3 col-lg-4">
            <TextBox
              label={
                this.props.form === "Malta"
                  ? "Interest of beneficiary of the assets of a target company in %"
                  : "Interest of beneficiary of the assets of a legal entity in %"
              }
              placeholder="enter interest of beneficiary of the assets of a legal entity"
              reducer={this.props.reducer}
              id="perIntrstBeneficiary"
              name="perIntrstBeneficiary"
              chapter={this.props.chapter}
              validationType="Amount"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.perIntrstBeneficiary?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.perIntrstBeneficiary}
                </span>
              )}
          </div>

          <div className="mb-3 col-lg-8">
            <CheckBox
              label={
                this.props.form === "Malta"
                  ? "Is the UBO exercising ultimate effective control over the management of the target company through other means?"
                  : "Is the UBO exercising ultimate effective control over the management of the legal entity through other means?"
              }
              options={["Yes", "No"]}
              id="uboEfctvCtrlMngmntLglEntity"
              name="uboEfctvCtrlMngmntLglEntity"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.uboEfctvCtrlMngmntLglEntity?.length >
                0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.uboEfctvCtrlMngmntLglEntity}
                </span>
              )}
          </div>

          {uboEfctvCtrlMngmntLglEntity === "Yes" && (
            <div className="mb-2 col-lg-4">
              <TextBox
                label="Please specify"
                placeholder="enter reason"
                reducer={this.props.reducer}
                id="reasonUboEfctvCtrlMngmntLglEntity"
                name="reasonUboEfctvCtrlMngmntLglEntity"
                chapter={this.props.chapter}
              />
              {this.props.errorMsg !== null &&
                Object.entries(this.props.errorMsg).length !== 0 &&
                this.props.errorMsg?.partA?.reasonUboEfctvCtrlMngmntLglEntity
                  ?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {
                      this.props.errorMsg?.partA
                        ?.reasonUboEfctvCtrlMngmntLglEntity
                    }
                  </span>
                )}
            </div>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uboEfctvCtrlMngmntLglEntity:
      state.UBODeclarationUIM.partA["uboEfctvCtrlMngmntLglEntity"],
  };
};

export default connect(mapStateToProps, null)(EffectiveControlOfUBO);
