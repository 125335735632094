import axios from "axios";
import {
  CHANGE_FILE_URL_FAIL,
  CHANGE_FILE_URL_REQUEST,
  CHANGE_FILE_URL_SUCCESS,
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  DOWNLOAD_FILE_FAIL,
  DOWNLOAD_FILE_REQUEST,
  DOWNLOAD_FILE_SUCCESS,
  GET_CUSTOMER_ACTIVITY_FAIL,
  GET_CUSTOMER_ACTIVITY_REQUEST,
  GET_CUSTOMER_ACTIVITY_SUCCESS,
  GET_GENERIC_DETAILS_FAIL,
  GET_GENERIC_DETAILS_REQUEST,
  GET_GENERIC_DETAILS_SUCCESS,
  SET_CUSTOMER_ACTIVITY_FAIL,
  SET_CUSTOMER_ACTIVITY_REQUEST,
  SET_CUSTOMER_ACTIVITY_SUCCESS,
  SET_GENERIC_DETAILS_FAIL,
  SET_GENERIC_DETAILS_REQUEST,
  SET_GENERIC_DETAILS_SUCCESS,
  GET_COMPANY_DETAILS_FAIL,
  GET_COMPANY_DETAILS_REQUEST,
  GET_COMPANY_DETAILS_SUCCESS,
  GET_CLIENT_INFORMATION_LIST_REQUEST,
  GET_CLIENT_INFORMATION_LIST_SUCCESS,
  GET_CLIENT_INFORMATION_LIST_FAIL,
  DELETE_CLIENT_INFORMATION_REQUEST,
  DELETE_CLIENT_INFORMATION_SUCCESS,
  DELETE_CLIENT_INFORMATION_FAIL,
  CREATE_CLIENT_INFORMATION_REQUEST,
  CREATE_CLIENT_INFORMATION_SUCCESS,
  CREATE_CLIENT_INFORMATION_FAIL,
  UPDATE_CLIENT_INFORMATION_REQUEST,
  UPDATE_CLIENT_INFORMATION_SUCCESS,
  UPDATE_CLIENT_INFORMATION_FAIL,
  DELETE_CLIENT_CAF_REQUEST,
  DELETE_CLIENT_CAF_SUCCESS,
  DELETE_CLIENT_CAF_FAIL,
  GET_All_OFFICES_REQUEST,
  GET_All_OFFICES_SUCCESS,
  GET_All_OFFICES_FAIL,
} from "../constants/CAFGenericConstants";
import { PublicClientApplication } from "@azure/msal-browser";
import {getToken} from "./getToken"
const MSLOGIN_CLIENTID = process.env.REACT_APP_MSLOGIN_CLIENTID;
const MSLOGIN_REDIRECTURL = process.env.REACT_APP_MSLOGIN_REDIRECTURL;
// const getToken = async () => {
//   const cfg = {
//     auth: {
//       clientId: MSLOGIN_CLIENTID,
//       authority:
//         "https://login.microsoftonline.com/37ab276a-6d7b-4b26-95c4-23f9fd7d5786", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
//       redirectUri: MSLOGIN_REDIRECTURL,
//     },
//     cache: {
//       cacheLocation: "localStorage", // This configures where your cache will be stored
//       storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
//     },
//   };

//   const pca = new PublicClientApplication(cfg);
//   let allAccounts = JSON.parse(localStorage.getItem("allAccounts"));
//   const account = allAccounts[0];
//   let idToken = null;
//   try {
//     const resp = await pca.acquireTokenSilent({
//       scopes: ["User.Read"],
//       account,
//     });
//     idToken = resp.idToken;
//     return idToken;
//   } catch (err) {
//     return;
//   }
// };
const BACKEND_API_URL = process.env.REACT_APP_USER_BACKEND_API_URL;

const setNewGenericReceiveData = (json) => {
  return {
    type: "UPDATE_NEW_GENERIC_DATA",
    data: json,
  };
};

export const setNewGenericDataToReducer = (data) => {
  return (dispatch) => {
    dispatch(setNewGenericReceiveData(data));
  };
};

// set generic details
const setGenericDetailsRequest = () => {
  return {
    type: SET_GENERIC_DETAILS_REQUEST,
  };
};

const setGenericDetailsReceiveData = (json) => {
  return {
    type: SET_GENERIC_DETAILS_SUCCESS,
    data: json,
  };
};

export const setGenericDetails = (data) => {
  let userInfo = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + userInfo.authToken,
  };
  return (dispatch) => {
    dispatch(setGenericDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/CAFGeneric/SetGenericDetails`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: SET_GENERIC_DETAILS_FAIL, data: json });
        } else {
          dispatch(globalStateChanged());
          return dispatch(setGenericDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);

        return dispatch({
          type: SET_GENERIC_DETAILS_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// get generic details
const getGenericDetailsRequest = () => {
  return {
    type: GET_GENERIC_DETAILS_REQUEST,
  };
};

const getGenericDetailsReceiveData = (json) => {
  return {
    type: GET_GENERIC_DETAILS_SUCCESS,
    data: json,
  };
};

export const getGenericDetails = (data) => {
  let userInfo = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + userInfo.authToken,
  };
  return (dispatch) => {
    dispatch(getGenericDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/CAFGeneric/GetGenericDetails`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: GET_GENERIC_DETAILS_FAIL, data: json });
        } else {
          return dispatch(getGenericDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);

        return dispatch({
          type: GET_GENERIC_DETAILS_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

//Get Company details
const getCompanyDetailsRequest = () => {
  return {
    type: GET_COMPANY_DETAILS_REQUEST,
  };
};
const getCompanyDetailsReceiveData = (json) => {
  return {
    type: GET_COMPANY_DETAILS_SUCCESS,
    data: json,
  };
};
export const getCompanyDetails = () => {
  let userInfo = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + userInfo.authToken,
  };
  return (dispatch) => {
    dispatch(getCompanyDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/CAFGeneric/GetCompanyDetails`, null, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: GET_COMPANY_DETAILS_FAIL, data: json });
        } else {
          return dispatch(getCompanyDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);

        return dispatch({
          type: GET_COMPANY_DETAILS_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

//chap 1
const getCustomerActivityRequest = () => {
  return {
    type: GET_CUSTOMER_ACTIVITY_REQUEST,
  };
};

const getCustomerActivityReceiveData = (json) => {
  return {
    type: GET_CUSTOMER_ACTIVITY_SUCCESS,
    data: json,
  };
};

export const getCustomerActivity = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(getCustomerActivityRequest());
    return axios
      .post(`${BACKEND_API_URL}/CAF/GetCustomerActivity`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: GET_CUSTOMER_ACTIVITY_FAIL, data: json });
        } else {
          return dispatch(getCustomerActivityReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: GET_CUSTOMER_ACTIVITY_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

const setCustomerActivityRequest = () => {
  return {
    type: SET_CUSTOMER_ACTIVITY_REQUEST,
  };
};

const setCustomerActivityReceiveData = (json) => {
  return {
    type: SET_CUSTOMER_ACTIVITY_SUCCESS,
    data: json,
  };
};

export const setCustomerActivity = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(setCustomerActivityRequest());
    return axios
      .post(`${BACKEND_API_URL}/CAF/SetCustomerActivity`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: SET_CUSTOMER_ACTIVITY_FAIL, data: json });
        } else {
          return dispatch(setCustomerActivityReceiveData(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: SET_CUSTOMER_ACTIVITY_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

//change file
const changeFileURLRequest = () => {
  return {
    type: CHANGE_FILE_URL_REQUEST,
  };
};

const changeFileURLReceiveData = (json) => {
  return {
    type: CHANGE_FILE_URL_SUCCESS,
    data: json,
  };
};

export const changeFileURL = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(changeFileURLRequest());
    return axios
      .post(`${BACKEND_API_URL}/CAF/UploadFile`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: CHANGE_FILE_URL_FAIL, data: json });
        } else {
          return dispatch(changeFileURLReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: CHANGE_FILE_URL_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

//download file
const downloadFileRequest = () => {
  return {
    type: DOWNLOAD_FILE_REQUEST,
  };
};

const downloadFileReceiveData = (json) => {
  return {
    type: DOWNLOAD_FILE_SUCCESS,
    data: json,
  };
};

export const downloadFile = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(downloadFileRequest());
    return axios
      .post(`${BACKEND_API_URL}/CAF/DownloadFile`, data, {
        headers,
        responseType: "blob",
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: DOWNLOAD_FILE_FAIL, data: json });
        } else {
          return dispatch(downloadFileReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: DOWNLOAD_FILE_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

//delete file
const deleteFileRequest = () => {
  return {
    type: DELETE_FILE_REQUEST,
  };
};

const deleteFileReceiveData = (json) => {
  return {
    type: DELETE_FILE_SUCCESS,
    data: json,
  };
};

export const deleteFile = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(deleteFileRequest());
    return axios
      .post(`${BACKEND_API_URL}/CAF/DeleteFile`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: DOWNLOAD_FILE_FAIL, data: json });
        } else {
          return dispatch(deleteFileReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: DOWNLOAD_FILE_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

//Get client information list details
const getClientInformationListRequest = () => {
  return {
    type: GET_CLIENT_INFORMATION_LIST_REQUEST,
  };
};
const getClientInformationListReceiveData = (json) => {
  return {
    type: GET_CLIENT_INFORMATION_LIST_SUCCESS,
    data: json,
  };
};
export const getClientInformationListDetails = () => {
  let userInfo = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + userInfo.authToken,
  };
  return (dispatch) => {
    dispatch(getClientInformationListRequest());
    return axios
      .post(`${BACKEND_API_URL}/ClientInfoForm/GetAllClientInfornation`, null, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({
            type: GET_CLIENT_INFORMATION_LIST_FAIL,
            data: json,
          });
        } else {
          dispatch(globalStateNotChanged());
          return dispatch(getClientInformationListReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);

        return dispatch({
          type: GET_CLIENT_INFORMATION_LIST_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// Delete ClientInformation
const deleteClientInformationRequest = () => {
  return {
    type: DELETE_CLIENT_INFORMATION_REQUEST,
  };
};

const deleteClientInformationReceiveData = (json) => {
  return {
    type: DELETE_CLIENT_INFORMATION_SUCCESS,
    data: json,
  };
};

export const deleteClientInformation = (data) => {
  let userInfo = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + userInfo.authToken,
  };
  return (dispatch) => {
    dispatch(deleteClientInformationRequest());
    return axios
      .post(`${BACKEND_API_URL}/ClientInfoForm/DeleteClientInfornation`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: DELETE_CLIENT_INFORMATION_FAIL, data: json });
        } else {
          return dispatch(deleteClientInformationReceiveData(json));
        }
      })
      .catch((err) => {
        return dispatch({
          type: DELETE_CLIENT_INFORMATION_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// Create ClientInformation
const createClientInformationRequest = () => {
  return {
    type: CREATE_CLIENT_INFORMATION_REQUEST,
  };
};

const createClientInformationReceiveData = (json) => {
  return {
    type: CREATE_CLIENT_INFORMATION_SUCCESS,
    data: json,
  };
};

export const createClientInformation = (data) => {
  let userInfo = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + userInfo.authToken,
  };
  return (dispatch) => {
    dispatch(createClientInformationRequest());
    return axios
      .post(`${BACKEND_API_URL}/ClientInfoForm/CreateClientInfornation`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: CREATE_CLIENT_INFORMATION_FAIL, data: json });
        } else {
          return dispatch(createClientInformationReceiveData(json));
        }
      })
      .catch((err) => {
        return dispatch({
          type: CREATE_CLIENT_INFORMATION_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

// Update ClientInformation
const updateClientInformationRequest = () => {
  return {
    type: UPDATE_CLIENT_INFORMATION_REQUEST,
  };
};

const updateClientInformationReceiveData = (json) => {
  return {
    type: UPDATE_CLIENT_INFORMATION_SUCCESS,
    data: json,
  };
};

export const updateClientInformation = (data) => {
  let userInfo = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + userInfo.authToken,
  };
  return (dispatch) => {
    dispatch(updateClientInformationRequest());
    return axios
      .post(`${BACKEND_API_URL}/ClientInfoForm/UpdateClientInfornation`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: UPDATE_CLIENT_INFORMATION_FAIL, data: json });
        } else {
          return dispatch(updateClientInformationReceiveData(json));
        }
      })
      .catch((err) => {
        return dispatch({
          type: UPDATE_CLIENT_INFORMATION_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

export const globalStateChanged = () => ({ type: "STATE_CHANGED" });
export const globalStateNotChanged = () => ({ type: "STATE_NOT_CHANGED" });

// delete Client CAF
const deleteClientCAFRequest = () => {
  return {
    type: DELETE_CLIENT_CAF_REQUEST,
  };
};

const deleteClientCAFReceiveData = (json) => {
  return {
    type: DELETE_CLIENT_CAF_SUCCESS,
    data: json,
  };
};

export const deleteClientCAF = (data) => {
  let userInfo = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + userInfo.authToken,
  };
  return (dispatch) => {
    dispatch(deleteClientCAFRequest());
    return axios
      .post(`${BACKEND_API_URL}/CAFGeneric/DeleteClientCAF`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: DELETE_CLIENT_CAF_FAIL, data: json });
        } else {
          return dispatch(deleteClientCAFReceiveData(json));
        }
      })
      .catch((err) => {
        return dispatch({
          type: DELETE_CLIENT_CAF_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

const setClientInfoFormDataToReducerReceiveData = (json) => {
  return {
    type: "SET_CLIENT_INFORMATION_IN_REDUCER",
    data: json,
  };
};

export const setClientInfoFormDataToReducer = (data) => {
  return (dispatch) => {
    dispatch(setClientInfoFormDataToReducerReceiveData(data));
  };
};

// Get all offices
const getAllOfficesRequest = () => {
  return {
    type: GET_All_OFFICES_REQUEST,
  };
};
const getAllOfficesReceiveData = (json) => {
  return {
    type: GET_All_OFFICES_SUCCESS,
    data: json,
  };
};
export const getAllOffices = () => {
  let userInfo = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + userInfo.authToken,
  };
  return (dispatch) => {
    dispatch(getAllOfficesRequest());
    return axios
      .post(`${BACKEND_API_URL}/ClientInfoForm/ReadAllOffice`, null, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: GET_All_OFFICES_FAIL, data: json });
        } else {
          return dispatch(getAllOfficesReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: GET_All_OFFICES_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};
