import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { useMsal } from "@azure/msal-react";

export const InternalHeader = () => {
  const { instance } = useMsal();
  const allAccounts = instance.getAllAccounts();

  return (
    //  <AzureAD provider={authProvider} reduxStore={store}>
    <div className="header headerInternal">
      <Container fluid>
        <Row>
          <Col xs={6}>
            <Link
              onClick={() => {
                window.location.reload();
              }}
              className="logo"
            />
          </Col>
          <Col
            xs={6}
            className="text-right d-inline-flex align-items-center justify-content-end"
          >
            {Object.keys(allAccounts).length !== 0 ? (
              <SignOutButton />
            ) : (
              <SignInButton />
            )}
          </Col>
        </Row>
      </Container>
    </div>
    // </AzureAD>
  );
};
