export const UBOXArray = {
  id: null,
  name: null,
  email: null,
  phone: null,
  residentialAddress: null,
  registeredOffice: null,
  altCrspdnAddress: null,
  dob: null,
  uboNationality: null,
  uboCountryOfBirth: null,
  uboCountryOfResidence: null,
  countryOfTaxResidence: null,
  certifiedTrueCopyPass: [],
  proofAddress: [],
  refLetter: [],
  bankName: null,
  location: null,
  remitterName: null,
  address: null,
  grossAnnualIncome: null,
  anualIncmAbvEUR1Mln: null,
  wealthRange: null,
  srcOfWealth: [],
  evdncSrcWealth: [],
};
export const UPCDir = {
  upcAuthDirName: null,
  upcAuthDirEmail: null,
  // upcAuthDirPhoneNo: null,
  upcAuthDirNationality: null,
};
export const objCompDirectorName = {
  typeOfDirector:null,
  dirName: null,
  dirNationality: null,
  dirCertifiedPass: [],
  //dirProofAddress: [],
};
export const objTrustee={
trusteeName: null,
  trusteeAddress: null,
  istrusPassport: null,
  trusPassport: [],
  truePassportReason: null,
  istrusProofAdd: null,
  trusProofAdd: [],
  trusProofAddReason: null,
  istrusRefLett: null,
  trusRefLett: [],
  trusRefLettReason: null,
}
export const objProtector={
  protectorDetails:null,
  isprotecPassport: null,
  protecPassport: [],
  protecPassportReason: null,
  isprotecProofAdd: null,
  protecProofAdd: [],
  protecProofAddReason: null,
  isprotecRefLett: null,
  protecRefLett: [],
  protecRefLettReason: null,
}

export const objSettlor={
  settlorsDetails:null,
  issettPassport: null,
  settPassport: [],
  settPassportReason: null,
  issettProofAdd: null,
  settProofAdd: [],
  settProofAddReason: null,
  issettRefLett: null,
  settRefLett: [],
  settRefLettReason: null,

}
export const objBeneficiarie={
  beneficiarieDetails:null,
isbenPassport: null,
  benPassport: [],
  benPassportReason: null,
  isbenProofAdd: null,
  benProofAdd: [],
  benProofAddReason: null,
  isbenRefLett: null,
  benRefLett: [],
  benRefLettReason: null,
}

export const UPCXArray={
  entityType:null,
  Options:null,
  //-------company---
  nameUPC: null,
  addressRegistered:null,
  postalCode:null,
  city: null,
  country: null,
  websiteUPC: null,
  taxNum: null,
  tradeRegisterNum: null,
  phoneNumberUPC: null,
  countryOfTaxRsdnc: null,
  isForceCloseFinAcc: null,
  forceCloseFinAcc: null,
  arethereExistingUPCDir:null,
  noOfUPCDir: 1,
  upcDirectors: [UPCDir],
  extractChamberOfCommerce: [],
  deedOfIncorporation: [],
  shareholdersRegister: [],
  shareholdersCertificates: [],
  nomineeAgreement: [],
  financialStatementsThreeYears: [],
  //-----------other----------
  // isObjCompExists: "",
  // commercialRegister: null,
  // objCompName: null,
  // noOfDirectors: 1,
  // objCompDirectorNames: [objCompDirectorName],
  // objCompActiveCountry: [],
  // objCompNaturePropsdObjctv: null,
  // objCompOtherNaturePropsdObjctv: null,
  // isObjCompBranches: null,
  // objCompBranch: [],
  // rationaleStructurigForNL: [],
  // rationaleSelectedInfo: null,
  // taxAdvisorFinds: [],
  //---------------Trust/fundation-----------
  directorsPartners: null,
  // shareholdersMembers: null,
   regOffice: null,
   trustName:null,
   trustJurisdiction:null,
   noOftrustees: 1,
   objTrustees: [objTrustee],
   noOfProtectors:1,
   objProtectors:[objProtector],
   noOfSettlors:1,
   objSettlors:[objSettlor],
   noOfBeneficiaries:1,
   objBeneficiaries:[objBeneficiarie],
   trustSupportingDocument:[],
   
}

const ubo = {
  noOfUBOs: 1,
  ubox: [UBOXArray],
};




const UPC = {
  upcId: null,
  nameUPC: null,
  addressRegistered: null,
  postalCode: null,
  city: null,
  country: null,
  websiteUPC: null,
  taxNum: null,
  tradeRegisterNum: null,
  phoneNumberUPC: null,
  countryOfTaxRsdnc: null,
  isForceCloseFinAcc: null,
  forceCloseFinAcc: null,
  arethereExistingUPCDir:null,
  noOfUPCDir: 1,
  //upcDirectors: [UPCDir],
  extractChamberOfCommerce: [],
  deedOfIncorporation: [],
  shareholdersRegister: [],
  shareholdersCertificates: [],
  nomineeAgreement: [],
  financialStatementsThreeYears: [],
};

export const Chapter3 = {
  dirty: false,
  isAssetClient: "",
  isAssetClientReason: null,
  isHoldingInterest: "",
  isHoldingInterestReason: null,
  isUBOClassified: "",
  isUBOClassifiedReason: null,
  isUBOExists:null,
  isUPCExists: null,
  noOfUPCs: 1,
  ubo: ubo,
  upcDetails: [UPCXArray],
 
};
