import React, { Component } from "react";
import { setUBOUIMDetails } from "../../../../../redux/actions/UBOdeclarationUIMactions";
import { setNewAnnexData } from "../../../../../redux/actions/UBOInformationAction";
import { connect } from "react-redux";
//import { updateClientInformation } from "../../../../../redux/actions/CAFGenericActions";
class AutoSaving extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  apiCall = () => {
    let requestData = {};
    let apiCallFlag = false;
    let annex_call_flag = false;
    if (this.props.UBODeclarationUIM.dirty) {
      apiCallFlag = true;
      delete this.props.UBODeclarationUIM["dirty"];
      requestData["uBOUIMEmailCommonModel"] = this.props.UBODeclarationUIM;
    }
    let uBODeclarationsModel =
      this.props.isUBO === "Yes"
        ? this.props.AnnexUBOInformation.AnnexUBO
        : this.props.AnnexUBOInformation.AnnexNoUBO;
    if (uBODeclarationsModel.dirty) {
      apiCallFlag = true;
      annex_call_flag = true;
      delete uBODeclarationsModel["dirty"];
      requestData["uBODeclarationsModel"] = uBODeclarationsModel;
    }
    let commonFields = this.props.commonFields;
    if (commonFields.dirty) {
      apiCallFlag = true;
      delete commonFields["dirty"];
      requestData["commonFields"] = commonFields;
    }
    requestData["id"] = this.props.uboId;
    requestData["formId"] = this.props.formId;
    requestData["userId"] = this.props.userId;
    requestData["clientId"] = this.props.clientId;
    requestData["office"] = this.props.office;
    requestData["status"] = false;
    if (apiCallFlag) {
      this.props.setUBOUIMDetails(requestData).then((res) => {
        if (res.data?.portalErrorCode === 200) {
          this.props.UBODeclarationUIM["dirty"] = false;
          this.props.commonFields["dirty"] = false;
          uBODeclarationsModel["dirty"] = false;
          if (annex_call_flag) {
            this.props.setNewAnnexData({
              isUBO: this.props.isUBO,
              newData: uBODeclarationsModel,
            });
          }
        }
      });
    }
  };

  componentDidMount = () => {
    this.apiCall();
    setInterval(this.apiCall, 5000);
  };

  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    UBODeclarationUIM: state.UBODeclarationUIM,
    AnnexUBOInformation: state.AnnexUBOInformation,
    commonFields: state.commonFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUBOUIMDetails: (data) => dispatch(setUBOUIMDetails(data)),
    setNewAnnexData: (data) => dispatch(setNewAnnexData(data)),
    // setNewDataToReducer: (data) => dispatch(setNewDataToReducer(data)),
    // updateClientInformation: (data) => dispatch(updateClientInformation(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AutoSaving);
