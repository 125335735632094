export const structureActivities = [
  {
    label: "Ultimate parent company",
    activityName: "upcActivites",
    sectorName: "upcSector",
  },
  {
    label: "Shareholder",
    activityName: "shareholderActivites",
    sectorName: "shareholderSector",
  },
  {
    label: "Object company",
    activityName: "objCompActivities",
    sectorName: "objCompSector",
  },
  {
    label: "Participations",
    activityName: "participantActivites",
    sectorName: "participantSector",
  },
  {
    label: "Other",
    activityName: "otherActivites",
    sectorName: "otherSector",
  },
];

export const fileAcceptType = ".doc,.docx,.odt,.pdf,.rtf,.tex,.txt,.wpd,.ods,.xls,.xlsm,.xlsx,.key,.odp,.pps,.ppt,.pptx,.jpeg,.jpg,.png";

export const CurrencyArray = [
  { value: "USD", label: "USD" },
  { value: "EUR", label: "EUR" },
  { value: "GBP", label: "GBP" },
];

export const UIMpartA = {
  shareCertificate: "",
  nomineeAgreement: "",
};
