import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import {
  setGenericDetails,
  getGenericDetails,
  updateClientInformation,
  globalStateNotChanged,
  globalStateChanged,
  setClientInfoFormDataToReducer,
} from "../../../../../redux/actions/CAFGenericActions";
import {
  setCommonFields,
  getCommonFields,
  setNewCommonFieldsToReducer,
} from "../../../../../redux/actions/CommonFieldsActions";
import {
  PEPXArray,
  UBOXArray,
} from "../../../../JSONs/CommonFields/commonFields";
import { setGenericDataToReducer } from "../../../../../redux/actions/GenericInformationAction";
import { deleteUBO } from "../../../../../redux/actions/userActions";
import CheckBox from "../../../../elements/CheckBox";
import NumberInput from "../../../../elements/NumberInput";
import { validateGenericForm } from "./ValidateGenericForm";
import UboInfo from "../client-information/Chapter3/uboinfo";
import AutoSaving from "./AutoSaving";
import PEPs from "./PEPs";
import { getDependentReducer } from "./getDependentReducer";

const reducer = "genericInformation";
const chapter = "generic";
const formValid = (formErrors) => {
  let valid = true;
  formErrors.forEach(function (errors) {
    Object.values(errors).forEach(function (val) {
      val.length > 0 && (valid = false);
    });
  });
  return valid;
};
class GenericForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      isLoading: false,
      UBOErrors: {},
      PEPErrors: {},
      form: "",
    };
  }

  componentDidMount() {
    let recordId = localStorage.getItem("formId");
    if (recordId) {
      this.GetGenericDetails(recordId);
    }
    let office = localStorage.getItem("Office");
    if (office) {
      this.setState({
        form: office,
      });
    }
  }

  GetGenericDetails = (recordId) => {
    this.props
      .getGenericDetails({
        id: recordId,
      })
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.portalErrorCode === 200) {
        }
      })
      .catch(this.catchError);
    this.props
      .getCommonFields(localStorage.getItem("formId"))
      .then((res) => {
        if (res.data.portalErrorCode === 200) {
        }
      })
      .catch(this.catchError);
  };
  deleteUBO = (itemId) => {
    let dataRemoveUBO = {
      id: itemId,
    };
    this.props.deleteUBO(dataRemoveUBO).then((res) => {
      console.log("res:", res);
    });
  };

  handleSubmit = (e) => {
    // this.setState({ isLoading: true });
    let response = validateGenericForm(this.props.state);
    if (response.errors.sameData) {
      NotificationManager.error(response.errors.sameData);
    }

    this.setState({
      errors: response.errors,
      UBOErrors: response.UBOErrors,
      PEPErrors: response.PEPErrors,
    });
    if (
      Object.keys(response.errors).length === 0 &&
      formValid(response.UBOErrors.ubox) &&
      formValid(response.PEPErrors.PEP)
    ) {
      let data = {
        Id: localStorage.getItem("formId"),
        CompanyId: localStorage.getItem("clientId"),
        office: localStorage.getItem("Office"),
        GenericDetails: this.props.genericDetails,
      };
      this.props.globalStateNotChanged();
      this.props
        .setGenericDetails(data)
        .then((res) => {
          this.setState({ isLoading: false });
          if (res.data.portalErrorCode === 200) {
            this.setState({ recordId: res.data?.result });
            this.props.setGenericDataToReducer(this.state);
            let details = {
              Id: localStorage.getItem("formId"),
              lastActivityName: "client-information",
              status: "In Progress",
              activeLinkLevel: 1,
            };
            this.props.setClientInfoFormDataToReducer(1);
            this.props.updateClientInformation(details);
            NotificationManager.success("Added Generic Details Successfully!");
            this.props.history.push({
              pathname: "/client-information",
            });
          }
        })
        .catch(this.catchError);

      //api call for common fields
      let requestData = this.props.commonFields;
      let apiCallFlag = false;

      if (requestData["dirty"] === true) {
        apiCallFlag = true;
        delete requestData["dirty"];
      }
      requestData["Id"] = localStorage.getItem("formId");
      requestData["isGeneric"] = true;
      if (apiCallFlag) {
        this.props.setCommonFields(requestData);
      }
    } else {
      this.setState({ isLoading: false });
    }
  };
  render() {
    const { errors } = this.state;
    const { noOfUBOs, genericDetails, noNonUboPep } = this.props;

    let uboinfo = [];
    for (let i = 0; i < noOfUBOs; i++) {
      uboinfo.push(
        <UboInfo
          index={i}
          baseName="ubox"
          reducer="commonFields"
          objBaseName={"ubo"}
          from="generic"
          errorMsg={this.state.UBOErrors}
          form={this.state.form}
        />
      );
    }
    let pepinfo = [];
    for (let i = 0; i < noNonUboPep; i++) {
      pepinfo.push(
        <PEPs
          index={i}
          baseName="pepx"
          reducer="commonFields"
          objBaseName={"pep"}
          errorMsg={this.state.PEPErrors}
          form={this.state.form}
        />
      );
    }

    let office = localStorage.getItem("Office");

    return (
      <>
        <AutoSaving />
        <div className="dashboard external-dashboard">
          <div className="generic-form">
            <div className="eventHeader pt-3 pb-3">
              <h2 className="m-0 p-0 pagetitle">Generic Form</h2>
            </div>
            {this.state.isLoading && <div className="loader"></div>}
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-6 pt-3">
                <CheckBox
                  label="Is there a identifiable UBO or UBO's in the structure?"
                  options={["Yes", "No"]}
                  name="isUBO"
                  info="UBO meaning Ultimate Beneficiary Owner"
                  chapter={chapter}
                  reducer={reducer}
                  dependentReducer={[
                    "commonFields",
                    getDependentReducer(office),
                  ]}
                  state={[
                    this.props.state.commonFields,
                    this.props.state[getDependentReducer(office)],
                  ]}
                  handleOtherData={true}
                  confirmation={
                    this.props.genericDetails.isUBO === "" ||
                    this.props.genericDetails.isUBO === null
                      ? false
                      : true
                  }
                />
                <div className="errorMsg">
                  <div>{errors.isUBO}</div>
                </div>
              </div>
              <div className="mb-2 col-lg-6 pt-3 pl-0">
                <CheckBox
                  label="Is there a Non-UBO PEP in the structure?"
                  options={["Yes", "No"]}
                  name="isPEP"
                  info="PEP meaning Politically Exposed Person"
                  chapter={chapter}
                  reducer={reducer}
                  dependentReducer={["commonFields"]}
                  state={[this.props.state.commonFields]}
                  confirmation={
                    this.props.genericDetails.isPEP === "" ||
                    this.props.genericDetails.isPEP === null
                      ? false
                      : true
                  }
                />

                <div className="errorMsg">
                  <div>{errors.isPEP}</div>
                </div>
              </div>
              <div className="col-lg-6">
                {genericDetails.isUBO === "Yes" && (
                  <Row>
                    <div className="mb-2 col-lg-12 pt-3">
                      <NumberInput
                        label="How many UBO are there?"
                        name="noOfUBOs"
                        referral={["ubox"]}
                        pushedObject={[UBOXArray]}
                        maxLimit={10}
                        reducer="commonFields"
                        isCommon={true}
                        objBaseName="ubo"
                        generateId="uboId"
                        deleteData={this.props.uboArray}
                      />
                      {/* <div className="errorMsg">
                        <div>{errors.isUBO}</div>
                      </div> */}
                    </div>
                    <div className="genericFormUBO">{uboinfo}</div>
                  </Row>
                )}
              </div>

              <div className="col-lg-6">
                {genericDetails.isPEP === "Yes" && (
                  <Row>
                    <div className="mb-2 col-lg-12 pt-3 pl-0">
                      <NumberInput
                        label="How many Non-UBO PEP's are there?"
                        name="noNonUboPep"
                        referral={["pepx"]}
                        pushedObject={[PEPXArray]}
                        maxLimit={10}
                        reducer="commonFields"
                        isCommon={true}
                        objBaseName="pep"
                        generateId="pepId"
                        deleteData={this.props.pepArray}
                      />
                      {/* <div className="errorMsg">
                        <div>{errors.isUBO}</div>
                      </div> */}
                    </div>
                    <div className="genericFormUBO">{pepinfo}</div>
                  </Row>
                )}
              </div>
            </Row>
          </div>
          <div
            className="mb-2 col-lg-12 text-center"
            style={{ paddingTop: "80px" }}
          >
            <button
              onClick={(e) => this.handleSubmit(e)}
              type="button"
              className="action-btn noicon pt-2 pb-2 pl-4 pr-4"
            >
              Proceed to Next
            </button>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    genericData: state.genericInformation.data,
    genericDetails: state.genericInformation.generic,
    noOfUBOs: state.commonFields.ubo["noOfUBOs"],
    noNonUboPep: state.commonFields.pep["noNonUboPep"],
    uboArray: state.commonFields.ubo.ubox,
    pepArray: state.commonFields.pep.pepx,
    commonFields: state.commonFields,
    state: state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setGenericDetails: (data) => dispatch(setGenericDetails(data)),
    setGenericDataToReducer: (data) => dispatch(setGenericDataToReducer(data)),
    getGenericDetails: (data) => dispatch(getGenericDetails(data)),
    updateClientInformation: (data) => dispatch(updateClientInformation(data)),
    globalStateNotChanged: () => dispatch(globalStateNotChanged()),
    globalStateChanged: () => dispatch(globalStateChanged()),
    setClientInfoFormDataToReducer: (data) =>
      dispatch(setClientInfoFormDataToReducer(data)),
    setCommonFields: (data) => dispatch(setCommonFields(data)),
    getCommonFields: (data) => dispatch(getCommonFields(data)),
    deleteUBO: (data) => dispatch(deleteUBO(data)),
    setNewCommonFieldsToReducer: (data) =>
      dispatch(setNewCommonFieldsToReducer(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GenericForm);
