import {
  GET_UBO_PEP_REQUEST,
  GET_UBO_PEP_SUCCESS,
  GET_UBO_PEP_FAIL,
  SEND_EMAIL_TO_UBO_FAIL,
  SEND_EMAIL_TO_UBO_REQUEST,
  SEND_EMAIL_TO_UBO_SUCCESS,
  RESEND_EMAIL_TO_UBO_REQUEST,
  RESEND_EMAIL_TO_UBO_SUCCESS,
  RESEND_EMAIL_TO_UBO_FAIL,
  CHANGE_STATUS_ON_NEXT_REQUEST,
  CHANGE_STATUS_ON_NEXT_SUCCESS,
  CHANGE_STATUS_ON_NEXT_FAIL,
  CHECK_FORM_STATUS_REQUEST,
  CHECK_FORM_STATUS_SUCCESS,
  CHECK_FORM_STATUS_FAIL,
} from "../constants/UBOPEPConstants";

const initialState = {
  isLoading: false,
  ubo: [],
  error: "",
};

const UBOPEPReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_UBO_PEP_REQUEST:
    case SEND_EMAIL_TO_UBO_REQUEST:
    case RESEND_EMAIL_TO_UBO_REQUEST:
    case CHANGE_STATUS_ON_NEXT_REQUEST:
    case CHECK_FORM_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: "",
      };

    case GET_UBO_PEP_SUCCESS:
    case SEND_EMAIL_TO_UBO_SUCCESS:
    case RESEND_EMAIL_TO_UBO_SUCCESS:
    case CHANGE_STATUS_ON_NEXT_SUCCESS:
    case CHECK_FORM_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };

    case GET_UBO_PEP_FAIL:
    case SEND_EMAIL_TO_UBO_FAIL:
    case RESEND_EMAIL_TO_UBO_FAIL:
    case CHANGE_STATUS_ON_NEXT_FAIL:
    case CHECK_FORM_STATUS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default UBOPEPReducer;
