import React from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";
import {
  setMaltaInternalCAFDetails,
  getMaltaInternalCAFDetails,
} from "../../../../../redux/actions/Malta/MaltaInternalCAFActions";
import "../../Onboarding/internalCAF.scss";
import { connect } from "react-redux";
import NotificationManager from "react-notifications/lib/NotificationManager";
import CheckBox from "../../../../elements/CheckBox";
import AutoSaving from "../AutoSaving";
import { chapValidationErrorMalta } from "../Constants";
import Risks from "../../Onboarding/Chapter1/Risks";
import ExternalDirectors from "../../Onboarding/Chapter6/ExternalDirectors";
import Agreements from "../../Onboarding/Chapter6/Agreements";
import SignedAgreements from "../../Onboarding/Chapter6/SignedAgreements";
import NonRecFees from "./NonRecFees";
import AnnualFees from "./AnnualFees";
import { setMaltaInternalChapterError } from "../../../../../redux/actions/Malta/MaltaInternalChapterErrorActions";
import getSequentialFields from "../getSequentialFields";

const chapter = "chapter6";
const reducer = "MaltaInternalCAF";
const externalReducer = "MaltaClientInformation";
let timer;

class Chapter6 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formErrorsChapter6: {},
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: "all",
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.getMaltaInternalCAFDetails(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          Object.keys(this.props.internalErrors.errors).length === 0 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationErrorMalta.forEach(async (element) => {
            let response =
              res?.data?.result?.cafInternalValidations[element.chapter];

            const newResponse = await Promise.resolve(
              getSequentialFields(response, element.chapter, false)
            );

            this.props.setMaltaInternalChapterError({
              form: `formErrors${element.chapter}`,
              errors: newResponse?.errorChapter,
            });
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;

    this.props.setMaltaInternalCAFDetails(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.props.ClientAcceptance.chapter6["dirty"] = false;
        let response = res.data.result;

        const newResponse = await Promise.resolve(
          getSequentialFields(response, chapter, true)
        );
        this.props.setMaltaInternalChapterError({
          errors: newResponse?.errorChapter,
          form: "formErrorschapter6",
        });
        if (!res.data.result.isValid) {
          NotificationManager.info(
            "All fields are mandatory to fill in",
            null,
            4000
          );
          timer = setTimeout(() => {
            return this.props.history.push({
              pathname: "/malta/consolidated-risk-assessment",
            });
          }, 4000);
        } else {
          NotificationManager.success("Form is submitted successfully.");

          return this.props.history.push({
            pathname: "/malta/consolidated-risk-assessment",
          });
        }
      }
    });
  };

  render() {
    const { isUnacceptableRisksIdentified, internalErrors } = this.props;
    const formErrorsChapter6 = JSON.stringify(internalErrors.errors);
    const errorsMsg = JSON.parse(formErrorsChapter6);

    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="html2pdf__page-break"></div>
          <div className="cafHeader">
            <h2 className="m-0">Business Acceptance Form</h2>
          </div>
          <div className="internal_caf generic-form">
            <h3 className="chapterttl">Agreements - Requested services</h3>
            <b>Which trust services are being offered?</b>
            <Agreements
              chapter={chapter}
              reducer={reducer}
              errors={formErrorsChapter6}
              isRequired={true}
              form="Malta"
              PdfGeneration={this.props.PdfGeneration}
            />
            <SignedAgreements
              chapter={chapter}
              reducer={reducer}
              errors={formErrorsChapter6}
              isRequired={true}
              form="Malta"
            />
            <div className="html2pdf__page-break"></div>
            <h4 className="col-md-12 sub-chapter-title pl-0 mb-2">Fees</h4>
            <NonRecFees
              errors={formErrorsChapter6}
              isRequired={true}
              chapter={chapter}
              reducer={reducer}
              PdfGeneration={this.props.PdfGeneration}
              form="Malta"
            />
            <AnnualFees
              errors={formErrorsChapter6}
              isRequired={true}
              chapter={chapter}
              reducer={reducer}
              PdfGeneration={this.props.PdfGeneration}
              form="Malta"
            />
            <ExternalDirectors
              chapter={chapter}
              reducer={reducer}
              externalReducer={externalReducer}
              errors={formErrorsChapter6}
              form="Malta"
              isRequired={true}
              PdfGeneration={this.props.PdfGeneration}
            />
            <div className="html2pdf__page-break"></div>
            <Risks
              chapter={chapter}
              reducer={reducer}
              errors={formErrorsChapter6}
              form="Malta"
              riskLabel="Services risk rating"
              questionLabel="How many service related risks can be identified?"
              boxLabel="Risk"
              isRequired={true}
              PdfGeneration={this.props.PdfGeneration}
            />

            <Row className="mb-3">
              <div className="col-lg-12">
                Brief description of the purpose of the services to be provided
                by the UIM office. As well as a description of the potential
                integrity risks.
              </div>
            </Row>
            <Row className="mb-3">
              <div className="col-lg-12">
                <CheckBox
                  label="Unacceptable risks can be identified?"
                  options={["Yes", "No"]}
                  name="isUnacceptableRisksIdentified"
                  id="isUnacceptableRisksIdentified"
                  chapter={chapter}
                  reducer={reducer}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.isUnacceptableRisksIdentified?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isUnacceptableRisksIdentified}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="mb-3">
              {isUnacceptableRisksIdentified === "Yes" && (
                <div className="col-lg-12">
                  <TextBox
                    label="Please describe"
                    placeholder="Enter your text"
                    name="unacceptableRisksReason"
                    id="unacceptableRisksReason"
                    isRequired={true}
                    chapter={chapter}
                    reducer={reducer}
                    isMultipleLines={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtArea"
                        : "form-control"
                    }
                  />
                  {errorsMsg &&
                    errorsMsg?.isSubmitted === true &&
                    errorsMsg?.unacceptableRisksReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.unacceptableRisksReason}
                      </span>
                    )}
                </div>
              )}
              {isUnacceptableRisksIdentified === "No" && (
                <div className="col-md-12">
                  <b>
                    With respect to the intended services, UIM sees no reason to
                    discontinue the set-up of a business relationship
                  </b>
                </div>
              )}
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    chapter6: state.MaltaClientInformation.chapter6,
    ClientAcceptance: state.MaltaInternalCAF,
    isUnacceptableRisksIdentified:
      state.MaltaInternalCAF.chapter6.isUnacceptableRisksIdentified,
    commonFields: state.commonFields,
    data: state,
    internalErrors: state.MaltaInternalChapterErrors.formErrorschapter6,
    errors: state.MaltaInternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMaltaInternalCAFDetails: async (data) =>
      dispatch(await getMaltaInternalCAFDetails(data)),
    setMaltaInternalCAFDetails: async (data) =>
      dispatch(await setMaltaInternalCAFDetails(data)),
    setMaltaInternalChapterError: (data) =>
      dispatch(setMaltaInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter6);
