import React, { Component } from "react";
import { connect } from "react-redux";
import { setLuxembourgInternalCAFDetails } from "../../../../redux/actions/Luxembourg/LuxembourgInternalCAFActions";
import { setLuxembourgInternalChapterError } from "../../../../redux/actions/Luxembourg/LuxembourgInternalChapterErrors";
import getSequentialFields from "./getSequentialFields";
class AutoSaving extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  apiCall = () => {
    //api call for chapters data
    let requestData = {};
    let apiCallFlag = false;
    let chapter = this.props.chapter;
    let data = this.props.ClientAcceptance[chapter];
    if (data["dirty"] === true) {
      apiCallFlag = true;
      delete data["dirty"];
    }
    if (apiCallFlag) {
      let user_data = JSON.parse(localStorage.getItem("formData"));
      requestData["id"] = user_data.id;
      requestData["userId"] = user_data.userId;
      if (chapter === "chapter3") {
        requestData[chapter] = data;
        requestData["commonFields"] = this.props.commonFields;
        requestData["cafExternalChap3"] = this.props.clientInformation[chapter];
      } else if (chapter === "chapter4") {
        requestData[chapter] = data;
        requestData["commonFields"] = this.props.commonFields;
        requestData["cafExternalChap4"] = this.props.clientInformation[chapter];
      } else {
        requestData[chapter] = data;
      }

      this.props
        .setLuxembourgInternalCAFDetails(requestData)
        .then(async (res) => {
          if (res.data.portalErrorCode === 200) {
            this.props.ClientAcceptance[this.props.chapter]["dirty"] = false;
            let response = res.data?.result;

            if (
              Object.keys(
                this.props.internalErrors[`formErrors${chapter}`]?.errors
              ).length > 0 &&
              this.props.internalErrors[`formErrors${chapter}`]?.errors
                ?.isSubmitted === true
            ) {
              const newResponse = await Promise.resolve(
                getSequentialFields(response, chapter, true)
              );
              this.props.setLuxembourgInternalChapterError({
                form: `formErrors${this.props.chapter}`,
                errors: newResponse?.errorChapter,
              });
            } else {
              const newResponse = await Promise.resolve(
                getSequentialFields(response, chapter, false)
              );
              this.props.setLuxembourgInternalChapterError({
                form: `formErrors${this.props.chapter}`,
                errors: newResponse?.errorChapter,
              });
            }
          }
        });
    }
  };

  componentDidMount = () => {
    this.apiCall();
    setInterval(this.apiCall, 5000);
  };

  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    ClientAcceptance: state.LuxembourgInternalCAF,
    commonFields: state.commonFields,
    clientInformation: state.LuxembourgClientInformation,
    internalErrors: state.LuxembourgInternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLuxembourgInternalCAFDetails: async (data) =>
      dispatch(await setLuxembourgInternalCAFDetails(data)),
    setLuxembourgInternalChapterError: (data) =>
      dispatch(setLuxembourgInternalChapterError(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AutoSaving);
