export const IntermediaryObjMalta = {
  isClientReal: "",
  isNaturPerInterm: null,
  isNaturPerAgent: null,
  clientRepNationality: null,
  clientRepName: null,
  clientRepPassport: [],
  activeInCountry: [],
  legalEntityName: null,
  cocExtract: [],
  isPOA: "",
  poa: [],
  noPOAReason: null,
  isUBOIdentifyVerify: "",
  uboIdentityPassport: [],
  utilityBill: [],
  address: null,
  addressUboInt: null,
  utilityBillUboInt: [],
  noUBOIdentifyVerifyReason: null,
};

export const Chapter2 = {
  isClientRepInvolved: "",
  clientRepNum: 1,
  intermediaryDetails: [IntermediaryObjMalta],
  dirty: false,
};
