const setGenericDataReceiveData = (json) => {
  return {
    type: "UPDATE_GENERIC_REDUCER_DATA",
    data: json,
  };
};

export const setGenericDataToReducer = (data) => {
  return (dispatch) => {
    dispatch(setGenericDataReceiveData(data));
  };
};
