import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import countryList from "react-select-country-list";
import CheckBox from "../../../../../../elements/CheckBox";
import DropDown from "../../../../../../elements/DropDown";
import FileUpload from "../../../../../../elements/FileUpload";
import NumberInput from "../../../../../../elements/NumberInput";
import PhoneInputField from "../../../../../../elements/PhoneInputField";
import TextBox from "../../../../../../elements/TextBox";
import { UPCDir,UPCXArray } from "../../../../../../JSONs/UK-Form/External/Client-Information/Chapter3";
import { fileAcceptType } from "../constants";
import UPCDirectors from "./UPCDirectors";
import UPCDetails from "./UPCDetails";

class UPC extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isUPCExists, chapter, isForceCloseFinAcc, noOfUPCDir, errorMsg, arethereExistingUPCDir,noOfUPCs} =
    this.props;
    const eMsg = JSON.parse(errorMsg);
    const upcinfo = [];
    for (let i = 0; i < noOfUPCs; i++) {
      upcinfo.push(
         <UPCDetails
          index={i}
          baseName="upcDetails"
          reducer={this.props.reducer}
          form="UK"
          eMsg={errorMsg}
        />
      );
    }
    return (
      <>
        <Row className="pt-3 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label={this.props.form === "UK" ? "Does the parent company/trust exist?" : "Does the Ultimate parent company exist?"} 
              name="isUPCExists"
              id="isUPCExists"
              options={["Yes", "No"]}
              chapter={chapter}
              reducer={this.props.reducer}
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {eMsg && eMsg?.isUPCExists && eMsg?.isUPCExists?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {eMsg?.isUPCExists}
              </span>
            )}
          </div>
          {isUPCExists && isUPCExists === "No" && (
             <div className="mb-3 col-lg-4">
               <TextBox
                  label="Explanation"
                  name="upcExplanation"
                  id="upcExplanation"
                  chapter={this.props.chapter}
                  placeholder="enter your text"
                  reducer={this.props.reducer}
                  validationType="Text"
                  isRequired={this.props.form === "UK" ? true : false} 
                />
                {eMsg && eMsg?.upcExplanation && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {eMsg?.upcExplanation}
              </span>
            )}
             </div>
             
           
        )}
        </Row>
       
        {isUPCExists && isUPCExists === "Yes" && (
          <>
            <Row className="pt-2 pb-2">
            <div className="mb-4 col-lg-4">
               <NumberInput
                        label="How many parent companies/trusts are there?"
                        name="noOfUPCs"
                        chapter="chapter3"
                        referral={["upcDetails"]}
                        pushedObject={[UPCXArray]}
                        maxLimit={10}
                        reducer={this.props.reducer}
                      />
          <div className="errorMsg"></div>
        </div>
        {upcinfo}
            </Row>
            {/* <Row className="pt-2 pb-2"> */}
              {/* <div className="mb-2 col-lg-6 col-xl-4">
                <TextBox
                  label="Name of UPC"
                  info="Ultimate Parent Company"
                  name="nameUPC"
                  id="nameUPC"
                  chapter={this.props.chapter}
                  placeholder="enter your text"
                  objBaseName={this.props.objBaseName}
                  reducer={this.props.reducer}
                  validationType="Text"
                  isRequired={this.props.form === "UK" ? true : false} 
                />
                {eMsg && eMsg?.nameUPC && eMsg?.nameUPC?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg?.nameUPC}
                  </span>
                )}
              </div> */}
              {/* <div className="mb-2 col-lg-6 col-xl-4">
                <TextBox
                  label="Registered address"
                  name="addressRegistered"
                  id="addressRegistered"
                  placeholder="enter your text"
                  objBaseName={this.props.objBaseName}
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                  isRequired={this.props.form === "UK" ? true : false} 
                />
                {eMsg &&
                  eMsg?.addressRegistered &&
                  eMsg?.addressRegistered?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.addressRegistered}
                    </span>
                  )}
              </div> */}
              {/* <div className="mb-2 col-lg-6 col-xl-4">
                <TextBox
                  label="Postal code"
                  name="postalCode"
                  id="postalCode"
                  chapter={this.props.chapter}
                  placeholder="enter your text"
                  objBaseName={this.props.objBaseName}
                  reducer={this.props.reducer}
                  validationType="Text"
                 />
                {eMsg && eMsg?.postalCode && eMsg?.postalCode?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg?.postalCode}
                  </span>
                )}
              </div> */}
              {/* <div className="mb-2 col-lg-6 col-xl-4">
                <TextBox
                  label="City"
                  name="city"
                  id="city"
                  placeholder="enter your text"
                  chapter={this.props.chapter}
                  objBaseName={this.props.objBaseName}
                  reducer={this.props.reducer}
                  validationType="Text"
                  isRequired={this.props.form === "UK" ? true : false} 
                />
                {eMsg && eMsg?.city && eMsg?.city?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg?.city}
                  </span>
                )}
              </div> */}
              {/* <div className="mb-2 col-lg-6 col-xl-4">
                <DropDown
                  label="Country"
                  name="country"
                  id="country"
                  chapter={this.props.chapter}
                  options={countryList().getData()}
                  reducer={this.props.reducer}
                  objBaseName={this.props.objBaseName}
                  isRequired={this.props.form === "UK" ? true : false} 
                />
                {eMsg && eMsg?.country && eMsg?.country?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg?.country}
                  </span>
                )}
              </div> */}
              {/* <div className="mb-2 col-lg-6 col-xl-4">
                <TextBox
                  label="Website"
                  name="websiteUPC"
                  id="websiteUPC"
                  chapter={this.props.chapter}
                  placeholder="enter your text"
                  objBaseName={this.props.objBaseName}
                  reducer={this.props.reducer}
                  />
                {eMsg && eMsg?.websiteUPC && eMsg?.websiteUPC?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg?.websiteUPC}
                  </span>
                )}
              </div> */}
              {/* <div className="mb-2 col-lg-6 col-xl-4">
                <TextBox
                  label="Tax-number"
                  name="taxNum"
                  id="taxNum"
                  placeholder="enter your text"
                  objBaseName={this.props.objBaseName}
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                />
                {eMsg && eMsg?.taxNum && eMsg?.taxNum?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg?.taxNum}
                  </span>
                )}
              </div> */}
              {/* <div className="mb-2 col-lg-6 col-xl-4">
                <TextBox
                  label="Trade register number"
                  chapter={this.props.chapter}
                  name="tradeRegisterNum"
                  id="tradeRegisterNum"
                  placeholder="enter your text"
                  objBaseName={this.props.objBaseName}
                  reducer={this.props.reducer}
                  validationType="Text"
                  isRequired={this.props.form === "UK" ? true : false} 
                />
                {eMsg &&
                  eMsg?.tradeRegisterNum &&
                  eMsg?.tradeRegisterNum?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.tradeRegisterNum}
                    </span>
                  )}
              </div> */}
              {/* <div className="mb-2 col-lg-6 col-xl-4" id="phoneNumberUPC">
                <PhoneInputField
                  label="Phone number"
                  name="phoneNumberUPC"
                  placeholder="enter your text"
                  chapter={this.props.chapter}
                  objBaseName={this.props.objBaseName}
                  reducer={this.props.reducer}
                  country={this.props.form}
                />
                {eMsg &&
                  eMsg?.phoneNumberUPC &&
                  eMsg?.phoneNumberUPC?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.phoneNumberUPC}
                    </span>
                  )}
              </div> */}
              {/* <div className="mb-2 col-lg-6 col-xl-4">
                <DropDown
                  label="Country of Tax Residence"
                  name="countryOfTaxRsdnc"
                  id="countryOfTaxRsdnc"
                  chapter={this.props.chapter}
                  options={countryList().getData()}
                  reducer={this.props.reducer}
                  objBaseName={this.props.objBaseName}
                  isRequired={this.props.form === "UK" ? true : false} 
                />
                {eMsg &&
                  eMsg?.countryOfTaxRsdnc &&
                  eMsg?.countryOfTaxRsdnc?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.countryOfTaxRsdnc}
                    </span>
                  )}
              </div> */}
            {/* </Row> */}
            {/* <Row>
              <div className="mb-3 col-lg-6 col-xl-4">
                <CheckBox
                  label="Has the UPC ever been rejected or forced to close accounts with any bank or financial institution?"
                  options={["Yes", "No"]}
                  name="isForceCloseFinAcc"
                  id="isForceCloseFinAcc"
                  chapter={this.props.chapter}
                  objBaseName={this.props.objBaseName}
                  reducer={this.props.reducer}
                  isRequired={this.props.form === "UK" ? true : false} 
                />
                {eMsg &&
                  eMsg?.isForceCloseFinAcc &&
                  eMsg?.isForceCloseFinAcc?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.isForceCloseFinAcc}
                    </span>
                  )}
              </div>
              {isForceCloseFinAcc === "Yes" && (
                <div className="mb-2 col-lg-6 col-xl-4">
                  <TextBox
                    label="Please specify a reason"
                    placeholder="enter a text"
                    name="forceCloseFinAcc"
                    id="forceCloseFinAcc"
                    chapter={this.props.chapter}
                    objBaseName={this.props.objBaseName}
                    reducer={this.props.reducer}
                    validationType="Text"
                    isRequired={this.props.form === "UK" ? true : false} 
                  />
                  {eMsg &&
                    eMsg?.forceCloseFinAcc &&
                    eMsg?.forceCloseFinAcc?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg?.forceCloseFinAcc}
                      </span>
                    )}
                </div>
              )}
            </Row> */}
            {/* <Row >
            <div className="mb-3 col-lg-6 col-xl-4">
            <CheckBox
                  label="Are there existing Director(s)?"
                  options={["Yes", "No"]}
                  name="arethereExistingUPCDir"
                  id="arethereExistingUPCDir"
                  chapter={this.props.chapter}
                  objBaseName={this.props.objBaseName}
                  reducer={this.props.reducer}
                  isRequired={this.props.form === "UK" ? true : false}
                />
                {eMsg &&
                    eMsg?.arethereExistingUPCDir &&
                    eMsg?.arethereExistingUPCDir?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg?.arethereExistingUPCDir}
                      </span>
                    )}
              </div>
            </Row>     */}
            {/* {arethereExistingUPCDir === "Yes" && (      
            <Row className="pt-4 pb-2">
              <div className="mb-2 col-lg-6 col-xl-4">
                <NumberInput
                  label="How many directors would you like to add?"
                  name="noOfUPCDir"
                  referral={["upcDirectors"]}
                  pushedObject={[UPCDir]}
                  maxLimit={100}
                  id="noOfUPCDir"
                  chapter={this.props.chapter}
                  objBaseName={this.props.objBaseName}
                  reducer={this.props.reducer}
                />
              </div>
              {dirs}
            </Row>
            )} */}
            {/* <Row className="pt-4 pb-2">
              <div className="mb-2 col-lg-6 col-xl-4">
                <FileUpload
                  label="Extract Chamber of Commerce"
                  acceptType={fileAcceptType}
                  name="extractChamberOfCommerce"
                  id="extractChamberOfCommerce"
                  chapter={this.props.chapter}
                  objBaseName={this.props.objBaseName}
                  reducer={this.props.reducer}
                />
                {eMsg &&
                  eMsg?.extractChamberOfCommerce &&
                  eMsg?.extractChamberOfCommerce?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.extractChamberOfCommerce}
                    </span>
                  )}
              </div>

              <div className="mb-2 col-lg-6 col-xl-4">
                <FileUpload
                  label="Deed of incorporation/ Articles of association"
                  acceptType={fileAcceptType}
                  name="deedOfIncorporation"
                  id="deedOfIncorporation"
                  reducer={this.props.reducer}
                  objBaseName={this.props.objBaseName}
                  chapter={this.props.chapter}
                />
                {eMsg &&
                  eMsg?.deedOfIncorporation &&
                  eMsg?.deedOfIncorporation?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.deedOfIncorporation}
                    </span>
                  )}
              </div>

              <div className="mb-2 col-lg-6 col-xl-4">
                <FileUpload
                  label="Shareholders’ register"
                  acceptType={fileAcceptType}
                  name="shareholdersRegister"
                  id="shareholdersRegister"
                  reducer={this.props.reducer}
                  chapter={this.props.chapter}
                  objBaseName={this.props.objBaseName}
                />
                {eMsg &&
                  eMsg?.shareholdersRegister &&
                  eMsg?.shareholdersRegister?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.shareholdersRegister}
                    </span>
                  )}
              </div>

              <div className="mb-2 col-lg-6 col-xl-4">
                <FileUpload
                  label="if applicable, Shareholders’ certificates"
                  acceptType={fileAcceptType}
                  name="shareholdersCertificates"
                  id="shareholdersCertificates"
                  reducer={this.props.reducer}
                  chapter={this.props.chapter}
                  objBaseName={this.props.objBaseName}
                />
                {eMsg &&
                  eMsg?.shareholdersCertificates &&
                  eMsg?.shareholdersCertificates?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.shareholdersCertificates}
                    </span>
                  )}
              </div>

              <div className="mb-2 col-lg-6 col-xl-4">
                <FileUpload
                  label="Nominee agreement/ Declaration of trust"
                  acceptType={fileAcceptType}
                  name="nomineeAgreement"
                  id="nomineeAgreement"
                  reducer={this.props.reducer}
                  chapter={this.props.chapter}
                  objBaseName={this.props.objBaseName}
                />
                {eMsg &&
                  eMsg?.nomineeAgreement &&
                  eMsg?.nomineeAgreement?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.nomineeAgreement}
                    </span>
                  )}
              </div>

              <div className="mb-2 col-lg-6 col-xl-4">
                <FileUpload
                  label="Financial statements of the past three years"
                  acceptType={fileAcceptType}
                  name="financialStatementsThreeYears"
                  id="financialStatementsThreeYears"
                  objBaseName={this.props.objBaseName}
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                />
                {eMsg &&
                  eMsg?.financialStatementsThreeYears &&
                  eMsg?.financialStatementsThreeYears?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.financialStatementsThreeYears}
                    </span>
                  )}
              </div>
            </Row> */}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer, chapter } = ownProps;
  return {
   // arethereExistingUPCDir:state[reducer][chapter]["upc"]["arethereExistingUPCDir"],
  //  noOfUPCDir: state[reducer][chapter]["upc"]["noOfUPCDir"],
    noOfUPCs: state[reducer][chapter]["noOfUPCs"],
    isUPCExists: state[reducer][chapter]["isUPCExists"],
    //isForceCloseFinAcc: state[reducer][chapter]["upc"]["isForceCloseFinAcc"],

  };
};

export default connect(mapStateToProps, null)(UPC);
