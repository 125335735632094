import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import countryList from "react-select-country-list";
import CheckBox from "../../../../elements/CheckBox";
import DateTimePicker from "../../../../elements/DateTimePicker";
import DropDown from "../../../../elements/DropDown";
import PhoneInputField from "../../../../elements/PhoneInputField";
import TextBox from "../../../../elements/TextBox";

class OCSecretary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isCmpSecExist, errors } = this.props;
    const errorMsg = JSON.parse(errors);
    return (
      <Row className="pt-3 pb-2">
        <div className="mb-3 col-lg-4">
          <CheckBox
            label="Is there a company secretary?"
            options={["Yes", "No"]}
            name="isCmpSecExist"
            id="isCmpSecExist"
            objBaseName="cmpSectr"
            chapter={this.props.chapter}
            reducer={this.props.externalReducer}
            disabled={true}
          />
        </div>
        {isCmpSecExist === "Yes" && (
          <>
            <div className="mb-3 col-lg-8">
              <Row>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Name of the company secretary"
                    name="cmpSectName"
                    id={`cmpSectName`}
                    placeholder="enter your text"
                    objBaseName="cmpSectr"
                    chapter={this.props.chapter}
                    reducer={this.props.externalReducer}
                    validationType="Text"
                    disabled={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtBox"
                        : "form-control"
                    }
                  />
                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Residental address"
                    name="cmpSecResAddress"
                    id={`cmpSecResAddress`}
                    placeholder="enter your text"
                    objBaseName="cmpSectr"
                    chapter={this.props.chapter}
                    reducer={this.props.externalReducer}
                    validationType="Text"
                    disabled={true}
                  />
                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Passport/Identity/Registration No."
                    name="cmpPassIdntyRegNum"
                    id={`cmpPassIdntyRegNum`}
                    placeholder="enter your text"
                    objBaseName="cmpSectr"
                    chapter={this.props.chapter}
                    reducer={this.props.externalReducer}
                    validationType="Text"
                    disabled={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtBox"
                        : "form-control"
                    }
                  />
                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Place of issue"
                    name="cmpPlaceOfIss"
                    id={`cmpPlaceOfIss`}
                    placeholder="enter your text"
                    objBaseName="cmpSectr"
                    chapter={this.props.chapter}
                    reducer={this.props.externalReducer}
                    validationType="Text"
                    disabled={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtBox"
                        : "form-control"
                    }
                  />
                </div>
                <div className="mb-3 col-lg-6" id={`cmpDtIssue`}>
                  <DateTimePicker
                    label="Date of issue"
                    name="cmpDtIssue"
                    chapter={this.props.chapter}
                    objBaseName="cmpSectr"
                    reducer={this.props.externalReducer}
                    disabled={true}
                  />
                </div>
                <div className="mb-3 col-lg-6" id={`cmpExprDt`}>
                  <DateTimePicker
                    label="Expiry date"
                    name="cmpExprDt"
                    maxDate={true}
                    chapter={this.props.chapter}
                    objBaseName="cmpSectr"
                    reducer={this.props.externalReducer}
                    disabled={true}
                  />
                </div>
                <div className="mb-3 col-lg-6" id={`cmpTelPhnNum`}>
                  <PhoneInputField
                    label="Telephone No."
                    name="cmpTelPhnNum"
                    chapter={this.props.chapter}
                    objBaseName="cmpSectr"
                    reducer={this.props.externalReducer}
                    disabled={true}
                    PdfGeneration={this.props.PdfGeneration}
                  />
                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Email address"
                    name="cmpEmail"
                    id={`cmpEmail`}
                    placeholder="enter your text"
                    objBaseName="cmpSectr"
                    chapter={this.props.chapter}
                    reducer={this.props.externalReducer}
                    validationType="Email"
                    disabled={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtBox"
                        : "form-control"
                    }
                  />
                </div>
                <div className="mb-3 col-lg-6">
                  <DropDown
                    label="Nationality"
                    name="cmpSecNationality"
                    options={countryList().getData()}
                    id={`cmpSecNationality`}
                    objBaseName="cmpSectr"
                    chapter={this.props.chapter}
                    reducer={this.props.externalReducer}
                    disabled={true}
                  />
                </div>
                <div className="mb-3 col-lg-12">
                  <TextBox
                    label="Please enter comment"
                    name="compSecCmmnt"
                    id={`compSecCmmnt`}
                    placeholder="enter your text"
                    chapter={this.props.chapter}
                    reducer={this.props.reducer}
                    validationType="Text"
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtBox"
                        : "form-control"
                    }
                  />
                  {errorMsg &&
                    errorMsg?.compSecCmmnt &&
                    errorMsg?.compSecCmmnt?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorMsg?.compSecCmmnt}
                      </span>
                    )}
                </div>
              </Row>
            </div>
          </>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { externalReducer } = ownProps;
  return {
    isCmpSecExist:
      state[externalReducer]["chapter4"]["cmpSectr"]["isCmpSecExist"],
  };
};

export default connect(mapStateToProps, null)(OCSecretary);
