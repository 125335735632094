import React from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";
import {
  setUKInternalCAFDetails,
  getUKInternalCAFDetails,
} from "../../../../../redux/actions/UK/UKInternalCAFActions";
import "../../Onboarding/internalCAF.scss";
import AutoSaving from "../AutoSaving";
import { connect } from "react-redux";
import NotificationManager from "react-notifications/lib/NotificationManager";
import EntityDetails from "./EntityDetails";
import BillingAddress from "./BillingAddress";
import SetUpFee from "./SetUpFee";
import AnnualManFee from "./AnnualManFee";
import VAT from "./VAT";
import { setUKInternalChapterError } from "../../../../../redux/actions/UK/UKInternalChapterErrorActions";
import getSequentialFields from "../getSequentialFields";
import { chapValidationErrorUK } from "../constants";
import { formValid } from "../../Onboarding/formValid";
import { HashLink } from "react-router-hash-link";

const chapter = "chapter11";
const reducer = "UKInternalCAF";

let timer;

class Chapter11 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idLoading: false,
      formErrorsChapter11: {},
      isCAFValid: null,
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: "all",
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.getUKInternalCAFDetails(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationErrorUK.forEach(async (element) => {
            if (element.chapter !== "chapter11") {
              let response =
                res?.data?.result?.cafInternalValidations[element.chapter];

              const newResponse = await Promise.resolve(
                getSequentialFields(response, element.chapter, true)
              );

              this.props.setUKInternalChapterError({
                form: `formErrors${element.chapter}`,
                errors: newResponse?.errorChapter,
              });
            }
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;

    this.props.setUKInternalCAFDetails(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.props.ClientAcceptance.chapter4["dirty"] = false;
        let response = res.data.result;

        const newResponse = await Promise.resolve(
          getSequentialFields(response, chapter, true)
        );
        this.props.setUKInternalChapterError({
          errors: newResponse?.errorChapter,
          form: "formErrorschapter11",
        });
        chapValidationErrorUK.forEach((element) => {
          element.isvalid =
            this.props.chapterErrors[
              `formErrors${element.chapter}`
            ]?.errors?.isValid;
        });
        if (!res.data.result.isCAFValid) {
          this.setState({ isCAFValid: false });
          NotificationManager.error(
            "Please fill up all the details",
            null,
            4000
          );
        } else {
          this.setState({ isCAFValid: true });

          NotificationManager.success("Form is submitted successfully.");

          return this.props.history.push({
            pathname: "/united-kingdom/signature",
          });
        }
      }
    });
  };

  render() {
    const { internalErrors } = this.props;
    const formErrorsChapter11 = JSON.stringify(internalErrors.errors);
    const errorsMsg = JSON.parse(formErrorsChapter11);
    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="cafHeader">
            <h2 className="m-0">Client Acceptance Form | Formal Acceptance</h2>
          </div>
          <div className="internal_caf generic-form">
            <div className="html2pdf__page-break"></div>
            <h3 className="chapterttl">Business Line Sign-Off</h3>
            <EntityDetails
              reducer={reducer}
              chapter={chapter}
              errors={formErrorsChapter11}
              PdfGeneration={this.props.PdfGeneration}
            />
            <Row>
              <BillingAddress
                reducer={reducer}
                chapter={chapter}
                objBaseName="billingAddress"
                errors={formErrorsChapter11}
                PdfGeneration={this.props.PdfGeneration}
              />
            </Row>
            <Row>
              <SetUpFee
                reducer={reducer}
                chapter={chapter}
                objBaseName="setUpFees"
                errors={formErrorsChapter11}
                PdfGeneration={this.props.PdfGeneration}
              />
            </Row>
            <Row>
              <AnnualManFee
                reducer={reducer}
                chapter={chapter}
                objBaseName="annualManagementFees"
                errors={formErrorsChapter11}
                PdfGeneration={this.props.PdfGeneration}
              />
            </Row>

            <Row>
              <VAT
                reducer={reducer}
                chapter={chapter}
                objBaseName="vat"
                errors={formErrorsChapter11}
              />
            </Row>
            <Row>
              <div className="mb-3 col-lg-12">
                <TextBox
                  label="Additional Information"
                  placeholder="Enter your text"
                  name="additionalInfo"
                  id="additionalInfo"
                  isMultipleLines={true}
                  reducer={reducer}
                  chapter={chapter}
                  validationType="Text"
                  //isRequired={true}
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtArea"
                      : "form-control"
                  }
                />{" "}
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.additionalInfo?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.additionalInfo}
                    </span>
                  )}
              </div>
            </Row>
            {this.state.isCAFValid === false ? (
              <div className="internalErrorSection">
                <h3>
                  All the fields from the chapters are mandatory, please fill
                  the unfilled fields.
                </h3>
                {chapValidationErrorUK
                  .filter((element) => element.isvalid === false)
                  .map((element) => {
                    const res = formValid(
                      this.props.chapterErrors[`formErrors${element.chapter}`]
                        .errors
                    );
                    return (
                      <HashLink to={`/united-kingdom/${element.path}#${res}`}>
                        <i className="fa fa-arrow-circle-o-right fa-fw"></i>
                        {element.label} has unfilled fields
                      </HashLink>
                    );
                  })}
              </div>
            ) : null}
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ClientAcceptance: state.UKInternalCAF,
    data: state,
    internalErrors: state.UKInternalChapterError.formErrorschapter11,
    chapterErrors: state.UKInternalChapterError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUKInternalCAFDetails: async (data) =>
      dispatch(await getUKInternalCAFDetails(data)),
    setUKInternalCAFDetails: async (data) =>
      dispatch(await setUKInternalCAFDetails(data)),
    setUKInternalChapterError: (data) =>
      dispatch(setUKInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter11);
