import React, { Component } from "react";
import { connect } from "react-redux";
import { setDataToReducer } from "../../redux/actions/ClientInformationAction";
import { getReducer } from "./getReducer";
import { checkValidation } from "./Validations";
import Tooltip from "./Tooltip";

class TextBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      chapter: this.props.chapter,
      errorMsg: "",
    };
  }

  componentDidMount = () => {
    this.setState({ value: this.props.value });
    if (this.props.defaultValue) {
      this.props.setDataToReducer({
        type: getReducer(this.props.reducer),
        chapter: this.props.chapter,
        index: this.props.index,
        name: this.props.name,
        value: this.props.defaultValue,
        baseName: this.props.baseName,
        objBaseName: this.props.objBaseName,
        nestedObjBaseName: this.props.nestedObjBaseName,
        indexObjBaseName:this.props.indexObjBaseName,
        noChapter: this.props.noChapter,
      });
    }
  };

  UNSAFE_componentWillReceiveProps = (nextProps, prevState) => {
    if (nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value });
    }
  };

  //needs to be check for local and reducer state update
  handleChange = (event) => {
     let errorMessage = checkValidation(
      this.props.validationType,
      event.target.value
    );
    this.setState({
      errorMsg: errorMessage,
    });

    if (this.props.validationType === "Email") {
      this.setState({ value: event.target.value });
      this.props.setDataToReducer({
        type: getReducer(this.props.reducer),
        chapter: this.props.chapter,
        index: this.props.index,
        name: this.props.name,
        value: event.target.value,
        baseName: this.props.baseName,
        objBaseName: this.props.objBaseName,
        nestedObjBaseName: this.props.nestedObjBaseName,
        indexObjBaseName:this.props.indexObjBaseName,
        noChapter: this.props.noChapter,
      });
    } else if (errorMessage.length === 0) {
      this.setState({ value: event.target.value });
      this.props.setDataToReducer({
        type: getReducer(this.props.reducer),
        chapter: this.props.chapter,
        index: this.props.index,
        name: this.props.name,
        value: event.target.value,
        baseName: this.props.baseName,
        objBaseName: this.props.objBaseName,
        nestedObjBaseName: this.props.nestedObjBaseName,
        indexObjBaseName:this.props.indexObjBaseName,
        noChapter: this.props.noChapter,
      });
    }
  };

  render() {
    const {
      label,
      placeholder,
      name,
      id,
      isMultipleLines,
      info,
      className,
      hideLable,
      isRequired,
    } = this.props;
    return (
      <>
        {!hideLable && (
          <label>
            {label} {isRequired && <span style={{ color: "red" }}>*</span>}
            {info && <Tooltip text={info}></Tooltip>}
          </label>
        )}

        {isMultipleLines ? (
          <>
            <textarea
              type="text"
              name={name}
              placeholder={placeholder}
              className={className ? className : "form-control"}
              onChange={(e) => this.handleChange(e)}
              value={this.state.value || ""}
              autoComplete="off"
              id={id}
              disabled={this.props.disabled}
            />
          </>
        ) : (
          <>
            <input
              type="text"
              name={name}
              placeholder={placeholder}
              className={className ? className : "form-control"}
              onChange={(e) => this.handleChange(e)}
              value={this.state.value || ""}
              autoComplete="off"
              id={id}
              disabled={this.props.disabled}
            />
          </>
        )}
        {this.props.ShowValidation && this.state.errorMsg.length > 0 && (
          <span id="onChangeError" style={{ color: "red", fontSize: "12px" }}>
            {this.state.errorMsg}
          </span>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    name,
    chapter,
    baseName,
    index,
    objBaseName,
    reducer,
    nestedObjBaseName,
    isCommon,
    noChapter,
    indexObjBaseName
  } = ownProps;

  if (isCommon) {
    if (!objBaseName && !baseName) {
      return {
        value: state[reducer][name],
      };
    } else if (objBaseName && nestedObjBaseName) {
      return {
        value: state[reducer][objBaseName][nestedObjBaseName][name],
      };
    } else if (objBaseName && !baseName) {
      return {
        value: state[reducer][objBaseName][name],
      };
    } else if (objBaseName && baseName) {
      return {
        value: state[reducer][objBaseName][baseName][index][name],
      };
    }
  } else if (noChapter) {
    return {
      value: state[reducer][name],
    };
  } else if (!baseName && !objBaseName) {
    return {
      value: state[reducer][chapter][name],
    };
  } else if (objBaseName && nestedObjBaseName) {
    return {
      value: state[reducer][chapter][objBaseName][nestedObjBaseName][name],
    };
  } else if (objBaseName && !baseName) {
    return {
      value: state[reducer][chapter][objBaseName][name],
    };
  } 
  else if(objBaseName && baseName && indexObjBaseName) {
    try {

      return {
        value: state[reducer][chapter][objBaseName][indexObjBaseName][baseName][index][name],
      };
    
    } catch (err) {
    
      // error handling
    
    }

   
  }
  else if (objBaseName && baseName) {
    return {
      value: state[reducer][chapter][objBaseName][baseName][index][name],
    };
  
  } else {
    return {
      value: state[reducer][chapter][baseName][index][name],
    };
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TextBox);
