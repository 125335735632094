import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import countryList from "react-select-country-list";
import CheckBox from "../../../../../elements/CheckBox";
import DropDown from "../../../../../elements/DropDown";
import TextBox from "../../../../../elements/TextBox";

class NewClient extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isClientNew, doesClientIntrdNewPer, errorMsg } = this.props;
    return (
      <Row className="pt-3 pb-0">
        <div className="mb-2 col-lg-4">
          <CheckBox
            label={
              this.props.form === "Luxembourg"
                ? "Is the client new?"
                : "Is the target company related to any individuals UBO/s already known to United Group?"
            }
            options={["Yes", "No"]}
            name="isClientNew"
            id="isClientNew"
            chapter={this.props.chapter}
            reducer={this.props.reducer}
          />
          {errorMsg &&
            errorMsg.isClientNew &&
            errorMsg.isClientNew?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errorMsg.isClientNew}
              </span>
            )}
        </div>
        {isClientNew === "Yes" && (
          <div className="mb-2 col-lg-8">
            <Row>
              <div className="mb-2 col-lg-6">
                <TextBox
                  label={
                    this.props.form === "Malta"
                      ? "How did the Promoter come to UIM Malta?"
                      : "How the client came to UIM S.A.?"
                  }
                  name="clientUIMMalta"
                  id="clientUIMMalta"
                  placeholder="Enter your text"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                />
                {errorMsg &&
                  errorMsg.clientUIMMalta &&
                  errorMsg.clientUIMMalta?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorMsg.clientUIMMalta}
                    </span>
                  )}
              </div>
              <div className="mb-2 col-lg-6">
                <TextBox
                  label="Name of the introducer"
                  name="introducerName"
                  id="introducerName"
                  placeholder="Enter your text"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                />
                {errorMsg &&
                  errorMsg.introducerName &&
                  errorMsg.introducerName?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorMsg.introducerName}
                    </span>
                  )}
              </div>
              <div className="mb-2 col-lg-6">
                <TextBox
                  label="Name of the company"
                  name="companyNameMal"
                  id="companyNameMal"
                  placeholder="Enter your text"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                />
                {errorMsg &&
                  errorMsg.companyNameMal &&
                  errorMsg.companyNameMal?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorMsg.companyNameMal}
                    </span>
                  )}
              </div>
              <div className="mb-2 col-lg-6">
                <DropDown
                  label="Nationality"
                  options={countryList().getData()}
                  name="introducerNationality"
                  id="introducerNationality"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                />
                {errorMsg &&
                  errorMsg.introducerNationality &&
                  errorMsg.introducerNationality?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorMsg.introducerNationality}
                    </span>
                  )}
              </div>
            </Row>
          </div>
        )}
        {isClientNew === "No" && (
          <div className="mb-2 col-lg-8">
            <Row>
              <div className="mb-2 col-lg-6">
                <CheckBox
                  label="Does the client introduced by any person?"
                  options={["Yes", "No"]}
                  name="doesClientIntrdNewPer"
                  id="doesClientIntrdNewPer"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                />
                {errorMsg &&
                  errorMsg.doesClientIntrdNewPer &&
                  errorMsg.doesClientIntrdNewPer?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorMsg.doesClientIntrdNewPer}
                    </span>
                  )}
              </div>
              {doesClientIntrdNewPer === "Yes" && (
                <>
                  <div className="mb-2 col-lg-6">
                    <TextBox
                      label="Name of the person"
                      name="clientPersName"
                      id="clientPersName"
                      placeholder="Enter your text"
                      chapter={this.props.chapter}
                      reducer={this.props.reducer}
                      validationType="Text"
                    />
                    {errorMsg &&
                      errorMsg.clientPersName &&
                      errorMsg.clientPersName?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errorMsg.clientPersName}
                        </span>
                      )}
                  </div>
                  <div className="mb-2 col-lg-6">
                    <DropDown
                      label="Nationality"
                      options={countryList().getData()}
                      name="clientPersNationality"
                      id="clientPersNationality"
                      chapter={this.props.chapter}
                      reducer={this.props.reducer}
                      validationType="Text"
                    />
                    {errorMsg &&
                      errorMsg.clientPersNationality &&
                      errorMsg.clientPersNationality?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errorMsg.clientPersNationality}
                        </span>
                      )}
                  </div>
                  <div className="mb-2 col-lg-6">
                    <TextBox
                      label="Name of the company"
                      name="clientPersComp"
                      id="clientPersComp"
                      placeholder="Enter your text"
                      chapter={this.props.chapter}
                      reducer={this.props.reducer}
                      validationType="Text"
                    />
                    {errorMsg &&
                      errorMsg.clientPersComp &&
                      errorMsg.clientPersComp?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errorMsg.clientPersComp}
                        </span>
                      )}
                  </div>
                  <div className="mb-2 col-lg-6">
                    <TextBox
                      label="Please add description"
                      name="descNewPer"
                      id="descNewPer"
                      placeholder="Enter your text"
                      chapter={this.props.chapter}
                      reducer={this.props.reducer}
                      validationType="Text"
                    />
                    {errorMsg &&
                      errorMsg.descNewPer &&
                      errorMsg.descNewPer?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errorMsg.descNewPer}
                        </span>
                      )}
                  </div>
                </>
              )}
            </Row>
          </div>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer } = ownProps;
  return {
    isClientNew: state[reducer]["chapter1"]["isClientNew"],
    doesClientIntrdNewPer: state[reducer]["chapter1"]["doesClientIntrdNewPer"],
  };
};

export default connect(mapStateToProps, null)(NewClient);
