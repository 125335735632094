const ovrvwBnkAC = {
  isFatcaSCF: "",
  fatcaSelfCertifctnFrm: [],
  noFATCAformReason: null,
  fatcaClassification: "",
  bnkStmnts: [],
  agremntsGrUpEntities: [],
  agremntsOthrParties: [],
  financialStmntsObjComp: [],
  finanicalStmntsAllPrticipatns: [],
  financialStmntsParntComp: [],
  declartnsFinanicalInstitutns: [],
};
export const DirectorNamesArray = {
  typeOfDirector:null,
  dirName: null,
  dirNationality: null,
  dirCertifiedPass: [],
  dirProofAddress: [],
};
const DocumentsObjectCompany = {
  copyPassportManagement: [],
  extractChamberOfCommerce: [],
  deedOfIncorporation: [],
  shareholdersRegister: [],
  shareholdersCertificates: [],
  financialStatmntsThreeYears: [],
  isAPA: "",
  objCompTaxResult: "",
  explanation: null,
  otherDocs: [],
};

export const ThirdPartyInvstObjComp = {
  tpName: null,
  tpAnnualFinStmnts: [],
  tpCountry: null,
};
const TypeSizeFreqTransct = {
  typeTrnsct: "",
  sizeTrnsct: "",
  freqTrnsct: "",
};

const srcFundsObjComp = {
  isUBOInvstObjComp: "",
  uboInvstCurrencyObjComp: null,
  uboInvstObjCompAmnt: null,
  uboInvstObjCompOrigin: null,
  isUPCfundObjComp: "",
  upcFundObjCompOrigin: null,
  annualFinStmnt: [],
  isTpInvstObjComp: "",
  noOfTPInvestObj: 1,
  tpInvstObjComp: [ThirdPartyInvstObjComp],
  cntrysInvlvdTrnsctnsObjComp: [],
  estmtdAnnualTrnOvrObjComp: "",
  assetSummryObjComp: "",
  assetSummryEvidence: [],
  expctdTrnsctnsSummary: "",
  expctdTrnsctnsEvidence: [],
  objCompToGrpComp: TypeSizeFreqTransct,
  grpCompToObjComp: TypeSizeFreqTransct,
  objCompToUBO: TypeSizeFreqTransct,
  uboToObjComp: TypeSizeFreqTransct,
  objCompToTP: TypeSizeFreqTransct,
  tpToObjComp: TypeSizeFreqTransct,
  rightsAndObligations: [],
};

const BusinessIntroduction = {
  thirdPartyRefApprvl: null,
  thirdPartyDetails: null,
  grpRefApprvl: null,
  officeIndividualDetails: null,
  exstngClntRefApprvl: null,
  clientDetails: null,
  prsnCntctDirePrspctngApprvl: null,
  uimUKStaffDetails: null,
  walkInApprvl: null,
  hearofUIM: null,
  internetApprvl: null,
  fromWhichWebsite:null,
};

const ServicesProvided = {
  domiciliationSrvsApprvl: null,
  domSrvcsExplaination: null,
  accntngSrvsApprvl: null,
  thirdPartyDetailsNoUIM: null,
  taxCmplanceSrvcsApprvl: null,
  coSecServicesApprvl: null,
  bnkAccReqApprvl: null,
  bankDetails: null,
  othrSrvcsApprvl: null,
  othrSrvcsExplain: null,
};

const EntityTrustDetails = {
  directorsPartners: null,
  shareholdersMembers: null,
  regOffice: null,
  trusteeName: null,
  trusteeAddress: null,
  protectorDetails: null,
  settlorDetails: null,
  beneficiaryDetails: null,
  istrusPassport: null,
  trusPassport: [],
  truePassportReason: null,
  istrusProofAdd: null,
  trusProofAdd: [],
  trusProofAddReason: null,
  istrusRefLett: null,
  trusRefLett: [],
  trusRefLettReason: null,
  isprotecPassport: null,
  protecPassport: [],
  protecPassportReason: null,
  isprotecProofAdd: null,
  protecProofAdd: [],
  protecProofAddReason: null,
  isprotecRefLett: null,
  protecRefLett: [],
  protecRefLettReason: null,
  issettPassport: null,
  settPassport: [],
  settPassportReason: null,
  issettProofAdd: null,
  settProofAdd: [],
  settProofAddReason: null,
  issettRefLett: null,
  settRefLett: [],
  settRefLettReason: null,
  isbenPassport: null,
  benPassport: [],
  benPassportReason: null,
  isbenProofAdd: null,
  benProofAdd: [],
  benProofAddReason: null,
  isbenRefLett: null,
  benRefLett: [],
  benRefLettReason: null,
};

export const Chapter4 = {
  isObjCompExists: "",
  commercialRegister: null,
  objCompName: null,
  noOfDirectors: 1,
  objCompDirectorNames: [DirectorNamesArray],
  objCompActiveCountry: [],
  objCompNaturePropsdObjctv: null,
  objCompOtherNaturePropsdObjctv: null,
  isObjCompBranches: [],
  objCompBranch: [],
  rationaleStructurigForNL: [],
  rationaleSelectedInfo: null,
  documentsObjComp: DocumentsObjectCompany,
  isTaxMemorandum: null,
  taxMemorandumAdvisorName: null,
  taxMemorandum: [],
  taxAdvisorName: null,
  isInvstmntMemorandum: null,
  invstmntMemorandum: [],
  taxAdvisorFinds: [],
  srcFundsObjComp: srcFundsObjComp,
  ovrvwBnkAC: ovrvwBnkAC,
  servicesProvided: ServicesProvided,
  businessIntroduction: BusinessIntroduction,
  entityTrustDetails: EntityTrustDetails,
  bnkInfo: [],
};
