import React, { Component } from "react";
import InternalSidebar from "../../../common/InternalSidebar";
import CuracaoInternalSidebar from "./CuracaoInternalSidebar";
import LuxembourgSidebar from "./LuxembourgSidebar";
import MaltaSidebar from "./MaltaSidebar";
import NLInternalSidebar from "./NLInternalSidebar";
import UKInternalSidebar from "./UKInternalSidebar";
class SidebarSelection extends Component {
 
  render() {
    if(this.props.office!==null || this.props.userType==="superadmin"){

    switch (this.props.office) {
      case "Netherlands": {
        return <NLInternalSidebar />;
      }
      case "United Kingdom": {
        return <UKInternalSidebar />;
      }

      case "Malta": {
        return <MaltaSidebar />;
      }
      case "Luxembourg": {
        return <LuxembourgSidebar />;
      }

      //   case "Cyprus": {
      //     return <NLExternalSidebar />;
      //   }
      case "Curacao": {
        return <CuracaoInternalSidebar />;
      }

      //   case "Onboarding Uk test": {
      //     return <NLExternalSidebar />;
      //   }
      default: {
        return <InternalSidebar />;
      }
    }
  }
  else{
    switch (this.props.userType) {
      case "Netherlands": {
        return <NLInternalSidebar />;
      }
      case "United Kingdom": {
        return <UKInternalSidebar />;
      }

      case "Malta": {
        return <MaltaSidebar />;
      }
      case "Luxembourg": {
        return <LuxembourgSidebar />;
      }

      //   case "Cyprus": {
      //     return <NLExternalSidebar />;
      //   }
      case "Curacao": {
        return <CuracaoInternalSidebar />;
      }

      //   case "Onboarding Uk test": {
      //     return <NLExternalSidebar />;
      //   }
      default: {
        return <InternalSidebar />;
      }
    }
  }
  
  }
}
export default SidebarSelection;
