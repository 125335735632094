const BillingAddress = {
  name: null,
  address: null,
};

const SetUpFees = {
  feesCurrency: null,
  formationFee: null,
  agentsFee: null,
  additionalCharges: null,
};

const AnnualManagementFees = {
  annlMngmtCurrency: null,
  frstBillPeriodToDec: null,
  annualRespFee: null,
};

const Vat = {
  addVatApprvl: null,
};

export const Chapter11 = {
  entityName: null,
  antiIncmCurrency: null,
  anticipatedIncome: null,
  billingAddress: BillingAddress,
  setUpFees: SetUpFees,
  annualManagementFees: AnnualManagementFees,
  vat: Vat,
  additionalInfo: null,
  dirty: false,
};
