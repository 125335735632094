import React, { Component } from "react";
import { Row } from "react-bootstrap";
import CheckBox from "../../../../../elements/CheckBox";

class RangeOfWealth extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Row className="mt-2 mb-2">
        <div className="mb-3 col-md-12 rangeCheckbox">
          <CheckBox
            label=" What is the range of your wealth"
            options={[
              "< EUR 500.000",
              "EUR 500.000 – 1 Mln",
              "EUR 1 Mln – 5 Mln",
              "EUR 5 Mln – 20 Mln",
              "EUR 20 Mln – 100 Mln",
              "EUR > 100 Mln",
            ]}
            id="wealthRange"
            name="wealthRange"
            chapter={this.props.chapter}
            reducer={this.props.reducer}
          />
          {this.props.errorMsg !== null &&
            Object.entries(this.props.errorMsg).length !== 0 &&
            this.props.errorMsg?.partB?.wealthRange?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {this.props.errorMsg?.partB?.wealthRange}
              </span>
            )}
          {/* <div class="options">
            <label>
              <input
                type="checkbox"
                name="GreaterThanfiveHundredEuroRange"
                value="GreaterThanfiveHundredEuroRange"
              />
              <img />
              <span className="ml-2"> &#60; EUR 500.000</span>
            </label>
          </div> */}
          <div className="errorMsg"></div>
        </div>
        {/* <div className="mb-3 col-lg-4">
          <div class="options">
            <label>
              <input
                type="checkbox"
                name="fiveHundredtoMilionEuroRange"
                value="fiveHundredtoMilionEuroRange"
              />
              <img />
              <span className="ml-2"> EUR 500.000 – 1 Mln</span>
            </label>
          </div>
          <div className="errorMsg"></div>
        </div>

        <div className="mb-3 col-lg-4">
          <div class="options">
            <label>
              <input
                type="checkbox"
                name="euromilionRange"
                value="euromilionRange"
              />
              <img />
              <span className="ml-2"> EUR 1 Mln – 5 Mln </span>
            </label>
          </div>
          <div className="errorMsg"></div>
        </div>

        <div className="mb-3 col-lg-4">
          <div class="options">
            <label>
              <input
                type="checkbox"
                name="fiveTOtwentyRange"
                value="fiveTOtwentyRange"
              />
              <img />
              <span className="ml-2"> EUR 5 Mln – 20 Mln </span>
            </label>
          </div>
          <div className="errorMsg"></div>
        </div>

        <div className="mb-3 col-lg-4">
          <div class="options">
            <label>
              <input
                type="checkbox"
                name="twentyTOhundredRange"
                value="twentyTOhundredRange"
              />
              <img />
              <span className="ml-2"> EUR 20 Mln – 100 Mln </span>
            </label>
          </div>
          <div className="errorMsg"></div>
        </div>

        <div className="mb-3 col-lg-4">
          <div class="options">
            <label>
              <input
                type="checkbox"
                name="lessThanHundredMilionRange"
                value="lessThanHundredMilionRange"
              />
              <img />
              <span className="ml-2"> EUR &#62; 100 Mln </span>
            </label>
          </div>
          <div className="errorMsg"></div>
        </div> */}
      </Row>
    );
  }
}

export default RangeOfWealth;
