import {
  SET_ALL_CURACAO_ERRORS_TO_NULL,
  SET_CURACAO_ERROR_FOR_CHAPTER,
} from "../../constants/Curacao/CuracaoInternalChapterErrorConstants";

export const setCuracaoInternalChapterError = (data) => {
  return (dispatch) => {
    return dispatch({ type: SET_CURACAO_ERROR_FOR_CHAPTER, data: data });
  };
};

export const setAllCuracaoErrorsToNull = () => {
  return (dispatch) => {
    return dispatch({ type: SET_ALL_CURACAO_ERRORS_TO_NULL });
  };
};
