import React, { Component } from "react";
import { Row } from "react-bootstrap";
import BankInformation from "../../client-information/Chapter4/bankInformation";
import NatureOfObjectCompany from "../../client-information/Chapter4/NatureOfObjectCompany";
import SourceOfFunds from "../../client-information/Chapter4/SourceOfFunds";

const chapter = "partC";
class PartC extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <h2 className="chapter-title mt-3">
          {this.props.form === "Malta"
            ? "Transaction profile of the target company"
            : "Transaction profile of the object company"}
        </h2>
        <Row className="mt-0 mb-2">
          <NatureOfObjectCompany
            reducer="commonFields"
            from="Ubo-Pep"
            form={this.props.form}
            errorMsg={this.props.errorMsg}
          />
        </Row>
        <div className="html2pdf__page-break"></div>
        <SourceOfFunds
          reducer="commonFields"
          chapter={chapter}
          objBaseName="srcFundsObjComp"
          form={this.props.form}
          from="ubo-declaration-uim"
          errorMsg={this.props.errorMsg}
        />
        <div className="html2pdf__page-break"></div>
        <h4 className="sub-chapter-title mb-4">Overview of bank accounts</h4>
        <div className="pt-0 pb-2 obligationComapnygrid">
          <BankInformation reducer="commonFields" form="NL" />
        </div>
      </>
    );
  }
}

export default PartC;
