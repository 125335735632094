import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../../elements/CheckBox";
import TextBox from "../../../../../elements/TextBox";
import UPC from "./UPC";
import UBO from "./UBO";
import Tooltip from "../../../../../elements/Tooltip";
import { getGenericDetails } from "../../../../../../redux/actions/CAFGenericActions";
import MaltaShareholder from "./MaltaShareholder";
const chapter = "chapter3";

class Chapter3 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    let data = {
      Id: localStorage.getItem("formId"),
      Chapter: "all",
    };

    if (data.Id !== "null") {
      this.props
        .getGenericDetails({
          id: data.Id,
        })
        .then((res) => {
          if (res.data.portalErrorCode === 200) {
            this.setState({
              selectedOptionUBO: res.data.result.genericDetails.isUBO,
              selectedOptionPEP: res.data.result.genericDetails.isPEP,
            });
          }
        })
        .catch(this.catchError);
    }
  };

  render() {
    const {
      isHoldingInterest,
      isUBOClassified,
      isAssetClient,
      isUPCExists,
      errorMsg,
    } = this.props;
    // let eMsg = {};
    let eMsg = JSON.parse(errorMsg);
    return (
      <div className="generic-form client-investigation mt-4">
        <h5 className="mb-3 mt-2">
          {this.props.form === "Malta"
            ? "Target Company - UBO/s or Ultimate Parent Company"
            : "3- Client Investigation"}
        </h5>
        <div className="jumbtron">
          <div className="jumbtronHeading">
            UBO <Tooltip text={"Ultimate Beneficiary Owner"}></Tooltip>
          </div>
          <div className="jumbtronBody">
            <Row className="mt-3 mb-2">
              <div className="mb-3 col-lg-6">
                <CheckBox
                  label={`Is there a natural person holding an interest of 25% or more in the capital interest of the ${
                    this.props.form === "Malta"
                      ? "target company"
                      : "legal entity"
                  } or can the natural person exercise 25% or more of the voting rights`}
                  options={["Yes", "No"]}
                  name="isHoldingInterest"
                  id="isHoldingInterest"
                  chapter={chapter}
                  reducer={this.props.reducer}
                  checkBoxValue={isHoldingInterest}
                  //disabled={true}
                />
                {eMsg.isHoldingInterest?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.isHoldingInterest}
                  </span>
                )}
              </div>
              {isHoldingInterest === "Yes" && (
                <div className="mb-3 col-lg-6 fieldAlignment">
                  <TextBox
                    label="Describe how the UBO is directly related to the top entity"
                    name="isHoldingInterestReason"
                    id="isHoldingInterestReasonYes"
                    placeholder="enter reason"
                    chapter={chapter}
                    reducer={this.props.reducer}
                    validationType="Text"
                    isRequired={this.props.form === "NL" ? true : false} 
                  />
                  {eMsg.isHoldingInterestReason?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg.isHoldingInterestReason}
                    </span>
                  )}
                  {eMsg.isHoldingInterestReasonYes?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg.isHoldingInterestReasonYes}
                    </span>
                  )}
                </div>
              )}
            </Row>
            <Row className="mt-3 mb-2">
              <div className="mb-3 col-lg-6 fieldAlignment">
                <CheckBox
                  label="Is there a natural person with ultimate effective control who should also be classified as UBO? "
                  options={["Yes", "No"]}
                  name="isUBOClassified"
                  id="isUBOClassified"
                  chapter={chapter}
                  reducer={this.props.reducer}
                  checkBoxValue={isUBOClassified}
                  //disabled={true}
                />
                {eMsg.isUBOClassified?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.isUBOClassified}
                  </span>
                )}
              </div>

              {isUBOClassified === "Yes" && (
                <div className="mb-3 col-lg-6 fieldAlignment">
                  <TextBox
                    label="Describe the reason why there are natural persons who are considered as persons who have ultimate effective control"
                    placeholder="enter reason"
                    name="isUBOClassifiedReason"
                    id="isUBOClassifiedReason"
                    chapter={chapter}
                    reducer={this.props.reducer}
                    validationType="Text"
                    isRequired={this.props.form === "NL" ? true : false} 
                  />
                  {eMsg.isUBOClassifiedReason?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg.isUBOClassifiedReason}
                    </span>
                  )}
                </div>
              )}
            </Row>

            <Row className="mt-3 mb-2">
              <div className="mb-0 col-lg-6">
                <CheckBox
                  label={
                    this.props.form === "Malta"
                      ? "Is there a natural person who legally holds, on behalf of third parties in virtue of a fidiciary agreement, 10% or more of the assets of the target company or has effective control over the target company?"
                      : `Is there a natural person who holds 10 per cent or more of the assets of a client or a trust or has special control over 10 per cent or more of the assets of a client (beneficiary).`
                  }
                  options={["Yes", "No"]}
                  name="isAssetClient"
                  id="isAssetClient"
                  chapter={chapter}
                  reducer={this.props.reducer}
                  checkBoxValue={isAssetClient}
                  //disabled={true}
                />
                {eMsg.isAssetClient?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.isAssetClient}
                  </span>
                )}
              </div>
              {isAssetClient === "Yes" && (
                <div className="mb-0 col-lg-6 fieldAlignment">
                  <TextBox
                    label="Describe the reason why there are natural persons who are considered as beneficiaries"
                    placeholder="enter reason"
                    name="isAssetClientReason"
                    id="isAssetClientReason"
                    chapter={chapter}
                    reducer={this.props.reducer}
                    validationType="Text"
                    isRequired={this.props.form === "NL" ? true : false} 
                  />
                  {eMsg.isAssetClientReason?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg.isAssetClientReason}
                    </span>
                  )}
                </div>
              )}
            </Row>

            {isHoldingInterest === "No" &&
              isUBOClassified === "No" &&
              isAssetClient === "No" && (
                <Row className="mt-3 mb-2">
                  <div className="mb-3 col-lg-6">
                    <TextBox
                      label="Describe how the UBO is directly related to the top entity"
                      name="isHoldingInterestReason"
                      id="isHoldingInterestReason"
                      placeholder="enter reason"
                      chapter={chapter}
                      reducer={this.props.reducer}
                      validationType="Text"
                      isRequired={this.props.form === "NL" ? true : false} 
                    />
                    {eMsg.isHoldingInterestReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg.isHoldingInterestReason}
                      </span>
                    )}
                  </div>
                </Row>
              )}
          </div>
        </div>

        {/* {isHoldingInterest === "Yes" &&
          isUBOClassified === "Yes" &&
          isAssetClient === "Yes" && ( */}
           {this.props.form === "NL" && (
            <UBO
              reducer={this.props.reducer}
              errorMsg={eMsg}
              form={this.props.form}
            />
          )}
          {isHoldingInterest === "Yes" &&
          isUBOClassified === "Yes" &&
          isAssetClient === "Yes" && 
          this.props.form !== "NL" && (
            <UBO
            reducer={this.props.reducer}
            errorMsg={eMsg}
            form={this.props.form}
          />

          )}

        <Row className="pt-3 pb-2">
          <div className="mb-3 col-lg-6">
            {this.state.selectedOptionPEP === "No" &&
            this.state.selectedOptionUBO === "No" ? (
              <CheckBox
                label="Does the Ultimate parent company exist?"
                name="isUPCExists"
                id="isUPCExists"
                options={["Yes", "No"]}
                chapter={chapter}
                reducer="commonFields"
                isCommon={true}
                disabled={true}
                isRequired={this.props.form === "NL" ? true : false} 
              />
            ) : (
              <CheckBox
                label="Does the Ultimate parent company exist?"
                name="isUPCExists"
                id="isUPCExists"
                options={["Yes", "No"]}
                chapter={chapter}
                reducer="commonFields"
                isCommon={true}
                isRequired={this.props.form === "NL" ? true : false} 
              />
            )}

            {eMsg.isUPCExists?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {eMsg.isUPCExists}
              </span>
            )}
          </div>
        </Row>
        {isUPCExists === "Yes" && (
          <UPC
            reducer={this.props.reducer}
            chapter={chapter}
            objBaseName="upc"
            errorMsg={eMsg}
            form={this.props.form}
            from="clientInformation"
          />
        )}
        {(this.props.form === "Malta" || this.props.form === "Luxembourg") && (
          //this will work for Malta as well as Luxembourg
          <MaltaShareholder
            reducer={this.props.reducer}
            chapter={chapter}
            errorMsg={eMsg}
            form={this.props.form}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer } = ownProps;
  return {
    isHoldingInterest: state[reducer]["chapter3"]["isHoldingInterest"],
    isUBOClassified: state[reducer]["chapter3"]["isUBOClassified"],
    isAssetClient: state[reducer]["chapter3"]["isAssetClient"],
    isUPCExists: state.commonFields["isUPCExists"],
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getGenericDetails: (data) => dispatch(getGenericDetails(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Chapter3);
