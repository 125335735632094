import React, { Component } from "react";
import { Row } from "react-bootstrap";
import cc from "currency-codes";
import TextBox from "../../../../elements/TextBox";
import DropDown from "../../../../elements/DropDown";

let currencies = [];
cc.codes().map((currency) => currencies.push({ label: currency }));
class EntityDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { reducer, chapter, errors } = this.props;
    const errorsMsg = JSON.parse(errors);
    return (
      <Row>
        <div className="mb-2 col-lg-4">
          <TextBox
            label="Entity Name"
            placeholder="Enter entity name"
            name="entityName"
            id="entityName"
            reducer={reducer}
            chapter={chapter}
            validationType="Text"
            //isRequired={true}
            className={
              this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
            }
          />
          {errorsMsg &&
            errorsMsg?.isSubmitted === true &&
            errorsMsg?.entityName?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errorsMsg.entityName}
              </span>
            )}
        </div>
        <div className="mb-2 col-lg-4">
          <DropDown
            label="Select currency"
            options={currencies}
            name="antiIncmCurrency"
            id="antiIncmCurrency"
            reducer={reducer}
            chapter={chapter}
            validationType="Text"
           //isRequired={true}
          />
          {errorsMsg &&
            errorsMsg?.isSubmitted === true &&
            errorsMsg?.antiIncmCurrency?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errorsMsg.antiIncmCurrency}
              </span>
            )}
        </div>
        <div className="mb-2 col-lg-4">
          <TextBox
            label="Anticipated income"
            placeholder="Enter income"
            name="anticipatedIncome"
            id="anticipatedIncome"
            reducer={reducer}
            chapter={chapter}
            validationType="Amount"
            //isRequired={true}
            className={
              this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
            }
          />
          {errorsMsg &&
            errorsMsg?.isSubmitted === true &&
            errorsMsg?.anticipatedIncome?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errorsMsg.anticipatedIncome}
              </span>
            )}
        </div>
      </Row>
    );
  }
}

export default EntityDetails;
