import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../../elements/CheckBox";
import TextBox from "../../../../../elements/TextBox";

class ServiceProvided extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { servicesProvided, errorMsg } = this.props;
    const errors = JSON.parse(errorMsg);
    return (
      <>
        <div className="html2pdf__page-break"></div>
        <h4 className="sub-chapter-title mb-4">Services to be Provided</h4>
        <Row>
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Domiciliation services"
              name="domiciliationSrvsApprvl"
              id="domiciliationSrvsApprvl"
              options={["Yes", "No"]}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {errors &&
              errors?.domiciliationSrvsApprvl &&
              errors?.domiciliationSrvsApprvl?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.domiciliationSrvsApprvl}
                </span>
              )}
          </div>
          {servicesProvided.domiciliationSrvsApprvl === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="More Information"
                id="domSrvcsExplaination"
                name="domSrvcsExplaination"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.domSrvcsExplaination &&
                errors?.domSrvcsExplaination?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.domSrvcsExplaination}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Accounting services"
              name="accntngSrvsApprvl"
              id="accntngSrvsApprvl"
              options={["Yes", "No"]}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {errors &&
              errors?.accntngSrvsApprvl &&
              errors?.accntngSrvsApprvl?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.accntngSrvsApprvl}
                </span>
              )}
          </div>
          {servicesProvided.accntngSrvsApprvl === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Third party details if not UIM"
                id="thirdPartyDetailsNoUIM"
                name="thirdPartyDetailsNoUIM"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.thirdPartyDetailsNoUIM &&
                errors?.thirdPartyDetailsNoUIM?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.thirdPartyDetailsNoUIM}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Tax compliance services"
              name="taxCmplanceSrvcsApprvl"
              id="taxCmplanceSrvcsApprvl"
              options={["Yes", "No"]}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {errors &&
              errors?.taxCmplanceSrvcsApprvl &&
              errors?.taxCmplanceSrvcsApprvl?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.taxCmplanceSrvcsApprvl}
                </span>
              )}
          </div>
          {servicesProvided.taxCmplanceSrvcsApprvl === "No" && (
            <div className="mb-3 col-lg-4">
            <TextBox
              label="Explanation"
              name="taxCmplanceExplanation"
              placeholder="enter your text"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {errors &&
              errors?.taxCmplanceExplanation &&
              errors?.taxCmplanceExplanation?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.taxCmplanceExplanation}
                </span>
              )}
          </div>
          )}
        </Row>
        <Row>
        <div className="mb-3 col-lg-4">
              <CheckBox
                label="CoSec services"
                name="coSecServicesApprvl"
                id="coSecServicesApprvl"
                options={["Yes", "No"]}
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.coSecServicesApprvl &&
                errors?.coSecServicesApprvl?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.coSecServicesApprvl}
                  </span>
                )}
            </div>
            {servicesProvided.coSecServicesApprvl === "No" && (
            <div className="mb-3 col-lg-4">
            <TextBox
              label="Explanation"
              name="coSecServicesExplanation"
              placeholder="enter your text"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {errors &&
              errors?.coSecServicesExplanation &&
              errors?.coSecServicesExplanation?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.coSecServicesExplanation}
                </span>
              )}
          </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Bank account required"
              name="bnkAccReqApprvl"
              id="bnkAccReqApprvl"
              options={["Yes", "No"]}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {errors &&
              errors?.bnkAccReqApprvl &&
              errors?.bnkAccReqApprvl?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.bnkAccReqApprvl}
                </span>
              )}
          </div>
          {servicesProvided.bnkAccReqApprvl === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Bank details"
                name="bankDetails"
                id="bankDetails"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.bankDetails &&
                errors?.bankDetails?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.bankDetails}
                  </span>
                )}
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Other Services"
              name="othrSrvcsApprvl"
              id="othrSrvcsApprvl"
              options={["Yes", "No"]}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {errors &&
              errors?.othrSrvcsApprvl &&
              errors?.othrSrvcsApprvl?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.othrSrvcsApprvl}
                </span>
              )}
          </div>
          {servicesProvided.othrSrvcsApprvl === "Yes" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Please specify"
                name="othrSrvcsExplain"
                id="othrSrvcsExplain"
                placeholder="enter your text"
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {errors &&
                errors?.othrSrvcsExplain &&
                errors?.othrSrvcsExplain?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.othrSrvcsExplain}
                  </span>
                )}
            </div>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer, chapter } = ownProps;
  return {
    servicesProvided: state[reducer][chapter]["servicesProvided"],
  };
};

export default connect(mapStateToProps, null)(ServiceProvided);
