import React, { Component } from "react";
import { Row } from "react-bootstrap";
import DatePicker from "react-date-picker";
import Select from "react-select";
import { SignedByList } from "../Constants";

class Signature extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e) => {
    this.props.handleChange(e, this.props.user);
  };
  render() {
    const { index } = this.props;
    let selectedValue, selectedName, date;
    if (this.props.from === "Other") {
      selectedValue = {
        label: this.props.signedBy,
      };
      date = new Date();
    } else {
      selectedValue = {
        label: this.props.user.label,
      };
      selectedName = {
        label: this.props.user.name,
      };
      //for date
      date = this.props.user.dos ? new Date(this.props.user.dos) : null;
    }

    return (
      <>
        <div className="html2pdf__page-break"></div>
        <h2 className="chapter-title mt-0 mb-0" style={{ fontSize: "18px" }}>
          Signature
        </h2>
        <Row className="pt-3 pb-2">
          <div className="mb-2 col-lg-3">
            <label>Name</label>
            <Select
              options={this.props.options}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.name}
              onChange={(e) => this.handleChange(e)}
              value={selectedName.label}
              isDisabled={this.props.user.label === "Form Creator"}
              placeholder={
                selectedName.label ? selectedName.label : <div>select...</div>
              }
            />
            {SignedByList &&
              SignedByList[index] &&
              SignedByList[index]?.message && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {SignedByList[index]?.message}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-3">
            <label>Email</label>
            <input
              type="text"
              name="name"
              className={
                this.props.PdfGeneration === "Yes"
                  ? "txtBox smallerFont"
                  : "form-control smallerFont"
              }
              placeholder="enter email"
              disabled
              value={this.props.user.email}
            ></input>
          </div>
          <div className="mb-2 col-lg-3 signatureControl">
            <label>Date</label>
            {this.props.PdfGeneration === "Yes" ? (
              <input
                type="text"
                name="dos"
                className={
                  this.props.PdfGeneration === "Yes"
                    ? "txtBox smallerFont"
                    : "form-control smallerFont"
                }
                disabled
                value={new Date().toISOString().split("T")[0]}
              ></input>
            ) : (
              <DatePicker
                name="dos"
                value={date}
                disabled
                maxDate={new Date()}
              />
            )}
          </div>
          <div className="mb-2 col-lg-3">
            <label>Signed By</label>
            <Select
              options={this.props.rolesList}
              isDisabled={this.props.from === "Other" ? false : true}
              value={!selectedValue.label ? selectedValue.label : selectedValue}
              isOptionDisabled={(option) => option.isFixed}
              onChange={this.props.handleDropDownValueChange}
              placeholder={!selectedValue.label && <div>select</div>}
            />
          </div>
          <div className="mb-2 pt-3 col-lg-12">
            <div
              className="signatureBlock"
              style={{ height: "150px", backgroundColor: "#f5f5f5" }}
            >
              <label>Add your signature here</label>
            </div>
            <div className="errorMsg"></div>
          </div>
        </Row>
      </>
    );
  }
}

export default Signature;
