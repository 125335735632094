import React, { Component } from "react";
import cc from "currency-codes";
import TextBox from "../../../../elements/TextBox";
import DropDown from "../../../../elements/DropDown";

let currencies = [];
cc.codes().map((currency) => currencies.push({ label: currency }));
class SetUpFee extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { chapter, reducer, objBaseName, errors } = this.props;
    const errorsMsg = JSON.parse(errors);
    return (
      <div className="mb-2 col-lg-12">
        <fieldset className="eqheight">
          <legend>Set Up Fees</legend>
          <div className="row mb-0">
            <div className="mb-3 col-lg-3">
              <DropDown
                label="Select currency"
                options={currencies}
                name="feesCurrency"
                id="feesCurrency"
                reducer={reducer}
                chapter={chapter}
                objBaseName={objBaseName}
                validationType="Text"
                //isRequired={true}
              />
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.setUpFees &&
                errorsMsg?.setUpFees?.feesCurrency?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg?.setUpFees?.feesCurrency}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-3">
              <TextBox
                label="Formation Fee"
                placeholder="Enter formation fee"
                name="formationFee"
                id="formationFee"
                reducer={reducer}
                chapter={chapter}
                objBaseName={objBaseName}
                validationType="Amount"
                //isRequired={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />{" "}
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.setUpFees &&
                errorsMsg?.setUpFees?.formationFee?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    {errorsMsg?.setUpFees?.formationFee}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-3">
              <TextBox
                label="Agents Fee"
                placeholder="Enter agents fee"
                name="agentsFee"
                id="agentsFee"
                reducer={reducer}
                chapter={chapter}
                objBaseName={objBaseName}
                validationType="Amount"
                //isRequired={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />{" "}
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.setUpFees &&
                errorsMsg?.setUpFees?.agentsFee?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    {errorsMsg?.setUpFees?.agentsFee}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-3">
              <TextBox
                label="Additional Charges"
                placeholder="Enter additional charges"
                name="additionalCharges"
                id="additionalCharges"
                reducer={reducer}
                chapter={chapter}
                objBaseName={objBaseName}
                validationType="Amount"
                //isRequired={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />{" "}
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.setUpFees &&
                errorsMsg?.setUpFees?.additionalCharges?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    {errorsMsg?.setUpFees?.additionalCharges}
                  </span>
                )}
            </div>
          </div>
        </fieldset>
      </div>
    );
  }
}

export default SetUpFee;
