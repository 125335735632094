import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { Component } from "react";
import { connect } from "react-redux";
import countryList from "react-select-country-list";
import DropDown from "../../../../elements/DropDown";
import TextBox from "../../../../elements/TextBox";
import {
  setCuracaoInternalCAFDetails,
  getCuracaoInternalCAFDetails,
} from "../../../../../redux/actions/Curacao/CuracaoInternalCAFActions";
import {
  setNewDataToReducer,
} from "../../../../../redux/actions/ClientInformationAction";
import "../../Onboarding/internalCAF.scss";
import ActivityRisks from "../../Onboarding/Chapter1/ActivityRisks";
import NotificationManager from "react-notifications/lib/NotificationManager";
import DownloadFile from "../../../../elements/DownloadFile";
import CheckBox from "../../../../elements/CheckBox";
import AutoSaving from "../AutoSaving";
import getSequentialFields from "../getSequentialFields";
import { setCuracaoInternalChapterError } from "../../../../../redux/actions/Curacao/CuracaoInternalChapterErrors";
import { chapValidationErrorCuracao,legalArray,clientGroupArr,clientTypeArr,clientActivitiesTypeArr,clientActivitiesRegionArr } from "../Constants";
import Select from "react-select";
import jwt_decode from "jwt-decode";
import DateTimePicker from "../../../../elements/DateTimePicker";
import NumberInput from "../../../../elements/NumberInput";
import { getClientList } from "../../../../../redux/actions/clientActions";
import { CorporateDirector, PersonalDirector, UBO, Entity, ClientIndustry } from "../../../../JSONs/CuracaoForm/Internal/Chapter1"
import CorporateDir from "./CorporateDir";
import PersonalDir from "./PersonalDir"
import UBODetails from "./UBODetails"
import EntityDetails from "./EntityDetails"
import ClientIndustries from "./ClientIndustries"
const chapter = "chapter1";
const reducer = "CuracaoInternalCAF";
let timer;
class EditClientForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
     // id: this.props.match.params.id,
      isLoading: false,
      errors: {},
      clientList: [],
      tokenData: null,
      clientName: JSON.parse(localStorage.getItem("formData")).clientName,
      clientId: JSON.parse(localStorage.getItem("formData")).clientId,
      clientInfoFormName: JSON.parse(localStorage.getItem("formData")).formName,
      selectedClient: null
    };

  }
  componentDidMount = async () => {
    let originalClientList = [];
    let token = JSON.parse(localStorage.getItem("authToken"));
    if (token) {
      var decoded = jwt_decode(token);
      var data = {
        name: decoded.name,
      };



      this.setState({ isLoading: true });

      //----------------get all CU clients-----------
      await this.props.getClientList(data).then((res) => {
        if (res.data.portalErrorCode === 200) {
          console.log("res?.data?.result", res?.data?.result);
          let filteredlst = [];
          res?.data?.result.filter((client) => {
            client.office.filter(value => {
              if (value.name.includes("Curacao")) {
                filteredlst.push(client);
              }
            })
          });
          this.setState({
            isLoading: false,
            clientList: filteredlst,
          });

          originalClientList = filteredlst;
        }
      });
      //-----------------------------------------
      window.scrollTo(0, 0);
      let formData = JSON.parse(localStorage.getItem("formData"));
      console.log("formData", formData);
      let reqData = null;
      if (formData !== null) {
        reqData = {
          id: formData.id,
          userId: formData.userId,
          chapter: "all",
        };
        this.props.getCuracaoInternalCAFDetails(reqData).then(async (res) => {
          this.setState({ isLoading: false });
          //this.state.clientList.length > 0 &&
          // this.state.clientList.forEach((client) => {
          //   if (client.name === res?.data?.result?.clientName) {
          // this.setState({
          //   clientName: res?.data?.result?.clientName,
          //   clientId: res?.data?.result?.clientId
          // });
          // }
          //});

          if (
            res.data.statusCode === 200 &&
            // Object.keys(this.props.internalErrors.errors).length === 0 &&
            res?.data?.result?.cafInternalValidations !== null
          ) {


          }
        });
      }
      else {
        console.log("pp", this.props.chapter1)
        this.props.setNewDataToReducer(this.props.chapter1);
        console.log("pp", this.props.chapter1)
      }


    }

  };
  handleNextProceed = async () => {
   // localStorage.removeItem("formData");
    return this.props.history.push({
      pathname: "/curacao/signature",
    });
  }
  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = "853e42b3-1402-4de4-b666-6eb9d7b89db4";
    requestData[chapter] = data;

    this.props.setCuracaoInternalCAFDetails(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.props.ClientAcceptance.chapter1["dirty"] = false;
        let response = res.data.result;

        const newResponse = await Promise.resolve(
          getSequentialFields(response, chapter, true)
        );
        this.props.setCuracaoInternalChapterError({
          errors: newResponse?.errorChapter,
          form: "formErrorschapter1",
        });
        if (!res.data.result.isValid) {
          // NotificationManager.info(
          //   "All fields are mandatory to fill in",
          //   null,
          //   4000
          // );
          NotificationManager.success("Form is submitted successfully.");
          localStorage.removeItem("formData");
          return this.props.history.push({
            pathname: "/client-form-list",
          });

          this.props.setNewDataToReducer(this.props.ClientAcceptance.chapter1);
          // timer = setTimeout(() => {
          //   return this.props.history.push({
          //     pathname: "/curacao/client-investigation",
          //   });
          // }, 4000);
        } else {
          NotificationManager.success("Form is submitted successfully.");
          localStorage.removeItem("formData");
          return this.props.history.push({
            pathname: "/client-form-list",
          });

        }
      }
    });
  };
  render() {
    const {
      clientList,
      clientId,
      clientName,
      clientInfoFormName
    } = this.state;
    const { chapter1 } = this.props;
    let clientArray = [];
    clientList.length > 0 &&
      clientList.forEach((client) => {
        if (client.status !== "PendingApproval") {
          let obj = {};
          obj["value"] = client.id;
          obj["label"] = client.name;
          clientArray.push(obj);

        }
      });
    let objClient = {};
    objClient["value"] = clientId;
    objClient["label"] = clientName;
    let selectedClient = objClient;

    let lstCorporateDirectors = [];

    for (let i = 0; i < chapter1.numCorporateDirectors; i++) {
      lstCorporateDirectors.push(
        <CorporateDir
          index={i}
          baseName="corporateDirectors"
          reducer={reducer}
          chapter={chapter}
        />
      );
    }
    let lstPersonalDirectors = [];
    for (let i = 0; i < chapter1.numPersonalDirectors; i++) {
      lstPersonalDirectors.push(
        <PersonalDir
          index={i}
          baseName="personalDirectors"
          reducer={reducer}
          chapter={chapter}
        />
      );
    }
    let lstUBOs = [];
    for (let i = 0; i < chapter1.numUBOs; i++) {
      lstUBOs.push(
        <UBODetails
          index={i}
          baseName="ubos"
          reducer={reducer}
          chapter={chapter}
        />
      );
    }
    let lstEntities = [];
    for (let i = 0; i < chapter1.numEntities; i++) {
      lstEntities.push(
        <EntityDetails
          index={i}
          baseName="entities"
          reducer={reducer}
          chapter={chapter}
        />
      );
    }
    let lstClientIndustries = [];
    for (let i = 0; i < chapter1.numClientIndustries; i++) {
      lstClientIndustries.push(
        <ClientIndustries
          index={i}
          baseName="clientIndustries"
          reducer={reducer}
          chapter={chapter}
        />
      );
    }
    return (
      <>
        <div className="dashboard">
          <AutoSaving chapter={chapter} />
          <div className="userlist">
            <div className="eventHeader pt-3 pb-3">
              <Container fluid>
                <Row className="align-items-center">
                  <Col sm={6} className="text-left">
                    <h2 className="mb-0 mt-2 pagetitle">Set-up form new client</h2>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="generic-form client-investigation mt-4">

              <Row className="pt-1">
                <Col lg={12}>
                  <div className="addUserflow addClientFlow">
                    {/* <div className="errorMsg">{errors.otherMessage}</div> */}
                    <Row>
                      <div className="mb-2 col-lg-6">
                        {this.state.clientName ? (<> <label>Client Name </label><br />
                          <Select
                            options={clientArray}
                            value={selectedClient}
                            className="countrylist"
                            isDisabled={true}
                          /></>) : (
                          <DropDown
                            label="Client Name"
                            options={clientArray}
                            name="clientName"
                            id="clientName"
                            chapter={chapter}
                            reducer={reducer}
                            validationType="Text"
                          />
                        )}
                      </div>
                      {/* <div className="mb-2 col-lg-6"> */}
                      <div className="mb-2 col-lg-6">
                        <label>Form Name </label><br />
                        <input
                          id="disabledTextInput"
                          type="text"
                          value={clientInfoFormName}
                          name="clientInfoFormName"
                          placeholder="Enter Form Name"
                          //className="form-control"
                          className="input_field"
                          isDisabled={true}
                          disabled
                        />
                      </div>
                      <div className="mb-2 col-lg-6">                       
                        <DropDown
                           label="Legal form"
                           name="legalform"
                           id="legalform"
                            options={legalArray}                           
                            chapter={chapter}
                            reducer={reducer}
                            validationType="Text"
                          />
                      </div>

                      <div className="mb-2 col-lg-12">
                        <TextBox
                          label="Address (if different from UNI)"
                          name="address"
                          id="address"
                          placeholder="enter your text"
                          chapter={chapter}
                          reducer={reducer}
                          isMultipleLines={true}

                        />
                      </div>
                      <div className="mb-2 col-lg-6">
                        <DropDown
                          label="Statutory seat"
                          name="statutorySeat"
                          id="statutorySeat"
                          options={countryList().getData()}
                          chapter={chapter}
                          reducer={reducer}
                        />
                      </div>
                      <div className="mb-2 col-lg-6">
                        <DropDown
                          label="Actual business seat"
                          name="actualBusinessSeat"
                          id="actualBusinessSeat"
                          options={countryList().getData()}
                          chapter={chapter}
                          reducer={reducer}
                        />
                      </div>
                      <div className="mb-2 col-lg-4">

                        <DateTimePicker
                          label="Incorporation date"
                          name="incorporationDate"
                          chapter={chapter}
                          reducer={reducer}
                        />

                      </div>

                      <Row className="mb-2 col-lg-12">
                        <div className="mb-2 col-lg-4">
                          <TextBox
                            label="Registration number COC/Registrar"
                            name="registrationNumberCOC"
                            id="registrationNumberCOC"
                            placeholder="enter your text"
                            chapter={chapter}
                            reducer={reducer}
                          />
                        </div>
                        <div className="mb-2 col-lg-4">
                          <CheckBox
                            label="Does the business seat differ from the statutory seat?"
                            options={["Yes", "No"]}
                            name="isbusinessSeatFromStatutorySeat"
                            id="isbusinessSeatFromStatutorySeat"
                            chapter={chapter}
                            reducer={reducer}
                          />
                        </div>
                        {chapter1.isbusinessSeatFromStatutorySeat === "Yes" && (
                          <div className="mb-2 col-lg-4">
                            <TextBox
                              label="Registration number COC/Registrar"
                              name="registrationNumberStatutorySeat"
                              id="registrationNumberStatutorySeat"
                              placeholder="enter your text"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                        )}
                      </Row>
                      <div className="mb-2 col-lg-6">
                        <TextBox
                          label="First book year"
                          name="firstBookYear"
                          id="firstBookYear"
                          placeholder="enter your text"
                          chapter={chapter}
                          reducer={reducer}
                        />
                      </div>
                      <div className="mb-2 col-lg-4">
                        <DateTimePicker
                          label="Date became customer (if different from incorporation date)"
                          name="dateBecameCustomer"
                          chapter={chapter}
                          reducer={reducer}
                        />
                      </div>
                      <div className="mb-2 col-lg-6">
                        <TextBox
                          label="Transferred from (if applicable)"
                          name="transferredFrom"
                          id="transferredFrom"
                          placeholder="enter your text"
                          chapter={chapter}
                          reducer={reducer}
                        />
                      </div>
                      <div className="mb-2 col-lg-12">

                        <NumberInput
                          label="How many corporate director(s) can be identified?"
                          name="numCorporateDirectors"
                          referral={["corporateDirectors"]}
                          pushedObject={[CorporateDirector]}
                          chapter={chapter}
                          reducer={reducer}
                          maxLimit={100}
                        />
                        {lstCorporateDirectors}

                      </div>
                      <div className="mb-2 col-lg-12">

                        <NumberInput
                          label="How many personal director(s) can be identified?"
                          name="numPersonalDirectors"
                          referral={["personalDirectors"]}
                          pushedObject={[PersonalDirector]}
                          chapter={chapter}
                          reducer={reducer}
                          maxLimit={100}
                        />
                        {lstPersonalDirectors}

                      </div>
                      <Col lg="12" className="mb-2">
                        <h4 className="sub-chapter-title mb-2">Services</h4>
                        <Row className="mb-3">
                          <div className="col-lg-4">
                            <CheckBox
                              label="Management"
                              options={["Yes", "No"]}
                              name="management"
                              id="management"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                          <div className="col-lg-4">
                            <TextBox
                              label="Fee"
                              name="managementFee"
                              id="managementFee"
                              placeholder="enter your text"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="col-lg-4">
                            <CheckBox
                              label="Representation"
                              options={["Yes", "No"]}
                              name="representation"
                              id="representation"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                          <div className="col-lg-4">
                            <TextBox
                              label="Fee"
                              name="representationFee"
                              id="representationFee"
                              placeholder="enter your text"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                        </Row>

                        <Row className="mb-3">
                          <div className="col-lg-4">
                            <CheckBox
                              label="Legal services"
                              options={["Yes", "No"]}
                              name="legalServices"
                              id="legalServices"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                          <div className="col-lg-4">
                            <TextBox
                              label="Fee"
                              name="legalServicesFee"
                              id="legalServicesFee"
                              placeholder="enter your text"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="col-lg-4">
                            <CheckBox
                              label="Accounting services"
                              options={["Yes", "No"]}
                              name="accountingServices"
                              id="accountingServices"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                          <div className="col-lg-4">
                            <TextBox
                              label="Fee"
                              name="accountingServicesFee"
                              id="accountingServicesFee"
                              placeholder="enter your text"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="col-lg-4">
                            <CheckBox
                              label="Tax services"
                              options={["Yes", "No"]}
                              name="taxServices"
                              id="taxServices"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                          <div className="col-lg-4">
                            <TextBox
                              label="Fee"
                              name="taxServicesFee"
                              id="taxServicesFee"
                              placeholder="enter your text"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                        </Row>

                      </Col>
                      <Col lg="12" className="mb-2">
                        <h4 className="sub-chapter-title mb-2">Fee arrangement</h4>
                        <Row className="mb-3">
                          <div className="col-lg-4">
                            <CheckBox
                              label="Hours"
                              name="hoursFeeArrang"
                              id="hoursFeeArrang"
                              options={["Yes", "No"]}
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                          <div className="col-lg-4">
                            <CheckBox
                              label="Fixed"
                              options={["Yes", "No"]}
                              name="fixedFeeArrang"
                              id="fixedFeeArrang"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                        </Row>

                      </Col>
                      <Col lg="12" className="mb-2">
                        <h4 className="sub-chapter-title mb-2">Contact</h4>
                        <Row className="mb-3">
                          <div className="col-lg-4">
                            <TextBox
                              label="Name"
                              name="nameContact"
                              id="nameContact"
                              placeholder="enter your text"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>

                          <div className="col-lg-4">
                            <TextBox
                              label="Address"
                              name="addressContact"
                              id="addressContact"
                              placeholder="enter your text"
                              chapter={chapter}
                              reducer={reducer}
                              isMultipleLines={true}
                            />
                          </div>

                        </Row>
                        <Row className="mb-3">
                          <div className="col-lg-4">
                            <TextBox
                              label="Telephone/fax"
                              name="telephonContact"
                              id="telephonContact"
                              placeholder="enter your text"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>

                          <div className="col-lg-4">
                            <TextBox
                              label="Email"
                              name="emailContact"
                              id="emailContact"
                              placeholder="enter your text"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>

                        </Row>
                      </Col>
                      <Col lg="12" className="mb-2">
                        <Row className="mb-3">
                          <div className="col-lg-4">
                            <CheckBox
                              label="Does the copy invoice differ from the contact?"
                              name="isCopyinvoiceSamecontact"
                              id="isCopyinvoiceSamecontact"
                              options={["Yes", "No"]}
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>

                        </Row>
                      </Col>
                      {chapter1.isCopyinvoiceSamecontact === "Yes" && (
                        <Col lg="12" className="mb-2">
                          <h4 className="sub-chapter-title mb-2">Copy invoice to (if different from contact)</h4>
                          <Row className="mb-3">
                            <div className="col-lg-4">
                              <TextBox
                                label="Name"
                                name="nameCopyInvoice"
                                id="nameCopyInvoice"
                                placeholder="enter your text"
                                chapter={chapter}
                                reducer={reducer}
                              />
                            </div>

                            <div className="col-lg-4">
                              <TextBox
                                label="Address"
                                name="addressCopyInvoice"
                                id="addressCopyInvoice"
                                placeholder="enter your text"
                                chapter={chapter}
                                reducer={reducer}
                                isMultipleLines={true}
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <div className="col-lg-4">
                              <TextBox
                                label="Telephone/fax"
                                name="telephonCopyInvoice"
                                id="telephonCopyInvoice"
                                placeholder="enter your text"
                                chapter={chapter}
                                reducer={reducer}
                              />
                            </div>

                            <div className="col-lg-4">
                              <TextBox
                                label="Email"
                                name="emailCopyInvoice"
                                id="emailCopyInvoice"
                                placeholder="enter your text"
                                chapter={chapter}
                                reducer={reducer}
                              />
                            </div>

                          </Row>
                        </Col>
                      )}
                      <Col lg="12" className="mb-2">
                        <h2 className="chapter-title mb-3 mt-2">
                          Client Group information
                        </h2>
                      </Col>
                      <div className="mb-2 col-lg-12">
                        <Row className="mb-3">
                          <div className="col-lg-6">                          
                             <DropDown
                           label="Client Group"
                           name="clientGroup"
                           id="clientGroup"
                            options={clientGroupArr}                           
                            chapter={chapter}
                            reducer={reducer}
                            validationType="Text"
                          />
                          </div>
                          <div className="col-lg-6">
                            <TextBox
                              label="Client Group if new"
                              name="isNewclientGroup"
                              id="isNewclientGroup"
                              placeholder="enter your text"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>

                          <div className="col-lg-6">
                            <TextBox
                              label="Introducing firm"
                              name="introducingFirm"
                              id="introducingFirm"
                              placeholder="enter your text"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                          <div className="col-lg-6">
                            <TextBox
                              label="Introducing person"
                              name="introducingPerson"
                              id="introducingPerson"
                              placeholder="enter your text"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                          <div className="col-lg-6">                           
                             <DropDown
                           label="Client type"
                           name="clientType"
                           id="clientType"
                            options={clientTypeArr}                           
                            chapter={chapter}
                            reducer={reducer}
                            validationType="Text"
                          />
                          </div>
                          <div className="mb-2 col-lg-12">


                            <NumberInput
                              label="How many client industries can be identified?"
                              name="numClientIndustries"
                              referral={["clientIndustries"]}
                              pushedObject={[ClientIndustry]}
                              chapter={chapter}
                              reducer={reducer}
                              maxLimit={100}
                            />
                            {lstClientIndustries}

                          </div>
                          <div className="col-lg-6">
                           <DropDown
                         label="Client Activities type"
                         name="clientActivitiesType"
                         id="clientActivitiesType"
                          options={clientActivitiesTypeArr}
                          chapter={chapter}
                          reducer={reducer}
                        />
                          </div>
                          <div className="col-lg-6">
                            <DropDown
                          label="Client Activities Country"
                          name="clientActivitiesCountry"
                          id="clientActivitiesCountry"
                          options={countryList().getData()}
                          chapter={chapter}
                          reducer={reducer}
                        />
                          </div>
                          <div className="col-lg-12">                           
                             <DropDown
                          label="Client Activities Region"
                          name="clientActivitiesRegion"
                          id="clientActivitiesRegion"
                          options={clientActivitiesRegionArr}
                          chapter={chapter}
                          reducer={reducer}
                        />
                          </div>
                          <div className="col-lg-3">
                            <DateTimePicker
                              label="Date completed"
                              name="dateCompleted"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                          <div className="col-lg-3">
                            <TextBox
                              label="By"
                              name="by"
                              id="by"
                              placeholder="enter your text"
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                        </Row>
                      </div>

                      <Col lg="12" className="mb-2">
                        <h4 className="sub-chapter-title mb-2">For compliance use only</h4>
                        <Row className="mb-3">
                          <div className="col-lg-6">
                            <CheckBox
                              label="Temporary acceptance"
                              name="temporaryAcceptance"
                              id="temporaryAcceptance"
                              options={["Yes", "No"]}
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                          <div className="col-lg-6">
                            <CheckBox
                              label="Client accepted"
                              name="clientAccepted"
                              id="clientAccepted"
                              options={["Yes", "No"]}
                              chapter={chapter}
                              reducer={reducer}
                            />
                          </div>
                        </Row>
                      </Col>
                      <Col lg="12" className="mb-2">
                        <h2 className="chapter-title mb-3 mt-2">
                          Client information
                        </h2>
                      </Col>
                      <div className="mb-2 col-lg-12">
                        <NumberInput
                          label="How many UBOs can be identified?"
                          name="numUBOs"
                          referral={["ubos"]}
                          pushedObject={[UBO]}
                          chapter={chapter}
                          reducer={reducer}
                          maxLimit={100}
                        />
                        {lstUBOs}

                      </div>
                      <Col lg="12" className="mb-2">
                        <h2 className="chapter-title mb-3 mt-2">
                          Explanation Memorandum and Diagram
                        </h2>
                      </Col>

                      <Col lg="12" className="mb-2">
                        <Row className="mb-3">
                          <div className="col-lg-12">
                            <TextBox
                              label="Description Client and Background Information"
                              name="descripClientAndBackgroundInfo"
                              id="descripClientAndBackgroundInfo"
                              placeholder="enter your text"
                              chapter={chapter}
                              reducer={reducer}
                              isMultipleLines={true}
                            />
                          </div>
                          <div className="col-lg-12">
                            <TextBox
                              label="Description of the Tax position and underlying rationale"
                              name="descripTaxPositionAndUnderlyingRationale"
                              id="descripTaxPositionAndUnderlyingRationale"
                              placeholder="enter your text"
                              chapter={chapter}
                              reducer={reducer}
                              isMultipleLines={true}
                            />
                          </div>
                          <div className="col-lg-12">
                            <TextBox
                              label="Description of the Structure and Entity + Diagram"
                              name="descripStructureAndEntityDiagram"
                              id="descripStructureAndEntityDiagram"
                              placeholder="enter your text"
                              chapter={chapter}
                              reducer={reducer}
                              isMultipleLines={true}
                            />
                          </div>
                        </Row>

                      </Col>
                      <Col>

                        <NumberInput
                          label="How many Entities can be identified?"
                          name="numEntities"
                          referral={["entities"]}
                          pushedObject={[Entity]}
                          chapter={chapter}
                          reducer={reducer}
                          maxLimit={100}
                        />
                        {lstEntities}
                      </Col>


                      <Col lg="12" className="mt-3">
                        {/* {this.state.id ? ( */}
                        {/* <Button
                            className="action-btn big mr-4"
                            onClick={this.handleUpdate}
                          >
                            Update
                          </Button>
                        ) : ( */}
                        <Button
                          className="action-btn big mr-4"
                          onClick={this.handleSubmit}
                        >
                          Submit
                        </Button>
                       
                        <Button
                          className="action-btn greybtn mr-4 mr-6"
                          onClick={this.handleNextProceed}
                        >
                          Proceed to Next
                        </Button>

                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={3}></Col>
                {this.state.isLoading && <div className="loader"></div>}
              </Row>

            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { baseName, index, reducer, objBaseName, indexObjBaseName } = ownProps;

  return {
    clients: state.user.data,
    chapter1: state.CuracaoInternalCAF.chapter1,
    //issettPassport: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["issettPassport"],
    chapter1Ext: state.CuracaoClientInformation.chapter1,
    ClientAcceptance: state.CuracaoInternalCAF,
    data: state,
    commonFields: state.commonFields,
    internalErrors: state.CuracaoInternalChapterErrors.formErrorschapter1,
    errors: state.CuracaoInternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientList: async (data) => dispatch(await getClientList(data)),
    getCuracaoInternalCAFDetails: async (data) =>
      dispatch(await getCuracaoInternalCAFDetails(data)),
    setCuracaoInternalCAFDetails: async (data) =>
      dispatch(await setCuracaoInternalCAFDetails(data)),
    setCuracaoInternalChapterError: (data) =>
      dispatch(setCuracaoInternalChapterError(data)),
    setNewDataToReducer: (data) => dispatch(setNewDataToReducer(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditClientForm);
