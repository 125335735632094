import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../../elements/CheckBox";
import FileUpload from "../../../../../elements/FileUpload";
import TextBox from "../../../../../elements/TextBox";
import { checkIsCommon } from "../CommonFunctions";
import { fileAcceptType } from "./Constants";
import TaxInvestmentMemorandum from "./TaxInvestmentMemorandum";

class DocumentsObjectCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isOCSFI, isObjCompExists, chapter, objBaseName, errorMsg,isCompIncorporated } =
      this.props;
    const eMsg = JSON.parse(errorMsg);
    // const eMsg = {};
    return (
      <>
        <h4 className="sub-chapter-title">
          {this.props.form === "Malta"
            ? "Documents Target Company"
            : "Documents Object Company"}
        </h4>
        <Row className="pt-3 pb-0">
          {this.props.form !== "Malta" && this.props.form !== "UK" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Copy Passport Management"
                acceptType={fileAcceptType}
                name="copyPassportManagement"
                id="copyPassportManagement"
                chapter={chapter}
                objBaseName={objBaseName}
                reducer={this.props.reducer}
              />
              {eMsg &&
                eMsg?.copyPassportManagement &&
                eMsg?.copyPassportManagement?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.copyPassportManagement}
                  </span>
                )}
            </div>
          )}
        
          <div className="mb-3 col-lg-4">
            <FileUpload
              label="Extract Chamber of Commerce"
              acceptType={fileAcceptType}
              name="extractChamberOfCommerce"
              id="extractChamberOfCommerce4"
              chapter={chapter}
              objBaseName={objBaseName}
              reducer={this.props.reducer}
            />
            {eMsg &&
              eMsg?.extractChamberOfCommerce4 &&
              eMsg?.extractChamberOfCommerce4?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg.extractChamberOfCommerce4}
                </span>
              )}
          </div>
       
          <div className="mb-3 col-lg-4">
            <FileUpload
              label="Deed of incorporation/ Articles of association"
              acceptType={fileAcceptType}
              name="deedOfIncorporation"
              id="deedOfIncorporation4"
              chapter={chapter}
              objBaseName={objBaseName}
              reducer={this.props.reducer}
            />
            {eMsg &&
              eMsg?.deedOfIncorporation4 &&
              eMsg.deedOfIncorporation4?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg.deedOfIncorporation4}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-0 pb-2">
          <div className="mb-3 col-lg-4">
            <FileUpload
              label="Shareholders’ register"
              acceptType={fileAcceptType}
              name="shareholdersRegister"
              id="shareholdersRegister4"
              chapter={chapter}
              objBaseName={objBaseName}
              reducer={this.props.reducer}
            />
            {eMsg &&
              eMsg?.shareholdersRegister4 &&
              eMsg.shareholdersRegister4?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg.shareholdersRegister4}
                </span>
              )}
          </div>

          <div className="mb-3 col-lg-4">
            <FileUpload
              label="Shareholders’ certificates"
              acceptType={fileAcceptType}
              name="shareholdersCertificates"
              id="shareholdersCertificates4"
              objBaseName={objBaseName}
              chapter={chapter}
              reducer={this.props.reducer}
            />
            {eMsg &&
              eMsg?.shareholdersCertificates4 &&
              eMsg.shareholdersCertificates4?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg.shareholdersCertificates4}
                </span>
              )}
          </div>
          {isObjCompExists === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Financial statements of the past three years"
                acceptType={fileAcceptType}
                name="financialStatmntsThreeYears"
                id="financialStatmntsThreeYears4"
                objBaseName={objBaseName}
                chapter={chapter}
                reducer={this.props.reducer}
              />
              {eMsg &&
                eMsg?.financialStatmntsThreeYears4 &&
                eMsg.financialStatmntsThreeYears4?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.financialStatmntsThreeYears4}
                  </span>
                )}
            </div>
          )}
          {this.props.form === "Malta" && (
            <>
              <div className="mb-3 col-lg-4">
                <FileUpload
                  label="Utility Bills"
                  acceptType={fileAcceptType}
                  name="utilityBills"
                  id="utilityBills"
                  objBaseName={objBaseName}
                  chapter={chapter}
                  reducer={this.props.reducer}
                />
                {eMsg &&
                  eMsg?.utilityBills &&
                  eMsg.utilityBills?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg.utilityBills}
                    </span>
                  )}
              </div>
              <div className="mb-3 col-lg-4">
                <FileUpload
                  label="Refrence letter"
                  acceptType={fileAcceptType}
                  name="refLetter"
                  id="refLetter"
                  objBaseName={objBaseName}
                  chapter={chapter}
                  reducer={this.props.reducer}
                />
                {eMsg && eMsg?.refLetter && eMsg.refLetter?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.refLetter}
                  </span>
                )}
              </div>
            </>
          )}
        </Row>
        {this.props.form !== "Malta" && (
          <TaxInvestmentMemorandum
            chapter={checkIsCommon(this.props.form) ? null : chapter}
            objBaseName={objBaseName}
            reducer={
              checkIsCommon(this.props.form)
                ? "commonFields"
                : this.props.reducer
            }
            form={this.props.form}
            errorMsg={errorMsg}
            from="clientInformation"
          />
        )}
       {isCompIncorporated === "Yes" && (
        <Row className="pt-3 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Advance Pricing Agreements (APA) or Advance Tax Rulings (ATR)"
              options={["Yes", "No"]}
              name="isAPA"
              id="isAPA"
              chapter={chapter}
              objBaseName={objBaseName}
              reducer={this.props.reducer}
            />
            {eMsg && eMsg?.isAPA && eMsg.isAPA?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {eMsg.isAPA}
              </span>
            )}
          </div>
          <div className="mb-3 col-lg-4">
            <CheckBox
              label={`Tax result for the ${
                this.props.form === "Malta" ? "Target" : "Object"
              } Company is not contrary to the intentions of law and regulation`}
              options={["Yes", "No"]}
              name="objCompTaxResult"
              id="objCompTaxResult"
              chapter={chapter}
              objBaseName={objBaseName}
              reducer={this.props.reducer}
            />
            {eMsg &&
              eMsg?.objCompTaxResult &&
              eMsg.objCompTaxResult?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg.objCompTaxResult}
                </span>
              )}
          </div>

          <div className="mb-3 col-lg-4 fieldAlignment">
            <TextBox
              label="Explanation"
              name="explanation"
              id="explanation"
              placeholder="enter your text"
              chapter={chapter}
              objBaseName={objBaseName}
              reducer={this.props.reducer}
              validationType="Text"
            />
            {eMsg && eMsg?.explanation && eMsg.explanation?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {eMsg.explanation}
              </span>
            )}
          </div>
        </Row>
        )}
        <Row className="pt-0 pb-2">
          <div className="mb-3 col-lg-4">
            <FileUpload
              label="Other relevant documents"
              acceptType={fileAcceptType}
              name="otherDocs"
              id="otherDocs"
              chapter={chapter}
              objBaseName={objBaseName}
              reducer={this.props.reducer}
            />
            {eMsg && eMsg?.otherDocs && eMsg.otherDocs?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {eMsg.otherDocs}
              </span>
            )}
          </div>
          {this.props.form === "NL" && isCompIncorporated === "Yes" && (          
            <>
              <div className="mb-3 col-lg-4">
                <CheckBox
                  label="Is the Object Company registered as SFI?"
                  options={["Yes", "No"]}
                  name="isOCSFI"
                  id="isOCSFI"
                  chapter={chapter}
                  objBaseName={objBaseName}
                  reducer={this.props.reducer}
                />
                {eMsg && eMsg?.isOCSFI && eMsg.isOCSFI?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.isOCSFI}
                  </span>
                )}
              </div>
              {isOCSFI === "Yes" && (
                <div className="mb-3 col-lg-4">
                  <TextBox
                    label="Registration number"
                    placeholder="Enter registration number"
                    name="registrationNum"
                    id="registrationNum"
                    chapter={chapter}
                    objBaseName={objBaseName}
                    reducer={this.props.reducer}
                    validationType="Text"
                  />
                  {eMsg &&
                    eMsg?.registrationNum &&
                    eMsg.registrationNum?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg.registrationNum}
                      </span>
                    )}
                </div>
              )}
            </>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer } = ownProps;
  return {
    isOCSFI: state[reducer]["chapter4"]["documentsObjComp"]["isOCSFI"],
    isObjCompExists: state[reducer]["chapter4"]["isObjCompExists"],
    isCompIncorporated: state[reducer]["chapter1"]["isCompIncorporated"]
  };
};

export default connect(mapStateToProps, null)(DocumentsObjectCompany);
