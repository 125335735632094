import React, { Component } from "react";
import TextBox from "../../../../../elements/TextBox";
import { Row } from "react-bootstrap";
import { checkIsCommon } from "../CommonFunctions";
import { getStructureActivities } from "./Constants";

class MainActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let structureActivities = getStructureActivities(this.props.form);
    return (
      <>
        <h3 className="mt-3 mb-2 sub-chapter-title">
          What are the (main) activities of the entities in the structure?
        </h3>
        <Row>
          {structureActivities.map((activity) => {
            return (
              <>
                <div className="col-md-4 mb-0" key={activity.label}>
                  <div className="activityBlock mt-2">
                    <h4 className="activityLabel">{activity.label}</h4>
                    <div className="actibityBody">
                      <Row className="mb-3">
                        <div className="col-lg-12">
                          <TextBox
                            label="Activity Name"
                            name={activity.activityName}
                            id={activity.activityName}
                            placeholder="Enter Activity"
                            chapter={this.props.form !== "NL" && "chapter1"}
                            reducer={this.props.reducer}
                            validationType="Text"
                            isCommon={checkIsCommon(this.props.form)}
                            isRequired={(activity.label==="Object Company Activities" && this.props.form === "UK") ? true : false} 
                            disabled={
                              (this.props.form === "NL" ||
                                this.props.form === "Curacao" ||
                                this.props.form === "Malta" ||
                                this.props.form === "Luxembourg") &&
                              this.props.from !== "clientInformation"
                            }
                             />                           
                          {this.props.errorMsg &&
                            this.props.errorMsg[activity.activityName] &&
                            this.props.errorMsg[activity.activityName]?.length >
                              0 && (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {this.props.errorMsg[activity.activityName]}
                              </span>
                            )}
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <div className="col-lg-12">
                          <TextBox
                            label="Sector Name"
                            name={activity.sectorName}
                            id={activity.sectorName}
                            placeholder="Enter Sector"
                            chapter={
                              this.props.form === "NL" ? null : "chapter1"
                            }
                            reducer={this.props.reducer}
                            validationType="Text"
                            isCommon={checkIsCommon(this.props.form)}
                            isRequired={(activity.label==="Object Company Activities" && this.props.form === "UK") ? true : false} 
                            disabled={
                              (this.props.form === "NL" ||
                                this.props.form === "Curacao" ||
                                this.props.form === "Malta" ||
                                this.props.form === "Luxembourg") &&
                              this.props.from !== "clientInformation"
                            }
                             
                          />
                          {this.props.errorMsg &&
                            this.props.errorMsg[activity.sectorName] &&
                            this.props.errorMsg[activity.sectorName]?.length >
                              0 && (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {this.props.errorMsg[activity.sectorName]}
                              </span>
                            )}
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </Row>
      </>
    );
  }
}

export default MainActivities;
