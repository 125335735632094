export const Entities = {
  chamberExtract: [],
  article: [],
  shareholderRegister: [],
  finStat: [],
};

export const Chapter1 = {
  structureChart: [],
  isCompIncorporated: "",
  noOfEntities: 1,
  entities: [Entities],
  dirty: false,
};
