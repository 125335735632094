import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import DownloadFile from "../../../../elements/DownloadFile";
import DropDown from "../../../../elements/DropDown";
import TextBox from "../../../../elements/TextBox";
import { checkIsCommon } from "../../../external/OnBoarding/client-information/CommonFunctions";

class BearerShares extends React.Component {
  render() {
    const { isInsuranceBearerShares, bsEvidences, errors, isRequired } =
      this.props;
    const errorsMsg = JSON.parse(errors);

    return (
      <Row className="pt-3 pb-2">
        <div className="mb-3 col-lg-4">
          <CheckBox
            label="Is there any issuance of bearer shares in the chain of the structure?"
            options={["Yes", "No"]}
            name="isInsuranceBearerShares"
            chapter={this.props.chapter}
            reducer={
              checkIsCommon(this.props.form)
                ? "commonFields"
                : this.props.externalReducer
            }
            isCommon={checkIsCommon(this.props.form)}
            bothDisabled={true}
          />
        </div>
        {isInsuranceBearerShares === "Yes" && (
          <>
            <div className="mb-3 col-lg-8">
              <Row className="pt-3 pb-2">
                <div className="mb-3 col-lg-6">
                  <DropDown
                    label="Please specify currency"
                    name="bsCurrency"
                    //options={CurrencyArray}
                    chapter={this.props.chapter}
                    reducer={
                      checkIsCommon(this.props.form)
                        ? "commonFields"
                        : this.props.externalReducer
                    }
                    isCommon={checkIsCommon(this.props.form)}
                    disabled={true}
                  />
                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Please specify amount"
                    placeholder="enter amount"
                    name="bsAmount"
                    chapter={this.props.chapter}
                    reducer={
                      checkIsCommon(this.props.form)
                        ? "commonFields"
                        : this.props.externalReducer
                    }
                    isCommon={checkIsCommon(this.props.form)}
                    validationType="Amount"
                    disabled={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtBox"
                        : "form-control"
                    }
                  />
                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Please specify holder(custodian) of bearer shares"
                    placeholder="enter holder"
                    name="bsHolder"
                    chapter={this.props.chapter}
                    reducer={
                      checkIsCommon(this.props.form)
                        ? "commonFields"
                        : this.props.externalReducer
                    }
                    isCommon={checkIsCommon(this.props.form)}
                    validationType="Text"
                    disabled={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtBox"
                        : "form-control"
                    }
                  />
                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Please specify beneficiary of bearer shares"
                    placeholder="enter beneficiary"
                    name="bsBeneficiary"
                    chapter={this.props.chapter}
                    reducer={
                      checkIsCommon(this.props.form)
                        ? "commonFields"
                        : this.props.externalReducer
                    }
                    isCommon={checkIsCommon(this.props.form)}
                    validationType="Text"
                    disabled={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtBox"
                        : "form-control"
                    }
                  />
                </div>
                <div className="mb-3 col-lg-8">
                  <DownloadFile
                    label="Please include evidence"
                    value={bsEvidences}
                  />
                </div>
                <div className="mb-2 col-lg-4 col-xl-4">
                  <CheckBox
                    label="Is approved"
                    options={["Yes", "No"]}
                    name="bsEvidencesIsApprvd"
                    id="bsEvidencesIsApprvd"
                    chapter={this.props.chapter}
                    reducer={this.props.reducer}
                    isRequired={isRequired}
                  />
                  {errorsMsg &&
                    errorsMsg?.isSubmitted === true &&
                    errorsMsg?.bsEvidencesIsApprvd &&
                    errorsMsg?.bsEvidencesIsApprvd?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.bsEvidencesIsApprvd}
                      </span>
                    )}
                </div>
              </Row>
              {this.props.form !== "NL" || this.props.from !== "Curacao" ? (
                <div className="html2pdf__page-break"></div>
              ) : (
                <div></div>
              )}
              <Row>
                <div className="mb-2 col-lg-12">
                  <TextBox
                    label="Please add comment"
                    placeholder="Enter your text"
                    name="bsEvidencesCmmnt"
                    id="bsEvidencesCmmnt"
                    reducer={this.props.reducer}
                    chapter={this.props.chapter}
                    validationType="Text"
                    isRequired={isRequired}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtBox"
                        : "form-control"
                    }
                  />
                  {errorsMsg &&
                    errorsMsg?.isSubmitted === true &&
                    errorsMsg?.bsEvidencesCmmnt &&
                    errorsMsg?.bsEvidencesCmmnt?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.bsEvidencesCmmnt}
                      </span>
                    )}
                </div>
              </Row>
            </div>
          </>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { externalReducer, form } = ownProps;
  if (form === "NL" || form === "Curacao") {
    return {
      isInsuranceBearerShares: state.commonFields["isInsuranceBearerShares"],
      bsEvidences: state.commonFields["bsEvidences"],
    };
  } else {
    return {
      isInsuranceBearerShares:
        state[externalReducer]["chapter4"]["isInsuranceBearerShares"],
      bsEvidences: state[externalReducer]["chapter4"]["bsEvidences"],
    };
  }
};

export default connect(mapStateToProps, null)(BearerShares);
