import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import TextBox from "../../../../elements/TextBox";
import NumberInput from "../../../../elements/NumberInput";
import CheckBox from "../../../../elements/CheckBox";
import InternalFileUpload from "../../../../elements/InternalFileUpload";
import { fileAcceptType } from "../../Onboarding/Constants";
import {
  setUKInternalCAFDetails,
  getUKInternalCAFDetails,
} from "../../../../../redux/actions/UK/UKInternalCAFActions";
import MediaCoverage from "../../Onboarding/Chapter1/MediaCoverage";
import "../../Onboarding/internalCAF.scss";
import AutoSaving from "../AutoSaving";
import UboQuestions from "../../Onboarding/Chapter3/UboQuestions";
import UboDetails from "./UboDetails";
import UpcDetails from "./UpcDetails";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { setUKInternalChapterError } from "../../../../../redux/actions/UK/UKInternalChapterErrorActions";
import getSequentialFields from "../getSequentialFields";
import { chapValidationErrorUK } from "../constants";

const chapter = "chapter3";
const reducer = "UKInternalCAF";
const externalReducer = "UKClientInformation";
class Chapter3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formErrorsChapter3: {},
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: "all",
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.getUKInternalCAFDetails(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          Object.keys(this.props.internalErrors.errors).length === 0 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationErrorUK.forEach(async (element) => {
            const response =
              res.data.result.cafInternalValidations[element.chapter];

            const newResponse = await Promise.resolve(
              getSequentialFields(response, element.chapter, false)
            );
            this.props.setUKInternalChapterError({
              form: `formErrors${element.chapter}`,
              errors: newResponse?.errorChapter,
            });
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;
    this.props.setUKInternalCAFDetails(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.props.ClientAcceptance.chapter3["dirty"] = false;
        let response = res.data.result;

        const newResponse = await Promise.resolve(
          getSequentialFields(response, chapter, true)
        );
        this.props.setUKInternalChapterError({
          form: `formErrors${chapter}`,
          errors: newResponse?.errorChapter,
        });
        if (!res.data.result.isValid) {
          NotificationManager.info(
            "All fields are mandatory to fill in",
            null,
            4000
          );
          const timer = setTimeout(() => {
            return this.props.history.push({
              pathname: "/united-kingdom/object-company",
            });
          }, 4000);
        } else {
          NotificationManager.success("Form is submitted successfully.");

          return this.props.history.push({
            pathname: "/united-kingdom/object-company",
          });
        }
      }
    });
  };

  render() {
    const {
      noOfUBOs,
      noOfUPCs,
      isUPCExists,
      isUBOresultIncrsdIntgrtyRsk,
      isHoldingInterest,
      isUBOClassified,
      isAssetClient,
      internalErrors,
    } = this.props;

    const formErrorsChapter3 = JSON.stringify(internalErrors.errors);
    const errorsMsg = JSON.parse(formErrorsChapter3);
    let uboinfo = [];
    for (let i = 0; i < noOfUBOs; i++) {
      uboinfo.push(
        <UboDetails
          errors={formErrorsChapter3}
          index={i}
          chapter={chapter}
          baseName="ubox"
          reducer={externalReducer}
          objBaseName="ubo"
          form="UK"
        />
      );
    }
    //---------------------------------
    let upcinfo = [];
    for (let i = 0; i < noOfUPCs; i++) {
      upcinfo.push(
        <UpcDetails
          errors={formErrorsChapter3}
          index={i}
          chapter={chapter}
          baseName="upcDetails"
          reducer={externalReducer}
          form="UK"
        />
      );
    }
   
    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="cafHeader">
            <h2 className="m-0">Client Acceptance Form </h2>
          </div>
          <div className="internal_caf generic-form">
            <div className="html2pdf__page-break"></div>
            <h3 className="chapterttl">
              Client Investigation (UBO)/ Ultimate Parent Company (UPC)
            </h3>
            <Row className="mb-3">
              <div className="col-md-12">
                <UboQuestions
                  chapter={chapter}
                  reducer={reducer}
                  externalReducer="UKClientInformation"
                  errors={formErrorsChapter3}
                  isRequired={false}
                  form="UK"
                />
              </div>
            </Row>
            {isHoldingInterest === "Yes" &&
              isUBOClassified === "Yes" &&
              isAssetClient === "Yes" && (
                <>
                  <div className="html2pdf__page-break"></div>
                  <Row className="mb-3">
                    <div className="col-lg-4">
                      <NumberInput
                        label="How many UBO's are there?"
                        name="noOfUBOs"
                        reducer={externalReducer}
                        chapter={chapter}
                        objBaseName="ubo"
                        disabled={true}
                      />
                      <div className="errorMessage"></div>
                    </div>
                    <div className="col-lg-12">{uboinfo}</div>
                  </Row>
                </>
              )}
            <div className="html2pdf__page-break"></div>
            <Row className="mb-3">
              <div className="col-lg-6">
                <CheckBox
                  label="Does the Ultimate Parent Company exist?"
                  options={["Yes", "No"]}
                  name="isUPCExists"
                  reducer={externalReducer}
                  chapter={chapter}
                  disabled={true}
                />
                <div className="errorMessage"></div>
              </div>
            </Row>
            {isUPCExists === "Yes" && (
               <>
               <Row className="mb-3">
               <div className="col-lg-4">
                 <NumberInput
                   label="How many parent companies/trusts are there?"
                   name="noOfUPCs"
                   reducer={externalReducer}
                   chapter={chapter}
                   disabled={true}
                 />
                 <div className="errorMessage"></div>
               </div>
               <div className="col-lg-12">{upcinfo}</div>
             </Row>
             </>
            )} 
            <Row className="mb-3 mt-3">
              {/* <div className="col-lg-4">
                <InternalFileUpload
                  label="Pascal report"
                  name="pascalReportUpload"
                  id="pascalReportUpload"
                  chapter={chapter}
                  acceptType={fileAcceptType}
                  reducer={reducer}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.pascalReportUpload &&
                  errorsMsg?.pascalReportUpload?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.pascalReportUpload}
                    </span>
                  )}
              </div> */}
              {/* <div className="col-lg-4">
                <TextBox
                  label="Findings"
                  placeholder="Enter your text"
                  name="isFindings"
                  id="isFindings"
                  chapter={chapter}
                  reducer={reducer}
                  validationType="Text"
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.isFindings &&
                  errorsMsg?.isFindings?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isFindings}
                    </span>
                  )}
              </div> */}
            </Row>
            <Row className="mb-3">
              <div className="col-lg-12">
                <TextBox
                  label="Please describe how the investigation into the UBO in the scope of determining whether he or she can be classified as a Politically Exposed Person (PEP) was carried out. What is his position and which extra measures should be taken?"
                  placeholder="Enter your text"
                  name="descInvstgUBOClsifiedPEP"
                  id="descInvstgUBOClsifiedPEP"
                  chapter={chapter}
                  reducer={reducer}
                  isMultipleLines={true}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.descInvstgUBOClsifiedPEP &&
                  errorsMsg?.descInvstgUBOClsifiedPEP?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.descInvstgUBOClsifiedPEP}
                    </span>
                  )}
              </div>
            </Row>
            
            <MediaCoverage
              chapter={chapter}
              reducer={reducer}
              errors={formErrorsChapter3}
              form="UK"
              isRequired={true}
            />

            <div className="html2pdf__page-break"></div>
            <Row className="mb-3">
              <div className="col-lg-12">
                if there are integrity risks related to the UBO, the
                qualification PEP and the acquiring of wealth over time by
                assessing the obtained information to verify the size and scope
                of the assets and how the assets were obtained. 'The story must
                be right'. Is it sufficiently substantiated that the client's
                (Object Company and UBO) entire wealth originates from
                legitimate sources. After this assessment it is possible to
                identify potential integrity risks which need to be added to the
                risk log. Take into account the press coverage of the UBO.
              </div>
            </Row>
            <Row className="mb-3">
              <div className="col-lg-12">
                <CheckBox
                  label="In addition, report whether the UBO criteria result in a potentially increased integrity risk"
                  options={["Yes", "No"]}
                  name="isUBOresultIncrsdIntgrtyRsk"
                  id="isUBOresultIncrsdIntgrtyRsk"
                  chapter={chapter}
                  isRequired={true}
                  reducer={reducer}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg?.isUBOresultIncrsdIntgrtyRsk?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isUBOresultIncrsdIntgrtyRsk}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="mb-3">
              {isUBOresultIncrsdIntgrtyRsk === "Yes" && (
                <div className="col-lg-12">
                  <TextBox
                    label="Please describe"
                    placeholder="Enter your text"
                    name="ubOresultIncrsdIntgrtyRskCmmnt"
                    id="ubOresultIncrsdIntgrtyRskCmmnt"
                    chapter={chapter}
                    reducer={reducer}
                    isRequired={true}
                    isMultipleLines={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtArea"
                        : "form-control"
                    }
                  />
                  {errorsMsg &&
                    errorsMsg.isSubmitted === true &&
                    errorsMsg?.ubOresultIncrsdIntgrtyRskCmmnt?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.ubOresultIncrsdIntgrtyRskCmmnt}
                      </span>
                    )}
                </div>
              )}
              {isUBOresultIncrsdIntgrtyRsk === "No" && (
                <div className="col-md-12 font-weight-bold">
                  With respect to the UBO information, UIM sees no reason to
                  discontinue the set-up of a business relationship
                </div>
              )}
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ClientAcceptance: state.UKInternalCAF,
    noOfUBOs: state.UKClientInformation.chapter3.ubo["noOfUBOs"],
    noOfUPCs:state.UKClientInformation.chapter3["noOfUPCs"],
    isUPCExists: state.UKClientInformation.chapter3["isUPCExists"],
    isHoldingInterest: state.UKClientInformation.chapter3["isHoldingInterest"],
    isUBOClassified: state.UKClientInformation.chapter3["isUBOClassified"],
    isAssetClient: state.UKClientInformation.chapter3["isAssetClient"],
    isUBOresultIncrsdIntgrtyRsk:
      state.UKInternalCAF.chapter3["isUBOresultIncrsdIntgrtyRsk"],
    data: state,
    internalErrors: state.UKInternalChapterError.formErrorschapter3,

    // clientInformation: state.clientInformation[chapter],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUKInternalCAFDetails: async (data) =>
      dispatch(await getUKInternalCAFDetails(data)),
    setUKInternalCAFDetails: async (data) =>
      dispatch(await setUKInternalCAFDetails(data)),
    setUKInternalChapterError: (data) =>
      dispatch(setUKInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter3);
