import { PEPXArray, UBOXArray } from "../JSONs/CommonFields/commonFields";

export function ManageDataOnSelection(name, value, reducer, state) {
  if (name === "isUBO" && reducer === "commonFields") {
    state.ubo.noOfUBOs = 1;
    state.ubo.ubox = [UBOXArray];
    state.dirty = true;
  } else if (name === "isPEP" && reducer === "commonFields") {
    state.pep.noNonUboPep = 1;
    state.pep.pepx = [PEPXArray];
    state.dirty = true;
  } else if (name === "isUBO" && reducer === "clientInformation") {
    state.chapter3.isHoldingInterest = value;
    state.chapter3.isAssetClient = value;
    state.chapter3.isUBOClassified = value;
  }
  return state;
}
