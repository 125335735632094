export const SignedByList = [
  {
    label: "Compliance Officer",
    value: "Compliance Officer",
    isFixed: false,
    name: null,
    email: null,
    dos: new Date(),
  },
  {
    label: "Director",
    value: "Director",
    isFixed: false,
    name: null,
    email: null,
    dos: null,
  },
  {    
    value: "Director",
    value: "Director",
    isFixed: false,
    name: null,
    email: null,
    dos: null,
  },
];
