export const formValid = (formErrors) => {
  let valid = true;
  Object.values(formErrors).forEach(function (val) {
    if (Array.isArray(val)) {
      val.forEach(function (errors) {
        Object.values(errors).forEach(function (val) {
          val !== undefined && val.length > 0 && (valid = false);
        });
      });
    } else if (typeof val === "object" && !Array.isArray(val) && val !== null) {
      Object.entries(val).forEach(function (value) {
        const valKey = value[0];
        const valError = value[1];
        if (val !== undefined && valError.length > 0) {
          valid = false;
        }
      });
    } else {
      val !== undefined && val.length > 0 && (valid = false);
    }
  });
  return valid;
};
