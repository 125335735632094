import React from "react";
import { Row } from "react-bootstrap";
import CheckBox from "../../../../elements/CheckBox";
import { connect } from "react-redux";
import NumberInput from "../../../../elements/NumberInput";
import {AgmntObj} from "../../../../JSONs/UK-Form/Internal/Chapter6"
import Agmnt from "./Agmnts";

class SignedAgreements extends React.Component {
  render() {
    const { reducer, chapter, errors, isRequired, form,chapter6} = this.props;
    const errorsMsg = JSON.parse(errors);
    let lstAgreement = [];
    if(form=="UK"){    
    for (let i = 0; i < chapter6.noAgmnt; i++) {
      lstAgreement.push(
        <Agmnt
          index={i}
          baseName="lstAgmnt"
          reducer={reducer}
          form="UK"
          chapter={chapter}
        />
      );
    }
  }
    return (
      <>
        <h4 className="sub-chapter-title pt-4 mb-2">Signed Agreements</h4>
        <Row className="pt-3 pb-2">
          <div className="mb-3 col-lg-6">
            <CheckBox
              label={
                form === "Malta"
                  ? "Is there a signed and dated contract “Lease Agreement Office Space” between UIM and the Target Company?"
                  : "Is there a signed and dated contract “Lease Agreement Office Space” between UIM and the Object Company?"
              }
              options={["Yes", "No"]}
              name="isLeaseAgmntOfcSpace"
              id="isLeaseAgmntOfcSpace"
              reducer={reducer}
              chapter={chapter}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isLeaseAgmntOfcSpace?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isLeaseAgmntOfcSpace}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-6">
            <CheckBox
              label={
                form === "Malta"
                  ? "Is there a signed and dated contract “Registered Office Agreement” between UIM and the Target Company?”"
                  : "Is there a signed and dated contract “Domiciliation Agreement” between UIM and the Object Company?”"
              }
              options={["Yes", "No"]}
              name="isDomicilliationAgmnt"
              id="isDomicilliationAgmnt"
              reducer={reducer}
              chapter={chapter}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isDomicilliationAgmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isDomicilliationAgmnt}
                </span>
              )}
          </div>
          {this.props.form !== "Malta" && (
            <div className="mb-3 col-lg-6">
              <CheckBox
                label="Is there a signed and dated “Declaration External Authorized Person” form?"
                options={["Yes", "No"]}
                name="isDeapForm"
                id="isDeapForm"
                isRequired={isRequired}
                reducer={reducer}
                chapter={chapter}
              />
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.isDeapForm?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg.isDeapForm}
                  </span>
                )}
            </div>
          )}
          <div className="mb-3 col-lg-6">
            <CheckBox
              label={
                form === "Malta"
                  ? "Is there a signed and dated contract “Administration Agreement” between UIM and the Target Company?"
                  : "Is there a signed and dated contract “Administration Agreement” between UIM and the Object Company?"
              }
              options={["Yes", "No"]}
              name="isAdministrationAgmnt"
              id="isAdministrationAgmnt"
              reducer={reducer}
              chapter={chapter}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isAdministrationAgmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isAdministrationAgmnt}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-6">
            <CheckBox
              label={
                form === "Malta"
                  ? "Is there a signed and dated “Client Agreement” between UIM and the UBOs?"
                  : "Is there a signed and dated “Principal Party Agreement” between UIM and the UBOs?"
              }
              options={["Yes", "No"]}
              name="isPrinciplePartyAgmnt"
              id="isPrinciplePartyAgmnt"
              reducer={reducer}
              chapter={chapter}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isPrinciplePartyAgmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isPrinciplePartyAgmnt}
                </span>
              )}
          </div>
          <div className="mb-3 col-lg-6">
            <CheckBox
              label={
                form === "Malta"
                  ? "Is there a signed and dated “Management agreement” between UIM and the Target Company?"
                  : "Is there a signed and dated “Management agreement” between UIM and the Object Company?"
              }
              options={["Yes", "No"]}
              name="isManagementAgmnt"
              id="isManagementAgmnt"
              reducer={reducer}
              chapter={chapter}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isManagementAgmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isManagementAgmnt}
                </span>
              )}
          </div>
          
          <div className="mb-3 col-lg-12">
            <CheckBox
              label={`Are there other agreements, which the ${
                this.props.form === "Malta" ? "" : "trust"
              } office has entered into in respect of the services provided by the trust office?`}
              options={["Yes", "No"]}
              name="isOtherAgmnt"
              id="isOtherAgmnt"
              reducer={reducer}
              chapter={chapter}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isOtherAgmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isOtherAgmnt}
                </span>
              )}
          </div>
   
            {form==="UK" && chapter6.isOtherAgmnt ==="Yes" &&(
              <>
               <div className="mb-3 col-lg-12">
              <NumberInput
                label="How many agreements?"
                name="noAgmnt"
                referral={["lstAgmnt"]}
                pushedObject={[AgmntObj]}
                maxLimit={100}
                reducer={reducer}
                chapter={chapter}               
              />
               
            </div>   
            <div className="mb-3 col-lg-12">{lstAgreement}</div>
            </>
            )}
         
        </Row>
      </>
    );
  }
}

//export default SignedAgreements;
const mapStateToProps = (state, ownProps) => {
  const { reducer, chapter } = ownProps;
  return {
    chapter6: state[reducer][chapter],
       
  };
};
export default connect(mapStateToProps, null)(SignedAgreements);
