import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import DownloadFile from "../../../../elements/DownloadFile";
import TextBox from "../../../../elements/TextBox";
import BankInfoMalta from "../../MaltaOffice/Chapter4/BankInfoMalta";
import BankInfo from "./BankInfo";
import { fileAcceptType } from "../Constants";
import InternalFileUpload from "../../../../elements/InternalFileUpload";
class BankAccntDetails extends React.Component {
  render() {
    const { ovrvwBnkAC, errors, isRequired, isFatcaSCF } = this.props;
    const errorsMsg = JSON.parse(errors);

    return (
      <>
        {this.props.form === "Malta" ? (
          <BankInfoMalta
            errors={errors}
            externalReducer={this.props.externalReducer}
            chapter={this.props.chapter}
            reducer={this.props.reducer}
            form={this.props.form}
            isRequired={isRequired}
            PdfGeneration={this.props.PdfGeneration}
          />
        ) : (
          <BankInfo
            errors={errors}
            externalReducer={this.props.externalReducer}
            chapter={this.props.chapter}
            reducer={this.props.reducer}
            form={this.props.form}
            isRequired={isRequired}
            PdfGeneration={this.props.PdfGeneration}
          />
        )}

        {(this.props.form === "NL" || this.props.form === "UK") && (
          <Row className="pt-4 pb-2">
            {ovrvwBnkAC.isFatcaSCF && (
              <div className="mb-3 col-lg-4">
                <CheckBox
                  label="FATCA Self Certification Form?"
                  options={["Yes", "No"]}
                  name="isFatcaSCF"
                  chapter={this.props.chapter}
                  objBaseName="ovrvwBnkAC"
                  reducer={this.props.externalReducer}
                  bothDisabled={true}
                />
              </div>
            )}
            {!ovrvwBnkAC.isFatcaSCF && (
              <div className="mb-3 col-lg-4">
                <CheckBox
                  label="FATCA Self Certification Form?"
                  options={["Yes", "No"]}
                  name="isFatcaSCF"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                />
              </div>
            )}
            {ovrvwBnkAC.isFatcaSCF === "Yes" && (
              <>
                <div className="mb-3 col-lg-4">
                  <DownloadFile
                    label="Upload FATCA Self Certification Form"
                    value={ovrvwBnkAC.fatcaSelfCertifctnFrm}
                  />
                </div>
                <div className="mb-2 col-lg-4">
                  <CheckBox
                    label="Is approved?"
                    options={["Yes", "No"]}
                    name="fatcaFormApprvd"
                    isRequired={isRequired}
                    id="fatcaFormApprvd"
                    chapter={this.props.chapter}
                    reducer={this.props.reducer}
                  />
                  {errorsMsg &&
                    errorsMsg?.isSubmitted === true &&
                    errorsMsg?.fatcaFormApprvd?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.fatcaFormApprvd}
                      </span>
                    )}
                </div>
              </>
            )}
            {isFatcaSCF === "Yes" && (
              <>
                <div className="mb-3 col-lg-4">
                  <InternalFileUpload
                    label="Upload FATCA Self Certification Form"
                    name="fatcaSelfCertifctnFrm"
                    id="fatcaSelfCertifctnFrm"
                    chapter={this.props.chapter}
                    reducer={this.props.reducer}
                    acceptType={fileAcceptType}
                  />
                </div>
                <div className="mb-2 col-lg-4">
                  <CheckBox
                    label="Is approved?"
                    options={["Yes", "No"]}
                    name="fatcaFormApprvd"
                    isRequired={isRequired}
                    id="fatcaFormApprvd"
                    chapter={this.props.chapter}
                    reducer={this.props.reducer}
                  />
                  {errorsMsg &&
                    errorsMsg?.isSubmitted === true &&
                    errorsMsg?.fatcaFormApprvd?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.fatcaFormApprvd}
                      </span>
                    )}
                </div>
              </>
            )}
            {ovrvwBnkAC.isFatcaSCF === "No" && (
              <div className="mb-3 col-lg-4">
                <TextBox
                  label="Please, explain why there is no self-certification for FATCA"
                  name="noFATCAformReason"
                  id="noFATCAformReason"
                  placeholder="enter your text"
                  chapter={this.props.chapter}
                  objBaseName="ovrvwBnkAC"
                  reducer={this.props.externalReducer}
                  validationType="Text"
                  disabled={true}
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
              </div>
            )}
            {isFatcaSCF === "No" && (
              <div className="mb-3 col-lg-4">
                <TextBox
                  label="Please, explain why there is no self-certification for FATCA"
                  name="noFATCAformReason"
                  id="noFATCAformReason"
                  placeholder="enter your text"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
              </div>
            )}
          </Row>
        )}
        <div className="html2pdf__page-break"></div>
        <h4 className="sub-chapter-title mb-4 mt-5">
          Explain the procedure of handling payments/processing of bank
          statements and drafting of financial statements.
        </h4>
        {(this.props.form === "NL" || this.props.form === "UK") && (
          <Row className="pt-4 pb-2">
            <div className="mb-2 col-lg-6 col-xl-4">
              {this.props.form !== "NL" && (
                <DownloadFile
                  label="Bank Statements"
                  value={ovrvwBnkAC.bnkStmnts}
                />
              )}
              {ovrvwBnkAC.bnkStmnts.length != 0 && this.props.form === "NL" && (
                <DownloadFile
                  label="Bank Statements"
                  value={ovrvwBnkAC.bnkStmnts}
                />
              )}
              {ovrvwBnkAC.bnkStmnts.length == 0 && this.props.form === "NL" && (
                <InternalFileUpload
                  label="Bank Statements"
                  name="bnkStmnts"
                  id="bnkStmnts"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  acceptType={fileAcceptType}
                />
              )}
            </div>
            <div className="mb-2 col-lg-3 col-xl-3">
              <CheckBox
                label="Is Approved?"
                options={["Yes", "No"]}
                name="bnkStmntsApprvd"
                isRequired={isRequired}
                id="bnkStmntsApprvd"
                chapter={this.props.chapter}
                reducer={this.props.reducer}
              />
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.bnkStmntsApprvd?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg.bnkStmntsApprvd}
                  </span>
                )}
            </div>
            <div className="mb-2 col-lg-5 col-xl-5">
            <TextBox
                  label="Explain"
                  name="bnkStmntsApprvdExplain"
                  id="bnkStmntsApprvdExplain"
                  placeholder="enter your text"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
             
            </div>
           
          </Row>
        )}
        <Row>
          <div className="mb-2 col-lg-6 col-xl-4">
            {this.props.form !== "NL" && (
              <DownloadFile
                label="Agreements with group entities or other parties"
                value={ovrvwBnkAC.agremntsGrUpEntities}
              />
            )}
            {ovrvwBnkAC.agremntsGrUpEntities.length != 0 && this.props.form === "NL" && (
              <DownloadFile
                label="Agreements with group entities or other parties"
                value={ovrvwBnkAC.agremntsGrUpEntities}
              />
            )}
            {ovrvwBnkAC.bnkStmnts.length == 0 && this.props.form === "NL" && (
              <InternalFileUpload
                label="Agreements with group entities or other parties"
                name="agremntsGrUpEntities"
                id="agremntsGrUpEntities"
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                acceptType={fileAcceptType}
              />
            )}
          </div>
          <div className="mb-2 col-lg-3 col-xl-3">
            <CheckBox
              label="Is Approved?"
              options={["Yes", "No"]}
              isRequired={isRequired}
              name="aggrmntsGrpEntApprvd"
              id="aggrmntsGrpEntApprvd"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.aggrmntsGrpEntApprvd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.aggrmntsGrpEntApprvd}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-5 col-xl-5">
            <TextBox
                  label="Explain"
                  name="aggrmntsGrpEntApprvdExplain"
                  id="aggrmntsGrpEntApprvdExplain"
                  placeholder="enter your text"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
             
            </div>
        </Row>
        <Row>
          <div className="mb-2 col-lg-6 col-xl-4">
            {this.props.form !== "NL" && (
              <DownloadFile
                label="Agreements with other parties"
                value={ovrvwBnkAC.agremntsOthrParties}
              />
            )}
            {ovrvwBnkAC.agremntsOthrParties.length != 0 && this.props.form === "NL" && (
              <DownloadFile
                label="Agreements with other parties"
                value={ovrvwBnkAC.agremntsOthrParties}
              />
            )}
            {ovrvwBnkAC.agremntsOthrParties.length == 0 && this.props.form === "NL" && (
              <InternalFileUpload
                label="Agreements with other parties"
                name="agremntsOthrParties"
                id="agremntsOthrParties"
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                acceptType={fileAcceptType}
              />
            )}
          </div>
          <div className="mb-2 col-lg-3 col-xl-3">
            <CheckBox
              label="Is Approved?"
              options={["Yes", "No"]}
              isRequired={isRequired}
              name="aggrmntsOthrParApprvd"
              id="aggrmntsOthrParApprvd"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.aggrmntsOthrParApprvd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.aggrmntsOthrParApprvd}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-5 col-xl-5">
            <TextBox
                  label="Explain"
                  name="aggrmntsOthrParApprvdExplain"
                  id="aggrmntsOthrParApprvdExplain"
                  placeholder="enter your text"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
             
            </div>
        </Row>
        <Row>
          <div className="mb-2 col-lg-6 col-xl-4">
            {this.props.form !== "NL" && (
              <DownloadFile
                label={
                  this.props.form === "Malta"
                    ? "Financial statements Target Company"
                    : "Financial statements Object Company"
                }
                value={ovrvwBnkAC.financialStmntsObjComp}
              />
            )}
            {ovrvwBnkAC.financialStmntsObjComp.length != 0 && this.props.form === "NL" && (
              <DownloadFile
                label={
                  this.props.form === "Malta"
                    ? "Financial statements Target Company"
                    : "Financial statements Object Company"
                }
                value={ovrvwBnkAC.financialStmntsObjComp}
              />
            )}
            {ovrvwBnkAC.financialStmntsObjComp.length == 0 && this.props.form === "NL" && (
              <InternalFileUpload
                label="Financial statements Object Company"
                name="financialStmntsObjComp"
                id="financialStmntsObjComp"
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                acceptType={fileAcceptType}
              />
            )}
          </div>
          <div className="mb-2 col-lg-3 col-xl-3">
            <CheckBox
              label="Is Approved?"
              options={["Yes", "No"]}
              name="finStmntObjCompApprvd"
              isRequired={isRequired}
              id="finStmntObjCompApprvd"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.finStmntObjCompApprvd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.finStmntObjCompApprvd}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-5 col-xl-5">
            <TextBox
                  label="Explain"
                  name="finStmntObjCompApprvdExplain"
                  id="finStmntObjCompApprvdExplain"
                  placeholder="enter your text"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
             
            </div>
        </Row>
        <Row>
          <div className="mb-2 col-lg-6 col-xl-4">
            {this.props.form !== "NL" && (
              <DownloadFile
                label="Financial statements for all participations"
                value={ovrvwBnkAC.finanicalStmntsAllPrticipatns}
              />
            )}
            {ovrvwBnkAC.finanicalStmntsAllPrticipatns.length != 0 && this.props.form === "NL" && (
              <DownloadFile
                label="Financial statements for all participations"
                value={ovrvwBnkAC.finanicalStmntsAllPrticipatns}
              />
            )}
            {ovrvwBnkAC.finanicalStmntsAllPrticipatns.length == 0 && this.props.form === "NL" && (
              <InternalFileUpload
                label="Financial statements for all participations"
                name="finanicalStmntsAllPrticipatns"
                id="finanicalStmntsAllPrticipatns"
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                acceptType={fileAcceptType}
              />
            )}
          </div>
          <div className="mb-2 col-lg-3 col-xl-3">
            <CheckBox
              label="Is Approved?"
              options={["Yes", "No"]}
              name="finStmntAllPartciApprvd"
              id="finStmntAllPartciApprvd"
              isRequired={isRequired}
              chapter={this.props.chapter}
              reducer={this.props.reducer}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.finStmntAllPartciApprvd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.finStmntAllPartciApprvd}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-5 col-xl-5">
            <TextBox
                  label="Explain"
                  name="finStmntAllPartciApprvdExplain"
                  id="finStmntAllPartciApprvdExplain"
                  placeholder="enter your text"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
             
            </div>
        </Row>
        <Row>
          <div className="mb-2 col-lg-6 col-xl-4">
            {this.props.form !== "NL" && (
              <DownloadFile
                label="Financial statements for the parent company"
                value={ovrvwBnkAC.financialStmntsParntComp}
              />
            )}
            {ovrvwBnkAC.financialStmntsParntComp.length != 0 && this.props.form === "NL" && (
              <DownloadFile
                label="Financial statements for the parent company"
                value={ovrvwBnkAC.financialStmntsParntComp}
              />
            )}
            {ovrvwBnkAC.financialStmntsParntComp.length == 0 && this.props.form === "NL" && (
              <InternalFileUpload
                label="Financial statements for the parent company"
                name="financialStmntsParntComp"
                id="financialStmntsParntComp"
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                acceptType={fileAcceptType}
              />
            )}
          </div>
          <div className="mb-2 col-lg-3 col-xl-3">
            <CheckBox
              label="Is Approved?"
              options={["Yes", "No"]}
              name="finStmntParCompApprvd"
              id="finStmntParCompApprvd"
              chapter={this.props.chapter}
              isRequired={isRequired}
              reducer={this.props.reducer}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.finStmntParCompApprvd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.finStmntParCompApprvd}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-5 col-xl-5">
            <TextBox
                  label="Explain"
                  name="finStmntParCompApprvdExplain"
                  id="finStmntParCompApprvdExplain"
                  placeholder="enter your text"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
             
            </div>
        </Row>
        {(this.props.form === "NL" || this.props.form === "UK") && (
          <Row>
            <div className="mb-2 col-lg-6 col-xl-4">
              {this.props.form !== "NL" && (
                <DownloadFile
                  label="Declarations from financial institutions"
                  value={ovrvwBnkAC.declartnsFinanicalInstitutns}
                />
              )}
              {ovrvwBnkAC.declartnsFinanicalInstitutns.length != 0 && this.props.form === "NL" && (
                <DownloadFile
                  label="Declarations from financial institutions"
                  value={ovrvwBnkAC.declartnsFinanicalInstitutns}
                />
              )}
              {ovrvwBnkAC.declartnsFinanicalInstitutns.length == 0 && this.props.form === "NL" && (
                <InternalFileUpload
                  label="Declarations from financial institutions"
                  name="declartnsFinanicalInstitutns"
                  id="declartnsFinanicalInstitutns"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  acceptType={fileAcceptType}
                />
              )}
            </div>
            <div className="mb-2 col-lg-3 col-xl-3">
              <CheckBox
                label="Is Approved?"
                options={["Yes", "No"]}
                name="declrFinInstApprvd"
                id="declrFinInstApprvd"
                isRequired={isRequired}
                chapter={this.props.chapter}
                reducer={this.props.reducer}
              />
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.declrFinInstApprvd?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg.declrFinInstApprvd}
                  </span>
                )}
            </div>
            <div className="mb-2 col-lg-5 col-xl-5">
            <TextBox
                  label="Explain"
                  name="declrFinInstApprvdExplain"
                  id="declrFinInstApprvdExplain"
                  placeholder="enter your text"
                  chapter={this.props.chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
             
            </div>
          </Row>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { externalReducer } = ownProps;
  return {
    ovrvwBnkAC: state[externalReducer].chapter4.ovrvwBnkAC,
    isFatcaSCF: state["ClientAcceptance"]["chapter4"]["isFatcaSCF"],
  };
};

export default connect(mapStateToProps, null)(BankAccntDetails);
