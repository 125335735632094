import React, { Component } from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../../elements/TextBox";

class DeclarationSourceofWealth extends Component {
  render() {
    return (
      <>
        <Row className="mt-0 mb-2">
          <div className="mb-2 col-lg-12">
            <TextBox
              label="Please describe the UBO’s acquiring of wealth over time in his/her professional business career. Key elements are income from salary, sale of property, personal wealth, savings, inheritance, company sale etc."
              placeholder="please enter details"
              id="uboAcqreWlthProfBusCar"
              name="uboAcqreWlthProfBusCar"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partB?.uboAcqreWlthProfBusCar?.length >
                0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partB?.uboAcqreWlthProfBusCar}
                </span>
              )}
          </div>
        </Row>
      </>
    );
  }
}

export default DeclarationSourceofWealth;
