import React, { Component } from "react";
import AuthorizedRepresentative from "./AuthorizedRepresentative";
import NumberInput from "../../../../../elements/NumberInput";
import { DeclarationForms } from "../../../../../JSONs/UBOJSON/AnnexUBO";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import SignatureComponent from "../../ubo-declaration-uim/SignatureComponent";

const UBOForm = "AnnexUBO";
const reducer = "AnnexUBOInformation";

class AnnexUBO extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { noOfRepresentative, errorMsg, name } = this.props;
    let eMsg = JSON.parse(errorMsg);
    let representatives = [];
    for (let i = 0; i < noOfRepresentative; i++) {
      representatives.push(
        <AuthorizedRepresentative
          index={i}
          baseName="declarationForms"
          errorMsg={eMsg}
        />
      );
    }

    return (
      <>
        <div className="dashboard external-dashboard">
          <div className="generic-form">
            <div className="eventHeader pt-3 pb-3">
              <h2 className="m-0 p-0 pagetitle">
                Annex UBO declaration: REPRESENTATION
              </h2>
            </div>
            <Row className="mt-3 mb-3">
              <div className="col-md-12">
                <p style={{ textAlign: "justify" }}>
                  The person or persons listed below are the first point(s) of
                  contact for the structure. They may give instructions to UIM
                  in the name of the UBO. For this purpose the UBO will grant a
                  Power of Attorney to the person he/she wishes to represent
                  him/her. Please include a copy of the passport of each
                  individual and fill out the Power of Attorney.
                </p>
              </div>
              <div className="mb-2 col-lg-12 pt-12">
                <NumberInput
                  label="How many representative are?"
                  name="noOfRepresentative"
                  chapter={UBOForm}
                  referral={["declarationForms"]}
                  pushedObject={[DeclarationForms]}
                  maxLimit={100}
                  reducer={reducer}
                />
              </div>
            </Row>
            <Row className="mt-3 mb-3">{representatives}</Row>
            <div className="html2pdf__page-break"></div>
            <SignatureComponent
              reducer={reducer}
              chapter={UBOForm}
              disabled={true}
              errorMsg={eMsg}
              name={name || localStorage.getItem("name")}
            />
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    noOfRepresentative:
      state.AnnexUBOInformation.AnnexUBO["noOfRepresentative"],
  };
};

export default connect(mapStateToProps, null)(AnnexUBO);
