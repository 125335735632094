import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { connect } from "react-redux";
import { setAllLuxembourgErrorsToNull } from "../../../../redux/actions/Luxembourg/LuxembourgInternalChapterErrors";
let formData;
let validForms = {};

class LuxembourgSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: null,
    };
  }
  render() {
    const { location } = this.props.history;
    const { internalErrors } = this.props;
    Object.entries(internalErrors).forEach((errors) => {
      const [key, value] = errors;
      const error = value?.errors;
      if (error) {
        validForms[key] = error;
      }
    });
    setInterval(() => {
      if (!formData) {
        formData = localStorage.getItem("formData");
        if (formData) {
          this.setState({ formData: formData });
        }
      }
    }, 100);
    return (
      <div className="sidebar">
        <ul>
          
          <li>
            <label>Client Maintenance</label>
            {/* <Link to="/clientlist"  className={activeIndex === "clientlist" ? "active" : ""}>Client Maintainance</Link> */}
            <ul className="sidebar-innerlist">
              <li>
                <Link
                  to="/clientlist"
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={
                    location.pathname === "/clientlist" ? "active" : ""
                  }
                >
                  <i className="fa fa-user fa-fw"></i> Client list
                </Link>
                <Link
                  to="/clientapprovals"
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={
                    location.pathname === "/clientapprovals" ? "active" : ""
                  }
                >
                  <i className="fa fa-check-circle fa-fw"></i> Client User Approvals
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <label>User Maintenance</label>
            {/* <Link to="/userlist"  className={activeIndex === "userlist" ? "active" : ""}>User Maintainance</Link> */}
            <ul className="sidebar-innerlist">
              <li>
                <Link
                  to="/userlist"
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={location.pathname === "/userlist" ? "active" : ""}
                >
                  <i className="fa fa-user fa-fw"></i> User list
                </Link>
                <Link
                  to="/userapprovals"
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  className={
                    location.pathname === "/userapprovals" ? "active" : ""
                  }
                >
                  <i className="fa fa-check-circle fa-fw"></i> User Approvals
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <label>Client Acceptance Form</label>
            <ul className="sidebar-innerlist">
              <li>
                <Link
                  onClick={() => {
                    formData = null;

                    localStorage.removeItem("isCAFValid");
                    localStorage.removeItem("formData");
                    this.props.setAllErrorToNull();
                  }}
                  to="/client-form-list"
                  className={
                    location.pathname === "/client-form-list" ? "active" : ""
                  }
                >
                  <i className="fa fa-user fa-fw"></i> Client Form List
                </Link>
              </li>
            </ul>
          </li>
          <li>
          <ul className="sidebar-innerlist subPoint">
            <li>
              <Link
                to="/luxembourg/client-activity"
                style={{
                  pointerEvents: formData ? "" : "none",
                  opacity: formData ? "" : "0.5",
                  fontWeight:
                    validForms?.formErrorschapter1?.isValid === true
                      ? "bolder"
                      : "",
                }}
                className={
                  location.pathname === "/luxembourg/client-activity"
                    ? "active"
                    : ""
                }
              >
                <i className="fa fa-check fa-fw"></i> Client Activity{" "}
              </Link>
            </li>
            <li>
              <Link
                to="/luxembourg/client-investigation"
                style={{
                  pointerEvents: formData ? "" : "none",
                  opacity: formData ? "" : "0.5",
                  fontWeight:
                    validForms?.formErrorschapter2?.isValid === true
                      ? "bolder"
                      : "",
                }}
                className={
                  location.pathname === "/luxembourg/client-investigation"
                    ? "active"
                    : ""
                }
              >
                <i className="fa fa-search fa-fw"></i> Client Investigation{" "}
              </Link>
            </li>
            <li>
              <Link
                to="/luxembourg/ubo-upc"
                style={{
                  pointerEvents: formData ? "" : "none",
                  opacity: formData ? "" : "0.5",
                  fontWeight:
                    validForms?.formErrorschapter3?.isValid === true
                      ? "bolder"
                      : "",
                }}
                className={
                  location.pathname === "/luxembourg/ubo-upc" ? "active" : ""
                }
              >
                <i className="fa fa-user-circle fa-fw"></i> UBO/UPC
              </Link>
            </li>
            <li>
              <Link
                to="/luxembourg/object-company"
                style={{
                  pointerEvents: formData ? "" : "none",
                  opacity: formData ? "" : "0.5",
                  fontWeight:
                    validForms?.formErrorschapter4?.isValid === true
                      ? "bolder"
                      : "",
                }}
                className={
                  location.pathname === "/luxembourg/object-company"
                    ? "active"
                    : ""
                }
              >
                <i className="fa fa-building-o fa-fw"></i> Object Company
              </Link>
            </li>
            <li>
              <Link
                to="/luxembourg/country-sanction-risks"
                style={{
                  pointerEvents: formData ? "" : "none",
                  opacity: formData ? "" : "0.5",
                  fontWeight:
                    validForms?.formErrorschapter5?.isValid === true
                      ? "bolder"
                      : "",
                }}
                className={
                  location.pathname === "/luxembourg/country-sanction-risks"
                    ? "active"
                    : ""
                }
              >
                <i className="fa fa-globe fa-fw"></i> Country/Sanction Risks
              </Link>
            </li>
            <li>
              <Link
                to="/luxembourg/agreement"
                style={{
                  pointerEvents: formData ? "" : "none",
                  opacity: formData ? "" : "0.5",
                  fontWeight:
                    validForms?.formErrorschapter6?.isValid === true
                      ? "bolder"
                      : "",
                }}
                className={
                  location.pathname === "/luxembourg/agreement" ? "active" : ""
                }
              >
                <i className="fa fa-thumbs-up fa-fw"></i> Agreement
              </Link>
            </li>
            <li>
              <Link
                to="/luxembourg/consolidated-risk-assessment"
                style={{
                  pointerEvents: formData ? "" : "none",
                  opacity: formData ? "" : "0.5",
                  fontWeight:
                    validForms?.formErrorschapter8?.isValid === true
                      ? "bolder"
                      : "",
                }}
                className={
                  location.pathname ===
                  "/luxembourg/consolidated-risk-assessment"
                    ? "active"
                    : ""
                }
              >
                <i className="fa fa-check-square-o fa-fw"></i> Consolidated Risk
                Assessment
              </Link>
            </li>
            <li>
              <Link
                to="/luxembourg/correspondence"
                style={{
                  pointerEvents: formData ? "" : "none",
                  opacity: formData ? "" : "0.5",
                  fontWeight:
                    validForms?.formErrorschapter9?.isValid === true
                      ? "bolder"
                      : "",
                }}
                className={
                  location.pathname === "/luxembourg/correspondence"
                    ? "active"
                    : ""
                }
              >
                <i className="fa fa-file-text-o fa-fw"></i> Correspondence
              </Link>
            </li>
            <li>
              <Link
                style={{
                  pointerEvents: formData ? "" : "none",
                  opacity: formData ? "" : "0.5",
                  fontWeight:
                    validForms?.formErrorschapter10?.isValid === true
                      ? "bolder"
                      : "",
                }}
                to="/luxembourg/miscellaneous"
                className={
                  location.pathname === "/luxembourg/miscellaneous"
                    ? "active"
                    : ""
                }
              >
                <i className="fa fa-cog fa-fw"></i> Miscellaneous
              </Link>
            </li>
            <li>
              <Link
                style={{
                  pointerEvents: formData ? "" : "none",
                  opacity: formData ? "" : "0.5",
                  fontWeight:
                    validForms?.formErrorschapter11?.isValid === true
                      ? "bolder"
                      : "",
                }}
                to="/luxembourg/summary-sheet-caf"
                className={
                  location.pathname === "/luxembourg/summary-sheet-caf"
                    ? "active"
                    : ""
                }
              >
                <i className="fa fa-list-alt fa-fw"></i> Summery sheet CAF
              </Link>
            </li>
            <li>
              <Link
                style={{
                  pointerEvents: formData ? "" : "none",
                  opacity: formData ? "" : "0.5",
                  fontWeight:
                    validForms?.formErrorschapter12?.isValid === true
                      ? "bolder"
                      : null,
                }}
                to="/luxembourg/formal-sign-off"
                className={
                  location.pathname === "/luxembourg/formal-sign-off"
                    ? "active"
                    : ""
                }
              >
                <i className="fa fa-pencil-square-o fa-fw"></i> Formal sign off
              </Link>
            </li>
          </ul>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    internalErrors: state.LuxembourgInternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAllErrorToNull: () => dispatch(setAllLuxembourgErrorsToNull()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LuxembourgSidebar)
);
