import { AdditionDocs } from "./Chapter1";

export const NewClient={
  clientName:null,
  country:null,
  explainDetails:null,
}

const UpcApprovals = {
  isExtCOCApprvd: null,
  isDeedOfIncorpoApprvd: null,
  isShareholdersRegisterApprvd: null,
  isshareholdersCertificatesApprvd: null,
  isNomineeAgreementApprvd: null,
  isFinStatmntsThreeYrsApprvd: null,
};

export const UboApprovals = {
  uboId: null,
  isSigningClient: null,
  isClientNew: null,
  isPep: null,
  pepDetail: null,
  isAsctPerson: null,
  asctPersonDetail: null,
  isHighProfilePerson: null,
  highProfDetail: null,
  isHighNetWorth: null,
  highNetDetail: null,
  noNewClients:1,
  newClients:[NewClient],
};

let array = [];
for (let i = 0; i < 10; i++) {
  array.push(UboApprovals);
}

export const Chapter3 = {
  isHldngIntrstCmmnt: null,
  isUBOClsifiedCmmnt: null,
  isAsstClientCmmnt: null,
  noUBOClassifiedCmmnt: null,
  uboApproval: array,
  upcApproval: UpcApprovals,
  pascalReportUpload: [],
  isFindings: null,
  descInvstgUBOClsifiedPEP: null,
  numAddDocs: 1,
  addDocs: [AdditionDocs],
  isUBOresultIncrsdIntgrtyRsk: null,
  ubOresultIncrsdIntgrtyRskCmmnt: null,
  dirty: false,
};
