import React, { Component } from "react";
import { Row } from "react-bootstrap";
import CheckBox from "../../../../../elements/CheckBox";
import FileUpload from "../../../../../elements/FileUpload";
import TextBox from "../../../../../elements/TextBox";
import countryList from "react-select-country-list";
import DropDown from "../../../../../elements/DropDown";
import { fileAcceptType } from "./Constants";
import { connect } from "react-redux";
import MultiSelectDropdown from "../../../../../elements/MultiSelectDropdown";

const chapter = "chapter2";
const baseName = "intermediaryDetails";
class Intermediary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, intermediary, eMsg } = this.props;

    const errorMsg = JSON.parse(eMsg);

    return (
      <div className="mb-4 col-lg-12">
        <fieldset>
          <legend>
            {this.props.form === "Malta"
              ? "Intermediary / Agent"
              : "Intermediary"}{" "}
            {index + 1}
          </legend>
          <Row>
            <div className="mb-2 col-lg-4">
              <CheckBox
                label="Natural Person"
                options={["Yes", "No"]}
                name="isClientReal"
                id={`isClientReal[${index}]`}
                index={index}
                chapter={chapter}
                baseName={baseName}
                reducer={this.props.reducer}
              />
              {Object.entries(errorMsg).length !== 0 &&
                errorMsg.intermediaryDetails &&
                errorMsg.intermediaryDetails[index] &&
                errorMsg.intermediaryDetails[index]?.isClientReal?.length >
                  0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errorMsg.intermediaryDetails[index]?.isClientReal}
                  </span>
                )}
            </div>

            {intermediary.isClientReal === "Yes" && (
              <>
                <div className="mb-2 col-lg-8">
                  {this.props.form === "Malta" && (
                    <>
                      <Row>
                        <div className="mb-3 col-lg-6">
                          <CheckBox
                            label="Is the natural person intermediary?"
                            options={["Yes", "No"]}
                            name="isNaturPerInterm"
                            id={`isNaturPerInterm[${index}]`}
                            index={index}
                            chapter={chapter}
                            baseName={baseName}
                            reducer={this.props.reducer}
                          />
                          {Object.entries(errorMsg).length !== 0 &&
                            errorMsg.intermediaryDetails &&
                            errorMsg.intermediaryDetails[index] &&
                            errorMsg.intermediaryDetails[index]
                              ?.isNaturPerInterm?.length > 0 && (
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {
                                  errorMsg.intermediaryDetails[index]
                                    ?.isNaturPerInterm
                                }
                              </span>
                            )}
                        </div>
                        {intermediary.isNaturPerInterm === "No" && (
                          <>
                            <div className="mb-3 col-lg-6">
                              <CheckBox
                                label="Is the natural person agent?"
                                options={["Yes", "No"]}
                                name="isNaturPerAgent"
                                id={`isNaturPerAgent[${index}]`}
                                index={index}
                                chapter={chapter}
                                baseName={baseName}
                                reducer={this.props.reducer}
                              />
                              {Object.entries(errorMsg).length !== 0 &&
                                errorMsg.intermediaryDetails &&
                                errorMsg.intermediaryDetails[index] &&
                                errorMsg.intermediaryDetails[index]
                                  ?.isNaturPerAgent?.length > 0 && (
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {
                                      errorMsg.intermediaryDetails[index]
                                        ?.isNaturPerAgent
                                    }
                                  </span>
                                )}
                            </div>
                          </>
                        )}
                      </Row>
                    </>
                  )}
                  {(this.props.form !== "Malta" ||
                    (this.props.form === "Malta" &&
                      intermediary.isNaturPerAgent === "Yes") ||
                    (this.props.form === "Malta" &&
                      intermediary.isNaturPerInterm === "Yes")) && (
                    <>
                      <Row>
                        <div className="mb-3 col-lg-6">
                          <TextBox
                            label={`Name intermediary ${
                              this.props.form === "Malta" ? "/ agent" : ""
                            }`}
                            placeholder="Enter Intermediary Name"
                            name="clientRepName"
                            id={`clientRepName[${index}]`}
                            index={index}
                            chapter={chapter}
                            baseName={baseName}
                            reducer={this.props.reducer}
                            validationType="Text"
                          />
                          {Object.entries(errorMsg).length !== 0 &&
                            errorMsg.intermediaryDetails &&
                            errorMsg.intermediaryDetails[index] &&
                            errorMsg.intermediaryDetails[index]?.clientRepName
                              ?.length > 0 && (
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {
                                  errorMsg.intermediaryDetails[index]
                                    ?.clientRepName
                                }
                              </span>
                            )}
                        </div>
                        <div className="mb-2 col-lg-6">
                          <TextBox
                            label="Legal entity name"
                            placeholder="enter legal entity name"
                            name="legalEntityName"
                            id={`legalEntityName[${index}]`}
                            index={index}
                            chapter={chapter}
                            baseName={baseName}
                            reducer={this.props.reducer}
                            validationType="Text"
                          />
                          {Object.entries(errorMsg).length !== 0 &&
                            errorMsg.intermediaryDetails &&
                            errorMsg.intermediaryDetails[index] &&
                            errorMsg.intermediaryDetails[index]?.legalEntityName
                              ?.length > 0 && (
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {
                                  errorMsg.intermediaryDetails[index]
                                    ?.legalEntityName
                                }
                              </span>
                            )}
                        </div>
                        <div className="mb-2 col-lg-6">
                          <FileUpload
                            label="Upload passport copy"
                            index={index}
                            name="clientRepPassport"
                            id={`clientRepPassport[${index}]`}
                            acceptType={fileAcceptType}
                            chapter={chapter}
                            baseName={baseName}
                            reducer={this.props.reducer}
                          />
                          {Object.entries(errorMsg).length !== 0 &&
                            errorMsg.intermediaryDetails &&
                            errorMsg.intermediaryDetails[index] &&
                            errorMsg.intermediaryDetails[index]
                              ?.clientRepPassport?.length > 0 && (
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {
                                  errorMsg.intermediaryDetails[index]
                                    ?.clientRepPassport
                                }
                              </span>
                            )}
                        </div>
                        <div className="mb-2 col-lg-6">
                          <DropDown
                            label="Nationality"
                            options={countryList().getData()}
                            index={index}
                            name="clientRepNationality"
                            id={`clientRepNationality[${index}]`}
                            chapter={chapter}
                            baseName={baseName}
                            reducer={this.props.reducer}
                          />
                          {Object.entries(errorMsg).length !== 0 &&
                            errorMsg.intermediaryDetails &&
                            errorMsg.intermediaryDetails[index] &&
                            errorMsg.intermediaryDetails[index]
                              ?.clientRepNationality?.length > 0 && (
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {
                                  errorMsg.intermediaryDetails[index]
                                    ?.clientRepNationality
                                }
                              </span>
                            )}
                        </div>
                        {this.props.form === "Malta" && (
                          <>
                            <div className="mb-2 col-lg-6">
                              <TextBox
                                label="Address"
                                placeholder="enter address"
                                name="address"
                                id={`address[${index}]`}
                                index={index}
                                chapter={chapter}
                                baseName={baseName}
                                reducer={this.props.reducer}
                                validationType="Text"
                              />
                              {Object.entries(errorMsg).length !== 0 &&
                                errorMsg.intermediaryDetails &&
                                errorMsg.intermediaryDetails[index] &&
                                errorMsg.intermediaryDetails[index]?.address
                                  ?.length > 0 && (
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {
                                      errorMsg.intermediaryDetails[index]
                                        ?.address
                                    }
                                  </span>
                                )}
                            </div>
                            <div className="mb-2 col-lg-6">
                              <FileUpload
                                label="Upload utility bill"
                                index={index}
                                name="utilityBill"
                                id={`utilityBill[${index}]`}
                                acceptType={fileAcceptType}
                                chapter={chapter}
                                baseName={baseName}
                                reducer={this.props.reducer}
                              />
                              {Object.entries(errorMsg).length !== 0 &&
                                errorMsg.intermediaryDetails &&
                                errorMsg.intermediaryDetails[index] &&
                                errorMsg.intermediaryDetails[index]?.utilityBill
                                  ?.length > 0 && (
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {
                                      errorMsg.intermediaryDetails[index]
                                        ?.utilityBill
                                    }
                                  </span>
                                )}
                            </div>
                          </>
                        )}
                        {this.props.form === "UK" && (
                          <>
                            <div className="mb-2 col-lg-6">
                              <FileUpload
                                label="Upload Proof of Address"
                                index={index}
                                name="intrProofAdd"
                                id={`intrProofAdd[${index}]`}
                                acceptType={fileAcceptType}
                                chapter={chapter}
                                baseName={baseName}
                                reducer={this.props.reducer}
                              />
                              {Object.entries(errorMsg).length !== 0 &&
                                errorMsg.intermediaryDetails &&
                                errorMsg.intermediaryDetails[index] &&
                                errorMsg.intermediaryDetails[index]
                                  ?.intrProofAdd?.length > 0 && (
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {
                                      errorMsg.intermediaryDetails[index]
                                        ?.intrProofAdd
                                    }
                                  </span>
                                )}
                            </div>
                            <div className="mb-2 col-lg-6">
                              <FileUpload
                                label="Upload reference letter"
                                index={index}
                                name="intrReferenceLett"
                                id={`intrReferenceLett[${index}]`}
                                acceptType={fileAcceptType}
                                chapter={chapter}
                                baseName={baseName}
                                reducer={this.props.reducer}
                              />
                              {Object.entries(errorMsg).length !== 0 &&
                                errorMsg.intermediaryDetails &&
                                errorMsg.intermediaryDetails[index] &&
                                errorMsg.intermediaryDetails[index]
                                  ?.intrReferenceLett?.length > 0 && (
                                  <span
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {
                                      errorMsg.intermediaryDetails[index]
                                        ?.intrReferenceLett
                                    }
                                  </span>
                                )}
                            </div>
                          </>
                        )}
                      </Row>
                    </>
                  )}
                </div>
              </>
            )}

            {intermediary.isClientReal === "No" && (
              <>
                <div className="mb-2 col-lg-4">
                  <TextBox
                    label="Legal entity name"
                    name="legalEntityName"
                    id={`legalEntityName[${index}]`}
                    placeholder="enter legal entity name"
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                    validationType="Text"
                  />
                  {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.intermediaryDetails &&
                    errorMsg.intermediaryDetails[index] &&
                    errorMsg.intermediaryDetails[index]?.legalEntityName
                      ?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errorMsg.intermediaryDetails[index]?.legalEntityName}
                      </span>
                    )}
                </div>
                <div className="mb-2 col-lg-4">
                  <FileUpload
                    label="Upload CoC extract"
                    index={index}
                    name="cocExtract"
                    id={`cocExtract[${index}]`}
                    acceptType={fileAcceptType}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                  />
                  {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.intermediaryDetails &&
                    errorMsg.intermediaryDetails[index] &&
                    errorMsg.intermediaryDetails[index]?.cocExtract?.length >
                      0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errorMsg.intermediaryDetails[index]?.cocExtract}
                      </span>
                    )}
                </div>
              </>
            )}
            {intermediary.isClientReal === "" && (
              <>
                <div className="mb-2 col-lg-4"></div>
                <div className="mb-2 col-lg-4"></div>
              </>
            )}

            <div className="mb-3 col-lg-12">
              <div className="row">
                <div className="form-group col-lg-4">
                  <MultiSelectDropdown
                    label="Active in countries"
                    name="activeInCountry"
                    id={`activeInCountry[${index}]`}
                    options={countryList().getData()}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                    isRequired={this.props.form === "UK" ? true : false} 
                  />
                  {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.intermediaryDetails &&
                    errorMsg.intermediaryDetails[index] &&
                    errorMsg.intermediaryDetails[index]?.activeInCountry
                      ?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errorMsg.intermediaryDetails[index]?.activeInCountry}
                      </span>
                    )}
                </div>

                <div className="mb-2 col-lg-4" id={`powerOfAttorney[${index}]`}>
                  <CheckBox
                    label="Power of attorney available"
                    options={["Yes", "No"]}
                    name="isPOA"
                    id={`isPOA[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                    isRequired={this.props.form === "UK" ? true : false} 
                  />
                  {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.intermediaryDetails &&
                    errorMsg.intermediaryDetails[index] &&
                    errorMsg.intermediaryDetails[index]?.isPOA?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errorMsg.intermediaryDetails[index]?.isPOA}
                      </span>
                    )}
                  {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.intermediaryDetails &&
                    errorMsg.intermediaryDetails[index] &&
                    errorMsg.intermediaryDetails[index]?.powerOfAttorney
                      ?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errorMsg.intermediaryDetails[index]?.powerOfAttorney}
                      </span>
                    )}
                </div>
                {intermediary.isPOA === "Yes" && (
                  <div className="mb-2 col-lg-4">
                    <FileUpload
                      label="Upload"
                      acceptType={fileAcceptType}
                      name="poa"
                      id={`poa[${index}]`}
                      index={index}
                      chapter={chapter}
                      baseName={baseName}
                      reducer={this.props.reducer}
                      isRequired={this.props.form === "UK" ? true : false} 
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.intermediaryDetails &&
                      errorMsg.intermediaryDetails[index] &&
                      errorMsg.intermediaryDetails[index]?.poa?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errorMsg.intermediaryDetails[index]?.poa}
                        </span>
                      )}
                  </div>
                )}
                {intermediary.isPOA === "No" && (
                  <div className="mb-2 col-lg-4">
                    <TextBox
                      label="Why Not?"
                      name="noPOAReason"
                      id={`noPOAReason[${index}]`}
                      placeholder="Enter Reason"
                      index={index}
                      chapter={chapter}
                      baseName={baseName}
                      reducer={this.props.reducer}
                      validationType="Text"
                      isRequired={this.props.form === "UK" ? true : false} 
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.intermediaryDetails &&
                      errorMsg.intermediaryDetails[index] &&
                      errorMsg.intermediaryDetails[index]?.noPOAReason?.length >
                        0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errorMsg.intermediaryDetails[index]?.noPOAReason}
                        </span>
                      )}
                  </div>
                )}
                {intermediary.isPOA === "" && (
                  <div className="mb-2 col-lg-4"></div>
                )}
              </div>
            </div>
            {this.props.form === "UK" && (
              <div className="col-lg-12">
                <div className="row">
                  <div className="mb-2 col-lg-4">
                    <CheckBox
                      label="Is the intermediary regulated by UK equivalent regulator?"
                      options={["Yes", "No"]}
                      name="isIntRegUKEqRegu"
                      id={`isIntRegUKEqRegu[${index}]`}
                      index={index}
                      chapter={chapter}
                      baseName={baseName}
                      reducer={this.props.reducer}
                      isRequired={this.props.form === "UK" ? true : false} 
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.intermediaryDetails &&
                      errorMsg.intermediaryDetails[index] &&
                      errorMsg.intermediaryDetails[index]?.isIntRegUKEqRegu
                        ?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {
                            errorMsg.intermediaryDetails[index]
                              ?.isIntRegUKEqRegu
                          }
                        </span>
                      )}
                  </div>

                  {intermediary.isIntRegUKEqRegu === "Yes" && (
                    <div className="mb-2 col-lg-4">
                      <FileUpload
                        label="Upload supporing documents"
                        acceptType={fileAcceptType}
                        name="eqRegulator"
                        id={`eqRegulator[${index}]`}
                        index={index}
                        chapter={chapter}
                        baseName={baseName}
                        reducer={this.props.reducer}
                      />
                      {Object.entries(errorMsg).length !== 0 &&
                        errorMsg.intermediaryDetails &&
                        errorMsg.intermediaryDetails[index] &&
                        errorMsg.intermediaryDetails[index]?.eqRegulator
                          ?.length > 0 && (
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {errorMsg.intermediaryDetails[index]?.eqRegulator}
                          </span>
                        )}
                    </div>
                  )}
                  {intermediary.isIntRegUKEqRegu === "No" && (
                    <div className="mb-2 col-lg-4">
                      <TextBox
                        label="Why Not?"
                        placeholder="Enter Reason"
                        name="eqRegExplaination"
                        id={`eqRegExplaination[${index}]`}
                        index={index}
                        chapter={chapter}
                        baseName={baseName}
                        reducer={this.props.reducer}
                        validationType="Text"
                        isRequired={this.props.form === "UK" ? true : false} 
                      />
                      {Object.entries(errorMsg).length !== 0 &&
                        errorMsg.intermediaryDetails &&
                        errorMsg.intermediaryDetails[index] &&
                        errorMsg.intermediaryDetails[index]?.eqRegExplaination
                          ?.length > 0 && (
                          <span style={{ color: "red", fontSize: "14px" }}>
                            {
                              errorMsg.intermediaryDetails[index]
                                ?.eqRegExplaination
                            }
                          </span>
                        )}
                    </div>
                  )}
                  {intermediary.isIntRegUKEqRegu === "" && (
                    <div className="mb-2 col-lg-4"></div>
                  )}
                </div>
              </div>
            )}
 {this.props.form !== "NL" &&(
            <div className="col-lg-12">
              <div className="row">
                <div className="mb-3 col-lg-4">
                  <CheckBox
                    label="Is the intermediary's' ultimate beneficial owner identified and verified? "
                    options={["Yes", "No"]}
                    name="isUBOIdentifyVerify"
                    id={`isUBOIdentifyVerify[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                    isRequired={this.props.form === "UK" ? true : false} 
                  />
                  {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.intermediaryDetails &&
                    errorMsg.intermediaryDetails[index] &&
                    errorMsg.intermediaryDetails[index]?.isUBOIdentifyVerify
                      ?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.intermediaryDetails[index]
                            ?.isUBOIdentifyVerify
                        }
                      </span>
                    )}
                </div>
                <div className="mb-3 col-lg-8">
                  <Row>
                    {intermediary.isUBOIdentifyVerify === "Yes" && (
                      <>
                        <div className="mb-2 col-lg-6">
                          <FileUpload
                            label="Upload Passport"
                            acceptType={fileAcceptType}
                            name="uboIdentityPassport"
                            id={`uboIdentityPassport[${index}]`}
                            index={index}
                            chapter={chapter}
                            baseName={baseName}
                            reducer={this.props.reducer}
                            isRequired={this.props.form === "UK" ? true : false} 
                          />
                          {Object.entries(errorMsg).length !== 0 &&
                            errorMsg.intermediaryDetails &&
                            errorMsg.intermediaryDetails[index] &&
                            errorMsg.intermediaryDetails[index]
                              ?.uboIdentityPassport?.length > 0 && (
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {
                                  errorMsg.intermediaryDetails[index]
                                    ?.uboIdentityPassport
                                }
                              </span>
                            )}
                        </div>
                        {this.props.form === "Malta" && (
                          <>
                            <div className="mb-2 col-lg-6">
                              <TextBox
                                label="Address of UBO"
                                placeholder="enter address"
                                name="addressUboInt"
                                id={`addressUboInt[${index}]`}
                                index={index}
                                chapter={chapter}
                                baseName={baseName}
                                reducer={this.props.reducer}
                                validationType="Text"
                              />
                              {Object.entries(errorMsg).length !== 0 &&
                                errorMsg.intermediaryDetails &&
                                errorMsg.intermediaryDetails[index] &&
                                errorMsg.intermediaryDetails[index]
                                  ?.addressUboInt?.length > 0 && (
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {
                                      errorMsg.intermediaryDetails[index]
                                        ?.addressUboInt
                                    }
                                  </span>
                                )}
                            </div>
                            <div className="mb-2 col-lg-4">
                              <FileUpload
                                label="Upload utility bill of UBO"
                                index={index}
                                name="utilityBillUboInt"
                                id={`utilityBillUboInt[${index}]`}
                                acceptType={fileAcceptType}
                                chapter={chapter}
                                baseName={baseName}
                                reducer={this.props.reducer}
                              />
                              {Object.entries(errorMsg).length !== 0 &&
                                errorMsg.intermediaryDetails &&
                                errorMsg.intermediaryDetails[index] &&
                                errorMsg.intermediaryDetails[index]
                                  ?.utilityBillUboInt?.length > 0 && (
                                  <span
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {
                                      errorMsg.intermediaryDetails[index]
                                        ?.utilityBillUboInt
                                    }
                                  </span>
                                )}
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {intermediary.isUBOIdentifyVerify === "No" && (
                      <div className="mb-2 col-lg-4">
                        <TextBox
                          label="Why Not?"
                          placeholder="Enter Reason"
                          name="noUBOIdentifyVerifyReason"
                          id={`noUBOIdentifyVerifyReason[${index}]`}
                          index={index}
                          chapter={chapter}
                          baseName={baseName}
                          reducer={this.props.reducer}
                          validationType="Text"
                          isRequired={this.props.form === "UK" ? true : false} 
                        />
                        {Object.entries(errorMsg).length !== 0 &&
                          errorMsg.intermediaryDetails &&
                          errorMsg.intermediaryDetails[index] &&
                          errorMsg.intermediaryDetails[index]
                            ?.noUBOIdentifyVerifyReason?.length > 0 && (
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {
                                errorMsg.intermediaryDetails[index]
                                  ?.noUBOIdentifyVerifyReason
                              }
                            </span>
                          )}
                      </div>
                    )}
                  </Row>
                </div>

                {this.props.form === "UK" ? (
                  <div className="mb-2 col-lg-4">
                    <FileUpload
                      label="Upload AML policies and procedure"
                      acceptType={fileAcceptType}
                      name="intrAmlPoliciesProcs"
                      id={`intrAmlPoliciesProcs[${index}]`}
                      index={index}
                      chapter={chapter}
                      baseName={baseName}
                      reducer={this.props.reducer}
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.intermediaryDetails &&
                      errorMsg.intermediaryDetails[index] &&
                      errorMsg.intermediaryDetails[index]?.intrAmlPoliciesProcs
                        ?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {
                            errorMsg.intermediaryDetails[index]
                              ?.intrAmlPoliciesProcs
                          }
                        </span>
                      )}
                  </div>
                ) : (
                  <div className="mb-2 col-lg-4"></div>
                )}
              </div>
            </div>
         )}
         {this.props.form === "NL" &&(
           <>
           <div className="mb-2 col-lg-4">
                        <TextBox
                          label="Name of Feeder"
                          placeholder="Enter name of feeder"
                          name="feederName"
                          id={`feederName[${index}]`}
                          index={index}
                          chapter={chapter}
                          baseName={baseName}
                          reducer={this.props.reducer}
                          validationType="Text"
                          isRequired={this.props.form === "UK" ? true : false} 
                        />
            </div>
            <div className="mb-2 col-lg-4">
                          <FileUpload
                            label="Upload Passport"
                            acceptType={fileAcceptType}
                            name="uboIdentityPassport"
                            id={`uboIdentityPassport[${index}]`}
                            index={index}
                            chapter={chapter}
                            baseName={baseName}
                            reducer={this.props.reducer}
                            isRequired={this.props.form === "UK" ? true : false} 
                          />
                          {Object.entries(errorMsg).length !== 0 &&
                            errorMsg.intermediaryDetails &&
                            errorMsg.intermediaryDetails[index] &&
                            errorMsg.intermediaryDetails[index]
                              ?.uboIdentityPassport?.length > 0 && (
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {
                                  errorMsg.intermediaryDetails[index]
                                    ?.uboIdentityPassport
                                }
                              </span>
                            )}
                        </div>
                        {/* <div className="mb-2 col-lg-4">
                          <FileUpload
                            label="Please include the findings of the independent tax advisor"
                            acceptType={fileAcceptType}
                            name="taxAdvisorFinds"
                            id={`taxAdvisorFinds[${index}]`}
                            index={index}
                            chapter={chapter}
                            baseName={baseName}
                            reducer={this.props.reducer}
                            isRequired={this.props.form === "UK" ? true : false} 
                          />
                          {Object.entries(errorMsg).length !== 0 &&
                            errorMsg.intermediaryDetails &&
                            errorMsg.intermediaryDetails[index] &&
                            errorMsg.intermediaryDetails[index]
                              ?.taxAdvisorFinds?.length > 0 && (
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {
                                  errorMsg.intermediaryDetails[index]
                                    ?.taxAdvisorFinds
                                }
                              </span>
                            )}
                        </div> */}
                        

           </>
         )}
          </Row>
        </fieldset>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { baseName, index, reducer } = ownProps;
  return {
    intermediary: state[reducer]["chapter2"][baseName][index],
    isClientReal: state[reducer]["chapter2"][baseName][index]["isClientReal"],
    IsCompIncorporated:
      state[reducer]["chapter2"][baseName][index]["IsCompIncorporated"],

    isPOA: state[reducer]["chapter2"][baseName][index]["isPOA"],
    isUBOIdentifyVerify:
      state[reducer]["chapter2"][baseName][index]["isUBOIdentifyVerify"],
    isIntRegUKEqRegu:
      state[reducer]["chapter2"][baseName][index]["isIntRegUKEqRegu"],
  };
};

export default connect(mapStateToProps, null)(Intermediary);
