import React, { Component } from "react";
import CheckBox from "../../../../elements/CheckBox";

class VAT extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { reducer, chapter, objBaseName, errors } = this.props;
    const errorsMsg = JSON.parse(errors);
    return (
      <div className="mb-3 col-lg-12">
        <fieldset className="eqheight">
          <legend>VAT</legend>
          <div className="row mb-0">
            <div className="mb-0 col-lg-4">
              <CheckBox
                label="Add VAT"
                options={["Yes", "No"]}
                name="addVatApprvl"
                id="addVatApprvl"
                reducer={reducer}
                chapter={chapter}
                objBaseName={objBaseName}
                //isRequired={true}
              />{" "}
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.vat &&
                errorsMsg?.vat?.addVatApprvl?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    {errorsMsg?.vat?.addVatApprvl}
                  </span>
                )}
            </div>
          </div>
        </fieldset>
      </div>
    );
  }
}

export default VAT;
