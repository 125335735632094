export function getTypeSizeFrqArray(office) {
  let array = [
    {
      label: `From ${
        office === "Malta" ? "Target" : "Object"
      } Company to group companies`,
      name: "objCompToGrpComp",
      dependency: "isUPCfundObjComp",
      textboxName: "objCompGrpCompsCmmnt",
    },
    {
      label: `From group companies to ${
        office === "Malta" ? "Target" : "Object"
      } Company`,
      name: "grpCompToObjComp",
      dependency: "isUPCfundObjComp",
      textboxName: "grpCompsObjCompCmmnt",
    },
    {
      label: `From ${office === "Malta" ? "Target" : "Object"} Company to UBO`,
      name: "objCompToUBO",
      dependency: "isUBOInvstObjComp",
      textboxName: "objCompUboCmmnt",
    },
    {
      label: `From UBO to ${office === "Malta" ? "Target" : "Object"} Company`,
      name: "uboToObjComp",
      dependency: "isUBOInvstObjComp",
      textboxName: "uboObjCmpCmmnt",
    },
    {
      label: `From ${
        office === "Malta" ? "Target" : "Object"
      } Company to third parties`,
      name: "objCompToTP",
      dependency: "isTpInvstObjComp",
      textboxName: "objCompTpCmmnt",
    },
    {
      label: `From third parties to ${
        office === "Malta" ? "Target" : "Object"
      } Company`,
      name: "tpToObjComp",
      dependency: "isTpInvstObjComp",
      textboxName: "tpObjCompCmmnt",
    },
  ];
  return array;
}
