import React, { Component } from "react";
import { Accordion, Card, Row } from "react-bootstrap";

class InformationOfUBO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeId: "",
    };
  }

  toggleActive = (id) => {
    if (this.state.activeId === id) {
      this.setState({
        activeId: null,
      });
    } else {
      this.setState({
        activeId: id,
      });
    }
  };
  
  render() {
    const { activeId } = this.state;
    return (
      <Row className="pt-3 pb-2">
        <div className="col-md-12">
          <Accordion defaultActiveKey={activeId}>
            <Card className="rounded-lg mb-3 border-0">
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                onClick={() => this.toggleActive("0")}
                className={
                  activeId === "0"
                    ? "active"
                    : ""
                }
              >
                DECLARATION OF BENEFICIAL OWNERSHIP
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    This declaration is established pursuant to the Financial
                    Supervision Act (Wet op het financieel toezicht), the
                    Anti-Money Laundering and Counter-Terrorist Financing Act
                    (Wet ter voorkoming van witwassen en financieren van
                    terrorisme), the Act on Supervision of Trust Offices 2018
                    (Wet toezicht trustkantoren Wtt 2018), and the Sanctions Act
                    1977 (Sanctiewet 1977). United International Management B.V.
                    and Perennial Administration Services B.V. (hereafter:
                    ‘United’) are supervised by the Dutch Central Bank.
                  </p>
                  <p>
                    The Ultimate Beneficial Owner (UBO) is, according to the
                    internal procedures of United, a natural person who
                    exercises ultimate effective control over a legal entity or
                    arrangement or holds a share or interest of 10 percent or
                    more in the capital interest of the legal entity or can
                    exercise 10 percent or more of the voting rights at the
                    general meeting of shareholders of a legal entity or is the
                    beneficiary of 10 percent or more of the assets of a client
                    or a trust or has special control of over 10 percent or more
                    of the assets of a client.{" "}
                  </p>
                  <p>
                    Natural persons who have been appointed as legal successors
                    in the statutory documents of a religious organization.
                  </p>

                  <h4 className="sub-chapter-title">Pseudo-UBO</h4>
                  <p>
                    In a case where no natural person can be identified as
                    defined above or where it is impossible to assign any person
                    as UBO based on the criteria as mentioned above, then the
                    natural person or persons who belong to the senior
                    management board of the company should be considered as UBO.
                  </p>
                  <p>
                    In the case of trusts; the settlors, trustees, beneficiaries
                    and possible protectors of the trust could be considered
                    UBOs depending on the type of trust.
                  </p>

                  <p>
                    A
                    <span className="font-weight-bold">
                      Politically Exposed Person (PEP)
                    </span>
                    is “an individual who is or has at any time been entrusted
                    with (prominent) public functions and/-or an immediate
                    family member, or a known close associate, of such a
                    person”. A natural person who is ultimate beneficial owner
                    of a legal entity or legal arrangement established for the
                    actual benefit of a politically exposed person. A natural
                    person who shares a joint ownership of a legal entity or
                    legal structure with a politically exposed person or a
                    natural person who has a close business relationship with a
                    politically exposed person.
                  </p>

                  <p>
                    Examples of a PEP are: Heads of State or of government,
                    senior politicians, senior government, judicial or military
                    officials, senior executives of state owned corporations,
                    important political party officials, persons who are or have
                    been entrusted with a prominent function by an international
                    organization. Family members who are related to a PEP either
                    directly (consanguinity) or through marriage or similar
                    (civil) forms of Partnership and Close associates who are
                    closely connected to a PEP, either socially or
                    professionally (June 2013 - FATF Guidance politically
                    exposed persons (recommendations 12 and 22).
                  </p>
                  <p>
                    A Ultimate Parent Company is the top tier entity in the
                    chain of corporate ownership.
                  </p>
                  <p>
                    Ultimate effective control refers to situations in which
                    ownership/control is exercised through a chain of ownership
                    or by means of control other than direct control.
                  </p>
                  <p>
                    Bearer Shares are negotiable instruments that accord
                    ownership in a legal person to the person who possesses the
                    bearer share certificate.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </Row>
    );
  }
}

export default InformationOfUBO;
