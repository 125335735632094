import React, { Component } from "react";
import TextBox from "../../../../elements/TextBox";
import PhoneInputField from "../../../../elements/PhoneInputField";
import DateTimePicker from "../../../../elements/DateTimePicker";
import CheckBox from "../../../../elements/CheckBox";
import countryList from "react-select-country-list";
import DownloadFile from "../../../../elements/DownloadFile";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import DropDown from "../../../../elements/DropDown";
import NumberInput from "../../../../elements/NumberInput";
import UPCDirectors from "./UPCDirectors";
import Trustees from "./Trustees"
import Protectors from "./Protectors"
import Settlors from "./Settlors"
import Beneficiaries from "./Beneficiaries"
class UpcDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { uboData, reducer, baseName, index, objBaseName, chapter, errors, entityType, upcDate, isForceCloseFinAcc, arethereExistingUPCDir,noOfUPCDir,noOftrustees,noOfProtectors,noOfSettlors,noOfBeneficiaries } =
            this.props;
            
    const upcDirectorsvar = [];
    if (arethereExistingUPCDir) {
      for (let i = 0; i < noOfUPCDir; i++) {
        upcDirectorsvar.push(
          <UPCDirectors
            index={i}
            form="UK"
            baseName="upcDirectors"
            objBaseName= "upcDetails"
            indexObjBaseName={index}
            reducer={this.props.reducer}
            chapter={chapter}            
          />
        );
      }
    }
    //---------------------------------
    const objTrusteesvar = [];
     for (let i = 0; i < noOftrustees; i++) {
       objTrusteesvar.push(
         <Trustees
           index={i}
           form={this.props.form}
           baseName="objTrustees"
           objBaseName= "upcDetails"
           indexObjBaseName={index}
           reducer={this.props.reducer}
           chapter={chapter}           
         />
       );
     }
      //---------------------------------
    const objProtectorsvar = [];
    for (let i = 0; i < noOfProtectors; i++) {
      objProtectorsvar.push(
        <Protectors
          index={i}
          form={this.props.form}
          baseName="objProtectors"
          objBaseName= "upcDetails"
          indexObjBaseName={index}
          reducer={this.props.reducer}
          chapter={chapter}
        />
      );
    }
    //--------------------------------
    const objSettlorsvar = [];
    for (let i = 0; i < noOfSettlors; i++) {
      objSettlorsvar.push(
        <Settlors
          index={i}
          form={this.props.form}
          baseName="objSettlors"
          objBaseName= "upcDetails"
          indexObjBaseName={index}
          reducer={this.props.reducer}
          chapter={chapter}
        />
      );
    }

    //--------------------------------
    const objBeneficiariesvar = [];
    for (let i = 0; i < noOfBeneficiaries; i++) {
      objBeneficiariesvar.push(
        <Beneficiaries
          index={i}
          form={this.props.form}
          baseName="objBeneficiaries"
          objBaseName= "upcDetails"
          indexObjBaseName={index}
          reducer={this.props.reducer}
          chapter={chapter}
        />
      );
    }

        return (
            <fieldset className="eqheight">
                <legend>  Parent Company {index + 1}</legend>
                <div className="row mb-0">
                    <div className="mb-2 col-lg-4">
                        <DropDown
                            label="Entity type"
                            name="entityType"
                            id={`entityType[${index}]`}
                            options={[
                                { value: 'Company', label: 'Company' },
                                { value: 'Trust/Foundation', label: 'Trust/Foundation' },
                                { value: 'Other', label: 'Other' }
                            ]}
                            index={index}
                            chapter={chapter}
                            baseName={baseName}
                            reducer={this.props.reducer}
                            disabled={true}
                        />

                    </div>
                    <div className="mb-2 col-lg-4">
                        <DropDown
                            label="Options"
                            name="options"
                            id={`options[${index}]`}
                            options={[
                                { value: 'Immediate', label: 'Immediate' },
                                { value: 'Intermediate', label: 'Intermediate' },
                                { value: 'Ultimate', label: 'Ultimate' }
                            ]}
                            index={index}
                            chapter={chapter}
                            baseName={baseName}
                            reducer={this.props.reducer}
                            disabled={true}
                        />

                    </div>
                </div>
                {entityType && entityType !== "Trust/Foundation" && (
                    <div>
                        <Row className="pt-2 pb-2">
                            <div className="mb-2 col-lg-4">
                                <TextBox
                                    label="Name of Entity"
                                    placeholder="Enter Name of UPC"
                                    name="nameUPC"
                                    id={`nameUPC[${index}]`}
                                    index={index}
                                    chapter={chapter}
                                    baseName={baseName}
                                    reducer={this.props.reducer}
                                    validationType="Text"
                                    disabled={true}
                                />

                            </div>
                            <div className="mb-2 col-lg-6 col-xl-4">
                                <TextBox
                                    label="Registered address"
                                    name="addressRegistered"
                                    id={`addressRegistered[${index}]`}
                                    index={index}
                                    placeholder="enter your text"
                                    baseName={baseName}
                                    chapter={chapter}
                                    reducer={this.props.reducer}
                                    validationType="Text"
                                    disabled={true}
                                />

                            </div>
                            <div className="mb-2 col-lg-6 col-xl-4">
                                <TextBox
                                    label="Postal code"
                                    name="postalCode"
                                    id={`postalCode[${index}]`}
                                    index={index}
                                    chapter={chapter}
                                    placeholder="enter your text"
                                    baseName={baseName}
                                    reducer={this.props.reducer}
                                    validationType="Text"
                                    disabled={true}
                                />

                            </div>
                            <div className="mb-2 col-lg-6 col-xl-4">
                                <TextBox
                                    label="City"
                                    name="city"
                                    id={`city[${index}]`}
                                    index={index}
                                    placeholder="enter your text"
                                    chapter={chapter}
                                    baseName={baseName}
                                    reducer={this.props.reducer}
                                    validationType="Text"
                                    disabled={true}
                                />


                            </div>
                            <div className="mb-2 col-lg-6 col-xl-4">
                                <DropDown
                                    label="Country"
                                    name="country"
                                    id={`country[${index}]`}
                                    index={index}
                                    chapter={chapter}
                                    options={countryList().getData()}
                                    baseName={baseName}
                                    reducer={this.props.reducer}
                                    disabled={true}
                                />

                            </div>
                            <div className="mb-2 col-lg-6 col-xl-4">
                                <TextBox
                                    label="Website"
                                    name="websiteUPC"
                                    id={`websiteUPC[${index}]`}
                                    index={index}
                                    chapter={chapter}
                                    placeholder="enter your text"
                                    baseName={baseName}
                                    reducer={this.props.reducer}
                                    disabled={true}
                                />

                            </div>
                            <div className="mb-2 col-lg-6 col-xl-4">
                                <TextBox
                                    label="Tax-number"
                                    name="taxNum"
                                    id={`taxNum[${index}]`}
                                    index={index}
                                    chapter={chapter}
                                    placeholder="enter your text"
                                    baseName={baseName}
                                    reducer={this.props.reducer}
                                    validationType="Text"
                                    disabled={true}
                                />

                            </div>
                            <div className="mb-2 col-lg-6 col-xl-4">
                                <TextBox
                                    label="Trade register number"
                                    chapter={chapter}
                                    name="tradeRegisterNum"
                                    id={`tradeRegisterNum[${index}]`}
                                    index={index}
                                    placeholder="enter your text"
                                    baseName={baseName}
                                    reducer={this.props.reducer}
                                    validationType="Text"
                                    disabled={true}
                                />

                            </div>
                            <div className="mb-2 col-lg-6 col-xl-4" id="phoneNumberUPC">
                                <PhoneInputField
                                    label="Phone number"
                                    name="phoneNumberUPC"
                                    id={`phoneNumberUPC[${index}]`}
                                    index={index}
                                    placeholder="enter your text"
                                    chapter={chapter}
                                    baseName={baseName}
                                    reducer={this.props.reducer}
                                    country={this.props.form}
                                    disabled={true}
                                />

                            </div>
                            <div className="mb-2 col-lg-6 col-xl-4">
                                <DropDown
                                    label="Country of Tax Residence"
                                    name="countryOfTaxRsdnc"
                                    id={`countryOfTaxRsdnc[${index}]`}
                                    index={index}
                                    chapter={chapter}
                                    options={countryList().getData()}
                                    reducer={this.props.reducer}
                                    baseName={baseName}
                                    disabled={true}
                                />

                            </div>

                        </Row>
                        <Row>
                            <div className="mb-3 col-lg-6 col-xl-4">
                                <CheckBox
                                    label="Has the UPC ever been rejected or forced to close accounts with any bank or financial institution?"
                                    options={["Yes", "No"]}
                                    name="isForceCloseFinAcc"
                                    id={`isForceCloseFinAcc[${index}]`}
                                    index={index}
                                    chapter={chapter}
                                    baseName={baseName}
                                    reducer={this.props.reducer}
                                    disabled={true}
                                />

                            </div>
                            {isForceCloseFinAcc === "Yes" && (
                                <div className="mb-2 col-lg-6 col-xl-4">
                                    <TextBox
                                        label="Please specify a reason"
                                        placeholder="enter a text"
                                        name="forceCloseFinAcc"
                                        id={`forceCloseFinAcc[${index}]`}
                                        index={index}
                                        chapter={chapter}
                                        baseName={baseName}
                                        reducer={this.props.reducer}
                                        validationType="Text"
                                        disabled={true}
                                    />

                                </div>
                            )}
                        </Row>
                        <Row >
                            <div className="mb-3 col-lg-6 col-xl-4">
                                <CheckBox
                                    label="Are there existing Director(s)?"
                                    options={["Yes", "No"]}
                                    name="arethereExistingUPCDir"
                                    id={`arethereExistingUPCDir[${index}]`}
                                    index={index}
                                    chapter={chapter}
                                    baseName={baseName}
                                    reducer={this.props.reducer}
                                    disabled={true}
                                />

                            </div>
                        </Row>
                        {arethereExistingUPCDir === "Yes" && (
                            <Row className="pt-4 pb-2">
                                <div className="mb-2 col-lg-6 col-xl-4">

                                    <NumberInput
                                        label="How many directors would you like to add?"
                                        name="noOfUPCDir"
                                        masterObj={["upcDetails"]}
                                        masterIndex={index}
                                        referral={["upcDirectors"]}
                                        index={index}
                                        chapter={chapter}
                                        baseName={baseName}
                                        reducer={this.props.reducer}
                                        disabled={true}
                                    />
                                </div>
                               {upcDirectorsvar}

                            </Row>

                        )}
                        <Row className="pt-4 pb-2">
                            <div className="mb-2 col-lg-6 col-xl-4">
                                <DownloadFile
                                    label="Extract Chamber of Commerce"
                                    name="extractChamberOfCommerce"
                                    value={upcDate.extractChamberOfCommerce}
                                />


                            </div>
                            <div className="mb-2 col-lg-6 col-xl-4">

                                <DownloadFile
                                    label="Deed of incorporation/ Articles of association"
                                    name="deedOfIncorporation"
                                    value={upcDate.deedOfIncorporation}
                                />

                            </div>

                            <div className="mb-2 col-lg-6 col-xl-4">
                                <DownloadFile
                                    label="Shareholders’ register"
                                    name="shareholdersRegister"
                                    value={upcDate.shareholdersRegister}
                                />

                            </div>
                            <div className="mb-2 col-lg-6 col-xl-4">
                                <DownloadFile
                                    label="if applicable, Shareholders’ certificates"
                                    name="shareholdersCertificates"
                                    value={upcDate.shareholdersCertificates}
                                />

                            </div>
                            <div className="mb-2 col-lg-6 col-xl-4">
                                <DownloadFile
                                    label="Nominee agreement/ Declaration of trust"
                                    name="nomineeAgreement"
                                    value={upcDate.nomineeAgreement}
                                />

                            </div>

                            <div className="mb-2 col-lg-6 col-xl-4">
                                <DownloadFile
                                    label="Financial statements of the past three years"
                                    name="financialStatementsThreeYears"
                                    value={upcDate.financialStatementsThreeYears}
                                />

                            </div>
                        </Row>
                    </div>
                )}
                {/* --------------------- */}
                {entityType && entityType === "Trust/Foundation" && (
            <div>
              <h4 className="sub-chapter-title mb-4">Trust/Foundation Details</h4>

              <Row className="pt-1 pb-2">
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Directors/Partners"
                    name="directorsPartners"
                    id={`directorsPartners[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    placeholder="enter your text"
                    reducer={this.props.reducer}
                    validationType="Text"
                    disabled={true}
                  />
                 
                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Registered office"
                    name="regOffice"
                    id={`regOffice[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    placeholder="enter your text"
                    reducer={this.props.reducer}
                    validationType="Text"
                    disabled={true}
                  />
               
                </div>
              </Row>
              <Row className="pt-1 pb-2">
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Trust Name"
                    name="trustName"
                    id={`trustName[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    placeholder="enter your text"
                    reducer={this.props.reducer}
                    validationType="Text"
                    disabled={true}
                  />

                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Trust Jurisdiction"
                    name="trustJurisdiction"
                    id={`trustJurisdiction[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    placeholder="enter your text"
                    reducer={this.props.reducer}
                    validationType="Text"
                    disabled={true}
                  />

                </div>
              </Row>
              <Row className="pt-4 pb-2">
                <div className="mb-2 col-lg-6 col-xl-4">
                  <NumberInput
                    label="Number of trustees"
                    name="noOftrustees"
                    masterObj={["upcDetails"]}
                    masterIndex={index}
                    referral={["objTrustees"]}
                    id={`noOftrustees[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                    disabled={true}
                  />

                </div>
                {objTrusteesvar}
              </Row>
              <Row className="pt-4 pb-2">
                <div className="mb-2 col-lg-6 col-xl-4">
                  <NumberInput
                    label="Number of Protectors"
                    name="noOfProtectors"
                    masterObj={["upcDetails"]}
                    masterIndex={index}
                    referral={["objProtectors"]}
                    id={`noOfProtectors[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                    disabled={true}
                  />

                </div>
              {objProtectorsvar}
              </Row>
              <Row className="pt-4 pb-2">
                <div className="mb-2 col-lg-6 col-xl-4">
                  <NumberInput
                    label="Number of Settlors"
                    name="noOfSettlors"
                    masterObj={["upcDetails"]}
                    masterIndex={index}
                    referral={["objSettlors"]}
                    id={`noOfSettlors[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                    disabled={true}
                  />

                </div>
                {objSettlorsvar}
              </Row>
              <Row className="pt-4 pb-2">
                <div className="mb-2 col-lg-6 col-xl-4">
                  <NumberInput
                    label="Number of Beneficiary"
                    name="noOfBeneficiaries"
                    masterObj={["upcDetails"]}
                    masterIndex={index}
                    referral={["objBeneficiaries"]}
                    id={`noOfBeneficiaries[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                    disabled={true}
                  />

                </div>
                
               {objBeneficiariesvar}

              </Row>
              <Row className="pt-4 pb-2">
                <div className="mb-3 col-lg-6">
                <DownloadFile
                        label="Upload Trust/Foundation supporting documents"
                        name="trustSupportingDocument"
                        value={upcDate.trustSupportingDocument}
                 />                               
                </div>

              </Row>
            </div>
          )}
            </fieldset>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { index, reducer } = ownProps;
    console.log("index:",state[reducer].chapter3.upcDetails[0]["entityType"])
    return {
        upcDate: state[reducer].chapter3.upcDetails[index],
        entityType: state[reducer].chapter3.upcDetails[index]["entityType"],
        isForceCloseFinAcc: state[reducer].chapter3.upcDetails[index]["isForceCloseFinAcc"],
        arethereExistingUPCDir:state[reducer].chapter3.upcDetails[index]["arethereExistingUPCDir"],
        noOfUPCDir:state[reducer].chapter3.upcDetails[index]["noOfUPCDir"],
        noOftrustees:state[reducer].chapter3.upcDetails[index]["noOftrustees"],
        noOfProtectors:state[reducer].chapter3.upcDetails[index]["noOfProtectors"],
        noOfSettlors:state[reducer].chapter3.upcDetails[index]["noOfSettlors"],
        noOfBeneficiaries:state[reducer].chapter3.upcDetails[index]["noOfBeneficiaries"],
    };
};

export default connect(mapStateToProps, null)(UpcDetails);
