import React, { Component } from "react";
import { Row } from "react-bootstrap";

import TextBox from "../../../../elements/TextBox";
import cc from "currency-codes";

let currencies = [];
cc.codes().map((currency) => currencies.push({ label: currency }));

const objBaseName = "situation";
class Situation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { chapter, errors, isRequired } = this.props;
    const errorMsg = JSON.parse(errors);

    return (
      <>
        <h4 className="sub-chapter-title mb-4">
          {this.props.form === "Malta"
            ? "Detail of Proposed Business"
            : "Situation"}
        </h4>
        <Row className="pt-3 pb-2">
          <div className="mb-3 col-lg-6">
            <TextBox
              label={
                this.props.form === "Malta"
                  ? "Brief description of the proposed business plan"
                  : "Description of client's need, objective or intensions"
              }
              name="descClientNeeds"
              id={`descClientNeeds`}
              placeholder="Enter Description"
              objBaseName={objBaseName}
              chapter={chapter}
              reducer={this.props.externalReducer}
              validationType="Text"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
          <div className="mb-3 col-lg-6">
            <TextBox
              label={`What is the benefit of the client in the proposed structure and the reason for choosing ${this.props.form}?`}
              name="benefitOfClient"
              id={`benefitOfClient`}
              placeholder="Enter Description"
              objBaseName={objBaseName}
              chapter={chapter}
              reducer={this.props.externalReducer}
              validationType="Text"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>

          <div className="mb-3 col-lg-6">
            <TextBox
              label="Tax Implications"
              name="taxImplications"
              id={`taxImplications`}
              placeholder="Enter Description"
              objBaseName={objBaseName}
              chapter={chapter}
              reducer={this.props.externalReducer}
              validationType="Text"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
          <div className="mb-3 col-lg-6">
            <TextBox
              label="Could the business acceptance entail a conflict of interest?"
              name="busAcptncCnflctIntrst"
              id={`busAcptncCnflctIntrsts`}
              placeholder="Enter Description"
              objBaseName={objBaseName}
              chapter={chapter}
              reducer={this.props.externalReducer}
              validationType="Text"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
          <div className="mb-3 col-lg-12">
            <TextBox
              label="Please enter comment"
              name="propAddInfo"
              id="propAddInfo"
              placeholder="Enter your text"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={isRequired}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorMsg &&
              errorMsg?.propAddInfo &&
              errorMsg?.propAddInfo?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMsg?.propAddInfo}
                </span>
              )}
          </div>
        </Row>
      </>
    );
  }
}

export default Situation;
