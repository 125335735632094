import React, { Component } from "react";
import { externalFileDownload } from "../../redux/actions/InternalFileHandleActions";
import { saveAs } from "file-saver";
import { connect } from "react-redux";
import Tooltip from "./Tooltip";

class DownloadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDownload = (fileName) => {
    let data = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      fileName: fileName,
      office: data.office,
      userName: data.userName,
      userEmail: data.userEmail,
      companyName: data.clientName,
      companyId: data.clientId,
    };
    this.props.externalFileDownload(reqData).then((result) => {
      var blob = new Blob([result.data]);
      let file = fileName;
      saveAs(blob, file);
    });
  };
  render() {
    const { label, info } = this.props;
    return (
      <>
        <label>
          {label}
          {info && <Tooltip text={info}></Tooltip>}
        </label>
        {this.props.value &&
          this.props.value.map((file, index) => {
            return (
              <div className="col-lg-12 mb-1" key={index}>
                <div className="d-flex align-items-center upload-file-name w-100">
                  <p
                    className="FileNameDisplay"
                    style={{ margin: "0", flexGrow: "1" }}
                    title={file.fileName}
                  >
                    {file.fileName}
                  </p>
                  <span className="fileDownload">
                    <i
                      className="fa fa-download"
                      onClick={() => this.handleDownload(file.fileName)}
                    ></i>
                  </span>
                </div>
              </div>
            );
          })}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    externalFileDownload: async(data) => dispatch(await externalFileDownload(data)),
  };
};

export default connect(null, mapDispatchToProps)(DownloadFile);
