export const fileAcceptType = ".doc,.docx,.odt,.pdf,.rtf,.tex,.txt,.wpd,.ods,.xls,.xlsm,.xlsx,.key,.odp,.pps,.ppt,.pptx,.jpeg,.jpg,.png";
export const chapter1 = {
  structureChartComm: "",
  isInLineUIMAccPolicy: "",
  explainIsInLineUIMAccPolicy:"",
  isMediaCoverageOnStruct: "",
  isAddDocs: "",
  addDocs: [],
  isUIMnoResonDiscntnuBusRel: "",
  reportOnActivities: "",
};

export const chapter2 = {
  intermediarySignNew: "",
  isPascalReport: "",
  pascalReportUpload: "",
  noPascalReportReason: "",
  isMediaCoverageOnStruct: "",
  pascalOtherReportUpload:"",
  pascalExplain:"",
  isAddDocs: "",
  addDocs: [],
  isSrvcPrvdrTermntdSrvcsClr: "",
  isSrvcPrvdrReputable: "",
  isReceiveNecessaryInfo: "",
  isIncreasedIntegrityRisk: "",
  increasedIntegrityRiskReason: "",
};

export const chapter3 = {
  isHldngIntrstCmmnt: "",
  isUBOClsifiedCmmnt: "",
  isAsstClientCmmnt: "",
  noUBOClassifiedCmmnt: "",
  uboApproval: [],
  upcApproval: [],
  isExtCOCApprvd: "",
  isDeedOfIncorpoApprvd: "",
  isShareholdersRegisterApprvd: "",
  isshareholdersCertificatesApprvd: "",
  isNomineeAgreementApprvd: "",
  isFinStatmntsThreeYrsApprvd: "",
  pascalReportUpload: [],
  isFindings: "",
  descInvstgUBOClsifiedPEP: "",
  isMediaCoverageOnStruct: "",
  isAddDocs: "",
  addDocs: [],
  isUBOresultIncrsdIntgrtyRsk: "",
  ubOresultIncrsdIntgrtyRskCmmnt: "",
};
export const chapter4 = {
  objCompCmmnt: "",
  objCompDirCmmnt: "",
  objCompActiveConCmmnt: "",
  objCompBranchesCmmnt: "",
  rationaleStrctrngForNLCmmnt: "",
  objPascalResport: [],
  objFindings: "",
  findingsIndpTaxAdvisorApprvd: "",
  bsEvidencesIsApprvd: "",
  bsEvidencesCmmnt: "",
  cpyPassportMangApprvd: "",
  extCOCApprvd: "",
  deedOfIncorpApprvd: "",
  shareholdersRegApprvd: "",
  shareholdersCrtfictsApprvd: "",
  finStmntsPstthreeYrsApprvd: "",
  taxAdviceApprvd: "",
  taxAdviceCmmnt: "",
  invstmntMemoApprvd: "",
  apaCmmnt: "",
  otherRelDocsApprvd: "",
  otherRelDocsCmmnt: "",
  entityTrustDetails: "",
  businessIntroduction: "",
  servicesProvided: "",
  uboInvstObjCompCmmnt: "",
  annualFinStmntupcApprvd: "",
  upcFundObjCompCmmnt: "",
  anulFinStmntTpApprvd: "",
  tpInvstObjCompCmmnt: "",
  cntrysInvldObjCompCmmnt: "",
  asstSmmryEvidenceApprvd: "",
  assSmmryObjCompCommnt: "",
  expctdTransSummryApprvd: "",
  expctdTransSummryCmmnt: "",
  objCompGrpCompsCmmnt: "",
  grpCompsObjCompCmmnt: "",
  objCompUboCmmnt: "",
  uboObjCmpCmmnt: "",
  objCompTpCmmnt: "",
  tpObjCompCmmnt: "",
  rightsAndOblgtnsRsk: [],
  rightsAndOblgtnsCmmnt: "",
  bnkInfoRsk: [],
  bnkInfoCmmnt: "",
  fatcaFormApprvd: "",
  bnkStmntsApprvd: "",
  aggrmntsGrpEntApprvd: "",
  aggrmntsOthrParApprvd: "",
  finStmntObjCompApprvd: "",
  finStmntAllPartciApprvd: "",
  finStmntParCompApprvd: "",
  declrFinInstApprvd: "",
  isMediaCoverageOnStruct: "",
  isAddDocs: "",
  addDocs: [],
  custActvtyRskRtng: "",
  descActnNeedsTkn: "",
  objCompIncrdIntgtyRsk: "",
  objCompIncrdIntgtyRskText: "",
};
export const chapter5 = {
  isUIMAwareOfIncidents: "",
  uIMComment: "",
  structureComment: "",
  isRestrictiveMeasure: "",
  isStructurePlacedOnFATF: "",
  isStructureOrObjCompanyDirectly: "",
  includeInformation: "",
  isClientsInclRelevantCounterparties: "",
  overviewOfNaturalPerson: "",
  isMediaCoverageOnStruct: "",
  isAddDocs: "",
  addDocs: [],
  isUnacceptableRisksIdentified: "",
  unacceptableRisksReason: "",
};
export const chapter6 = {
  isDomiciliationSvc: "",
  isManagementSvc: "",
  isAdministrationSvc: "",
  isUIMPrvdOthSvc: "",
  explanationOthSvc: "",
  isLeaseAgmntOfcSpace: "",
  isDomicilliationAgmnt: "",
  isDeapForm: "",
  isAdministrationAgmnt: "",
  isPrinciplePartyAgmnt: "",
  isManagementAgmnt: "",
  isOtherAgmnt: "",
  reportWorldCheckExtDir: "",
  findingsWorldCheck: "",
  isUnacceptableRisksIdentified: "",
  unacceptableRisksReason: "",
};

export const chapter8 = { overallRiskRating: "" };
export const chapter9 = {
  overallRiskRating: "",
  findingWorldCheck: "",
};
export const chapter10 = {
  isOtherMatterNotable: "",
  uploadFinding:[],
  otherMtrsImpCmnt: "",
};
export const chapter11 = {
  entityName: "",
  antiIncmCurrency: "",
  anticipatedIncome: "",
  billingAddress: "",
  setUpFees: "",
  annualManagementFees: "",
  vat: "",
  additionalInfo: "",
  rejectCmnt:""
};
export const newClient = {
  clientName: null,
  country: null,
  explainDetails: null,
};
export const chapValidationErrorUK = [
  {
    chapter: "chapter1",
    label: "Client Activity",
    path: "client-activity",
    isvalid: false,
    name: "isChapter1valid",
  },
  {
    chapter: "chapter2",
    label: "Client Investigation",
    path: "client-investigation",
    name: "isChapter2valid",
    isvalid: false,
  },
  {
    chapter: "chapter3",
    label: "UBO/UPC",
    path: "ubo-upc",
    name: "isChapter3valid",
    isvalid: false,
  },
  {
    chapter: "chapter4",
    label: "Object Company",
    name: "isChapter4valid",
    path: "object-company",
    isvalid: false,
  },
  {
    chapter: "chapter5",
    label: "Country/Sanction Risks",
    name: "isChapter5valid",
    path: "country-sanction-risks",
    isvalid: false,
  },
  {
    chapter: "chapter6",
    label: "Agreement",
    name: "isChapter6valid",
    path: "agreement",
    isvalid: false,
  },
  {
    chapter: "chapter8",
    label: "Consolidated Risk Assesment",
    name: "isChapter8valid",
    path: "consolidated-risk-assessment",

    isvalid: false,
  },
  {
    chapter: "chapter9",
    label: "Correspondence",
    path: "correspondence",
    name: "isChapter9valid",

    isvalid: false,
  },
  {
    chapter: "chapter10",
    name: "isChapter10valid",
    label: "Miscellaneous",
    path: "miscellaneous",
    isvalid: false,
  },

  {
    chapter: "chapter11",
    label: "Formal sign off",
    name: "isChapter12valid",
    path: "formal-sign-off",
    isvalid: false,
  },
];
