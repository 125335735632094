import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import TextBox from "../../../../elements/TextBox";
import SourceOfWealth from "../../../external/OnBoarding/ubo-declaration-uim/PartB/SourceOfWealth";

class DeclarationSoW extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, grossAnnualIncome, errors } = this.props;

    return (
      <>
        <div className="html2pdf__page-break"></div>
        <h4 className="sub-chapter-title mb-4">
          Declaration of the source of wealth of the UBO
        </h4>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-12 wealthDeclaration">
            <CheckBox
              label="What is your gross annual Income (please tick)"
              options={["< EUR 500.000", "EUR 500.000 – 1 Mln", "> EUR 1 Mln","N/A"]}
              id="grossAnnualIncome"
              name="grossAnnualIncome"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={index}
              baseName={this.props.baseName}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {grossAnnualIncome === "> EUR 1 Mln" && (
            <div className="mb-3 col-lg-12">
              <TextBox
                label="please state here your annual income if it is above EUR 1 Mln"
                placeholder="enter annual income"
                reducer={this.props.reducer}
                name="anualIncmAbvEUR1Mln"
                chapter={this.props.chapter}
                index={index}
                baseName={this.props.baseName}
                objBaseName={this.props.objBaseName}
                disabled={true}
              />
            </div>
          )}
          <div className="mb-3 col-md-12 rangeCheckbox">
            <CheckBox
              label=" What is the range of your wealth?"
              options={[
                "< EUR 500.000",
                "EUR 500.000 – 1 Mln",
                "EUR 1 Mln – 5 Mln",
                "EUR 5 Mln – 20 Mln",
                "EUR 20 Mln – 100 Mln",
                "EUR > 100 Mln",
              ]}
              id="wealthRange"
              name="wealthRange"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={index}
              baseName={this.props.baseName}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          <div className="col-md-12">
            <SourceOfWealth
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={index}
              errorMsg={errors}
              baseName={this.props.baseName}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer, chapter, index } = ownProps;
  return {
    grossAnnualIncome:
      state[reducer][chapter]["ubo"]["ubox"][index]["grossAnnualIncome"],
  };
};

export default connect(mapStateToProps, null)(DeclarationSoW);
