import React from "react";
import { AuthenticatedTemplate,useMsal } from "@azure/msal-react";
import {  Dropdown,  } from "react-bootstrap";
import jwt_decode from "jwt-decode";

const MSLOGIN_MAINWINDOWREDIRECTURL=process.env.REACT_APP_MSLOGIN_MAINWINDOWREDIRECTURL;
const handleLogout = async (instance) => {
      await instance
      .logoutPopup({
        account: instance.getAllAccounts()[0],
        mainWindowRedirectUri: MSLOGIN_MAINWINDOWREDIRECTURL,
        popupWindowAttributes: {
            popupSize: {
                height: 500,
                width: 500
            },
            popupPosition: {
                top: 500,
                left: 500
            }
          }
        })
      .then(() => {
        let user = localStorage.getItem("user");
        let formId = localStorage.getItem("formId");
        let clientId=localStorage.getItem("clientId");
        let CompanyName=localStorage.getItem("CompanyName");
        let Office=localStorage.getItem("Office");
        let activeLinkLevel=localStorage.getItem("activeLinkLevel");
        let uboformtoken=localStorage.getItem("ubo-form-token");
        window.localStorage.clear();
        if(user) localStorage.setItem("user", user);
        if(formId)  localStorage.setItem("formId", formId);
        if(clientId) localStorage.setItem("clientId", clientId);
        if(CompanyName) localStorage.setItem("CompanyName", CompanyName);
        if(Office) localStorage.setItem("Office", Office);
        if(activeLinkLevel) localStorage.setItem("activeLinkLevel",activeLinkLevel);
        if(uboformtoken) localStorage.setItem("ubo-form-token",uboformtoken)
        //localStorage.removeItem("authToken")
        //localStorage.removeItem("allAccounts")  
       // localStorage.removeItem("formData")
        //localStorage.removeItem("Office")  
        //localStorage.removeItem("userType");   
      })
      .catch((e) => {
        console.error(e);
      });
     
  };

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutButton = () => {
    const { instance } = useMsal();
    let userName;
    const token = JSON.parse(localStorage.getItem("authToken"));
    if (token) {
      const decodedDetails = jwt_decode(token);
      userName=decodedDetails.name
    }
      return (
        <AuthenticatedTemplate>
            <React.Fragment>
                 <Dropdown>
                  <Dropdown.Toggle
                    className="dropdownNameLink"
                    id="dropdown-basic"
                  >
                    {userName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#" onClick={() => handleLogout(instance)}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </React.Fragment>
        {/* <button variant="secondary"  className="action-btn whiteBtn text-center mt-2 rounded" onClick={() => handleLogout(instance)}>Logout</button> */}
        </AuthenticatedTemplate>
    );
}