export const chapter1 = {
  structureChart: "",
  isCompIncorporated: "",
  entities: [],
  upcActivites: "",
  upcSector: "",
  shareholderActivites: "",
  shareholderSector: "",
  objCompActivities: "",
  objCompSector: "",
  participantActivites: "",
  participantSector: "",
  otherActivites: "",
  otherSector: "",
};

export const chapter2 = {
  isClientRepInvolved: "",
  intermediaryDetails: [],
  isClientTransfrd: "",
  srvcPrvdrName: "",
  reasonClientTransfrd: "",
};

export const chapter3 = {
  isHoldingInterest: "",
  isHoldingInterestReason: "",
  isUBOClassified: "",
  isUBOClassifiedReason: "",
  isAssetClient: "",
  isAssetClientReason: "",
  ubox: [],
  isUPCExists: "",
  nameUPC: "",
  addressRegistered: "",
  postalCode: "",
  city: "",
  country: "",
  websiteUPC: "",
  taxNum: "",
  tradeRegisterNum: "",
  phoneNumberUPC: "",
  countryOfTaxRsdnc: "",
  isForceCloseFinAcc: "",
  forceCloseFinAcc: "",
  upcAuthDirName: "",
  upcAuthDirEmail: "",
  upcAuthDirNationality: "",
  extractChamberOfCommerce: [],
  deedOfIncorporation: [],
  shareholdersRegister: [],
  shareholdersCertificates: [],
  nomineeAgreement: [],
  financialStatementsThreeYears: [],
};

export const chapter4 = {
  isObjCompExists: "",
  objCompName: "",
  commercialRegister: "",
  objCompDirectorNames: [],
  objCompActiveCountry: "",
  objCompNaturePropsdObjctv: "",
  objCompOtherNaturePropsdObjctv: "",
  isObjCompBranches: "",
  objCompBranch: "",
  rationaleStructurigForNL: [],
  rationaleSelectedInfo: "",
  taxAdvisorFinds: [],
  isInsuranceBearerShares: "",
  bsCurrency: "",
  bsAmount: "",
  bsHolder: "",
  bsBeneficiary: "",
  bsEvidences: "",
  copyPassportManagement: "",
  extractChamberOfCommerce4: "",
  deedOfIncorporation4: "",
  shareholdersRegister4: "",
  shareholdersCertificates4: "",
  financialStatmntsThreeYears4: "",
  isTaxMemorandum: "",
  taxAdvisorName: "",
  taxMemorandum: "",
  taxMemorandumAdvisorName: "",
  isAPA: "",
  objCompTaxResult: "",
  explanation: "",
  otherDocs: "",
  isUBOInvstObjComp: "",
  uboInvstCurrencyObjComp: "",
  uboInvstObjCompAmnt: "",
  uboInvstObjCompOrigin: "",
  isUPCfundObjComp: "",
  upcFundObjCompOrigin: "",
  annualFinStmnt: "",
  isTpInvstObjComp: "",
  tpName: "",
  tpCountry: "",
  tpAnnualFinStmnts: "",
  cntrysInvlvdTrnsctnsObjComp: "",
  estmtdAnnualTrnOvrObjComp: "",
  assetSummryObjComp: "",
  assetSummryEvidence: "",
  expctdTrnsctnsSummary: "",
  expctdTrnsctnsEvidence: "",
  objCompToGrpComp: "",
  grpCompToObjComp: "",
  objCompToUBO: "",
  uboToObjComp: "",
  objCompToTP: "",
  tpToObjComp: "",
  bnkInfo: "",
  agremntsGrUpEntities: "",
  agremntsOthrParties: "",
  financialStmntsObjComp: "",
  finanicalStmntsAllPrticipatns: "",
  financialStmntsParntComp: "",
};

export const chapter5 = { countryList: "" };

export const chapter6 = {
  isExternalDirectors: "",
  isExternalDirectorsNaturalPerson: "",
  nameOfNaturalPerson: "",
  nationality: "",
  isOneOrMoreExtDirectorsAuthorized: "",
};
