import { PublicClientApplication } from "@azure/msal-browser";
import { InteractionRequiredAuthError } from "msal";
const MSLOGIN_CLIENTID = process.env.REACT_APP_MSLOGIN_CLIENTID;
const MSLOGIN_REDIRECTURL = process.env.REACT_APP_MSLOGIN_REDIRECTURL;
export const getToken =async () => {
    let result;
    const cfg = {
  
      auth: {
  
        clientId: MSLOGIN_CLIENTID,
  
        authority:
  
          "https://login.microsoftonline.com/b6c7c4de-0732-4fbd-b5d1-e07be86946b1", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
  
        redirectUri: MSLOGIN_REDIRECTURL,
  
      },
      system: {
        iframeHashTimeout: 10000,
        navigateFrameWait: 1000,
      },
      cache: {
  
        cacheLocation: "localStorage", // This configures where your cache will be stored
  
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  
      },
  
    };
    const publicClientApplication= new PublicClientApplication(cfg);
    const account = publicClientApplication.getAllAccounts()[0];
    const accessTokenRequest = {
      scopes: ["user.read"],
  
      account: account,
  
    };
  
    await publicClientApplication.acquireTokenSilent(accessTokenRequest)
  
      .then(function (accessTokenResponse) {
  
        // Acquire token silent success
        let accessToken = accessTokenResponse.idToken;
        // Call your API with token
        localStorage.setItem("authToken", JSON.stringify(accessToken));
        result=accessToken;
  
      })
  
      .catch(async function (error) {
         //Acquire token silent failure, and send an interactive request
        if (error instanceof InteractionRequiredAuthError) {
         await publicClientApplication.acquireTokenPopup(accessTokenRequest)
            .then(function (accessTokenResponse) {
              // Acquire token interactive success
             let accessToken = accessTokenResponse.idToken;
              // Call your API with token
              localStorage.setItem("authToken", JSON.stringify(accessToken));
              result=accessToken;
  
            })
  
            .catch(function (error) {
  
              // Acquire token interactive failure
  
              console.log(error);
  
            });
  
        }
  
        console.log(error);
  
      });
      return result;
  
  };