import React, { Component } from "react";
import Chapter1 from "./client-information/Chapter1/Chapter1";
import Chapter2 from "./client-information/Chapter2/Chapter2";
import LazyLoad from "react-lazy-load";

class lazyLoadingDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="dashboard external-dashboard">
          <LazyLoad>
            <Chapter1 />
          </LazyLoad>
          <LazyLoad>
            <Chapter2 />
          </LazyLoad>
          <LazyLoad>
            <Chapter1 />
          </LazyLoad>
          <LazyLoad>
            <Chapter2 />
          </LazyLoad>
          <LazyLoad>
            <Chapter1 />
          </LazyLoad>
          <LazyLoad
            onContentVisible={() =>
              console.log("look ma I have been lazyloaded!")
            }
          >
            <Chapter2 />
          </LazyLoad>
        </div>
      </>
    );
  }
}

export default lazyLoadingDemo;
