import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import TextBox from "../../../../elements/TextBox";
import { fileAcceptType } from "../Constants";
import InternalFileUpload from "../../../../elements/InternalFileUpload";
import DropDown from "../../../../elements/DropDown";
import countryList from "react-select-country-list";
class ExternalDirectors extends React.Component {
  render() {
    const { chapter, chapter6, errors, isRequired, isExternalDirectors, isExternalDirectorsNaturalPerson, isOneOrMoreExtDirectorsAuthorized, nameOfNaturalPerson, nationality } = this.props;
    const errorsMsg = JSON.parse(errors);
    return (
      <>
        {/* ---------------------------NL---------------------------------- */}
        {this.props.form === "NL" && (
          <>
            <h4 className="sub-chapter-title pt-4 mb-2">External Directors</h4>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-4">
                {chapter6.isExternalDirectors && (
                  <CheckBox
                    label="Are there external directors?"
                    options={["Yes", "No"]}
                    name="isExternalDirectors"
                    reducer={this.props.externalReducer}
                    chapter={chapter}
                    bothDisabled={true}
                  />
                )}
                {!chapter6.isExternalDirectors && (
                  <CheckBox
                    label="Are there external directors?"
                    options={["Yes", "No"]}
                    name="isExternalDirectors"
                    reducer={this.props.reducer}
                    chapter={chapter}

                  />
                )}

              </div>
            </Row>
            <Row>
              {chapter6.isExternalDirectors === "Yes" && (
                <>
                  <div className="mb-2 col-lg-4">
                    <CheckBox
                      label="Are the external directors a natural person?"
                      options={["Yes", "No"]}
                      name="isExternalDirectorsNaturalPerson"
                      reducer={this.props.externalReducer}
                      chapter={chapter}
                      bothDisabled={true}
                    />
                  </div>
                  {chapter6.isExternalDirectorsNaturalPerson === "Yes" && (
                    <>
                      <div className="mb-2 col-lg-4">
                        <TextBox
                          label="Enter name of natural person"
                          placeholder="Enter the text"
                          name="nameOfNaturalPerson"
                          reducer={this.props.externalReducer}
                          chapter={chapter}
                          disabled={true}
                          className={
                            this.props.PdfGeneration === "Yes"
                              ? "txtBox"
                              : "form-control"
                          }
                        />
                      </div>
                      <div className="mb-2 col-lg-4">
                        <DropDown
                          label="Nationality"
                          name="nationality"
                          reducer={this.props.externalReducer}
                          chapter={chapter}
                          disabled={true}
                          className={
                            this.props.PdfGeneration === "Yes"
                              ? "txtBox"
                              : "form-control"
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}

              {isExternalDirectors === "Yes" && (
                <>
                  <div className="mb-2 col-lg-4">
                    <CheckBox
                      label="Are the external directors a natural person?"
                      options={["Yes", "No"]}
                      name="isExternalDirectorsNaturalPerson"
                      reducer={this.props.reducer}
                      chapter={chapter}
                    />
                  </div>
                  {isExternalDirectorsNaturalPerson === "Yes" && (
                    <>
                      <div className="mb-2 col-lg-4">
                        <TextBox
                          label="Enter name of natural person"
                          placeholder="Enter the text"
                          name="nameOfNaturalPerson"
                          reducer={this.props.reducer}
                          chapter={chapter}
                          className={
                            this.props.PdfGeneration === "Yes"
                              ? "txtBox"
                              : "form-control"
                          }
                        />
                      </div>
                      <div className="mb-2 col-lg-4">
                        <DropDown
                          label="Nationality"
                          name="nationality"
                          options={countryList().getData()}
                          reducer={this.props.reducer}
                          chapter={chapter}
                          className={
                            this.props.PdfGeneration === "Yes"
                              ? "txtBox"
                              : "form-control"
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </Row>
            <Row>
            {chapter6.isOneOrMoreExtDirectorsAuthorized && (
              <div className="mb-2 col-lg-6">
                <CheckBox
                  label="Are one or more external directors independently authorized?"
                  options={["Yes", "No"]}
                  name="isOneOrMoreExtDirectorsAuthorized"
                  chapter={chapter}
                  reducer={this.props.externalReducer}
                  bothDisabled={true}
                />
              </div>
            )}
              {!chapter6.isOneOrMoreExtDirectorsAuthorized && (
              <div className="mb-2 col-lg-6">
                <CheckBox
                  label="Are one or more external directors independently authorized?"
                  options={["Yes", "No"]}
                  name="isOneOrMoreExtDirectorsAuthorized"
                  chapter={chapter}
                  reducer={this.props.reducer}                 
                />
              </div>
            )}
            </Row>
          </>)}
        {/* ---------------------------UK,CU,LU---------------------------------- */}
        {this.props.form !== "Malta" && this.props.form !== "NL" && (
          <>
            <h4 className="sub-chapter-title pt-4 mb-2">External Directors</h4>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-4">
                <CheckBox
                  label="Are there external directors?"
                  options={["Yes", "No"]}
                  name="isExternalDirectors"
                  reducer={this.props.externalReducer}
                  chapter={chapter}
                  bothDisabled={true}
                />
              </div>
              {chapter6.isExternalDirectors === "Yes" && (
                <>
                  <div className="mb-2 col-lg-4">
                    <CheckBox
                      label="Are the external directors a natural person?"
                      options={["Yes", "No"]}
                      name="isExternalDirectorsNaturalPerson"
                      reducer={this.props.externalReducer}
                      chapter={chapter}
                      bothDisabled={true}
                    />
                  </div>
                  {chapter6.isExternalDirectorsNaturalPerson === "Yes" && (
                    <>
                      <div className="mb-2 col-lg-4">
                        <TextBox
                          label="Enter name of natural person"
                          placeholder="Enter the text"
                          name="nameOfNaturalPerson"
                          reducer={this.props.externalReducer}
                          chapter={chapter}
                          disabled={true}
                          className={
                            this.props.PdfGeneration === "Yes"
                              ? "txtBox"
                              : "form-control"
                          }
                        />
                      </div>
                      <div className="mb-2 col-lg-4">
                        <DropDown
                          label="Nationality"
                          name="nationality"
                          reducer={this.props.externalReducer}
                          chapter={chapter}
                          disabled={true}
                          className={
                            this.props.PdfGeneration === "Yes"
                              ? "txtBox"
                              : "form-control"
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}

            </Row>
            <Row>
              {this.props.form !== "UK" && (
                <div className="mb-2 col-lg-6">
                  <CheckBox
                    label="Are one or more external directors independently authorized?"
                    options={["Yes", "No"]}
                    name="isOneOrMoreExtDirectorsAuthorized"
                    chapter={chapter}
                    reducer={this.props.externalReducer}
                    bothDisabled={true}
                  />
                </div>
              )}
            </Row>
          </>
        )}
        {/* ---------------------------/UK,CU,LU---------------------------------- */}

        <Row className="pt-3 pb-2">
          <div className="mb-2 col-lg-4">
            <InternalFileUpload
              label="Pascal report"
              name="reportWorldCheckExtDir"
              id="reportWorldCheckExtDir"
              chapter={chapter}
              acceptType={fileAcceptType}
              reducer={this.props.reducer}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.reportWorldCheckExtDir?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.reportWorldCheckExtDir}
                </span>
              )}
          </div>
          <div className="col-lg-4">
            <CheckBox
              label="Findings Pascal"
              options={["Yes", "No"]}
              name="findingsWorldCheck"
              id="findingsWorldCheck"
              chapter={chapter}
              isRequired={isRequired}
              reducer={this.props.reducer}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.findingsWorldCheck?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.findingsWorldCheck}
                </span>
              )}
          </div>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { externalReducer } = ownProps;
  return {
    chapter6: state[externalReducer]["chapter6"],
    isExternalDirectors: state["ClientAcceptance"]["chapter6"]["isExternalDirectors"],
    isExternalDirectorsNaturalPerson: state["ClientAcceptance"]["chapter6"]["isExternalDirectorsNaturalPerson"],   
  };
};
export default connect(mapStateToProps, null)(ExternalDirectors);
