import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { setDataToReducer } from "../../../../../../redux/actions/ClientInformationAction";
import CheckBox from "../../../../../elements/CheckBox";
import { getReducer } from "../../../../../elements/getReducer";
import TextBox from "../../../../../elements/TextBox";
import {
  oneServicesLuxembourg,
  oneServicesMalta,
  recurrentServicesLuxembourg,
  recurrentServicesMalta,
} from "./Constants";

const objBaseName = "servicesProvidedMal";
class ServicesProvidedMalta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oneServices:
        props.form === "Malta" ? oneServicesMalta : oneServicesLuxembourg,
      recurrentServices:
        props.form === "Malta"
          ? recurrentServicesMalta
          : recurrentServicesLuxembourg,
      selectedOneServices: [],
      selectedRecurrenceServices: [],
    };
  }
  componentDidMount() {
    oneServicesLuxembourg.forEach((element) => (element.isChecked = false));
    oneServicesMalta.forEach((element) => (element.isChecked = false));
    recurrentServicesLuxembourg.forEach(
      (element) => (element.isChecked = false)
    );
    recurrentServicesMalta.forEach((element) => (element.isChecked = false));
  }
  handleChange = (service, nestedObjBaseName) => {
    if (nestedObjBaseName === "oneTimeServ") {
      let array = this.state.oneServices;
      let newIndex = array.indexOf(service);
      service.isChecked = !service.isChecked;
      array[newIndex] = service;
      this.setState({
        oneServices: array,
      });
      let selectedItemsArray = [];
      array.forEach((item) => {
        if (item.isChecked) {
          let obj = {};
          obj["service"] = item.label;
          selectedItemsArray.push(obj);
        }
      });
      this.setState({
        selectedOneServices: selectedItemsArray,
      });
      this.props.setDataToReducer({
        type: getReducer(this.props.reducer),
        name: "serviceNames",
        chapter: this.props.chapter,
        value: selectedItemsArray,
        objBaseName: objBaseName,
        nestedObjBaseName: nestedObjBaseName,
      });
    }
    if (nestedObjBaseName === "recurrntSrvcs") {
      let array = this.state.recurrentServices;
      let newIndex = array.indexOf(service);
      service.isChecked = !service.isChecked;
      array[newIndex] = service;
      this.setState({
        recurrentServices: array,
      });
      let selectedItemsArray = [];
      array.forEach((item) => {
        if (item.isChecked) {
          let obj = {};
          obj["service"] = item.label;
          selectedItemsArray.push(obj);
        }
      });
      this.setState({
        selectedRecurrenceServices: selectedItemsArray,
      });
      this.props.setDataToReducer({
        type: getReducer(this.props.reducer),
        name: "serviceNames",
        chapter: this.props.chapter,
        value: selectedItemsArray,
        objBaseName: objBaseName,
        nestedObjBaseName: nestedObjBaseName,
      });
    }
  };
  render() {
    let oneServicesArray = this.props.oneServices;
    let recurrentServicesArray = this.props.recurrentServices;
    // debugger;
    let existingOneServices = this.state.oneServices;
    let existingRecurrentServices = this.state.recurrentServices;

    if (oneServicesArray?.length > 0) {
      existingOneServices.forEach((item) => {
        oneServicesArray.forEach((service) => {
          if (item.label === service.service) {
            item.isChecked = true;
          }
        });
      });
    }
    if (recurrentServicesArray?.length > 0) {
      existingRecurrentServices.forEach((item) => {
        recurrentServicesArray.forEach((service) => {
          if (item.label === service.service) {
            item.isChecked = true;
          }
        });
      });
    }
    const { sttngCompCntrct, isOffRegstrd, errorMsg } = this.props;
    const errors = JSON.parse(errorMsg);
    // const errors = {};
    return (
      <>
        <div className="html2pdf__page-break"></div>
        <h4 className="sub-chapter-title mb-4">Services to be Provided</h4>
        <Row>
          <div className="mb-3 col-lg-4">
            <p>Onetime Services</p>
          </div>
          <div className="mb-3 col-lg-8">
            <div className="multiSelection">
              <CheckBox
                label="Setting up of company"
                name="sttngCompCntrct"
                id="sttngCompCntrct"
                options={["Yes", "No"]}
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={objBaseName}
                nestedObjBaseName="oneTimeServ"
              />
              {errors &&
                errors?.sttngCompCntrct &&
                errors?.sttngCompCntrct?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.sttngCompCntrct}
                  </span>
                )}
            </div>
            {this.props.form === "Malta" && sttngCompCntrct === "Yes" && (
              <div id="serviceNames">
                {existingOneServices.map((option, index) => {
                  return (
                    <div key={index}>
                      <div className="options">
                        <label
                          value={option.label}
                          className={
                            this.props.from !== "External" && "disabledCheckbox"
                          }
                        >
                          <input
                            type="checkbox"
                            name={option.label}
                            onChange={(e) =>
                              this.handleChange(option, "oneTimeServ")
                            }
                            checked={option.isChecked}
                            value={option.label}
                            disabled={this.props.from !== "External"}
                          />
                          <img alt="" />
                          <span>{option.label}</span>
                        </label>
                      </div>
                    </div>
                  );
                })}
                {errors &&
                  errors?.serviceNames &&
                  errors?.serviceNames?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors?.serviceNames}
                    </span>
                  )}
                {oneServicesArray.filter((item) => item.service === "Others")
                  .length > 0 && (
                  <div className="mb-3 col-lg-12 pl-0 pt-2">
                    <TextBox
                      label="Provide name"
                      name="othrService"
                      id="othrService"
                      placeholder="Enter your text"
                      reducer={this.props.reducer}
                      chapter={this.props.chapter}
                      objBaseName={objBaseName}
                      nestedObjBaseName="oneTimeServ"
                      className={
                        this.props.PdfGeneration === "Yes"
                          ? "txtBox"
                          : "form-control"
                      }
                    />
                    {errors &&
                      errors?.othrService &&
                      errors?.othrService?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors?.othrService}
                        </span>
                      )}
                  </div>
                )}
              </div>
            )}
            {this.props.form === "Luxembourg" && sttngCompCntrct === "Yes" && (
              <div id="serviceNames">
                {existingOneServices.map((option, index) => {
                  return (
                    <div key={index}>
                      <div className="options">
                        <label
                          value={option.label}
                          className={
                            this.props.from !== "External" && "disabledCheckbox"
                          }
                        >
                          <input
                            type="checkbox"
                            name={option.label}
                            onChange={(e) =>
                              this.handleChange(option, "oneTimeServ")
                            }
                            checked={option.isChecked}
                            value={option.label}
                            disabled={this.props.from !== "External"}
                          />
                          <img alt="" />
                          <span>{option.label}</span>
                        </label>
                      </div>
                    </div>
                  );
                })}
                {errors &&
                  errors?.serviceNames &&
                  errors?.serviceNames?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors?.serviceNames}
                    </span>
                  )}
                {oneServicesArray.filter((item) => item.service === "Others")
                  .length > 0 && (
                  <div className="mb-3 col-lg-12 pl-0 pt-2">
                    <TextBox
                      label="Provide name"
                      name="othrService"
                      id="othrService"
                      placeholder="Enter your text"
                      reducer={this.props.reducer}
                      chapter={this.props.chapter}
                      objBaseName={objBaseName}
                      nestedObjBaseName="oneTimeServ"
                      className={
                        this.props.PdfGeneration === "Yes"
                          ? "txtBox"
                          : "form-control"
                      }
                    />
                    {errors &&
                      errors?.othrService &&
                      errors?.othrService?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors?.othrService}
                        </span>
                      )}
                  </div>
                )}
              </div>
            )}
            {sttngCompCntrct === "No" && (
              <Row>
                <div className="mb-3 col-lg-12">
                  <TextBox
                    label="By whom?"
                    name="byWhom"
                    id="byWhom"
                    placeholder="Enter your text"
                    reducer={this.props.reducer}
                    chapter={this.props.chapter}
                    objBaseName={objBaseName}
                    nestedObjBaseName="oneTimeServ"
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtBox"
                        : "form-control"
                    }
                  />
                  {errors && errors?.byWhom && errors?.byWhom?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors?.byWhom}
                    </span>
                  )}
                </div>
              </Row>
            )}
          </div>
        </Row>

        <Row>
          <div className="mb-3 col-lg-4">
            <p>Recurrent Services</p>
          </div>
          <div className="mb-3 col-lg-8">
            <div className="multiSelection">
              <CheckBox
                label="Is the office registered?"
                name="isOffRegstrd"
                id="isOffRegstrd"
                options={["Yes", "No"]}
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                objBaseName={objBaseName}
                nestedObjBaseName="recurrntSrvcs"
              />
              {errors &&
                errors?.isOffRegstrd &&
                errors?.isOffRegstrd?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.isOffRegstrd}
                  </span>
                )}
            </div>
            {this.props.form === "Malta" && isOffRegstrd === "Yes" && (
              <div id="serviceNames2">
                {existingRecurrentServices.map((option, index) => {
                  return (
                    <div key={index}>
                      <div className="options">
                        <label
                          value={option.label}
                          className={
                            this.props.from !== "External" && "disabledCheckbox"
                          }
                        >
                          <input
                            type="checkbox"
                            name={option.label}
                            onChange={(e) =>
                              this.handleChange(option, "recurrntSrvcs")
                            }
                            checked={option.isChecked}
                            value={option.label}
                            disabled={this.props.from !== "External"}
                          />
                          <img alt="" />
                          <span>{option.label}</span>
                        </label>
                      </div>
                    </div>
                  );
                })}
                {errors &&
                  errors?.serviceNames2 &&
                  errors?.serviceNames2?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors?.serviceNames2}
                    </span>
                  )}
                {recurrentServicesArray.filter((item) => {
                     return item.service === "Others";
                }).length > 0 && (
                  <div className="mb-3 col-lg-12">
                    <TextBox
                      label="Provide name"
                      name="othrService"
                      id="othrService"
                      placeholder="Enter your text"
                      reducer={this.props.reducer}
                      chapter={this.props.chapter}
                      objBaseName={objBaseName}
                      nestedObjBaseName="recurrntSrvcs"
                      className={
                        this.props.PdfGeneration === "Yes"
                          ? "txtBox"
                          : "form-control"
                      }
                    />
                    {errors &&
                      errors?.othrService2 &&
                      errors?.othrService2?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors?.othrService2}
                        </span>
                      )}
                  </div>
                )}
              </div>
            )}
            {this.props.form === "Luxembourg" && isOffRegstrd === "Yes" && (
              <div id="serviceNames2">
                {existingRecurrentServices.map((option, index) => {
                  return (
                    <div key={index}>
                      <div className="options">
                        <label
                          value={option.label}
                          className={
                            this.props.from !== "External" && "disabledCheckbox"
                          }
                        >
                          <input
                            type="checkbox"
                            name={option.label}
                            onChange={(e) =>
                              this.handleChange(option, "recurrntSrvcs")
                            }
                            checked={option.isChecked}
                            value={option.label}
                            disabled={this.props.from !== "External"}
                          />
                          <img alt="" />
                          <span>{option.label}</span>
                        </label>
                      </div>
                    </div>
                  );
                })}
                {errors &&
                  errors?.serviceNames2 &&
                  errors?.serviceNames2?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors?.serviceNames2}
                    </span>
                  )}
                {recurrentServicesArray.filter(
                  (item) => item.service === "Others"
                ).length > 0 && (
                  <div className="mb-3 col-lg-12">
                    <TextBox
                      label="Provide name"
                      name="othrService"
                      id="othrService2"
                      placeholder="Enter your text"
                      reducer={this.props.reducer}
                      chapter={this.props.chapter}
                      objBaseName={objBaseName}
                      nestedObjBaseName="recurrntSrvcs"
                      className={
                        this.props.PdfGeneration === "Yes"
                          ? "txtBox"
                          : "form-control"
                      }
                    />
                    {errors &&
                      errors?.othrService2 &&
                      errors?.othrService2?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors?.othrService2}
                        </span>
                      )}
                  </div>
                )}
              </div>
            )}
            {isOffRegstrd === "No" && (
              <Row>
                <div className="mb-3 col-lg-12">
                  <TextBox
                    label="Provide complete address where the company is registered?"
                    name="comAddComReg"
                    id="comAddComReg"
                    placeholder="Enter your text"
                    reducer={this.props.reducer}
                    chapter={this.props.chapter}
                    objBaseName={objBaseName}
                    nestedObjBaseName="recurrntSrvcs"
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtBox"
                        : "form-control"
                    }
                  />
                  {errors &&
                    errors?.comAddComReg &&
                    errors?.comAddComReg?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors?.comAddComReg}
                      </span>
                    )}
                </div>
              </Row>
            )}
          </div>
        </Row>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { reducer, chapter } = ownProps;
  return {
    sttngCompCntrct:
      state[reducer][chapter]["servicesProvidedMal"]["oneTimeServ"][
        "sttngCompCntrct"
      ],
    oneServices:
      state[reducer][chapter]["servicesProvidedMal"]["oneTimeServ"][
        "serviceNames"
      ],
    isOffRegstrd:
      state[reducer][chapter]["servicesProvidedMal"]["recurrntSrvcs"][
        "isOffRegstrd"
      ],
    recurrentServices:
      state[reducer][chapter]["servicesProvidedMal"]["recurrntSrvcs"][
        "serviceNames"
      ],
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicesProvidedMalta);
