import React, { Component } from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../../elements/TextBox";
import FileUpload from "../../../../../elements/FileUpload";
import DropDown from "../../../../../elements/DropDown";
import { fileAcceptType } from "./Constants";
import countryList from "react-select-country-list";
import PhoneInputField from "../../../../../elements/PhoneInputField";
import DateTimePicker from "../../../../../elements/DateTimePicker";
import MultiSelectDropdown from "../../../../../elements/MultiSelectDropdown";
class UBOs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Row className="pt-3 pb-2">
          <div className="mb-2 col-lg-4">
            <TextBox
              label="Full name of UBO"
              placeholder="enter name"
              reducer={this.props.reducer}
              id="uboXName"
              name="uboXName"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
              //  index={this.props.index}
              //baseName={this.props.baseName}
              validationType="OnlyLetter"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.uboXName?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.uboXName}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-4" id="dob">
            <DateTimePicker
              label="Date of birth"
              reducer={this.props.reducer}
              name="dob"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              //  index={this.props.index}
              //baseName={this.props.baseName}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.dob?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.dob}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-4">
            <TextBox
              label="Place of birth"
              placeholder="enter birth place"
              reducer={this.props.reducer}
              id="pob"
              name="pob"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              //  index={this.props.index}
              //baseName={this.props.baseName}
              validationType="Text"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.pob?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.pob}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-4 countrySelection">
            <DropDown
              label="Country of birth"
              placeholder="enter country of birth"
              options={countryList().getData()}
              reducer={this.props.reducer}
              id="cob"
              name="cob"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              //  index={this.props.index}
              //baseName={this.props.baseName}
              validationType="OnlyLetter"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.cob?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.cob}
                </span>
              )}
          </div>

          {this.props.form === "Curacao" ? (
            <div className="mb-2 col-lg-4">
              <MultiSelectDropdown
                label="Nationality"
                options={countryList().getData()}
                reducer={this.props.reducer}
                id="multiNationality"
                name="multiNationality"
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
              />
              {this.props.errorMsg !== null &&
                Object.entries(this.props.errorMsg).length !== 0 &&
                this.props.errorMsg?.partA?.multiNationality?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {this.props.errorMsg?.partA?.multiNationality}
                  </span>
                )}
            </div>
          ) : (
            <div className="mb-2 col-lg-4">
              <DropDown
                label="Nationality"
                options={countryList().getData()}
                reducer={this.props.reducer}
                id="nationality"
                name="nationality"
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                //  index={this.props.index}
                //  baseName={this.props.baseName}
                validationType="OnlyLetter"
              />
              {this.props.errorMsg !== null &&
                Object.entries(this.props.errorMsg).length !== 0 &&
                this.props.errorMsg?.partA?.nationality?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {this.props.errorMsg?.partA?.nationality}
                  </span>
                )}
            </div>
          )}

          <div className="mb-2 col-lg-4">
            <TextBox
              label="Passport number"
              placeholder="enter passport number"
              reducer={this.props.reducer}
              id="passportNum"
              name="passportNum"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.passportNum?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.passportNum}
                </span>
              )}
          </div>
          {this.props.form !== "Malta" && (
            <div className="mb-2 col-lg-4">
              <TextBox
                label="Marital status"
                placeholder="enter marital status"
                reducer={this.props.reducer}
                id="maritalStatus"
                name="maritalStatus"
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                //  index={this.props.index}
                // baseName={this.props.baseName}
                validationType="OnlyLetter"
              />
              {this.props.errorMsg !== null &&
                Object.entries(this.props.errorMsg).length !== 0 &&
                this.props.errorMsg?.partA?.maritalStatus?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {this.props.errorMsg?.partA?.maritalStatus}
                  </span>
                )}
            </div>
          )}

          <div className="mb-2 col-lg-4" id="certifiedPassportUBO">
            <FileUpload
              label="Enclose a legalized passport copy"
              info="Legalized by (Civil Law) Notary, Lawyer"
              acceptType={fileAcceptType}              
              name="certifiedPassportUBO"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              objBaseName={this.props.objBaseName}
              disabled={true}
              //  index={this.props.index}
              //  baseName={this.props.baseName}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.certifiedPassportUBO?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.certifiedPassportUBO}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-4">
            <TextBox
              label="Private address"
              placeholder="enter private address"
              reducer={this.props.reducer}
              id="pvtAddress"
              name="pvtAddress"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              //  index={this.props.index}
              //  baseName={this.props.baseName}
              validationType="Text"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.pvtAddress?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.pvtAddress}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-4">
            <TextBox
              label="Postal code"
              placeholder="enter postal code"
              reducer={this.props.reducer}
              id="uboPostalCode"
              name="uboPostalCode"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              //  index={this.props.index}
              //  baseName={this.props.baseName}
              validationType="Text"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.uboPostalCode?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.uboPostalCode}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-4">
            <TextBox
              label="City"
              placeholder="enter city"
              reducer={this.props.reducer}
              id="uboCity"
              name="uboCity"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              //  index={this.props.index}
              //  baseName={this.props.baseName}
              validationType="OnlyLetter"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.uboCity?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.uboCity}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-4 countrySelection">
            <DropDown
              label="Country"
              id="uboCountry"
              name="uboCountry"
              options={countryList().getData()}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              //  index={this.props.index}
              //  baseName={this.props.baseName}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.uboCountry?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.uboCountry}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-4">
            <TextBox
              label="E-mail address"
              placeholder="enter email address"
              reducer={this.props.reducer}
              id="uboEmail"
              name="uboEmail"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              //  index={this.props.index}
              //  baseName={this.props.baseName}
              validationType="Email"
              disabled={true}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.uboEmail?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.uboEmail}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-4">
            <PhoneInputField
              label="Phone number"
              placeholder="enter cell phone"
              reducer={this.props.reducer}
              id="uboPhoneNum"
              name="uboPhoneNum"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
              country={this.props.form}
              //   index={this.props.index}
              //  baseName={this.props.baseName}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.uboPhoneNum?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.uboPhoneNum}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-4">
            <TextBox
              label="Fax"
              placeholder="enter fax number"
              reducer={this.props.reducer}
              id="fax"
              name="fax"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              //  index={this.props.index}
              //  baseName={this.props.baseName}
              validationType="Text"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.fax?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.fax}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-4">
            <TextBox
              label="Occupation"
              placeholder="enter UBO's occupation"
              reducer={this.props.reducer}
              id="occupation"
              name="occupation"
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              //  index={this.props.index}
              //  baseName={this.props.baseName}
              validationType="Text"
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.occupation?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.occupation}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-4">
            <FileUpload
              label="Curriculum Vitae"
              acceptType={fileAcceptType}
              id="curriculumVitae"
              name="curriculumVitae"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              objBaseName={this.props.objBaseName}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.curriculumVitae?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.curriculumVitae}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-4">
            <FileUpload
              label="Proof of Address"
              acceptType={fileAcceptType}
              id="proofOfAddress"
              name="proofOfAddress"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              objBaseName={this.props.objBaseName}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.proofOfAddress?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.proofOfAddress}
                </span>
              )}
          </div>
        </Row>
      </>
    );
  }
}

export default UBOs;
