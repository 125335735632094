import { Component } from "react";
import {
  isFieldEmpty,
  isFileEmpty,
  isEmailValid,
} from "../UKOffice/checkFields";

class FormValidations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrorschapter1: {},
      formErrorschapter2: {},
      formErrorschapter3: {},
      formErrorschapter4: {},
      formErrorschapter5: {},
      formErrorschapter6: {},
    };
  }

  validateDetails = async (data) => {
    let formErrors = {};
    let commonFields = { ...data.commonFields };
    Object.entries(data).forEach(async (entry) => {
      const [key, value] = entry;
      if (key === "CuracaoClientInformation") {
        Object.entries(value).forEach(async (chp) => {
          const [chapter, requestData] = chp;
          let result = await this.checkFormValidations(
            requestData,
            chapter,
            commonFields
          );
          formErrors[chapter] = result;
        });
      }
    });
    return { formErrors };
  };
  checkFormValidations = (data, chapter, commonFields) => {
    switch (chapter) {
      case "chapter1": {
        let formErrorsChapter1 = this.state.formErrorschapter1;
        formErrorsChapter1.structureChart = isFileEmpty(data.structureChart);
        formErrorsChapter1.isCompIncorporated = isFieldEmpty(
          data.isCompIncorporated
        );
        formErrorsChapter1.entities = [];
        if (data.isCompIncorporated === "Yes") {
          data.entities.forEach((element) => {
            let errors = {};
            errors.chamberExtract = isFileEmpty(element.chamberExtract);
            errors.article = isFileEmpty(element.article);
            errors.shareholderRegister = isFileEmpty(
              element.shareholderRegister
            );
            errors.finStat = isFileEmpty(element.finStat);
            formErrorsChapter1.entities.push(errors);
          });
        } else {
          formErrorsChapter1.entities = [];
        }
        formErrorsChapter1.upcActivites = isFieldEmpty(
          commonFields.upcActivites
        );
        formErrorsChapter1.upcSector = isFieldEmpty(commonFields.upcSector);
        formErrorsChapter1.upcActivites = isFieldEmpty(
          commonFields.upcActivites
        );
        formErrorsChapter1.shareholderActivites = isFieldEmpty(
          commonFields.shareholderActivites
        );
        formErrorsChapter1.shareholderSector = isFieldEmpty(
          commonFields.shareholderSector
        );
        formErrorsChapter1.objCompActivities = isFieldEmpty(
          commonFields.objCompActivities
        );
        formErrorsChapter1.objCompSector = isFieldEmpty(
          commonFields.objCompSector
        );
        formErrorsChapter1.participantActivites = isFieldEmpty(
          commonFields.participantActivites
        );
        formErrorsChapter1.participantSector = isFieldEmpty(
          commonFields.participantSector
        );
        formErrorsChapter1.otherActivites = isFieldEmpty(
          commonFields.otherActivites
        );
        formErrorsChapter1.otherSector = isFieldEmpty(commonFields.otherSector);
        return { formErrorsChapter1 };
      }
      case "chapter2": {
        let formErrorsChapter2 = this.state.formErrorschapter2;
        formErrorsChapter2.isClientRepInvolved = isFieldEmpty(
          data.isClientRepInvolved
        );
        if (data.isClientRepInvolved === "Yes") {
          formErrorsChapter2.intermediaryDetails = [];
          data.intermediaryDetails.forEach((element) => {
            let errors = {};
            errors.isClientReal = isFieldEmpty(element.isClientReal);
            if (element.isClientReal === "Yes") {
              errors.clientRepName = isFieldEmpty(element.clientRepName);
              errors.legalEntityName = isFieldEmpty(element.legalEntityName);
              errors.clientRepPassport = isFileEmpty(element.clientRepPassport);
              errors.clientRepNationality = isFieldEmpty(
                element.clientRepNationality
              );
              errors.cocExtract = "";
            }
            if (element.isClientReal === "No") {
              errors.legalEntityName = "";
              errors.clientRepName = "";
              errors.clientRepPassport = "";
              errors.clientRepNationality = "";
              errors.cocExtract = isFileEmpty(element.cocExtract);
            }
            // errors.isAuthrzdPrsn = isFieldEmpty(element.isAuthrzdPrsn);
            // if (element.isAuthrzdPrsn === "Yes") {
            //   errors.authrzdPrsnName = isFieldEmpty(element.authrzdPrsnName);
            //   errors.authrzdPrsnNationality = isFieldEmpty(
            //     element.authrzdPrsnNationality
            //   );
            //   errors.authrzdPrsnPassport = isFileEmpty(
            //     element.authrzdPrsnPassport
            //   );
            // }
            // if (element.isAuthrzdPrsn === "No") {
            //   errors.authrzdPrsnName = "";
            //   errors.authrzdPrsnNationality = "";
            //   errors.authrzdPrsnPassport = "";
            // }

            errors.activeInCountry = isFieldEmpty(element.activeInCountry);

            errors.isPOA = isFieldEmpty(element.isPOA);
            if (element.isPOA === "Yes") {
              errors.poa = isFileEmpty(element.poa);
              errors.noPOAReason = "";
            }

            if (element.isPOA === "No") {
              errors.poa = "";
              errors.noPOAReason = isFieldEmpty(element.noPOAReason);
            }

            errors.isUBOIdentifyVerify = isFieldEmpty(
              element.isUBOIdentifyVerify
            );

            if (element.isUBOIdentifyVerify === "Yes") {
              errors.uboIdentityPassport = isFileEmpty(
                element.uboIdentityPassport
              );
              errors.noUBOIdentifyVerifyReason = "";
            }
            if (element.isUBOIdentifyVerify === "No") {
              errors.uboIdentityPassport = "";
              errors.noUBOIdentifyVerifyReason = isFieldEmpty(
                element.noUBOIdentifyVerifyReason
              );
            }

            formErrorsChapter2.intermediaryDetails.push(errors);
          });
        }
        formErrorsChapter2.isClientTransfrd = isFieldEmpty(
          commonFields.isClientTransfrd
        );
        if (commonFields.isClientTransfrd === "Yes") {
          formErrorsChapter2.srvcPrvdrName = isFieldEmpty(
            commonFields.srvcPrvdrName
          );
          formErrorsChapter2.reasonClientTransfrd = isFieldEmpty(
            commonFields.reasonClientTransfrd
          );
        }
        if (commonFields.isClientTransfrd === "No") {
          formErrorsChapter2.srvcPrvdrName = "";
          formErrorsChapter2.reasonClientTransfrd = "";
        }
        return { formErrorsChapter2 };
      }
      case "chapter3": {
        let formErrorsChapter3 = this.state.formErrorschapter3;
        if (data.isHoldingInterest === "Yes") {
          formErrorsChapter3.isHoldingInterestReason = isFieldEmpty(
            data.isHoldingInterestReason
          );
        }

        if (data.isUBOClassified === "Yes") {
          formErrorsChapter3.isUBOClassifiedReason = isFieldEmpty(
            data.isUBOClassifiedReason
          );
        }

        if (data.isAssetClient === "Yes") {
          formErrorsChapter3.isAssetClientReason = isFieldEmpty(
            data.isAssetClientReason
          );
        }
        if (
          data.isHoldingInterest === "No" &&
          data.isUBOClassified === "No" &&
          data.isAssetClient === "No"
        ) {
          formErrorsChapter3.ubo = [];

          // formErrorsChapter3.isHoldingInterestReason = isFieldEmpty(
          //   data.isHoldingInterestReason
          // );
          formErrorsChapter3.nameUPC = isFieldEmpty(commonFields.upc.nameUPC);
          formErrorsChapter3.addressRegistered = isFieldEmpty(
            commonFields.upc.addressRegistered
          );
          formErrorsChapter3.postalCode = isFieldEmpty(
            commonFields.upc.postalCode
          );
          formErrorsChapter3.city = isFieldEmpty(commonFields.upc.city);
          formErrorsChapter3.country = isFieldEmpty(commonFields.upc.country);
          formErrorsChapter3.websiteUPC = isFieldEmpty(
            commonFields.upc.websiteUPC
          );
          formErrorsChapter3.taxNum = isFieldEmpty(commonFields.upc.taxNum);
          formErrorsChapter3.tradeRegisterNum = isFieldEmpty(
            commonFields.upc.tradeRegisterNum
          );
          formErrorsChapter3.phoneNumberUPC = isFieldEmpty(
            commonFields.upc.phoneNumberUPC
          );
          formErrorsChapter3.countryOfTaxRsdnc = isFieldEmpty(
            commonFields.upc.countryOfTaxRsdnc
          );
          formErrorsChapter3.isForceCloseFinAcc = isFieldEmpty(
            commonFields.upc.isForceCloseFinAcc
          );
          if (commonFields.upc.isForceCloseFinAcc === "Yes") {
            formErrorsChapter3.forceCloseFinAcc = isFieldEmpty(
              commonFields.upc.forceCloseFinAcc
            );
          }

          if (commonFields.upc.isForceCloseFinAcc === "No") {
            formErrorsChapter3.forceCloseFinAcc = "";
          }

          formErrorsChapter3.upcAuthDirName = isFieldEmpty(
            commonFields.upc.upcAuthDirName
          );
          formErrorsChapter3.upcAuthDirEmail = isFieldEmpty(
            commonFields.upc.upcAuthDirEmail
          );
          if (commonFields.upc.upcAuthDirEmail) {
            formErrorsChapter3.upcAuthDirEmail = isEmailValid(
              commonFields.upc.upcAuthDirEmail
            );
          }

          formErrorsChapter3.upcAuthDirNationality = isFieldEmpty(
            commonFields.upc.upcAuthDirNationality
          );
          formErrorsChapter3.extractChamberOfCommerce = isFileEmpty(
            commonFields.upc.extractChamberOfCommerce
          );
          formErrorsChapter3.deedOfIncorporation = isFileEmpty(
            commonFields.upc.deedOfIncorporation
          );
          formErrorsChapter3.shareholdersRegister = isFileEmpty(
            commonFields.upc.shareholdersRegister
          );
          formErrorsChapter3.shareholdersCertificates = isFileEmpty(
            commonFields.upc.shareholdersCertificates
          );
          formErrorsChapter3.nomineeAgreement = isFileEmpty(
            commonFields.upc.nomineeAgreement
          );
          formErrorsChapter3.financialStatementsThreeYears = isFileEmpty(
            commonFields.upc.financialStatementsThreeYears
          );
        }

        if (
          data.isHoldingInterest === "Yes" &&
          data.isUBOClassified === "Yes" &&
          data.isAssetClient === "Yes"
        ) {
          formErrorsChapter3.ubox = [];
          commonFields.ubo.ubox.forEach((element) => {
            let errors = {};
            errors.isUBOPEP = isFieldEmpty(element.isUBOPEP);
            errors.certifiedPassportUBO = isFieldEmpty(
              element.certifiedPassportUBO
            );
            formErrorsChapter3.ubox.push(errors);
          });
          formErrorsChapter3.isUPCExists = isFieldEmpty(
            commonFields.isUPCExists
          );
          if (commonFields.isUPCExists === "Yes") {
            formErrorsChapter3.nameUPC = isFieldEmpty(commonFields.upc.nameUPC);
            formErrorsChapter3.addressRegistered = isFieldEmpty(
              commonFields.upc.addressRegistered
            );
            formErrorsChapter3.postalCode = isFieldEmpty(
              commonFields.upc.postalCode
            );
            formErrorsChapter3.city = isFieldEmpty(commonFields.upc.city);
            formErrorsChapter3.country = isFieldEmpty(commonFields.upc.country);
            formErrorsChapter3.websiteUPC = isFieldEmpty(
              commonFields.upc.websiteUPC
            );
            formErrorsChapter3.taxNum = isFieldEmpty(commonFields.upc.taxNum);
            formErrorsChapter3.tradeRegisterNum = isFieldEmpty(
              commonFields.upc.tradeRegisterNum
            );
            formErrorsChapter3.phoneNumberUPC = isFieldEmpty(
              commonFields.upc.phoneNumberUPC
            );
            formErrorsChapter3.countryOfTaxRsdnc = isFieldEmpty(
              commonFields.upc.countryOfTaxRsdnc
            );
            formErrorsChapter3.isForceCloseFinAcc = isFieldEmpty(
              commonFields.upc.isForceCloseFinAcc
            );
            if (commonFields.upc.isForceCloseFinAcc === "Yes") {
              formErrorsChapter3.forceCloseFinAcc = isFieldEmpty(
                commonFields.upc.forceCloseFinAcc
              );
            }

            if (commonFields.upc.isForceCloseFinAcc === "No") {
              formErrorsChapter3.forceCloseFinAcc = "";
            }

            formErrorsChapter3.upcAuthDirName = isFieldEmpty(
              commonFields.upc.upcAuthDirName
            );
            formErrorsChapter3.upcAuthDirEmail = isFieldEmpty(
              commonFields.upc.upcAuthDirEmail
            );
            if (commonFields.upc.upcAuthDirEmail) {
              formErrorsChapter3.upcAuthDirEmail = isEmailValid(
                commonFields.upc.upcAuthDirEmail
              );
            }
            formErrorsChapter3.upcAuthDirNationality = isFieldEmpty(
              commonFields.upc.upcAuthDirNationality
            );
            formErrorsChapter3.extractChamberOfCommerce = isFileEmpty(
              commonFields.upc.extractChamberOfCommerce
            );
            formErrorsChapter3.deedOfIncorporation = isFileEmpty(
              commonFields.upc.deedOfIncorporation
            );
            formErrorsChapter3.shareholdersRegister = isFileEmpty(
              commonFields.upc.shareholdersRegister
            );
            formErrorsChapter3.shareholdersCertificates = isFileEmpty(
              commonFields.upc.shareholdersCertificates
            );
            formErrorsChapter3.nomineeAgreement = isFileEmpty(
              commonFields.upc.nomineeAgreement
            );
            formErrorsChapter3.financialStatementsThreeYears = isFileEmpty(
              commonFields.upc.financialStatementsThreeYears
            );
          }
          if (commonFields.upc.isUPCExists === "No") {
            formErrorsChapter3.nameUPC = "";
            formErrorsChapter3.addressRegistered = "";
            formErrorsChapter3.postalCode = "";
            formErrorsChapter3.city = "";
            formErrorsChapter3.country = "";
            formErrorsChapter3.websiteUPC = "";
            formErrorsChapter3.taxNum = "";
            formErrorsChapter3.tradeRegisterNum = "";
            formErrorsChapter3.phoneNumberUPC = "";
            formErrorsChapter3.countryOfTaxRsdnc = "";
            formErrorsChapter3.isForceCloseFinAcc = "";

            formErrorsChapter3.forceCloseFinAcc = "";

            formErrorsChapter3.upcAuthDirName = "";
            formErrorsChapter3.upcAuthDirEmail = "";
            formErrorsChapter3.upcAuthDirNationality = "";
            formErrorsChapter3.extractChamberOfCommerce = "";
            formErrorsChapter3.deedOfIncorporation = "";
            formErrorsChapter3.shareholdersRegister = "";
            formErrorsChapter3.shareholdersCertificates = "";
            formErrorsChapter3.nomineeAgreement = "";
            formErrorsChapter3.financialStatementsThreeYears = "";
          }
        }

        return { formErrorsChapter3 };
      }
      case "chapter4": {
        let formErrorsChapter4 = this.state.formErrorschapter4;
        formErrorsChapter4.isObjCompExists = isFieldEmpty(data.isObjCompExists);
        formErrorsChapter4.objCompName = isFieldEmpty(commonFields.objCompName);
        if (data.isObjCompExists === "Yes") {
          formErrorsChapter4.commercialRegister = isFieldEmpty(
            data.commercialRegister
          );
        }
        if (data.isObjCompExists === "No") {
          formErrorsChapter4.commercialRegister = "";
        }
        formErrorsChapter4.objCompDirectorNames = [];

        // data.objCompDirectorNames.forEach((element) => {
        //   let errors = {};
        //   errors.dirName = isFieldEmpty(element.dirName);
        //   errors.dirNationality = isFieldEmpty(element.dirNationality);
        //   formErrorsChapter4.objCompDirectorNames.push(errors);
        // });
        formErrorsChapter4.objCompActiveCountry = isFieldEmpty(
          data.objCompActiveCountry
        );
        formErrorsChapter4.objCompNaturePropsdObjctv = isFieldEmpty(
          commonFields.objCompNaturePropsdObjctv
        );
        if (commonFields.objCompNaturePropsdObjctv === "Other") {
          formErrorsChapter4.objCompOtherNaturePropsdObjctv = isFieldEmpty(
            commonFields.objCompOtherNaturePropsdObjctv
          );
        } else {
          formErrorsChapter4.objCompOtherNaturePropsdObjctv = "";
        }
        formErrorsChapter4.isObjCompBranches = isFieldEmpty(
          data.isObjCompBranches
        );
        if (data.isObjCompBranches === "Yes") {
          formErrorsChapter4.objCompBranch = isFieldEmpty(data.objCompBranch);
        }
        if (data.isObjCompBranches === "No") {
          formErrorsChapter4.objCompBranch = "";
        }
        formErrorsChapter4.rationaleStructurigForNL = isFieldEmpty(
          commonFields.rationaleStructurigForNL
        );
        formErrorsChapter4.rationaleSelectedInfo = isFieldEmpty(
          commonFields.rationaleSelectedInfo
        );
        formErrorsChapter4.taxAdvisorFinds = isFileEmpty(data.taxAdvisorFinds);
        formErrorsChapter4.isInsuranceBearerShares = isFieldEmpty(
          commonFields.isInsuranceBearerShares
        );
        if (commonFields.isInsuranceBearerShares === "Yes") {
          formErrorsChapter4.bsCurrency = isFieldEmpty(commonFields.bsCurrency);
          formErrorsChapter4.bsAmount = isFieldEmpty(commonFields.bsAmount);
          formErrorsChapter4.bsHolder = isFieldEmpty(commonFields.bsHolder);
          formErrorsChapter4.bsBeneficiary = isFieldEmpty(
            commonFields.bsBeneficiary
          );
          formErrorsChapter4.bsEvidences = isFileEmpty(
            commonFields.bsEvidences
          );
        }
        if (commonFields.isInsuranceBearerShares === "No") {
          formErrorsChapter4.bsCurrency = "";
          formErrorsChapter4.bsAmount = "";
          formErrorsChapter4.bsHolder = "";
          formErrorsChapter4.bsBeneficiary = "";
          formErrorsChapter4.bsEvidences = "";
        }

        formErrorsChapter4.copyPassportManagement = isFileEmpty(
          data.documentsObjComp.copyPassportManagement
        );
        formErrorsChapter4.extractChamberOfCommerce4 = isFileEmpty(
          data.documentsObjComp.extractChamberOfCommerce
        );
        formErrorsChapter4.deedOfIncorporation4 = isFileEmpty(
          data.documentsObjComp.deedOfIncorporation
        );
        formErrorsChapter4.shareholdersRegister4 = isFileEmpty(
          data.documentsObjComp.shareholdersRegister
        );
        formErrorsChapter4.shareholdersCertificates4 = isFileEmpty(
          data.documentsObjComp.shareholdersCertificates
        );
        if (data.isObjCompExists === "Yes") {
          formErrorsChapter4.financialStatmntsThreeYears4 = isFileEmpty(
            data.documentsObjComp.financialStatmntsThreeYears
          );
        }
        if (data.isObjCompExists === "No") {
          formErrorsChapter4.financialStatmntsThreeYears4 = "";
        }
        formErrorsChapter4.isTaxMemorandum = isFieldEmpty(
          commonFields.isTaxMemorandum
        );
        if (commonFields.isTaxMemorandum === "Yes") {
          formErrorsChapter4.taxAdvisorName = isFieldEmpty(
            commonFields.taxAdvisorName
          );
          formErrorsChapter4.taxMemorandum = isFileEmpty(
            commonFields.taxMemorandum
          );
        }
        if (commonFields.isTaxMemorandum === "No") {
          formErrorsChapter4.taxAdvisorName = "";
          formErrorsChapter4.taxMemorandum = "";
        }

        formErrorsChapter4.taxMemorandumAdvisorName = isFieldEmpty(
          commonFields.taxMemorandumAdvisorName
        );
        formErrorsChapter4.isAPA = isFieldEmpty(data.documentsObjComp.isAPA);
        formErrorsChapter4.objCompTaxResult = isFieldEmpty(
          data.documentsObjComp.objCompTaxResult
        );
        formErrorsChapter4.explanation = isFieldEmpty(
          data.documentsObjComp.explanation
        );
        formErrorsChapter4.otherDocs = isFileEmpty(
          data.documentsObjComp.otherDocs
        );
        formErrorsChapter4.isUBOInvstObjComp = isFileEmpty(
          commonFields.srcFundsObjComp.isUBOInvstObjComp
        );
        if (commonFields.srcFundsObjComp.isUBOInvstObjComp === "Yes") {
          formErrorsChapter4.uboInvstCurrencyObjComp = isFieldEmpty(
            commonFields.srcFundsObjComp.uboInvstCurrencyObjComp
          );
          formErrorsChapter4.uboInvstObjCompAmnt = isFieldEmpty(
            commonFields.srcFundsObjComp.uboInvstObjCompAmnt
          );
          formErrorsChapter4.uboInvstObjCompOrigin = isFieldEmpty(
            commonFields.srcFundsObjComp.uboInvstObjCompOrigin
          );
        }
        if (commonFields.srcFundsObjComp.isUBOInvstObjComp === "No") {
          formErrorsChapter4.uboInvstCurrencyObjComp = "";
          formErrorsChapter4.uboInvstObjCompAmnt = "";
          formErrorsChapter4.uboInvstObjCompOrigin = "";
        }
        formErrorsChapter4.isUPCfundObjComp = isFieldEmpty(
          commonFields.srcFundsObjComp.isUPCfundObjComp
        );
        if (commonFields.srcFundsObjComp.isUPCfundObjComp === "Yes") {
          formErrorsChapter4.upcFundObjCompOrigin = isFieldEmpty(
            commonFields.srcFundsObjComp.upcFundObjCompOrigin
          );
          formErrorsChapter4.annualFinStmnt = isFileEmpty(
            commonFields.srcFundsObjComp.annualFinStmnt
          );
        }
        if (commonFields.srcFundsObjComp.isUPCfundObjComp === "No") {
          formErrorsChapter4.upcFundObjCompOrigin = "";
          formErrorsChapter4.annualFinStmnt = "";
        }
        formErrorsChapter4.isTpInvstObjComp = isFieldEmpty(
          commonFields.srcFundsObjComp.isTpInvstObjComp
        );
        if (commonFields.srcFundsObjComp.isTpInvstObjComp === "Yes") {
          formErrorsChapter4.tpName = isFieldEmpty(
            commonFields.srcFundsObjComp.tpInvstObjComp.tpName
          );
          formErrorsChapter4.tpCountry = isFieldEmpty(
            commonFields.srcFundsObjComp.tpInvstObjComp.tpCountry
          );
          formErrorsChapter4.tpAnnualFinStmnts = isFileEmpty(
            commonFields.srcFundsObjComp.tpInvstObjComp.tpAnnualFinStmnts
          );
        }
        if (commonFields.srcFundsObjComp.isTpInvstObjComp === "No") {
          formErrorsChapter4.tpName = "";
          formErrorsChapter4.tpCountry = "";
          formErrorsChapter4.tpAnnualFinStmnts = "";
        }
        formErrorsChapter4.cntrysInvlvdTrnsctnsObjComp = isFieldEmpty(
          commonFields.srcFundsObjComp.cntrysInvlvdTrnsctnsObjComp
        );
        formErrorsChapter4.estmtdAnnualTrnOvrObjComp = isFieldEmpty(
          commonFields.srcFundsObjComp.estmtdAnnualTrnOvrObjComp
        );
        formErrorsChapter4.assetSummryObjComp = isFieldEmpty(
          commonFields.srcFundsObjComp.assetSummryObjComp
        );
        formErrorsChapter4.assetSummryEvidence = isFileEmpty(
          commonFields.srcFundsObjComp.assetSummryEvidence
        );
        formErrorsChapter4.expctdTrnsctnsSummary = isFieldEmpty(
          commonFields.srcFundsObjComp.expctdTrnsctnsSummary
        );
        formErrorsChapter4.expctdTrnsctnsEvidence = isFileEmpty(
          commonFields.srcFundsObjComp.expctdTrnsctnsEvidence
        );
        if (
          commonFields.srcFundsObjComp.isTpInvstObjComp === "Yes" ||
          commonFields.srcFundsObjComp.isUPCfundObjComp === "Yes" ||
          commonFields.srcFundsObjComp.isUBOInvstObjComp === "Yes"
        ) {
          if (commonFields["srcFundsObjComp"]["isUBOInvstObjComp"] === "Yes") {
            formErrorsChapter4.objCompToUBO = {
              typeTrnsct: "",
              sizeTrnsct: "",
              freqTrnsct: "",
            };
            formErrorsChapter4.uboToObjComp = {
              typeTrnsct: "",
              sizeTrnsct: "",
              freqTrnsct: "",
            };
            formErrorsChapter4.objCompToUBO.freqTrnsct = isFieldEmpty(
              commonFields["srcFundsObjComp"]["objCompToUBO"]["freqTrnsct"]
            );

            formErrorsChapter4.objCompToUBO.sizeTrnsct = isFieldEmpty(
              commonFields["srcFundsObjComp"]["objCompToUBO"]["sizeTrnsct"]
            );

            formErrorsChapter4.objCompToUBO.typeTrnsct = isFieldEmpty(
              commonFields["srcFundsObjComp"]["objCompToUBO"]["typeTrnsct"]
            );

            formErrorsChapter4.uboToObjComp.freqTrnsct = isFieldEmpty(
              commonFields["srcFundsObjComp"]["uboToObjComp"]["freqTrnsct"]
            );

            formErrorsChapter4.uboToObjComp.sizeTrnsct = isFieldEmpty(
              commonFields["srcFundsObjComp"]["uboToObjComp"]["sizeTrnsct"]
            );

            formErrorsChapter4.uboToObjComp.typeTrnsct = isFieldEmpty(
              commonFields["srcFundsObjComp"]["uboToObjComp"]["typeTrnsct"]
            );
          }
          if (commonFields["srcFundsObjComp"]["isUBOInvstObjComp"] === "No") {
            formErrorsChapter4.objCompToUBO = {
              typeTrnsct: "",
              sizeTrnsct: "",
              freqTrnsct: "",
            };
            formErrorsChapter4.uboToObjComp = {
              typeTrnsct: "",
              sizeTrnsct: "",
              freqTrnsct: "",
            };
          }
          if (commonFields["srcFundsObjComp"]["isUPCfundObjComp"] === "Yes") {
            formErrorsChapter4.objCompToGrpComp = {
              typeTrnsct: "",
              sizeTrnsct: "",
              freqTrnsct: "",
            };
            formErrorsChapter4.grpCompToObjComp = {
              typeTrnsct: "",
              sizeTrnsct: "",
              freqTrnsct: "",
            };
            formErrorsChapter4.objCompToGrpComp.freqTrnsct = isFieldEmpty(
              commonFields["srcFundsObjComp"]["objCompToGrpComp"]["freqTrnsct"]
            );

            formErrorsChapter4.objCompToGrpComp.sizeTrnsct = isFieldEmpty(
              commonFields["srcFundsObjComp"]["objCompToGrpComp"]["sizeTrnsct"]
            );

            formErrorsChapter4.objCompToGrpComp.typeTrnsct = isFieldEmpty(
              commonFields["srcFundsObjComp"]["objCompToGrpComp"]["typeTrnsct"]
            );

            formErrorsChapter4.grpCompToObjComp.freqTrnsct = isFieldEmpty(
              commonFields["srcFundsObjComp"]["grpCompToObjComp"]["freqTrnsct"]
            );

            formErrorsChapter4.grpCompToObjComp.sizeTrnsct = isFieldEmpty(
              commonFields["srcFundsObjComp"]["grpCompToObjComp"]["sizeTrnsct"]
            );

            formErrorsChapter4.grpCompToObjComp.typeTrnsct = isFieldEmpty(
              commonFields["srcFundsObjComp"]["grpCompToObjComp"]["typeTrnsct"]
            );
          }
          if (commonFields["srcFundsObjComp"]["isUPCfundObjComp"] === "No") {
            formErrorsChapter4.objCompToGrpComp = {
              typeTrnsct: "",
              sizeTrnsct: "",
              freqTrnsct: "",
            };
            formErrorsChapter4.grpCompToObjComp = {
              typeTrnsct: "",
              sizeTrnsct: "",
              freqTrnsct: "",
            };
          }
          if (commonFields["srcFundsObjComp"]["isTpInvstObjComp"] === "Yes") {
            formErrorsChapter4.objCompToTP = {
              typeTrnsct: "",
              sizeTrnsct: "",
              freqTrnsct: "",
            };
            formErrorsChapter4.tpToObjComp = {
              typeTrnsct: "",
              sizeTrnsct: "",
              freqTrnsct: "",
            };
            formErrorsChapter4.objCompToTP.freqTrnsct = isFieldEmpty(
              commonFields["srcFundsObjComp"]["objCompToTP"]["freqTrnsct"]
            );
            formErrorsChapter4.objCompToTP.sizeTrnsct = isFieldEmpty(
              commonFields["srcFundsObjComp"]["objCompToTP"]["sizeTrnsct"]
            );
            formErrorsChapter4.objCompToTP.typeTrnsct = isFieldEmpty(
              commonFields["srcFundsObjComp"]["objCompToTP"]["typeTrnsct"]
            );
            formErrorsChapter4.tpToObjComp.freqTrnsct = isFieldEmpty(
              commonFields.srcFundsObjComp.tpToObjComp.freqTrnsct
            );
            formErrorsChapter4.tpToObjComp.sizeTrnsct = isFieldEmpty(
              commonFields.srcFundsObjComp.tpToObjComp.sizeTrnsct
            );
            formErrorsChapter4.tpToObjComp.typeTrnsct = isFieldEmpty(
              commonFields.srcFundsObjComp.tpToObjComp.typeTrnsct
            );
          }
          if (commonFields.srcFundsObjComp.isTpInvstObjComp === "No") {
            formErrorsChapter4.objCompToTP = {
              typeTrnsct: "",
              sizeTrnsct: "",
              freqTrnsct: "",
            };
            formErrorsChapter4.tpToObjComp = {
              typeTrnsct: "",
              sizeTrnsct: "",
              freqTrnsct: "",
            };
          }
        }
        formErrorsChapter4.bnkInfo = isFieldEmpty(commonFields.bnkInfo);
        formErrorsChapter4.agremntsGrUpEntities = isFileEmpty(
          data.ovrvwBnkAC.agremntsGrUpEntities
        );
        formErrorsChapter4.agremntsOthrParties = isFileEmpty(
          data.ovrvwBnkAC.agremntsOthrParties
        );
        formErrorsChapter4.financialStmntsObjComp = isFileEmpty(
          data.ovrvwBnkAC.financialStmntsObjComp
        );
        formErrorsChapter4.finanicalStmntsAllPrticipatns = isFileEmpty(
          data.ovrvwBnkAC.finanicalStmntsAllPrticipatns
        );
        formErrorsChapter4.financialStmntsParntComp = isFileEmpty(
          data.ovrvwBnkAC.financialStmntsParntComp
        );
        return { formErrorsChapter4 };
      }
      case "chapter5": {
        let formErrorsChapter5 = this.state.formErrorschapter5;
        formErrorsChapter5.countryList = isFieldEmpty(data.countryList);
        return { formErrorsChapter5 };
      }
      case "chapter6": {
        let formErrorsChapter6 = this.state.formErrorschapter6;
        formErrorsChapter6.isExternalDirectors = isFieldEmpty(
          data.isExternalDirectors
        );
        if (data.isExternalDirectors === "Yes") {
          formErrorsChapter6.isExternalDirectorsNaturalPerson = isFieldEmpty(
            data.isExternalDirectorsNaturalPerson
          );
          if (data.isExternalDirectorsNaturalPerson === "Yes") {
            formErrorsChapter6.nameOfNaturalPerson = isFieldEmpty(
              data.nameOfNaturalPerson
            );
            formErrorsChapter6.nationality = isFieldEmpty(data.nationality);
          }
          if (data.isExternalDirectorsNaturalPerson === "No") {
            formErrorsChapter6.nameOfNaturalPerson = "";
            formErrorsChapter6.nationality = "";
          }
        }
        if (data.isExternalDirectors === "No") {
          formErrorsChapter6.isExternalDirectorsNaturalPerson = "";
          formErrorsChapter6.nameOfNaturalPerson = "";
          formErrorsChapter6.nationality = "";
        }
        formErrorsChapter6.isOneOrMoreExtDirectorsAuthorized = isFieldEmpty(
          data.isOneOrMoreExtDirectorsAuthorized
        );
        return { formErrorsChapter6 };
      }
      default:
        return null;
    }
  };
}

const Validations = new FormValidations();
export default Validations;
