import React, { Component } from "react";
import { Row } from "react-bootstrap";
import countryList from "react-select-country-list";
import Select from "react-select";
import { connect } from "react-redux";
import { setDataToReducer } from "../../../../../../redux/actions/ClientInformationAction";
import { getReducer } from "../../../../../elements/getReducer";
const chapter = "chapter5";

class Chapter5 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleMultiSelectionChange = (value) => {
    this.props.setDataToReducer({
      type: getReducer(this.props.reducer),
      chapter: chapter,
      name: "countryList",
      value: value,
    });
  };

  render() {
    const { errorMsg } = this.props;
    // const eMsg = {};
    const eMsg = JSON.parse(errorMsg);
    return (
      <div className="generic-form client-investigation mt-4">
        <h5 className="mb-3 mt-2">          
          {this.props.form === "NL"
              ? "5- Client Acceptance – Country /Sanctions Risks"
              :"Client Acceptance – Country /Sanctions Risks"}
         
        </h5>
        <Row className="pt-3 pb-2">
          <div className="mb-2 col-lg-4">
            <label>{`Which countries involved in the ${
              this.props.form === "Malta" || this.props.form === "NL" ? "" : "client"
            } structure?`}</label>
            {this.props.form === "UK" && <span style={{ color: "red" }}>*</span>}
            <Select
              options={countryList().getData()}
              isMulti={true}
              value={this.props.countryList}
              id="countryList"
              onChange={this.handleMultiSelectionChange}
              reducer={this.props.reducer}
             />
            {eMsg && eMsg?.countryList && eMsg.countryList?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.countryList}
              </span>
            )}
          </div>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer } = ownProps;
  return {
    countryList: state[reducer]["chapter5"]["countryList"],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Chapter5);
