import React, { Component } from "react";
import { withRouter } from "react-router";
import AutoSaving from "./AutoSaving";
import { updateClientInformation } from "../../../../../redux/actions/CAFGenericActions";
import {
  setCommonFields,
  getCommonFields,
  setNewCommonFieldsToReducer,
} from "../../../../../redux/actions/CommonFieldsActions";
import { NotificationManager } from "react-notifications";
import {
  getCAFDetails,
  setCAFDetails,
  setNewDataToReducer,
} from "../../../../../redux/actions/ClientInformationAction";
import { connect } from "react-redux";
import Chapter1 from "./Chapter1/Chapter1";
import Chapter2 from "./Chapter2/Chapter2";
import Chapter3 from "./Chapter3/Chapter3";
import Chapter4 from "./Chapter4/Chapter4";
import Chapter5 from "./Chapter5/Chapter5";
import Chapter6 from "./Chapter6/Chapter6";
import { Row } from "react-bootstrap";
import Validation from "./FormValidation";
import {
  globalStateChanged,
  setClientInfoFormDataToReducer,
  getGenericDetails,
} from "../../../../../redux/actions/CAFGenericActions";
import { getFirstUnfilledFieldExternal } from "../UKOffice/getFirstUnfilledField";
import getSequentialFields from "./getSequentialFields";

const reducer = "clientInformation";
const form = "NL";
const formValid = (formErrors) => {
  let valid = true;
  Object.values(formErrors).forEach(function (val) {
    if (Array.isArray(val)) {
      val.forEach(function (errors) {
        Object.values(errors).forEach(function (val) {
          val.length > 0 && (valid = false);
        });
      });
    } else {
      val.length > 0 && (valid = false);
    }
  });
  return valid;
};
class ClientInformationDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptionUBO: "",
      selectedOptionPEP: "",
      formErrorsChapter1: {},
      formErrorsChapter2: {},
      formErrorsChapter3: {},
      formErrorsChapter4: {},
      formErrorsChapter5: {},
      formErrorsChapter6: {},
      isUpdate: "",
      isValid: false,
      firstFields: null,
    };
  }

  componentDidMount = () => {
    let data = {
      Id: localStorage.getItem("formId"),
      Chapter: "all",
    };
    this.props.globalStateChanged();
    if (data.Id !== "null") {
      this.props
        .getGenericDetails({
          id: data.Id,
        })
        .then((res) => {
          if (res.data.portalErrorCode === 200) {
            this.setState({
              selectedOptionUBO: res.data.result.genericDetails.isUBO,
              selectedOptionPEP: res.data.result.genericDetails.isPEP,
            });
          }
        })
        .catch(this.catchError);
    }
    this.props
      .getCAFDetails(data)
      .then((res) => {
        if (res.data.portalErrorCode === 200) {
        }
      })
      .catch(this.catchError);
    this.props
      .getCommonFields(localStorage.getItem("formId"))
      .then((res) => {
        if (res.data.portalErrorCode === 200) {
        }
      })
      .catch(this.catchError);
  };

  handleSubmit = async () => {
    let requestData = {};
    for (let [chapterNumber, data] of Object.entries(
      this.props.clientInformation
    )) {
      delete data["dirty"];
      requestData[chapterNumber] = data;
    }
    let response = await Validation.ValidateClientInformation(
      requestData,
      this.props.commonFields
    );

    this.setState({
      formErrorsChapter1: response.formErrors.chapter1.formErrorsChapter1,
      formErrorsChapter2: response.formErrors.chapter2.formErrorsChapter2,
      formErrorsChapter3: response.formErrors.chapter3.formErrorsChapter3,
      formErrorsChapter4: response.formErrors.chapter4.formErrorsChapter4,
      formErrorsChapter5: response.formErrors.chapter5.formErrorsChapter5,
      formErrorsChapter6: response.formErrors.chapter6.formErrorsChapter6,
    });
    if (
      formValid(this.state.formErrorsChapter1) &&
      formValid(this.state.formErrorsChapter2) &&
      formValid(this.state.formErrorsChapter3) &&
      formValid(this.state.formErrorsChapter4) &&
      formValid(this.state.formErrorsChapter5) &&
      formValid(this.state.formErrorsChapter6)
    ) {
      this.setState({ isValid: true });
      requestData["Id"] = localStorage.getItem("formId");
      requestData["IsSubmitted"] = true;

      this.props.setCAFDetails(requestData).then((res) => {
        if (res.data.portalErrorCode === 200) {
          let details = {
            Id: localStorage.getItem("formId"),
            lastActivityName: "ubo-status-list",
            status: "In Progress",
            activeLinkLevel: 2,
          };
          this.props.setClientInfoFormDataToReducer(2);
          this.props.updateClientInformation(details);
          for (let [chapterNumber, data] of Object.entries(
            this.props.clientInformation
          )) {
            data["dirty"] = false;
          }
          NotificationManager.success("Client information added successfully");
          this.props.setNewDataToReducer(this.props.clientInformation);
          window.scrollTo(0, 0);
          return this.props.history.push({
            pathname: "/ubo-status-list",
          });
        }
      });

      let data = this.props.commonFields;
      delete data["dirty"];
      data["Id"] = localStorage.getItem("formId");

      this.props.setCommonFields(data);
    } else {
      this.setState({ isValid: false });
      let fields = [];
      const newErrorsChapter1 = await getSequentialFields(
        this.state.formErrorsChapter1,
        "chapter1"
      );
      const firstFieldsChapter1 = await getFirstUnfilledFieldExternal(
        newErrorsChapter1?.errorChapter
      );
      if (firstFieldsChapter1) {
        fields.push(firstFieldsChapter1);
      }
      
      const newErrorsChapter2 = await getSequentialFields(
        this.state.formErrorsChapter2,
        "chapter2"
      );
      const firstFieldsChapter2 = await getFirstUnfilledFieldExternal(
        newErrorsChapter2?.errorChapter
      );
      if (firstFieldsChapter2) {
        fields.push(firstFieldsChapter2);
      }
      const newErrorsChapter3 = await getSequentialFields(
        this.state.formErrorsChapter3,
        "chapter3"
      );
      const firstFieldsChapter3 = await getFirstUnfilledFieldExternal(
        newErrorsChapter3?.errorChapter
      );
      if (firstFieldsChapter3) {
        fields.push(firstFieldsChapter3);
      }
      const newErrorsChapter4 = await getSequentialFields(
        this.state.formErrorsChapter4,
        "chapter4"
      );
      const firstFieldsChapter4 = await getFirstUnfilledFieldExternal(
        newErrorsChapter4?.errorChapter
      );
      if (firstFieldsChapter4) {
        fields.push(firstFieldsChapter4);
      }
      const newErrorsChapter5 = await getSequentialFields(
        this.state.formErrorsChapter5,
        "chapter5"
      );
      const firstFieldsChapter5 = await getFirstUnfilledFieldExternal(
        newErrorsChapter5?.errorChapter
      );
      if (firstFieldsChapter5) {
        fields.push(firstFieldsChapter5);
      }
      const newErrorsChapter6 = await getSequentialFields(
        this.state.formErrorsChapter6,
        "chapter6"
      );
      const firstFieldsChapter6 = await getFirstUnfilledFieldExternal(
        newErrorsChapter6?.errorChapter
      );
      if (firstFieldsChapter6) {
        fields.push(firstFieldsChapter6);
      }
      this.setState({ firstFields: fields[0] ? fields[0] : null });
      // window.scrollTo(0, 0);
      if (fields[0] !== null && fields.length > 0) {
        document.getElementById(fields[0]).scrollIntoView({
          behavior: "smooth",
        });
      }
      NotificationManager.error("Please fill the mandatory fields");
    }
  };
  render() {
    const formErrorsChapter1 = JSON.stringify(this.state.formErrorsChapter1);
    const formErrorsChapter2 = JSON.stringify(this.state.formErrorsChapter2);
    const formErrorsChapter3 = JSON.stringify(this.state.formErrorsChapter3);
    const formErrorsChapter4 = JSON.stringify(this.state.formErrorsChapter4);
    const formErrorsChapter5 = JSON.stringify(this.state.formErrorsChapter5);
    const formErrorsChapter6 = JSON.stringify(this.state.formErrorsChapter6);

    return (
      <>
        <AutoSaving />
        <div className="dashboard external-dashboard">
          <Chapter1
            reducer={reducer}
            errorMsg={formErrorsChapter1}        
            form={form}
            from="NL"
          />
          <Chapter2
            reducer={reducer}
            errorMsg={formErrorsChapter2}
            form={form}
          />
          <Chapter3
            reducer={reducer}
            errorMsg={formErrorsChapter3}
            form={form}
          />
          <Chapter4
            reducer={reducer}
            errorMsg={formErrorsChapter4}
            form={form}
          />
          <Chapter5
            reducer={reducer}
            errorMsg={formErrorsChapter5}
            form={form}
          />
          <Chapter6
            reducer={reducer}
            errorMsg={formErrorsChapter6}
            form={form}
          />
          {/* {this.state.isValid == false && this.state.firstFields !== null && (
            <div className="internalErrorSection">
              <HashLink to={`/client-information#${this.state.firstFields}`}>
                Unfilled fields
              </HashLink>
            </div>
          )} */}
          <Row className="pt-3 pb-2">
            <div className="mb-2 col-lg-12">
              <button
                onClick={this.handleSubmit}
                type="button"
                className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
              >
                Proceed to Next
              </button>
            </div>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientInformation: state.clientInformation,
    commonFields: state.commonFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCAFDetails: (data) => dispatch(setCAFDetails(data)),
    setNewDataToReducer: (data) => dispatch(setNewDataToReducer(data)),
    getCAFDetails: (data) => dispatch(getCAFDetails(data)),
    globalStateChanged: () => dispatch(globalStateChanged()),
    updateClientInformation: (data) => dispatch(updateClientInformation(data)),
    setClientInfoFormDataToReducer: (data) =>
      dispatch(setClientInfoFormDataToReducer(data)),
    getGenericDetails: (data) => dispatch(getGenericDetails(data)),
    setCommonFields: (data) => dispatch(setCommonFields(data)),
    getCommonFields: (data) => dispatch(getCommonFields(data)),
    setNewCommonFieldsToReducer: (data) =>
      dispatch(setNewCommonFieldsToReducer(data)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClientInformationDashboard)
);
