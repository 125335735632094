import { GET_CLIENT_BY_ID_SUCCESS } from "../constants/clientConstants";
import {
  GET_CLIENT_FORM_LIST_FAIL,
  GET_CLIENT_FORM_LIST_REQUEST,
} from "../constants/clientFormListConstants";

const initialState = {
  isLoading: false,
  error: "",
  clientForms: [],
};

const ClientFormListReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CLIENT_FORM_LIST_REQUEST:
      return { ...state, isLoading: true };
    case GET_CLIENT_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: "",
      };
    case GET_CLIENT_FORM_LIST_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default ClientFormListReducer;
