export const CustActivityRisks = {
  risk: null,
  riskType: null,
  riskLevel: null,
  preventiveMeasure: null,
  isMeasrResLowRtngRsk: null,
};

export const AdditionDocs = {
  addDocUpload: [],
  addDocExplain: null,
};

export const Chapter1 = {
  structureChart: [],
  structureChartComm: null,
  isInLineUIMAccPolicy: null, 
  isMediaCoverageOnStruct: null,
  isAddDocs: null,
  numAddDocs: 1,
  addDocs: [AdditionDocs],
  isRisk: null,
  numCustActivityRisks: 1,
  custActivityRisks: [CustActivityRisks],
  custActvtyRskRtng: null,
  descActnNeedsTkn: null,
  isUIMnoResonDiscntnuBusRel: null,
  reportOnActivities: null,
  dirty: false,
};
