import React, { Component } from "react";
import InformationOfUBO from "./InformationOfUBO";
import UBOs from "./UBOs";
import ObjectCompanyDetails from "./ObjectCompanyDetails";
import UPC from "../../client-information/Chapter3/UPC";
import BearerShares from "../../client-information/Chapter4/BearerShares";
import NomineeAgreement from "./NomineeAgreement";
import EffectiveControlOfUBO from "./EffectiveControlOfUBO";
import UboUpcQuestions from "./UboUpcQuestions";
import PEPs from "./PEPs";
import MaltaUBOPEPDetails from "./MaltaUBOPEPDetails";
import { connect } from "react-redux";

const chapter = "partA";
class PartA extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isUBO, isPEP, isUBOPEP, eMsg, isUPCExists } = this.props;
    const errorMsg = JSON.parse(eMsg);

    return (
      <>
        <div className="eventHeader pb-3">
          {isUBO === "Yes" && isUBOPEP === "Yes" && (
            <h2 className="m-0 p-0 pagetitle">UBO & PEP Declaration</h2>
          )}
          {isUBO === "Yes" && isUBOPEP === "No" && (
            <h2 className="m-0 p-0 pagetitle">UBO Declaration</h2>
          )}
          {isUBO === "No" && isPEP === "Yes" && (
            <h2 className="m-0 p-0 pagetitle">PEP Declaration</h2>
          )}
        </div>
        <InformationOfUBO />
        {isUBO === "Yes" && (
          <>
            <h4 className="sub-chapter-title">
              UBO (Ultimate beneficial ownership)
            </h4>
            <UBOs
              reducer={this.props.reducer}
              objBaseName="uboDetails"
              chapter={chapter}
              form={this.props.form}
              //  baseName="uboDetails"
              errorMsg={errorMsg}
            />
          </>
        )}
        {isUBO === "No" && isPEP === "Yes" && (
          <>
            <h4 className="sub-chapter-title">
              PEP (Politically exposed person)
            </h4>
            <PEPs
              reducer={this.props.reducer}
              objBaseName="pepDetails"
              chapter={chapter}
              form={this.props.form}
              errorMsg={errorMsg}
            />
          </>
        )}
        {this.props.form === "Malta" && (
          <MaltaUBOPEPDetails
            reducer={this.props.reducer}
            objBaseName="maltaUBOPEPDetails"
            chapter={chapter}
            form={this.props.form}
            errorMsg={errorMsg}
          />
        )}

        <h4 className="sub-chapter-title">
          {this.props.form === "Malta" ? "Target Company" : "Object Company"}
        </h4>
        <ObjectCompanyDetails
          reducer={this.props.reducer}
          objBaseName="objCompDetails"
          form={this.props.form}
          chapter={chapter}
          errorMsg={eMsg}
        />
        <div className="html2pdf__page-break"></div>
        {isUPCExists === "Yes" && (
          <>
            <h4 className="pdf-title">UPC(Ultimate parent Company)</h4>
            <UPC
              reducer="commonFields"
              objBaseName="upc"
              form={this.props.form}
            />
          </>
        )}

        {this.props.form === "NL" && (
          <BearerShares
            reducer={"commonFields"}
            chapter={chapter}
            form={this.props.form}
            errorMsg={eMsg}
          />
        )}

        <NomineeAgreement
          reducer={this.props.reducer}
          chapter={chapter}
          errorMsg={errorMsg}
        />
        <EffectiveControlOfUBO
          reducer={this.props.reducer}
          chapter={chapter}
          errorMsg={errorMsg}
          form={this.props.form}
        />
        <UboUpcQuestions
          reducer={this.props.reducer}
          chapter={chapter}
          errorMsg={errorMsg}
          form={this.props.form}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isUPCExists: state.commonFields["isUPCExists"],
  };
};

export default connect(mapStateToProps, null)(PartA);
