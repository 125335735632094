import React, { Component } from "react";
import DeclarationSourceofWealth from "./DeclarationSourceofWealth";
import GrossAnnualIncome from "./GrossAnnualIncome";
import RangeOfWealth from "./RangeOfWealth";
import SourceOfWealth from "./SourceOfWealth";

const chapter = "partB";
class PartB extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const eMsg = JSON.parse(this.props.errorMsg);
    return (
      <>
        <div className="html2pdf__page-break"></div>
        <h2 className="chapter-title mt-4">
          Declaration of the source of wealth of the UBO
        </h2>
        <GrossAnnualIncome
          reducer={this.props.reducer}
          chapter={chapter}
          errorMsg={eMsg}
        />
        <RangeOfWealth
          reducer={this.props.reducer}
          chapter={chapter}
          errorMsg={eMsg}
        />
        <SourceOfWealth
          reducer={this.props.reducer}
          chapter={chapter}
          errorMsg={this.props.errorMsg}
        />
        <DeclarationSourceofWealth
          chapter={chapter}
          reducer={this.props.reducer}
          errorMsg={eMsg}
        />
      </>
    );
  }
}

export default PartB;
