const initialState = {
  data: {},
  generic: {
    isUBO: "",
    isPEP: "",
    dirty: false,
  },
};

const GenericDetailForReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "UPDATE_GENERIC_DETAILS":
      const { name, value, chapter } = action.data;
      return {
        ...state,
        [chapter]: {
          ...state[chapter],
          [name]: value,
          dirty: true,
        },
      };

    case "GET_GENERIC_DETAILS_SUCCESS":
      let response = action.data.result;
      let res =
        response === null ? state.generic : action.data.result.genericDetails;
      res["dirty"] = false;
      return {
        ...state,
        generic: res,
      };

    case "UPDATE_NEW_GENERIC_DATA":
      let data = action.data;
      data["dirty"] = false;
      return {
        ...state,
        generic: data,
      };

    case "UPDATE_GENERIC_REDUCER_DATA":
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};

export default GenericDetailForReducer;
