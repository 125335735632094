import React, { Component } from "react";
import { Redirect, Switch } from "react-router-dom";

import InternalUserRoutes from "../../../../routes/InternalUserRoutes";

import Chapter1 from "../Onboarding/Chapter1/Chapter1";
import Chapter2 from "../Onboarding/Chapter2/Chapter2";
import Chapter3 from "../Onboarding/Chapter3/Chapter3";
import Chapter4 from "../Onboarding/Chapter4/Chapter4";
import Chapter5 from "../Onboarding/Chapter5/Chapter5";
import Chapter6 from "../Onboarding/Chapter6/Chapter6";
import Chapter8 from "../Onboarding/Chapter8/Chapter8";
import Chapter9 from "../Onboarding/Chapter9/Chapter9";
import Chapter10 from "../Onboarding/Chapter10/Chapter10";
import Chapter11 from "../Onboarding/Chapter11/Chapter11";
import Chapter12 from "../Onboarding/Chapter12/Chapter12";

import Chapter13 from "../Onboarding/Chapter13/Chapter13";

class InternalDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  handleChange(e) {
    this.props.handleOfficeChange(e);
  }
  render() {
    // setInterv
    return (
      <Switch>
        <Redirect exact from="/" to="/netherlands/client-activity" />
        <InternalUserRoutes
          path="/netherlands/client-activity"
          component={Chapter1}
        />

        <InternalUserRoutes
          path="/netherlands/client-investigation"
          component={Chapter2}
        />
        <InternalUserRoutes path="/netherlands/ubo-upc" component={Chapter3} />
        <InternalUserRoutes
          path="/netherlands/object-company"
          component={Chapter4}
        />
        <InternalUserRoutes
          path="/netherlands/country-sanction-risks"
          component={Chapter5}
        />
        <InternalUserRoutes
          path="/netherlands/agreement"
          component={Chapter6}
        />
        <InternalUserRoutes
          path="/netherlands/consolidated-risk-assessment"
          component={Chapter8}
        />
        <InternalUserRoutes
          path="/netherlands/correspondence"
          component={Chapter9}
        />
        <InternalUserRoutes
          path="/netherlands/miscellaneous"
          component={Chapter10}
        />
        <InternalUserRoutes
          path="/netherlands/summary-sheet-caf"
          component={Chapter11}
        />
        <InternalUserRoutes
          path="/netherlands/formal-sign-off"
          component={Chapter12}
        />
        <InternalUserRoutes
          path="/netherlands/signature"
          component={Chapter13}
        />
      </Switch>
    );
  }
}

export default InternalDashboard;
