import React, { Component } from "react";
import FileUpload from "../../../../../elements/FileUpload";
import { Row } from "react-bootstrap";
import DropDown from "../../../../../elements/DropDown";
import { peerEntities, fileAcceptType, EntityType } from "./Constants";
import { connect } from "react-redux";

const chapter = "chapter1";
class PeerEntities extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, baseName, entity, errorMsg } = this.props;
    
    return (
      <div className="mb-4 col-lg-12">
        <Row>
          <fieldset className="position-relative">
            <span className="impNote">
              Document should not older than 3 months
            </span>
            <legend>Entity {index + 1} in the chart</legend>
            {this.props.form === "UK" && (
              <div className="row pb-2">
                <div class="mb-3 col-lg-6">
                  <DropDown
                    label="Entity Type"
                    options={EntityType}
                    name="entityType"
                    id={`entityType[${index}]`}
                    chapter={chapter}
                    index={index}
                    baseName={baseName}
                    reducer={this.props.reducer}
                    isRequired={this.props.form === "UK" ? true : false} 
                  />
                  {errorMsg !== null &&
                    Object.entries(errorMsg).length !== 0 &&
                    errorMsg.entities.length !== 0 &&
                    errorMsg?.entities[index] &&
                    errorMsg?.entities[index].entityType &&
                    errorMsg?.entities[index].entityType?.length > 0 && (
                      <div className="errorMsg col-md-12">
                        {errorMsg?.entities[index]["entityType"]}
                      </div>
                    )}
                </div>
                {entity.entityType === "Trust" && (
                  <div class="mb-3 col-lg-6">
                    <FileUpload
                      label="Upload Trust Documents"
                      name="trustDocs"
                      id={`trustDocs[${index}]`}
                      chapter={chapter}
                      index={index}
                      baseName={baseName}
                      reducer={this.props.reducer}
                      acceptType={fileAcceptType}
                      isRequired={this.props.form === "UK" ? true : false} 
                    />
                    {errorMsg !== null &&
                      Object.entries(errorMsg).length !== 0 &&
                      errorMsg.entities.length !== 0 &&
                      errorMsg?.entities &&
                      errorMsg?.entities[index] &&
                      errorMsg?.entities[index].trustDocs &&
                      errorMsg?.entities[index].trustDocs?.length > 0 && (
                        <div className="errorMsg col-md-12">
                          {errorMsg?.entities[index]["trustDocs"]}
                        </div>
                      )}
                  </div>
                )}
                {entity.entityType === "Other" && (
                  <div class="mb-3 col-lg-6">
                    <FileUpload
                      label="Upload Other Relevent Documents"
                      name="otherDocs"
                      id={`otherDocs[${index}]`}
                      chapter={chapter}
                      index={index}
                      baseName={baseName}
                      reducer={this.props.reducer}
                      acceptType={fileAcceptType}
                      isRequired={this.props.form === "UK" ? true : false} 
                    />
                    {errorMsg !== null &&
                      Object.entries(errorMsg).length !== 0 &&
                      errorMsg.entities.length !== 0 &&
                      errorMsg?.entities &&
                      errorMsg?.entities[index] &&
                      errorMsg?.entities[index].otherDocs &&
                      errorMsg?.entities[index].otherDocs?.length > 0 && (
                        <div className="errorMsg col-md-12">
                          {errorMsg?.entities[index]["otherDocs"]}
                        </div>
                      )}
                  </div>
                )}
              </div>
            )}
            <div className="row">
              {peerEntities.map((entity, i) => {
                return (
                  <>
                    <div className="col-lg-6">
                      <div class="form-group files">
                        <FileUpload
                          name={entity.name}
                          info={entity.info}
                          label={entity.label}
                          id={`${entity.name}[${index}]`}
                          acceptType={fileAcceptType}
                          index={index}
                          baseName={baseName}
                          chapter={chapter}
                          reducer={this.props.reducer}
                        />
                        {errorMsg !== null &&
                          errorMsg?.entities &&
                          errorMsg?.entities[index] &&
                          errorMsg?.entities[index][entity.name] &&
                          errorMsg?.entities[index][entity.name]?.length >
                            0 && (
                            <div className="errorMsg col-md-12">
                              {errorMsg?.entities[index][entity.name]}
                            </div>
                          )}

                        {/* {errorMsg !==null &&
                        Object.entries(errorMsg).length !== 0 &&
                        errorMsg.entries.length !== 0 &&
                        entity.name === "article" &&
                        errorMsg?.entries[index].article?.length > 0 && (
                          <div className="errorMsg col-md-12">
                            {errorMsg?.entries[index].article}
                          </div>
                        )}

                      {errorMsg !==null &&
                        Object.entries(errorMsg).length !== 0 &&
                        errorMsg.entries.length !== 0 &&
                        entity.label == "Financial Statement" &&
                        errorMsg?.entries[index].finStat.length > 0 && (
                          <div className="errorMsg col-md-12">
                            {errorMsg?.entries[index].finStat}
                          </div>
                        )}

                      {errorMsg !==null &&
                        Object.entries(errorMsg).length !== 0 &&
                        errorMsg.entries.length !== 0 &&
                        entity.label == "Shareholders Register" &&
                        errorMsg?.entries[index].shareholderRegister.length >
                          0 && (
                          <div className="errorMsg col-md-12">
                            {errorMsg?.entries[index].shareholderRegister}
                          </div>
                        )} */}
                      </div>
                    </div>
                    {/* {i % 2 !== 0 && <div className="col-lg-6"></div>} */}
                  </>
                );
              })}
            </div>
          </fieldset>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer, index } = ownProps;
  return {
    entity: state[reducer]["chapter1"]["entities"][index],
  };
};

export default connect(mapStateToProps, null)(PeerEntities);
