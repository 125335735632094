const UBOs = {
  uboId: null,
  dob: null,
  pob: null,
  cob: null,
  nationality: null,
  multiNationality: [],
  maritalStatus: null,
  passportNum: null,
  pvtAddress: null,
  uboPostalCode: null,
  uboCity: null,
  uboCountry: null,
  fax: null,
  occupation: null,
  curriculumVitae: null,
  proofOfAddress: null,
};

const PEPs = {
  pepId: null,
  dob: null,
  pob: null,
  cob: null,
  nationality: null,
  multiNationality: [],
  maritalStatus: null,
  pepPassportNum: null,
  passportUpload: [],
  pvtAddress: null,
  uboPostalCode: null,
  uboCity: null,
  uboCountry: null,
  fax: null,
  occupation: null,
  curriculumVitae: null,
  proofOfAddress: null,
};

const maltaUBOPEPDetails = {
  civilStatus: null,
  tinNumber: null,
  tax: null,
  usTiesPast: null,
  usTiesPersonal: null,
  hasTaxAdvice: null,
  convCriminalofnce: null,
  citizspInvsmtScheme: null,
  dualCitizenship: null,
  residency: [],
  actingThirdParties: null,
  isDirIndirOwner: null,
  indirectEntity: null,
  perctgShareHeld: null,
};

export const PartA = {
  uboDetails: UBOs,
  pepDetails: PEPs,
  maltaUBOPEPDetails: maltaUBOPEPDetails,
  isNomineeAgrmntUBO: null,
  nomineeAgrmntUBO: [],
  perIntrstUBOinUPC: null,
  perIntrstBeneficiary: null,
  uboEfctvCtrlMngmntLglEntity: null,
  proofOfTaxRes: [],
  reasonUboEfctvCtrlMngmntLglEntity: null,
  isUBOEntrstdPEP: null,
  isUBOCnctedPEP: null,
  isUBOAssociatePEP: null,
  isUBOForceCloseFinAcc: null,
  uboForceCloseFinAcc: null,
  isUPCForceCloseFinAcc: null,
  upcForceCloseFinAcc: null,
  isUBOActingOnSelfBehalf: null,
  uboNotActingOnSelfBehalf: null,
  isUBOInvlvdDoubtfulFinancng: null,
  uboInvlvdDbtfulFinancng: null,
  isOthrCrmstncsNegImpct: null,
  othrCrmstncsNegImpct: null,
};
