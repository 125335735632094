import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { setDataToReducer } from "../../../../../redux/actions/ClientInformationAction";
import TextBox from "../../../../elements/TextBox";
class RightsNdObligations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: "Right and Obligations",
          selector: (row) => row.obligation,
          sortable: true,
        },

        {
          name: "Counterparty",
          selector: (row) => row.counterparty,
          sortable: true,
        },
        {
          name: "Currency",
          selector: (row) => row.currency,
        },
        {
          name: "Amount",
          selector: (row) => row.amount,
          sortable: true,
        },
        {
          name: "Purpose",
          selector: (row) => row.purpose,
        },
        {
          name: "Integrity risk",
          cell: (row) => (
            <>
              <TextBox
                name="integrityRsk"
                id="integrityRsk"
                placeholder="Enter risk"
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                baseName="rightsAndOblgtnsRsk"
                validationType="Text"
                isRequired={false}
                index={Number(row.id) - 1}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </>
          ),
        },
      ],
    };
  }

  render() {
    const { isRequired } = this.props;
    const errorsMsg = JSON.parse(this.props.errors);
    return (
      <>
        <h4 className="sub-chapter-title pt-5 mb-4">
          Rights and Obligations of the Object Company
        </h4>
        <div className="pt-2 pb-2 internalro" id="rightsAndOblgtnsRsk">
          <DataTable
            id="rightsAndOblgtnsRsk"
            title="Describe the rights and obligations of the Object Company"
            columns={this.state.columns}
            data={[...this.props.rightsAndObligations]}
          />
          {errorsMsg &&
            errorsMsg?.isSubmitted === true &&
            errorsMsg?.rightsAndOblgtnsRsk?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errorsMsg.rightsAndOblgtnsRsk}
              </span>
            )}
        </div>
        <div className="mb-2 col-lg-12 pl-0">
          <TextBox
            label="Please add comment"
            placeholder="Enter your text"
            name="rightsAndOblgtnsCmmnt"
            id="rightsAndOblgtnsCmmnt"
            chapter={this.props.chapter}
            reducer={this.props.reducer}
            isRequired={isRequired}
            validationType="Text"
            className={
              this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
            }
          />
          {errorsMsg &&
            errorsMsg?.isSubmitted === true &&
            errorsMsg?.rightsAndOblgtnsCmmnt?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errorsMsg.rightsAndOblgtnsCmmnt}
              </span>
            )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { form, externalReducer, chapter } = ownProps;
  if (form === "NL") {
    return {
      rightsAndObligations:
        state.commonFields.srcFundsObjComp["rightsAndObligations"],
    };
  } else {
    return {
      rightsAndObligations:
        state[externalReducer][chapter].srcFundsObjComp["rightsAndObligations"],
    };
  }
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RightsNdObligations);
