import React, { Component } from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { InternalHeader } from "../header/InternalHeader";
import InternalUserRoutes from "../../../../routes/InternalUserRoutes";
import userlist from "../../../components/user/userlist";
import userApprovals from "../../../components/user/userApprovals";
import clientlist from "../../../components/client/clientlist";
import clientApprovals from "../../../components/client/clientApprovals";
import clientFormList from "../Onboarding/client-form-list/clientFormList";
import addUser from "../../../components/user/addUser";
import addClient from "../../../components/client/addClient";
import SidebarSelection from "./SidebarSelection";
import InternalDashboard from "./InternalDashboard";
import UKInternalDashboard from "./UKInternalDashboard";
import { getUserOffice } from "../../../../redux/actions/userActions";
import CuracaoInternalDashboard from "./CuracaoInternalDashboard";
import { connect } from "react-redux";
import MaltaInternalDashboard from "./MaltaInternalDashboard";
import LuxembourgInternalDashboard from "./LuxembourgInternalDashboard";
class CommonDashboard extends Component {
  handleChange(e) {
    this.props.handleOfficeChange(e);
  }

  componentDidMount() {
    this.props
      .getUserOffice()
      .then((res) => res.data)
      .then((data) => {
        if (data?.statusCode === 200) {
          localStorage.setItem("userType", data?.result?.name); 
               
        }
      });
  }
  render() {
    return (
      <Router basename="/internal-dashboard">
        <InternalHeader />
        <div className="id-parent">
          <SidebarSelection office={this.props.office} userType={localStorage.getItem("userType")}/>
          <Switch>
            <Redirect exact from="/" to="/clientlist" />
            <InternalUserRoutes path="/userlist" component={userlist} />
            <InternalUserRoutes
              path="/userapprovals"
              component={userApprovals}
            />

            <InternalUserRoutes path="/clientlist" component={clientlist} />
            <InternalUserRoutes path="/adduser" component={addUser} />
            <InternalUserRoutes path="/editUser/:id" component={addUser} />
            <InternalUserRoutes path="/addclient" component={addClient} />
            <InternalUserRoutes path="/editClient/:id" component={addClient} />
            <InternalUserRoutes
              path="/clientapprovals"
              component={clientApprovals}
            />
            <InternalUserRoutes
              path="/client-form-list"
              component={clientFormList}
              handleOfficeChange={(e) => this.handleChange(e)}
            />

            <InternalUserRoutes
              path="/netherlands"
              component={InternalDashboard}
            />
            <InternalUserRoutes
              path="/united-kingdom"
              office={this.props.office}
              component={UKInternalDashboard}
            />
            <InternalUserRoutes
              path="/curacao"
              office={this.props.office}
              component={CuracaoInternalDashboard}
            />
            <InternalUserRoutes
              path="/malta"
              office={this.props.office}
              component={MaltaInternalDashboard}
            />
            <InternalUserRoutes
              path="/luxembourg"
              office={this.props.office}
              component={LuxembourgInternalDashboard}
            />
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return { getUserOffice: async () => dispatch(await getUserOffice()) };
};
export default connect(null, mapDispatchToProps)(CommonDashboard);
