import axios from "axios";

const BACKEND_API_URL = process.env.REACT_APP_USER_BACKEND_API_URL;

const setNewMaltaDataToReducerReceiveData = (json) => {
  return {
    type: "UPDATE_NEW_MALTA_DATA",
    data: json,
  };
};

export const setNewMaltaDataToReducer = (data) => {
  return (dispatch) => {
    dispatch(setNewMaltaDataToReducerReceiveData(data));
  };
};

const SetMaltaCAFDetailsRequest = () => {
  return {
    type: "SET_MALTA_CAF_DETAILS_REQUEST",
  };
};

const SetMaltaCAFDetailsReceiveData = (json) => {
  return {
    type: "SET_MALTA_CAF_DETAILS_SUCCESS",
    data: json,
  };
};

export const setMaltaCAFDetails = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(SetMaltaCAFDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/MaltaCAF/SetCAFDetails`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: "SET_MALTA_CAF_DETAILS_FAIL", data: json });
        } else {
          return dispatch(SetMaltaCAFDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "SET_MALTA_CAF_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};

const GetMaltaCAFDetailsRequest = () => {
  return {
    type: "GET_MALTA_CAF_DETAILS_REQUEST",
  };
};

const GetMaltaCAFDetailsReceiveData = (json) => {
  return {
    type: "GET_MALTA_CAF_DETAILS_SUCCESS",
    data: json,
  };
};

export const getMaltaCAFDetails = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(GetMaltaCAFDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/MaltaCAF/GetCAFExternal`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: "GET_MALTA_CAF_DETAILS_FAIL", data: json });
        } else {
          return dispatch(GetMaltaCAFDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "GET_MALTA_CAF_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};
