export const UKEntities = {
  entityType: null,
  trustDocs: null,
  otherDocs: null,
  chamberExtract: null,
  article: null,
  shareholderRegister: null,
  finStat: null,
};

export const Chapter1 = {
  structureChart: [],
  isCompIncorporated: "",
  entityJurisdiction: null,
  noOfEntities: 1,
  entities: [UKEntities],
  upcActivites: null,
  upcSector: null,
  shareholderActivites: null,
  shareholderSector: null,
  objCompActivities: null,
  objCompSector: null,
  participantActivites: null,
  participantSector: null,
  otherActivites: null,
  otherSector: null,
  dirty: false,
};
