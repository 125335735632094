import { CustActivityRisks, AdditionDocs } from "../../InternalUser/Chapter1";
export const CorporateDirector = {
    corporateDirectorName: null,
  };
export const PersonalDirector = {
    corporateDirectorName: null,
  };

  export const UBO = {
    uboName: null,
  };
  export const Entity = {
    entityName: null,
    entityPercent:null
  };

export const ClientIndustry={
  clientIName:null,
};
export const Chapter1 = {
    legalform:null,
    address:null,
    statutorySeat:null,
    actualBusinessSeat:null,
    incorporationDate:null,
    registrationNumberCOC:null,
    isbusinessSeatFromStatutorySeat:null,
    registrationNumberStatutorySeat:null,
    firstBookYear:null,
    dateBecameCustomer:null,
    transferredFrom:null,
    numCorporateDirectors:1,
    corporateDirectors:[CorporateDirector],
    numPersonalDirectors:1,
    personalDirectors:[PersonalDirector],
    management:null,
    managementFee:null,
    representation:null,
    representationFee:null,
    legalServices:null,
    legalServicesFee:null,
    accountingServices:null,
    accountingServicesFee:null,
    taxServices:null,
    taxServicesFee:null,
    hoursFeeArrang:null,
    fixedFeeArrang:null,
    nameContact:null,
    addressContact:null,
    telephonContact:null,
    emailContact:null,
    nameCopyInvoice:null,
    addressCopyInvoice:null,
    telephonCopyInvoice:null,
    emailCopyInvoice:null,
    isNewclientGroup:null,
    clientGroup:null,
    introducingFirm:null,
    introducingPerson:null,
    clientType:null,
    numClientIndustries:1,
    clientIndustry:[ClientIndustry],
    clientActivitiesType:null,
    clientActivitiesCountry:null,
    clientActivitiesRegion:null,
    dateCompleted:null,
    by:null,
    temporaryAcceptance:null,
    clientAccepted:null,
    numUBOs:1,
    ubos:[UBO],
    descripClientAndBackgroundInfo:null,
    descripTaxPositionAndUnderlyingRationale:null,
    descripStructureAndEntityDiagram:null,
    numEntities:1,
    Entities:[Entity],
  dirty: false,
};
