export const chapter1 = {
  structureChart: [],
  entityJurisdiction: "",
  isCompIncorporated: "",
  entities: [],
  upcActivites: "",
  upcSector: "",
  shareholderActivites: "",
  shareholderSector: "",
  objCompActivities: "",
  objCompSector: "",
  participantActivites: "",
  participantSector: "",
  otherActivites: "",
  otherSector: "",
};

export const chapter2 = {
  isClientRepInvolved: "",
  intermediaryDetails: [],
  authorisedDetails:[],
  isAuthrzdPrsn: "",
  //authrzdPrsnName: "",
  //authrzdPrsnNationality: "",
  authrzdPrsnPassport: "",
  authrzdPrsnAuthLetter: "",
  authrzdPrsnRefLetter: "",
  authrzdPrsnPOA: "",
  isClientTransfrd: "",
  srvcPrvdrName: "",
  reasonClientTransfrd: "",
};
export const chapter3 = {
  isHoldingInterest: "",
  isHoldingInterestReason: "",
  isUBOClassified: "",
  isUBOClassifiedReason: "",
  isAssetClient: "",
  isAssetClientReason: "",
  ubox: [],
  isUBOExists:"",
  isUPCExists: "",
  upcExplanation:"",
  noOfUPCDir: 1,
  upcDetails:[],
  nameUPC: "",
  addressRegistered: "",
  postalCode: "",
  city: "",
  country: "",
  websiteUPC: "",
  taxNum: "",
  tradeRegisterNum: "",
  phoneNumberUPC: "",
  countryOfTaxRsdnc: "",
  isForceCloseFinAcc: "",
  forceCloseFinAcc: "",
  //upcDirectors: [],
  extractChamberOfCommerce: [],
  deedOfIncorporation: [],
  shareholdersRegister: [],
  shareholdersCertificates: [],
  nomineeAgreement: [],
  financialStatementsThreeYears: [],
};

export const chapter4 = {
  isObjCompExists: "",
  objCompName: "",
  commercialRegister: "",
  objCompDirectorNames: [],
  objCompActiveCountry: [],
  objCompNaturePropsdObjctv: "",
  objCompOtherNaturePropsdObjctv: "",
  isObjCompBranches: [],
  objCompBranch: [],
  rationaleStructurigForNL: [],
  rationaleSelectedInfo: "",
  taxAdvisorFinds: [],
  thirdPartyRefApprvl: "",
  thirdPartyDetails: "",
  grpRefApprvl: "",
  officeIndividualDetails: "",
  exstngClntRefApprvl: "",
  clientDetails: "",
  prsnCntctDirePrspctngApprvl: "",
  uimUKStaffDetails: "",
  walkInApprvl: "",
  hearofUIM: "",
  internetApprvl: "",
  fromWhichWebsite:"",
  domiciliationSrvsApprvl: "",
  domSrvcsExplaination: "",
  accntngSrvsApprvl: "",
  thirdPartyDetailsNoUIM: "",
  taxCmplanceSrvcsApprvl: "",
  coSecServicesApprvl: "",
  bnkAccReqApprvl: "",
  bankDetails: "",
  othrSrvcsApprvl: "",
  othrSrvcsExplain: "",
  directorsPartners: "",
  shareholdersMembers: "",
  regOffice: "",
  trusteeName: "",
  trusteeAddress: "",
  istrusPassport: "",
  trusPassport: "",
  truePassportReason: "",
  istrusProofAdd: "",
  trusProofAdd: "",
  trusProofAddReason: "",
  istrusRefLett: "",
  trusRefLett: "",
  trusRefLettReason: "",
  protectorDetails: "",
  isprotecPassport: "",
  protecPassport: "",
  protecPassportReason: "",
  isprotecProofAdd: "",
  protecProofAdd: "",
  protecProofAddReason: "",
  isprotecRefLett: "",
  protecRefLett: "",
  protecRefLettReason: "",
  settlorDetails: "",
  issettPassport: "",
  settPassport: "",
  settPassportReason: "",
  issettProofAdd: "",
  settProofAdd: "",
  settProofAddReason: "",
  issettRefLett: "",
  settRefLett: "",
  settRefLettReason: "",
  beneficiaryDetails: "",
  isbenPassport: "",
  benPassport: "",
  benPassportReason: "",
  isbenProofAdd: "",
  benProofAdd: "",
  benProofAddReason: "",
  isbenRefLett: "",
  benRefLett: "",
  benRefLettReason: "",

  copyPassportManagement: [],
  extractChamberOfCommerce4: [],
  deedOfIncorporation4: [],
  shareholdersRegister4: [],
  shareholdersCertificates4: [],
  financialStatmntsThreeYears4: [],
  isTaxMemorandum: "",
  taxAdvisorName: "",
  taxMemorandum: [],
  taxMemorandumAdvisorName: "",
  proofOfTaxRes: "",

  isInvstmntMemorandum: "",
  invstmntMemorandum: [],
  isAPA: "",
  objCompTaxResult: "",
  explanation: "",
  otherDocs: [],

  isUBOInvstObjComp: "",
  uboInvstCurrencyObjComp: "",
  uboInvstObjCompAmnt: "",
  uboInvstObjCompOrigin: "",
  isUPCfundObjComp: "",
  upcFundObjCompOrigin: "",
  annualFinStmnt: [],
  isTpInvstObjComp: "",
  tpInvstObjComp: [],
  tpName: "",
  tpCountry:"",
  tpAnnualFinStmnts:"",
  

  cntrysInvlvdTrnsctnsObjComp: [],
  estmtdAnnualTrnOvrObjComp: "",
  assetSummryObjComp: "",
  assetSummryEvidence: [],
  expctdTrnsctnsSummary: "",
  expctdTrnsctnsEvidence: [],
  objCompToGrpComp: "",
  grpCompToObjComp: "",
  objCompToUBO: "",
  uboToObjComp: "",
  objCompToTP: "",
  tpToObjComp: "",
  rightsAndObligations: [],
  bnkInfo: [],
  isFatcaSCF: "",
  fatcaSelfCertifctnFrm: [],
  noFATCAformReason: "",
  fatcaClassification: "",
  bnkStmnts: [],
  agremntsGrUpEntities: [],
  agremntsOthrParties: [],
  financialStmntsObjComp: [],
  finanicalStmntsAllPrticipatns: [],
  financialStmntsParntComp: [],
  declartnsFinanicalInstitutns: [],
};

export const chapter5 = {
  countryList: [],
};
export const chapter6 = {
  isExternalDirectors: "",
  isExternalDirectorsNaturalPerson: "",
  nameOfNaturalPerson: "",
  nationality: "",
};


