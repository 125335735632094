import React, { Component } from "react";
import { connect } from "react-redux";
import { getReducer } from "../../../../../elements/getReducer";
import { srcOfWealthArray, fileAcceptType } from "./Constants";
import { setDataToReducer } from "../../../../../../redux/actions/ClientInformationAction";
import { Row } from "react-bootstrap";
import FileUpload from "../../../../../elements/FileUpload";
import DownloadFile from "../../../../../elements/DownloadFile";

class SourceOfWealth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sources: srcOfWealthArray,
      selectedSources: [],
    };
  }

  handleChange = (source) => {
    let mainSrc = this.props.srcOfWealth;
    let array = this.state.sources;
    if (array.length > 0) {
      array.forEach((item) => {
        item.isChecked = false;
      });
      array.forEach((item) => {
        mainSrc.forEach((source) => {
          if (item.label === source.descrptn) {
            item.isChecked = true;
          }
        });
      });
    }
    let newIndex = array.indexOf(source);
    source.isChecked = !source.isChecked;
    array[newIndex] = source;
    this.setState({
      sources: array,
    });
    let selectedItemsArray = [];
    array.forEach((item) => {
      if (item.isChecked) {
        let obj = {};
        obj["descrptn"] = item.label;
        selectedItemsArray.push(obj);
      }
    });
    this.setState({
      selectedSources: selectedItemsArray,
    });
    this.props.setDataToReducer({
      type: getReducer(this.props.reducer),
      chapter: this.props.chapter,
      name: "srcOfWealth",
      value: selectedItemsArray,
      objBaseName: this.props.objBaseName,
      index: this.props.index,
      baseName: this.props.baseName,
    });
  };
  render() {
    let array = this.props.srcOfWealth;
    const index = this.props.index;
    const eMsg = JSON.parse(this.props.errorMsg);
    let existingArray = this.state.sources;
    if (array.length > 0) {
      existingArray.forEach((item) => {
        item.isChecked = false;
      });

      existingArray.forEach((item) => {
        array.forEach((source) => {
          if (item.label === source.descrptn) {
            item.isChecked = true;
          }
        });
      });
    } else {
      existingArray.forEach((item) => {
        item.isChecked = false;
      });
    }

    return (
      <Row className="pt-0 pb-3" id="srcOfWealth">
        <p className="col-md-12 mb-3" id={`srcOfWealth[${index}]`}>
          Please describe the source of your wealth (please tick).
          {this.props.form === "UK" && <span style={{ color: "red" }}>*</span>}
        </p>       
        {existingArray.map((option, i) => {
          return (
            <div className="mb-2 col-lg-4" key={index}>
              <div className="options">
                <label
                  value={option.label}
                  className={this.props.disabled && "disabledCheckbox"}
                >
                  <input
                    type="checkbox"
                    name={option.label}
                    onChange={(e) => this.handleChange(option)}
                    checked={option.isChecked}
                    value={option.label}
                    disabled={this.props.disabled}
                  />
                  <img alt="" />
                  <span className="ml-2">{option.label}</span>
                </label>
              </div>
            </div>
          );
        })}
        {eMsg !== null &&
          Object.entries(eMsg).length !== 0 &&
          eMsg?.partB &&
          eMsg?.partB?.srcOfWealth &&
          eMsg?.partB?.srcOfWealth?.length > 0 && (
            <div className="col-md-12 pt-3">
              <span style={{ color: "red", fontSize: "14px" }}>
                {eMsg?.partB?.srcOfWealth}
              </span>
            </div>
          )}
        {eMsg !== null &&
          Object.entries(eMsg).length !== 0 &&
          eMsg?.ubox &&
          eMsg?.ubox[index]?.srcOfWealth &&
          eMsg?.ubox[index]?.srcOfWealth?.length > 0 && (
            <div className="col-md-12 pt-3">
              <span style={{ color: "red", fontSize: "14px" }}>
                {eMsg?.ubox[index]?.srcOfWealth}
              </span>
            </div>
          )}

        {this.props.disabled ? (
          <>
            <div className="col-md-4" id="evdncSrcWealth">
              <DownloadFile
                label="Evidence/Substantiating documents"
                name="evdncSrcWealth"
                id={`evdncSrcWealth[${index}]`}
                chapter={this.props.chapter}
                reducer={this.props.reducer}
                index={this.props.index}
                baseName={this.props.baseName}
                objBaseName={this.props.objBaseName}
                value={this.props.evdncSrcWealth}                
              />
            </div>
          </>
        ) : (
          <div className="col-md-4 pt-2" id="evdncSrcWealth">
            <FileUpload
              label="Evidence/Substantiating documents"
              acceptType={fileAcceptType}
              id={`evdncSrcWealth[${index}]`}
              name="evdncSrcWealth"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={this.props.index}
              baseName={this.props.baseName}
              objBaseName={this.props.objBaseName}
              //isRequired={this.props.form === "UK" ? true : false} 
            />{" "}
            {eMsg !== null &&
              Object.entries(eMsg).length !== 0 &&
              eMsg?.partB &&
              eMsg?.partB?.evdncSrcWealth &&
              eMsg?.partB?.evdncSrcWealth?.length > 0 && (
                <div className="col-md-12 pt-3">
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg?.partB?.evdncSrcWealth}
                  </span>
                </div>
              )}
            {eMsg !== null &&
              Object.entries(eMsg).length !== 0 &&
              eMsg?.ubox &&
              eMsg?.ubox[index]?.evdncSrcWealth?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg?.ubox[index]?.evdncSrcWealth}
                </span>
              )}
          </div>
        )}
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { reducer, chapter, index } = ownProps;

  if (reducer === "UBODeclarationUIM") {
    return {
      srcOfWealth: state.UBODeclarationUIM.partB["srcOfWealth"],
    };
  } else {
    return {
      srcOfWealth: state[reducer][chapter]["ubo"]["ubox"][index]["srcOfWealth"],
      evdncSrcWealth:
        state[reducer][chapter]["ubo"]["ubox"][index]["evdncSrcWealth"],
    };
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceOfWealth);
