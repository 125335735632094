export const IntermediaryObj = {
  isClientReal: "",
  clientRepNationality: null,
  clientRepName: null,
  clientRepPassport: [],
  activeInCountry: [],
  legalEntityName: null,
  cocExtract: [],
  isPOA: "",
  poa: [],
  noPOAReason: null,
  isUBOIdentifyVerify: "",
  uboIdentityPassport: [],
  noUBOIdentifyVerifyReason: null,
  intrAmlPoliciesProcs:null
};

export const Chapter2 = {
  isClientRepInvolved: "",
  clientRepNum: 1,
  intermediaryDetails: [IntermediaryObj],
  dirty: false,
};
