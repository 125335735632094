import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../../elements/CheckBox";
import NumberInput from "../../../../../elements/NumberInput";
import TextBox from "../../../../../elements/TextBox";
import Intermediary from "./Intermediary";
import Authorised from "./Authorised";
import { saveAs } from "file-saver";
import { IntermediaryObj } from "../../../../../JSONs/ClientInformationForm/Chapter2";
import { fileAcceptType } from "./Constants";
import countryList from "react-select-country-list";
import { downloadFATCAForm } from "../../../../../../redux/actions/userActions";
import DropDown from "../../../../../elements/DropDown";
import FileUpload from "../../../../../elements/FileUpload";
import { checkIsCommon } from "../CommonFunctions";
import { IntermediaryObjCuracao } from "../../../../../JSONs/CuracaoForm/External/Chapter2";
import { IntermediaryObjMalta } from "../../../../../JSONs/MaltaForm/External/Chapter2";
import { AuthorisedObj } from "../../../../../JSONs/UK-Form/External/Client-Information/Chapter2";
const chapter = "chapter2";

class Chapter2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  downloadForm = (fileName) => {
    this.props.downloadFATCAForm({ fileName: fileName }).then((result) => {
      var blob = new Blob([result.data]);
      let file = fileName;
      saveAs(blob, file);
    });
  };

  render() {
    const { isClientRepInvolved, clientRepNum, isClientTransfrd, authorisedPersonsNum, errorMsg } =
      this.props;
    let intermediaries = [];
    let authorisedPersons = [];
    // const eMsg = {};
    const eMsg = JSON.parse(errorMsg);
    for (let i = 0; i < clientRepNum; i++) {
      intermediaries.push(
        <Intermediary
          index={i}
          baseName="intermediaryDetails"
          reducer={this.props.reducer}
          form={this.props.form}
          eMsg={errorMsg}
        />
      );
    }


    for (let i = 0; i < authorisedPersonsNum; i++) {
      authorisedPersons.push(
        <Authorised
          index={i}
          baseName="authorisedDetails"
          reducer={this.props.reducer}
          form={this.props.form}
          eMsg={errorMsg}
        />
      );
    }
    return (
      <div className="generic-form client-investigation mt-4">
        <h5 className="mb-0  mt-2">
          {this.props.form === "Malta"
            ? "Target Company - Intermediary / Agent"
            : this.props.form === "NL" ? "2- Client Investigation - Client Relationship (Intermediary)":"Client Investigation - Client Relationship (Intermediary)"}
        </h5>
        <Row className="pt-3 pb-2">
          <div className="mb-2 col-lg-6">
            <CheckBox
              label={`Are relevant intermediaries/${this.props.form === "Malta"
                  ? "agents"
                  : "client representatives"
                } involved in the structure?`}
              options={["Yes", "No"]}
              name="isClientRepInvolved"
              id="isClientRepInvolved"
              chapter={chapter}
              reducer={this.props.reducer}
            />
            {eMsg.isClientRepInvolved?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.isClientRepInvolved}
              </span>
            )}
          </div>

          {isClientRepInvolved === "Yes" && (
            <>
              <div className="mb-2 col-lg-6">
                <NumberInput
                  label={`How many intermediaries/${this.props.form === "Malta"
                      ? "agents"
                      : "client representatives"
                    } can be identified?`}
                  name="clientRepNum"
                  chapter={chapter}
                  referral={["intermediaryDetails"]}
                  pushedObject={
                    this.props.form === "Curacao"
                      ? [IntermediaryObjCuracao]
                      : this.props.form === "Malta"
                        ? [IntermediaryObjMalta]
                        : [IntermediaryObj]
                  }
                  maxLimit={100}
                  reducer={this.props.reducer}
                />
                <div className="errorMsg"></div>
              </div>
              {intermediaries}
            </>
          )}
        </Row>
        {this.props.form === "UK" && (
          <Row className="pt-3 pb-2">
            <div className="mb-2 col-lg-6">
              <CheckBox
                label="Is Authorized Person?"
                options={["Yes", "No"]}
                name="isAuthrzdPrsn"
                id={`isAuthrzdPrsn`}
                chapter={chapter}
                reducer={this.props.reducer}
                isRequired={this.props.form === "UK" ? true : false}
              />
              {eMsg.isAuthrzdPrsn?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {eMsg.isAuthrzdPrsn}
                </span>
              )}
            </div>
            <div className="mb-2 col-lg-8">
              <Row className="pt-3 pb-2">
                {this.props.isAuthrzdPrsn === "Yes" && (
                  <>
                    <div className="mb-2 col-lg-6">
                      <label>Download authorization letter</label>
                      <div className="fileDownload">
                        <button
                          className="action-btn big download"
                          onClick={() =>
                            this.downloadForm(
                              "Authorised Person Appointment.doc"
                            )
                          }
                        >
                          Download
                          <i className="fa fa-download ml-2"></i>
                        </button>
                      </div>
                    </div>
                    {/* <div className="mb-2 col-lg-6">
                      <label>Download reference letter</label>
                      <div className="fileDownload">
                        <button
                          className="action-btn big download"
                          onClick={() =>
                            this.downloadForm(
                              "Reference Letter - Template.docx"
                            )
                          }
                        >
                          Download
                          <i className="fa fa-download ml-2"></i>
                        </button>
                      </div>
                    </div> */}
                    <div className="mb-2 col-lg-6">
                      <NumberInput
                        label={`How many authorised persons`}
                        name="authorisedPersonsNum"
                        chapter={chapter}
                        referral={["authorisedDetails"]}
                        pushedObject={[AuthorisedObj]}
                        maxLimit={100}
                        reducer={this.props.reducer}
                      />
                      <div className="errorMsg"></div>
                    </div>
                    {authorisedPersons}



                    {/* <div className="mb-2 col-lg-6">
                      <TextBox
                        label="Name of authorized person"
                        name="authrzdPrsnName"
                        id={`authrzdPrsnName`}
                        placeholder="Enter name"
                        chapter={chapter}
                        reducer={this.props.reducer}
                        validationType="Text"
                        isRequired={this.props.form === "UK" ? true : false} 
                      />
                      {eMsg.authrzdPrsnName?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {eMsg.authrzdPrsnName}
                        </span>
                      )}
                    </div> */}
                    {/* <div className="mb-2 col-lg-6">
                      <DropDown
                        label="Nationality of authorized person"
                        name="authrzdPrsnNationality"
                        id={`authrzdPrsnNationality`}
                        options={countryList().getData()}
                        chapter={chapter}
                        reducer={this.props.reducer}
                        isRequired={this.props.form === "UK" ? true : false} 
                      />
                      {eMsg.authrzdPrsnNationality?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {eMsg.authrzdPrsnNationality}
                        </span>
                      )}
                    </div> */}
                    {/* <div className="mb-2 col-lg-6">
                      <FileUpload
                        label="Upload passport"
                        acceptType={fileAcceptType}
                        name="authrzdPrsnPassport"
                        id={`authrzdPrsnPassport`}
                        chapter={chapter}
                        reducer={this.props.reducer}
                        isRequired={this.props.form === "UK" ? true : false} 
                      />
                      {eMsg.authrzdPrsnPassport?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {eMsg.authrzdPrsnPassport}
                        </span>
                      )}
                    </div>

                   

                    <div className="mb-2 col-lg-6">
                      <FileUpload
                        label="Upload authorization letter"
                        acceptType={fileAcceptType}
                        name="authrzdPrsnAuthLetter"
                        id={`authrzdPrsnAuthLetter`}
                        chapter={chapter}
                        reducer={this.props.reducer}
                        isRequired={this.props.form === "UK" ? true : false} 
                      />
                      {eMsg.authrzdPrsnAuthLetter?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {eMsg.authrzdPrsnAuthLetter}
                        </span>
                      )}
                    </div>

                    
                    <div className="mb-2 col-lg-6">
                      <FileUpload
                        label="Upload reference letter"
                        acceptType={fileAcceptType}
                        name="authrzdPrsnRefLetter"
                        id={`authrzdPrsnRefLetter`}
                        chapter={chapter}
                        reducer={this.props.reducer}
                        isRequired={this.props.form === "UK" ? true : false} 
                      />
                      {eMsg.authrzdPrsnRefLetter?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {eMsg.authrzdPrsnRefLetter}
                        </span>
                      )}
                    </div>
                    <div className="mb-2 col-lg-6">
                      <FileUpload
                        label="Upload proof of address"
                        acceptType={fileAcceptType}
                        name="authrzdPrsnPOA"
                        id={`authrzdPrsnPOA`}
                        chapter={chapter}
                        reducer={this.props.reducer}
                        isRequired={this.props.form === "UK" ? true : false} 
                      />
                      {eMsg.authrzdPrsnPOA?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {eMsg.authrzdPrsnPOA}
                        </span>
                      )}
                    </div> */}
                  </>
                )}
              </Row>
            </div>
          </Row>
        )}
        <Row className="pt-3 pb-2">
          <div className="mb-2 col-lg-4">
           
            <CheckBox
              label={
                this.props.form === "Malta"
                  ? "Is the target company being transferred from another service provider?"
                  : "Was the client transferred from another service provider (trust office)?"
              }
              options={["Yes", "No"]}
              name="isClientTransfrd"
              id="isClientTransfrd"
              chapter={this.props.form !== "NL" && "chapter2"}
              reducer={
                checkIsCommon(this.props.form)
                  ? "commonFields"
                  : this.props.reducer
              }
              isCommon={checkIsCommon(this.props.form)}
              isRequired={this.props.form === "UK" || this.props.form === "NL"  ? true : false} 
            />
            {eMsg.isClientTransfrd?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {eMsg.isClientTransfrd}
              </span>
            )}
          </div>
          {isClientTransfrd === "Yes" && (
            <>
              <div className="mb-2 col-lg-4 fieldAlignment">
                <TextBox
                  label="Please mention name of the service provider"
                  placeholder="enter service provider name"
                  name="srvcPrvdrName"
                  id="srvcPrvdrName"
                  chapter={this.props.form !== "NL" && "chapter2"}
                  reducer={
                    checkIsCommon(this.props.form)
                      ? "commonFields"
                      : this.props.reducer
                  }
                  validationType="Text"
                  isCommon={checkIsCommon(this.props.form)}
                  isRequired={this.props.form === "UK" || this.props.form === "NL" ? true : false}
                />
                {eMsg.srvcPrvdrName?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {eMsg.srvcPrvdrName}
                  </span>
                )}
              </div>
              <div className="mb-2 col-lg-4 fieldAlignment">
                <TextBox
                  label="What are the reasons for changing of service provider?"
                  placeholder="enter reason"
                  name="reasonClientTransfrd"
                  id="reasonClientTransfrd"
                  chapter={this.props.form !== "NL" && "chapter2"}
                  reducer={
                    checkIsCommon(this.props.form)
                      ? "commonFields"
                      : this.props.reducer
                  }
                  isCommon={checkIsCommon(this.props.form)}
                  validationType="Text"
                  isRequired={this.props.form === "UK" || this.props.form === "NL" ? true : false}
                />
                {eMsg.reasonClientTransfrd?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {eMsg.reasonClientTransfrd}
                  </span>
                )}
              </div>
            </>
          )}
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    downloadFATCAForm: (data) => dispatch(downloadFATCAForm(data)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { reducer, form } = ownProps;
  if (
    form === "NL" ||
    form === "Curacao" ||
    form === "Malta" ||
    form === "Luxembourg"
  ) {
    return {
      isClientTransfrd: state.commonFields["isClientTransfrd"],
      isClientRepInvolved: state[reducer]["chapter2"]["isClientRepInvolved"],
      clientRepNum: state[reducer]["chapter2"]["clientRepNum"],
    };
  } else {
    return {
      isClientTransfrd: state[reducer]["chapter2"]["isClientTransfrd"],
      isClientRepInvolved: state[reducer]["chapter2"]["isClientRepInvolved"],
      clientRepNum: state[reducer]["chapter2"]["clientRepNum"],
      isAuthrzdPrsn: state[reducer]["chapter2"]["isAuthrzdPrsn"],
      authorisedPersonsNum: state[reducer]["chapter2"]["authorisedPersonsNum"],

    };
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter2);
