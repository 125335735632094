const ovrvwBnkAC = {
  isFatcaSCF: "",
  fatcaSelfCertifctnFrm: [],
  noFATCAformReason: null,
  bnkStmnts: [],
  agremntsGrUpEntities: [],
  agremntsOthrParties: [],
  financialStmntsObjComp: [],
  finanicalStmntsAllPrticipatns: [],
  financialStmntsParntComp: [],
  declartnsFinanicalInstitutns: [],
};
export const DirectorNamesArray = {
  dirId: null,
  dirName: null,
  dirResAdd: null,
  dirNationality: null,
  dirPassIdntyNum: null,
  dirPlaceOfIssu: null,
  dirDtIssue: null,
  dirExprDt: null,
  dirTelPhnNum: null,
  dirEmail: null,
  isDirJudRepComp: null,
  dirPassportCopy: [],
  dirUtilityBill: [],
  dirReferenceLetter: [],
};
const DocumentsObjectCompany = {
  copyPassportManagement: [],
  extractChamberOfCommerce: [],
  deedOfIncorporation: [],
  shareholdersRegister: [],
  shareholdersCertificates: [],
  financialStatmntsThreeYears: [],
  refLetter: [],
  utilityBills: [],
  isAPA: "",
  objCompTaxResult: "",
  explanation: null,
  otherDocs: [],
};

const CompanySecreatary = {
  isCmpSecExist: null,
  cmpSectName: null,
  cmpSecResAddress: null,
  cmpPassIdntyRegNum: null,
  cmpPlaceOfIss: null,
  cmpDtIssue: null,
  cmpExprDt: null,
  cmpTelPhnNum: null,
  cmpEmail: null,
  cmpSecNationality: null,
};

const situation = {
  descClientNeeds: null,
  benefitOfClient: null,
  taxImplications: null,
  busAcptncCnflctIntrst: null,
};

const oneTimeServices = {
  sttngCompCntrct: null,
  serviceNames: [],
  byWhom: null,
  othrService: null,
};

const recurrentServices = {
  isOffRegstrd: null,
  serviceNames: [],
  comAddComReg: null,
  othrService: null,
};
const ServicesProvidedMalta = {
  oneTimeServ: oneTimeServices,
  recurrntSrvcs: recurrentServices,
};

const ContactPrsn = {
  isCntcPersnExist: null,
  cntctPrsnName: null,
  cntctPrsnResAdd: null,
  cntctPrsnTelPhnNum: null,
  cntctPrsnEmail: null,
  cntctPrsnMailingAdd: null,
  cntctNationality: null,
  prtclrInstructions: null,
};

const shareCapCom = {
  isShrCapComp: null,
  shrCapCurrency: null,
  authrzdShrCap: null,
  issuedShrCap: null,
  shrCapDenm: null,
};

const documentation = {
  certifiedCopyIdentity: [],
  bankersRef: [],
  profRef: [],
  declrGoodStndng: [],
  formDocConfmIdntyShrHold: [],
  recGoodStndngCrt: [],
  cpyIncorDoc: [],
  relCompaniesHse: [],
  ltstFinStmnts: [],
};
export const Chapter4 = {
  isObjCompExists: "",
  commercialRegister: null,
  regOffMal: null,
  typOfCompMal: null,
  typeCmpOthrSpcfy: null,
  noOfDirectors: 1,
  objCompDirectorNames: [DirectorNamesArray],
  cmpSectr: CompanySecreatary,
  isAcctngYrChng: null,
  acctngDtChng: null,
  antcptAnnTurnFinStmts: [],
  cntctPerNewComp: ContactPrsn,
  objCompActiveCountry: [],
  isObjCompBranches: "",
  objCompBranch: "",
  shareCapComp: shareCapCom,
  documentsObjComp: DocumentsObjectCompany,
  servicesProvidedMal: ServicesProvidedMalta,
  situation: situation,
  documentation: documentation,
  taxAdvisorFinds: [],
  ovrvwBnkAC: ovrvwBnkAC,
};
