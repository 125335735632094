import React, { Component } from "react";
import { Button } from "react-bootstrap";
import {
  changePwd,
  getProfile,
  setProfile,
} from "../../../../redux/actions/userActions";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import ExternalHeader from "../../../pages/external/header/ExternalHeader";
import { Base64 } from "js-base64";
import countryList from "react-select-country-list";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";

/* eslint-disable no-useless-escape */
class changepassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      name: null,
      phoneNo: null,
      oldpassword: "",
      newpassword: "",
      confirmpassword: "",
      profileUpdateFlag: false,
      changePwdFlag: false,
      errors: {},
      nationality: null,
      isLoading: false,
    };
  }

  componentDidMount = () => {
    this.setState({ isLoading: true });
    this.props.getProfile().then((res) => {
      this.setState({ isLoading: false });
      if (res.data?.portalErrorCode === 200) {
        this.setState({
          name: res.data?.result?.name,
          email: res.data?.result?.email,
          phoneNo: res.data?.result?.phoneNo,
          nationality: res.data?.result?.nationality,
        });
      } else {
        NotificationManager.error("Error while loading details");
      }
    });
  };
  validateForm = () => {
    let errors = {};
    let formIsValid = true;
    const message = "Enter valid password";
    if (!this.state.oldpassword) {
      formIsValid = false;
      errors["oldpassword"] = message;
    }
    if (this.state.oldpassword) {
      if (
        !this.state.oldpassword.match(
          /(?=[A-Za-z0-9!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~])(?=.{8,}).*$/
          ///^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~])[a-zA-Z0-9!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~]{8}$/
        )
      ) {
        formIsValid = false;
        errors["oldpassword"] = message;
      }
    }
    if (!this.state.newpassword) {
      formIsValid = false;
      errors["newpassword"] = message;
    }
    if (this.state.newpassword) {
      if (
        !this.state.newpassword.match(
          /(?=[A-Za-z0-9!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~])(?=.{8,}).*$/
          ///^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~])[a-zA-Z0-9!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~]{8}$/
        )
      ) {
        formIsValid = false;
        errors["newpassword"] = message;
      }
    }
    if (
      this.state.newpassword &&
      this.state.oldpassword &&
      this.state.newpassword === this.state.oldpassword
    ) {
      formIsValid = false;
      errors["otherMessage"] =
        "Current password and new password should not be the same";
    }
    if (!this.state.confirmpassword) {
      formIsValid = false;
      errors["confirmpassword"] = message;
    }
    if (this.state.confirmpassword) {
      if (this.state.confirmpassword !== this.state.newpassword) {
        formIsValid = false;
        errors["confirmpassword"] = "Both Passwords should match";
      }
    }
    this.setState({ errors });
    return formIsValid;
  };

  //handle change on all inputs
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      changePwdFlag: true,
    });
  };

  clearInputs = () => {
    this.setState({
      oldpassword: "",
      newpassword: "",
      confirmpassword: "",
    });
  };

  handleBack = () => {
    let userInfo = JSON.parse(localStorage.getItem("user"));
    let errors = {};
    if (userInfo.isTemporaryPassword) {
      errors["otherMessage"] = "Please change your new password";
      this.setState({ errors });
    } else {
      window.location = "/dashboard";
    }
  };

  handleNationality = (value) => {
    this.setState({ nationality: value.label, profileUpdateFlag: true });
  };

  handleSaveProfile = (e) => {
    e.preventDefault();
    let errors = {};
    this.setState({ isLoading: true });
    if (this.state.nationality) {
      let data = {
        nationality: this.state.nationality,
      };
      this.props.setProfile(data).then((res) => {
        this.setState({ isLoading: false });
        if (res.data?.portalErrorCode === 200) {
          NotificationManager.success("Profile updated successfully.");
        } else {
          NotificationManager.error("Error while updating profile.");
        }
        this.setState({ errors });
      });
    } else {
      let errors = {};
      errors["nationality"] = "Please select nationality";
      this.setState({ errors });
      this.setState({ isLoading: false });
    }
  };

  handleChangePwd = async (e) => {
    e.preventDefault();
    let errors = {};
    this.setState({ isLoading: true });
    if (this.validateForm()) {
      {
        const { oldpassword, newpassword } = this.state;
        let new_data = {
          oldPassword: Base64.encode(oldpassword),
          newPassword: Base64.encode(newpassword),
        };
        await this.props.changePwd(new_data).then((res) => {
          this.setState({ isLoading: false });
          if (res.data?.portalErrorCode === 200) {
            this.clearInputs();
            NotificationManager.success("Password updated successfully");
          } else if (!res.data.isError) {
            errors["oldpassword"] = "Enter valid password";
          } else {
            errors["otherMessage"] = "Error while changing password";
          }
          this.setState({ errors });
        });
      }
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleSubmit = () => {
    if (this.state.nationality) {
      return this.props.history.push({
        pathname: "/dashboard/client-information-list",
      });
    } else {
      NotificationManager.error("Please select nationality.");
    }
  };

  handleCancel = () => {
    return this.props.history.push({
      pathname: "/dashboard/client-information-list",
    });
  };

  render() {
    const { oldpassword, newpassword, confirmpassword, errors, nationality } =
      this.state;
    const selectedvalue = {
      label: nationality,
    };
    return (
      <>
        <ExternalHeader />
        <div className="profileChangePassword">
          <div className="profileHeader w-100 mb-3">
            <h2 className="heading-midium">Profile</h2>
            <p>
              It is mandatory to add the nationality & change the password if
              you are login first time.{" "}
            </p>
          </div>
          <div className="profileScreen">
            <form
              onSubmit={this.handleSubmit}
              className="form-data changepassword"
            >
              <div className="profileContainer d-flex rounded">
                <div className="profileForm p-3">
                  <h3 className="heading-midium mb-2">Update Profile</h3>
                  <p>Please update your profile</p>

                  <div className="formGroup mb-2">
                    <label className="d-block">Name</label>
                    <input
                      type="text"
                      value={this.state.name}
                      name="name"
                      className="inputstyle"
                      disabled
                    />
                  </div>
                  <div className="formGroup mb-2">
                    <label className="d-block">Email</label>
                    <input
                      type="email"
                      value={this.state.email}
                      name="email"
                      className="inputstyle"
                      disabled
                    />
                  </div>
                  <div className="formGroup mb-2">
                    <label className="d-block">Phone Number</label>
                    <PhoneInput
                      country={"nl"}
                      className="inputstyle"
                      name="phoneNo"
                      value={this.state.phoneNo}
                      disabled
                    />
                  </div>

                  <div className="formGroup mb-2">
                    <label className="d-block">Nationality</label>
                    <Select
                      options={countryList().getData()}
                      value={selectedvalue}
                      onChange={this.handleNationality}
                      placeholder={!selectedvalue.label && <div>select...</div>}
                    />
                  </div>
                  <div className="errorMsg">{errors.nationality}</div>
                  <div className="formGroup mb-2">
                    <Button
                      className="action-btn pl-3 pr-3 mt-3 mb-4"
                      onClick={this.handleSaveProfile}
                    >
                      Save Profile
                    </Button>
                  </div>
                </div>

                <div className="LoginWindow p-3">
                  <h1 className="heading-midium mb-2">Change Password</h1>
                  <p>Please change your password.</p>

                  {this.state.isLoading && <div className="loader"></div>}
                  <div className="errorMsg">{errors.otherMessage}</div>

                  <div className="formGroup mb-2">
                    <label>Current Password</label>
                    <input
                      type="password"
                      value={oldpassword}
                      name="oldpassword"
                      placeholder="Enter current password"
                      className="inputstyle"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{errors.oldpassword}</div>
                  </div>

                  <div className="formGroup mb-2">
                    <label>New Password</label>
                    <input
                      type="password"
                      value={newpassword}
                      name="newpassword"
                      placeholder="Enter new password"
                      className="inputstyle"
                      onChange={this.handleChange}
                    />
                    <div className="helptext">
                      Password should be at least 8 characters with mix of
                      letters, numbers &amp; numeric characters.
                    </div>

                    <div className="errorMsg">{errors.newpassword}</div>
                  </div>

                  <div className="formGroup mb-2">
                    <label>Confirm Password</label>
                    <input
                      type="password"
                      value={confirmpassword}
                      name="confirmpassword"
                      placeholder="Enter confirm password"
                      className="inputstyle"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{errors.confirmpassword}</div>
                    <div className="formGroup mb-2">
                      <Button
                        className="action-btn pl-3 pr-3 mt-3 mb-4"
                        onClick={this.handleChangePwd}
                      >
                        Update Password
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 text-center w-100 mt-3">
                <Button
                  className="action-btn pl-3 pr-3 mt-3 mb-4"
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
                <Button
                  className="ml-3 pl-3 pr-3 mt-3 mb-4 btn btn-light"
                  onClick={this.handleCancel}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePwd: (data) => dispatch(changePwd(data)),
    setProfile: (data) => dispatch(setProfile(data)),
    getProfile: () => dispatch(getProfile()),
  };
};
export default connect(null, mapDispatchToProps)(changepassword);
