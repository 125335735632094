import { Route, Redirect } from "react-router-dom";

const UBORoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("ubo-form-token") ? (
          <Component {...props} />
        ) : (
          <Redirect to="/ubo-authentication" />
        )
      }
    ></Route>
  );
};
export default UBORoutes;
