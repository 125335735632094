import React from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";
import CheckBox from "../../../../elements/CheckBox";
import InternalFileUpload from "../../../../elements/InternalFileUpload";
import {
  setMaltaInternalCAFDetails,
  getMaltaInternalCAFDetails,
} from "../../../../../redux/actions/Malta/MaltaInternalCAFActions";
import "../../Onboarding/internalCAF.scss";
import { connect } from "react-redux";
import NotificationManager from "react-notifications/lib/NotificationManager";
import AutoSaving from "../AutoSaving";
import { setMaltaInternalChapterError } from "../../../../../redux/actions/Malta/MaltaInternalChapterErrorActions";
import getSequentialFields from "../getSequentialFields";
import { chapValidationErrorMalta, fileAcceptType } from "../Constants";

const chapter = "chapter10";
const reducer = "MaltaInternalCAF";

let timer;

class Chapter10 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formErrorsChapter10: {},
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: "all",
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.getMaltaInternalCAFDetails(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          Object.keys(this.props.internalErrors.errors).length === 0 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationErrorMalta.forEach(async (element) => {
            let response =
              res?.data?.result?.cafInternalValidations[element.chapter];

            const newResponse = await Promise.resolve(
              getSequentialFields(response, element.chapter, false)
            );
            this.props.setMaltaInternalChapterError({
              form: `formErrors${element.chapter}`,
              errors: newResponse?.errorChapter,
            });
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;

    this.props.setMaltaInternalCAFDetails(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.props.ClientAcceptance.chapter10["dirty"] = false;
        let response = res.data.result;

        const newResponse = await Promise.resolve(
          getSequentialFields(response, chapter, true)
        );
        this.props.setMaltaInternalChapterError({
          errors: newResponse?.errorChapter,
          form: "formErrorschapter10",
        });
        if (!res.data.result.isValid) {
          NotificationManager.info(
            "All fields are mandatory to fill in",
            null,
            4000
          );
          timer = setTimeout(() => {
            return this.props.history.push({
              pathname: "/malta/summary-sheet-caf",
            });
          }, 4000);
        } else {
          NotificationManager.success("Form is submitted successfully.");

          return this.props.history.push({
            pathname: "/malta/summary-sheet-caf",
          });
        }
      }
    });
  };

  render() {
    const { ClientAcceptance, internalErrors } = this.props;
    const formErrorsChapter10 = JSON.stringify(internalErrors.errors);
    const errorsMsg = JSON.parse(formErrorsChapter10);

    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="cafHeader">
            <h2 className="m-0">Client Acceptance Form</h2>
          </div>
          <div className="internal_caf generic-form">
            <div className="html2pdf__page-break"></div>
            <h3 className="chapterttl">
              Miscellaneous – (Internet, brochures, social statements, etc.)
            </h3>
            <Row>
              <div className="col-lg-5">
                <CheckBox
                  label="Are there other matters that are notable or important?"
                  options={["Yes", "No"]}
                  name="isOtherMatterNotable"
                  id="isOtherMatterNotable"
                  chapter={chapter}
                  reducer={reducer}
                  isRequired={true}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.isOtherMatterNotable?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isOtherMatterNotable}
                    </span>
                  )}
              </div>
              {ClientAcceptance.chapter10.isOtherMatterNotable === "Yes" && (
                <>
                  <div classname="col-lg-6">
                    <InternalFileUpload
                      label="Upload relevant document"
                      chapter={chapter}
                      name="relevantDocs"
                      id={`relevantDocs`}
                      acceptType={fileAcceptType}
                      reducer={reducer}
                    />
                    {errorsMsg &&
                      errorsMsg?.isSubmitted === true &&
                      errorsMsg?.relevantDocs &&
                      errorsMsg?.relevantDocs?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errorsMsg.relevantDocs}
                        </span>
                      )}
                  </div>
                  <div className="col-lg-7">
                    <TextBox
                      label="Enter your comment"
                      placeholder="Enter your text"
                      name="otherMtrsImpCmnt"
                      id="otherMtrsImpCmnt"
                      chapter={chapter}
                      reducer={reducer}
                      isRequired={true}
                      className={
                        this.props.PdfGeneration === "Yes"
                          ? "txtBox"
                          : "form-control"
                      }
                    />
                    {errorsMsg &&
                      errorsMsg?.isSubmitted === true &&
                      errorsMsg?.otherMtrsImpCmnt?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errorsMsg.otherMtrsImpCmnt}
                        </span>
                      )}
                  </div>
                </>
              )}
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ClientAcceptance: state.MaltaInternalCAF,
    data: state,
    internalErrors: state.MaltaInternalChapterErrors.formErrorschapter10,
    errors: state.MaltaInternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMaltaInternalCAFDetails: async (data) =>
      dispatch(await getMaltaInternalCAFDetails(data)),
    setMaltaInternalCAFDetails: async (data) =>
      dispatch(await setMaltaInternalCAFDetails(data)),
    setMaltaInternalChapterError: (data) =>
      dispatch(setMaltaInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter10);
