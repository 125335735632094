import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import countryList from "react-select-country-list";
import CheckBox from "../../../../elements/CheckBox";
import DropDown from "../../../../elements/DropDown";
import TextBox from "../../../../elements/TextBox";

class NewClient extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isClientNew, doesClientIntrdNewPer, isRequired, errors } =
      this.props;

    return (
      <Row className="pt-3 pb-0">
        <div className="mb-2 col-lg-4">
          <CheckBox
            label={
              this.props.form === "Luxembourg"
                ? "Is the client new?"
                : "Is the target company related to any individuals UBO/s already known to United Group?"
            }
            options={["Yes", "No"]}
            name="isClientNew"
            id="isClientNew"
            chapter={this.props.chapter}
            reducer={this.props.externalReducer}
            disabled={true}
          />
        </div>
        {isClientNew === "Yes" && (
          <div className="mb-2 col-lg-8">
            <Row>
              <div className="mb-2 col-lg-6">
                <TextBox
                  label={
                    this.props.form === "Malta"
                      ? "How did the Promoter come to UIM Malta?"
                      : "How the client came to UIM S.A.?"
                  }
                  name="clientUIMMalta"
                  id="clientUIMMalta"
                  placeholder="Enter your text"
                  chapter={this.props.chapter}
                  reducer={this.props.externalReducer}
                  validationType="Text"
                  disabled={true}
                />
              </div>
              <div className="mb-2 col-lg-6">
                <TextBox
                  label="Name of the introducer"
                  name="introducerName"
                  id="introducerName"
                  placeholder="Enter your text"
                  chapter={this.props.chapter}
                  reducer={this.props.externalReducer}
                  validationType="Text"
                  disabled={true}
                />
              </div>
              <div className="mb-2 col-lg-6">
                <TextBox
                  label="Name of the company"
                  name="companyNameMal"
                  id="companyNameMal"
                  placeholder="Enter your text"
                  chapter={this.props.chapter}
                  reducer={this.props.externalReducer}
                  validationType="Text"
                  disabled={true}
                />
              </div>
              <div className="mb-2 col-lg-6">
                <DropDown
                  label="Nationality"
                  options={countryList().getData()}
                  name="introducerNationality"
                  id="introducerNationality"
                  chapter={this.props.chapter}
                  reducer={this.props.externalReducer}
                  validationType="Text"
                  disabled={true}
                />
              </div>
            </Row>
          </div>
        )}
        {isClientNew === "No" && (
          <div className="mb-2 col-lg-8">
            <Row>
              <div className="mb-2 col-lg-6">
                <CheckBox
                  label="Does the client introduced by any person?"
                  options={["Yes", "No"]}
                  name="doesClientIntrdNewPer"
                  id="doesClientIntrdNewPer"
                  chapter={this.props.chapter}
                  reducer={this.props.externalReducer}
                  disabled={true}
                />
              </div>
              {doesClientIntrdNewPer === "Yes" && (
                <>
                  <div className="mb-2 col-lg-6">
                    <TextBox
                      label="Name of the person"
                      name="clientPersName"
                      id="clientPersName"
                      placeholder="Enter your text"
                      chapter={this.props.chapter}
                      reducer={this.props.externalReducer}
                      validationType="Text"
                      disabled={true}
                    />
                  </div>
                  <div className="mb-2 col-lg-6">
                    <DropDown
                      label="Nationality"
                      options={countryList().getData()}
                      name="clientPersNationality"
                      id="clientPersNationality"
                      chapter={this.props.chapter}
                      reducer={this.props.externalReducer}
                      validationType="Text"
                      disabled={true}
                    />
                  </div>
                  <div className="mb-2 col-lg-6">
                    <TextBox
                      label="Name of the company"
                      name="clientPersComp"
                      id="clientPersComp"
                      placeholder="Enter your text"
                      chapter={this.props.chapter}
                      reducer={this.props.externalReducer}
                      validationType="Text"
                      disabled={true}
                    />
                  </div>
                  <div className="mb-2 col-lg-6">
                    <TextBox
                      label="Please add description"
                      name="descNewPer"
                      id="descNewPer"
                      placeholder="Enter your text"
                      chapter={this.props.chapter}
                      reducer={this.props.externalReducer}
                      validationType="Text"
                      disabled={true}
                    />
                  </div>
                </>
              )}
            </Row>
          </div>
        )}
        <div className="mb-2 col-lg-12">
          <TextBox
            label={
              this.props.form === "Malta"
                ? "Please give details of the activities of  the existing client of UIM."
                : "Enter your relavant comment for client"
            }
            name="relvClientComment"
            id="relvClientComment"
            placeholder="Enter your text"
            chapter={this.props.chapter}
            reducer={this.props.reducer}
            validationType="Text"
            isMultipleLines={true}
            isRequired={isRequired}
          />
          {errors &&
            errors?.relvClientComment &&
            errors?.relvClientComment?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors?.relvClientComment}
              </span>
            )}
        </div>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { externalReducer } = ownProps;
  return {
    isClientNew: state[externalReducer]["chapter1"]["isClientNew"],
    doesClientIntrdNewPer:
      state[externalReducer]["chapter1"]["doesClientIntrdNewPer"],
  };
};

export default connect(mapStateToProps, null)(NewClient);
