import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
class MaltaDeclaration extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const eMsg = JSON.parse(this.props.errorMsg);
    return (
      <>
        <h2 className="chapter-title">DECLARATION</h2>
        <Row className="pt-0 pb-2">
          <div className="mb-2 col-lg-12">
            <CheckBox
              options={[
                `I ${this.props.uboName} as prospective UBO, hereby declare that`,
              ]}
              reducer={this.props.reducer}
              chapter="partC"
              name="checkProspectUBO"
              id="checkProspectUBO"
            />

            <ul className="declarationlist mt-3 ml-5">
              <li>
                I am not resident in Malta, and neither am I acting on behalf of
                any other person whether resident in Malta or otherwise;
              </li>
              <li>
                I undertake to inform UIMM immediately, should I become tax
                resident in Malta;
              </li>

              <li>
                I have / have never (delete as applicable) been convicted of a
                criminal offence (with the exception of traffic contravention);{" "}
              </li>
              <li>
                I have / have never (delete as applicable) been adjudged
                bankrupt, or have/have not (delete as applicable) been involved
                in a company or legal entity as a shareholder/director/officer
                or manager which was adjudged bankrupt;{" "}
              </li>

              <li>
                I have/ have not had forced closure of any bank account with any
                financial institution, or of an entity of which I was a
                beneficia owner or director.
              </li>

              <li>
                I have / have never (delete as applicable) been the subject of
                an investigation by a governmental, professional or regulatory
                body in relation to fraud, money laundering, financing of
                terrorism, or have ever been connected with any business
                concern, company or entity as a shareholder/director/officer or
                manager, which has been so adjudged or subjected to an
                investigation, compulsorily wound up, or has entered into a
                compromise with its creditors.
              </li>

              <li>
                I am / am not (delete as applicable) a politically exposed
                person nor am I related to or a close associate of a politically
                exposed person;
              </li>

              <li>
                I am / am not (delete as applicable) tax in the US am/ am not
                (delete as applicable) a citizen/ green card holder and do not
                receive any income subject to taxation in the US;{" "}
              </li>

              <li>
                I as beneficial owner act /do not act (delete as applicable) on
                my own behalf and I am/am not (delete as applicable) acting in a
                fiduciary capacity or on behalf of third parties.{" "}
              </li>

              <li>
                I have availed myself/ have not availed myself (delete as
                applicable) from a citizenship by investment scheme.
              </li>
              <div className="html2pdf__page-break"></div>
              <li>
                I declare that I and/-or the entities within the group structure
                do / do not cooperate in transactions related to certain
                countries, parties or goods that are in violation with the EU
                and/or US sanction regulations.
              </li>

              <li>
                I declare that I and/-or the entities within the group structure
                are/ are not involved in importing or exporting goods from or to
                the following countries: Iran, Cuba North Korea, Sudan or Syria,
                Lybia, Bahamas, Botswana, Cambodia, Ghana, Mongolia, Pakistan,
                Panama, Syria, Trinidad and Tobago, Yemen, Zimbabwe.
              </li>
              <li>
                I declare that I and/-or the entities within the group structure
                do / do not cooperate in transactions related to certain
                countries, parties or goods that are in violation with the EU
                and/or US sanction regulations.
              </li>
              <li>
                I declare that the establishment of the Object Company is not
                intended to facilitate unlawful behavior, money laundering or
                funding of terrorism.
              </li>
              <li>
                I declare that I and/- or the entities within the group
                structure are / are not involved in activities (production,
                investment, trade or other activities) related to the nuclear
                weapons, cluster weapons, anti-personnel land mines, biological
                and chemical weapons.
              </li>
              <li>
                I declare that I and/ -or the entities within the group
                structure are / are not involved in human rights violations.
              </li>
              <li>
                I declare that all tangible and intangible assets and all other
                assets belonging to the Object Company (including cash and
                securities), and the income or proceeds thereof, as well as my
                interest in the Object Company, will be fully disclosed to the
                relevant tax- Authorities and, if required, other relevant
                authorities in my country of residence and in any other
                jurisdiction.
              </li>
              <li>
                Ever had or currently have any direct or indirect beneficial
                interest in or whether ever was or currently is a director of
                any other company or entity registered in Malta.
              </li>
              <li>
                I undertake to inform UIMM immediately, should any circumstances
                as noted above change.
              </li>
            </ul>
          </div>
          {this.props.errorMsg !== null &&
            Object.entries(eMsg).length !== 0 &&
            eMsg?.partC?.checkProspectUBO?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {eMsg?.partC?.checkProspectUBO}
              </span>
            )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uboName: state.UBODeclarationUIM.name,
  };
};

export default connect(mapStateToProps, null)(MaltaDeclaration);
