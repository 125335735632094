import React, { Component } from "react";
import { Row } from "react-bootstrap";
import countryList from "react-select-country-list";
import DropDown from "../../../../../../elements/DropDown";
import TextBox from "../../../../../../elements/TextBox";
import CheckBox from "../../../../../../elements/CheckBox";
import FileUpload from "../../../../../../elements/FileUpload";
import { fileAcceptType, NatureOfObjectComArray } from "../constants";
import { connect } from "react-redux";
class Trustees extends Component {
    constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, errorMsg,istrusPassport,istrusProofAdd,istrusRefLett} = this.props;
    const eMsg = errorMsg;
    return (
      <div className="mb-4 col-lg-12">
        <fieldset>
          <legend>Trustee {this.props.index + 1}</legend>
          <Row>
          <div className="mb-2 col-lg-6 col-xl-6">
            <TextBox
              label="Trustee name"
              name="trusteeName"
              id={`trusteeName[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              validationType="Text"
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false}
            />
           {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objTrustees &&
                    errorMsg.objTrustees[index] &&
                    errorMsg.objTrustees[index]
                      ?.trusteeName?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objTrustees[index]
                            ?.trusteeName
                        }
                      </span>
                    )}
          </div>
          <div className="mb-2 col-lg-6 col-xl-6">
            <TextBox
              label="Trustee address"
              name="trusteeAddress"
              id={`trusteeAddress[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              validationType="Text"
              chapter={this.props.chapter}
            />
            {/* {errors &&
              errors?.trusteeAddress &&
              errors?.trusteeAddress?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.trusteeAddress}
                </span>
              )} */}
          </div>
          <div className="mb-3 col-lg-6">
            <CheckBox
              label="Trustee's passport or national identity card available?"
              name="istrusPassport"
              options={["Yes", "No"]}
              id={`istrusPassport[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objTrustees &&
                    errorMsg.objTrustees[index] &&
                    errorMsg.objTrustees[index]
                      ?.istrusPassport?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objTrustees[index]
                            ?.istrusPassport
                        }
                      </span>
                    )}
          </div>
          {istrusPassport === "Yes" && (
            <div className="mb-3 col-lg-4">
            
              <FileUpload
                label="Upload Trustee's Passport or national identity"
                acceptType={fileAcceptType}
                name="trusPassport"
                id={`trusPassport[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              //isRequired={this.props.form === "UK" ? true : false} 
              />
              {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objTrustees &&
                    errorMsg.objTrustees[index] &&
                    errorMsg.objTrustees[index]
                      ?.trusPassport?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objTrustees[index]
                            ?.trusPassport
                        }
                      </span>
                    )}
            </div>
          )} 
           {istrusPassport === "No" && (
              <div className="mb-3 col-lg-4">
                  <TextBox
                label="Explanation"
                name="truePassportReason"
                id={`truePassportReason[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              validationType="Text"
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
              />
             {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objTrustees &&
                    errorMsg.objTrustees[index] &&
                    errorMsg.objTrustees[index]
                      ?.truePassportReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objTrustees[index]
                            ?.truePassportReason
                        }
                      </span>
                    )}

              </div>              
              )} 
        </Row> 
        <Row>
          <div className="mb-3 col-lg-6">
            <CheckBox
              label="Trustee's proof of address available?"
              name="istrusProofAdd"
              options={["Yes", "No"]}
              id={`istrusProofAdd[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objTrustees &&
                    errorMsg.objTrustees[index] &&
                    errorMsg.objTrustees[index]
                      ?.istrusProofAdd?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objTrustees[index]
                            ?.istrusProofAdd
                        }
                      </span>
                    )}
          </div>
          {istrusProofAdd === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Upload Trustee's proof of address"
                info="Not older than 3 months"
                acceptType={fileAcceptType}
                name="trusProofAdd"
                id={`trusProofAdd[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              //isRequired={this.props.form === "UK" ? true : false} 
              />
             {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objTrustees &&
                    errorMsg.objTrustees[index] &&
                    errorMsg.objTrustees[index]
                      ?.trusProofAdd?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objTrustees[index]
                            ?.trusProofAdd
                        }
                      </span>
                    )}
            </div>
          )}
          {istrusProofAdd === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="trusProofAddReason"
                id={`trusProofAddReason[${index}]`}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objTrustees &&
                    errorMsg.objTrustees[index] &&
                    errorMsg.objTrustees[index]
                      ?.trusProofAddReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objTrustees[index]
                            ?.trusProofAddReason
                        }
                      </span>
                    )}
            </div>
          )}
        </Row>   
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-6">
            <CheckBox
              label="Trustee's reference letter available?"
              name="istrusRefLett"
              options={["Yes", "No"]}
              id={`istrusRefLett[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
            />
             {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objTrustees &&
                    errorMsg.objTrustees[index] &&
                    errorMsg.objTrustees[index]
                      ?.istrusRefLett?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objTrustees[index]
                            ?.istrusRefLett
                        }
                      </span>
                    )}
          </div>
          {istrusRefLett === "Yes" && (
            <div className="mb-3 col-lg-4">
              <FileUpload
                label="Upload Trustee's reference letter"
                info="by Accountant, Banker or Lawyer"
                acceptType={fileAcceptType}
                name="trusRefLett"
                id={`trusRefLett[${index}]`}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                isRequired={this.props.form === "UK" ? true : false} 
              />
             {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objTrustees &&
                    errorMsg.objTrustees[index] &&
                    errorMsg.objTrustees[index]
                      ?.trusRefLett?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objTrustees[index]
                            ?.trusRefLett
                        }
                      </span>
                    )}
            </div>
          )}
          {istrusRefLett === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Explanation"
                name="trusRefLettReason"
                id={`trusRefLettReason[${index}]`}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}
                isRequired={this.props.form === "UK" ? true : false} 
              />
             {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objTrustees &&
                    errorMsg.objTrustees[index] &&
                    errorMsg.objTrustees[index]
                      ?.trusRefLettReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objTrustees[index]
                            ?.trusRefLettReason
                        }
                      </span>
                    )}
            </div>
          )}
        </Row>     
        </fieldset>
      </div>
    );
  }
}

//export default Trustees;
const mapStateToProps = (state, ownProps) => {
    const { baseName, index, reducer,objBaseName,indexObjBaseName } = ownProps;
    return {
        istrusPassport: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["istrusPassport"],
        istrusProofAdd: state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["istrusProofAdd"],
        istrusRefLett:state[reducer]["chapter3"][objBaseName][indexObjBaseName][baseName][index]["istrusRefLett"],
    };
  };
  
  export default connect(mapStateToProps, null)(Trustees);
