export const fileAcceptType = ".doc,.docx,.odt,.pdf,.rtf,.tex,.txt,.wpd,.ods,.xls,.xlsm,.xlsx,.key,.odp,.pps,.ppt,.pptx,.jpeg,.jpg,.png";
export const NatureOfObjectComArray = [
    { value: "Holding", label: "Holding" },
    { value: "Financing", label: "Financing" },
    { value: "Royalty", label: "Royalty" },
    { value: "Operational", label: "Operational" },
    { value: "Counsultancy/Management", label: "Counsultancy/Management" },
    { value: "Other", label: "Other" },
  ];

  export function rationaleArray(office) {
    let rationales = [
      {
        label: "Tax planning",
        isChecked: false,
      },
      {
        label: "Asset protection",
        isChecked: false,
      },
      {
        label: "Identity protection",
        isChecked: false,
      },
      {
        label: "Investment protection",
        isChecked: false,
      },
      {
        label: "Stable legal infrastructure",
        isChecked: false,
      },
      {
        label: `Geographical location of ${office}`,
        isChecked: false,
      },
      {
        label: "Participation exemption",
        isChecked: false,
      },
      {
        label: "Other commercial reasons",
        isChecked: false,
      },
    ];
  
    return rationales;
  }