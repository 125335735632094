import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import countryList from "react-select-country-list";
import CheckBox from "../../../../../elements/CheckBox";
import DateTimePicker from "../../../../../elements/DateTimePicker";
import DropDown from "../../../../../elements/DropDown";
import PhoneInputField from "../../../../../elements/PhoneInputField";
import TextBox from "../../../../../elements/TextBox";

class OCSecretary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isCmpSecExist, chapter, errorMsg } = this.props;
    const eMsg = JSON.parse(errorMsg);
    return (
      <Row className="pt-0 pb-2">
        <div className="mb-3 col-lg-4">
          <CheckBox
            label="Is there a company secretary?"
            options={["Yes", "No"]}
            name="isCmpSecExist"
            id="isCmpSecExist"
            objBaseName="cmpSectr"
            chapter={chapter}
            reducer={this.props.reducer}
          />
          {eMsg && eMsg?.isCmpSecExist && eMsg?.isCmpSecExist?.length > 0 && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {eMsg?.isCmpSecExist}
            </span>
          )}
        </div>
        {isCmpSecExist === "Yes" && (
          <>
            <div className="mb-3 col-lg-8">
              <Row>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Name of the company secretary"
                    name="cmpSectName"
                    id={`cmpSectName`}
                    placeholder="enter your text"
                    objBaseName="cmpSectr"
                    chapter={chapter}
                    reducer={this.props.reducer}
                    validationType="Text"
                  />
                  {eMsg &&
                    eMsg?.cmpSectName &&
                    eMsg?.cmpSectName?.length > 0 && (
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {eMsg?.cmpSectName}
                      </span>
                    )}
                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Residental address"
                    name="cmpSecResAddress"
                    id={`cmpSecResAddress`}
                    placeholder="enter your text"
                    objBaseName="cmpSectr"
                    chapter={chapter}
                    reducer={this.props.reducer}
                    validationType="Text"
                  />
                  {eMsg &&
                    eMsg?.cmpSecResAddress &&
                    eMsg?.cmpSecResAddress?.length > 0 && (
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {eMsg?.cmpSecResAddress}
                      </span>
                    )}
                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Passport/Identity/Registration No."
                    name="cmpPassIdntyRegNum"
                    id={`cmpPassIdntyRegNum`}
                    placeholder="enter your text"
                    objBaseName="cmpSectr"
                    chapter={chapter}
                    reducer={this.props.reducer}
                    validationType="Text"
                  />
                  {eMsg &&
                    eMsg?.cmpPassIdntyRegNum &&
                    eMsg?.cmpPassIdntyRegNum?.length > 0 && (
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {eMsg?.cmpPassIdntyRegNum}
                      </span>
                    )}
                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Place of issue"
                    name="cmpPlaceOfIss"
                    id={`cmpPlaceOfIss`}
                    placeholder="enter your text"
                    objBaseName="cmpSectr"
                    chapter={chapter}
                    reducer={this.props.reducer}
                    validationType="Text"
                  />
                  {eMsg &&
                    eMsg?.cmpPlaceOfIss &&
                    eMsg?.cmpPlaceOfIss?.length > 0 && (
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {eMsg?.cmpPlaceOfIss}
                      </span>
                    )}
                </div>
                <div className="mb-3 col-lg-6" id={`cmpDtIssue`}>
                  <DateTimePicker
                    label="Date of issue"
                    name="cmpDtIssue"
                    chapter={chapter}
                    objBaseName="cmpSectr"
                    reducer={this.props.reducer}
                  />
                  {eMsg && eMsg?.cmpDtIssue && eMsg?.cmpDtIssue?.length > 0 && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {eMsg?.cmpDtIssue}
                    </span>
                  )}
                </div>
                <div className="mb-3 col-lg-6" id={`cmpExprDt`}>
                  <DateTimePicker
                    label="Expiry date"
                    name="cmpExprDt"
                    maxDate={true}
                    chapter={chapter}
                    objBaseName="cmpSectr"
                    reducer={this.props.reducer}
                  />
                  {eMsg && eMsg?.cmpExprDt && eMsg?.cmpExprDt?.length > 0 && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {eMsg?.cmpExprDt}
                    </span>
                  )}
                </div>
                <div className="mb-3 col-lg-6" id={`cmpTelPhnNum`}>
                  <PhoneInputField
                    label="Telephone No."
                    name="cmpTelPhnNum"
                    chapter={chapter}
                    objBaseName="cmpSectr"
                    reducer={this.props.reducer}
                    country={this.props.form}
                  />
                  {eMsg &&
                    eMsg?.cmpTelPhnNum &&
                    eMsg?.cmpTelPhnNum?.length > 0 && (
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {eMsg?.cmpTelPhnNum}
                      </span>
                    )}
                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Email address"
                    name="cmpEmail"
                    id={`cmpEmail`}
                    placeholder="enter your text"
                    objBaseName="cmpSectr"
                    chapter={chapter}
                    reducer={this.props.reducer}
                    validationType="Email"
                    ShowValidation= {eMsg && eMsg?.cmpEmail && eMsg?.cmpEmail?.length > 0 ? false:true}
                  />
                  {eMsg && eMsg?.cmpEmail && eMsg?.cmpEmail?.length > 0 && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {eMsg?.cmpEmail}
                    </span>
                  )}
                </div>
                <div className="mb-3 col-lg-6">
                  <DropDown
                    label="Nationality"
                    name="cmpSecNationality"
                    options={countryList().getData()}
                    id={`cmpSecNationality`}
                    objBaseName="cmpSectr"
                    chapter={chapter}
                    reducer={this.props.reducer}
                  />
                  {eMsg &&
                    eMsg?.cmpSecNationality &&
                    eMsg?.cmpSecNationality?.length > 0 && (
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {eMsg?.cmpSecNationality}
                      </span>
                    )}
                </div>
              </Row>
            </div>
          </>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer } = ownProps;
  return {
    isCmpSecExist: state[reducer]["chapter4"]["cmpSectr"]["isCmpSecExist"],
  };
};

export default connect(mapStateToProps, null)(OCSecretary);
