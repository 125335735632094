import axios from "axios";
const BACKEND_API_URL = process.env.REACT_APP_USER_BACKEND_API_URL;

const setNewCommonFieldsToReducerReceiveData = (json) => {
  return {
    type: "UPDATE_NEW_COMMON_DATA",
    data: json,
  };
};

export const setNewCommonFieldsToReducer = (data) => {
  return (dispatch) => {
    dispatch(setNewCommonFieldsToReducerReceiveData(data));
  };
};
const SetCommonFieldsRequest = () => {
  return {
    type: "SET_COMMON_FIELDS_REQUEST",
  };
};

const SetCommonFieldsReceiveData = (json) => {
  return {
    type: "SET_COMMON_FIELDS_SUCCESS",
    data: json,
  };
};

export const setCommonFields = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(SetCommonFieldsRequest());
    return axios
      .post(`${BACKEND_API_URL}/CommonFields/SetCommonFields`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: "SET_COMMON_FIELDS_FAIL", data: json });
        } else {
          return dispatch(SetCommonFieldsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "SET_COMMON_FIELDS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};

const GetCommonFieldsRequest = () => {
  return {
    type: "GET_COMMON_FIELDS_REQUEST",
  };
};

const GetCommonFieldsReceiveData = (json) => {
  return {
    type: "GET_COMMON_FIELDS_SUCCESS",
    data: json,
  };
};

export const getCommonFields = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
    "Content-Type": "application/json",
  };
  return (dispatch) => {
    dispatch(GetCommonFieldsRequest());
    return axios
      .post(
        `${BACKEND_API_URL}/CommonFields/GetCommonFields`,
        JSON.stringify(data),
        {
          headers,
        }
      )
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: "GET_COMMON_FIELDS_FAIL", data: json });
        } else {
          return dispatch(GetCommonFieldsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "GET_COMMON_FIELDS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};
