import React, { Component } from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";
import DropDown from "../../../../elements/DropDown";
import countryList from "react-select-country-list";
class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, errorMsg } = this.props;
    const eMsg = errorMsg;
    return (
      <div className="mb-4 col-lg-12">
        <fieldset>
          <legend>Client {this.props.index + 1}</legend>
          <Row>

            <div className="mb-2 col-lg-6 col-xl-4">
              <TextBox
                label="Client name"
                name="clientName"
                id={`clientName[${index}]`}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}              
              />
             
            </div>
           
            <div className="mb-2 col-lg-6 col-xl-4">
              <DropDown
                label="Country"
                name="country"
                id={`country[${index}]`}
                options={countryList().getData()}
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                baseName={this.props.baseName}
                index={this.props.index}
                chapter={this.props.chapter}
                reducer={this.props.reducer}                
              />
             
            </div>
            <div className="mb-2 col-lg-6 col-xl-12">
              <TextBox
                label="Provide details of existing relationship"
                name="explainDetails"
                id={`explainDetails[${index}]`}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                validationType="Text"
                chapter={this.props.chapter}
                isMultipleLines={true}             
              />
             
            </div>
          </Row>
        </fieldset>
      </div>
    );
  }
}

export default Client;
