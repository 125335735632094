import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setDataToReducer } from "../../../../../../redux/actions/ClientInformationAction";
import { RightsAndObligationArray } from "./Constants";
import Select from "react-select";
import Modal from "../../../../../components/shared/Modal";
import { getReducer } from "../../../../../elements/getReducer";
import cc from "currency-codes";

let currencies = [];
cc.codes().map((currency) => currencies.push({ label: currency }));
class rightObligationCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      id: null,
      rightObligation: "",
      currency: "",
      amount: "",
      counterparty: "",
      purpose: "",
      errors: {},
      addForm: false,
      toggleCleared: false,
      localCurrency: null,
      selectedRows: [],
      columns: [
        {
          name:(<>{this.props.form === "NL" ?(<>Agreements</>):( <> Rights and Obligation </>)}</>),
          selector: (row) => row.obligation,
        },

        {
          name: "Counterparty",
          selector: (row) => row.counterparty,
        },
        {
          name: "Currency",
          selector: (row) => row.currency,
        },
        {
          name: "Amount",
          selector: (row) => row.amount,
        },
        {
          name: "Purpose",
          selector: (row) => row.purpose,
        },
        {
          title: "",
          cell: (row) => (
            <div className="Edit-Btn">
              <button
                type="button"
                className="action-btn edit"
                onClick={() => this.handleEdit(row)}
                style={{
                  pointerEvents:
                    this.props.from !== "clientInformation" ? "none" : "",
                }}
              >
                Edit
              </button>
            </div>
          ),
        },
      ],
    };
  }

  modalOpen = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  modalClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  cancelForm = () => {
    this.setState({
      isEdit: false,
      addForm: false,
    });
  };

  handleAddForm = () => {
    this.setState({
      id: null,
      rightObligation: "",
      currency: "",
      amount: "",
      counterparty: "",
      purpose: "",
      isEdit: false,
      addForm: true,
    });
  };

  clearInputs = () => {
    this.setState({
      id: null,
      rightObligation: "",
      currency: "",
      amount: "",
      counterparty: "",
      purpose: "",
      localCurrency: null,
    });
  };

  handleEdit = (row) => {
    this.setState({
      isEdit: true,
      addForm: true,
      id: row.id,
      rightObligation: row.obligation,
      currency: row.currency,
      amount: row.amount,
      counterparty: row.counterparty,
      purpose: row.purpose,
    });
  };

  handleRightsChange = (e) => {
    this.setState({ rightObligation: e.label });
    this.setState({
      errors: {
        ...this.state.errors,
        rightObligation: "",
      },
    });
  };

  handleCurrencyChange = (e) => {
    this.setState({ currency: e.label });
    this.setState({
      errors: {
        ...this.state.errors,
        currency: "",
      },
    });
  };

  //handle change on all inputs
  handleChange = (event) => {
    let ferrors = this.state.errors;
    let formIsValid = true;
    const { name, value } = event.target;
    if (name === "amount") {
      var numbers = /^\d+(\.\d{1,2})?$/;
      if (!numbers.test(value) && value.length > 0) {
        formIsValid = false;
        ferrors["amount"] = "Please enter valid amount";
      } else {
        formIsValid = true;
        ferrors["amount"] = "";
      }
      this.setState({
        errors: ferrors,
      });
    }
    this.setState({
      [name]: value,
    });
    // this.setState({
    //   errors: {
    //     ...this.state.errors,
    //     [name]: "",
    //   },
    // });
  };

  validateForm = () => {
    let errors = {};
    let formIsValid = true;
    const { purpose, counterparty, amount, currency, rightObligation } =
      this.state;
    if (!purpose) {
      formIsValid = false;
      errors["purpose"] = "Please enter purpose";
    }
    if (!counterparty) {
      formIsValid = false;
      errors["counterparty"] = "Please enter counterparty";
    }
    if (!amount) {
      formIsValid = false;
      errors["amount"] = "Please enter amount";
    }
    if (amount) {
      var numbers = /^\d+(\.\d{1,2})?$/;
      if (!String(amount).match(numbers)) {
        formIsValid = false;
        errors["amount"] = "Please enter valid amount";
      }
    }
    if (!currency) {
      formIsValid = false;
      errors["currency"] = "Please select currency";
    }
    if (!rightObligation) {
      formIsValid = false;
      errors["rightObligation"] = "Please select right and obligation type";
    }
    this.setState({ errors });
    return formIsValid;
  };

  handleSubmit = async () => {
    const { purpose, counterparty, amount, currency, rightObligation, id } =
      this.state;
    let array = this.props.rightsAndObligations;
    if (this.validateForm()) {
      if (this.state.isEdit) {
        const index = array.findIndex((f) => f.id === id);
        let obj = array[index];
        obj = {
          ...obj,
          purpose: purpose,
          counterparty: counterparty,
          amount: amount,
          currency: currency,
          obligation: rightObligation,
        };
        array[index] = obj;
      } else {
        let Id =
          array.length === 0
            ? 1
            : Math.max(
                ...array.map(function (i) {
                  return i.id;
                })
              ) + 1;
        let obj = {
          id: String(Id),
          purpose: purpose,
          counterparty: counterparty,
          amount: amount,
          currency: currency,
          obligation: rightObligation,
        };
        array.push(obj);
      }
      this.props.setDataToReducer({
        type: getReducer(this.props.reducer),
        chapter: this.props.chapter,
        name: "rightsAndObligations",
        value: array,
        objBaseName: "srcFundsObjComp",
      });
      this.clearInputs();
      this.cancelForm();
    }
  };

  handleRowSelection = (selectedRows) => {
    this.setState({ selectedRows: selectedRows });
  };

  handleDelete = () => {
    this.modalClose();
    let array = this.props.rightsAndObligations;
    let selectedRows = this.state.selectedRows.selectedRows;
    let IdArray = [];
    selectedRows.map((obj) => {
      IdArray.push(obj.id);
    });
    let newArray = array.filter((obj) => !IdArray.includes(obj.id));

    this.props.setDataToReducer({
      type: getReducer(this.props.reducer),
      chapter: this.props.chapter,
      name: "rightsAndObligations",
      value: newArray,
      objBaseName: "srcFundsObjComp",
    });
    this.setState({
      selectedRows: [],
      toggleCleared: !this.state.toggleCleared,
    });
  };

  render() {
    const {
      addForm,
      purpose,
      counterparty,
      amount,
      currency,
      toggleCleared,
      errors,
      selectedRows,
    } = this.state;
    const selectedObligation = {
      label: this.state.rightObligation,
    };

    return (
      <>
        <Container fluid>
          <Row>
            <Col sm={6} className="text-left d-flex">
              {selectedRows?.selectedRows?.length > 0 && (
                <button
                  className="action-btn delete"
                  onClick={this.modalOpen}
                  style={{
                    pointerEvents:
                      this.props.from !== "clientInformation" ? "none" : "",
                  }}
                >
                  Delete
                </button>
              )}
            </Col>
            <Col sm={6} className="text-right add-button">
              <Link
                to="#"
                className="action-btn big addRightBtn"
                onClick={this.handleAddForm}
                style={{
                  pointerEvents:
                    this.props.from === "clientInformation" ? "" : "none",
                }}
              >
                 {this.props.form === "NL" ?(<>Add Agreements</>):( <>  Add Rights and Obligations </>)}
               
              </Link>
            </Col>
          </Row>
        </Container>

        <DataTable
          title={<p className="col-md-12 mb-3">
             {this.props.form === "NL" ?(<>Describe the agreements entered into by Object company</>):( <> Describe the rights and obligations of the Object Company </>)}
        
          {this.props.form === "NL" && this.props.from === "clientInformation" && <span style={{ color: "red" }}>*</span>}
        </p> }
          columns={this.state.columns}
          data={[...this.props.rightsAndObligations]}
          selectableRows
          pagination
          onSelectedRowsChange={this.handleRowSelection}
          clearSelectedRows={toggleCleared}
        />
        {addForm && (
          <div className="addFormForCAF mt-3">
            <div className="col-md-8 m-auto pt-3">
              <Row>
                <div className="mb-2 col-lg-6">
                  <label> {this.props.form === "NL" ?(<>Agreements</>):( <> Rights and Obligation </>)}</label>
                  <Select
                    options={RightsAndObligationArray}
                    value={
                      !selectedObligation.label
                        ? selectedObligation.label
                        : selectedObligation
                    }
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.label}
                    onChange={this.handleRightsChange}
                    placeholder={
                      !selectedObligation.label && (
                        <div>select rights and obligations</div>
                      )
                    }
                  />
                  <div className="errorMsg">{errors.rightObligation}</div>
                </div>
                <div className="mb-2 col-lg-6">
                  <label>Currency</label>
                  <Select
                    options={currencies}
                    value={currency ? currency : ""}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.label}
                    onChange={this.handleCurrencyChange}
                    placeholder={
                      currency ? currency : <div>select currency</div>
                    }
                  />
                  <div className="errorMsg">{errors.currency}</div>
                </div>
                <div className="mb-2 col-lg-4">
                  <label>Amount</label>
                  <input
                    type="text"
                    name="amount"
                    className="form-control"
                    placeholder="enter amount"
                    onChange={this.handleChange}
                    value={amount}
                  ></input>

                  <div className="errorMsg">{errors.amount}</div>
                </div>
                <div className="mb-2 col-lg-4">
                  <label>Counterparty</label>
                  <input
                    type="text"
                    name="counterparty"
                    className="form-control"
                    placeholder="enter counterparty"
                    onChange={this.handleChange}
                    value={counterparty}
                  ></input>
                  <div className="errorMsg">{errors.counterparty}</div>
                </div>
                <div className="mb-2 col-lg-4">
                  <label>Purpose</label>
                  <input
                    type="text"
                    name="purpose"
                    className="form-control"
                    placeholder="enter purpose"
                    onChange={this.handleChange}
                    value={purpose}
                  ></input>
                  <div className="errorMsg">{errors.purpose}</div>
                </div>
              </Row>
            </div>
            <Row className="pt-4 pb-2 text-center">
              <div className="mb-2 col-lg-12 ">
                <button
                  type="button"
                  className="action-btn noicon pl-3 pr-3"
                  onClick={this.handleSubmit}
                >
                  {this.state.isEdit ? "Edit Row" : "Add Row"}
                </button>
                <button
                  type="button"
                  className="action-btn link noicon pl-4 pr-4"
                  onClick={this.cancelForm}
                >
                  Cancel
                </button>
              </div>
            </Row>
          </div>
        )}
        <Modal
          show={this.state.isModalOpen}
          handleClose={(e) => this.modalClose(e)}
        >
          <h4 className="mb-4">Are you sure you want to delete?</h4>

          <div className="form-group mb-0">
            <button
              onClick={(e) => this.handleDelete(e)}
              type="button"
              className="action-btn noicon mr-2"
            >
              Yes
            </button>
            <button
              onClick={this.modalClose}
              type="button"
              className="action-btn greybtn noicon"
            >
              No
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { form, reducer, chapter } = ownProps;
  if (form === "NL") {
    return {
      rightsAndObligations:
        state.commonFields.srcFundsObjComp["rightsAndObligations"],
    };
  } else {
    return {
      rightsAndObligations:
        state[reducer][chapter]["srcFundsObjComp"]["rightsAndObligations"],
    };
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(rightObligationCompany);
