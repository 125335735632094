import { combineReducers } from "redux";
import clientReducer from "./clientReducer";
import userReducer from "./userReducer";
import MSLoginReducer from "./MSLoginReducer";
import CAFGenericReducer from "./CAFGenericReducer";
import ClientInformationReducer from "./ClientInformationReducer";
import GenericDetailForReducer from "./GenericDetailForReducer";
import AnnexUBOReducer from "./AnnexUBOReducer";
import UBODeclarationUIMReducer from "./UBODeclarationUIMReducer";
import UBOPEPReducer from "./UBOPEPReducer";
import CommonFieldsReducer from "./CommonFieldsReducer";
import ClientFormListReducer from "./ClientFormListReducer";
import ClientAcceptanceFormReducer from "./ClientAcceptanceFormReducer";
import InternalFileHandleReducer from "./InternalFileHandleReducer";
import internalChapterErrorReducer from "./InternalChapterErrorReducer";
import UKClientInformationReducer from "./UK/UKClientInformationReducer";
import UKInternalCAFReducer from "./UK/UKInternalCAFReducer";
import UKInternalChapterErrorReducer from "./UK/UKInternalChapterErrorReducer";
import CuracaoClientInformationReducer from "./Curacao/CuracaoClientInformationReducer";
import CuracaoInternalCAFReducer from "./Curacao/CuracaoInternalCAFReducer";
import CuracaoInternalChapterErrors from "./Curacao/CuracaoInternalChapterErrorReducer";
import MaltaClientInformationReducer from "./Malta/MaltaClientInformationReducer";
import MaltaInternalCAFReducer from "./Malta/MaltaInternalCAFReducer";
import LuxembourgClientInformationReducer from "./Luxembourg/LuxembourgClientInformationReducer";
import LuxembourgInternalCAFReducer from "./Luxembourg/LuxembourgInternalCAFReducer";
import MaltaInternalChapterErrorReducer from "./Malta/MaltaInternalChapterErrorReducer";
import LuxembourgInternalChapterErrors from "./Luxembourg/LuxembourgInternalChapterErrors";
export default combineReducers({
  user: userReducer,
  client: clientReducer,
  MSLoginReducer,
  CAFGeneric: CAFGenericReducer,
  clientInformation: ClientInformationReducer,
  genericInformation: GenericDetailForReducer,
  AnnexUBOInformation: AnnexUBOReducer,
  UBODeclarationUIM: UBODeclarationUIMReducer,
  UBOPEP: UBOPEPReducer,
  commonFields: CommonFieldsReducer,
  ClientFormList: ClientFormListReducer,
  ClientAcceptance: ClientAcceptanceFormReducer,
  InternalFileHandle: InternalFileHandleReducer,
  InternalChapterErrors: internalChapterErrorReducer,
  UKClientInformation: UKClientInformationReducer,
  UKInternalCAF: UKInternalCAFReducer,
  UKInternalChapterError: UKInternalChapterErrorReducer,
  CuracaoClientInformation: CuracaoClientInformationReducer,
  CuracaoInternalCAF: CuracaoInternalCAFReducer,
  CuracaoInternalChapterErrors: CuracaoInternalChapterErrors,
  MaltaClientInformation: MaltaClientInformationReducer,
  MaltaInternalCAF: MaltaInternalCAFReducer,
  MaltaInternalChapterErrors: MaltaInternalChapterErrorReducer,
  LuxembourgClientInformation: LuxembourgClientInformationReducer,
  LuxembourgInternalCAF: LuxembourgInternalCAFReducer,
  LuxembourgInternalChapterErrors: LuxembourgInternalChapterErrors,
});
