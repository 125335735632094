import React, { Component } from "react";
import { setDataToReducer } from "../../redux/actions/ClientInformationAction";
import {
  fileUpload,
  fileDelete,
  fileDownload,
} from "../../redux/actions/InternalFileHandleActions";
import { connect } from "react-redux";
import { saveAs } from "file-saver";
import { NotificationManager } from "react-notifications";
import { getReducer } from "./getReducer";
import Tooltip from "./Tooltip";
const validExtensions = [
  "doc",
  "docx",
  "odt",
  "pdf",
  "rtf",
  "tex",
  "txt",
  "wpd",
  "ods",
  "xls",
  "xlsm",
  "xlsx",
  "key",
  "odp",
  "pps",
  "ppt",
  "pptx",
  "jpeg",
  "jpg",
  "png",
];
class InternalFileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileArray: this.props.value,
      user: JSON.parse(localStorage.getItem("formData")),
      office: localStorage.getItem("Office"),
      companyName: localStorage.getItem("CompanyName"),
    };
  }
  componentDidMount = () => {
    if (this.props.value && this.props.value.length > 0) {
      this.setState({ fileArray: this.props.value });
    } else {
      this.setState({ fileArray: [] });
    }
  };

  handleFileChange = async (event, fileArray) => {
    const { user } = this.state;
    const array = this.state.fileArray;
    const newFiles = event.target.files;
    for (let i = 0; i < newFiles.length; i++) {
      let fname = newFiles[i].name;
      let extension = fname.split(".").pop();
      if (validExtensions.includes(extension)) {
      const formData = new FormData();
      formData.append(`file`, newFiles[i]);
      formData.append("UserEmail", user.userEmail);
      formData.append("UserName", user.userName);
      formData.append("Office", user.office);
      formData.append("CompanyName", user.clientName);
      formData.append("CompanyId", user.clientId);
      await this.props.fileUpload(formData).then((res) => {
        if (res.data.portalErrorCode === 200) {
          let newObject = {};
          newObject["fileName"] = newFiles[i].name;
          array.push(newObject);
          this.setState({
            fileArray: array,
          });
          NotificationManager.success(
            `${newFiles[i].name} uploaded successfully`
          );
        } else if (res.data.portalErrorCode === 500) {
          NotificationManager.error(`${newFiles[i].name} is already exist`);
        }
        else if (res.data.portalErrorCode === 400) {

          NotificationManager.error(`This file format is not supported.`);

        }
        else {
          console.log(res.data);
          NotificationManager.error(
            `Error while uploading ${newFiles[i].name}`
          );
        }
      });
    }
    else{
      NotificationManager.error(`.${extension} file format is not allowed.`);
    }
    }

    await this.props.setDataToReducer({
      type: getReducer(this.props.reducer),
      chapter: this.props.chapter,
      index: this.props.index,
      name: this.props.name,
      value: array,
      baseName: this.props.baseName,
      objBaseName: this.props.objBaseName,
      nestedObjBaseName: this.props.nestedObjBaseName,
    });
  };

  handleDownload = (fileName) => {
    const { user } = this.state;
    let data = {
      fileName: fileName,
      office: user.office,
      userName: user.userName,
      userEmail: user.userEmail,
      companyName: user.clientName,
      companyId: user.clientId,
    };
    this.props.fileDownload(data).then((result) => {
      var blob = new Blob([result.data]);
      let file = fileName;
      saveAs(blob, file);
    });
  };

  handleDelete = async (file) => {
    const { user } = this.state;
    const array = this.props.value || [];

    let data = {
      fileName: file,
      office: user.office,
      userName: user.userName,
      userEmail: user.userEmail,
      companyName: user.clientName,
      companyId: user.clientId,
    };
    await this.props.fileDelete(data).then((res) => {
      if (res.data.portalErrorCode === 200) {
        NotificationManager.success(`${file} deleted successfully`);
        const index = array.findIndex((f) => f.fileName === file);
        if (index > -1) {
          array.splice(index, 1);
        }
        this.setState({
          fileArray: array,
        });
      } else {
        NotificationManager.error(`Error while deleting ${file}`);
      }
    });
    await this.props.setDataToReducer({
      type: getReducer(this.props.reducer),
      chapter: this.props.chapter,
      index: this.props.index,
      name: this.props.name,
      value: array,
      baseName: this.props.baseName,
      objBaseName: this.props.objBaseName,
    });
  };

  render() {
    const { label, id, info, acceptType, isRequired } = this.props;

    return (
      <>
        <label>
          {label}
          {isRequired && <span style={{ color: "red" }}>*</span>}
          {info && <Tooltip text={info}></Tooltip>}
        </label>

        <br />
        <div className="form-group files mb-0 fileuploader">
          <div className="row d-flex align-items-center">
            <div className="col-lg-12 col-xl-12 mb-2">
              <input
                id={id}
                type="file"
                name="file"
                disabled={this.props.disabled}
                onChange={(e) => {
                  this.handleFileChange(e, this.props.value);
                }}
                accept={acceptType}
                multiple
              />
              <p className="helptext mt-2 mb-0">
                (.doc,.docx,.odt,.pdf,.rtf,.tex,.txt,.wpd,.ods,.xls,.xlsm,.xlsx,.key,.odp,.pps,.ppt,.pptx,.jpeg,.jpg,.png)
              </p>
            </div>

            {this.props.value &&
              this.props.value.map((file, index) => {
                return (
                  <div className="col-lg-12 mb-1" key={index}>
                    <div className="d-flex align-items-center upload-file-name w-100">
                      <p
                        className="FileNameDisplay"
                        style={{ margin: "0", flexGrow: "1" }}
                        title={file.fileName}
                      >
                        {file.fileName}
                      </p>
                      {!this.props.disabled && (
                        <span className="fileDelete">
                          <i
                            className="fa fa-times-circle"
                            style={{
                              pointerEvents: this.props.disabled && "none",
                            }}
                            onClick={() => this.handleDelete(file.fileName)}
                          ></i>
                        </span>
                      )}

                      <span className="fileDownload">
                        <i
                          className="fa fa-download"
                          onClick={() => this.handleDownload(file.fileName)}
                        ></i>
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    name,
    chapter,
    baseName,
    index,
    objBaseName,
    reducer,
    nestedObjBaseName,
    isCommon,
  } = ownProps;

  if (isCommon) {
    if (!objBaseName && !baseName) {
      return {
        value: state[reducer][name],
      };
    } else if (objBaseName && nestedObjBaseName) {
      return {
        value: state[reducer][objBaseName][nestedObjBaseName][name],
      };
    } else if (objBaseName && !baseName) {
      return {
        value: state[reducer][objBaseName][name],
      };
    } else if (objBaseName && baseName) {
      return {
        value: state[reducer][objBaseName][baseName][index][name],
      };
    }
  } else if (!baseName && !objBaseName) {
    return {
      value: state[reducer][chapter][name],
    };
  } else if (objBaseName && nestedObjBaseName) {
    return {
      value: state[reducer][chapter][objBaseName][nestedObjBaseName][name],
    };
  } else if (objBaseName && !baseName) {
    return {
      value: state[reducer][chapter][objBaseName][name],
    };
  } else if (objBaseName && baseName) {
    return {
      value: state[reducer][chapter][objBaseName][baseName][index][name],
    };
  } else {
    return {
      value: state[reducer][chapter][baseName][index][name],
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fileUpload: async(data) => dispatch(await fileUpload(data)),
    fileDelete: async(data) => dispatch(await fileDelete(data)),
    fileDownload: async(data) => dispatch(await fileDownload(data)),
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InternalFileUpload);
