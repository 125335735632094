import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import countryList from "react-select-country-list";
import CheckBox from "../../../../../elements/CheckBox";
import DropDown from "../../../../../elements/DropDown";
import PhoneInputField from "../../../../../elements/PhoneInputField";
import TextBox from "../../../../../elements/TextBox";

const objBaseName = "cntctPerNewComp";
class ContactPerson extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isCntcPersnExist, chapter, errorMsg } = this.props;
    const eMsg = JSON.parse(errorMsg);
    return (
      <Row className="pt-0 pb-2">
        <div className="mb-3 col-lg-4">
          <CheckBox
            label="Is there a contact person UIMM to laise in respect to this new company?"
            options={["Yes", "No"]}
            name="isCntcPersnExist"
            id="isCntcPersnExist"
            objBaseName={objBaseName}
            chapter={chapter}
            reducer={this.props.reducer}
          />
          {eMsg &&
            eMsg?.isCntcPersnExist &&
            eMsg.isCntcPersnExist?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {eMsg.isCntcPersnExist}
              </span>
            )}
        </div>
        {isCntcPersnExist === "Yes" && (
          <>
            <div className="mb-3 col-lg-8">
              <Row>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Name of the person"
                    name="cntctPrsnName"
                    id={`cntctPrsnName`}
                    placeholder="enter your text"
                    objBaseName={objBaseName}
                    chapter={chapter}
                    reducer={this.props.reducer}
                    validationType="Text"
                  />
                  {eMsg &&
                    eMsg?.cntctPrsnName &&
                    eMsg.cntctPrsnName?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg.cntctPrsnName}
                      </span>
                    )}
                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Address"
                    name="cntctPrsnResAdd"
                    id={`cntctPrsnResAdd`}
                    placeholder="enter your text"
                    objBaseName={objBaseName}
                    chapter={chapter}
                    reducer={this.props.reducer}
                    validationType="Text"
                  />
                  {eMsg &&
                    eMsg?.cntctPrsnResAdd &&
                    eMsg.cntctPrsnResAdd?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg.cntctPrsnResAdd}
                      </span>
                    )}
                </div>

                <div className="mb-3 col-lg-6" id={`cntctPrsnTelPhnNum`}>
                  <PhoneInputField
                    label="Phone number"
                    name="cntctPrsnTelPhnNum"
                    chapter={chapter}
                    objBaseName={objBaseName}
                    reducer={this.props.reducer}
                    country={this.props.form}
                  />
                  {eMsg &&
                    eMsg?.cntctPrsnTelPhnNum &&
                    eMsg.cntctPrsnTelPhnNum?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg.cntctPrsnTelPhnNum}
                      </span>
                    )}
                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Email address"
                    name="cntctPrsnEmail"
                    id={`cntctPrsnEmail`}
                    placeholder="enter your text"
                    objBaseName={objBaseName}
                    chapter={chapter}
                    reducer={this.props.reducer}
                    validationType="Email"
                    ShowValidation={
                      eMsg &&
                      eMsg?.cntctPrsnEmail &&
                      eMsg.cntctPrsnEmail?.length > 0
                        ? false
                        : true
                    }
                  />
                  {eMsg &&
                    eMsg?.cntctPrsnEmail &&
                    eMsg.cntctPrsnEmail?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg.cntctPrsnEmail}
                      </span>
                    )}
                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Mailing address"
                    name="cntctPrsnMailingAdd"
                    id={`cntctPrsnMailingAdd`}
                    placeholder="enter your text"
                    objBaseName={objBaseName}
                    chapter={chapter}
                    reducer={this.props.reducer}
                    isMultipleLines={true}
                    validationType="Text"
                  />
                  {eMsg &&
                    eMsg?.cntctPrsnMailingAdd &&
                    eMsg.cntctPrsnMailingAdd?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg.cntctPrsnMailingAdd}
                      </span>
                    )}
                </div>
                <div className="mb-3 col-lg-6">
                  <DropDown
                    label="Nationality"
                    name="cntctNationality"
                    options={countryList().getData()}
                    id={`cntctNationality`}
                    objBaseName={objBaseName}
                    chapter={chapter}
                    reducer={this.props.reducer}
                  />
                  {eMsg &&
                    eMsg?.cntctNationality &&
                    eMsg?.cntctNationality?.length > 0 && (
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {eMsg?.cntctNationality}
                      </span>
                    )}
                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Should you have any particular instructions. Please state"
                    name="prtclrInstructions"
                    id={`prtclrInstructions`}
                    placeholder="enter your text"
                    objBaseName={objBaseName}
                    chapter={chapter}
                    reducer={this.props.reducer}
                    isMultipleLines={true}
                    validationType="Text"
                  />
                  {eMsg &&
                    eMsg?.prtclrInstructions &&
                    eMsg.prtclrInstructions?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg.prtclrInstructions}
                      </span>
                    )}
                </div>
              </Row>
            </div>
          </>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer } = ownProps;
  return {
    isCntcPersnExist:
      state[reducer]["chapter4"]["cntctPerNewComp"]["isCntcPersnExist"],
  };
};

export default connect(mapStateToProps, null)(ContactPerson);
