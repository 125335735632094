import React, { Component } from "react";
import { connect } from "react-redux";
import Chapter1 from "../../client-information/Chapter1/Chapter1";
import Chapter2 from "../../client-information/Chapter2/Chapter2";
import Chapter5 from "../../client-information/Chapter5/Chapter5";
import Chapter6 from "../../client-information/Chapter6/Chapter6";
import AutoSaving from "./AutoSaving";
import {
  getUKCAFDetails,
  setUKCAFDetails,
  setNewUKDataToReducer,
} from "../../../../../../redux/actions/UK/UKClientInformationActions";
import Validations from "../formValidations";
import { formValid } from "../formValid";
import { withRouter } from "react-router";
import { NotificationManager } from "react-notifications";
import { getFirstUnfilledFieldExternal } from "../getFirstUnfilledField";
import Chapter3 from "./Chapter3/Chapter3";
import Chapter4 from "../../client-information/Chapter4/Chapter4";
import { Row } from "react-bootstrap";
import getSequentialFields from "./getSequentialFields";

const reducer = "UKClientInformation";
const form = "UK";
class UKFormDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: null,
      firstFields: "",
      formErrorsChapter1: {},
      formErrorsChapter2: {},
      formErrorsChapter3: {},
      formErrorsChapter4: {},
      formErrorsChapter5: {},
      formErrorsChapter6: {},
    };
  }

  componentDidMount = () => {
    let data = {
      Id: localStorage.getItem("formId"),
      Chapter: "all",
    };
    //this.props.globalStateChanged();

    this.props
      .getUKCAFDetails(data)
      .then((res) => {
        if (res.data.portalErrorCode === 200) {
          }
      })
      .catch(this.catchError);
  };

  handleSubmit = async () => {
    let requestData = {};
    Object.entries(this.props.UKClientInformation).forEach((chapter) => {
      let [chapterNumber, data] = chapter;
      delete data["dirty"];
      requestData[chapterNumber] = data;
    });
    let response = await Validations.validateDetails(this.props.data);
    this.setState({
      formErrorsChapter1: response.formErrors.chapter1.formErrorsChapter1,
      formErrorsChapter2: response.formErrors.chapter2.formErrorsChapter2,
      formErrorsChapter3: response.formErrors.chapter3.formErrorsChapter3,
      formErrorsChapter4: response.formErrors.chapter4.formErrorsChapter4,
      formErrorsChapter5: response.formErrors.chapter5.formErrorsChapter5,
      formErrorsChapter6: response.formErrors.chapter6.formErrorsChapter6,
    });
   
    if (
      formValid(this.state.formErrorsChapter1) &&
      formValid(this.state.formErrorsChapter2) &&
      formValid(this.state.formErrorsChapter3) &&
      formValid(this.state.formErrorsChapter4) &&
      formValid(this.state.formErrorsChapter5) &&
      formValid(this.state.formErrorsChapter6)
    ) {
      this.setState({ isValid: true });
      requestData["id"] = localStorage.getItem("formId");
      requestData["companyId"] = localStorage.getItem("clientId");
      requestData["office"] = localStorage.getItem("Office");
      requestData["isSubmitted"] = true;
      this.props.setUKCAFDetails(requestData).then((res) => {
        if (res.data.portalErrorCode === 200) {
          for (let [chapterNumber, data] of Object.entries(
            this.props.UKClientInformation
          )) {
            data["dirty"] = false;
          }
          NotificationManager.success("Client information added successfully");
          this.props.setNewUKDataToReducer(this.props.UKClientInformation);
          window.scrollTo(0, 0);

          return this.props.history.push("/client-information-list");
        }
      });
    } else {
      this.setState({ isValid: false });
      let fields = [];
      const newErrorsChapter1 = await getSequentialFields(
        this.state.formErrorsChapter1,
        "chapter1"
      );
      const firstFieldsChapter1 = await getFirstUnfilledFieldExternal(
        newErrorsChapter1?.errorChapter
      );
      if (firstFieldsChapter1) {
        fields.push(firstFieldsChapter1);
      }
      const newErrorsChapter2 = await getSequentialFields(
        this.state.formErrorsChapter2,
        "chapter2"
      );
      const firstFieldsChapter2 = await getFirstUnfilledFieldExternal(
        newErrorsChapter2?.errorChapter
      );
      if (firstFieldsChapter2) {
        fields.push(firstFieldsChapter2);
      }
      const newErrorsChapter3 = await getSequentialFields(
        this.state.formErrorsChapter3,
        "chapter3"
      );
      const firstFieldsChapter3 = await getFirstUnfilledFieldExternal(
        newErrorsChapter3?.errorChapter
      );
      

      if (firstFieldsChapter3) {
        fields.push(firstFieldsChapter3);
      }
      const newErrorsChapter4 = await getSequentialFields(
        this.state.formErrorsChapter4,
        "chapter4"
      );
      const firstFieldsChapter4 = await getFirstUnfilledFieldExternal(
        newErrorsChapter4?.errorChapter
      );
      if (firstFieldsChapter4) {
        fields.push(firstFieldsChapter4);
      }
      const newErrorsChapter5 = await getSequentialFields(
        this.state.formErrorsChapter5,
        "chapter5"
      );
      const firstFieldsChapter5 = await getFirstUnfilledFieldExternal(
        newErrorsChapter5?.errorChapter
      );
      if (firstFieldsChapter5) {
        fields.push(firstFieldsChapter5);
      }
      const newErrorsChapter6 = await getSequentialFields(
        this.state.formErrorsChapter6,
        "chapter6"
      );
      const firstFieldsChapter6 = await getFirstUnfilledFieldExternal(
        newErrorsChapter6?.errorChapter
      );
      if (firstFieldsChapter6) {
        fields.push(firstFieldsChapter6);
      }
      if (fields.length > 0) {
        this.setState({ firstFields: fields[0] });
      } else {
        this.setState({ firstFields: null });
      }
      NotificationManager.error("Please fill the mandatory fields");
      if (fields[0] !== null && fields.length > 0) {
        document.getElementById(fields[0]).scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  };
  render() {
    const formErrorsChapter1 = JSON.stringify(this.state.formErrorsChapter1);
    const formErrorsChapter2 = JSON.stringify(this.state.formErrorsChapter2);
    const formErrorsChapter3 = JSON.stringify(this.state.formErrorsChapter3);
    const formErrorsChapter4 = JSON.stringify(this.state.formErrorsChapter4);
    const formErrorsChapter5 = JSON.stringify(this.state.formErrorsChapter5);
    const formErrorsChapter6 = JSON.stringify(this.state.formErrorsChapter6);

    return (
      <>
        <AutoSaving />
        <div className="dashboard external-dashboard">
          <Chapter1
            reducer={reducer}
            form={form}
            errorMsg={formErrorsChapter1}
            errorMsgCh4={formErrorsChapter4}
            from="UK"
          />
          <Chapter2
            reducer={reducer}
            form={form}
            errorMsg={formErrorsChapter2}
          />
          {/* <Chapter1 reducer={reducer} /> */}
          <Chapter3
            reducer={reducer}
            form={form}
            errorMsg={formErrorsChapter3}
          />

          <Chapter4
            reducer={reducer}
            form={form}
            errorMsg={formErrorsChapter4}
          />
          <Chapter5
            reducer={reducer}
            form={form}
            errorMsg={formErrorsChapter5}
          />
          {/* <Chapter6
            reducer={reducer}
            form={form}
            errorMsg={formErrorsChapter6}
          /> */}

          {/* {this.state.isValid === false && this.state.firstFields !== null && (
            <div className="internalErrorSection">
              <HashLink to={`/client-information#${this.state.firstFields}`}>
                Unfilled fields
              </HashLink>
            </div>
          )} */}

          <Row className="pt-3 pb-2">
            <div className="mb-2 col-lg-12">
              <button
                onClick={this.handleSubmit}
                type="button"
                className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
              >
                Submit
              </button>
            </div>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UKClientInformation: state.UKClientInformation,
    data: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUKCAFDetails: (data) => dispatch(setUKCAFDetails(data)),
    getUKCAFDetails: (data) => dispatch(getUKCAFDetails(data)),
    setNewUKDataToReducer: (data) => dispatch(setNewUKDataToReducer(data)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UKFormDashboard)
);
