export const SET_GENERIC_DETAILS_REQUEST = "SET_GENERIC_DETAILS_REQUEST";
export const SET_GENERIC_DETAILS_SUCCESS = "SET_GENERIC_DETAILS_SUCCESS";
export const SET_GENERIC_DETAILS_FAIL = "SET_GENERIC_DETAILS_FAIL";

export const GET_GENERIC_DETAILS_REQUEST = "GET_GENERIC_DETAILS_REQUEST";
export const GET_GENERIC_DETAILS_SUCCESS = "GET_GENERIC_DETAILS_SUCCESS";
export const GET_GENERIC_DETAILS_FAIL = "GET_GENERIC_DETAILS_FAIL";

export const SET_CUSTOMER_ACTIVITY_REQUEST = "SET_CUSTOMER_ACTIVITY_REQUEST";
export const SET_CUSTOMER_ACTIVITY_SUCCESS = "SET_CUSTOMER_ACTIVITY_SUCCESS";
export const SET_CUSTOMER_ACTIVITY_FAIL = "SET_CUSTOMER_ACTIVITY_FAIL";

export const GET_CUSTOMER_ACTIVITY_REQUEST = "GET_CUSTOMER_ACTIVITY_REQUEST";
export const GET_CUSTOMER_ACTIVITY_SUCCESS = "GET_CUSTOMER_ACTIVITY_SUCCESS";
export const GET_CUSTOMER_ACTIVITY_FAIL = "GET_CUSTOMER_ACTIVITY_FAIL";

export const CHANGE_FILE_URL_REQUEST = "CHANGE_FILE_URL_REQUEST";
export const CHANGE_FILE_URL_SUCCESS = "CHANGE_FILE_URL_SUCCESS";
export const CHANGE_FILE_URL_FAIL = "CHANGE_FILE_URL_FAIL";

export const GET_COMPANY_DETAILS_REQUEST = "GET_COMPANY_DETAILS_REQUEST";
export const GET_COMPANY_DETAILS_SUCCESS = "GET_COMPANY_DETAILS_SUCCESS";
export const GET_COMPANY_DETAILS_FAIL = "GET_COMPANY_DETAILS_FAIL";

export const DOWNLOAD_FILE_REQUEST = "DOWNLOAD_FILE_REQUEST";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_FAIL = "DOWNLOAD_FILE_FAIL";

export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAIL = "DELETE_FILE_FAIL";

export const GET_CLIENT_INFORMATION_LIST_REQUEST = "GET_CLIENT_INFORMATION_LIST_REQUEST";
export const GET_CLIENT_INFORMATION_LIST_SUCCESS = "GET_CLIENT_INFORMATION_LIST_SUCCESS";
export const GET_CLIENT_INFORMATION_LIST_FAIL = "GET_CLIENT_INFORMATION_LIST_FAIL";

export const DELETE_CLIENT_INFORMATION_REQUEST ="DELETE_CLIENT_INFORMATION_REQUEST";
export const DELETE_CLIENT_INFORMATION_SUCCESS = "DELETE_CLIENT_INFORMATION_SUCCESS";
export const DELETE_CLIENT_INFORMATION_FAIL = "DELETE_CLIENT_INFORMATION_FAIL";

export const CREATE_CLIENT_INFORMATION_REQUEST = "CREATE_CLIENT_INFORMATION_REQUEST";
export const CREATE_CLIENT_INFORMATION_SUCCESS = "CREATE_CLIENT_INFORMATION_SUCCESS";
export const CREATE_CLIENT_INFORMATION_FAIL = "CREATE_CLIENT_INFORMATION_FAIL";

export const UPDATE_CLIENT_INFORMATION_REQUEST ="UPDATE_CLIENT_INFORMATION_REQUEST";
export const UPDATE_CLIENT_INFORMATION_SUCCESS= "UPDATE_CLIENT_INFORMATION_SUCCESS";
export const UPDATE_CLIENT_INFORMATION_FAIL = "UPDATE_CLIENT_INFORMATION_FAIL";

export const DELETE_CLIENT_CAF_REQUEST = "DELETE_CLIENT_CAF_REQUEST";
export const DELETE_CLIENT_CAF_SUCCESS = "DELETE_CLIENT_CAF_SUCCESS";
export const DELETE_CLIENT_CAF_FAIL = "DELETE_CLIENT_CAF_FAIL";

export const GET_All_OFFICES_REQUEST = "GET_All_OFFICES_REQUEST";
export const GET_All_OFFICES_SUCCESS = "GET_All_OFFICES_SUCCESS";
export const GET_All_OFFICES_FAIL = "GET_All_OFFICES_FAIL";