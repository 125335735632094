export function generateURL(office) {
  switch (office) {
    case "Netherlands":
      return "/netherlands";
    case "United Kingdom":
      return "/united-kingdom";
    case "Curacao":
      return "/curacao";
    case "Malta":
      return "/malta";
    case "Luxembourg":
      return "/luxembourg";
    default:
      return null;
  }
}
