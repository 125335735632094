import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser } from "../../../../redux/actions/userActions";
import "./login.scss";
import { NotificationManager } from "react-notifications";
import Header from "../../../common/header";
import { Base64 } from "js-base64";

/* eslint-disable no-useless-escape */
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: {},
      isLoading: false,
    };
  }

  //handle change on all inputs
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  clearInputs = () => {
    this.setState({
      email: "",
      password: "",
    });
  };

  //function to validate login form
  validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.email) {
      formIsValid = false;
      errors["email"] = "Enter valid Email ID";
    }
    if (this.state.email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.state.email)) {
        formIsValid = false;
        errors["email"] = "Enter valid Email ID";
      }
    }
    if (!this.state.password) {
      formIsValid = false;
      errors["password"] = "Enter valid password";
    }
    if (this.state.password) {
      if (
        !this.state.password.match(
          /(?=[A-Za-z0-9!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~])(?=.{8,}).*$/
        )
      ) {
        formIsValid = false;
        errors["password"] = "Enter valid password";
      }
    }
    this.setState({ errors });
    return formIsValid;
  };

  //function which handle submit request
  handleSubmit = (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    let errors = {};
    if (this.validateForm()) {
      let data = {
        email: email,
        password: Base64.encode(password),
      };
      this.props.loginUser(data).then((res) => {
        this.setState({ isLoading: false });
        if (res.data.portalErrorCode === 200) {
          NotificationManager.success("login successfully");
          localStorage.setItem(
            "autheticate-token",
            //JSON.stringify(res.data.result)
            Base64.encode(JSON.stringify(data))
          );
          this.clearInputs();
          return this.props.history.push({
            pathname: "/authentication",
          });
        } else if (!res.data.isError) {
          errors["otherMessage"] = res.data?.portalMessage;
        } else {
          errors["otherMessage"] = "Error while login";
        }
        this.setState({ errors });
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <>
        <Header />
        <div className="loginBg d-flex justify-content-center align-items-center flex-wrap">
          <form className="LoginWindow rounded" onSubmit={this.handleSubmit}>
            <h1 className="primary-heading mb-2">Login</h1>
            <p className="mb-3">
              Please, use your credential to enter into system
            </p>
            {this.state.isLoading && <div className="loader"></div>}
            <div className="errorMsg">{this.state.errors.otherMessage}</div>
            <div className="formGroup">
              <label>Email ID</label>
              <input
                type="text"
                placeholder="Enter email ID"
                className="inputstyle"
                value={this.state.email}
                name="email"
                onChange={this.handleChange}
              />
              <div className="errorMsg">{this.state.errors.email}</div>
            </div>
            <div className="formGroup">
              <label>Password</label>
              <input
                type="password"
                placeholder="********"
                className="inputstyle"
                value={this.state.password}
                name="password"
                onChange={this.handleChange}
              />
              <div className="errorMsg">{this.state.errors.password}</div>
            </div>
            <Button
              className="generalBtn mt-3"
              type="submit"
              onClick={() => {
                this.setState({ isLoading: true });
              }}
            >
              Login
            </Button>
            <p className="text-center pt-4">
              <Link to="/forgotpassword" className="link">
                Forgot password?
              </Link>
            </p>
            <p className="copyright">Copyright @ 2021</p>
          </form>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (data) => dispatch(loginUser(data)),
  };
};
export default connect(null, mapDispatchToProps)(Login);
