import {
  SET_ALL_UK_ERRORS_TO_NULL,
  SET_UK_ERROR_FOR_CHAPTER,
} from "../../constants/UK/UKInternalChapterErrorConstants";

export const setUKInternalChapterError = (data) => {
  return (dispatch) => {
    return dispatch({ type: SET_UK_ERROR_FOR_CHAPTER, data: data });
  };
};

export const setAllUKErrorsToNull = () => {
  return (dispatch) => {
    return dispatch({ type: SET_ALL_UK_ERRORS_TO_NULL });
  };
};
