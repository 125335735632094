import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../../../elements/CheckBox";
import TextBox from "../../../../../../elements/TextBox";

class UboClassification extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      isHoldingInterest,
      isUBOClassified,
      isAssetClient,
      chapter,
      errorMsg,
      form,
    } = this.props;
    const eMsg = JSON.parse(errorMsg);
    return (
      <div className="jumbtron">
        <div className="jumbtronHeading">
          UBO Classification / Ultimate Parent Company
        </div>

        <div className="jumbtronBody">
          <Row className="mt-3 mb-2">
            <div className="mb-3 col-lg-6">
              <CheckBox
                label="Is there a natural person holding an interest of 10% or more in the capital interest of the legal entity or can the natural person exercise 10% or more of the voting rights "
                options={["Yes", "No"]}
                name="isHoldingInterest"
                id="isHoldingInterest"
                chapter={chapter}
                reducer={this.props.reducer}
                isRequired={this.props.form === "UK" ? true : false}
               />
              {eMsg.isHoldingInterest?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg.isHoldingInterest}
                </span>
              )}
            </div>
            {isHoldingInterest === "Yes" && (
              <div className="mb-3 col-lg-6 fieldAlignment">
                <TextBox
                  label="Describe how the UBO is directly related to the top entity"
                  name="isHoldingInterestReason"
                  id="isHoldingInterestReason"
                  placeholder="enter reason"
                  chapter={chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                  isRequired={this.props.form === "UK" ? true : false}
                />
                {eMsg.isHoldingInterestReason?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.isHoldingInterestReason}
                  </span>
                )}
              </div>
            )}
          </Row>
          <Row className="mt-3 mb-2">
            <div className="mb-3 col-lg-6 fieldAlignment">
              <CheckBox
                label="Is there a natural person with ultimate effective control who should also be classified as UBO? "
                options={["Yes", "No"]}
                name="isUBOClassified"
                id="isUBOClassified"
                chapter={chapter}
                reducer={this.props.reducer}
                isRequired={this.props.form === "UK" ? true : false}
              />
              {eMsg.isUBOClassified?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg.isUBOClassified}
                </span>
              )}
            </div>

            {isUBOClassified === "Yes" && (
              <div className="mb-3 col-lg-6 fieldAlignment">
                <TextBox
                  label="Describe the reason why there are natural persons who are considered as persons who have ultimate effective control"
                  placeholder="enter reason"
                  name="isUBOClassifiedReason"
                  id="isUBOClassifiedReason"
                  chapter={chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                  isRequired={this.props.form === "UK" ? true : false}
                />
                {eMsg.isUBOClassifiedReason?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.isUBOClassifiedReason}
                  </span>
                )}
              </div>
            )}
          </Row>

          <Row className="mt-3 mb-2">
            <div className="mb-0 col-lg-6">
              <CheckBox
                label="Is there a natural person who holds 10 per cent or more of the assets of a client or a trust or has special control over 10 per cent or more of the assets of a client (beneficiary)."
                options={["Yes", "No"]}
                name="isAssetClient"
                id="isAssetClient"
                chapter={chapter}
                reducer={this.props.reducer}
                isRequired={this.props.form === "UK" ? true : false}
              />
              {eMsg.isAssetClient?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {eMsg.isAssetClient}
                </span>
              )}
            </div>
            {isAssetClient === "Yes" && (
              <div className="mb-0 col-lg-6 fieldAlignment">
                <TextBox
                  label="Describe the reason why there are natural persons who are considered as beneficiaries"
                  placeholder="enter reason"
                  name="isAssetClientReason"
                  id="isAssetClientReason"
                  chapter={chapter}
                  reducer={this.props.reducer}
                  validationType="Text"
                  isRequired={this.props.form === "UK" ? true : false}
                />
                {eMsg.isAssetClientReason?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {eMsg.isAssetClientReason}
                  </span>
                )}
              </div>
            )}
          </Row>

          {isHoldingInterest === "No" &&
            isUBOClassified === "No" &&
            isAssetClient === "No" && (
              <Row className="mt-3 mb-2">
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Describe how the UBO is directly related to the top entity"
                    name="isHoldingInterestReason"
                    id="isHoldingInterestReason"
                    placeholder="enter reason"
                    chapter={chapter}
                    reducer={this.props.reducer}
                    validationType="Text"
                    isRequired={this.props.form === "UK" ? true : false}
                  />
                  {eMsg.isHoldingInterestReason?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg.isHoldingInterestReason}
                    </span>
                  )}
                </div>
              </Row>
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer } = ownProps;
  return {
    isHoldingInterest: state[reducer]["chapter3"]["isHoldingInterest"],
    isUBOClassified: state[reducer]["chapter3"]["isUBOClassified"],
    isAssetClient: state[reducer]["chapter3"]["isAssetClient"],
    //isUPCExists: state[reducer]["chapter3"]["isUPCExists"],
  };
};

export default connect(mapStateToProps, null)(UboClassification);
