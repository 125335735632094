import { AdditionDocs, CustActivityRisks } from "./Chapter1";

export const Chapter5 = {
  isUIMAwareOfIncidents: null,
  uIMComment: null,
  countryList: [],
  structureComment: null,
  isRestrictiveMeasure: null,
  isStructurePlacedOnFATF: null,
  isStructureOrObjCompanyDirectly: null,
  includeInformation: null,
  isClientsInclRelevantCounterparties: null,
  overviewOfNaturalPerson: null,
  isMediaCoverageOnStruct: null,
  isAddDocs: null,
  numAddDocs: 1,
  addDocs: [AdditionDocs],
  isRisk: null,
  numCustActivityRisks: 1,
  custActivityRisks: [CustActivityRisks],
  custActvtyRskRtng: null,
  descActnNeedsTkn: null,
  isUnacceptableRisksIdentified: null,
  unacceptableRisksReason: null,
  dirty: false,
};
