import React, { Component } from "react";
import { Switch } from "react-router-dom";
import InternalUserRoutes from "../../../../routes/InternalUserRoutes";

import Chapter1 from "../CuracaoOffice/Chapter1/Chapter1";
import Chapter2 from "../CuracaoOffice/Chapter2/Chapter2";
import Chapter3 from "../CuracaoOffice/Chapter3/Chapter3";
import Chapter4 from "../CuracaoOffice/Chapter4/Chapter4";
import Chapter5 from "../CuracaoOffice/Chapter5/Chapter5";
import Chapter6 from "../CuracaoOffice/Chapter6/Chapter6";
import Chapter8 from "../CuracaoOffice/Chapter8/Chapter8";
import Chapter9 from "../CuracaoOffice/Chapter9/Chapter9";
import Chapter10 from "../CuracaoOffice/Chapter10/Chapter10";
import Chapter11 from "../CuracaoOffice/Chapter11/Chapter11";
import Chapter12 from "../CuracaoOffice/Chapter12/Chapter12";
import Chapter13 from "../CuracaoOffice/Chapter13/Chapter13";
import addClientForm from "../CuracaoOffice/Chapter1/addClientForm";
import editClientForm from "../CuracaoOffice/Chapter1/editClientForm";
import SignClientForm from "../CuracaoOffice/Chapter13/SignClientForm";

class CuracaoInternalDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  handleChange(e) {
    this.props.handleOfficeChange(e);
  }
  render() {
    return (
      <Switch>
        <InternalUserRoutes path="/curacao/addclientForm" component={addClientForm} />
        <InternalUserRoutes path="/curacao/editclientForm" component={editClientForm} />
        <InternalUserRoutes
          path="/curacao/client-activity"
          component={Chapter1}
        />

        <InternalUserRoutes
          path="/curacao/client-investigation"
          component={Chapter2}
        />
        <InternalUserRoutes path="/curacao/ubo-upc" component={Chapter3} />
        <InternalUserRoutes
          path="/curacao/object-company"
          component={Chapter4}
        />
        <InternalUserRoutes
          path="/curacao/country-sanction-risks"
          component={Chapter5}
        />
        <InternalUserRoutes path="/curacao/agreement" component={Chapter6} />
        <InternalUserRoutes
          path="/curacao/consolidated-risk-assessment"
          component={Chapter8}
        />
        <InternalUserRoutes
          path="/curacao/correspondence"
          component={Chapter9}
        />
        <InternalUserRoutes
          path="/curacao/miscellaneous"
          component={Chapter10}
        />
        <InternalUserRoutes
          path="/curacao/summary-sheet-caf"
          component={Chapter11}
        />

        <InternalUserRoutes
          path="/curacao/formal-sign-off"
          component={Chapter12}
        />
        {/* <InternalUserRoutes path="/curacao/signature" component={Chapter13} /> */}
        <InternalUserRoutes path="/curacao/signature" component={SignClientForm} />
      </Switch>
    );
  }
}

export default CuracaoInternalDashboard;
