export const chapter1 = {
  structureChart: "",
  isCompIncorporated: "",
  entities: [],
  isClientNew: "",
  clientUIMMalta: "",
  introducerName: "",
  companyNameMal: "",
  introducerNationality: "",
  doesClientIntrdNewPer: "",
  clientPersName: "",
  clientPersNationality: "",
  clientPersComp: "",
  descNewPer: "",
  upcActivites: "",
  upcSector: "",
  shareholderActivites: "",
  shareholderSector: "",
  objCompActivities: "",
  objCompSector: "",
  participantActivites: "",
  participantSector: "",
  otherActivites: "",
  otherSector: "",
};
export const chapter2 = {
  isClientRepInvolved: "",
  intermediaryDetails: [],
  isClientTransfrd: "",
  srvcPrvdrName: "",
  reasonClientTransfrd: "",
};
export const chapter3 = {
  isHoldingInterest: "",
  isHoldingInterestReason: "",
  isUBOClassified: "",
  isUBOClassifiedReason: "",
  isAssetClient: "",
  isAssetClientReason: "",
  ubox: [],
  isUPCExists: "",
  nameUPC: "",
  addressRegistered: "",
  postalCode: "",
  city: "",
  country: "",
  websiteUPC: "",
  taxNum: "",
  tradeRegisterNum: "",
  phoneNumberUPC: "",
  countryOfTaxRsdnc: "",
  typeOfUPC: "",
  isForceCloseFinAcc: "",
  forceCloseFinAcc: "",
  upcAuthDirName: "",
  upcAuthDirEmail: "",
  upcAuthDirNationality: "",
  extractChamberOfCommerce: "",
  deedOfIncorporation: "",
  shareholdersRegister: "",
  shareholdersCertificates: "",
  nomineeAgreement: "",
  financialStatementsThreeYears: "",
  isShareholderUBO: "",
  complianceList: "",
};
export const chapter4 = {
  isObjCompExists: "",
  objCompName: "",
  commercialRegister: "",
  regOffMal: "",
  typOfCompMal: "",
  typeCmpOthrSpcfy: "",
  objCompDirectorNames: [],
  objCompActiveCountry: "",
  objCompNaturePropsdObjctv: "",
  objCompOtherNaturePropsdObjctv: "",
  isObjCompBranches: "",
  objCompBranch: "",
  rationaleStructurigForNL: "",
  rationaleSelectedInfo: "",
  taxAdvisorFinds: "",
  copyPassportManagement: "",
  extractChamberOfCommerce4: "",
  deedOfIncorporation4: "",
  shareholdersRegister4: "",
  shareholdersCertificates4: "",
  isTaxMemorandum: "",
  taxAdvisorName: "",
  taxMemorandum: "",
  taxMemorandumAdvisorName: "",
  isAPA: "",
  objCompTaxResult: "",
  explanation: "",
  otherDocs: "",
  sttngCompCntrct: "",
  serviceNames: "",
  byWhom: "",
  othrService: "",
  isOffRegstrd: "",
  serviceNames2: "",
  comAddComReg: "",
  othrService2: "",

  descClientNeeds: "",
  benefitOfClient: "",
  taxImplications: "",
  busAcptncCnflctIntrst: "",
  isUBOInvstObjComp: "",
  uboInvstCurrencyObjComp: "",
  uboInvstObjCompAmnt: "",
  uboInvstObjCompOrigin: "",
  isUPCfundObjComp: "",
  upcFundObjCompOrigin: "",
  annualFinStmnt: "",
  isTpInvstObjComp: "",
  tpName: "",
  tpCountry: "",
  tpAnnualFinStmnts: "",
  cntrysInvlvdTrnsctnsObjComp: "",
  estmtdAnnualTrnOvrObjComp: "",
  assetSummryObjComp: "",
  assetSummryEvidence: "",
  expctdTrnsctnsSummary: "",
  expctdTrnsctnsEvidence: "",
  objCompToGrpComp: "",
  grpCompToObjComp: "",
  objCompToUBO: "",
  uboToObjComp: "",
  objCompToTP: "",
  tpToObjComp: "",
  bnkInfo: "",
  agremntsGrUpEntities: "",
  agremntsOthrParties: "",
  financialStmntsObjComp: "",
  finanicalStmntsAllPrticipatns: "",
  financialStmntsParntComp: "",
};
export const chapter5 = { countryList: "" };
export const chapter6 = {
  isExternalDirectors: "",
  isExternalDirectorsNaturalPerson: "",
};

export const chapValid = [
  {
    chapter: "chapter1",
    name: "isChapter1valid",
  },
  {
    chapter: "chapter2",
    isvalid: false,
  },
  {
    chapter: "chapter3",
    isvalid: false,
  },
  {
    chapter: "chapter4",
    isvalid: false,
  },
  {
    chapter: "chapter5",
    isvalid: false,
  },
  {
    chapter: "chapter6",
    isvalid: false,
  },
];
