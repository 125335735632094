import React, { Component } from "react";
import { Row } from "react-bootstrap";
import countryList from "react-select-country-list";
import DropDown from "../../../../../../elements/DropDown";
import TextBox from "../../../../../../elements/TextBox";
import CheckBox from "../../../../../../elements/CheckBox";
import FileUpload from "../../../../../../elements/FileUpload";
import { fileAcceptType, NatureOfObjectComArray,rationaleArray } from "../constants";
import { connect } from "react-redux";
class CompDirectors extends Component {
    constructor(props) {
    super(props);
    this.state = {     
      };
  }
  render() {
    const { index, errorMsg} = this.props;
    const eMsg = errorMsg;
    
    return (
      <div className="mb-4 col-lg-12">
        <fieldset>
          <legend>Director {this.props.index + 1}</legend>
          <Row>
          <div className="mb-2 col-lg-6 col-xl-6">
          <DropDown
                label="Type of Director"
                name="typeOfDirector"
                id={`typeOfDirector[${index}]`}
                options={[
                  { value: 'United', label: 'United' },
                  { value: 'External', label: 'External' },                 
                ]}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                isRequired={this.props.form === "UK" ? true : false}
              />
              {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objCompDirectorNames &&
                    errorMsg.objCompDirectorNames[index] &&
                    errorMsg.objCompDirectorNames[index]
                      ?.typeOfDirector?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objCompDirectorNames[index]
                            ?.typeOfDirector
                        }
                      </span>
                    )}

            </div>
            </Row> 
            <Row>
            <div className="mb-2 col-lg-6 col-xl-6">
                <TextBox
              label="Name Director of Object Company"
              name="dirName"
              id={`dirName[${index}]`}
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              indexObjBaseName={`${this.props.indexObjBaseName}`}
              index={this.props.index}
              baseName={this.props.baseName}
              reducer={this.props.reducer}
              validationType="Text"
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? true : false} 
            />
            {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objCompDirectorNames &&
                    errorMsg.objCompDirectorNames[index] &&
                    errorMsg.objCompDirectorNames[index]
                      ?.dirName?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objCompDirectorNames[index]
                            ?.dirName
                        }
                      </span>
                    )}
            </div> 
            <div className="mb-2 col-lg-6 col-xl-6">
            <DropDown
                label="Nationality of Director"
                name="dirNationality"
                id={`dirNationality[${index}]`}
                options={countryList().getData()}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                isRequired={this.props.form === "UK" ? true : false}
              />  
               {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objCompDirectorNames &&
                    errorMsg.objCompDirectorNames[index] &&
                    errorMsg.objCompDirectorNames[index]
                      ?.dirNationality?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objCompDirectorNames[index]
                            ?.dirNationality
                        }
                      </span>
                    )}  
            </div> 
          
            </Row>
          <Row>
          <div className="mb-2 col-lg-8 col-xl-8">
          <FileUpload
                label="Certified Passport Copy/Proof of address/supporting documents"
                acceptType={fileAcceptType}
                name="dirCertifiedPass"
                id={`dirCertifiedPass[${index}]`}
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                indexObjBaseName={`${this.props.indexObjBaseName}`}
                index={this.props.index}
                baseName={this.props.baseName}
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                isRequired={this.props.form === "UK" ? true : false} 
              />
               {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.objCompDirectorNames &&
                    errorMsg.objCompDirectorNames[index] &&
                    errorMsg.objCompDirectorNames[index]
                      ?.dirCertifiedPass?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.objCompDirectorNames[index]
                            ?.dirCertifiedPass
                        }
                      </span>
                    )}  
              </div>
          </Row>
                
            
        </fieldset>
      </div>
    );
  }
}

//export default Trustees;
const mapStateToProps = (state, ownProps) => {
    const { baseName, index, reducer,objBaseName,indexObjBaseName } = ownProps;
    return {
         };
  };
  
  export default connect(mapStateToProps, null)(CompDirectors);
