import { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default class Header extends Component {
  render() {
    return (
      <div className="header">
        <Container>
          <Row>
            <Col xs={6}>
              <Link to="" className="logo" />
            </Col>
            {window.location.pathname === "/" && (
              <Col xs={6} className="text-right">
                <Link to="/Login" className="loginbtn text-center mt-2 rounded">
                  Login
                </Link>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    );
  }
}
