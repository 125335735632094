import { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./addclient.scss";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import {
  addClient,
  getClientById,
  updateClient,
  getAllOffices,
  assignUserList,
} from "../../../redux/actions/clientActions";
import { getUserList } from "../../../redux/actions/userActions";
import countryList from "react-select-country-list";
import Select from "react-select";
import jwt_decode from "jwt-decode";

/* eslint-disable no-useless-escape */

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
const cityFormat = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;
const styles = {
  multiValue: (base, state) => {
    return state.data.isFixed
      ? { ...base, backgroundColor: "#d2d2d2", borderRadius: "25px" }
      : { ...base, backgroundColor: "white", borderRadius: "25px" };
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: "500", color: "#a5a5a5", paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed
      ? { ...base, display: "none" }
      : {
          ...base,
        };
  },
};

class AddClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      isLoading: false,
      clientname: "",
      address: "",
      city: "",
      postalcode: "",
      country: "",
      office: [],
      assignUser: [],
      errors: {},
      selectedCities: [],
      officeArray: [],
      userList: [],
      countries: [],
      originalUserList: [],
      userType:localStorage.getItem("userType"),
    };
  }

  //handle change on all inputs
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidMount = async () => {
    let originalUserList = [];

    // Get countryList
    let countryListArray = countryList().getData();
    if (countryListArray.length > 0) {
      this.setState({ countries: countryListArray });
    }

    /* GET ALL OFFICES */
    if(this.state.userType !=="Curacao"){
    this.props.getAllOffices().then((res) => {
     
      if (res.data.portalErrorCode === 200) {
        let array = res.data.result;

        let new_office_array = [];
        if (array.length > 0) {
          array.forEach((office) => {
            let obj = {};
            obj["name"] = office.name;
            obj["isChecked"] = false;
            new_office_array.push(obj);
          });
        }
       
        this.setState({ officeArray: new_office_array });
      }
   });
  }
  else{
    let new_office_array = [
      {
          "name": "Netherlands",
          "isChecked": false
      },
      {
          "name": "Malta",
          "isChecked": false
      },
      {
          "name": "United Kingdom",
          "isChecked": false
      },
      {
          "name": "Luxembourg",
          "isChecked": false
      },
      {
          "name": "Curacao",
          "isChecked": true
      }
  ];
  this.setState({ officeArray: new_office_array });
  }

    // get assign user list
    let token = JSON.parse(localStorage.getItem("authToken"));

    var decoded = jwt_decode(token);
    var data = {
      name: decoded.name,
    };

    /* GET USER LIST */
    await this.props.getUserList(data).then((res) => {
      if (res.data.portalErrorCode === 200) {
        this.setState({ isLoading: false });
        originalUserList = res.data?.result;
        this.setState({
          userList: res.data.result,
        });
        let addedUser = [];
        res.data.result.forEach((user) => {
          if (user.status !== "PendingApproval") {
            let obj = {};
            obj["value"] = user.id;
            obj["label"] = user.firstName + " " + user.lastName;
            addedUser.push(obj);
          }
        });
        this.setState({ userList: addedUser });
      }
    });

    // Edit client
    if (this.state.id) {
      this.setState({ isLoading: true });
      let request = {
        id: this.state.id,
      };
      await this.props.getClientById(request).then((res) => {
        this.setState({ isLoading: false });
        if (res.data.portalErrorCode === 200) {
          const { result } = res.data;
          this.setState({
            clientname: result.name,
            address: result.address,
            city: result.city,
            postalcode: result.postalCode,
          });
          let userlist = [];

          result.assignUser.length > 0 &&
            result.assignUser.forEach((user) => {
              let flag = false;
              originalUserList.length > 0 &&
                originalUserList.forEach((originalUser) => {
                  if (
                    user.id === originalUser.id &&
                    originalUser.status === "PendingApproval"
                  ) {
                    flag = true;
                  }
                });
              if (flag) {
                let obj = {};
                obj["value"] = user.id;
                obj["label"] = user.name;
                obj["isFixed"] = true;
                userlist.push(obj);
              } else {
                let obj = {};
                obj["value"] = user.id;
                obj["label"] = user.name;
                obj["isFixed"] = false;
                userlist.push(obj);
              }
            });

          this.setState({ assignUser: userlist });
          countryListArray.length > 0 &&
            countryListArray.forEach((country) => {
              if (country.label === result.country) {
                this.setState({ country });
              }
            });
          let offices = [];
          result.office.length > 0 &&
            result.office.forEach((office) => {
              let userOfficeArray = this.state.officeArray;
              userOfficeArray.length > 0 &&
                userOfficeArray.forEach((oldOffice) => {
                  if (office.name === oldOffice.name) {
                    oldOffice.isChecked = true;
                  }
                });

              this.setState({ userOfficeArray: offices });
            });
        }
      });
    }
  };

  handleSelectionOfClients = (assignUser) => {
    this.setState({ assignUser });
  };

  handleDeletionOfClients = (assignUser) => {
    this.setState({ assignUser });
  };

  validateForm = () => {
    let errors = {};
    let formIsValid = true;
    const { clientname, address, city, postalcode, country, officeArray } =
      this.state;
    if (!clientname) {
      formIsValid = false;
      errors["clientname"] = "Please enter client name";
    }
    if (!address) {
      formIsValid = false;
      errors["address"] = "Please enter address";
    }
    if (!city) {
      formIsValid = false;
      errors["city"] = "Please enter city";
    }
    if (city) {
      let isNotvalidCity = cityFormat.test(city);
      if (!isNotvalidCity) {
        formIsValid = false;
        errors["city"] = "Numeric value is not allowed in city";
      }
    }
    if (!postalcode) {
      formIsValid = false;
      errors["postalcode"] = "Please enter postal code";
    }
    if (postalcode) {
      let isNotvalidPostCode = format.test(postalcode);
      if (isNotvalidPostCode) {
        formIsValid = false;
        errors["postalcode"] =
          "Special character is not allowed in postal code";
      }
    }
    if (!country) {
      formIsValid = false;
      errors["country"] = "Please select country";
    }
    if (officeArray.length > 0) {
      let count = 0;
      officeArray.forEach((office) => {
        if (office.isChecked) {
          count++;
        }
      });
      if (count === 0) {
        formIsValid = false;
        errors["office"] = "Please select at least one office";
      }
    }

    this.setState({ errors });
    return formIsValid;
  };

  manageOfficeArray = () => {
    let offices = this.state.officeArray;
    let array = [];
    if (offices.length > 0) {
      offices.forEach((office) => {
        if (office.isChecked) {
          let obj = {};
          obj["Name"] = office.name;
          array.push(obj);
        }
      });
    }
    return array;
  };

  manageAssignUserObjArray = () => {
    let AssignUser = [];
    if (this.state.assignUser.length > 0) {
      this.state.assignUser.forEach((user) => {
        let obj = {};
        obj["id"] = user.value;
        obj["Name"] = user.label;
        AssignUser.push(obj);
      });
    }
    return AssignUser;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const { clientname, address, city, postalcode, country, assignUser } =
      this.state;
    let errors = {};
    if (this.validateForm()) {
      let data = {
        Name: capitalizeFirstLetter(clientname),
        Address: address,
        City: capitalizeFirstLetter(city),
        PostalCode: postalcode,
        Country: country.label,
        Office: this.manageOfficeArray(),
        AssignUser: this.manageAssignUserObjArray(),
      };
      this.props
        .addClient(data)
        .then((res) => {
          this.setState({ isLoading: false });
          if (res.data.portalErrorCode === 200) {
            NotificationManager.success("Client added successfully");
            return this.props.history.push({
              pathname: "/clientlist",
            });
          } else if (!res.data.isError) {
            errors["otherMessage"] = res.data.portalMessage;
          } else {
            errors["otherMessage"] = "Error while adding Client";
          }
          this.setState({ errors });
        })
        .catch((err) => console.log(err));
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleUpdate = (event) => {
    this.setState({ isLoading: true });
    event.preventDefault();
    const { id, clientname, address, city, postalcode, country, assignUser } =
      this.state;
    let errors = {};
    if (this.validateForm()) {
      let data = {
        id: id,
        Name: clientname,
        Address: address,
        City: capitalizeFirstLetter(city),
        PostalCode: postalcode,
        Country: country.label,
        Office: this.manageOfficeArray(),
        AssignUser: this.manageAssignUserObjArray(),
      };
      this.props
        .updateClient(data)
        .then((res) => {
          this.setState({ isLoading: false });
          if (res.data.portalErrorCode === 200) {
            NotificationManager.success("Client updated successfully!");
            return this.props.history.push({
              pathname: "/clientlist",
            });
          } else if (!res.data.isError) {
            errors["otherMessage"] = res.data.portalMessage;
          } else {
            errors["otherMessage"] = "Error while adding client";
          }
          this.setState({ errors });
        })
        .catch((err) => console.log(err));
    } else {
      this.setState({ isLoading: false });
    }
  };

  clearInputs = () => {
    this.setState({
      clientname: "",
      address: "",
      city: "",
      postalcode: "",
      country: "",
      office: "",
      assignUser: [],
    });
  };

  handleOfficeSelect = (office) => {
    let offices = this.state.officeArray;
    let newIndex = offices.indexOf(office);
    office.isChecked = !office.isChecked;
    offices[newIndex] = office;
    this.setState({
      officeArray: offices,
    });
  };

  handleMultiSelectionChange = (e, option) => {
    if (option.removedValue && option.removedValue.isFixed) return;
    this.setState({ assignUser: e });
  };

  changeCountry = (value) => {
    this.setState({ country: value });
  };

  render() {
    const {
      clientname,
      address,
      city,
      postalcode,
      countries,
      country,
      assignUser,
      userList,
      errors,
      officeArray} = this.state;
    
     return (
     
      <>
        <div className="dashboard">
          <div className="userlist">
            <div className="eventHeader pt-3 pb-3">
              <Container fluid>
                <Row className="align-items-center">
                  <Col sm={6} className="text-left">
                    <h2 className="mb-0 mt-2 pagetitle">Client Maintenance</h2>
                  </Col>
                </Row>
              </Container>
            </div>

            <Container fluid>
              <Row className="pt-5">
                <Col lg={3}></Col>
                <Col lg={6}>
                  <h2 className="heading2">
                    {this.state.id ? "Edit Client" : "Add Client"}
                  </h2>
                  <div className="addUserflow addClientFlow">
                    <div className="errorMsg">{errors.otherMessage}</div>
                    <Row>
                      <Col lg="6" className="mb-2">
                        <label>Client Name *</label>
                        <input
                          type="text"
                          value={clientname}
                          name="clientname"
                          placeholder="Enter your firstname"
                          className="input_field"
                          onChange={this.handleChange}
                        />
                        <div className="errorMsg">{errors.clientname}</div>
                      </Col>

                      <Col lg="6" className="mb-2">
                        <label>Address *</label>
                        <input
                          type="text"
                          name="address"
                          value={address}
                          placeholder="Enter your address"
                          className="input_field"
                          onChange={this.handleChange}
                        />
                        <div className="errorMsg">{errors.address}</div>
                      </Col>
                      <Col lg="6" className="mb-2">
                        <label>City *</label>
                        <input
                          type="text"
                          name="city"
                          value={city}
                          placeholder="Enter city"
                          className="input_field"
                          onChange={this.handleChange}
                        />
                        <div className="errorMsg">{errors.city}</div>
                      </Col>
                      <Col lg="6" className="mb-2">
                        <label>Postal Code *</label>
                        <input
                          type="text"
                          name="postalcode"
                          value={postalcode}
                          placeholder="Enter postal code"
                          className="input_field"
                          onChange={this.handleChange}
                        />
                        <div className="errorMsg">{errors.postalcode}</div>
                      </Col>
                      <Col lg="12" className="mb-2">
                        <label>Country *</label>
                        <Select
                          options={countries}
                          value={country}
                          className="countrylist"
                          placeholder="Select a country from the Dropdown"
                          onChange={this.changeCountry}
                        />
                        <div className="errorMsg">{errors.country}</div>
                      </Col>
                      {this.state.userType !== "Curacao" ?(
                      <Col lg="12" className="mb-2">                     
                        <label>Office *</label>
                        <div className="officeLocation">
                         
                          {this.state.officeArray.map((office, index) => {
                            return (
                              <label value={office} key={index}>
                                <input
                                  type="checkbox"
                                  checked={office.isChecked}
                                  value={office.name}                                  
                                  name={office.name}
                                  onChange={() =>
                                    this.handleOfficeSelect(office)
                                  }
                                />
                                {office.name}
                              </label>
                            );
                          })}
                        </div>
                        <div className="errorMsg">{errors.office}</div>
                      </Col>
                      ):(<Col lg="12" className="mb-2">                     
                      <label>Office : Curacao</label></Col>)}
                      {this.state.userType !== "Curacao" &&(
                      <Col lg="12" className="mb-2 autocomplete">
                        <label className="d-block">Assign User </label>

                        <Select
                          options={userList}
                          isMulti={true}
                          value={assignUser}
                          onChange={this.handleMultiSelectionChange}
                          isClearable={assignUser.some((v) => !v.isFixed)}
                          styles={styles}
                        />
                        <i
                          className="fa fa-search"
                          style={{
                            position: "absolute",
                            right: "25px",
                            top: "35px",
                          }}
                        ></i>

                        <div className="errorMsg">{errors.assignUser}</div>
                      </Col>
                      )}
                      <Col lg="12" className="mt-3">
                        {this.state.id ? (
                          <Button
                            className="action-btn big mr-4"
                            onClick={this.handleUpdate}
                          >
                            Update
                          </Button>
                        ) : (
                          <Button
                            className="action-btn big mr-4"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </Button>
                        )}
                        <Link
                          to="/clientlist"
                          className="action-btn link font-weight-bold"
                        >
                          Cancel
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={3}></Col>
                {this.state.isLoading && <div className="loader"></div>}
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addClient: async (data) => dispatch(await addClient(data)),
    getUserList: async (data) => dispatch(await getUserList(data)),
    getClientById: async (data) => dispatch(await getClientById(data)),
    updateClient: async (data) => dispatch(await updateClient(data)),
    getAllOffices: async (data) => dispatch(await getAllOffices(data)),
    assignUserList: async (data) => dispatch(await assignUserList(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddClient);
