import React, { Component } from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../../../elements/TextBox";
import countryList from "react-select-country-list";
import CheckBox from "../../../../../../elements/CheckBox";
import DropDown from "../../../../../../elements/DropDown";
import MultiSelectDropdown from "../../../../../../elements/MultiSelectDropdown";
import FileUpload from "../../../../../../elements/FileUpload";
import PhoneInputField from "../../../../../../elements/PhoneInputField";
import { getReducer } from "../../../../../../elements/getReducer";
import { connect } from "react-redux";
import NumberInput from "../../../../../../elements/NumberInput";
import UPCDirectors from "./UPCDirectors";
import { UPCDir, objCompDirectorName, objTrustee, objProtector, objSettlor, objBeneficiarie, UPCXArray } from "../../../../../../JSONs/UK-Form/External/Client-Information/Chapter3";
import { fileAcceptType, NatureOfObjectComArray, rationaleArray } from "../constants";
import { parse } from "@fortawesome/fontawesome-svg-core";
import Trustees from "./Trustees";
import Protectors from "./Protectors";
import Settlors from "./Settlors";
import Beneficiaries from "./Beneficiaries"
import CompDirectors from "./CompDirectors"
import { setDataToReducer } from "../../../../../../../redux/actions/ClientInformationAction";
const chapter = "chapter3";
const baseName = "upcDetails";
class UPCDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rationales: rationaleArray(
        this.props.from === "Internal"
          ? JSON.parse(localStorage.getItem("formData")).office
          : localStorage.getItem("Office")
      ),
      selectedRationale: [],
    };
  }
  handleChange = (rationale) => {
    let array = this.state.rationales;
    let newIndex = array.indexOf(rationale);
    rationale.isChecked = !rationale.isChecked;
    array[newIndex] = rationale;
    this.setState({
      rationales: array,
    });
    let selectedItemsArray = [];
    array.forEach((item) => {
      if (item.isChecked) {
        let obj = {};
        obj["rationalStructure"] = item.label;
        selectedItemsArray.push(obj);
      }
    });
    this.setState({
      selectedRationale: selectedItemsArray,
    });
    this.props.setDataToReducer({
      type: getReducer(this.props.reducer),
      name: "rationaleStructurigForNL",
      chapter: chapter,
      value: selectedItemsArray,
      baseName: baseName,
      index: this.props.index,
      //objBaseName: this.props.objBaseName,
    });
  };
  render() {
    const { index, eMsg, entityType, isForceCloseFinAcc, arethereExistingUPCDir, noOfUPCDir, isObjCompExists, objCompNaturePropsdObjctv, isObjCompBranches, upcDirectors, noOftrustees, noOfProtectors, noOfSettlors, noOfBeneficiaries, noOfDirectors } = this.props;
    const errorMsg = JSON.parse(eMsg);
    let array = this.props.rationaleStructurigForNL;
    let existingArray = this.state.rationales;
    if (array?.length > 0) {
      existingArray.forEach((item) => {
        array.forEach((rationale) => {
          if (item.label === rationale.rationalStructure) {
            item.isChecked = true;
          }

        });
      });
    }
    else {
      existingArray.forEach((item) => {
        item.isChecked = false;
      });
    }
    //--------------------------------

    const upcDirectorsvar = [];
    if (arethereExistingUPCDir) {
      for (let i = 0; i < noOfUPCDir; i++) {
        upcDirectorsvar.push(
          <UPCDirectors
            index={i}
            form={this.props.form}
            baseName="upcDirectors"
            objBaseName={baseName}
            indexObjBaseName={index}
            reducer={this.props.reducer}
            chapter={chapter}
            errorMsg={errorMsg}
          />
        );
      }
    }

    //---------------------------------
    const objTrusteesvar = [];
    for (let i = 0; i < noOftrustees; i++) {
      objTrusteesvar.push(
        <Trustees
          index={i}
          form={this.props.form}
          baseName="objTrustees"
          objBaseName={baseName}
          indexObjBaseName={index}
          reducer={this.props.reducer}
          chapter={chapter}
          errorMsg={errorMsg}
        />
      );
    }
    //---------------------------------
    const objProtectorsvar = [];
    for (let i = 0; i < noOfProtectors; i++) {
      objProtectorsvar.push(
        <Protectors
          index={i}
          form={this.props.form}
          baseName="objProtectors"
          objBaseName={baseName}
          indexObjBaseName={index}
          reducer={this.props.reducer}
          chapter={chapter}
          errorMsg={errorMsg}
        />
      );
    }
    //--------------------------------
    const objSettlorsvar = [];
    for (let i = 0; i < noOfSettlors; i++) {
      objSettlorsvar.push(
        <Settlors
          index={i}
          form={this.props.form}
          baseName="objSettlors"
          objBaseName={baseName}
          indexObjBaseName={index}
          reducer={this.props.reducer}
          chapter={chapter}
          errorMsg={errorMsg}
        />
      );
    }

    //--------------------------------
    const objBeneficiariesvar = [];
    for (let i = 0; i < noOfBeneficiaries; i++) {
      objBeneficiariesvar.push(
        <Beneficiaries
          index={i}
          form={this.props.form}
          baseName="objBeneficiaries"
          objBaseName={baseName}
          indexObjBaseName={index}
          reducer={this.props.reducer}
          chapter={chapter}
          errorMsg={errorMsg}
        />
      );
    }
    // //-----------------------------------
    // const objCompDirectorNamesvar = [];
    // for (let i = 0; i < noOfDirectors; i++) {
    //   objCompDirectorNamesvar.push(
    //     <CompDirectors
    //       index={i}
    //       form={this.props.form}
    //       baseName="objCompDirectorNames"
    //       objBaseName={baseName}
    //       indexObjBaseName={index}
    //       reducer={this.props.reducer}
    //       chapter={chapter}
    //       errorMsg={errorMsg}
    //     />
    //   );
    // }
    return (
      <div className="mb-4 col-lg-12">
        <fieldset>
          <legend>
            Parent Company
            {index + 1}
          </legend>
          <Row>
            <div className="mb-2 col-lg-4">
              <DropDown
                label="Entity type"
                name="entityType"
                id={`entityType[${index}]`}
                options={[
                  { value: 'Company', label: 'Company' },
                  { value: 'Trust/Foundation', label: 'Trust/Foundation' },
                  { value: 'Other', label: 'Other' }
                ]}
                index={index}
                chapter={chapter}
                baseName={baseName}
                reducer={this.props.reducer}
                isRequired={this.props.form === "UK" ? true : false}
              />
              {Object.entries(errorMsg).length !== 0 &&
                errorMsg.upcDetails &&
                errorMsg.upcDetails[index] &&
                errorMsg.upcDetails[index]
                  ?.entityType?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {
                      errorMsg.upcDetails[index]
                        ?.entityType
                    }
                  </span>
                )}
            </div>
            <div className="mb-2 col-lg-4">
              <DropDown
                label="Options"
                name="options"
                id={`options[${index}]`}
                options={[
                  { value: 'Immediate', label: 'Immediate' },
                  { value: 'Intermediate', label: 'Intermediate' },
                  { value: 'Ultimate', label: 'Ultimate' }
                ]}
                index={index}
                chapter={chapter}
                baseName={baseName}
                reducer={this.props.reducer}
                isRequired={this.props.form === "UK" ? true : false}
              />
              {Object.entries(errorMsg).length !== 0 &&
                errorMsg.upcDetails &&
                errorMsg.upcDetails[index] &&
                errorMsg.upcDetails[index]
                  ?.options?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {
                      errorMsg.upcDetails[index]
                        ?.options
                    }
                  </span>
                )}
            </div>
          </Row>
          {entityType && entityType !== "Trust/Foundation" && (
            <div>
              <Row className="pt-2 pb-2">
                <div className="mb-2 col-lg-4">
                  <TextBox
                    label="Name of Entity"
                    placeholder="Enter Name of UPC"
                    name="nameUPC"
                    id={`nameUPC[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                    validationType="Text"
                    isRequired={this.props.form === "UK" ? true : false}
                  />
                  {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.upcDetails &&
                    errorMsg.upcDetails[index] &&
                    errorMsg.upcDetails[index]
                      ?.nameUPC?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.upcDetails[index]
                            ?.nameUPC
                        }
                      </span>
                    )}
                </div>
                <div className="mb-2 col-lg-6 col-xl-4">
                  <TextBox
                    label="Registered address"
                    name="addressRegistered"
                    id={`addressRegistered[${index}]`}
                    index={index}
                    placeholder="enter your text"
                    baseName={baseName}
                    chapter={chapter}
                    reducer={this.props.reducer}
                    validationType="Text"
                    isRequired={this.props.form === "UK" ? true : false}
                  />
                  {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.upcDetails &&
                    errorMsg.upcDetails[index] &&
                    errorMsg.upcDetails[index]
                      ?.addressRegistered?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.upcDetails[index]
                            ?.addressRegistered
                        }
                      </span>
                    )}

                </div>
                <div className="mb-2 col-lg-6 col-xl-4">
                  <TextBox
                    label="Postal code"
                    name="postalCode"
                    id={`postalCode[${index}]`}
                    index={index}
                    chapter={chapter}
                    placeholder="enter your text"
                    baseName={baseName}
                    reducer={this.props.reducer}
                    validationType="Text"
                  />

                </div>
                <div className="mb-2 col-lg-6 col-xl-4">
                  <TextBox
                    label="City"
                    name="city"
                    id={`city[${index}]`}
                    index={index}
                    placeholder="enter your text"
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                    validationType="Text"
                    isRequired={this.props.form === "UK" ? true : false}
                  />
                  {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.upcDetails &&
                    errorMsg.upcDetails[index] &&
                    errorMsg.upcDetails[index]
                      ?.city?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.upcDetails[index]
                            ?.city
                        }
                      </span>
                    )}

                </div>
                <div className="mb-2 col-lg-6 col-xl-4">
                  <DropDown
                    label="Country"
                    name="country"
                    id={`country[${index}]`}
                    index={index}
                    chapter={chapter}
                    options={countryList().getData()}
                    baseName={baseName}
                    reducer={this.props.reducer}
                    isRequired={this.props.form === "UK" ? true : false}
                  />
                  {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.upcDetails &&
                    errorMsg.upcDetails[index] &&
                    errorMsg.upcDetails[index]
                      ?.country?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.upcDetails[index]
                            ?.country
                        }
                      </span>
                    )}
                </div>
                <div className="mb-2 col-lg-6 col-xl-4">
                  <TextBox
                    label="Website"
                    name="websiteUPC"
                    id={`websiteUPC[${index}]`}
                    index={index}
                    chapter={chapter}
                    placeholder="enter your text"
                    baseName={baseName}
                    reducer={this.props.reducer}
                  />

                </div>
                <div className="mb-2 col-lg-6 col-xl-4">
                  <TextBox
                    label="Tax-number"
                    name="taxNum"
                    id={`taxNum[${index}]`}
                    index={index}
                    chapter={chapter}
                    placeholder="enter your text"
                    baseName={baseName}
                    reducer={this.props.reducer}
                    validationType="Text"
                  />

                </div>
                <div className="mb-2 col-lg-6 col-xl-4">
                  <TextBox
                    label="Trade register number"
                    chapter={chapter}
                    name="tradeRegisterNum"
                    id={`tradeRegisterNum[${index}]`}
                    index={index}
                    placeholder="enter your text"
                    baseName={baseName}
                    reducer={this.props.reducer}
                    validationType="Text"
                    isRequired={this.props.form === "UK" ? true : false}
                  />
                  {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.upcDetails &&
                    errorMsg.upcDetails[index] &&
                    errorMsg.upcDetails[index]
                      ?.tradeRegisterNum?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.upcDetails[index]
                            ?.tradeRegisterNum
                        }
                      </span>
                    )}
                </div>
                <div className="mb-2 col-lg-6 col-xl-4" id="phoneNumberUPC">
                  <PhoneInputField
                    label="Phone number"
                    name="phoneNumberUPC"
                    id={`phoneNumberUPC[${index}]`}
                    index={index}
                    placeholder="enter your text"
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                    country={this.props.form}
                  />

                </div>
                <div className="mb-2 col-lg-6 col-xl-4">
                  <DropDown
                    label="Country of Tax Residence"
                    name="countryOfTaxRsdnc"
                    id={`countryOfTaxRsdnc[${index}]`}
                    index={index}
                    chapter={chapter}
                    options={countryList().getData()}
                    reducer={this.props.reducer}
                    baseName={baseName}
                    isRequired={this.props.form === "UK" ? true : false}
                  />
                  {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.upcDetails &&
                    errorMsg.upcDetails[index] &&
                    errorMsg.upcDetails[index]
                      ?.countryOfTaxRsdnc?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.upcDetails[index]
                            ?.countryOfTaxRsdnc
                        }
                      </span>
                    )}
                </div>

              </Row>
              <Row>
                <div className="mb-3 col-lg-6 col-xl-4">
                  <CheckBox
                    label="Has the UPC ever been rejected or forced to close accounts with any bank or financial institution?"
                    options={["Yes", "No"]}
                    name="isForceCloseFinAcc"
                    id={`isForceCloseFinAcc[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                    isRequired={this.props.form === "UK" ? true : false}
                  />
                  {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.upcDetails &&
                    errorMsg.upcDetails[index] &&
                    errorMsg.upcDetails[index]
                      ?.isForceCloseFinAcc?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.upcDetails[index]
                            ?.isForceCloseFinAcc
                        }
                      </span>
                    )}
                </div>
                {isForceCloseFinAcc === "Yes" && (
                  <div className="mb-2 col-lg-6 col-xl-4">
                    <TextBox
                      label="Please specify a reason"
                      placeholder="enter a text"
                      name="forceCloseFinAcc"
                      id={`forceCloseFinAcc[${index}]`}
                      index={index}
                      chapter={chapter}
                      baseName={baseName}
                      reducer={this.props.reducer}
                      validationType="Text"
                      isRequired={this.props.form === "UK" ? true : false}
                    />
                    {Object.entries(errorMsg).length !== 0 &&
                      errorMsg.upcDetails &&
                      errorMsg.upcDetails[index] &&
                      errorMsg.upcDetails[index]
                        ?.forceCloseFinAcc?.length > 0 && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {
                            errorMsg.upcDetails[index]
                              ?.forceCloseFinAcc
                          }
                        </span>
                      )}
                  </div>
                )}
              </Row>
              <Row >
                <div className="mb-3 col-lg-6 col-xl-4">
                  <CheckBox
                    label="Are there existing Director(s)?"
                    options={["Yes", "No"]}
                    name="arethereExistingUPCDir"
                    id={`arethereExistingUPCDir[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                    isRequired={this.props.form === "UK" ? true : false}
                  />
                  {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.upcDetails &&
                    errorMsg.upcDetails[index] &&
                    errorMsg.upcDetails[index]
                      ?.arethereExistingUPCDir?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.upcDetails[index]
                            ?.arethereExistingUPCDir
                        }
                      </span>
                    )}
                </div>
              </Row>
              {arethereExistingUPCDir === "Yes" && (
                <Row className="pt-4 pb-2">
                  <div className="mb-2 col-lg-6 col-xl-4">

                    <NumberInput
                      label="How many directors would you like to add?"
                      name="noOfUPCDir"
                      masterObj={["upcDetails"]}
                      masterIndex={index}
                      referral={["upcDirectors"]}
                      pushedObject={[UPCDir]}
                      maxLimit={100}
                      id={`noOfUPCDir[${index}]`}
                      index={index}
                      chapter={chapter}
                      baseName={baseName}
                      reducer={this.props.reducer}
                    />
                  </div>
                  {upcDirectorsvar}

                </Row>

              )}
              <Row className="pt-4 pb-2">
                <div className="mb-2 col-lg-6 col-xl-4">
                  <FileUpload
                    label="Extract Chamber of Commerce"
                    acceptType={fileAcceptType}
                    name="extractChamberOfCommerce"
                    id={`extractChamberOfCommerce[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                  />
                  {/* {eMsg &&
                  eMsg?.extractChamberOfCommerce &&
                  eMsg?.extractChamberOfCommerce?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.extractChamberOfCommerce}
                    </span>
                  )} */}
                </div>

                <div className="mb-2 col-lg-6 col-xl-4">
                  <FileUpload
                    label="Deed of incorporation/ Articles of association"
                    acceptType={fileAcceptType}
                    name="deedOfIncorporation"
                    id={`deedOfIncorporation[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}

                  />
                  {/* {eMsg &&
                  eMsg?.deedOfIncorporation &&
                  eMsg?.deedOfIncorporation?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.deedOfIncorporation}
                    </span>
                  )} */}
                </div>

                <div className="mb-2 col-lg-6 col-xl-4">
                  <FileUpload
                    label="Shareholders’ register"
                    acceptType={fileAcceptType}
                    name="shareholdersRegister"
                    id={`shareholdersRegister[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}

                  />
                  {/* {eMsg &&
                  eMsg?.shareholdersRegister &&
                  eMsg?.shareholdersRegister?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.shareholdersRegister}
                    </span>
                  )} */}
                </div>
                <div className="mb-2 col-lg-6 col-xl-4">
                  <FileUpload
                    label="if applicable, Shareholders’ certificates"
                    acceptType={fileAcceptType}
                    name="shareholdersCertificates"
                    id={`shareholdersCertificates[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                  />
                </div>
                <div className="mb-2 col-lg-6 col-xl-4">
                  <FileUpload
                    label="Nominee agreement/ Declaration of trust"
                    acceptType={fileAcceptType}
                    name="nomineeAgreement"
                    id={`nomineeAgreement[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                  />
                  {/* {eMsg &&
                  eMsg?.nomineeAgreement &&
                  eMsg?.nomineeAgreement?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {eMsg?.nomineeAgreement}
                    </span>
                  )} */}
                </div>

                <div className="mb-2 col-lg-6 col-xl-4">
                  <FileUpload
                    label="Financial statements of the past three years"
                    acceptType={fileAcceptType}
                    name="financialStatementsThreeYears"
                    id={`financialStatementsThreeYears[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                  />
                 
                </div>
              </Row>
            </div>
          )}
          {entityType && entityType === "Trust/Foundation" && (
            <div>
              <h4 className="sub-chapter-title mb-4">Trust/Foundation Details</h4>

              <Row className="pt-1 pb-2">
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Directors/Partners"
                    name="directorsPartners"
                    id={`directorsPartners[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    placeholder="enter your text"
                    reducer={this.props.reducer}
                    validationType="Text"
                    isRequired={this.props.form === "UK" ? true : false}
                  />
                  {Object.entries(errorMsg).length !== 0 &&
                    errorMsg.upcDetails &&
                    errorMsg.upcDetails[index] &&
                    errorMsg.upcDetails[index]
                      ?.directorsPartners?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {
                          errorMsg.upcDetails[index]
                            ?.directorsPartners
                        }
                      </span>
                    )}
                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Registered office"
                    name="regOffice"
                    id={`regOffice[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    placeholder="enter your text"
                    reducer={this.props.reducer}
                    validationType="Text"
                  />
                  {/* {errors && errors?.regOffice && errors?.regOffice?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors?.regOffice}
              </span>
            )} */}
                </div>
              </Row>
              <Row className="pt-1 pb-2">
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Trust Name"
                    name="trustName"
                    id={`trustName[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    placeholder="enter your text"
                    reducer={this.props.reducer}
                    validationType="Text"

                  />

                </div>
                <div className="mb-3 col-lg-6">
                  <TextBox
                    label="Trust Jurisdiction"
                    name="trustJurisdiction"
                    id={`trustJurisdiction[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    placeholder="enter your text"
                    reducer={this.props.reducer}
                    validationType="Text"
                  />

                </div>
              </Row>
              <Row className="pt-4 pb-2">
                <div className="mb-2 col-lg-6 col-xl-4">

                  <NumberInput
                    label="Number of trustees"
                    name="noOftrustees"
                    masterObj={["upcDetails"]}
                    masterIndex={index}
                    referral={["objTrustees"]}
                    pushedObject={[objTrustee]}
                    maxLimit={100}
                    id={`noOftrustees[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                  />

                </div>
                {objTrusteesvar}
              </Row>
              <Row className="pt-4 pb-2">
                <div className="mb-2 col-lg-6 col-xl-4">
                  <NumberInput
                    label="Number of Protectors"
                    name="noOfProtectors"
                    masterObj={["upcDetails"]}
                    masterIndex={index}
                    referral={["objProtectors"]}
                    pushedObject={[objProtector]}
                    maxLimit={100}
                    id={`noOfProtectors[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                  />

                </div>
                {objProtectorsvar}
              </Row>
              <Row className="pt-4 pb-2">
                <div className="mb-2 col-lg-6 col-xl-4">
                  <NumberInput
                    label="Number of Settlors"
                    name="noOfSettlors"
                    masterObj={["upcDetails"]}
                    masterIndex={index}
                    referral={["objSettlors"]}
                    pushedObject={[objSettlor]}
                    maxLimit={100}
                    id={`noOfSettlors[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                  />

                </div>
                {objSettlorsvar}
              </Row>
              <Row className="pt-4 pb-2">
                <div className="mb-2 col-lg-6 col-xl-4">
                  <NumberInput
                    label="Number of Beneficiary"
                    name="noOfBeneficiaries"
                    masterObj={["upcDetails"]}
                    masterIndex={index}
                    referral={["objBeneficiaries"]}
                    pushedObject={[objBeneficiarie]}
                    maxLimit={100}
                    id={`noOfBeneficiaries[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                  />

                </div>
                {objBeneficiariesvar}

              </Row>
              <Row className="pt-4 pb-2">
                <div className="mb-3 col-lg-6">
                  <FileUpload
                    label="Upload Trust/Foundation supporting documents"
                    acceptType={fileAcceptType}
                    name="trustSupportingDocument"
                    id={`trustSupportingDocument[${index}]`}
                    index={index}
                    chapter={chapter}
                    baseName={baseName}
                    reducer={this.props.reducer}
                  />
                  {eMsg &&
                    eMsg?.taxAdvisorFinds &&
                    eMsg.taxAdvisorFinds?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {eMsg.taxAdvisorFinds}
                      </span>
                    )}
                </div>

              </Row>
            </div>
          )}

        </fieldset>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { baseName, index, reducer } = ownProps;
   return {
    upcDetails: state[reducer]["chapter3"][baseName][index],
    entityType: state[reducer]["chapter3"][baseName][index]["entityType"],
    isForceCloseFinAcc: state[reducer]["chapter3"][baseName][index]["isForceCloseFinAcc"],
    arethereExistingUPCDir: state[reducer]["chapter3"][baseName][index]["arethereExistingUPCDir"],
    noOfUPCDir: state[reducer]["chapter3"][baseName][index]["noOfUPCDir"],
    isObjCompExists: state[reducer]["chapter3"][baseName][index]["isObjCompExists"],
    objCompNaturePropsdObjctv: state[reducer]["chapter3"][baseName][index]["objCompNaturePropsdObjctv"],
    isObjCompBranches: state[reducer]["chapter3"][baseName][index]["isObjCompBranches"],
    upcDirectors: state[reducer]["chapter3"][baseName][index]["upcDirectors"],
    noOftrustees: state[reducer]["chapter3"][baseName][index]["noOftrustees"],
    noOfProtectors: state[reducer]["chapter3"][baseName][index]["noOfProtectors"],
    noOfSettlors: state[reducer]["chapter3"][baseName][index]["noOfSettlors"],
    noOfBeneficiaries: state[reducer]["chapter3"][baseName][index]["noOfBeneficiaries"],
    noOfDirectors: state[reducer]["chapter3"][baseName][index]["noOfDirectors"],
    rationaleStructurigForNL: state[reducer]["chapter3"][baseName][index]["rationaleStructurigForNL"],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UPCDetails);
