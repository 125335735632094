import React, { Component } from "react";
import {
  setLuxembourgCAFDetails,
  setNewLuxembourgDataToReducer,
} from "../../../../../../redux/actions/Luxembourg/LuxembourgClientInformationActions";
import {
  setCommonFields,
  setNewCommonFieldsToReducer,
} from "../../../../../../redux/actions/CommonFieldsActions";
import { connect } from "react-redux";
import { updateClientInformation } from "../../../../../../redux/actions/CAFGenericActions";

class AutoSaving extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  apiCall = () => {
    //api call for chapters data
    let requestData = {};
    let apiCallFlag = false;
    for (let [chapterNumber, data] of Object.entries(
      this.props.clientInformation
    )) {
      if (data["dirty"] === true) {
        apiCallFlag = true;
        delete data["dirty"];
        requestData[chapterNumber] = data;
      }
    }
    requestData["Id"] = localStorage.getItem("formId");
    if (apiCallFlag) {
      this.props.setLuxembourgCAFDetails(requestData).then((res) => {
        if (res.data.portalErrorCode === 200) {
          let details = {
            Id: localStorage.getItem("formId"),
            lastActivityName: "client-information",
            //lastModifiedFormId: localStorage.getItem("lastModifiedFormId"),
            status: "In Progress",
            activeLinkLevel: 1,
          };
          this.props.updateClientInformation(details);
          for (let [chapterNumber, data] of Object.entries(
            this.props.clientInformation
          )) {
            data["dirty"] = false;
          }
          this.props.setNewLuxembourgDataToReducer(
            this.props.clientInformation
          );
        }
      });
    }

    //api call for common fields
    requestData = this.props.commonFields;
    apiCallFlag = false;

    if (requestData["dirty"] === true) {
      apiCallFlag = true;
      delete requestData["dirty"];
    }
    requestData["Id"] = localStorage.getItem("formId");
    requestData["isGeneric"] = false;
    if (apiCallFlag) {
      this.props.setCommonFields(requestData);
    }
  };

  componentDidMount = () => {
    this.apiCall();
    setInterval(this.apiCall, 5000);
  };

  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    clientInformation: state.LuxembourgClientInformation,
    commonFields: state.commonFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLuxembourgCAFDetails: (data) => dispatch(setLuxembourgCAFDetails(data)),
    setNewLuxembourgDataToReducer: (data) =>
      dispatch(setNewLuxembourgDataToReducer(data)),
    updateClientInformation: (data) => dispatch(updateClientInformation(data)),
    setCommonFields: (data) => dispatch(setCommonFields(data)),
    setNewCommonFieldsToReducer: (data) =>
      dispatch(setNewCommonFieldsToReducer(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AutoSaving);
