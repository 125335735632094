import React from "react";
import { Row } from "react-bootstrap";
import CheckBox from "../../../../elements/CheckBox";
import TextBox from "../../../../elements/TextBox";
import {
  GetClientAcceptance,
  SetClientAcceptance,
} from "../../../../../redux/actions/ClientAcceptanceFormActions";
import "./../internalCAF.scss";
import { connect } from "react-redux";
import OCExists from "./OCExists";
import Directors from "./Directors";
import ObjectCompany from "./ObjectCompany";
import Rationale from "../../../external/OnBoarding/client-information/Chapter4/Rationale";
import DownloadFile from "../../../../elements/DownloadFile";
import BearerShares from "./BearerShares";
import DOC from "./DOC";
import SourceOfFundsOC from "./SourceOfFundsOC";
import BankAccntDetails from "./BankAccntDetails";
import RightsNdObligations from "./RightsNdObligations";
import MediaCoverage from "../Chapter1/MediaCoverage";
import Risks from "../Chapter1/Risks";
import AutoSaving from "../AutoSaving";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { createObject } from "../../../../../redux/actions/ClientInformationAction";
import { increaseObjects } from "../../../../elements/getReducer";
import { setInternalChapterError } from "../../../../../redux/actions/InternalChapterErrorActions";
import {fileAcceptType } from "../Constants";
import InternalFileUpload from "../../../../elements/InternalFileUpload";
import {
  BnkInfoRsk,
  RightsAndOblgtnsRsk,
} from "../../../../JSONs/InternalUser/Chapter4";
import getSequentialFields from "../getSequentialFields";
import { chapValidationError } from "../Constants";

const chapter = "chapter4";
const reducer = "ClientAcceptance";
const externalReducer = "clientInformation";
let timer;

class Chapter4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idLoading: false,
      formErrorsChapter4: {},
    };
    this.props.createObject({
      type: increaseObjects(reducer),
      chap: chapter,
      maxIndex: this.props.rightsAndOblgtnsRskLength,
      referral: "rightsAndOblgtnsRsk",
      pushedObject: RightsAndOblgtnsRsk,
    });
    this.props.createObject({
      type: increaseObjects(reducer),
      chap: chapter,
      maxIndex: this.props.bnkInfoRskLength,
      referral: "bnkInfoRsk",
      pushedObject: BnkInfoRsk,
    });
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: chapter,
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.GetClientAcceptance(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          Object.keys(this.props.errors.errors).length === 0 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationError.forEach(async (element) => {
            if (element.chapter !== "chapter12") {
              let response =
                res?.data?.result?.cafInternalValidations[element.chapter];

              const newResponse = await Promise.resolve(
                getSequentialFields(response, element.chapter, false)
              );
              this.props.setInternalChapterError({
                form: `formErrors${element.chapter}`,
                errors: newResponse?.errorChapter,
              });
            }
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    if (timer) {
      clearTimeout(timer);
    }
  };
  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;
    requestData["commonFields"] = this.props.commonFields;
    requestData[`cafExternalChap4`] = this.props.clientInformation;

    this.props.SetClientAcceptance(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.props.ClientAcceptance.chapter4["dirty"] = false;
        let response = res.data.result;

        const newResponse = await Promise.resolve(
          getSequentialFields(response, chapter, true)
        );
        this.props.setInternalChapterError({
          errors: newResponse?.errorChapter,
          form: "formErrorschapter4",
        });
        if (!res.data.result.isValid) {
          NotificationManager.info(
            "All fields are mandatory to fill in",
            null,
            4000
          );
          timer = setTimeout(() => {
            return this.props.history.push({
              pathname: "/netherlands/country-sanction-risks",
            });
          }, 4000);
        } else {
          NotificationManager.success("Form is submitted successfully.");

          return this.props.history.push({
            pathname: "/netherlands/country-sanction-risks",
          });
        }
      }
    });
  };

  render() {
    const { taxAdvisorFinds, objCompIncrdIntgtyRsk, errors } = this.props;
    const formErrorsChapter4 = JSON.stringify(errors.errors);
    const errorsMsg = JSON.parse(formErrorsChapter4);
    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="cafHeader">
            <h2 className="m-0">Client Acceptance Form </h2>
          </div>
          <div className="internal_caf generic-form">
            <div className="html2pdf__page-break"></div>
            <h3 className="chapterttl">
              4-Client Investigation - Object Company
            </h3>
            <OCExists
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              form="NL"
              PdfGeneration={this.props.PdfGeneration}
              isRequired={false}
            />
            <Directors
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              form="NL"
              PdfGeneration={this.props.PdfGeneration}
              isRequired={false}
            />
            <ObjectCompany
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              form="NL"
              PdfGeneration={this.props.PdfGeneration}
              isRequired={false}
            />
            <Rationale
              reducer="commonFields"
              errorMsg={formErrorsChapter4}
              from="internal"
              form="NL"
              PdfGeneration={this.props.PdfGeneration}
            />
            <div className="mb-2 col-lg-12 pl-0">
              <TextBox
                label="Please add comment"
                placeholder="Enter your text"
                name="rationaleStrctrngForNLCmmnt"
                id="rationaleStrctrngForNLCmmnt"
                reducer="ClientAcceptance"
                chapter="chapter4"
                validationType="Text"
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
                isRequired={false}
              />
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.rationaleStrctrngForNLCmmnt?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg.rationaleStrctrngForNLCmmnt}
                  </span>
                )}
            </div>
            <div className="html2pdf__page-break"></div>
            <Row className="pt-3 pb-2">
              <div className="mb-3 col-lg-4">
                {taxAdvisorFinds.length != 0 && (
                  <DownloadFile
                    label="Include the findings of the independent tax advisor."
                    value={taxAdvisorFinds}
                  />
                )}
                {taxAdvisorFinds.length == 0 && (
                  <InternalFileUpload
                    label="Include the findings of the independent tax advisor."
                    name="taxAdvisorFinds"
                    id="taxAdvisorFinds"
                    chapter={chapter}
                    acceptType={fileAcceptType}
                    reducer={reducer}
                  />
                )}
              </div>
              <div className="mb-3 col-lg-4">
                <CheckBox
                  label="Is approved?"
                  options={["Yes", "No"]}
                  name="findingsIndpTaxAdvisorApprvd"
                  id="findingsIndpTaxAdvisorApprvd"
                  chapter="chapter4"
                  reducer="ClientAcceptance"
                  isRequired={false}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.findingsIndpTaxAdvisorApprvd?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.findingsIndpTaxAdvisorApprvd}
                    </span>
                  )}
              </div>
            </Row>
            <BearerShares
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              form="NL"
              PdfGeneration={this.props.PdfGeneration}
              isRequired={false}
            />
            <DOC
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              form="NL"
              PdfGeneration={this.props.PdfGeneration}
              isRequired={false}
            />
            <SourceOfFundsOC
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              form="NL"
              PdfGeneration={this.props.PdfGeneration}
              isRequired={false}
            />
            <RightsNdObligations
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              form="NL"
              PdfGeneration={this.props.PdfGeneration}
              isRequired={true}
            />
            <BankAccntDetails
              errors={formErrorsChapter4}
              externalReducer={externalReducer}
              reducer={reducer}
              chapter={chapter}
              form="NL"
              PdfGeneration={this.props.PdfGeneration}
              isRequired={false}
            />
            <div className="html2pdf__page-break"></div>
            <MediaCoverage
              chapter={chapter}
              reducer={reducer}
              errors={formErrorsChapter4}
              form="NL"
              PdfGeneration={this.props.PdfGeneration}
            />
            <Risks
              chapter={chapter}
              reducer={reducer}
              errors={formErrorsChapter4}
              riskLabel="Object Company and Transaction Profile risk rating"
              questionLabel="How many OC/TP related risks can be identified?"
              boxLabel="Risk"
              form="NL"
              PdfGeneration={this.props.PdfGeneration}
            />
            <Row className="mb-3">
              <div className="col-lg-12">
                If there are integrity risks about the purpose, nature and
                reason for structuring via{" "}
                {JSON.parse(localStorage.getItem("formData")).office} of the
                object company (e.g. Aggressive tax planning, BEPS) and explain
                if there are integrity risks with respect to the transaction
                profile and the rights and obligations of the object company.
              </div>
            </Row>
            <Row className="mb-3">
              <div className="col-lg-12">
                <CheckBox
                  label="In addition, report whether the OC/Transaction Profile criteria result in a potentially increased integrity risk."
                  options={["Yes", "No"]}
                  name="objCompIncrdIntgtyRsk"
                  id="objCompIncrdIntgtyRsk"
                  chapter={chapter}
                  reducer={reducer}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.objCompIncrdIntgtyRsk?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.objCompIncrdIntgtyRsk}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="mb-3">
              {objCompIncrdIntgtyRsk === "Yes" && (
                <div className="col-lg-12">
                  <TextBox
                    label="Please describe"
                    placeholder="Enter your text"
                    name="objCompIncrdIntgtyRskText"
                    id="objCompIncrdIntgtyRskText"
                    chapter={chapter}
                    reducer={reducer}
                    isMultipleLines={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtArea"
                        : "form-control"
                    }
                  />
                  {errorsMsg &&
                    errorsMsg?.isSubmitted === true &&
                    errorsMsg?.objCompIncrdIntgtyRskText?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.objCompIncrdIntgtyRskText}
                      </span>
                    )}
                </div>
              )}
              {objCompIncrdIntgtyRsk === "No" && (
                <div className="col-md-12">
                  <b>
                    With respect to the OC/Transaction profile information, UIM
                    sees no reason to discontinue the set-up of a business
                    relationship.
                  </b>
                </div>
              )}
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ClientAcceptance: state.ClientAcceptance,
    taxAdvisorFinds: state.clientInformation.chapter4["taxAdvisorFinds"],
    objCompIncrdIntgtyRsk:
      state.ClientAcceptance.chapter4["objCompIncrdIntgtyRsk"],
    rightsAndOblgtnsRskLength:
      state.commonFields.srcFundsObjComp.rightsAndObligations.length,
    bnkInfoRskLength: state.commonFields.bnkInfo.length,
    data: state,
    errors: state.InternalChapterErrors.formErrorschapter4,
    commonFields: state.commonFields,
    clientInformation: state.clientInformation[chapter],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createObject: (data) => dispatch(createObject(data)),
    GetClientAcceptance: async (data) =>
      dispatch(await GetClientAcceptance(data)),
    SetClientAcceptance: async (data) =>
      dispatch(await SetClientAcceptance(data)),
    setInternalChapterError: (data) => dispatch(setInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter4);
