import axios from "axios";
import {
  GET_UBO_PEP_REQUEST,
  GET_UBO_PEP_SUCCESS,
  GET_UBO_PEP_FAIL,
  SEND_EMAIL_TO_UBO_REQUEST,
  SEND_EMAIL_TO_UBO_SUCCESS,
  SEND_EMAIL_TO_UBO_FAIL,
  RESEND_EMAIL_TO_UBO_REQUEST,
  RESEND_EMAIL_TO_UBO_SUCCESS,
  RESEND_EMAIL_TO_UBO_FAIL,
  CHANGE_STATUS_ON_NEXT_REQUEST,
  CHANGE_STATUS_ON_NEXT_SUCCESS,
  CHANGE_STATUS_ON_NEXT_FAIL,
  CHECK_FORM_STATUS_REQUEST,
  CHECK_FORM_STATUS_SUCCESS,
  CHECK_FORM_STATUS_FAIL,
} from "../constants/UBOPEPConstants";

const BACKEND_API_URL = process.env.REACT_APP_USER_BACKEND_API_URL;

const getUBOPEPRequest = () => {
  return {
    type: GET_UBO_PEP_REQUEST,
  };
};

const getUBOPEPRecieveData = (json) => {
  return {
    type: GET_UBO_PEP_SUCCESS,
    data: json,
  };
};

export const getUBOPEPList = (data) => {
  const userInfo = JSON.parse(localStorage.getItem("user"));

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + userInfo.authToken,
  };
  return (dispatch) => {
    dispatch(getUBOPEPRequest());
    return axios
      .post(
        `${BACKEND_API_URL}/UBOUIM/GetUBOUIMStatusList`,
        JSON.stringify(data),
        {
          headers,
        }
      )
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: GET_UBO_PEP_FAIL, data: json });
        } else {
          return dispatch(getUBOPEPRecieveData(json));
        }
      })
      .catch((err) => {
        return dispatch({
          type: GET_UBO_PEP_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

const sendEmailToUboRequest = () => {
  return {
    type: SEND_EMAIL_TO_UBO_REQUEST,
  };
};

const sendEmailToUboSuccess = (json) => {
  return {
    type: SEND_EMAIL_TO_UBO_SUCCESS,
    data: json,
  };
};

export const sendEmailToUbo = (data) => {
  const userInfo = JSON.parse(localStorage.getItem("user"));

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + userInfo.authToken,
  };
  return (dispatch) => {
    dispatch(sendEmailToUboRequest());
    return axios
      .post(`${BACKEND_API_URL}/UBOUIM/SendMailToUBO`, data, { headers })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: SEND_EMAIL_TO_UBO_FAIL, data: json });
        } else {
          return dispatch(sendEmailToUboSuccess(json));
        }
      })
      .catch((err) => {
        return dispatch({
          type: SEND_EMAIL_TO_UBO_FAIL,
          data: [],
          error: err.response,
        });
      });
  };
};

const resendEmailToUboRequest = () => {
  return {
    type: RESEND_EMAIL_TO_UBO_REQUEST,
  };
};

const resendEmailToUboSuccess = (json) => {
  return {
    type: RESEND_EMAIL_TO_UBO_SUCCESS,
    data: json,
  };
};

export const resendEmailToUbo = (data) => {
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + userInfo.authToken,
  };
  return (dispatch) => {
    dispatch(resendEmailToUboRequest());
    return axios
      .post(`${BACKEND_API_URL}/UBOUIM/ResendMailToUBO`, data, { headers })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({
            type: RESEND_EMAIL_TO_UBO_FAIL,
            data: json,
          });
        } else {
          return dispatch(resendEmailToUboSuccess(json));
        }
      })
      .catch((err) => {
        return dispatch({
          type: RESEND_EMAIL_TO_UBO_FAIL,
          error: err.response,
        });
      });
  };
};

const changeStatusOnNextReq = () => {
  return {
    type: CHANGE_STATUS_ON_NEXT_REQUEST,
  };
};

const changeStatusOnNextRcvData = (json) => {
  return {
    type: CHANGE_STATUS_ON_NEXT_SUCCESS,
    data: json,
  };
};

export const changeStatusOnNext = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(changeStatusOnNextReq());
    return axios
      .post(
        `${BACKEND_API_URL}/ClientInfoForm/SetClientInfornationFormStatus`,
        data,
        {
          headers,
        }
      )
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: CHANGE_STATUS_ON_NEXT_FAIL, data: json });
        } else {
          return dispatch(changeStatusOnNextRcvData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: CHANGE_STATUS_ON_NEXT_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

const checkFormStatusReq = () => {
  return {
    type: CHECK_FORM_STATUS_REQUEST,
  };
};

const checkFormStatusResponse = (json) => {
  return {
    type: CHECK_FORM_STATUS_SUCCESS,
    data: json,
  };
};

export const checkFormStatus = (data) => {
  const headers = {
    Authorization: "Bearer " + data.token,
  };
  return (dispatch) => {
    dispatch(checkFormStatusReq());
    return axios
      .post(`${BACKEND_API_URL}/UBOUIM/CheckFormStatus`, null, { headers })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: CHECK_FORM_STATUS_FAIL, data: json });
        } else {
          return dispatch(checkFormStatusResponse(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: CHECK_FORM_STATUS_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};
