import React, { Component } from "react";
import TextBox from "../../../../../elements/TextBox";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import DropDown from "../../../../../elements/DropDown";
import FileUpload from "../../../../../elements/FileUpload";
import countryList from "react-select-country-list";
import { fileAcceptType, upcTypes } from "./Constants";
import CheckBox from "../../../../../elements/CheckBox";
import PhoneInputField from "../../../../../elements/PhoneInputField";

class UPC extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isForceCloseFinAcc, errorMsg } = this.props;
    return (
      <>
        <Row className="pt-2 pb-2">
          <div className="mb-2 col-lg-6 col-xl-4">
            <TextBox
              label="Name of UPC"
              info="Ultimate Parent Company"
              name="nameUPC"
              id="nameUPC"
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              reducer="commonFields"
              isCommon={true}
              validationType="Text"
              disabled={this.props.from !== "clientInformation" ? true : false}
              isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false}  
            />
            {errorMsg && errorMsg?.nameUPC && errorMsg?.nameUPC?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {errorMsg?.nameUPC}
              </span>
            )}
          </div>
          <div className="mb-2 col-lg-6 col-xl-4">
            <TextBox
              label="Registered address"
              name="addressRegistered"
              id="addressRegistered"
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              reducer="commonFields"
              isCommon={true}
              validationType="Text"
              disabled={this.props.from !== "clientInformation" ? true : false}
              isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
            />
            {errorMsg &&
              errorMsg?.addressRegistered &&
              errorMsg?.addressRegistered?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errorMsg?.addressRegistered}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-6 col-xl-4">
            <TextBox
              label="Postal code"
              name="postalCode"
              id="postalCode"
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              reducer="commonFields"
              isCommon={true}
              validationType="Text"
              disabled={this.props.from !== "clientInformation" ? true : false}
              isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
            />
            {errorMsg &&
              errorMsg?.postalCode &&
              errorMsg?.postalCode?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errorMsg?.postalCode}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-6 col-xl-4">
            <TextBox
              label="City"
              name="city"
              id="city"
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              reducer="commonFields"
              isCommon={true}
              validationType="Text"
              disabled={this.props.from !== "clientInformation" ? true : false}
              isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
            />
            {errorMsg && errorMsg?.city && errorMsg?.city?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {errorMsg?.city}
              </span>
            )}
          </div>
          <div className="mb-2 col-lg-6 col-xl-4">
            <DropDown
              label="Country"
              name="country"
              id="country"
              options={countryList().getData()}
              reducer="commonFields"
              isCommon={true}
              objBaseName={this.props.objBaseName}
              disabled={this.props.from !== "clientInformation" ? true : false}
              isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
            />
            {errorMsg && errorMsg?.country && errorMsg?.country?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {errorMsg?.country}
              </span>
            )}
          </div>
          <div className="mb-2 col-lg-6 col-xl-4">
            <TextBox
              label="Website"
              name="websiteUPC"
              id="websiteUPC"
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              reducer="commonFields"
              isCommon={true}
              disabled={this.props.from !== "clientInformation" ? true : false}
              //isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
            />
            {errorMsg &&
              errorMsg?.websiteUPC &&
              errorMsg?.websiteUPC?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errorMsg?.websiteUPC}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-6 col-xl-4">
            <TextBox
              label="Tax-number"
              name="taxNum"
              id="taxNum"
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              reducer="commonFields"
              isCommon={true}
              validationType="Text"
              disabled={this.props.from !== "clientInformation" ? true : false}
              //isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
            />
            {errorMsg && errorMsg?.taxNum && errorMsg?.taxNum?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {errorMsg?.taxNum}
              </span>
            )}
          </div>
          <div className="mb-2 col-lg-6 col-xl-4">
            <TextBox
              label="Trade register number"
              name="tradeRegisterNum"
              id="tradeRegisterNum"
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              reducer="commonFields"
              isCommon={true}
              validationType="Text"
              disabled={this.props.from !== "clientInformation" ? true : false}
              //isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
            />
            {errorMsg &&
              errorMsg?.tradeRegisterNum &&
              errorMsg?.tradeRegisterNum?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errorMsg?.tradeRegisterNum}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-6 col-xl-4" id="phoneNumberUPC">
            <PhoneInputField
              label="Phone number"
              name="phoneNumberUPC"
              placeholder="enter your text"
              objBaseName={this.props.objBaseName}
              reducer="commonFields"
              isCommon={true}
              country={this.props.form}
              disabled={this.props.from !== "clientInformation" ? true : false}
              //isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
            />

            {errorMsg &&
              errorMsg?.phoneNumberUPC &&
              errorMsg?.phoneNumberUPC?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errorMsg?.phoneNumberUPC}
                </span>
              )}
          </div>
          {(this.props.form === "Curacao" ||
            this.props.form === "Malta" ||
            this.props.form === "Luxembourg") && (
            <div className="mb-2 col-lg-6 col-xl-4">
              <DropDown
                label="Country of Tax Residence"
                name="countryOfTaxRsdnc"
                id="countryOfTaxRsdnc"
                options={countryList().getData()}
                reducer="commonFields"
                isCommon={true}
                objBaseName={this.props.objBaseName}
                disabled={
                  this.props.from !== "clientInformation" ? true : false
                }
              />
              {errorMsg?.countryOfTaxRsdnc?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errorMsg?.countryOfTaxRsdnc}
                </span>
              )}
            </div>
          )}
          {(this.props.form === "Malta" ||
            this.props.form === "Luxembourg") && (
            <div className="mb-2 col-lg-6 col-xl-4">
              <DropDown
                label="Type of UPC"
                name="typeOfUPC"
                id="typeOfUPC"
                options={upcTypes}
                reducer="commonFields"
                isCommon={true}
                objBaseName={this.props.objBaseName}
                disabled={
                  this.props.from !== "clientInformation" ? true : false
                }
              />
              {errorMsg &&
                errorMsg?.typeOfUPC &&
                errorMsg?.typeOfUPC?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errorMsg?.typeOfUPC}
                  </span>
                )}
            </div>
          )}

          {(this.props.reducer === "clientInformation" ||
            this.props.reducer === "CuracaoClientInformation" ||
            this.props.reducer === "MaltaClientInformation" ||
            this.props.reducer === "LuxembourgClientInformation") && (
            <>
              <div className="mb-2 col-lg-6 col-xl-4">
                <CheckBox
                  label="Has the UPC ever been rejected or forced to close accounts with any bank or financial institution?"
                  options={["Yes", "No"]}
                  name="isForceCloseFinAcc"
                  id="isForceCloseFinAcc"
                  objBaseName={this.props.objBaseName}
                  reducer="commonFields"
                  isCommon={true}
                />
                {errorMsg &&
                  errorMsg?.isForceCloseFinAcc &&
                  errorMsg?.isForceCloseFinAcc?.length > 0 && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errorMsg?.isForceCloseFinAcc}
                    </span>
                  )}
              </div>
              {isForceCloseFinAcc === "Yes" && (
                <div className="mb-2 col-lg-6 col-xl-4">
                  <TextBox
                    label="Please specify a reason"
                    placeholder="enter a text"
                    name="forceCloseFinAcc"
                    id="forceCloseFinAcc"
                    objBaseName={this.props.objBaseName}
                    reducer="commonFields"
                    isCommon={true}
                    validationType="Text"
                  />
                  {errorMsg &&
                    errorMsg?.forceCloseFinAcc &&
                    errorMsg?.forceCloseFinAcc?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errorMsg?.forceCloseFinAcc}
                      </span>
                    )}
                </div>
              )}
            </>
          )}
        </Row>
        {(this.props.reducer === "clientInformation" ||
          this.props.reducer === "CuracaoClientInformation" ||
          this.props.reducer === "MaltaClientInformation" ||
          this.props.reducer === "LuxembourgClientInformation") && (
          <Row>
            <div className="mb-2 col-lg-6 col-xl-4">
              <TextBox
                label="Director name"
                name="upcAuthDirName"
                id="upcAuthDirName"
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                reducer="commonFields"
                isCommon={true}
                validationType="Text"
                disabled={
                  this.props.from !== "clientInformation" ? true : false
                }
                //isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
              />
              {errorMsg &&
                errorMsg?.upcAuthDirName &&
                errorMsg?.upcAuthDirName?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errorMsg?.upcAuthDirName}
                  </span>
                )}
            </div>
            <div className="mb-2 col-lg-6 col-xl-4">
              <TextBox
                label="Director email"
                name="upcAuthDirEmail"
                id="upcAuthDirEmail"
                placeholder="enter your text"
                objBaseName={this.props.objBaseName}
                reducer="commonFields"
                isCommon={true}
                validationType="Email"
                ShowValidation={
                  errorMsg &&
                  errorMsg?.upcAuthDirEmail &&
                  errorMsg?.upcAuthDirEmail?.length
                    ? false
                    : true
                }
                disabled={
                  this.props.from !== "clientInformation" ? true : false
                }
                //isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
              />
              {errorMsg &&
                errorMsg?.upcAuthDirEmail &&
                errorMsg?.upcAuthDirEmail?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errorMsg?.upcAuthDirEmail}
                  </span>
                )}
            </div>
            <div className="mb-2 col-lg-6 col-xl-4">
              <DropDown
                label="Director nationality"
                name="upcAuthDirNationality"
                id="upcAuthDirNationality"
                options={countryList().getData()}
                objBaseName={this.props.objBaseName}
                reducer="commonFields"
                isCommon={true}
                validationType="Email"
                disabled={
                  this.props.from !== "clientInformation" ? true : false
                }
              />
              {errorMsg &&
                errorMsg?.upcAuthDirNationality &&
                errorMsg?.upcAuthDirNationality?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errorMsg?.upcAuthDirNationality}
                  </span>
                )}
            </div>
            {this.props.form === "Malta" && (
              <>
                <div className="mb-2 col-lg-6 col-xl-4">
                  <FileUpload
                    label="Utility bills of director"
                    acceptType={fileAcceptType}
                    name="upcAuthDirUtilityBill"
                    id="upcAuthDirUtilityBill"
                    reducer="commonFields"
                    isCommon={true}
                    objBaseName={this.props.objBaseName}
                  />
                  {errorMsg &&
                    errorMsg?.upcAuthDirUtilityBill &&
                    errorMsg?.upcAuthDirUtilityBill?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errorMsg?.upcAuthDirUtilityBill}
                      </span>
                    )}
                </div>
                <div className="mb-2 col-lg-6 col-xl-4">
                  <FileUpload
                    label="Passport of director"
                    acceptType={fileAcceptType}
                    name="upcAuthDirPass"
                    id="upcAuthDirPass"
                    reducer="commonFields"
                    isCommon={true}
                    objBaseName={this.props.objBaseName}
                  />
                  {errorMsg &&
                    errorMsg?.upcAuthDirPass &&
                    errorMsg?.upcAuthDirPass?.length > 0 && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errorMsg?.upcAuthDirPass}
                      </span>
                    )}
                </div>
              </>
            )}
          </Row>
        )}
        {(this.props.reducer === "clientInformation" ||
          this.props.reducer === "CuracaoClientInformation" ||
          this.props.reducer === "MaltaClientInformation" ||
          this.props.reducer === "LuxembourgClientInformation") && (
          <Row className="pt-4 pb-2">
            <div className="mb-2 col-lg-6 col-xl-4">
              <FileUpload
                label="Extract Chamber of Commerce"
                acceptType={fileAcceptType}
                name="extractChamberOfCommerce"
                id="extractChamberOfCommerce"
                objBaseName={this.props.objBaseName}
                reducer="commonFields"
                isCommon={true}
              />
              {errorMsg &&
                errorMsg?.extractChamberOfCommerce &&
                errorMsg?.extractChamberOfCommerce?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errorMsg?.extractChamberOfCommerce}
                  </span>
                )}
            </div>

            <div className="mb-2 col-lg-6 col-xl-4">
              <FileUpload
                label="Deed of incorporation/ Articles of association"
                acceptType={fileAcceptType}
                name="deedOfIncorporation"
                id="deedOfIncorporation"
                reducer="commonFields"
                isCommon={true}
                objBaseName={this.props.objBaseName}
              />
              {errorMsg &&
                errorMsg?.deedOfIncorporation &&
                errorMsg?.deedOfIncorporation?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errorMsg?.deedOfIncorporation}
                  </span>
                )}
            </div>

            <div className="mb-2 col-lg-6 col-xl-4">
              <FileUpload
                label="Shareholders’ register"
                acceptType={fileAcceptType}
                name="shareholdersRegister"
                id="shareholdersRegister"
                reducer="commonFields"
                isCommon={true}
                objBaseName={this.props.objBaseName}
              />
              {errorMsg &&
                errorMsg?.shareholdersRegister &&
                errorMsg?.shareholdersRegister?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errorMsg?.shareholdersRegister}
                  </span>
                )}
            </div>

            <div className="mb-2 col-lg-6 col-xl-4">
              <FileUpload
                label="if applicable, Shareholders’ certificates"
                acceptType={fileAcceptType}
                name="shareholdersCertificates"
                id="shareholdersCertificates"
                reducer="commonFields"
                isCommon={true}
                objBaseName={this.props.objBaseName}
              />
              {errorMsg &&
                errorMsg?.shareholdersCertificates &&
                errorMsg?.shareholdersCertificates?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errorMsg?.shareholdersCertificates}
                  </span>
                )}
            </div>

            <div className="mb-2 col-lg-6 col-xl-4">
              <FileUpload
                label="if applicable, Nominee agreement/ Declaration of trust"
                acceptType={fileAcceptType}
                name="nomineeAgreement"
                id="nomineeAgreement"
                reducer="commonFields"
                isCommon={true}
                objBaseName={this.props.objBaseName}
              />
              {errorMsg &&
                errorMsg?.nomineeAgreement &&
                errorMsg?.nomineeAgreement?.length > 0 && (
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errorMsg?.nomineeAgreement}
                  </span>
                )}
            </div>

            <div className="mb-2 col-lg-6 col-xl-4">
              <FileUpload
                label="if applicable, financial statements of the past three years"
                acceptType={fileAcceptType}
                name="financialStatementsThreeYears"
                id="financialStatementsThreeYears"
                objBaseName={this.props.objBaseName}
                reducer="commonFields"
                isCommon={true}
              />
              {errorMsg?.financialStatementsThreeYears?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {errorMsg?.financialStatementsThreeYears}
                </span>
              )}
            </div>
          </Row>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isForceCloseFinAcc: state.commonFields.upc["isForceCloseFinAcc"],
  };
};

export default connect(mapStateToProps, null)(UPC);
