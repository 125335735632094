import React, { Component } from "react";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import { setDataToReducer } from "../../../../../redux/actions/ClientInformationAction";
import TextBox from "../../../../elements/TextBox";
class BankInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          id: 1,
          name: "Name of the bank",
          selector: (row) => row.bnkName,
          sortable: true,
        },
        {
          id: 2,
          name: "Country of Registration",
          selector: (row) => row.registrationCountry,
          sortable: true,
        },
        {
          id: 3,
          name: "Currency",
          selector: (row) => row.currency,
        },
        {
          id: 4,
          name: "Account number",
          selector: (row) => row.accNum,
          sortable: true,
        },
        {
          id: 5,
          name: "Authorized person(s)",
          selector: (row) => row.authorizdPrsn,
          sortable: true,
        },
        {
          id: 6,
          name: "Integrity risk",
          cell: (row) => (
            <TextBox
              name="integrityRsk"
              id="integrityRsk"
              placeholder="Enter risk"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              baseName="bnkInfoRsk"
              validationType="Text"
              isRequired={this.props.form === "Curacao" ? true : false}
              index={Number(row.id) - 1}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          ),
        },
      ],
    };
  }
  render() {
    const { errors, isRequired } = this.props;
    const errorsMsg = JSON.parse(errors);

    return (
      <>
        <h4 className="sub-chapter-title pt-3 mb-2">Bank Information</h4>
        <div className="pb-2 internalro bankinfoInternal" id="bnkInfoRsk">
          <DataTable
            columns={this.state.columns}
            data={[...this.props.bnkInfo]}
          />
          {errorsMsg &&
            errorsMsg?.isSubmitted === true &&
            errorsMsg?.bnkInfoRsk?.length > 0 && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errorsMsg.bnkInfoRsk}
              </span>
            )}
          <div className="mb-0 col-lg-12 pl-0">
            <TextBox
              label="Please add comment"
              placeholder="Enter your text"
              name="bnkInfoCmmnt"
              id="bnkInfoCmmnt"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              isRequired={isRequired}
              validationType="Text"
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.bnkInfoCmmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.bnkInfoCmmnt}
                </span>
              )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
  };
};
const mapStateToProps = (state, ownProps) => {
  const { externalReducer, chapter, form } = ownProps;
  if (form === "NL" || form === "Curacao" || form === "Luxembourg") {
    return {
      bnkInfo: state.commonFields["bnkInfo"],
    };
  } else {
    return {
      bnkInfo: state[externalReducer][chapter]["bnkInfo"],
    };
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(BankInfo);
