import axios from "axios";
const BACKEND_API_URL = process.env.REACT_APP_USER_BACKEND_API_URL;

const setDataReceiveData = (json) => {
  return {
    type: json.type,
    data: json,
  };
};

export const setDataToReducer = (data) => {
  return (dispatch) => {
    dispatch(setDataReceiveData(data));
  };
};

const CreateObjReceiveData = (json) => {
  return {
    type: json.type,
    data: json,
  };
};

export const createObject = (data) => {
  return (dispatch) => {
    dispatch(CreateObjReceiveData(data));
  };
};
const RemoveObjReceiveData = (json) => {
  return {
    type: json.type,
    data: json,
  };
};
export const removeObject = (data) => {
  return (dispatch) => {
    dispatch(RemoveObjReceiveData(data));
  };
};

const setNewDataToReducerReceiveData = (json) => {
  return {
    type: "UPDATE_NEW_DATA",
    data: json,
  };
};

export const setNewDataToReducer = (data) => {
  return (dispatch) => {
    dispatch(setNewDataToReducerReceiveData(data));
  };
};

const manageNewStateReceiveData = (json) => {
  return {
    type: json.type,
    data: json,
  };
};

export const manageNewState = (data) => {
  return (dispatch) => {
    dispatch(manageNewStateReceiveData(data));
  };
};

const SetCAFDetailsRequest = () => {
  return {
    type: "SET_CAF_DETAILS_REQUEST",
  };
};

const SetCAFDetailsReceiveData = (json) => {
  return {
    type: "SET_CAF_DETAILS_SUCCESS",
    data: json,
  };
};

export const setCAFDetails = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(SetCAFDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/CAF/SetCAFDetails`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: "SET_CAF_DETAILS_FAIL", data: json });
        } else {
          return dispatch(SetCAFDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "SET_CAF_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};

const GetCAFDetailsRequest = () => {
  return {
    type: "GET_CAF_DETAILS_REQUEST",
  };
};

const GetCAFDetailsReceiveData = (json) => {
  return {
    type: "GET_CAF_DETAILS_SUCCESS",
    data: json,
  };
};

export const getCAFDetails = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(GetCAFDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/CAF/GetCAFExternal`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: "GET_CAF_DETAILS_FAIL", data: json });
        } else {
          return dispatch(GetCAFDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "GET_CAF_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};
