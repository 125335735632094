import React, { Component } from "react";
import { connect } from "react-redux";
import Signature from "./Signature";
import jwt_decode from "jwt-decode";
import SignedBy from "./SignedBy";
import { SignedByList } from "../Constants";

import { Base64 } from "js-base64";
import {
  GetClientAcceptance,
  SetClientAcceptance,
} from "../../../../../redux/actions/ClientAcceptanceFormActions";
import { docuSignGenerateURL } from "../../../../../redux/actions/InternalFileHandleActions";
import { setDataToReducer } from "../../../../../redux/actions/ClientInformationAction";
import { getReducer } from "../../../../elements/getReducer";
import PDFContainerAllChapters from "../PDFContainerAllChapters";
import Doc from "../../../../elements/DocService";
import NotificationManager from "react-notifications/lib/NotificationManager";

import { getAllUsers } from "../../../../../redux/actions/userActions";

const chapter = "chapter13";
const reducer = "ClientAcceptance";

let selectedNames = [];
class Chapter13 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: null,
      signedBy: [],
      name: null,
      message: null,
      SignatureList: [],
      options: [],
      isValid: false,
      newOptions: [],
    };
  }

  APICall = async (array) => {
    let chapter13 = {};
    chapter13.signedBy = array;
    let requestData = {};
    let data = await this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData["chapter13"] = chapter13;
    await this.props.SetClientAcceptance(requestData).then((res) => {
      if (res.data.portalErrorCode === 200) {
        NotificationManager.success("Form details are submitted successfully.");
      }
    });
  };

  getClientAcceptanceDetails = () => {
    let formDataLocal = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formDataLocal.id,
      userId: formDataLocal.userId,
      chapter: "all",
    };
    this.props.GetClientAcceptance(reqData).then(() => {
      this.setState({ isLoading: false });
    });
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    SignedByList.forEach((element) => {
      element.name = null;
      element.email = null;
    });
    selectedNames = [];
    const search = this.props.location.search;
    const jwtToken = new URLSearchParams(search).get("jwtToken");
    let token = JSON.parse(localStorage.getItem("authToken"));
    let decoded;
    if (token) {
      decoded = jwt_decode(token);
    }

    this.setState({
      email: decoded.preferred_username,
      name: decoded.name,
    });

    let office = JSON.parse(localStorage.getItem("formData")).office;
    if (localStorage.getItem("userType") === "superadmin") {
      await this.props
        .getAllUsers({ name: "superadmin", office: office })
        .then((res) => {
          if (res.data.portalErrorCode === 200) {
            this.setState({
              options: res.data.result,
              newOptions: res.data.result,
            });
          }
        });
    } else {
      await this.props
        .getAllUsers({ name: decoded.name, office: office })
        .then((res) => {
          if (res.data.portalErrorCode === 200) {
            this.setState({
              options: res.data.result,
              newOptions: res.data.result,
            });
          }
        });
    }
    this.getClientAcceptanceDetails();
    if (jwtToken) {
      let decodedJWT = jwt_decode(jwtToken);
      let message = decodedJWT.Message;
      message = Base64.decode(message);
      if (message === "Success") {
        NotificationManager.success("Document signed successfully");
      } else if (message === "Failure") {
        NotificationManager.error("Error while signing document");
      }
    }
    let list = SignedByList;
    let new_list = [];
    list.forEach((role) => {
      if (role.label === "Form Creator") {
        let obj = {
          ...role,
          name: decoded.name,
          email: decoded.preferred_username,
          dos: new Date(),
        };
        new_list.push(obj);
      } else {
        new_list.push(role);
      }
    });
    this.setState({ SignatureList: new_list });
    this.props.setDataToReducer({
      type: getReducer(reducer),
      name: "SignatureList",
      chapter: "chapter13",
      value: list,
    });
  };

  createPdf = async (html) => {
    let array = this.state.SignatureList;
    this.setState({ SignatureList: array });
    await this.validateFields(array);

    if (this.state.isValid) {
      this.setState({ isLoading: true });
      this.state.SignatureList.forEach((list, i) => {
        list["id"] = String(i + 1);
        list["signedBy"] = list["label"];
        list["status"] = "NotSigned";
        list["dos"] = null;
        delete list["label"];
        delete list["isFixed"];
        delete list["value"];
      });

      await this.APICall(array);
      let user_data = JSON.parse(localStorage.getItem("formData"));
      let pdfAllChapters = await Doc.createPdf(html);
      const formData = new FormData();
      formData.append("CAFInternfile", pdfAllChapters, "CAFForm.pdf");
      formData.append("id", array[0].id);
      formData.append("signedByEmail", array[0].email);
      formData.append("signedByName", array[0].name);
      formData.append("extUsrOffice", user_data.office);
      formData.append("formId", user_data.id);
      formData.append("userId", user_data.userId);
      formData.append("extUserEmail", user_data.userEmail);
      formData.append("extUsername", user_data.userName);
      formData.append("companyId", user_data.clientId);
      formData.append("companyName", user_data.clientName);
      formData.append("signedByRole", array[0].signedBy);
      await this.props
        .docuSignGenerateURL(formData)
        .then((res) => {
          this.setState({ isLoading: false });
          if (res.data?.portalErrorCode === 200) {
            window.location = res.data.result;
          }
        })
        .catch(this.catchError);
    }
  };
  removeDuplicates = (arr) => {
    let new_array = [...new Set(arr)];

    return new_array;
  };

  handleChange = (e, user) => {
    let list = this.state.SignatureList;
    list.forEach((role) => {
      if (role.label === user.label) {
        if (role.name === null) {
          role["name"] = e.name;
          role["email"] = e.email;
        } else {
          const isExists = (element) => element.role === user.label;
          const index = selectedNames.findIndex(isExists);

          if (index > -1) {
            selectedNames.splice(index, 1);
            role["name"] = e.name;
            role["email"] = e.email;
          }
        }
      }
    });

    this.setState({ SignatureList: list });

    //remove selected option from list
    let newOptions = [];
    selectedNames.push({ name: e.name, role: user.label });
    this.state.options.forEach((option) => {
      selectedNames.forEach((names, i) => {
        if (names.name !== option.name) {
          newOptions.push(option);
        }
      });
    });
    let names = selectedNames.map((names) => names.name);
    let newOption = this.state.options.filter(
      (option) => !names.includes(option.name)
    );
    newOption = this.removeDuplicates(newOption);

    this.setState({ newOptions: newOption });
  };

  validateFields(array) {
    let isValid = false;
    array.forEach((list) => {
      if (list.name === null || list.name === "" || list.name === undefined) {
        list["message"] = "Please fill this detail";
        isValid = false;
        this.setState({ isValid: false });
        return this.state.isValid;
      } else {
        this.setState({ isValid: true });
        delete list["message"];
        isValid = true;
        return this.state.isValid;
      }
    });
  }

  render() {
    const { SignatureList } = this.state;
    let signatureCompArray = [];
    let signatureCompArrayPDF = [];
    for (let i = 0; i < SignatureList.length; i++) {
      signatureCompArray.push(
        <Signature
          user={SignatureList[i]}
          from="List"
          index={i}
          options={this.state.newOptions}
          handleChange={(e, user) => this.handleChange(e, user)}
        />
      );
      signatureCompArrayPDF.push(
        <Signature
          user={SignatureList[i]}
          from="List"
          index={i}
          options={this.state.newOptions}
          handleChange={(e, user) => this.handleChange(e, user)}
          PdfGeneration="Yes"
        />
      );
    }
    let array = [...this.props.signedBy];
    let display4Comps = false;
    if (array.length > 0) {
      array.forEach((data) => {
        if (data.signedBy === "Form Creator" && data.status === "NotSigned") {
          display4Comps = true;
        }
      });
    } else {
      display4Comps = true;
    }

    return (
      <>
        <div className="dashboard">
          <div className="userlist">
            <div className="cafHeader">
              <h2 className="m-0">Client Acceptance Form</h2>
            </div>
            <div className="internal_caf generic-form">
              <div className="html2pdf__page-break"></div>
              {display4Comps ? (
                <>
                  {signatureCompArray}
                  <PDFContainerAllChapters createPdf={this.createPdf}>
                    {signatureCompArrayPDF}
                  </PDFContainerAllChapters>
                </>
              ) : (
                <>
                  <SignedBy
                    user={{ email: this.state.email, name: this.state.name }}
                  />
                </>
              )}
            </div>
            {this.state.isLoading && <div className="loader"></div>}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
    GetClientAcceptance: async (data) =>
      dispatch(await GetClientAcceptance(data)),
    SetClientAcceptance: async (data) =>
      dispatch(await SetClientAcceptance(data)),
    docuSignGenerateURL: async (data) =>
      dispatch(await docuSignGenerateURL(data)),
    getAllUsers: async (data) => dispatch(await getAllUsers(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    ClientAcceptance: state.ClientAcceptance,
    signedBy: state.ClientAcceptance.chapter13["signedBy"],
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Chapter13);
