import { Component } from "react";

/* eslint-disable no-useless-escape */
const emailRegex = RegExp(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
class FormValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors1: {
        entries: [],
      },
      formErrors2: {
        intermediaryDetails: [],
      },
      formErrors3: {
        ubox: [],
      },
      formErrors4: {
        objCompDirectorNames: [],
        objCompToUBO: {},
        uboToObjComp: {},
        objCompToGrpComp: {},
        grpCompToObjComp: {},
        objCompToTP: {},
        tpToObjComp: {},
      },
      formErrors5: {},
      formErrors6: {},
    };
  }
  ValidateClientInformation = async (data, commonFields) => {
    let formErrors = {};
    Object.entries(data).forEach(async (entry) => {
      const [key, value] = entry;
      let result = await this.checkFormValidations(value, key, commonFields,data);

      formErrors[key] = result;
    });
    return { formErrors };
  };

  //Check Form validations
  checkFormValidations = (data, chapter, commonFields,allData) => {
    const message = "Please fill this detail";
    const messageFile = "Please upload the file";

    switch (chapter) {
      case "chapter1":
        let formErrorsChapter1 = this.state.formErrors1;
        // formErrorsChapter1.isCompIncorporated =
        //   data["isCompIncorporated"] === "" ||
        //   data["isCompIncorporated"] === null ||
        //   data["isCompIncorporated"] === undefined
        //     ? message
        //     : "";
        // if (data["isCompIncorporated"] === "Yes") {
        //   formErrorsChapter1.entities = [];
        //   data["entities"].forEach((element) => {
        //     let errors = {};
        //     errors.article =
        //       element["article"] === null || element["article"].length === 0
        //         ? messageFile
        //         : "";
        //     errors.chamberExtract =
        //       element["chamberExtract"] === null ||
        //       element["chamberExtract"].length === 0
        //         ? messageFile
        //         : "";
        //     errors.finStat =
        //       element["finStat"] === null || element["finStat"].length === 0
        //         ? messageFile
        //         : "";
        //     errors.shareholderRegister =
        //       element["shareholderRegister"] === null ||
        //       element["shareholderRegister"].length === 0
        //         ? messageFile
        //         : "";
        //     formErrorsChapter1.entities.push(errors);
        //   });
        // }
        // if (data["isCompIncorporated"] === "No") {
        //   formErrorsChapter1.entities = [];
        // }
        // formErrorsChapter1.upcActivites =
        //   commonFields["upcActivites"] === "" ||
        //   commonFields["upcActivites"] === null ||
        //   commonFields["upcActivites"] === undefined
        //     ? message
        //     : "";
        // formErrorsChapter1.upcSector =
        //   commonFields["upcSector"] === "" ||
        //   commonFields["upcSector"] === null ||
        //   commonFields["upcSector"] === undefined
        //     ? message
        //     : "";
        // formErrorsChapter1.shareholderActivites =
        //   commonFields["shareholderActivites"] === "" ||
        //   commonFields["shareholderActivites"] === null ||
        //   commonFields["shareholderActivites"] === undefined
        //     ? message
        //     : "";
        // formErrorsChapter1.shareholderSector =
        //   commonFields["shareholderSector"] === "" ||
        //   commonFields["shareholderSector"] === null ||
        //   commonFields["shareholderSector"] === undefined
        //     ? message
        //     : "";
        // formErrorsChapter1.objCompActivities =
        //   commonFields["objCompActivities"] === "" ||
        //   commonFields["objCompActivities"] === null ||
        //   commonFields["objCompActivities"] === undefined
        //     ? message
        //     : "";
        // formErrorsChapter1.objCompSector =
        //   commonFields["objCompSector"] === "" ||
        //   commonFields["objCompSector"] === null ||
        //   commonFields["objCompSector"] === undefined
        //     ? message
        //     : "";
        // formErrorsChapter1.participantActivites =
        //   commonFields["participantActivites"] === "" ||
        //   commonFields["participantActivites"] === null ||
        //   commonFields["participantActivites"] === undefined
        //     ? message
        //     : "";
        // formErrorsChapter1.participantSector =
        //   commonFields["participantSector"] === "" ||
        //   commonFields["participantSector"] === null ||
        //   commonFields["participantSector"] === undefined
        //     ? message
        //     : "";
        // formErrorsChapter1.otherActivites =
        //   commonFields["otherActivites"] === "" ||
        //   commonFields["otherActivites"] === null ||
        //   commonFields["otherActivites"] === undefined
        //     ? message
        //     : "";
        // formErrorsChapter1.otherSector =
        //   commonFields["otherSector"] === "" ||
        //   commonFields["otherSector"] === null ||
        //   commonFields["otherSector"] === undefined
        //     ? message
        //     : "";
        // formErrorsChapter1.structureChart =
        //   data["structureChart"].length === 0 ? messageFile : "";
        this.setState({
          formErrors1: formErrorsChapter1,
        });

        return { formErrorsChapter1 };

      case "chapter2":
        let formErrorsChapter2 = this.state.formErrors2;
        // formErrorsChapter2.isClientRepInvolved =
        //   data["isClientRepInvolved"] === "" ||
        //   data["isClientRepInvolved"] === null ||
        //   data["isClientRepInvolved"] === undefined
        //     ? message
        //     : "";
        formErrorsChapter2.isClientTransfrd =
          commonFields["isClientTransfrd"] === "" ||
          commonFields["isClientTransfrd"] === null ||
          commonFields["isClientTransfrd"] === undefined
            ? message
            : "";
        formErrorsChapter2.srvcPrvdrName =
          commonFields["isClientTransfrd"] === "Yes" &&
          (commonFields["srvcPrvdrName"] === "" ||
            commonFields["srvcPrvdrName"] === null ||
            commonFields["srvcPrvdrName"] === undefined)
            ? message
            : "";
        formErrorsChapter2.reasonClientTransfrd =
          commonFields["isClientTransfrd"] === "Yes" &&
          (commonFields["reasonClientTransfrd"] === "" ||
            commonFields["reasonClientTransfrd"] === null ||
            commonFields["reasonClientTransfrd"] === undefined)
            ? message
            : "";
        if (commonFields["isClientTransfrd"] === "No") {
          formErrorsChapter2.srvcPrvdrName = "";
          formErrorsChapter2.reasonClientTransfrd = "";
        }

        // if (data["isClientRepInvolved"] === "Yes") {
        //   formErrorsChapter2.intermediaryDetails = [];
        //   data["intermediaryDetails"].forEach((element) => {
        //     let errors = {};
        //     errors.isClientReal =
        //       element["isClientReal"] === "" ||
        //       element["isClientReal"] === null ||
        //       element["isClientReal"] === undefined
        //         ? message
        //         : "";
        //     errors.activeInCountry =
        //       element["activeInCountry"].length === 0 ? message : "";
        //     errors.isPOA =
        //       element["isPOA"] === "" ||
        //       element["isPOA"] === null ||
        //       element["isPOA"] === undefined
        //         ? message
        //         : "";
        //     errors.legalEntityName =
        //       element["isClientReal"] === "No" &&
        //       (element["legalEntityName"] === "" ||
        //         element["legalEntityName"] === null ||
        //         element["legalEntityName"] === undefined)
        //         ? message
        //         : "";
        //     errors.cocExtract =
        //       element["isClientReal"] === "No" &&
        //       element["cocExtract"].length === 0
        //         ? messageFile
        //         : "";
        //     errors.clientRepPassport =
        //       element["isClientReal"] === "Yes" &&
        //       element["clientRepPassport"].length === 0
        //         ? messageFile
        //         : "";
        //     errors.legalEntityName =
        //       element["isClientReal"] === "Yes" &&
        //       (element["legalEntityName"] === "" ||
        //         element["legalEntityName"] === null ||
        //         element["legalEntityName"] === undefined)
        //         ? message
        //         : "";
        //     errors.clientRepName =
        //       element["isClientReal"] === "Yes" &&
        //       (element["clientRepName"] === "" ||
        //         element["clientRepName"] === null ||
        //         element["clientRepName"] === undefined)
        //         ? message
        //         : "";
        //     errors.clientRepNationality =
        //       element["isClientReal"] === "Yes" &&
        //       (element["clientRepNationality"] === "" ||
        //         element["clientRepNationality"] === null ||
        //         element["clientRepNationality"] === undefined)
        //         ? message
        //         : "";
        //     errors.poa =
        //       element["isPOA"] === "Yes" && element["poa"].length === 0
        //         ? messageFile
        //         : "";
        //     errors.noPOAReason =
        //       element["isPOA"] === "No" &&
        //       (element["noPOAReason"] === "" ||
        //         element["noPOAReason"] === null ||
        //         element["noPOAReason"] === undefined)
        //         ? message
        //         : "";
        //     errors.isUBOIdentifyVerify =
        //       element["isUBOIdentifyVerify"] === "" ||
        //       element["isUBOIdentifyVerify"] === null ||
        //       element["isUBOIdentifyVerify"] === undefined
        //         ? message
        //         : "";
        //     errors.uboIdentityPassport =
        //       element["isUBOIdentifyVerify"] === "Yes" &&
        //       element["uboIdentityPassport"].length === 0
        //         ? messageFile
        //         : "";
        //     errors.noUBOIdentifyVerifyReason =
        //       element["isUBOIdentifyVerify"] === "No" &&
        //       (element["noUBOIdentifyVerifyReason"] === "" ||
        //         element["noUBOIdentifyVerifyReason"] === null ||
        //         element["noUBOIdentifyVerifyReason"] === undefined)
        //         ? message
        //         : "";
        //     formErrorsChapter2.intermediaryDetails.push(errors);
        //   });
        // }
        // if (data["isClientRepInvolved"] === "No") {
        //   formErrorsChapter2.intermediaryDetails = [];
        // }
        this.setState({
          formErrors2: formErrorsChapter2,
        });

        return { formErrorsChapter2 };

      case "chapter3":
        let formErrorsChapter3 = this.state.formErrors3;
        formErrorsChapter3.isHoldingInterest =
          data["isHoldingInterest"] === "" ||
          data["isHoldingInterest"] === null ||
          data["isHoldingInterest"] === undefined
            ? message
            : "";
        formErrorsChapter3.isUBOClassified =
          data["isUBOClassified"] === "" ||
          data["isUBOClassified"] === null ||
          data["isUBOClassified"] === undefined
            ? message
            : "";
        formErrorsChapter3.isAssetClient =
          data["isAssetClient"] === "" ||
          data["isAssetClient"] === null ||
          data["isAssetClient"] === undefined
            ? message
            : "";
        formErrorsChapter3.isUPCExists =
          commonFields["isUPCExists"] === "" ||
          commonFields["isUPCExists"] === null ||
          commonFields["isUPCExists"] === undefined
            ? message
            : "";
        formErrorsChapter3.isHoldingInterestReasonYes =
          data["isHoldingInterest"] === "Yes" &&
          (data["isHoldingInterestReason"] === "" ||
            data["isHoldingInterestReason"] === null ||
            data["isHoldingInterestReason"] === undefined)
            ? message
            : "";
        formErrorsChapter3.isUBOClassifiedReason =
          data["isUBOClassified"] === "Yes" &&
          (data["isUBOClassifiedReason"] === "" ||
            data["isUBOClassifiedReason"] === null ||
            data["isUBOClassifiedReason"] === undefined)
            ? message
            : "";
        formErrorsChapter3.isAssetClientReason =
          data["isAssetClient"] === "Yes" &&
          (data["isAssetClientReason"] === "" ||
            data["isAssetClientReason"] === null ||
            data["isAssetClientReason"] === undefined)
            ? message
            : "";
        formErrorsChapter3.isHoldingInterestReason =
          data["isHoldingInterest"] === "No" &&
          data["isUBOClassified"] === "No" &&
          data["isAssetClient"] === "No" &&
          (data["isHoldingInterestReason"] === "" ||
            data["isHoldingInterestReason"] === null ||
            data["isHoldingInterestReason"] === undefined)
            ? message
            : "";
        if (
          data["isHoldingInterest"] === "Yes" &&
          data["isUBOClassified"] === "Yes" &&
          data["isAssetClient"] === "Yes"
        ) {
          formErrorsChapter3.ubox = [];
          commonFields.ubo.ubox.forEach((element) => {
            let errors = {};
            errors.uboXName =
              element["uboXName"] === "" ||
              element["uboXName"] === null ||
              element["uboXName"] === undefined
                ? message
                : "";
            errors.uboEmail =
              element["uboEmail"] === "" ||
              element["uboEmail"] === null ||
              element["uboEmail"] === undefined
                ? message
                : "";
            if (element["uboEmail"]) {
              emailRegex.test(element["uboEmail"])
                ? (errors.uboEmail = "")
                : (errors.uboEmail = "Please enter valid email");
            }
            errors.uboPhoneNum =
              element["uboPhoneNum"] === "" ||
              element["uboPhoneNum"] === null ||
              element["uboPhoneNum"] === undefined
                ? message
                : "";

            errors.isUBOPEP =
              element["isUBOPEP"] === "" ||
              element["isUBOPEP"] === null ||
              element["isUBOPEP"] === undefined
                ? message
                : "";
            // errors.certifiedPassportUBO =
            //   element["certifiedPassportUBO"].length === 0 ? messageFile : "";
            formErrorsChapter3.ubox.push(errors);
          });
        }
        if (commonFields["isUPCExists"] === "Yes") {
          formErrorsChapter3.nameUPC =
            commonFields["upc"]["nameUPC"] === "" ||
            commonFields["upc"]["nameUPC"] === null ||
            commonFields["upc"]["nameUPC"] === undefined
              ? message
              : "";
          formErrorsChapter3.addressRegistered =
            commonFields["upc"]["addressRegistered"] === "" ||
            commonFields["upc"]["addressRegistered"] === null ||
            commonFields["upc"]["addressRegistered"] === undefined
              ? message
              : "";
          formErrorsChapter3.postalCode =
            commonFields["upc"]["postalCode"] === "" ||
            commonFields["upc"]["postalCode"] === null ||
            commonFields["upc"]["postalCode"] === undefined
              ? message
              : "";
          formErrorsChapter3.city =
            commonFields["upc"]["city"] === "" ||
            commonFields["upc"]["city"] === null ||
            commonFields["upc"]["city"] === undefined
              ? message
              : "";
          formErrorsChapter3.country =
            commonFields["upc"]["country"] === "" ||
            commonFields["upc"]["country"] === null ||
            commonFields["upc"]["country"] === undefined
              ? message
              : "";
          // formErrorsChapter3.websiteUPC =
          //   commonFields["upc"]["websiteUPC"] === "" ||
          //   commonFields["upc"]["websiteUPC"] === null ||
          //   commonFields["upc"]["websiteUPC"] === undefined
          //     ? message
          //     : "";
          // formErrorsChapter3.taxNum =
          //   commonFields["upc"]["taxNum"] === "" ||
          //   commonFields["upc"]["taxNum"] === null ||
          //   commonFields["upc"]["taxNum"] === undefined
          //     ? message
          //     : "";
          // formErrorsChapter3.tradeRegisterNum =
          //   commonFields["upc"]["tradeRegisterNum"] === "" ||
          //   commonFields["upc"]["tradeRegisterNum"] === null ||
          //   commonFields["upc"]["tradeRegisterNum"] === undefined
          //     ? message
          //     : "";
          // formErrorsChapter3.phoneNumberUPC =
          //   commonFields["upc"]["phoneNumberUPC"] === "" ||
          //   commonFields["upc"]["phoneNumberUPC"] === null ||
          //   commonFields["upc"]["phoneNumberUPC"] === undefined
          //     ? message
          //     : "";
          // formErrorsChapter3.isForceCloseFinAcc =
          //   commonFields["upc"]["isForceCloseFinAcc"] === "" ||
          //   commonFields["upc"]["isForceCloseFinAcc"] === null ||
          //   commonFields["upc"]["isForceCloseFinAcc"] === undefined
          //     ? message
          //     : "";

          // formErrorsChapter3.forceCloseFinAcc =
          //   commonFields["upc"]["isForceCloseFinAcc"] === "Yes" &&
          //   (commonFields["upc"]["forceCloseFinAcc"] === "" ||
          //     commonFields["upc"]["forceCloseFinAcc"] === null ||
          //     commonFields["upc"]["forceCloseFinAcc"] === undefined)
          //     ? message
          //     : "";
          // formErrorsChapter3.upcAuthDirName =
          //   commonFields["upc"]["upcAuthDirName"] === "" ||
          //   commonFields["upc"]["upcAuthDirName"] === null ||
          //   commonFields["upc"]["upcAuthDirName"] === undefined
          //     ? message
          //     : "";
          // formErrorsChapter3.upcAuthDirEmail =
          //   commonFields["upc"]["upcAuthDirEmail"] === "" ||
          //   commonFields["upc"]["upcAuthDirEmail"] === null ||
          //   commonFields["upc"]["upcAuthDirEmail"] === undefined
          //     ? message
          //     : "";
          if (commonFields["upc"]["upcAuthDirEmail"]) {
            emailRegex.test(commonFields["upc"]["upcAuthDirEmail"])
              ? (formErrorsChapter3.upcAuthDirEmail = "")
              : (formErrorsChapter3.upcAuthDirEmail =
                  "Please enter valid email");
          }
          // formErrorsChapter3.upcAuthDirNationality =
          //   commonFields["upc"]["upcAuthDirNationality"] === "" ||
          //   commonFields["upc"]["upcAuthDirNationality"] === null ||
          //   commonFields["upc"]["upcAuthDirNationality"] === undefined
          //     ? message
          //     : "";

          // formErrorsChapter3.extractChamberOfCommerce =
          //   commonFields["upc"]["extractChamberOfCommerce"].length === 0
          //     ? messageFile
          //     : "";
          // formErrorsChapter3.deedOfIncorporation =
          //   commonFields["upc"]["deedOfIncorporation"].length === 0
          //     ? messageFile
          //     : "";
          // formErrorsChapter3.shareholdersRegister =
          //   commonFields["upc"]["shareholdersRegister"].length === 0
          //     ? messageFile
          //     : "";
          // formErrorsChapter3.shareholdersCertificates =
          //   commonFields["upc"]["shareholdersCertificates"].length === 0
          //     ? messageFile
          //     : "";
          // formErrorsChapter3.nomineeAgreement =
          //   commonFields["upc"]["nomineeAgreement"].length === 0
          //     ? messageFile
          //     : "";
          // formErrorsChapter3.financialStatementsThreeYears =
          //   commonFields["upc"]["financialStatementsThreeYears"].length === 0
          //     ? messageFile
          //     : "";
        }
        if (commonFields["isUPCExists"] === "No") {
          formErrorsChapter3.nameUPC = "";
          formErrorsChapter3.addressRegistered = "";
          formErrorsChapter3.postalCode = "";
          formErrorsChapter3.city = "";
          formErrorsChapter3.country = "";
          formErrorsChapter3.websiteUPC = "";
          formErrorsChapter3.taxNum = "";
          formErrorsChapter3.tradeRegisterNum = "";
          formErrorsChapter3.phoneNumberUPC = "";
          formErrorsChapter3.isForceCloseFinAcc = "";
          formErrorsChapter3.ForceCloseFinAcc = "";
          formErrorsChapter3.extractChamberOfCommerce = "";
          formErrorsChapter3.deedOfIncorporation = "";
          formErrorsChapter3.shareholdersRegister = "";
          formErrorsChapter3.shareholdersCertificates = "";
          formErrorsChapter3.nomineeAgreement = "";
          formErrorsChapter3.financialStatementsThreeYears = "";
        }
        this.setState({
          formErrorsChapter3: formErrorsChapter3,
        });

        return { formErrorsChapter3 };

      case "chapter4":
        let formErrorsChapter4 = this.state.formErrors4;

        formErrorsChapter4.isObjCompExists =
          data["isObjCompExists"] === "" ||
          data["isObjCompExists"] === null ||
          data["isObjCompExists"] === undefined
            ? message
            : "";
        formErrorsChapter4.objCompName =
          commonFields["objCompName"] === "" ||
          commonFields["objCompName"] === null ||
          commonFields["objCompName"] === undefined
            ? message
            : "";
        if (data["isObjCompExists"] === "Yes") {
          formErrorsChapter4.commercialRegister =
            data["commercialRegister"] === "" ||
            data["commercialRegister"] === null ||
            data["commercialRegister"] === undefined
              ? message
              : "";
        } else {
          formErrorsChapter4.commercialRegister = "";
        }
        formErrorsChapter4.objCompDirectorNames = [];
        // data.objCompDirectorNames.forEach((element) => {
        //   let errors = {};
        //   errors.dirName =
        //     element["dirName"] === "" ||
        //     element["dirName"] === null ||
        //     element["dirName"] === undefined
        //       ? message
        //       : "";
        //   errors.dirNationality =
        //     element["dirNationality"] === "" ||
        //     element["dirNationality"] === null ||
        //     element["dirNationality"] === undefined
        //       ? message
        //       : "";
        //   formErrorsChapter4.objCompDirectorNames.push(errors);
        // });
        // formErrorsChapter4.objCompActiveCountry =
        //   data["objCompActiveCountry"] === null ||
        //   data["objCompActiveCountry"].length === 0
        //     ? message
        //     : "";
        // formErrorsChapter4.isObjCompBranches =
        //   data["isObjCompBranches"] === "" ||
        //   data["isObjCompBranches"] === null ||
        //   data["isObjCompBranches"] === undefined
        //     ? message
        //     : "";
        // formErrorsChapter4.objCompBranch =
        //   data["isObjCompBranches"] === "Yes" &&
        //   (data["objCompBranch"] === null || data["objCompBranch"].length === 0)
        //     ? message
        //     : "";
        // if (data["isObjCompBranches"] === "No") {
        //   formErrorsChapter4.objCompBranch = "";
        // }
        // formErrorsChapter4.taxAdvisorFinds =
        //   data["taxAdvisorFinds"] === null ||
        //   data["taxAdvisorFinds"].length === 0
        //     ? message
        //     : "";
        formErrorsChapter4.rationaleSelectedInfo =
          commonFields["rationaleSelectedInfo"] === "" ||
          commonFields["rationaleSelectedInfo"] === null ||
          commonFields["rationaleSelectedInfo"] === undefined
            ? message
            : "";
        formErrorsChapter4.isInsuranceBearerShares =
          commonFields["isInsuranceBearerShares"] === "" ||
          commonFields["isInsuranceBearerShares"] === null ||
          commonFields["isInsuranceBearerShares"] === undefined
            ? message
            : "";
        formErrorsChapter4.bsCurrency =
          commonFields["isInsuranceBearerShares"] === "Yes" &&
          (commonFields["bsCurrency"] === "" ||
            commonFields["bsCurrency"] === null ||
            commonFields["bsCurrency"] === undefined)
            ? message
            : "";
        formErrorsChapter4.bsAmount =
          commonFields["isInsuranceBearerShares"] === "Yes" &&
          (commonFields["bsAmount"] === "" ||
            commonFields["bsAmount"] === null ||
            commonFields["bsAmount"] === undefined)
            ? message
            : "";
        formErrorsChapter4.bsHolder =
          commonFields["isInsuranceBearerShares"] === "Yes" &&
          (commonFields["bsHolder"] === "" ||
            commonFields["bsHolder"] === null ||
            commonFields["bsHolder"] === undefined)
            ? message
            : "";
        formErrorsChapter4.bsBeneficiary =
          commonFields["isInsuranceBearerShares"] === "Yes" &&
          (commonFields["bsBeneficiary"] === "" ||
            commonFields["bsBeneficiary"] === null ||
            commonFields["bsBeneficiary"] === undefined)
            ? message
            : "";
        formErrorsChapter4.bsEvidences =
          commonFields["isInsuranceBearerShares"] === "Yes" &&
          (commonFields["bsEvidences"] === "" ||
            commonFields["bsEvidences"] === null ||
            commonFields["bsEvidences"] === undefined)
            ? message
            : "";
        if (commonFields["isInsuranceBearerShares"] === "No") {
          formErrorsChapter4.bsCurrency = "";
          formErrorsChapter4.bsAmount = "";
          formErrorsChapter4.bsHolder = "";
          formErrorsChapter4.bsBeneficiary = "";
          formErrorsChapter4.bsEvidences = "";
        }
        // formErrorsChapter4.copyPassportManagement =
        //   data["documentsObjComp"]["copyPassportManagement"] === null ||
        //   data["documentsObjComp"]["copyPassportManagement"].length === 0
        //     ? messageFile
        //     : "";
        // formErrorsChapter4.extractChamberOfCommerce4 =
        //   data["documentsObjComp"]["extractChamberOfCommerce"] === null ||
        //   data["documentsObjComp"]["extractChamberOfCommerce"].length === 0
        //     ? messageFile
        //     : "";
        // formErrorsChapter4.deedOfIncorporation4 =
        //   data["documentsObjComp"]["deedOfIncorporation"] === null ||
        //   data["documentsObjComp"]["deedOfIncorporation"].length === 0
        //     ? messageFile
        //     : "";
        // formErrorsChapter4.shareholdersRegister4 =
        //   data["documentsObjComp"]["shareholdersRegister"] === null ||
        //   data["documentsObjComp"]["shareholdersRegister"].length === 0
        //     ? messageFile
        //     : "";
        // formErrorsChapter4.shareholdersCertificates4 =
        //   data["documentsObjComp"]["shareholdersCertificates"] === null ||
        //   data["documentsObjComp"]["shareholdersCertificates"].length === 0
        //     ? messageFile
        //     : "";
        // formErrorsChapter4.financialStatmntsThreeYears4 =
        //   data["isObjCompExists"] === "Yes" &&
        //   (data["documentsObjComp"]["financialStatmntsThreeYears"] === null ||
        //     data["documentsObjComp"]["financialStatmntsThreeYears"].length ===
        //       0)
        //     ? messageFile
        //     : "";
        formErrorsChapter4.isTaxMemorandum =
          commonFields["isTaxMemorandum"] === "" ||
          commonFields["isTaxMemorandum"] === null ||
          commonFields["isTaxMemorandum"] === undefined
            ? message
            : "";
        formErrorsChapter4.taxAdvisorName =
          commonFields["isTaxMemorandum"] === "Yes" &&
          (commonFields["taxAdvisorName"] === "" ||
            commonFields["taxAdvisorName"] === null ||
            commonFields["taxAdvisorName"] === undefined)
            ? message
            : "";
        formErrorsChapter4.taxMemorandum =
          commonFields["isTaxMemorandum"] === "Yes" &&
          (commonFields["taxMemorandum"] === null ||
            commonFields["taxMemorandum"].length === 0)
            ? messageFile
            : "";
        if (commonFields["isTaxMemorandum"] === "No") {
          formErrorsChapter4.taxAdvisorName = "";
          formErrorsChapter4.taxMemorandum = "";
        }
        formErrorsChapter4.taxMemorandumAdvisorName =
          commonFields["taxMemorandumAdvisorName"] === "" ||
          commonFields["taxMemorandumAdvisorName"] === null ||
          commonFields["taxMemorandumAdvisorName"] === undefined
            ? message
            : "";
        formErrorsChapter4.isInvstmntMemorandum =
          commonFields["isInvstmntMemorandum"] === "" ||
          commonFields["isInvstmntMemorandum"] === null ||
          commonFields["isInvstmntMemorandum"] === undefined
            ? message
            : "";
        formErrorsChapter4.invstmntMemorandum =
          commonFields["isInvstmntMemorandum"] === "Yes" &&
          (commonFields["invstmntMemorandum"] === null ||
            commonFields["invstmntMemorandum"].length === 0)
            ? messageFile
            : "";
        if (commonFields["isInvstmntMemorandum"] === "No") {
          formErrorsChapter4.invstmntMemorandum = "";
        }
        // formErrorsChapter4.isAPA =
        //   data["documentsObjComp"]["isAPA"] === "" ||
        //   data["documentsObjComp"]["isAPA"] === null ||
        //   data["documentsObjComp"]["isAPA"] === undefined
        //     ? message
        //     : "";
        // formErrorsChapter4.objCompTaxResult =
        //   data["documentsObjComp"]["objCompTaxResult"] === "" ||
        //   data["documentsObjComp"]["objCompTaxResult"] === null ||
        //   data["documentsObjComp"]["objCompTaxResult"] === undefined
        //     ? message
        //     : "";
        // formErrorsChapter4.explanation =
        //   data["documentsObjComp"]["explanation"] === "" ||
        //   data["documentsObjComp"]["explanation"] === null ||
        //   data["documentsObjComp"]["explanation"] === undefined
        //     ? message
        //     : "";
        // formErrorsChapter4.otherDocs =
        //   data["documentsObjComp"]["otherDocs"] === null ||
        //   data["documentsObjComp"]["otherDocs"].length === 0
        //     ? messageFile
        //     : "";
        // formErrorsChapter4.isOCSFI =
        //   data["documentsObjComp"]["isOCSFI"] === "" ||
        //   data["documentsObjComp"]["isOCSFI"] === null ||
        //   data["documentsObjComp"]["isOCSFI"] === undefined
        //     ? message
        //     : "";
        // formErrorsChapter4.registrationNum =
        //   data["documentsObjComp"]["isOCSFI"] === "Yes" &&
        //   (data["documentsObjComp"]["registrationNum"] === "" ||
        //     data["documentsObjComp"]["registrationNum"] === null ||
        //     data["documentsObjComp"]["registrationNum"] === undefined)
        //     ? message
        //     : "";
        if (data["documentsObjComp"]["isOCSFI"] === "No") {
          formErrorsChapter4.registrationNum = "";
        }
       
        //if(commonFields["isUPCExists"] === "Yes" && commonFields["ubo"]["ubox"][0]["uboXName"]!==null){
          formErrorsChapter4.isUBOInvstObjComp =
          commonFields["srcFundsObjComp"]["isUBOInvstObjComp"] === "" ||
          commonFields["srcFundsObjComp"]["isUBOInvstObjComp"] === null ||
          commonFields["srcFundsObjComp"]["isUBOInvstObjComp"] === undefined
            ? message
            : "";
        // }
        // else{
        //   formErrorsChapter4.isUBOInvstObjComp ="";
        // }
        formErrorsChapter4.uboInvstCurrencyObjComp =
          commonFields["srcFundsObjComp"]["isUBOInvstObjComp"] === "Yes" &&
          (commonFields["srcFundsObjComp"]["uboInvstCurrencyObjComp"] === "" ||
            commonFields["srcFundsObjComp"]["uboInvstCurrencyObjComp"] ===
              null ||
            commonFields["srcFundsObjComp"]["uboInvstCurrencyObjComp"] ===
              undefined)
            ? message
            : "";
        formErrorsChapter4.uboInvstObjCompAmnt =
          commonFields["srcFundsObjComp"]["isUBOInvstObjComp"] === "Yes" &&
          (commonFields["srcFundsObjComp"]["uboInvstObjCompAmnt"] === "" ||
            commonFields["srcFundsObjComp"]["uboInvstObjCompAmnt"] === null ||
            commonFields["srcFundsObjComp"]["uboInvstObjCompAmnt"] ===
              undefined)
            ? message
            : "";
        formErrorsChapter4.uboInvstObjCompOrigin =
          commonFields["srcFundsObjComp"]["isUBOInvstObjComp"] === "Yes" &&
          (commonFields["srcFundsObjComp"]["uboInvstObjCompOrigin"] === "" ||
            commonFields["srcFundsObjComp"]["uboInvstObjCompOrigin"] === null ||
            commonFields["srcFundsObjComp"]["uboInvstObjCompOrigin"] ===
              undefined)
            ? message
            : "";
        if (commonFields["srcFundsObjComp"]["isUBOInvstObjComp"] === "No") {
          formErrorsChapter4.uboInvstCurrencyObjComp = "";
          formErrorsChapter4.uboInvstObjCompAmnt = "";
          formErrorsChapter4.uboInvstObjCompOrigin = "";
        }
        //if(commonFields["isUPCExists"] === "Yes" && commonFields["ubo"]["ubox"][0]["uboXName"]!==null){
        formErrorsChapter4.isUPCfundObjComp =
          commonFields["srcFundsObjComp"]["isUPCfundObjComp"] === "" ||
          commonFields["srcFundsObjComp"]["isUPCfundObjComp"] === null ||
          commonFields["srcFundsObjComp"]["isUPCfundObjComp"] === undefined
            ? message
            : "";
        // }
        // else{
        //   formErrorsChapter4.isUPCfundObjComp ="";  
        // }
        formErrorsChapter4.upcFundObjCompOrigin =
          commonFields["srcFundsObjComp"]["isUPCfundObjComp"] === "Yes" &&
          (commonFields["srcFundsObjComp"]["upcFundObjCompOrigin"] === "" ||
            commonFields["srcFundsObjComp"]["upcFundObjCompOrigin"] === null ||
            commonFields["srcFundsObjComp"]["upcFundObjCompOrigin"] ===
              undefined)
            ? message
            : "";
        formErrorsChapter4.annualFinStmnt =
          commonFields["srcFundsObjComp"]["isUPCfundObjComp"] === "Yes" &&
          (commonFields["srcFundsObjComp"]["annualFinStmnt"] === null ||
            commonFields["srcFundsObjComp"]["annualFinStmnt"].length === 0)
            ? messageFile
            : "";
        if (commonFields["srcFundsObjComp"]["isUPCfundObjComp"] === "No") {
          formErrorsChapter4.upcFundObjCompOrigin = "";
          formErrorsChapter4.annualFinStmnt = "";
        }
        formErrorsChapter4.isTpInvstObjComp =
          commonFields["srcFundsObjComp"]["isTpInvstObjComp"] === "" ||
          commonFields["srcFundsObjComp"]["isTpInvstObjComp"] === null ||
          commonFields["srcFundsObjComp"]["isTpInvstObjComp"] === undefined
            ? message
            : "";
        formErrorsChapter4.tpName =
          commonFields["srcFundsObjComp"]["isTpInvstObjComp"] === "Yes" &&
          (commonFields["srcFundsObjComp"]["tpInvstObjComp"]["tpName"] === "" ||
            commonFields["srcFundsObjComp"]["tpInvstObjComp"]["tpName"] ==
              null ||
            commonFields["srcFundsObjComp"]["tpInvstObjComp"]["tpName"] ===
              undefined)
            ? message
            : "";
        formErrorsChapter4.tpCountry =
          commonFields["srcFundsObjComp"]["isTpInvstObjComp"] === "Yes" &&
          (commonFields["srcFundsObjComp"]["tpInvstObjComp"]["tpCountry"] ===
            "" ||
            commonFields["srcFundsObjComp"]["tpInvstObjComp"]["tpCountry"] ===
              null ||
            commonFields["srcFundsObjComp"]["tpInvstObjComp"]["tpCountry"] ===
              undefined)
            ? message
            : "";
        formErrorsChapter4.tpAnnualFinStmnts =
          commonFields["srcFundsObjComp"]["isTpInvstObjComp"] === "Yes" &&
          (commonFields["srcFundsObjComp"]["tpInvstObjComp"][
            "tpAnnualFinStmnts"
          ] === null ||
            commonFields["srcFundsObjComp"]["tpInvstObjComp"][
              "tpAnnualFinStmnts"
            ].length === 0)
            ? messageFile
            : "";
        if (commonFields["srcFundsObjComp"]["isTpInvstObjComp"] === "No") {
          formErrorsChapter4.tpName = "";
          formErrorsChapter4.tpCountry = "";
          formErrorsChapter4.tpAnnualFinStmnts = "";
        }
        if(allData["chapter1"]["isCompIncorporated"] ==="Yes"){
        formErrorsChapter4.cntrysInvlvdTrnsctnsObjComp =
          commonFields["srcFundsObjComp"]["cntrysInvlvdTrnsctnsObjComp"] ===
            null ||
          commonFields["srcFundsObjComp"]["cntrysInvlvdTrnsctnsObjComp"]
            .length === 0
            ? message
            : "";
        }
        if(allData["chapter1"]["isCompIncorporated"] ==="No"){
          formErrorsChapter4.cntrysInvlvdTrnsctnsObjComp ="";
        }
        if(allData["chapter1"]["isCompIncorporated"] ==="Yes"){
        formErrorsChapter4.estmtdAnnualTrnOvrObjComp =
          commonFields["srcFundsObjComp"]["estmtdAnnualTrnOvrObjComp"] === "" ||
          commonFields["srcFundsObjComp"]["estmtdAnnualTrnOvrObjComp"] ===
            null ||
          commonFields["srcFundsObjComp"]["estmtdAnnualTrnOvrObjComp"] ===
            undefined
            ? message
            : "";
        }
        if(allData["chapter1"]["isCompIncorporated"] ==="No"){
          formErrorsChapter4.estmtdAnnualTrnOvrObjComp ="";
        }
            if(allData["chapter1"]["isCompIncorporated"] ==="Yes"){
        formErrorsChapter4.assetSummryObjComp =
          commonFields["srcFundsObjComp"]["assetSummryObjComp"] === "" ||
          commonFields["srcFundsObjComp"]["assetSummryObjComp"] === null ||
          commonFields["srcFundsObjComp"]["assetSummryObjComp"] === undefined
            ? message
            : "";
            }
            if(allData["chapter1"]["isCompIncorporated"] ==="No"){
              formErrorsChapter4.assetSummryObjComp ="";
            }
            if(allData["chapter1"]["isCompIncorporated"] ==="Yes"){
        formErrorsChapter4.assetSummryEvidence =
          commonFields["srcFundsObjComp"]["assetSummryEvidence"] === null ||
          commonFields["srcFundsObjComp"]["assetSummryEvidence"].length === 0
            ? messageFile
            : "";
            }
            if(allData["chapter1"]["isCompIncorporated"] ==="No"){
              formErrorsChapter4.assetSummryEvidence ="";
            }
            if(allData["chapter1"]["isCompIncorporated"] ==="Yes"){
        formErrorsChapter4.expctdTrnsctnsSummary =
          commonFields["srcFundsObjComp"]["expctdTrnsctnsSummary"] === "" ||
          commonFields["srcFundsObjComp"]["expctdTrnsctnsSummary"] === null ||
          commonFields["srcFundsObjComp"]["expctdTrnsctnsSummary"] === undefined
            ? message
            : "";
            }
            if(allData["chapter1"]["isCompIncorporated"] ==="No"){
              formErrorsChapter4.expctdTrnsctnsSummary ="";
            }
            if(allData["chapter1"]["isCompIncorporated"] ==="Yes"){
        formErrorsChapter4.expctdTrnsctnsEvidence =
          commonFields["srcFundsObjComp"]["expctdTrnsctnsEvidence"] === null ||
          commonFields["srcFundsObjComp"]["expctdTrnsctnsEvidence"].length === 0
            ? messageFile
            : "";
            }
            if(allData["chapter1"]["isCompIncorporated"] ==="No"){
              formErrorsChapter4.expctdTrnsctnsEvidence ="";
            }
       if(allData["chapter1"]["isCompIncorporated"] ==="Yes"){
        formErrorsChapter4.rightsAndObligations =
          commonFields["srcFundsObjComp"]["rightsAndObligations"] === null ||
          commonFields["srcFundsObjComp"]["rightsAndObligations"].length === 0
            ? message
            : "";
           }
       if(allData["chapter1"]["isCompIncorporated"] ==="No"){
            formErrorsChapter4.rightsAndObligations ="";
           }
           if(allData["chapter1"]["isCompIncorporated"] ==="Yes"){ 
        formErrorsChapter4.bnkInfo =
          commonFields["bnkInfo"] === null ||
          commonFields["bnkInfo"].length === 0
            ? message
            : "";
           }
           if(allData["chapter1"]["isCompIncorporated"] ==="No"){
            formErrorsChapter4.bnkInfo ="";
           }
        if (commonFields["srcFundsObjComp"]["isUBOInvstObjComp"] === "Yes") {
          formErrorsChapter4.objCompToUBO = {
            typeTrnsct: "",
            sizeTrnsct: "",
            freqTrnsct: "",
          };
          formErrorsChapter4.uboToObjComp = {
            typeTrnsct: "",
            sizeTrnsct: "",
            freqTrnsct: "",
          };
          formErrorsChapter4.objCompToUBO.freqTrnsct =
            commonFields["srcFundsObjComp"]["objCompToUBO"]["freqTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["objCompToUBO"]["freqTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["objCompToUBO"]["freqTrnsct"] ===
              undefined
              ? message
              : "";
          formErrorsChapter4.objCompToUBO.sizeTrnsct =
            commonFields["srcFundsObjComp"]["objCompToUBO"]["sizeTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["objCompToUBO"]["sizeTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["objCompToUBO"]["sizeTrnsct"] ===
              undefined
              ? message
              : "";
          formErrorsChapter4.objCompToUBO.typeTrnsct =
            commonFields["srcFundsObjComp"]["objCompToUBO"]["typeTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["objCompToUBO"]["typeTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["objCompToUBO"]["typeTrnsct"] ===
              undefined
              ? message
              : "";
          formErrorsChapter4.uboToObjComp.freqTrnsct =
            commonFields["srcFundsObjComp"]["uboToObjComp"]["freqTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["uboToObjComp"]["freqTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["uboToObjComp"]["freqTrnsct"] ===
              undefined
              ? message
              : "";
          formErrorsChapter4.uboToObjComp.sizeTrnsct =
            commonFields["srcFundsObjComp"]["uboToObjComp"]["sizeTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["uboToObjComp"]["sizeTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["uboToObjComp"]["sizeTrnsct"] ===
              undefined
              ? message
              : "";
          formErrorsChapter4.uboToObjComp.typeTrnsct =
            commonFields["srcFundsObjComp"]["uboToObjComp"]["typeTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["uboToObjComp"]["typeTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["uboToObjComp"]["typeTrnsct"] ===
              undefined
              ? message
              : "";
        }
        if (commonFields["srcFundsObjComp"]["isUBOInvstObjComp"] === "No") {
          formErrorsChapter4.objCompToUBO = {
            typeTrnsct: "",
            sizeTrnsct: "",
            freqTrnsct: "",
          };
          formErrorsChapter4.uboToObjComp = {
            typeTrnsct: "",
            sizeTrnsct: "",
            freqTrnsct: "",
          };
        }
        if (commonFields["srcFundsObjComp"]["isUPCfundObjComp"] === "Yes") {
          formErrorsChapter4.objCompToGrpComp = {
            typeTrnsct: "",
            sizeTrnsct: "",
            freqTrnsct: "",
          };
          formErrorsChapter4.grpCompToObjComp = {
            typeTrnsct: "",
            sizeTrnsct: "",
            freqTrnsct: "",
          };
          formErrorsChapter4.objCompToGrpComp.freqTrnsct =
            commonFields["srcFundsObjComp"]["objCompToGrpComp"][
              "freqTrnsct"
            ] === "" ||
            commonFields["srcFundsObjComp"]["objCompToGrpComp"][
              "freqTrnsct"
            ] === "" ||
            commonFields["srcFundsObjComp"]["objCompToGrpComp"][
              "freqTrnsct"
            ] === undefined
              ? message
              : "";
          formErrorsChapter4.objCompToGrpComp.sizeTrnsct =
            commonFields["srcFundsObjComp"]["objCompToGrpComp"][
              "sizeTrnsct"
            ] === "" ||
            commonFields["srcFundsObjComp"]["objCompToGrpComp"][
              "sizeTrnsct"
            ] === "" ||
            commonFields["srcFundsObjComp"]["objCompToGrpComp"][
              "sizeTrnsct"
            ] === undefined
              ? message
              : "";
          formErrorsChapter4.objCompToGrpComp.typeTrnsct =
            commonFields["srcFundsObjComp"]["objCompToGrpComp"][
              "typeTrnsct"
            ] === "" ||
            commonFields["srcFundsObjComp"]["objCompToGrpComp"][
              "typeTrnsct"
            ] === "" ||
            commonFields["srcFundsObjComp"]["objCompToGrpComp"][
              "typeTrnsct"
            ] === undefined
              ? message
              : "";
          formErrorsChapter4.grpCompToObjComp.freqTrnsct =
            commonFields["srcFundsObjComp"]["grpCompToObjComp"][
              "freqTrnsct"
            ] === "" ||
            commonFields["srcFundsObjComp"]["grpCompToObjComp"][
              "freqTrnsct"
            ] === "" ||
            commonFields["srcFundsObjComp"]["grpCompToObjComp"][
              "freqTrnsct"
            ] === undefined
              ? message
              : "";
          formErrorsChapter4.grpCompToObjComp.sizeTrnsct =
            commonFields["srcFundsObjComp"]["grpCompToObjComp"][
              "sizeTrnsct"
            ] === "" ||
            commonFields["srcFundsObjComp"]["grpCompToObjComp"][
              "sizeTrnsct"
            ] === "" ||
            commonFields["srcFundsObjComp"]["grpCompToObjComp"][
              "sizeTrnsct"
            ] === undefined
              ? message
              : "";
          formErrorsChapter4.grpCompToObjComp.typeTrnsct =
            commonFields["srcFundsObjComp"]["grpCompToObjComp"][
              "typeTrnsct"
            ] === "" ||
            commonFields["srcFundsObjComp"]["grpCompToObjComp"][
              "typeTrnsct"
            ] === "" ||
            commonFields["srcFundsObjComp"]["grpCompToObjComp"][
              "typeTrnsct"
            ] === undefined
              ? message
              : "";
        }
        if (commonFields["srcFundsObjComp"]["isUPCfundObjComp"] === "No") {
          formErrorsChapter4.objCompToGrpComp = {
            typeTrnsct: "",
            sizeTrnsct: "",
            freqTrnsct: "",
          };
          formErrorsChapter4.grpCompToObjComp = {
            typeTrnsct: "",
            sizeTrnsct: "",
            freqTrnsct: "",
          };
        }
        if (commonFields["srcFundsObjComp"]["isTpInvstObjComp"] === "Yes") {
          formErrorsChapter4.objCompToTP = {
            typeTrnsct: "",
            sizeTrnsct: "",
            freqTrnsct: "",
          };
          formErrorsChapter4.tpToObjComp = {
            typeTrnsct: "",
            sizeTrnsct: "",
            freqTrnsct: "",
          };
          formErrorsChapter4.objCompToTP.freqTrnsct =
            commonFields["srcFundsObjComp"]["objCompToTP"]["freqTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["objCompToTP"]["freqTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["objCompToTP"]["freqTrnsct"] ===
              undefined
              ? message
              : "";
          formErrorsChapter4.objCompToTP.sizeTrnsct =
            commonFields["srcFundsObjComp"]["objCompToTP"]["sizeTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["objCompToTP"]["sizeTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["objCompToTP"]["sizeTrnsct"] ===
              undefined
              ? message
              : "";
          formErrorsChapter4.objCompToTP.typeTrnsct =
            commonFields["srcFundsObjComp"]["objCompToTP"]["typeTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["objCompToTP"]["typeTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["objCompToTP"]["typeTrnsct"] ===
              undefined
              ? message
              : "";
          formErrorsChapter4.tpToObjComp.freqTrnsct =
            commonFields["srcFundsObjComp"]["tpToObjComp"]["freqTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["tpToObjComp"]["freqTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["tpToObjComp"]["freqTrnsct"] ===
              undefined
              ? message
              : "";
          formErrorsChapter4.tpToObjComp.sizeTrnsct =
            commonFields["srcFundsObjComp"]["tpToObjComp"]["sizeTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["tpToObjComp"]["sizeTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["tpToObjComp"]["sizeTrnsct"] ===
              undefined
              ? message
              : "";
          formErrorsChapter4.tpToObjComp.typeTrnsct =
            commonFields["srcFundsObjComp"]["tpToObjComp"]["typeTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["tpToObjComp"]["typeTrnsct"] ===
              "" ||
            commonFields["srcFundsObjComp"]["tpToObjComp"]["typeTrnsct"] ===
              undefined
              ? message
              : "";
        }
        if (commonFields["srcFundsObjComp"]["isTpInvstObjComp"] === "No") {
          formErrorsChapter4.objCompToTP = {
            typeTrnsct: "",
            sizeTrnsct: "",
            freqTrnsct: "",
          };
          formErrorsChapter4.tpToObjComp = {
            typeTrnsct: "",
            sizeTrnsct: "",
            freqTrnsct: "",
          };
        }
        // formErrorsChapter4.isFatcaSCF =
        //   data["ovrvwBnkAC"]["isFatcaSCF"] === "" ||
        //   data["ovrvwBnkAC"]["isFatcaSCF"] === null ||
        //   data["ovrvwBnkAC"]["isFatcaSCF"] === undefined
        //     ? message
        //     : "";
        // formErrorsChapter4.fatcaClassification =
        //   data["ovrvwBnkAC"]["fatcaClassification"] === "" ||
        //   data["ovrvwBnkAC"]["fatcaClassification"] === null ||
        //   data["ovrvwBnkAC"]["fatcaClassification"] === undefined
        //     ? message
        //     : "";

        // formErrorsChapter4.fatcaSelfCertifctnFrm =
        //   data["ovrvwBnkAC"]["isFatcaSCF"] === "Yes" &&
        //   (data["ovrvwBnkAC"]["fatcaSelfCertifctnFrm"] === null ||
        //     data["ovrvwBnkAC"]["fatcaSelfCertifctnFrm"].length === 0)
        //     ? messageFile
        //     : "";
        // formErrorsChapter4.noFATCAformReason =
        //   data["ovrvwBnkAC"]["isFatcaSCF"] === "No" &&
        //   (data["ovrvwBnkAC"]["noFATCAformReason"] === "" ||
        //     data["ovrvwBnkAC"]["noFATCAformReason"] === null ||
        //     data["ovrvwBnkAC"]["noFATCAformReason"] === undefined)
        //     ? message
        //     : "";
        // formErrorsChapter4.bnkStmnts =
        //   data["ovrvwBnkAC"]["bnkStmnts"] === null ||
        //   data["ovrvwBnkAC"]["bnkStmnts"].length === 0
        //     ? messageFile
        //     : "";
        // formErrorsChapter4.agremntsGrUpEntities =
        //   data["ovrvwBnkAC"]["agremntsGrUpEntities"] === null ||
        //   data["ovrvwBnkAC"]["agremntsGrUpEntities"].length === 0
        //     ? messageFile
        //     : "";
        // formErrorsChapter4.agremntsOthrParties =
        //   data["ovrvwBnkAC"]["agremntsOthrParties"] === null ||
        //   data["ovrvwBnkAC"]["agremntsOthrParties"].length === 0
        //     ? messageFile
        //     : "";
        // formErrorsChapter4.financialStmntsObjComp =
        //   data["ovrvwBnkAC"]["financialStmntsObjComp"] === null ||
        //   data["ovrvwBnkAC"]["financialStmntsObjComp"].length === 0
        //     ? messageFile
        //     : "";
        // formErrorsChapter4.finanicalStmntsAllPrticipatns =
        //   data["ovrvwBnkAC"]["finanicalStmntsAllPrticipatns"] === null ||
        //   data["ovrvwBnkAC"]["finanicalStmntsAllPrticipatns"].length === 0
        //     ? messageFile
        //     : "";
        // formErrorsChapter4.financialStmntsParntComp =
        //   data["ovrvwBnkAC"]["financialStmntsParntComp"] === null ||
        //   data["ovrvwBnkAC"]["financialStmntsParntComp"].length === 0
        //     ? messageFile
        //     : "";
        // formErrorsChapter4.declartnsFinanicalInstitutns =
        //   data["ovrvwBnkAC"]["declartnsFinanicalInstitutns"] === null ||
        //   data["ovrvwBnkAC"]["declartnsFinanicalInstitutns"].length === 0
        //     ? messageFile
        //     : "";

        formErrorsChapter4.objCompNaturePropsdObjctv =
          commonFields["objCompNaturePropsdObjctv"] === "" ||
          commonFields["objCompNaturePropsdObjctv"] === null ||
          commonFields["objCompNaturePropsdObjctv"] === undefined
            ? message
            : "";
        formErrorsChapter4.objCompOtherNaturePropsdObjctv =
          commonFields["objCompNaturePropsdObjctv"] === "Other" &&
          (commonFields["objCompOtherNaturePropsdObjctv"] === "" ||
            commonFields["objCompOtherNaturePropsdObjctv"] === null ||
            commonFields["objCompOtherNaturePropsdObjctv"] === undefined)
            ? message
            : "";
        formErrorsChapter4.rationaleStructurigForNL =
          commonFields["rationaleStructurigForNL"] === null ||
          commonFields["rationaleStructurigForNL"].length === 0
            ? message
            : "";

        this.setState({
          formErrors4: formErrorsChapter4,
        });

        return { formErrorsChapter4 };

      case "chapter5":
        let formErrorsChapter5 = this.state.formErrors5;
        // formErrorsChapter5.countryList =
        //   data["countryList"] === null || data["countryList"].length === 0
        //     ? message
        //     : "";
        this.setState({
          formErrors5: formErrorsChapter5,
        });
        return { formErrorsChapter5 };

      case "chapter6":
        let formErrorsChapter6 = this.state.formErrors6;
        // formErrorsChapter6.isExternalDirectors =
        //   data["isExternalDirectors"] === "" ||
        //   data["isExternalDirectors"] === null ||
        //   data["isExternalDirectors"] === undefined
        //     ? message
        //     : "";
        // formErrorsChapter6.isExternalDirectorsNaturalPerson =
        //   data["isExternalDirectors"] === "Yes" &&
        //   (data["isExternalDirectorsNaturalPerson"] === "" ||
        //     data["isExternalDirectorsNaturalPerson"] === null ||
        //     data["isExternalDirectorsNaturalPerson"] === undefined)
        //     ? message
        //     : "";
        // formErrorsChapter6.nameOfNaturalPerson =
        //   data["isExternalDirectorsNaturalPerson"] === "Yes" &&
        //   (data["nameOfNaturalPerson"] === "" ||
        //     data["nameOfNaturalPerson"] === null ||
        //     data["nameOfNaturalPerson"] === undefined)
        //     ? message
        //     : "";

        // formErrorsChapter6.nationality =
        //   data["isExternalDirectorsNaturalPerson"] === "Yes" &&
        //   (data["nationality"] === "" ||
        //     data["nationality"] === null ||
        //     data["nationality"] === undefined)
        //     ? message
        //     : "";

        // formErrorsChapter6.isOneOrMoreExtDirectorsAuthorized =
        //   data["isOneOrMoreExtDirectorsAuthorized"] === "" ||
        //   data["isOneOrMoreExtDirectorsAuthorized"] === null ||
        //   data["isOneOrMoreExtDirectorsAuthorized"] === undefined
        //     ? message
        //     : "";

        this.setState({
          formErrors6: formErrorsChapter6,
        });
        return { formErrorsChapter6 };

      default:
        return "";
    }
  };
}
const Validation = new FormValidation();
export default Validation;
