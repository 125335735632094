import {
  chapter1,
  chapter2,
  chapter3,
  chapter4,
  chapter5,
  chapter6,
} from "../constants";
export default function getSequentialFields(response, chapter) {
  let errorChapter;
  switch (chapter) {
    case "chapter1":
      errorChapter = chapter1;
      break;
    case "chapter2":
      errorChapter = chapter2;
      break;
    case "chapter3":
      errorChapter = chapter3;
      break;
    case "chapter4":
      errorChapter = chapter4;
      break;
    case "chapter5":
      errorChapter = chapter5;
      break;
    case "chapter6":
      errorChapter = chapter6;
      break;
    default:
      return null;
  }
  Object.keys(errorChapter).forEach((key) => {
    Object.entries(response).forEach((val) => {
      const reskey = val[0];
      const resVal = val[1];
      if (key === reskey) {
        errorChapter[key] = resVal;
      }
    });
  });
  return { errorChapter };
}
