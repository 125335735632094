import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import TextBox from "../../../../elements/TextBox";

class ServiceProvided extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { servicesProvided } = this.props;

    return (
      <>
        <div className="html2pdf__page-break"></div>
        <h4 className="sub-chapter-title mb-4">Services to be Provided</h4>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Domiciliation services"
              name="domiciliationSrvsApprvl"
              id="domiciliationSrvsApprvl"
              options={["Yes", "No"]}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {servicesProvided.domiciliationSrvsApprvl === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="More Information"
                name="domSrvcsExplaination"
                id="domSrvcsExplaination"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Accounting services"
              name="accntngSrvsApprvl"
              id="accntngSrvsApprvl"
              options={["Yes", "No"]}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {servicesProvided.accntngSrvsApprvl === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Third party details if not UIM"
                name="thirdPartyDetailsNoUIM"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Tax compliance services"
              name="taxCmplanceSrvcsApprvl"
              id="taxCmplanceSrvcsApprvl"
              options={["Yes", "No"]}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {servicesProvided.taxCmplanceSrvcsApprvl === "Yes" && (
            <div className="mb-3 col-lg-4">
              <CheckBox
                label="CoSec services"
                name="coSecServicesApprvl"
                id="coSecServicesApprvl"
                options={["Yes", "No"]}
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                disabled={true}
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Bank account required"
              name="bnkAccReqApprvl"
              id="bnkAccReqApprvl"
              options={["Yes", "No"]}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {servicesProvided.bnkAccReqApprvl === "No" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Bank details"
                name="bankDetails"
                id="bankDetails"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label="Other Services"
              name="othrSrvcsApprvl"
              id="othrSrvcsApprvl"
              options={["Yes", "No"]}
              reducer={this.props.externalReducer}
              chapter={this.props.chapter}
              objBaseName={this.props.objBaseName}
              disabled={true}
            />
          </div>
          {servicesProvided.othrSrvcsApprvl === "Yes" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Please specify"
                name="othrSrvcsExplain"
                id="othrSrvcsExplain"
                placeholder="enter your text"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                objBaseName={this.props.objBaseName}
                validationType="Text"
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { chapter, externalReducer } = ownProps;
  return {
    servicesProvided: state[externalReducer][chapter]["servicesProvided"],
  };
};

export default connect(mapStateToProps, null)(ServiceProvided);
