export const getRiskList = (reducer) => {
  return [
    {
      label:
        "General - Structural overview of the relevant parts of the client structure",
      reducer: reducer,
      chapter: "chapter1",
      name: "descActnNeedsTkn",
    },
    {
      label: "Client Investigation - Client Relationship",
      reducer: reducer,
      chapter: "chapter2",
      name: "descActnNeedsTkn",
    },
    {
      label: "Client Investigation - UBO/ Ultimate Parent Company (UPC)",
      reducer: reducer,
      chapter: "chapter3",
      name: "descActnNeedsTkn",
    },
    {
      label: "Client Investigation - Object Company",
      reducer: reducer,
      chapter: "chapter4",
      name: "descActnNeedsTkn",
    },
    {
      label: "Client Acceptance - Sanctions external",
      reducer: reducer,
      chapter: "chapter5",
      name: "descActnNeedsTkn",
    },
    {
      label: "Agreements - Purpose of the (trust)services",
      reducer: reducer,
      chapter: "chapter6",
      name: "descActnNeedsTkn",
    },
  ];
};
