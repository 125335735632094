import React, { Component } from "react";
import { Row } from "react-bootstrap";
import FileUpload from "../../../../../elements/FileUpload";
import CheckBox from "../../../../../elements/CheckBox";
import { fileAcceptType } from "./Constants";
import { connect } from "react-redux";

class NomineeAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { isNomineeAgrmntUBO } = this.props;
    return (
      <Row className="mt-3 mb-3">
        <div className="mb-2 col-lg-4">
          <CheckBox
            label="Is there a nominee agreement?"
            options={["Yes", "No"]}
            info="A declaration of trust or other agreements (e.g. profit participating loans) affiliated with the UBO"
            id="isNomineeAgrmntUBO"
            name="isNomineeAgrmntUBO"
            chapter={this.props.chapter}
            reducer={this.props.reducer}
          />
          {this.props.errorMsg !== null &&
            Object.entries(this.props.errorMsg).length !== 0 &&
            this.props.errorMsg?.partA?.isNomineeAgrmntUBO?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {this.props.errorMsg?.partA?.isNomineeAgrmntUBO}
              </span>
            )}
        </div>

        {isNomineeAgrmntUBO === "Yes" && (
          <div className="mb-2 col-lg-4">
            <FileUpload
              label="Please include the nominee agreement, the declaration of trust or other agreements"
              acceptType={fileAcceptType}
              id="nomineeAgrmntUBO"
              name="nomineeAgrmntUBO"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
            />
            {this.props.errorMsg !== null &&
              Object.entries(this.props.errorMsg).length !== 0 &&
              this.props.errorMsg?.partA?.nomineeAgrmntUBO?.length > 0 && (
                <span style={{ color: "red", fontSize: "14px" }}>
                  {this.props.errorMsg?.partA?.nomineeAgrmntUBO}
                </span>
              )}
          </div>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isNomineeAgrmntUBO: state.UBODeclarationUIM.partA["isNomineeAgrmntUBO"],
  };
};

export default connect(mapStateToProps, null)(NomineeAgreement);
