import React from "react";
import { Row } from "react-bootstrap";
import "../../Onboarding/internalCAF.scss";
import { connect } from "react-redux";
import NotificationManager from "react-notifications/lib/NotificationManager";
import AutoSaving from "../AutoSaving";
import FormalQuestions from "../../Onboarding/Chapter12/FormalQuestions";
import DateTimePicker from "../../../../elements/DateTimePicker";
import getSequentialFields from "../getSequentialFields";
import { getFirstUnfilledField } from "../getFirstUnfilledField";
import { HashLink } from "react-router-hash-link";
import {
  getMaltaInternalCAFDetails,
  setMaltaInternalCAFDetails,
} from "../../../../../redux/actions/Malta/MaltaInternalCAFActions";
import { setMaltaInternalChapterError } from "../../../../../redux/actions/Malta/MaltaInternalChapterErrorActions";
import { chapValidationErrorMalta } from "../Constants";

const chapter = "chapter12";
const reducer = "MaltaInternalCAF";

let timer;

class Chapter12 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formErrorsChapter12: {},
      isCAFValid: null,
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: "all",
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.getMaltaInternalCAFDetails(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationErrorMalta.forEach(async (element) => {
            if (element.chapter !== "chapter12") {
              let response =
                res?.data?.result?.cafInternalValidations[element.chapter];

              const newResponse = await Promise.resolve(
                getSequentialFields(response, element.chapter, true)
              );
              this.props.setMaltaInternalChapterError({
                form: `formErrors${element.chapter}`,
                errors: newResponse?.errorChapter,
              });
            }
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;

    this.props.setMaltaInternalCAFDetails(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.props.ClientAcceptance.chapter12["dirty"] = false;
        let response = res.data.result;

        const newResponse = await Promise.resolve(
          getSequentialFields(response, chapter, true)
        );

        this.props.setMaltaInternalChapterError({
          errors: newResponse?.errorChapter,
          form: "formErrorschapter12",
        });
        chapValidationErrorMalta.forEach((element) => {
          element.isvalid =
            this.props.chapterErrors[
              `formErrors${element.chapter}`
            ]?.errors?.isValid;
        });
        if (!res.data.result.isCAFValid) {
          this.setState({ isCAFValid: false });
          NotificationManager.error("Please fill all the details");
        } else {
          this.setState({ isCAFValid: true });

          NotificationManager.success("Form is submitted successfully.");

          return this.props.history.push({
            pathname: "/malta/signature",
          });
        }
      }
    });
  };

  render() {
    const { internalErrors } = this.props;

    const formErrorsChapter12 = JSON.stringify(internalErrors.errors);

    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="html2pdf__page-break"></div>
          <div className="cafHeader">
            <h2 className="m-0">Client Acceptance Form</h2>
          </div>
          <div className="internal_caf generic-form">
            <h3 className="chapterttl">Formal sign off</h3>
            <FormalQuestions
              reducer={reducer}
              chapter={chapter}
              errors={formErrorsChapter12}
              isRequired={true}
              form="Malta"
              PdfGeneration={this.props.PdfGeneration}
            />
            {/* <div className="html2pdf__page-break"></div> */}

            <Row>
              <div className="mb-2 col-lg-4" id="dateStrtOfSrvcs">
                <DateTimePicker
                  info="Note that the services may only start at the registration date in the CoC and AFTER BAC approval"
                  label="Date of start of services"
                  chapter={chapter}
                  name="dateStrtOfSrvcs"
                  id="dateStrtOfSrvcs"
                  reducer={reducer}
                  isRequired={true}
                />
              </div>
            </Row>

            {this.state.isCAFValid === false ? (
              <div>
                <div className="html2pdf__page-break"></div>
                <div className="internalErrorSection">
                  <h3>
                    All the fields from the chapters are mandatory, please fill
                    the unfilled fields.
                  </h3>
                  {chapValidationErrorMalta
                    .filter((element) => element.isvalid === false)
                    .map((element) => {
                      const res = getFirstUnfilledField(
                        this.props.chapterErrors[`formErrors${element.chapter}`]
                          .errors
                      );
                      return (
                        <HashLink to={`/malta/${element.path}#${res}`}>
                          <i className="fa fa-arrow-circle-o-right fa-fw"></i>
                          {element.label} has unfilled fields
                        </HashLink>
                      );
                    })}
                </div>
              </div>
            ) : null}
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ClientAcceptance: state.MaltaInternalCAF,
    data: state,
    internalErrors: state.MaltaInternalChapterErrors.formErrorschapter12,
    chapterErrors: state.MaltaInternalChapterErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMaltaInternalCAFDetails: async (data) =>
      dispatch(await getMaltaInternalCAFDetails(data)),
    setMaltaInternalCAFDetails: async (data) =>
      dispatch(await setMaltaInternalCAFDetails(data)),
    setMaltaInternalChapterError: (data) =>
      dispatch(setMaltaInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter12);
