import { PartA } from "../../app/JSONs/UBO-Declaration_UBO/PartA";
import { PartB } from "../../app/JSONs/UBO-Declaration_UBO/PartB";
import { PartC } from "../../app/JSONs/UBO-Declaration_UBO/PartC";
const initialState = {
  partA: PartA,
  partB: PartB,
  partC: PartC,
  date: null,
  name: null,
  place: null,
  dirty: false,
};

const UBODeclarationUIMReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "UPDATE_UIM_UBO_DATA":
      const {
        baseName,
        index,
        name,
        value,
        chapter,
        objBaseName,
        nestedObjBaseName,
        noChapter,
      } = action.data;
      let array, obj;
      if (noChapter) {
        return {
          ...state,
          dirty: true,
          [name]: value,
        };
      } else if (!baseName && !objBaseName) {
        return {
          ...state,
          dirty: true,
          [chapter]: {
            ...state[chapter],
            [name]: value,
          },
        };
      } else if (nestedObjBaseName && objBaseName) {
        return {
          ...state,
          dirty: true,
          [chapter]: {
            ...state[chapter],
            [objBaseName]: {
              ...state[chapter][objBaseName],
              [nestedObjBaseName]: {
                ...state[chapter][objBaseName][nestedObjBaseName],
                [name]: value,
              },
            },
          },
        };
      } else if (objBaseName && !baseName) {
        return {
          ...state,
          dirty: true,
          [chapter]: {
            ...state[chapter],
            [objBaseName]: {
              ...state[chapter][objBaseName],
              [name]: value,
            },
          },
        };
      } else if (objBaseName && baseName) {
        array = state[chapter][objBaseName][baseName];

        obj = {
          ...array[index],
          [name]: value,
        };
        array[index] = obj;
        return {
          ...state,
          dirty: true,
          [chapter]: {
            ...state[chapter],
            [objBaseName]: {
              ...state[chapter][objBaseName],
              [baseName]: array,
            },
          },
        };
      } else {
        array = state[chapter][baseName];
        obj = {
          ...array[index],
          [name]: value,
        };
        array[index] = obj;
        return {
          ...state,
          dirty: true,
          [chapter]: {
            ...state[chapter],
            [baseName]: array,
          },
        };
      }

    case "SET_NEW_UBO_DATA_FROM_API":
      if (action.data.data) {
        return action.data.data;
      } else {
        return state;
      }

    default:
      return state;
  }
};

export default UBODeclarationUIMReducer;
