import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import TextBox from "../../../../../elements/TextBox";
import { getTypeSizeFrqArray, TypeSizeFreqTransct } from "./Constants";
import { checkIsCommon } from "../CommonFunctions";

class TypeSizeFreq extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isUBOInvstObjComp, isUPCfundObjComp, isTpInvstObjComp, errorMsg } =
      this.props;
    let TypeSizeFrqArray = getTypeSizeFrqArray(this.props.form);
    const eMsg = JSON.parse(errorMsg);
    // const eMsg = {};
    return (
      <>
        <div className="html2pdf__page-break"></div>
        <h4 className="col-md-12 sub-chapter-title pl-0 mb-2">
          Type, size and frequecy of transactions
        </h4>
        {TypeSizeFrqArray.map((transaction, index) => {
          return (
            <>
              {transaction.dependency === "isUPCfundObjComp" &&
                isUPCfundObjComp === "Yes" && (
                  <div key={index} className="groupCompaniesBlock">
                    <h6 className="font-weight-bold" id={`${transaction.name}`}>
                      {transaction.label}
                    </h6>
                    <Row className="mt-2">
                      {TypeSizeFreqTransct.map((item) => {
                        return (
                          <div
                            className="col-lg-4"
                            key={transaction.dependency + item.name}
                          >
                             <TextBox
                              label={item.label}
                              name={item.name}
                              placeholder="enter your text"
                              isCommon={checkIsCommon(this.props.form) && true}
                              reducer={this.props.reducer}
                              chapter={this.props.chapter}
                              objBaseName={this.props.objBaseName}
                              nestedObjBaseName={transaction.name}
                              validationType="Text"
                              disabled={
                                (this.props.form === "NL" ||
                                  this.props.form === "Curacao" ||
                                  this.props.form === "Malta" ||
                                  this.props.form === "Luxembourg") &&
                                this.props.from !== "clientInformation"
                              }
                              isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
                            />
                            {eMsg &&
                              eMsg[transaction.name] &&
                              eMsg[transaction.name][item.name] &&
                              eMsg[transaction.name][item.name].length > 0 && (
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  {eMsg[transaction.name][item.name]}
                                </span>
                              )}
                          </div>
                        );
                      })}
                    </Row>
                  </div>
                )}
              {transaction.dependency === "isUBOInvstObjComp" &&
                isUBOInvstObjComp === "Yes" && (
                  <div
                    key={transaction.dependency}
                    className="groupCompaniesBlock"
                  >
                    <h6 className="font-weight-bold" id={`${transaction.name}`}>
                      {transaction.label}
                    </h6>
                    <Row className="mt-2">
                      {TypeSizeFreqTransct.map((item) => {
                        return (
                          <div
                            className="col-lg-4"
                            key={transaction.dependency + item.name}
                          >
                            <TextBox
                              label={item.label}
                              name={item.name}
                              placeholder="enter your text"
                              isCommon={checkIsCommon(this.props.form) && true}
                              reducer={this.props.reducer}
                              chapter={this.props.chapter}
                              objBaseName={this.props.objBaseName}
                              nestedObjBaseName={transaction.name}
                              validationType="Text"
                              disabled={
                                (this.props.form === "NL" ||
                                  this.props.form === "Curacao" ||
                                  this.props.form === "Malta" ||
                                  this.props.form === "Luxembourg") &&
                                this.props.from !== "clientInformation"
                              }
                              isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
                            />
                            {eMsg &&
                              eMsg[transaction.name] &&
                              eMsg[transaction.name][item.name] &&
                              eMsg[transaction.name][item.name].length > 0 && (
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  {eMsg[transaction.name][item.name]}
                                </span>
                              )}
                          </div>
                        );
                      })}
                    </Row>
                  </div>
                )}
              {transaction.dependency === "isTpInvstObjComp" &&
                isTpInvstObjComp === "Yes" && (
                  <div
                    key={transaction.dependency}
                    className="groupCompaniesBlock"
                  >
                    <h6 className="font-weight-bold" id={`${transaction.name}`}>
                      {transaction.label}
                    </h6>
                    <Row className="mt-2">
                      {TypeSizeFreqTransct.map((item) => {
                        return (
                          <div
                            className="col-lg-4"
                            key={transaction.dependency + item.name}
                          >
                            <TextBox
                              label={item.label}
                              name={item.name}
                              isCommon={checkIsCommon(this.props.form) && true}
                              placeholder="enter your text"
                              reducer={this.props.reducer}
                              chapter={this.props.chapter}
                              objBaseName={this.props.objBaseName}
                              nestedObjBaseName={transaction.name}
                              validationType="Text"
                              disabled={
                                (this.props.form === "NL" ||
                                  this.props.form === "Curacao" ||
                                  this.props.form === "Malta" ||
                                  this.props.form === "Luxembourg") &&
                                this.props.from !== "clientInformation"
                              }
                              isRequired={this.props.form === "NL" && this.props.from === "clientInformation" ? true : false} 
                            />
                            {eMsg &&
                              eMsg[transaction.name] &&
                              eMsg[transaction.name][item.name] &&
                              eMsg[transaction.name][item.name].length > 0 && (
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  {eMsg[transaction.name][item.name]}
                                </span>
                              )}
                          </div>
                        );
                      })}
                    </Row>
                  </div>
                )}
            </>
          );
        })}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { form, reducer, chapter } = ownProps;
  if (
    form === "NL" ||
    form === "Curacao" ||
    form === "Malta" ||
    form === "Luxembourg"
  ) {
    return {
      isUBOInvstObjComp:
        state.commonFields.srcFundsObjComp["isUBOInvstObjComp"],
      isUPCfundObjComp: state.commonFields.srcFundsObjComp["isUPCfundObjComp"],
      isTpInvstObjComp: state.commonFields.srcFundsObjComp["isTpInvstObjComp"],
    };
  } else {
    return {
      isUBOInvstObjComp:
        state[reducer][chapter]["srcFundsObjComp"]["isUBOInvstObjComp"],
      isUPCfundObjComp:
        state[reducer][chapter]["srcFundsObjComp"]["isUPCfundObjComp"],
      isTpInvstObjComp:
        state[reducer][chapter]["srcFundsObjComp"]["isTpInvstObjComp"],
    };
  }
};
export default connect(mapStateToProps, null)(TypeSizeFreq);
