import React, { Component } from "react";
import { Row } from "react-bootstrap";
import CheckBox from "../../../../../../elements/CheckBox";
import { connect } from "react-redux";
import UboClassification from "./UboClassification";
import UKUBOs from "./UKUBOs";
import UPC from "./UPC";
const chapter = "chapter3";

class Chapter3 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isHoldingInterest, isUBOClassified, isAssetClient,isUBOExists } = this.props;
     let eMsg = JSON.parse(this.props.errorMsg);
    return (
      <div className="generic-form client-investigation mt-4">
        <h5 className="mb-3 mt-2">
          Client Investigation - UBO/ Ultimate Parent Company
        </h5>

        <UboClassification
          chapter={chapter}
          errorMsg={this.props.errorMsg}
          reducer={this.props.reducer}
          form="UK"
        />
        {isHoldingInterest === "Yes" &&
          isUBOClassified === "Yes" &&
          isAssetClient === "Yes" && (
         
            <Row className="pt-3 pb-2">
            <div className="mb-3 col-lg-12">
              <CheckBox
                label="Does the ultimate beneficial owner (ubo) exist?"
                name="isUBOExists"
                id="isUBOExists"
                options={["Yes", "No"]}
                chapter={chapter}
                reducer={this.props.reducer}
                isRequired={this.props.form === "UK" ? true : false} 
              />
              {eMsg && eMsg?.isUBOExists && eMsg?.isUBOExists?.length > 0 && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {eMsg?.isUBOExists}
              </span>
            )}
            </div>
            {isUBOExists && isUBOExists === "Yes" && (       
            <div className="mb-3 col-lg-12">
            <UKUBOs
             chapter={chapter}
             reducer={this.props.reducer}
             errorMsg={this.props.errorMsg}
             form="UK"
            />
           </div>
               )}
           </Row>
           
          )}

        <UPC
          chapter={chapter}
          reducer={this.props.reducer}
          objBaseName="upc"
          errorMsg={this.props.errorMsg}
          form="UK"
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isHoldingInterest: state.UKClientInformation.chapter3["isHoldingInterest"],
    isUBOClassified: state.UKClientInformation.chapter3["isUBOClassified"],
    isAssetClient: state.UKClientInformation.chapter3["isAssetClient"],
    isUBOExists:state.UKClientInformation.chapter3["isUBOExists"]
  };
};

export default connect(mapStateToProps, null)(Chapter3);
