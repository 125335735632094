import React, { Component } from "react";
import SidebarSelections from "./SidebarSelections";

class ExternalSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  handleChange(e) {
    this.props.handleOfficeChange(e);
  }

  render() {
    return (
      <div className="sidebarClientacceptance">
        {this.state.isLoading && <div className="loader"></div>}
        <h6
          style={{
            fontWeight: "700",
            position: "relative",
            bottom: "35px",
          }}
          className="text-center"
        >
          Client Acceptance Form
        </h6>
        <SidebarSelections
          office={this.props.office}
          handleOfficeChange={(e) => this.handleChange(e)}
        />
      </div>
    );
  }
}

export default ExternalSidebar;
