import axios from "axios";
const BACKEND_API_URL = process.env.REACT_APP_USER_BACKEND_API_URL;

const GetUBOUIMDetailsRequest = () => {
  return {
    type: "GET_UBO_UIM_DETAILS_REQUEST",
  };
};

const GetUBOUIMDetailsReceiveData = (json) => {
  return {
    type: "GET_UBO_UIM_DETAILS_SUCCESS",
    data: json,
  };
};

export const getUBOUIMDetails = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(GetUBOUIMDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/UBOUIM/GetUBOUIMDetailByEmail`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: "GET_UBO_UIM_DETAILS_FAIL", data: json });
        } else {
          return dispatch(GetUBOUIMDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "GET_UBO_UIM_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};

const SetUBOUIMDetailsRequest = () => {
  return {
    type: "SET_UBO_UIM_DETAILS_REQUEST",
  };
};

const SetUBOUIMDetailsReceiveData = (json) => {
  return {
    type: "SET_UBO_UIM_DETAILS_SUCCESS",
    data: json,
  };
};

export const setUBOUIMDetails = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const headers = {
    Authorization: "Bearer " + user.authToken,
  };
  return (dispatch) => {
    dispatch(SetUBOUIMDetailsRequest());
    return axios
      .post(`${BACKEND_API_URL}/UBOUIM/SetUBOUIMDetailFromEmail`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: "SET_UBO_UIM_DETAILS_FAIL", data: json });
        } else {
          return dispatch(SetUBOUIMDetailsReceiveData(json));
        }
      })
      .catch((err) => {
        console.error(err);
        return dispatch({
          type: "SET_UBO_UIM_DETAILS_FAIL",
          error: err.response,
          data: [],
        });
      });
  };
};

const makeReducerEmptySentData = () => {
  return {
    type: "CLEAR_UBO_UIM_REDUCER",
  };
};

export const makeReducerEmpty = () => {
  return (dispatch) => {
    dispatch(makeReducerEmptySentData());
  };
};

const setNewDataFromAPIRes = (data) => {
  return {
    type: "SET_NEW_UBO_DATA_FROM_API",
    data: data,
  };
};

export const setUBOUIMToReducer = (data) => {
  return (dispatch) => {
    dispatch(setNewDataFromAPIRes(data));
  };
};

const setCommonFromUBOPEPres = (data) => {
  return {
    type: "SET_NEW_COMMON_FROM_UBO_PEP",
    data: data,
  };
};

export const setCommonFromUBOPEP = (data) => {
  return (dispatch) => {
    dispatch(setCommonFromUBOPEPres(data));
  };
};
