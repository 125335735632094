import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import DownloadFile from "../../../../elements/DownloadFile";
import DropDown from "../../../../elements/DropDown";
import MultiSelectDropdown from "../../../../elements/MultiSelectDropdown";
import NumberInput from "../../../../elements/NumberInput";
import TextBox from "../../../../elements/TextBox";
import { checkIsCommon } from "../../../external/OnBoarding/client-information/CommonFunctions";
import TypeSizeFreq from "./TypeSizeFreq";
class SourceOfFundsOC extends React.Component {
  render() {
    const { srcFundsObjComp, errors, isRequired, form } = this.props;

    const errorsMsg = JSON.parse(errors);

    let tpOCArray = [];
    if (this.props.form === "UK") {
      for (let i = 0; i < srcFundsObjComp.noOfTPInvestObj; i++) {
        tpOCArray.push(
          <div className="mb-4 col-lg-12">
            <fieldset>
              <legend>Third Party {i + 1}</legend>

              <Row>
                <div className="mb-3 col-lg-4">
                  <TextBox
                    label="Please provide name of company"
                    name="tpName"
                    id="tpName"
                    placeholder="enter your text"
                    isCommon={checkIsCommon(this.props.form) && true}
                    reducer={this.props.externalReducer}
                    chapter={this.props.chapter}
                    objBaseName="srcFundsObjComp"
                    baseName="tpInvstObjComp"
                    index={i}
                    disabled={true}
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "txtBox"
                        : "form-control"
                    }
                  />
                </div>
                <div className="mb-3 col-lg-4">
                  <DropDown
                    label="Please provide the country of residence of object company"
                    name="tpCountry"
                    id="tpCountry"
                    isCommon={checkIsCommon(this.props.form) && true}
                    reducer={this.props.externalReducer}
                    chapter={this.props.chapter}
                    objBaseName="srcFundsObjComp"
                    baseName="tpInvstObjComp"
                    index={i}
                    disabled={true}
                  />
                </div>
                <div className="mb-3 col-lg-4">
                  <DownloadFile
                    label="Please provide Annual Financial Statements/cash flow charts"
                    value={srcFundsObjComp.tpInvstObjComp[i].tpAnnualFinStmnts}
                  />
                </div>
              </Row>
            </fieldset>
          </div>
        );
      }
    }

    return (
      <>
        <div className="html2pdf__page-break"></div>
        <h4 className="sub-chapter-title mb-4">
          {form === "Malta"
            ? "Source of Funds Target Company / Transaction profile"
            : "Source of Funds Object Company / Transaction profile"}
        </h4>
        <Row className="pt-1 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label={
                form === "Malta"
                  ? "Will the UBO invest in the Target Company?"
                  : "Will the UBO invest in the Object Company?"
              }
              name="isUBOInvstObjComp"
              options={["Yes", "No"]}
              chapter={
                checkIsCommon(this.props.form) ? null : this.props.chapter
              }
              reducer={
                checkIsCommon(this.props.form)
                  ? "commonFields"
                  : this.props.externalReducer
              }
              isCommon={checkIsCommon(this.props.form)}
              objBaseName="srcFundsObjComp"
              bothDisabled={true}
            />
          </div>
          {srcFundsObjComp.isUBOInvstObjComp === "Yes" && (
            <>
              <div className="mb-3 col-lg-8">
                <Row className="pb-2">
                  <div className="mb-3 col-lg-6">
                    <DropDown
                      label="Please specify currency"
                      name="uboInvstCurrencyObjComp"
                      //   options={CurrencyArray}
                      chapter={
                        checkIsCommon(this.props.form)
                          ? null
                          : this.props.chapter
                      }
                      reducer={
                        checkIsCommon(this.props.form)
                          ? "commonFields"
                          : this.props.externalReducer
                      }
                      isCommon={checkIsCommon(this.props.form)}
                      objBaseName="srcFundsObjComp"
                      disabled={true}
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    <TextBox
                      label="Please specify the amount"
                      name="uboInvstObjCompAmnt"
                      placeholder="enter amount"
                      chapter={
                        checkIsCommon(this.props.form)
                          ? null
                          : this.props.chapter
                      }
                      reducer={
                        checkIsCommon(this.props.form)
                          ? "commonFields"
                          : this.props.externalReducer
                      }
                      isCommon={checkIsCommon(this.props.form)}
                      objBaseName="srcFundsObjComp"
                      validationType="Amount"
                      disabled={true}
                      className={
                        this.props.PdfGeneration === "Yes"
                          ? "txtBox"
                          : "form-control"
                      }
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    <TextBox
                      label="Please specify source/origin of funds"
                      name="uboInvstObjCompOrigin"
                      placeholder="enter source"
                      chapter={
                        checkIsCommon(this.props.form)
                          ? null
                          : this.props.chapter
                      }
                      reducer={
                        checkIsCommon(this.props.form)
                          ? "commonFields"
                          : this.props.externalReducer
                      }
                      isCommon={checkIsCommon(this.props.form)}
                      objBaseName="srcFundsObjComp"
                      validationType="Text"
                      disabled={true}
                      className={
                        this.props.PdfGeneration === "Yes"
                          ? "txtBox"
                          : "form-control"
                      }
                    />
                  </div>
                </Row>
              </div>
            </>
          )}
          <div className="mb-2 col-lg-12">
            <TextBox
              label="Please add comment"
              placeholder="Enter your text"
              name="uboInvstObjCompCmmnt"
              id="uboInvstObjCompCmmnt"
              reducer={this.props.reducer}
              chapter="chapter4"
              isRequired={this.props.form === "UK" ? false : isRequired}
              validationType="Text"
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.uboInvstObjCompCmmnt &&
              errorsMsg?.uboInvstObjCompCmmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.uboInvstObjCompCmmnt}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-0 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label={
                form === "Malta"
                  ? "Will the UPC fund the Target Company?"
                  : "Will the UPC fund the Object Company?"
              }
              name="isUPCfundObjComp"
              options={["Yes", "No"]}
              chapter={
                checkIsCommon(this.props.form) ? null : this.props.chapter
              }
              reducer={
                checkIsCommon(this.props.form)
                  ? "commonFields"
                  : this.props.externalReducer
              }
              isCommon={checkIsCommon(this.props.form)}
              objBaseName="srcFundsObjComp"
              bothDisabled={true}
            />
          </div>
          {srcFundsObjComp.isUPCfundObjComp === "Yes" && (
            <>
              <div className="mb-3 col-lg-4">
                <TextBox
                  label="Please specify source/origin of funds"
                  name="upcFundObjCompOrigin"
                  placeholder="enter source"
                  chapter={
                    checkIsCommon(this.props.form) ? null : this.props.chapter
                  }
                  reducer={
                    checkIsCommon(this.props.form)
                      ? "commonFields"
                      : this.props.externalReducer
                  }
                  isCommon={checkIsCommon(this.props.form)}
                  objBaseName="srcFundsObjComp"
                  validationType="Text"
                  disabled={true}
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtBox"
                      : "form-control"
                  }
                />
              </div>
              <div className="mb-3 col-lg-4">
                <DownloadFile
                  label="Please provide Annual Financial Statements"
                  value={srcFundsObjComp.annualFinStmnt}
                />
                <div className="mb-0 mt-3 col-lg-12 pl-0">
                  <CheckBox
                    label="Is approved?"
                    options={["Yes", "No"]}
                    name="annualFinStmntupcApprvd"
                    id="annualFinStmntupcApprvd"
                    chapter={this.props.chapter}
                    isRequired={this.props.form === "UK" ? false : isRequired}
                    reducer={this.props.reducer}
                  />
                  {errorsMsg &&
                    errorsMsg?.isSubmitted === true &&
                    errorsMsg?.annualFinStmntupcApprvd &&
                    errorsMsg?.annualFinStmntupcApprvd?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.annualFinStmntupcApprvd}
                      </span>
                    )}
                </div>
              </div>
            </>
          )}
          <div className="mb-2 col-lg-12">
            <TextBox
              label="Please add comment"
              placeholder="Enter your text"
              name="upcFundObjCompCmmnt"
              id="upcFundObjCompCmmnt"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? false : isRequired}
              validationType="Text"
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.upcFundObjCompCmmnt &&
              errorsMsg?.upcFundObjCompCmmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.upcFundObjCompCmmnt}
                </span>
              )}
          </div>
        </Row>
        <div className="html2pdf__page-break"></div>
        <Row className="pt-0 pb-2">
          <div className="mb-3 col-lg-4">
            <CheckBox
              label={
                form === "Malta"
                  ? "Does the financing come from a third party/loan (Other than bank loans)?"
                  : "Will third parties invest in the Object Company?"
              }
              name="isTpInvstObjComp"
              options={["Yes", "No"]}
              chapter={
                checkIsCommon(this.props.form) ? null : this.props.chapter
              }
              reducer={
                checkIsCommon(this.props.form)
                  ? "commonFields"
                  : this.props.externalReducer
              }
              isCommon={checkIsCommon(this.props.form)}
              objBaseName="srcFundsObjComp"
              bothDisabled={true}
            />
          </div>
          {srcFundsObjComp.isTpInvstObjComp === "Yes" && (
            <>
              {this.props.form === "UK" ? (
                <>
                  <div className="mb-3 col-lg-4">
                    <NumberInput
                      label="How many third parties will invest in the object Company?"
                      name="noOfTPInvestObj"
                      reducer="UKClientInformation"
                      chapter="chapter4"
                      objBaseName="srcFundsObjComp"
                      disabled={true}
                      maxLimit={100}
                    />
                  </div>
                  {tpOCArray}
                </>
              ) : (
                <div className="mb-3 col-lg-8">
                  <Row className="pb-2">
                    <div className="mb-3 col-lg-6">
                      <TextBox
                        label="Please provide the names of these entities"
                        name="tpName"
                        placeholder="enter your text"
                        chapter={
                          checkIsCommon(this.props.form)
                            ? null
                            : this.props.chapter
                        }
                        reducer={
                          checkIsCommon(this.props.form)
                            ? "commonFields"
                            : this.props.externalReducer
                        }
                        isCommon={checkIsCommon(this.props.form)}
                        objBaseName="srcFundsObjComp"
                        nestedObjBaseName="tpInvstObjComp"
                        disabled={true}
                        className={
                          this.props.PdfGeneration === "Yes"
                            ? "txtBox"
                            : "form-control"
                        }
                      />
                    </div>
                    <div className="mb-3 col-lg-6">
                      <DropDown
                        label="Please provide the country of residence of these entities"
                        name="tpCountry"
                        chapter={
                          checkIsCommon(this.props.form)
                            ? null
                            : this.props.chapter
                        }
                        reducer={
                          checkIsCommon(this.props.form)
                            ? "commonFields"
                            : this.props.externalReducer
                        }
                        isCommon={checkIsCommon(this.props.form)}
                        objBaseName="srcFundsObjComp"
                        nestedObjBaseName="tpInvstObjComp"
                        disabled={true}
                      />
                    </div>
                    <div className="mb-3 col-lg-6">
                      <DownloadFile
                        label="Please provide Annual Financial Statements/cash flow charts"
                        value={srcFundsObjComp.tpInvstObjComp.tpAnnualFinStmnts}
                      />
                      <div className="mt-3 col-lg-6 col-xl-4 pl-0">
                        <CheckBox
                          label="Is approved?"
                          options={["Yes", "No"]}
                          name="anulFinStmntTpApprvd"
                          id="anulFinStmntTpApprvd"
                          chapter={this.props.chapter}
                          isRequired={isRequired}
                          reducer={this.props.reducer}
                        />
                        {errorsMsg &&
                          errorsMsg?.isSubmitted === true &&
                          errorsMsg?.anulFinStmntTpApprvd &&
                          errorsMsg?.anulFinStmntTpApprvd?.length > 0 && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              {errorsMsg.anulFinStmntTpApprvd}
                            </span>
                          )}
                      </div>
                    </div>
                  </Row>
                </div>
              )}
            </>
          )}
          <div className="mb-2 col-lg-12">
            <TextBox
              label="Please add comment"
              placeholder="Enter your text"
              name="tpInvstObjCompCmmnt"
              id="tpInvstObjCompCmmnt"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? false : isRequired}
              validationType="Text"
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.tpInvstObjCompCmmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.tpInvstObjCompCmmnt}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-0 pb-2">
          <div className="mb-3 col-lg-4">
            <MultiSelectDropdown
              label={
                form === "Malta"
                  ? "Which countries are involved in transactions with the Target Company?"
                  : "Which countries are involved in transactions with the Object Company?"
              }
              name="cntrysInvlvdTrnsctnsObjComp"
              //options={countryList().getData()}
              chapter={
                checkIsCommon(this.props.form) ? null : this.props.chapter
              }
              reducer={
                checkIsCommon(this.props.form)
                  ? "commonFields"
                  : this.props.externalReducer
              }
              isCommon={checkIsCommon(this.props.form)}
              objBaseName="srcFundsObjComp"
              disabled={true}
              PdfGeneration={this.props.PdfGeneration}
            />
          </div>
          <div className="mb-3 col-lg-4 fieldAlignment">
            <TextBox
              label={
                form === "Malta"
                  ? "Estimated annual turnover Target Company"
                  : "Estimated annual turnover Object Company"
              }
              name="estmtdAnnualTrnOvrObjComp"
              placeholder="enter turnover"
              chapter={
                checkIsCommon(this.props.form) ? null : this.props.chapter
              }
              reducer={
                checkIsCommon(this.props.form)
                  ? "commonFields"
                  : this.props.externalReducer
              }
              isCommon={checkIsCommon(this.props.form)}
              objBaseName="srcFundsObjComp"
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
          <div className="mb-2 col-lg-12">
            <TextBox
              label="Please add comment"
              placeholder="Enter your text"
              name="cntrysInvldObjCompCmmnt"
              id="cntrysInvldObjCompCmmnt"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={this.props.form === "UK" ? false : isRequired}
              validationType="Text"
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.cntrysInvldObjCompCmmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.cntrysInvldObjCompCmmnt}
                </span>
              )}
          </div>
        </Row>
        <div className="html2pdf__page-break"></div>
        <Row className="pt-0 pb-2">
          <div className="mb-3 col-lg-4">
            <TextBox
              label={
                form === "Malta"
                  ? "Summary of assets (excluding participations) of Target Company"
                  : "Summary of assets (excluding participations) of Object Company"
              }
              name="assetSummryObjComp"
              placeholder="enter your text"
              chapter={
                checkIsCommon(this.props.form) ? null : this.props.chapter
              }
              reducer={
                checkIsCommon(this.props.form)
                  ? "commonFields"
                  : this.props.externalReducer
              }
              isCommon={checkIsCommon(this.props.form)}
              objBaseName="srcFundsObjComp"
              isMultipleLines={true}
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
          <div className="mb-3 col-lg-4">
            <DownloadFile
              label="For existing companies please include supporting evidence"
              value={srcFundsObjComp.assetSummryEvidence}
            />
          </div>
          <div className="mb-2 col-lg-4">
            <CheckBox
              label="Is approved?"
              options={["Yes", "No"]}
              name="asstSmmryEvidenceApprvd"
              id="asstSmmryEvidenceApprvd"
              chapter={this.props.chapter}
              isRequired={isRequired}
              reducer={this.props.reducer}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.asstSmmryEvidenceApprvd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.asstSmmryEvidenceApprvd}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-12">
            <TextBox
              label="Please add comment"
              placeholder="Enter your text"
              name="assSmmryObjCompCommnt"
              id="assSmmryObjCompCommnt"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              isRequired={this.props.form === "UK" ? false : true}
              validationType="Text"
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.assSmmryObjCompCommnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.assSmmryObjCompCommnt}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-0 pb-2">
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Summary of expected transactions"
              name="expctdTrnsctnsSummary"
              placeholder="enter your text"
              chapter={
                checkIsCommon(this.props.form) ? null : this.props.chapter
              }
              reducer={
                checkIsCommon(this.props.form)
                  ? "commonFields"
                  : this.props.externalReducer
              }
              isCommon={checkIsCommon(this.props.form)}
              objBaseName="srcFundsObjComp"
              isMultipleLines={true}
              disabled={true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
          </div>
          <div className="mb-3 col-lg-4">
            <DownloadFile
              label="Include supporting evidence for existing companies"
              value={srcFundsObjComp.expctdTrnsctnsEvidence}
            />
          </div>
          <div className="mb-2 col-lg-6 col-xl-4">
            <CheckBox
              label="Is approved?"
              options={["Yes", "No"]}
              name="expctdTransSummryApprvd"
              id="expctdTransSummryApprvd"
              chapter={this.props.chapter}
              isRequired={isRequired}
              reducer={this.props.reducer}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.expctdTransSummryApprvd?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.expctdTransSummryApprvd}
                </span>
              )}
          </div>

          <div className="mb-2 col-lg-12">
            <TextBox
              label="Please add comment"
              placeholder="Enter your text"
              name="expctdTransSummryCmmnt"
              id="expctdTransSummryCmmnt"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              isRequired={this.props.form === "UK" ? false : isRequired}
              validationType="Text"
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.expctdTransSummryCmmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.expctdTransSummryCmmnt}
                </span>
              )}
          </div>
        </Row>
        {(srcFundsObjComp.isUBOInvstObjComp === "Yes" ||
          srcFundsObjComp.isUPCfundObjComp === "Yes" ||
          srcFundsObjComp.isTpInvstObjComp === "Yes") && (
          <div className="pt-0 obligationComapnygrid">
            <div className="html2pdf__page-break"></div>
            <TypeSizeFreq
              errorsMsg={errorsMsg}
              externalReducer={this.props.externalReducer}
              form={this.props.form}
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              isRequired={isRequired}
              PdfGeneration={this.props.PdfGeneration}
            />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { form, externalReducer, chapter } = ownProps;
  if (
    form === "NL" ||
    form === "Curacao" ||
    form === "Malta" ||
    form === "Luxembourg"
  ) {
    return {
      srcFundsObjComp: state.commonFields.srcFundsObjComp,
    };
  } else {
    return {
      srcFundsObjComp: state[externalReducer][chapter].srcFundsObjComp,
    };
  }
};

export default connect(mapStateToProps, null)(SourceOfFundsOC);
