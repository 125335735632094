import axios from "axios";
import {
  DOCUSIGN_GENERATE_URL_FAIL,
  DOCUSIGN_GENERATE_URL_REQUEST,
  DOCUSIGN_GENERATE_URL_SUCCESS,
  DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_FAIL,
  DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_REQUEST,
  DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_SUCCESS,
  DOWNLOAD_EXTERNAL_FILE_FAIL,
  DOWNLOAD_EXTERNAL_FILE_REQUEST,
  DOWNLOAD_EXTERNAL_FILE_SUCCESS,
  DOWNLOAD_SIGNED_DOCS_FAIL,
  DOWNLOAD_SIGNED_DOCS_REQUEST,
  DOWNLOAD_SIGNED_DOCS_SUCCESS,
  INTERNAL_DELETE_FILE_REQUEST,
  INTERNAL_DELETE_FILE_SUCCESS,
  INTERNAL_DOWNLOAD_FILE_FAIL,
  INTERNAL_DOWNLOAD_FILE_REQUEST,
  INTERNAL_DOWNLOAD_FILE_SUCCESS,
  INTERNAL_UPLOAD_FILE_FAIL,
  INTERNAL_UPLOAD_FILE_REQUEST,
  INTERNAL_UPLOAD_FILE_SUCCESS,
} from "../constants/InternalFileHandleConstants";
import { PublicClientApplication } from "@azure/msal-browser";
import {getToken} from "./getToken"
const MSLOGIN_CLIENTID = process.env.REACT_APP_MSLOGIN_CLIENTID;
const MSLOGIN_REDIRECTURL = process.env.REACT_APP_MSLOGIN_REDIRECTURL;
// const getToken = async () => {
//   const cfg = {
//     auth: {
//       clientId: MSLOGIN_CLIENTID,
//       authority:
//         "https://login.microsoftonline.com/37ab276a-6d7b-4b26-95c4-23f9fd7d5786", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
//       redirectUri: MSLOGIN_REDIRECTURL,
//     },
//     cache: {
//       cacheLocation: "localStorage", // This configures where your cache will be stored
//       storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
//     },
//   };

//   const pca = new PublicClientApplication(cfg);
//   let allAccounts = JSON.parse(localStorage.getItem("allAccounts"));
//   const account = allAccounts[0];
//   let idToken = null;
//   try {
//     const resp = await pca.acquireTokenSilent({
//       scopes: ["User.Read"],
//       account,
//     });
//     idToken = resp.idToken;
//     return idToken;
//   } catch (err) {
//     return;
//   }
// };
const COMPANY_API_URL = process.env.REACT_APP_INTERNAL_USER_BACKEND_API_URL;
const FileUploadRequest = () => {
  return {
    type: INTERNAL_UPLOAD_FILE_REQUEST,
  };
};

const FileUploadSuccess = (json) => {
  return {
    type: INTERNAL_UPLOAD_FILE_SUCCESS,
    data: json,
  };
};

export const fileUpload = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(FileUploadRequest());
    return axios
      .post(`${COMPANY_API_URL}/CAF/UploadFile`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: INTERNAL_UPLOAD_FILE_FAIL, data: json });
        } else {
          return dispatch(FileUploadSuccess(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: INTERNAL_UPLOAD_FILE_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

const FileDownloadRequest = () => {
  return {
    type: INTERNAL_DOWNLOAD_FILE_REQUEST,
  };
};

const FileDownloadSuccess = (json) => {
  return {
    type: INTERNAL_DOWNLOAD_FILE_SUCCESS,
    data: json,
  };
};

export const fileDownload = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(FileDownloadRequest());
    return axios
      .post(`${COMPANY_API_URL}/CAF/DownloadFile`, data, {
        headers,
        responseType: "blob",
      })
      .then((response) => response.data)
      .then((json) => {
        if (json.Success === "false") {
          return dispatch({ type: INTERNAL_DOWNLOAD_FILE_FAIL, data: json });
        } else {
          return dispatch(FileDownloadSuccess(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: INTERNAL_DOWNLOAD_FILE_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

const FileDeleteRequest = () => {
  return {
    type: INTERNAL_DELETE_FILE_REQUEST,
  };
};

const FileDeleteSuccess = (json) => {
  return {
    type: INTERNAL_DELETE_FILE_SUCCESS,
    data: json,
  };
};

export const fileDelete = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(FileDeleteRequest());
    return axios
      .post(`${COMPANY_API_URL}/CAF/DeleteFile`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        console.log("json:",json);
        if (json.Success === "false") {        
          return dispatch({ type: INTERNAL_DOWNLOAD_FILE_FAIL, data: json });
        } else {
          return dispatch(FileDeleteSuccess(json));
        }
      })
      .catch((err) => {
        console.log(err.response);
          return dispatch({
          type: INTERNAL_DOWNLOAD_FILE_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

const externalFileDownloadRequest = () => {
  return {
    type: DOWNLOAD_EXTERNAL_FILE_REQUEST,
  };
};

const externalFileDownloadSuccess = (json) => {
  return {
    type: DOWNLOAD_EXTERNAL_FILE_SUCCESS,
    data: json,
  };
};

export const externalFileDownload = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(externalFileDownloadRequest());
    return axios
      .post(`${COMPANY_API_URL}/CAF/DownloadExternalFile`, data, {
        headers,
        responseType: "blob",
      })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: DOWNLOAD_EXTERNAL_FILE_FAIL, data: json });
        } else {
          return dispatch(externalFileDownloadSuccess(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: DOWNLOAD_EXTERNAL_FILE_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

const signedFileDownloadRequest = () => {
  return {
    type: DOWNLOAD_SIGNED_DOCS_REQUEST,
  };
};

const signedFileDownloadSuccess = (json) => {
  return {
    type: DOWNLOAD_SIGNED_DOCS_SUCCESS,
    data: json,
  };
};

export const downloadSignedDocs = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(signedFileDownloadRequest());
    return axios
      .post(`${COMPANY_API_URL}/CAF/DownloadSignedDocs`, data, {
        headers,
        responseType: "blob",
      })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: DOWNLOAD_SIGNED_DOCS_FAIL, data: json });
        } else {
          return dispatch(signedFileDownloadSuccess(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: DOWNLOAD_SIGNED_DOCS_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

const docuSignGenerateURLRequest = () => {
  return {
    type: DOCUSIGN_GENERATE_URL_REQUEST,
  };
};

const docuSignGenerateURLSuccess = (json) => {
  return {
    type: DOCUSIGN_GENERATE_URL_SUCCESS,
    data: json,
  };
};

export const docuSignGenerateURL = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(docuSignGenerateURLRequest());
    return axios
      .post(`${COMPANY_API_URL}/DocuSign/GenerateURL`, data, {
        headers,
      })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({ type: DOCUSIGN_GENERATE_URL_FAIL, data: json });
        } else {
          return dispatch(docuSignGenerateURLSuccess(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: DOCUSIGN_GENERATE_URL_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};

const downloadInternalSignedFileRequest = () => {
  return {
    type: DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_REQUEST,
  };
};

const downloadInternalSignedFileSuccess = (json) => {
  return {
    type: DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_SUCCESS,
    data: json,
  };
};

export const downloadInternalSignedFile = async (data) => {
  let idToken = await getToken();
  if (idToken !== undefined) {
    localStorage.setItem("authToken", JSON.stringify(idToken));
  } else {
    idToken = JSON.parse(localStorage.getItem("authToken"));
  }
  const headers = {
    Authorization: "Bearer " + idToken,
  };
  return async (dispatch) => {
    dispatch(downloadInternalSignedFileRequest());
    return axios
      .post(`${COMPANY_API_URL}/CAF/DownloadCAFInterSignedDocs`, data, {
        headers,
        responseType: "blob",
      })
      .then((response) => response.data)
      .then((json) => {
        window.sessionStorage.setItem("reloadCounter", 0);
        if (json.Success === "false") {
          return dispatch({
            type: DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_FAIL,
            data: json,
          });
        } else {
          return dispatch(downloadInternalSignedFileSuccess(json));
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //   localStorage.setItem(
        //     `server-telemetry-${MSLOGIN_CLIENTID}`,
        //     '{"failedRequests":[],"errors":["LoginAgain"],"cacheHits":327}'
        //   );
        // }
        return dispatch({
          type: DOWNLOAD_CAF_INTERNAL_SIGNED_DOCS_FAIL,
          error: err.response,
          data: [],
        });
      });
  };
};
