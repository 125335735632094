import React, { Component } from "react";
import { structureActivities } from "../Constants";

class ActivityDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { activites } = this.props;

    return (
      <>
        <h3 className="smallSubTitle mb-3">
          All the below activities are in line with the client accpetance
          policy.
        </h3>
        <div className="row">
          {structureActivities.map((activity, i) => {
            return (
              <div className="col-lg-4 internalActivity mb-3" classkey={i}>
                <h4 className="mb-3">{activity.label}</h4>
                <p className="mb-2">
                  <span className="font-weight-bold">{this .props.form=="NL" ? "Activity:":"Activity Name:"}</span>{" "}
                  {activites[activity.activityName]}
                </p>
                <p className="mb-2">
                  <span className="font-weight-bold">{this .props.form=="NL" ? "Sector:":"Sector Name:"}</span>{" "}
                  {activites[activity.sectorName]}
                </p>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default ActivityDetails;
