import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { approveRejectFromMail } from "../../../redux/actions/userActions";
import { iconSuccess } from "../../../assets/images";
import { Container, Row, Col } from "react-bootstrap";
import NotificationManager from "react-notifications/lib/NotificationManager";

class userMailAcceptance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      token: this.props.match.params.token,
      status: this.props.match.params.status,
      isSuccess: false,
      reason: "",
      errors: {},
    };
  }

  //handle change on all inputs
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (!this.state.reason) {
      errors["reason"] = "Fill required details";
      formIsValid = false;
    }
    this.setState({ errors });
    return formIsValid;
  };

  redirect = () => {
    const timer = setTimeout(() => {
      return this.props.history.push({
        pathname: "/internal-dashboard",
      });
    }, 5000);
  };

  componentDidMount = () => {
    const { token, status } = this.state;
    if (status === "1" || status === "3") {
      this.setState({ isLoading: true });
      let data = {
        Token: token,
        Status: parseInt(status),
        Remarks: "",
      };
      this.props.approveRejectFromMail(data).then((res) => {
        this.setState({ isLoading: false });
        if (res.data.portalErrorCode === 200) {
          this.setState({ isSuccess: true, reason: "" });
          this.redirect();
        } else if (res.data.portalErrorCode === 400) {
          NotificationManager.error("Link is already used");
          this.redirect();
        } else {
          NotificationManager.error("Error while accepting request");
        }
      });
    }
  };

  rejectRequest = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    if (this.validateForm()) {
      const { token, status } = this.state;

      let data = {
        Token: token,
        Status: parseInt(status),
        Remarks: this.state.reason,
      };
      this.props.approveRejectFromMail(data).then((res) => {
        this.setState({ isLoading: false });
        if (res.data.portalErrorCode === 200) {
          this.setState({ isSuccess: true });
          this.redirect();
        } else if (res.data.portalErrorCode === 400) {
          NotificationManager.error("Link is already used");
          this.redirect();
        } else {
          NotificationManager.error("Error while rejecting request");
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  handleCancel = () => {
    return this.props.history.push({
      pathname: "/login",
    });
  };

  render() {
    const { status, reason, isSuccess, isLoading } = this.state;
    const successMsg = (
      <div className="LoginWindow rounded mt-5">
        <div className="successMessage text-center">
          <img src={iconSuccess} alt="" />
          <p className="mt-4 success-message">
            Request {status === "1" ? "Approved" : "Rejected"} Successfully
          </p>
        </div>
      </div>
    );

    return (
      <>
        <div className="header headerInternal">
          <Container fluid>
            <Row>
              <Col xs={6}>
                <Link to="/internal-home" className="logo" />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="dashboard">
          <div className="acceptanceBg">
            {(status === "1" || status === "3") && isSuccess && successMsg}
            {(status === "2" || status === "4" || status === "7") &&
              (isSuccess ? (
                successMsg
              ) : (
                <div className="LoginWindow rounded mt-5 pt-4 pb-4">
                  <h1 className="heading-midium mt-0 pt-0">Remarks</h1>
                  <textarea
                    onChange={this.handleChange}
                    className="remarks-input"
                    name="reason"
                    rows="5"
                    value={reason}
                  ></textarea>
                  <div className="errorMsg">{this.state.errors.reason}</div>
                  <div className="button-grp mt-3">
                    <button
                      className="action-btn big"
                      onClick={this.rejectRequest}
                    >
                      Submit
                    </button>{" "}
                    <button
                      className="action-btn whiteBtn big"
                      onClick={this.handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ))}
            {isLoading && <div className="loader"></div>}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    approveRejectFromMail: (data) => dispatch(approveRejectFromMail(data)),
  };
};
export default connect(null, mapDispatchToProps)(userMailAcceptance);
