import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";
import {
  getCompanyDetails,
  createClientInformation,
} from "../../../../../redux/actions/CAFGenericActions";
import Modal from "../../../../components/shared/Modal";
import { NotificationManager } from "react-notifications";
import Tooltip from "./../../../../elements/Tooltip";
import { manageLandingPage } from "./manageLandingPage";

class AddEditClientInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      countries: [],
      offices: [],
      selectedCountry: "",
      selectedOffice: "",
      errors: {},
      modalOpen: false,
      modalContent: "",
      clientInfoFormName: "",
      response: false,
      formErrors: {},
    };
  }
  componentDidMount() {
    this.GetCompanyInfo();
  }
  GetCompanyInfo = () => {
    let fErrors = this.state.formErrors;
    this.props
      .getCompanyDetails()
      .then((res) => {
        if (res.data.portalErrorCode === 200) {
          let countriesArray = res.data.result.company;
          if (countriesArray.length !== 0) {
            this.setState({
              countries: res.data.result.company,
            });
          }
        } else if (!res.data.isError) {
          if (res.data.portalMessage === undefined) {
            fErrors.otherMessage = "Error occurred!";
          } else {
            fErrors.otherMessage = res.data.portalMessage;
          }
        } else {
          fErrors.otherMessage = "Error";
        }
        this.setState({ formErrors: fErrors });
      })
      .catch(this.catchError);
  };
  handleChange = (event) => {
    let fErrors = this.state.errors;
    const re = /[^a-zA-Z0-9\s_]/;
    //const re = /^[a-zA-Z0-9_]*$/;
    if (re.test(event.target.value)) {
      fErrors.clientInfoFormName = "Special characters not allowed.";
      this.setState({ errors: fErrors });
    } else {
      fErrors.clientInfoFormName = "";
      this.setState({
        clientInfoFormName: event.target.value,
        errors: fErrors,
      });
    }
  };
  onchangeCountry = (selectedoption) => {
    let errors = {};
    errors["country"] = "";
    this.setState({ errors });
    this.setState({
      offices: [],
      selectedOffice: "",
    });
    this.setState({
      selectedCountry: selectedoption,
      offices: selectedoption.office,
    });
  };
  onchangeOffice = (selectedoffice) => {
    let errors = {};
    errors["office"] = "";
    this.setState({ errors });
    this.setState({
      selectedOffice: selectedoffice,
    });
  };

  hideModal = () => {
    this.setState({
      modalOpen: false,
    });
  };
  confirmClick = () => {
    this.setState({
      modalOpen: false,
    });
    this.props.history.push({
      pathname: "/client-information-list",
    });
  };

  validateInputs = () => {
    let errors = {};
    let formIsValid = true;
    const { clientInfoFormName, selectedCountry, selectedOffice } = this.state;
    const msg = "Please fill required detail";
    if (!clientInfoFormName) {
      formIsValid = false;
      errors["clientInfoFormName"] = msg;
    }
    if (!selectedCountry.id) {
      formIsValid = false;
      errors["country"] = msg;
    }
    if (!selectedOffice.name) {
      formIsValid = false;
      errors["office"] = msg;
    }
    this.setState({ errors });
    return formIsValid;
  };

  AddClientInformation = async (data) => {
    if (this.validateInputs()) {
      await this.props
        .createClientInformation(data)
        .then((res) => {
          localStorage.setItem("formId", res.data?.result?.id);
          this.setState({ isLoading: false });
          if (res.data.portalErrorCode === 200) {
            NotificationManager.success("Form added successfully!");
            this.setState({ response: true });
          } else if (res.data.portalErrorCode === 400) {
            NotificationManager.error(res.data.portalMessage);
            this.setState({ response: false });
          } else {
            this.setState({ response: false });
          }
        })
        .catch(this.catchError);
    }
    return this.state.response;
  };

  backToList = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  handleNewOfficeChange = (e) => {
    this.props.handleOfficeChange(e);
  };

  handleFillNow = async () => {
    localStorage.setItem("clientId", this.state.selectedCountry.id);
    localStorage.setItem("CompanyName", this.state.selectedCountry.name);
    localStorage.setItem("Office", this.state.selectedOffice.name);
    this.handleNewOfficeChange(this.state.selectedOffice.name);
    const data = {
      id: this.state.id,
      name: this.state.clientInfoFormName,
      clientId: this.state.selectedCountry.id,
      clientName: this.state.selectedCountry.name,
      office: this.state.selectedOffice.name,
      status: "In Progress",
      lastActivityName: "generic-form",
      activeLinkLevel: 0,
    };
    let res = false;
    res = await this.AddClientInformation(data);
    if (res) {
      this.setState({ response: false });
      let path = manageLandingPage(this.state.selectedOffice.name);
      this.props.history.push({
        pathname: path,
      });
    }
  };

  handleFillLater = () => {
    const data = {
      name: this.state.clientInfoFormName,
      clientId: this.state.selectedCountry.id,
      clientName: this.state.selectedCountry.name,
      office: this.state.selectedOffice.name,
      status: "Not Started",
    };
    let res = false;
    res = this.AddClientInformation(data);
    if (res) {
      this.setState({ response: false });
      window.setTimeout(() => {
        this.props.history.push({
          pathname: "/client-information-list",
        });
      }, 1000);
    }
  };
  render() {
    const { clientInfoFormName, errors } = this.state;
    return (
      <div className="dashboard external-dashboard">
        <div className="generic-form border-bottom-0">
          <div className="eventHeader pb-3">
            <h2 className="m-0 p-0 pagetitle">Create Form</h2>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
          <Row className="pt-5 pb-4">
            <div className="create-form p-4">
              <Row>
                <Col lg="12" className="mb-2">
                  <label className="tooltipFullWidth">
                    Form Name *{" "}
                    <Tooltip text="Ideal way of writing form name ”Clientname_officename_formname"></Tooltip>
                  </label>

                  <input
                    type="text"
                    value={clientInfoFormName}
                    name="clientInfoFormName"
                    placeholder="Enter Form Name"
                    className="form-control"
                    onChange={this.handleChange}
                  />
                  <div className="errorMsg">{errors.clientInfoFormName}</div>
                </Col>

                <Col lg="12" className="mb-2">
                  <label>Select Client *</label>
                  <Select
                    options={this.state.countries}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={this.onchangeCountry}
                    value={this.state.selectedCountry}
                  />
                  <div className="errorMsg">{errors.country}</div>
                </Col>

                <Col lg="12" className="mb-2">
                  <label>Select Office *</label>
                  <Select
                    options={this.state.offices}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    onChange={this.onchangeOffice}
                    value={this.state.selectedOffice}
                  />
                  <div className="errorMsg">{errors.office}</div>
                </Col>

                <Col lg="12" className="mt-3">
                  <Button
                    className="action-btn big mr-4"
                    onClick={this.handleFillNow}
                  >
                    Start Filling Now
                  </Button>
                  <Button
                    className="action-btn greybtn big mr-4"
                    onClick={this.handleFillLater}
                  >
                    Fill Form Later
                  </Button>
                </Col>
              </Row>
            </div>
            {this.state.isLoading && <div className="loader"></div>}
          </Row>
          <Row className="justify-content-center pt-3">
            <Button className="action-btn link" onClick={this.backToList}>
              Back to List
            </Button>
          </Row>
          {this.state.modalOpen && (
            <Modal
              show={this.state.modalOpen}
              handleClose={(e) => this.hideModal(e)}
            >
              <h4 className="mb-4">
                Are you sure you wants to go back? <br /> Your data will not be
                saved
              </h4>
              <div className="form-group mb-0">
                <button
                  onClick={(e) => this.confirmClick(e)}
                  type="button"
                  className="action-btn noicon mr-2"
                >
                  Yes
                </button>
                <button
                  onClick={(e) => this.hideModal(e)}
                  type="button"
                  className="action-btn greybtn noicon"
                >
                  No
                </button>
              </div>
            </Modal>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    genericData: state.genericInformation.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyDetails: () => dispatch(getCompanyDetails()),
    createClientInformation: (data) => dispatch(createClientInformation(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditClientInformation);
