import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import DropDown from "../../../../elements/DropDown";
import InternalFileUpload from "../../../../elements/InternalFileUpload";
import MultiSelectDropdown from "../../../../elements/MultiSelectDropdown";
import TextBox from "../../../../elements/TextBox";
import { checkIsCommon } from "../../../external/OnBoarding/client-information/CommonFunctions";
import { fileAcceptType } from "../Constants";
import countryList from "react-select-country-list";

class ObjectCompany extends React.Component {
  render() {
    const {
      objCompNaturePropsdObjctv,
      form,
      isObjCompBranches,
      isObjCompBranchesInter,
      errors,
      isRequired,
      objCompActiveCountry,
      objCompBranch
    } = this.props;
    const errorsMsg = JSON.parse(errors);

    return (
      <>
        <Row className="pt-0 pb-2">
          <div
            className={
              this.props.PdfGeneration === "Yes"
                ? "mb-3 col-lg-4"
                : "mb-3 col-lg-4 fieldAlignment"
            }
          >
            {form != "NL" && (
              <MultiSelectDropdown
                label={
                  form === "Malta"
                    ? "Target Company active  in country"
                    : "Object Company active in country?"
                }
                name="objCompActiveCountry"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                disabled={true}
                PdfGeneration={this.props.PdfGeneration}
              />
            )}
            {objCompActiveCountry !== undefined && objCompActiveCountry.length != 0 && form == "NL" && (
              <MultiSelectDropdown
                label={
                  form === "Malta"
                    ? "Target Company active  in country"
                    : "Object Company active in country?"
                }
                name="objCompActiveCountry"
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                disabled={true}
                PdfGeneration={this.props.PdfGeneration}
              />
            )}
            {objCompActiveCountry !== undefined && objCompActiveCountry.length == 0 && form == "NL" && (
              <MultiSelectDropdown
                label="Object Company active in country?"
                name="objCompActiveCountry"
                options={countryList().getData()}
                reducer={this.props.reducer}
                chapter={this.props.chapter}
                PdfGeneration={this.props.PdfGeneration}
              />
            )}
          </div>

          <div className="col-lg-4">
            <InternalFileUpload
              label="Pascal report"
              name="objPascalResport"
              id="objPascalResport"
              chapter={this.props.chapter}
              acceptType={fileAcceptType}
              reducer={this.props.reducer}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.objPascalResport &&
              errorsMsg?.objPascalResport?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.objPascalResport}
                </span>
              )}
          </div>
          <div className="col-lg-4 mb-3">
            <TextBox
              label="Findings"
              placeholder="Enter your text"
              name="objFindings"
              id="objFindings"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              validationType="Text"
              isRequired={isRequired}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.objFindings &&
              errorsMsg?.objFindings?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.objFindings}
                </span>
              )}
          </div>
          <div className="html2pdf__page-break"></div>

          <div className="mb-3 col-lg-4">
            <DropDown
              label={
                form === "Malta"
                  ? "Nature and proposed objective of the Target Company"
                  : "Nature and proposed objective of the Object Company"
              }
              name="objCompNaturePropsdObjctv"
              reducer={
                checkIsCommon(this.props.form)
                  ? "commonFields"
                  : this.props.externalReducer
              }
              chapter={
                checkIsCommon(this.props.form) ? null : this.props.chapter
              }
              isCommon={checkIsCommon(this.props.form)}
              disabled={true}
            />
          </div>
          {objCompNaturePropsdObjctv === "Other" && (
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Other"
                name="objCompOtherNaturePropsdObjctv"
                placeholder="Enter your text"
                reducer={
                  checkIsCommon(this.props.form)
                    ? "commonFields"
                    : this.props.externalReducer
                }
                chapter={
                  checkIsCommon(this.props.form) ? null : this.props.chapter
                }
                isCommon={checkIsCommon(this.props.form)}
                disabled={true}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
            </div>
          )}

          <div className="mb-2 col-lg-12">
            <TextBox
              label="Please add comment"
              placeholder="Enter your text"
              name="objCompActiveConCmmnt"
              id="objCompActiveConCmmnt"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              validationType="Text"
              isRequired={isRequired}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.objCompActiveConCmmnt &&
              errorsMsg?.objCompActiveConCmmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.objCompActiveConCmmnt}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-0 pb-2">
          <div className="mb-3 col-lg-4">
            {isObjCompBranches && (
              <CheckBox
                label={
                  form === "Malta"
                    ? "Does the Target Company have branches / Subsidiaries?"
                    : "Does the Object Company have branches?"
                }
                options={["Yes", "No"]}
                name="isObjCompBranches"
                chapter={this.props.chapter}
                reducer={this.props.externalReducer}
                bothDisabled={true}
              />
            )}
            {!isObjCompBranches && form === "NL" && (
              <>
                <CheckBox
                  label="Does the Object Company have branches?"
                  options={["Yes", "No"]}
                  name="isObjCompBranches1"
                  id="isObjCompBranches"
                  reducer={this.props.reducer}
                  chapter={this.props.chapter}
                />
              </>
            )}
          </div>
          {form !== "NL" && (
            <div className="col-lg-4">
              <MultiSelectDropdown
                label="In which country/countries?"
                name="objCompBranch"
                //options={countryList().getData()}
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                disabled={true}
                PdfGeneration={this.props.PdfGeneration}
              />
            </div>
          )}
          {objCompBranch !== undefined && objCompBranch.length != 0 && form === "NL" && (
            <div className="col-lg-4">
              <MultiSelectDropdown
                label="In which country/countries?"
                name="objCompBranch"
                //options={countryList().getData()}
                reducer={this.props.externalReducer}
                chapter={this.props.chapter}
                disabled={true}
                PdfGeneration={this.props.PdfGeneration}
              />
            </div>
          )}
          {objCompBranch !== undefined && objCompBranch.length == 0 && form === "NL" && (
            <div className="col-lg-4">
              <MultiSelectDropdown
                label="In which country/countries?"
                name="objCompBranch"
                options={countryList().getData()}
                reducer={this.props.reducer}
                chapter={this.props.chapter}

              />
            </div>
          )}
          <div className="mb-2 col-lg-12">
            <TextBox
              label="Please add comment"
              placeholder="Enter your text"
              name="objCompBranchesCmmnt"
              id="objCompBranchesCmmnt"
              reducer={this.props.reducer}
              chapter={this.props.chapter}
              validationType="Text"
              isRequired={this.props.form === "UK" || this.props.form === "Curacao" || this.props.form ==="NL" ? false : true}
              className={
                this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
              }
            />

            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.objCompBranchesCmmnt &&
              errorsMsg?.objCompBranchesCmmnt?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.objCompBranchesCmmnt}
                </span>
              )}
          </div>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { form, externalReducer, chapter } = ownProps;
  if (form === "NL") {
    return {
      objCompNaturePropsdObjctv:
        state.commonFields["objCompNaturePropsdObjctv"],
      isObjCompBranches: state.clientInformation.chapter4["isObjCompBranches"],
      objCompActiveCountry: state[externalReducer][chapter]["objCompActiveCountry"],
      objCompBranch: state.clientInformation.chapter4["objCompBranch"],
      isObjCompBranchesInter: state["ClientAcceptance"][chapter]["isObjCompBranches"],
    };
  } else {
    return {
      objCompNaturePropsdObjctv:
        state[externalReducer][chapter]["objCompNaturePropsdObjctv"],
      isObjCompBranches: state[externalReducer][chapter]["isObjCompBranches"],
    };
  }
};

export default connect(mapStateToProps, null)(ObjectCompany);
