import React from "react";
import { Row } from "react-bootstrap";
import TextBox from "../../../../elements/TextBox";
import {
  setUKInternalCAFDetails,
  getUKInternalCAFDetails,
} from "../../../../../redux/actions/UK/UKInternalCAFActions";
import "../../Onboarding/internalCAF.scss";
import AutoSaving from "../AutoSaving";
import { connect } from "react-redux";
import NotificationManager from "react-notifications/lib/NotificationManager";
import getSequentialFields from "../getSequentialFields";
import { setUKInternalChapterError } from "../../../../../redux/actions/UK/UKInternalChapterErrorActions";
import { chapValidationErrorUK } from "../constants";

const chapter = "chapter8";
const reducer = "UKInternalCAF";

let timer;

class Chapter8 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idLoading: false,
      formErrorsChapter8: {},
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: "all",
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.props.getUKInternalCAFDetails(reqData).then(async (res) => {
        this.setState({ isLoading: false });
        if (
          res.data.statusCode === 200 &&
          Object.keys(this.props.internalErrors.errors).length === 0 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationErrorUK.forEach(async (element) => {
            const response =
              res.data.result.cafInternalValidations[element.chapter];

            const newResponse = await Promise.resolve(
              getSequentialFields(response, element.chapter, false)
            );
            this.props.setUKInternalChapterError({
              form: `formErrors${element.chapter}`,
              errors: newResponse?.errorChapter,
            });
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;

    this.props.setUKInternalCAFDetails(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.props.ClientAcceptance.chapter8["dirty"] = false;
        let response = res.data.result;

        const newResponse = await Promise.resolve(
          getSequentialFields(response, chapter, true)
        );
        this.props.setUKInternalChapterError({
          errors: newResponse?.errorChapter,
          form: "formErrorschapter8",
        });
        if (!res.data.result.isValid) {
          NotificationManager.info(
            "All fields are mandatory to fill in",
            null,
            4000
          );
          timer = setTimeout(() => {
            return this.props.history.push({
              pathname: "/united-kingdom/correspondence",
            });
          }, 4000);
        } else {
          NotificationManager.success("Form is submitted successfully.");

          return this.props.history.push({
            pathname: "/united-kingdom/correspondence",
          });
        }
      }
    });
  };

  render() {
    const { internalErrors } = this.props;
    const formErrorsChapter8 = JSON.stringify(internalErrors.errors);
    const errorsMsg = JSON.parse(formErrorsChapter8);
    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="cafHeader">
            <h2 className="m-0">Client Acceptance Form</h2>
          </div>
          <div className="internal_caf generic-form">
            <div className="html2pdf__page-break"></div>
            <h3 className="chapterttl mb-3">Consolidated Risk Assessment</h3>
            <Row className="pt-0 pb-2">
              <div className="col-lg-12">
                <TextBox
                  label="Based on the individual risk ratings per tab, what is your view on the overall risk rating?"
                  placeholder="Enter your text"
                  name="overallRiskRating"
                  id="overallRiskRating"
                  chapter={chapter}
                  reducer={reducer}
                  isMultipleLines={true}
                  isRequired={true}
                  className={
                    this.props.PdfGeneration === "Yes"
                      ? "txtArea"
                      : "form-control"
                  }
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.overallRiskRating?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.overallRiskRating}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ClientAcceptance: state.UKInternalCAF,
    data: state,
    internalErrors: state.UKInternalChapterError.formErrorschapter8,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUKInternalCAFDetails: async (data) =>
      dispatch(await getUKInternalCAFDetails(data)),
    setUKInternalCAFDetails: async (data) =>
      dispatch(await setUKInternalCAFDetails(data)),
    setUKInternalChapterError: (data) =>
      dispatch(setUKInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter8);
