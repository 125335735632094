export const IntermediaryObj = {
  isClientReal: "",
  clientRepNationality: null,
  isIntRegUKEqRegu: null,
  eqRegExplaination: null,
  eqRegulator: [],
  intrAmlPoliciesProcs: [],
  intrProofAdd: [],
  intrReferenceLett: [],
  clientRepName: null,
  clientRepPassport: [],
  legalEntityName: null,
  cocExtract: [],
  activeInCountry: null,
  isPOA: "",
  poa: [],
  noPOAReason: null,
  isUBOIdentifyVerify: "",
  uboIdentityPassport: [],
  noUBOIdentifyVerifyReason: null,
};
export const AuthorisedObj={
  authrzdPrsnName: null,
  authrzdPrsnNationality:null,
  authrzdPrsnPassport: [],
  authrzdPrsnPOA: [],
  authrzdPrsnAuthLetter: [],
  authrzdPrsnRefLetter: [],
}
export const Chapter2 = {
  isClientRepInvolved: "",
  clientRepNum: 1,
  intermediaryDetails: [IntermediaryObj],
  authorisedPersonsNum:1,
  authorisedDetails: [AuthorisedObj],
  isClientTransfrd: null,
  srvcPrvdrName: null,
  reasonClientTransfrd: null,
  isAuthrzdPrsn: null,
  //---------remove later-------
  // authrzdPrsnName: null,
  // authrzdPrsnNationality: null,
  // authrzdPrsnPassport: [],
  // authrzdPrsnPOA: [],
  // authrzdPrsnAuthLetter: [],
  // authrzdPrsnRefLetter: [],
  //---------------------------
  dirty: false,
};
