import React, { Component } from "react";
import PhoneInputField from "../../../../elements/PhoneInputField";
import TextBox from "../../../../elements/TextBox";

class PEPs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { index, baseName, errorMsg } = this.props;

    return (
      <div className="mb-4 col-lg-12 pl-0">
        <fieldset className="eqheight">
          <legend>Non-UBO PEP {index + 1}</legend>
          <div className="row mb-0">
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Name"
                placeholder="Enter name"
                name="pepName"
                index={index}
                baseName={baseName}
                reducer="commonFields"
                isCommon={true}
                objBaseName={this.props.objBaseName}
                validationType="OnlyLetter"
              />
              {errorMsg !== null &&
                Object.entries(errorMsg).length !== 0 &&
                errorMsg.PEP[index]?.pepName?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorMsg.PEP[index]?.pepName}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-4">
              <TextBox
                label="Email"
                placeholder="Enter email"
                name="pepEmail"
                index={index}
                baseName={baseName}
                reducer="commonFields"
                isCommon={true}
                objBaseName={this.props.objBaseName}
                validationType="Email"
                ShowValidation={
                  errorMsg !== null &&
                  Object.entries(errorMsg).length !== 0 &&
                  errorMsg.PEP[index]?.pepEmail?.length > 0
                    ? false
                    : true
                }
              />

              {errorMsg !== null &&
                Object.entries(errorMsg).length !== 0 &&
                errorMsg.PEP[index]?.pepEmail?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorMsg.PEP[index]?.pepEmail}
                  </span>
                )}
            </div>
            <div className="mb-3 col-lg-4">
              <PhoneInputField
                label="Phone Number"
                name="pepPhoneNum"
                index={index}
                baseName={baseName}
                reducer="commonFields"
                isCommon={true}
                objBaseName={this.props.objBaseName}
                validationType="Email"
                country={this.props.form}
              />
              {errorMsg !== null &&
                Object.entries(errorMsg).length !== 0 &&
                errorMsg.PEP[index]?.pepPhoneNum?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorMsg.PEP[index]?.pepPhoneNum}
                  </span>
                )}
            </div>
          </div>
        </fieldset>
      </div>
    );
  }
}

export default PEPs;
