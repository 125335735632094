import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { connect } from "react-redux";
import {
  getUBOPEPList,
  sendEmailToUbo,
  resendEmailToUbo,
  changeStatusOnNext,
} from "../../../../../redux/actions/UBOPEPActions";

class UboList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      originalData: [],
      data: [],
      columns: [
        {
          name: "Name",
          selector: (row) => row.uboXName || row.pepName || row.upcAuthDirName,
          sortable: true,
        },
        {
          name: "Email",
          selector: (row) =>
            row.uboEmail || row.pepEmail || row.upcAuthDirEmail,
          sortable: true,
        },
        {
          name: "Mobile No.",
          selector: (row) =>
            row.uboPhoneNum || row.pepPhoneNum || row.phoneNumberUPC,
          sortable: true,
        },
        {
          name: "Status",
          selector: (row) =>
            row.status === "NotStarted"
              ? "Not Started"
              : row.status === "InProgress"
              ? "In Progress"
              : "Completed",

          sortable: true,
        },

        {
          name: "",
          selector: (row) => {
            return row.buttonStatus === "Send" ? (
              <button
                type="button"
                className="action-btn edit noicon"
                onClick={() =>
                  this.handleSend(row.uboId || row.pepId || row.upcId)
                }
              >
                Send
              </button>
            ) : row.buttonStatus === "Resend" ? (
              <button
                type="button"
                className="action-btn clientEdit noicon"
                onClick={() =>
                  this.handleResendEmail(row.uboId || row.pepId || row.upcId)
                }
              >
                Resend
              </button>
            ) : row.buttonStatus === "Downlod" ? (
              <span
                style={{
                  width: "100%",
                  diplay: "block",
                  textAlign: "center",
                  color: "green",
                }}
              >
                <i class="fa fa-check-circle fa-2x mr-3"></i>
              </span>
            ) : (
              // <button type="button" className="action-btn reject noicon">
              //   Download
              // </button>
              <span
                style={{ width: "100%", diplay: "block", textAlign: "center" }}
              >
                -
              </span>
              // <button type="button" className="action-btn reject noicon">
              //   Download
              // </button>
            );
          },
        },
      ],
    };
  }

  componentDidMount = () => {
    const formId = localStorage.getItem("formId");
    if (formId) {
      this.GetUBOPEPList(formId);
    }
  };
  handleResendEmail(id) {
    const formId = localStorage.getItem("formId");
    this.props.resendEmailToUbo({ id: id, formId: formId }).then((res) => {
      if (res.data?.portalErrorCode === 200) {
        NotificationManager.success("Email sent successfully.");
        this.GetUBOPEPList(formId);
      } else {
        NotificationManager.error("Error in sending mail");
      }
    });
  }

  handleSend(id) {
    const formId = localStorage.getItem("formId");
    this.props.sendEmailToUbo({ id: id, formId: formId }).then((res) => {
      if (res.data?.portalErrorCode === 200) {
        NotificationManager.success("Email sent successfully.");
        this.GetUBOPEPList(formId);
      } else {
        NotificationManager.error("Error in sending mail");
      }
    });
  }
  GetUBOPEPList(formId) {
    this.setState({ isLoading: true });
    this.props.getUBOPEPList(formId).then((res) => {
      this.setState({ isLoading: false });
      if (res.data?.portalErrorCode === 200) {
        let concatedData = [];
        if (res.data?.result?.pep !== null) {
          res.data?.result?.pep?.map((data) => concatedData.push(data));
        }
        if (res.data?.result?.ubo !== null) {
          res.data?.result?.ubo?.map((data) => concatedData.push(data));
        }
        if (res.data?.result?.uPC !== null) {
          concatedData.push(res.data?.result?.uPC);
          //res.data?.result?.uPC?.map((data) => concatedData.push(data));
        }
        this.setState({
          originalData: concatedData,
          data: concatedData,
        });
      }
    });
  }

  handleProceedNext = () => {
    this.props
      .changeStatusOnNext({ id: localStorage.getItem("formId") })
      .then((res) => {
        if (res.data?.portalErrorCode === 200) {
          NotificationManager.success("Form is completed successfully.");
          return this.props.history.push({
            pathname: "/client-information-list",
          });
        } else {
          NotificationManager.error("Error while submitting the form.");
        }
      });
  };

  render() {
    const { columns, data } = this.state;
    let proceedFlag = true;
    data.map((ubo) => {
      if (ubo.status === "NotStarted" || ubo.status === "InProgress") {
        proceedFlag = false;
      }
    });
    return (
      <div className="dashboard external-dashboard">
        <div className="generic-form pb-3">
          {this.state.isLoading && <div className="loader"></div>}
          <Container fluid>
            <Row className="eventHeader mb-3">
              <Col sm={9} className="pt-0 pb-0 pl-0">
                <h2 className="m-0 p-0 pagetitle">UBO/PEP Status</h2>
                <p className="pt-2 mb-1">
                  Status List of all the UBOs form you have sent via Client
                  Information form, Once all the UBOs filled the form you can
                  proceed further
                </p>
              </Col>
              <Col sm={3} className="text-right add-button">
                <button className="d-none action-btn big">Email All</button>
              </Col>
            </Row>
          </Container>
          <Row className="noDataTableSelection">
            <Col lg={12}>
              <DataTable
                className="datatable"
                columns={columns}
                data={data}
                pagination
              />
            </Col>
          </Row>
        </div>
        <Row className="text-center">
          <Col lg={12}>
            <button
              onClick={this.handleProceedNext}
              //onClick={(e) => this.redirect(e)}
              type="button"
              className={`action-btn noicon pt-2 pb-2 pl-4 pr-4 ${
                !proceedFlag && `greybtn disabled`
              }`}
            >
              Submit
            </button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ubos: state.ubo,
  };
};
const mapDisptachToProps = (dispatch) => {
  return {
    getUBOPEPList: (data) => dispatch(getUBOPEPList(data)),
    sendEmailToUbo: (data) => dispatch(sendEmailToUbo(data)),
    resendEmailToUbo: (data) => dispatch(resendEmailToUbo(data)),
    changeStatusOnNext: (data) => dispatch(changeStatusOnNext(data)),
  };
};

export default connect(mapStateToProps, mapDisptachToProps)(UboList);
