/* eslint-disable no-useless-escape */
const emailRegex = RegExp(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const onlyLetter = /[^a-zA-Z\s]/;
const textRegex = /[^a-zA-Z0-9?&.,-\s]/;
const alphanumericWithSpecialCharacters = /^[ A-Za-z0-9_@./#&+-]*$/;
const amountRegex = /^[+-]?\d*(?:[.,]\d*)?$/;
const passportNoRegex = /^[A-Z]{1}[0-9]{7}/;
export const checkValidation = (validationType, value) => {
  switch (validationType) {
    case "Email":
      return emailRegex.test(value) ? "" : "Add valid Email ID";

    case "OnlyLetter":
      return !onlyLetter.test(value) ? "" : "Please enter only characters";

    case "Text":
      return !textRegex.test(value) ? "" : "Special characters not allowed";

    case "All":
      return !alphanumericWithSpecialCharacters.test(value)
        ? ""
        : "Please enter valid data ";
    case "PassportNO":
      return passportNoRegex.test(value)
        ? ""
        : "Please enter valid passport number ";

    case "Amount":
      return amountRegex.test(value) ? "" : "Please enter valid data";

    default:
      return "";
  }
};
