const MSLOGIN_REDIRECTURL = process.env.REACT_APP_MSLOGIN_REDIRECTURL;
export const msalConfig = {
  auth: {
    //clientId: "okay",
    clientId: "f3a68744-2cf4-449a-88e1-905ff800f74e",
    authority:
      "https://login.microsoftonline.com/b6c7c4de-0732-4fbd-b5d1-e07be86946b1", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
     redirectUri: MSLOGIN_REDIRECTURL,
     //redirectUri: "http://localhost:3000/internal-dashboard/userlist",
     //postLogoutRedirectUri:"https://clientportal-uibt-frontend-qa.azurewebsites.net/internal-home",
     navigateToLoginRequestUrl: false
        
  },
  system: {
    iframeHashTimeout: 10000,
    navigateFrameWait: 1000,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge    
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
