import { AdditionDocs } from "./Chapter1";

export const RightsAndOblgtnsRsk = {
  rightsId: null,
  integrityRsk: null,
};

export const BnkInfoRsk = {
  bankId: null,
  integrityRsk: null,
};

let roarray = [];
for (let i = 0; i < 10; i++) {
  roarray.push(RightsAndOblgtnsRsk);
}

let bankarray = [];
for (let i = 0; i < 10; i++) {
  bankarray.push(BnkInfoRsk);
}

export const Chapter4 = {
  objCompCmmnt: null,
  objCompDirCmmnt: null,
  objCompActiveConCmmnt: null,
  objCompBranchesCmmnt: null,
  rationaleStrctrngForNLCmmnt: null,
  objPascalResport: [],
  objFindings: null,
  findingsIndpTaxAdvisorApprvd: null,
  bsEvidencesIsApprvd: null,
  bsEvidencesCmmnt: null,
  cpyPassportMangApprvd: null,
  extCOCApprvd: null,
  deedOfIncorpApprvd: null,
  shareholdersRegApprvd: null,
  shareholdersCrtfictsApprvd: null,
  finStmntsPstthreeYrsApprvd: null,
  taxAdviceApprvd: null,
  taxAdviceCmmnt: null,
  invstmntMemoApprvd: null,
  apaCmmnt: null,
  otherRelDocsApprvd: null,
  otherRelDocsCmmnt: null,
  uboInvstObjCompCmmnt: null,
  annualFinStmntupcApprvd: null,
  upcFundObjCompCmmnt: null,
  anulFinStmntTpApprvd: null,
  tpInvstObjCompCmmnt: null,
  cntrysInvldObjCompCmmnt: null,
  asstSmmryEvidenceApprvd: null,
  assSmmryObjCompCommnt: null,
  expctdTransSummryApprvd: null,
  expctdTransSummryCmmnt: null,
  objCompGrpCompsCmmnt: null,
  grpCompsObjCompCmmnt: null,
  objCompUboCmmnt: null,
  uboObjCmpCmmnt: null,
  objCompTpCmmnt: null,
  tpObjCompCmmnt: null,
  rightsAndOblgtnsRsk: roarray,
  rightsAndOblgtnsCmmnt: null,
  bnkInfoRsk: bankarray,
  bnkInfoCmmnt: null,
  fatcaFormApprvd: null,
  bnkStmntsApprvd: null,
  aggrmntsGrpEntApprvd: null,
  aggrmntsOthrParApprvd: null,
  finStmntObjCompApprvd: null,
  finStmntAllPartciApprvd: null,
  finStmntParCompApprvd: null,
  declrFinInstApprvd: null,
  isMediaCoverageOnStruct: null,
  isAddDocs: null,
  numAddDocs: 1,
  addDocs: [AdditionDocs],
  custActvtyRskRtng: null,
  descActnNeedsTkn: null,
  objCompIncrdIntgtyRsk: null,
  objCompIncrdIntgtyRskText: null,

  dirty: false,
};
