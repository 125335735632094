import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import DropDown from "../../../../elements/DropDown";
import NumberInput from "../../../../elements/NumberInput";
import TextBox from "../../../../elements/TextBox";
import ActivityRisks from "./ActivityRisks";
import { riskLevel } from "../Constants";
import { CustActivityRisks } from "../../../../JSONs/InternalUser/Chapter1";
import { CustActivityRisksMalta } from "../../../../JSONs/MaltaForm/Internal/Chapter1";
import CheckBox from "../../../../elements/CheckBox";

class Risks extends React.Component {
  render() {
    const {
      chapter,
      reducer,
      numCustActivityRisks,
      isRisk,
      errors,
      isRequired,
    } = this.props;

    let errorsMsg = JSON.parse(errors);

    let risks = [];
    for (let i = 0; i < numCustActivityRisks; i++) {
      risks.push(
        <ActivityRisks
          index={i}
          form={this.props.form}
          errorsMsg={errors}
          baseName="custActivityRisks"
          reducer={reducer}
          chapter={chapter}
          boxLabel={this.props.boxLabel}
          isRequired={isRequired}
          PdfGeneration={this.props.PdfGeneration}
        />
      );
    }

    return (
      <>
        <Row className="mb-4">
          <div className="col-lg-12">  
            <CheckBox
              label={this.props.form == "NL" & chapter=="chapter2" ? "Are there any Intermediary/ Feeder risks which are identified?" :
              this.props.form == "NL" & chapter=="chapter3" ? "Are there any UPC/UBO related risks which are identified?":
              this.props.form == "NL" & chapter=="chapter4" ? "Are there any object company risks which are identified?":
              this.props.form == "NL" & chapter=="chapter5" ? "Are there any country/sanction risks which are identified?" :
              this.props.form == "NL" & chapter=="chapter6" ? "Are there any services risks which are identified?" :
              "Are there any customer activity risks which are identified?"}
              options={["Yes", "No"]}
              name="isRisk"
              id="isRisk"
              chapter={chapter}
              reducer={reducer}
              isRequired={isRequired}
              isMultiple={true}             
            />
            {errorsMsg &&
              errorsMsg.isSubmitted === true &&
              errorsMsg.isRisk?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isRisk}
                </span>
              )}
          </div>
        </Row>
        {isRisk === "Yes" && (
          <Row className="mb-2">
            <div className="col-lg-4">
              <NumberInput
                label={this.props.questionLabel}
                name="numCustActivityRisks"
                chapter={chapter}
                reducer={reducer}
                referral={["custActivityRisks"]}
                pushedObject={
                  this.props.form === "Malta"
                    ? [CustActivityRisksMalta]
                    : [CustActivityRisks]
                }
                maxLimit={100}
              />
              <div className="errorMessage"></div>
            </div>

            <div className="col-lg-8">
              <Row className="mb-0">{risks}</Row>

              {this.props.form !== "Malta" && (
                <Row className="mb-3">
                  <div
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "col-lg-6 mb-2"
                        : "col-lg-12 mb-2"
                    }
                  >
                    <DropDown
                      label={this.props.riskLabel}
                      chapter={chapter}
                      name="custActvtyRskRtng"
                      id="custActvtyRskRtng"
                      options={riskLevel}
                      reducer={reducer}
                      isRequired={isRequired}
                      isMultipleLines={true}
                    />
                    {errorsMsg &&
                      errorsMsg.isSubmitted === true &&
                      errorsMsg.custActvtyRskRtng?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errorsMsg.custActvtyRskRtng}
                        </span>
                      )}
                  </div>
                  <div
                    className={
                      this.props.PdfGeneration === "Yes"
                        ? "col-lg-6 mb-0"
                        : "col-lg-12 mb-0"
                    }
                  >
                    <TextBox
                      label="Description of actions which still need to be taken or fill in 'None'"
                      chapter={chapter}
                      name="descActnNeedsTkn"
                      placeholder="Enter your text"
                      reducer={reducer}
                      id="descActnNeedsTkn"
                      validationType="Text"
                      isRequired={isRequired}
                      isMultipleLines={true}
                      className={
                        this.props.PdfGeneration === "Yes"
                          ? "txtArea"
                          : "form-control"
                      }
                    />
                    {errorsMsg &&
                      errorsMsg.isSubmitted === true &&
                      errorsMsg.descActnNeedsTkn?.length > 0 && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errorsMsg.descActnNeedsTkn}
                        </span>
                      )}
                   {this.props.form == "NL" && (
                     <TextBox
                     label="Risk analysis"
                     info="If the activities are in line with the UIM client acceptance policy please add the following line:
                     With respect to the structure, the activities and the sector, UIM sees no reason to discontinue the set-up of a business relationship."
                     chapter={chapter}
                     name="descRiskAnalysis"
                     placeholder="Enter your text"
                     reducer={reducer}
                     id="descRiskAnalysis"
                     validationType="Text"
                     isRequired={isRequired}
                     isMultipleLines={true}
                     className={
                       this.props.PdfGeneration === "Yes"
                         ? "txtArea"
                         : "form-control"
                     }
                   />
                    )}
                  </div>
                 
                </Row>
              )}
            </div>
          </Row>
        )}
         {isRisk === "Yes" && (<div className="html2pdf__page-break"></div>)}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { chapter, reducer } = ownProps;
  return {
    isRisk: state[reducer][chapter]["isRisk"],
    numCustActivityRisks: state[reducer][chapter]["numCustActivityRisks"],
  };
};

export default connect(mapStateToProps, null)(Risks);
