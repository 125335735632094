export const Chapter12 = {
  isReasonTigCliInvstg: null,
  isBusAccepAccClnt: null,
  reserveBusAccepComite: null,
  descReasDueDilignce: null,
  isstructOvrvwClr: null,
  isclientsIdntVrfd: null,
  isuboClssfctnIdntVrfctn: null,
  issrcWlthClr: null,
  ischckPEP: null,
  isobjPurObjCompClr: null,
  isTrnsPrflClr: null,
  isPurStrctrngKnwRecd: null,
  isSancRulChckd: null,
  isRelCorrespndceAdded: null,
  consolidtdRskObjComp: null,
  netRskRskCompliance: null,
  dateStrtOfSrvcs: null,
  dirty: false,
};
