import React, { Component } from "react";
import { Switch } from "react-router-dom";
import InternalUserRoutes from "../../../../routes/InternalUserRoutes";

import Chapter1 from "../LuxembourgOffice/Chapter1/Chapter1";
import Chapter2 from "../LuxembourgOffice/Chapter2/Chapter2";
import Chapter3 from "../LuxembourgOffice/Chapter3/Chapter3";
import Chapter4 from "../LuxembourgOffice/Chapter4/Chapter4";
import Chapter5 from "../LuxembourgOffice/Chapter5/Chapter5";
import Chapter6 from "../LuxembourgOffice/Chapter6/Chapter6";
import Chapter8 from "../LuxembourgOffice/Chapter8/Chapter8";
import Chapter9 from "../LuxembourgOffice/Chapter9/Chapter9";
import Chapter10 from "../LuxembourgOffice/Chapter10/Chapter10";
import Chapter11 from "../LuxembourgOffice/Chapter11/Chapter11";
import Chapter12 from "../LuxembourgOffice/Chapter12/Chapter12";
import Chapter13 from "../LuxembourgOffice/Chapter13/Chapter13";

class LuxembourgInternalDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  handleChange(e) {
    this.props.handleOfficeChange(e);
  }
  render() {
    return (
      <Switch>
        <InternalUserRoutes
          path="/luxembourg/client-activity"
          component={Chapter1}
        />

        <InternalUserRoutes
          path="/luxembourg/client-investigation"
          component={Chapter2}
        />
        <InternalUserRoutes path="/luxembourg/ubo-upc" component={Chapter3} />
        <InternalUserRoutes
          path="/luxembourg/object-company"
          component={Chapter4}
        />
        <InternalUserRoutes
          path="/luxembourg/country-sanction-risks"
          component={Chapter5}
        />
        <InternalUserRoutes path="/luxembourg/agreement" component={Chapter6} />
        <InternalUserRoutes
          path="/luxembourg/consolidated-risk-assessment"
          component={Chapter8}
        />
        <InternalUserRoutes
          path="/luxembourg/correspondence"
          component={Chapter9}
        />
        <InternalUserRoutes
          path="/luxembourg/miscellaneous"
          component={Chapter10}
        />

        <InternalUserRoutes
          path="/luxembourg/summary-sheet-caf"
          component={Chapter11}
        />

        <InternalUserRoutes
          path="/luxembourg/formal-sign-off"
          component={Chapter12}
        />
        <InternalUserRoutes
          path="/luxembourg/signature"
          component={Chapter13}
        />
      </Switch>
    );
  }
}

export default LuxembourgInternalDashboard;
