import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../../../elements/CheckBox";
import TextBox from "../../../../../../elements/TextBox";
import SourceOfWealth from "../../../ubo-declaration-uim/PartB/SourceOfWealth";

class DeclarationSoW extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { index, grossAnnualIncome, errorMsg } = this.props;
    const eMsg =
      errorMsg !== null || errorMsg !== undefined ? JSON.parse(errorMsg) : null;
    return (
      <>
        <h4 className="sub-chapter-title mb-4">
          Declaration of the source of wealth of the UBO
        </h4>
        <Row className="pt-1 pb-2">
          <div className="mb-4 col-lg-12 wealthDeclaration">
            <CheckBox
              label="What is your gross annual Income (please tick)"
              options={["< EUR 500.000", "EUR 500.000 – 1 Mln", "> EUR 1 Mln","N/A"]}
              name="grossAnnualIncome"
              id={`grossAnnualIncome[${index}]`}
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={index}
              baseName={this.props.baseName}
              objBaseName={this.props.objBaseName}
              isRequired={this.props.form === "UK" ? true : false}  
            />
            {eMsg !== null &&
              Object.entries(eMsg).length !== 0 &&
              eMsg.ubox &&
              eMsg.ubox[index] &&
              eMsg.ubox[index]?.grossAnnualIncome?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {eMsg.ubox[index]?.grossAnnualIncome}
                </span>
              )}
          </div>
          {grossAnnualIncome === "> EUR 1 Mln" && (
            <div className="mt-0 mb-3 col-lg-12">
              <TextBox
                label="please state here your annual income if it is above EUR 1 Mln"
                placeholder="enter annual income"
                reducer={this.props.reducer}
                name="anualIncmAbvEUR1Mln"
                id={`anualIncmAbvEUR1Mln[${index}]`}
                chapter={this.props.chapter}
                index={index}
                baseName={this.props.baseName}
                objBaseName={this.props.objBaseName}
                isRequired={this.props.form === "UK" ? true : false}
              />
              {eMsg !== null &&
                Object.entries(eMsg).length !== 0 &&
                eMsg.ubox &&
                eMsg.ubox[index] &&
                eMsg.ubox[index]?.anualIncmAbvEUR1Mln?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {eMsg.ubox[index]?.anualIncmAbvEUR1Mln}
                  </span>
                )}
            </div>
          )}
          <div className="mb-3 col-md-12 rangeCheckbox">
            <CheckBox
              label=" What is the range of your wealth?"
              options={[
                "< EUR 500.000",
                "EUR 500.000 – 1 Mln",
                "EUR 1 Mln – 5 Mln",
                "EUR 5 Mln – 20 Mln",
                "EUR 20 Mln – 100 Mln",
                "EUR > 100 Mln",
              ]}
              id="wealthRange"
              name="wealthRange"
              id={`wealthRange[${index}]`}
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={index}
              baseName={this.props.baseName}
              objBaseName={this.props.objBaseName}
              isRequired={this.props.form === "UK" ? true : false}
            />
            {eMsg !== null &&
              Object.entries(eMsg).length !== 0 &&
              eMsg.ubox &&
              eMsg.ubox[index] &&
              eMsg.ubox[index]?.wealthRange?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {eMsg.ubox[index]?.wealthRange}
                </span>
              )}
          </div>
          <div className="mb-3 col-md-12 rangeCheckbox">
            <SourceOfWealth
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={index}
              errorMsg={errorMsg}
              baseName={this.props.baseName}
              objBaseName={this.props.objBaseName}
              form={this.props.form}
            />
          </div>
          {/* <div className="mb-3 col-lg-4">
            <TextBox
              label="Location"
              placeholder="Enter location"
              name="location"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={index}
              baseName={this.props.baseName}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
          </div>
          <div className="mb-3 col-lg-4">
            <TextBox
              label="Remitter Name"
              placeholder="Enter remitter name"
              name="remitterName"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={index}
              baseName={this.props.baseName}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
          </div>
          <div className="mb-3 col-lg-12">
            <TextBox
              label="Address"
              placeholder="Enter address"
              name="address"
              chapter={this.props.chapter}
              reducer={this.props.reducer}
              index={index}
              baseName={this.props.baseName}
              objBaseName={this.props.objBaseName}
              validationType="Text"
            />
          </div> */}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { reducer, chapter, index } = ownProps;
  return {
    grossAnnualIncome:
      state[reducer][chapter]["ubo"]["ubox"][index]["grossAnnualIncome"],
  };
};

export default connect(mapStateToProps, null)(DeclarationSoW);
