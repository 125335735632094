import React, { Component } from "react";
import ClientInformationDashboard from "./client-information/ClientInformationDashboard";
import UKFormDashboard from "./UKOffice/Client-Information-Form/UKFormDashboard";
import CuracaoOfficeDashboard from "./CuracaoOffice/Client-Information-Form/CuracaoOfficeDashboard";
import MaltaOfficeDashboard from "./Malta/Client-Information-Form/MaltaOfficeDashboard";
import LuxembourgDashboard from "./Luxembourg/Client-Information-form/LuxembourgDashboard";

class OfficeFormSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      office: localStorage.getItem("Office"),
    };
  }
  render() {
    switch (this.state.office) {
      case "Netherlands":
        return <ClientInformationDashboard />;
      case "United Kingdom":
        return <UKFormDashboard />;
      case "Curcao":
        return <CuracaoOfficeDashboard />;
      case "Curacao":
        return <CuracaoOfficeDashboard />;
      case "Malta":
        return <MaltaOfficeDashboard />;
      case "Luxembourg":
        return <LuxembourgDashboard />;
      default:
        return null;
    }
  }
}

export default OfficeFormSelection;
