import { AdditionDocs } from "./../../InternalUser/Chapter1";
import { CustActivityRisksMalta } from "./Chapter1";

const UpcApprovals = {
  isPassportCopyApprvd: null,
  isUtilityBillsApprvd: null,
  isExtCOCApprvd: null,
  isDeedOfIncorpoApprvd: null,
  isShareholdersRegisterApprvd: null,
  isshareholdersCertificatesApprvd: null,
  isNomineeAgreementApprvd: null,
  isFinStatmntsThreeYrsApprvd: null,
};

export const UboApprovals = {
  uboId: null,
  isApproved: null,
  isIdntifiedVerifiedByUIM: null,
  verifiedName: null,
  legalEntityName: null,
  dateOfNotary: null,
};

let array = [];
for (let i = 0; i < 10; i++) {
  array.push(UboApprovals);
}

export const Chapter3 = {
  isHldngIntrstCmmnt: null,
  isUBOClsifiedCmmnt: null,
  isAsstClientCmmnt: null,
  noUBOClassifiedCmmnt: null,
  shareHolderCmnt: null,
  uboApproval: array,
  upcApproval: UpcApprovals,
  pascalReportUpload: [],
  isMediaCoverageOnStruct: null,
  mediaCmnt: null,
  isAddDocs: null,
  numAddDocs: 1,
  addDocs: [AdditionDocs],
  isRisk: null,
  numCustActivityRisks: 1,
  custActivityRisks: [CustActivityRisksMalta],
  isFindings: null,
  isMRZPassport: null,
  isUBOresultIncrsdIntgrtyRsk: null,
  ubOresultIncrsdIntgrtyRskCmmnt: null,
  dirty: false,
};
