import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import CheckBox from "../../../../elements/CheckBox";
import TextBox from "../../../../elements/TextBox";

class Services extends React.Component {
  render() {
    const { reducer, chapter, errors, isRequired, isUIMPrvdOthSvc, form } =
    this.props;
  const errorsMsg = JSON.parse(errors);
    return (
      <>
        <h4 className="sub-chapter-title pt-4 mb-2">Services</h4>
        <Row className="pt-3 pb-2">
        {form === "NL" &&(
        <div className="mb-2 col-lg-3">
            <CheckBox
              label="Domiciliation services"             
              options={["Yes", "No"]}
              name="isDomiciliationservices"
              id="isDomiciliationservices"
              reducer={reducer}
              chapter={chapter}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isDomiciliationSvc?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isDomiciliationSvc}
                </span>
              )}
          </div>
          )}
          <div className="mb-2 col-lg-3">
            <CheckBox
              label={
                form === "Malta"
                  ? "Registeted office services"
                  : "Domiciliation sec services"
              }
              info="providing an address or postal address to a legal entity or partnership on the instruction of a third party that does not belong to the same group as UIM, without conducting any additional activity"
              options={["Yes", "No"]}
              name="isDomiciliationSvc"
              id="isDomiciliationSvc"
              // name="isDomiciliationSecServcs"
              // id="isDomiciliationSecServcs"
              isRequired={isRequired}
              reducer={reducer}
              chapter={chapter}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isDomiciliationSecServcs?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isDomiciliationSecServcs}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-3">
            <CheckBox
              label="Management services"
              options={["Yes", "No"]}
              name="isManagementSvc"
              id="isManagementSvc"
              // name="isMngmntSecServcs"
              // id="isMngmntSecServcs"
              isRequired={isRequired}
              reducer={reducer}
              chapter={chapter}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isMngmntSecServcs?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isMngmntSecServcs}
                </span>
              )}
          </div>
          <div className="mb-2 col-lg-3">
            <CheckBox
              label="Administration services"
              options={["Yes", "No"]}
              name="isAdministrationSvc"
              id="isAdministrationSvc"
              // name="isAdministrtnSecServcs"
              // id="isAdministrtnSecServcs"
              isRequired={isRequired}
              reducer={reducer}
              chapter={chapter}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isAdministrtnSecServcs?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isAdministrtnSecServcs}
                </span>
              )}
          </div>
        </Row>
        <Row className="pt-3 pb-2">
          <div className="mb-2 col-lg-3">
            <CheckBox
              label="Does UIM provide other services?"
              options={["Yes", "No"]}
              name="isUIMPrvdOthSvc"
              id="isUIMPrvdOthSvc"
              reducer={reducer}
              chapter={chapter}
              isRequired={isRequired}
            />
            {errorsMsg &&
              errorsMsg?.isSubmitted === true &&
              errorsMsg?.isUIMPrvdOthSvc?.length > 0 && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorsMsg.isUIMPrvdOthSvc}
                </span>
              )}
          </div>
          {isUIMPrvdOthSvc === "Yes" && (
            <div className="mb-2 col-lg-8">
              <TextBox
                label="Explanation other of services(e.g. lease office space)"
                placeholder="Enter the text"
                name="explanationOthSvc"
                id="explanationOthSvc"
                reducer={reducer}
                chapter={chapter}
                isRequired={isRequired}
                className={
                  this.props.PdfGeneration === "Yes" ? "txtBox" : "form-control"
                }
              />
              {errorsMsg &&
                errorsMsg?.isSubmitted === true &&
                errorsMsg?.explanationOthSvc?.length > 0 && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsMsg.explanationOthSvc}
                  </span>
                )}
            </div>
          )}
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const { reducer, chapter } = ownProps;
  return {
    isUIMPrvdOthSvc: state[reducer][chapter]["isUIMPrvdOthSvc"],
  };
};

export default connect(mapStateToProps, null)(Services);
//export default Services;
