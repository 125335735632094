import React, { Component } from "react";
import { connect } from "react-redux";
import { setUKInternalCAFDetails } from "../../../../redux/actions/UK/UKInternalCAFActions";
import { setUKInternalChapterError } from "../../../../redux/actions/UK/UKInternalChapterErrorActions";
import getSequentialFields from "./getSequentialFields";
class AutoSaving extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  apiCall = () => {
    //api call for chapters data

    let requestData = {};
    let apiCallFlag = false;
    let chapter = this.props.chapter;
    let data = this.props.ClientAcceptance[chapter];

    if (data["dirty"] === true) {
      apiCallFlag = true;
      delete data["dirty"];
    }
    if (apiCallFlag) {
      let user_data = JSON.parse(localStorage.getItem("formData"));
      if(user_data){
        requestData["id"] = user_data.id;
        requestData["userId"] = user_data.userId;
        requestData[chapter] = data;
      }
      this.props.setUKInternalCAFDetails(requestData).then(async (res) => {
        if (res.data.portalErrorCode === 200) {
          this.props.ClientAcceptance[this.props.chapter]["dirty"] = false;
          let response = res.data?.result;

          if (
            Object.keys(
              this.props.internalErrors[`formErrors${chapter}`]?.errors
            ).length > 0 &&
            this.props.internalErrors[`formErrors${chapter}`]?.errors
              ?.isSubmitted === true
          ) {
            const newResponse = await Promise.resolve(
              getSequentialFields(response, chapter, true)
            );
            this.props.setUKInternalChapterError({
              form: `formErrors${this.props.chapter}`,
              errors: newResponse?.errorChapter,
            });
          } else {
            const newRes = new Promise((resolve) =>
              resolve(getSequentialFields(response, chapter, false))
            );
            const newResponse = await newRes;
            this.props.setUKInternalChapterError({
              form: `formErrors${this.props.chapter}`,
              errors: newResponse?.errorChapter,
            });
          }
        }
      });
    }
  };

  componentDidMount = () => {
    this.apiCall();
    setInterval(this.apiCall, 5000);
  };

  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    ClientAcceptance: state.UKInternalCAF,
    internalErrors: state.UKInternalChapterError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUKInternalCAFDetails: async (data) =>
      dispatch(await setUKInternalCAFDetails(data)),
    setUKInternalChapterError: async (data) =>
      dispatch(await setUKInternalChapterError(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AutoSaving);
