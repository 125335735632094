import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "react-calendar/dist/Calendar.css";
import "../../../../elements/DateTimePicker.css";
import DatePicker from "react-date-picker";

import { setDataToReducer } from "../../../../../redux/actions/ClientInformationAction";
import countryList from "react-select-country-list";
import Select from "react-select";
import moment from "moment";
import Modal from "../../../../components/shared/Modal";
import { getReducer } from "../../../../elements/getReducer";
import cc from "currency-codes";

let currencies = [];
cc.codes().map((currency) => currencies.push({ label: currency }));

class FamilyAndAnuualIncome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      id: null,
      relation: null,
      fullName: null,
      dob: null,
      pob: null,
      cob: null,
      passportNo: null,
      currency: null,
      annualIncome: null,
      errors: {},
      toggleCleared: false,
      selectedRows: [],
      addForm: false,
      columns: [
        {
          id: 1,
          name: "Relation",
          selector: (row) => row.relation,
        },
        {
          id: 2,
          name: "Full name",
          selector: (row) => row.fullName,
        },
        {
          id: 3,
          name: "Date of birth",
          selector: (row) => <div>{moment(row.dob).format("DD-MM-YYYY")}</div>,
        },
        {
          id: 4,
          name: "Place of birth",
          selector: (row) => row.pob,
        },
        {
          id: 5,
          name: "Country of birth",
          selector: (row) => row.cob,
        },
        {
          id: 6,
          name: "Passport Number",
          selector: (row) => row.passportNo,
        },
        {
          id: 7,
          name: "Currency",
          selector: (row) => row.currency,
        },
        {
          id: 8,
          name: "Annual Income",
          selector: (row) => row.annualIncome,
        },
        {
          id: 9,
          title: "",
          cell: (row) => (
            <div className="Edit-Btn">
              <button
                type="button"
                className="action-btn edit"
                onClick={() => this.handleEdit(row)}
              >
                Edit
              </button>
            </div>
          ),
        },
      ],
    };
  }

  modalOpen = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  modalClose = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  cancelForm = () => {
    this.setState({
      isEdit: false,
      addForm: false,
    });
  };

  handleAddForm = () => {
    this.setState({
      id: null,
      relation: null,
      fullName: null,
      dob: null,
      pob: null,
      cob: null,
      passportNo: null,
      currency: null,
      annualIncome: null,
      isEdit: false,
      addForm: true,
    });
  };

  clearInputs = () => {
    this.setState({
      id: null,
      relation: null,
      fullName: null,
      dob: null,
      pob: null,
      cob: null,
      passportNo: null,
      currency: null,
      annualIncome: null,
    });
  };

  handleDateChange = (value) => {
    this.setState({ dob: value });
  };

  handleEdit = (row) => {
    this.setState({
      isEdit: true,
      addForm: true,
      id: row.id,
      relation: row.relation,
      fullName: row.fullName,
      dob: row.dob,
      pob: row.pob,
      cob: row.cob,
      passportNo: row.passportNo,
      currency: row.currency,
      annualIncome: row.annualIncome,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });

    this.setState({
      errors: {
        ...this.state.errors,
        [name]: "",
      },
    });
  };

  handleCountryChange = (e) => {
    this.setState({ cob: e.label });
    this.setState({
      errors: {
        ...this.state.errors,
        cob: "",
      },
    });
  };

  handleCurrencyChange = (e) => {
    this.setState({ currency: e.label });
    this.setState({
      errors: {
        ...this.state.errors,
        currency: "",
      },
    });
  };

  handleRowSelection = (selectedRows) => {
    this.setState({ selectedRows: selectedRows });
  };

  validateForm = () => {
    let errors = {};
    let formIsValid = true;
    const msg = "Please fill this details";
    if (!this.state.relation) {
      formIsValid = false;
      errors["relation"] = msg;
    }
    if (!this.state.fullName) {
      formIsValid = false;
      errors["fullName"] = msg;
    }
    if (!this.state.dob) {
      formIsValid = false;
      errors["dob"] = msg;
    }
    if (this.state.dob) {
      if (this.state.dob > new Date()) {
        formIsValid = false;
        errors["dob"] = "Date should be less than current date";
      }
    }
    if (!this.state.pob) {
      formIsValid = false;
      errors["pob"] = msg;
    }
    if (!this.state.cob) {
      formIsValid = false;
      errors["cob"] = msg;
    }
    if (!this.state.passportNo) {
      formIsValid = false;
      errors["passportNo"] = msg;
    }
    if (this.state.passportNo) {
      var text = /[^a-zA-Z0-9\s]/;
      if (String(this.state.passportNo).match(text)) {
        formIsValid = false;
        errors["passportNo"] = "Please enter valid passport number";
      }
    }
    if (!this.state.currency) {
      formIsValid = false;
      errors["currency"] = msg;
    }
    if (!this.state.annualIncome) {
      formIsValid = false;
      errors["annualIncome"] = msg;
    }
    if (this.state.annualIncome) {
      var numbers = /^\d+(\.\d{1,2})?$/;
      if (!String(this.state.annualIncome).match(numbers)) {
        formIsValid = false;
        errors["annualIncome"] = "Please enter valid income";
      }
    }
    this.setState({
      errors,
    });
    return formIsValid;
  };

  handleSubmit = async () => {
    let array = this.props.familyAnnualIncome;

    if (this.validateForm()) {
      if (this.state.isEdit) {
        const index = array.findIndex((f) => f.id === this.state.id);
        let obj = array[index];
        obj = {
          ...obj,
          relation: this.state.relation,
          fullName: this.state.fullName,
          dob: this.state.dob,
          pob: this.state.pob,
          cob: this.state.cob,
          passportNo: this.state.passportNo,
          currency: this.state.currency,
          annualIncome: this.state.annualIncome,
        };
        array[index] = obj;
      } else {
        let Id =
          array.length === 0
            ? 1
            : Math.max(
                ...array.map(function (i) {
                  return i.id;
                })
              ) + 1;
        let obj = {
          id: String(Id),
          relation: this.state.relation,
          fullName: this.state.fullName,
          dob: this.state.dob,
          pob: this.state.pob,
          cob: this.state.cob,
          passportNo: this.state.passportNo,
          currency: this.state.currency,
          annualIncome: this.state.annualIncome,
        };
        array.push(obj);
      }
      this.props.setDataToReducer({
        type: getReducer(this.props.reducer),
        name: "familyAnnualIncome",
        chapter: "partB",
        value: array,
      });
      this.clearInputs();
      this.cancelForm();
    }
  };

  handleDelete = () => {
    this.modalClose();
    let array = this.props.familyAnnualIncome;
    let selectedRows = this.state.selectedRows.selectedRows;
    let IdArray = [];
    selectedRows.map((obj) => {
      IdArray.push(obj.id);
    });
    let newArray = array.filter((obj) => !IdArray.includes(obj.id));
    this.props.setDataToReducer({
      type: getReducer(this.props.reducer),
      name: "familyAnnualIncome",
      chapter: "partB",
      value: newArray,
    });
    this.setState({
      selectedRows: [],
      toggleCleared: !this.state.toggleCleared,
    });
  };

  render() {
    const { selectedRows, addForm, errors, toggleCleared, currency } =
      this.state;
    const errorMsg = JSON.parse(this.props.errorMsg);
    const selectedCountry = {
      label: this.state.cob,
    };
    let selectedVal;
    if (this.state.dob) {
      selectedVal = new Date(this.state.dob);
    } else {
      selectedVal = null;
    }

    return (
      <>
        <h2 className="chapter-title mt-4">
          Family & Annual Income Information of PEP
        </h2>
        <Container fluid className="familyincomePDf">
          <Row>
            <Col sm={6} className="text-left d-flex">
              {selectedRows?.selectedRows?.length > 0 && (
                <button className="action-btn delete" onClick={this.modalOpen}>
                  Delete
                </button>
              )}
            </Col>
            {!this.props.classDisplayFlag && (
              <Col sm={6} className="text-right add-button">
                <Link
                  to="#"
                  className="action-btn big addRightBtn"
                  onClick={this.handleAddForm}
                >
                  Add Family Member
                </Link>
              </Col>
            )}
          </Row>
        </Container>
        <div style={{ width: "100%" }} className="familyIncomeList">
          <DataTable
            //title="Provide family information as applicable"
            columns={this.state.columns}
            data={[...this.props.familyAnnualIncome]}
            selectableRows
            pagination
            onSelectedRowsChange={this.handleRowSelection}
            clearSelectedRows={toggleCleared}
          />
        </div>
        {errorMsg && errorMsg?.partB?.familyAnnualIncome?.length > 0 && (
          <span style={{ color: "red", fontSize: "14px" }}>
            {errorMsg?.partB?.familyAnnualIncome}
          </span>
        )}

        {addForm && (
          <div className="addFormForCAF mt-3">
            <div className="m-auto pt-3">
              <Row>
                <div className="mb-2 col-lg-4">
                  <label>Relation</label>
                  <input
                    type="text"
                    name="relation"
                    className="form-control"
                    placeholder="enter relation"
                    onChange={this.handleChange}
                    value={this.state.relation}
                  ></input>
                  <div className="errorMsg">{errors.relation}</div>
                </div>
                <div className="mb-2 col-lg-4">
                  <label>Full name</label>
                  <input
                    type="text"
                    name="fullName"
                    className="form-control"
                    placeholder="enter fullname"
                    onChange={this.handleChange}
                    value={this.state.fullName}
                  ></input>
                  <div className="errorMsg">{errors.fullName}</div>
                </div>
                <div className="mb-2 col-lg-4">
                  <label>Date of birth</label>
                  <DatePicker
                    name="dob"
                    //className="form-control"
                    onChange={(value) => this.handleDateChange(value)}
                    value={selectedVal}
                    maxDate={new Date()}
                  />
                  <div className="errorMsg">{errors.dob}</div>
                </div>
                <div className="mb-2 col-lg-4">
                  <label>Place of birth</label>
                  <input
                    type="text"
                    name="pob"
                    className="form-control"
                    placeholder="enter place of birth"
                    onChange={this.handleChange}
                    value={this.state.pob}
                  ></input>
                  <div className="errorMsg">{errors.pob}</div>
                </div>

                <div className="mb-2 col-lg-4">
                  <label>Country of birth</label>
                  <Select
                    options={countryList().getData()}
                    value={
                      !selectedCountry.label
                        ? selectedCountry.label
                        : selectedCountry
                    }
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.label}
                    onChange={this.handleCountryChange}
                    placeholder={
                      !selectedCountry.label && <div>select country</div>
                    }
                  />
                  <div className="errorMsg">{errors.cob}</div>
                </div>
                <div className="mb-2 col-lg-4">
                  <label>Passport Number</label>
                  <input
                    type="text"
                    name="passportNo"
                    className="form-control"
                    placeholder="enter passport number"
                    onChange={this.handleChange}
                    value={this.state.passportNo}
                  ></input>
                  <div className="errorMsg">{errors.passportNo}</div>
                </div>
                <div className="mb-2 col-lg-4">
                  <label>Currency</label>
                  <Select
                    options={currencies}
                    value={currency ? currency : ""}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.label}
                    onChange={this.handleCurrencyChange}
                    placeholder={
                      currency ? currency : <div>select currency</div>
                    }
                  />
                  <div className="errorMsg">{errors.currency}</div>
                </div>
                <div className="mb-2 col-lg-4">
                  <label>Annual Income</label>
                  <input
                    type="text"
                    name="annualIncome"
                    className="form-control"
                    placeholder="Enter your Annual Income"
                    onChange={this.handleChange}
                    value={this.state.annualIncome}
                  ></input>
                  <div className="errorMsg">{errors.annualIncome}</div>
                </div>
              </Row>
            </div>
            <Row className="pt-4 pb-2 text-center">
              <div className="mb-2 col-lg-12 ">
                <button
                  type="button"
                  className="action-btn noicon pl-3 pr-3"
                  onClick={this.handleSubmit}
                >
                  {this.state.isEdit ? "Edit Row" : "Add Row"}
                </button>
                <button
                  type="button"
                  className="action-btn link noicon pl-4 pr-4"
                  onClick={this.cancelForm}
                >
                  Cancel
                </button>
              </div>
            </Row>
          </div>
        )}
        <Modal
          show={this.state.isModalOpen}
          handleClose={(e) => this.modalClose(e)}
        >
          <h4 className="mb-4">Are you sure you want to delete?</h4>

          <div className="form-group mb-0">
            <button
              onClick={(e) => this.handleDelete(e)}
              type="button"
              className="action-btn noicon mr-2"
            >
              Yes
            </button>
            <button
              onClick={this.modalClose}
              type="button"
              className="action-btn greybtn noicon"
            >
              No
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDataToReducer: (data) => dispatch(setDataToReducer(data)),
  };
};
const mapStateToProps = (state, ownProps) => {
  const { reducer } = ownProps;
  return {
    familyAnnualIncome: state[reducer]["partB"]["familyAnnualIncome"],
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FamilyAndAnuualIncome);
