import React, { Component } from "react";
import InternalFileUpload from "../../../../elements/InternalFileUpload";
import { chapValidationError, fileAcceptType } from "../Constants";
import MediaCoverage from "../Chapter1/MediaCoverage";
import NumberInput from "../../../../elements/NumberInput";
import CheckBox from "../../../../elements/CheckBox";
import {
  GetClientAcceptance,
  SetClientAcceptance,
} from "../../../../../redux/actions/ClientAcceptanceFormActions";
import "./../internalCAF.scss";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import Intermediary from "./Intermediary";
import TextBox from "../../../../elements/TextBox";
import Risks from "../Chapter1/Risks";
import AutoSaving from "./../AutoSaving";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { setInternalChapterError } from "../../../../../redux/actions/InternalChapterErrorActions";
import getSequentialFields from "../getSequentialFields";
import { IntermediaryObj } from "../../../../JSONs/InternalUser/Chapter2";

const chapter = "chapter2";
const reducer = "ClientAcceptance";
let timer;
class Chapter2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formErrorsChapter2: {},
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    let formData = JSON.parse(localStorage.getItem("formData"));
    let reqData = {
      id: formData.id,
      userId: formData.userId,
      chapter: chapter,
    };
    this.setState({ isLoading: true });
    if (this.props.PdfGeneration !== "Yes") {
      this.setState({ isLoading: false });
      this.props.GetClientAcceptance(reqData).then(async (res) => {
        if (
          res.data.statusCode === 200 &&
          Object.keys(this.props.errors.errors).length === 0 &&
          res?.data?.result?.cafInternalValidations !== null
        ) {
          chapValidationError.forEach(async (element) => {
            if (element.chapter !== "chapter12") {
              let response =
                res?.data?.result?.cafInternalValidations[element.chapter];

              const newResponse = await Promise.resolve(
                getSequentialFields(response, element.chapter, false)
              );
              this.props.setInternalChapterError({
                form: `formErrors${element.chapter}`,
                errors: newResponse?.errorChapter,
              });
            }
          });
        }
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  handleSubmit = async () => {
    let requestData = {};
    let data = this.props.ClientAcceptance[chapter];
    delete data["dirty"];
    let user_data = JSON.parse(localStorage.getItem("formData"));
    requestData["id"] = user_data.id;
    requestData["userId"] = user_data.userId;
    requestData[chapter] = data;
    requestData["commonFields"] = this.props.commonFields;

    this.props.SetClientAcceptance(requestData).then(async (res) => {
      if (res.data.portalErrorCode === 200) {
        this.props.ClientAcceptance.chapter2["dirty"] = false;
        let response = res.data.result;

        const newResponse = await Promise.resolve(
          getSequentialFields(response, chapter, true)
        );

        this.props.setInternalChapterError({
          form: "formErrorschapter2",
          errors: newResponse?.errorChapter,
        });
        if (!res.data.result.isValid) {
          NotificationManager.info(
            "All fields are mandatory to fill in",
            null,
            4000
          );
          timer = setTimeout(() => {
            return this.props.history.push({
              pathname: "/netherlands/ubo-upc",
            });
          }, 4000);
        } else {
          NotificationManager.success("Form is submitted successfully.");

          return this.props.history.push({
            pathname: "/netherlands/ubo-upc",
          });
        }
      }
    });
  };

  render() {
    const {
      isClientRepInvolved,
      clientRepNum,
      clientRepNumInter,
      isClientTransfrd,
      isPascalReport,
      isIncreasedIntegrityRisk,
      isClientRepInvolvedInter,
      isUIMnoResonIntermediaries,
      errors,
    } = this.props;
    const formErrorsChapter2 = JSON.stringify(errors.errors);
    const errorsMsg = JSON.parse(formErrorsChapter2);
    let intermediaries = [];
    for (let i = 0; i < clientRepNum; i++) {
      intermediaries.push(
        <Intermediary
          index={i}
          baseName="intermediaryDetails"
          reducer="clientInformation"
          chapter={chapter}
          errors={formErrorsChapter2}
          form="NL"
        />
      );
    }
    let intermediariesInter = [];
    for (let i = 0; i < clientRepNumInter; i++) {
      intermediariesInter.push(
        <Intermediary
          index={i}
          baseName="intermediaryDetails"
          reducer={reducer}
          chapter={chapter}
          errors={formErrorsChapter2}
          form="NL"
        />
      );
    }
    return (
      <div className="dashboard">
        <AutoSaving chapter={chapter} />
        <div className="userlist">
          <div className="cafHeader">
            <h2 className="m-0">Client Acceptance Form </h2>
          </div>
          <div className="internal_caf generic-form">
            <div className="html2pdf__page-break"></div>
            <h3 className="chapterttl">
              2-Intermediary/ Feeder
              {/* Client Investigation - Client Relationship (intermediary); Feeder */}
            </h3>
            <Row className="mb-3">
              <div className="col-lg-4">
              {isClientRepInvolved && (
                <CheckBox
                  label="Are relevant intermediaries/client representatives involved in the structure?"
                  options={["Yes", "No"]}
                  defaultValue={isClientRepInvolved}
                  name="isClientRepInvolved"
                  chapter="chapter2"
                  reducer="clientInformation"
                  disabled={true}
                />
              )}
               {!isClientRepInvolved && (
               <CheckBox
                  label="Are relevant intermediaries/client representatives involved in the structure?"
                  options={["Yes", "No"]}
                  name="isClientRepInvolved"
                  id="isClientRepInvolved"
                  reducer={reducer}
                  chapter={chapter}
                />
               )}
              </div>
              {this.props.isClientRepInvolved === "Yes" &&(
              <div className="col-lg-6">
                <NumberInput
                  label="How many intermediaries/client epresentatives can be identified?"
                  name="clientRepNum"
                  reducer="clientInformation"
                  chapter="chapter2"
                  disabled={true}
                />
                <div className="errorMessage"></div>
              </div>
              )}
               {!this.props.isClientRepInvolved && isClientRepInvolvedInter==="Yes" &&(
                 <NumberInput
                 label="How many intermediaries/client epresentatives can be identified?"
                 name="clientRepNumInter"
                 referral={["intermediaryDetails"]}
                 pushedObject={[IntermediaryObj]}
                 maxLimit={100}
                 reducer={reducer}
                 chapter={chapter}                
               />

               )}
            </Row>
           
            {this.props.isClientRepInvolved === "Yes" && intermediaries}
           
            {!this.props.isClientRepInvolved && isClientRepInvolvedInter==="Yes" && intermediariesInter}
            <CheckBox
                  label="Media Coverage on the Intermediary (Internet, Press etc."
                  options={["Yes", "No"]}
                  name="isMediaCoverage"
                  id="isMediaCoverage"
                  reducer="commonFields"
                  isCommon={true}
                  
                />
              <TextBox
                  label="What media coverage can be found on the Intermediary?"
                  placeholder="Enter your text"
                  name="mediaCoverageDesc"
                  id="mediaCoverageDesc"
                  reducer={reducer}
                  chapter={chapter}
                  validationType="Text"
                  isMultipleLines={true}
                  isRequired={false}
                  info="Print the relevant articles and add your assessment on the articles. In case of no media exposure, please mention this as evidence that you did check.
                  The feeder’s experience has been verified via various open source checks as mentioned 
                  above. There are no adverse hits on the feeder."
                />   
            {/* <div className="html2pdf__page-break"></div> */}
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-4">
                <CheckBox
                  label="Was the client transferred from another service provider (trust office)?"
                  options={["Yes", "No"]}
                  name="isClientTransfrd"
                  id="isClientTransfrd"
                  reducer="commonFields"
                  isCommon={true}
                  bothDisabled={true}
                />
              </div>
              {isClientTransfrd === "Yes" && (
                <>
                  <div className="mb-2 col-lg-4 fieldAlignment">
                    <TextBox
                      label="Please mention name of the service provider"
                      placeholder="enter service provider name"
                      name="srvcPrvdrName"
                      id="srvcPrvdrName"
                      reducer="commonFields"
                      isCommon={true}
                      validationType="Text"
                      disabled={true}
                    />
                  </div>
                  <div className="mb-2 col-lg-4 fieldAlignment">
                    <TextBox
                      label="What are the reasons for changing of service provider?"
                      placeholder="enter reason"
                      name="reasonClientTransfrd"
                      id="reasonClientTransfrd"
                      reducer="commonFields"
                      isCommon={true}
                      validationType="Text"
                      disabled={true}
                    />
                  </div>
                </>
              )}
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <TextBox
                  label="If the intermediary does not have an ultimate beneficial owner, which information does UIM have based on which this has been determined?"
                  placeholder="Enter your text"
                  name="noUBOInfoByUIM"
                  id="noUBOInfoByUIM"
                  reducer={reducer}
                  chapter={chapter}
                  validationType="Text"
                  isMultipleLines={true}
                  isRequired={false}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg.noUBOInfoByUIM?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.noUBOInfoByUIM}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-4">
                <CheckBox
                  label="Pascal available of legal entity, client representative and UBO of intermediary?"
                  options={["Yes", "No"]}
                  name="isPascalReport"
                  id="isPascalReport"
                  reducer={reducer}
                  chapter={chapter}
                  isRequired={false}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg.isPascalReport?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isPascalReport}
                    </span>
                  )}
              </div>
              {isPascalReport === "Yes" && (
                <div className="mb-2 col-lg-4">
                  <InternalFileUpload
                    label="Upload report"
                    name="pascalReportUpload"
                    id="pascalReportUpload"
                    reducer={reducer}
                    acceptType={fileAcceptType}
                    chapter={chapter}
                    isRequired={false}
                  />
                  {errorsMsg &&
                    errorsMsg.isSubmitted === true &&
                    errorsMsg.pascalReportUpload?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.pascalReportUpload}
                      </span>
                    )}
                </div>
              )}
              {isPascalReport === "No" && (
                <div className="mb-2 col-lg-4">
                  <TextBox
                    label="Why not?"
                    name="noPascalReportReason"
                    reducer={reducer}
                    id="noPascalReportReason"
                    placeholder="Enter reason"
                    chapter={chapter}
                    isRequired={true}
                  />
                  {errorsMsg &&
                    errorsMsg.isSubmitted === true &&
                    errorsMsg.noPascalReportReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.noPascalReportReason}
                      </span>
                    )}
                </div>
              )}
            </Row>
            <MediaCoverage
              chapter={chapter}
              reducer={reducer}
              errors={formErrorsChapter2}
              form="NL"
            />
            {/* <div className="html2pdf__page-break"></div> */}
            <Risks
              chapter={chapter}
              reducer={reducer}
              form="NL"
              errors={formErrorsChapter2}
              riskLabel="Distribution risk rating"
              questionLabel="How many distribution risks have been identified?"
              boxLabel="Risk"
              isRequired={false}
              form="NL"
            />
            {/* <div className="html2pdf__page-break"></div> */}
            <Row className="pb-2">
              <div className="mb-2 col-lg-12">
                <TextBox
                 // label="Explain if there are (integrity) risks concerning intermediary or former (trust) service provider."
                 label="Explain all distribution risks"
                  placeholder="Enter your text"
                  name="risksCncrnngIntermediary"
                  id="risksCncrnngIntermediary"
                  reducer={reducer}
                  chapter={chapter}
                  validationType="Text"
                  isRequired={false}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg.risksCncrnngIntermediary?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.risksCncrnngIntermediary}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="pt-1 pb-2">
              <div className="mb-2 col-lg-4">
                <CheckBox
                  label="Is it sufficiently clear why the intermediary or former (trust) service provider has terminated its services?"
                  options={["Yes", "No"]}
                  name="isSrvcPrvdrTermntdSrvcsClr"
                  id="isSrvcPrvdrTermntdSrvcsClr"
                  reducer={reducer}
                  chapter={chapter}
                  isRequired={false}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg.isSrvcPrvdrTermntdSrvcsClr?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isSrvcPrvdrTermntdSrvcsClr}
                    </span>
                  )}
              </div>
              <div className="mb-2 col-lg-4">
                <CheckBox
                  label="Is the intermediary or former (trust) service provider reputable?"
                  options={["Yes", "No"]}
                  name="isSrvcPrvdrReputable"
                  reducer={reducer}
                  id="isSrvcPrvdrReputable"
                  chapter={chapter}
                  isRequired={false}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg.isSrvcPrvdrReputable?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isSrvcPrvdrReputable}
                    </span>
                  )}
              </div>
              <div className="mb-2 col-lg-4">
                <CheckBox
                  label="Can we be certain that we will receive all necessary information?"
                  options={["Yes", "No"]}
                  name="isReceiveNecessaryInfo"
                  id="isReceiveNecessaryInfo"
                  reducer={reducer}
                  chapter={chapter}
                  isRequired={false}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg.isReceiveNecessaryInfo?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isReceiveNecessaryInfo}
                    </span>
                  )}
              </div>
            </Row>
            <Row className="pt-1">
              <div className="mb-3 col-lg-12">
                <CheckBox
                  //label="In addition, report whether the distribution criteria result in a potentially increased integrity risk (sector risk analysis)"
                  label="Does the distribution criteria result in a potentially increased integrity risk?"
                  options={["Yes", "No"]}
                  name="isIncreasedIntegrityRisk"
                  id="isIncreasedIntegrityRisk"
                  reducer={reducer}
                  chapter={chapter}
                  isRequired={false}
                />
                {errorsMsg &&
                  errorsMsg.isSubmitted === true &&
                  errorsMsg.isIncreasedIntegrityRisk?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isIncreasedIntegrityRisk}
                    </span>
                  )}
              </div>
              {isIncreasedIntegrityRisk === "Yes" && (
                <div className="mb-2 col-lg-12">
                  <TextBox
                    label="Please describe"
                    placeholder="Enter your text"
                    name="increasedIntegrityRiskReason"
                    id="increasedIntegrityRiskReason"
                    reducer={reducer}
                    chapter={chapter}
                    validationType="Text"
                    isMultipleLines={true}
                    isRequired={false}
                  />
                  {errorsMsg &&
                    errorsMsg.isSubmitted === true &&
                    errorsMsg.increasedIntegrityRiskReason?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.increasedIntegrityRiskReason}
                      </span>
                    )}
                </div>
              )}
              {isIncreasedIntegrityRisk === "No" && (
                <div className="col-lg-12">
                  <b>
                    With respect to the intermediaries and/or feeders of the
                    client, UIM sees no reason to discontinue the set-up of a
                    business relationship
                  </b>
                </div>
              )}
              </Row>
              <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <h4 className="sub-chapter-title mb-2">Risk analysis- Distribution</h4>
                </div>
            </Row>
            <Row className="mb-3">
              <div className="col-lg-12">
                <CheckBox
                  label="With respect to the intermediaries and/or feeders of the client, UIM sees no reason to discontinue the set-up of a business relationship."
                  name="isUIMnoResonIntermediaries"
                  id="isUIMnoResonIntermediaries"
                  options={["Yes", "No"]}
                  chapter={chapter}
                  reducer={reducer}
                  isRequired={false}
                />
                {errorsMsg &&
                  errorsMsg?.isSubmitted === true &&
                  errorsMsg?.isUIMnoResonDiscntnuBusRel?.length > 0 && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errorsMsg.isUIMnoResonDiscntnuBusRel}
                    </span>
                  )}
              </div>
            </Row>

            <Row className="mb-3">
              {isUIMnoResonIntermediaries === "Yes" && (
                <div className="col-lg-12">
                  <TextBox
                    label="Report on activities"
                    name="reportOnActivities"
                    id="reportOnActivities"
                    placeholder="Enter your text"
                    chapter={chapter}
                    reducer={reducer}
                    isRequired={false}
                  />
                  {errorsMsg &&
                    errorsMsg?.isSubmitted === true &&
                    errorsMsg?.reportOnActivities?.length > 0 && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errorsMsg.reportOnActivities}
                      </span>
                    )}
                </div>
              )} 
              {isUIMnoResonIntermediaries === "No" && (
                <div className="col-lg-12">
                  <b>
                    The activities and the sector, UIM sees no reason to
                    discountinue the set-up of a business relationship
                  </b>
                </div>
              )}
            </Row>
            <Row className="pt-3 pb-2">
              <div className="mb-2 col-lg-12">
                <button
                  onClick={this.handleSubmit}
                  type="button"
                  className="action-btn noicon pt-2 pb-2 pl-4 pr-4 d-block ml-auto mr-auto mt-5"
                >
                  Proceed to Next
                </button>
              </div>
            </Row>
          </div>
          {this.state.isLoading && <div className="loader"></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ClientAcceptance: state.ClientAcceptance,
    isPascalReport: state.ClientAcceptance.chapter2["isPascalReport"],
    clientRepNumInter: state.ClientAcceptance.chapter2["clientRepNumInter"],
    isUIMnoResonIntermediaries: state.ClientAcceptance.chapter2["isUIMnoResonIntermediaries"],
    isClientRepInvolved:
      state.clientInformation.chapter2["isClientRepInvolved"],
    isClientRepInvolvedInter:state.ClientAcceptance.chapter2["isClientRepInvolved"],
    clientRepNum: state.clientInformation.chapter2["clientRepNum"],
    isClientTransfrd: state.commonFields["isClientTransfrd"],
    isIncreasedIntegrityRisk:
      state.ClientAcceptance.chapter2["isIncreasedIntegrityRisk"],
    data: state,
    errors: state.InternalChapterErrors.formErrorschapter2,
    commonFields: state.commonFields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetClientAcceptance: async (data) =>
      dispatch(await GetClientAcceptance(data)),
    SetClientAcceptance: async (data) =>
      dispatch(await SetClientAcceptance(data)),
    setInternalChapterError: async (data) =>
      dispatch(await setInternalChapterError(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapter2);
